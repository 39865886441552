import { CaseType } from './Case';
import { ContactType } from './Contact';
import { HandwryttenCardOrderDetails } from './Handwrytten';

export interface CampaignType {
  firmId: string;
  campaignId: string;
  dateCreated?: string;
  dateModified?: string;

  caseType?: string[];
  title?: string;
  description?: string;
  status?: 'active' | 'past' | 'draft' | 'paused';
}

export interface CompleteCampaignType {
  campaignTriggerId: string;
  dateCreated?: string;
  dateModified?: string;
  firmId: string;
  caseStatus: string;
  campaignTriggerMessages: CampaignTriggerMessageType[];
}

export interface CampaignTriggerType {
  firmId: string;
  campaignTriggerId: string;
  dateCreated?: string;
  dateModified?: string;

  caseStatus?: string;
}

export interface CampaignTriggerMessageType {
  firmId: string;
  campaignTriggerMessageId: string;
  dateCreated?: string;
  dateModified?: string;

  message?: string;
  type?: string;
}

export type CampaignTypeRoute = 'case' | 'contact';

export const CampaignTypeRouteOptions: Record<CampaignTypeRoute, string> = {
  case: 'case',
  contact: 'contact',
} as const;

export type CampaignStep = 'criteria' | 'stages' | 'subscription' | 'summary';
export const CampaignStepOptions: Record<CampaignStep, string> = {
  criteria: 'criteria',
  stages: 'stages',
  subscription: 'subscription',
  summary: 'summary',
} as const;

export interface CampaignDetails {
  firmId: string;
  description?: string;
  status: CaseCampaignStatus;
  title?: string;
  dateCreated?: string;
  dateModified?: string;
  provisionedPhoneNumberId?: string;
}

export type CaseCampaignStatus = 'active' | 'past' | 'draft' | 'paused';
export interface CaseCampaign extends CampaignDetails {
  caseCampaignId: string;
  caseTypeIds: string[];
  caseCampaignTriggers: CaseCampaignTrigger[];
  caseCampaignSubscriptions: CaseCampaignSubscription[];
}

export type CampaignDeliveryCadence = 'sendImmediately' | 'sendAfter' | 'sendOnRepeat';

export const deliveryCadenceListItems: Record<string, CampaignDeliveryCadence> = {
  Immediately: 'sendImmediately',
  'After a period': 'sendAfter',
  Recurring: 'sendOnRepeat',
} as const;

export const deliveryCadenceListItemsWithDetails = {
  Immediately: {
    value: 'sendImmediately',
    details: 'Message will be sent within 15 minutes of case status update.',
  },
  'After a period': {
    value: 'sendAfter',
    details: 'Message will be sent after the specified amount of time in the case status.',
  },
  Recurring: {
    value: 'sendOnRepeat',
    details: 'Message will be sent on specified schedule until case status updates.',
  },
} as const;
export type CampaignDeliveryMechanism = 'sms' | 'handwrytten';

export const deliveryMechanismOptionsMap: Record<CampaignDeliveryMechanism, string> = {
  sms: 'Text',
  handwrytten: 'Handwrytten',
} as const;

export type SendImmediatelyParams = Record<string, never>;

export interface SendAfterParams {
  sendAfterTimeAmount: number;
  sendAfterTimeDimensions: 'hours' | 'days' | 'weeks';
}

export type WeekdayOptions = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

export type TimeZoneOptions =
  | 'US/Alaska'
  | 'US/Aleutian'
  | 'US/Arizona'
  | 'US/Central'
  | 'US/East-Indiana'
  | 'US/Eastern'
  | 'US/Hawaii'
  | 'US/Indiana-Starke'
  | 'US/Michigan'
  | 'US/Mountain'
  | 'US/Pacific'
  | 'US/Samoa';

export interface SendOnRepeatParams {
  sendOnRepeatDimension: 'Daily' | 'Weekly' | 'Monthly';
  sendOnRepeatWeekday: WeekdayOptions[];
  sendOnRepeatCalendarDay: string[];
  sendOnRepeatTime: string;
  sendOnRepeatTimeHour: number;
  sendOnRepeatTimeMinute: number;
  sendOnRepeatTimeZone: TimeZoneOptions;
}

export type CaseCampaignTriggerParams<T extends CampaignDeliveryCadence> =
  T extends 'sendImmediately'
    ? SendImmediatelyParams
    : T extends 'sendAfter'
    ? SendAfterParams
    : T extends 'sendOnRepeat'
    ? SendOnRepeatParams
    : never;
export interface CaseCampaignTrigger {
  caseCampaignTriggerId?: string;
  caseStatusId: string;
  deliveryCadence: CampaignDeliveryCadence;
  message: string;

  deliveryMechanism?: CampaignDeliveryMechanism;
  sendAfterTimeAmount?: number;
  sendAfterTimeDimension?: string;
  sendOnRepeatDimension?: string;
  sendOnRepeatCalendarDay?: string;
  sendOnRepeatWeekday?: (string | undefined)[];
  sendOnRepeatTime?: any;
  sendOnRepeatTimeHour: number;
  sendOnRepeatTimeMinute: number;
  sendOnRepeatTimeZone?: string;
  dateCreated?: string;
  dateModified?: string;
  handwryttenFontId?: string;
  handwryttenCardId?: string;
  provisionedPhoneNumberId?: string;
}

export type ContactTypeOptions = 'individual' | 'organization';
export interface ContactCampaign extends CampaignDetails {
  contactCampaignId: string;
  contactType: ContactTypeOptions[];
  inclusionaryTags: string[];
  exclusionaryTags: string[];
  contactCampaignTriggers: ContactCampaignTrigger[];
  contactCampaignSubscriptions: ContactCampaignSubscription[];
}

export type ContactCampaignTriggerOptions = 'isBirthday' | 'date';
export interface ContactCampaignTrigger {
  contactCampaignTriggerId?: string;
  deliveryMechanism: CampaignDeliveryMechanism;
  message: string;

  isBirthday?: string;
  date?: string;

  sendAtTimeHour?: number;
  sendAtTimeMinute?: number;
  sendAtTimeZone: TimeZoneOptions;
  handwryttenFontId?: string;
  handwryttenCardId?: string;
  provisionedPhoneNumberId?: string;
}

export type CampaignSubscriptionStatus = 'active' | 'inactive';
export interface CaseCampaignSubscription {
  dateCreated?: string;
  dateModified?: string;
  caseCampaignId: string;
  caseCampaignSubscriptionId: string;
  status: CampaignSubscriptionStatus;
  case: CaseType;
}

export interface ContactCampaignSubscription {
  dateCreated?: string;
  dateModified?: string;
  contactCampaignId: string;
  contactCampaignSubscriptionId: string;
  status: CampaignSubscriptionStatus;
  contact: ContactType;
}
