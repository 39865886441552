import {
  CaseCompleteType,
  SibylsCaseContactConnectionType,
  calculateDaysAgo,
  formatPhoneNumber,
  formatPrice,
  getEarliestStatuteOfLimitations,
  getMedicalCoverageForCaseList,
  prettifyDateString,
  prettifyDateTimeString,
} from '@colosseum/data';
import {
  CaseConflictIcon,
  ContactItemViewer,
  TooltipWrapper,
  Typography,
  conflictFilterFn,
  containsAssigneeFilterFn,
  containsTagsFilterFn,
  containsTeamFilterFn,
  dateRangeFilterFn,
  rangeFilterFn,
  renderCell,
  renderCellWithAssigneeChipGroup,
  renderHeader,
  renderNumberCellWithHover,
  renderTextCell,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import {
  CaseConflictType,
  ItemTagConnectionType,
  MedicalTreatmentBill,
  PolicyType,
  StaffType,
  TaskType,
  TeamType,
  taskStatusOptions,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';

const statuteOfLimitationsDaysAway = {
  warning: {
    days: 90,
    color: 'yellow',
  },
  danger: {
    days: 30,
    color: 'red',
  },
} as const;

export const columns: ColumnDef<CaseCompleteType>[] = [
  {
    accessorFn: (row) => row.caseTitle,
    id: 'Case Title',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.caseType?.title,
    id: 'Case Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) => row?.caseStatus?.title,
    id: 'Case Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) => {
      const currentStatus = row.caseStatusUpdates?.find((statusUpdate) => !statusUpdate.dateEnded);
      return dayjs(currentStatus?.dateEnded).diff(currentStatus?.dateStarted, 'days');
    },
    id: 'Days in Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      const goal = row.caseStatus?.goal;
      if (row.caseStatusUpdates?.length === 0 || !goal) return null;
      const dateStarted = row?.caseStatusUpdates?.[row?.caseStatusUpdates.length - 1].dateStarted;
      const dateEnded = row.caseStatusUpdates?.[row.caseStatusUpdates.length - 1].dateEnded;
      const duration = dayjs(dateEnded).diff(dateStarted, 'days');
      const percent = duration / goal;
      if (percent > 1) return 'over';
      if (percent >= 0.8 && percent <= 1) return 'approaching';
      return 'under';
    },
    enableHiding: false, // This column only exists for filtering purposes
    id: 'Days in Status Relative to Goal',
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => {
      const status = props.getValue() as string;
      return renderTextCell({ value: status?.toString() ?? '-' });
    },
  },
  {
    accessorFn: (row) => row.caseStatus?.goal,
    id: 'Case Status Goal',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },

  {
    accessorFn: (row) => prettifyDateString(row.caseOpenDate),
    id: 'Open Date',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    filterFn: dateRangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => {
      const openDate = props?.getValue() as string;
      if (!openDate) return renderTextCell({ value: '-' });
      const daysAgo = calculateDaysAgo(openDate);
      const returnString = `${prettifyDateString(openDate)} (${daysAgo})`;
      return renderTextCell({ value: returnString });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateOfIncident),
    id: 'Incident Date',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    filterFn: dateRangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.initialFilingDate),
    id: 'Initial Filing Date',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    filterFn: dateRangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.trialDate),
    id: 'Trial Date',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    filterFn: dateRangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },

  {
    accessorFn: (row) => prettifyDateString(getEarliestStatuteOfLimitations(row).date),

    id: 'Statute Date',
    sortUndefined: -1,
    filterFn: dateRangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => {
      const statuteDate = getEarliestStatuteOfLimitations(props.row.original).date;
      const statuteOfLimitationsSatisfied = getEarliestStatuteOfLimitations(
        props.row.original,
      ).satisfied;

      const daysAway = dayjs(statuteDate).diff(dayjs().format('YYYY-MM-DD'), 'days');
      const dateToDisplay = statuteDate
        ? `${prettifyDateString(statuteDate)} (${pluralize('day', daysAway, true)})`
        : '-';
      return (
        <div>
          <Typography
            className="flex space-x-2"
            color={
              statuteOfLimitationsSatisfied
                ? 'default'
                : daysAway && daysAway < statuteOfLimitationsDaysAway.danger.days
                ? statuteOfLimitationsDaysAway.danger.color
                : daysAway && daysAway < statuteOfLimitationsDaysAway.warning.days
                ? statuteOfLimitationsDaysAway.warning.color
                : 'default'
            }
          >
            {daysAway && daysAway < 90 && !statuteOfLimitationsSatisfied ? (
              <TooltipWrapper message="The Statute of Limitations on this case is approaching and has not yet been satisfied.">
                <span>{dateToDisplay}</span>
              </TooltipWrapper>
            ) : (
              dateToDisplay
            )}
          </Typography>
        </div>
      );
    },
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'thirdPartyCoverage',
        returnUnformatted: true,
      })[0],
    id: 'Third Party Coverage Person',
    filterFn: rangeFilterFn<CaseCompleteType>,
    enableHiding: false, // This column only exists for filtering purposes
    sortUndefined: -1,
    header: renderHeader,

    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'thirdPartyCoverage',
        returnUnformatted: true,
      })[1],
    id: 'Third Party Coverage Accident',
    filterFn: rangeFilterFn<CaseCompleteType>,
    enableHiding: false, // This column only exists for filtering purposes
    sortUndefined: -1,
    header: renderHeader,

    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'thirdPartyCoverage',
      }),
    id: 'Third Party Coverage',
    sortUndefined: -1,
    header: renderHeader,

    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'umUimCoverage',
      }),
    id: 'UM/UIM Coverage',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'umUimCoverage',
        returnUnformatted: true,
      })[0],
    id: 'UM/UIM Coverage Person',
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortUndefined: -1,
    enableHiding: false, // This column only exists for filtering purposes
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      getMedicalCoverageForCaseList({
        caseData: row,
        type: 'umUimCoverage',
        returnUnformatted: true,
      })[1],
    id: 'UM/UIM Coverage Accident',
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortUndefined: -1,
    enableHiding: false, // This column only exists for filtering purposes
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.medicalTreatmentAmountBilled,
    id: 'Total Medicals',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: rangeFilterFn<CaseCompleteType>,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row.projectedSettlementAmount,
    id: 'Projected Settlement Amount',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: rangeFilterFn<CaseCompleteType>,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row.estimatedMedicalCosts,
    id: 'Estimated Medical Costs',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: rangeFilterFn<CaseCompleteType>,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row.tags,
    id: 'Tags',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: containsTagsFilterFn<CaseCompleteType>,
    cell: (props) => {
      const tagList = props.getValue<ItemTagConnectionType[]>() ?? [];

      const tagTitles = tagList.map((tag) => tag?.tagAttributes?.title).join(', ');

      return renderNumberCellWithHover({
        value: `${tagList.length || '-'}`,
        tooltipText: tagTitles,
      });
    },
  },
  {
    accessorFn: (row) => row.tasks,
    id: 'Uncompleted Tasks',
    sortUndefined: -1,
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortingFn: (a, b, columnId) => {
      const aTasks = a.getValue<TaskType[]>(columnId);
      const aIncompleteTasks = aTasks.filter((task) => task.status !== taskStatusOptions.completed);
      const aTasksLeftPercentage = aTasks.length
        ? (aIncompleteTasks.length / aTasks.length) * 100
        : 0;
      const bTasks = b.getValue<TaskType[]>(columnId);
      const bIncompleteTasks = bTasks.filter((task) => task.status !== taskStatusOptions.completed);
      const bTasksLeftPercentage = bTasks.length
        ? (bIncompleteTasks.length / bTasks.length) * 100
        : 0;
      if (bTasks.length === 0) return 1;
      if (aTasks.length === 0) return -1;
      if (typeof bTasksLeftPercentage === 'undefined') return -1;
      if (aTasksLeftPercentage === bTasksLeftPercentage) {
        if (aTasks.length > bTasks.length) return 1;
        if (aTasks.length < bTasks.length) return -1;
        return 0;
      }
      return aTasksLeftPercentage > bTasksLeftPercentage ? 1 : -1;
    },
    header: renderHeader,
    cell: (props) => {
      const totalTasks = props.getValue() as TaskType[];
      const incompleteTasks = totalTasks.filter(
        (task) => task.status !== taskStatusOptions.completed,
      );
      if (totalTasks.length === 0) return renderTextCell({ value: '-' });
      const displayVal = `${incompleteTasks.length} / ${totalTasks.length} (${Math.round(
        (incompleteTasks.length / totalTasks.length) * 100,
      )}%)`;
      return renderTextCell({ value: displayVal });
    },
  },
  {
    accessorFn: (row) => {
      const totalTasks = row.tasks;
      const incompleteTasks = totalTasks.filter(
        (task) => task.status !== taskStatusOptions.completed,
      );
      if (totalTasks.length === 0) return '0';
      return `${Math.round((incompleteTasks.length / totalTasks.length) * 100)}`;
    },
    id: 'Uncompleted Tasks Percentage',
    sortUndefined: -1,
    filterFn: rangeFilterFn<CaseCompleteType>,
    enableHiding: false, // This column only exists for filtering purposes
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.assignees,
    id: 'Assignees',
    sortUndefined: -1,
    filterFn: containsAssigneeFilterFn<CaseCompleteType>,
    header: renderHeader,
    sortingFn: (a, b, columnId) => {
      const aAssigneesRaw: StaffType[] = a.getValue(columnId);
      const bAssigneesRaw: StaffType[] = b.getValue(columnId);
      const aAssignees = uniqBy(aAssigneesRaw, 'username').filter(
        (assignee) => assignee.displayName !== '-',
      );
      const bAssignees = uniqBy(bAssigneesRaw, 'username').filter(
        (assignee) => assignee.displayName !== '-',
      );

      if (aAssignees.length > bAssignees.length) return 1;
      if (aAssignees.length < bAssignees.length) return -1;
      if (aAssignees.length === bAssignees.length) {
        let aBigger: 1 | -1 | 0 = 0;
        aAssignees.some((assignee, index) => {
          const bAssigneesIndex = bAssignees[index];
          if (!assignee?.displayName || !bAssigneesIndex?.displayName) {
            // noop - if one assignee doesnt have a name, we can't compare
          } else if (assignee?.displayName < bAssigneesIndex?.displayName) {
            aBigger = 1; // a is bigger, so we return 1
            return true;
          } else if (assignee?.displayName > bAssigneesIndex?.displayName) {
            aBigger = -1; // b is bigger, so we return -1
            return true;
          }
          return false; // if they are equal, we continue to the next assignee
        });
        return aBigger;
      }
      return 0;
    },
    cell: (props) => renderCellWithAssigneeChipGroup({ props }),
  },
  {
    accessorFn: (row) => row.teams,
    id: 'Teams',
    sortUndefined: -1,
    filterFn: containsTeamFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => {
      const teams = props.getValue() as TeamType[];
      return renderTextCell({ value: teams[0]?.title ?? '-' });
      // This can be updated to show multiple teams if needed
    },
  },
  {
    accessorFn: (row) => row.expenses,
    id: 'Expenses',
    sortUndefined: -1,
    filterFn: rangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row.conflicts,
    id: 'Conflicts',
    sortUndefined: -1,
    filterFn: conflictFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => {
      const conflicts = props.getValue<CaseConflictType[]>();
      if (conflicts?.length === 0) return renderTextCell({ value: '-' });
      return <CaseConflictIcon caseConflicts={conflicts ?? []} />;
    },
  },
  {
    accessorFn: (row) => row.medicalTreatments,
    id: 'Treatments',
    sortUndefined: -1,
    filterFn: rangeFilterFn<CaseCompleteType>,
    header: renderHeader,
    cell: (props) => {
      const treatments = props.getValue() as MedicalTreatmentBill[];
      const returnVal = treatments?.length || '-';
      return renderTextCell({ value: returnVal.toString() });
    },
  },
  {
    accessorFn: (row) => row.surgeryCount,
    id: 'Surgery Count',
    sortingFn: (a, b, columnId) => {
      const aCount = a.getValue<number>(columnId);
      const bCount = b.getValue<number>(columnId);
      if (aCount === null) return -1;
      if (bCount === null) return 1;
      return aCount > bCount ? 1 : -1;
    },
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortUndefined: -1,

    header: renderHeader,
    cell: (props) => {
      const surgeryCount = props.getValue<number | null>();
      return renderTextCell({ value: surgeryCount?.toString() ?? '-' });
    },
  },
  {
    accessorFn: (row) => row.mriCount,
    id: 'MRI Count',
    sortingFn: (a, b, columnId) => {
      const aCount = a.getValue<number>(columnId);
      const bCount = b.getValue<number>(columnId);
      if (aCount === null) return -1;
      if (bCount === null) return 1;
      return aCount > bCount ? 1 : -1;
    },
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => {
      const mriCount = props.getValue<number | null>();
      return renderTextCell({ value: mriCount?.toString() ?? '-' });
    },
  },
  {
    accessorFn: (row) => row.injectionCount,
    id: 'Injection Count',
    sortingFn: (a, b, columnId) => {
      const aCount = a.getValue<number>(columnId);
      const bCount = b.getValue<number>(columnId);
      if (aCount === null) return -1;
      if (bCount === null) return 1;
      return aCount > bCount ? 1 : -1;
    },
    filterFn: rangeFilterFn<CaseCompleteType>,
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => {
      const injectionCount = props.getValue<number | null>();
      return renderTextCell({ value: injectionCount?.toString() ?? '-' });
    },
  },
  {
    accessorFn: (row) => row.referralSource,
    id: 'Marketing Referral Source',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.referralSourceId, // This only exists for filtering purposes
    id: 'Marketing Referral Source ID',
    sortUndefined: -1,
    enableHiding: false,
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.courtName,
    id: 'Court Name',
    sortUndefined: -1,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.courtType,
    id: 'Court Type',
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.courtLocation,
    id: 'Court Location',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) => (row.statuteOfLimitationsSatisfied === '1' ? 'Yes' : 'No'),
    id: 'Statute of Limitations Satisfied',
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentStreet,
    id: 'Incident Street',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentCity,
    id: 'Incident City',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentState,
    id: 'Incident State',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentZipCode,
    id: 'Incident Zip Code',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => getContactsByType(row, 'client').length,
    id: 'No. of Clients on Case',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => getContactsNameList(row, getContactsByType(row, 'client')),
    id: 'Client Names',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as string[];
      if (value.length === 0) return '-';

      return (
        <ContactItemViewer
          primaryValue={value.slice(0, 3).join(', ')}
          additionalValues={value.slice(3)}
        />
      );
    },
  },
  {
    accessorFn: (row) => getContactsEmailList(row, getContactsByType(row, 'client')),
    id: 'Client Emails',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as string[];
      if (value.length === 0) return '-';
      return <ContactItemViewer primaryValue={value[0]} additionalValues={value.slice(1)} />;
    },
  },
  {
    accessorFn: (row) => getContactsPhoneList(row, getContactsByType(row, 'client')),
    id: 'Client Phone Numbers',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as string[];
      if (value.length === 0) return '-';
      return <ContactItemViewer primaryValue={value[0]} additionalValues={value.slice(1)} />;
    },
  },
  {
    accessorFn: (row) => getContactsEmailList(row, getContactsByType(row, 'defendant')),
    id: 'Defendant Emails',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as string[];
      if (value.length === 0) return '-';
      return <ContactItemViewer primaryValue={value[0]} additionalValues={value.slice(1)} />;
    },
  },
  {
    accessorFn: (row) => getContactsPhoneList(row, getContactsByType(row, 'defendant')),
    id: 'Defendant Phone Numbers',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as string[];
      if (value.length === 0) return '-';
      return <ContactItemViewer primaryValue={value[0]} additionalValues={value.slice(1)} />;
    },
  },
  {
    accessorFn: (row) => getLastInboundCommunication(getContactsByType(row, 'client')),
    id: 'Last Inbound Communication',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => getLastOutboundCommunication(getContactsByType(row, 'client')),
    id: 'Last Outbound Communication',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.policies,
    id: 'Claim Numbers',
    header: renderHeader,
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
    cell: (props) => {
      const value = props.getValue() as PolicyType[];
      if (!value?.length) return '-';

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const claimNumbers: string[] = value.map((p) => p.claimNumber).filter(Boolean) as string[];

      return (
        <ContactItemViewer
          primaryValue={claimNumbers.slice(0, 3).join(', ')}
          additionalValues={claimNumbers.slice(3)}
        />
      );
    },
  },
];

function getContactsByType(caseData: CaseCompleteType, type: 'client' | 'defendant') {
  return caseData.caseContactConnections?.filter((connection) =>
    connection.roles.find((r) => r.roleOnCase === type),
  );
}

function getContactsOnCase(
  caseData: CaseCompleteType,
  contacts: SibylsCaseContactConnectionType[],
) {
  return contacts?.map((client) =>
    caseData.contacts?.find((contact) => contact.contactId === client.contactId),
  );
}

function getContactsNameList(
  caseData: CaseCompleteType,
  contacts: SibylsCaseContactConnectionType[],
) {
  return getContactsOnCase(caseData, contacts)
    .map((client) => (client?.name?.trim() === '' ? client?.firstName : client?.name))
    .filter(Boolean);
}

function getContactsEmailList(
  caseData: CaseCompleteType,
  contacts: SibylsCaseContactConnectionType[],
) {
  return getContactsOnCase(caseData, contacts)
    ?.map((client) => client?.emails?.[0]?.emailAddress)
    .filter(Boolean);
}

function getContactsPhoneList(
  caseData: CaseCompleteType,
  contacts: SibylsCaseContactConnectionType[],
) {
  return getContactsOnCase(caseData, contacts)
    .map((client) =>
      client?.numbers[0]?.number ? formatPhoneNumber(client.numbers?.[0]?.number) : null,
    )
    .filter(Boolean);
}

function getLastInboundCommunication(contacts: SibylsCaseContactConnectionType[]) {
  return (
    contacts
      ?.map((contact) => {
        const lastOutboundCommunication = contact.communications?.find(
          (communication) => communication.lastContactedByContact,
        );

        return lastOutboundCommunication?.lastContactedByContact;
      })
      .filter(Boolean)
      .map((date) => prettifyDateTimeString(date))
      .filter(Boolean)
      .sort((a, b) => (a && b ? new Date(a).getTime() - new Date(b).getTime() : 0))[0] ?? '-'
  );
}

function getLastOutboundCommunication(contacts: SibylsCaseContactConnectionType[]) {
  return (
    contacts
      ?.map((contact) => {
        const lastOutboundCommunication = contact.communications?.find(
          (communication) => communication.lastContactedByFirm,
        );
        return lastOutboundCommunication?.lastContactedByFirm;
      })
      .filter(Boolean)
      .map((date) => prettifyDateTimeString(date))
      .filter(Boolean)
      .sort((a, b) => (a && b ? new Date(a).getTime() - new Date(b).getTime() : 0))[0] ?? '-'
  );
}
