import { useGetAuditDetails, useGetFirmUserWithDisplayNameFromUsername } from '@colosseum/data';
import { AuditDetailWithNotificationsType } from '@gladiate/types';
import { BellIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';

/* eslint-disable-next-line */
export interface ResourceHistoryDetailProps {
  auditId: string;
  notificationAuditIds?: string[];
}

export function ResourceHistoryDetail(props: ResourceHistoryDetailProps) {
  const { auditId, notificationAuditIds } = props;
  const { data: auditDetails, isLoading: isAuditDetailsLoading } = useGetAuditDetails(auditId);
  const auditDetailsWithNotifications = auditDetails?.data?.map((auditDetail) => {
    if (notificationAuditIds && notificationAuditIds?.includes(auditDetail.auditDetailId)) {
      return {
        ...auditDetail,
        notification: true,
      } as AuditDetailWithNotificationsType;
    }
    return auditDetail as AuditDetailWithNotificationsType;
  });
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  return (
    <div className="flex flex-col pb-4 gap-y-2">
      {isAuditDetailsLoading ? (
        <GladiateLoader className="w-24" />
      ) : (
        auditDetailsWithNotifications?.map((auditDetail) => (
          <div key={auditDetail.auditDetailId} className="flex flex-col p-2">
            <div className="flex gap-x-2">
              <div className="text-md">{auditDetail.auditDetailMessage}</div>
              {auditDetail.notification && (
                <div className="self-center">
                  <BellIcon height={20} className="text-orange-500" />
                </div>
              )}
            </div>

            <div className="flex items-center mt-2">
              <span className="flex items-center pr-4 text-xs gap-x-2">
                <ProfilePhoto
                  className="self-center text-xs"
                  loading={false}
                  diameter={3}
                  displayName={auditDetail.username ?? ''}
                />
                {getFirmUserWithDisplayNameFromUsername(auditDetail.username ?? '')?.displayName ??
                  'System'}
              </span>
              <span className="text-xs text-gray-500 ">
                {dayjs(`${auditDetail.dateModified}Z`).format(
                  // Z is so that it converts to local time
                  'MMM DD YYYY, h:mm a',
                )}
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ResourceHistoryDetail;
