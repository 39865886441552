import { cn, displayContactName, prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ContactType, PriorMedicalTreatmentType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<
  PriorMedicalTreatmentType & {
    medicalProvider?: ContactType;
    medicalTreatmentTypeName?: string;
  }
>[] = [
  {
    accessorFn: (row) => displayContactName(row.medicalProvider),
    id: 'Medical Provider',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<string>();

      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Medical Provider>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.startingDateOfService),
    id: 'Starting Date of Service',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.endingDateOfService),
    id: 'Ending Date of Service',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.medicalTreatmentTypeName,
    id: 'Type of Treatment',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.numberOfVisits,
    id: 'Number of Visits',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
