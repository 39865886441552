import {
  useCreateOneClickSignSetting,
  useDeleteOneClickSignSetting,
  useGetOneClickSignSettingList,
} from '@colosseum/data';
import {
  Button,
  CreateButton,
  GladiateLoader,
  OneClickSignSettingsForm,
  Slideover,
  Typography,
  oneClickSignSettingsFormId,
} from '@colosseum/shared-ui';
import { OneClickSignSettingType } from '@gladiate/types';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface OneClickSignSettingsProps {}

export function OneClickSignSettings() {
  const [open, setOpen] = useState(false);
  const [selectedOneClickSetting, setSelectedOneClickSetting] = useState<
    OneClickSignSettingType | undefined
  >(undefined);

  const { data: oneClickSignData, isLoading } = useGetOneClickSignSettingList();
  const createOneClickSignSetting = useCreateOneClickSignSetting();
  const deleteOneClickSignSetting = useDeleteOneClickSignSetting();

  useEffect(() => {
    if (selectedOneClickSetting?.oneClickSignSettingId) {
      setOpen(true);
    }
  }, [selectedOneClickSetting]);

  useEffect(() => {
    if (open === false) {
      setSelectedOneClickSetting(undefined);
    }
  }, [open]);

  return (
    <>
      <div className="relative bg-white rounded-2xl" data-automations-submenu-item="One Click Sign">
        <div className="justify-between sm:flex sm:items-center">
          <div>
            <Typography className="mt-2" size="xl" variant="heading">
              One Click Sign
            </Typography>
            <Typography className="mt-2" size="sm">
              Set up your One Click Sign account to enable signing documents
            </Typography>
          </div>
          <CreateButton
            title="Create One Click Sign"
            loading={createOneClickSignSetting.isLoading}
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        {isLoading && <GladiateLoader />}
        {!isLoading && !oneClickSignData?.data.length && (
          <div className="w-full flex justify-center items-center">
            <Typography className="mt-2" size="sm" variant="subtext">
              No results
            </Typography>
          </div>
        )}
        <div className="mt-6 space-y-4 overflow-auto sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3 max-h-96">
          {oneClickSignData &&
            oneClickSignData?.data.map((item) => (
              <div
                className="relative px-5 border border-gray-300 rounded-lg shadow-lg py-7"
                key={item.oneClickSignSettingId}
              >
                <button
                  onClick={() => {
                    setSelectedOneClickSetting(item);
                  }}
                  className="absolute top-2 right-2 hover:text-sky-blue hover:animate-pulse"
                >
                  <PencilSquareIcon className="w-6 h-6" />
                </button>
                <div className="text-xl font-bold text-sky-blue">
                  {item.title || <Typography>Untitled</Typography>}
                </div>
                <div className="mt-3 mb-3 text-sm font-semibold text-gray-400">
                  {item.description || (
                    <Typography>This setting does not have a description yet</Typography>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Slideover
        dataCy="task-plan-slideover"
        title={'One Click Sign Setting'}
        description={'Edit One Click Sign setting'}
        open={open}
        setOpen={setOpen}
        displayDeleteButton={true}
        typing={false}
        submitButton={
          <Button
            className="flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-sky-blue hover:bg-atlantic-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-blue"
            form={oneClickSignSettingsFormId}
            content="Submit"
            value="Submit"
          >
            {selectedOneClickSetting?.oneClickSignSettingId ? 'Update' : 'Create'}
          </Button>
        }
        deleteFunction={() => {
          if (selectedOneClickSetting?.oneClickSignSettingId) {
            deleteOneClickSignSetting.mutateAsync(selectedOneClickSetting.oneClickSignSettingId);
            setSelectedOneClickSetting(undefined);
          }
        }}
      >
        <div>
          <OneClickSignSettingsForm
            selectedOneClickSignSetting={selectedOneClickSetting}
            setOpen={setOpen}
          ></OneClickSignSettingsForm>
        </div>
      </Slideover>
    </>
  );
}

export default OneClickSignSettings;
