import {
  useGetAllTimeLogs,
  useGetCase,
  useGetCurrentUser,
  useUpdateTimeLog,
} from '@colosseum/data';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { SlideoverContext } from '../SlideoverProvider';
import TimeLogSlideover from '../TimeLogSlideover/TimeLogSlideover';
import Timer from '../Timer/Timer';

export function TimeLogProvider() {
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const location = useLocation();

  const { caseId } = useParams() as { caseId: string };
  const currentUser = useGetCurrentUser();
  const { data: allTimeLogsData } = useGetAllTimeLogs(currentUser?.data?.data?.Username);
  const updateTimeLog = useUpdateTimeLog();
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const itemId = pendingSlideoverToOpen?.id;
  const unfinishedTimeLog = allTimeLogsData?.data?.find((timeLog) => !timeLog.endTime);
  const unfinishedTimeLogCaseId = unfinishedTimeLog?.timeLogId.split('-')[0];
  const { data: caseData } = useGetCase(unfinishedTimeLogCaseId);

  const handleEndTimeLog = () => {
    if (unfinishedTimeLog) {
      setPendingSlideoverToOpen({
        id: unfinishedTimeLog.timeLogId.split('-')[0],
        type: 'timeLog',
      });
      updateTimeLog.mutate({
        timeLogId: unfinishedTimeLog.timeLogId,
        endTime: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    }
  };

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'timeLog') {
      setSlideoverOpen(true);
    }
  }, [pendingSlideoverToOpen]);

  useEffect(() => {
    const currentLocationCaseId = location.pathname.split('/')[2];
    if (
      currentLocationCaseId !== unfinishedTimeLogCaseId &&
      currentUser?.data?.data?.automaticTimerStop === '1' &&
      unfinishedTimeLog
    ) {
      // If we load to a page that isn't the case the timer is running for, stop the timer
      handleEndTimeLog();
    }
  }, [location, unfinishedTimeLog, currentUser?.data?.data?.automaticTimerStop]);

  return (
    <>
      {unfinishedTimeLog &&
        currentUser?.data?.data &&
        !(unfinishedTimeLogCaseId === caseId) &&
        !(currentUser?.data?.data?.automaticTimerStop === '1') && (
          <div
            className="sticky rounded-lg top-2 lg:ml-[275px] ml-2 mr-2 z-50 min-h-10 bg-orange-500 cursor-pointer"
            onClick={() => {
              setPendingSlideoverToOpen({
                id: unfinishedTimeLog.timeLogId,
                type: 'timeLog',
              });
            }}
          >
            <div className="m-4 text-xl font-semibold leading-10 text-center text-white ">
              Active timer for
              <NavLink to={`cases/${unfinishedTimeLogCaseId}`} className="text-blue-300 underline">
                {` ${caseData?.data?.caseTitle ?? 'No title'}`}
              </NavLink>
              {`: `}
              <Timer startTime={unfinishedTimeLog?.startTime} runTimer={!!unfinishedTimeLog} />
            </div>
          </div>
        )}
      <TimeLogSlideover open={slideoverOpen} setOpen={setSlideoverOpen} itemId={itemId} />
    </>
  );
}

export default TimeLogProvider;
