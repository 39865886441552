import {
  cn,
  useCreateCoCounsel,
  useCreateCoCounselForm,
  useDeleteCoCounsel,
  useDeleteUserFromFirm,
  useGetCoCounselsComplete,
  useGetFirmGroupsAndPermissions,
  useUpdateCoCounselForm,
} from '@colosseum/data';
import { CreateButton, DataTable, Form, GladiateLoader, Slideover } from '@colosseum/shared-ui';
import { useState } from 'react';
import { CoCounselForm } from './CoCounselForm';
import { columns } from './co-counsel-table-columns';

export const CoCounselSection = () => {
  const [open, setOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [selectedUserId, setSelectedUserName] = useState<string>();
  const { data: coCounselsData, isLoading: isCoCounselsLoading } = useGetCoCounselsComplete();
  const selectedUser = coCounselsData?.data.find((user) => user.username === selectedUserId);
  const { data: firmGroupsData } = useGetFirmGroupsAndPermissions();
  const firmGroups = firmGroupsData?.data ?? [];
  const coCounselFirmGroup = firmGroups.find((group) => group.groupName === 'CoCounsel');

  const createCoCounsel = useCreateCoCounsel();
  const deleteCoCounsel = useDeleteCoCounsel();
  const deleteUser = useDeleteUserFromFirm();
  const coCounsels = coCounselsData?.data || [];
  const {
    form: newCoCounselForm,
    handleUpdate: handleCreateCoCounsel,
    isLoading: isCreateCoCounselLoading,
  } = useCreateCoCounselForm({
    setOpen,
    setTyping: setIsTyping,
  });
  const { form: updateCoCounselForm, handleUpdate: handleUpdateCoCounsel } =
    useUpdateCoCounselForm(selectedUser);
  const handleDelete = () => {
    deleteCoCounsel.mutateAsync(selectedUser?.username).then(() => {
      // delete cocounsel user, then firm user
      deleteUser.mutate(selectedUser?.username);
    });
  };

  const handleSubmit = () => {
    if (isCreating) {
      handleCreateCoCounsel().then(() => {
        setIsTyping(false);
        setOpen(false);
      });
    }
  };

  const handleOnBlur = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    handleUpdateCoCounsel(target.name, target.value);
  };

  return (
    <>
      {isCreating ? (
        <Form {...newCoCounselForm}>
          <Slideover
            title="Add New Co-Counsel to Firm"
            description={
              isCreating
                ? 'Enter the username of the user you would like to add as co-counsel'
                : 'View and edit the details of the selected co-counsel'
            }
            open={open}
            setOpen={setOpen}
            displayDeleteButton={false}
            deleteFunction={handleDelete}
            bottomButtons={
              <div className="flex items-center justify-between px-5 py-5 border-t border-t-gray-200">
                <button
                  type="button"
                  className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
                  onClick={() => {
                    setOpen(false);

                    setTimeout(() => {
                      setIsCreating(false);
                    }, 1000);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={
                    isCreating
                      ? newCoCounselForm.handleSubmit(handleSubmit)
                      : updateCoCounselForm.handleSubmit(handleSubmit)
                  }
                  className={cn(
                    'inline-flex justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                    'bg-sky-blue hover:bg-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue',
                  )}
                >
                  {isCreateCoCounselLoading ? (
                    <div>
                      <GladiateLoader white height={20} />
                    </div>
                  ) : (
                    'Add User'
                  )}
                </button>
              </div>
            }
            typing={isTyping}
          >
            <div>
              <CoCounselForm groupId={coCounselFirmGroup?.groupId} />
            </div>
          </Slideover>
        </Form>
      ) : (
        <Form {...updateCoCounselForm}>
          {' '}
          <Slideover
            title={'Co-Counsel Details'}
            description={'View and edit the details of the selected co-counsel'}
            open={open}
            setOpen={setOpen}
            displayDeleteButton={true}
            deleteFunction={handleDelete}
            typing={isTyping}
          >
            <div>
              {selectedUser ? (
                <CoCounselForm
                  selectedUser={selectedUser}
                  groupId={coCounselFirmGroup?.groupId}
                  handleOnBlur={handleOnBlur}
                />
              ) : undefined}
            </div>
          </Slideover>
        </Form>
      )}

      <div className="px-1">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Co-Counsel</h1>
            <p className="mt-2 text-sm text-gray-700">{`A list of all the 'Co-Counsel' users in your firm collaborating on cases`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title="Add Co-Counsel"
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-blue hover:bg-atlantic-blue focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                setSelectedUserName(undefined);
                setIsCreating(true);
                setOpen(true);
              }}
              loading={createCoCounsel.isLoading}
            />
          </div>
        </div>
        {isCoCounselsLoading ? (
          <div>
            <GladiateLoader height={150} />
          </div>
        ) : (
          <DataTable
            tableName="User Groups"
            handleRowClick={(row) => {
              setIsCreating(false);
              setOpen(true);
              setSelectedUserName(row.original.username);
              // noop TODO: Add slideover for editing
            }}
            data={coCounsels}
            columns={columns}
            filters={[]}
            hideViewButton
          />
        )}
      </div>
    </>
  );
};

export default CoCounselSection;
