import { CaseType, ContactType, RecordRequest } from '@gladiate/types';
import { useGetRecordRequests } from '../lib/react-query/medical-record-request';
import { useGetCasesComplete } from './useGetCasesComplete';
import { flattenArrayByKey } from '../utils/miscUtils';
import { useGetFirmMedicalTreatments } from '../lib/react-query/medical-treatment';
import { useGetContactsInfinite } from '../lib/react-query/contact';
// import { useGetContacts } from '../lib/react-query/contact';

export interface InsightsMedicalRecordsViewModel extends RecordRequest {
  recordRequestor: string;
  case: CaseType;
  provider: string;
}

export const useInsightsMedicalRecordsViewModel = () => {
  const { data: medicalRecordsData, isLoading: medicalRecordsDataLoading } = useGetRecordRequests();

  const records = Object.values(medicalRecordsData?.data ?? {})
    .map((record) => {
      return record;
    })
    .flat();

  return {
    data: {
      data: records,
    },
    isLoading: medicalRecordsDataLoading,
  };
};
