import { LinkIcon } from '@heroicons/react/20/solid';
import {
  BanknotesIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Statistic } from '../models/Statistic';

/* eslint-disable-next-line */
export interface FirmStatisticProps {
  statistic: Statistic;
}

export function FirmStatistic(props: FirmStatisticProps) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (props.statistic?.title?.toLowerCase()?.includes('statute')) {
          const sixMonthsAway: string = dayjs().add(6, 'month').format('YYYY-MM-DD');
          const today = dayjs().format('YYYY-MM-DD');
          navigate(`/cases?Statute+Date=0,${sixMonthsAway}&Statute+of+Limitations+Satisfied=No`);
        }
      }}
      className={classNames(
        'flex relative flex-col justify-between p-4 overflow-hidden bg-white rounded-lg shadow group',
        props.statistic?.title?.toLowerCase()?.includes('statute')
          ? 'cursor-pointer hover:bg-light-blue'
          : null,
      )}
    >
      {props.statistic?.title?.toLowerCase()?.includes('statute') && (
        <div className="absolute bg-gray-100 rounded-lg top-1 right-1 p-0.5 group-hover:bg-atlantic-blue">
          <LinkIcon className="w-5 h-5 text-gray-500 group-hover:text-white " />
        </div>
      )}
      <div className="pb-3">
        {props.statistic.icon === 'briefcase' ? <BriefcaseIcon className="w-6 h-6" /> : null}
        {props.statistic.icon === 'clock' ? <ClockIcon className="w-6 h-6" /> : null}
        {props.statistic.icon === 'banknotes' ? <BanknotesIcon className="w-6 h-6" /> : null}
        {props.statistic.icon === 'calendar-days' ? <CalendarDaysIcon className="w-6 h-6" /> : null}
      </div>

      <dd
        className={classNames(
          'mt-1 font-semibold tracking-tight text-gray-900',
          Number(props.statistic.value) > 100000 ? 'text-xl' : 'text-3xl',
        )}
      >
        {props.statistic.icon === 'banknotes'
          ? props.statistic.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
          : props.statistic.value}
      </dd>

      <dt title={props.statistic.title} className="text-sm font-medium text-gray-500 truncate ">
        {props.statistic.title}
      </dt>
    </div>
  );
}

export default FirmStatistic;
