import { PAGES_MAP, PermissionType, SUBSCRIPTION_ACCESS_MAP } from '@gladiate/types';
import { pickBy } from 'lodash';
import { useCallback } from 'react';
import { useGetCurrentUser } from '../lib/react-query/firm';
import { useGetSubscriptions } from '../lib/react-query/payment';

export const usePermissions = () => {
  const { data: currentUserData } = useGetCurrentUser();
  const currentUser = currentUserData?.data;
  const isCocounsel = currentUser?.groupName === 'CoCounsel';

  const {
    subscriptionCaseOptimizationId,
    subscriptionClientEngagementId,
    subscriptionCoCounselId,
    subscriptionInsightId,
    subscriptionLeadManagementId,
  } = currentUserData?.data || {};

  const currentUserSubscriptions = pickBy(
    {
      subscriptionCaseOptimizationId,
      subscriptionClientEngagementId,
      subscriptionCoCounselId,
      subscriptionInsightId,
      subscriptionLeadManagementId,
    },
    (value) => !!value,
  );

  const queryParams = Object.values(currentUserSubscriptions)?.join(',');

  const { data: userSubscriptionData, isLoading: userSubscriptionDataIsLoading } =
    useGetSubscriptions(queryParams && `subscriptionIds=${queryParams}`);

  const { subscriptions } = userSubscriptionData?.data || {};
  const validSubscriptionTypes = subscriptions
    ?.filter((sub) => sub.status === 'active' || sub.status === 'pending')
    .map((sub) => sub.subscriptionType);

  /**
   * @description A hook to check if the current user has access to a service based on the access type (READ, MODIFY, DELETE)
   */
  const hasServiceAccess = useCallback(
    (service: keyof PermissionType) => {
      const permissions = currentUser?.[service];

      if (!service) {
        return true;
      }

      if (!permissions) {
        return false;
      }

      return permissions === '1';
    },
    [currentUser],
  );

  /**
   * @description A hook to check if the current user has access to a section permissions
   * @usage This would get triggered if for instance a user tries to access a page that they haven't been given
   * access to via user group permissions (ie. if they directly type in the URL)
   */
  const hasPageAccess = useCallback(
    (page: keyof typeof PAGES_MAP) => {
      const permission = currentUser?.[page];

      if (!page) {
        return true;
      }

      return permission === '1';
    },
    [currentUser],
  );

  /**
   * @description A hook to check if the current user has access to a section based on their subscription types
   */
  const hasSubscriptionAccess = useCallback(
    (section: keyof typeof PAGES_MAP) => {
      const allowedSections = new Set(
        validSubscriptionTypes?.map((sub) => SUBSCRIPTION_ACCESS_MAP[sub]).flat(),
      );

      return allowedSections.has(section);
    },
    [currentUser, userSubscriptionData],
  );

  /**
   * @description A hook to check if the current user has access to a section based on their subscription types
   */
  const hasAssignedLicense = useCallback(() => {
    return Object.values(currentUserSubscriptions).some((value) => !!value);
  }, [currentUser]);

  return {
    hasServiceAccess,
    hasPageAccess,
    isCocounsel,
    hasSubscriptionAccess,
    hasSubscriptionAccessIsLoading: userSubscriptionDataIsLoading,
    hasAssignedLicense,
  };
};
