import { PolicyType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { ZodSchema, z } from 'zod';
import { useUpdatePolicyForScenario } from '../../../lib/react-query/vehicle';
import { zodCurrencyType } from '../zodValidationTypes';

export const NoticeStatusOptions = [
  { value: 'unknown', label: 'Unknown' },
  { value: 'sent-notice', label: 'Sent Notice' },
  { value: 'ledger-received', label: 'Ledger Received' },
  { value: 'na', label: 'N/A' },
];

export const CoverageTypeOptions = [
  { value: 'employer-sponsored', label: 'Employer Sponsored' },
  { value: 'gov-chip', label: 'Government CHIP' },
  { value: 'gov-medicare', label: 'Government Medicare' },
  { value: 'gov-medicaid', label: 'Government Medicaid' },
  { value: 'gov-state-chi', label: 'Government State CHI' }, // state
  { value: 'gov-state-medicaid', label: 'Government State Medicaid' }, // state
  { value: 'gov-tricare', label: 'Government Tricare' },
  { value: 'gov-va', label: 'Government VA' },
  { value: 'marketplace-plan', label: 'Marketplace Plan' },
];

export const StateCoverageTypeOptions = ['gov-state-chi', 'gov-state-medicaid'];

export const HealthPolicyFormSchema = z.object({
  policyType: z.string().optional(),
  coverageStatus: z.string().optional(),
  noticeStatus: z
    .enum(NoticeStatusOptions.map((option) => option.value) as [string, ...string[]])
    .optional(),
  coverageType: z
    .enum(CoverageTypeOptions.map((option) => option.value) as [string, ...string[]])
    .optional(),
  state: z.string().optional(),
  memberId: z.string().optional(),
  payerNumber: z.string().optional(),
  claimSubmissionNumber: z.string().optional(),
  coverageStartDate: z.string().optional(),
  coverageEndDate: z.string().optional(),
  deductibleAmount: zodCurrencyType,
  outOfPocketMax: zodCurrencyType,
});
export const healthPolicyFormFields = Object.keys(HealthPolicyFormSchema.shape);

export const LifePolicyFormSchema = z.object({
  policyNumber: z.string().optional(),
  claimNumber: z.string().optional(),
  coverageStartDate: z.string().optional(),
  contestabilityEndDate: z.string().optional(),
  deathBenefit: zodCurrencyType,
  accidentalDeathBenefitRider: z.string().optional(),
});
export const lifePolicyFormFields = Object.keys(LifePolicyFormSchema.shape);

export const DefaultPolicyFormSchema = z.object({
  bodilyInjuryLiabilityCoverage: z.string().optional(),
  bodilyInjuryLiabilityPerAccident: zodCurrencyType,
  bodilyInjuryLiabilityPerPerson: zodCurrencyType,
  claimNumber: z.string().optional(),
  coverageStatus: z.string().optional(),
  deductibleAmount: zodCurrencyType,
  effectiveDatesOfCoverageEnd: z.string().optional(),
  effectiveDatesOfCoverageStart: z.string().optional(),
  liabilityCoverage: z.string().optional(),
  liabilityCoveragePerAccident: zodCurrencyType,
  liabilityCoveragePerPerson: zodCurrencyType,
  medicalPaymentCoverage: z.string().optional(),
  medicalPaymentCoverageAmount: zodCurrencyType,
  personalInjuryProtectionAmount: zodCurrencyType,
  personalInjuryProtectionCoverage: z.string().optional(),
  policyNumber: z.string().optional(),
  policyType: z.string().optional(),
  propertyDamageLiability: zodCurrencyType,
  propertyDamageLiabilityCoverage: z.string().optional(),
  rentalReimbursement: zodCurrencyType,
  type: z.string().optional(),
  umbrellaPolicy: zodCurrencyType,
  umbrellaPolicyCoverage: z.string().optional(),
  umUimCoverage: z.string().optional(),
  umUimCoverageAmountPerAccident: zodCurrencyType,
  umUimCoverageAmountPerPerson: zodCurrencyType,
  umUimMotoristPropertyDamage: zodCurrencyType,
  umUimMotoristPropertyDamageCoverage: z.string().optional(),
});

export const DefaultValues: z.infer<typeof DefaultPolicyFormSchema> = {
  bodilyInjuryLiabilityCoverage: undefined,
  bodilyInjuryLiabilityPerAccident: 0,
  bodilyInjuryLiabilityPerPerson: 0,
  claimNumber: '',
  coverageStatus: 'unknown',
  deductibleAmount: 0,
  effectiveDatesOfCoverageEnd: '',
  effectiveDatesOfCoverageStart: '',
  liabilityCoverage: '',
  liabilityCoveragePerAccident: 0,
  liabilityCoveragePerPerson: 0,
  medicalPaymentCoverage: '',
  medicalPaymentCoverageAmount: 0,
  personalInjuryProtectionAmount: 0,
  personalInjuryProtectionCoverage: '',
  policyNumber: '',
  policyType: 'firstParty',
  propertyDamageLiability: 0,
  propertyDamageLiabilityCoverage: '',
  rentalReimbursement: 0,
  type: 'auto',
  umbrellaPolicy: 0,
  umbrellaPolicyCoverage: '',
  umUimCoverage: '',
  umUimCoverageAmountPerAccident: 0,
  umUimCoverageAmountPerPerson: 0,
  umUimMotoristPropertyDamage: 0,
  umUimMotoristPropertyDamageCoverage: '',
};

export const usePolicyForm = ({ policy }: { policy?: PolicyType }) => {
  const updatePolicy = useUpdatePolicyForScenario();

  // Dynamically set the schema based on the policy type since they have different fields
  let schema: ZodSchema;
  switch (policy?.type) {
    case 'Life':
      schema = LifePolicyFormSchema;
      break;
    case 'Health':
      schema = HealthPolicyFormSchema;
      break;
    default:
      schema = DefaultPolicyFormSchema;
      break;
  }

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: 'onBlur',
    defaultValues: policy,
  });

  const setupPayload = (name: string, value: string | number | undefined) => {
    if (!policy) {
      enqueueSnackbar('Policy not found', {
        variant: 'error',
      });

      return;
    }
    const payload = {
      policyId: policy.policyId,
      [name]: !isNil(value) ? value : null,
    };
    // We want to clear the dependant values if a user selects notApplicable or unknown for a policy coverage value
    if (['notApplicable', 'unknown'].includes(String(value))) {
      switch (name) {
        case 'bodilyInjuryLiabilityCoverage':
          form.setValue('bodilyInjuryLiabilityPerAccident', null);
          form.setValue('bodilyInjuryLiabilityPerPerson', null);
          payload.bodilyInjuryLiabilityPerAccident = null;
          payload.bodilyInjuryLiabilityPerPerson = null;
          break;
        case 'propertyDamageLiabilityCoverage':
          form.setValue('propertyDamageLiability', null);
          payload.propertyDamageLiability = null;
          break;
        case 'personalInjuryProtectionCoverage':
          form.setValue('personalInjuryProtectionAmount', null);
          payload.personalInjuryProtectionAmount = null;
          break;
        case 'medicalPaymentCoverage':
          form.setValue('medicalPaymentCoverageAmount', null);
          payload.medicalPaymentCoverageAmount = null;
          break;
        case 'umUimCoverage':
          form.setValue('umUimCoverageAmountPerAccident', null);
          form.setValue('umUimCoverageAmountPerPerson', null);
          payload.umUimCoverageAmountPerAccident = null;
          payload.umUimCoverageAmountPerPerson = null;
          break;
        case 'umUimMotoristPropertyDamageCoverage':
          form.setValue('umUimMotoristPropertyDamage', null);
          payload.umUimMotoristPropertyDamage = null;
          break;
        case 'liabilityCoverage':
          form.setValue('liabilityCoveragePerAccident', null);
          form.setValue('liabilityCoveragePerPerson', null);
          payload.liabilityCoveragePerAccident = null;
          payload.liabilityCoveragePerPerson = null;
          break;
        case 'umbrellaPolicyCoverage':
          form.setValue('umbrellaPolicy', null);
          payload.umbrellaPolicy = null;
          break;
        default:
          break;
      }
    }
    return payload;
  };

  const handleUpdatePolicy = (
    name: keyof Omit<PolicyType, 'firmId' | 'policyId' | 'dateCreated' | 'dateModified'>,
    value: string | number | undefined,
    overrideDirty?: boolean,
  ) => {
    // Only update if the field is dirty
    if (form.getFieldState(name).isDirty || overrideDirty) {
      const payload = setupPayload(name, value);
      if (payload) {
        updatePolicy.mutateAsync(payload).then(() => {
          form.resetField(name, { defaultValue: value });
        });
      }
    }
  };
  return { form, handleUpdatePolicy, updatePolicyMutation: updatePolicy, setupPayload };
};
