import { TemplateDictionaryItemType } from '@gladiate/types';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<TemplateDictionaryItemType>();

export const columns = [
  columnHelper.accessor((row) => row.name, {
    id: 'Name',
  }),
  columnHelper.accessor((row) => row.description, {
    id: 'Description',
  }),
  columnHelper.accessor((row) => row.type, {
    id: 'Type',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor((row) => row.value.split('_')[0], {
    id: 'Resource',
    filterFn: 'arrIncludesSome',
  }),
];
