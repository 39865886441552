import {
  CompleteLienType,
  cn,
  displayContactName,
  formatPrice,
  prettifyDateString,
} from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ContactType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<CompleteLienType & { contact?: ContactType }>[] = [
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      return displayContactName(row.lienHolder);
    },
    id: 'Lien Holder',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<[string[], any]>();

      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Lien Holder>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      return displayContactName(row.lienProvider);
    },
    id: 'Lien Provider',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<[string[], any]>();

      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Lien Provider>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => {
      return displayContactName(row.billingEntity);
    },
    id: 'Billing Entity',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<[string[], any]>();

      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Billing Entity>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.lienAmount,
    id: 'Lien Amount',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return [row.reductionAmount, row.reductionPercentage];
    },
    id: 'Reduction', // changing this has alot of side effects
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const [reductionAmount, reductionPercentage] = props.getValue<[number, number]>();
      const reductionAmountToDisplay =
        reductionAmount >= 0 ? formatPrice(Number(reductionAmount)) : '-';
      const reductionPercentageToDisplay =
        reductionPercentage >= 0 ? `${reductionPercentage.toFixed(2)}%` : '-';
      return (
        <div>
          <span>
            {reductionAmount >= 0
              ? `${reductionAmountToDisplay} (${reductionPercentageToDisplay})`
              : '-'}
          </span>
        </div>
      );
    },
    aggregationFn: (_columnId, values) => {
      let aggregateReductionAmount: number = 0;
      let aggregateFinalBalance: number = 0;
      values.forEach((value) => {
        const { reductionAmount, finalBalance } = value.original;
        if (reductionAmount && reductionAmount > 0) {
          aggregateReductionAmount += value?.original?.reductionAmount ?? 0;
        }
        if (finalBalance && finalBalance > 0) {
          aggregateFinalBalance += value?.original?.finalBalance ?? 0;
        }
      });
      const aggregateReductionPercentage =
        aggregateReductionAmount > 0
          ? (1 - aggregateFinalBalance / aggregateReductionAmount) * 100
          : undefined;
      return [aggregateReductionAmount, aggregateReductionPercentage];
    },
    aggregatedCell: (props) => {
      const [reductionAmount, reductionPercentage] =
        props.getValue<[number | undefined, number | undefined]>();
      if (
        typeof reductionAmount == 'undefined' ||
        typeof reductionPercentage == 'undefined' ||
        reductionAmount < 0 ||
        reductionPercentage < 0
      ) {
        return '-';
      }
      return `${formatPrice(reductionAmount)} (${reductionPercentage.toFixed(2)}%)`;
    },
  },
  {
    accessorFn: (row) => row.finalBalance,
    id: 'Final Balance',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.filingDetails,
    id: 'Filing Details',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (row.accepted === '1' ? 'Accepted' : '-'),
    id: 'Accepted by Holder',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.balanceVerifiedDate),
    id: 'Date Verified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
