// Dependencies
import { casesSectionMappings, scrollToCaseSection } from '@colosseum/data';
import {
  AgreementStatusTable,
  FileExplorer,
  OneDriveRepo,
  SlideoverContext,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { useContext, useEffect, useState } from 'react';

export interface CaseFileSectionProps {
  caseId: string;
}

export function CaseFileSection(props: CaseFileSectionProps) {
  const { caseId } = props;
  const [activeTab, setActiveTab] = useState('gladiate');

  const { pendingSlideoverToOpen } = useContext(SlideoverContext);

  useEffect(() => {
    const ref = document.querySelectorAll(
      `[data-case-submenu-item='${casesSectionMappings.files}']`,
    )[0];

    if (pendingSlideoverToOpen?.type === 'agreement') {
      setActiveTab('agreement');
      scrollToCaseSection(ref, true);
    }
    if (pendingSlideoverToOpen?.type === 'file') {
      setActiveTab('gladiate');
      scrollToCaseSection(ref, true);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger
          className={tabTriggerClass}
          key="gladiate"
          value="gladiate"
          data-cy="case-gladiate-files-tab"
        >
          Files
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="oneDrive"
          value="oneDrive"
          data-cy="case-oneDrive-files-tab"
        >
          OneDrive
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} key="agreement" value="agreement">
          Send For Sign Status
        </TabsTrigger>
      </TabsList>
      <TabsContent value="gladiate">
        <FileExplorer caseId={caseId} />
      </TabsContent>
      <TabsContent value="oneDrive">
        <OneDriveRepo />
      </TabsContent>
      <TabsContent value="agreement">
        <AgreementStatusTable />
      </TabsContent>
    </Tabs>
  );
}

export default CaseFileSection;
