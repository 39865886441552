import { bitBToB, cn, prettifyDateString, prettifyDateTimeString } from '@colosseum/data';
import { Chip, renderCell, renderHeader } from '@colosseum/shared-ui';
import { EventAttendeeType, OfflineEventType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
export const columns: ColumnDef<
  OfflineEventType & {
    ownerDisplayName?: string;
    categoryTitle?: string;
    attendeesWithDisplayNames: ({ displayName: string } & EventAttendeeType)[];
  }
>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateTimeString(row.startDateTime),
    id: 'Start Time',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateTimeString(row.endDateTime),
    id: 'End Time',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      row.attendeesWithDisplayNames.map((attendee) => attendee.displayName).join(', '),
    id: 'Attendees',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const { row } = props;
      const attendees = row.original.attendeesWithDisplayNames;
      return (
        <div className={cn('flex gap-2 flex-wrap', attendees?.length > 1 ? 'min-w-[350px] ' : '')}>
          {attendees?.map((attendee) => {
            return (
              <Chip
                key={attendee.attendeeId}
                name={attendee.displayName}
                color={attendee.attendeeType === 'user' ? 'blue' : 'green'}
              />
            );
          })}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.categoryTitle,
    id: 'Category',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (bitBToB(row.synced) ? 'Yes' : 'No'),
    id: 'Synced',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (row.busy ? 'Yes' : 'No'),
    id: 'Busy',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.location,
    id: 'Location',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.ownerDisplayName,
    id: 'Owner',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
