import { prettifyDateString } from '@colosseum/data';
import {
  renderCell,
  renderCellWithChips,
  renderHeader,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { CaseCampaign } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<CaseCampaign>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    filterFn: stringIncludesFilterFn,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    filterFn: stringIncludesFilterFn,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.status,
    id: 'Status',
    filterFn: stringIncludesFilterFn,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Created Date',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
];
