// Dependencies
import {
  VITE_API_STAGE,
  apiStageOptions,
  calculateDaysAgo,
  casesSectionMappings,
  copyToClipboard,
  findRoleSpecificCaseContactConnections,
  getNameToDisplayFromCognito,
  prettifyDateString,
  useCreateCaseContactConnection,
  useCreateLinkedTogetherCase,
  useDeleteLinkedTogetherCase,
  useGetCase,
  useGetCaseContactConnectionsViewModel,
  useGetCommunications,
  useGetContact,
  useGetFirmUsers,
  useGetLinkedTogetherCases,
  useGetStaff,
  useLocalStorage,
} from '@colosseum/data';
import { Button, ContactInfoSlideover, TooltipWrapper } from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  CaseContactConnectionViewModelType,
  ContactType,
  ContactViewModelType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { ClipboardDocumentCheckIcon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { CaseClientCard } from '../CaseClientCard/CaseClientCard';
import { CaseSummaryCard } from '../CaseSummaryCard/CaseSummaryCard';
import ClientsOnCaseCard from '../ClientsOnCaseCard/ClientsOnCaseCard';
import { LinkedCasesCard } from '../LinkedCasesCard/LinkedCasesCard';
import PinnedNotesCard from '../PinnedNotesCard/PinnedNotesCard';

export interface SummarySectionProps {
  caseId: string;
  setSettingsSliderOpen: (open: boolean) => void;
}

const API_STAGE = VITE_API_STAGE;

export function SummarySection(props: SummarySectionProps) {
  const { caseId, setSettingsSliderOpen } = props;

  const [selectedClientId, setSelectedClientId] = useLocalStorage<string | undefined>(
    `selectedClientId-${caseId}`,
    undefined,
  );
  const [newClientSlideoverOpen, setNewClientSlideoverOpen] = useState(false);
  const [isDeletingContact, setIsDeletingContact] = useState(false);

  const { data: caseData, isLoading: isCaseLoading } = useGetCase(caseId);

  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;

  const createCaseContactConnection = useCreateCaseContactConnection();

  const { data: staffData } = useGetStaff(caseId);

  const caseAssigneeUsernames = uniqBy(staffData?.data, 'username');

  const caseAssignees =
    firmUsersData
      ?.filter((firmUser) =>
        caseAssigneeUsernames
          .map((assignees) => assignees?.username)
          .includes(firmUser?.Username ?? ''),
      )
      .map((item) => {
        if (!item?.name) {
          return item?.Username ?? '';
        }

        return getNameToDisplayFromCognito(item) ?? '-';
      }) ?? [];

  const { data: caseContactConnectionsData, isLoading: isCaseContactConnectionsLoading } =
    useGetCaseContactConnectionsViewModel(caseId);

  const caseContactConnections = caseContactConnectionsData?.data.map((connection) => {
    return {
      ...connection,
      ...connection.contact,
    } as CaseContactConnectionViewModelType & ContactViewModelType;
  });

  const caseClientConnection = selectedClientId
    ? findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
        caseContactConnections: caseContactConnections ?? [],
        role: caseContactConnectionOptions.client,
      }).find((connection) => connection.contact?.contactId === selectedClientId)
    : findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
        caseContactConnections: caseContactConnections ?? [],
        role: caseContactConnectionOptions.client,
      })[0];

  const clientsOnCase = findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
    caseContactConnections: caseContactConnections ?? [],
    role: caseContactConnectionOptions.client,
  }).map((connection) => connection?.contact);

  function clientsOnCaseClickHandler(client: ContactType) {
    setSelectedClientId(client.contactId);
  }

  // Linked Cases
  const { data: linkedTogetherCaseData, isLoading: isLinkedTogetherCaseLoading } =
    useGetLinkedTogetherCases(caseId);
  const linkedTogetherCases = linkedTogetherCaseData?.data;

  const createLinkedTogetherCaseMutation = useCreateLinkedTogetherCase(caseId);
  function handleCreateLinkedTogetherCase(linkedCaseId: string) {
    createLinkedTogetherCaseMutation.mutateAsync({
      caseId,
      linkedCaseId,
    });
  }

  const deleteLinkedTogetherCaseMutation = useDeleteLinkedTogetherCase();
  function handleDeleteLinkedTogetherCase(linkedCaseId: string) {
    deleteLinkedTogetherCaseMutation.mutateAsync(linkedCaseId);
  }

  const {
    data: clientContactData,
    isLoading: isContactLoading,
    isFetching: isContactFetching,
  } = useGetContact(selectedClientId ?? caseClientConnection?.contact?.contactId);
  const selectedClient = clientContactData?.data;

  const { data: communicationsData, isLoading: isCommunicationsDataLoading } = useGetCommunications(
    selectedClient?.contactId ?? '',
  );

  const communications = communicationsData?.data;

  const lastContactedByFirm = communications?.lastContactedByFirm ?? '';

  const lastContactedByContact = communications?.lastContactedByContact ?? '';

  const handleCreateConnection = (
    roleOnCase: CaseContactConnectionRoleType['roleOnCase'],
    contactId?: string,
  ) => {
    createCaseContactConnection
      .mutateAsync({ caseId, contactId, roleOnCase })
      .then((res) =>
        createCaseContactConnection.mutateAsync({
          caseId,
          contactId: res.data?.contactId ?? contactId,
          roleOnCase: caseContactConnectionOptions.party,
        }),
      )
      .then((res) => {
        if (!contactId) {
          setSelectedClientId(res.data?.contactId);
          setNewClientSlideoverOpen(true);
        } else {
          setSelectedClientId(contactId);
        }
      });
  };

  useEffect(() => {
    if (isDeletingContact) {
      if (selectedClient?.contactId === caseClientConnection?.contact?.contactId) {
        setSelectedClientId(undefined);
      }
      setIsDeletingContact(false);
    }
  }, [isDeletingContact]);

  return (
    <div
      className="w-full p-5 bg-white border shadow-sm rounded-xl"
      data-case-submenu-item={casesSectionMappings.summary}
    >
      <div className="flex justify-between mb-4">
        <div className="flex items-center">
          <div className="w-4 h-8 rounded-md bg-light-blue"></div>
          <h2 className="ml-2 text-xl font-semibold">{casesSectionMappings.summary}</h2>
        </div>
        <div className="flex flex-wrap items-center gap-x-3">
          <div className="flex items-center pr-4">
            <div className="mr-2 font-semibold tracking-tighter text-gray-400 uppercase">
              Case Opened
            </div>
            <div>
              {caseData?.data?.caseOpenDate
                ? `${prettifyDateString(caseData?.data?.caseOpenDate) ?? ''} (${calculateDaysAgo(
                    caseData?.data?.caseOpenDate,
                  )})`
                : 'N/A'}
            </div>
          </div>
          {caseData?.data?.secureReferenceId && (
            <div className="flex items-center pr-2 text-gray-400 rounded-lg">
              <TooltipWrapper message={'Send an email to this address to save it as a case note.'}>
                <div className="flex items-center mr-2 font-semibold tracking-tighter uppercase gap-x-1">
                  Case Email Address
                </div>
              </TooltipWrapper>
              <TooltipWrapper message={'Copy to clipboard'}>
                <Button variant="ghost" className="text-gray-900">
                  <ClipboardDocumentCheckIcon
                    className="w-5 h-5"
                    onClick={() => {
                      if (API_STAGE === apiStageOptions.prod) {
                        copyToClipboard(
                          `case+${caseData?.data?.secureReferenceId}@forwarding.gladiatelaw.com`,
                        );
                      } else {
                        copyToClipboard(
                          `case.${API_STAGE}+${caseData?.data?.secureReferenceId}@forwarding.gladiatelaw.com`,
                        );
                      }
                    }}
                  />
                </Button>
              </TooltipWrapper>
            </div>
          )}
          <Button
            variant="outline"
            data-cy="case-settings-button"
            onClick={() => {
              setSettingsSliderOpen(true);
            }}
            className="flex items-center group"
          >
            <Cog8ToothIcon className="w-5 h-5 mr-1 text-gray-900 group-hover:animate-spin-then-stop" />
            Settings
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col col-span-2 lg:col-span-1">
          <CaseClientCard
            caseId={caseId}
            caseClientConnection={caseClientConnection ?? ({} as CaseContactConnectionType)}
            contact={selectedClient}
            caseData={caseData?.data}
            isCaseContactConnectionsLoading={isCaseContactConnectionsLoading}
            isCaseLoading={isCaseLoading}
            isContactLoading={isContactLoading}
            isContactFetching={isContactFetching}
            messagingDates={{
              lastResponse: lastContactedByContact,
              lastOutbound: lastContactedByFirm,
            }}
            isMessagingDatesLoading={
              isContactLoading || isCaseLoading || isCommunicationsDataLoading
            }
            communicationsData={communications}
            onDeleteCallback={setIsDeletingContact}
            handleAddClient={{
              mutation: handleCreateConnection,
              isLoading: createCaseContactConnection.isLoading,
            }}
          />
          <ContactInfoSlideover
            contact={selectedClient}
            open={newClientSlideoverOpen}
            setOpen={setNewClientSlideoverOpen}
          />
          {clientsOnCase.length >= 1 && (
            <ClientsOnCaseCard
              clientsOnCase={clientsOnCase}
              onClickHandler={clientsOnCaseClickHandler}
              caseId={caseId}
              handleAddClient={{
                mutation: handleCreateConnection,
                isLoading: createCaseContactConnection.isLoading,
              }}
            />
          )}
          <LinkedCasesCard
            isLoading={isLinkedTogetherCaseLoading}
            handleUnlinkCase={{
              mutation: handleDeleteLinkedTogetherCase,
              isLoading: deleteLinkedTogetherCaseMutation.isLoading,
            }}
            handleLinkCase={{
              mutation: handleCreateLinkedTogetherCase,
              isLoading: createLinkedTogetherCaseMutation.isLoading,
            }}
            linkedCases={linkedTogetherCases}
            caseId={caseId}
          />
        </div>
        <div className="col-span-2">
          <CaseSummaryCard
            caseId={caseId}
            assignees={caseAssignees ?? []}
            caseData={caseData?.data}
            clientContactId={
              selectedClient?.contactId || caseClientConnection?.contact?.contactId || ''
            }
            isCaseLoading={isCaseLoading}
            setSettingsSliderOpen={setSettingsSliderOpen}
          />
        </div>
        <div className="col-span-2 lg:col-span-3">
          <PinnedNotesCard caseId={caseId} />
        </div>
      </div>
    </div>
  );
}

export default SummarySection;
