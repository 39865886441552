// collegium-census types

import { ItemTagConnectionType } from './Tag';
import { contactConnectionOptions } from './connectionOptions';
import { BitBoolean } from './global';

export const contactLanguageOptions = {
  Arabic: 'arabic',
  Armenian: 'armenian',
  'Cantonese Chinese': 'cantoneseChinese',
  English: 'english',
  Farsi: 'farsi',
  Hindi: 'hindi',
  Japanese: 'japanese',
  Korean: 'korean',
  'Mandarin Chinese': 'mandarinChinese',
  Persian: 'persian',
  Punjabi: 'punjabi',
  Russian: 'russian',
  Spanish: 'spanish',
  Tagalog: 'tagalog',
  Vietnamese: 'vietnamese',
} as const;

export interface ContactAddressType {
  city?: string;
  contactAddressId: string;
  addressLabel?: string;
  addressLocation?: 'Home' | 'Work' | 'Billing' | 'Other';
  country?: string;
  county?: string;
  dateCreated: string;
  dateModified: string;
  firmId: string;
  state?: string;
  streetAddress?: string;
  streetAddress2?: string;
  streetAddress3?: string;
  streetAddress4?: string;
  zipCode?: string;
}

export type ContactWebAddressType = {
  contactWebAddressId: string;
  dateCreated: string;
  dateModified: string;
  firmId: string;
  webAddress?: string;
  webAddressLabel?: string;
  webAddressType: 'Work' | 'Personal' | 'Other';
};

export type ContactNumberType = {
  firmId: string;
  contactNumberId: string;
  dateCreated?: string;
  dateModified?: string;
  number?: string;
  numberExtension?: string;
  numberLabel?: string;
  numberType?: 'phone' | 'fax';
  numberLocation?: 'Home' | 'Work' | 'Other';
};

export interface ContactConnectionType {
  connectedToContact: ContactType;
  connectedToContactId: string;
  contactConnectionId: string;
  contactEmploymentId?: string;
  dateCreated?: string;
  dateModified?: string;
  firmId: string;
  relationship?: (typeof contactConnectionOptions)[keyof typeof contactConnectionOptions];
}

export interface ContactEmailType {
  contactEmailId: string;
  dateCreated: string;
  dateModified: string;
  emailAddress?: string;
  emailLabel?: string;
  emailType?: 'Work' | 'Personal' | 'Other';
  firmId: string;
}

export interface ContactEmploymentType {
  contactEmploymentId: string;
  dateCreated: string;
  dateModified: string;
  endDate?: string;
  firmId: string;
  payRate?: number;
  payType?: string;
  responsibilities?: string;
  startDate?: string;
  title?: string;
}

export interface ContactRoleType {
  firmId: string;
  contactRoleId: string;
  dateCreated: string;
  dateModified: string;
  role: string;
}

export type ContactResourceStringType =
  | 'employments'
  | 'addresses'
  // | 'phone-numbers'
  // | 'faxes'
  | 'emails'
  | 'connections'
  | 'web-addresses'
  | 'numbers';

export interface ContactType {
  bankruptcy?: boolean;
  bankruptcyDescription?: string;
  contactId: string;
  contactType?: string;
  dateCreated?: string;
  dateModified?: string;
  dateOfBirth?: string;
  department?: string;
  deceased?: BitBoolean;
  dateOfDeath?: string;
  driversLicenseNumber?: string;
  driversLicenseState?: string;
  employedAtTimeOfIncident?: boolean;
  facebookHandle?: string;
  felony?: boolean;
  felonyDescription?: string;
  firmId: string;
  firstName?: string;
  gender?: string;
  healthClubMembershipId?: string;
  instagramHandle?: string;
  lastName?: string;
  legalStatus?: string;
  linkedInHandle?: string;
  maritalStatus?: string;
  middleName?: string;
  name?: string;
  nickname?: string;
  note?: string;
  prefix?: string;
  languages: (typeof contactLanguageOptions)[keyof typeof contactLanguageOptions][];
  priors?: boolean;
  priorsDescription?: string;
  pronouns?: 'He/Him' | 'She/Her' | 'They/Them';
  referralSource?: string;
  socialSecurityNumber?: string;
  suffix?: string;
  tikTokHandle?: string;
  twitterHandle?: string;
  unionMembershipId?: string;
  website?: string;
}

export interface ContactViewModelType extends ContactType {
  contactAddresses: ContactAddressType[];
  contactConnections: ContactConnectionType[];
  contactEmails: ContactEmailType[];
  contactEmployments: ContactEmploymentType[];
  contactNumbers: ContactNumberType[];
  contactTags: ItemTagConnectionType[];
  contactWebAddresses: ContactWebAddressType[];
}

export type AudienceType = {
  audienceId?: string;
  cities?: string[];
  contactCreatedDateRangeEnd?: string;
  contactCreatedDateRangeStart?: string;
  contactTypes?: string[];
  counties?: string[];
  dateCreated?: string;
  dateModified?: string;
  description?: string;
  emails?: string[];
  firmId?: string;
  lastContactedByClientDateRangeEnd?: string;
  lastContactedByClientDateRangeStart?: string;
  lastContactedByFirmDateRangeEnd?: string;
  lastContactedByFirmDateRangeStart?: string; // typo in model (Firmt -> Firm)
  phoneNumbers?: string[];
  referralSources?: string[];
  states?: string[];
  tags?: string[];
  title?: string;
  zipCodes?: string[];
};
