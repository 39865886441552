import { BitBoolean, DefaultV3Response, NoteType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const basePapyrusRoute = `papyrus/${API_STAGE}`;

// ----------------- NOTES -----------------

// POST create_note_v3
export const createNoteV3 = (
  resourceId: string,
  data: { message: string; type: NoteType['type'] },
) =>
  axiosInstance
    .post<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/${resourceId}/notes`, data)
    .then((res) => res.data);

// DEL delete_notes_v3
export const deleteNoteV3 = (resourceId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/${resourceId}`)
    .then((res) => res.data);

// GET get_notes_v3
export const getNotesV3 = (resourceId?: string) =>
  axiosInstance
    .get<DefaultV3Response<NoteType[]>>(`${basePapyrusRoute}/v3/${resourceId}/notes`)
    .then((res) => res.data);

// PATCH update_notes_v3
export const updateNoteV3 = (
  resourceId?: string,
  data?: { message?: string; pinned?: BitBoolean },
) =>
  axiosInstance
    .patch<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/${resourceId}`, data)
    .then((res) => res.data);

// PATCH swap_note_priority_v3
export const swapNotePriorityV3 = (noteIdOne: string, noteIdTwo: string) => {
  return axiosInstance
    .patch<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/swap-priority`, {
      noteIdOne,
      noteIdTwo,
    })
    .then((res) => res.data);
};

// ----------------- REPLIES -----------------

// POST create_note_reply_v3
export const createNoteReplyV3 = (
  noteId: string,
  data: { message?: string; type?: NoteType['type'] },
) =>
  axiosInstance
    .post<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/${noteId}/replies`, data)
    .then((res) => res.data);

// DEL delete_note_reply_v3
export const deleteNoteReplyV3 = (replyId: string) =>
  axiosInstance
    .delete<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/replies/${replyId}`)
    .then((res) => res.data);

// PATCH update_note_reply_v3
export const updateNoteReplyV3 = (
  noteReplyId: string,
  data: {
    message?: string;
    pinned?: boolean;
  },
) =>
  axiosInstance
    .patch<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/replies/${noteReplyId}`, data)
    .then((res) => res.data);

export const createNoteAttachmentV3 = (
  noteId: string,
  data: {
    fileResourceId: string;
  },
) =>
  axiosInstance
    .post<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/${noteId}/attachments`, data)
    .then((res) => res.data);

export const deleteNoteAttachmentV3 = (attachmentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<NoteType>>(`${basePapyrusRoute}/v3/notes/attachments/${attachmentId}`)
    .then((res) => res.data);
