export const trustTypeOfEntryOptions = {
  deposit: 'deposit',
  withdrawal: 'withdrawal',
} as const;

export const trustReasonForEntryOptions = {
  'Attorneys fee': 'attorneysFee',
  'Insurance proceeds': 'insuranceProceeds',
  'Lien payment': 'lienPayment',
  'Firm reimbursement': 'firmReimbursement',
  'Third party reimbursement': 'thirdPartyReimbursement',
  'Client Disbursement': 'clientDisbursement',
} as const;

export type TrustAccountTransactionType = {
  firmId: string;
  trustAccountTransactionId: string;
  dateCreated: string;
  dateModified: string;
  amount?: number;
  checkNumber?: string;
  datePaid?: string;
  description?: string;
  reasonForEntry?: (typeof trustReasonForEntryOptions)[keyof typeof trustReasonForEntryOptions];
  typeOfEntry: (typeof trustTypeOfEntryOptions)[keyof typeof trustTypeOfEntryOptions];
};
