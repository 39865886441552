import {
  useGetFirmUserWithDisplayNameFromUsername,
  useGetTimeLogCategories,
} from '@colosseum/data';
import { TimeLogType } from '@gladiate/types';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import DataTabs from '../DataTabs/DataTabs';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns, compactColumns } from './time-log-table-columns';

/* eslint-disable-next-line */
export interface TimeLogTableProps {
  compact?: boolean;
  isLoading: boolean;
  timeLogs: (TimeLogType & { caseTitle?: string })[];
  otherTableProps?: any;
}

export function TimeLogTable(props: TimeLogTableProps) {
  const { timeLogs, isLoading, compact, otherTableProps } = props;
  const { data: timeLogCategories } = useGetTimeLogCategories();
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const [grouping, setGrouping] = useState(['Case']);

  const timeLogsFormatted = timeLogs.map((timeLog) => {
    const timeLogCategory = timeLogCategories?.data?.find(
      (category) => category?.timeLogCategoryId === timeLog.timeLogCategoryId,
    );
    return {
      ...timeLog,
      timeLogCategory: timeLogCategory?.title ?? '-',
      displayName: timeLog?.username
        ? getFirmUserWithDisplayNameFromUsername(timeLog.username)?.displayName
        : '-',
    };
  });

  const caseOptions = useMemo(
    () =>
      timeLogsFormatted
        ? uniqBy(
            timeLogsFormatted
              .filter((c) => c.caseTitle)
              .map((c) => {
                return {
                  value: c.caseTitle,
                  label: c.caseTitle ?? '-',
                };
              }),
            'value',
          )
        : [],
    [timeLogsFormatted],
  );

  const userOptions = useMemo(
    () =>
      uniqBy(
        timeLogsFormatted
          .filter((c) => c.displayName)
          .map((c) => {
            return {
              value: c.displayName,
              label: c.displayName,
            };
          }),
        'value',
      ),
    [timeLogsFormatted],
  );

  const tableFilters = useMemo(
    () => [
      {
        id: 'Case',
        options: caseOptions,
      },
      {
        id: 'User',
        options: userOptions,
      },
      {
        id: 'Category',
        options: uniqBy(
          timeLogsFormatted
            .filter((c) => c.timeLogCategory)
            .map((c) => {
              return {
                value: c.timeLogCategory,
                label: c.timeLogCategory,
              };
            }),
          'value',
        ),
      },
      {
        id: 'Active',
        options: uniqBy(
          timeLogsFormatted.map((c) => {
            return {
              value: c.endTime ? '-' : 'True',
              label: c.endTime ? 'Completed' : 'Active',
            };
          }),
          'value',
        ),
      },
    ],
    [timeLogsFormatted],
  );

  return (
    <div className="flex flex-col gap-y-6">
      <DataTabs
        filters={[
          {
            title: 'By Case',
            count: caseOptions?.length,
            value: 'Case',
          },
          {
            title: 'By User',
            count: userOptions?.length,
            value: 'User',
          },
        ]}
        setActiveFilter={(value) => {
          setGrouping([value]);
        }}
        activeFilter={grouping[0]}
      />
      <DataTable
        isViewOnly
        data={timeLogsFormatted ?? []}
        grouping={grouping}
        columns={compact ? compactColumns : columns}
        hideToolbar={compact}
        isLoading={isLoading}
        tableName="timeLogs"
        showCSVDownload={!compact}
        filters={!compact ? tableFilters : []}
        customDateRangeId="Date"
        {...otherTableProps}
      />
    </div>
  );
}

export default TimeLogTable;
