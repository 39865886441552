import { useGetCaseCampaign } from '@colosseum/data';
import { CardSection, CaseCampaignTriggersTable } from '@colosseum/shared-ui';
import { CampaignStepOptions } from '@gladiate/types';
import { useNavigate, useParams } from 'react-router';
import NextButton from '../NextButton/NextButton';

export interface CaseCampaignStagesPageProps {}
export function CaseCampaignStagesPage(props: CaseCampaignStagesPageProps) {
  const { campaignId } = useParams() as { campaignId: string };
  const { data: caseCampaignData, isLoading: isCaseCampaignLoading } =
    useGetCaseCampaign(campaignId);
  const campaignData = caseCampaignData?.data;
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col gap-5">
        <CardSection
          cardHeading="Case Campaign Status Selection"
          className="flex flex-col justify-center"
        >
          <>
            {campaignData && <CaseCampaignTriggersTable campaignData={campaignData} />}
            <div className="flex justify-between pt-4">
              <button
                className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <NextButton
                title="Continue"
                nextRoute={`/campaigns/${campaignData?.caseCampaignId}/case/${CampaignStepOptions.subscription}`}
              />
            </div>
          </>
        </CardSection>
      </div>
    </>
  );
}

export default CaseCampaignStagesPage;
