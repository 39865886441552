import {
  CustomAttributeOptionType,
  CustomAttributeType,
  CustomAttributeValueType,
  DefaultV3Response,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseLabyrinthRoute = `labyrinth/${API_STAGE}`;

// ------------------ Custom Attribute ------------------

// POST create_custom_attribute_v3
export const createCustomAttributeV3 = (data: Partial<CustomAttributeType>) =>
  axiosInstance
    .post<DefaultV3Response<CustomAttributeType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes`,
      data,
    )
    .then((res) => res.data);

// DEL delete_custom_attribute_v3
export const deleteCustomAttributeV3 = (customAttributeId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CustomAttributeType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/${customAttributeId}`,
    )
    .then((res) => res.data);

// GET get_custom_attributes_v3
export const getCustomAttributesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CustomAttributeType[]>>(`${baseLabyrinthRoute}/v3/custom-attributes`)
    .then((res) => res.data);

// PATCH update_custom_attribute_v3
export const updateCustomAttributeV3 = (
  data: Partial<CustomAttributeType> & { customAttributeId: string },
) => {
  const { customAttributeId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CustomAttributeType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/${customAttributeId}`,
      rest,
    )
    .then((res) => res.data);
};

// -------------- Custom Attribute Value --------------
// POST create_custom_attribute_value_v3
export const createCustomAttributeValueV3 = (objectId: string, customAttributeId: string) =>
  axiosInstance
    .post<
      DefaultV3Response<{
        customAttribute: CustomAttributeType;
        customAttributeValue: CustomAttributeValueType;
      }>
    >(`${baseLabyrinthRoute}/v3/${objectId}/custom-attribute-values`, {
      customAttributeId,
    })
    .then((res) => res.data);

// DEL delete_custom_attribute_value_v3
export const deleteCustomAttributeValueV3 = (customAttributeValueId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CustomAttributeValueType>>(
      `${baseLabyrinthRoute}/v3/custom-attribute-values/${customAttributeValueId}`,
    )
    .then((res) => res.data);

// GET get_custom_attribute_values_v3
export const getCustomAttributeValuesV3 = (objectId: string) =>
  axiosInstance
    .get<DefaultV3Response<CustomAttributeValueType[]>>(
      `${baseLabyrinthRoute}/v3/${objectId}/custom-attribute-values`,
    )
    .then((res) => res.data);

// PATCH update_custom_attribute_value_v3
export const updateCustomAttributeValueV3 = (
  data: Partial<CustomAttributeValueType> & { customAttributeValueId: string },
) => {
  const { customAttributeValueId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CustomAttributeValueType>>(
      `${baseLabyrinthRoute}/v3/custom-attribute-values/${customAttributeValueId}`,
      rest,
    )
    .then((res) => res.data);
};

// ------------------ Custom Attribute Options ------------------
// POST create_custom_attribute_option_v3
export const createCustomAttributeOptionV3 = (
  data: Partial<CustomAttributeOptionType> & { customAttributeId: string },
) => {
  const { customAttributeId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<CustomAttributeOptionType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/${customAttributeId}/custom-attribute-options`,
      rest,
    )
    .then((res) => res.data);
};

// DEL delete_custom_attribute_option_v3
export const deleteCustomAttributeOptionV3 = (customAttributeOptionId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CustomAttributeOptionType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/custom-attribute-options/${customAttributeOptionId}`,
    )
    .then((res) => res.data);

// GET get_custom_attribute_options_v3
export const getCustomAttributeOptionsV3 = (customAttributeId: string) =>
  axiosInstance
    .get<DefaultV3Response<CustomAttributeOptionType[]>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/${customAttributeId}/custom-attribute-options`,
    )
    .then((res) => res.data);

// PATCH update_custom_attribute_option_v3
export const updateCustomAttributeOptionV3 = (
  data: Partial<CustomAttributeOptionType> & { customAttributeOptionId: string },
) => {
  const { customAttributeOptionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CustomAttributeOptionType>>(
      `${baseLabyrinthRoute}/v3/custom-attributes/custom-attribute-options/${customAttributeOptionId}`,
      rest,
    )
    .then((res) => res.data);
};
