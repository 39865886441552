import { usePathParts } from '@colosseum/data';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Redirect = ({ redirectURL }: { redirectURL: string }) => {
  const [params] = useSearchParams();
  const { resource } = usePathParts();

  //TODO: handle if resource is not survey
  // if (resource !== 'survey') {
  // }

  // TODO handle missing surveyId
  // if (!params.get('surveyId')) {
  // }

  useEffect(() => {
    window.location.replace(`${redirectURL}${params.get('surveyId')}`);
  }, []);

  return <></>;
};
