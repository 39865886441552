import {
  contactsSectionMappings,
  displayContactName,
  useDeleteContact,
  useGetContact,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  Button,
  CardSection,
  ContactInfoForm,
  CustomErrorBoundary,
  GladiateLoader,
  ResourceNotes,
  ResourceTags,
  SectionContainer,
} from '@colosseum/shared-ui';
import { withErrorBoundary } from '@sentry/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorPage from '../error/ErrorPage';

/* eslint-disable-next-line */
export interface ContactPageProps {}

export function ContactPage(props: ContactPageProps) {
  const navigate = useNavigate();
  const { contactId: id } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    data: contactData,
    status: contactStatus,
    isLoading: isContactLoading,
  } = useGetContact(id);
  const deleteContact = useDeleteContact();
  function handleDelete() {
    return deleteContact.mutateAsync({ contactId: id ?? '' }).then(() => navigate('/contacts'));
  }

  if (contactStatus === 'error') {
    return (
      <ErrorPage
        code={404}
        errorTitle={'Contact not found'}
        errorMessage={'Please check the URL in the address bar and try again.'}
        backRoute="/contacts"
        backMessage="Back to Contacts"
      />
    );
  }

  return (
    <div className="pb-60">
      <div
        data-contact-submenu-item={
          isContactLoading ? 'Loading...' : displayContactName(contactData?.data) ?? 'No Name'
        }
      ></div>

      <Helmet defer={false}>
        <title>
          {isContactLoading
            ? 'Contact'
            : `Contact | ${displayContactName(contactData?.data) ?? 'No Name'}`}
        </title>
      </Helmet>
      {contactData?.data ? (
        <div className="flex flex-col gap-5">
          <ContactInfoForm
            contact={contactData?.data}
            caseData={{
              caseTitle: '',
              caseId: '',
            }}
            conscriptionId=""
            showTitle
          />
          <CardSection
            className="flex flex-col gap-4"
            cardHeading={contactsSectionMappings.notes}
            submenuItem={contactsSectionMappings.notes}
          >
            <ResourceNotes createType="contact" resourceId={id ?? ''} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            cardHeading={contactsSectionMappings.tags}
            submenuItem={contactsSectionMappings.tags}
          >
            <ResourceTags resourceType="contact" resourceId={id ?? ''} />
          </CardSection>
        </div>
      ) : (
        <SectionContainer>
          <GladiateLoader className="h-[100vh]" />
        </SectionContainer>
      )}

      <div className="float-right pb-10 mt-5">
        <Button
          onClick={() => {
            setShowDeleteModal(true);
          }}
          variant="destructive"
          data-cy="delete-contact-button"
        >
          Delete Contact
        </Button>
      </div>

      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={handleDelete}
      />
    </div>
  );
}

export default withErrorBoundary(ContactPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
