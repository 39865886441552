import { prettifyDateString } from '@colosseum/data';
import { AdobeAgreementStatus } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { ContactItemViewer } from '../ContactChip/ContactItemViewer';
import {
  renderCell,
  renderCellWithChips,
  renderHeader,
} from '../shadcn/data-table/table-render-helpers';

export const columns: ColumnDef<AdobeAgreementStatus>[] = [
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row.agreementName,
    id: 'Agreement Name',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.status,
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
  {
    accessorFn: (row) => row.participants.map((participant) => participant.email).join(', ') || '',
    id: 'Recipients',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const emailListString = props.getValue() as string;
      if (!emailListString || !emailListString.length) {
        return '--';
      }
      const emailList = emailListString.split(', ');
      const [firstEmail, ...restEmail] = emailList;
      return <ContactItemViewer primaryValue={firstEmail} additionalValues={restEmail || []} />;
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
];
