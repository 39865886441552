import { templateDictionary } from '@colosseum/data';
import { DataTablePagination, DataTableToolbar, dayjsDateTime } from '@colosseum/shared-ui';
import { Updater } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import TemplateDictionaryItem from '../TemplateDictionaryItem/TemplateDictionaryItem';
import { columns } from './template-dictionary-table-columns';

/* eslint-disable-next-line */
export interface TemplateDictionaryProps {}

export function TemplateDictionary() {
  const [copiedItem, setCopiedItem] = useState(-1);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const tableFilters = useMemo(() => {
    return [
      {
        id: 'Type',
        options: [
          { value: 'text', label: 'Text' },
          { value: 'numeric', label: 'Numeric' },
          { value: 'date', label: 'Date' },
          { value: 'table', label: 'Table' },
          { value: 'input', label: 'Input' },
        ],
      },
      {
        id: 'Resource',
        options: [
          { value: 'case', label: 'Case' },
          { value: 'contact', label: 'Contact' },
          { value: 'insurance', label: 'Insurance' },
          { value: 'staff', label: 'Staff' },
          { value: 'vehicle', label: 'Vehicle' },
          { value: 'settlement', label: 'Settlement' },
          { value: 'firm', label: 'Firm' },
          { value: 'cocounsel', label: 'Co-Counsel' },
        ],
      },
    ];
  }, []);

  const handleUpdateFilters = (filters: Updater<ColumnFiltersState, ColumnFiltersState>) => {
    setColumnFilters(filters);
    setPagination({ ...pagination, pageIndex: 0 }); // reset page index when filtering
  };

  const table = useReactTable({
    data: templateDictionary.sort((a, b) => a.name.localeCompare(b.name)),
    columns,
    state: {
      columnFilters,
      pagination,
    },
    sortingFns: {
      dayjsDateTime: dayjsDateTime,
    },
    onColumnFiltersChange: handleUpdateFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    autoResetPageIndex: false,
    filterFromLeafRows: false,
  });

  return (
    <div>
      <div className="pb-4">
        <DataTableToolbar filters={tableFilters} table={table} hideViewButton showSearchBar />
      </div>
      <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3">
        {table.getRowModel().rows?.length ? (
          table
            .getRowModel()
            .rows.map((row) => (
              <TemplateDictionaryItem
                key={row.original.name}
                templateDictionaryItem={row.original}
              />
            ))
        ) : (
          <div className="p-4"> No results.</div>
        )}
      </div>
      <div className="py-4">
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}

export default TemplateDictionary;
