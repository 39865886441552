import { cn, settingsSectionMappings } from '@colosseum/data';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
  tabsListClass,
} from '@colosseum/shared-ui';
import ExpenseCategoryDefaults from '../ExpenseCategoryDefaults/ExpenseCategoryDefaults';
import UTBMSCategoryDefaults from '../UTBMSCategoryDefaults/UTBMSCategoryDefaults';

/* eslint-disable-next-line */
export interface ExpensesDefaultsProps {}

export function ExpensesDefaults(props: ExpensesDefaultsProps) {
  return (
    <Tabs defaultValue="categories">
      <h1 className="pb-2 pl-2 text-xl font-semibold text-gray-900">Expense Defaults</h1>
      <TabsList
        id="expenses-defaults"
        data-settings-submenu-item={settingsSectionMappings.expenseCategories}
        className={cn(tabsListClass, 'mb-4')}
      >
        <TabsTrigger
          className={tabTriggerClass}
          key="categories"
          value="categories"
          data-cy="expenses-categories"
        >
          Categories
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} key="codes" value="codes" data-cy="expenses-codes">
          UTBMS Codes
        </TabsTrigger>
      </TabsList>
      <TabsContent value="categories">
        <ExpenseCategoryDefaults />
      </TabsContent>
      <TabsContent value="codes">
        <UTBMSCategoryDefaults />
      </TabsContent>
    </Tabs>
  );
}

export default ExpensesDefaults;
