import { SettlementProposalType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateSettlementProposal } from '../../../lib/react-query/settlement';
import { zodCurrencyType } from '../zodValidationTypes';

const formSchema = z.object({
  amount: zodCurrencyType,
  coverageType: z.string().optional(),
  policyType: z.string().optional(),
  dateIssued: z.string().optional(),
  description: z.string().optional(),
  expirationDate: z.string().optional(),
  policyLimits: z.string().optional(),
  type: z.string().optional(),
  proposalStatus: z.string().optional(),
  proposalContingencies: z.array(z.string()).optional(),
});

interface useCaseSettlementFormProps {
  settlement?: SettlementProposalType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCaseSettlementForm = (props: useCaseSettlementFormProps) => {
  const { settlement, setTyping } = props;
  const updateSettlement = useUpdateSettlementProposal();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: settlement,
  });
  function handleUpdate(
    name: keyof Omit<
      SettlementProposalType,
      'settlementProposalId' | 'dateCreated' | 'dateModified' | 'firmId'
    >,
    value?: string | number | string[],
  ) {
    if (!settlement) {
      //TODO: Determine if this will ever happen - enqueue snackbar?
      return;
    }
    if (form.getFieldState(name).isDirty) {
      updateSettlement
        .mutateAsync({
          settlementProposalId: props.settlement?.settlementProposalId,
          [name]: value,
        })
        .then(() => {
          setTyping(false);
          form.resetField(name, { defaultValue: value });
        });
    }
  }
  return { form, handleUpdate };
};
