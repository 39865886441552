// authUtils.ts
import { HurinUserType } from '@gladiate/types';
import Auth from '@aws-amplify/auth';
import jwt_decode from 'jwt-decode';
import { enqueueAPISnackbar } from './snackbars';

export const refreshToken = async () => {
  try {
    const data = await Auth.currentSession();
    localStorage.setItem('accessToken', data.getAccessToken().getJwtToken());
    localStorage.setItem('idToken', data.getIdToken().getJwtToken());
    localStorage.setItem('refreshToken', data.getRefreshToken().getToken());
  } catch (err) {
    console.error(err);
  }
};

export const reduceCognitoAttributes = (user: HurinUserType) => {
  const attributes = user?.Attributes ?? user.UserAttributes;

  return attributes?.reduce(
    (
      prev: {
        [key: string]: string;
      }[],
      attr,
    ) => {
      return { ...prev, [attr.Name]: attr.Value };
    },
    [],
  ) as { [key: string]: string }[];
};

export function getNameToDisplayFromCognito(firmUser?: HurinUserType) {
  if (!firmUser) return undefined;
  const awsName = firmUser?.name?.trim();

  const nameToReturn = awsName && awsName?.length > 0 ? awsName : firmUser?.Username ?? ''; // If name contains no characters, this will return the username instead

  return nameToReturn;
}

interface decodedToken {
  ['cognito:groups']: string[];
  username: string;
}

export const getUser = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    const decoded: decodedToken = jwt_decode(accessToken);
    return decoded['username'];
  } else {
    return 'ERROR';
  }
};

interface decodedToken {
  ['cognito:groups']: string[];
  username: string;
}

export const getFirmId = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    const decoded: decodedToken = jwt_decode(accessToken);

    if (decoded['cognito:groups']) {
      return decoded['cognito:groups'][0];
    } else {
      enqueueAPISnackbar({
        message:
          'You are not a member of a firm. Please contact your firm administrator to add you to a firm.',
        variant: 'error',
        key: 'auth-no-firm-membership-error',
      });
      window.localStorage.clear();
      window.location.href = '/login';
      throw new Error('NO FIRM ID FOUND');
    }
  } else {
    return 'NO_ACCESS_TOKEN_ERROR';
  }
};
