import {
  campaignStatusColorMap,
  useDeleteCaseCampaign,
  useGetCaseCampaign,
  useGetCaseTypes,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  CardSection,
  CaseCampaignTriggersTable,
  Chip,
  GladiateLoader,
  Typography,
} from '@colosseum/shared-ui';
import { CampaignStepOptions, CaseCampaign, CompleteCampaignType } from '@gladiate/types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import CaseSubscriptionTable from '../SubscriptionTables/CaseSubscriptionTable';
import EditButton from './EditButton/EditButton';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';

/* eslint-disable-next-line */
export interface CaseSummaryPageProps {
  campaignData?: CaseCampaign;
  completeCampaignData?: CompleteCampaignType[];
}

export function CaseSummaryPage(props: CaseSummaryPageProps) {
  const deleteCaseCampaign = useDeleteCaseCampaign();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { data: allCaseTypes } = useGetCaseTypes();
  const { campaignId: caseCampaignId } = useParams() as { campaignId: string };

  const {
    data: caseCampaignData,
    isLoading: isCaseCampaignLoading,
    isFetching: isCaseCampaignFetching,
  } = useGetCaseCampaign(caseCampaignId);
  const campaignData = caseCampaignData?.data;

  const selectedCaseTypes = campaignData?.caseTypeIds
    ?.map((caseTypeId) => allCaseTypes?.data?.find((ct) => caseTypeId === ct.caseTypeId))
    .filter(Boolean);
  return (
    <>
      <div className="flex col-span-2 pb-5">
        <button
          onClick={() => navigate('/campaigns')}
          className="inline-block p-1 mr-4 text-black rounded-lg cursor-pointer fadeAnimation hover:text-atlantic-blue hover:bg-light-blue"
        >
          <ArrowLeftIcon className="w-5 h-5" />
        </button>
        <h1 className="ml-1 text-3xl font-semibold">Summary</h1>
      </div>

      <div className="flex flex-col gap-5">
        <CardSection cardHeading="Campaign Message Criteria">
          <div className="flex flex-col items-center gap-2">
            {(isCaseCampaignLoading || isCaseCampaignFetching) && <GladiateLoader />}
            <div className="flex gap-2">
              {campaignData?.title || campaignData?.title !== '' ? (
                <>
                  <Typography size="3xl" variant="heading" color="black">
                    {campaignData?.title}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography size="3xl" variant="heading" color="gray">
                    {`<no title>`}
                  </Typography>{' '}
                </>
              )}
              <EditButton
                route={`/campaigns/${campaignData?.caseCampaignId}/case/${CampaignStepOptions.criteria}`}
              />
            </div>
            <div className="font-thin text-md">{campaignData?.description}</div>
            <div
              className="flex items-center gap-1 text-sm font-semibold up group hover:cursor-pointer"
              onClick={() =>
                navigate(
                  `/campaigns/${campaignData?.caseCampaignId}/case/${CampaignStepOptions.criteria}`,
                )
              }
            >
              Campaign Status:
              {['draft', 'active', 'past', 'paused'].includes(campaignData?.status ?? '') && (
                <Chip
                  name={campaignData?.status}
                  color={campaignStatusColorMap[campaignData?.status ?? 'past']}
                  className="group-hover:cursor-pointer"
                />
              )}
            </div>
            <div className="flex flex-wrap justify-center py-2 pb-4 gap-x-2 gap-y-2 w-96">
              {selectedCaseTypes?.map((item) => (
                <div
                  className="p-2 text-xs border border-gray-300 rounded-md"
                  key={item?.caseTypeId}
                >
                  {item?.title}
                </div>
              ))}
            </div>
          </div>
        </CardSection>

        <CardSection>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Typography size="xl" variant="heading" color="black">
                Campaign Messages Triggers
              </Typography>
              <EditButton
                route={`/campaigns/${campaignData?.caseCampaignId}/case/${CampaignStepOptions.stages}`}
              />
            </div>
            <Typography variant="subtext" color="black">
              These messages will be sent to the client based case status and trigger settings
              specified below.
            </Typography>
          </div>
          {campaignData && (
            <CaseCampaignTriggersTable
              campaignData={campaignData}
              viewOnly={true}
              loading={isCaseCampaignLoading || isCaseCampaignFetching}
            />
          )}
        </CardSection>
        <CardSection>
          <div className="flex gap-2">
            <Typography size="xl" variant="heading" color="black" className="pb-1">
              Case Campaign Subscriptions
            </Typography>
            <EditButton
              route={`/campaigns/${campaignData?.caseCampaignId}/case/${CampaignStepOptions.subscription}`}
            />
          </div>
          <Typography variant="subtext" color="black" className="pb-4">
            All future cases associated with the case types specified above will be automatically
            added to this campaign.
          </Typography>
          <CaseSubscriptionTable caseCampaignSubscriptionId={caseCampaignId} isViewOnly={true} />
        </CardSection>
        <div className="flex justify-end">
          <button
            onClick={() => {
              setShowDeleteModal(true);
            }}
            className="px-10 py-4 text-right text-white bg-red-600 rounded-md"
            data-cy="delete-contact-button"
          >
            Delete
          </button>
        </div>
      </div>

      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={() =>
          deleteCaseCampaign.mutateAsync(campaignData?.caseCampaignId ?? '').then(() => {
            navigate(`/campaigns`);
            setShowDeleteModal(false);
          })
        }
        title="Delete Campaign"
      />
    </>
  );
}

export default CaseSummaryPage;
