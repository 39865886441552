import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useCreateCoCounsel } from '../../../lib/react-query/firm';
import { useCreateCognitoUserForm } from './useCreateCognitoUserForm';

const formSchema = z.object({
  userName: z.string().optional(),
  name: z.string(),
  email: z.string(),
  phone_number: z.string(),
  groupId: z.string(),
  cocounselName: z.string().optional(),
  cocounselFirmAddress: z.string().optional(),
  cocounselFirmCity: z.string().optional(),
  cocounselFirmState: z.string().optional(),
  cocounselFirmZip: z.string().optional(),
});

interface useCreateCoCounselFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCreateCoCounselForm = (props: useCreateCoCounselFormProps) => {
  const { setOpen, setTyping } = props;
  const createCoCounsel = useCreateCoCounsel();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
  });

  const { handleUpdate: handleCreateCognitoUser, isLoading: isCreateCognitoUserLoading } =
    useCreateCognitoUserForm(form.getValues());

  const handleCreateCoCounsel = () => {
    return handleCreateCognitoUser().then((res) => {
      if (res.data.username) {
        createCoCounsel
          .mutateAsync({
            username: res.data.username,
            cocounselName: form.getValues('cocounselName'),
            cocounselFirmAddress: form.getValues('cocounselFirmAddress'),
            cocounselFirmCity: form.getValues('cocounselFirmCity'),
            cocounselFirmState: form.getValues('cocounselFirmState'),
            cocounselFirmZip: form.getValues('cocounselFirmZip'),
          })
          .then(() => {
            form.reset();
          });
      }
    });
  };

  return {
    form,
    handleUpdate: handleCreateCoCounsel,
    isLoading: isCreateCognitoUserLoading || createCoCounsel.isLoading,
  };
};
