import { cn } from '@colosseum/data';
import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface ToggleProps {
  newState?: boolean;
  editable?: boolean;
  onToggleChange?: (value: boolean) => void; // New callback prop
}

export function Toggle(props: ToggleProps) {
  const [enabled, setEnabled] = useState(props.newState);

  useEffect(() => {
    if (props.newState !== undefined) {
      setEnabled(props.newState);
    }
  }, [props.newState]);

  const handleChange = (newEnabled: boolean) => {
    setEnabled(newEnabled);
    if (props.onToggleChange) {
      props.onToggleChange(newEnabled);
    }
  };

  return (
    <Switch
      checked={enabled}
      onChange={props.editable ? handleChange : undefined}
      className={cn(
        enabled ? 'bg-atlantic-blue' : 'bg-gray-200',
        props.editable
          ? 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2'
          : ' pointer-events-none bg-gray-200 ',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ',
        !props.editable && enabled && 'bg-atlantic-blue bg-opacity-30',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={cn(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      >
        <span
          className={cn(
            enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          )}
          aria-hidden="true"
        >
          <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={cn(
            enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          )}
          aria-hidden="true"
        >
          <svg
            className={cn(
              'w-3 h-3 text-atlantic-blue ',
              !props.editable && enabled && 'opacity-50',
            )}
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
}

export default Toggle;
