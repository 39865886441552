import {
  CampaignTriggerType,
  CampaignType,
  CaseCampaign,
  CaseCampaignSubscription,
  CaseCampaignTrigger,
  CompleteCampaignType,
  ContactCampaign,
  ContactCampaignSubscription,
  ContactCampaignTrigger,
  DefaultV3Response,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

export const baseGordianRoute = `gordian/${API_STAGE}`;

// GET get_campaigns_v3
export const getCampaignsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CampaignType[]>>(`${baseGordianRoute}/v3/campaigns`)
    .then((res) => res.data);

export const getCampaignV3 = (campaignId: string) =>
  axiosInstance
    .get<DefaultV3Response<CampaignType>>(`${baseGordianRoute}/v3/campaigns/${campaignId}`)
    .then((res) => res.data);

// POST create_campaign_v3
export const createCampaignV3 = (body: { title?: string; description?: string }) =>
  axiosInstance
    .post<DefaultV3Response<CampaignType>>(`${baseGordianRoute}/v3/campaigns`, body)
    .then((res) => res.data);

// PUT update_campaign_v3
export const updateCampaignV3 = (data: {
  campaignId: string;
  title?: string;
  description?: string;
  caseType?: string[];
  status?: CampaignType['status'];
}) => {
  const { campaignId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<CampaignType>>(`${baseGordianRoute}/v3/campaigns/${campaignId}`, rest)
    .then((res) => res.data);
};

// DELETE delete_campaign_v3
export const deleteCampaignV3 = (campaignId: string) => {
  return axiosInstance
    .delete<DefaultV3Response<CampaignType>>(`${baseGordianRoute}/v3/campaigns/${campaignId}`)
    .then((res) => res.data);
};

// GET get_campaign_triggers_v3
export const getCampaignTriggersV3 = (campaignId: string) => {
  return axiosInstance
    .get<DefaultV3Response<CampaignTriggerType[]>>(
      `${baseGordianRoute}/v3/campaigns/${campaignId}/triggers`,
    )
    .then((res) => res.data);
};

// GET get_campaign_complete_v3
export const getCampaignCompleteV3 = (campaignId: string) => {
  return axiosInstance
    .get<DefaultV3Response<CompleteCampaignType[]>>(
      `${baseGordianRoute}/v3/campaigns/${campaignId}/complete`,
    )
    .then((res) => res.data);
};

// #region Case Campaigns
/**
 * GET list_case_campaigns_v3
 */
export const getListCaseCampaignsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseCampaign[]>>(`${baseGordianRoute}/v3/cases/campaigns`)
    .then((res) => res.data);

/**
 * GET get_case_campaign_v3
 */
export const getCaseCampaignV3 = (caseCampaignId: string) =>
  axiosInstance
    .get<DefaultV3Response<CaseCampaign>>(
      `${baseGordianRoute}/v3/cases/campaigns/${caseCampaignId}`,
    )
    .then((res) => res.data);

/**
 * POST create_case_campaign_v3
 */
export const createCaseCampaignV3 = (data: { title?: string; descritpion?: string }) =>
  axiosInstance
    .post<DefaultV3Response<CaseCampaign>>(`${baseGordianRoute}/v3/cases/campaigns`, data)
    .then((res) => res.data);

/**
 * DEL delete_case_campaign_v3
 */
export const deleteCaseCampaignV3 = (caseCampaignId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseCampaign>>(
      `${baseGordianRoute}/v3/cases/campaigns/${caseCampaignId}`,
    )
    .then((res) => res.data);

/**
 * PATCH update_case_campaign_v3
 */
export const updateCaseCampaignV3 = (data: Partial<CaseCampaign>) => {
  const { caseCampaignId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<CaseCampaign>>(
      `${baseGordianRoute}/v3/cases/campaigns/${caseCampaignId}`,
      rest,
    )
    .then((res) => res.data);
};
/**
 * POST create_case_campaign_trigger_v3
 */
export const createCaseCampaignTriggerV3 = ({
  data,
  caseCampaignId,
}: {
  data: Partial<CaseCampaignTrigger>;
  caseCampaignId: string;
}) => {
  return axiosInstance
    .post<DefaultV3Response<CaseCampaignTrigger>>(
      `${baseGordianRoute}/v3/cases/campaigns/${caseCampaignId}/triggers`,
      data,
    )
    .then((res) => res.data);
};

/**
 * PUT update_case_campaign_trigger_v3
 */
export const updateCaseCampaignTriggerV3 = (data: Partial<CaseCampaignTrigger>) => {
  const { caseCampaignTriggerId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<CaseCampaignTrigger>>(
      `${baseGordianRoute}/v3/cases/campaigns/triggers/${caseCampaignTriggerId}`,
      rest,
    )
    .then((res) => res.data);
};

/**
 * DELETE delete_case_campaign_trigger_v3
 */
export const deleteCaseCampaignTriggerV3 = (caseCampaignTriggerId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseCampaignTrigger>>(
      `${baseGordianRoute}/v3/cases/campaigns/triggers/${caseCampaignTriggerId}`,
    )
    .then((res) => res.data);

/**
 * PATCH case_campaign_subscription_v3
 */
export const updateCaseCampaignSubscriptionV3 = (data: Partial<CaseCampaignSubscription>) => {
  const { caseCampaignSubscriptionId, ...rest } = data;

  return axiosInstance
    .patch<DefaultV3Response<CaseCampaign>>(
      `${baseGordianRoute}/v3/cases/campaigns/subscriptions/${caseCampaignSubscriptionId}`,
      rest,
    )
    .then((res) => res.data);
};

// #endregion

// #region Contact Campaigns
/**
 * GET list_contact_campaigns_v3
 */
export const getListContactCampaignsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ContactCampaign[]>>(`${baseGordianRoute}/v3/contacts/campaigns`)
    .then((res) => res.data);

/**
 * GET get_contact_campaign_v3
 */
export const getContactCampaignV3 = (contactCampaignId: string) =>
  axiosInstance
    .get<DefaultV3Response<ContactCampaign>>(
      `${baseGordianRoute}/v3/contacts/campaigns/${contactCampaignId}`,
    )
    .then((res) => res.data);

/**
 * POST create_contact_campaign_v3
 */
export const createContactCampaignV3 = (data: { title?: string; description?: string }) => {
  return axiosInstance
    .post<DefaultV3Response<ContactCampaign>>(`${baseGordianRoute}/v3/contacts/campaigns`, data)
    .then((res) => res.data);
};

/**
 * DELETE delete_contact_campaign_v3
 */
export const deleteContactCampaignV3 = (contactCampaignId: string) =>
  axiosInstance
    .delete<DefaultV3Response<ContactCampaign>>(
      `${baseGordianRoute}/v3/contacts/campaigns/${contactCampaignId}`,
    )
    .then((res) => res.data);

/**
 *  PATCH update_contact_campaign_v3
 */
export const updateContactCampaignV3 = (data: Partial<ContactCampaign>) => {
  const { contactCampaignId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<ContactCampaign>>(
      `${baseGordianRoute}/v3/contacts/campaigns/${contactCampaignId}`,
      rest,
    )
    .then((res) => res.data);
};

/**
 *  POST create_contact_campaign_trigger_v3
 */
export const createContactCampaignTriggerV3 = ({
  data,
  contactCampaignId,
}: {
  data: Partial<ContactCampaignTrigger>;
  contactCampaignId: string;
}) => {
  return axiosInstance
    .post<DefaultV3Response<ContactCampaignTrigger>>(
      `${baseGordianRoute}/v3/contacts/campaigns/${contactCampaignId}/triggers`,
      data,
    )
    .then((res) => res.data);
};

/**
 * PUT update_contact_campaign_trigger_v3
 */
export const updateContactCampaignTriggerV3 = (data: Partial<ContactCampaignTrigger>) => {
  const { contactCampaignTriggerId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<ContactCampaignTrigger>>(
      `${baseGordianRoute}/v3/contacts/campaigns/triggers/${contactCampaignTriggerId}`,
      rest,
    )
    .then((res) => res.data);
};

/**
 * DELETE delete_contact_campaign_trigger_v3
 */
export const deleteContactCampaignTriggerV3 = (contactCampaignTriggerId: string) =>
  axiosInstance
    .delete<DefaultV3Response<ContactCampaignTrigger>>(
      `${baseGordianRoute}/v3/contacts/campaigns/triggers/${contactCampaignTriggerId}`,
    )
    .then((res) => res.data);

/**
 * PATCH contact_campaign_subscription_v3
 */
export const updateContactCampaignSubscriptionV3 = (data: Partial<ContactCampaignSubscription>) => {
  const { contactCampaignSubscriptionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<ContactCampaign>>(
      `${baseGordianRoute}/v3/contacts/campaigns/subscriptions/${contactCampaignSubscriptionId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion
