import { ItemTagConnectionType, TagType } from '@gladiate/types';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { cn } from '@colosseum/data';

/* eslint-disable-next-line */
export interface TagProps {
  color: TagType['color'];
  title: TagType['title'];
  itemTagConnectionId: ItemTagConnectionType['itemTagConnectionId'];
  isViewOnly?: boolean;
  handleDeleteItemTagConnection: (arg0: string) => void;
  modern?: boolean;
}

export function Tag({
  color,
  title,
  itemTagConnectionId,
  handleDeleteItemTagConnection,
  isViewOnly,
  modern,
}: TagProps) {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={cn(
        'flex items-center rounded-full text-white text-sm',
        modern ? 'px-2 py-0.5' : 'px-2 py-1 mr-2',
      )}
    >
      <Typography className={cn('truncate', !isViewOnly && 'mr-2')}>{title}</Typography>
      {!isViewOnly && (
        <Button
          variant="ghost"
          size="unset"
          onClick={() => {
            handleDeleteItemTagConnection(itemTagConnectionId);
          }}
        >
          <XMarkIcon className="w-3 h-3" />
        </Button>
      )}
    </div>
  );
}

export default Tag;
