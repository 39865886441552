import {
  cn,
  downloadFile,
  findFolderPathFromRoot,
  getFullFileId,
  getGlobalIcon,
  useGetParallelFiles,
  useGetParallelS3FileDownloadLinks,
} from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import {
  ArrowLeftIcon,
  ArrowUturnRightIcon,
  Bars4Icon,
  PencilSquareIcon,
  RectangleGroupIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, SquaresPlusIcon } from '@heroicons/react/24/solid';
import { UseMutationResult } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { Dispatch, FC, SetStateAction, useContext, useRef, useState } from 'react';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { Input } from '../shadcn/Input/Input';
import Modal from '../shadcn/Modal/Modal';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { FileExplorerContext } from './FileExplorerProvider';
import { FileTreeNode } from './FileTreeNode';

interface ToolbarProps {
  onGoBack: () => void;
  onViewModeToggle: () => void;
  isListView: boolean;
  setNavigationHistory: any;
  navigationHistory: FileResourceType[];
  caseId: string;
  currentDirectoryId: string;
  setShowDocGenerationFormSlideover: Dispatch<SetStateAction<boolean>>;
  setShowSignDocFormSlideover: Dispatch<SetStateAction<boolean>>;
  uploadFileMutation: UseMutationResult<any, any, any, any>;
  deleteFileMutation: UseMutationResult<any, any, any, any>;
  createFileItemMutation: UseMutationResult<any, any, any, any>;
  renameFileItemMutation: UseMutationResult<any, any, any, any>;
  moveFileItemMutation: UseMutationResult<any, any, any, any>;
  singularSelectedFile: FileResourceType | undefined;
  buttonsState?: {
    addContent: boolean;
    rename: boolean;
    move: boolean;
    download: boolean;
    delete: boolean;
    sendForSignature: boolean;
  };
  listViewOnly?: boolean;
}

export const Toolbar: FC<ToolbarProps> = (props) => {
  const {
    onGoBack,
    onViewModeToggle,
    isListView,
    setNavigationHistory,
    navigationHistory,
    caseId,
    currentDirectoryId,
    setShowDocGenerationFormSlideover,
    setShowSignDocFormSlideover,
    uploadFileMutation: uploadFile,
    deleteFileMutation: deleteFile,
    createFileItemMutation: createFileItem,
    renameFileItemMutation: renameFile,
    moveFileItemMutation: moveFile,
    singularSelectedFile,
    buttonsState,
    listViewOnly,
  } = props;

  const {
    rootFolder,
    currentFolder,
    selectedFiles,
    setSelectedFiles,
    setCurrentFolderId,
    setDeletingFiles,
    setMovingFiles,
  } = useContext(FileExplorerContext);

  const folderItems = currentFolder ? currentFolder : rootFolder;
  const currentFolderItems = folderItems?.children?.map((item) => {
    return {
      ...item,
      fullId: getFullFileId(item),
    };
  });
  const s3DownloadLinks = useGetParallelS3FileDownloadLinks(
    currentFolderItems?.map((item) => item?.s3ObjKey ?? '') ?? [],
  );
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];
  const PhotoGalleryIcon = getGlobalIcon('photo');

  const photos = s3DownloadLinks
    .filter((item) => {
      const fileExtension = item?.data?.data.objKey?.split('.').pop() ?? '';
      return imageTypes.includes(fileExtension);
    })
    ?.map((item) => {
      return {
        src: item.data?.data?.previewUrl,
        width: 200,
        height: 200,
      };
    });
  const canGoBack = navigationHistory.length > 0;

  const [newName, setNewName] = useState('');
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [selectedFolderToMoveTo, setSelectedFolderToMoveTo] = useState<string>('');
  const [renamePopoverOpen, setRenamePopoverOpen] = useState(false);
  const [addContentPopoverOpen, setAddContentPopoverOpen] = useState(false);
  const [photoGalleryPopoverOpen, setPhotoGalleryPopoverOpen] = useState(false);
  const [urlToAdd, setUrlToAdd] = useState('');
  const [urlTitleToAdd, setUrlTitleToAdd] = useState('');
  const [actionType, setActionType] = useState<'ADD_URL' | 'CREATE_FOLDER' | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const selectedFileInfo = useGetParallelFiles(
    selectedFiles.map((selectedFile) => getFullFileId(selectedFile)),
  );
  const selectedFileData = selectedFileInfo.map((query) => {
    if (query.data) {
      return query.data?.data;
    } else {
      return null;
    }
  });

  const getCurrentPath = () => {
    return './' + navigationHistory.map((folder) => folder.name).join('/');
  };

  // Logic for escaping special characters in path might be needed in the future
  // const getCurrentPath = () => {
  //   return './' + navigationHistory.map((folder) => {
  //     return folder.name.replace(/\//g, '\\/');
  //   }).join('/');
  // };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const sendForSignDisabled =
    selectedFiles.length !== 1 ||
    !singularSelectedFile ||
    singularSelectedFile.resourceType !== 'FILE';

  function handleItemClick(item: string) {
    switch (item) {
      case 'Upload File':
        if (fileInputRef.current) {
          fileInputRef.current.click();
        } else {
          enqueueSnackbar('Error pulling up local files please try again', {
            variant: 'error',
          });
        }
        break;
      case 'Create Folder':
        setActionType('CREATE_FOLDER');
        break;
      case 'Add Url':
        setActionType('ADD_URL');
        break;
      case 'Generate Document':
        setShowDocGenerationFormSlideover(true);
        break;
      default:
        break;
    }

    setAddContentPopoverOpen(false);
  }

  return (
    <>
      <Modal
        open={actionType !== null}
        setOpen={() => setActionType(null)}
        title={actionType === 'ADD_URL' ? 'Add Url' : 'Create Folder'}
        primaryButton={{
          label: actionType === 'ADD_URL' ? 'Add' : 'Create',
          className: 'px-4 py-1 text-white rounded hover:bg-atlantic-blue right-0 bg-sky-blue',
          onClick: () => {
            if (actionType === 'ADD_URL') {
              if (!urlToAdd) {
                enqueueSnackbar('Error: No url entered', {
                  variant: 'error',
                });
                return;
              }

              const calcUrlName = (urlTitleToAdd === '' ? urlToAdd : urlTitleToAdd) ?? urlToAdd;

              createFileItem.mutate({
                name: calcUrlName,
                caseId: caseId,
                currentDirectory: currentDirectoryId,
                urlRefLink: urlToAdd,
                resourceType: 'LINK',
              });

              enqueueSnackbar('Url added successfully', {
                variant: 'success',
              });
            } else if (actionType === 'CREATE_FOLDER') {
              createFileItem.mutate({
                name: urlTitleToAdd || 'New Folder',
                caseId: caseId,
                currentDirectory: currentDirectoryId,
                resourceType: 'DIR',
              });
            }

            setUrlToAdd('');
            setUrlTitleToAdd('');
            setActionType(null);
          },
        }}
      >
        <div className="mt-4 mb-4">
          {actionType === 'ADD_URL' && (
            <Input
              className="mb-4"
              placeholder="Url"
              value={urlToAdd}
              onChange={(e) => setUrlToAdd(e.target.value)}
              data-cy="file-repo-url-input"
            />
          )}

          <Input
            placeholder="Title"
            value={urlTitleToAdd}
            onChange={(e) => setUrlTitleToAdd(e.target.value)}
            data-cy="file-repo-url-title-input"
          />
        </div>
      </Modal>
      <Modal
        title="Photo Gallery"
        open={photoGalleryPopoverOpen}
        setOpen={setPhotoGalleryPopoverOpen}
        hideAllInteraction
        width="w-5/6"
      >
        <div className="flex flex-wrap gap-1 overflow-scroll">
          {photos.map((photo) => (
            <img
              key={photo.src}
              src={photo.src}
              className="border-gray-200 border p-1 object-contain w-[32%] aspect-square"
            />
          ))}
        </div>
      </Modal>
      <input
        type="file"
        multiple // add this line
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e) => {
          const files = e?.target?.files; // Now this will be an array of files

          if (!files) {
            enqueueSnackbar('Error uploading files please try again', {
              variant: 'error',
            });
            return;
          }

          // Call the uploadFile function for each file
          Array.from(files).forEach((file) => {
            uploadFile.mutate({
              rawFile: file,
              caseId: caseId,
              currentDirectory: currentDirectoryId,
            });
          });

          if (e.target) e.target.value = '';
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }}
      />
      <div className="justify-between border-b border-gray-300 toolbar ">
        <div className="flex flex-wrap items-center justify-between px-2 pt-2 ">
          <div className="flex items-center">
            <button
              className={`flex items-center justify-center w-8 h-8 rounded-md ${
                canGoBack ? 'text-atlantic-blue hover:bg-gray-200 ' : 'text-gray-300'
              } focus:outline-none`}
              onClick={onGoBack}
              disabled={!canGoBack}
            >
              <ArrowLeftIcon className="w-5 h-5" />
            </button>

            <div className="flex ml-2 text-atlantic-blue">
              {getCurrentPath()
                .split('/')
                .map((part, idx, arr) => (
                  <React.Fragment key={idx}>
                    <div
                      onClick={
                        idx === arr.length - 1
                          ? () => {
                              // do nothing
                            }
                          : () => {
                              const newHistory = navigationHistory.slice(0, idx);

                              setNavigationHistory(newHistory);
                              setCurrentFolderId(newHistory[idx - 1].resourceId);
                            }
                      }
                      className="cursor-pointer hover:underline"
                    >
                      {part}
                    </div>

                    {idx < arr.length - 1 && '/'}
                  </React.Fragment>
                ))}
            </div>
          </div>

          {!listViewOnly && (
            <button
              className="flex items-center justify-center w-8 h-8 text-blue-500 rounded-full hover:bg-gray-200 focus:outline-none"
              onClick={onViewModeToggle}
            >
              {isListView ? (
                <RectangleGroupIcon className="w-5 h-5" />
              ) : (
                <Bars4Icon className="w-5 h-5" />
              )}
            </button>
          )}
        </div>
        <div className="flex flex-wrap items-center pt-2 mt-2 mb-2 border-t border-gray-300 gap-y-2">
          {(!buttonsState || buttonsState.addContent) && (
            <Popover
              open={addContentPopoverOpen}
              onOpenChange={(isOpen) => {
                setAddContentPopoverOpen(isOpen);
              }}
            >
              <PopoverTrigger asChild>
                <button
                  data-cy="file-repo-add-content-button"
                  className="flex border border-gray-400 items-center  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none"
                >
                  <SquaresPlusIcon className="w-5 h-5 mr-2" />
                  Add Content
                </button>
              </PopoverTrigger>
              <PopoverContent align="start" className="z-50" alignOffset={5}>
                <div>
                  {['Add Url', 'Upload File', 'Create Folder', 'Generate Document'].map(
                    (item, index) => (
                      <div
                        key={item}
                        className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleItemClick(item)}
                        data-cy={`file-repo-add-content-option-${item}`}
                      >
                        {item}
                      </div>
                    ),
                  )}
                </div>
              </PopoverContent>
            </Popover>
          )}

          {(!buttonsState || buttonsState.rename) && (
            <Popover
              open={renamePopoverOpen}
              onOpenChange={(isOpen) => {
                setRenamePopoverOpen(isOpen);
              }}
            >
              <PopoverTrigger>
                <div className="flex border border-gray-400 items-center  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none">
                  <PencilSquareIcon className="w-5 h-5 mr-2" />
                  Rename
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <div className="p-4">
                  <Input
                    placeholder="New Name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />

                  <div className="flex justify-end">
                    <button
                      className="px-4 py-1.5 mt-3 text-white rounded right-0  bg-sky-blue"
                      onClick={() => {
                        setRenamePopoverOpen(false);
                        setNewName('');
                        setSelectedFiles([]);

                        // rename folder
                        selectedFiles.forEach((selectedFile) => {
                          renameFile.mutate({
                            fileItemToRename: getFullFileId(selectedFile),
                            newName: newName,
                            parentDirectory: currentDirectoryId,
                          });
                        });
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          )}

          {(!buttonsState || buttonsState.move) && (
            <button
              className="flex items-center border border-gray-400  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none"
              onClick={() => {
                // move folder
                setShowMoveModal(true);
              }}
            >
              <ArrowUturnRightIcon className="w-5 h-5 mr-2" />
              Move
            </button>
          )}

          {(!buttonsState || buttonsState.download) && (
            <button
              className="flex items-center border border-gray-400  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none"
              onClick={() => {
                selectedFileData.forEach((fileItem) => {
                  if (fileItem) {
                    downloadFile(fileItem);
                  }
                });
              }}
            >
              <ArrowDownTrayIcon className="w-5 h-5 mr-2" />
              Download
            </button>
          )}

          {(!buttonsState || buttonsState.delete) && (
            <button
              className="flex items-center border border-gray-400  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none"
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <TrashIcon className="w-5 h-5 mr-2" />
              Delete
            </button>
          )}

          {(!buttonsState || buttonsState.sendForSignature) && (
            <button
              className={cn(
                'flex items-center border border-gray-400  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none',
                sendForSignDisabled && 'opacity-50 cursor-not-allowed hover:bg-inherit',
              )}
              onClick={() => {
                // sign document
                setShowSignDocFormSlideover(true);
              }}
              disabled={sendForSignDisabled}
            >
              <PaperAirplaneIcon className="w-5 h-5 mr-2" />
              Send for Signature
            </button>
          )}
          {!buttonsState && (
            <button
              className={cn(
                'flex items-center border border-gray-400  ml-2 px-2 py-0.5 text-xs text-gray-500 rounded-full hover:bg-gray-200 focus:outline-none',
                !photos ||
                  (photos.length === 0 && 'opacity-50 cursor-not-allowed hover:bg-inherit'),
              )}
              onClick={() => {
                setPhotoGalleryPopoverOpen(true);
              }}
              disabled={!photos || photos.length === 0}
            >
              {PhotoGalleryIcon && <PhotoGalleryIcon className="w-5 h-5 mr-2" />}
              Photo Gallery
            </button>
          )}

          <div className="flex grow"></div>

          {selectedFiles.length > 0 && (
            <TooltipWrapper
              message={selectedFiles
                .map(
                  (selectedFile) =>
                    `./${
                      findFolderPathFromRoot(
                        {
                          children: [rootFolder ?? {}],
                        },
                        selectedFile.resourceId,
                      )
                        ?.slice(1, -1)
                        .map((folder) => folder.name)
                        .join('/') ?? ''
                    }`,
                )
                .join('\n')}
            >
              <button
                className="flex items-center border border-atlantic-blue text-atlantic-blue mr-2 px-2 py-0.5 text-xs rounded-full hover:bg-gray-200 focus:outline-none"
                onClick={() => {
                  // clear selected
                  setSelectedFiles([]);
                }}
              >
                {selectedFiles.length} Item(s) Selected
                <XMarkIcon className="w-4 h-4" />
              </button>
            </TooltipWrapper>
          )}
        </div>
      </div>
      <Modal
        open={showMoveModal}
        setOpen={setShowMoveModal}
        title={'Move Items'}
        primaryButton={{
          label: 'Move',
          className: 'px-4 py-1 text-white rounded hover:bg-atlantic-blue right-0  bg-sky-blue',
          onClick: () => {
            setShowMoveModal(false);
            setSelectedFolderToMoveTo('');

            if (currentDirectoryId === selectedFolderToMoveTo) {
              enqueueSnackbar('Error: This item already in the folder you selected', {
                variant: 'error',
              });
              return;
            }

            if (!selectedFolderToMoveTo) {
              enqueueSnackbar('Error: No selected folder to move to', {
                variant: 'error',
              });
              return;
            }

            selectedFiles.forEach((selectedFile) => {
              if (getFullFileId(selectedFile) === selectedFolderToMoveTo) {
                enqueueSnackbar('Error: Cannot move a folder into itself', {
                  variant: 'error',
                });
                return;
              }

              setMovingFiles(selectedFiles);

              moveFile
                .mutateAsync({
                  fileItemToMove: getFullFileId(selectedFile),
                  moveToDirectory: selectedFolderToMoveTo,
                  parentDirectory: currentDirectoryId,
                })
                .then(() => {
                  setMovingFiles([]);
                  setSelectedFiles([]);
                })
                .catch(() => {
                  setMovingFiles([]);
                  setSelectedFiles([]);
                });
            });
          },
        }}
      >
        {rootFolder && (
          <div className="mb-4 overflow-auto rounded-lg bg-gray-50">
            <FileTreeNode
              item={rootFolder}
              level={0}
              selected={selectedFolderToMoveTo}
              setSelected={setSelectedFolderToMoveTo}
            />
          </div>
        )}
      </Modal>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={() => {
          selectedFiles.forEach((selectedFile) => {
            setDeletingFiles((prevState) => [...prevState, selectedFile]);

            deleteFile
              .mutateAsync({
                fileResourceId: getFullFileId(selectedFile),
              })
              .then(() => setSelectedFiles([]))
              .then(() => setShowDeleteModal(false));
          });
        }}
      />
    </>
  );
};
