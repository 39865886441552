import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface ResourceNoteFormProps {
  messageTitle?: string;
  buttonText?: string;
  handleNoteSubmit: (data: any) => Promise<any>;
  isLoading?: boolean;
  defaultMessage?: string;
  clearOnSubmit?: boolean;
  setEditMode?: (editMode: boolean) => void;
}

const formSchema = z.object({
  message: z.string().nonempty(),
});

export function ResourceNoteForm(props: ResourceNoteFormProps) {
  const {
    messageTitle,
    buttonText,
    handleNoteSubmit,
    isLoading,
    defaultMessage,
    clearOnSubmit,
    setEditMode,
  } = props;
  const [showFileModal, setShowFileModal] = useState(false);

  const noteForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: defaultMessage ?? '',
    },
  });
  const textAreaRef = useRef(null);

  return (
    <Form {...noteForm}>
      <form
        onSubmit={noteForm.handleSubmit((data) =>
          handleNoteSubmit(data).then(() => {
            if (clearOnSubmit) {
              noteForm.reset();
            }
          }),
        )}
      >
        <TextFormInput
          title={messageTitle ?? 'Note'}
          allowUserTags={true}
          type="textarea"
          {...noteForm.register('message')}
          ref={textAreaRef}
        />
        <div className="flex float-right mt-2 gap-x-2">
          {setEditMode && (
            <Button onClick={() => setEditMode(false)} type="button" variant="outline">
              Cancel
            </Button>
          )}
          <Button disabled={isLoading} variant="primary" type="submit">
            {isLoading ? <GladiateLoader height={20} /> : <div>{buttonText}</div>}
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default ResourceNoteForm;
