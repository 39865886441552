import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  commandForRecordsRequestV3,
  createArctrievalEntityV3,
  createRecordRequest,
  deleteRecordRequest,
  getArctrievalEntityV3,
  listArctrievalEntitiesV3,
  listRecordRequestsForCaseV3,
  sendArctrievalRequestV3,
  updateArctrievalEntityV3,
  updateRecordRequest,
} from '../requests/argonauts';
import { getMedicalRecordRequestV3, getRecordRequestTaskLineupV3 } from '../requests/sibyls';

// #region ------------ RECORD REQUESTS-------------------
export const useGetRecordRequests = () =>
  useQuery({
    queryKey: [queryKeys.recordRequests],
    queryFn: async () => getMedicalRecordRequestV3(),
  });

export const useGetRecordRequestsForCase = (caseId: string) =>
  useQuery({
    queryKey: [queryKeys.recordRequests, caseId],
    queryFn: async () => listRecordRequestsForCaseV3(caseId),
    enabled: !!caseId,
  });

// #endregion

// #region ------------------- commands ------------------- //

export const useCreateArctrievalRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof sendArctrievalRequestV3>[0]) =>
      sendArctrievalRequestV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.recordRequests],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'update-settlement-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateArctrievalRecordsRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof commandForRecordsRequestV3>[0]) =>
      commandForRecordsRequestV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.recordRequests],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'update-settlement-error',
          variant: 'error',
        });
      }
    },
  });
};

// #region ------------ arctrieval entities ----------------- //
export const useGetArctrievalEntity = (gladiateId: string) => {
  return useQuery({
    queryKey: [queryKeys.arctrievalEntities, gladiateId],
    queryFn: async () => getArctrievalEntityV3(gladiateId),
    enabled: !!gladiateId,
  });
};

export const useGetArctrievalEntities = () => {
  return useQuery({
    queryKey: [queryKeys.arctrievalEntities],
    queryFn: async () => listArctrievalEntitiesV3(),
  });
};

export const useCreateArctrievalEntity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createArctrievalEntityV3>[0]) =>
      createArctrievalEntityV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.arctrievalEntities],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'update-settlement-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateArctrievalEntity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateArctrievalEntityV3>[0]) =>
      updateArctrievalEntityV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.arctrievalEntities],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'update-settlement-error',
          variant: 'error',
        });
      }
    },
  });
};

// #endregion

// #region ------------ manual records entities ----------------- //

export const useCreateRecordRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createRecordRequest>[0]) => createRecordRequest(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.recordRequests],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'medical-record-create-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateRecordRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateRecordRequest>[0]) => updateRecordRequest(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.recordRequests],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'medical-record-update-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteRecordRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestId: Parameters<typeof deleteRecordRequest>[0]) =>
      deleteRecordRequest(requestId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.recordRequests],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'medical-record-delete-error',
          variant: 'error',
        });
      }
    },
  });
};
// #endregion

export const useGetRecordRequestTaskLineup = () => {
  return useQuery({
    queryKey: [queryKeys.tasks, queryKeys.recordRequests, 'record-request-lineup'],
    queryFn: async () => getRecordRequestTaskLineupV3(),
  });
};
