import { queryKeys, useGetReferralSources, useUpdateCaseData } from '@colosseum/data';
import {
  CaseContactConnectionLinker,
  Form,
  RangeSlider,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import { CaseType, ReferralSource, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { z } from 'zod';

const formSchema = z.object({
  referralSource: z.unknown().optional(),
  referralSourceFeeType: z.string().optional(),
  referralSourceFee: z.coerce.number().nonnegative().nullish().optional(),
});

export interface ReferralFromSectionProps {
  caseData?: CaseType;
  labelClassName?: string;
}

export function ReferralSourceSection(props: ReferralFromSectionProps) {
  const { caseData, labelClassName } = props;
  const queryClient = useQueryClient();
  const { caseId } = useParams() as { caseId: string };

  const { data: referralSourcesData } = useGetReferralSources();
  const referralSourcesTransformed = referralSourcesData?.data
    .filter((referralSource) => referralSource?.title)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.referralSourceId]: curr,
      }),
      {},
    ) as Record<string, ReferralSource>;

  const referralSources = referralSourcesData?.data
    .filter((referralSource) => referralSource?.title)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.title ?? '']: curr.referralSourceId,
      }),
      {},
    );

  const updateCase = useUpdateCaseData(); // TODO: pass this as prop

  const [referredFee, setReferredFee] = useState(0);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      ...caseData,
    },
  });

  useEffect(() => {
    if (caseData) {
      setReferredFee(caseData.referralSourceFee ? caseData.referralSourceFee : 0);
    }
  }, [caseData]);

  function handleUpdate(key: string, value: string | ReferralSource) {
    updateCase.mutate({
      caseId,
      [key]: value,
    });
  }

  function onAfterChange(value: number) {
    setReferredFee(value);
    updateCase.mutate({ caseId, referralSourceFee: value });
  }

  return (
    <div>
      <div className="w-full mt-5 bg-white rounded-lg ">
        <div className="w-full pb-5 align-middle ">
          <Form {...form}>
            <form>
              <div className="pb-5">
                <SelectFormInput
                  title="Referral Source"
                  listItems={referralSources ?? {}}
                  listItemsIsObject
                  defaultValue={caseData?.referralSourceId}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('referralSource', referralSourcesTransformed[target.value]);
                    setTimeout(() => {
                      queryClient.invalidateQueries({
                        queryKey: [queryKeys.caseContactConnections],
                      });
                    }, 1000);
                  }}
                  {...form.register('referralSource')}
                />
              </div>
              {caseData?.referralSourceId && (
                <>
                  <div className="">
                    <CaseContactConnectionLinker
                      caseId={caseId}
                      title="Contact referring case"
                      roleOnCase={caseContactConnectionOptions.referralFromContact}
                    />
                  </div>
                  <div className="mt-6">
                    <SelectFormInput
                      {...form.register('referralSourceFeeType')}
                      title="Referral Fee Type"
                      listItems={{
                        Amount: 'amount',
                        Percentage: 'percentage',
                        None: 'none',
                      }}
                      listItemsIsObject
                      value={caseData?.referralSourceFeeType}
                      handleOnChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        handleUpdate('referralSourceFeeType', lowerCase(target.value));
                      }}
                    />
                  </div>
                  {caseData?.referralSourceFeeType === 'percentage' && (
                    <div className="mt-4">
                      <RangeSlider
                        title={'Referral fee to be paid'}
                        setValue={setReferredFee}
                        value={referredFee}
                        onAfterChange={onAfterChange}
                      />
                    </div>
                  )}
                  {caseData?.referralSourceFeeType === 'amount' && (
                    <div className="mt-6">
                      <TextFormInput
                        {...form.register(`referralSourceFee`, {
                          valueAsNumber: true,
                        })}
                        title="Referral fee to be paid"
                        handleOnBlur={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          onAfterChange(Number(target.value));
                        }}
                        type="number"
                      />
                    </div>
                  )}
                </>
              )}
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ReferralSourceSection;
