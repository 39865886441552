import {
  AuditDetailType,
  AuditType,
  DefaultV3Response,
  NotificationPreferencesType,
  NotificationType,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const baseHerodotusRoute = `herodotus/${API_STAGE}`;

// ------------------ AUDITS ------------------

// GET get_all_audits_v3
export const getAllAuditsV3 = () => {
  return axiosInstance
    .get<DefaultV3Response<AuditType[]>>(`${baseHerodotusRoute}/v3/audits`)
    .then((res) => res.data);
};

// GET get_audits_v3
export const getAuditsV3 = (itemId?: string) => {
  return axiosInstance
    .get<DefaultV3Response<AuditType[]>>(`${baseHerodotusRoute}/v3/${itemId}/audits`)
    .then((res) => res.data);
};

// GET get_audit_details_v3
export const getAuditDetailsV3 = (auditId?: string) => {
  return axiosInstance
    .get<DefaultV3Response<AuditDetailType[]>>(
      `${baseHerodotusRoute}/v3/audits/${auditId}/audit-details`,
    )
    .then((res) => res.data);
};

// ------------------ NOTIFICATIONS ------------------

// PATCH acknowledge_notifications_v3
export const acknowledgeNotificationsV3 = (data: { notifications: string[] }) => {
  return axiosInstance
    .post<DefaultV3Response<NotificationType>>(`${baseHerodotusRoute}/v3/notifications`, data)
    .then((res) => res.data);
};

// GET get_notifications_v3
export const getNotificationsV3 = () => {
  return axiosInstance
    .get<DefaultV3Response<NotificationType[]>>(`${baseHerodotusRoute}/v3/notifications`)
    .then((res) => res.data);
};

// ------------------ NOTIFICATION PREFERENCES ------------------

// GET get_notification_preferences_v3
export const getNotificationPreferencesV3 = () => {
  return axiosInstance
    .get<DefaultV3Response<NotificationPreferencesType>>(
      `${baseHerodotusRoute}/v3/notifications/preferences`,
    )
    .then((res) => res.data);
};

// PATCH update_notification_preferences_v3
export const updateNotificationPreferencesV3 = (
  notificationPreferences: Partial<NotificationPreferencesType>,
) => {
  return axiosInstance
    .patch<DefaultV3Response<NotificationPreferencesType>>(
      `${baseHerodotusRoute}/v3/notifications/preferences`,
      notificationPreferences,
    )
    .then((res) => res.data);
};
