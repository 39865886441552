import {
  casesSectionMappings,
  displayContactName,
  getCaseContactConnectionRoleIdForParty,
  scrollToCaseSection,
  useCreateCaseContactConnection,
  useCreateItemConnectionForScenario,
  useCreatePolicyForScenario,
  useCreateVehicleForScenario,
  useDeleteItemConnectionForScenario,
  useGetContactsInfinite,
  useGetScenarioComplete,
} from '@colosseum/data';
import {
  CaseContactConnectionSlideover,
  ContactInfoSlideover,
  CreateButton,
  DataTabs,
  EntityAddPopover,
  GladiateLoader,
  PolicyForm,
  ResourceSlideover,
  SlideoverContext,
  VehicleForm,
} from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  ContactType,
  PolicyType,
  VehicleType,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { TabsContent } from '@radix-ui/react-tabs';
import { Row } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import PartyCard from './PartyCard';
import PolicyCard from './PolicyCard';
import VehicleCard from './VehicleCard';

export interface PartiesAndPoliciesProps {
  caseId: string;
}

export interface handleCreateConnectionProps {
  roleOnCase: CaseContactConnectionRoleType['roleOnCase'];
  contactId?: string;
  vehicleId?: string;
  policyId?: string;
}

export function PartiesAndPolicies(props: PartiesAndPoliciesProps) {
  const { caseId } = props;

  const [policySlideoverOpen, setPolicySlideoverOpen] = useState(false);
  const [vehicleSlideoverOpen, setVehicleSlideoverOpen] = useState(false);
  const [contactSlideoverOpen, setContactSlideoverOpen] = useState(false);
  const [caseContactConnectionSlideoverOpen, setCaseContactConnectionSlideoverOpen] =
    useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | undefined>();
  const [selectedPolicyId, setSelectedPolicyId] = useState<string | undefined>();
  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [selectedConnectionId, setSelectedConnectionId] = useState<string>();
  const [newContactId, setNewContactId] = useState<string | undefined>();
  const [partyLoading, setPartyLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState('Party');
  const [selectedParty, setSelectedParty] = useState<ContactType & CaseContactConnectionType>();

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const { data: scenarioData, isLoading: isScenarioLoading } = useGetScenarioComplete(caseId);
  const selectedPolicy = scenarioData?.data?.policies?.find(
    (policy) => policy.policyId === selectedPolicyId,
  );
  const selectedVehicle = scenarioData?.data?.vehicles?.find(
    (vehicle) => vehicle.vehicleId === selectedVehicleId,
  );
  const { data: allContactsData } = useGetContactsInfinite();

  const scenario = scenarioData?.data;
  const createPolicy = useCreatePolicyForScenario();
  const createVehicle = useCreateVehicleForScenario();
  const createItemConnection = useCreateItemConnectionForScenario();
  const deleteConnection = useDeleteItemConnectionForScenario();
  const createCaseContactConnection = useCreateCaseContactConnection();

  const allContacts = allContactsData?.data;

  // #region Event Handlers
  const handleLinkedPoliciesRowClick = (
    row: Row<PolicyType>,
    parentEntity: { type: 'party' | 'vehicle'; id: string },
  ) => {
    const connection = scenario?.itemConnections?.find((itemConnection) => {
      if (parentEntity.type === 'party') {
        return (
          itemConnection.policyId === row.original?.policyId &&
          itemConnection.partyId === parentEntity.id
        );
      } else if (parentEntity.type === 'vehicle') {
        return (
          itemConnection.policyId === row.original?.policyId &&
          itemConnection.vehicleId === parentEntity.id
        );
      }
    });
    setSelectedConnectionId(connection?.itemConnectionId);
    setSelectedPolicyId(row.original?.policyId);
    setPolicySlideoverOpen(true);
  };

  const handleLinkedVehiclesRowClick = (
    row: Row<VehicleType>,
    parentEntity: { type: 'party' | 'policy'; id: string },
  ) => {
    const connection = scenario?.itemConnections?.find((itemConnection) => {
      if (parentEntity.type === 'party') {
        return (
          itemConnection.vehicleId === row.original?.vehicleId &&
          itemConnection.partyId === parentEntity.id
        );
      } else if (parentEntity.type === 'policy') {
        return (
          itemConnection.vehicleId === row.original?.vehicleId &&
          itemConnection.policyId === parentEntity.id
        );
      }
    });
    setSelectedConnectionId(connection?.itemConnectionId);
    setSelectedVehicleId(row.original?.vehicleId);
    setVehicleSlideoverOpen(true);
  };

  // parties current have no unlink button in slideover so we don't need to setConnectionID
  const handleLinkedPartiesRowClick = (row: Row<ContactType>) => {
    setSelectedContactId(row.original?.contactId);
    setContactSlideoverOpen(true);
  };

  // #endregion

  /**
   *
   * @description This function creates a caseContactConnection and then creates an itemConnection
   */
  const handleCreateConnection = ({
    roleOnCase,
    contactId,
    vehicleId,
    policyId,
  }: handleCreateConnectionProps) => {
    if (!caseId) return;
    // TODO: Extract this function into a hook
    setPartyLoading(true);

    createCaseContactConnection.mutateAsync({ caseId, contactId, roleOnCase }).then((res) => {
      if (!contactId) {
        setNewContactId(res?.data?.contactId);
        setContactSlideoverOpen(true);
        setPartyLoading(false);
      }

      if (vehicleId) {
        createItemConnection.mutateAsync({
          caseId,
          partyId: res?.data.caseContactConnectionRole?.caseContactConnectionRoleId,
          vehicleId,
        });
      }

      if (policyId) {
        createItemConnection.mutateAsync({
          caseId,
          partyId: res?.data?.caseContactConnectionRole?.caseContactConnectionRoleId,
          policyId,
        });
      }

      setPartyLoading(false);
    });
  };

  useEffect(() => {
    if (policySlideoverOpen === false && vehicleSlideoverOpen === false) {
      setSelectedPolicyId(undefined);
      setSelectedVehicleId(undefined);
      setSelectedConnectionId(undefined);
    }
  }, [policySlideoverOpen, vehicleSlideoverOpen]);

  useEffect(() => {
    const ref = document.querySelectorAll(
      `[data-case-submenu-item='${casesSectionMappings.incident}']`,
    )[0];
    if (pendingSlideoverToOpen?.type === 'vehicle') {
      if (!pendingSlideoverToOpen.dontScroll) {
        scrollToCaseSection(ref, true);
        setActiveFilter('Vehicle');
      }
      setSelectedVehicleId(pendingSlideoverToOpen?.id ?? '');
      setVehicleSlideoverOpen(true);
      setPendingSlideoverToOpen(undefined);
    } else if (pendingSlideoverToOpen?.type === 'policy') {
      if (!pendingSlideoverToOpen.dontScroll) {
        scrollToCaseSection(ref, true);
        setActiveFilter('Policy');
      }
      setSelectedPolicyId(pendingSlideoverToOpen?.id ?? '');
      setPolicySlideoverOpen(true);
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <>
      <div>
        <div className="flex items-center justify-between w-full pb-5">
          <div className="grow"></div>
          <div className="relative">
            {activeFilter === 'Party' && (
              <EntityAddPopover
                title="Add Party"
                onAdd={() => {
                  handleCreateConnection({
                    roleOnCase: partiesAndPoliciesConnectionOptions.party,
                  });
                }}
                primary
                placeholder={`Search ${allContacts?.length ?? 0}${
                  allContactsData?.meta.lastEvaluatedKey ? '+' : ''
                } contacts...`}
                isLoading={partyLoading}
                listItems={
                  allContacts
                    ?.filter((contact) => {
                      return contact.firstName || contact.lastName || contact.name;
                    })
                    ?.map((contact) => {
                      return {
                        label: displayContactName(contact),
                        onClick: () => {
                          handleCreateConnection({
                            roleOnCase: partiesAndPoliciesConnectionOptions.party,
                            contactId: contact.contactId,
                          });
                        },
                      };
                    }) ?? []
                }
              />
            )}
            {activeFilter === 'Policy' && (
              <CreateButton
                title="Add Policy"
                onClick={() => {
                  createPolicy
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      setSelectedPolicyId(res?.data?.policyId);
                      setPolicySlideoverOpen(true);
                    });
                }}
                loading={createPolicy.isLoading}
              />
            )}

            {activeFilter === 'Vehicle' && (
              <CreateButton
                title="Add Vehicle"
                onClick={() => {
                  createVehicle
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      setSelectedVehicleId(res?.data?.vehicleId);
                      setVehicleSlideoverOpen(true);
                    });
                }}
                loading={createVehicle.isLoading}
              />
            )}
          </div>
        </div>
        <DataTabs
          className="mt-0"
          filters={[
            {
              title: 'Party',
              count: scenario?.parties?.length ?? 0,
              value: 'Party',
            },
            {
              title: 'Policy',
              count: scenario?.policies?.length ?? 0,
              value: 'Policy',
            },
            {
              title: 'Vehicle',
              count: scenario?.vehicles?.length ?? 0,
              value: 'Vehicle',
            },
          ]}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
        />
        <div className="mt-3 border divide-y divide-gray-300 rounded-lg shadow-md px-7">
          {activeFilter === 'Party' &&
            (isScenarioLoading ? (
              <div className="py-5 text-center text-gray-500">
                <GladiateLoader />
              </div>
            ) : scenario?.parties.length ? (
              scenario?.parties?.map((party) => (
                <PartyCard
                  caseId={caseId}
                  key={getCaseContactConnectionRoleIdForParty(party)}
                  contact={party}
                  scenario={scenario}
                  handleLinkedPoliciesRowClick={handleLinkedPoliciesRowClick}
                  handleLinkedVehiclesRowClick={handleLinkedVehiclesRowClick}
                  setSelectedPolicyId={setSelectedPolicyId}
                  setSelectedVehicleId={setSelectedVehicleId}
                  setPolicySlideoverOpen={setPolicySlideoverOpen}
                  setVehicleSlideoverOpen={setVehicleSlideoverOpen}
                  setSelectedConnectionId={setSelectedConnectionId}
                  setCaseContactConnectionSlideoverOpen={() => {
                    setSelectedParty(party);
                    setCaseContactConnectionSlideoverOpen(true);
                  }}
                />
              ))
            ) : (
              <div className="py-5 text-center text-gray-500">
                No parties found. Click the button above to add a party.
              </div>
            ))}
          {activeFilter === 'Policy' &&
            (isScenarioLoading ? (
              <div className="py-5 text-center text-gray-500">
                <GladiateLoader />
              </div>
            ) : scenario?.policies?.length ? (
              scenario?.policies?.map((policy) => (
                <PolicyCard
                  caseId={caseId}
                  key={policy.policyId}
                  parties={scenario?.parties}
                  setNewContactId={setNewContactId}
                  policy={policy}
                  scenario={scenario}
                  handleLinkedPartiesRowClick={(e) => {
                    setSelectedParty(e.original as ContactType & CaseContactConnectionType);
                    setCaseContactConnectionSlideoverOpen(true);
                  }}
                  handleLinkedVehiclesRowClick={handleLinkedVehiclesRowClick}
                  setSelectedVehicleId={setSelectedVehicleId}
                  setPolicySlideoverOpen={setPolicySlideoverOpen}
                  handleCreateConnection={handleCreateConnection}
                  setSelectedPolicyId={setSelectedPolicyId}
                  setContactSlideoverOpen={setContactSlideoverOpen}
                  setVehicleSlideoverOpen={setVehicleSlideoverOpen}
                  setSelectedConnectionId={setSelectedConnectionId}
                />
              ))
            ) : (
              <div className="py-5 text-center text-gray-500">
                No policies found. Click the button above to add a policy.
              </div>
            ))}
          {activeFilter === 'Vehicle' &&
            (isScenarioLoading ? (
              <div className="py-5 text-center text-gray-500">
                <GladiateLoader />
              </div>
            ) : scenario?.vehicles?.length ? (
              scenario?.vehicles?.map((vehicle) => (
                <VehicleCard
                  caseId={caseId}
                  key={vehicle.vehicleId}
                  vehicle={vehicle}
                  scenario={scenario}
                  parties={scenario?.parties}
                  setNewContactId={setNewContactId}
                  handleLinkedPartiesRowClick={handleLinkedPartiesRowClick}
                  handleLinkedPoliciesRowClick={handleLinkedPoliciesRowClick}
                  setSelectedPolicyId={setSelectedPolicyId}
                  setPolicySlideoverOpen={setPolicySlideoverOpen}
                  setSelectedConnectionId={setSelectedConnectionId}
                  handleCreateConnection={handleCreateConnection}
                  setSelectedVehicleId={setSelectedVehicleId}
                  setContactSlideoverOpen={setContactSlideoverOpen}
                  setVehicleSlideoverOpen={setVehicleSlideoverOpen}
                />
              ))
            ) : (
              <div className="py-5 text-center text-gray-500">
                No vehicles found. Click the button above to add a vehicle.
              </div>
            ))}
        </div>
      </div>
      <ContactInfoSlideover
        contactId={newContactId ?? selectedContactId}
        open={contactSlideoverOpen}
        setOpen={setContactSlideoverOpen}
      />
      <ResourceSlideover
        title="Edit Policy"
        description="Manage the policy for this party."
        open={policySlideoverOpen}
        setOpen={setPolicySlideoverOpen}
        deleteFunction={() => {
          if (!scenario?.itemConnections) {
            enqueueSnackbar('Error unlinking policy', {
              variant: 'error',
            });
            return;
          }
          if (selectedConnectionId) {
            deleteConnection.mutate(selectedConnectionId ?? '');
          } else {
            enqueueSnackbar('Error unlinking policy', {
              variant: 'error',
            });
          }
        }}
        displayDeleteButton
        deleteButtonText="Unlink Policy"
        createType="policy"
        resourceId={selectedPolicyId ?? ''}
        caseId={caseId}
      >
        <TabsContent value="details">
          <PolicyForm caseId={caseId} policy={selectedPolicy} policyLoading={false} />
        </TabsContent>
      </ResourceSlideover>
      <ResourceSlideover
        title="Edit Vehicle"
        description="Manage the vehicle for this party."
        open={vehicleSlideoverOpen}
        setOpen={setVehicleSlideoverOpen}
        deleteFunction={() => {
          if (!scenario?.itemConnections) {
            enqueueSnackbar('Error unlinking vehicle', {
              variant: 'error',
            });
            return;
          }
          if (selectedConnectionId) {
            deleteConnection.mutate(selectedConnectionId);
          } else {
            enqueueSnackbar('Error unlinking vehicle', {
              variant: 'error',
            });
          }
        }}
        displayDeleteButton
        deleteButtonText="Unlink Vehicle"
        createType="vehicle"
        resourceId={selectedVehicleId ?? ''}
        caseId={caseId}
      >
        <TabsContent value="details">
          <VehicleForm caseId={caseId} vehicle={selectedVehicle} vehicleLoading={false} />
        </TabsContent>
      </ResourceSlideover>
      <CaseContactConnectionSlideover
        title={'Case Contact Connection'}
        description={'View your case contact connection'}
        open={caseContactConnectionSlideoverOpen}
        setOpen={setCaseContactConnectionSlideoverOpen}
        contact={selectedParty}
        handleContactDelete={() => {
          setCaseContactConnectionSlideoverOpen(false);
        }}
        showLiabilityStatus
        selectedCaseContactConnection={
          {
            ...scenarioData?.data.contacts.find(
              (elt) => elt.contact.contactId === selectedParty?.contactId,
            ),
          } as ContactType & CaseContactConnectionType
        }
        caseId={caseId}
      >
        <></>
      </CaseContactConnectionSlideover>
    </>
  );
}

export default PartiesAndPolicies;
