import {
  displayContactName,
  tanstackTableNames,
  useCreateContactConnection,
  useGetContactConnectionsByContact,
  useGetContactsInfinite,
} from '@colosseum/data';
import { ContactConnectionType, ContactType } from '@gladiate/types';
import { useState } from 'react';
import ContactConnectionsTableSlideover from '../ContactConnectionsTableSlideover/ContactConnectionsTableSlideover';
import EntityAddPopover from '../EntityAddPopover/EntityAddPopover';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './contact-connections-columns';

export interface ContactConnectionsTableProps {
  contactId: string;
}

export const getUniqueContactConnections = (connections?: ContactConnectionType[]) => {
  return (
    connections?.reduce((acc, curr) => {
      if (curr.connectedToContactId) {
        if (!acc[curr.connectedToContactId]) {
          acc[curr.connectedToContactId] = [curr];
        } else {
          acc[curr.connectedToContactId].push(curr);
        }
      }

      return acc;
    }, {} as { [key: string]: ContactConnectionType[] }) ?? {}
  );
};

export const getContactsFromConnections = (connections?: ContactConnectionType[]) => {
  const uniqueContactConnections = getUniqueContactConnections(connections);
  return Object.keys(uniqueContactConnections)?.map((key: string) => {
    const contact = uniqueContactConnections?.[key];
    return {
      ...contact?.[0]?.connectedToContact,
      roles: contact?.map((connection) => connection.relationship),
    };
  }) as (ContactType & { roles: ContactConnectionType['relationship'][] })[];
};

export function ContactConnectionsTable(props: ContactConnectionsTableProps) {
  const { contactId } = props;
  const [open, setOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | undefined>(undefined);
  const { data: contactsData, isError: isContactsError } = useGetContactsInfinite();
  const createContactConnection = useCreateContactConnection();
  const { data: contactConnectionsData, isLoading: isContactConnectionsLoading } =
    useGetContactConnectionsByContact(contactId);
  const contactsForTable = getContactsFromConnections(contactConnectionsData?.data);
  const selectedContact = contactsForTable.find(
    (contact) => contact.contactId === selectedContactId,
  );

  const handleCreateConnection = (secondaryContactId: string) => {
    createContactConnection
      .mutateAsync({
        primaryContactId: contactId,
        secondaryContactId,
        primaryContactRelationship: 'connection',
      })
      .then(() => {
        setOpen(true);
        setSelectedContactId(secondaryContactId);
      });
  };

  return (
    <div>
      <ContactConnectionsTableSlideover
        open={open}
        setOpen={setOpen}
        selectedContact={selectedContact}
        primaryContactId={contactId}
      />

      <DataTable
        data={contactsForTable}
        columns={columns}
        tableName={tanstackTableNames.contactConnections}
        showSearchBar
        customRightButton={
          <EntityAddPopover
            buttonClassName="ml-2"
            title="Add Contact"
            hideCreateNew
            onAdd={() => {}}
            primary
            placeholder={`Search ${contactsData?.data.length ?? 0}${
              contactsData?.meta.lastEvaluatedKey ? '+' : ''
            } contacts...`}
            isError={isContactsError}
            isLoading={createContactConnection.isLoading}
            isFetchingMore={!!contactsData?.meta?.lastEvaluatedKey}
            listItems={
              contactsData?.data
                ?.filter((contact) => {
                  return contact.firstName || contact.lastName || contact.name;
                })
                ?.map((contact) => {
                  return {
                    label: displayContactName(contact),
                    onClick: () => {
                      handleCreateConnection(contact.contactId);
                    },
                  };
                }) ?? []
            }
          />
        }
        handleRowClick={(item) => {
          setOpen(true);
          setSelectedContactId(item.original.contactId);
        }}
        isLoading={isContactConnectionsLoading}
      />
    </div>
  );
}

export default ContactConnectionsTable;
