import { LabyrinthField } from '@gladiate/types';
import { Popover, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import Tooltip from '../data-display/Tooltip/Tooltip';

/* eslint-disable-next-line */
export interface MultiSelectDropdownProps {
  fieldInfo: LabyrinthField; // TODO: Refactor these props to just take title, valueVariable, options, and tooltip
  fieldValue: string[];
  handleOnChange: any;
  unopenedText?: string;
  disabled?: boolean;
}

export function MultiSelectDropdown(props: MultiSelectDropdownProps) {
  const options = props.fieldInfo.options ?? ['No options available'];
  const [selected, setSelected] = useState<string[]>(props.fieldValue || null);

  return (
    <div className="relative">
      <label className="absolute z-10 flex items-center px-1 text-xs font-medium text-gray-400 bg-white rounded-sm -top-2 left-2">
        {props.fieldInfo.title ?? 'No title'}
        {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo?.tooltip?.description} />}
      </label>

      <div
        className={classNames(
          'relative w-full flex items-center justify-between py-2.5 pl-3 pr-2 text-left text-gray-900 bg-white rounded-md shadow-sm cursor-default sm:text-sm sm:leading-6 ',
          props.disabled ? '' : ' ring-1 ring-inset ring-gray-300 focus:outline-none',
        )}
      >
        <Popover.Group className="flex w-full">
          <Popover
            as="div"
            key={props.fieldInfo?.valueVariable}
            id={`multi-select-${props.fieldInfo?.valueVariable}`}
            className="relative inline-block w-full text-left"
          >
            <Popover.Button className="flex items-center justify-between w-full text-sm font-medium text-gray-700 group hover:text-gray-900 focus:outline-none">
              <div className="flex items-center">
                <span className="mr-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                  {selected?.length ?? 0}
                </span>
                <span className="">
                  {props.unopenedText ? props.unopenedText : 'Selected Options'}
                </span>
              </div>

              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 z-20 w-full p-4 mt-2 overflow-y-auto origin-top-right bg-white rounded-md shadow-2xl max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="space-y-4">
                  {options?.map((option, optionIdx) => (
                    <div key={option} className="flex items-center">
                      <input
                        id={`filter-${props.fieldInfo?.valueVariable}-${optionIdx}`}
                        name={`${props.fieldInfo?.valueVariable}[]`}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded text-atlantic-blue form-checkbox focus:ring-atlantic-blue"
                        checked={selected?.includes(option)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelected([...selected, option]);

                            props.handleOnChange({
                              value: [...selected, option],
                              field: props.fieldInfo,
                            });
                          } else {
                            setSelected(selected.filter((item) => item !== option));

                            props.handleOnChange({
                              value: selected.filter((item) => item !== option),
                              field: props.fieldInfo,
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor={`filter-${props.fieldInfo?.valueVariable}-${optionIdx}`}
                        className="pr-6 ml-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
      </div>
    </div>
  );
}

export default MultiSelectDropdown;
