import {
  tanstackTableNames,
  useCreateTemplate,
  useGetAllItemTagConnections,
  useGetTemplates,
} from '@colosseum/data';
import {
  CreateButton,
  CustomErrorBoundary,
  DataTable,
  FilePreview,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { FileResourceType, TemplateType } from '@gladiate/types';
import { withErrorBoundary } from '@sentry/react';
import { uniqBy } from 'lodash';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import TemplateDictionary from './TemplateDictionary/TemplateDictionary';
import { columns } from './templates-table-columns';

/* eslint-disable-next-line */
export interface TemplatesPageProps {}

export function TemplatesPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<FileResourceType | undefined>();
  const templateQuery = useGetTemplates();
  const { data: itemTagConnectionsData } = useGetAllItemTagConnections();
  const createTemplate = useCreateTemplate();

  const templates = templateQuery.data?.data ?? [];
  const tagItemConnections = itemTagConnectionsData?.data || [];
  const tagsFilterOptions = tagItemConnections
    .filter(
      (tag) =>
        tag.type === 'template' &&
        tag.tagAttributes?.title &&
        templates.some((template) => template.templateId === tag.itemTagConnectionId.split('-')[0]),
    )
    .map((tag) => ({
      value: tag.tagAttributes?.title ?? '',
      label: tag.tagAttributes?.title ?? 'No Title',
    }));

  const templatesWithTags = templates.map((template) => {
    const tagsForTemplate =
      tagItemConnections.filter(
        (tag) =>
          tag?.itemTagConnectionId.split('-')[0] === template.templateId && tag.type === 'template',
      ) ?? [];
    return { ...template, tags: tagsForTemplate };
  });

  const handleRowClick = useCallback(
    (row: any) => {
      const id: string = row?.original?.templateId;
      return navigate(`${id}`, { replace: true });
    },
    [navigate],
  );

  const handleTemplateClick = (template: TemplateType) => {
    setShowFilePreview(true);
    setFileToPreview({
      name: template.fileName,
      s3ObjKey: template.documentId,
    });
  };

  function createTemplateById() {
    setLoading(true);
    createTemplate.mutateAsync({}).then((res) => {
      navigate(`/templates/${res.data.data.templateId}`);
      setLoading(false);
    });
  }

  return (
    <div
      className="relative p-4 mt-5 bg-white border shadow-sm rounded-xl"
      data-cy="templates-list-view"
    >
      <Helmet defer={false}>
        <title>Templates</title>
      </Helmet>
      <FilePreview
        isOpen={showFilePreview}
        setOpen={setShowFilePreview}
        filePreviewItem={fileToPreview}
      />
      <Typography tag="h1" variant="pageHeading">
        Templates
      </Typography>
      <Tabs defaultValue="templates">
        <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
          <TabsTrigger
            className={tabTriggerClass}
            key="templates"
            value="templates"
            data-cy="templates-tab"
          >
            Templates
          </TabsTrigger>
          <TabsTrigger
            className={tabTriggerClass}
            key="template-dictionary"
            value="template-dictionary"
            data-cy="template-dictionary-tab"
          >
            Template Dictionary
          </TabsTrigger>
        </TabsList>
        <TabsContent value="templates">
          <div className="flex w-full">
            <div className="flex justify-between w-full gap-10 ml-1">
              <div></div>
              <CreateButton
                title={'Create Template'}
                loading={loading}
                onClick={createTemplateById}
              />
            </div>
          </div>
          <div className="pt-4">
            <DataTable
              data={templatesWithTags ?? []}
              columns={columns(handleTemplateClick)}
              handleRowClick={handleRowClick}
              filters={[
                {
                  id: 'Tags',
                  options: uniqBy(tagsFilterOptions, 'value'),
                },
              ]}
              showSearchBar
              isLoading={templateQuery.isInitialLoading}
              initialVisibility={{
                'Date Created': false,
              }}
              tableName={tanstackTableNames.templates}
            />
          </div>
        </TabsContent>
        <TabsContent value="template-dictionary" className="px-4">
          <TemplateDictionary />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default withErrorBoundary(TemplatesPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
