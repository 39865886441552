import { CompleteMedicalTreatmentType, useGetMedicalTreatmentTypes } from '@colosseum/data';
import { MedicalTreatmentBill, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';
import CaseContactConnectionLinker from '../CaseContactConnectionLinker/CaseContactConnectionLinker';
import ClientSelectDropdown from '../ClientSelectDropdown/ClientSelectDropdown';
import Toggle from '../Toggle/Toggle';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface TreatmentFormProps {
  updateHandler: any;
  treatment: CompleteMedicalTreatmentType;
  caseId: string;
  typing: boolean;
  setTyping: any;
  estimatedMedicalCosts: number;
}

const formSchema = z.object({
  medicalTreatmentTypeId: z.string().optional(),
  startingDateOfService: z.string().optional(),
  endingDateOfService: z.string().optional(),
  description: z.string().optional(),
  caseContactConnectionId: z.string().optional(),
});

//TODO this component is a mess and needs major rework to be more readable and maintainable
export function TreatmentForm(props: TreatmentFormProps) {
  const { caseId } = props;
  const { data: medicalTreatmentTypeData } = useGetMedicalTreatmentTypes();
  const medicalTreatmentTypeOptions = medicalTreatmentTypeData?.data
    .filter((treatmentType) => treatmentType.title)
    .reduce((acc, treatmentType) => {
      return {
        ...acc,
        [treatmentType.title ?? '']: treatmentType.medicalTreatmentTypeId,
      };
    }, {});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...props.treatment,
    },
  });

  async function handleUpdate(value: string | number, key: string) {
    await props.updateHandler(value, key, props.treatment.medicalTreatmentId);
  }

  function handleUpdateNumber(value: any, key: string) {
    // strip value of currency formatting
    value = value.replace(/[^0-9.-]+/g, '');

    const temp = parseFloat(value);
    props.updateHandler(temp, key, props.treatment.medicalTreatmentId);
  }

  function handleUpdateBool(value: '1' | '0', key: string) {
    props.updateHandler(value, key, props.treatment.medicalTreatmentId);
  }

  const renderNumberInput = ({
    key,
    label,
    decimals,
    prefix,
  }: {
    key: string;
    label: string;
    decimals?: number;
    prefix?: string;
  }) => {
    const decimalsToShow = decimals ?? 0;
    return (
      <div className="relative w-full p-3 border border-gray-300 rounded-md">
        <div className="flex items-center">
          {prefix && <span className="mr-2 text-sm text-gray-500">{prefix}</span>}
          <NumericFormat
            className="w-full bg-white focus:outline-none"
            value={props.treatment?.[key as keyof MedicalTreatmentBill]?.toLocaleString('en-US', {
              minimumFractionDigits: decimalsToShow,
            })}
            onBlur={(e) => {
              props.setTyping(false);
              handleUpdateNumber(e.target.value, key);
            }}
            onChange={(e) => {
              props.setTyping(true);
            }}
            decimalScale={decimalsToShow}
            thousandSeparator=","
            allowNegative={false}
          />
          <div className="absolute px-1 text-xs text-gray-400 bg-white rounded-md -top-2 left-2">
            {label}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Form {...form}>
        <form>
          <ClientSelectDropdown
            caseId={props.caseId}
            form={form}
            caseItemType="medicalTreatment"
            caseItemId={props.treatment.medicalTreatmentId}
          />

          <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
            <CalendarFormInput
              name="startingDateOfService"
              title="Starting Date of Service"
              handleOnChange={(e) => {
                const target = e.target as HTMLInputElement;
                props.setTyping(true);
                handleUpdate(target.value, 'startingDateOfService');
              }}
              resourceTypeObj={{
                type: 'medical-treatment-bill',
                id: props.treatment.medicalTreatmentId,
              }}
            />
            <CalendarFormInput
              name="endingDateOfService"
              title="Ending Date of Service"
              handleOnChange={(e) => {
                const target = e.target as HTMLInputElement;
                props.setTyping(true);

                handleUpdate(target.value, 'endingDateOfService');
              }}
              resourceTypeObj={{
                type: 'medical-treatment-bill',
                id: props.treatment.medicalTreatmentId,
              }}
            />
            <div className="min-h-[50px]">
              <SelectFormInput
                {...form.register('medicalTreatmentTypeId')}
                title="Type of Treatment"
                listItems={medicalTreatmentTypeOptions ?? {}}
                listItemsIsObject
                defaultValue={props.treatment?.medicalTreatmentTypeId}
                triggerClassName="h-[50px]"
                placeholderText="Select a type"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate(target.value, target.name);
                }}
              />
            </div>

            {renderNumberInput({
              key: 'numberOfVisits',
              label: 'Number of Visits',
            })}
            <div className="relative w-full col-span-2 ml-1 rounded-md">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Billing Entity"
                roleOnCase={caseContactConnectionOptions.billingEntity}
                itemType="medicalTreatment"
                itemId={props.treatment.medicalTreatmentId}
              />
            </div>
            <div className="relative w-full col-span-2 mt-1 ml-1 rounded-md">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Medical Provider"
                roleOnCase={caseContactConnectionOptions.medicalProvider}
                itemType="medicalTreatment"
                itemId={props.treatment.medicalTreatmentId}
              />
            </div>
            <span className="relative w-full col-span-2 pt-3 ">
              <TextFormInput
                {...form.register('description')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate(target.value, 'description');
                }}
                title="Description"
                type="textarea"
              />
            </span>
            <div className="relative flex items-center justify-between w-full pr-1 text-sm min-h-[50px]">
              <p> Surgery Performed?</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleUpdateBool(
                    props.treatment?.surgeryPerformed === '1' ? '0' : '1',
                    'surgeryPerformed',
                  );
                }}
              >
                <Toggle editable={true} newState={props.treatment?.surgeryPerformed === '1'} />
              </div>
            </div>
            <div>
              {props.treatment?.surgeryPerformed === '1'
                ? renderNumberInput({
                    key: 'surgeryCount',
                    label: 'Surgery Count',
                  })
                : null}
            </div>
            <div className="relative flex items-center justify-between min-h-[50px] w-full pr-1 text-sm">
              <p> MRI Performed?</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleUpdateBool(
                    props.treatment?.mriPerformed === '1' ? '0' : '1',
                    'mriPerformed',
                  );
                }}
              >
                <Toggle editable={true} newState={props.treatment?.mriPerformed === '1'} />
              </div>
            </div>
            <div>
              {props.treatment?.mriPerformed === '1'
                ? renderNumberInput({ key: 'mriCount', label: 'MRI Count' })
                : null}
            </div>
            <div className="relative min-h-[50px] flex items-center justify-between w-full pr-1 text-sm">
              <p> Injection Performed?</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleUpdateBool(
                    props.treatment?.injectionPerformed === '1' ? '0' : '1',
                    'injectionPerformed',
                  );
                }}
              >
                <Toggle editable={true} newState={props.treatment?.injectionPerformed === '1'} />
              </div>
            </div>
            <div>
              {props.treatment?.injectionPerformed === '1'
                ? renderNumberInput({
                    key: 'injectionCount',
                    label: 'Injection Count',
                  })
                : null}
            </div>
          </div>
        </form>
      </Form>
      <h1 className="mt-5 font-semibold">Billed</h1>
      <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
        {renderNumberInput({
          key: 'amountBilled',
          label: 'Amount Billed',
          decimals: 2,
          prefix: '$',
        })}
        {renderNumberInput({
          key: 'adjustments',
          label: 'Adjustments',
          decimals: 2,
          prefix: '$',
        })}
        <div className="relative w-full col-span-2">
          <p className="float-right text-sm font-semibold">
            Net After Adjustments: $
            {props.treatment?.netBillAfterAdjustments
              ? props.treatment?.netBillAfterAdjustments?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })
              : (0.0).toFixed(2)}
          </p>
        </div>
      </div>

      {/* PAYMENTS SECTION */}
      <h1 className="mt-5 font-semibold">Payments</h1>
      <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
        <div className="relative flex min-h-[50px] items-center justify-between w-full pr-1 text-sm">
          <p> Health Insurance Coverage </p>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleUpdateBool(
                props.treatment?.healthInsuranceCoverage === '1' ? '0' : '1',
                'healthInsuranceCoverage',
              );
            }}
          >
            <Toggle editable={true} newState={props.treatment?.healthInsuranceCoverage === '1'} />
          </div>
        </div>
        <div>
          {props.treatment?.healthInsuranceCoverage === '1'
            ? renderNumberInput({
                key: 'healthInsurancePayments',
                label: 'Health Insurance Payment',
                decimals: 2,
                prefix: '$',
              })
            : null}
        </div>

        <div className="relative flex min-h-[50px] items-center justify-between w-full pr-1 text-sm">
          <p> Client Paid out of Pocket </p>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleUpdateBool(
                props.treatment?.clientPaidOutOfPocket === '1' ? '0' : '1',
                'clientPaidOutOfPocket',
              );
            }}
          >
            <Toggle editable={true} newState={props.treatment?.clientPaidOutOfPocket === '1'} />
          </div>
        </div>
        <div>
          {props.treatment?.clientPaidOutOfPocket === '1'
            ? renderNumberInput({
                key: 'clientPaidOutOfPocketPayment',
                label: 'Client Out Of Pocket Payment',
                decimals: 2,
                prefix: '$',
              })
            : null}
        </div>

        <div className="relative min-h-[50px] flex items-center w-full pr-1 text-sm">
          <p> Personal Injury Protection {'(PIP)'} Coverage</p>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleUpdateBool(
                props.treatment?.personalInjuryProtectionCoverage === '1' ? '0' : '1',
                'personalInjuryProtectionCoverage',
              );
            }}
          >
            <Toggle
              editable={true}
              newState={props.treatment?.personalInjuryProtectionCoverage === '1'}
            />
          </div>
        </div>
        <div>
          {props.treatment?.personalInjuryProtectionCoverage === '1'
            ? renderNumberInput({
                key: 'personalInjuryProtectionPayment',
                label: 'PIP Payment',
                decimals: 2,
                prefix: '$',
              })
            : null}
        </div>
        <div className="relative flex items-center min-h-[50px] justify-between w-full pr-1 text-sm">
          <p> Medical Payment Coverage {'(Med Pay)'}</p>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleUpdateBool(
                props.treatment?.medicalPaymentCoverage === '1' ? '0' : '1',
                'medicalPaymentCoverage',
              );
            }}
          >
            <Toggle editable={true} newState={props.treatment?.medicalPaymentCoverage === '1'} />
          </div>
        </div>
        <div>
          {props.treatment?.medicalPaymentCoverage === '1'
            ? renderNumberInput({
                key: 'medicalPaymentAmount',
                label: 'Med Pay Payment',
                decimals: 2,
                prefix: '$',
              })
            : null}
        </div>

        <div className="relative w-full col-span-2">
          <p className="float-right text-sm font-semibold">
            Total Payments Made: ${' '}
            {props.treatment?.totalPayments
              ? props.treatment?.totalPayments?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })
              : (0.0).toFixed(2)}
          </p>
        </div>
      </div>
      <h1 className="mt-5 font-semibold">Balance and Reductions</h1>
      <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
        <div className="relative w-full col-span-2">
          <p className="text-sm font-semibold">
            Unpaid Balance: ${' '}
            {props.treatment?.unpaidBalance
              ? props.treatment?.unpaidBalance?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })
              : (0.0).toFixed(2)}{' '}
          </p>
        </div>
        <div className="relative w-full col-span-2">
          <p className="text-sm font-semibold ">
            Paid + Owed: ${' '}
            {props.treatment?.paidPlusOwed
              ? props.treatment?.paidPlusOwed?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })
              : (0.0).toFixed(2)}
          </p>
        </div>
        <div className="relative w-full col-span-2">
          <p className="text-sm font-semibold ">
            {'Reduction: '}
            {props.treatment?.reductionPercentage &&
            props.treatment.reductionAmount &&
            props.treatment.reductionAmount > 0
              ? `$${Number(props.treatment?.reductionAmount).toLocaleString('en-US')}
                  (${Number(props.treatment?.reductionPercentage).toFixed(2)}%)`
              : 'N/A'}
          </p>
        </div>
        {renderNumberInput({
          key: 'presentBalance',
          label: 'Present Balance',
          decimals: 2,
          prefix: '$',
        })}
        <div className="relative w-full p-3 border border-gray-300 rounded-md">
          <div>
            <input
              className="w-full bg-white focus:outline-none"
              placeholder=" "
              type="date"
              defaultValue={props.treatment?.balanceVerifiedDate}
              onBlur={(e) => {
                handleUpdate(e.target.value, 'balanceVerifiedDate');
                props.setTyping(false);
              }}
              onChange={(e) => {
                props.setTyping(true);
              }}
            ></input>
            <div className="absolute px-1 text-xs text-gray-400 bg-white rounded-md -top-2 left-2">
              Date Verified
            </div>
          </div>
        </div>
        <div className="relative w-full col-span-2">
          <p className="text-sm font-semibold">
            Percentage of Estimated Medical Costs:{' '}
            {props?.treatment?.presentBalance && props.estimatedMedicalCosts != 0
              ? `${((props?.treatment?.presentBalance / props.estimatedMedicalCosts) * 100).toFixed(
                  2,
                )}%`
              : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TreatmentForm;
