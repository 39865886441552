import { useUpdateCaseData } from '@colosseum/data';
import {
  CaseContactConnectionLinker,
  Form,
  RangeSlider,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import { CaseType, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { z } from 'zod';

/* eslint-disable-next-line */
export interface ReferCaseProps {
  caseData?: CaseType;
}

const formSchema = z.object({
  referToReason: z.string().optional(),
  referToReasonDescription: z.string().optional(),
});

export function ReferCase(props: ReferCaseProps) {
  const { caseData } = props;
  const { caseId } = useParams() as { caseId: string };
  const updateCase = useUpdateCaseData(); // TODO: pass this as prop

  const [referredFee, setReferredFee] = useState(0);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...caseData,
    },
  });

  useEffect(() => {
    if (caseData) {
      setReferredFee(caseData.referToAttorneyFee ? caseData.referToAttorneyFee : 0);
    }
  }, [caseData]);

  function handleUpdate(key: string, value: string) {
    updateCase.mutate({
      caseId,
      [key]: value,
    });
  }

  function onAfterChange(value: number) {
    setReferredFee(value);
    updateCase.mutate({ caseId, referToAttorneyFee: value });
  }

  return (
    <div>
      {caseData && caseData?.caseStatus?.category === 'refer' ? (
        <div className="w-full mt-5 bg-white rounded-lg ">
          <div className="w-full pb-5 align-middle ">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <label className="mb-3 text-xl font-semibold capitalize">Refer To</label>
              </div>
            </div>
            <Form {...form}>
              <form>
                <div className="pb-5">
                  <CaseContactConnectionLinker
                    caseId={caseId}
                    title="Attorney referring case to"
                    roleOnCase={caseContactConnectionOptions.referToAttorney}
                  />
                </div>

                <div className="pb-5">
                  <CaseContactConnectionLinker
                    caseId={caseId}
                    title="Firm referring case to"
                    roleOnCase={caseContactConnectionOptions.referToFirm}
                  />
                </div>

                <div className="pb-5 ">
                  <SelectFormInput
                    title="Internal Reason for referral"
                    listItems={[
                      'Incident Type',
                      'Damages',
                      'Liability',
                      'Coverage',
                      'Conflict',
                      'Other',
                    ]}
                    defaultValue={caseData?.referToReason}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdate('referToReason', target.value);
                    }}
                    {...form.register('referToReason')}
                  />
                </div>

                <TextFormInput
                  {...form.register('referToReasonDescription')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('referToReasonDescription', target.value);
                  }}
                  title={'Details For Referral'}
                  type="textarea"
                />
                <div className="mt-4">
                  <RangeSlider
                    title={'Referral fee to be received'}
                    value={referredFee}
                    setValue={setReferredFee}
                    onAfterChange={onAfterChange}
                  />
                </div>
              </form>
            </Form>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ReferCase;
