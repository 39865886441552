import { cn, copyToClipboard, getAbsoluteUrl, getFullFileId } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { ChevronDownIcon, ChevronRightIcon, FolderOpenIcon } from '@heroicons/react/24/outline';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { FileExplorerContext } from './FileExplorerProvider';
import FileGridItemIcon from './FileGridItemIcon';

interface FileTreeNodeProps {
  item: FileResourceType;
  level: number;
  selected?: string;
  setSelected?: Dispatch<SetStateAction<string>>;
  onFolderClick?: (item: FileResourceType) => void;
}

export const FileTreeNode: FC<FileTreeNodeProps> = (props) => {
  const { item, level, selected, setSelected, onFolderClick } = props;

  const { setIsFilePreviewModalOpen, setFilePreviewItem, currentFolder } =
    useContext(FileExplorerContext);
  const isCurrentFolder = currentFolder?.resourceId === item.resourceId;

  const [isExpanded, setIsExpanded] = useState(false);

  const isDirectory = item.resourceType === 'DIR';
  const isFile = item.resourceType === 'FILE';
  const isLink = item.resourceType === 'LINK';
  const fullItemId = getFullFileId(item);

  const toggleExpand = (expanded: boolean) => {
    setIsExpanded(expanded);
  };

  useEffect(() => {
    if (isCurrentFolder) {
      setIsExpanded(true);
    }
  }, [isCurrentFolder]);

  return (
    <div>
      <div
        className={cn('flex items-center justify-between select-none')}
        style={{ paddingLeft: level * 16 }}
      >
        <div
          className={cn(
            'flex items-center w-full px-1 overflow-hidden rounded-md  py-1 mr-2',
            isDirectory && 'font-semibold hover:bg-gray-200',
            (isFile || isLink) && 'cursor-pointer',
            isCurrentFolder && 'bg-light-blue',
          )}
        >
          {isDirectory &&
            (isExpanded ? (
              <ChevronDownIcon
                className="h-5 cursor-pointer min-w-5"
                onClick={() => toggleExpand(false)}
              />
            ) : (
              <ChevronRightIcon
                className="h-5 cursor-pointer min-w-5"
                onClick={() => toggleExpand(true)}
              />
            ))}
          <div
            className="flex"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (isDirectory && onFolderClick) {
                onFolderClick(item);
              }
              if (isFile) {
                setFilePreviewItem(item);
                setIsFilePreviewModalOpen(true);
              }
              if (isLink && item.urlRefLink?.length && item.urlRefLink.length > 0) {
                const absoluteUrl = getAbsoluteUrl(item.urlRefLink ?? '');
                copyToClipboard(absoluteUrl);
              }
            }}
          >
            {isDirectory && isExpanded ? (
              <FolderOpenIcon className="h-5 size-5 min-w-5 text-atlantic-blue" />
            ) : (
              <FileGridItemIcon item={item} className="w-5 h-5 mx-0 min-w-5 size-5" />
            )}
            <span className={cn('flex flex-shrink w-40 ml-2 truncate')}>{item.name}</span>
          </div>
        </div>
        {setSelected && isDirectory && (
          <input
            type="checkbox"
            checked={selected === fullItemId}
            onChange={(e) => {
              e.stopPropagation();
              if (e.target.checked) {
                setSelected(fullItemId);
              } else {
                setSelected('');
              }
            }}
            className="z-50 mr-2 rounded form-checkbox"
          />
        )}
      </div>

      {isExpanded &&
        isDirectory &&
        (item.children && item.children.length > 0 ? (
          item.children?.map((child) => (
            <FileTreeNode
              onFolderClick={onFolderClick}
              key={`${child.parentResourceId}-${child.resourceId}`}
              item={child}
              level={level + 1}
              selected={selected ? selected : undefined}
              setSelected={setSelected ? setSelected : undefined}
            />
          ))
        ) : (
          <div className="text-xs text-gray-600" style={{ marginLeft: (level + 1) * 16 }}>
            This folder is empty.
          </div>
        ))}
    </div>
  );
};
