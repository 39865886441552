import {
  CommunicationType,
  DefaultV3Response,
  EmailType,
  ManualMessageType,
  MessageSnippetType,
  MessageType,
  PhoneNumberInfo,
  ProvisionedPhoneType,
  SendMessagePayload,
  SendMessageRes,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const getCommunicationsForFirmV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CommunicationType[]>>(`/nuntius/${API_STAGE}/v3/contacts/communications`)
    .then((res) => res.data);

export const getCommunicationsForContact = (contactId: string) =>
  axiosInstance
    .get<DefaultV3Response<CommunicationType>>(
      `/nuntius/${API_STAGE}/v3/contacts/${contactId}/communications`,
    )
    .then((res) => res.data);

export const getCommunicationsForContacts = (contactIds: string[]) =>
  axiosInstance
    .post<DefaultV3Response<CommunicationType[]>>(
      `/nuntius/${API_STAGE}/v3/contacts/communications`,
      { contactIds },
    )
    .then((res) => res.data);

export const getEmailsForCase = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<EmailType[]>>(`/nuntius/${API_STAGE}/v3/emails/cases/${caseId}`)
    .then((res) => res.data);

export const updateEmail = async (data: ManualMessageType) => {
  const { emailId, ...rest } = data;
  const res = await axiosInstance.patch<DefaultV3Response<EmailType[]>>(
    `/nuntius/${API_STAGE}/v3/emails/${emailId}`,
    rest,
  );
  return res.data;
};

// GET messages_v3
export const getMessagesForCommunication = (communicationId: string) =>
  axiosInstance
    .get<DefaultV3Response<MessageType[]>>(
      `/nuntius/${API_STAGE}/v3/contacts/communications/${communicationId}/messages`,
    )
    .then((res) => res.data);

export const checkAvailablePhoneNumbersV3 = (areaCode: string) =>
  axiosInstance
    .post<DefaultV3Response<PhoneNumberInfo[]>>(
      `/nuntius/${API_STAGE}/v3/available-phone-numbers`,
      { areaCode },
    )
    .then((res) => res.data);

// POST provision_phone_number_v3
export const provisionPhoneNumberV3 = (data: { phoneNumber: string; phoneNumberType: string }) =>
  axiosInstance
    .post<DefaultV3Response<any>>(`/nuntius/${API_STAGE}/v3/provisioned-phone-numbers`, data)
    .then((res) => res.data);

// GET provisioned_phone_numbers_v3
export const getProvisionedPhoneNumbersV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ProvisionedPhoneType[]>>(
      `/nuntius/${API_STAGE}/v3/provisioned-phone-numbers`,
    )
    .then((res) => res.data);

export const sendMessageV3 = (data: SendMessagePayload) =>
  axiosInstance.post<DefaultV3Response<SendMessageRes>>(`/nuntius/${API_STAGE}/v3/send-sms`, data);

export const createManualCommunicationV3 = (data: ManualMessageType, type: 'messages' | 'calls') =>
  axiosInstance.post(`/nuntius/${API_STAGE}/v3/contacts/communications/${type}`, data);

export const createEmail = (data: ManualMessageType, caseId: string) =>
  axiosInstance.post(`/nuntius/${API_STAGE}/v3/emails/cases/${caseId}`, data);

export const updateManualCommunicationV3 = (
  data: ManualMessageType,
  type: 'messages' | 'calls' | 'emails' | 'unknown',
) => {
  const id = data?.messageId || data?.callId || data?.emailId;
  switch (type) {
    case 'messages':
      delete data.messageId;
      break;
    case 'calls':
      delete data.callId;
      break;
    case 'emails':
      delete data.emailId;
      break;
  }

  return axiosInstance.patch(
    `/nuntius/${API_STAGE}/v3/contacts/communications/${type}/${id}`,
    data,
  );
};

export const deleteManualCommunicationV3 = (data: ManualMessageType, type: string) => {
  const id = data?.messageId || data?.callId || data?.emailId;
  return axiosInstance.delete(`/nuntius/${API_STAGE}/v3/contacts/communications/${type}/${id}`);
};

export const deleteEmail = (emailId: string) =>
  axiosInstance.delete(`/nuntius/${API_STAGE}/v3/emails/${emailId}`);

const snippetRoute = `/nuntius/${API_STAGE}/v3/snippets`;

// POST snippets_v3
export const createMessageSnippetV3 = (data: MessageSnippetType) =>
  axiosInstance
    .post<DefaultV3Response<MessageSnippetType>>(snippetRoute, data)
    .then((res) => res.data);

// GET snippets_v3
export const getMessageSnippetsV3 = () =>
  axiosInstance.get<DefaultV3Response<MessageSnippetType[]>>(snippetRoute).then((res) => res.data);

// PATCH snippets_v3
export const updateMessageSnippetV3 = (data: MessageSnippetType) => {
  const { snippetId, ...rest } = data;

  return axiosInstance.patch<DefaultV3Response<MessageSnippetType>>(
    `${snippetRoute}/${snippetId}`,
    rest,
  );
};

// DELETE snippets_v3
export const deleteMessageSnippetV3 = (snippetId: string) =>
  axiosInstance
    .delete<DefaultV3Response<MessageSnippetType>>(`${snippetRoute}/${snippetId}`)
    .then((res) => res.data);
