import { findFolderPathFromRoot, useGetExhaustiveFileResource } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { useParams } from 'react-router';

export type FileExplorerContextType = {
  currentFolder?: FileResourceType | undefined;
  filePreviewItem?: FileResourceType;
  isFilePreviewModalOpen: boolean;
  isRootFolderDataFetching: boolean;
  isRootFolderDataLoading: boolean;
  rootFolder?: FileResourceType | undefined;
  selectedFiles: FileResourceType[];
  setCurrentFolderId: Dispatch<SetStateAction<string | undefined>>;
  setFilePreviewItem: Dispatch<SetStateAction<FileResourceType | undefined>>;
  setIsFilePreviewModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedFiles: Dispatch<SetStateAction<FileResourceType[]>>;
  movingFiles: FileResourceType[];
  setMovingFiles: Dispatch<SetStateAction<FileResourceType[]>>;
  deletingFiles: FileResourceType[];
  setDeletingFiles: Dispatch<SetStateAction<FileResourceType[]>>;
};

export const FileExplorerContext = createContext<FileExplorerContextType>({
  // default values
  isFilePreviewModalOpen: false,
  isRootFolderDataLoading: false,
  isRootFolderDataFetching: false,
  setIsFilePreviewModalOpen: () => {
    //noop
  },
  filePreviewItem: undefined,
  setFilePreviewItem: () => {
    //noop
  },
  rootFolder: undefined,
  selectedFiles: [],
  setSelectedFiles: () => {
    //noop
  },
  currentFolder: undefined,
  setCurrentFolderId: () => {
    //noop
  },
  movingFiles: [],
  setMovingFiles: () => {
    //noop
  },
  deletingFiles: [],
  setDeletingFiles: () => {
    //noop
  },
});

const FileExplorerProvider = ({
  children,
  caseId,
}: {
  children?: React.ReactNode;
  caseId: string;
}) => {
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
  const [filePreviewItem, setFilePreviewItem] = useState<FileResourceType>();
  const [selectedFiles, setSelectedFiles] = useState<FileResourceType[]>([]);
  const [currentFolderId, setCurrentFolderId] = useState<string | undefined>();
  const [deletingFiles, setDeletingFiles] = useState<FileResourceType[]>([]);
  const [movingFiles, setMovingFiles] = useState<FileResourceType[]>([]);
  const {
    data: rootFolderData,
    isLoading: isRootFolderDataLoading,
    isFetching: isRootFolderDataFetching,
  } = useGetExhaustiveFileResource(caseId);
  const currentFolder = findFolderPathFromRoot(
    {
      children: [rootFolderData?.data ?? {}],
    },
    currentFolderId,
  )?.slice(-2, -1)[0];

  const rootFolder = rootFolderData?.data;
  return (
    <FileExplorerContext.Provider
      value={{
        isFilePreviewModalOpen,
        setIsFilePreviewModalOpen,
        filePreviewItem,
        setFilePreviewItem,
        rootFolder,
        selectedFiles,
        setSelectedFiles,
        isRootFolderDataLoading,
        isRootFolderDataFetching,
        currentFolder,
        setCurrentFolderId,
        movingFiles,
        setMovingFiles,
        deletingFiles,
        setDeletingFiles,
      }}
    >
      {children}
    </FileExplorerContext.Provider>
  );
};

export { FileExplorerProvider };
