import { CaseContactConnectionType, CaseType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { uniq } from 'lodash';
import {
  renderCell,
  renderCellWithChips,
  renderHeader,
} from '../shadcn/data-table/table-render-helpers';

export const columns: ColumnDef<CaseContactConnectionType & { case?: CaseType }>[] = [
  {
    accessorFn: (row) => {
      return row.case?.caseTitle;
    },
    id: 'Case Title',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return uniq(row.roles.map((role) => role.roleOnCase)).join(', ');
    },
    id: 'Roles',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) => {
      return renderCellWithChips({ props, showParty: true });
    },
  },
];
