import { CaseCompleteType, FirmSurveyType } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { FirmSurveysList } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<FirmSurveysList>[] = [
  {
    accessorFn: (row) => row.typeform.title,
    id: 'Title',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
