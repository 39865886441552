import { cn, usePermissions } from '@colosseum/data';
import {
  CustomErrorBoundary,
  Tabs,
  TabsList,
  tabsListClass,
  TabsTrigger,
  tabTriggerClass,
  Typography,
} from '@colosseum/shared-ui';
import { TabsContent } from '@radix-ui/react-tabs';
import { withErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import ExpenseLineupsTable from './ExpenseLineupsTable/ExpenseLineupsTable';
import RecordRequestTasksLineupsTable from './RecordRequestTasksLineupsTable/RecordRequestTasksLineupsTable';

export function LineupsPage() {
  const { hasServiceAccess } = usePermissions();
  const defaultTab = hasServiceAccess('lineUpFirmCaseExpenses')
    ? 'expenses'
    : 'record-request-tasks';
  return (
    <>
      <Helmet defer={false}>
        <title>Lineups</title>
      </Helmet>

      <div
        className="relative pt-4 mt-5 bg-white border shadow-sm rounded-xl"
        data-cy="cases-list-view"
      >
        <div className="flex w-full px-4 mb-5">
          <div className="w-full">
            <div className="flex flex-wrap items-end justify-between">
              <Typography tag="h1" variant="pageHeading">
                Lineups
              </Typography>
            </div>
            <Tabs defaultValue={defaultTab}>
              <TabsList id="firm-lineups-tabs" className={cn(tabsListClass, 'my-4')}>
                {hasServiceAccess('lineUpFirmCaseExpenses') && (
                  <TabsTrigger
                    className={tabTriggerClass}
                    key="expenses"
                    value="expenses"
                    data-cy="firm-case-expenses"
                  >
                    Expenses
                  </TabsTrigger>
                )}
                {hasServiceAccess('lineUpTasks') && (
                  <TabsTrigger
                    className={tabTriggerClass}
                    key="record-request-tasks"
                    value="record-request-tasks"
                    data-cy="firm-record-request-tasks"
                  >
                    Record Request Tasks
                  </TabsTrigger>
                )}
              </TabsList>
              {hasServiceAccess('lineUpFirmCaseExpenses') && (
                <TabsContent value="expenses">
                  <ExpenseLineupsTable />
                </TabsContent>
              )}
              {hasServiceAccess('lineUpTasks') && (
                <TabsContent value="record-request-tasks">
                  <RecordRequestTasksLineupsTable />
                </TabsContent>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
export default withErrorBoundary(LineupsPage, { fallback: CustomErrorBoundary });
