import { CompleteLienType, formatPrice } from '@colosseum/data';
import { LienType, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';
import CaseContactConnectionLinker from '../CaseContactConnectionLinker/CaseContactConnectionLinker';
import ClientSelectDropdown from '../ClientSelectDropdown/ClientSelectDropdown';
import Toggle from '../Toggle/Toggle';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface LienFormProps {
  updateHandler: any;
  lien: CompleteLienType;
  caseId: string;
  typing: boolean;
  setTyping: any;
}

const formSchema = z.object({
  // lienId: z.string().optional(),
  filingDetails: z.string().optional(),
  caseContactConnectionId: z.string().optional(),
  balanceVerifiedDate: z.string().optional(),
});

//TODO this component is a mess and needs major rework to be more readable and maintainable
export function LienForm(props: LienFormProps) {
  const queryClient = useQueryClient();
  const { lien, caseId } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...props.lien,
    },
  });

  async function handleUpdate(value: string | number, key: string) {
    await props.updateHandler(value, key, props.lien.lienId);
  }

  function handleUpdateNumber(value: any, key: string) {
    // strip value of currency formatting
    value = value.replace(/[^0-9.-]+/g, '');

    const temp = parseFloat(value);
    props.updateHandler(temp, key, props.lien.lienId);
  }

  function handleUpdateBool(value: '1' | '0', key: string) {
    props.updateHandler(value, key, props.lien.lienId);
  }

  const renderNumberInput = ({
    key,
    label,
    decimals,
    prefix,
  }: {
    key: string;
    label: string;
    decimals?: number;
    prefix?: string;
  }) => {
    const decimalsToShow = decimals ?? 0;
    return (
      <div className="relative w-full p-3 border border-gray-300 rounded-md">
        <div className="flex items-center">
          {prefix && <span className="mr-2 text-sm text-gray-500">{prefix}</span>}
          <NumericFormat
            className="w-full bg-white focus:outline-none"
            value={props.lien?.[key as keyof LienType]?.toLocaleString('en-US', {
              minimumFractionDigits: decimalsToShow,
            })}
            onBlur={(e) => {
              props.setTyping(false);
              handleUpdateNumber(e.target.value, key);
            }}
            onChange={(e) => {
              props.setTyping(true);
            }}
            decimalScale={decimalsToShow}
            thousandSeparator=","
            allowNegative={false}
          />
          <div className="absolute px-1 text-xs text-gray-400 bg-white rounded-md -top-2 left-2">
            {label}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Form {...form}>
        <form>
          <ClientSelectDropdown
            caseId={caseId}
            form={form}
            caseItemType="lien"
            caseItemId={lien?.lienId}
          />
          <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
            <div className="relative w-full col-span-2 mt-1 ml-1 rounded-md">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Lien Holder"
                roleOnCase={caseContactConnectionOptions.lienHolder}
                itemType="lien"
                itemId={props.lien.lienId}
              />
            </div>
            <div className="relative w-full col-span-2 mt-1 ml-1 rounded-md">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Lien Provider"
                roleOnCase={caseContactConnectionOptions.lienProvider}
                itemType="lien"
                itemId={props.lien.lienId}
              />
            </div>
            <div className="relative w-full col-span-2 ml-1 rounded-md">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Billing Entity"
                roleOnCase={caseContactConnectionOptions.billingEntity}
                itemType="lien"
                itemId={props.lien.lienId}
              />
            </div>
            <div className="relative flex items-center justify-between w-full pr-1 text-sm min-h-[50px]">
              <p> Filed?</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleUpdateBool(props.lien?.filed === '1' ? '0' : '1', 'filed');
                }}
              >
                <Toggle editable={true} newState={props.lien.filed === '1'} />
              </div>
            </div>
            <div></div>
            <div className="col-span-2">
              <TextFormInput
                {...form.register('filingDetails')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate(target.value, 'filingDetails');
                }}
                title="Filing Details"
                type="textarea"
              />
            </div>

            <div className="">
              {renderNumberInput({
                key: 'lienAmount',
                label: 'Lien Amount',
                decimals: 2,
                prefix: '$',
              })}
            </div>
            <div></div>
            <div>
              <>
                <span className="text-gray-500">Reduction:</span>
                {props.lien.reductionAmount &&
                props.lien.reductionPercentage &&
                props.lien.reductionAmount > 0
                  ? ` ${formatPrice(
                      props.lien.reductionAmount,
                    )} (${props.lien.reductionPercentage.toFixed(2)}%)`
                  : ' N/A'}
              </>
            </div>
            <div></div>
            {renderNumberInput({
              key: 'finalBalance',
              label: 'Final Balance',
              decimals: 2,
              prefix: '$',
            })}
            <div></div>
            <div className="relative flex items-center justify-between w-full pr-1 text-sm min-h-[50px]">
              <p> Accepted?</p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleUpdateBool(props.lien?.accepted === '1' ? '0' : '1', 'accepted');
                }}
              >
                <Toggle editable={true} newState={props.lien.accepted === '1'} />
              </div>
            </div>
            <CalendarFormInput
              {...form.register(`balanceVerifiedDate`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate(target.value, 'balanceVerifiedDate');
              }}
              title="Date Verified"
              resourceTypeObj={{
                type: 'lien',
                id: props.lien?.balanceVerifiedDate,
              }}
            />
          </div>
        </form>
      </Form>
    </div>
  );
}

export default LienForm;
