import { useGetSelectedCases } from '@colosseum/data';
import { CustomAttributeType, CustomAttributeValueType } from '@gladiate/types';
import { LinkIcon } from 'lucide-react';
import AllCasesDropdown from '../AllCasesDropdown/AllCasesDropdown';
import BubbleItem from '../BubbleItem/BubbleItem';

export interface CustomAtributeCaseSelectProps {
  customAttributeValue: CustomAttributeValueType & CustomAttributeType;
  updateValue: (data: string) => void;
  deleteValue: (caseId: string) => void;
}

export function CustomAtributeCaseSelect(props: CustomAtributeCaseSelectProps) {
  const { customAttributeValue, updateValue, deleteValue } = props;
  const { data: selectedCasesData } = useGetSelectedCases(
    customAttributeValue.attributeMultiValue ?? [],
  );
  const selectedCases = selectedCasesData?.data ?? [];

  return (
    <div className="flex flex-col gap-y-2">
      {customAttributeValue.attributeLabel}
      <div className="flex flex-row flex-wrap gap-x-2">
        {selectedCases?.map((c) => (
          <BubbleItem
            key={c.caseId}
            text={c.caseTitle ?? '-'}
            icon={<LinkIcon className="w-4 h-4 mr-1" />}
            onDelete={(e: any) => {
              e.preventDefault();
              deleteValue(c.caseId);
            }}
            href={`/cases/${c.caseId}`}
          />
        ))}
        <AllCasesDropdown
          isLoading={false}
          onSelectCase={updateValue}
          hideCreateOption
        ></AllCasesDropdown>
      </div>
    </div>
  );
}

export default CustomAtributeCaseSelect;
