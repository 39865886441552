import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createTemplateV3,
  deleteTemplateV3,
  getTemplateV3,
  getTemplatesV3,
  updateTemplateV3,
} from '../requests/exemplar';

export const useGetTemplate = (templateId: string) => {
  return useQuery({
    queryKey: [queryKeys.templates, templateId],
    queryFn: async () => getTemplateV3(templateId),
    enabled: !!templateId,
  });
};

export const useGetTemplates = () => {
  return useQuery({
    queryKey: [queryKeys.templates],
    queryFn: async () => getTemplatesV3(),
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateTemplateV3>[0]) => updateTemplateV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.templates],
      });
    },
    onError: (_error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: 'There was an error updating the template.',
        key: 'update-template-error',
        variant: 'error',
      });
    },
  });
};

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createTemplateV3>[0]) => createTemplateV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.templates],
      });
    },
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (templateId: string) => deleteTemplateV3(templateId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.templates],
      });
    },
  });
};
