import { useUploadFileForPreview } from '@colosseum/data';
import { ArrowTopRightOnSquareIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { DownloadIcon } from '@radix-ui/react-icons';
import DOMPurify from 'dompurify';
import { Attachment, Email } from 'postal-mime';
import { useEffect, useState } from 'react';
import SparkMD5 from 'spark-md5';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Modal from '../shadcn/Modal/Modal';
import ShimmerSkeletonWrapper from '../ShimmerSkeletonWrapper/ShimmerSkeletonWrapper';
import Typography from '../Typography/Typography';

const EmailContentSkeleton = () => (
  <ShimmerSkeletonWrapper>
    <div className="flex flex-col gap-4 h-[550px] border-2 rounded-xl p-3">
      <div className="flex flex-col h-24 gap-4 p-2 bg-gray-300 rounded-md w-36">
        <div className="w-full h-2 bg-gray-100 rounded-md"></div>
        <div className="w-full h-2 bg-gray-100 rounded-md"></div>
        <div className="w-full h-2 bg-gray-100 rounded-md"></div>
      </div>
      <div className="h-4 bg-gray-100 rounded-md w-4/4"></div>
      <div className="bg-gray-200 rounded-md h-36 w-4/4"></div>
      <div className="bg-gray-200 rounded-md h-36 w-4/4"></div>
      <div className="bg-gray-200 rounded-md h-36 w-4/4"></div>

      <div className="w-1/4 h-2 bg-gray-300 rounded-md"></div>
    </div>
  </ShimmerSkeletonWrapper>
);

const EmailAttachment = (item: Attachment) => {
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [microsoftFileUrl, setMicrosoftFileUrl] = useState('');

  const uploadFile = useUploadFileForPreview();

  const isMicrosoftFile =
    item.filename?.includes('.doc') ||
    item.filename?.includes('.xls') ||
    item.filename?.includes('.ppt');

  const downloadFile = (item: Attachment) => {
    const blob = new Blob([item.content]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = item.filename || 'download';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const fileUrl = URL.createObjectURL(new Blob([item.content], { type: item.mimeType }));

  const Thumbnail = () => {
    if (item.mimeType.includes('image')) {
      return (
        <img
          src={fileUrl}
          alt={item.filename || 'image'}
          className="object-scale-down border-2 rounded-md h-7 w-7"
        />
      );
    }
    return <DocumentIcon className="w-5 h-5" />;
  };

  useEffect(() => {
    if (isMicrosoftFile) {
      // if this is a Microsoft file, we need to upload it to S3 to get a public preview link from the BE in order
      // to use the Microsoft Office Viewer Preview iframe. We save s3 object key in local storage to avoid re-uploading
      const spark = new SparkMD5.ArrayBuffer();
      spark.append(item.content);
      const hash = spark.end();
      const existingS3ObjKey = window.localStorage.getItem(hash);

      uploadFile
        .mutateAsync({
          rawFile: new File([item.content], item.filename || ''),
          objKey: existingS3ObjKey ?? '',
        })
        .then((res) => {
          item.content && hash && window.localStorage.setItem(hash, res.data.objKey);
          setMicrosoftFileUrl(res.data.previewUrl);
        });
    }
  }, []);

  return (
    <>
      <Modal
        width="w-[80vw]"
        open={showFilePreview}
        setOpen={setShowFilePreview}
        title={`${item?.filename} Preview`}
        primaryButton={{
          label: 'Download',
          className:
            'text-white bg-sky-blue hover:bg-light-blue hover:text-atlantic-blue focus:shadow-atlantic-blue inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]',
          onClick: () => {
            if (item) {
              downloadFile(item);
            }
          },
        }}
        secondaryButton={{
          label: 'Cancel',
          className:
            'text-white bg-sky-blue hover:bg-light-blue hover:text-atlantic-blue focus:shadow-atlantic-blue inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]',
          onClick: () => {
            setShowFilePreview(false);
          },
        }}
        hideCancel={true}
      >
        <div className="flex justify-center">
          {isMicrosoftFile ? (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${microsoftFileUrl}`}
              title="file-preview"
              className="h-[60vh] w-[100vw] m-4"
            />
          ) : (
            <embed
              src={fileUrl}
              type={item.mimeType}
              className={
                item.mimeType.includes('image')
                  ? 'h-[60vh] w-[60vw] m-4 object-scale-down'
                  : 'h-[60vh] w-[60vw] m-4'
              }
              width="100%"
              height="100%"
            />
          )}
        </div>
      </Modal>
      <div className="self-center px-1">
        <div className="flex items-center w-fit">
          <div
            onClick={() => {
              setShowFilePreview(true);
            }}
            className="flex items-center gap-1 p-1 rounded-sm hover:cursor-pointer hover:bg-blue-50 hover:rounded-md"
          >
            <Typography variant="link">
              <Thumbnail />
            </Typography>
            <Typography variant="link">{item.filename}</Typography>
          </div>
          <div
            onClick={() => downloadFile(item)}
            className="p-1 hover:cursor-pointer hover:bg-blue-50 hover:rounded-md"
          >
            <Typography variant="link">
              <DownloadIcon className="w-5 h-5" />
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

// const ExternalAttachment = (item: {
//   filename: string;
//   link: string;
//   html: string;
// }) => {
//   const Thumbnail = () => {
//     return <DocumentIcon className="w-5 h-5" />;
//   };

//   const downloadFile = () => {
//     try {
//       const link = document.createElement('a');
//       link.innerHTML = item.html;

//       link.click();
//     } catch (error) {
//       console.error('Error downloading file:', error);
//     }
//   };

//   return (
//     <>
//       <div className="self-center px-1">
//         <div className="flex items-center w-fit">
//           <div
//             onClick={() => {
//               // setShowFilePreview(true);
//             }}
//             className="flex flex-row items-center gap-1 p-1 rounded-sm hover:cursor-pointer hover:bg-blue-50 hover:rounded-md"
//           >
//             <Typography variant="link">
//               <div className="flex gap-1">
//                 <Thumbnail />
//                 <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
//                 <ArrowTopRightOnSquareIcon className="w-5 h-5" />
//               </div>
//             </Typography>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// function extractLinksFromHTML(html: string, keywords: string[]): [] {
//   const regex = /<a\s+(?:title="[^"]*"\s+)?href="([^"]+)"[^>]*>(.*?)<\/a>/gi;

//   const matches = html.match(regex) || [];
//   const externalAttachments: any = [];

//   matches.forEach((mtc) => {
//     if (keywords.some((keyword) => mtc?.includes(keyword))) {
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = mtc;

//       const anchorTag = tempDiv.querySelectorAll('a')[0];
//       const children = anchorTag.childNodes;

//       const filename = anchorTag.textContent?.trim();

//       const href = mtc.match(/href="([^"]*)"/g);
//       const link = href?.[0].replace('href="', '').replace('"', '');

//       anchorTag.removeAttribute('style');
//       children.forEach((child) => {
//         if (child.nodeName === 'IMG') {
//           anchorTag.removeChild(child);
//         }
//       });
//       externalAttachments.push({
//         filename,
//         link,
//         html: (document.createElement('div').innerHTML = anchorTag.outerHTML),
//       });
//     }
//   });
//   return externalAttachments;
// }

export function EmailPreview(props: { data: Email | undefined; dataIsLoading: boolean }) {
  const { data, dataIsLoading } = props;
  const [emailHtmlContent, setEmailHtmlContent] = useState(data?.html);

  // const externalDocumentLinks = extractLinksFromHTML(data?.html || '', [
  //   'nefdocuments',
  //   'drive.google',
  //   '1drv.ms',
  // ]);

  useEffect(() => {
    setEmailHtmlContent(data?.html);
    return () => {
      setEmailHtmlContent(undefined);
    };
  }, [data, dataIsLoading]);

  useEffect(() => {
    const highlightAnchorStyle = (anchorTag: HTMLAnchorElement) => {
      anchorTag.style.color = 'rgb(0, 102, 204)';
      anchorTag.style.textDecoration = 'underline';
      anchorTag.style.cursor = 'pointer';
      anchorTag.style.padding = '3px';
      anchorTag.style.border = '3px solid rgb(0, 102, 204)';
      anchorTag.style.borderRadius = '5px';
    };

    const document2 = emailHtmlContent && document.createElement('html');
    if (document2) {
      document2.innerHTML = emailHtmlContent || '';
      const anchorTags = document2.querySelectorAll('a');
      anchorTags.forEach((anchorTag) => {
        if (!anchorTag.innerText.includes('@')) {
          const tempAnchor = document.createElement('a');
          highlightAnchorStyle(tempAnchor);
          tempAnchor.innerHTML = anchorTag.innerHTML;
          tempAnchor.href = anchorTag.href;
          tempAnchor.title = anchorTag.title;
          anchorTag.replaceWith(tempAnchor);
        }
      });

      setEmailHtmlContent(document2.innerHTML);
    }
  }, [emailHtmlContent]);

  if (!data && !dataIsLoading) {
    return <div>No content</div>;
  }

  return (
    <div style={{ whiteSpace: 'pre-line' }} className="flex flex-col w-full bg-white ">
      <div className="py-4 bg-white">
        <Typography>
          From:{' '}
          {dataIsLoading ? (
            <>Loading ...</>
          ) : (
            <>{data?.from && `${data?.from?.name || ''} (${data?.from?.address || ''})`}</>
          )}
        </Typography>
        <Typography>
          Date:{' '}
          {dataIsLoading ? (
            <> Loading ... </>
          ) : (
            <>{data?.date && new Date(data?.date || '').toLocaleDateString()}</>
          )}
        </Typography>
        <Typography>
          Subject:
          {dataIsLoading ? <> Loading ... </> : <>{data?.subject || 'No Subject'}</>}
        </Typography>
        <Typography>
          To:{' '}
          {dataIsLoading ? (
            <> Loading ... </>
          ) : (
            <>{data?.to && `${data?.to?.map((item) => item.address)?.join(', ')}`}</>
          )}
        </Typography>
        <div className="flex gap-1">
          <Typography>Attachments: </Typography>
          <div className="flex flex-wrap">
            {dataIsLoading && 'Loading ...'}
            {!dataIsLoading && data?.attachments?.length === 0 && (
              <Typography>No Attachments</Typography>
            )}
            {!dataIsLoading &&
              data?.attachments?.map((item) => <EmailAttachment key={item.filename} {...item} />)}
          </div>
        </div>
        {/* <div className="flex gap-1">
          <Typography>External URLs: </Typography>
          <div className="flex flex-wrap">
            {dataIsLoading && 'Loading ...'}
            {!dataIsLoading && externalDocumentLinks?.length === 0 && (
              <Typography>No External URLs</Typography>
            )}
            {!dataIsLoading &&
              externalDocumentLinks.map((item) => (
                <ExternalAttachment
                  key={item.filename}
                  filename={item?.filename}
                  link={item?.link}
                  html={item?.html}
                />
              ))}
          </div>
        </div> */}
      </div>
      {dataIsLoading && <EmailContentSkeleton />}
      {!dataIsLoading && (
        <div className="flex-grow px-2 py-4 overflow-auto border-2 border-solid rounded-md bg-slate-50">
          {data?.html ? (
            <div>
              <div
                className="w-full "
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(emailHtmlContent || ''),
                }}
              />
            </div>
          ) : (
            <GladiateLoader />
          )}
        </div>
      )}
    </div>
  );
}
