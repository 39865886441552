import { useUpdateEventCategory } from '@colosseum/data';
import { Form, TextFormInput } from '@colosseum/shared-ui';
import { EventCategoryType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

/* eslint-disable-next-line */
export interface EventCategoriesFormProps {
  category?: EventCategoryType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EventCategoriesForm(props: EventCategoriesFormProps) {
  const { category, setTyping } = props;

  const updateEventCategory = useUpdateEventCategory();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      title: category?.title || '',
      description: category?.description || '',
    },
  });

  const handleUpdate = (key: keyof Pick<EventCategoryType, 'title' | 'description'>) => {
    const value = form.getValues(key);
    if (category?.categoryId && form.getFieldState(key).isDirty) {
      updateEventCategory
        .mutateAsync({
          categoryId: category.categoryId,
          [key]: value,
        })
        .then(() => setTyping(false));
    }
  };
  return (
    <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
      <Form {...form}>
        <TextFormInput
          {...form.register('title')}
          title="Title"
          handleOnBlur={() => handleUpdate('title')}
        />
        <TextFormInput
          {...form.register('description')}
          title="Description"
          type="textarea"
          handleOnBlur={() => handleUpdate('description')}
        />
      </Form>
    </div>
  );
}

export default EventCategoriesForm;
