import { displayContactName, prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader, stringIncludesFilterFn } from '@colosseum/shared-ui';
import { ContactType, RecordRequest } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const requestColumns: ColumnDef<
  | (RecordRequest & {
      recordsProvider: ContactType | undefined;
      client: ContactType | undefined;
      requestingUser: string | undefined;
    })
  | undefined
>[] = [
  {
    accessorFn: (row) => displayContactName(row?.recordsProvider),
    id: 'Records Provider',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => displayContactName(row?.client),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row?.requestedInformation,
    id: 'Requested Information',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({
        props,
        valueModifierFunc: (value: string) =>
          value.length > 40 ? value.substring(0, 40) + '...' : value,
      });
    },
  },
  {
    accessorFn: (row) => (row?.arctrievalRequestId ? 'Arctrieval' : 'Manual'),
    filterFn: stringIncludesFilterFn,
    id: 'Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row?.requestingUser,
    id: 'Requesting User',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => startCase(row?.requestStatus),
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateCreated),
    id: 'Date Sent',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateSubmitted),
    id: 'Date Submitted',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateReceived),
    id: 'Date Received',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateRequestRangeStart),
    id: 'Date Request Range Start',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateRequestRangeEnd),
    id: 'Date Request Range End',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
];
