import { cn, useGetS3FileDownloadLink } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { DocumentIcon, FolderIcon } from '@heroicons/react/24/outline';
import { LinkIcon } from '@heroicons/react/24/solid';
import { CSVIcon } from '../../svgs/CSVIcon';
import { DocIcon } from '../../svgs/DocIcon';
import { EMLIcon } from '../../svgs/EMLIcon';
import { MP3Icon } from '../../svgs/MP3Icon';
import { MP4Icon } from '../../svgs/MP4Icon';
import { PDFIcon } from '../../svgs/PDFIcon';
import { WavIcon } from '../../svgs/WavIcon';
import { ZIPIcon } from '../../svgs/ZIPIcon';

export interface FileGridItemIconProps {
  item: FileResourceType;
  filePreviewUrl?: string;
  isListView?: boolean;
  className?: string;
}

const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];

const iconMapping: {
  [key: string]: { icon: any; color: string };
} = {
  DIR: { icon: FolderIcon, color: 'text-atlantic-blue' },
  LINK: { icon: LinkIcon, color: 'text-light-blue' },
  default: { icon: DocumentIcon, color: 'text-light-blue' },
};

const ImageComponent = ({
  name,
  className,
  filePreviewUrl,
}: {
  name?: string;
  className?: string;
  filePreviewUrl?: { data: { previewUrl: string } };
}) => (
  <img
    src={filePreviewUrl?.data.previewUrl}
    alt={name}
    className={cn('object-cover rounded-md shadow-md gap-x-2', className)}
  />
);

export function FileGridItemIcon(props: FileGridItemIconProps) {
  const { item, isListView, className } = props;

  const fileExtension = item?.s3ObjKey?.split('.').pop() ?? '';
  const commonClasses = cn(isListView ? 'w-8 h-8' : 'w-12 h-12 mx-auto');

  const { data: filePreviewUrl } = useGetS3FileDownloadLink(item.s3ObjKey);

  switch (fileExtension) {
    case 'pdf':
      return <PDFIcon className={cn(commonClasses, className)} />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
    case 'webp':
      return (
        <ImageComponent
          name={item.name}
          className={cn(commonClasses, className)}
          filePreviewUrl={filePreviewUrl}
        />
      );
    case 'doc':
    case 'docx':
      return <DocIcon className={cn(commonClasses, className)} />;
    case 'eml':
      return <EMLIcon className={cn(commonClasses, className)} />;
    case 'mp4':
      return <MP4Icon className={cn(commonClasses, className)} />;
    case 'mp3':
      return <MP3Icon className={cn(commonClasses, className)} />;
    case 'csv':
      return <CSVIcon className={cn(commonClasses, className)} />;
    case 'wav':
      return <WavIcon className={cn(commonClasses, className)} />;
    case 'zip':
      return <ZIPIcon className={cn(commonClasses, className)} />;
    default:
      break;
  }

  const IconComponent = imageTypes.includes(fileExtension)
    ? ImageComponent
    : item.resourceType
    ? iconMapping[item.resourceType]?.icon ?? iconMapping.default.icon
    : iconMapping.default.icon;
  const iconColor = item.resourceType
    ? iconMapping[item.resourceType]?.color ?? iconMapping.default.color
    : iconMapping.default.color;
  return <IconComponent className={cn(iconColor, commonClasses, className)} />;
}

export default FileGridItemIcon;
