import {
  processCourtCsv,
  useCreateLitigationPlan,
  useCreateLitigationPlanAction,
  useDeleteLitigationPlan,
  useDeleteLitigationPlanAction,
  useGetLitigationPlans,
  useUpdateLitigationPlan,
} from '@colosseum/data';
import {
  CourtsTieredSelect,
  CreateButton,
  GladiateLoader,
  LabyrinthTextInput,
  LitigationActionForm,
  Slideover,
  Typography,
} from '@colosseum/shared-ui';
import { LabyrinthUpdateParams, LitigationPlanAction, LitigationPlanType } from '@gladiate/types';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { LitigationPlan } from '../LitigationPlan/LitigationPlan';

/* eslint-disable-next-line */
export interface LitigationPlansProps {}

export interface CreatePlanFormProps {
  litigationPlan: LitigationPlanType | undefined;
}

function PlanForm(props: CreatePlanFormProps) {
  const queryClient = useQueryClient();

  const updateLitigationPlan = useUpdateLitigationPlan();

  function handleOnChange(params: LabyrinthUpdateParams) {
    // do nothing
  }

  function handleOnBlur(params: LabyrinthUpdateParams) {
    updateLitigationPlan.mutate({
      litigationPlanId: props.litigationPlan?.litigationPlanId ?? '',
      [params.field.valueVariable]: params.value,
    });
  }

  return (
    <div className="w-full">
      <div className="text-lg font-semibold">Plan Details</div>
      <div className="grid grid-cols-2 pt-2 pb-2 mt-3 gap-y-5 gap-x-3">
        <div className="col-span-2">
          <LabyrinthTextInput
            fieldInfo={{
              title: 'Plan Title',
              type: 'textBox',
              valueVariable: 'title',
            }}
            fieldValue={props.litigationPlan?.title || ''}
            handleOnChange={handleOnChange}
            handleOnBlur={handleOnBlur}
          />
        </div>

        <div className="col-span-2">
          <CourtsTieredSelect
            fieldInfo={{
              title: 'Courts',
              type: 'tieredSelect',
              valueVariable: 'court',
            }}
            handleOnChange={handleOnBlur}
            courtData={processCourtCsv()}
            fieldValue={
              Array.isArray(props.litigationPlan?.court) ? props.litigationPlan?.court ?? [] : []
            }
          />
        </div>

        <LabyrinthTextInput
          fieldInfo={{
            title: 'Description',
            type: 'textArea',
            valueVariable: 'description',
          }}
          fieldValue={props.litigationPlan?.description || ''}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
        />
      </div>
    </div>
  );
}

export function LitigationPlans(props: LitigationPlansProps) {
  const queryClient = useQueryClient();
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [actionSlideoverOpen, setActionSlideoverOpen] = useState(false);
  const [slideoverTitle, setSlideoverTitle] = useState('');
  const [slideoverDescription, setSlideoverDescription] = useState('');
  const [selectedLitigationPlan, setSelectedLitigationPlan] = useState<LitigationPlanType>();
  const [selectedLitigationPlanAction, setSelectedLitigationPlanAction] =
    useState<LitigationPlanAction>();
  const [otherActions, setOtherActions] = useState<LitigationPlanAction[]>([]);
  const [createActionLoadingIndex, setCreateActionLoadingIndex] = useState(-1);

  const { data: litigationPlanData, isLoading: isLitigationPlansLoading } = useGetLitigationPlans();
  const litigationPlans =
    litigationPlanData?.data?.sort((a: LitigationPlanType, b: LitigationPlanType) => {
      const aDate = a.dateModified ? new Date(a.dateModified).getTime() : 0;
      const bDate = b.dateModified ? new Date(b.dateModified).getTime() : 0;

      return bDate - aDate;
    }) ?? [];

  const createLitigationPlan = useCreateLitigationPlan();
  const deleteLitigationPlan = useDeleteLitigationPlan();
  const deleteLitigationPlanAction = useDeleteLitigationPlanAction();
  const createAction = useCreateLitigationPlanAction();

  function clearAllSlideoverData() {
    setSelectedLitigationPlan(undefined);
    setSelectedLitigationPlanAction(undefined);
    setOtherActions([]);
  }

  const handlePlanClick = (plan: LitigationPlanType) => {
    setSelectedLitigationPlan(plan);
    setSlideoverTitle('Edit Litigation Plan');
    setSlideoverDescription('Edit key actions to create your unique litigation plan.');
    setSlideoverOpen(true);
  };

  const handleActionClick = (
    actions: LitigationPlanAction[],
    action: LitigationPlanAction,
    plan: LitigationPlanType,
  ) => {
    setSelectedLitigationPlanAction(action);
    setSelectedLitigationPlan(plan);
    setActionSlideoverOpen(true);
    setOtherActions(
      actions.filter((a) => a.litigationPlanActionId !== action.litigationPlanActionId),
    );
  };

  const handleCreateAction = (index: number, plan: LitigationPlanType) => {
    setCreateActionLoadingIndex(index);
    setSelectedLitigationPlan(plan);

    createAction
      .mutateAsync({
        litigationPlanId: plan.litigationPlanId,
      })
      .then((res) => {
        if (res.data) {
          setSelectedLitigationPlanAction({
            litigationPlanActionId: res?.data?.litigationPlanActionId,
          });
          setActionSlideoverOpen(true);
        }
        setCreateActionLoadingIndex(-1);
      });
  };

  return (
    <>
      <Slideover
        title={slideoverTitle}
        description={slideoverDescription}
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        displayDeleteButton={true}
        typing={false}
        deleteFunction={() => {
          deleteLitigationPlan.mutateAsync(selectedLitigationPlan?.litigationPlanId).then(() => {
            clearAllSlideoverData();
            setSlideoverOpen(false);
          });
        }}
      >
        <PlanForm litigationPlan={selectedLitigationPlan} />
      </Slideover>
      <Slideover
        title={'Add Action'}
        description={'Add an action to this litigation plan'}
        open={actionSlideoverOpen}
        setOpen={setActionSlideoverOpen}
        displayDeleteButton={true}
        typing={false}
        deleteFunction={() => {
          if (selectedLitigationPlanAction?.litigationPlanActionId) {
            deleteLitigationPlanAction
              .mutateAsync(selectedLitigationPlanAction?.litigationPlanActionId)
              .then(() => setActionSlideoverOpen(false));
          }
        }}
      >
        <LitigationActionForm
          litigationPlanAction={selectedLitigationPlanAction}
          otherActions={otherActions}
          caseAction={false}
        />
      </Slideover>
      <div>
        <div className="sm:flex sm:items-center" data-automations-submenu-item="Litigation Plans">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Litigation Plans</h1>
            <p className="mt-2 text-sm text-gray-700">
              Create litigation deadline plans that you can apply to help keep track of key
              deadlines for each case.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title="Add Plan"
              loading={createLitigationPlan.isLoading}
              onClick={() => {
                setSlideoverTitle('Create Litigation Plan');
                setSlideoverDescription(
                  'Add in key actions to create your unique litigation plan.',
                );
                createLitigationPlan
                  .mutateAsync({
                    title: 'New Litigation Plan',
                    description: 'New Litigation Plan Description',
                  })
                  .then(() => setSlideoverOpen(true));
              }}
            />
          </div>
        </div>
        {isLitigationPlansLoading ? (
          <div className="mt-5">
            <GladiateLoader />
          </div>
        ) : (
          <div className="mt-6 space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3">
            {litigationPlans?.map((plan: LitigationPlanType, index: number) => (
              <LitigationPlan
                key={plan.litigationPlanId}
                handlePlanClick={handlePlanClick}
                handleActionClick={handleActionClick}
                handleCreateAction={handleCreateAction}
                planIndex={index}
                plan={plan}
                isPlanLoading={isLitigationPlansLoading}
                isCreateActionLoading={createActionLoadingIndex === index}
              />
            ))}
          </div>
        )}
      </div>
      {!isLitigationPlansLoading && !litigationPlans?.length && (
        <div className="w-full flex justify-center items-center">
          <Typography className="mt-2" size="sm" variant="subtext">
            No results
          </Typography>
        </div>
      )}
    </>
  );
}

export default LitigationPlans;
