import { cn, makeAcronym } from '@colosseum/data';

/* eslint-disable-next-line */
export interface ProfilePhotoProps {
  loading: boolean;
  diameter?: number;
  displayName?: string;
  className?: string;
}

export function ProfilePhoto(props: ProfilePhotoProps) {
  const { loading, diameter, displayName, className } = props;
  const diameterClass = `w-${diameter ?? 10} h-${diameter ?? 10}`;
  const minDiameterClass = `min-w-[${(diameter ?? 10) * 4}px] min-h-[${(diameter ?? 10) * 4}px]`;
  return (
    <div
      className={cn(
        'flex items-center justify-center text-center text-gray-700 bg-gray-200 rounded-full text-lg font-semibold uppercase bg-gray-200 rounded-full',
        diameterClass,
        minDiameterClass,
        className,
      )}
    >
      {loading ? (
        <div className="w-full h-full bg-gray-100 rounded-full animate-pulse">&nbsp;</div>
      ) : (
        <div>{makeAcronym(displayName ?? '', 2)}</div>
      )}
    </div>
  );
}

export default ProfilePhoto;
