/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionViewModelType,
  CaseType,
  CoCounselType,
  ContactEmploymentType,
  ContactViewModelType,
  FirmOfficeType,
  FirmType,
  HurinUserType,
  PolicyType,
  RecordRequest,
  SettlementProposalType,
  StaffType,
  TemplateDictionaryItemType,
  VehicleType,
  contactConnectionOptions,
  contactConnectionOptionsReverse,
} from '@gladiate/types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { lowerCase, startCase, uniqBy, upperCase } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import pluralize from 'pluralize';
import { useGetFirmUserWithDisplayNameFromUsername } from '../hooks/useGetFirmUserWithDisplayNameFromUsername';
import {
  displayContactName,
  findRoleSpecificCaseContactConnections,
  formatAddress,
  getContactDetailsOptions,
} from './contactUtils';
import { formatPhoneNumber } from './miscUtils';
import { formatPrice } from './priceUtils';
import { formatVehicleName } from './vehicleUtils';
dayjs.extend(advancedFormat);
const instancePartMatcher = (part: string) => part.match(/\d+/);

/**
 *
 * @description This function takes a tag and returns the tag parts as an array and the instance number
 * @returns
 */
export const processTag = (tag: string) => {
  // Remove double curly braces from either side
  const rawTag = tag.replace(/{{/g, '').replace(/}}/g, '');
  // Split tag name by underscores
  const tagParts = rawTag.split('_');

  const formatItems = Object.keys(getFormatOptions());
  // remove instance and format from tag parts
  const tagPartsMinusInstanceAndFormat = tagParts.filter(
    (part) => !instancePartMatcher(part) && !formatItems.includes(part),
  );
  // get instance from tag parts
  const instance = tagParts.filter((part) => instancePartMatcher(part)) || 1;
  const format = tagParts.filter((part) => formatItems.includes(part))[0];
  return {
    tagParts: tagPartsMinusInstanceAndFormat,
    instance: Number(instance),
    format: format,
  };
};

/**
 *
 * @description  This function takes a tag and returns a displayable name for it
 * @returns
 */
export const formatTagName = (tag: string, showInstance: boolean = true) => {
  const contactOptions = Object.keys(contactConnectionOptionsReverse);

  // if the tag has a contactOption in it we want to replace it with the value from the contactConnectionOptionsReverse object
  // this will allow us to display the correct friendly name for the contact option
  // example: {{contact_client_connection_employee_firstName}} will become {{contact_client_connection_employer_firstName}}
  const tagWithConnectionOptionReplaced = contactOptions.reduce((acc, option) => {
    const regex = new RegExp(option, 'g');
    return acc.replace(
      regex,
      contactConnectionOptionsReverse[option as keyof typeof contactConnectionOptionsReverse],
    );
  }, tag);
  const { tagParts, instance, format } = processTag(tagWithConnectionOptionReplaced);
  const formatItems = getFormatOptions();
  // remove double curly braces from either side and replace underscores with spaces and also remove the word connection
  const friendlyTagName = tagParts
    .map((part) => startCase(part))
    .join('/') // join array of tagparts with a slash
    // .replace(/([a-z])([A-Z])/g, '$1 $2') // add space between camelCase
    .replace('/Connection', ''); // remove the word connection
  return showInstance
    ? friendlyTagName +
        (instance > 1 ? ` (${instance})` : '') +
        `${formatItems[format] ? ` - Formatted` : ''}`
    : friendlyTagName + `${formatItems[format] ? ` - Formatted` : ''}`;
};

type FormatOptionsType = {
  [key: string]: { modifierFunc: (item: string) => string; label: string };
};
/**
 *
 * @description This function returns all of the format options as a dictionary
 * @param varType optional parameter to specify the type of the variable;
 *  will return all options if not specified
 * @returns
 */
export const getFormatOptions = (varType?: TemplateDictionaryItemType['type']) => {
  const textOptions = {
    upperCase: {
      modifierFunc: (item: string) => upperCase(item),
      label: 'UPPERCASE',
    },
    lowerCase: {
      modifierFunc: (item: string) => lowerCase(item),
      label: 'lowercase',
    },
    default: {
      modifierFunc: (item: string) => item,
      label: 'Default',
    },
  };
  const dateOptions = {
    MMMDYYYY: {
      modifierFunc: (item: string) => dayjs(item).format('MMM D, YYYY'),
      label: 'Mon d, Year',
    },
    MMMMDYYYY: {
      modifierFunc: (item: string) => dayjs(item).format('MMMM D YYYY'),
      label: 'Month d year',
    },
    default: {
      modifierFunc: (item: string) => dayjs(item).format('YYYY-MM-DD'),
      label: 'Default',
    },
    fromNow: {
      modifierFunc: (item: string) => dayjs(item).fromNow(true),
      label: 'From now',
    },
    dddMMMMDoYYYY: {
      modifierFunc: (item: string) => dayjs(item).format('ddd, MMMM Do YYYY'),
      label: 'Day, Month d year',
    },
  };
  if (varType === 'text') {
    return textOptions as FormatOptionsType;
  } else if (varType === 'date') {
    return {
      ...dateOptions,
    } as FormatOptionsType;
  } else if (['boolean', 'numeric', 'table', 'input', 'list'].includes(varType || '')) {
    return {};
  }
  return {
    ...textOptions,
    ...dateOptions,
  } as FormatOptionsType;
};

// #region document dropdown options

export type OptionType = {
  value: string;
  label: string;
  context?: string;
};

export const getNonSpecificOptions = <T>({
  options,
  tagParts,
}: {
  options?: T[];
  tagParts: string[];
}) => {
  if (!options) {
    return [];
  }

  const uniqueOptions = new Set();
  options.forEach((option) => {
    const key = tagParts[1] as keyof typeof option;
    if (option && key) {
      uniqueOptions.add(option[key]);
    }
  });

  const uniqueOptionsFiltered = Array.from(uniqueOptions).filter((value) => value !== undefined);

  return Array.from(uniqueOptionsFiltered).map((value) => ({
    value: String(value),
    label: value,
  })) as OptionType[];
};
export const getRecordRequestOptions = ({
  options: recordRequests,
  tagParts,
  caseContactConnections,
}: {
  options?: RecordRequest[];
  tagParts: string[];
  caseContactConnections?: CaseContactConnectionViewModelType[];
}) => {
  if (!recordRequests) {
    return [];
  }

  const uniqueOptions = new Set<{
    value?: string | number | string[];
    context?: string;
  }>();
  recordRequests.forEach((recordRequest) => {
    const key = tagParts[1] as keyof typeof recordRequest;
    if (recordRequest && key) {
      const recordsProviders =
        findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
          caseContactConnections: caseContactConnections,
          role: 'recordsProvider',
        });
      const clients = findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
        caseContactConnections: caseContactConnections,
        role: 'client',
      });
      const recordsProvider = recordsProviders?.find((connection) =>
        connection.roles?.find((role) => role.itemId === recordRequest.requestId),
      );
      const client = clients?.find((connection) =>
        connection.roles?.find((role) => role.itemId === recordRequest.requestId),
      );

      uniqueOptions.add({
        value: recordRequest[key],
        context: `${
          recordsProvider ? displayContactName(recordsProvider?.contact) : 'No Provider'
        } - ${client ? displayContactName(client?.contact) : 'No Client'}`,
      });
    }
  });

  const uniqueOptionsFiltered = Array.from(uniqueOptions).filter((value) => value !== undefined);

  return Array.from(uniqueOptionsFiltered).map((option) => ({
    value: String(option.value),
    label: option.value,
    context: option.context,
  })) as OptionType[];
};

export const getStaffOptions = ({
  staff,
  getFirmUserWithDisplayNameFromUsername,
  tagParts,
}: {
  staff?: StaffType[];
  getFirmUserWithDisplayNameFromUsername: ReturnType<
    typeof useGetFirmUserWithDisplayNameFromUsername
  >['getFirmUserWithDisplayNameFromUsername'];
  tagParts: string[];
}) => {
  if (!staff) {
    return [];
  }
  const key = tagParts[1] as keyof HurinUserType | 'name' | 'number';
  const uniqStaff = uniqBy(staff, 'username');
  let staffKey: 'email' | 'displayName' | 'phone_number' | 'barNumber' | undefined;
  if (!key || key === 'name') {
    staffKey = 'displayName';
  } else if (key === 'email') {
    staffKey = 'email';
  } else if (key === 'number') {
    staffKey = 'phone_number';
  } else if (key === 'barNumber') {
    staffKey = 'barNumber';
  }
  if (!staffKey) {
    return [];
  }
  return uniqStaff.map((staffMember) => ({
    value: getFirmUserWithDisplayNameFromUsername(staffMember.username ?? '')?.[
      staffKey ?? 'displayName'
    ],
    label: getFirmUserWithDisplayNameFromUsername(staffMember.username ?? '')?.[
      staffKey ?? 'displayName'
    ],
    context: getFirmUserWithDisplayNameFromUsername(staffMember.username ?? '')?.displayName,
  })) as OptionType[];
};

export const getCocounselOptions = ({
  coCounsel,
  getFirmUserWithDisplayNameFromUsername,
  tagParts,
}: {
  coCounsel?: CoCounselType[];
  getFirmUserWithDisplayNameFromUsername: ReturnType<
    typeof useGetFirmUserWithDisplayNameFromUsername
  >['getFirmUserWithDisplayNameFromUsername'];
  tagParts: string[];
}) => {
  if (!coCounsel) {
    return [];
  }
  const key = tagParts[1] as keyof CoCounselType | 'name' | 'address';
  if (!key) {
    return [];
  }
  const coCounselWithNames = coCounsel.map((coCounselMember) => ({
    ...coCounselMember,
    name: getFirmUserWithDisplayNameFromUsername(coCounselMember.username ?? '')?.displayName,
  }));
  if (key === 'name') {
    return coCounselWithNames.map((coCounselMember) => ({
      value: coCounselMember.name,
      label: coCounselMember.name,
    })) as OptionType[];
  } else if (key === 'address') {
    return coCounselWithNames.map((coCounselMember) => {
      const address = formatAddress({
        streetAddress: coCounselMember.cocounselFirmAddress,
        city: coCounselMember.cocounselFirmCity,
        state: coCounselMember.cocounselFirmState,
        zipCode: coCounselMember.cocounselFirmZip,
        country: '',
      });
      return {
        value: address,
        label: address,
        context: coCounselMember.name,
      };
    });
  } else {
    return coCounselWithNames.map((coCounselMember) => ({
      value: coCounselMember[key],
      label: coCounselMember[key],
      context: coCounselMember.name,
    })) as OptionType[];
  }
};

export const getContactOptions = ({
  caseContactConnectionsData,
  contacts,
  tagParts,
}: {
  caseContactConnectionsData?: CaseContactConnectionViewModelType[];
  contacts?: ContactViewModelType[];
  tagParts: string[];
}) => {
  const numberOfTags = tagParts.length;
  const caseContactConnectionRole = numberOfTags > 2 ? tagParts[1] : undefined;
  const key = (numberOfTags > 2 ? tagParts[2] : tagParts[1]) as keyof typeof contacts;
  let connections = caseContactConnectionsData;
  if (caseContactConnectionRole) {
    connections = findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
      caseContactConnections: caseContactConnectionsData,
      role: caseContactConnectionRole,
    });
  }
  if (!connections) {
    return [];
  }
  const uniqueOptionsWithContext = new Set<{
    value?: unknown;
    context?: string;
    label?: string;
  }>();
  connections.forEach((connection) => {
    const contact = contacts?.find((contact) => contact.contactId === connection.contact.contactId);

    if (!contact || !key) {
      enqueueSnackbar('Error: Contact or key not found', {
        variant: 'error',
      });
      return [];
    }
    // @ts-ignore
    const keyToUse = key === 'number' ? 'phoneNumber' : key;
    // @ts-ignore
    if (keyToUse === 'connectedValue') {
      const valueKey = tagParts[3] as keyof CaseContactConnectionRoleType;

      if (!valueKey) {
        enqueueSnackbar('Error: Value key not found', {
          variant: 'error',
        });
        return [];
      }

      const role = connection.roles?.find((role) => role.roleOnCase === caseContactConnectionRole);

      if (!role) {
        enqueueSnackbar('Error: Role not found', {
          variant: 'error',
        });
        return [];
      }
      uniqueOptionsWithContext.add({
        value: role[valueKey],
        context: displayContactName(contact),
      });
    }

    // @ts-ignore
    if (keyToUse === 'fullName') {
      uniqueOptionsWithContext.add({
        value: displayContactName(contact),
      });
    }

    // @ts-ignore
    if (keyToUse === 'connection') {
      const connectionKey = tagParts[3] as keyof typeof contactConnectionOptions;
      if (!connectionKey) {
        enqueueSnackbar('Error: Connection key not found', {
          variant: 'error',
        });
        return [];
      }

      const connectedContactConnection = contact.contactConnections?.find(
        (connectedContact) => connectedContact.relationship === connectionKey,
      );

      const connectedContactData = contacts?.find(
        (contact) => contact.contactId === connectedContactConnection?.connectedToContactId,
      );

      if (!connectedContactData) {
        return [];
      }

      const connectedContactKey = tagParts[4] as keyof typeof connectedContactData;

      if (!connectedContactKey) {
        return [];
      }

      if (
        // jest complains without these ignores.
        // @ts-ignore
        keyToUse === 'emailAddress' ||
        // @ts-ignore
        keyToUse === 'phoneNumber' ||
        // @ts-ignore
        keyToUse === 'number' ||
        // @ts-ignore
        keyToUse === 'address' ||
        // @ts-ignore
        keyToUse === 'job' ||
        (connectedContactKey as any) === 'address' ||
        (connectedContactKey as any) === 'job'
      ) {
        const contactDetails = getContactDetailsOptions(connectedContactData, connectedContactKey);
        contactDetails.forEach((detail) => {
          if (detail.value !== undefined) {
            uniqueOptionsWithContext.add({
              value: detail.value,
              context: detail.context,
            });
          }
        });
      } else if (connectedContactKey === 'name') {
        uniqueOptionsWithContext.add({
          value: displayContactName(connectedContactData),
          context: displayContactName(connectedContactData),
        });
      } else {
        uniqueOptionsWithContext.add({
          value: connectedContactData[connectedContactKey],
          context: displayContactName(connectedContactData),
        });
      }
    }
    //@ts-ignore
    else if (['list', 'fullList'].includes(keyToUse)) {
      let listOfContacts = [] as ContactViewModelType[];
      if (caseContactConnectionRole) {
        caseContactConnectionsData?.forEach((connection) => {
          const contact = contacts?.find(
            (contact) =>
              contact.contactId === connection.contact.contactId &&
              connection.roles?.find((role) => role.roleOnCase === caseContactConnectionRole),
          );
          if (contact) {
            listOfContacts.push(contact);
          }
        });
      } else {
        listOfContacts = contacts ?? [];
      }
      // This is unnecessarily complex, but it works
      const contactsListValue =
        //@ts-ignore
        keyToUse === 'list'
          ? `${listOfContacts
              ?.map(
                (contact, index) =>
                  `${displayContactName(contact)}${
                    index !== listOfContacts.length - 1 ? ',' : ''
                  }<br/>`,
              )
              .join('')}`
          : `${listOfContacts
              ?.map((contact, index) => {
                const phoneNumber = contact?.contactNumbers[0]?.number
                  ? formatPhoneNumber(contact?.contactNumbers[0]?.number)
                  : '';
                const email = contact?.contactEmails[0]?.emailAddress;
                const address = formatAddress(contact?.contactAddresses[0]);
                let formattedString = `${displayContactName(contact)}`;
                if (phoneNumber) {
                  formattedString = formattedString + `<br/>${phoneNumber}`;
                }
                if (email) {
                  formattedString = formattedString + `<br/>${email}`;
                }
                if (address && address !== '-') {
                  formattedString = formattedString + `<br/>${address}`;
                }
                return `${formattedString}${
                  index !== listOfContacts.length - 1 ? '</br>' : ''
                }<br/>`;
              })
              .join('')}`;
      uniqueOptionsWithContext.add({
        value: contactsListValue,
        label: 'List of Contacts',
        context: listOfContacts?.length
          ? `${listOfContacts.length} ${
              // @ts-ignore
              keyToUse === 'list'
                ? pluralize('contacts', listOfContacts?.length)
                : `full ${pluralize('contacts', listOfContacts?.length)}`
            }`
          : 'No contacts found',
      });
      return;
    } else {
      const furtherIdentifyingKey = tagParts[3] as keyof ContactEmploymentType;

      if (
        // jest complains without these ignores.
        // @ts-ignore
        keyToUse === 'emailAddress' ||
        // @ts-ignore
        keyToUse === 'phoneNumber' ||
        // @ts-ignore
        keyToUse === 'number' ||
        // @ts-ignore
        keyToUse === 'address' ||
        // @ts-ignore
        keyToUse === 'job' ||
        // @ts-ignore
        keyToUse === 'pronounsPersonal' ||
        // @ts-ignore
        keyToUse === 'pronounsPossessive'
      ) {
        const contactDetails = getContactDetailsOptions(contact, keyToUse, furtherIdentifyingKey);
        contactDetails.forEach((detail) => {
          if (String(detail).trim()) {
            uniqueOptionsWithContext.add({
              value: detail.value,
              context: detail.context,
            });
          }
        });
      } else {
        uniqueOptionsWithContext.add({
          value: contact[keyToUse],
          context: displayContactName(contact),
        });
      }
    }
    return [];
  });

  return Array.from(uniqueOptionsWithContext)
    .filter((option) => option.value !== undefined)
    .map((option) => {
      return {
        value: option.value,
        label: option.value,
        context: option.context,
      };
    }) as OptionType[];
};

export const getSettlementNegotiationOptions = ({
  settlements,
  caseContactConnections,
  tagParts,
}: {
  settlements?: SettlementProposalType[];
  caseContactConnections?: CaseContactConnectionViewModelType[];
  tagParts: string[];
}) => {
  if (!settlements) {
    return [];
  }
  const connections = findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
    caseContactConnections: caseContactConnections,
    role: 'insurer',
  });

  const uniqueOptions = new Set<{
    value?: string | number | string[];
    context?: string;
  }>();
  settlements.forEach((settlement) => {
    const key = tagParts[1] as keyof typeof settlement;
    if (settlement && key) {
      const insurer = connections?.find((connection) =>
        connection.roles?.find((role) => role.itemId === settlement.settlementProposalId),
      );
      uniqueOptions.add({
        value: settlement[key],
        context: `${insurer?.contact ? displayContactName(insurer?.contact) : '-'} / ${
          settlement.type ? startCase(settlement.type) : '-'
        } / ${settlement.amount ? formatPrice(settlement.amount) : '-'}`,
      });
    }
  });

  const uniqueOptionsFiltered = Array.from(uniqueOptions).filter((value) => value !== undefined);

  return Array.from(uniqueOptionsFiltered).map((option) => ({
    value: String(option.value),
    label: option.value,
    context: option.context,
  })) as OptionType[];
};

export const getCaseOptions = ({
  caseData,
  tagParts,
}: {
  caseData?: CaseType;
  tagParts: string[];
}) => {
  if (!caseData) {
    return [];
  }

  const key = tagParts[1] as keyof typeof caseData;
  if (!key) {
    return [];
  }

  if (key === 'caseType') {
    const caseType = caseData?.caseType?.title ?? '-';
    return [
      {
        value: caseType,
        label: caseType,
      },
    ];
  }

  // I have to use any here because technically the key should only be of a case and incidentAddress is not a key of case
  if (key === ('incidentAddress' as any)) {
    const address = formatAddress({
      streetAddress: caseData?.incidentStreet,
      city: caseData?.incidentCity,
      state: caseData?.incidentState,
      zipCode: caseData?.incidentZipCode,
      country: '',
    });
    return [
      {
        value: address,
        label: address,
      },
    ];
  }

  const uniqueOptionsWithContext = new Set<{
    value?: unknown;
    context?: string;
  }>();

  uniqueOptionsWithContext.add({
    value: caseData[key],
  });

  return Array.from(uniqueOptionsWithContext)
    .filter((option) => option.value !== undefined)
    .map((option) => {
      return {
        value: option.value,
        label: option.value,
        context: option.context,
      };
    }) as OptionType[];
};

export const getInsuranceOptions = ({
  tagParts,
  insurances,
  caseContactConnections,
  contacts,
}: {
  tagParts: string[];
  insurances?: PolicyType[];
  caseContactConnections?: CaseContactConnectionViewModelType[];
  contacts?: ContactViewModelType[];
}) => {
  if (!insurances) {
    return [] as OptionType[];
  }

  const key = tagParts[1] as keyof (typeof insurances)[0];
  if (!key) {
    return [] as OptionType[];
  }

  const uniqueOptionsWithContext = new Set<{
    value?: unknown;
    context?: string;
  }>();

  insurances.forEach((insurance) => {
    const billingEntityId = caseContactConnections?.find((connection) =>
      connection.roles?.find((role) => role.itemId === insurance.policyId),
    )?.contact.contactId;
    const billingEntity = contacts?.find((contact) => contact.contactId === billingEntityId);
    uniqueOptionsWithContext.add({
      value: insurance[key],
      context:
        `${billingEntity ? displayContactName(billingEntity) : 'N/A'} ` +
        `/ ${insurance.policyType ? `${startCase(insurance.policyType)}` : 'N/A'} ` +
        `/ ${insurance.type ? `${startCase(insurance.type)}` : 'N/A'}`,
    });
  });
  return Array.from(uniqueOptionsWithContext)
    .filter((option) => option.value !== undefined)
    .map((option) => {
      return {
        value: String(option.value),
        label: String(option.value),
        context: option.context,
      };
    }) as OptionType[];
};

export const getVehicleOptions = ({
  tagParts,
  vehicles,
}: {
  tagParts: string[];
  vehicles?: VehicleType[];
}) => {
  if (!vehicles) {
    return [];
  }

  const key = tagParts[1] as keyof (typeof vehicles)[0];
  if (!key) {
    return [];
  }

  const uniqueOptionsWithContext = [] as {
    value?: unknown;
    context?: string;
  }[];
  vehicles.forEach((vehicle) => {
    uniqueOptionsWithContext.push({
      value: vehicle[key],
      context: formatVehicleName(vehicle),
    });
  });

  return uniqueOptionsWithContext
    .filter((option) => option.value !== undefined)
    .map((option) => {
      return {
        value: option.value,
        label: option.value,
        context: option.context,
      };
    }) as OptionType[];
};

export const getFirmOptions = ({
  tagParts,
  firmOffices,
  firmSettings,
}: {
  tagParts: string[];
  firmOffices?: FirmOfficeType[];
  firmSettings?: FirmType;
}) => {
  if (!firmSettings) {
    return [] as OptionType[];
  }

  const key = tagParts[1] as keyof typeof firmSettings | keyof typeof firmOffices;
  if (!key) {
    return [];
  }

  const combinedFirmData = {
    ...firmSettings,
    offices: firmOffices,
  };

  const uniqueOptions = new Set();

  if (key === ('address' as any)) {
    combinedFirmData.offices?.forEach((office) => {
      const address = formatAddress({
        streetAddress: office.streetAddress,
        city: office.city,
        state: office.state,
        zipCode: office.zip,
        country: '',
      });

      uniqueOptions.add(address);
    });

    const uniqueOptionsFiltered = Array.from(uniqueOptions).filter((value) => value !== undefined);

    return Array.from(uniqueOptionsFiltered).map((value) => ({
      value: value,
      label: value,
    })) as OptionType[];
  }

  uniqueOptions.add(combinedFirmData[key]);

  const uniqueOptionsFiltered = Array.from(uniqueOptions).filter((value) => value !== undefined);

  return Array.from(uniqueOptionsFiltered).map((value) => ({
    value: value,
    label: value,
  })) as OptionType[];
};
// #endregion document dropdown options

function copyTable(table: string) {
  const type = 'text/html';
  const blobHtml = new Blob([table], { type });

  const data = [
    new ClipboardItem({
      [type]: blobHtml,
    }),
  ];

  navigator.clipboard.write(data).then(
    () => {
      /* success */
    },
    () => {
      /* failure */
    },
  );
}

export const copyTreatmentTable = () =>
  copyTable(`<table>
<tr>
  <th>Medical Provider</th>
  <th>Billing Entity</th>
  <th>Starting Date of Service</th>
  <th>Amount Billed</th>
  <th>Adjustments</th>
  <th>Total Payments</th>
  <th>Unpaid Balance</th>
  <th>Reductions</th>
  <th>Present Balance</th>
</tr>
<tr>
  <td>{{treatment.medicalProvider:default-val(-)}}</td>
  <td>{{treatment.billingEntity:default-val(-)}}</td>
  <td>{{treatment.startingDateOfService:default-val(-)}}</td>
  <td>{{treatment.amountBilled:default-val(0)}}</td>
  <td>{{treatment.adjustments:default-val(0)}}</td>
  <td>{{treatment.totalPayments:default-val(0)}}</td>
  <td>{{treatment.unpaidBalance:default-val(0)}}</td>
  <td>{{treatment.reductions:default-val(0)}}</td>
  <td>{{treatment.presentBalance:default-val(0)}}</td>
</tr>
<tr>
  <td></td>
  <td>Total</td>
  <td>{{treatmentAggregates.aggregateAmountBilled:default-val(0)}}</td>
  <td>{{treatmentAggregates.aggregateAdjustments:default-val(0)}}</td>
  <td>{{treatmentAggregates.aggregateTotalPayments:default-val(0)}}</td>
  <td>{{treatmentAggregates.aggregateUnpaidBalance:default-val(0)}}</td>
  <td>{{treatmentAggregates.aggregateReductions:default-val(0)}}</td>
  <td>{{treatmentAggregates.aggregatePresentBalance:default-val(0)}}</td>
</tr>
</table>`);

export const copyExpensesTable = () =>
  copyTable(`<table>
<tr>
  <th>Category</th>
  <th>Total</th>
  <th>Vendor</th>
  <th>Client</th>
  <th>Payment Completed</th>
  <th>Date Incurred</th>
  <th>UTBMS Code</th>
  

</tr>
<tr>
  <td>{{expenses.title:default-val(-)}}</td>
  <td>{{expenses.totalCost:default-val(0)}}</td>
  <td>{{expenses.vendor:default-val(-)}}</td>
  <td>{{expenses.client:default-val(-)}}</td>
  <td>{{expenses.paymentCompleted:default-val(-)}}</td>
  <td>{{expenses.dateIncurred:default-val(-)}}</td>
  <td>{{expenses.UTBMSCodeName:default-val(-)}}</td>
</tr>
<tr>
  <td>Total</td>
  <td>{{expenseAggregates.total:default-val(0)}}</td>
</tr>
</table>`);

export const copyLiensTable = () =>
  copyTable(`<table>
<tr>
  <th>Client</th> 
  <th>Lien Holder</th>
  <th>Lien Provider</th>
  <th>Lien Amount</th>
  <th>Reduction</th>
  <th>Final Balance</th>
  <th>Accepted?</th>
  <th>Date Verified</th>
</tr>
<tr>

  <td>{{liens.client:default-val(-)}}</td>
  <td>{{liens.lienHolderName:default-val(-)}}</td>
  <td>{{liens.lienProviderName:default-val(-)}}</td>
  <td>{{liens.lienAmount:default-val(0)}}</td>
  <td>{{liens.reduction:default-val(0)}}</td>
  <td>{{liens.finalBalance:default-val(0)}}</td>
  <td>{{liens.accepted:default-val(0)}}</td>
  <td>{{liens.balanceVerifiedDate:default-val(-)}}</td>
</tr>
<tr>
  <td>Totals</td>
  <td></td>
  <td></td>
  <td>{{liensAggregates.lienAmountTotal:default-val(0)}}</td>
  <td>{{liensAggregates.reductionAmountTotal:default-val(0)}}</td>
  <td>{{liensAggregates.finalBalanceTotal:default-val(0)}}</td>
</tr>
</table>`);
