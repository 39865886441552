import { CreateButton } from '@colosseum/shared-ui';
import { useNavigate } from 'react-router';

/* eslint-disable-next-line */
export interface NextButtonProps {
  nextRoute: string;
  disabled?: boolean;
  submit?: boolean;
  onClick?: () => void;
  title?: string;
}

export function NextButton(props: NextButtonProps) {
  const { nextRoute, disabled, submit = false, onClick = () => {}, title = 'Next' } = props;
  const navigate = useNavigate();
  if (submit) {
    return (
      <div className="text-sm">
        <CreateButton disabled={disabled} title={title} onClick={onClick} />
      </div>
    );
  }
  return (
    <div className="text-sm">
      <CreateButton onClick={() => navigate(nextRoute)} disabled={disabled} title={title} />
    </div>
  );
}

export default NextButton;
