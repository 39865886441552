import { copyToClipboard, getAbsoluteUrl } from '@colosseum/data';
import { ClipboardDocumentCheckIcon, LinkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

type AdditionalButton = {
  icon: ReactElement;
  message: string;
  clickHandler: () => void;
  disabled?: boolean;
};

export interface ActionButtonGroupProps {
  deleteFunction: () => void;
  copyText?: string;
  url?: string;
  emailAddress?: string;
  dataCyTitle?: string;
  showLink?: boolean;
  additionalButton1?: AdditionalButton;
}

export function ActionButtonGroup(props: ActionButtonGroupProps) {
  const { copyText, deleteFunction, url, emailAddress, dataCyTitle, showLink, additionalButton1 } =
    props;

  const renderButton = (
    enabled: boolean,
    icon: ReactElement,
    message: string,
    clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void,
    dataCySuffix: string,
  ): ReactElement => {
    const button = (
      <button
        onClick={clickHandler}
        disabled={!enabled}
        className={`h-full p-2 rounded-md ${enabled ? 'hover:text-light-blue' : 'opacity-50 '}`}
        data-cy={dataCyTitle ? `${dataCyTitle}-${dataCySuffix}` : `${dataCySuffix}-button`}
      >
        {icon}
      </button>
    );

    return enabled ? <TooltipWrapper message={message}>{button}</TooltipWrapper> : button;
  };

  return (
    <div className="flex items-center justify-between col-span-2 gap-3">
      <div className="flex items-center">
        {renderButton(
          !!copyText,
          <ClipboardDocumentCheckIcon className="w-5 h-5" />,
          'Copy to clipboard',
          (e) => copyText && copyToClipboard(copyText, e),
          'copy',
        )}

        {showLink &&
          renderButton(
            !!url,
            <LinkIcon className="w-5 h-5" />,
            'Open in new tab',
            (e) => {
              e.preventDefault();
              url && window.open(getAbsoluteUrl(url), '_blank');
            },
            'open',
          )}

        {additionalButton1 &&
          renderButton(
            additionalButton1.disabled ? false : true,
            additionalButton1.icon,
            additionalButton1.message,
            (e) => {
              e.preventDefault();
              additionalButton1.clickHandler();
            },
            'additional',
          )}
      </div>

      {renderButton(
        true,
        <TrashIcon className="w-4 h-4 text-red-500" />,
        'Delete item',
        (e) => {
          e.preventDefault();
          deleteFunction();
        },
        'delete',
      )}

      {/* 
      <TooltipWrapper message="Open new email">
        <a
          className={`h-full p-2 rounded-md hover:text-light-blue ${
            !emailAddress ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          href={emailAddress ? `mailto:${emailAddress}` : undefined}
          onClick={(e) => !emailAddress && e.preventDefault()}
        >
          <EnvelopeIcon className="w-5 h-5" />
        </a>
      </TooltipWrapper> 
      */}
    </div>
  );
}

export default ActionButtonGroup;
