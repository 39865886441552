import {
  VITE_API_STAGE,
  apiStageOptions,
  copyToClipboard,
  useCreateReferralSource,
  useDeleteReferralSource,
  useGetContact,
  useGetContactsInfinite,
  useGetReferralSources,
  useUpdateReferralSource,
} from '@colosseum/data';
import {
  ContactChip,
  ContactDropdown,
  CreateButton,
  DataTable,
  Form,
  RangeSlider,
  SelectFormInput,
  Slideover,
  TextFormInput,
  TooltipWrapper,
} from '@colosseum/shared-ui';
import { ReferralSource } from '@gladiate/types';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { lowerCase, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './Refferal-Sources-Table-Columns';

const API_STAGE = VITE_API_STAGE;

export const handleCopyReferralUrl = (firmReferralSourceReferenceId: string) => {
  if (!firmReferralSourceReferenceId) {
    return;
  }

  if (API_STAGE === apiStageOptions.prod) {
    copyToClipboard(
      `https://api.gladiatelaw.com/jurisconsults/${API_STAGE}/v3/firms/leads/${firmReferralSourceReferenceId}`,
    );
  } else {
    copyToClipboard(
      `https://${API_STAGE}.api.gladiatelaw.com/jurisconsults/${API_STAGE}/v3/firms/leads/${firmReferralSourceReferenceId}`,
    );
  }
};

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  defaultReferralFeeType: z.string().optional(),
  defaultReferralFee: z.coerce.number().nonnegative().nullish().optional(),
});

export function ReferralSources() {
  const {
    data: referralSourcesData,
    isLoading: isReferralSourcesLoading,
    isError: isReferralSourcesError,
  } = useGetReferralSources();
  const { data: allContacts } = useGetContactsInfinite();
  const createReferralSource = useCreateReferralSource();
  const updateReferralSource = useUpdateReferralSource();
  const deleteReferralSource = useDeleteReferralSource();
  const referralSources = referralSourcesData?.data;
  const typing = createReferralSource.isLoading || updateReferralSource.isLoading;
  const [openReferralSourceId, setOpenReferralSourceId] = useState<string | undefined>();
  const openReferralSource = referralSourcesData?.data.find(
    (referralSource) => referralSource.referralSourceId === openReferralSourceId,
  );
  const { data: referralDefaultContact } = useGetContact(openReferralSource?.defaultContactId);

  const [openReferralSourceFeePercentage, setOpenReferralSourceFeePercentage] = useState(
    openReferralSource?.defaultReferralFee,
  );
  const [open, setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openReferralSource?.title || '',
      description: openReferralSource?.description || '',
      defaultReferralFeeType: openReferralSource?.defaultReferralFeeType || 'None',
      defaultReferralFee: openReferralSource?.defaultReferralFee || 0,
    },
  });

  const handleCreate = () => {
    createReferralSource.mutateAsync({}).then((res) => {
      setOpenReferralSourceId(res.data?.referralSourceId);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string | number) => {
    updateReferralSource.mutate({
      referralSourceId: openReferralSourceId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenReferralSourceId(undefined);
    }
  }, [open, form]);

  useEffect(() => {
    setOpenReferralSourceFeePercentage(openReferralSource?.defaultReferralFee);
  }, [openReferralSource?.defaultReferralFee]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openReferralSourceId)
            deleteReferralSource.mutateAsync(openReferralSourceId).then(() => {
              setOpen(false);
              setOpenReferralSourceId(undefined);
            });
        }}
        title="Referral Source"
        description="Update your Referral Source's information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
              <div className="flex items-center gap-x-2">
                <div className="flex flex-col self-start">
                  Default Contact
                  {openReferralSource?.defaultContactId ? (
                    <ContactChip
                      contactId={referralDefaultContact?.data.contactId || ''}
                      onDelete={(e) => {
                        e.preventDefault();
                        handleUpdate('defaultContactId', '');
                      }}
                      dataCyTitle={openReferralSource?.defaultContactId}
                    />
                  ) : (
                    <div className="w-auto">
                      <ContactDropdown
                        contactOptions={allContacts?.data ?? []}
                        popoverOpen={popoverOpen}
                        setPopoverOpen={setPopoverOpen}
                        linkingFunction={(data) => {
                          setPopoverOpen(false);
                          handleUpdate('defaultContactId', data.contactId);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <SelectFormInput
                title="Default Referral Fee Type"
                listItems={['Amount', 'Percentage', 'None']}
                defaultValue={startCase(openReferralSource?.defaultReferralFeeType) ?? 'None'}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('defaultReferralFeeType', lowerCase(target.value));
                }}
                {...form.register('defaultReferralFeeType')}
              />
              {openReferralSource?.defaultReferralFeeType === 'amount' && (
                <TextFormInput
                  {...form.register(`defaultReferralFee`, {
                    valueAsNumber: true,
                  })}
                  title="Default Referral Fee Amount"
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('defaultReferralFee', Number(target.value));
                  }}
                  type="number"
                />
              )}
              {openReferralSource?.defaultReferralFeeType === 'percentage' && (
                <RangeSlider
                  title="Fee Percentage"
                  value={openReferralSourceFeePercentage ?? 0}
                  setValue={(e: number) => setOpenReferralSourceFeePercentage(e)}
                  onAfterChange={(e) => handleUpdate('defaultReferralFee', e)}
                />
              )}
              {openReferralSource?.firmReferralSourceReferenceId && (
                <div className="flex items-center pr-2 text-gray-400 rounded-lg">
                  <TooltipWrapper message={'Copy to clipboard'}>
                    <button
                      className="flex"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCopyReferralUrl(
                          openReferralSource.firmReferralSourceReferenceId || '',
                        );
                      }}
                    >
                      <div className="flex items-center mr-2 font-semibold tracking-tighter uppercase gap-x-1">
                        Copy Share Link to clipboard
                      </div>
                      <ClipboardDocumentCheckIcon className="w-5 h-5 text-gray-900 cursor-pointer hover:text-atlantic-blue" />
                    </button>
                  </TooltipWrapper>
                </div>
              )}
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="referral-sources" className="text-xl font-semibold text-gray-900">
              Referral Sources
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's referral sources.`}</p>
          </div>
        </div>
        <DataTable
          data={referralSources || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          handleRowClick={(item: Row<ReferralSource>) => {
            setOpenReferralSourceId(item.original.referralSourceId);
            setOpen(true);
          }}
          isLoading={isReferralSourcesLoading}
          isError={isReferralSourcesError}
          tableName="referral-sources"
          customRightButton={
            <CreateButton
              className="ml-2"
              title={'Create New Source'}
              loading={createReferralSource.isLoading}
              onClick={handleCreate}
            />
          }
        />
      </div>
    </>
  );
}

export default ReferralSources;
