import { CommunicationLogViewModel, CommLogContacts, displayContactName } from '@colosseum/data';
import { ContactType } from '@gladiate/types';
import { startCase } from 'lodash';
import { useMemo } from 'react';

interface tableFilterOptions {
  value: string;
  label: string;
}

interface tableFilter {
  id: string;
  options: tableFilterOptions[];
}

export default function useGetCommLogsTableFilters(
  contacts: CommLogContacts,
  connectedContactsData: ContactType[] | undefined,
) {
  const tableTypeFilter = useMemo<tableFilter[]>(
    () => [
      {
        id: 'Type',
        options: [
          'Call - Manual Entry',
          'Email - Manual Entry',
          'Email - System',
          'Text - Manual Entry',
          'Text - System',
        ]?.map((item) => {
          return {
            value: item,
            label: item,
          };
        }),
      },
    ],
    [],
  );

  const tableContactFilter = useMemo<tableFilter[]>(
    () => [
      {
        id: 'Contact',
        options:
          connectedContactsData?.map((item) => {
            return {
              value: displayContactName(item),
              label: displayContactName(item),
            };
          }) || [],
      },
    ],
    [connectedContactsData],
  );

  const rolesFilter: tableFilterOptions[] = [
    ...(Object.values(contacts)
      ?.map((item) => {
        return item.roleList;
      })
      .reduce((acc, curr) => {
        if (curr && curr.length) {
          curr.forEach((role) => acc.add(role));
        }
        return acc;
      }, new Set()) || []),
  ]
    ?.map((role) => {
      return {
        value: role as string,
        label: startCase(role as string),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const tableRolesFilter = useMemo<tableFilter[]>(
    () => [
      {
        id: 'Roles',
        options: (rolesFilter.length && rolesFilter) || [],
      },
    ],
    [rolesFilter],
  );

  const tableDirectionFilter = useMemo<tableFilter[]>(
    () => [
      {
        id: 'Direction',
        options: ['Inbound', 'Outbound']?.map((item) => {
          return {
            value: item,
            label: item,
          };
        }),
      },
    ],
    [],
  );

  return [...tableTypeFilter, ...tableContactFilter, ...tableRolesFilter, ...tableDirectionFilter];
}
