import * as React from 'react';

import { cn } from '@colosseum/data';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Bars2Icon } from '@heroicons/react/24/outline';
import { Cell, Header, flexRender } from '@tanstack/react-table';
import { Button } from '../Button/Button';

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <div className="w-full overflow-auto rounded-md">
    <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
  </div>
));
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b-2 bg-gray-50', className)} {...props} />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('bg-primary font-medium text-primary-foreground', className)}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    className?: string;
    dontStripeRow?: boolean;
  }
>(({ className, dontStripeRow, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
      dontStripeRow ? '' : 'even:bg-gray-50',
      className,
    )}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-12 min-w-[130px] px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 ',
      className,
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const DraggableTableHead = ({
  header,
  className,
}: {
  header: Header<any, unknown>;
  className?: string;
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });
  const style: React.CSSProperties = {
    opacity: isDragging ? 0.6 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };
  return (
    <th
      colSpan={header.colSpan}
      className={cn(
        'h-12 min-w-[130px] px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 ',
        className,
      )}
      ref={setNodeRef}
      style={style}
    >
      <span className="flex first:pl-4">
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        <Button
          size="unset"
          variant="ghost"
          onClick={(e) => e.preventDefault()}
          {...attributes}
          {...listeners}
        >
          <Bars2Icon className="h-3 mr-2 text-gray-500 min-w-3 " />
        </Button>
      </span>
    </th>
  );
};

const DraggableTableCell = ({
  cell,
  className,
  ...props
}: {
  cell: Cell<any, unknown>;
  className?: string;
}) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: React.CSSProperties = {
    opacity: isDragging ? 0.6 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td
      style={style}
      ref={setNodeRef}
      className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
      {...props}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
));
TableCaption.displayName = 'TableCaption';

export {
  DraggableTableCell,
  DraggableTableHead,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
};
