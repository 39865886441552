import {
  enqueueAPISnackbar,
  findResourceSpecificCaseContactConnectionRoles,
  scrollToCaseSection,
  tanstackTableNames,
  useCreateSettlementProposal,
  useDeleteSettlementProposal,
  useGetCaseContactConnections,
  useGetContactsInfinite,
  useGetSettlementProposals,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';
import { ContactType, caseContactConnectionOptions } from '@gladiate/types';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import CaseSettlementForm from '../CaseSettlementForm/CaseSettlementForm';
import { columns } from './settlements-table-columns';

export interface CaseSettlementsProps {
  caseId: string;
}

export function CaseSettlements(props: CaseSettlementsProps) {
  const { caseId } = props;
  const [activeSettlementId, setActiveSettlementId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [typing, setTyping] = useState(false);

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const createSettlementMutation = useCreateSettlementProposal();
  const deleteSettlementMutation = useDeleteSettlementProposal();

  const { data: settlementsData, isLoading: isSettlementsLoading } =
    useGetSettlementProposals(caseId);

  const activeSettlement = settlementsData?.data.find(
    (settlement) => settlement.settlementProposalId === activeSettlementId,
  );

  const { data: caseContactConnections, isLoading: contactConnectionsLoading } =
    useGetCaseContactConnections(caseId, 'cases');

  const { data: contactsData } = useGetContactsInfinite();

  const connections = findResourceSpecificCaseContactConnectionRoles({
    caseContactConnections: caseContactConnections?.data,
    itemType: 'settlementProposal',
  });

  // This logic is messy but the data is very much not related so this will ahve to do
  const settlementsWithRoles =
    settlementsData?.data.map((settlement) => {
      let settlementAdjuster;
      let settlementInsurer;
      let settlementClient;
      connections.forEach((connection) => {
        connection.roles.forEach((role) => {
          if (
            role.roleOnCase &&
            (
              [
                caseContactConnectionOptions.adjuster,
                caseContactConnectionOptions.insurer,
                caseContactConnectionOptions.client,
              ] as string[]
            ).includes(role.roleOnCase) &&
            role.itemId === settlement.settlementProposalId
          ) {
            contactsData?.data?.forEach((contact) => {
              if (contact.contactId === connection.contactId) {
                if (role.roleOnCase === caseContactConnectionOptions.adjuster) {
                  settlementAdjuster = contact;
                }
                if (role.roleOnCase === caseContactConnectionOptions.insurer) {
                  settlementInsurer = contact;
                }
                if (role.roleOnCase === caseContactConnectionOptions.client) {
                  settlementClient = contact;
                }
              }
            });
          }
        });
      });
      return {
        ...settlement,
        adjuster: settlementAdjuster as ContactType | undefined,
        insurer: settlementInsurer as ContactType | undefined,
        client: settlementClient as ContactType | undefined,
      };
    }) ?? [];

  const hasAcceptedSettlement = settlementsWithRoles.some(
    (settlement) => settlement.proposalStatus === 'accepted',
  );

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'settlementProposal' && !isSettlementsLoading) {
      if (settlementsData?.data.find((s) => s.settlementProposalId === pendingSlideoverToOpen.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='Settlement Negotiation']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActiveSettlementId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Settlement not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, isSettlementsLoading]);

  return (
    <>
      <ResourceSlideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          deleteSettlementMutation.mutate(activeSettlement?.settlementProposalId);
        }}
        title="Settlement"
        description="Get started by filling in the information below to
          create your new settlement."
        typing={typing}
        caseId={caseId}
        createType="settlementProposal"
        resourceId={activeSettlement?.settlementProposalId ?? ''}
      >
        <TabsContent value="details">
          <CaseSettlementForm caseId={caseId} settlement={activeSettlement} setTyping={setTyping} />
        </TabsContent>
      </ResourceSlideover>
      <div className="">
        <div className="mb-4 sm:flex sm:items-center">
          <div className="grow"></div>
          <div className=" sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Add Settlement'}
              loading={createSettlementMutation.isLoading}
              onClick={() => {
                createSettlementMutation.mutateAsync({ caseId }).then((res) => {
                  setActiveSettlementId(res?.data.settlementProposalId);
                  setOpen(true);
                });
              }}
            />
          </div>
        </div>
        <DataTable
          data={settlementsWithRoles ?? []}
          initialSort={{
            id: 'Date Created',
            desc: true,
          }}
          showSearchBar
          filters={[]}
          columns={columns}
          handleRowClick={(item) => {
            const group = item.getIsGrouped();
            if (!group) {
              setActiveSettlementId(item.original.settlementProposalId);
              setOpen(true);
            }
          }}
          initialGroupingsExpanded={!hasAcceptedSettlement}
          persistentVisibility={{
            'Insurer / Policy / Coverage': true,
          }}
          initialVisibility={
            {
              'Date Created': false,
              'Policy Type': false,
              Insurer: false,
              'Coverage Type': false,
            } as VisibilityState
          }
          isLoading={isSettlementsLoading}
          autoResetExpanded={false}
          tableName={tanstackTableNames.settlements}
          grouping={['Insurer / Policy / Coverage']}
        />
      </div>
    </>
  );
}

export default CaseSettlements;
