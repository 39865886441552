import { useState } from 'react';
import ActiveMedicalTreatments from './ActiveMedicalTreatments/ActiveMedicalTreatments';

/* eslint-disable-next-line */
export interface MedicalTreatmentBillsProps {
  caseId: string;
}

export function MedicalTreatmentBills(props: MedicalTreatmentBillsProps) {
  const { caseId } = props;
  const [activeTreatmentId, setActiveTreatmentId] = useState<string>();

  return (
    <>
      <ActiveMedicalTreatments
        activeTreatmentId={activeTreatmentId}
        caseId={caseId}
        setActiveTreatmentId={setActiveTreatmentId}
      />
    </>
  );
}

export default MedicalTreatmentBills;
