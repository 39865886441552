import {
  casesSectionMappings,
  displayContactName,
  findRoleSpecificCaseContactConnections,
  formatPrice,
  mergeCaseExpensesAndContacts,
  scrollToCaseSection,
  tanstackTableNames,
  useCreateCaseContactConnectionWithItem,
  useCreateExpense,
  useDeleteExpense,
  useGetCaseContactConnections,
  useGetCaseExpensesWithCategories,
  useGetContactsInfinite,
  useGetFirmUserWithDisplayNameFromUsername,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  ExpenseForm,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
  Typography,
} from '@colosseum/shared-ui';
import {
  CaseContactConnectionType,
  ExpenseType,
  ExpenseWithCategoryType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { expenseColumns } from './case-expenses-columns';

/* eslint-disable-next-line */
export interface CaseExpensesProps {
  caseId: string;
}

interface AggregatedExpenses {
  subtotal: number;
  category: string;
  expenses: ExpenseWithCategoryType[];
}

export function CaseExpenses(props: CaseExpensesProps) {
  const { caseId } = props;

  const [showModal, setShowModal] = useState(false);
  const [activeExpenseId, setActiveExpenseId] = useState<string>();

  const [typing, setTyping] = useState(false);

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const { data: caseExpensesData, isLoading: isCaseExpensesLoading } =
    useGetCaseExpensesWithCategories(props.caseId);
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(props.caseId, 'cases');
  const { data: allContactsData } = useGetContactsInfinite();

  const { createCaseContactConnectionWithItemSoleClient } =
    useCreateCaseContactConnectionWithItem();

  const caseExpenseTotal = caseExpensesData?.meta?.aggregateTotalCost;
  const caseExpenses = caseExpensesData?.data;

  const activeExpense = caseExpenses?.find((expense) => expense.caseExpenseId === activeExpenseId);

  const mergedClientExpenses = mergeCaseExpensesAndContacts({
    caseContactConnections: caseContactConnectionsData?.data ?? [],
    contacts: allContactsData?.data ?? [],
    caseExpenses: caseExpenses,
  });

  // sort expenses by date if they don't have a date then they will be at the end of the list
  const sortedExpenses = mergedClientExpenses
    ?.sort((a: ExpenseType, b: ExpenseType) => {
      if (a.dateIncurred && b.dateIncurred) {
        return new Date(a.dateIncurred).getTime() - new Date(b.dateIncurred).getTime();
      } else if (a.dateIncurred && !b.dateIncurred) {
        return -1;
      } else if (!a.dateIncurred && b.dateIncurred) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((expense) => {
      const vendor = getVendorName(expense);
      return {
        ...expense,
        vendor,
        incurredByDisplayNames: expense.incurredBy?.map((incurrer) => {
          return getFirmUserWithDisplayNameFromUsername(incurrer).displayName ?? incurrer;
        }),
      };
    });

  const createExpenseMutation = useCreateExpense();
  const deleteExpenseMutation = useDeleteExpense();

  function getVendorName(expense: ExpenseType) {
    const vendors = findRoleSpecificCaseContactConnections<CaseContactConnectionType>({
      caseContactConnections: caseContactConnectionsData?.data,
      role: caseContactConnectionOptions.vendor,
    });
    const vendorId = vendors.find((vendors) => {
      const matchingRole = vendors.roles.find((role) => role.itemId === expense.caseExpenseId);
      return matchingRole;
    })?.contactId;
    const vendor = allContactsData?.data?.find((contact) => {
      return contact.contactId === vendorId;
    });
    if (vendor) {
      return displayContactName(vendor);
    } else {
      return '-';
    }
  }

  function createExpenseHandler() {
    createExpenseMutation.mutateAsync({ caseId: props.caseId }).then((res) => {
      setActiveExpenseId(res?.data.caseExpenseId);
      setShowModal(true);
      createCaseContactConnectionWithItemSoleClient({
        caseId,
        itemId: res.data.caseExpenseId,
        itemType: 'caseExpense',
        roleOnCase: 'client',
        caseContactConnections: caseContactConnectionsData?.data,
      });
    });
  }

  function handleDelete() {
    if (activeExpense?.caseExpenseId) {
      deleteExpenseMutation
        .mutateAsync(activeExpense?.caseExpenseId)
        .then(() => setShowModal(false));
    }
  }

  function openExpenseHandler(expense: ExpenseWithCategoryType) {
    setActiveExpenseId(expense.caseExpenseId);
    setShowModal(true);
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'expense') {
      const ref = document.querySelectorAll(
        `[data-case-submenu-item='${casesSectionMappings.accounting}']`,
      )[0];
      scrollToCaseSection(ref, true);
      setShowModal(true);
      setActiveExpenseId(pendingSlideoverToOpen?.id ?? '');
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, setPendingSlideoverToOpen]);

  return (
    <>
      <ResourceSlideover
        displayDeleteButton={true}
        open={showModal}
        setOpen={setShowModal}
        deleteFunction={handleDelete}
        title="Expense"
        description="Get started by filling in the information below to
            create your new expense."
        typing={typing}
        createType="expense"
        resourceId={activeExpense?.caseExpenseId ?? ''}
        caseId={caseId}
      >
        <TabsContent value="details">
          {activeExpense && (
            <ExpenseForm
              caseId={caseId}
              expense={activeExpense}
              setShowModal={setShowModal}
              typing={typing}
              setTyping={setTyping}
            />
          )}
        </TabsContent>
      </ResourceSlideover>
      <div className="">
        <div className="flex gap-x-2">
          <Typography color="gray">Total:</Typography>
          <Typography>{formatPrice(caseExpenseTotal ?? 0)}</Typography>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="grow"></div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              className="mb-4"
              title={'Add Expense'}
              loading={createExpenseMutation.isLoading}
              onClick={createExpenseHandler}
            />
          </div>
        </div>
        <DataTable
          data={sortedExpenses ?? []}
          showSearchBar
          initialSort={{
            id: 'Category',
            desc: false,
          }}
          filters={[]}
          columns={expenseColumns}
          handleRowClick={(item) => {
            const group = item.getIsGrouped();
            if (!group) {
              openExpenseHandler(item.original);
            }
          }}
          initialVisibility={
            {
              // These are initially hidden if no local storage state yet
              'Date Created': false,
            } as VisibilityState
          }
          persistentVisibility={{
            // These will override local storage
            Category: true,
          }}
          isLoading={isCaseExpensesLoading}
          autoResetExpanded={false}
          tableName={tanstackTableNames.expenses}
          grouping={['Category']}
        />
      </div>
    </>
  );
}

export default CaseExpenses;
