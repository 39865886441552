const {
  SENTRY_AUTH_TOKEN,
  VITE_API_STAGE,
  VITE_BASE_API_URL,
  VITE_COGNITO_BASE_URL,
  VITE_COGNITO_CLIENT_ID,
  VITE_MICROSOFT_CLIENT_ID,
  VITE_USER_POOL_ID,
  VITE_JUSTIFI_CLIENT,
  VITE_JUSTIFI_ACCOUNT,
} = import.meta.env;

export {
  SENTRY_AUTH_TOKEN,
  VITE_API_STAGE,
  VITE_BASE_API_URL,
  VITE_COGNITO_BASE_URL,
  VITE_COGNITO_CLIENT_ID,
  VITE_MICROSOFT_CLIENT_ID,
  VITE_USER_POOL_ID,
  VITE_JUSTIFI_CLIENT,
  VITE_JUSTIFI_ACCOUNT,
};

export const apiStageOptions = {
  prod: 'prod',
  demo: 'demo',
  pre: 'pre',
  stage: 'stage',
  sandbox101: 'sandbox101',
} as const;
