import {
  roleColorMap,
  useGetCaseContactConnectionsViewModel,
  useUpdateCaseContactConnection,
} from '@colosseum/data';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  ContactType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { startCase, uniqBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import Chip from '../Chip/Chip';
import ContactChip from '../ContactChip/ContactChip';
import EditRolesPopover from '../EditRolesPopover/EditRolesPopover';
import ResourceSlideover from '../ResourceSlideover/ResourceSlideover';
import { SlideoverProps } from '../Slideover/Slideover';
import { SlideoverContext } from '../SlideoverProvider';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
import { TabsContent } from '../shadcn/Tabs/Tabs';

const formSchema = z.object({
  descriptionOfCaseInvolvement: z.string().optional(),
  liabilityStatus: z.string().optional(),
});

/* eslint-disable-next-line */
export type CaseContactConnectionSlideoverProps = {
  contact?: ContactType;
  selectedCaseContactConnection: Pick<
    CaseContactConnectionType,
    'descriptionOfCaseInvolvement' | 'liabilityStatus' | 'roles' | 'caseContactConnectionId'
  >;
  showLiabilityStatus?: boolean;
  handleContactDelete?: () => void;
  caseId: string;
};

type conditionalOptionalDeleteProps =
  | {
      displayDeleteButton?: never;
      deleteButtonText?: never;
      deleteModalTitle?: never;
      deleteModalDescription?: never;
    }
  | {
      displayDeleteButton: boolean;
      deleteButtonText: string;
      deleteModalTitle: string;
      deleteModalDescription: string;
    };

export function CaseContactConnectionSlideover(
  props: CaseContactConnectionSlideoverProps &
    conditionalOptionalDeleteProps &
    Partial<SlideoverProps>,
) {
  const {
    open = false,
    setOpen = () => {},
    displayDeleteButton,
    deleteButtonText,
    deleteFunction,
    contact,
    handleContactDelete,
    selectedCaseContactConnection,
    showLiabilityStatus,
    deleteModalTitle,
    deleteModalDescription,
    caseId,
  } = props;
  const [typing, setTyping] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: selectedCaseContactConnection,
  });
  const updateCaseContactConnection = useUpdateCaseContactConnection();
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const { data: caseContactConnectionsDataViewModel } =
    useGetCaseContactConnectionsViewModel(caseId);
  const [selectedCCC, setSelectedCCC] = useState(selectedCaseContactConnection);
  const [selectedContact, setSelectedContact] = useState(contact);
  function handleUpdate(field: keyof z.infer<typeof formSchema>, update?: string) {
    if (!selectedCaseContactConnection?.caseContactConnectionId) {
      return;
    }
    updateCaseContactConnection
      .mutateAsync({
        caseContactConnectionId: selectedCaseContactConnection?.caseContactConnectionId,
        [field]: update,
      })
      .then(() => {
        setTyping(false);
      });
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'caseContactConnection') {
      if (selectedCCC && selectedContact) {
        setPendingSlideoverToOpen(undefined);
        setOpen(true);
      }
    }
  }, [pendingSlideoverToOpen, selectedCCC, selectedContact]);

  useEffect(() => {
    const selectedCaseContactConnection = caseContactConnectionsDataViewModel?.data.find(
      (c) => c.caseContactConnectionId === pendingSlideoverToOpen?.id,
    );
    if (selectedCaseContactConnection !== undefined) {
      setSelectedCCC(selectedCaseContactConnection);
      setSelectedContact(selectedCaseContactConnection.contact);
    }
  }, [pendingSlideoverToOpen, caseContactConnectionsDataViewModel?.data]);

  useEffect(() => {
    if (
      selectedCaseContactConnection &&
      Object.entries(selectedCaseContactConnection)?.length !== 0
    ) {
      setSelectedCCC(selectedCaseContactConnection);
      setSelectedContact(contact);
    }
  }, [selectedCaseContactConnection]);
  if (!caseId || !selectedCaseContactConnection) {
    return null;
  }
  return (
    <ResourceSlideover
      title={'Case Contact Connection'}
      description={'View your case contact connection'}
      open={open}
      setOpen={setOpen}
      displayDeleteButton={displayDeleteButton}
      typing={typing}
      deleteButtonText={deleteButtonText}
      deleteFunction={deleteFunction}
      deleteModalTitle={deleteModalTitle}
      deleteModalDescription={deleteModalDescription}
      createType="caseContactConnection"
      resourceId={selectedCCC?.caseContactConnectionId}
      caseId={caseId}
    >
      <TabsContent value="details">
        <div className="flex flex-col gap-4">
          <div className="flex-col">
            <h1 className="font-semibold">Contact</h1>
            <ContactChip
              contactId={selectedContact?.contactId}
              viewOnly={true}
              contactSlideoverDelete={handleContactDelete}
            />
          </div>
          <div className="flex-col">
            <h1 className="font-semibold">Roles</h1>
            <div className="flex flex-wrap items-center gap-1">
              {uniqBy(selectedCCC?.roles, 'roleOnCase')
                ?.filter(
                  (role) =>
                    role.roleOnCase && role.roleOnCase !== caseContactConnectionOptions.party,
                )
                ?.map((role) => (
                  <Chip
                    key={role.caseContactConnectionRoleId}
                    name={startCase(role.roleOnCase)}
                    color={roleColorMap?.[`${role.roleOnCase}`]}
                  />
                ))}
              <EditRolesPopover
                contact={selectedCCC as ContactType & CaseContactConnectionType}
                availableRoleOptions={
                  Object.keys(caseContactConnectionOptions).filter(
                    (role) => role !== caseContactConnectionOptions.party,
                  ) as CaseContactConnectionRoleType['roleOnCase'][]
                }
                caseId={caseId}
                caseContactConnectionId={selectedCCC?.caseContactConnectionId}
              />
            </div>
          </div>
          <div className="flex-col">
            <Form {...form}>
              <form className="mt-3">
                <TextFormInput
                  {...form.register('descriptionOfCaseInvolvement')}
                  title="Description of Case Involvement"
                  type="textarea"
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('descriptionOfCaseInvolvement', target.value);
                  }}
                  handleOnChange={() => {
                    setTyping(true);
                  }}
                />
                {showLiabilityStatus && (
                  <div className="w-[150px] pt-4">
                    <SelectFormInput
                      title="Liability Status"
                      listItems={{
                        Accepted: 'accepted',
                        Denied: 'denied',
                        Unknown: 'unknown',
                      }}
                      listItemsIsObject
                      defaultValue={selectedCaseContactConnection?.liabilityStatus}
                      placeholderText="Liability Status"
                      handleOnChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        if (selectedCaseContactConnection)
                          updateCaseContactConnection.mutate({
                            caseContactConnectionId:
                              selectedCaseContactConnection?.caseContactConnectionId,
                            liabilityStatus: target.value,
                          });
                      }}
                      {...form.register(`liabilityStatus`)}
                    />
                  </div>
                )}
              </form>
            </Form>
          </div>
        </div>
      </TabsContent>
    </ResourceSlideover>
  );
}

export default CaseContactConnectionSlideover;
