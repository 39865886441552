import { cn } from '@colosseum/data';
import { ArrowLeftIcon, FolderIcon } from '@heroicons/react/24/outline';
import { FileList, MgtTemplateProps, useIsSignedIn } from '@microsoft/mgt-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Typography from '../Typography/Typography';
import { Card } from '../shadcn/Card/Card';

// This is a hacky way to do things but onedrive's integration is buggy so we need to double render - I tried to make a random string
// that would never overlap with a file ID
const RERENDER_STRING = '<<FGEUYIWFBQKLWEYYVBDHSKUQTW212316FHBJAFGE81237FJEGUKY>>';

const LoadingTemplate = (props: MgtTemplateProps) => {
  return (
    <div>
      <GladiateLoader />
    </div>
  );
};

const NoDataTemplate = (props: MgtTemplateProps) => {
  return <Typography className="p-4">This folder is empty.</Typography>;
};

const MyEvent = (
  props: MgtTemplateProps & {
    updateFolderId: (id: string) => void;
    breadCrumbs: { name: string; folderId: string }[];
    setBreadCrumbs: React.Dispatch<
      React.SetStateAction<
        {
          name: string;
          folderId: string;
        }[]
      >
    >;
  },
) => {
  const { files } = props.dataContext as {
    files: { folder: boolean; id: string; name: string }[];
  };
  const folders = files?.filter((file) => file?.folder);
  return (
    <div>
      <div className="flex flex-col pt-6 gap-y-2">
        {folders.map((folder) => (
          <button
            key={folder.id}
            onClick={() => {
              props.updateFolderId(folder.id);
              props.setBreadCrumbs(() => [
                ...props.breadCrumbs,
                { name: folder.name ?? '', folderId: folder.id ?? '' },
              ]);
            }}
          >
            <Typography size="sm" className="flex font-semibold gap-x-2">
              <FolderIcon className="h-5 min-w-5 text-atlantic-blue" />
              {folder.name}
            </Typography>
          </button>
        ))}
      </div>
    </div>
  );
};

export function OneDriveRepo() {
  const [isSignedIn] = useIsSignedIn();

  const [currentFolderId, setCurrentFolderId] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState<{ name: string; folderId: string }[]>([
    { name: 'My files', folderId: '' },
  ]);

  const updateFolderId = (id: string) => {
    setCurrentFolderId(RERENDER_STRING);
    setTimeout(() => {
      setCurrentFolderId(id);
    }, 1500);
  };

  if (!isSignedIn) {
    return (
      <Card className="p-6">
        <Typography className="pb-2" variant="heading">
          Not signed in to OneDrive.
        </Typography>
        <Typography variant="subtext">
          Navigate to Integrations in your{' '}
          <Link className="underline text-atlantic-blue hover:text-sky-blue" to="/profile">
            profile
          </Link>{' '}
          to start the sign-in process.
        </Typography>
      </Card>
    );
  }

  return (
    <div className="p-4 border border-gray-300 rounded-xl">
      <div className={cn('flex border-b border-gray-300 py-2')}>
        <button
          className={cn(
            'flex w-8 h-8 rounded-md items-center justify-center',
            breadCrumbs.length > 1 ? 'text-atlantic-blue hover:bg-gray-200 ' : 'text-gray-300',
            'focus:outline-none',
          )}
          onClick={() => {
            updateFolderId(breadCrumbs[breadCrumbs.length - 2].folderId);
            setBreadCrumbs((prev) => prev.slice(0, -1));
          }}
          disabled={breadCrumbs.length <= 1}
        >
          <ArrowLeftIcon className="w-5 h-5" />
        </button>
        {breadCrumbs.map((breadCrumb, index) => {
          return (
            <div key={breadCrumb.folderId} className="flex">
              <button
                className={cn(
                  'hover:underline text-atlantic-blue',
                  currentFolderId === breadCrumb.folderId &&
                    'font-semibold cursor-default hover:no-underline',
                )}
                onClick={(e) => {
                  if (breadCrumb.folderId !== currentFolderId) {
                    updateFolderId(breadCrumb.folderId);
                    setBreadCrumbs((prev) => prev.slice(0, index + 1).map((item) => item));
                  }
                }}
              >
                {breadCrumb.name}
              </button>
              <Typography
                className={cn('leading-8', index === breadCrumbs.length - 1 && 'hidden')}
                variant="subtext"
              >{`/`}</Typography>
            </div>
          );
        })}
      </div>

      <div className={cn('flex flex-wrap')}>
        <div className="flex-[0_0_50%]  min-h-96 border-r border-gray-300">
          {currentFolderId === RERENDER_STRING && <GladiateLoader />}
          <FileList
            id="fileSideMenu"
            key={`sidemenu-${currentFolderId}`}
            itemId={currentFolderId}
            disableOpenOnClick
          >
            <MyEvent
              template="default"
              updateFolderId={updateFolderId}
              setBreadCrumbs={setBreadCrumbs}
              breadCrumbs={breadCrumbs}
            />
          </FileList>
        </div>
        <div className="pl-2 flex-[0_0_50%] min-h-96">
          {currentFolderId === RERENDER_STRING && <GladiateLoader />}
          <span className={cn(currentFolderId === RERENDER_STRING && 'hidden')}>
            <FileList
              key={`repo-${currentFolderId}`}
              id="fileRepo"
              enableFileUpload
              itemRef={currentFolderId}
              itemId={currentFolderId}
            >
              <LoadingTemplate template="loading" />
              <NoDataTemplate template="no-data" />
            </FileList>
          </span>
        </div>
      </div>
    </div>
  );
}

export default OneDriveRepo;
