import { cn } from '@colosseum/data';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import kebabCase from 'lodash/kebabCase';
import { useState } from 'react';
import Typography, { TypographyProps } from '../Typography/Typography';

/* eslint-disable-next-line */
export interface SectionCollapsibleProps {
  chevronClassName?: string;
  children: any;
  className?: string;
  containerClassName?: string;
  dataCy?: string;
  defaultOpen?: boolean;
  textSize?: TypographyProps['size'];
  textVariant?: TypographyProps['variant'];
  title: string;
}

export function SectionCollapsible(props: SectionCollapsibleProps) {
  const {
    chevronClassName,
    children,
    className,
    containerClassName,
    dataCy,
    defaultOpen,
    textSize,
    textVariant,
    title,
  } = props;

  const [sectionOpen, setSectionOpen] = useState(defaultOpen ?? false);

  return (
    <div className="w-full">
      <div
        data-cy={`collapsible-${kebabCase(dataCy ?? title) as string}`}
        onClick={() => {
          setSectionOpen(!sectionOpen);
        }}
        className={cn('pt-2 pb-4 cursor-pointer', containerClassName)}
      >
        <div
          className={cn(
            'flex items-center justify-between h-12 pl-2 border-gray-300',
            textSize && ['xs', 'sm'].includes(textSize) // small border if text is small
              ? 'border-b'
              : 'border-b-2',
            className,
          )}
        >
          <Typography
            size={textSize ?? 'md'}
            variant={textVariant ?? 'semiBold'}
            tag="label"
            className="cursor-pointer select-none"
          >
            {title ?? 'Section Title'}
          </Typography>

          <ChevronRightIcon
            className={cn(
              'w-5 h-5 text-gray-500 ',
              chevronClassName,
              sectionOpen && 'transform rotate-90',
            )}
          />
        </div>
      </div>

      {sectionOpen && <div className="">{children}</div>}
    </div>
  );
}

export default SectionCollapsible;
