import {
  SlideoverContext,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { useContext, useEffect, useState } from 'react';
import CaseExpenses from '../../cases/CaseExpenses/CaseExpenses';
import CaseTrustAccounting from '../CaseTrustAccounting/CaseTrustAccounting';

export interface AccountingSectionProps {
  caseId: string;
}

export function AccountingSection(props: AccountingSectionProps) {
  const { caseId } = props;
  const [activeTab, setActiveTab] = useState('expense');
  const { pendingSlideoverToOpen } = useContext(SlideoverContext);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'expense') {
      setActiveTab('expense');
    } else if (pendingSlideoverToOpen?.type === 'trustAccountTransaction') {
      setActiveTab('trust');
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger className={tabTriggerClass} value="expense">
          Expenses
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} value="trust">
          Trust Ledger
        </TabsTrigger>
      </TabsList>
      <TabsContent value="expense">
        <CaseExpenses caseId={caseId} />
      </TabsContent>
      <TabsContent value="trust">
        <CaseTrustAccounting caseId={caseId} />
      </TabsContent>
    </Tabs>
  );
}

export default AccountingSection;
