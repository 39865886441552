/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CommunicationLogViewModel,
  bitBToB,
  displayContactName,
  enqueueAPISnackbar,
  getCommunicationLogViewModel,
  getGlobalIcon,
  useCreateContact,
  useCreateEmail,
  useCreateManualCommunication,
  useDeleteEmail,
  useDeleteManualCommunication,
  useGetCommunicationLogViewModel,
  useGetCommunicationsForContacts,
  useGetContact,
  useGetContactsInfinite,
  useGetProvisionedPhoneNumbers,
} from '@colosseum/data';
import {
  CaseContactConnectionRoleType,
  ContactType,
  ContactViewModelType,
  ManualMessageType,
} from '@gladiate/types';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CommunicationLogForm from '../CommunicationLogForm/CommunicationLogForm';
import EntityAddPopover from '../EntityAddPopover/EntityAddPopover';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ResourceSlideover from '../ResourceSlideover/ResourceSlideover';
import { SlideoverContext } from '../SlideoverProvider';
import { DataTable } from '../shadcn/data-table/DataTable';
import useGetCommLogsTableFilters from './CommunicationLogTableFilters';
import { columns } from './communication-log-columns';

/* eslint-disable-next-line */
export interface CommunicationLogProps {
  contactIds: string[];
}

export function CommunicationLog(props: CommunicationLogProps) {
  const { contactIds } = props;

  const [selectedCommunication, setSelectedCommunication] = useState<CommunicationLogViewModel>();
  const { caseId } = useParams() as { caseId: string };
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [typing, setTyping] = useState(false);
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const { data: tableData, isLoading: isCommunicationLoading } = useGetCommunicationLogViewModel(
    caseId,
    contactIds,
  );

  const { isLoading: isCommunicationsLoading } = useGetCommunicationsForContacts(contactIds);
  const { data: allContactsData, isLoading: isContactsLoading } = useGetContactsInfinite();
  const { data: contactData } = useGetContact(contactIds.length === 1 ? contactIds[0] : undefined); // only enable if exactly one contact

  const createContact = useCreateContact();

  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  const provisionedPhoneNumbers = provisionedPhoneNumberData?.data;
  const clientCommunicationsNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication',
  );

  const createManualCommunication = useCreateManualCommunication();
  const deleteManualCommunication = useDeleteManualCommunication();
  const createEmailCommunication = useCreateEmail(caseId);
  const deleteEmailCommunication = useDeleteEmail(caseId);

  const contacts =
    contactIds?.length > 1
      ? allContactsData?.data
      : ([contactData?.data] as ContactViewModelType[]);
  const connectedContactsData = contacts?.filter((contact) =>
    contactIds?.includes(contact?.contactId),
  );

  const tableFilters = useGetCommLogsTableFilters(tableData.contacts, connectedContactsData);

  const tableDataWithContacts = tableData?.logs.map((log) => {
    const contactForMessage = contacts?.find((contact) => contact?.contactId === log?.contactId);
    return {
      contact: contactForMessage,
      roleList: contactForMessage
        ? tableData.contacts[contactForMessage.contactId]?.roleList.map((role) => ({
            roleOnCase: role,
          }))
        : '',
      ...log,
    } as CommunicationLogViewModel & {
      contact: ContactType;
      roleList: CaseContactConnectionRoleType[];
    };
  });

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'communicationLog' && !isCommunicationLoading) {
      const communicationLog = tableData?.logs?.find((c) => c.id === pendingSlideoverToOpen?.id);

      if (!communicationLog && !isCommunicationLoading && tableData?.logs.length > 0) {
        enqueueAPISnackbar({
          message: 'Communication log not found (it may have been deleted).',
          variant: 'error',
        });
        !isCommunicationLoading &&
          tableData?.logs.length > 0 &&
          setPendingSlideoverToOpen(undefined);
      } else {
        setSelectedCommunication(communicationLog);
        setSlideoverOpen(true);
        !isCommunicationLoading &&
          tableData?.logs.length > 0 &&
          setPendingSlideoverToOpen(undefined);
      }
    }
  }, [pendingSlideoverToOpen, tableData, isCommunicationLoading]);

  if (isCommunicationsLoading) {
    return <GladiateLoader />;
  }

  const handleDeleteCommunication = (data: CommunicationLogViewModel) => {
    if (data.type === 'emails') {
      deleteEmailCommunication.mutate(data.id);
    } else {
      const update: ManualMessageType = {
        ...(data.type === 'calls' ? { callId: data.id } : {}),
        ...(data.type === 'messages' ? { messageId: data.id } : {}),
      };
      deleteManualCommunication.mutate({ data: update, type: data.type });
    }
  };

  const contactsList: { [key: string]: string } =
    connectedContactsData
      ?.filter((contact) => {
        return contact.firstName || contact.lastName || contact.name;
      })
      .reduce((acc: { [key: string]: string }, contact: ContactType) => {
        return {
          ...acc,
          [displayContactName(contact)]: contact.contactId,
        };
      }, {}) || {};

  const getTableCellLabel = (text: string, icon: string) => {
    const Icon = getGlobalIcon(icon);
    return (
      <span className="flex items-center p-0 text-sm">
        {Icon && <Icon width="22px" />}
        <span className="ps-3 whitespace-nowrap">{text}</span>
      </span>
    );
  };

  return (
    <>
      <ResourceSlideover
        title="Communication Log"
        description="Edit the communication log for this contact. NOTE: This is for record keeping purposes only. This action will not send anything to the contact."
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        typing={typing}
        displayDeleteButton={!bitBToB(selectedCommunication?.isSystemGenerated)}
        deleteFunction={() => {
          if (selectedCommunication) {
            handleDeleteCommunication(selectedCommunication);
          }
        }}
        hideSections={{
          notes: true,
          tasks: true,
          feed: true,
          attachments: !caseId, // communication log from contacts page can't create new attachments
        }}
        resourceId={selectedCommunication?.id || ''}
        caseId={caseId}
        createType="communicationLog"
      >
        <CommunicationLogForm
          selectedCommunication={selectedCommunication}
          setTyping={setTyping}
          contactsList={contactsList}
          clientCommunicationsNumbers={clientCommunicationsNumbers}
        />
      </ResourceSlideover>

      <DataTable
        showSearchBar
        customRightButton={
          <div className="flex gap-3 ml-3">
            <EntityAddPopover
              hideCreateNew
              title="Log Message"
              onAdd={() => {
                createContact.mutateAsync().then((res) => {
                  const contact = res.data;

                  createManualCommunication
                    .mutateAsync({
                      data: {
                        contactId: contact.contactId,
                      },
                      type: 'messages',
                    })
                    .then((res) => {
                      setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                      setSlideoverOpen(true);
                    });
                });
              }}
              primary
              isLoading={createManualCommunication.isLoading || createEmailCommunication.isLoading}
              listItems={
                connectedContactsData
                  ?.filter((contact) => {
                    return contact.firstName || contact.lastName || contact.name;
                  })
                  ?.map((contact) => {
                    return {
                      label: displayContactName(contact),
                      onClick: () => {
                        let toNumber = '';
                        if (
                          tableData.contacts[contact.contactId] &&
                          tableData.contacts[contact.contactId].phoneNumberE164List.length === 1
                        ) {
                          toNumber = tableData.contacts[contact.contactId].phoneNumberE164List[0];
                        }
                        createManualCommunication
                          .mutateAsync({
                            data: {
                              contactId: contact.contactId,
                              ...(toNumber ? { toNumber } : {}),
                              body: '',
                            },
                            type: 'messages',
                          })
                          .then((res) => {
                            setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                            setSlideoverOpen(true);
                          });
                      },
                    };
                  }) ?? []
              }
            />
            <EntityAddPopover
              hideCreateNew
              title="Log Call"
              onAdd={() => {
                createContact.mutateAsync().then((res) => {
                  const contact = res.data;

                  createManualCommunication
                    .mutateAsync({
                      data: {
                        contactId: contact.contactId,
                        body: '',
                      },
                      type: 'calls',
                    })
                    .then((res) => {
                      setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                      setSlideoverOpen(true);
                    });
                });
              }}
              primary
              isLoading={createManualCommunication.isLoading || createEmailCommunication.isLoading}
              listItems={
                connectedContactsData
                  ?.filter((contact) => {
                    return contact.firstName || contact.lastName || contact.name;
                  })
                  ?.map((contact) => {
                    return {
                      label: displayContactName(contact),
                      onClick: () => {
                        let toNumber = '';
                        if (
                          tableData.contacts[contact.contactId] &&
                          tableData.contacts[contact.contactId].phoneNumberE164List.length === 1
                        ) {
                          toNumber = tableData.contacts[contact.contactId].phoneNumberE164List[0];
                        }
                        createManualCommunication
                          .mutateAsync({
                            data: {
                              contactId: contact.contactId,
                              ...(toNumber ? { toNumber } : {}),
                            },
                            type: 'calls',
                          })
                          .then((res) => {
                            setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                            setSlideoverOpen(true);
                          });
                      },
                    };
                  }) ?? []
              }
            />
            <EntityAddPopover
              hideCreateNew
              title="Log Email"
              onAdd={() => {
                createContact.mutateAsync().then((res) => {
                  const contact = res.data;
                  createEmailCommunication
                    .mutateAsync({
                      contactId: contact.contactId,
                    })
                    .then((res) => {
                      setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                      setSlideoverOpen(true);
                    });
                });
              }}
              primary
              isLoading={createManualCommunication.isLoading || createEmailCommunication.isLoading}
              listItems={
                connectedContactsData
                  ?.filter((contact) => {
                    return contact.firstName || contact.lastName || contact.name;
                  })
                  ?.map((contact) => {
                    return {
                      label: displayContactName(contact),
                      onClick: () => {
                        createEmailCommunication
                          .mutateAsync({
                            contactId: contact.contactId,
                          })
                          .then((res) => {
                            setSelectedCommunication(getCommunicationLogViewModel(res.data.data));
                            setSlideoverOpen(true);
                          });
                      },
                    };
                  }) ?? []
              }
            />
          </div>
        }
        columns={columns}
        data={tableDataWithContacts ?? []}
        handleRowClick={(row) => {
          setSelectedCommunication(row.original);
          setSlideoverOpen(true);
        }}
        isLoading={isCommunicationsLoading || isContactsLoading}
        tableName="communications-table"
        initialSort={{
          id: 'Date',
          desc: true,
        }}
        filters={tableFilters}
      />
    </>
  );
}

export default CommunicationLog;
