import {
  GladiateLoader,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { CaseType } from '@gladiate/types';
import IncidentForm from '../IncidentForm/IncidentForm';
import PartiesAndPolicies from '../PartiesAndPolicies/PartiesAndPolicies';

/* eslint-disable-next-line */
export interface IncidentSectionProps {
  caseData?: CaseType;
  isCaseLoading: boolean;
}

export function IncidentSection(props: IncidentSectionProps) {
  const { caseData, isCaseLoading } = props;
  return (
    <Tabs defaultValue="partiesPolicies">
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger className={tabTriggerClass} value="partiesPolicies">
          Parties and Policies
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} value="details">
          Details
        </TabsTrigger>
      </TabsList>
      <TabsContent value="partiesPolicies">
        <PartiesAndPolicies caseId={caseData?.caseId ?? ''} />
      </TabsContent>
      {caseData ? (
        <IncidentForm isCaseLoading={isCaseLoading} caseData={caseData} />
      ) : (
        <GladiateLoader />
      )}
    </Tabs>
  );
}

export default IncidentSection;
