import {
  getGlobalIcon,
  useAcknowledgeNotifications,
  useGetCases,
  useGetFirmUserWithDisplayNameFromUsername,
} from '@colosseum/data';
import { AuditWithNotificationsType } from '@gladiate/types';
import { BellIcon } from '@heroicons/react/20/solid';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { map, startCase, uniq } from 'lodash';
import { PlusCircle } from 'lucide-react';
import { useMemo, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import ResourceHistoryDetail from '../ResourceHistoryDetail/ResourceHistoryDetail';
import SectionCollapsible from '../SectionCollapsible/SectionCollapsible';
import Toggle from '../Toggle/Toggle';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { Card } from '../shadcn/Card/Card';
import { DefaultCalendar } from '../shadcn/DefaultCalendar/DefaultCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { dayjsDateTime } from '../shadcn/data-table/DataTable';
import { DataTablePagination } from '../shadcn/data-table/DataTablePagination';
import { DataTableToolbar } from '../shadcn/data-table/DataTableToolbar';
import { getRangeValue, onRangeSelect } from '../shadcn/data-table/table-filter-helpers';
import { formatDatesForFilterButton } from '../shadcn/data-table/table-render-helpers';
import { columns } from './resource-history-columns';
dayjs.extend(calendar);

/* eslint-disable-next-line */
export interface ResourceHistoryProps {
  isPaginated?: boolean;
  showCaseFilter?: boolean;
  audits?: {
    data?: AuditWithNotificationsType[];
    isLoading: boolean;
    notificationCount?: string;
    notificationsOnly: boolean;
    setNotificationsOnly: (value: boolean) => void;
  };
  isAuditsLoading?: boolean;
  notificationsOnly: boolean;
  setNotificationsOnly: (value: boolean) => void;
  isLead?: boolean;
}

const MarkAsReadButton = ({ notificationId }: { notificationId: string }) => {
  const acknowledgeNotifications = useAcknowledgeNotifications();
  return (
    <Button
      variant="ghost"
      key={notificationId}
      onClick={() => {
        acknowledgeNotifications.mutate({
          notifications: [notificationId],
        });
      }}
    >
      {acknowledgeNotifications.isLoading ? (
        <Typography color={'blue'}>Loading...</Typography>
      ) : (
        <Typography color={'blue'}>Mark as read</Typography>
      )}
    </Button>
  );
};

export function ResourceHistory(props: ResourceHistoryProps) {
  const {
    isPaginated,
    showCaseFilter,
    audits,
    isAuditsLoading,
    notificationsOnly,
    setNotificationsOnly,
    isLead,
  } = props;
  const { data: caseData } = useGetCases();
  const acknowledgeNotifications = useAcknowledgeNotifications();

  const tableFilters = useMemo(() => {
    const typeOptions = uniq(map(audits?.data, 'type'));
    const filters: {
      id: string;
      options: { value: string; label: string }[];
    }[] = [
      {
        id: 'Type',
        options: typeOptions
          .filter((type) => !!type)
          .sort()
          .map((type) => {
            return {
              value: type,
              label: startCase(type),
              icon: getGlobalIcon(type),
            };
          }),
      },
    ];
    if (showCaseFilter) {
      filters.push({
        id: 'Case',
        options:
          caseData?.data
            .filter((c) => c.caseTitle)
            .map((c) => {
              return { value: c.caseId, label: c.caseTitle ?? '-' };
            }) ?? [],
      });
    }
    return filters;
  }, [audits?.data, showCaseFilter, caseData?.data]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data: audits?.data ?? [],
    columns,
    state: {
      columnFilters,
    },
    sortingFns: {
      dayjsDateTime: dayjsDateTime,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: isPaginated ? getPaginationRowModel() : undefined,
    autoResetPageIndex: isPaginated ? false : undefined,
    filterFromLeafRows: false,
  });
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  return (
    <div>
      <DataTableToolbar
        filters={tableFilters}
        table={table}
        hideViewButton
        customRightButton={
          <div className="flex gap-2">
            <div className="flex items-center h-8 p-1 px-3 text-sm font-medium border border-gray-300 border-dashed rounded-md gap-x-2">
              <Toggle
                newState={notificationsOnly}
                onToggleChange={(value) => setNotificationsOnly(value)}
                editable
              />
              <span>My notifications</span>
            </div>
            <Button
              variant="outline"
              size="xs"
              disabled={['0', undefined].includes(audits?.notificationCount)}
              onClick={() => {
                const notifications =
                  audits?.data
                    ?.filter((audit) => audit.notification?.notificationId)
                    .map((audit) => audit.notification?.notificationId ?? '') ?? [];
                if (notifications && notifications.length) {
                  acknowledgeNotifications.mutate({
                    notifications: notifications ?? [],
                  });
                }
              }}
            >
              Mark all as Read
            </Button>
          </div>
        }
        customButtons={
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" size="sm" className="h-8 border-dashed">
                <PlusCircle className="w-4 h-4 mr-2" />
                Last Modified
                {formatDatesForFilterButton(table, 'Last Modified')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full p-4 bg-white " align="center">
              <div className="flex">
                <DefaultCalendar
                  mode="single"
                  selected={getRangeValue(0, 'Last Modified', table)}
                  onSelect={(date) => onRangeSelect(0, 'Last Modified', table, date)}
                  initialFocus
                />
                <DefaultCalendar
                  mode="single"
                  selected={getRangeValue(1, 'Last Modified', table)}
                  onSelect={(date) => onRangeSelect(1, 'Last Modified', table, date)}
                  initialFocus
                />
              </div>
            </PopoverContent>
          </Popover>
        }
      />

      {isAuditsLoading ? (
        <GladiateLoader />
      ) : (
        <div className="flex flex-col py-2 gap-y-4">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              const isLead = row.original.type === 'lead';

              return (
                <Card key={row.original.auditId} className="p-6">
                  <Breadcrumbs
                    resourceType={row.original.type}
                    resourceId={row.original.auditId}
                    isLead={isLead}
                  />
                  <div className="flex justify-between gap-x-6">
                    <div className="flex flex-col grow">
                      <div className="pt-4 text-lg font-semibold">Most Recent update:</div>
                      <div className="flex justify-between pl-4 gap-x-2 grow">
                        <div className="content-center text-md">
                          {row.original.mostRecentAuditMessage ?? '-'}
                        </div>
                        {row.original?.notification && (
                          <div className="flex justify-between grow">
                            <div className="self-center">
                              <BellIcon height={20} className="text-orange-500" />
                            </div>
                            <MarkAsReadButton
                              notificationId={row.original.notification?.notificationId ?? ''}
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex items-center pl-4 mt-2">
                        <span className="flex items-center pr-4 text-xs gap-x-2">
                          <ProfilePhoto
                            className="self-center text-xs"
                            loading={false}
                            diameter={3}
                            displayName={row.original.mostRecentUsername ?? ''}
                          />
                          {getFirmUserWithDisplayNameFromUsername(
                            row.original.mostRecentUsername ?? '',
                          )?.displayName ?? 'System'}
                        </span>
                        <span className="text-xs text-gray-500 ">
                          {dayjs(`${row.original.dateModified}Z`).format(
                            // Z is so that it converts to local time
                            'MMM DD YYYY, h:mm a',
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <SectionCollapsible
                    title="All updates"
                    className="justify-start pl-0 text-sm font-normal border-b-0 gap-x-2"
                    containerClassName="pb-0"
                    chevronClassName="h-3 w-3"
                  >
                    <div className="pb-4">
                      <ResourceHistoryDetail
                        auditId={row.original.auditId}
                        notificationAuditIds={row.original?.notification?.auditDetails}
                      />
                    </div>
                  </SectionCollapsible>
                  <h2 className="pb-2 text-sm text-gray-500">
                    {dayjs(`${row?.original?.dateModified}Z`).calendar()}
                  </h2>
                </Card>
              );
            })
          ) : (
            <div>
              <div className="flex flex-col p-2">
                <div className="py-8 font-semibold">
                  <Typography variant="subtext">
                    {notificationsOnly
                      ? 'You’re all caught up, no notifications to show.'
                      : 'No Feed items to show.'}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isPaginated && <DataTablePagination table={table} />}
    </div>
  );
}

export default ResourceHistory;
