import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  assignTeamV3,
  createTeamV3,
  createTeammateV3,
  deleteTeamV3,
  deleteTeammateV3,
  getTeamsV3,
  updateTeamV3,
} from '../requests/legion';

// Team
export const useGetTeams = () => {
  return useQuery({
    queryKey: [queryKeys.teams],
    queryFn: async () => getTeamsV3(),
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createTeamV3>[0]) => createTeamV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.teams],
      });
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateTeamV3>[0]) => updateTeamV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.teams],
      });
    },
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (teamId: Parameters<typeof deleteTeamV3>[0]) => deleteTeamV3(teamId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.teams],
      });
    },
  });
};

export const useAssignTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof assignTeamV3>[0]) => assignTeamV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff],
      });
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.cases],
        });
      }, 1000); // not a fan of this but since this is sending an event to cases,
      // we need to give it some time to process before revalidating the case data
    },
  });
};

// Teammate
export const useCreateTeammate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createTeammateV3>[0]) => createTeammateV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.teams],
      });
    },
  });
};

export const useDeleteTeammate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (teammateId: Parameters<typeof deleteTeammateV3>[0]) =>
      deleteTeammateV3(teammateId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.teams],
      });
    },
  });
};
