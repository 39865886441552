import {
  conflictsOptions,
  daysInStatusRelativeToGoalOptions,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetReferralSources,
  useGetTeams,
} from '@colosseum/data';
import { Typography } from '@colosseum/shared-ui';
import { startCase } from 'lodash';

export interface PresetFilterChipProps {
  title: string;
  filterName: string;
}

export function PresetFilterChip(props: PresetFilterChipProps) {
  const { title, filterName } = props;
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const { data: teamsData } = useGetTeams();
  const { data: referralSourcesData } = useGetReferralSources();
  const modifiedTitleByFilterName = () => {
    switch (filterName) {
      case 'assignees':
        return getFirmUserWithDisplayNameFromUsername(title)?.displayName;
      case 'teams':
        return teamsData?.data?.find((team) => team.teamId === title)?.title;
      case 'daysInStatusRelativeToGoal':
        return daysInStatusRelativeToGoalOptions.find((option) => option.value === title)?.label;
      case 'conflicts':
        return conflictsOptions.find((option) => option.value === title)?.label;
      case 'contactTypes':
        return startCase(title);
      case 'referralSources':
        return (
          referralSourcesData?.data?.find(
            (referralSource) => referralSource.referralSourceId === title,
          )?.title ?? ''
        );
      default:
        return title;
    }
  };
  return (
    <div className="flex flex-col px-4 py-2 bg-gray-100 rounded-full w-fit">
      <Typography size="sm" className="line-clamp-2">
        {modifiedTitleByFilterName()}
      </Typography>
    </div>
  );
}

export default PresetFilterChip;
