export interface ErrorPageProps {
  code: number;
  errorTitle: string;
  errorMessage: string;
  backRoute?: string;
  backMessage?: string;
}

export function ErrorPage(props: ErrorPageProps) {
  return (
    <div className="h-[calc(100vh-6.625rem)] lg:h-[calc(100vh-3rem)] p-5 sm:p-10" data-cy="error">
      <div className="flex flex-col min-h-full bg-white border border-gray-300 rounded-lg">
        <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-12xl sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-atlantic-blue">{props.code}</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {props.errorTitle}
              </h1>
              <p className="mt-2 text-base text-gray-500">{props.errorMessage}</p>
              <div className="mt-6">
                <a
                  href={props.backRoute ?? '/home'}
                  className="text-base font-medium text-atlantic-blue hover:text-sky-blue"
                >
                  <span className="mr-2" aria-hidden="true">
                    &larr;
                  </span>
                  {props.backMessage ?? 'Go back home'}
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ErrorPage;
