import { useGetFirmUsers, useGetStaff } from '@colosseum/data';
import { HurinUserType, StaffType } from '@gladiate/types';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Typography from '../../Typography/Typography';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover/Popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '../Select/Select';

export const TagUserPopover = ({
  message,
  setMessage,
  cursorRef,
}: {
  message: string;
  setMessage: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  cursorRef: React.ForwardedRef<HTMLTextAreaElement>;
}) => {
  const { caseId } = useParams();
  const { data: staffRolesData } = useGetStaff(caseId);
  const firmUsersQuery = useGetFirmUsers();
  const popoverTriggerRef = useRef(null);

  const [isMentionPopoverOpen, setIsMentionPopoverOpen] = useState(false);
  const [lastKey, setLastKey] = useState(message);
  const [userIndex, setUserIndex] = useState(0);
  const [userFilter, setUserFilter] = useState('');
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });

  const firmUsers = firmUsersQuery?.data?.data;

  const filteredFirmUsers = firmUsers?.filter(
    (staff) =>
      staff?.username?.toLowerCase().includes(userFilter) ||
      staff?.email?.toLowerCase().includes(userFilter) ||
      staff?.name?.toLowerCase().includes(userFilter),
  );

  const usernamesOnCase = staffRolesData?.data.map((staff: StaffType) => {
    return staff?.username;
  });

  const firmUsersNotOnCase: HurinUserType[] = [];

  const firmUsersOnCase = filteredFirmUsers?.filter((user) => {
    if (usernamesOnCase?.includes(user?.username)) {
      return true;
    } else {
      firmUsersNotOnCase.push(user);
      return false;
    }
  });

  function handleUserTagClick(staff: HurinUserType) {
    if (userFilter) {
      setMessage?.(
        // @ts-expect-error - string is acceptable
        `${message.substring(0, userIndex)}@${staff.Username}${message.substring(
          userIndex + userFilter.length + 1,
        )} `,
      );
    } else if (message[message.length - 1] !== '@') {
      // @ts-expect-error - string is acceptable
      setMessage?.(`${message.substring(0, userIndex)}@${staff.Username} `);
    } else {
      // @ts-expect-error - string is acceptable
      setMessage?.(`${message}${staff.Username} `);
    }

    setIsMentionPopoverOpen(false);
    setLastKey('');
    setUserFilter('');
    setUserIndex(0);
    // @ts-expect-error - cursorRef is not null
    cursorRef?.current?.focus();
  }

  function handleClosePopover() {
    // @ts-expect-error - cursorRef is not null
    cursorRef?.current?.focus();
    setIsMentionPopoverOpen(false);
    setLastKey('');
    setUserFilter('');
    setUserIndex(0);
  }

  useEffect(() => {
    // this handles the positioning of the popover so that it tracks left to right with the user input
    // @ts-expect-error - cursorRef is not null
    if (cursorRef?.current && message?.length > 0) {
      // @ts-expect-error - cursorRef is not null
      const selectionStart = cursorRef?.current.selectionStart;
      // @ts-expect-error - cursorRef is not null
      const textBeforeCaret = cursorRef?.current.value.substring(0, selectionStart);
      const tempSpan = document.createElement('span');
      tempSpan.style.whiteSpace = 'pre'; // Preserve spaces
      tempSpan.textContent = textBeforeCaret;
      document.body.appendChild(tempSpan);
      document.body.removeChild(tempSpan);
      const lines = message.split('\n');
      const lineIndex = lines.length - 1;
      const line = lines[lineIndex];
      const lineLength = line.length;
      const cursorPosition = {
        x: lineLength * 6 + 50,
        y: lineIndex + 90,
      };
      setPopoverPosition(cursorPosition);
    }
  }, [message]);

  useEffect(() => {
    // sets the index of the user string in the message for use in splitting the username string after the @ symbol
    if (lastKey === '@') {
      setIsMentionPopoverOpen(true);
      if (!userFilter.includes('@')) {
        setUserIndex(message.length - 1);
      }
    }
  }, [lastKey]);

  useEffect(() => {
    // parses a username string from the message as its being typed
    setLastKey(message[message?.length - 1]);
    if (isMentionPopoverOpen) {
      const userString = message.substring(userIndex);
      setUserFilter(userString.substring(1).toLowerCase());
    }
  }, [message]);

  return (
    <Popover open={isMentionPopoverOpen}>
      <PopoverTrigger></PopoverTrigger>
      <PopoverContent
        className="invisible absolute -top-20"
        style={{
          left: popoverPosition.x,
          // top: popoverPosition.y - 100,
        }}
        onInteractOutside={(e) => {
          handleClosePopover();
        }}
      >
        <Select open={isMentionPopoverOpen}>
          <SelectTrigger></SelectTrigger>
          <SelectContent
            ref={popoverTriggerRef}
            onKeyDownCapture={(e) => {
              const key = e.key;

              function isKeyAllowed(key: string) {
                // Use your preferred filtering logic here
                // what if there is a space?
                return /^[a-zA-Z0-9]$/.test(key);
              }
              if (key === 'Backspace') {
                // @ts-expect-error - string is acceptable
                setMessage?.(message.slice(0, -1));
              }
              if (isKeyAllowed(key)) {
                // @ts-expect-error - string is acceptable
                setMessage?.(`${message}${e.key}`);
              }

              if (key === 'Escape' || key === ' ' || (key === 'Backspace' && lastKey === '@')) {
                handleClosePopover();
              }
            }}
          >
            {firmUsersOnCase?.length === 0 && firmUsersNotOnCase?.length === 0 && (
              <Typography size={'xs'} className="pl-2 py-1">
                No users found...
              </Typography>
            )}
            <SelectGroup
              key="Staff"
              defaultValue={firmUsersOnCase && firmUsersOnCase[0]?.name}
              className=" max-h-[200px] overflow-y-auto"
            >
              {firmUsersOnCase && firmUsersOnCase?.length > 0 && (
                <>
                  <Typography variant={'grayUpper'} size={'xs'} className="pl-2 py-1">
                    Staff
                  </Typography>
                  {firmUsersOnCase?.map((user) => (
                    <SelectItem
                      className="focus:outline-none list-none cursor-pointer hover:bg-gray-100 hover:rounded-md w-full"
                      key={user?.username}
                      onClick={() => handleUserTagClick(user)}
                      onSelect={() => handleUserTagClick(user)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleUserTagClick(user);
                        }
                      }}
                      value={user?.username || ''}
                      noIndicator={true}
                    >
                      <Typography size={'xs'} key={user?.username}>
                        {user.name}
                      </Typography>
                    </SelectItem>
                  ))}
                </>
              )}

              {firmUsersNotOnCase && firmUsersNotOnCase?.length > 0 && (
                <>
                  <Typography variant={'grayUpper'} size={'xs'} className="pl-2 py-1">
                    Firm Users
                  </Typography>
                  {firmUsersNotOnCase?.map((user) => (
                    <SelectItem
                      value={user?.username || ''}
                      noIndicator={true}
                      key={user?.username}
                      onClick={() => handleUserTagClick(user)}
                      onSelect={() => handleUserTagClick(user)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleUserTagClick(user);
                        }
                      }}
                    >
                      <Typography size={'xs'} key={user?.username}>
                        {user.name}
                      </Typography>
                    </SelectItem>
                  ))}
                </>
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
      </PopoverContent>
    </Popover>
  );
};
