import {
  LeadListViewType,
  findRoleSpecificCaseContactConnections,
  useCaseCreationAndUpdateQueue,
  useGetAuditsWithNotifications,
  useGetCaseContactConnections,
  useGetContact,
} from '@colosseum/data';
import {
  GladiateLoader,
  ResourceHistory,
  ResourceNotes,
  ResourceTasks,
  TabsContent,
} from '@colosseum/shared-ui';
import {
  CaseContactConnectionType,
  ContactViewModelType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import LeadForm from '../LeadForm/LeadForm';

/* eslint-disable-next-line */
export interface LeadSlideoverProps {
  casesData?: LeadListViewType[];
  isCasesLoading?: boolean;
}

export function LeadSlideover(props: LeadSlideoverProps) {
  const { caseId } = useParams() as { caseId: string };
  const { casesData, isCasesLoading } = props;
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId ?? '', 'cases');
  const audits = useGetAuditsWithNotifications({
    itemId: caseId,
    isCase: true,
  });

  const caseClientConnection = findRoleSpecificCaseContactConnections<CaseContactConnectionType>({
    caseContactConnections: caseContactConnectionsData?.data,
    role: caseContactConnectionOptions.client,
  })[0];
  const { data: clientContactData, isLoading: isClientContactLoading } = useGetContact(
    caseClientConnection?.contactId,
  );
  const clientContact = clientContactData?.data;

  const { handleUpdates, setCreatedIds, createdIds } = useCaseCreationAndUpdateQueue();

  const lead = casesData?.find((c) => c.caseId === caseId) ?? ({} as LeadListViewType);

  useEffect(() => {
    if (
      createdIds.caseId === (lead?.caseId ?? '') &&
      createdIds.contactId === (clientContact?.contactId ?? '') &&
      createdIds.phoneNumberId === (clientContact?.contactNumbers?.[0]?.contactNumberId ?? '') &&
      createdIds.emailId === (clientContact?.contactEmails?.[0]?.contactEmailId ?? '') &&
      createdIds.conscriptionId === (caseClientConnection?.caseContactConnectionId ?? '')
    )
      return;
    setCreatedIds({
      caseId: lead?.caseId ?? '',
      contactId: clientContact?.contactId ?? '',
      phoneNumberId: clientContact?.contactNumbers?.[0]?.contactNumberId ?? '',
      emailId: clientContact?.contactEmails?.[0]?.contactEmailId ?? '',
      conscriptionId: caseClientConnection?.caseContactConnectionId ?? '',
    });
  }, [lead, caseClientConnection, clientContact]);

  return (
    <>
      <TabsContent value="details">
        {isCasesLoading || isClientContactLoading ? (
          <GladiateLoader />
        ) : (
          <LeadForm
            lead={
              {
                ...lead,
                ...clientContact,
              } as LeadListViewType & ContactViewModelType
            }
            isLeadLoading={isCasesLoading || isClientContactLoading}
          />
        )}
      </TabsContent>
      <TabsContent value="notes">
        <ResourceNotes
          scrollable
          createType="case"
          resourceId={caseId ?? ''}
          showFilters
          caseId={caseId}
          isLead
        />
      </TabsContent>
      <TabsContent value="tasks">
        <ResourceTasks createType="case" isPaginated resourceId={caseId} scrollable isLead />
      </TabsContent>
      <TabsContent value="history">
        <ResourceHistory
          isPaginated={true}
          audits={audits}
          isAuditsLoading={audits.isLoading}
          setNotificationsOnly={audits.setNotificationsOnly}
          notificationsOnly={audits.notificationsOnly}
          isLead
        />
      </TabsContent>
    </>
  );
}

export default LeadSlideover;
