import {
  copyToClipboard,
  enqueueAPISnackbar,
  prettifyDateString,
  useCreateCaseSurvey,
  useDeleteCaseSurvey,
  useGetListCaseSurveys,
  useGetListSurveys,
} from '@colosseum/data';
import {
  DataTable,
  EntityAddPopover,
  ListItem,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';
import { CaseSurvey, TypeformAttributes } from '@gladiate/types';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { columns } from './case-survey-table-columns';

function CaseSurveyDataAccess(caseId: string) {
  const { data: availableSurveys, isLoading: availableSurveysLoading } = useGetListSurveys();

  const {
    data: caseSurveysData,
    isLoading: caseSurveysLoading,
    isFetching: caseSurveysFetching,
  } = useGetListCaseSurveys(caseId);

  const createCaseSurvey = useCreateCaseSurvey();
  const deleteCaseSurvey = useDeleteCaseSurvey();

  return {
    availableSurveys: availableSurveys?.data,
    availableSurveysLoading,
    caseSurveys: caseSurveysData?.data,
    caseSurveysLoading,
    createCaseSurvey,
    deleteCaseSurvey,
    caseSurveysFetching,
  };
}

/* eslint-disable-next-line */
export interface CaseSurveysProps {}

export function CaseSurveys(props: CaseSurveysProps) {
  // #region Hooks
  const { caseId } = useParams() as { caseId: string };
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  // #endregion

  // #region Data Fetching
  const {
    availableSurveys,
    availableSurveysLoading,
    caseSurveysLoading,
    caseSurveys,
    createCaseSurvey,
    deleteCaseSurvey,
    caseSurveysFetching,
  } = CaseSurveyDataAccess(caseId);
  // #endregion

  // #region State
  const [caseSurveyDetailsSlideover, setCaseSurveyDetailsSlideover] = useState(false);
  const [selectedCaseSurveyId, setSelectedCaseSurveyId] = useState('');
  // #endregion

  // #region Derived State
  const selectedCaseSurvey = useMemo(() => {
    return caseSurveys?.find((survey) => survey.caseSurveyId === selectedCaseSurveyId);
  }, [caseSurveys, selectedCaseSurveyId]);
  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers
  function handleClick(e: Row<CaseSurvey>) {
    setCaseSurveyDetailsSlideover(true);
    setSelectedCaseSurveyId(e.original.caseSurveyId);
  }
  // #endregion

  const surveyListItem: ListItem[] =
    availableSurveys?.map((survey) => ({
      label: survey.typeform.title,
      onClick: () => {
        createCaseSurvey.mutateAsync({ caseId, surveyId: survey.surveyId }).then((res) => {});
      },
    })) ?? [];

  function formatTypeformFieldAnswer(field: TypeformAttributes['fields'][0]) {
    if (field?.type === 'date') {
      return prettifyDateString(field?.answer);
    }
    return field?.answer;
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'caseSurvey' && !caseSurveysLoading) {
      if (pendingSlideoverToOpen?.id) {
        setSelectedCaseSurveyId(pendingSlideoverToOpen?.id);
        setCaseSurveyDetailsSlideover(true);
      } else {
        enqueueAPISnackbar({
          message: 'Case survey not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, caseSurveys]);
  return (
    <>
      <DataTable
        data={caseSurveys ?? []}
        showSearchBar
        columns={columns}
        handleRowClick={handleClick}
        isLoading={
          caseSurveysLoading ||
          caseSurveysFetching ||
          deleteCaseSurvey.isLoading ||
          createCaseSurvey.isLoading
        }
        tableName="Case Survey Details"
        hideViewButton
        customRightButton={
          <EntityAddPopover
            hideCreateNew
            title="Add Survey"
            onAdd={() => {}}
            primary
            isLoading={availableSurveysLoading || createCaseSurvey.isLoading}
            listItems={surveyListItem}
          />
        }
      />
      <ResourceSlideover
        open={caseSurveyDetailsSlideover}
        setOpen={setCaseSurveyDetailsSlideover}
        title="Survey Details"
        description="View the details of the selected survey"
        displayDeleteButton={true}
        deleteButtonText="Delete Case Survey"
        deleteFunction={() => {
          deleteCaseSurvey.mutateAsync(selectedCaseSurveyId);
        }}
        caseId={caseId}
        resourceId={selectedCaseSurveyId}
        createType="caseSurvey"
      >
        <TabsContent value="details">
          <h2 className="text-lg font-bold">{selectedCaseSurvey?.typeform.title}</h2>
          <table className="min-w-full">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-md font-semibold text-gray-900 w-1/2"
                >
                  Survey Item(s)
                </th>
              </tr>
            </thead>
            <tbody className="box-content m-10 bg-white divide-y divide-gray-200">
              {selectedCaseSurvey?.typeform.fields.map((field, index) => (
                <tr key={index} className="p-5">
                  <td className="px-3 py-2 text-sm text-gray-900">{field.question}</td>
                  <td className="">
                    <div className="flex justify-between ">
                      <div className="w-full px-3 text-sm text-blue-600 rounded-md bg-slate-100">
                        {field.answer && formatTypeformFieldAnswer(field)}
                      </div>
                      {field.answer && (
                        <ClipboardDocumentCheckIcon
                          className="w-5 h-5 pl-1 text-gray-900 cursor-pointer hover:text-atlantic-blue"
                          onClick={() => {
                            copyToClipboard(`${field.answer}`);
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabsContent>
      </ResourceSlideover>
    </>
  );
}

export default CaseSurveys;
