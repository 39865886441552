import { settingsSectionMappings } from '@colosseum/data';
import { Tabs, TabsContent, TabsList, TabsTrigger, tabTriggerClass } from '@colosseum/shared-ui';
import CustomAttributesTable from '../CustomAttributesTable/CustomAttributesTable';

export function CustomAttributes() {
  return (
    <Tabs defaultValue="cases">
      <h1 className="pl-2 text-xl font-semibold text-gray-900">Custom Attributes</h1>
      <TabsList
        id="custom-fields"
        data-settings-submenu-item={settingsSectionMappings.customAttributes}
        className="my-4 text-gray-500 bg-transparent"
      >
        <TabsTrigger
          className={tabTriggerClass}
          key="cases"
          value="cases"
          data-cy="custom-fields-cases"
        >
          Cases
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="contacts"
          value="contacts"
          data-cy="custom-fields-contacts"
        >
          Contacts
        </TabsTrigger>
      </TabsList>
      <TabsContent value="cases">
        <CustomAttributesTable attributeResource="case" />
      </TabsContent>
      <TabsContent value="contacts">
        <CustomAttributesTable attributeResource="contact" />
      </TabsContent>
    </Tabs>
  );
}

export default CustomAttributes;
