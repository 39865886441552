import {
  US_STATES,
  bToBitB,
  bitBToB,
  booleanToString,
  caseIncidentFormSchema,
  useCaseIncidentForm,
} from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  CheckboxFormInput,
  Form,
  GladiateLoader,
  SelectFormInput,
  Switch,
  TabsContent,
  TextFormInput,
} from '@colosseum/shared-ui';
import { CaseType, caseContactConnectionOptions } from '@gladiate/types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { IncidentFormFiles } from './IncidentFormFiles';

/* eslint-disable-next-line */
export interface IncidentFormProps {
  caseData?: CaseType;
  isCaseLoading: boolean;
}

export function IncidentForm(props: IncidentFormProps) {
  const { caseData, isCaseLoading } = props;
  const [typing, setTyping] = useState(false);
  const { form, handleUpdate } = useCaseIncidentForm({
    caseData,
    setTyping,
  });

  const renderTextFormInput = (
    key: keyof typeof caseIncidentFormSchema.shape,
    title: string,
    type?: string,
  ) => (
    <TextFormInput
      {...form.register(key)}
      handleOnBlur={(e) => {
        handleUpdate(key, e.target.value);
      }}
      title={title}
      type={type}
    />
  );

  return (
    <Form {...form}>
      <form>
        <TabsContent value="details">
          {isCaseLoading || !caseData ? (
            <GladiateLoader />
          ) : (
            <div className="w-full">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                {renderTextFormInput('incidentStreet', 'Location (Street)')}
                {renderTextFormInput('incidentCity', 'Location (City)')}
                {renderTextFormInput('incidentCounty', 'Location (County)')}
                <SelectFormInput
                  title="Location (State)"
                  listItems={US_STATES}
                  defaultValue={caseData?.incidentState}
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleUpdate('incidentState', e.target.value);
                  }}
                  {...form.register('incidentState')}
                />
                {renderTextFormInput('incidentZipCode', 'Location (Zip Code)')}
                <CalendarFormInput
                  {...form.register(`dateOfIncident`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('dateOfIncident', target.value);
                  }}
                  title="Date of Incident"
                  resourceTypeObj={{
                    type: 'case-incident-date',
                    id: caseData.caseId,
                  }}
                />
                <CalendarFormInput
                  {...form.register(`statuteOfLimitations`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('statuteOfLimitations', target.value);
                  }}
                  title="Statute of Limitations"
                  disabled={(date) => dayjs(date) > dayjs().add(10, 'years')}
                  resourceTypeObj={{
                    type: 'case-statute-of-limitations',
                    id: caseData.caseId,
                  }}
                  additionalCalendarProps={
                    caseData?.estimatedStatuteOfLimitations && !caseData?.statuteOfLimitations
                      ? {
                          defaultMonth: dayjs(caseData?.estimatedStatuteOfLimitations).toDate(),
                          modifiers: {
                            estimatedStatute: dayjs(
                              caseData?.estimatedStatuteOfLimitations,
                            ).toDate(),
                          },
                          modifiersStyles: {
                            estimatedStatute: {
                              border: '1px dashed gray',
                            },
                          },
                          footer: (
                            <div className="text-xs">{`The outlined date is Gladiate's best estimate of the Statute of Limitations based on the type and location of the case. Please confirm before selecting.`}</div>
                          ),
                        }
                      : undefined
                  }
                />
                {renderTextFormInput('statuteOfLimitationsLabel', 'Statute of Limitations Label')}
                <CheckboxFormInput
                  {...form.register('statuteOfLimitationsSatisfied')}
                  handleOnChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('statuteOfLimitationsSatisfied', booleanToString(target.value));
                  }}
                  isDisabled={!caseData.statuteOfLimitations}
                  isDisabledMessage='Please set "Statute of Limitations" first.'
                  title="Statute of Limitations Satisfied?"
                />
                <div></div>
                <CalendarFormInput
                  {...form.register(`additionalStatuteOfLimitations`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('additionalStatuteOfLimitations', target.value);
                  }}
                  title="Additional Statute of Limitations"
                  disabled={(date) => dayjs(date) > dayjs().add(10, 'years')}
                  resourceTypeObj={{
                    type: 'case-additional-statute-of-limitations',
                    id: caseData.caseId,
                  }}
                  additionalCalendarProps={
                    caseData?.estimatedAdditionalStatuteOfLimitations &&
                    !caseData?.additionalStatuteOfLimitations
                      ? {
                          defaultMonth: dayjs(
                            caseData?.estimatedAdditionalStatuteOfLimitations,
                          ).toDate(),
                          modifiers: {
                            estimatedStatute: dayjs(
                              caseData?.estimatedAdditionalStatuteOfLimitations,
                            ).toDate(),
                          },
                          modifiersStyles: {
                            estimatedStatute: {
                              border: '1px dashed gray',
                            },
                          },
                          footer: (
                            <div className="text-xs">{`The outlined date is Gladiate's best estimate of the Additional Statute of Limitations based on the type and location of the case. Please confirm before selecting.`}</div>
                          ),
                        }
                      : undefined
                  }
                />
                {renderTextFormInput(
                  'additionalStatuteOfLimitationsLabel',
                  'Additional Statute of Limitations Label',
                )}
                <CheckboxFormInput
                  {...form.register('additionalStatuteOfLimitationsSatisfied')}
                  handleOnChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate(
                      'additionalStatuteOfLimitationsSatisfied',
                      booleanToString(target.value),
                    );
                  }}
                  isDisabled={!caseData.additionalStatuteOfLimitations}
                  isDisabledMessage='Please set "Additional Statute of Limitations" first.'
                  title="Additional Statute of Limitations Satisfied?"
                />
              </div>
            </div>
          )}
          {isCaseLoading || !caseData ? (
            <GladiateLoader />
          ) : (
            <div className="w-full">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                <div className="col-span-2">
                  {renderTextFormInput(
                    'clientDescriptionOfIncident',
                    'Description of Incident',
                    'textarea',
                  )}
                </div>

                <IncidentFormFiles caseId={caseData?.caseId} />

                <div />

                {renderTextFormInput('incidentTimeOfDay', 'Time of day')}
                {renderTextFormInput('dayOfWeekIncidentOccurred', 'Day of the week')}
                {renderTextFormInput('clientComingFromPriorToIncident', 'Coming from')}
                {renderTextFormInput('clientGoingToPriorToIncident', 'Going to')}
                <SelectFormInput
                  title="Lighting"
                  listItems={['Bright', 'Dim', 'Dark']}
                  defaultValue={caseData?.lightingQualityAtTimeOfIncident}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('lightingQualityAtTimeOfIncident', target.value);
                  }}
                  {...form.register('lightingQualityAtTimeOfIncident')}
                />
                <SelectFormInput
                  title="Weather"
                  listItems={[
                    'Clear/Sunny',
                    'Partly Cloudy',
                    'Overcast/Cloudy',
                    'Foggy',
                    'Rain - Light',
                    'Rain - Heavy',
                    'Thunderstorm',
                    'Snow - Light',
                    'Snow - Heavy',
                    'Sleet/Hail',
                    'Windy',
                    'Blizzard',
                    'Icy',
                    'Hazy',
                    'Dust Storm',
                  ]}
                  defaultValue={caseData?.weatherAtTimeOfIncident}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('weatherAtTimeOfIncident', target.value);
                  }}
                  {...form.register('weatherAtTimeOfIncident')}
                />
                <SelectFormInput
                  title="Road Conditions"
                  listItems={[
                    '​Dry',
                    'Wet',
                    'Snow-covered',
                    '​Ice-covered',
                    '​Slushy',
                    '​Muddy',
                    '​Gravel/Loose Surface',
                    '​Potholes',
                    '​Under Construction',
                    '​Debris on Road',
                    '​Flooded',
                    '​Oil Spill',
                    '​Sand/Dirt',
                    '​Obstructed (e.g., fallen tree, stalled vehicle)',
                  ]}
                  defaultValue={caseData?.roadConditionsAtTimeOfIncident}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('roadConditionsAtTimeOfIncident', target.value);
                  }}
                  {...form.register('roadConditionsAtTimeOfIncident')}
                />
                <Switch
                  fullRow
                  title="Construction?"
                  value={caseData.constructionPresentAtTimeOfIncident ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    form.setValue('constructionPresentAtTimeOfIncident', !!target.value, {
                      shouldDirty: true,
                    });
                    handleUpdate('constructionPresentAtTimeOfIncident', target.value);
                  }}
                  {...form.register('constructionPresentAtTimeOfIncident')}
                />
                {caseData.constructionPresentAtTimeOfIncident && (
                  <span className="col-span-2">
                    {renderTextFormInput(
                      'constructionPresentAtTimeOfIncidentDescription',
                      'Description of construction',
                      'textarea',
                    )}
                  </span>
                )}
                <Switch
                  fullRow
                  title="Any cell phone use before incident?"
                  value={caseData.clientCellPhoneUseAtTimeOfIncident ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    form.setValue('clientCellPhoneUseAtTimeOfIncident', !!target.value, {
                      shouldDirty: true,
                    });
                    handleUpdate('clientCellPhoneUseAtTimeOfIncident', target.value);
                  }}
                  {...form.register('clientCellPhoneUseAtTimeOfIncident')}
                />
                {caseData.clientCellPhoneUseAtTimeOfIncident && (
                  <span className="col-span-2">
                    {renderTextFormInput(
                      'clientCellPhoneUseAtTimeOfIncidentDescription',
                      'Description of cell phone usage',
                      'textarea',
                    )}
                  </span>
                )}
                <Switch
                  fullRow
                  title="Any medication, drugs, or alcohol as part of the incident?"
                  value={caseData.clientDrugAlcoholUseAtTimeOfIncident ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    form.setValue('clientDrugAlcoholUseAtTimeOfIncident', !!target.value, {
                      shouldDirty: true,
                    });
                    handleUpdate('clientDrugAlcoholUseAtTimeOfIncident', target.value);
                  }}
                  {...form.register('clientDrugAlcoholUseAtTimeOfIncident')}
                />
                {caseData.clientDrugAlcoholUseAtTimeOfIncident && (
                  <span className="col-span-2">
                    {renderTextFormInput(
                      'clientDrugAlcoholUseAtTimeOfIncidentDescription',
                      'Description of medication, drugs, or alcohol',
                      'textarea',
                    )}
                  </span>
                )}
                <Switch
                  fullRow
                  title="Working at time of Incident?"
                  value={bitBToB(caseData.clientInScopeOfWorkAtTimeOfIncident) ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    const value = bToBitB(!!target.value);
                    form.setValue('clientInScopeOfWorkAtTimeOfIncident', value, {
                      shouldDirty: true,
                    });
                    handleUpdate('clientInScopeOfWorkAtTimeOfIncident', value);
                  }}
                  {...form.register('clientInScopeOfWorkAtTimeOfIncident')}
                />
                <Switch
                  fullRow
                  title="Police Report?"
                  value={bitBToB(caseData.incidentReports) ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    const value = bToBitB(!!target.value);
                    form.setValue('incidentReports', value, {
                      shouldDirty: true,
                    });
                    handleUpdate('incidentReports', value);
                  }}
                  {...form.register('incidentReports')}
                />
                <Switch
                  fullRow
                  title="Authorities called to scene?"
                  value={bitBToB(caseData.firstRespondersPresent) ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    const value = bToBitB(!!target.value);
                    form.setValue('firstRespondersPresent', value, {
                      shouldDirty: true,
                    });
                    handleUpdate('firstRespondersPresent', value);
                  }}
                  {...form.register('firstRespondersPresent')}
                />
                {bitBToB(caseData.firstRespondersPresent) && (
                  <div className="flex flex-col ml-2 gap-y-8">
                    <span className="ml-2">
                      <CaseContactConnectionLinker
                        caseId={caseData.caseId}
                        title="First Responders"
                        showMultiple
                        roleOnCase={caseContactConnectionOptions.firstResponder}
                      />
                    </span>
                    <CheckboxFormInput
                      {...form.register('citationsIssued')}
                      handleOnChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        handleUpdate('citationsIssued', booleanToString(target.value));
                      }}
                      title="Citations Issued?"
                    />
                  </div>
                )}
                <Switch
                  fullRow
                  title="Transported in an ambulance?"
                  value={bitBToB(caseData.transportedByAmbulance) ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    const value = bToBitB(!!target.value);
                    form.setValue('transportedByAmbulance', value, {
                      shouldDirty: true,
                    });
                    handleUpdate('transportedByAmbulance', value);
                  }}
                  {...form.register('transportedByAmbulance')}
                />
                {bitBToB(caseData.transportedByAmbulance) && (
                  <span className="ml-4">
                    <CaseContactConnectionLinker
                      caseId={caseData.caseId}
                      title="Ambulance"
                      roleOnCase={caseContactConnectionOptions.ambulance}
                    />
                  </span>
                )}
                <Switch
                  fullRow
                  title="Transported to Hospital?"
                  value={bitBToB(caseData.transportedToHospital) ?? false}
                  handleChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    const value = bToBitB(!!target.value);
                    form.setValue('transportedToHospital', value, {
                      shouldDirty: true,
                    });
                    handleUpdate('transportedToHospital', value);
                  }}
                  {...form.register('transportedToHospital')}
                />
                {bitBToB(caseData.transportedToHospital) && (
                  <span className="ml-4">
                    <CaseContactConnectionLinker
                      caseId={caseData.caseId}
                      title="Hospital"
                      roleOnCase={caseContactConnectionOptions.hospital}
                    />
                  </span>
                )}
                <span className="flex flex-col col-span-2 gap-y-8">
                  <CaseContactConnectionLinker
                    caseId={caseData.caseId}
                    title="Witness"
                    roleOnCase={caseContactConnectionOptions.witness}
                  />
                  <CaseContactConnectionLinker
                    caseId={caseData.caseId}
                    title="Passengers"
                    roleOnCase={caseContactConnectionOptions.passenger}
                    showMultiple
                  />
                </span>
              </div>
            </div>
          )}
        </TabsContent>
      </form>
    </Form>
  );
}

export default IncidentForm;
