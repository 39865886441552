import {
  cn,
  getUniqueAttributeFromContacts,
  useDeleteAudience,
  useGetAudiences,
  useGetContactsListViewInfinite,
} from '@colosseum/data';
import {
  DateRangePicker,
  FilterCombobox,
  Form,
  FormField,
  FormItem,
  FormLabel,
  Slideover,
} from '@colosseum/shared-ui';
import { AudienceType } from '@gladiate/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AudienceFormSlideover from '../AudienceFormSlideover/AudienceFormSlideover';
import AudienceSelect from '../AudienceSelect/AudienceSelect';

export interface AudienceSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  activeAudience?: AudienceType;
  setActiveAudience: Dispatch<SetStateAction<AudienceType | undefined>>;
  handleAudienceSelect: (audienceId: string) => void;
}

const filtersConfig = [
  { title: 'City', keyPath: ['contactAddresses', 'city'] },
  { title: 'County', keyPath: ['contactAddresses', 'county'] },
  { title: 'State', keyPath: ['contactAddresses', 'state'] },
  { title: 'Zip Code', keyPath: ['contactAddresses', 'zipCode'] },
  { title: 'Email', keyPath: ['contactEmails', 'emailAddress'] },
  { title: 'Number', keyPath: ['contactNumbers', 'number'] },
  { title: 'Contact Type', keyPath: ['contactType'] },
  { title: 'Referral Source', keyPath: ['referralSource'] },
  { title: 'Tags', keyPath: ['contactTags', 'tagAttributes', 'title'] },
];

export function AudienceSlideover(props: AudienceSlideoverProps) {
  const { open, setOpen, activeAudience, setActiveAudience, handleAudienceSelect } = props;

  const [audienceModalOpen, setAudienceModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<AudienceType>();
  const [audienceToDelete, setAudienceToDelete] = useState<AudienceType>();
  const [audienceToEdit, setAudienceToEdit] = useState<AudienceType>();

  const [audienceDropdownOpen, setAudienceDropdownOpen] = useState(false);
  const { data: contactsData, isLoading: isContactsLoading } = useGetContactsListViewInfinite();
  const { data: audiencesData, isLoading: isAudiencesLoading } = useGetAudiences();
  const deleteAudience = useDeleteAudience();

  const navigate = useNavigate();
  const form = useForm();

  return (
    <Slideover
      title="Filters"
      description="Apply custom filters to your contacts."
      open={open}
      setOpen={setOpen}
      displayDeleteButton={false}
      typing={false}
      bottomButtons={
        <div className="flex items-center justify-end px-5 py-5 border-t border-t-gray-200">
          <button
            type="button"
            className={cn(
              'inline-flex justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue',
              activeAudience
                ? ' bg-sky-blue hover:bg-atlantic-blue '
                : ' bg-gray-300 pointer-events-none',
            )}
            onClick={() => {
              setAudienceModalOpen(true);
              setAudienceToEdit(activeAudience);
            }}
            disabled={!activeAudience}
          >
            Update Audience
          </button>
          <button
            type="submit"
            className="inline-flex justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue bg-sky-blue hover:bg-atlantic-blue "
            onClick={() => setAudienceModalOpen(true)}
          >
            Create New Audience
          </button>
        </div>
      }
    >
      <div>
        <div className="flex items-center pb-4 pr-3 border-b border-b-gray-200">
          <Form {...form}>
            <FormField
              control={form.control}
              name={'audience'}
              render={() => (
                <FormItem>
                  <FormLabel>
                    <div className="flex gap-1">Audience</div>
                  </FormLabel>
                  <AudienceSelect
                    activeFilter={activeAudience}
                    handleItemSelect={handleAudienceSelect}
                    placeholder="Select an audience"
                    triggerClassName="min-w-[200px]"
                  />
                </FormItem>
              )}
            />
          </Form>
        </div>
        <div className="flex items-center mt-8 mb-4 text-lg font-semibold">
          <button
            onClick={() => {
              navigate(window.location.pathname);
              handleAudienceSelect('');
            }}
            className="flex items-center h-8 px-2 text-sm border border-gray-200 rounded-md hover:bg-gray-100 "
          >
            Reset Filters
          </button>
        </div>

        <div className="grid grid-cols-2 gap-5 pb-2">
          {filtersConfig.map((filter) => (
            <FilterCombobox
              key={filter.title}
              title={filter.title}
              updateKey={filter.title}
              options={
                getUniqueAttributeFromContacts(contactsData?.data ?? [], filter.keyPath) ?? []
              }
              hideSearch={filter.title === 'Contact Type'} // Conditionally hide search only for "Contact Type"
            />
          ))}
          <div className="col-span-2">
            <DateRangePicker title="Contact Created" updateKey="Date Created" />
          </div>
        </div>
      </div>
      <AudienceFormSlideover
        open={audienceModalOpen}
        setOpen={setAudienceModalOpen}
        audienceToEdit={audienceToEdit}
        setAudienceToEdit={setAudienceToEdit}
        setActiveAudience={setActiveAudience}
      />
    </Slideover>
  );
}

export default AudienceSlideover;
