/* eslint-disable-next-line */
import {
  useGetCaseCampaign,
  useGetCaseTypes,
  useGetProvisionedPhoneNumbers,
  useUpdateCaseCampaign,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  CardSection,
  Form,
  GladiateLoader,
  MultiSelectFormInput,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import { CampaignStepOptions, CaseCampaignStatus } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';
import NextButton from '../NextButton/NextButton';

export interface CaseTypePageProps {}

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  caseTypeIds: z.array(z.string()),
  status: z.union([
    z.literal<CaseCampaignStatus>('active'),
    z.literal<CaseCampaignStatus>('paused'),
    z.literal<CaseCampaignStatus>('draft'),
    z.literal<CaseCampaignStatus>('past'),
  ]),
});

export function CaseCampaignCriteriaPage(props: CaseTypePageProps) {
  // #region Hooks
  const { campaignId: caseCampaignId } = useParams() as { campaignId: string };
  const updateCampaign = useUpdateCaseCampaign();
  const navigate = useNavigate();

  // #endregion

  // #region Data Fetching
  const { data: caseTypesList } = useGetCaseTypes();
  const { data: caseCampaignData, isLoading: isCaseCampaignLoading } =
    useGetCaseCampaign(caseCampaignId);
  const campaignData = caseCampaignData?.data;
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: campaignData,
  });
  // #endregion

  // #region State
  const [selectedCaseTypes, setSelectedCaseTypes] = useState<string[]>(
    campaignData?.caseTypeIds ?? [],
  );
  const [recalculateCampaignModal, setRecalculateCampaignModal] = useState(false);

  // #endregion

  // #region Derived State
  const caseTypesOptions = caseTypesList?.data.map((item) => ({
    title: item.title ?? '',
    value: item.caseTypeId,
  })) as { title: string; value: string }[];
  // #endregion

  // #region Event Handlers
  function onSubmit(data: z.infer<typeof formSchema>) {
    updateCampaign
      .mutateAsync({
        caseCampaignId,
        caseTypeIds: selectedCaseTypes,
        description: data.description,
        status: data.status,
        title: data.title,
      })
      .then(() => {
        navigate(`/campaigns/${caseCampaignId}/case/${CampaignStepOptions.stages}`);
      });
  }
  // #endregion

  // #region useEffects
  // #endregion

  if (isCaseCampaignLoading) {
    return <GladiateLoader />;
  }

  return (
    <div className="flex flex-col gap-5">
      <ActionConfirmModal
        open={recalculateCampaignModal}
        setOpen={setRecalculateCampaignModal}
        actionFunction={() => {
          onSubmit(form.getValues());
          setRecalculateCampaignModal(false);
        }}
        buttonText="Recalculate Campaign"
        title="Recalculate Campaign"
        description="Recalculating this campaign may change who will be the recipient of this campaign. Are you sure you want to recalculate this campaign?"
        actionFunctionIsMutation={true}
      />
      <CardSection cardHeading="Case Campaign Criteria" className="flex flex-col justify-center">
        <div className="flex flex-col">
          <div className="p-2 text-sm">
            Before continuing, please fill out the following information to build this campaign
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="w-full">
                <div className="grid grid-cols-1 pt-2 pb-2 gap-y-5 gap-x-3">
                  <TextFormInput {...form.register('title')} title="Campaign Title" />
                  <TextFormInput
                    {...form.register('description')}
                    title="Description"
                    type="textarea"
                  />
                  <MultiSelectFormInput
                    title="Applicable Case Types"
                    {...form.register(`caseTypeIds`)}
                    selectedOptions={selectedCaseTypes}
                    options={caseTypesOptions}
                    optionsIsObject
                    onChange={(option: string) => {
                      setSelectedCaseTypes((prev) => {
                        if (prev.includes(option)) {
                          return prev.filter((i) => i !== option);
                        } else {
                          return [...prev, option];
                        }
                      });
                    }}
                  />
                  <SelectFormInput
                    title="Campaign Status"
                    listItems={{
                      Active: 'active',
                      Paused: 'paused',
                      Draft: 'draft',
                    }}
                    listItemsIsObject
                    defaultValue={campaignData?.status ?? 'draft'}
                    placeholderText="Select a campaign status"
                    handleOnChange={(e: React.SyntheticEvent) => {}}
                    {...form.register(`status`)}
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </button>
                    <NextButton
                      submit={true}
                      title="Continue"
                      nextRoute={`/campaigns/${caseCampaignId}/case/${CampaignStepOptions.summary}`}
                      disabled={selectedCaseTypes.length === 0}
                      onClick={() => {
                        setRecalculateCampaignModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Form>
          <div className="flex justify-center"></div>
        </div>
      </CardSection>
    </div>
  );
}

export default CaseCampaignCriteriaPage;
