import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createCustomAttributeOptionV3,
  createCustomAttributeV3,
  createCustomAttributeValueV3,
  deleteCustomAttributeOptionV3,
  deleteCustomAttributeV3,
  deleteCustomAttributeValueV3,
  getCustomAttributeOptionsV3,
  getCustomAttributeValuesV3,
  getCustomAttributesV3,
  updateCustomAttributeOptionV3,
  updateCustomAttributeV3,
  updateCustomAttributeValueV3,
} from '../requests/labryinth';

// ------------------ Custom Attribute ------------------

export const useGetCustomAttributes = () => {
  return useQuery({
    queryKey: [queryKeys.customAttributes],
    queryFn: () => getCustomAttributesV3(),
  });
};

export const useCreateCustomAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCustomAttributeV3>[0]) =>
      createCustomAttributeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useDeleteCustomAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (customAttributeId: string) => deleteCustomAttributeV3(customAttributeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useUpdateCustomAttribute = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCustomAttributeV3>[0]) =>
      updateCustomAttributeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

// ------------------ Custom Attribute Value ------------------

export const useGetCustomAttributeValues = (objectId: string) => {
  return useQuery({
    queryKey: [queryKeys.customAttributes, objectId],
    queryFn: () => getCustomAttributeValuesV3(objectId),
    enabled: !!objectId,
  });
};

export const useCreateCustomAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      objectId,
      customAttributeId,
    }: {
      objectId: string;
      customAttributeId: string;
    }) => createCustomAttributeValueV3(objectId, customAttributeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useDeleteCustomAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (customAttributeValueId: string) =>
      deleteCustomAttributeValueV3(customAttributeValueId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useUpdateCustomAttributeValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCustomAttributeValueV3>[0]) =>
      updateCustomAttributeValueV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

// ------------------ Custom Attribute Option ------------------
export const useGetCustomAttributeOptions = (customAttributeId: string) => {
  return useQuery({
    queryKey: [queryKeys.customAttributeOptions, customAttributeId],
    queryFn: () => getCustomAttributeOptionsV3(customAttributeId),
    enabled: !!customAttributeId,
  });
};

export const useDeleteCustomAttributeOption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (customAttributeOptionId: string) =>
      deleteCustomAttributeOptionV3(customAttributeOptionId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributeOptions],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useCreateCustomAttributeOption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCustomAttributeOptionV3>[0]) =>
      createCustomAttributeOptionV3(data),

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributeOptions],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributes],
      });
    },
  });
};

export const useUpdateCustomAttributeOption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCustomAttributeOptionV3>[0]) =>
      updateCustomAttributeOptionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customAttributeOptions],
      });
    },
  });
};
