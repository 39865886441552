import { getS3FileDownloadLink } from '@colosseum/data';
import { DocumentPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface DragDropFileProps {
  fileObject: any; // this needs a better name but its whatever object holds the documentId and fileName
  deleteHandler: any;
  uploadHandler: any;
  subheadingCopy?: string;
  loading: boolean;
  accept?: string;
  setLoading: (loading: boolean) => void;
}

export function DragDropFile(props: DragDropFileProps) {
  const [fileExists, setFileExists] = useState(false);
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (props.fileObject?.objKey) {
      setFileExists(true);
    } else {
      setFileExists(false);
    }
  }, [props.fileObject]);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.uploadHandler(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.files && e.target.files[0]) {
      props.uploadHandler(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.click();
  };

  function downloadFile() {
    getS3FileDownloadLink(props.fileObject?.objKey).then((res) => {
      const url = res.data?.url;

      window.open(url, '_blank');
    });
  }

  return (
    <div>
      {fileExists ? (
        <div className="flex w-full mt-2 ">
          <div className="flex items-center pl-1 font-semibold">
            Attached Document:
            <div className="flex items-center justify-between p-1 px-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue ">
              <p
                onClick={() => downloadFile()}
                className="cursor-pointer hover:underline hover:text-black"
              >
                {props.fileObject?.name || 'File Name Missing'}
              </p>
              <XMarkIcon
                onClick={() => {
                  props.deleteHandler();
                  setFileExists(false);
                }}
                className="w-5 h-5 ml-2 cursor-pointer hover:text-black"
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {props.loading ? (
            <div className="flex w-full h-full mt-2 border border-dashed rounded-lg cursor-not-allowed border-sky-blue bg-blue-50">
              <div className="py-20 mx-auto">
                <GladiateLoader height={190} />
              </div>
            </div>
          ) : (
            <form
              id="form-file-upload"
              className="flex w-full h-full mt-2 border border-dashed rounded-lg border-sky-blue bg-blue-50"
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop} // Add this line
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="w-full h-full m-auto">
                <input
                  ref={inputRef}
                  type="file"
                  className="hidden"
                  multiple={true}
                  onChange={handleChange}
                  accept={props.accept}
                />
                <label
                  id="label-file-upload"
                  className={
                    'flex items-center justify-center h-full w-full py-20' +
                    (dragActive ? ' bg-light-blue rounded-lg' : '')
                  }
                  htmlFor="input-file-upload"
                >
                  <div className="text-center">
                    <div className="flex items-center w-full">
                      <div className="mx-auto">
                        <DocumentPlusIcon className="w-20 h-20 text-gray-400" />
                      </div>
                    </div>

                    <p className="mt-5 text-3xl">
                      Drag and drop your file, or{' '}
                      <Button
                        className="text-3xl underline"
                        variant="link"
                        size="unset"
                        onClick={onButtonClick}
                      >
                        Browse
                      </Button>
                    </p>
                    <p className="mt-5 text-gray-400">
                      {props.subheadingCopy ? props.subheadingCopy : null}
                    </p>
                  </div>
                </label>
                {dragActive && (
                  <div
                    className="absolute top-0 bottom-0 left-0 right-0 w-full h-full rounded-lg"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
}

export default DragDropFile;
