import { displayContactName, formatPrice, prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';
import { LinkedVehiclesTableProps } from './LinkedVehiclesTable';

export const vehicleColumns: ColumnDef<LinkedVehiclesTableProps['vehicles'][0] | undefined>[] = [
  {
    accessorFn: (row) => {
      return row?.year;
    },
    id: 'Year',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.make;
    },
    id: 'Make',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.model;
    },
    id: 'Model',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.vin;
    },
    id: 'VIN',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.licensePlate;
    },
    id: 'License Plate',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row?.ownershipStatus;
    },
    id: 'Ownership Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return displayContactName(row?.towCompany);
    },
    id: 'Tow Company',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return displayContactName(row?.repairShop);
    },
    id: 'Repair Shop',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => (row?.isTotalLoss === '1' ? row?.totalLossProposedFmv : undefined),
    id: 'Total Loss Proposed FMV',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => (row?.isTotalLoss === '1' ? row?.totalLossFinalFmv : undefined),
    id: 'Total Loss Final FMV',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => (row?.isRepairable === '1' ? row?.repairEstimate : undefined),
    id: 'Repair Estimate',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) => (row?.isRepairable === '1' ? row?.repairFinalCost : undefined),
    id: 'Repair Final Cost',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) =>
      row?.isDiminishedValueClaim === '1' ? row?.diminishedValueClaimFinalAmount : undefined,
    id: 'DVC Final Amount',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: formatPrice });
    },
  },
  {
    accessorFn: (row) =>
      row?.isDiminishedValueClaim === '1' ? row?.diminishedValueClaimOdometerAtIncident : undefined,
    id: 'DVC Odometer at Incident',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => (row?.isRentalCarNeeded === '1' ? row?.rentalStartDate : undefined),
    id: 'Rental Start Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: prettifyDateString });
    },
  },
  {
    accessorFn: (row) => (row?.isRentalCarNeeded === '1' ? row?.rentalEndDate : undefined),
    id: 'Rental End Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props, valueModifierFunc: prettifyDateString });
    },
  },
  {
    accessorFn: (row) =>
      row?.isRentalCarNeeded === '1' ? displayContactName(row.rentalCarCompany) : undefined,
    id: 'Rental Car Company',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => (row?.companyProperty === '1' ? 'Yes' : 'No'),
    id: 'Company Property',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: startCase }),
  },
  {
    accessorFn: (row) => (row?.governmentProperty === '1' ? 'Yes' : 'No'),
    id: 'Government Property',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: startCase }),
  },
];
