import {
  CommunicationLogViewModel,
  displayContactName,
  getGlobalIcon,
  prettifyDateString,
  roleColorMap,
} from '@colosseum/data';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionViewModelType,
  ContactType,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { capitalize, startCase, uniq } from 'lodash';
import Chip from '../Chip/Chip';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { stringIncludesFilterFn } from '../shadcn/data-table/table-filter-helpers';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

const getTableCellLabel = (text: string, icon: string) => {
  const Icon = getGlobalIcon(icon);
  return (
    <span className="flex items-center p-0 text-sm">
      {Icon && <Icon width="22px" />}
      <span className="ps-3 whitespace-nowrap">{text}</span>
    </span>
  );
};

export const columns: ColumnDef<
  | (CommunicationLogViewModel & {
      contact: ContactType;
      roleList: { roleOnCase: string }[];
    })
  | undefined
>[] = [
  {
    accessorFn: (row) => row?.label?.text,
    filterFn: stringIncludesFilterFn,
    id: 'Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: ({ row }) => {
      const label = row?.original?.label?.text || '';
      const icon = row?.original?.label?.icon || '';
      return getTableCellLabel(label, icon);
    },
  },
  {
    accessorFn: (row): string => row?.description || row?.body || '-',
    id: 'Preview',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({
        props,
        valueModifierFunc: (value: string) =>
          value.length > 40 ? value.substring(0, 40) + '...' : value,
      });
    },
  },
  {
    accessorFn: (row) => displayContactName(row?.contact),
    filterFn: stringIncludesFilterFn,
    id: 'Contact',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({
        props,
      });
    },
  },
  {
    accessorFn: (row) => row?.roleList,
    id: 'Roles',
    header: renderHeader,
    filterFn: (row, _id, value: CaseContactConnectionRoleType['roleOnCase'][]) => {
      const rolesInContactConnect = row.original?.roleList?.map(({ roleOnCase }) => roleOnCase);
      return value.some((elt) => rolesInContactConnect?.includes(elt));
    },
    cell: (props) => {
      const roles = (props?.getValue() || []) as
        | CaseContactConnectionViewModelType['roles']
        | undefined;
      const totalRoles = roles?.map((role) => role.roleOnCase) ?? [];
      const uniqTotalRoles = uniq(totalRoles);

      if (!totalRoles?.length) return '-';

      return (
        <div className="flex flex-wrap gap-1">
          {uniqTotalRoles.slice(0, 4)?.map((roleOnCase) => (
            <Chip
              key={roleOnCase}
              name={startCase(roleOnCase)}
              color={roleColorMap?.[roleOnCase ?? 'default']}
            />
          ))}
          {uniqTotalRoles.length > 4 && (
            <TooltipWrapper
              message={uniqTotalRoles?.map((roleOnCase) => startCase(roleOnCase)).join(', ')}
            >
              <div>
                <Chip name={`+${uniqTotalRoles.length - 4} more`} color="stone" />
              </div>
            </TooltipWrapper>
          )}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => {
      return capitalize(row?.direction);
    },
    filterFn: stringIncludesFilterFn,
    id: 'Direction',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateTime),
    id: 'Date',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({
        props,
      });
    },
  },
];
