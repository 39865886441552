import {
  AudienceType,
  ContactAddressType,
  ContactConnectionType,
  ContactEmailType,
  ContactEmploymentType,
  ContactNumberType,
  ContactType,
  DefaultV3Response,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

export const baseCollegiumRoute = `collegium/${API_STAGE}`;

// #region
// GET get_contacts_complete_v3
export const getContactsCompleteV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ContactType[]>>(`${baseCollegiumRoute}/v3/contacts/complete`)
    .then((res) => res.data);

export const getContactsV3 = (contactId?: string, firmId?: string) => {
  let contactsRoute = `${baseCollegiumRoute}/v3/contacts`;
  if (contactId && firmId) {
    contactsRoute += `?contactId=${contactId}&firmId=${firmId}`;
  }
  return axiosInstance
    .get<
      DefaultV3Response<
        ContactType[],
        {
          last_evaluated_key?: {
            contactId: { S: string };
            firmId: { S: string };
          };
        }
      >
    >(contactsRoute)
    .then((res) => res.data);
};
// #endregion
// #region Contact Address
// GET get_all_contact_addresses_v3
export const getContactAddressesV3 = (contactId?: string, firmId?: string) => {
  let contactAddressesRoute = `${baseCollegiumRoute}/v3/contacts/addresses`;
  if (contactId && firmId) {
    contactAddressesRoute += `?contactAddressId=${contactId}&firmId=${firmId}`;
  }
  return axiosInstance
    .get<
      DefaultV3Response<
        ContactAddressType[],
        {
          last_evaluated_key?: {
            contactAddressId: { S: string };
            firmId: { S: string };
          };
        }
      >
    >(contactAddressesRoute)
    .then((res) => res.data);
};
// #endregion

// #region Contact Emails
// GET get_all_contact_emails_v3
export const getContactEmailsV3 = (contactId?: string, firmId?: string) => {
  let contactEmailsRoute = `${baseCollegiumRoute}/v3/contacts/emails`;
  if (contactId && firmId) {
    contactEmailsRoute += `?contactEmailId=${contactId}&firmId=${firmId}`;
  }
  return axiosInstance
    .get<DefaultV3Response<ContactEmailType[]>>(contactEmailsRoute)
    .then((res) => res.data);
};

// #region Contact Employment
// GET get_all_contact_employments_v3
export const getContactEmploymentsV3 = (contactId?: string, firmId?: string) => {
  let contactEmploymentsRoute = `${baseCollegiumRoute}/v3/contacts/employments`;
  if (contactId && firmId) {
    contactEmploymentsRoute += `?contactEmploymentId=${contactId}&firmId=${firmId}`;
  }
  return axiosInstance
    .get<DefaultV3Response<ContactEmploymentType[]>>(contactEmploymentsRoute)
    .then((res) => res.data);
};
// #endregion

// #region Contact Number
// GET get_all_contact_numbers_v3 -- NOTE: this doesn't exist in postman as of may23/24
export const getContactNumbersV3 = (contactId?: string, firmId?: string) => {
  let contactNumbersRoute = `${baseCollegiumRoute}/v3/contacts/numbers`;
  if (contactId && firmId) {
    contactNumbersRoute += `?contactNumberId=${contactId}&firmId=${firmId}`;
  }
  return axiosInstance
    .get<DefaultV3Response<ContactNumberType[]>>(contactNumbersRoute)
    .then((res) => res.data);
};
// #endregion

// #region Contact to Contact Connection
// GET get_all_contact_connections_v3
export const getAllContactConnectionsV3 = () => {
  const contactConnectionssRoute = `${baseCollegiumRoute}/v3/contacts/connections`;
  return axiosInstance
    .get<DefaultV3Response<ContactConnectionType[]>>(contactConnectionssRoute)
    .then((res) => res.data);
};

export const getContactConnectionsV3 = (contactId: string) => {
  const contactConnectionsRoute = `${baseCollegiumRoute}/v3/contacts/${contactId}/connections`;
  return axiosInstance
    .get<DefaultV3Response<ContactConnectionType[]>>(contactConnectionsRoute)
    .then((res) => res.data);
};

export const createContactConnectionV3 = (data: {
  primaryContactId: string;
  secondaryContactId: string;
  primaryContactRelationship: ContactConnectionType['relationship'];
  contactEmploymentId?: string;
}) => {
  const { primaryContactId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<ContactConnectionType>>(
      `${baseCollegiumRoute}/v3/contacts/${primaryContactId}/connections`,
      rest,
    )
    .then((res) => res.data);
};

export const deleteContactConnectionV3 = (contactConnectionId: string) => {
  return axiosInstance
    .delete<DefaultV3Response<ContactConnectionType>>(
      `${baseCollegiumRoute}/v3/contacts/connections/${contactConnectionId}`,
    )
    .then((res) => res.data);
};
// #endregion

// #region Audience

// GET get_audiences_v3
export const getAudiencesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<AudienceType[]>>(`${baseCollegiumRoute}/v3/audiences`)
    .then((res) => res.data);

// POST create_audience_v3
export const createAudienceV3 = (data: Partial<AudienceType> & { title: AudienceType['title'] }) =>
  axiosInstance
    .post<DefaultV3Response<AudienceType>>(`${baseCollegiumRoute}/v3/audiences`, data)
    .then((res) => res.data);

// PUT update_audience_v3
export const updateAudienceV3 = (data: Partial<AudienceType>) => {
  const { audienceId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<AudienceType>>(`${baseCollegiumRoute}/v3/audiences/${audienceId}`, rest)
    .then((res) => res.data);
};

// DELETE delete_audience_v3
export const deleteAudienceV3 = (audienceId: string) =>
  axiosInstance
    .delete<DefaultV3Response<AudienceType>>(`${baseCollegiumRoute}/v3/audiences/${audienceId}`)
    .then((res) => res.data);
// #endregion
