import {
  displayContactName,
  queryKeys,
  useCreateCaseContactConnection,
  useGetCaseMedicalSummary,
  useGetContactsInfinite,
} from '@colosseum/data';
import { EntityAddPopover, GladiateLoader } from '@colosseum/shared-ui';
import { caseContactConnectionOptions } from '@gladiate/types';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import ProvidersListCard from '../ProvidersListCard/ProvidersListCard';

export interface ProvidersListProps {
  caseId: string;
}

export function ProvidersList(props: ProvidersListProps) {
  const { caseId } = props;

  const [partyLoading, setPartyLoading] = useState(false);

  const {
    data: caseMedicalSummaryData,
    isLoading: isMedicalSummaryLoading,
    isError: isMedicalSummaryError,
  } = useGetCaseMedicalSummary(caseId);
  const createCaseContactConnection = useCreateCaseContactConnection();
  const { data: allContactsData } = useGetContactsInfinite();
  const allContacts = allContactsData?.data;
  const queryClient = useQueryClient();

  const handleCreateConnection = async (data: { roleOnCase: string; contactId?: string }) => {
    setPartyLoading(true);
    await createCaseContactConnection
      .mutateAsync({
        caseId,
        ...data,
      })
      .then(() => {
        setPartyLoading(false);
        queryClient.invalidateQueries([queryKeys.medicalTreatments]);
      });
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full pb-5">
        <div className="grow"></div>
        <EntityAddPopover
          title="Add Provider"
          onAdd={() => {
            handleCreateConnection({
              roleOnCase: caseContactConnectionOptions.medicalProvider,
            });
          }}
          primary
          placeholder={`Search ${allContacts?.length ?? 0}${
            allContactsData?.meta.lastEvaluatedKey ? '+' : ''
          } contacts...`}
          isLoading={partyLoading}
          listItems={
            allContacts
              ?.filter((contact) => {
                return contact.firstName || contact.lastName || contact.name;
              })
              ?.map((contact) => {
                return {
                  label: displayContactName(contact),
                  onClick: () => {
                    handleCreateConnection({
                      roleOnCase: caseContactConnectionOptions.medicalProvider,
                      contactId: contact.contactId,
                    });
                  },
                };
              }) ?? []
          }
        />
      </div>
      <div className="mt-3 border divide-y divide-gray-300 rounded-lg shadow-md px-7">
        {isMedicalSummaryLoading && <GladiateLoader />}
        {!isMedicalSummaryLoading && caseMedicalSummaryData?.data?.length === 0 && (
          <div className="py-5 text-center text-gray-500">
            No providers found. Click the button above to add a provider.
          </div>
        )}
        {isMedicalSummaryError && (
          <div className="py-5 text-center text-gray-500">
            An error occurred while fetching providers.
          </div>
        )}

        {caseMedicalSummaryData?.data?.map((provider) => (
          <ProvidersListCard
            key={provider.contactId}
            providerData={provider}
            caseId={caseId}
            isLoading={isMedicalSummaryLoading}
          />
        ))}
      </div>
    </div>
  );
}

export default ProvidersList;
