import { daysBetween, prettifyDateString } from '@colosseum/data';
import { CaseStatusType, CaseStatusUpdateType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

export interface CycleTimeTableData {
  caseTitle: string;
  status: CaseStatusType;
  caseStatusId: string;
  caseStatusUpdateId: string;
  caseStatusUpdate: CaseStatusUpdateType;
  firmId: string;
  dateCreated: string;
  dateModified: string;
  dateStarted: string;
  dateEnded: string;
}
export const CycleByCaseColumns: ColumnDef<Partial<CycleTimeTableData | undefined>>[] = [
  {
    accessorFn: (row) => row?.caseTitle,
    id: 'Case Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.status?.title,
    id: 'Status',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.dateStarted,
    id: 'Start Time',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row?.dateEnded,
    id: 'End Time',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => {
      if (!row?.dateStarted || !row?.dateEnded) return null;
      return daysBetween(row.dateStarted, row.dateEnded);
    },
    id: 'Total Days',
    header: (props) => renderHeader(props),
    cell: (props) => null,
    aggregationFn: (columnId, values, rows) => {
      let sumDaysInStatus = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          sumDaysInStatus += value.getValue<number>(columnId);
        }
      });
      return sumDaysInStatus;
    },
    aggregatedCell: (props) => {
      const daysInStatusSum = props.getValue();
      return daysInStatusSum;
    },
  },
  {
    accessorFn: (row) => {
      if (!row?.dateStarted || !row?.dateEnded) return null;
      return daysBetween(row.dateStarted, row.dateEnded);
    },
    id: 'Days in Status',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregatedCell: () => {
      return null;
    },
  },
];

export const CycleByStatusColumns: ColumnDef<Partial<CycleTimeTableData | undefined>>[] = [
  {
    accessorFn: (row) => row?.caseTitle,
    id: 'Case Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.status?.title,
    id: 'Status',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.dateStarted,
    id: 'Start Time',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row?.dateEnded,
    id: 'End Time',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => {
      if (!row?.dateStarted || !row?.dateEnded) return null;
      return daysBetween(row.dateStarted, row.dateEnded);
    },
    id: 'Stats',
    header: (props) => {
      return 'Avg. Days in Status';
    },
    cell: (props) => null,
    aggregationFn: (columnId, values, rows) => {
      let sumDaysInStatus = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          sumDaysInStatus += value.getValue<number>(columnId);
        }
      });
      return Math.round(sumDaysInStatus / rows.length || 0);
    },
    aggregatedCell: (props) => {
      const avgDaysInStatus = props.getValue();
      return avgDaysInStatus;
    },
  },
  {
    accessorFn: (row) => {
      if (!row?.dateStarted || !row?.dateEnded) return null;
      return daysBetween(row.dateStarted, row.dateEnded);
    },
    id: 'Days in Status',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregatedCell: () => null,
  },
];
