import { ColumnDef, TableOptions } from '@tanstack/react-table';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';
import {
  CampaignDeliveryCadence,
  CampaignDeliveryMechanism,
  CaseCampaign,
  CaseCampaignTrigger,
  deliveryCadenceListItems,
  deliveryMechanismOptionsMap,
} from '@gladiate/types';
import { getOrdinalNum, prettifyDateString } from '@colosseum/data';
import Typography from '../Typography/Typography';

const Incomplete = () => (
  <Typography variant="subtext" color="red">
    Incomplete
  </Typography>
);

export const columns: ColumnDef<CaseCampaign & CaseCampaignTrigger>[] = [
  {
    accessorFn: (row) => row.message,
    id: 'Message Preview',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.caseStatusId,
    id: 'Case Status',
    header: renderHeader,
    cell: (props) => {
      const caseStatusId = props.getValue() as string;
      const { caseStatusMap } = props.table.options.meta as {
        caseStatusMap: { [key: string]: string };
      };
      return caseStatusMap[caseStatusId] ?? '-';
    },
  },
  {
    accessorFn: (row) => row.deliveryCadence,
    id: 'Delivery Cadence',
    header: renderHeader,
    cell: (props) => {
      const deliveryCadence = props.getValue() as CampaignDeliveryCadence;
      const deliveryCadenceOptionsMap = Object.fromEntries(
        Object.entries(deliveryCadenceListItems).map(([key, value]) => [value, key]),
      );
      return deliveryCadenceOptionsMap[deliveryCadence];
    },
  },
  {
    accessorFn: (row) => row.deliveryCadence,
    id: 'Cadence Details',
    header: renderHeader,
    cell: (props) => {
      const rowData = props.row.original;
      if (rowData.deliveryCadence === 'sendImmediately') {
        return `Send immediately`;
      }
      if (rowData.deliveryCadence === 'sendAfter') {
        const timeAmount = rowData.sendAfterTimeAmount;
        const timeDimension = rowData.sendAfterTimeDimension;
        return !timeAmount || !timeDimension ? (
          <Incomplete />
        ) : (
          `Send after ${rowData.sendAfterTimeAmount} ${rowData.sendAfterTimeDimension} `
        );
      }
      if (rowData.deliveryCadence === 'sendOnRepeat' && rowData.sendOnRepeatDimension === 'Daily') {
        return rowData.sendOnRepeatTime ? `Send daily` : <Incomplete />;
      }

      if (
        rowData.deliveryCadence === 'sendOnRepeat' &&
        rowData.sendOnRepeatDimension === 'Weekly'
      ) {
        const weekday = rowData.sendOnRepeatWeekday?.[0];
        return !weekday || !rowData.sendOnRepeatTime ? (
          <Incomplete />
        ) : (
          `Send weekly on ${rowData.sendOnRepeatWeekday?.[0]}`
        );
      }
      if (
        rowData.deliveryCadence === 'sendOnRepeat' &&
        rowData.sendOnRepeatDimension === 'Monthly'
      ) {
        const dateInfo = getOrdinalNum(parseInt(rowData?.sendOnRepeatCalendarDay || ''));
        const timeInfo = rowData.sendOnRepeatTime;
        return dateInfo === 'NaN' || !timeInfo ? <Incomplete /> : `Send monthly on the ${dateInfo}`;
      }
      if (rowData.deliveryCadence === 'sendOnRepeat' && !!rowData.sendOnRepeatDimension) {
        return <Incomplete />;
      }
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row.deliveryMechanism,
    id: 'Deliver By',
    header: renderHeader,
    cell: (props) => {
      const deliveryMechanism = props.getValue() as CampaignDeliveryMechanism;
      return deliveryMechanismOptionsMap[deliveryMechanism] ?? <Incomplete />;
    },
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date Created',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
];
