import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createTrustAccountTransactionV3,
  deleteTrustAccountTransactionV3,
  getTrustAccountTransactionsV3,
  updateTrustAccountTransactionV3,
} from '../requests/pacioli';

export const useGetTrustAccountTransactions = (
  caseId?: Parameters<typeof getTrustAccountTransactionsV3>[0],
) => {
  return useQuery({
    // This types the success and error functions for axios
    queryKey: [queryKeys.trustAccountTransactions, caseId],
    queryFn: async () => getTrustAccountTransactionsV3(caseId ?? ''),
    enabled: !!caseId,
  });
};

export const useCreateTrustAccountTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { caseId: string }) => createTrustAccountTransactionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.trustAccountTransactions],
      });
    },
  });
};

export const useUpdateTrustAccountTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateTrustAccountTransactionV3>[0]) =>
      updateTrustAccountTransactionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.trustAccountTransactions],
      });
    },
  });
};

export const useDeleteTrustAccountTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (trustAccountTransactionIdId?: string) =>
      deleteTrustAccountTransactionV3(trustAccountTransactionIdId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.trustAccountTransactions],
      });
    },
  });
};
