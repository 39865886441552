import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  CaseContactSubObject,
  ContactAddressType,
  ContactEmailType,
  ContactEmploymentType,
  ContactNumberType,
  ContactType,
  ContactViewModelType,
  DiscoveryType,
  ExpenseType,
  MedicalTreatmentBill,
  caseContactConnectionOptions,
} from '@gladiate/types';
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import keyBy from 'lodash/keyBy';
import { CompleteLienType, contactTypeOptions, contactTypeOptionsOld, formatPhoneNumber } from '..';

export const displayContactName = (
  contact?: Pick<ContactType, 'contactType' | 'name' | 'firstName' | 'lastName'>,
): string => {
  if (!contact) return '';
  if (
    [contactTypeOptions.Organization, contactTypeOptionsOld.Organization].includes(
      contact.contactType ?? '',
    )
  ) {
    return contact.name?.trim() || '-';
  }
  return `${contact?.firstName?.trim() || '-'} ${contact?.lastName?.trim() ?? '-'}` || '-';
};

export const findRoleSpecificCaseContactConnections = <T>({
  caseContactConnections,
  role,
}: {
  caseContactConnections?: Pick<CaseContactConnectionType, 'roles'>[];
  role: string;
}) => {
  if (!caseContactConnections) return [];
  return caseContactConnections?.filter((caseContactConnection) =>
    caseContactConnection?.roles.some(
      (caseContactConnectionRole) => caseContactConnectionRole.roleOnCase === role,
    ),
  ) as T[];
};

export const findResourceSpecificCaseContactConnectionRoles = ({
  caseContactConnections,
  itemType,
}: {
  caseContactConnections?: CaseContactConnectionType[];
  itemType: CaseContactSubObject;
}) => {
  if (!caseContactConnections) return [];
  return caseContactConnections?.filter((caseContactConnection: any) =>
    caseContactConnection?.roles.some(
      (caseContactConnectionRole: CaseContactConnectionRoleType) =>
        caseContactConnectionRole.itemType === itemType,
    ),
  );
};

/**
 * @deprecated The method should not be used going forward but is still used in the app.
 * This should be deleted once the last instance is removed - sibyls "get_case_contacts" is the replacement,
 * need to wait until contact ids can be passed
 */
export const mergeConnectionsAndContacts = <T>({
  caseContactConnections,
  contacts,
}: {
  caseContactConnections?: CaseContactConnectionType[];
  contacts?: T[];
}) => {
  const contactsWithIdKey = keyBy(contacts, 'contactId');
  if (!caseContactConnections || !contacts) return [];
  return caseContactConnections?.map((caseContactConnection) => {
    return {
      ...caseContactConnection,
      ...(contactsWithIdKey[caseContactConnection.contactId] ),
    };
  });
};

/**
 * @deprecated The method should not be used going forward but is still used in the app.
 * This should be deleted once the last instance is removed - sibyls "get_case_contacts" is the replacement,
 * need to wait until contact ids can be passed
 */
export const mergedContactConnectionForRoleOnCase = ({
  caseContactConnections,
  contacts,
  roleOnCase,
}: {
  caseContactConnections?: CaseContactConnectionType[];
  contacts?: ContactType[];
  roleOnCase: CaseContactConnectionRoleType['roleOnCase'];
}) => {
  return mergeConnectionsAndContacts<ContactType>({
    caseContactConnections,
    contacts,
  }).filter((contact) => contact.roles.map((role) => role.roleOnCase).includes(roleOnCase));
};

export const mergeMedicalTreatmentsAndContacts = ({
  caseContactConnections,
  medicalTreatments,
  contacts,
}: {
  caseContactConnections: CaseContactConnectionType[];
  medicalTreatments: MedicalTreatmentBill[];
  contacts?: ContactType[];
}) => {
  return medicalTreatments.flatMap((medicalTreatment) => {
    const matchingConnection = mergeConnectionsAndContacts({
      caseContactConnections,
      contacts,
    }).find(({ roles }) =>
      roles.find(
        (role) =>
          role.itemId === medicalTreatment.medicalTreatmentId &&
          role.roleOnCase === caseContactConnectionOptions.client,
      ),
    );
    return { ...medicalTreatment, contact: matchingConnection };
  });
};

// TODO refactor into something similar to useGetCasesComplete
export const mergeCaseExpensesAndContacts = ({
  caseExpenses,
  caseContactConnections,
  contacts,
}: {
  caseExpenses: ExpenseType[];
  caseContactConnections: CaseContactConnectionType[];
  contacts: ContactType[];
}) => {
  const contactsMerge = mergeConnectionsAndContacts({
    caseContactConnections,
    contacts,
  });
  const mergedExpenses = caseExpenses?.map((expense) => {
    const matchingConnection = contactsMerge.find(({ roles }) =>
      roles.find(
        (role) =>
          role.itemId === expense.caseExpenseId &&
          role.roleOnCase === caseContactConnectionOptions.client,
      ),
    );

    return { ...expense, contact: matchingConnection };
  });
  return mergedExpenses;
};

// TODO refactor into something similar to useGetCasesComplete
export const mergeDiscoveryAndContacts = ({
  discoveries,
  caseContactConnections,
  contacts,
}: {
  discoveries: DiscoveryType[];
  caseContactConnections: CaseContactConnectionType[];
  contacts: ContactType[];
}) => {
  const contactsMerge = mergeConnectionsAndContacts({
    caseContactConnections,
    contacts,
  });
  const mergedDiscoveries = discoveries?.map((discovery) => {
    const matchingConnection = contactsMerge.find(({ roles }) =>
      roles.find(
        (role) =>
          role.itemId === discovery.discoveryId &&
          role.roleOnCase === caseContactConnectionOptions.client,
      ),
    );

    return { ...discovery, contact: matchingConnection };
  });
  return mergedDiscoveries;
};

// TODO refactor into something similar to useGetCasesComplete
export const mergeLiensAndContacts = ({
  liens,
  caseContactConnections,
  contacts,
}: {
  liens: CompleteLienType[];
  caseContactConnections: CaseContactConnectionType[];
  contacts: ContactType[];
}) => {
  const contactsMerge = mergeConnectionsAndContacts({
    caseContactConnections,
    contacts,
  });
  const mergedLiens = liens?.map((lien) => {
    const matchingConnection = contactsMerge.find(({ roles }) =>
      roles.find(
        (role) =>
          role.itemId === lien.lienId && role.roleOnCase === caseContactConnectionOptions.client,
      ),
    );

    return { ...lien, contact: matchingConnection };
  });

  return mergedLiens;
};

export const filterOutCurrentContact = (contacts: ContactType[], currentContactId: string) => {
  if (!currentContactId || !contacts) return contacts;
  return contacts.filter((contact) => contact.contactId !== currentContactId);
};

/**
 *
 * @description This function is used to get the options for template document generation
 */
export const getContactDetailsOptions = (
  contact: ContactViewModelType,
  key:
    | 'emailAddress'
    | 'phoneNumber'
    | 'address'
    | 'job'
    | 'pronounsPersonal'
    | 'pronounsPossessive'
    | keyof ContactViewModelType,
  furtherIdentifyingKey?: string,
) => {
  const contactName = displayContactName(contact);
  if (key === 'emailAddress') {
    return (
      contact?.contactEmails.map((email) => {
        const context = email.emailLabel ? `${contactName} (${email.emailLabel})` : contactName;
        return {
          value: email.emailAddress,
          context,
        };
      }) ?? []
    );
  } else if (key === 'phoneNumber') {
    return (
      contact?.contactNumbers.map((phone) => {
        let context = contactName;
        if (phone.numberType) {
          context = `${context} - ${startCase(phone.numberType)}`;
        }
        if (phone.numberLabel) {
          context = `${context} (${phone.numberLabel})`;
        }
        return {
          value: formatPhoneNumber(phone.number ?? ''),
          context,
        };
      }) ?? []
    );
  } else if (key === 'address') {
    let getAddressValue = (address: ContactAddressType) => formatAddress(address);
    switch (furtherIdentifyingKey) {
      case 'state':
        getAddressValue = (address) => address.state ?? '-';
        break;
      case 'zip':
        getAddressValue = (address) => address.zipCode ?? '-';
        break;
      case 'city':
        getAddressValue = (address) => address.city ?? '-';
        break;
      default:
        break;
    }
    return (
      contact?.contactAddresses.map((address) => {
        let context = contactName;
        if (furtherIdentifyingKey) {
          context = `${context} - ${formatAddress(address)}`;
        }
        if (address.addressLabel) {
          context = `${context} (${address.addressLabel})`;
        }
        return {
          value: getAddressValue(address) ?? '-',
          context,
        };
      }) ?? []
    );
  } else if (key === 'job') {
    const jobKey =
      (furtherIdentifyingKey as keyof ContactEmploymentType) ??
      ('title' as keyof ContactEmploymentType);

    return (
      contact?.contactEmployments?.map((employment) => {
        return { value: employment[jobKey], context: contactName };
      }) ?? []
    );
  } else if (key === 'pronounsPersonal') {
    return [{ value: contact.pronouns?.split('/')[0], context: contactName }];
  } else if (key === 'pronounsPossessive') {
    return [{ value: contact.pronouns?.split('/')[1], context: contactName }];
  }
  return [];
};

export const formatContactChipDetails = (contact: ContactViewModelType) => {
  const address = `${contact.contactAddresses[0]?.streetAddress ?? ''} ${
    contact.contactAddresses[0]?.city ?? ''
  } ${contact.contactAddresses[0]?.state ?? ''} ${contact.contactAddresses[0]?.zipCode ?? ''} ${
    contact.contactAddresses[0]?.country ?? ''
  }`;
  const addressesMore =
    contact?.contactAddresses?.length > 1 && ` +${contact?.contactAddresses?.length - 1} more`;
  const email = contact.contactEmails[0]?.emailAddress;
  const emailsMore =
    contact?.contactEmails?.length > 1 && ` +${contact?.contactEmails?.length - 1} more`;

  return {
    address,
    addressesMore,
    email,
    emailsMore,
  };
};

export const formatPhoneNumbers = (contactPhoneNumbers: ContactNumberType[]) => {
  const primaryPhoneNumber = formatPhoneNumber(contactPhoneNumbers?.[0]?.number ?? '-');
  const additionalPhoneNumbers = contactPhoneNumbers
    ?.slice(1)
    ?.map((phoneNumber) => formatPhoneNumber(phoneNumber.number ?? '-'));

  return {
    primaryPhoneNumber,
    additionalPhoneNumbers,
  };
};

export const formatEmails = (contactEmails: ContactEmailType[]) => {
  const primaryEmail = contactEmails?.[0]?.emailAddress ?? '-';

  const additionalEmails = contactEmails?.slice(1)?.map((email) => email ?? '-');

  return {
    primaryEmail,
    additionalEmails,
  };
};

export const formatAddress = (
  contactAddress: Pick<
    ContactAddressType,
    | 'streetAddress'
    | 'streetAddress2'
    | 'streetAddress3'
    | 'streetAddress4'
    | 'city'
    | 'state'
    | 'zipCode'
    | 'country'
  >,
) => {
  const {
    streetAddress = '-',
    city = '',
    state = '',
    zipCode = '',
    country = '',
  } = contactAddress ?? {};

  const commaSeparator = (streetAddress || city) && (state || zipCode || country) ? ',' : '';

  return `${streetAddress} ${city}${commaSeparator} ${state} ${zipCode} ${country}`.trim();
};

export const formatAddresses = (contactAddresses: ContactAddressType[]) => {
  const primaryAddress = formatAddress(contactAddresses?.[0] ?? null);

  const additionalAddresses = contactAddresses?.slice(1)?.map((address) => formatAddress(address));

  return {
    primaryAddress,
    additionalAddresses,
  };
};
type NestedArrayOrObject = { [key: string]: any } | any[];

const getNestedAttributes = (contact: ContactType, keyPath: string[]): string[] => {
  return keyPath.reduce<NestedArrayOrObject | string[]>(
    (acc: NestedArrayOrObject, key: string): NestedArrayOrObject => {
      if (Array.isArray(acc)) {
        return acc
          .map((item: { [key: string]: string | string[] | undefined }) => item[key])
          .filter(Boolean) as string[];
      }
      return acc && typeof acc === 'object' ? (acc[key] as string[]) : [];
    },
    contact,
  ) as string[];
};

export const getUniqueAttributeFromContacts = (contacts: ContactType[], keyPath: string[]) => {
  const allAttributes = contacts
    .flatMap((contact) => getNestedAttributes(contact, keyPath))
    .filter((attr): attr is string => attr !== null && attr !== undefined && attr !== '');

  return Array.from(new Set(allAttributes)).map((attr) => ({
    label: attr ?? '',
    value: attr ?? '',
  }));
};

export const calculateContactAge = (
  contact?: Pick<ContactType, 'dateOfBirth' | 'deceased' | 'dateOfDeath'>,
) => {
  if (!contact || !contact.dateOfBirth) {
    return 0;
  }

  const endDate = contact.deceased && contact.dateOfDeath ? dayjs(contact.dateOfDeath) : dayjs();

  return endDate.diff(contact.dateOfBirth, 'year');
};
