import {
  objectToQueryString,
  tanstackTableNames,
  useCreateContact,
  useGetAudiences,
  useGetContactsListViewInfinite,
} from '@colosseum/data';
import {
  Button,
  CreateButton,
  CustomErrorBoundary,
  DataTable,
  Typography,
} from '@colosseum/shared-ui';
import { AudienceType, ColumnType, FilterField } from '@gladiate/types';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@sentry/react';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import AudienceSelect from './AudienceSelect/AudienceSelect';
import AudienceSlideover from './AudienceSlideover/AudienceSlideover';
import { columns } from './contact-table-columns';

/* eslint-disable-next-line */
export interface ContactsPageProps {}

export function ContactsPage() {
  const navigate = useNavigate();
  const [filterSlideoverOpen, setFilterSlideoverOpen] = useState(false);
  const [activeAudience, setActiveAudience] = useState<AudienceType | undefined>(undefined);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramsSize = [...new Set(params.keys())].length;

  //VARIABLES
  const contactsRes = useGetContactsListViewInfinite();

  const { data: audiencesData } = useGetAudiences();
  const createContact = useCreateContact();

  //FUNCTIONS
  function createContactById() {
    createContact.mutateAsync().then((res) => {
      navigate(`/contacts/${res.data.contactId}`);
    });
  }

  const contacts = contactsRes.data?.data ?? [];

  const handleRowClick = useCallback(
    (row: { original: { contactId: string } }) => {
      navigate(`/contacts/${row.original.contactId}`);
    },
    [navigate],
  );

  const getChildValue = (column: ColumnType): FilterField => {
    return { name: column.title, value: column.valueVariable };
  };

  const getColumnChildren = (columns: ColumnType[], filterFields: FilterField[]): FilterField[] => {
    columns.forEach((column: ColumnType) => {
      if (column.columns) {
        getColumnChildren(column.columns, filterFields);
      } else {
        filterFields.push(getChildValue(column));
      }
    });
    return filterFields;
  };

  const handleAudienceSelect = (audienceIdParam: string) => {
    const selectedAudience = audiencesData?.data?.find(
      (audience) => audience.audienceId === audienceIdParam,
    ) as AudienceType & { Name: string };
    setActiveAudience(selectedAudience);
    if (!selectedAudience) {
      return;
    }

    const {
      title,
      description,
      firmId,
      dateModified,
      dateCreated,
      audienceId,
      lastContactedByClientDateRangeEnd,
      lastContactedByClientDateRangeStart,
      lastContactedByFirmDateRangeEnd,
      lastContactedByFirmDateRangeStart,
      Name,
      ...audienceQueryObject
    } = selectedAudience;

    // Convert the query object to a query string
    const queryString = objectToQueryString(audienceQueryObject);

    // Navigate to the current page with the new query parameters
    navigate(`?${queryString}`, { replace: true });
  };

  return (
    <>
      <Helmet defer={false}>
        <title>Contacts</title>
      </Helmet>
      <AudienceSlideover
        open={filterSlideoverOpen}
        setOpen={setFilterSlideoverOpen}
        setActiveAudience={setActiveAudience}
        handleAudienceSelect={handleAudienceSelect}
        activeAudience={activeAudience}
      />
      <div
        className="relative mt-5 bg-white border shadow-sm rounded-xl"
        data-cy="contacts-list-view"
      >
        <div className="flex w-full px-4">
          <div className="flex justify-between w-full gap-10 mt-5 ml-1">
            <Typography tag="h1" variant="pageHeading">
              Contacts
            </Typography>
            <div className="flex items-center">
              <CreateButton
                title={'Create Contact'}
                loading={createContact.isLoading}
                onClick={createContactById}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <DataTable
            fetchingMore={!!contactsRes.data?.meta.lastEvaluatedKey}
            data={contacts}
            columns={columns}
            handleRowClick={handleRowClick}
            showSearchBar
            isLoading={contactsRes.isLoading}
            handleResetFilters={() => {
              setActiveAudience(undefined);
            }}
            initialVisibility={{
              'Date Created': false,
              'Contact Type': false,
            }}
            initialSort={{
              id: 'Date Created',
              desc: true,
            }}
            customRightButton={
              <div className="inline-flex h-8 rounded-md whitespace-nowrap">
                <Button
                  variant="outline"
                  size="unset"
                  className="relative px-2 ml-2 rounded-r-none"
                  onClick={() => {
                    setFilterSlideoverOpen(true);
                  }}
                >
                  <FunnelIcon className="w-5 h-5 mr-1" />
                  <Typography className="max-w-[200px] truncate">
                    {activeAudience?.title ?? 'Filters'}
                  </Typography>
                  {paramsSize > 0 && (
                    <div className="absolute top-[-7px] w-4 h-4 left-[-7px] bg-sky-blue text-white leading-[16px] rounded-xl text-xs">
                      {paramsSize}
                    </div>
                  )}
                </Button>
                <AudienceSelect
                  activeFilter={activeAudience}
                  handleItemSelect={handleAudienceSelect ?? undefined}
                  placeholder=""
                  triggerClassName="relative top-[-1px] inline-flex items-center px-1.5 h-8 text-sm border border-gray-200 rounded-none rounded-r-md focus:z-10 focus:ring-0 focus:ring-offset-0"
                />
              </div>
            }
            filtersInParams
            tableName={tanstackTableNames.contacts}
          />
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(ContactsPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
