import {
  settingsSectionMappings,
  useCreateCalendar,
  useGetPrimaryFirmCalendar,
  useGetUserNylasAccount,
} from '@colosseum/data';
import { CreateButton, Form, GladiateLoader, TextFormInput } from '@colosseum/shared-ui';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

const createFirmCalendarFormSchema = z.object({
  name: z.string().min(1, 'Calendar name must be at least 1 character'),
  description: z.string().min(1, 'Calendar description must be at least 1 character'),
});

/* eslint-disable-next-line */
export interface CalendarSectionProps {}

export function CalendarSection(props: CalendarSectionProps) {
  const primaryCalendarQuery = useGetPrimaryFirmCalendar();
  const nylasAccountQuery = useGetUserNylasAccount();
  const nylasAccount = nylasAccountQuery.data?.data;
  const nylasAccountNotFound = nylasAccount === undefined;

  const primaryCalendarQueryData = primaryCalendarQuery.data?.data;
  const primaryCalendarQueryNotFound = primaryCalendarQueryData === undefined;

  const createCalendarMutation = useCreateCalendar();

  const createFirmCalendarForm = useForm<z.infer<typeof createFirmCalendarFormSchema>>({
    resolver: zodResolver(createFirmCalendarFormSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  function onSubmit(values: z.infer<typeof createFirmCalendarFormSchema>) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    createCalendarMutation.mutate({
      name: values.name,
      description: values.description,
      timezone: timezone,
      primaryCalendar: true,
    });
  }

  return (
    <div className="px-1" data-settings-submenu-item={settingsSectionMappings.firmCalendar}>
      {primaryCalendarQueryNotFound && (
        <h1 className="pr-3 mb-5 text-xl font-semibold text-gray-900 whitespace-nowrap">
          Firm Calendar
        </h1>
      )}
      <div className="flex items-center justify-between">
        {!primaryCalendarQueryNotFound && (
          <h1 className="pr-3 text-xl font-semibold text-gray-900 whitespace-nowrap">
            Firm Calendar
          </h1>
        )}

        {primaryCalendarQueryNotFound ? (
          <Form {...createFirmCalendarForm}>
            <form className="flex w-full space-x-3">
              <TextFormInput title={'Calendar Name'} name="name" />
              <div className="flex-grow pr-3">
                <TextFormInput title={'Calendar Description'} name="description" />
              </div>

              <CreateButton
                title="Create Calendar"
                loading={createCalendarMutation.isLoading}
                onClick={createFirmCalendarForm.handleSubmit(onSubmit)}
                disabled={nylasAccountNotFound}
                disabledTooltip="Please connect your Nylas account on the profile page to create a calendar."
              />
            </form>
          </Form>
        ) : (
          <div className="flex items-center">
            {createCalendarMutation.isLoading ? (
              <div>
                <GladiateLoader height={40} />
              </div>
            ) : (
              <div className="flex items-center px-3 py-1 border border-green-500 rounded-md">
                <CalendarDaysIcon className="w-6 h-6 text-green-500" />
                <p className="ml-2 text-sm text-green-500">Primary Calendar Linked</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarSection;
