import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Typography from '../Typography/Typography';

export interface OptionLookupProps {
  caseId: string;
  options: any[]; //list of options to display
  idString: string; // id of the option to be used in the lookup e.g. 'contactId'
  displayOption: any; // the function to use to display the option
  linkingFunction: any; // function to call to link the option to whatever you are linking it to
  createFunction: any; // function to call to create the option, usually an async function that returns a response object
  rightAlign: boolean; // if true, the dropdown will be left aligned
  mutationFn?: boolean; // if true the create linking function will be a mutation function instead of a regular function
  isLoading?: boolean; // if true, the loader will be displayed
  isError?: boolean; // if true, the error message will be displayed
  placeholder?: string;
  isFetchingMore?: boolean;
  setOpen?: (arg0: boolean) => void;
}

export function OptionLookup(props: OptionLookupProps) {
  const { isLoading, isFetchingMore, setOpen } = props;
  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const [newOptionId, setNewOptionId] = useState('');

  const filteredOptions = props.options
    .filter((option) => {
      const displayOption = props.displayOption(option)?.toLowerCase();
      // Removes options with dashes and options that don't include search term
      return (
        displayOption &&
        !['- ', '- -', ' -', '-'].includes(displayOption) &&
        displayOption.includes(search.toLowerCase())
      );
    })
    .sort((a: any, b: any) => {
      const nameA = props.displayOption(a)?.toLowerCase() || '';
      const nameB = props.displayOption(b)?.toLowerCase() || '';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return nameA.localeCompare(nameB); // Sort remaining names alphabetically
    });

  const createOptionAndLink = (e: any) => {
    if (props.mutationFn) {
      props.createFunction(e);
    } else {
      props.createFunction(e)?.then((res: any) => {
        setNewOptionId(res[props.idString]);
        queryClient.invalidateQueries({
          queryKey: ['contacts'],
        });
        queryClient.invalidateQueries({
          queryKey: ['contact'],
        });
        queryClient.invalidateQueries({
          queryKey: ['caseContactConnections', props.caseId],
        });

        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ['contacts'],
          });
          queryClient.invalidateQueries({
            queryKey: ['contact'],
          });
          queryClient.invalidateQueries({
            queryKey: ['caseContactConnections', props.caseId],
          });
        }, 1000);
      });
    }
  };

  return (
    <div>
      <div className="sticky top-0 flex items-center px-2 bg-white border-b rounded-md border-b-gray-200">
        {isFetchingMore ? (
          <GladiateLoader height={20} />
        ) : (
          <MagnifyingGlassIcon className="w-4 h-4 ml-1 text-gray-700 bg-white" />
        )}
        <input
          placeholder={props.placeholder ?? 'Search...'}
          onChange={(e) => setSearch(e.target.value)}
          className="block py-2 ml-2 text-sm text-gray-700 focus:outline-none"
        />
      </div>
      {props.isError && (
        <Typography variant="subtext" className="px-4 py-2">
          Error fetching data. Gladiate has been notified.
        </Typography>
      )}

      {isLoading ? (
        <GladiateLoader />
      ) : (
        <>
          {props.createFunction && (
            <button
              onClick={(e) => {
                createOptionAndLink(e);
                setTimeout(() => {
                  setOpen && setOpen(false);
                }, 1000);
              }}
              disabled={isLoading}
              className="block w-full px-4 py-2 text-sm text-left text-gray-700 bg-white border-b cursor-pointer border-b-gray-200 hover:bg-gray-100 hover:text-gray-900"
            >
              {isLoading ? <GladiateLoader height={30} /> : <div>Create New ...</div>}
            </button>
          )}
          <Virtuoso // virtualize this as it is a large list
            style={{
              height: `${filteredOptions.length * 36}px`,
              maxHeight: '180px',
            }}
            className="max-w-[90vw]"
            totalCount={filteredOptions.length}
            itemContent={(index: number) => (
              <div
                className="block px-4 py-2 text-sm text-gray-700 bg-white cursor-pointer hover:bg-gray-100 hover:text-gray-900 line-clamp-2"
                data-cy={`option-lookup-${props.displayOption(filteredOptions[index]) as string}`}
                role="menuitem"
                key={`${props.displayOption(filteredOptions[index]) as string}${index}`}
                onClick={() => {
                  props.linkingFunction(filteredOptions[index]);
                  setOpen && setOpen(false);
                }}
              >
                <span className="text-gray-900">
                  {props.displayOption(filteredOptions[index])}
                  <span className="text-gray-700"> </span>
                </span>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
}

export default OptionLookup;
