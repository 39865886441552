import {
  displayContactName,
  filterOutCurrentContact,
  useCreateContact,
  useCreateContactConnection,
  useDeleteContactConnection,
  useGetContactsInfinite,
} from '@colosseum/data';
import { ContactType, ContactViewModelType, contactConnectionOptions } from '@gladiate/types';
import { PlusIcon } from '@heroicons/react/24/outline';
import kebabCase from 'lodash/kebabCase';
import { useCallback, useState } from 'react';
import ContactChip from '../ContactChip/ContactChip';
import ContactInfoSlideover from '../ContactInfoSlideover/ContactInfoSlideover';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import { OptionLookup } from '../OptionLookup/OptionLookup';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

export interface ContactDropdownProps {
  popoverOpen: boolean;
  setPopoverOpen: (arg0: boolean) => void;
  contactOptions?: ContactType[];
  linkingFunction?: (e: ContactType) => void;
  createFunction?: (e: any) => void;
  dataCy?: string;
  isFetchingMore?: boolean;
}

export function ContactDropdown(props: ContactDropdownProps) {
  const {
    popoverOpen,
    setPopoverOpen,
    contactOptions,
    dataCy,
    createFunction,
    linkingFunction,
    isFetchingMore,
  } = props;
  return (
    <Popover open={popoverOpen} onOpenChange={(open) => setPopoverOpen(open)} modal>
      <PopoverTrigger data-cy={dataCy ?? 'contacts-dropdown'} asChild>
        <Button
          variant="outline"
          size="unset"
          className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-1 text-gray-500"
        >
          <PlusIcon className="h-5 min-w-5" aria-hidden="true" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-50">
        <OptionLookup
          isFetchingMore={isFetchingMore}
          placeholder={`Search ${contactOptions?.length ?? 0}${
            isFetchingMore ? '+' : ''
          } contacts...`}
          caseId={''}
          options={contactOptions ?? []}
          displayOption={(option: ContactType) => displayContactName(option)}
          idString={'contactId'}
          linkingFunction={linkingFunction}
          createFunction={createFunction}
          rightAlign={false}
        />
      </PopoverContent>
    </Popover>
  );
}

export interface ContactConnectionDropdownProps {
  title: string;
  contact: ContactViewModelType;
  primaryRelationship: (typeof contactConnectionOptions)[keyof typeof contactConnectionOptions];
  secondaryRelationship: (typeof contactConnectionOptions)[keyof typeof contactConnectionOptions];
  directManagerContactConnectionId?: string;
  employerContactConnectionId?: string;
  contactEmploymentId?: string;
  relationshipShowKey?: (typeof contactConnectionOptions)[keyof typeof contactConnectionOptions];
}

export function ContactConnectionDropdown(props: ContactConnectionDropdownProps) {
  const {
    title,
    contact,
    primaryRelationship,
    secondaryRelationship,
    directManagerContactConnectionId,
    employerContactConnectionId,
    contactEmploymentId, // one contact employment ID, map through contact connections
    relationshipShowKey,
  } = props;

  const createContact = useCreateContact();
  const createContactConnection = useCreateContactConnection();
  const deleteContactConnection = useDeleteContactConnection();
  const [newContactId, setNewContactId] = useState('');
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { data: allContacts, isLoading: isContactsLoading } = useGetContactsInfinite();
  const allContactsExcludingCurrent = filterOutCurrentContact(
    allContacts?.data ?? [],
    contact?.contactId,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [newContactModalOpen, setNewContactModalOpen] = useState(false);
  const relationshipToShow = relationshipShowKey ? relationshipShowKey : primaryRelationship;

  const handleLinkContactConnection = (e: any) => {
    createContactConnection.mutate({
      primaryContactId: contact?.contactId,
      secondaryContactId: e.contactId,
      primaryContactRelationship: primaryRelationship,
      contactEmploymentId: contactEmploymentId,
    });
    setPopoverOpen(false);
  };

  const handleCreateContactConnection = () => {
    const res = createContact.mutateAsync().then((res) => {
      createContactConnection.mutate({
        primaryContactId: contact?.contactId,
        secondaryContactId: res?.data?.contactId,
        primaryContactRelationship: primaryRelationship,
        contactEmploymentId: contactEmploymentId,
      });
      setNewContactId(res.data?.contactId);
      setPopoverOpen(false);
      setNewContactModalOpen(true);
      return res?.data;
    });
    return res;
  };

  const handleDeleteContactConnection = (contactConnectionId: string) => {
    deleteContactConnection.mutate(contactConnectionId);
  };

  const calculateRelationshipSpecificConnections = useCallback(() => {
    const { directReport, employee } = contactConnectionOptions;
    if (([directReport, employee] as string[]).includes(relationshipToShow)) {
      return contact?.contactConnections?.filter((connection) => {
        return (
          connection?.contactEmploymentId === contactEmploymentId &&
          connection?.relationship === relationshipToShow
        );
      });
    }
    return contact?.contactConnections?.filter((connection) => {
      return connection.relationship === relationshipToShow;
    });
  }, [contact, directManagerContactConnectionId, employerContactConnectionId, primaryRelationship]);
  const relationshipSpecificConnections = calculateRelationshipSpecificConnections();

  return (
    <>
      {newContactId && (
        <ContactInfoSlideover
          contactId={newContactId}
          open={newContactModalOpen}
          setOpen={setNewContactModalOpen}
        />
      )}
      <Typography variant="subtext" color={'black'} size={'sm'}>
        {title}
      </Typography>

      <div className="relative flex flex-wrap items-center pb-4">
        {relationshipSpecificConnections &&
          relationshipSpecificConnections.map((connection) => {
            return (
              <ContactChip
                contactId={connection.connectedToContact.contactId}
                key={connection.contactConnectionId}
                onClick={(e) => {
                  e.preventDefault();
                  setPopoverOpen(false);
                  setModalOpen(true);
                }}
                onDelete={(e) => {
                  e.preventDefault();
                  handleDeleteContactConnection(connection?.contactConnectionId);
                }}
                dataCyTitle={title.toLowerCase()}
              />
            );
          })}
      </div>
      {isContactsLoading || createContactConnection.isLoading ? (
        <button className="flex items-center w-full text-sm text-center">
          <GladiateLoader height={42} />
        </button>
      ) : (
        <ContactDropdown
          isFetchingMore={!!allContacts?.meta?.lastEvaluatedKey}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          contactOptions={allContactsExcludingCurrent}
          linkingFunction={handleLinkContactConnection}
          createFunction={handleCreateContactConnection}
          dataCy={`add-${kebabCase(title) as string}-contact-connection`}
        />
      )}
    </>
  );
}

export default ContactConnectionDropdown;
