import { Outlet } from 'react-router-dom';
import PreviewFeaturePage from '../../preview/PreviewFeaturePage';
import { menuNavigation, usePathParts, usePermissions } from '@colosseum/data';
import { PAGES_MAP } from '@gladiate/types';
import { Typography } from '@colosseum/shared-ui';
import { CheckIcon } from '@heroicons/react/24/outline';

const SubscriptionOfferringCards = () => {
  const SubscriptionFeatures = [
    {
      title: 'Case Optimization',
      price: '$99',
      interval: 'each month',
      features: ['Case management', 'Case analysis', 'Case outreach', 'Firm Calendar'],
    },
    {
      title: 'Client Engagement',
      price: '$99',
      interval: 'each month',
      features: ['SMS communication', 'Surveys', 'Campaigns', 'Handwrytten letters'],
    },
    {
      title: 'Insights',
      price: '$99',
      interval: 'each month',
      features: ['Time log analytics', 'Medical billing history'],
    },
  ];
  return (
    <div className="flex flex-row justify-center gap-4 flex-wrap">
      {SubscriptionFeatures.map((feature) => (
        <div
          key={feature.title}
          className="px-5 flex flex-col h-auto bg-white border shadow-sm rounded-xl mt-6 sm:flex-row sm:space-x-4"
        >
          <div className="flex flex-col p-4 py-10  ">
            <Typography variant="grayUpper" color="blue" size="xl" className="mb-5">
              {feature.title}
            </Typography>
            <Typography size={'3xl'} variant="semiBold">
              {feature.price}
            </Typography>
            <Typography size="xs" color="gray">
              {feature.interval}
            </Typography>

            <div className="divide-y divide-gray-50 divide-solid w-[200px] border-[1px] my-8">
              {' '}
            </div>
            <ul className={' list-inside gap-1 flex flex-col items-start'}>
              {feature.features.map((item) => (
                <div className="flex gap-2" key={item}>
                  <CheckIcon className="w-4 h-4 text-green-500" />
                  <li className="text-sm text-gray-500">{item}</li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductSummary = () => {
  const features = [
    'Streamline firm operations with the most intuitive all-in-one CMS & CRM for personal injury',
    'Get a consolidated view of every case',
    'Leverage built in task automation, electronic signature, and automated' +
      ' medical record requests',
  ];

  return (
    <div className="flex flex-col flex-wrap max-w-[500px] text-justify mt-4">
      <ul className="marker:text-gray-500  list-disc flex flex-col gap-1 leading-7">
        {features.map((feature) => (
          <li key={feature}>
            <Typography color="gray" className=" mr-20">
              {feature}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
/**
 * @param redirectTo optional redirect path
 * @param children optional children
 * @usage <ProtectedRoute redirectTo="/path">...</ProtectedRoute>
 * @description A component to protect a page route and redirects to the PreviewFeature page if the user doesn't have
 * subscription access to the page/section being accessed. Different from PrivateRoute in that it checks for
 * subscription access instead of authentication.
 */
export default function ProtectedRoute({
  redirectTo = '/subscriptions',
  children,
}: {
  redirectTo?: string;
  children?: React.ReactNode;
}) {
  const { resource } = usePathParts();
  const { hasSubscriptionAccess, hasSubscriptionAccessIsLoading, hasAssignedLicense } =
    usePermissions();

  const accessedSection = menuNavigation.find((item) => item.href === resource.replace(/^/, '/'))
    ?.pageMapKey as keyof typeof PAGES_MAP;

  const sectionIsAllowed = resource === 'profile' ? true : hasSubscriptionAccess(accessedSection);

  const isProfilePage = resource === 'profile';

  if (hasSubscriptionAccessIsLoading) {
    return null;
  }

  if ((!sectionIsAllowed || !hasAssignedLicense()) && !isProfilePage) {
    return (
      <PreviewFeaturePage
        previewTitle="Run your firm with clarity, speed, and agility."
        previewSection={<ProductSummary />}
        buttonMessage={'Contact Gladiate'}
        buttonRoute={'mailto:success@gladiatelaw.com'}
        caption={
          <div className="flex flex-row justify-center mt-4">
            <Typography color="gray" size="sm">
              {'Contact us at '}
              <a
                href={'mailto:success@gladiatelaw.com'}
                className={'font-medium text-atlantic-blue hover:text-sky-blue'}
              >
                {'success@gladiatelaw.com'}
              </a>
              {' to begin or re-activate your subscription.'}
            </Typography>
          </div>
        }
      />
    );
  }
  return children ? children : <Outlet />;
}
