import {
  DefaultV3Response,
  StaffRoleType,
  StaffType,
  TeamType,
  TeammateType,
  TimeLogCategoryType,
  TimeLogType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

export const baseLegionRoute = `legion/${API_STAGE}/v3`;

// ------------------- STAFF ROLES ------------------- //
// #region
// POST create_staff_role_v3
export const createStaffRoleV3 = (role: Partial<StaffRoleType>) =>
  axiosInstance
    .post<DefaultV3Response<StaffRoleType>>(`${baseLegionRoute}/roles`, role)
    .then((res) => res.data);

// DELETE delete_staff_role_v3
export const deleteStaffRoleV3 = (roleId: string) =>
  axiosInstance
    .delete<DefaultV3Response<StaffRoleType>>(`${baseLegionRoute}/roles/${roleId}`)
    .then((res) => res.data);

// GET get_staff_roles_v3
export const getStaffRolesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<StaffRoleType[]>>(`${baseLegionRoute}/roles`)
    .then((res) => res.data);

// PATCH update_staff_role_v3
export const updateStaffRoleV3 = (role: Partial<StaffRoleType>) => {
  const { roleId, ...rest } = role;
  return axiosInstance
    .patch<DefaultV3Response<StaffRoleType>>(`${baseLegionRoute}/roles/${role.roleId}`, rest)
    .then((res) => res.data);
};
// #endregion
// ------------------- STAFF ------------------- //
// #region
// POST create_staff_v3
export const createStaffV3 = ({ caseId, staff }: { caseId: string; staff?: Partial<StaffType> }) =>
  axiosInstance
    .post<DefaultV3Response<StaffType>>(`${baseLegionRoute}/cases/${caseId}/staff`, staff)
    .then((res) => res.data);

// DELETE delete_staff_v3
export const deleteStaffV3 = (staffId: string) =>
  axiosInstance
    .delete<DefaultV3Response<StaffType>>(`${baseLegionRoute}/cases/staff/${staffId}`)
    .then((res) => res.data);

// GET get_staff_v3
export const getStaffV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<StaffType[]>>(`${baseLegionRoute}/cases/${caseId}/staff`)
    .then((res) => res.data);

// GET get_all_staff_v3
export const getAllStaffV3 = () =>
  axiosInstance
    .get<DefaultV3Response<StaffType[]>>(`${baseLegionRoute}/cases/staff`)
    .then((res) => res.data);
// #endregion
// ------------------- TIME LOG ------------------- //
// #region
// POST create_time_log_v3
export const createTimeLogV3 = (data: Partial<TimeLogType> & { itemId: string }) => {
  const { itemId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<TimeLogType>>(`${baseLegionRoute}/${itemId}/time-logs`, rest)
    .then((res) => res.data);
};

// DELETE delete_time_log_v3
export const deleteTimeLogV3 = (timeLogId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TimeLogType>>(`${baseLegionRoute}/time-logs/${timeLogId}`)
    .then((res) => res.data);

// GET get_all_time_logs_v3
export const getAllTimeLogsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TimeLogType[]>>(`${baseLegionRoute}/time-logs`)
    .then((res) => res.data);

// GET get_time_logs_v3
export const getTimeLogsV3 = (itemId?: string) =>
  axiosInstance
    .get<DefaultV3Response<TimeLogType[]>>(`${baseLegionRoute}/${itemId}/time-logs`)
    .then((res) => res.data);

export const updateTimeLogV3 = (timeLog: Partial<TimeLogType>) => {
  const { timeLogId, ...rest } = timeLog;
  return axiosInstance
    .patch<DefaultV3Response<TimeLogType>>(`${baseLegionRoute}/time-logs/${timeLogId}`, rest)
    .then((res) => res.data);
};
// #endregion
// ------------------- TIME LOG CATEGORY ------------------- //
// #region
// POST create_time_log_category_v3
export const createTimeLogCategoryV3 = (category: Partial<TimeLogCategoryType>) =>
  axiosInstance
    .post<DefaultV3Response<TimeLogCategoryType>>(
      `${baseLegionRoute}/time-log-categories`,
      category,
    )
    .then((res) => res.data);

// DELETE delete_time_log_category_v3
export const deleteTimeLogCategoryV3 = (timeLogCategoryId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TimeLogCategoryType>>(
      `${baseLegionRoute}/time-log-categories/${timeLogCategoryId}`,
    )
    .then((res) => res.data);

// get, update
// GET get_time_log_categories_v3
export const getTimeLogCategoriesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TimeLogCategoryType[]>>(`${baseLegionRoute}/time-log-categories`)
    .then((res) => res.data);

// PATCH update_time_log_category_v3
export const updateTimeLogCategoryV3 = (category: Partial<TimeLogCategoryType>) => {
  const { timeLogCategoryId, ...rest } = category;
  return axiosInstance
    .patch<DefaultV3Response<TimeLogCategoryType>>(
      `${baseLegionRoute}/time-log-categories/${timeLogCategoryId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ------------ TEAM ------------------- //

// POST create_team_v3
export const createTeamV3 = (team: Partial<TeamType>) =>
  axiosInstance
    .post<DefaultV3Response<TeamType>>(`${baseLegionRoute}/teams`, team)
    .then((res) => res.data);

// DEL delete_team_v3
export const deleteTeamV3 = (teamId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<TeamType>>(`${baseLegionRoute}/teams/${teamId}`)
    .then((res) => res.data);

// GET get_teams_v3
export const getTeamsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TeamType[]>>(`${baseLegionRoute}/teams`)
    .then((res) => res.data);

// UPDATE update_team_v3
export const updateTeamV3 = (team: Partial<TeamType>) => {
  const { teamId, ...rest } = team;
  return axiosInstance
    .patch<DefaultV3Response<TeamType>>(`${baseLegionRoute}/teams/${teamId}`, rest)
    .then((res) => res.data);
};

// POST assign_team_v3
export const assignTeamV3 = ({ teamId, caseId }: { teamId: string; caseId: string }) =>
  axiosInstance
    .post<DefaultV3Response<TeamType>>(`${baseLegionRoute}/cases/${caseId}/assign-team`, { teamId })
    .then((res) => res.data);

// #endregion

// #region ------------ TEAMMATE ------------------- //
// POST create_teammate_v3
export const createTeammateV3 = (teammate: Partial<TeammateType> & { teamId: string }) => {
  const { teamId, ...rest } = teammate;
  return axiosInstance
    .post<DefaultV3Response<TeammateType>>(`${baseLegionRoute}/teams/${teamId}/teammates`, rest)
    .then((res) => res.data);
};

// DELETE delete_teammate_v3
export const deleteTeammateV3 = (teammateId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<TeammateType>>(`${baseLegionRoute}/teams/teammates/${teammateId}`)
    .then((res) => res.data);
