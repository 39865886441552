import { renderCell, renderHeader } from '@colosseum/shared-ui';

import { TooltipWrapper } from '@colosseum/shared-ui';
import { ReferralSource } from '@gladiate/types';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import { handleCopyReferralUrl } from './ReferralSources';
export const columns: ColumnDef<ReferralSource>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.referralSourceId,
    id: 'Share Link',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const id = props.getValue() as string;
      return (
        <TooltipWrapper message={'Copy to clipboard'}>
          <ClipboardDocumentCheckIcon
            className="w-5 h-5 text-gray-900 cursor-pointer hover:text-atlantic-blue"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              handleCopyReferralUrl(id || '');
            }}
          />
        </TooltipWrapper>
      );
    },
  },
];
