import { cn } from '@colosseum/data';
import { XMarkIcon } from '@heroicons/react/24/solid';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React from 'react';

interface ButtonProps {
  label: string;
  className: string;
  onClick: () => void;
}

/* eslint-disable-next-line */
export interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  hideCancel?: boolean;
  hideAllInteraction?: boolean;
}

//! newest modal use this
export function Modal(props: ModalProps) {
  return (
    <AlertDialog.Root open={props.open} onOpenChange={props.setOpen}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
        <AlertDialog.Content
          className={cn(
            'data-[state=open]:animate-contentShow flex flex-col z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
            props.width ? props.width : 'w-[90vw] max-w-[500px] ',
            props.height ? props.height : 'max-h-[85vh]',
          )}
        >
          <button
            onClick={() => props.setOpen(false)}
            className="absolute top-[15px] right-[15px] w-[20px] h-[20px]  text-mauve11 hover:text-mauve12 focus:outline-none"
          >
            <XMarkIcon />
          </button>

          <AlertDialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
            {props.title}
          </AlertDialog.Title>
          {props.description && (
            <AlertDialog.Description className="text-mauve11 mt-4 mb-5 text-[15px] leading-normal">
              {props.description}
            </AlertDialog.Description>
          )}

          {props.children && props.children}

          {!props.hideAllInteraction && (
            <div className="flex justify-end gap-[25px]">
              <AlertDialog.Action asChild>
                {props.primaryButton ? (
                  <button
                    data-cy={`modal-${props.primaryButton.label}`}
                    className={props.primaryButton.className}
                    onClick={props.primaryButton.onClick}
                  >
                    {props.primaryButton.label}
                  </button>
                ) : (
                  <button className="text-red11 bg-red4 hover:bg-red5 focus:shadow-red7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]">
                    Delete
                  </button>
                )}
              </AlertDialog.Action>

              {props.secondaryButton && (
                <button
                  className={props.secondaryButton.className}
                  onClick={props.secondaryButton.onClick}
                >
                  {props.secondaryButton.label}
                </button>
              )}

              {!props.hideCancel && (
                <AlertDialog.Cancel asChild>
                  <button className="text-mauve11 bg-mauve4 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]">
                    Cancel
                  </button>
                </AlertDialog.Cancel>
              )}
            </div>
          )}
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

export default Modal;
