import { cn } from '@colosseum/data';
import {
  ContactConnectionType,
  ContactType,
  contactConnectionOptions,
  contactConnectionOptionsReverse,
} from '@gladiate/types';
import { motion } from 'framer-motion';
import { startCase, uniq } from 'lodash';
import { PencilIcon } from 'lucide-react';
import { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

const animationVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
};

export interface EditRelationshipsPopoverProps {
  contact?: ContactType & { roles?: ContactConnectionType['relationship'][] };
  handleAddRole: (role: ContactConnectionType['relationship']) => void;
  handleRemoveRole: (role: ContactConnectionType['relationship']) => void;
}

export function EditRelationshipsPopover(props: EditRelationshipsPopoverProps) {
  const { contact, handleAddRole, handleRemoveRole } = props;
  const reversedRoles = contact?.roles?.map(
    (role) => role && contactConnectionOptionsReverse[role],
  );
  const buttonText = reversedRoles?.length === 0 ? 'Add Roles' : 'Edit Roles';
  const [selectedRoles, setSelectedRoles] = useState<ContactConnectionType['relationship'][]>(
    uniq((reversedRoles as ContactConnectionType['relationship'][]) ?? []),
  );

  return (
    <Popover modal>
      <PopoverTrigger>
        <div className="flex items-center px-2 py-1 text-xs border rounded-md gap-x-1 hover:bg-light-blue border-atlantic-blue text-atlantic-blue">
          <PencilIcon className="w-3 h-3" />
          {buttonText}
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-50 bg-white border border-gray-300 rounded-md shadow-md over ">
        <motion.div initial={'hidden'} animate={'show'} variants={animationVariants}>
          <div className="top-0 p-1 space-y-2 overflow-y-auto max-h-72">
            {Object.keys(contactConnectionOptions)
              .filter((option) => option !== 'connection')
              ?.map((contactConnectionOption) => (
                <div key={contactConnectionOption} className="flex items-center justify-between">
                  <div>{startCase(contactConnectionOption)}</div>
                  <div>
                    <input
                      type="checkbox"
                      className={cn(
                        'w-4 h-4 mr-2 text-blue-500 border-gray-300  rounded focus:ring-blue-500 form-checkbox',
                      )}
                      checked={selectedRoles?.some(
                        (selectedRole) => selectedRole === contactConnectionOption,
                      )}
                      onChange={() => {
                        let newRoles = [...selectedRoles];
                        if (
                          selectedRoles.includes(
                            contactConnectionOption as ContactConnectionType['relationship'],
                          )
                        ) {
                          newRoles = selectedRoles.filter(
                            (selectedRole) => selectedRole !== contactConnectionOption,
                          );
                          setSelectedRoles(newRoles);
                          handleRemoveRole(
                            contactConnectionOption as ContactConnectionType['relationship'],
                          );
                        } else {
                          newRoles.push(
                            contactConnectionOption as ContactConnectionType['relationship'],
                          );
                          setSelectedRoles(newRoles);
                          handleAddRole(
                            contactConnectionOption as ContactConnectionType['relationship'],
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </motion.div>
      </PopoverContent>
    </Popover>
  );
}

export default EditRelationshipsPopover;
