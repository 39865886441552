import { getOpenCases, useGetCaseStatuses, useGetCases } from '@colosseum/data';
import { GladiateLoader } from '@colosseum/shared-ui';
import { cloneDeep } from 'lodash';
import { Line } from 'rc-progress';
import { useMemo } from 'react';
import { OpenCaseData } from '../models/OpenCasesData';

export function OpenCases() {
  // REACT QUERY
  const casesQuery = useGetCases();
  const casesEntities = casesQuery.data?.data ?? [];
  const cases = Object.values(casesEntities); // it was complaining about about stuff so I added object as well
  const { data: caseStatuses } = useGetCaseStatuses();

  const defaultCaseStatuses = useMemo(() => {
    const temp = {} as {
      [key: string]: {
        workflowStatus: string;
        totalCases: number;
        percentage: number;
      };
    };
    caseStatuses?.data
      .sort((a, b) => {
        if (!a?.title || !b?.title) return 0;
        return a?.title.toLowerCase() > b?.title.toLowerCase() ? 1 : -1;
      })
      .forEach((caseStatus) => {
        if (!caseStatus.title || temp[caseStatus.title] || caseStatus.category !== 'open') return; // if no title, already exists, or not open, skip
        temp[caseStatus.title] = {
          workflowStatus: caseStatus.title,
          totalCases: 0,
          percentage: 0,
        };
      });
    temp['No Case Status'] = {
      workflowStatus: 'No Case Status',
      totalCases: 0,
      percentage: 0,
    };
    return temp;
  }, [caseStatuses]);

  const openCases = useMemo(() => getOpenCases(cases), [cases]);

  const openCasesByStatus = useMemo(
    () =>
      openCases.reduce(
        (
          acc: {
            [key: string]: {
              workflowStatus: string;
              totalCases: number;
              percentage?: number;
            };
          },
          curr,
        ) => {
          const caseStatus = curr?.caseStatus?.title ?? 'No Case Status';
          if (acc[caseStatus]) {
            acc[caseStatus].totalCases += 1;
            acc[caseStatus].percentage = (acc[caseStatus].totalCases / openCases.length) * 100;
          }
          return acc;
        },
        cloneDeep(defaultCaseStatuses),
      ),
    [openCases, defaultCaseStatuses],
  );

  return (
    <div className="relative p-5 mt-5 overflow-y-auto bg-white border shadow-sm rounded-xl sm:w-1/2">
      <h1 className="mb-5 text-lg font-medium leading-6 text-gray-900">Open Cases</h1>
      <div className="flex flex-col">
        {casesQuery.isLoading ? (
          <GladiateLoader />
        ) : (
          Object.values(openCasesByStatus)?.map((openCase: OpenCaseData, index: number) => {
            return (
              <div key={`${openCase?.workflowStatus}${index}`}>
                <div className="grid w-full grid-cols-2">
                  <div className="m-2">{openCase.workflowStatus}</div>
                  <div className="flex justify-end m-2">{openCase.totalCases}</div>
                </div>
                <div>
                  <div className="m-2">
                    <Line
                      percent={openCase.percentage ? openCase.percentage : 0}
                      strokeWidth={4}
                      strokeColor="#2db7f5"
                      trailWidth={4}
                      trailColor="#D9D9D9"
                    />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default OpenCases;
