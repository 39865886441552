import {
  CompleteMedicalTreatmentType,
  useDeleteMedicalTreatment,
  useUpdateMedicalTreatment,
} from '@colosseum/data';
import { ResourceSlideover, TabsContent, TreatmentForm } from '@colosseum/shared-ui';
import { Dispatch, SetStateAction, useState } from 'react';

export interface ActiveMedicalTreatmentSlideoverProps {
  caseId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  activeTreatment: CompleteMedicalTreatmentType;
  setActiveTreatmentId: Dispatch<SetStateAction<string | undefined>>;
  caseDetails: any;
}

export function ActiveMedicalTreatmentSlideover(props: ActiveMedicalTreatmentSlideoverProps) {
  const { caseId, open, setOpen, activeTreatment, setActiveTreatmentId, caseDetails } = props;

  const deleteMedicalTreatment = useDeleteMedicalTreatment();
  const updateMedicalTreatment = useUpdateMedicalTreatment();

  const [typing, setTyping] = useState(false);

  const handleDeleteTreatment = () => {
    deleteMedicalTreatment.mutateAsync(activeTreatment?.medicalTreatmentId).then(() => {
      setOpen(false);
      setActiveTreatmentId(undefined);
    });
  };

  function handleUpdateTreatment(val: string | number, key: string, medicalTreatmentId: string) {
    const treatmentUpdate = {
      medicalTreatmentId: medicalTreatmentId,
      [key]: val,
    };
    updateMedicalTreatment.mutate(treatmentUpdate);
  }

  return (
    <ResourceSlideover
      open={open}
      setOpen={setOpen}
      title={'Medical Treatment Bill'}
      description={'Add a new medical treatment bill'}
      typing={typing}
      deleteFunction={handleDeleteTreatment}
      displayDeleteButton={true}
      createType="medicalTreatment"
      resourceId={activeTreatment?.medicalTreatmentId}
      caseId={caseId}
    >
      <TabsContent value="details">
        <TreatmentForm
          updateHandler={handleUpdateTreatment}
          treatment={activeTreatment}
          caseId={caseId}
          typing={typing}
          setTyping={setTyping}
          estimatedMedicalCosts={caseDetails?.estimatedMedicalCosts ?? 0}
        />
      </TabsContent>
    </ResourceSlideover>
  );
}
