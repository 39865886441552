import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface RangePickerProps {
  title: string;
  updateKey: string;
}

const formSchema = z.object({
  start: z.string().optional(),
  end: z.string().optional(),
});

export function RangePicker(props: RangePickerProps) {
  const { title, updateKey } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const combinedVals = queryParams.get(updateKey) || '';
  const [startString, endString] = combinedVals.split(',');

  const [start, setStart] = useState<string | undefined>(startString ? startString : undefined);
  const [end, setEnd] = useState<string | undefined>(endString ? endString : undefined);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: useMemo(() => {
      return {
        start,
        end,
      };
    }, [start, end]),
  });

  const updateQueryParam = () => {
    let combinedValue;
    if (start && end) {
      combinedValue = `${start},${end}`;
    } else if (start) {
      combinedValue = start ?? '';
    } else if (end) {
      combinedValue = `0,${end}`;
    } else {
      combinedValue = '';
    }

    if (combinedValue) {
      queryParams.set(updateKey, String(combinedValue));
    } else {
      queryParams.delete(updateKey);
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  useEffect(() => {
    updateQueryParam();
  }, [start, end]);

  useEffect(() => {
    const paramsSize = [...new Set(queryParams.keys())].length;
    // if query params are reset - we want to make these all undefined
    if (paramsSize === 0 && (start || end)) {
      setEnd('');
      setStart('');
    }
  }, [queryParams]);

  return (
    <Form {...form}>
      <form>
        <div className="w-full">
          <div className="grid grid-cols-2 pt-2 gap-x-3">
            <TextFormInput
              {...form.register('start')}
              title={`Min ${title}`}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                setStart(target.value);
              }}
              type="number"
            />
            <TextFormInput
              {...form.register('end')}
              title={`Max ${title}`}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                setEnd(target.value);
              }}
              type="number"
            />
          </div>
        </div>
      </form>
    </Form>
  );
}

export default RangePicker;
