import { DefaultV3Error, DefaultV3Response, NotificationPreferencesType } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  acknowledgeNotificationsV3,
  getNotificationPreferencesV3,
  getNotificationsV3,
  updateNotificationPreferencesV3,
} from '../requests/herodotus';

// ------------------ NOTIFICATIONS ------------------

export const useGetNotifications = () => {
  return useQuery({
    queryKey: [queryKeys.notifications],
    queryFn: async () => getNotificationsV3(),
  });
};

export const useAcknowledgeNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof acknowledgeNotificationsV3>[0]) =>
      acknowledgeNotificationsV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notifications],
      });
    },
  });
};

// ------------------ NOTIFICATION PREFERENCES ------------------
export const useGetNotificationPreferences = () => {
  return useQuery({
    queryKey: [queryKeys.notificationPreferences],
    queryFn: async () => getNotificationPreferencesV3(),
  });
};

export const useUpdateNotificationPreferences = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateNotificationPreferencesV3>[0]) =>
      updateNotificationPreferencesV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onMutate: (data: Partial<NotificationPreferencesType>) => {
      // Update the cache optimistically
      queryClient.setQueryData(
        [queryKeys.notificationPreferences],
        (oldData: DefaultV3Response<NotificationPreferencesType> | undefined) => {
          if (!oldData) return;

          return { ...oldData, data: { ...oldData?.data, ...data } };
        },
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notificationPreferences],
      });
    },
  });
};
