import { cn } from '@colosseum/data';
import { LabyrinthField, LabyrinthUpdateHandler } from '@gladiate/types';
import { Popover, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';

interface CourtsTieredSelectProps {
  courtData: {
    [state: string]: {
      [type: string]: string[];
    };
  };
  handleOnChange: LabyrinthUpdateHandler;
  fieldValue: string[];
  fieldInfo: LabyrinthField;
  singleSelect?: boolean;
}

export function CourtsTieredSelect(props: CourtsTieredSelectProps) {
  const [selected, setSelected] = useState<string[]>(props?.fieldValue);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCourtData, setFilteredCourtData] = useState<{
    [state: string]: { [type: string]: string[] };
  }>(props.courtData);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredCourtData(props.courtData);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered: {
        [state: string]: {
          [type: string]: string[];
        };
      } = JSON.parse(JSON.stringify(props.courtData));

      for (const state in filtered) {
        for (const type in filtered[state]) {
          filtered[state][type] = filtered[state][type].filter((option) =>
            option.toLowerCase().includes(lowerCaseQuery),
          );
        }
        // Remove empty court types
        for (const type in filtered[state]) {
          if (filtered[state][type].length === 0) {
            delete filtered[state][type];
          }
        }
      }

      // Remove empty states
      const filteredStates = Object.fromEntries(
        Object.entries(filtered).filter(([_, courtTypes]) => {
          return Object.keys(courtTypes).length > 0;
        }),
      );

      setFilteredCourtData(filteredStates);
    }
  }, [searchQuery, props.courtData]);

  const handleTypeSelect = (type: string, courts: string[]) => {
    const allSelected = courts.every((court) => selected.includes(court));

    if (allSelected) {
      const newSelected = selected.filter((item) => !courts.includes(item));
      setSelected(newSelected);
      props.handleOnChange({
        field: props.fieldInfo,
        value: newSelected,
      });
    } else {
      const newSelected = [...selected, ...courts.filter((court) => !selected.includes(court))];
      setSelected(newSelected);
      props.handleOnChange({
        field: props.fieldInfo,
        value: newSelected,
      });
    }
  };

  const handleSelect = (option: string, additionalInfo: { [key: string]: string }) => {
    if (props.singleSelect) {
      if (selected[0] === option) {
        // Deselect the option
        setSelected([]);
        props.handleOnChange({
          field: props.fieldInfo,
          value: [],
        });
      } else {
        // Select the option
        setSelected([option]);
        props.handleOnChange({
          field: props.fieldInfo,
          value: [option],
          additionalInfo,
        });
      }
      return;
    }
    if (selected.includes(option)) {
      const newSelected = selected.filter((item) => item !== option);
      setSelected(newSelected);
      props.handleOnChange({
        field: props.fieldInfo,
        value: newSelected,
        additionalInfo,
      });
    } else {
      const newSelected = [...selected, option];
      setSelected(newSelected);
      props.handleOnChange({
        field: props.fieldInfo,
        value: newSelected,
        additionalInfo,
      });
    }
  };

  return (
    <div className="relative">
      <div className="relative ring-1 ring-inset ring-gray-300 focus:outline-none w-full flex items-center justify-between py-2.5 pl-3 pr-2 text-left text-gray-900 bg-white rounded-md shadow-sm cursor-default sm:text-sm sm:leading-6">
        <label className="absolute z-10 flex items-center px-1 text-xs font-medium text-gray-400 bg-white rounded-sm -top-2 left-2">
          {props.singleSelect ? 'Court Name' : 'Select Courts'}
        </label>
        <Popover.Group className="flex w-full">
          <Popover as="div" className="relative inline-block w-full text-left">
            <Popover.Button className="flex items-center justify-between w-full text-sm font-medium text-gray-700 group hover:text-gray-900 focus:outline-none">
              <div className="flex items-center">
                {!props.singleSelect && (
                  <span className="mr-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                    {selected.length}
                  </span>
                )}
                {selected.length === 1 ? (
                  selected
                ) : (
                  <span>{props.singleSelect ? 'Select a Court' : 'Selected Courts'}</span>
                )}
              </div>

              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 z-20 w-full p-4 mt-2 overflow-y-auto origin-top-right bg-white rounded-md shadow-2xl max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {/* Search input field */}
                <div className="relative w-full mb-4">
                  <input
                    type="text"
                    placeholder="Search options..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-3 -mb-2 py-1.5 form-input border border-gray-300 rounded-md shadow-sm focus:outline-none ring-inset focus:ring-1 focus:ring-atlantic-blue"
                  />
                </div>
                <div className="space-y-4">
                  {Object.entries(filteredCourtData).map(([state, courtTypes]) => (
                    <div key={state} className="space-y-1">
                      <h3 className="text-lg font-semibold">{state}</h3>
                      {Object.entries(courtTypes).map(([type, courts]) => (
                        <div key={type} className="space-y-1">
                          <div className="flex items-center ml-4">
                            {!props.singleSelect && (
                              <input
                                id={`filter-type-${state}-${type}`}
                                type="checkbox"
                                className="w-4 h-4 border-gray-300 rounded text-atlantic-blue form-checkbox"
                                checked={courts.some((court) => selected.includes(court))}
                                onChange={() => handleTypeSelect(type, courts)}
                              />
                            )}
                            <label
                              htmlFor={`filter-type-${state}-${type}`}
                              className={cn(
                                'block ml-2 text-base font-medium text-gray-800',
                                props.singleSelect && 'ml-0',
                              )}
                            >
                              {type}
                            </label>
                          </div>
                          {courts.map((court) => (
                            <div key={court} className="flex items-center ml-8">
                              <input
                                id={`filter-${state}-${type}-${court}`}
                                type="checkbox"
                                className="w-4 h-4 border-gray-300 rounded text-atlantic-blue form-checkbox "
                                checked={selected.includes(court)}
                                onChange={() => handleSelect(court, { type, state })}
                              />
                              <label
                                htmlFor={`filter-${state}-${type}-${court}`}
                                className="block ml-2 text-sm font-medium text-gray-600"
                              >
                                {court}
                              </label>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
      </div>
    </div>
  );
}

export default CourtsTieredSelect;
