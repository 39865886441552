import { convertDiffToHoursDuration } from '@colosseum/data';
import { TimeLogType } from '@gladiate/types';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import { dateRangeFilterFn } from '../shadcn/data-table/table-filter-helpers';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(objectSupport);

const columnHelper = createColumnHelper<
  TimeLogType & {
    timeLogCategory?: string;
    caseTitle?: string;
    displayName: string;
  }
>();

export const compactColumns = [
  columnHelper.accessor(
    (row) => {
      const startTime = dayjs(`${row?.startTime}Z`);
      const endTime = dayjs(row.endTime ? `${row?.endTime}Z` : undefined);
      const difference = endTime.diff(startTime);
      const hoursDuration = convertDiffToHoursDuration(difference);
      const formattedDuration = hoursDuration.format('HH:mm:ss');
      // return `${formattedDuration} (${startTime.format(
      //   "MMM D 'YY, hh:mm a"
      // )}-${endTime.format('hh:mm a')})`;
      return {
        duration: formattedDuration,
        startTime: startTime.format("MMM D 'YY, hh:mm a"),
        endTime: endTime.format('hh:mm a'),
      };
    },
    {
      id: 'Duration',
      filterFn: 'arrIncludesSome',
      cell: (props) => {
        const { duration, startTime, endTime } = props.getValue() ?? {};

        return (
          <div>
            <div className="font-semibold">{duration}</div>
            <div className="text-gray-600">
              (<span>{startTime}</span>
              <span> - </span>
              <span>{endTime}</span>)
            </div>
          </div>
        );
      },
    },
  ),
  columnHelper.accessor((row) => row.timeLogCategory, {
    id: 'Category',
  }),
  columnHelper.accessor((row) => row.displayName, {
    id: 'User',
  }),
];

export const columns = [
  columnHelper.accessor((row) => row.caseTitle, {
    id: 'Case',
    filterFn: 'arrIncludesSome',
    sortUndefined: -1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),

  columnHelper.accessor((row) => row.displayName, {
    id: 'User',
    filterFn: 'arrIncludesSome',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  }),
  columnHelper.accessor((row) => row.startTime, {
    id: 'Date',
    filterFn: dateRangeFilterFn,
    enableHiding: false,
  }),
  columnHelper.accessor(
    (row) => {
      const startTime = dayjs(`${row?.startTime}Z`);
      const endTime = dayjs(row.endTime ? `${row?.endTime}Z` : undefined);
      return `${startTime.format("MMM D 'YY, hh:mm a")} - ${endTime.format("MMM D 'YY, hh:mm a")}`;
    },
    {
      id: 'Date Time',
      filterFn: 'arrIncludesSome',
      header: renderHeader,
      cell: (props) => {
        const value = props.getValue();
        return <span className="whitespace-nowrap">{value}</span>;
      },
    },
  ),
  columnHelper.accessor(
    (row) => {
      const startTime = dayjs(`${row?.startTime}Z`);
      const endTime = dayjs(row.endTime ? `${row?.endTime}Z` : undefined);
      const difference = endTime.diff(startTime);
      const hoursDuration = convertDiffToHoursDuration(difference);
      const formattedDuration = hoursDuration.format('HH:mm:ss');
      return formattedDuration as string;
    },
    {
      id: 'Duration',
      filterFn: 'arrIncludesSome',
      aggregationFn: (columnId, values) => {
        let total = dayjs.duration({ hours: 0, minutes: 0, seconds: 0 });
        values.forEach((v) => {
          const [hours, minutes, seconds] = v.getValue<string>(columnId).split(':');
          total = total.add(
            dayjs.duration({
              hours: Number(hours),
              minutes: Number(minutes),
              seconds: Number(seconds),
            }),
          );
        });
        return dayjs
          .duration({
            hours: Math.floor(total.asHours()),
            minutes: total.minutes(),
            seconds: total.seconds(),
          })
          .format('HH:mm:ss') as string;
      },
      aggregatedCell: (props) => {
        const value = props.getValue();
        return value ? `${value}` : '';
      },
      header: renderHeader,
    },
  ),
  columnHelper.accessor((row) => row.timeLogCategory, {
    id: 'Category',
    filterFn: 'arrIncludesSome',
    header: renderHeader,
  }),
  columnHelper.accessor((row) => row.details, {
    id: 'Details',
    header: renderHeader,
  }),
  columnHelper.accessor((row) => (!row.endTime ? 'True' : '-'), {
    id: 'Active',
    header: renderHeader,
    filterFn: 'arrIncludesSome',
  }),
];
