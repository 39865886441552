import { useUpdateExpense } from '@colosseum/data';
import { ExpenseWithCategoryType } from '@gladiate/types';

/* eslint-disable-next-line */
export interface CloseExpenseFormProps {
  expenses: ExpenseWithCategoryType[];
  expenseTotal: number;
}

export function CloseExpenseForm(props: CloseExpenseFormProps) {
  const updateExpenseMutation = useUpdateExpense();

  const handleWaiveChange = (
    expense: ExpenseWithCategoryType,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateExpenseMutation.mutate({
      caseExpenseId: expense.caseExpenseId,
      waive: event.target.checked ? '1' : '0',
    });
  };

  // sort expenses by date
  const sortedExpenses = props?.expenses?.sort(
    (a: ExpenseWithCategoryType, b: ExpenseWithCategoryType) => {
      if (!a.dateIncurred || !b.dateIncurred) {
        return 0;
      }

      const dateA = new Date(a.dateIncurred);
      const dateB = new Date(b.dateIncurred);
      return dateA.getTime() - dateB.getTime();
    },
  );

  return (
    <div className="flex -mx-4 fl1ex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
            >
              Date Incurred
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Category
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Logged
            </th>
            <th
              scope="col"
              className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 "
            >
              Waive
            </th>
          </tr>
        </thead>
        <tbody className="">
          {sortedExpenses &&
            sortedExpenses?.map((expense, index) => (
              <tr key={expense?.caseExpenseId} className="">
                <td
                  className={
                    'pl-4 pr-3 text-sm sm:pl-6 md:pl-0 pb-3 ' + (index === 0 ? ' pt-3 ' : ' ')
                  }
                >
                  <div className="font-medium text-gray-900 ">{expense?.dateIncurred || '-'}</div>
                </td>

                <td
                  className={'pl-3 pr-4 text-sm text-right pb-3 ' + (index === 0 ? ' pt-3 ' : '  ')}
                >
                  {expense?.category?.title || '-'}
                </td>

                <td
                  className={
                    'pl-3 pr-4 text-sm text-right  pb-3 ' + (index === 0 ? ' pt-3 ' : '  ')
                  }
                >
                  {expense.totalCost?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) || '-'}
                </td>

                <td
                  className={
                    'pl-3 pr-4 text-sm text-right text-gray-500  pb-3 ' +
                    (index === 0 ? ' pt-3 ' : '  ')
                  }
                >
                  <input
                    type="checkbox"
                    defaultChecked={expense?.waive === '1'}
                    onClick={(event: unknown) =>
                      handleWaiveChange(expense, event as React.ChangeEvent<HTMLInputElement>)
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              className="pt-4 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 md:pl-0 whitespace-nowrap"
            >
              Total Case Expenses
            </th>
            <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 sm:pr-6 md:pr-0"></td>
            <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 ">
              {props?.expenseTotal?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) || '$0.00'}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default CloseExpenseForm;
