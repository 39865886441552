import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ExpenseCategoryType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<ExpenseCategoryType>[] = [
  {
    accessorFn: (row: ExpenseCategoryType) => row.title,
    id: 'Title',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: ExpenseCategoryType) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
