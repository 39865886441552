import { displayContactName } from '@colosseum/data';
import { renderCell, renderCellWithChips, renderHeader } from '@colosseum/shared-ui';
import { CaseContactConnectionRoleType, ContactType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { uniq } from 'lodash';

export const partyColumns: ColumnDef<
  (ContactType & { roles: CaseContactConnectionRoleType[] }) | undefined
>[] = [
  {
    accessorFn: (row) => {
      return displayContactName(row);
    },
    id: 'Name',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      //return roles in a comma separated string
      if (!row?.roles) return '';

      const rolesArr = row?.roles?.map((role) => role.roleOnCase ?? '');

      const roles = uniq(rolesArr)?.join(', ');

      return roles;
    },
    id: 'Roles',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCellWithChips({ props });
    },
  },
];
