import {
  DefaultV3Response,
  DiscoveryType,
  LitigationPlanAction,
  LitigationPlanActionNotification,
  LitigationPlanType,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseTestudoRoute = `testudo/${API_STAGE}`;

// #region ----------- LITIGATION PLANS -------------------------
// POST apply_litigation_plan_to_case_v3
export const applyLitigationPlanToCaseV3 = ({
  litigationPlanId,
  caseId,
}: {
  litigationPlanId: string;
  caseId: string;
}) =>
  axiosInstance
    .post<DefaultV3Response<LitigationPlanType>>(
      `${baseTestudoRoute}/v3/litigation-plans/${litigationPlanId}/cases/${caseId}`,
    )
    .then((res) => res.data);
// POST create_litigation_plan_v3
export const createLitigationPlanV3 = (data: Partial<LitigationPlanType>) =>
  axiosInstance
    .post<DefaultV3Response<LitigationPlanType>>(`${baseTestudoRoute}/v3/litigation-plans`, data)
    .then((res) => res.data);

// GET list_litigation_plans_v3
export const listLitigationPlansV3 = () =>
  axiosInstance
    .get<DefaultV3Response<LitigationPlanType[]>>(`${baseTestudoRoute}/v3/litigation-plans`)
    .then((res) => res.data);

// DEL delete_litigation_plan_v3
export const deleteLitigationPlanV3 = (litigationPlanId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<LitigationPlanType>>(
      `${baseTestudoRoute}/v3/litigation-plans/${litigationPlanId}`,
    )
    .then((res) => res.data);

// PATCH update_litigation_plan_v3
export const updateLitigationPlanV3 = (
  data: Partial<LitigationPlanType> & { litigationPlanId: string },
) => {
  const { litigationPlanId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LitigationPlanType>>(
      `${baseTestudoRoute}/v3/litigation-plans/${litigationPlanId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ----------- LITIGATION PLAN ACTION NOTIFICATIONS -----
// POST create_litigation_plan_action_notification_v3
export const createLitigationPlanActionNotificationV3 = (litigationPlanActionId?: string) =>
  axiosInstance
    .post<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/${litigationPlanActionId}/notifications`,
    )
    .then((res) => res.data);

// GET list_litigation_plan_action_notifications_v3
export const listLitigationPlanActionNotificationsV3 = (litigationPlanActionId?: string) =>
  axiosInstance
    .get<DefaultV3Response<LitigationPlanActionNotification[]>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/${litigationPlanActionId}/notifications`,
    )
    .then((res) => res.data);

// DEL delete_litigation_plan_action_notification_v3
export const deleteLitigationPlanActionNotificationV3 = (
  litigationPlanActionNotificationId?: string,
) =>
  axiosInstance
    .delete<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/notifications/${litigationPlanActionNotificationId}`,
    )
    .then((res) => res.data);

// PATCH update_litigation_plan_action_notification_v3
export const updateLitigationPlanActionNotificationV3 = (
  data: Partial<LitigationPlanActionNotification> & {
    litigationPlanActionNotificationId?: string;
  },
) => {
  const { litigationPlanActionNotificationId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/notifications/${litigationPlanActionNotificationId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ----------- LITIGATION PLAN ACTIONS ------------------

// POST create_litigation_plan_action_v3
export const createLitigationPlanActionV3 = (
  data: Partial<LitigationPlanAction> & { litigationPlanId: string },
) => {
  const { litigationPlanId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/litigation-plans/${litigationPlanId}/actions`,
      rest,
    )
    .then((res) => res.data);
};

// DELETE delete_litigation_plan_action_v3
export const deleteLitigationPlanActionV3 = (litigationPlanActionId: string) =>
  axiosInstance
    .delete<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/${litigationPlanActionId}`,
    )
    .then((res) => res.data);

// PATCH update_litigation_plan_action_v3
export const updateLitigationPlanActionV3 = (
  data: Partial<LitigationPlanAction> & { litigationPlanActionId?: string },
) => {
  const { litigationPlanActionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/litigation-plans/actions/${litigationPlanActionId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ----------- CASE LITIGATION ACTION NOTIFICATIONS -----
// POST create_case_litigation_action_notification_v3
export const createCaseLitigationActionNotificationV3 = (caseLitigationActionId?: string) =>
  axiosInstance
    .post<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/${caseLitigationActionId}/notifications`,
    )
    .then((res) => res.data);

// GET list_case_litigation_action_notifications_v3
export const listCaseLitigationActionNotificationsV3 = (caseLitigationActionId?: string) =>
  axiosInstance
    .get<DefaultV3Response<LitigationPlanActionNotification[]>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/${caseLitigationActionId}/notifications`,
    )
    .then((res) => res.data);

// DEL delete_case_litigation_action_notification_v3
export const deleteCaseLitigationActionNotificationV3 = (
  caseLitigationActionNotificationId?: string,
) =>
  axiosInstance
    .delete<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/notifications/${caseLitigationActionNotificationId}`,
    )
    .then((res) => res.data);

// PATCH update_case_litigation_action_notification_v3
export const updateCaseLitigationActionNotificationV3 = (
  data: Partial<LitigationPlanActionNotification> & {
    caseLitigationActionNotificationId?: string;
  },
) => {
  const { caseLitigationActionNotificationId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LitigationPlanActionNotification>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/notifications/${caseLitigationActionNotificationId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ----------- CASE LITIGATION ACTIONS ------------------
// POST create_case_litigation_action_v3
export const createCaseLitigationActionV3 = (caseId: string) =>
  axiosInstance
    .post<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/cases/${caseId}/litigation-actions`,
    )
    .then((res) => res.data);

// GET list_case_litigation_actions_v3
export const getCaseLitigationActionsV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<LitigationPlanAction[]>>(
      `${baseTestudoRoute}/v3/cases/${caseId}/litigation-actions`,
    )
    .then((res) => res.data);

// DEL delete_case_litigation_action_v3
export const deleteCaseLitigationActionV3 = (caseLitigationActionId: string) =>
  axiosInstance
    .delete<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/${caseLitigationActionId}`,
    )
    .then((res) => res.data);

// PATCH update_case_litigation_action_v3
export const updateCaseLitigationActionV3 = (
  data: Partial<LitigationPlanAction> & { caseLitigationActionId?: string },
) => {
  const { caseLitigationActionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LitigationPlanAction>>(
      `${baseTestudoRoute}/v3/cases/litigation-actions/${caseLitigationActionId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ----------- Discoveries ------------------------------
// POST create_discovery_v3
export const createDiscoveryV3 = (caseId: string) =>
  axiosInstance
    .post<DefaultV3Response<DiscoveryType>>(`${baseTestudoRoute}/v3/cases/${caseId}/discoveries`)
    .then((res) => res.data);

// GET list_discoveries_for_case_v3
export const getDiscoveriesForCaseV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<DiscoveryType[]>>(`${baseTestudoRoute}/v3/cases/${caseId}/discoveries`)
    .then((res) => res.data);

// GET list_discoveries_v3
export const getDiscoveriesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<DiscoveryType[]>>(`${baseTestudoRoute}/v3/cases/discoveries`)
    .then((res) => res.data);

// DEL delete_discovery_v3
export const deleteDiscoveryV3 = (discoveryId: string) =>
  axiosInstance
    .delete<DefaultV3Response<DiscoveryType>>(
      `${baseTestudoRoute}/v3/cases/discoveries/${discoveryId}`,
    )
    .then((res) => res.data);

// PATCH update_discovery_v3
export const updateDiscoveryV3 = (data: Partial<DiscoveryType> & { discoveryId?: string }) => {
  const { discoveryId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<DiscoveryType>>(
      `${baseTestudoRoute}/v3/cases/discoveries/${discoveryId}`,
      rest,
    )
    .then((res) => res.data);
};

// #endregion
