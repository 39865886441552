import { ColumnDef } from '@tanstack/react-table';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

export interface InsightsImpactDataProps {
  teamMember: string;
  role: string;
  casesClosed: number;
  closedWithFee: string;
  avgSettlement: string;
  avgFee: string;
  clientNPS: number;
}
export const columns: ColumnDef<InsightsImpactDataProps>[] = [
  {
    accessorFn: (row) => row.teamMember,
    id: 'Parameter',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.role,
    id: 'Role',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.casesClosed,
    id: 'Cases Closed',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.closedWithFee,
    id: 'Closed with Fee',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.avgSettlement,
    id: 'Avg. Settlement',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.avgFee,
    id: 'Avg. Fee',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.clientNPS,
    id: 'Client NPS',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
