import React, { useState } from 'react';
import {
  useCreateMessagingSnippet,
  useDeleteMessagingSnippet,
  useGetMessagingSnippets,
  useUpdateMessagingSnippet,
} from '@colosseum/data';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { ScissorsIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export interface SnippetToolProps {
  onSnippetSelect: (message: string) => void;
}

export function SnippetTool({ onSnippetSelect }: SnippetToolProps) {
  const [newSnippet, setNewSnippet] = useState('');
  const [editSnippetText, setEditSnippetText] = useState('');
  const [showNewSnippet, setShowNewSnippet] = useState(false);
  const [editingExistingSnippet, setEditingExistingSnippet] = useState(-1);
  const [newMessageBody, setNewMessageBody] = useState('');

  const { data: snippetsData } = useGetMessagingSnippets();
  const snippets = snippetsData?.data;

  const createSnippet = useCreateMessagingSnippet();
  const deleteSnippet = useDeleteMessagingSnippet();
  const updateSnippet = useUpdateMessagingSnippet();

  return (
    <div className="flex items-start">
      <Popover
        modal
        onOpenChange={(open) => {
          if (!open) {
            setEditingExistingSnippet(-1);
            setEditSnippetText('');
            setNewSnippet('');
            setShowNewSnippet(false);
            setNewMessageBody('');
          }
        }}
      >
        <PopoverTrigger>
          <div
            onClick={() => {
              setEditingExistingSnippet(-1);
              if (newMessageBody && newMessageBody !== '') {
                setNewSnippet(newMessageBody);
                setShowNewSnippet(true);
              } else {
                setShowNewSnippet(false);
                setNewSnippet('');
                setEditSnippetText('');
              }
            }}
            className="p-2 rounded-md hover:text-light-blue hover:bg-atlantic-blue bg-light-blue text-atlantic-blue"
          >
            <ScissorsIcon className="w-5 h-5" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="z-50 overflow-y-hidden bg-white h-full w-80">
          <div className="flex flex-col gap-2 bg-blue-50 p-2 rounded-md">
            <div className="flex gap-2 flex-shrink-0  items-center  ">
              <InformationCircleIcon className="w-8 h-8 text-blue-400" aria-hidden="true" />
              <div className="flex flex-col gap-1">
                <Typography variant="grayUpper">Snippet Tool</Typography>
                <Typography className="text-sm text-gray-500 ">
                  Use this tool to save and manage snippets of text that you can use in your
                  messages.
                </Typography>
              </div>
            </div>
          </div>

          <div className="overflow-y-scroll h-[300px]">
            {snippets &&
              snippets.map((snippet, index) => {
                return (
                  <div
                    key={snippet.snippetId}
                    className="flex items-center justify-between break-all "
                  >
                    {editingExistingSnippet === index ? (
                      <div className="flex flex-col items-center w-full bg-white border-b border-t border-gray-300 shadow-sm p-4 gap-4">
                        <textarea
                          value={editSnippetText}
                          onChange={(e) => {
                            setEditSnippetText(e.target.value);
                            const target = e.target as HTMLTextAreaElement;
                            target.style.height = 'inherit';
                            target.style.height = `${target.scrollHeight + 20}px`;
                            e.target = target;
                          }}
                          onSelect={(e) => {
                            const target = e.target as HTMLTextAreaElement;
                            target.style.height = 'inherit';
                            target.style.height = `${target.scrollHeight + 20}px`;
                            e.target = target;
                          }}
                          maxLength={1599}
                          className="w-full text-sm bg-white border border-gray-300 rounded-lg form-textarea focus:outline-none focus:border-atlantic-blue"
                          placeholder="Type a message to save as a snippet..."
                        />
                        <div className="flex flex-row justify-between w-full">
                          <Button
                            variant="secondary"
                            size={'xs'}
                            onClick={() => {
                              setEditingExistingSnippet(-1);
                              setEditSnippetText('');
                              setNewSnippet('');
                              setShowNewSnippet(false);
                            }}
                          >
                            <Typography className="flex w-full cursor-pointer  ">Back</Typography>
                          </Button>
                          <Button
                            onClick={() => {
                              if (editSnippetText === '') {
                                return;
                              }
                              updateSnippet.mutate({
                                snippetId: snippet.snippetId,
                                message: editSnippetText,
                              });
                              setEditingExistingSnippet(-1);
                              setEditSnippetText('');
                              setNewSnippet('');
                              setShowNewSnippet(false);
                            }}
                            variant="primary"
                            size={'xs'}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setShowNewSnippet(false);
                          onSnippetSelect?.(snippet.message);
                        }}
                        variant="ghost"
                        className="w-full flex h-fit pr-2"
                      >
                        <div className="flex flex-row items-center cursor-pointer justify-between w-full">
                          <Typography className="flex self-center text-left">
                            {snippet.message}
                          </Typography>
                          <div className="flex flex-row gap-2">
                            <Button
                              className="text-gray-400 hover:text-light-blue p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingExistingSnippet(index);
                                setEditSnippetText(snippet.message);
                                setShowNewSnippet(false);
                              }}
                            >
                              <PencilSquareIcon className="w-5 h-5" />
                            </Button>
                            <Button
                              className="text-gray-400 hover:text-red-500 p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteSnippet.mutate({
                                  snippetId: snippet.snippetId ?? '',
                                });
                              }}
                            >
                              <TrashIcon className="w-5 h-5" />
                            </Button>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>
          {!showNewSnippet ? (
            <div className="flex p-4 justify-center">
              <Button
                onClick={() => {
                  setShowNewSnippet(true);
                  setEditingExistingSnippet(-1);
                }}
                variant="primary"
                className="w-full"
              >
                <Typography className="flex flex-col items-center cursor-pointer">
                  Create New Snippet
                </Typography>
              </Button>
            </div>
          ) : (
            <div className="flex flex-col gap-4 items-center p-4 bg-white border-b border-gray-300 shadow-sm ">
              <textarea
                value={newSnippet}
                onChange={(e) => {
                  setNewSnippet(e.target.value);
                  const target = e.target as HTMLTextAreaElement;
                  target.style.height = 'inherit';
                  target.style.height = `${target.scrollHeight + 20}px`;
                  e.target = target;
                }}
                onSelect={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  target.style.height = 'inherit';
                  target.style.height = `${target.scrollHeight + 20}px`;
                  e.target = target;
                }}
                maxLength={1599}
                className="w-full px-4 py-2 mt-2 text-sm bg-white border border-gray-300 rounded-lg form-textarea focus:outline-none focus:border-atlantic-blue"
                placeholder="Type a message to save as a snippet..."
              />

              <div className="flex flex-row justify-between w-full ">
                <Button
                  variant="secondary"
                  size={'xs'}
                  onClick={() => {
                    setNewSnippet('');
                    setShowNewSnippet(false);
                  }}
                >
                  <Typography className="flex flex-col items-center w-full  cursor-pointer">
                    Back
                  </Typography>
                </Button>
                <Button
                  variant={'primary'}
                  size={'xs'}
                  onClick={() => {
                    if (newSnippet === '') {
                      return;
                    }

                    createSnippet.mutate({
                      message: newSnippet,
                    });

                    setNewSnippet('');
                    setShowNewSnippet(false);
                  }}
                  disabled={!newSnippet}
                >
                  <Typography className="flex flex-col items-center w-full  cursor-pointer  ">
                    Save
                  </Typography>
                </Button>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default SnippetTool;
