import {
  tanstackTableNames,
  useCreateCaseType,
  useDeleteCaseType,
  useGetCaseTypes,
  useUpdateCaseType,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { CaseTypeType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './CaseTypesTableColumns';

/* eslint-disable-next-line */
export interface CaseTypesProps {}

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function CaseTypes(props: CaseTypesProps) {
  const {
    data: caseTypesData,
    isLoading: isCaseTypesLoading,
    isError: isCaseTypesError,
  } = useGetCaseTypes(true);
  const createCaseType = useCreateCaseType();
  const updateCaseType = useUpdateCaseType();
  const deleteCaseType = useDeleteCaseType();
  const caseTypes = caseTypesData?.data;
  const typing = createCaseType.isLoading || updateCaseType.isLoading;
  const [openCaseType, setOpenCaseType] = useState<CaseTypeType | undefined>();
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openCaseType?.title || '',
      description: openCaseType?.description || '',
    },
  });

  const handleCreate = () => {
    createCaseType.mutateAsync({}).then((res) => {
      setOpenCaseType(res.data);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string) => {
    updateCaseType.mutate({
      caseTypeId: openCaseType?.caseTypeId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenCaseType(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openCaseType?.caseTypeId)
            deleteCaseType.mutateAsync(openCaseType?.caseTypeId).then(() => {
              setOpenCaseType(undefined);
              setOpen(false);
            });
        }}
        title="Case Type"
        description="Update your case types' information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
                maxLength={120}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="case-types" className="text-xl font-semibold text-gray-900">
              Case Types
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's case types.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create New Type'}
              loading={createCaseType.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={caseTypes || []}
          initialSort={{
            id: 'Title',
            desc: false,
          }}
          columns={columns}
          isLoading={isCaseTypesLoading}
          isError={isCaseTypesError}
          tableName={tanstackTableNames.caseTypes}
          handleRowClick={(item: Row<CaseTypeType>) => {
            setOpenCaseType(item.original);
            setOpen(true);
          }}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.caseTypes, {})}
          hideViewButton
        />
      </div>
    </>
  );
}

export default CaseTypes;
