import {
  displayContactName,
  findRoleSpecificCaseContactConnections,
  formatPhoneNumber,
  useGenerateDocumentData,
  useGenerateDocumentWithData,
  useGetAdobeSignerListV3,
  useGetCaseContactConnectionsViewModel,
  useGetContact,
  useGetParallelS3FileDownloadLinks,
  useGetProvisionedPhoneNumbers,
  useSendDocumentForSigning,
} from '@colosseum/data';
import {
  AdobeSignerType,
  CaseContactConnectionViewModelType,
  FileResourceType,
  MemberInfo,
  ParticipantSetsInfo,
  SigningEvent,
  TemplateType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { PaperAirplaneIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import AttachedFileChip from '../AttachedFileChip/AttachedFileChip';
import FilePreview from '../FilePreview/FilePreview';
import { GladiateLoader } from '../GladiateLoader/GladiateLoader';
import CheckboxFormInput from '../forms/CheckboxFormInput/CheckboxFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
import adobeBottom from './adobe_bottom.jpeg';
import adobeTop from './adobe_top.jpeg';

export interface SendDocFormData {
  body: string;
  sendersEmail: string;
  sendersUsername: string;
  firmPhoneNumber: string;
  subject: string;
  recipients: MemberInfo[];
  attorneySign: boolean;
}

export interface SendDocFormProps {
  s3File: FileResourceType | undefined;
  selectedTemplate: TemplateType;
  setSelectedTemplate: any;
  setSignDocFormSlideover: any;
  formRef: any;
  selectedInsurancePolicy: string;
  caseId: string;
  setLoading: any;
  loading: boolean;
  setError: any;
}

const formSchema = z.object({
  attorneySign: z.boolean(),
  body: z.string(),
  recipients: z
    .array(
      z.object({
        email: z.string().nonempty({ message: 'Email is required for each recipient' }),
        name: z.string(),
      }),
    )
    .nonempty({ message: 'At least one recipient is required' }),
  sendersEmail: z.string().nonempty({ message: "Sender's email cannot be empty" }),
  sendersUsername: z.string(),
  firmPhoneNumber: z.string().nonempty({ message: 'Phone number required' }),
  subject: z.string().nonempty({ message: 'Subject cannot be empty' }),
});

const getSignerOptions = (signers: AdobeSignerType[]) => {
  return signers.reduce((returnArr, signer) => {
    return {
      ...returnArr,
      [`${signer.firstName} ${signer.lastName} - ${signer.adobeEmail}`]: signer.adobeEmail,
    };
  }, {});
};

export function SendDocForm(props: SendDocFormProps) {
  const { caseId, selectedInsurancePolicy, s3File, selectedTemplate, loading, setLoading } = props;

  const { generateDocumentData } = useGenerateDocumentData(caseId, selectedInsurancePolicy, 'pdf');
  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  const phoneNumberOptions: { [key: string]: string } =
    (provisionedPhoneNumberData?.data &&
      provisionedPhoneNumberData?.data
        ?.filter((phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication')
        .reduce((acc, curr) => {
          return {
            ...acc,
            [formatPhoneNumber(curr.phoneNumber)]: curr.phoneNumber,
          };
        }, {})) ||
    {};

  const downloadLinkData = useGetParallelS3FileDownloadLinks([s3File?.s3ObjKey || '']);

  const generateDocument = useGenerateDocumentWithData();

  const sendDocumentForSigning = useSendDocumentForSigning();

  const [sentMessage, setSentMessage] = useState(false);
  const [messageSentSuccess, setMessageSentSuccess] = useState(false);
  const [attorneyShouldSign, setAttorneyShouldSign] = useState(
    selectedTemplate?.senderSignatureRequired ?? false,
  );
  const [openFilePreview, setOpenFilePreview] = useState(false);
  const [filePreviewItem, setFilePreviewItem] = useState<FileResourceType>();

  const { data: signersData } = useGetAdobeSignerListV3();

  const signers = signersData?.data ?? [];

  const signerOptions = getSignerOptions(signers);

  const initialValues = {
    sendersEmail: signers[0]?.adobeEmail,
    recipients: [],
    subject: selectedTemplate?.defaultSubjectContinued
      ? selectedTemplate?.defaultSubjectContinued
      : selectedTemplate?.title
      ? selectedTemplate?.title
      : '',
    body: selectedTemplate?.defaultMessage ? selectedTemplate?.defaultMessage : '',
    attorneySign: attorneyShouldSign,
    sendersUsername: signers[0]?.username,
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const additionalSignersArray = useFieldArray({
    control: form.control,
    name: 'recipients', // unique name for your Field Array
  });

  const { data: caseContactConnectionsData } = useGetCaseContactConnectionsViewModel(caseId);
  const caseClientConnections =
    findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>({
      caseContactConnections: caseContactConnectionsData?.data,
      role: caseContactConnectionOptions.client,
    });

  const { data: clientContactData } = useGetContact(caseClientConnections[0]?.contact?.contactId);
  const clientContact = clientContactData?.data;

  useEffect(() => {
    setUpClientData();
  }, [clientContact]);

  function setUpClientData() {
    if (
      !initialValues.recipients.some(
        (recipient: { email: string; name: string }) =>
          recipient.email === clientContact?.contactEmails[0]?.emailAddress,
      ) &&
      form.getValues().recipients.length === 0
    ) {
      additionalSignersArray.append({
        email: clientContact?.contactEmails[0]?.emailAddress ?? '',
        name: displayContactName(clientContact) ?? '',
      });
    }
  }

  async function convertDocxToPdf(fileId: string) {
    const documentData = generateDocumentData();
    const result = await generateDocument.mutateAsync({
      fileId,
      ...documentData,
    });

    return result?.data?.genDocKey ?? '';
  }

  function getSendersName(email: string): string {
    const signer = signers.find((signer) => signer.adobeEmail === email);
    return signer ? `${signer.firstName} ${signer.lastName}` : '';
  }

  function reorganizeParticipantSets(
    participantSets: ParticipantSetsInfo[],
    clientEmail: string,
  ): ParticipantSetsInfo[] {
    const clientSet = participantSets.find((set) =>
      set.memberInfos.some((info) => info.email === clientEmail),
    );
    const otherSets = participantSets.filter(
      (set) => !set.memberInfos.some((info) => info.email === clientEmail),
    );

    if (clientSet) {
      clientSet.order = 1;
      otherSets.forEach((set, index) => {
        set.order = index + 2;
      });

      return [clientSet, ...otherSets];
    }

    return participantSets; // Return as is if no client set is found
  }

  async function getDocumentSigningData(values: SendDocFormData): Promise<SigningEvent> {
    let genDocKey = '';
    if (s3File?.s3ObjKey?.includes('docx') && props?.selectedTemplate?.documentId) {
      genDocKey = await convertDocxToPdf(selectedTemplate?.documentId ?? '');
    } else {
      genDocKey = s3File?.s3ObjKey ?? '';
    }

    const participantSets: ParticipantSetsInfo[] = values?.recipients?.map((recipient, index) => {
      if (recipient.email === clientContact?.contactEmails[0]?.emailAddress) {
        return {
          order: index + 1,
          role: 'SIGNER',
          memberInfos: [
            {
              email: recipient.email,
              name: displayContactName(clientContact) ?? '',
              phoneNumber: clientContact?.contactNumbers[0]?.number ?? '',
              contactId: clientContact?.contactId ?? '',
            },
          ],
        };
      }

      return {
        order: index + 1,
        role: 'SIGNER',
        memberInfos: [recipient],
      };
    });

    const signingEvent: SigningEvent = {
      username: values?.sendersUsername ?? '',
      userEmail: values?.sendersEmail ?? '',
      objKey: genDocKey,
      caseId: props.caseId,
      firmPhoneNumber: values.firmPhoneNumber || '',
      signaturePayload: {
        message: values?.body ?? '',
        documentName: values?.subject ? values?.subject || '' : selectedTemplate?.title || '',
        participantSetsInfo: reorganizeParticipantSets(
          participantSets,
          clientContact?.contactEmails[0]?.emailAddress ?? '',
        ),
      },
    };

    if (attorneyShouldSign) {
      signingEvent.signaturePayload.participantSetsInfo.push({
        order: participantSets.length + 1,
        role: 'SIGNER',
        memberInfos: [
          {
            email: values?.sendersEmail ?? '',
            name: getSendersName(values?.sendersEmail ?? ''),
          },
        ],
      });
    }
    return signingEvent;
  }

  async function onSubmit(values: SendDocFormData) {
    try {
      setLoading(true);

      values.sendersEmail = values.sendersEmail.trim();

      // Trim whitespace from recipient emails
      values.recipients = values.recipients.map((recipient) => ({
        ...recipient,
        email: recipient.email.trim(),
      }));

      const documentSigningData = await getDocumentSigningData(values);

      const result = await sendDocumentForSigning.mutateAsync(documentSigningData);

      enqueueSnackbar('Document sent for signing', { variant: 'success' });

      setLoading(false);
      setSentMessage(true);

      if (result) {
        setMessageSentSuccess(true);
      }

      props.setSignDocFormSlideover(false);
      if (typeof props?.selectedTemplate?.senderSignatureRequired === 'boolean') {
        setAttorneyShouldSign(selectedTemplate?.senderSignatureRequired ?? false);
      }

      props.setSelectedTemplate(null);
    } catch (error) {
      enqueueSnackbar('Error sending document for signing', {
        variant: 'error',
      });
      setLoading(false);
      setSentMessage(true);
      setMessageSentSuccess(false);
    }
  }

  const FormElements = (
    <div>
      <div>
        <div className="w-full mb-3">
          {s3File && (
            <AttachedFileChip
              file={s3File}
              downloadLink={downloadLinkData[0].data?.data.previewUrl || ''}
              onPreview={() => {
                setOpenFilePreview(true);
                setFilePreviewItem(s3File);
              }}
              onDelete={() => {}}
              deletable={false}
            />
          )}
        </div>
      </div>
      <Form {...form}>
        <form ref={props.formRef} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col pt-2 pb-2 gap-y-5 gap-x-3">
            <SelectFormInput
              {...form.register(`sendersEmail`)}
              title="Sender"
              listItems={signerOptions}
              listItemsIsObject
              defaultValue={initialValues.sendersEmail}
              placeholderText="Select Sender"
              handleOnChange={(e) => {
                const target = e.target as HTMLInputElement;
                form.setValue('sendersEmail', target.value);
                const sender = signers?.find((item) => item.adobeEmail === target.value);
                if (sender) {
                  form.setValue('sendersUsername', sender.username);
                }
              }}
            />
            <div className="col-span-2">
              <SelectFormInput
                title="Firm Phone Number"
                name="firmPhoneNumber"
                listItemsIsObject
                listItems={phoneNumberOptions}
                handleOnChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  form.setValue('firmPhoneNumber', target.value);
                }}
              />
            </div>
            <CheckboxFormInput
              {...form.register('attorneySign')}
              handleOnChange={() => {
                setAttorneyShouldSign(!attorneyShouldSign);
              }}
              title="Sender Signature Required"
            />
            {additionalSignersArray.fields.map((field, i) => (
              <div className="grid pb-2 gap-y-5 gap-x-3" key={field.id}>
                <TextFormInput {...form.register(`recipients.${i}.email`)} title="Email" />
                <TextFormInput {...form.register(`recipients.${i}.name`)} title="Name" />
              </div>
            ))}
            <div className="group w-fit">
              <button
                type="button"
                onClick={() =>
                  additionalSignersArray.append({
                    email: '',
                    name: '',
                  })
                }
                className="flex items-center pl-1 mt-2 group-hover:text-atlantic-blue"
              >
                <PlusCircleIcon className="w-5 h-5 mr-1 text-gray-800 group-hover:text-atlantic-blue " />
                Add Signer
              </button>
            </div>
            <TextFormInput
              {...form.register('subject')}
              title="Subject Continued..."
              type="textarea"
            />
            <TextFormInput {...form.register('body')} title="Message" type="textarea" />
          </div>
        </form>
      </Form>

      <div className="relative p-4 my-4 mb-10 bg-gray-200 rounded-md">
        <div className="relative max-w-md px-2 text-gray-800 whitespace-normal bg-white rounded-md ">
          <div className="flex p-2 mb-2 font-semibold break-all">
            Signature requested on {form.getValues().subject}
          </div>
          <div className="relative flex flex-col max-w-md pt-5 border-t border-t-gray">
            <img className="w-full" alt="adobe" src={adobeTop} />
            <div className="w-full p-5 mt-3 break-all border-y border-y-gray-200">
              {form.getValues().body}
            </div>
          </div>
          <div className="w-full pb-10 mt-3 text-gray-400 ">
            <img className="w-full" alt="adobe" src={adobeBottom} />{' '}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FilePreview
        caseId={caseId}
        isOpen={openFilePreview}
        setOpen={setOpenFilePreview}
        filePreviewItem={filePreviewItem}
      />
      <div>
        {loading ? (
          <div className="flex items-center w-full h-32 align-middle">
            <div className="m-auto">
              <div className="flex items-center w-full">
                <div className="mx-auto">
                  <GladiateLoader height={100} />
                </div>
              </div>
              <p className="mt-4 text-lg font-semibold">Sending file...</p>
            </div>
          </div>
        ) : (
          <div>
            {sentMessage ? (
              <div>
                {messageSentSuccess ? (
                  <div>
                    <div className="flex items-center justify-center text-gray-700">
                      <p className="mt-4 font-semibold w-36">Document Sent</p>
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="w-12 h-12 text-gray-700">
                        <PaperAirplaneIcon />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="p-4 mb-5 rounded-md bg-red-50">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="w-5 h-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            An error has occurred while sending your document. Please try again.
                          </h3>
                        </div>
                      </div>
                    </div>
                    {FormElements}
                  </>
                )}
              </div>
            ) : (
              FormElements
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default SendDocForm;
