import { cn } from '@colosseum/data';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface DataTabsProps<TFilters> {
  filters: { title: string; count: number; value: TFilters }[];
  activeFilter: TFilters;
  setActiveFilter: (filter: TFilters) => void;
  className?: string;
}

export function DataTabs<TFilters>(props: DataTabsProps<TFilters>) {
  const { filters, activeFilter, setActiveFilter, className } = props;

  return (
    <div>
      {/* DESKTOP CASE TABS */}
      <div
        className={cn('justify-between hidden w-full gap-6 pl-1 pr-3 ml-1 md:flex mt-7', className)}
      >
        <div className="flex items-center justify-between w-full h-10 border-b-2 border-gray-400 ">
          <div className="flex">
            {filters.map((filter, index) => {
              return (
                <Button
                  variant="ghost"
                  size="unset"
                  key={`${filter.value as string}-${index}-filter`}
                  onClick={() => setActiveFilter(filter.value)}
                  className={cn(
                    'py-2 px-2 min-w-28 text-md font-normal border-b-2 rounded-b-none border-gray-400',
                    filter.value === activeFilter &&
                      'text-atlantic-blue border-b-2  border-atlantic-blue font-semibold hover:bg-white hover:text-unset',
                  )}
                >
                  <div className="flex items-center justify-center text-center align-middle">
                    <p className={filter.value === activeFilter ? '' : 'text-gray-300'}>
                      {filter.title}
                    </p>
                    <div
                      className={
                        filter.value === activeFilter
                          ? 'px-2 py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold'
                          : 'px-2 py-0.5 ml-2 text-xs text-gray-600 bg-gray-300 rounded-full text-bold'
                      }
                    >
                      {filter.count}
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      {/* MOBILE CASE TABS */}
      <div className="pr-2 md:hidden">
        <div className="grid-cols-2 ml-1 space-x-2 space-y-5">
          {filters.map((filter, index) => {
            return (
              <button
                key={`${filter.value as string}-${index}-filter`}
                onClick={() => setActiveFilter(filter.value)}
                className={
                  filter.value === activeFilter
                    ? ' text-atlantic-blue border border-atlantic-blue rounded-lg py-2 font-semibold w-28 '
                    : '  rounded-lg font-semibold w-28 first:ml-2'
                }
              >
                <div
                  className={
                    'flex items-center text-left align-middle' +
                    (filter.value === activeFilter ? ' justify-center ' : ' justify-start')
                  }
                >
                  <p className={filter.value === activeFilter ? '' : 'text-gray-300'}>
                    {filter.title}
                  </p>
                  <div
                    className={
                      filter.value === activeFilter
                        ? 'px-2 py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold'
                        : 'px-2 py-0.5 ml-2 text-xs text-gray-600 bg-gray-300 rounded-full text-bold'
                    }
                  >
                    {filter.count}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DataTabs;
