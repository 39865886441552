import {
  CalendarPicker,
  Form,
  FormField,
  FormItem,
  FormLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SectionCollapsible,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { caseContactConnectionOptions, TemplateDictionaryItemType } from '@gladiate/types';
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

/* eslint-disable-next-line */
import {
  copyExpensesTable,
  copyLiensTable,
  copyTreatmentTable,
  getFormatOptions,
} from '@colosseum/data';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { startCase, uniqBy } from 'lodash';
import { CalendarIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  templateInstance: z.number().optional(),
  templateFormat: z.string().optional(),
  description: z.string().optional(),
  value: z.string().optional(),
  roleOnCase: z.string().optional(),
});

export interface TemplateDictionaryItemProps {
  templateDictionaryItem: TemplateDictionaryItemType;
}

const caseContactConnectionRolesOptions = (
  Object.keys(caseContactConnectionOptions) as Array<keyof typeof caseContactConnectionOptions>
).map((role) => ({
  title: startCase(caseContactConnectionOptions[role]),
  value: caseContactConnectionOptions[role],
}));

const contactRoles = uniqBy(
  [
    {
      title: 'Attorney Referring Case',
      value: 'referralFromAttorney',
    },
    { title: 'Firm Referring Case', value: 'referralFromFirm' },
    {
      title: 'Health Insurer',
      value: 'healthInsurance',
    },
    { title: 'PIP Adjuster', value: 'pipAdjuster' },
    {
      title: "Client's Spouse",
      value: 'client_connection_spouse',
    },
    {
      title: "Client's Employer",
      value: 'client_connection_employee',
    },
    {
      title: 'UM/UIM Adjuster',
      value: 'umUimAdjuster',
    },
    ...caseContactConnectionRolesOptions,
  ],
  'value',
).sort((a, b) => a.title.localeCompare(b.title));

export function TemplateDictionaryItem(props: TemplateDictionaryItemProps) {
  const { templateDictionaryItem } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState<number | undefined>();
  const [selectedFormat, setSelectedFormat] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [maxInstance, setMaxInstance] = useState(1);
  const [exampleDate, setExampleDate] = useState('1970-01-01');
  const [exampleDateModalOpen, setExampleDateModalOpen] = useState(false);

  const formatCopyValue = () => {
    let copyValue = `{{${templateDictionaryItem.value}`;
    if (selectedRole) {
      copyValue = copyValue.replace('contact', `contact_${selectedRole}`);
    }
    if (selectedInstance && selectedInstance > 1) {
      copyValue = copyValue + `_${selectedInstance}`;
    }
    if (selectedFormat && selectedFormat !== 'default') {
      copyValue = copyValue + `_${selectedFormat}`;
    }
    return copyValue + '}}';
  };

  const formatOptions = getFormatOptions(templateDictionaryItem.type);
  const formatExample = () => {
    switch (templateDictionaryItem.type) {
      case 'text':
        return 'Example Text';
      case 'date':
        return exampleDate;
      default:
        return '';
    }
  };
  const copyValue = formatCopyValue();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      templateInstance: selectedInstance,
      description: templateDictionaryItem.description,
      value: copyValue,
    },
  });

  const handleInstanceChange = (newInstance: string) => {
    setSelectedInstance(Number(newInstance));
    if (Number(newInstance) > maxInstance) {
      setMaxInstance(Number(newInstance));
    }
  };

  const copyTemplateDictionaryItem = () => {
    if (templateDictionaryItem.name === 'Medical Treatment Table') {
      copyTreatmentTable();
    } else if (templateDictionaryItem.name === 'Expense Table') {
      copyExpensesTable();
    } else if (templateDictionaryItem.name === 'Lien Table') {
      copyLiensTable();
    } else {
      navigator.clipboard.writeText(copyValue);
    }
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
    });

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="relative flex px-6 py-5 overflow-hidden bg-white border border-gray-300 rounded-lg shadow-lg">
      <div className="flex-1 min-w-0">
        <div className="mb-4">
          <Typography variant="heading" size="lg">
            {templateDictionaryItem.name}
          </Typography>
          <Typography variant="subtext">{templateDictionaryItem.description}</Typography>
        </div>
        <Form {...form}>
          <form className="grid grid-cols-1 mb-2 gap-x-3 gap-y-5">
            <div className="flex gap-x-2">
              <div className="grow">
                <TextFormInput
                  disabled={true}
                  {...form.register(`value`)}
                  title="Template Variable"
                />
              </div>
              <div
                className="content-center cursor-pointer"
                onClick={() => copyTemplateDictionaryItem()}
              >
                {isCopied ? (
                  <ClipboardDocumentCheckIcon className="float-right w-5 h-5 text-green-500 animate__animated animate__rubberBand" />
                ) : (
                  <ClipboardDocumentIcon className="float-right w-5 h-5 text-gray-400" />
                )}
              </div>
            </div>
            <SectionCollapsible className="text-gray-500" title="More options">
              <FormField
                control={form.control}
                name="templateInstance"
                render={() => (
                  <FormItem>
                    <FormLabel>Instance</FormLabel>
                    <Select
                      value={selectedInstance?.toString()}
                      onValueChange={handleInstanceChange}
                      disabled={templateDictionaryItem.type === 'list'}
                    >
                      <SelectTrigger className="h-8" data-cy="select-trigger-case-type">
                        <SelectValue placeholder="Select an instance" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[...Array(maxInstance + 1).keys()]
                            .map((i) => i + 1)
                            .map((number, index) => (
                              <SelectItem
                                key={number}
                                className={index === maxInstance ? 'border-t' : ''}
                                value={number.toString()}
                              >
                                {index === maxInstance ? 'Add new Instance' : number}
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              ></FormField>
              <div className="pt-4"></div>
              <FormField
                control={form.control}
                name="templateFormat"
                render={() => (
                  <FormItem>
                    <FormLabel>Format</FormLabel>
                    <Select
                      value={selectedFormat}
                      onValueChange={(item) => {
                        setSelectedFormat(item);
                      }}
                    >
                      <SelectTrigger className="h-8" data-cy="select-trigger-template-format">
                        <SelectValue placeholder="Select a format" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {Object.keys(formatOptions).length > 0 ? (
                            Object.keys(formatOptions).map((optionKey) => (
                              <SelectItem key={optionKey} value={optionKey}>
                                {templateDictionaryItem.type === 'date'
                                  ? formatOptions[optionKey].modifierFunc(exampleDate)
                                  : formatOptions[optionKey].label}
                              </SelectItem>
                            ))
                          ) : (
                            <SelectItem value="no-options" disabled>
                              No options available.
                            </SelectItem>
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              ></FormField>
              <div className="pt-4"></div>
              {templateDictionaryItem.parentResource === 'contact' && (
                <FormField
                  control={form.control}
                  name="roleOnCase"
                  render={() => (
                    <FormItem>
                      <FormLabel>Role on Case</FormLabel>
                      <Select
                        value={selectedRole}
                        onValueChange={(item) => {
                          setSelectedRole(item);
                        }}
                      >
                        <SelectTrigger data-cy="select-trigger-template-format">
                          <SelectValue placeholder="Select a Role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem key={'empty'} value="">
                              No Role
                            </SelectItem>
                          </SelectGroup>
                          <SelectSeparator />
                          <SelectGroup>
                            {contactRoles.map((role) => (
                              <SelectItem key={role.value} value={role.value}>
                                {role.title}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                ></FormField>
              )}

              {templateDictionaryItem.type === 'date' ? (
                <div className="flex mt-4 gap-x-1">
                  <Typography variant="subtext">Change example date</Typography>
                  <Popover modal open={exampleDateModalOpen} onOpenChange={setExampleDateModalOpen}>
                    <PopoverTrigger
                      className="self-baseline"
                      onClick={() => setExampleDateModalOpen(true)}
                    >
                      <Typography variant="subtext">
                        <CalendarIcon className="w-4 h-4 mr-2" />
                      </Typography>
                    </PopoverTrigger>
                    <PopoverContent className="z-50 w-full overflow-auto bg-white shadow-lg">
                      <CalendarPicker
                        fixedWeeks
                        onSelect={
                          (e) => {
                            setExampleDate(dayjs(e).format('YYYY-MM-DD'));
                            setExampleDateModalOpen(false);
                          } // Format date to be like '2021-09-25' for backend
                        }
                        selected={dayjs(exampleDate).toDate()}
                        mode="single"
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              ) : (
                <Typography variant="subtext" className="mt-4">
                  {selectedFormat
                    ? formatOptions[selectedFormat]?.modifierFunc(formatExample())
                    : formatExample()}
                </Typography>
              )}
            </SectionCollapsible>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default TemplateDictionaryItem;
