import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { EventCategoryType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
export const columns: ColumnDef<EventCategoryType>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
