import { cn, getFullFileId } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { UseMutationResult } from '@tanstack/react-query';
import React, { FC, useContext, useState } from 'react';
import { FileExplorerContext } from './FileExplorerProvider';
import FileGridItem from './FileGridItem';
import { FileResourceItemSkeleton } from './FileResourceItemSkeleton';

export interface FileGridProps {
  items: FileResourceType[];
  isLoading: boolean;
  onFolderClick?: (item: FileResourceType) => void;
  isListView: boolean;
  currentDirectoryId: string;

  newItemLoading: boolean;
  moveFileMutation: UseMutationResult<any, any, any, any>;
  folderSelectDisabled?: boolean;
  singleSelectMode?: boolean;
  unavailableResources?: {
    resourceId: string;
    reason?: string;
  }[];
}

export const FileGrid: FC<FileGridProps> = (props) => {
  const {
    items,
    isLoading,
    onFolderClick,
    isListView,
    currentDirectoryId,
    newItemLoading,
    moveFileMutation: moveFile,
    folderSelectDisabled,
    singleSelectMode,
    unavailableResources,
  } = props;

  const {
    setIsFilePreviewModalOpen,
    setFilePreviewItem,
    selectedFiles,
    setSelectedFiles,
    movingFiles,
    setMovingFiles,
    deletingFiles,
  } = useContext(FileExplorerContext);

  const [urlCopied, setUrlCopied] = useState(false);

  const sortedItems = [...items].sort(
    (a, b) => new Date(a.dateCreated ?? '').getTime() - new Date(b.dateCreated ?? '').getTime(),
  );

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 auto-rows-min">
        {Array.from({ length: 12 }).map((_, index) => (
          <div key={index} className="animate-pulse">
            <div className="w-12 h-12 mx-auto mb-2 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  if (items.length === 0 && !isLoading && !newItemLoading) {
    return <div className="py-4 text-center text-gray-500">This folder is empty.</div>;
  }

  const onItemSelect = (e: React.ChangeEvent<HTMLInputElement>, item: FileResourceType) => {
    e.stopPropagation();
    if (singleSelectMode) {
      if (e.target.checked) {
        // Limit selection to one file if in singleSelectMode
        setSelectedFiles([item]);
      } else {
        setSelectedFiles([]);
      }
    } else {
      if (e.target.checked) {
        setSelectedFiles((prev) => [...prev, item]);
      } else {
        setSelectedFiles((prev) => {
          const updatedSet = prev.filter((selectedItem) => selectedItem !== item);
          return updatedSet;
        });
      }
    }
  };

  return (
    <div>
      {isListView ? (
        <ul className="w-full pl-2 space-y-4">
          {sortedItems.map((item: FileResourceType) => {
            const isDirectory = item.resourceType === 'DIR';
            const fullId = getFullFileId(item);
            const isDeleting = deletingFiles.some(
              (movingFile) => getFullFileId(movingFile) === fullId,
            );
            const isMoving = movingFiles.some((movingFile) => getFullFileId(movingFile) === fullId);

            if (isDeleting || isMoving) {
              return <FileResourceItemSkeleton key={fullId} isListView />;
            }

            const unavailable = unavailableResources?.find(
              (resource) => resource.resourceId === getFullFileId(item),
            );

            return (
              <div
                data-cy={`file-grid-item-${item.resourceId}`}
                key={fullId}
                className="flex items-center w-full"
              >
                <input
                  type="checkbox"
                  checked={selectedFiles?.some(
                    (selectedFile) => selectedFile.resourceId === item.resourceId,
                  )}
                  disabled={(folderSelectDisabled && isDirectory) || !!unavailable}
                  onChange={
                    (folderSelectDisabled && isDirectory) || !!unavailable
                      ? undefined
                      : (e) => onItemSelect(e, item)
                  }
                  className={cn(
                    'mr-2 rounded form-checkbox',
                    isDirectory && folderSelectDisabled ? 'hidden' : '',
                    unavailable && 'text-gray-200 cursor-not-allowed',
                  )}
                />
                <FileGridItem
                  unavailable={!!unavailable}
                  unavailableReason={unavailable?.reason}
                  item={item}
                  isListView={isListView}
                  onFolderClick={onFolderClick}
                  moveFileMutation={moveFile}
                  currentDirectoryId={currentDirectoryId}
                  urlCopied={urlCopied}
                  setUrlCopied={setUrlCopied}
                />
              </div>
            );
          })}
          {newItemLoading && <FileResourceItemSkeleton key={'new-item-skeleton'} isListView />}
        </ul>
      ) : (
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 auto-rows-min">
          {sortedItems.map((item: FileResourceType) => {
            const isDirectory = item.resourceType === 'DIR';
            const fullId = getFullFileId(item);

            const isDeleting = deletingFiles.some(
              (movingFile) => getFullFileId(movingFile) === fullId,
            );
            const isMoving = movingFiles.some((movingFile) => getFullFileId(movingFile) === fullId);

            if (isDeleting || isMoving) {
              return <FileResourceItemSkeleton key={fullId} />;
            }

            const unavailable = unavailableResources?.find(
              (resource) => resource.resourceId === getFullFileId(item),
            );

            return (
              <div
                data-cy={`file-grid-item-${item.resourceId}`}
                key={fullId}
                className={cn(
                  'text-center relative p-2 rounded',
                  isDirectory && 'font-semibold',
                  unavailable
                    ? 'text-gray-400 cursor-default'
                    : 'cursor-pointer hover:bg-gray-100 ',
                )}
              >
                <input
                  type="checkbox"
                  disabled={folderSelectDisabled && isDirectory}
                  checked={selectedFiles?.some(
                    (selectedFile) => selectedFile.resourceId === item.resourceId,
                  )}
                  onChange={
                    folderSelectDisabled && isDirectory ? undefined : (e) => onItemSelect(e, item)
                  }
                  className={cn(
                    'absolute mb-2 rounded top-2 right-2 form-checkbox',

                    isDirectory && folderSelectDisabled ? 'hidden' : '',
                    unavailable && 'text-gray-200 cursor-default',
                  )}
                />

                <FileGridItem
                  unavailable={!!unavailable}
                  unavailableReason={unavailable?.reason}
                  item={item}
                  isListView={isListView}
                  onFolderClick={onFolderClick}
                  moveFileMutation={moveFile}
                  currentDirectoryId={currentDirectoryId}
                  urlCopied={urlCopied}
                  setUrlCopied={setUrlCopied}
                />
              </div>
            );
          })}
          {newItemLoading && <FileResourceItemSkeleton key={'new-item-skeleton'} />}
        </div>
      )}
    </div>
  );
};
