import { DefaultV3Response, TrustAccountTransactionType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const basepacioliRoute = `pacioli/${API_STAGE}`;

// POST create_trust_account_transaction_v3
export const createTrustAccountTransactionV3 = (data: { caseId: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<TrustAccountTransactionType>>(
      `${basepacioliRoute}/v3/cases/${caseId}/trust-account-transactions`,
      rest,
    )
    .then((res) => res.data);
};

// DEL delete_trust_account_transaction_v3
export const deleteTrustAccountTransactionV3 = (trustAccountTransactionId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<TrustAccountTransactionType>>(
      `${basepacioliRoute}/v3/cases/trust-account-transactions/${trustAccountTransactionId}`,
    )
    .then((res) => res.data);

// GET get_trust_account_transactions_v3
export const getTrustAccountTransactionsV3 = (caseId: string) => {
  return axiosInstance
    .get<DefaultV3Response<TrustAccountTransactionType[], { runningTotal: number }>>(
      `${basepacioliRoute}/v3/cases/${caseId}/trust-account-transactions`,
    )
    .then((res) => res.data);
};

// PATCH update_trust_account_transaction_v3
export const updateTrustAccountTransactionV3 = (data: { trustAccountTransactionId: string }) => {
  const { trustAccountTransactionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<TrustAccountTransactionType>>(
      `${basepacioliRoute}/v3/cases/trust-account-transactions/${trustAccountTransactionId}`,
      rest,
    )
    .then((res) => res.data);
};
