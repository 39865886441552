import { cn } from '@colosseum/data';
import Typography from '../Typography/Typography';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

interface ContactItemViewerProps {
  primaryValue: string | React.ReactNode;
  additionalValues?: string[] | React.ReactNode[];
  className?: string;
}
export const ContactItemViewer = (props: ContactItemViewerProps) => {
  const { primaryValue, additionalValues, className } = props;
  return (
    <Typography className={cn('max-w-full', className)}>
      {primaryValue}
      {!!additionalValues?.length && (
        <TooltipWrapper message={`${additionalValues.join(', ')}`}>
          <span>
            <Typography tag="span" color="gray" size="xs" className={className}>
              {' '}
              +{additionalValues.length} more
            </Typography>
          </span>
        </TooltipWrapper>
      )}
    </Typography>
  );
};
