import {
  CaseContactConnectionRoleType,
  ContactType,
  ScenarioType,
  VehicleType,
  caseContactConnectionOptions,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { useGetRoleIdForItem } from '../hooks/useGetBillingEntityId';
import { useGetCaseContactConnectionsViewModel } from '../lib/react-query/case-contact-connection';
import { useGetScenarioForCase } from '../lib/react-query/vehicle';

export interface ScenarioComplete extends ScenarioType {
  vehicles: (VehicleType & {
    towCompany?: ContactType;
    repairShop?: ContactType;
    rentalCarCompany?: ContactType;
  })[];
  parties: ContactType[];
}

/**
 *
 * @description This hook is used to get all the scenario data with joined contacts for a case
 */
export const useGetScenarioComplete = (caseId: string) => {
  const { data: caseContactConnectionsData, isLoading: isCaseContactConnectionsLoading } =
    useGetCaseContactConnectionsViewModel(caseId);
  const scenarioRes = useGetScenarioForCase(caseId);
  const caseContactConnections = caseContactConnectionsData?.data || [];
  const getRoleIdForVehicle = useGetRoleIdForItem<VehicleType>(caseId);

  const isLoading = scenarioRes.isLoading || isCaseContactConnectionsLoading;

  const mergedCaseContactConnectionsSorted = caseContactConnections?.sort((a, b) => {
    const aHasClientRole = a.roles?.some(
      (role) => role.roleOnCase === partiesAndPoliciesConnectionOptions.client,
    );
    const bHasClientRole = b.roles?.some(
      (role) => role.roleOnCase === partiesAndPoliciesConnectionOptions.client,
    );

    if (aHasClientRole && !bHasClientRole) {
      return -1;
    }

    if (!aHasClientRole && bHasClientRole) {
      return 1;
    }

    return 0;
  });

  const mergedContactsFiltered = mergedCaseContactConnectionsSorted
    ?.filter((contact: { roles: CaseContactConnectionRoleType[] }) => {
      const rolesArr = contact.roles?.map((role) => {
        return role.roleOnCase ?? '';
      });

      const roles = rolesArr?.join(', ');

      return roles?.includes(partiesAndPoliciesConnectionOptions.party);
    })
    .map((caseContactConnection) => {
      return {
        ...caseContactConnection.contact,
        ...caseContactConnection,
      };
    });

  const completeVehicles = scenarioRes?.data?.data?.vehicles?.map((vehicle) => {
    const towCompanyContactId = getRoleIdForVehicle(
      vehicle,
      caseContactConnectionOptions.towCompany,
      'vehicleId',
    );
    const towCompany = caseContactConnections?.find(
      (caseContactConnection) => caseContactConnection.contact?.contactId === towCompanyContactId,
    )?.contact;
    const repairShopContactId = getRoleIdForVehicle(
      vehicle,
      caseContactConnectionOptions.repairShop,
      'vehicleId',
    );
    const repairShop = caseContactConnections?.find(
      (caseContactConnection) => caseContactConnection.contact?.contactId === repairShopContactId,
    )?.contact;
    const rentalCarCompanyContactId = getRoleIdForVehicle(
      vehicle,
      caseContactConnectionOptions.rentalCarCompany,
      'vehicleId',
    );
    const rentalCarCompany = caseContactConnections?.find(
      (caseContactConnection) =>
        caseContactConnection.contact?.contactId === rentalCarCompanyContactId,
    )?.contact;
    return {
      ...vehicle,
      towCompany,
      repairShop,
      rentalCarCompany,
    };
  });

  return {
    ...scenarioRes,
    isLoading: isLoading,
    data: {
      data: {
        ...scenarioRes?.data?.data,
        parties: mergedContactsFiltered,
        contacts: caseContactConnections,
        policies: scenarioRes?.data?.data?.policies ?? [],
        vehicles: completeVehicles ?? [],
        itemConnections: scenarioRes?.data?.data?.itemConnections ?? [],
      },
    },
  };
};
