import { DefaultV3Response, SettlementProposalType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baselutatiusRoute = `lutatius/${API_STAGE}`;

// POST create_settlement_proposal_v3
export const createSettlementProposalV3 = (data: { caseId?: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<SettlementProposalType>>(
      `${baselutatiusRoute}/v3/cases/${caseId}/settlement-proposals`,
      rest,
    )
    .then((res) => res.data);
};

// DEL delete_settlement_proposal_v3
export const deleteSettlementProposalV3 = (settlementProposalId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<SettlementProposalType>>(
      `${baselutatiusRoute}/v3/cases/settlement-proposals/${settlementProposalId}`,
    )
    .then((res) => res.data);

// GET get_settlement_proposals_v3
export const getSettlementProposalsV3 = (caseId: string) => {
  return axiosInstance
    .get<DefaultV3Response<SettlementProposalType[]>>(
      `${baselutatiusRoute}/v3/cases/${caseId}/settlement-proposals`,
    )
    .then((res) => res.data);
};

// PATCH update_settlement_proposal_v3
export const updateSettlementProposalV3 = (data: { settlementProposalId?: string }) => {
  const { settlementProposalId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<SettlementProposalType>>(
      `${baselutatiusRoute}/v3/cases/settlement-proposals/${settlementProposalId}`,
      rest,
    )
    .then((res) => res.data);
};
