import { useGetCaseContactConnectionsByContact, useGetCases } from '@colosseum/data';
import { CaseContactConnectionType, ContactType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './associated-cases-columns';

const AssociatedCases = ({ contact }: { contact: ContactType }) => {
  const navigate = useNavigate();
  const { data: casesForContactData } = useGetCaseContactConnectionsByContact(contact?.contactId);
  const { data: selectedCasesData } = useGetCases();
  const selectedCases = selectedCasesData?.data ?? [];

  const associatedCases = casesForContactData?.data?.map((caseConnection) => {
    const selectedCase = selectedCases.find(
      (selectedCase) =>
        selectedCase.caseId === caseConnection.caseContactConnectionId.split('-')[0],
    );
    return { ...caseConnection, case: selectedCase };
  });

  const handleRelatedCaseClick = (targetCaseConnection: CaseContactConnectionType) => {
    const caseData = selectedCases?.find(
      (selectedCase) =>
        selectedCase.caseId === targetCaseConnection.caseContactConnectionId.split('-')[0],
    );
    if (caseData?.caseStatus?.category === 'lead') {
      navigate(`/leads/${targetCaseConnection.caseContactConnectionId.split('-')[0]}`);
    } else {
      navigate(`/cases/${targetCaseConnection.caseContactConnectionId.split('-')[0]}`);
    }
  };

  return (
    <div>
      <DataTable
        data={associatedCases ?? []}
        columns={columns}
        disableReorder
        handleRowClick={(row: Row<CaseContactConnectionType>) => {
          handleRelatedCaseClick(row.original);
        }}
        hideToolbar
        tableName="contact-associated-cases"
      />
    </div>
  );
};

export default AssociatedCases;
