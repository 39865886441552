import { Table } from '@tanstack/react-table';
import { X } from 'lucide-react';

import Chip from '../../Chip/Chip';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { DataTableFacetedFilter } from './DataTableFacetedFilter';
import { DataTableViewOptions } from './DataTableViewOptions';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  showSearchBar?: boolean;
  filters?: {
    id: string;
    options: {
      value: string;
      label: string;
      icon?: any;
    }[];
  }[];
  handleResetFilters?: () => void;
  hideViewButton?: boolean;
  csvButton?: React.ReactElement;
  customButtons?: React.ReactElement;
  customRightButton?: React.ReactElement;
}

export function DataTableToolbar<TData>({
  table,
  filters,
  showSearchBar = false,
  handleResetFilters,
  hideViewButton,
  csvButton,
  customButtons,
  customRightButton,
}: DataTableToolbarProps<TData>) {
  const isFiltered =
    table.getPreFilteredRowModel().rows.length > table.getFilteredRowModel().rows.length;

  const sortingColumnIsVisible =
    table.getState().sorting?.length > 0 &&
    table?.getColumn(table.getState().sorting[0]?.id)?.getIsVisible();

  return (
    <div className="flex flex-wrap items-center justify-between gap-y-2">
      <div className="flex flex-wrap items-center flex-1 gap-2">
        {showSearchBar && (
          <Input
            placeholder={'Search...'}
            data-cy="table-searchbar"
            value={table.getState().globalFilter ?? ''}
            onChange={(event) => {
              table.setGlobalFilter(event.target.value);
              // If we are searching in a table, we don't want to stay on page n as if there
              // are less than pageSize results, we would wrongly get a no results message.
              table.resetPageIndex();
            }}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}
        {!sortingColumnIsVisible && table.getState().sorting.length > 0 ? (
          <TooltipWrapper message="You are seeing this because the column you are sorting by is not currently visible.">
            <div>
              <Chip
                name={`Sorting by: ${table.getState().sorting[0]?.id} ${
                  table.getState().sorting[0].desc ? 'Desc' : 'Asc'
                }`}
                color="stone"
              />
            </div>
          </TooltipWrapper>
        ) : null}
        {filters?.map(
          (filter) =>
            table.getColumn(filter.id) && (
              <DataTableFacetedFilter
                key={filter.id}
                column={table.getColumn(filter.id)}
                title={filter.id}
                options={filter.options}
              />
            ),
        )}
        {customButtons}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {
              table.resetColumnFilters();
              table.resetGlobalFilter();
              handleResetFilters?.();
            }}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="w-4 h-4 ml-2" />
          </Button>
        )}
      </div>

      {csvButton}
      {!hideViewButton && <DataTableViewOptions table={table} />}
      {customRightButton}
    </div>
  );
}
