import {
  US_STATES,
  bToBitB,
  bitBToB,
  contactTypeOptions,
  contactTypeOptionsOld,
  useBiographicalForm,
} from '@colosseum/data';
import { ContactViewModelType, contactLanguageOptions } from '@gladiate/types';
import dayjs from 'dayjs';
import { useState } from 'react';
import SectionCollapsible from '../../SectionCollapsible/SectionCollapsible';
import SsnInput from '../../SsnInput/SsnInput';
import Switch from '../../Switch/Switch';
import { Form } from '../../shadcn/Form/Form';
import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';
import MultiSelectFormInput from '../MultiSelectFormInput/MultiSelectFormInput';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface BiographicalFormProps {
  contact?: ContactViewModelType;
}

export const BiographicalForm = (props: BiographicalFormProps) => {
  const { contact } = props;
  const { form, handleUpdateBiographical } = useBiographicalForm({ contact });

  const [selectedLanguages, setSelectedLanguages] = useState<
    (typeof contactLanguageOptions)[keyof typeof contactLanguageOptions][]
  >(contact?.languages ?? []);

  if (!contact) return null;

  const isOrganization = [
    contactTypeOptions.Organization,
    contactTypeOptionsOld.Organization,
  ].includes(contact?.contactType ?? '');

  function handleOnBlur(e: React.SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    handleUpdateBiographical(target.name, target.value);
  }

  return (
    <Form {...form}>
      <div className="grid w-full grid-cols-2 mb-2 gap-x-3 gap-y-5">
        {!isOrganization && (
          <>
            <TextFormInput
              {...form.register(`firstName`)}
              handleOnBlur={handleOnBlur}
              title="First Name"
              maxLength={60}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Middle Name"
              {...form.register(`middleName`)}
              maxLength={60}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Last Name"
              {...form.register(`lastName`)}
              maxLength={60}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Nickname"
              {...form.register(`nickname`)}
              maxLength={60}
            />
            <CalendarFormInput
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateBiographical('dateOfBirth', target.value);
              }}
              title="Date of Birth"
              disabled={(date) => date > dayjs().toDate()}
              {...form.register(`dateOfBirth`)}
              resourceTypeObj={{
                id: contact?.contactId,
                type: 'contact',
              }}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Prefix"
              {...form.register(`prefix`)}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Suffix"
              {...form.register(`suffix`)}
            />
            <MultiSelectFormInput
              title="Language(s)"
              {...form.register(`languages`)}
              selectedOptions={selectedLanguages ?? []}
              options={Object.keys(contactLanguageOptions).map((key) => ({
                title: key,
                value: contactLanguageOptions[key as keyof typeof contactLanguageOptions],
              }))}
              optionsIsObject
              onChange={(option: string) => {
                if (
                  contact.languages?.includes(
                    option as (typeof contactLanguageOptions)[keyof typeof contactLanguageOptions],
                  )
                ) {
                  const newLangs = selectedLanguages.filter((lang) => lang !== option);
                  setSelectedLanguages(newLangs);
                  handleUpdateBiographical('languages', newLangs);
                } else {
                  const newLangs = [
                    ...selectedLanguages,
                    option as (typeof contactLanguageOptions)[keyof typeof contactLanguageOptions],
                  ];
                  setSelectedLanguages(newLangs);
                  handleUpdateBiographical('languages', newLangs);
                }
              }}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Gender"
              {...form.register(`gender`)}
            />

            <SelectFormInput
              title="Pronouns"
              listItems={['He/His', 'She/Her', 'They/Their']}
              defaultValue={contact?.pronouns}
              placeholderText="Select Pronouns"
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateBiographical('pronouns', target.value);
              }}
              {...form.register(`pronouns`)}
            />
            <SsnInput
              formHandleBlur={handleOnBlur}
              title="Social Security Number"
              {...form.register(`socialSecurityNumber`)}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Marital Status"
              {...form.register(`maritalStatus`)}
            />
          </>
        )}
        {isOrganization && (
          <>
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Organization name"
              {...form.register(`name`)}
              maxLength={120}
            />
            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Website"
              {...form.register(`website`)}
            />
          </>
        )}
        {!isOrganization && (
          <>
            <SelectFormInput
              title="Legal Status"
              listItems={[
                'United States Citizen',
                'Lawful Permanent Resident',
                'Temporary Visitor',
                'Undocumented',
              ]}
              defaultValue={contact?.legalStatus}
              placeholderText="Select a legal status"
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateBiographical('legalStatus', target.value);
              }}
              {...form.register(`legalStatus`)}
            />

            <SelectFormInput
              title="Driver's License State"
              listItems={US_STATES}
              defaultValue={contact?.driversLicenseState}
              placeholderText="Select a DL state"
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateBiographical('driversLicenseState', target.value);
              }}
              {...form.register(`driversLicenseState`)}
            />

            <TextFormInput
              handleOnBlur={handleOnBlur}
              title="Driver's License Number"
              {...form.register(`driversLicenseNumber`)}
            />
          </>
        )}
      </div>
      {!isOrganization && (
        <SectionCollapsible title="Additional Details">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-4 xl:max-w-[50%] w-full">
                <Switch
                  title="Deceased?"
                  value={bitBToB(contact?.deceased)}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleOnBlur({
                      target: {
                        name: 'deceased',
                        value: bToBitB(e.target.value ? '1' : '0'),
                      },
                    } as React.ChangeEvent<HTMLInputElement>);
                  }}
                  {...form.register('deceased')}
                />
                {bitBToB(contact?.deceased) && (
                  <div className="col-span-2">
                    <CalendarFormInput
                      handleOnChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        handleUpdateBiographical('dateOfDeath', target.value);
                      }}
                      title="Date of Death"
                      disabled={(date) => date > dayjs().toDate()}
                      {...form.register(`dateOfDeath`)}
                      resourceTypeObj={{
                        id: contact?.contactId,
                        type: 'contact',
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4 xl:max-w-[50%] w-full">
                <Switch
                  title="Bankruptcy?"
                  value={contact?.bankruptcy ?? false}
                  handleChange={handleOnBlur}
                  {...form.register('bankruptcy')}
                />
                {contact?.bankruptcy && (
                  <div className="col-span-2">
                    <TextFormInput
                      handleOnBlur={handleOnBlur}
                      title="Description of Bankruptcy"
                      type="textarea"
                      {...form.register('bankruptcyDescription')}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4 xl:max-w-[50%] w-full">
                <Switch
                  title="Felony?"
                  value={contact?.felony ?? false}
                  handleChange={handleOnBlur}
                  {...form.register('felony')}
                />
                {contact?.felony && (
                  <div className="col-span-2">
                    <TextFormInput
                      handleOnBlur={handleOnBlur}
                      title="Description of Felony"
                      type="textarea"
                      {...form.register('felonyDescription')}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4 xl:max-w-[50%] w-full">
                <Switch
                  title="Priors?"
                  value={contact?.priors ?? false}
                  handleChange={handleOnBlur}
                  {...form.register('priors')}
                />

                {contact?.priors && (
                  <div className="col-span-2">
                    <TextFormInput
                      handleOnBlur={handleOnBlur}
                      title="Description of Priors"
                      type="textarea"
                      {...form.register('priorsDescription')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionCollapsible>
      )}
    </Form>
  );
};

export default BiographicalForm;
