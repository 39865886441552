import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import { getAllAuditsV3, getAuditDetailsV3, getAuditsV3 } from '../requests/herodotus';
import { getCaseAuditsV3 } from '../requests/sibyls';

export const useGetAuditDetails = (auditId?: string) => {
  return useQuery({
    // This types the success and error functions for axios
    queryKey: [queryKeys.auditDetails, auditId],
    queryFn: async () => getAuditDetailsV3(auditId),
    enabled: !!auditId,
  });
};

export const useGetAudits = (itemId?: Parameters<typeof getAuditsV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.audits, itemId],
    queryFn: async () => getAuditsV3(itemId),
    enabled: !!itemId,
  });
};

export const useGetCaseAudits = (caseId?: string) => {
  return useQuery({
    queryKey: [queryKeys.audits, 'case', caseId],
    queryFn: async () => getCaseAuditsV3(caseId),
    enabled: !!caseId,
  });
};
export const useGetAllAudits = (skip?: string) => {
  return useQuery({
    queryKey: [queryKeys.audits],
    queryFn: async () => getAllAuditsV3(),
    enabled: !skip,
  });
};
