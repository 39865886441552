import {
  DefaultV3Error,
  DefaultV3Response,
  FirmOfficeType,
  FirmType,
  GroupPermission,
} from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { queryKeys } from '../../static/queryKeys';
import { getFirmId } from '../../utils/authUtils';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  baseFirmSettingsRoute,
  firmSettingsOfficeRoute,
  firmSettingsRoute,
  updateFirmOfficeRoute,
} from '../apiRoutes';
import { axiosInstance } from '../https';
import {
  addUserToFirmV3,
  createCaseAccessV3,
  createCoCounselV3,
  createFirmGroupV3,
  createFirmUserV3,
  deleteCaseAccessV3,
  deleteCoCounselV3,
  deleteFirmGroupV3,
  deleteUserFromFirmV3,
  disableUserForFirmV3,
  enableUserForFirmV3,
  getAllCaseAccessesV3,
  getCaseAccessesV3,
  getCoCounselsV3,
  getCurrentUserV3,
  getFirmDetailsV3,
  getFirmGroupPermissionsV3,
  getUserForFirmV3,
  getUsersForFirmV3,
  resendUserInvitationToJoinFirmV3,
  updateCoCounselV3,
  updateFirmGroupV3,
  updateFirmUserV3,
} from '../requests/hurin';
import {
  createOneClickSignSetting,
  deleteOneClickSignSetting,
  getOneClickSignSetting,
  getOneClickSignSettingList,
  updateOneClickSignSetting,
} from '../requests/jurisconsults';

const firmId = getFirmId();

export const useGetFirmUser = (username?: string) => {
  return useQuery({
    queryKey: ['firmUsers', username],
    queryFn: async () => getUserForFirmV3(username),
    enabled: !!username,
  });
};

export const useGetFirmUsers = (showCoCounsel?: boolean) => {
  return useQuery({
    queryKey: ['firmUsers', showCoCounsel],
    queryFn: async () => {
      if (!showCoCounsel) {
        return getUsersForFirmV3().then((res) => {
          return {
            ...res,
            data: res?.data.filter((user) => user.groupName !== 'CoCounsel'),
          };
        });
      }
      return getUsersForFirmV3();
    },
  });
};

export const useGetFirm = () => {
  return useQuery<DefaultV3Response<FirmType>, AxiosError<DefaultV3Error>>({
    queryKey: [queryKeys.firm],
    queryFn: async () =>
      axiosInstance.get<DefaultV3Response<FirmType>>(firmSettingsRoute).then((res) => res.data),
  });
};

export const useGetCurrentUser = () => {
  return useQuery({
    queryKey: [queryKeys.currentUser],
    queryFn: async () => getCurrentUserV3(),
  });
};

export const useGetFirmOffices = () => {
  return useQuery<DefaultV3Response<FirmOfficeType[]>, AxiosError<DefaultV3Error>>({
    queryKey: [queryKeys.firmOffices],
    queryFn: async () =>
      axiosInstance
        .get<DefaultV3Response<FirmOfficeType[]>>(firmSettingsOfficeRoute)
        .then((res) => res.data),
  });
};

export const useCreateFirmOffice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return axiosInstance.post(firmSettingsOfficeRoute, {});
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmOffices]);
    },
  });
};

export const useUpdateFirmOffice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FirmOfficeType) => {
      const { firmOfficeId, ...restData } = data;
      return axiosInstance.patch(updateFirmOfficeRoute(firmOfficeId), restData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmOffices]);
    },
  });
};

export const useUpdateFirmSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FirmType) => {
      const { firmId, ...restData } = data;
      return axiosInstance.patch(baseFirmSettingsRoute, restData);
    },
    onMutate: async (variables: FirmType) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([queryKeys.firm]);

      // Snapshot the previous value
      const previousFirmSettings = queryClient.getQueryData([queryKeys.firm]);

      // Optimistically update to the new value
      queryClient.setQueryData([queryKeys.firm], (old: { data: FirmType } | undefined) => {
        return {
          ...old,
          data: {
            ...old?.data,
            ...variables,
          },
        };
      });

      return { previousFirmSettings };
    },
    onError: (error, variables, context: any) => {
      // Rollback to the previous value if the mutation fails
      queryClient.setQueryData([queryKeys.firm], context?.previousFirmSettings);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firm]);
    },
  });
};

export const useDeleteFirmOffice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (firmOfficeId: string) => {
      return axiosInstance.delete(updateFirmOfficeRoute(firmOfficeId));
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmOffices]);
    },
  });
};

export const useGetFirmDetails = () => {
  return useQuery({
    queryKey: ['firmDetails'],
    queryFn: async () => getFirmDetailsV3(firmId),
  });
};

export const useUpdateFirmUserAttributes = (username: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      data,
      usernameOverride,
    }: {
      data: Parameters<typeof updateFirmUserV3>[1];
      usernameOverride?: string;
    }) => {
      const id = usernameOverride ? usernameOverride : username;
      return updateFirmUserV3(id, data);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';
      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user, username]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUsers],
      });
      queryClient.invalidateQueries({ queryKey: ['firmUserDetails'] });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.availableSubscriptions],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.subscriptions],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.currentUser],
      });
    },
  });
};

export const useCreateUserForFirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createFirmUserV3>[0]) => createFirmUserV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
    },
  });
};

export const useGetFirmGroupsAndPermissions = () => {
  return useQuery({
    queryKey: [queryKeys.firmGroups],
    queryFn: async () => getFirmGroupPermissionsV3(),
  });
};

export const useCreateFirmGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createFirmGroupV3>[0]) => createFirmGroupV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmGroups]);
    },
  });
};

export const useDeleteFirmGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (groupId: string) => deleteFirmGroupV3(groupId),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmGroups]);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};

export const useUpdateFirmGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<GroupPermission>) => {
      const { groupId, ...restData } = data;

      if (!groupId) {
        return;
      }

      return updateFirmGroupV3(groupId, restData);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmGroups],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.currentUser],
      });
    },
    onSuccess: () => {
      enqueueAPISnackbar({
        message: 'User Group updated successfully',
        variant: 'success',
      });
    },
  });
};

export const useAddUserToFirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: string) => addUserToFirmV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
    },
  });
};

export const useDeleteUserFromFirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: Parameters<typeof deleteUserFromFirmV3>[0]) =>
      deleteUserFromFirmV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.availableSubscriptions],
      });
    },
  });
};

export const useResendUserInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: string) => resendUserInvitationToJoinFirmV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
    },
  });
};

export const useDisableUserForFirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: string) => disableUserForFirmV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
    },
  });
};

export const useEnableUserForFirm = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: string) => enableUserForFirmV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.firmUsers]);
    },
  });
};

// #region ------------------ Case Access -------------------
export const useGetAllCaseAccesses = () => {
  return useQuery({
    queryKey: [queryKeys.caseAccesses],
    queryFn: async () => getAllCaseAccessesV3(),
  });
};

export const useGetCaseAccesses = (username: Parameters<typeof getCaseAccessesV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.caseAccesses, username],
    queryFn: async () => getCaseAccessesV3(username),
  });
};

export const useCreateCaseAccess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createCaseAccessV3>[0]) => createCaseAccessV3(data),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.caseAccesses]);
    },
  });
};

export const useDeleteCaseAccess = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userAccessId: Parameters<typeof deleteCaseAccessV3>[0]) =>
      deleteCaseAccessV3(userAccessId),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.caseAccesses]);
    },
  });
};

// #region ------------------ Co-Counsels -------------------
export const useGetCoCounsel = () => {
  return useQuery({
    queryKey: [queryKeys.coCounsels],
    queryFn: async () => getCoCounselsV3(),
  });
};

export const useCreateCoCounsel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Parameters<typeof createCoCounselV3>[0]) => createCoCounselV3(data),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.coCounsels]);
    },
  });
};

export const useDeleteCoCounsel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (username: Parameters<typeof deleteCoCounselV3>[0]) =>
      deleteCoCounselV3(username),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.coCounsels]);
    },
  });
};

export const useUpdateCoCounsel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Parameters<typeof updateCoCounselV3>[0]) => {
      return updateCoCounselV3(data);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.coCounsels]);
    },
  });
};
// #endregion

// #region ------------------ One Click Sign Settings -------------------
export const useGetOneClickSignSettingList = () => {
  return useQuery({
    queryKey: [queryKeys.oneClickSignSettings],
    queryFn: async () => getOneClickSignSettingList(),
  });
};

export const useGetOneClickSignSetting = (
  oneClickSignSettingId: Parameters<typeof getOneClickSignSetting>[0],
) => {
  return useQuery({
    queryKey: [queryKeys.oneClickSignSettings, oneClickSignSettingId],
    queryFn: async () => getOneClickSignSetting(oneClickSignSettingId),
  });
};

export const useCreateOneClickSignSetting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Parameters<typeof createOneClickSignSetting>[0]) =>
      createOneClickSignSetting(data),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.oneClickSignSettings]);
    },
  });
};

export const useUpdateOneClickSignSetting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Parameters<typeof updateOneClickSignSetting>[0]) =>
      updateOneClickSignSetting(data),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.oneClickSignSettings]);
    },
  });
};

export const useDeleteOneClickSignSetting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (oneClickSignSettingId: Parameters<typeof deleteOneClickSignSetting>[0]) =>
      deleteOneClickSignSetting(oneClickSignSettingId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.oneClickSignSettings],
      });
    },
  });
};
// #endregion
