import { prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { TeamType, TeammateType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { uniqBy } from 'lodash';

interface TeamsWithTeammateDisplayNames extends Omit<TeamType, 'teammates'> {
  teammates?: TeammateType & { displayName?: string }[];
}

export const columns: ColumnDef<TeamsWithTeammateDisplayNames>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date Created',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) =>
      row.teammates
        ? uniqBy(row.teammates, 'username')
            .map((teammate) => teammate.displayName)
            .join(', ')
        : null,
    id: 'Teammates',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
