import { DefaultV3Response } from '@gladiate/types';
import { QueryClient } from '@tanstack/react-query';

export const fetchDataDefaultV3 = async <T>(
  apiCall: () => Promise<DefaultV3Response<T>>,
  isCasesQuery = false,
): Promise<T | []> => {
  try {
    const response = await apiCall();
    return response.data; // Extracting the data property
  } catch (error) {
    if (isCasesQuery) {
      //throw react query error
      throw new Error('No cases found');
    }
    console.error('Error fetching data:', error);
    return []; // Return [] in case of an error
  }
};

export const fetchDataOld = async <T>(apiCall: () => Promise<T>): Promise<T | []> => {
  try {
    const response = await apiCall();
    return response; // Extracting the data property
  } catch (error) {
    console.error('Error fetching data:', error);
    return []; // Return [] in case of an error
  }
};

const MAX_ITERATIONS = 250;
/**
 * @description This function fetches data from the API,
 * and while there is a last evaluated key, it fetches the next set of data.
 * @param getFunction This is an axios function that fetches data from the API
 * @param hashKey This is the hash key of the last evaluated key
 * @param rangeKey This is the range key of the last evaluated key
 * @param queryClient Query client from react-query
 * @param queryKey Query key for the react-query
 * @returns The data from the API, joined together
 * @note MAX_ITERATIONS is set to 250 to prevent an infinite loop
 */
export const fetchLastEvaluatedKeys = async <T>(
  getFunction: (arg0?: string, arg1?: string) => Promise<DefaultV3Response<T[]>>,
  hashKey: keyof T,
  rangeKey: keyof T,
  queryClient: QueryClient,
  queryKey: string[],
) => {
  // if the last evaluated key is the same as the previous one
  let currentReq = {} as Awaited<ReturnType<typeof getFunction>>;
  let firstRequest = true;
  let iterations = 0;

  while (
    (firstRequest || currentReq?.meta?.lastEvaluatedKey) &&
    iterations < MAX_ITERATIONS // if this is reached, there is probably an infinite loop
  ) {
    const nextReq = await getFunction(
      currentReq?.meta?.lastEvaluatedKey?.[hashKey as string]?.s ||
        currentReq?.meta?.lastEvaluatedKey?.[hashKey as string]?.S,
      currentReq?.meta?.lastEvaluatedKey?.[rangeKey as string]?.s ||
        currentReq?.meta?.lastEvaluatedKey?.[rangeKey as string]?.S,
    );
    if (firstRequest) {
      currentReq = nextReq;
    } else {
      currentReq = {
        ...nextReq,
        data: currentReq.data.concat(nextReq?.data ?? []),
      };
    }
    firstRequest = false;
    iterations++;
    queryClient.setQueryData(queryKey, currentReq);
  }
  return currentReq;
};
