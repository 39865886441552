import { NoteType } from '@gladiate/types';
import { createColumnHelper } from '@tanstack/react-table';
import { dateRangeFilterFn } from '../shadcn/data-table/table-filter-helpers';

const columnHelper = createColumnHelper<NoteType>();

export const columns = [
  columnHelper.accessor(
    (row) => {
      return row.type;
    },
    {
      id: 'Type',
      filterFn: 'arrIncludesSome',
    },
  ),
  columnHelper.accessor(
    (row) => {
      return row.username;
    },
    {
      id: 'User',
      filterFn: 'arrIncludesSome',
    },
  ),
  columnHelper.accessor((row) => row.dateCreated, {
    id: 'Date Created',
    filterFn: dateRangeFilterFn,
  }),
];
