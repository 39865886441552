import { Form, SelectFormInput, TextFormInput } from '@colosseum/shared-ui';
import { CaseType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as z from 'zod';

const formSchema = z.object({
  declineCaseReason: z.string().optional(),
  declineCaseReasonDescription: z.string().optional(),
});

export interface DeclineCaseProps {
  caseData?: CaseType;
  updateCase: any;
}

export function DeclineCase(props: DeclineCaseProps) {
  const { caseData, updateCase } = props;
  const { caseId } = useParams() as { caseId: string };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...caseData,
    },
  });

  function handleUpdate(key: string, value: string) {
    updateCase.mutate({
      caseId,
      [key]: value,
    });
  }

  return (
    <div>
      {caseData && caseData.caseStatus?.category === 'decline' ? (
        <div className="w-full mt-5 bg-white rounded-lg p-7">
          <div className="w-full pb-5 align-middle ">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <label className="text-xl font-semibold capitalize ">Decline Case</label>
              </div>
            </div>
            <Form {...form}>
              <form>
                <div className="pt-5 pb-5">
                  <SelectFormInput
                    title="Internal Reason for decline"
                    listItems={[
                      'Incident Type',
                      'Damages',
                      'Liability',
                      'Coverage',
                      'Conflict',
                      'Other',
                    ]}
                    defaultValue={caseData?.declineCaseReason}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdate('declineCaseReason', target.value);
                    }}
                    {...form.register('declineCaseReason')}
                  />
                </div>
                <TextFormInput
                  {...form.register('declineCaseReasonDescription')}
                  handleOnBlur={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleUpdate('declineCaseReasonDescription', target.value);
                  }}
                  title={'Details For Decline'}
                  type="textarea"
                />
              </form>
            </Form>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DeclineCase;
