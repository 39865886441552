import { cn, settingsSectionMappings } from '@colosseum/data';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
  tabsListClass,
} from '@colosseum/shared-ui';
import {} from '@radix-ui/react-tabs';
import CoCounselSection from '../CoCounsel/CoCounselSection';
import TeamManagement from '../TeamManagement/TeamManagement';
import UserGroupsSection from './UserGroups/UserGroupsSection';
import UserManagement from './UserManagement/UserManagement';

export const UsersTab = () => (
  <Tabs defaultValue="users">
    <h1
      data-settings-submenu-item={settingsSectionMappings.users}
      className="pb-4 pl-2 text-xl font-semibold text-gray-900"
    >
      User Management
    </h1>
    <TabsList id="firm-settings-users" className={cn(tabsListClass, 'mb-4')}>
      <TabsTrigger
        className={tabTriggerClass}
        key="users"
        value="users"
        data-cy="firm-settings-users"
      >
        Users
      </TabsTrigger>
      <TabsTrigger
        className={tabTriggerClass}
        key="user-groups"
        value="user-groups"
        data-cy="firm-settings-user-groups"
      >
        User Groups
      </TabsTrigger>
      <TabsTrigger
        className={tabTriggerClass}
        key="co-counsel"
        value="co-counsel"
        data-cy="firm-settings-co-counsel"
      >
        Co-Counsel
      </TabsTrigger>
      <TabsTrigger
        className={tabTriggerClass}
        key="teams"
        value="teams"
        data-cy="firm-settings-teams"
      >
        Teams
      </TabsTrigger>
    </TabsList>
    <TabsContent value="users">
      <UserManagement />
    </TabsContent>
    <TabsContent value="user-groups">
      <UserGroupsSection />
    </TabsContent>
    <TabsContent value="co-counsel">
      <CoCounselSection />
    </TabsContent>
    <TabsContent value="teams">
      <TeamManagement />
    </TabsContent>
  </Tabs>
);

export default UsersTab;
