import {
  tanstackTableNames,
  useGetCasesComplete,
  useGetInsightsMedicalViewModel,
} from '@colosseum/data';
import { useState } from 'react';
import DataTabs from '../DataTabs/DataTabs';
import { DataTable } from '../shadcn/data-table/DataTable';
import { FirmMedicalTreatmentsComplete, insightsColumns } from './medical-providers-table-columns';
export interface MedicalTreatmentsTableProps {}

export function MedicalProvidersTable(props: MedicalTreatmentsTableProps) {
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data: casesData, isLoading: casesDataLoading } = useGetCasesComplete();
  const { data: insightsMedicalProviderData, isLoading: insightsMedicalProviderDataLoading } =
    useGetInsightsMedicalViewModel();
  // #endregion

  // #region State
  const [grouping, setGrouping] = useState(['Case Title']);
  // #endregion

  // #region Derived State
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <div className="flex flex-col gap-y-6">
      {casesData.data && (
        <>
          <DataTabs
            filters={[
              {
                title: 'By Case',
                count: casesData.data?.length,
                value: 'Case Title',
              },
              {
                title: 'By Billing Entity',
                count: casesData.data?.length,
                value: 'Billing Entity',
              },
            ]}
            setActiveFilter={(value) => {
              setGrouping([value]);
            }}
            activeFilter={grouping[0]}
          />

          <DataTable
            isViewOnly={true}
            data={
              // TODO fix typing when moving to a complete treatments service in the future
              insightsMedicalProviderData.data as unknown as FirmMedicalTreatmentsComplete[]
            }
            columns={insightsColumns}
            isLoading={insightsMedicalProviderDataLoading}
            tableName={tanstackTableNames.medicalProviders}
            grouping={grouping}
            initialGroupingsExpanded={false}
            initialVisibility={{
              'Client Paid out of Pocket': false,
              'Medical Provider': false,
              'Date Created': false,
              'Date Modified': false,
              'Date Verified': false,
              'Ending Date of Service': false,
              'Health Insurance Coverage': false,
              'Injection Count': false,
              'Med Pay Coverage': false,
              'MRI Count': false,
              'Number of Visits': false,
              'PIP Coverage': false,
              'Surgery Count': false,
              'Type of Treatment': false,
              Description: false,
            }}
            defaultPageSize={25}
          />
        </>
      )}
    </div>
  );
}
