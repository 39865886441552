import { useDeleteContact, useGetContact } from '@colosseum/data';
import { ContactViewModelType } from '@gladiate/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ResourceSlideover from '../ResourceSlideover/ResourceSlideover';
import { ContactInfoForm, ExpandSections } from '../forms/ContactInfoForm/ContactInfoForm';
import { TabsContent } from '../shadcn/Tabs/Tabs';

/* eslint-disable-next-line */
interface SharedProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDelete?: (arg0?: any) => void;
  expandSections?: ExpandSections;
}

type ConditionalProps =
  | { contact?: ContactViewModelType; contactId?: undefined }
  | { contactId: string; contact?: never };

export function ContactInfoSlideover(props: SharedProps & ConditionalProps) {
  const { caseId } = useParams() as { caseId: string };
  const { open, setOpen, contact, contactId, onDelete, expandSections } = props;
  const [typing, setTyping] = useState(false);
  const deleteContact = useDeleteContact();
  const { data: queriedContact, isLoading: isQueriedContactLoading } = useGetContact(contactId);
  if (!contact && isQueriedContactLoading) {
    <GladiateLoader />;
  }

  const localContactId = contact?.contactId ?? contactId;

  let contactToDisplay;
  if (contact) {
    contactToDisplay = contact;
  } else if (queriedContact && !isQueriedContactLoading) {
    contactToDisplay = queriedContact?.data;
  }

  const handleDeleteContact = () => {
    deleteContact.mutateAsync({ contactId: contact?.contactId ?? contactId ?? '' }).then(() => {
      onDelete?.();
      setOpen(false);
    });
  };

  return (
    <ResourceSlideover
      open={open}
      setOpen={setOpen}
      title={'Edit Contact'}
      description={'Edit contact details'}
      typing={typing}
      deleteFunction={handleDeleteContact}
      displayDeleteButton={true}
      resourceId={contact?.contactId ?? ''}
      createType="contact"
      caseId={caseId}
      hideSections={{
        attachments: true,
        tasks: true,
        feed: true,
      }}
    >
      <TabsContent value="details">
        {contactToDisplay && (
          <ContactInfoForm
            contact={contactToDisplay}
            caseData={{
              caseTitle: '',
              caseId: '',
            }}
            conscriptionId=""
            inSlideover
            expandSections={expandSections}
          />
        )}
      </TabsContent>
    </ResourceSlideover>
  );
}

export default ContactInfoSlideover;
