import { useState } from 'react';
import Slideover from '../Slideover/Slideover';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './insights-impact-table-columns';

export interface InsightsImpactTableProps {
  data: any[];
}

export function InsightsImpactTable(props: InsightsImpactTableProps) {
  const { data } = props;
  // #region Hooks
  // #endregion

  // #region Data Fetching
  // const data = [];
  // #endregion

  // #region State
  // #endregion

  // #region Derived State
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <>
      <DataTable
        isViewOnly
        data={data}
        columns={columns}
        isLoading={false}
        tableName="InsightsImpact"
      />
    </>
  );
}
