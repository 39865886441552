import { useGetNotificationPreferences, useUpdateNotificationPreferences } from '@colosseum/data';
import { GladiateLoader, Toggle, TooltipWrapper } from '@colosseum/shared-ui';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { startCase } from 'lodash';
import { useCallback } from 'react';

/* eslint-disable-next-line */
export interface NotificationPreferencesProps {}

type NotificationTypesType =
  | 'case'
  | 'communication'
  | 'event'
  | 'note'
  | 'settlementProposal'
  | 'task'
  | 'staff'
  | 'trustAccountTransaction'
  | 'mention';

export function NotificationPreferences(props: NotificationPreferencesProps) {
  const { data: notificationPreferencesData, isLoading } = useGetNotificationPreferences();
  const updateNotificationPreferences = useUpdateNotificationPreferences();

  const toggleFeed = useCallback(
    (notificationType: NotificationTypesType, value: boolean) => {
      const notificationUpdate = {
        [`${notificationType}Feed`]: value ? '1' : '0',
      };
      if (!value) {
        const email = notificationPreferencesData?.data?.[`${notificationType}Email`] === '1';
        const sms = notificationPreferencesData?.data?.[`${notificationType}Sms`] === '1';

        if (email) {
          notificationUpdate[`${notificationType}Email`] = '0';
        }
        if (sms) {
          notificationUpdate[`${notificationType}Sms`] = '0';
        }
      }
      updateNotificationPreferences.mutate({
        ...notificationUpdate,
      });
    },
    [updateNotificationPreferences, notificationPreferencesData?.data],
  );

  const toggleEmail = useCallback(
    (notificationType: NotificationTypesType, value: boolean) => {
      const notificationUpdate = {
        [`${notificationType}Email`]: value ? '1' : '0',
      };
      if (value) {
        if (notificationPreferencesData?.data?.[`${notificationType}Feed`] === '0') {
          notificationUpdate[`${notificationType}Feed`] = '1';
        }
      }
      updateNotificationPreferences.mutate({
        ...notificationUpdate,
      });
    },
    [updateNotificationPreferences, notificationPreferencesData?.data],
  );

  const toggleSMS = useCallback(
    (notificationType: NotificationTypesType, value: boolean) => {
      const notificationUpdate = {
        [`${notificationType}Sms`]: value ? '1' : '0',
      };
      if (value) {
        if (notificationPreferencesData?.data?.[`${notificationType}Feed`] === '0') {
          notificationUpdate[`${notificationType}Feed`] = '1';
        }
      }
      updateNotificationPreferences.mutate({
        ...notificationUpdate,
      });
    },
    [updateNotificationPreferences, notificationPreferencesData?.data],
  );

  const notificationTypes = Object.keys(notificationPreferencesData?.data ?? {})
    .filter((key) => {
      return key.includes('Email');
    })
    .map((key) => key.replace('Email', '')) as NotificationTypesType[];

  return (
    <div>
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
            >
              Event Type
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
            >
              Dashboard Feed
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
            >
              <div className="flex gap-x-2">
                Email Notification
                <TooltipWrapper
                  message={
                    'Dashboard feed notifications must be enabled for Email notifications to be enabled'
                  }
                >
                  <QuestionMarkCircleIcon className="self-center w-4 h-4 text-gray-500" />
                </TooltipWrapper>
              </div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
            >
              <div className="flex gap-x-2">
                Text Notification
                <TooltipWrapper
                  message={
                    'Dashboard feed notifications must be enabled for Text notifications to be enabled'
                  }
                >
                  <QuestionMarkCircleIcon className="self-center w-4 h-4 text-gray-500" />
                </TooltipWrapper>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {isLoading && (
            <tr className="border-t border-gray-200">
              <th
                colSpan={5}
                scope="colgroup"
                className="py-2 pl-4 pr-2 text-sm font-semibold text-left text-gray-900 bg-gray-100 sm:pl-6 sm:pr-3"
              >
                <GladiateLoader />
              </th>
            </tr>
          )}
          {notificationTypes.map((notificationType) => (
            <tr key={notificationType} className="border-t border-gray-200">
              <td className="px-3 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                {startCase(notificationType) || '-'}
              </td>
              <td className="px-3 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
                <Toggle
                  newState={notificationPreferencesData?.data?.[`${notificationType}Feed`] === '1'}
                  editable={notificationType !== 'mention'}
                  onToggleChange={(value) => {
                    toggleFeed(notificationType, value);
                  }}
                />
              </td>
              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                <Toggle
                  newState={notificationPreferencesData?.data?.[`${notificationType}Email`] === '1'}
                  editable
                  onToggleChange={(value) => toggleEmail(notificationType, value)}
                />
              </td>

              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                <Toggle
                  newState={notificationPreferencesData?.data?.[`${notificationType}Sms`] === '1'}
                  editable
                  onToggleChange={(value) => {
                    toggleSMS(notificationType, value);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NotificationPreferences;
