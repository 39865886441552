import {
  cn,
  menuNavigation,
  useCreateFirmGroup,
  useDeleteFirmGroup,
  useGetFirmGroupsAndPermissions,
  useUpdateFirmGroup,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  GladiateLoader,
  SectionCollapsible,
  Slideover,
  TabsContent,
  TextFormInput,
  Toggle,
  Typography,
  renderCell,
  renderHeader,
} from '@colosseum/shared-ui';
import { GroupPermission, PAGES_MAP, PermissionType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import UserGroupsPermissionsDropdown from './UserGroupsPermissionsDropdown';

const automationsSections: {
  [key: string]: { label: string; value: keyof PermissionType }[];
} = {
  'One Click Sign': [
    {
      label: 'Access',
      value: 'automationsOneClickSignAccess',
    },
  ],
  'Task Plans': [
    {
      label: 'Access',
      value: 'automationsTaskPlansAccess',
    },
  ],
  'File Repo Structure': [
    {
      label: 'Access',
      value: 'automationsFileRepoStructureAccess',
    },
  ],
  'Litigation Plans': [
    {
      label: 'Access',
      value: 'automationsLitigationPlansAccess',
    },
  ],
  'Firm Surveys': [
    {
      label: 'Access',
      value: 'automationsFirmSurveysAccess',
    },
  ],
};

const caseSections: {
  [key: string]: { label: string; value: keyof PermissionType }[];
} = {
  Summary: [
    {
      label: 'Access',
      value: 'caseSummaryAccess',
    },
  ],
  'Collaboration Hub': [
    {
      label: 'Access',
      value: 'caseCollaborationHubAccess',
    },
  ],
  Calendar: [
    {
      label: 'Access',
      value: 'caseCalendarAccess',
    },
  ],
  Incidents: [
    {
      label: 'Access',
      value: 'caseIncidentAccess',
    },
  ],
  Medical: [
    {
      label: 'Access',
      value: 'caseMedicalsAccess',
    },
  ],
  Accounting: [
    {
      label: 'Access',
      value: 'caseAccountingAccess',
    },
  ],
  Negotiations: [
    {
      label: 'Access',
      value: 'caseNegotiationsAccess',
    },
  ],
  Litigation: [
    {
      label: 'Access',
      value: 'caseLitigationAccess',
    },
  ],
  Closing: [
    {
      label: 'Access',
      value: 'caseClosingAccess',
    },
  ],
  Files: [
    {
      label: 'Access',
      value: 'caseFilesAccess',
    },
  ],
  'Custom Attributes': [
    {
      label: 'Access',
      value: 'caseCustomAttributesAccess',
    },
  ],
};

const settingSections: {
  [key: string]: { label: string; value: keyof PermissionType }[];
} = {
  'User Management': [
    {
      label: 'Access',
      value: 'settingsUserAccess',
    },
  ],
  'Firm Details': [
    {
      label: 'Access',
      value: 'settingsFirmDetailsAccess',
    },
  ],
  'Attorneys Fees': [
    {
      label: 'Access',
      value: 'settingsAttorneysFeesAccess',
    },
  ],
  'Case Defaults': [
    {
      label: 'Access',
      value: 'settingsCaseDefaultsAccess',
    },
  ],
  'Time Tracking': [
    {
      label: 'Access',
      value: 'settingsTimeTrackingAccess',
    },
  ],

  'Medical Treatment Types': [
    {
      label: 'Access',
      value: 'settingsMedicalTreatmentTypesAccess',
    },
  ],
  'Expense Categories': [
    {
      label: 'Access',
      value: 'settingsExpenseCategoriesAccess',
    },
  ],
  'Custom Attributes': [
    {
      label: 'Access',
      value: 'settingsCustomAttributesAccess',
    },
  ],
  'Firm Calendar': [
    {
      label: 'Access',
      value: 'settingsFirmCalendarAccess',
    },
  ],
  'E-Sign': [
    {
      label: 'Access',
      value: 'settingsESignAccess',
    },
  ],
  'Phone Numbers': [
    {
      label: 'Access',
      value: 'settingsPhoneNumbersAccess',
    },
  ],
};

const lineupsSections: {
  [key: string]: { label: string; value: keyof PermissionType }[];
} = {
  Expense: [
    {
      label: 'Access',
      value: 'lineUpFirmCaseExpenses',
    },
  ],
  'Record Request Tasks': [
    {
      label: 'Access',
      value: 'lineUpTasks',
    },
  ],
};

const moreOptions = {
  sectionAutomations: automationsSections,
  sectionCases: caseSections,
  sectionSettings: settingSections,
  sectionLineUps: lineupsSections,
};

const columns: ColumnDef<GroupPermission>[] = [
  {
    accessorFn: (row) => row.groupName,
    id: 'Name',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.groupDescription,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
];

const groupSchema = z.object({
  groupName: z.string().optional(),
  groupDescription: z.string().optional(),
});

export function UserGroupsSection() {
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Partial<GroupPermission> | null>(null);
  const [selectedPermissions, setSelectedPermissions] = useState<Partial<GroupPermission> | null>();

  const {
    groupName,
    dateCreated,
    dateModified,
    groupId,
    firmId,
    groupDescription,
    ...selectedGroupPermissions
  } = selectedGroup || {};

  const { data: firmGroupsData, isLoading: isFirmGroupsLoading } = useGetFirmGroupsAndPermissions();
  const firmGroups = firmGroupsData?.data.filter((group) => group.groupName !== 'CoCounsel');

  const createGroup = useCreateFirmGroup();
  const deleteGroup = useDeleteFirmGroup();
  const updateGroup = useUpdateFirmGroup();

  const groupForm = useForm<z.infer<typeof groupSchema>>({
    resolver: zodResolver(groupSchema),
    mode: 'onBlur',
    values: {
      groupName: groupName,
      groupDescription: groupDescription,
    },
  });

  useEffect(() => {
    if (!open) {
      setSelectedPermissions(undefined);
    }
  }, [open, selectedGroupPermissions]);

  const orderMap = new Map();
  menuNavigation.forEach((item, index) => {
    orderMap.set(item.pageMapKey, index);
  });

  return (
    <>
      <Slideover
        title="Edit Group"
        description="Edit the group name and permissions."
        open={open}
        setOpen={setOpen}
        tabHeaders={[
          {
            value: 'details',
            name: 'Details',
            showBottomButtons: false,
          },
          {
            value: 'sectionAccess',
            name: 'Section Access',
            showBottomButtons: true,
          },
        ]}
        displayDeleteButton
        deleteFunction={() => {
          if (selectedGroup && selectedGroup.groupId) {
            deleteGroup
              .mutateAsync(selectedGroup.groupId)
              .then(() => {
                setOpen(false);
              })
              .catch(() => {
                setOpen(false);
              });
          }
        }}
        submitButton={
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-blue hover:bg-atlantic-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-sky-blue"
            onClick={() => {
              if (selectedGroup) {
                updateGroup.mutate({
                  ...(selectedPermissions ?? {}),
                  groupId: selectedGroup.groupId,
                  groupName: selectedGroup?.groupName,
                  groupDescription: selectedGroup?.groupDescription,
                });
                setOpen(false);
              }
            }}
          >
            Save
          </button>
        }
        warningOnExit={
          !!selectedPermissions ||
          groupForm.getFieldState('groupName').isDirty ||
          groupForm.getFieldState('groupDescription').isDirty
        }
      >
        <div>
          {selectedGroupPermissions && (
            <>
              <TabsContent value="details">
                <Form {...groupForm}>
                  <form>
                    <div className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
                      <div className="col-span-2">
                        <TextFormInput
                          {...groupForm.register('groupName')}
                          handleOnBlur={(e) => {
                            setSelectedGroup({
                              ...selectedGroup,
                              groupName: e.target.value,
                            });
                          }}
                          name="groupName"
                          title="Title"
                        />
                      </div>

                      <div className="col-span-2">
                        <TextFormInput
                          {...groupForm.register('groupDescription')}
                          name="groupDescription"
                          title="Description"
                          type="textarea"
                          handleOnBlur={(e) => {
                            setSelectedGroup({
                              ...selectedGroup,
                              groupDescription: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              </TabsContent>

              <TabsContent value="sectionAccess">
                <div className="space-y-3 ">
                  {Object.keys(selectedGroupPermissions)
                    .sort((a, b) => {
                      const orderA = orderMap.get(a);
                      const orderB = orderMap.get(b);
                      return orderA - orderB;
                    })
                    .map((key) => {
                      if (PAGES_MAP[key as keyof typeof PAGES_MAP] === undefined) {
                        return null;
                      }
                      const toggleState = selectedPermissions?.[
                        key as keyof typeof selectedGroupPermissions
                      ]
                        ? selectedPermissions[key as keyof typeof selectedGroupPermissions] === '1'
                        : selectedGroupPermissions[key as keyof typeof selectedGroupPermissions] ===
                          '1';

                      const item = menuNavigation.find((item) => item.pageMapKey === key) ?? {
                        icon: null,
                      };

                      return (
                        <div key={key} className="px-3 py-2 border border-gray-200 rounded-md">
                          <div className="flex items-center justify-between text-xl ">
                            <div className="flex items-center">
                              <item.icon
                                className={cn(
                                  'mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500',
                                )}
                                aria-hidden="true"
                              />
                              {PAGES_MAP[key as keyof typeof PAGES_MAP]}
                            </div>
                            <Toggle
                              newState={toggleState}
                              editable
                              onToggleChange={(value) => {
                                if (selectedPermissions) {
                                  // Update the selectedPermissions state
                                  setSelectedPermissions({
                                    ...selectedPermissions,
                                    [key]: value ? '1' : '0',
                                  });
                                } else {
                                  // Initialize the selectedPermissions state
                                  setSelectedPermissions({
                                    [key]: value ? '1' : '0',
                                  });
                                }
                              }}
                            />
                          </div>
                          {key in moreOptions &&
                            moreOptions[key as keyof typeof moreOptions] &&
                            toggleState && (
                              <SectionCollapsible
                                title="More options"
                                className="h-auto pl-0 my-1 border-b-0"
                                containerClassName="p-2 ml-[-8px] mt-0 mb-0 w-[110px] hover:bg-accent hover:text-accent-foreground rounded-md"
                                chevronClassName="w-3 h-3"
                                textSize="sm"
                                textVariant="semiBold"
                              >
                                {Object.keys(moreOptions[key as keyof typeof moreOptions]).map(
                                  (sectionKey) => (
                                    <div className="flex items-center pb-2" key={sectionKey}>
                                      <Typography variant="subtext">{sectionKey}</Typography>
                                      <UserGroupsPermissionsDropdown
                                        options={
                                          moreOptions[key as keyof typeof moreOptions][
                                            sectionKey as keyof typeof caseSections
                                          ]
                                        }
                                        selectedPermissions={selectedPermissions}
                                        setSelectedPermissions={setSelectedPermissions}
                                        selectedGroup={selectedGroup}
                                      />
                                    </div>
                                  ),
                                )}
                              </SectionCollapsible>
                            )}
                        </div>
                      );
                    })}
                </div>
              </TabsContent>
            </>
          )}
        </div>
      </Slideover>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">User Groups</h1>
            <p className="mt-2 text-sm text-gray-700">Manage user groups and permissions.</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title="Add Group"
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-blue hover:bg-atlantic-blue focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                createGroup.mutate({
                  groupName: 'New Group',
                });
              }}
              loading={createGroup.isLoading}
            />
          </div>
        </div>
        {isFirmGroupsLoading ? (
          <div>
            <GladiateLoader height={150} />
          </div>
        ) : (
          <DataTable
            tableName="User Groups"
            handleRowClick={(row) => {
              const data = row.original;

              const { id, name, ...rest } = data;

              setSelectedGroup(rest);
              setOpen(true);
            }}
            data={
              firmGroups?.map((group) => ({
                ...group,
                id: group.groupId,
                name: group.groupName,
              })) ?? []
            }
            columns={columns}
            filters={[]}
            hideViewButton
          />
        )}
      </div>
    </>
  );
}

export default UserGroupsSection;
