export const attributeTypeOptions = [
  'boolean',
  'caseSelect',
  'contactSelect',
  'currency',
  'date',
  'dropdown',
  'email',
  'multiSelect',
  'number',
  'phone',
  'text',
  'textarea',
  'url',
  'userSelect',
] as const;
type attributeTypeOptionsType = (typeof attributeTypeOptions)[number];
export interface CustomAttributeType {
  firmId: string;
  customAttributeId: string;
  dateCreated: string;
  dateModified?: string;
  attributeLabel?: string;
  attributeResource?: string;
  attributeType?: attributeTypeOptionsType;
  customAttributeOptions?: CustomAttributeOptionType[];
}

export interface CustomAttributeValueType {
  firmId: string;
  customAttributeValueId: string;
  dateCreated: string;
  dateModified?: string;

  customAttributeId: string;
  attributeSingleValue?: string;
  attributeMultiValue?: string[];
}

export interface CustomAttributeOptionType {
  customAttributeOptionId: string;
  customOptionValue: string;
  dateCreated: string;
  dateModified?: string;
  firmId: string;
}
