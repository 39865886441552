import {
  enqueueAPISnackbar,
  getS3FileUploadLink,
  useDeleteTemplate,
  useGetTemplate,
  useUpdateTemplate,
  useUploadDocumentToS3,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  Button,
  CheckboxFormInput,
  CustomErrorBoundary,
  FilePreview,
  Form,
  GladiateLoader,
  ResourceNotes,
  ResourceTags,
  SectionCollapsible,
  SectionContainer,
  Switch,
  TextFormInput,
  TypingDots,
} from '@colosseum/shared-ui';
import { FileResourceType } from '@gladiate/types';
import { ArrowLeftIcon, CheckIcon, DocumentPlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { withErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  sign: z.boolean().optional(),
  senderSignatureRequired: z.boolean().optional(),
  defaultMessage: z.string().optional(),
  defaultSubjectContinued: z.string().optional(),
});

/* eslint-disable-next-line */
export interface TemplateFormProps {}

function DragDropFile() {
  const queryClient = useQueryClient();
  const { templateId } = useParams() as { templateId: string };
  const uploadDocument = useUploadDocumentToS3();
  const [fileExists, setFileExists] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [fileToPreview, setFileToPreview] = useState<FileResourceType | undefined>();
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<any>(null);

  const templateQuery = useGetTemplate(templateId);
  const updateTemplate = useUpdateTemplate();
  const templates = templateQuery.data?.data;
  const templateDetails = templates;

  useEffect(() => {
    if (templateDetails?.documentId) {
      setFileExists(true);
    } else {
      setFileExists(false);
    }
  }, [templateDetails]);

  async function uploadFile(file: File) {
    const docxMimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (file.type !== docxMimeType) {
      enqueueSnackbar('Invalid file type. Only .docx files are supported.', {
        variant: 'error',
      });
      return;
    }

    setUploadLoading(true);
    const ext = file.name.split('.').pop();
    const data = await getS3FileUploadLink(ext ?? 'pdf');
    uploadDocument
      .mutateAsync({
        url: data.data?.url,
        rawFile: file,
      })
      .then((res) => {
        if (res.status === 200) {
          const update = {
            templateId: templateId,
            fileName: file.name,
            documentId: data.data?.objKey,
          };
          updateTemplate
            .mutateAsync(update)
            .then(() => {
              setUploadLoading(false);
              setFileExists(true);
            })
            .catch(() => {
              setUploadLoading(false);
            });
        }
      })
      .catch(() => {
        // If there is an error uploading the file, we should set the loading state to false
        setUploadLoading(false);
      });
  }

  // handle drag events
  const handleDrag = function <T>(e: React.DragEvent<T>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function <T>(e: React.DragEvent<T>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files[0]).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['templates'],
        });
        templateQuery.refetch();
      });
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      uploadFile(e.target.files[0]).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['templates'],
        });
        templateQuery.refetch();
      });
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <FilePreview
        isOpen={showFilePreview}
        setOpen={setShowFilePreview}
        filePreviewItem={fileToPreview}
      />
      <div>
        {fileExists ? (
          <div className="flex w-full mt-2 ">
            <div className="flex items-center pl-1 font-semibold">
              Attached Document:
              <div className="flex items-center justify-between p-1 px-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue ">
                <p
                  onClick={() => {
                    if (!templateDetails) return;
                    setFileToPreview({
                      name: templateDetails.fileName,
                      s3ObjKey: templateDetails.documentId,
                    });
                    setShowFilePreview(true);
                  }}
                  className="cursor-pointer hover:underline hover:text-black"
                >
                  {templateDetails?.fileName}
                </p>
                <XMarkIcon
                  onClick={() => {
                    if (!templateDetails || !templateDetails?.documentId) return;

                    const update = {
                      templateId: templateId,
                      fileName: null as unknown as string,
                      documentId: null as unknown as string,
                    };

                    updateTemplate
                      .mutateAsync(update)
                      .then(() => {
                        if (!templateDetails || !templateDetails?.documentId) return;

                        setFileExists(false);
                      })
                      .catch(() => {
                        enqueueAPISnackbar({
                          message: 'Error deleting file',
                          variant: 'error',
                          key: 'template-deleting-file-error',
                        });
                      });
                  }}
                  className="w-5 h-5 ml-2 cursor-pointer hover:text-black"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {uploadLoading ? (
              <div className="flex w-full h-full mt-2 border border-dashed rounded-lg cursor-not-allowed border-sky-blue bg-blue-50">
                <div className="py-40 mx-auto">
                  <GladiateLoader height={190} />
                </div>
              </div>
            ) : (
              <form
                id="form-file-upload"
                className="flex w-full h-full mt-2 border border-dashed rounded-lg border-sky-blue bg-blue-50"
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop} // Add this line
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="w-full h-full m-auto">
                  <input
                    ref={inputRef}
                    type="file"
                    className="hidden"
                    multiple={true}
                    onChange={handleChange}
                    accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <label
                    id="label-file-upload"
                    className={
                      'flex items-center justify-center h-full w-full py-40' +
                      (dragActive ? ' bg-light-blue rounded-lg' : '')
                    }
                    htmlFor="input-file-upload"
                  >
                    <div className="text-center">
                      <div className="flex items-center w-full">
                        <div className="mx-auto">
                          <DocumentPlusIcon className="w-20 h-20 text-gray-400" />
                        </div>
                      </div>

                      <p className="mt-5 text-3xl">
                        Drag and drop your file, or{' '}
                        <Button
                          size="unset"
                          variant="link"
                          className="text-3xl underline "
                          onClick={onButtonClick}
                        >
                          Browse
                        </Button>
                      </p>
                      <p className="mt-5 text-gray-400">
                        Supports Microsoft Word documents (.doc, .docx)
                      </p>
                    </div>
                  </label>
                  {dragActive && (
                    <div
                      className="absolute top-0 bottom-0 left-0 right-0 w-full h-full rounded-lg"
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export function TemplateForm() {
  const { templateId } = useParams() as { templateId: string };
  const navigate = useNavigate();
  const updateTemplate = useUpdateTemplate();
  const [saved, setSaved] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);

  const templateQuery = useGetTemplate(templateId);
  const deleteTemplate = useDeleteTemplate();
  const templates = templateQuery.data?.data;

  const templateDetails = templates;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      title: templateDetails?.title,
      description: templateDetails?.description,
      sign: templateDetails?.sign,
      senderSignatureRequired: templateDetails?.senderSignatureRequired,
      defaultMessage: templateDetails?.defaultMessage,
      defaultSubjectContinued: templateDetails?.defaultSubjectContinued,
    },
    mode: 'onBlur',
  });

  const isSectionEditable =
    templateDetails?.title !== undefined ||
    templateDetails?.description !== undefined ||
    templateDetails?.documentId !== undefined;

  //temp variables
  const [sectionEditable, setSectionEditable] = useState(!isSectionEditable);

  function handleDelete() {
    deleteTemplate.mutate(templateId);
    navigate('/templates');
  }

  //TODO this is going to be replace with the actual figma designs so I didn't want to make a separate component for it
  return (
    <div>
      <Helmet defer={false}>
        <title>
          {templateQuery.isLoading
            ? 'Template'
            : `Template | ${templateQuery.data?.data.title ?? 'No Title'}`}
        </title>
      </Helmet>
      <div className="flex items-center justify-between ">
        <div className="flex col-span-2 pb-5 ">
          <Button
            variant="ghost"
            hoverVariant="lightBlue"
            onClick={() => navigate('/templates')}
            className="inline-block p-2 mr-4 fadeAnimation"
          >
            <ArrowLeftIcon className="w-5 h-5" />
          </Button>
          <h1 className="ml-1 text-3xl font-semibold">Template Details</h1>
          {sectionEditable ? (
            saved ? (
              <CheckIcon className="self-center w-5 h-5 ml-2 text-green-500" />
            ) : (
              <TypingDots />
            )
          ) : null}
        </div>
      </div>
      <div className="-mt-5">
        <SectionContainer>
          <div>
            {!templateQuery.isLoading ? (
              <>
                {/* working on putting this in the SectionContainer component */}
                <div className="w-full pb-5 align-middle ">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">{/* get rid of negative margin */}</div>

                    {/* This isn't being used anymore */}
                    {/* {sectionEditable ? (
                        // eslint-disable-next-line react/jsx-no-useless-fragment

                        <div className="flex rounded-full cursor-pointer">
                          <LockOpenIcon
                            onClick={() => setSectionEditable(!sectionEditable)}
                            className="w-6 h-6"
                          />
                        </div>
                      ) : (
                        <div className="flex cursor-pointer">
                          <LockClosedIcon
                            onClick={() => setSectionEditable(!sectionEditable)}
                            className="w-6 h-6"
                          />
                        </div>
                      )} */}
                  </div>
                </div>
                <Form {...form}>
                  <form>
                    <div className="grid grid-cols-2 pb-2 gap-y-5 gap-x-3">
                      <TextFormInput
                        handleOnBlur={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          updateTemplate.mutate({
                            title: target.value,
                            templateId,
                          });
                        }}
                        title="Title"
                        type="text"
                        {...form.register('title')}
                      />
                      <div className="col-span-2">
                        <TextFormInput
                          handleOnBlur={(e: React.SyntheticEvent) => {
                            const target = e.target as HTMLInputElement;
                            updateTemplate.mutate({
                              description: target.value,
                              templateId,
                            });
                          }}
                          title="Description"
                          type="textarea"
                          {...form.register('description')}
                        />
                      </div>
                      <Switch
                        fullRow
                        title="Send for signature?"
                        value={templateDetails?.sign ?? false}
                        handleChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          updateTemplate.mutate({
                            sign: !!target.value,
                            templateId,
                          });
                        }}
                        {...form.register('senderSignatureRequired')}
                      />
                      {templateDetails?.sign && (
                        <>
                          <CheckboxFormInput
                            {...form.register('senderSignatureRequired')}
                            name={'senderSignatureRequired'}
                            title={'Sender Signature Required'}
                            handleOnChange={(e: React.SyntheticEvent) => {
                              const target = e.target as HTMLInputElement;
                              updateTemplate.mutate({
                                senderSignatureRequired: !!target.value,
                                templateId,
                              });
                            }}
                          />
                          <TextFormInput
                            handleOnBlur={(e: React.SyntheticEvent) => {
                              const target = e.target as HTMLInputElement;
                              updateTemplate.mutate({
                                defaultSubjectContinued: target.value,
                                templateId,
                              });
                            }}
                            title="Default Subject Continued"
                            type="text"
                            {...form.register('defaultSubjectContinued')}
                          />
                          <div className="col-span-2">
                            <TextFormInput
                              handleOnBlur={(e: React.SyntheticEvent) => {
                                const target = e.target as HTMLInputElement;
                                updateTemplate.mutate({
                                  defaultMessage: target.value,
                                  templateId,
                                });
                              }}
                              title="Default Message"
                              type="textarea"
                              {...form.register('defaultMessage')}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                </Form>
                <DragDropFile />
                <div className="px-2 mt-5">
                  <div className="flex pt-5 border-t border-t-gray-300">
                    <h3 className="mr-6 text-lg font-semibold">Tags</h3>
                    <ResourceTags resourceId={templateId} resourceType="template" />
                  </div>
                </div>
                <SectionCollapsible title="Notes" defaultOpen>
                  <ResourceNotes createType="template" resourceId={templateId ?? ''} />
                </SectionCollapsible>
              </>
            ) : (
              <div className="flex flex-col justify-center h-screen bg-white">
                <GladiateLoader height={150} />
              </div>
            )}
          </div>
        </SectionContainer>
      </div>

      <div className="float-right pb-10 mt-5">
        <button
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className="px-10 py-4 text-right text-white bg-red-600 rounded-md"
        >
          Delete
        </button>
      </div>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={handleDelete}
      />
    </div>
  );
}

export default withErrorBoundary(TemplateForm, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
