import { useGetAgreementsForCaseV3 } from '@colosseum/data';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ResourceSlideover from '../ResourceSlideover/ResourceSlideover';
import { SlideoverContext } from '../SlideoverProvider';
import { TabsContent } from '../shadcn/Tabs/Tabs';
import { DataTable } from '../shadcn/data-table/DataTable';
import { AgreementStatusForm } from './AgreementStatusForm';
import { columns } from './agreement-status-table-columns';

export function AgreementStatusTable() {
  const { caseId } = useParams() as { caseId: string };
  const { data, isLoading, isError } = useGetAgreementsForCaseV3(caseId);
  const [open, setOpen] = useState(false);
  const [activeAgreementStatusId, setActiveAgreementStatusId] = useState<string>();
  const activeAgreementStatus = data?.data.find(
    (agreementStatus) => agreementStatus.agreementId === activeAgreementStatusId,
  );

  const { pendingSlideoverToOpen } = useContext(SlideoverContext);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'agreement') {
      setActiveAgreementStatusId(pendingSlideoverToOpen.id);
      setOpen(true);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <>
      <ResourceSlideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        title="Agreement Status"
        description="The agreement status table displays the status of all agreements associated with this case."
        caseId={caseId}
        createType="agreement"
        resourceId={activeAgreementStatus?.agreementId ?? 'cant-find-id'}
      >
        <TabsContent value="details">
          <AgreementStatusForm agreementStatus={activeAgreementStatus} />
        </TabsContent>
      </ResourceSlideover>
      <DataTable
        data={data?.data || []}
        showSearchBar
        initialSort={{
          id: 'Date Modified',
          desc: true,
        }}
        handleRowClick={(row) => {
          setActiveAgreementStatusId(row.original.agreementId);
          setOpen(true);
        }}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        tableName="agreement-status"
      />
    </>
  );
}

export default AgreementStatusTable;
