import {
  updateAudienceV3,
  useCreateAudience,
  useURLParamsAsObject,
  useUpdateAudience,
} from '@colosseum/data';
import { CreateButton, Form, Slideover, TextFormInput, Typography } from '@colosseum/shared-ui';
import { AudienceType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { startCase } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import PresetFilterChip from '../../cases/PresetFilterChip/PresetFilterChip';

/* eslint-disable-next-line */
export interface AudienceFormSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  audienceToEdit?: AudienceType;
  setAudienceToEdit?: Dispatch<SetStateAction<AudienceType | undefined>>;
  setActiveAudience: Dispatch<SetStateAction<AudienceType | undefined>>;
}

const audienceFormSchema = z.object({
  title: z.string().trim().min(1, "This field can't be blank"),
  description: z.string().optional(),
});

export function AudienceFormSlideover(props: AudienceFormSlideoverProps) {
  const { open, setOpen, audienceToEdit, setAudienceToEdit, setActiveAudience } = props;

  const createAudience = useCreateAudience();
  const updateAudience = useUpdateAudience();

  const queryParamsObject = useURLParamsAsObject();
  const audienceForm = useForm<z.infer<typeof audienceFormSchema>>({
    resolver: zodResolver(audienceFormSchema),
    values: {
      title: audienceToEdit?.title ?? '',
      description: audienceToEdit?.description ?? '',
    },
  });

  const createAudienceHandler = (data: AudienceType) => {
    const audience = {
      title: data.title,
      description: data.description,
      ...queryParamsObject,
    };

    createAudience.mutateAsync(audience).then((res) => {
      audienceForm.reset();
      setActiveAudience(res.data);
      setOpen(false);
    });
  };

  const updateAudienceHandler = (data: Parameters<typeof updateAudienceV3>[0]) => {
    const audience = {
      title: data.title,
      description: data.description,
      ...queryParamsObject,
    };

    updateAudience
      .mutateAsync({
        ...audience,
        audienceId: audienceToEdit?.audienceId,
      })
      .then((res) => {
        audienceForm.reset();
        setActiveAudience(res.data);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (!open) {
      audienceForm.reset();
      setAudienceToEdit?.(undefined);
    }
  }, [open, audienceForm, setAudienceToEdit]);

  return (
    <div>
      <Slideover
        title={audienceToEdit ? `Edit ${audienceToEdit.title}` : 'Create new Audience'}
        description={audienceToEdit ? 'Update an existing audience.' : 'Create a new audience.'}
        open={open}
        setOpen={setOpen}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5 border-t border-t-gray-200">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            {audienceToEdit ? (
              <CreateButton
                title={'Update Audience'}
                loading={updateAudience.isLoading}
                onClick={audienceForm.handleSubmit(updateAudienceHandler)}
              />
            ) : (
              <CreateButton
                title={'Create Audience'}
                loading={createAudience.isLoading}
                onClick={audienceForm.handleSubmit(createAudienceHandler)}
              />
            )}
          </div>
        }
      >
        <Form {...audienceForm}>
          <form
            onSubmit={
              audienceToEdit
                ? audienceForm.handleSubmit(updateAudienceHandler)
                : audienceForm.handleSubmit(createAudienceHandler)
            }
          >
            <div className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
              <div className="col-span-2">
                <TextFormInput
                  name="title"
                  title="Title"
                  dataCy="text-input-title-slideover"
                  maxLength={200}
                />
              </div>
              <div className="col-span-2">
                <TextFormInput
                  name="description"
                  title="Description"
                  type="textarea"
                  dataCy="text-input-description-slideover"
                  maxLength={400}
                />
              </div>
            </div>
          </form>
        </Form>
        <Typography variant="heading" className="pt-2">
          Summary
        </Typography>
        <div>
          {Object.keys(queryParamsObject).map((key) => (
            <div key={key} className="mt-4 ">
              <Typography variant="grayUpper">{startCase(key)}</Typography>
              <div className="flex flex-wrap mt-2 gap-x-2 gap-y-4">
                {typeof queryParamsObject[key] === 'string' ? (
                  <PresetFilterChip title={queryParamsObject[key] as string} filterName={key} />
                ) : (
                  (queryParamsObject[key] as string[])?.map((keyArrayItem) => (
                    <PresetFilterChip key={keyArrayItem} title={keyArrayItem} filterName={key} />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </Slideover>
    </div>
  );
}

export default AudienceFormSlideover;
