import {
  useDeleteContactCampaign,
  useGetContactCampaign,
  useGetContactCampaignSubscriptions,
  useGetTags,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  CardSection,
  Chip,
  ContactCampaignTriggersTable,
  GladiateLoader,
  Typography,
} from '@colosseum/shared-ui';
import { CampaignStepOptions, ContactCampaign } from '@gladiate/types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import EditButton from './EditButton/EditButton';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import ContactSubscriptionTable from '../SubscriptionTables/ContactSubscriptionTable';

/* eslint-disable-next-line */
export interface ContactSummaryPageProps {
  campaignData?: ContactCampaign;
}

const statusColors: {
  draft: 'yellow';
  active: 'green';
  past: 'blue';
  paused: 'stone';
} = {
  draft: 'yellow',
  active: 'green',
  past: 'blue',
  paused: 'stone',
};

export function ContactSummaryPage(props: ContactSummaryPageProps) {
  // #region Hooks
  const deleteCaseCampaign = useDeleteContactCampaign();
  const navigate = useNavigate();
  const { campaignId: contactCampaignId } = useParams() as {
    campaignId: string;
  };

  // #endregion

  // #region Data Fetching
  const {
    data,
    isLoading: isContactCampaignLoading,
    isFetching: isContactCampaignFetching,
  } = useGetContactCampaign(contactCampaignId);
  const { data: tagsData } = useGetTags();
  // #endregion

  // #region State
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // #endregion

  // #region Derived State
  const campaignData = data?.data;

  const selectedTags = campaignData?.inclusionaryTags
    ?.map((tag) => {
      return tagsData?.data?.find((ct) => tag === ct.tagId);
    })
    .filter(Boolean);
  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <>
      <div className="flex col-span-2 pb-5 ">
        <button
          onClick={() => navigate('/campaigns')}
          className="inline-block p-1 mr-4 text-black rounded-lg cursor-pointer fadeAnimation hover:text-atlantic-blue hover:bg-light-blue"
        >
          <ArrowLeftIcon className="w-5 h-5 " />
        </button>
        <h1 className="ml-1 text-3xl font-semibold">Summary</h1>
      </div>

      <div className="flex flex-col gap-5">
        <CardSection cardHeading="Campaign Message Criteria">
          <div className="flex flex-col items-center gap-2 ">
            {(isContactCampaignLoading || isContactCampaignFetching) && <GladiateLoader />}
            <div className="flex gap-2 ">
              {campaignData?.title || campaignData?.title !== '' ? (
                <>
                  <Typography size="3xl" variant="heading" color="black">
                    {campaignData?.title}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography size="3xl" variant="heading" color="gray">
                    {`<no title>`}
                  </Typography>{' '}
                </>
              )}
              <EditButton
                route={`/campaigns/${campaignData?.contactCampaignId}/contact/${CampaignStepOptions.criteria}`}
              />
            </div>
            <div className="px-10 font-thin text-md">{campaignData?.description}</div>
            <div
              className="flex items-center gap-1 text-sm font-semibold up group hover:cursor-pointer"
              onClick={() =>
                navigate(
                  `/campaigns/${campaignData?.contactCampaignId}/contact/${CampaignStepOptions.criteria}`,
                )
              }
            >
              Campaign Status:
              {['draft', 'active', 'past', 'paused'].includes(campaignData?.status ?? '') && (
                <Chip
                  name={campaignData?.status}
                  color={statusColors[campaignData?.status ?? 'past']}
                  className="group-hover:cursor-pointer"
                />
              )}
            </div>
            <div className="flex flex-wrap justify-center py-2 pb-4 gap-x-2 gap-y-2 w-96">
              {selectedTags?.map((item) => {
                return (
                  <div className="p-2 text-xs border border-gray-300 rounded-md" key={item?.tagId}>
                    {item?.title}
                  </div>
                );
              })}
            </div>
          </div>
        </CardSection>

        <CardSection>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <Typography size="xl" variant="heading" color="black">
                Campaign Messages Triggers
              </Typography>
              <EditButton
                route={`/campaigns/${campaignData?.contactCampaignId}/contact/${CampaignStepOptions.stages}`}
              />
            </div>
            <Typography variant="subtext" color="black">
              These messages will be sent to the client based on the tags associated with the
              contacts.
            </Typography>
          </div>
          {campaignData && (
            <ContactCampaignTriggersTable
              campaignData={campaignData}
              viewOnly={true}
              loading={isContactCampaignLoading || isContactCampaignFetching}
            />
          )}
        </CardSection>
        <CardSection>
          <div className="flex gap-2">
            <Typography size="xl" variant="heading" color="black">
              Contact Campaign Subscriptions
            </Typography>
            <EditButton
              route={`/campaigns/${campaignData?.contactCampaignId}/contact/${CampaignStepOptions.subscription}`}
            />
          </div>
          <Typography variant="subtext" color="black" className="pb-4">
            All contacts associated with the tags specified above will be automatically added to
            this campaign.
          </Typography>
          <ContactSubscriptionTable contactCampaignId={contactCampaignId} isViewOnly={true} />
        </CardSection>
        <div className="flex justify-end">
          <button
            onClick={() => {
              setShowDeleteModal(true);
            }}
            className="px-10 py-4 text-right text-white bg-red-600 rounded-md"
            data-cy="delete-contact-button"
          >
            Delete
          </button>
        </div>
      </div>

      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={() =>
          deleteCaseCampaign.mutateAsync(campaignData?.contactCampaignId ?? '').then(() => {
            navigate(`/campaigns`);
            setShowDeleteModal(false);
          })
        }
        title="Delete Campaign"
      />
    </>
  );
}

export default ContactSummaryPage;
