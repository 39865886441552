import { DefaultV3Response, TemplateType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseExemplarRoute = `exemplar/${API_STAGE}`;

// GET list_templates_v3
export const getTemplatesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TemplateType[]>>(`${baseExemplarRoute}/v3/templates`)
    .then((res) => res.data);

// GET get_template_v3
export const getTemplateV3 = (templateId: string) =>
  axiosInstance
    .get<DefaultV3Response<TemplateType>>(`${baseExemplarRoute}/v3/templates/${templateId}`)
    .then((res) => res.data);

// PATCH update_template_v3
export const updateTemplateV3 = (data: Partial<TemplateType> & { templateId: string }) => {
  const { templateId, ...rest } = data;
  return axiosInstance.patch<DefaultV3Response<TemplateType>>(
    `${baseExemplarRoute}/v3/templates/${templateId}`,
    rest,
  );
};

// POST create_template_v3
export const createTemplateV3 = (data: Partial<TemplateType>) =>
  axiosInstance.post<DefaultV3Response<TemplateType>>(`${baseExemplarRoute}/v3/templates`, data);

// DELETE delete_template_v3
export const deleteTemplateV3 = (templateId: string) =>
  axiosInstance.delete<DefaultV3Response<TemplateType>>(
    `${baseExemplarRoute}/v3/templates/${templateId}`,
  );
