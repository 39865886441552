import { cn } from '@colosseum/data';
import * as React from 'react';
import { TagUserPopover } from './TagUserPopover';

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
    allowUserTags?: boolean;
  }
>(({ className, allowUserTags, ...props }, ref) => {
  return (
    <>
      <textarea
        ref={ref}
        {...props}
        className={cn(
          'block w-full rounded-md form-textarea border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue sm:text-sm sm:leading-6',
          className,
        )}
      />

      {allowUserTags && (
        <TagUserPopover
          // eslint-disable-next-line react/prop-types
          message={props.value?.toString() ?? ''}
          setMessage={
            // eslint-disable-next-line react/prop-types
            props.onChange as React.ChangeEventHandler<HTMLTextAreaElement>
          }
          cursorRef={ref}
        />
      )}
    </>
  );
});
Textarea.displayName = 'Textarea';

export { Textarea };
