/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '../shadcn/Command/Command';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { startCase } from 'lodash';
import { cn } from '@colosseum/data';
import { TooltipWrapper } from '../data-display/TooltipWrapper/TooltipWrapper';
import Typography from '../Typography/Typography';

/* eslint-disable-next-line */
interface SharedProps {
  selectedValues: React.ComponentState;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  title: string;
  searchPlaceholder?: string;
  hideSearch?: boolean;
  hideClearButton?: boolean;
  tooltipMessage?: string;
}

interface ListItemsIsObjectProps {
  listItemsIsObject: true;
  listItems: {
    [key: string]: string;
  };
}

interface ListItemsIsStringArrayProps {
  listItemsIsObject?: false;
  listItems: string[];
}

type SelectComboboxProps = SharedProps & (ListItemsIsObjectProps | ListItemsIsStringArrayProps);

export function SelectCombobox({
  selectedValues,
  setSelectedValues,
  listItems,
  title,
  hideSearch = false,
  hideClearButton = false,
  searchPlaceholder = 'Search ...',
  listItemsIsObject = false,
  tooltipMessage,
}: SelectComboboxProps) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<string[]>(selectedValues);

  function buttonText(): string {
    if (selectedValues.length === 0) {
      return `Select an option...`;
    } else if (selectedValues.length === 1 && selectedValues[0] === '') {
      return `Select an option...`;
    } else if (selectedValues.length > 2) {
      return `${selectedValues.length} selected`;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return selectedValues
        .map((value: string) => {
          return listItemsIsObject
            ? startCase(
                Object.keys(listItems).find((key: string) => {
                  if (!Array.isArray(listItems) && listItems[key] === value) {
                    return key;
                  }
                }),
              )
            : startCase(value);
        })
        .join(', ');
    }
  }

  function handleClearOrSelectAll() {
    if (values.length > 0) {
      setSelectedValues([]);
    } else {
      setSelectedValues(
        // @ts-ignore @typescript-eslint/no-unsafe-return
        Object.keys(listItems).map((option) => listItems[option]),
      );
      setValues(selectedValues);
    }
  }

  function handleSelection(value: string) {
    const valueToAddRemove =
      listItemsIsObject && !Array.isArray(listItems) ? listItems[value] : value;
    setSelectedValues((prev: string[]) => {
      if (prev.includes(valueToAddRemove)) {
        return prev.filter((item: string) => item !== valueToAddRemove);
      }
      return [...prev, valueToAddRemove];
    });
  }

  useEffect(() => {
    setValues(selectedValues);
  }, [selectedValues]);

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <div className="relative">
          <div className="absolute flex text-gray-400 truncate bg-white left-2 -top-2">
            <span className="px-1 text-xs ">{startCase(title)}</span>
            {tooltipMessage && (
              <div className="pr-1">
                <TooltipWrapper message={tooltipMessage}>
                  <QuestionMarkCircleIcon className="self-center w-3 h-3 text-gray-500" />
                </TooltipWrapper>
              </div>
            )}
          </div>
          <button
            aria-expanded={open}
            type="button"
            className="flex items-center justify-between w-full px-3 py-2 text-sm font-medium text-left text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm text-nowrap hover:bg-gray-50 focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          >
            <span className="truncate">{buttonText()}</span>
            <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </button>
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-full p-0 overflow-y-auto max-h-96 min-w-[200px]">
        <Command className="rounded-md shadow-md ">
          {!hideSearch && <CommandInput placeholder={searchPlaceholder} />}
          <CommandList>
            {!hideSearch && <CommandEmpty>No results found.</CommandEmpty>}
            <CommandGroup>
              {!hideClearButton && (
                <CommandItem
                  onSelect={handleClearOrSelectAll}
                  className="justify-center mb-1 text-center"
                >
                  {values.length > 0 ? (
                    <Typography variant="grayUpper">Clear Selection</Typography>
                  ) : (
                    <Typography variant="grayUpper">Select All</Typography>
                  )}
                </CommandItem>
              )}
              <CommandSeparator className="mb-1 h-[0.5px]" />
              {Object.keys(listItems).map((key: number | string) => {
                // @ts-ignore @ts-expect-error types are fine
                const item = listItemsIsObject ? key : listItems[key as number];
                return (
                  <>
                    <CommandItem key={item} onSelect={handleSelection} className="max-w-[600px]">
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4 flex-shrink-0',
                          selectedValues.includes(
                            listItemsIsObject
                              ? // @ts-ignore @ts-expect-error types are fine
                                listItems[item as unknown as number]
                              : item,
                          )
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                      <span className=" line-clamp-2">{startCase(item as string)}</span>
                    </CommandItem>
                  </>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default SelectCombobox;
