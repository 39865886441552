/* eslint-disable-next-line */
export interface SectionContainerProps {
  children: React.ReactNode;
  title?: string;
  button?: React.ReactNode;
  saved?: boolean;
}

export function SectionContainer(props: SectionContainerProps) {
  return (
    <div className="w-full mt-5 bg-white border shadow-sm rounded-xl p-7 ">{props.children}</div>
  );
}

export default SectionContainer;
