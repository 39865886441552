import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { FileResourceType, TemplateType, TemplateVariables } from '@gladiate/types';
import { useCreateFileItemForCase, useGenerateDocumentWithData } from '@colosseum/data';
import { Slideover } from '../Slideover/Slideover';
import { DocGenerationForm } from '../DocGenerationForm/DocGenerationForm';
import CreateButton from '../CreateButton/CreateButton';

interface DocumentGenerationSlideoverProps {
  caseId: string;
  currentDirectoryId: string;
  isOpen: boolean;
  onClose: () => void;
  onDocumentGenerated: (file: FileResourceType) => void;
}

export const DocumentGenerationSlideover: React.FC<DocumentGenerationSlideoverProps> = ({
  caseId,
  currentDirectoryId,
  isOpen,
  onClose,
  onDocumentGenerated,
}) => {
  const [docGenPayload, setDocGenPayload] = useState<TemplateVariables>({});
  const [docGenReady, setDocGenReady] = useState(false);
  const [docGenLoading, setDocGenLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType>({} as TemplateType);
  const [, setSelectedInsurancePolicy] = useState('');

  const generateDocumentMutation = useGenerateDocumentWithData();
  const createFileItem = useCreateFileItemForCase(caseId);

  const generateDocument = async (fileId: string, templateTitle: string, outputFormat: string) => {
    setDocGenLoading(true);

    try {
      const result = await generateDocumentMutation.mutateAsync({
        fileId,
        outputFormat,
        ...docGenPayload,
      });

      if (result && result.data?.status === 'SUCCESS' && result.data?.genDocKey) {
        const newFile = await createFileItem.mutateAsync({
          name: `${templateTitle}.${outputFormat}`,
          currentDirectory: currentDirectoryId,
          resourceType: 'FILE',
          s3ObjKey: result.data.genDocKey,
        });

        if (newFile?.data?.data) {
          onDocumentGenerated(newFile.data.data);
          enqueueSnackbar('Document generated successfully', { variant: 'success' });
        }
      } else {
        throw new Error('Document generation failed');
      }
    } catch (error) {
      enqueueSnackbar('Error generating document. Please try again.', { variant: 'error' });
    } finally {
      setDocGenLoading(false);
      onClose();
    }
  };

  return (
    <Slideover
      open={isOpen}
      setOpen={onClose}
      title="Document Generation"
      description="Generate a document from a template."
      typing={false}
      displayDeleteButton={false}
      bottomButtons={
        <div className="flex justify-between w-full px-4 py-4 border-t border-t-gray-200">
          <CreateButton
            title="Generate PDF"
            loading={docGenLoading}
            disabled={!docGenReady}
            onClick={() =>
              void generateDocument(
                selectedTemplate.documentId || '',
                selectedTemplate.title || '',
                'pdf',
              )
            }
          />
          <CreateButton
            title="Generate Docx"
            loading={docGenLoading}
            disabled={!docGenReady}
            onClick={() =>
              void generateDocument(
                selectedTemplate.documentId || '',
                selectedTemplate.title || '',
                'docx',
              )
            }
          />
        </div>
      }
    >
      <DocGenerationForm
        caseId={caseId}
        setSelectedTemplate={setSelectedTemplate}
        setSelectedInsurancePolicy={setSelectedInsurancePolicy}
        setDocGenPayload={setDocGenPayload}
        setDocGenReady={setDocGenReady}
      />
    </Slideover>
  );
};
