import {
  URL_REGEX,
  cn,
  convertToE164,
  formatDate,
  formatPhoneNumber,
  getFullFileId,
  useGetParallelFiles,
  useGetProvisionedPhoneNumbers,
  useSendMessage,
  useUpdateCaseData,
} from '@colosseum/data';
import {
  CommunicationType,
  ContactNumberType,
  ContactType,
  FileResourceType,
  MessageType,
} from '@gladiate/types';
import { PaperAirplaneIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PaperClipIcon } from '@heroicons/react/24/solid';
import DOMPurify from 'dompurify';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import FileAttachmentModal from '../FileAttachmentModal/FileAttachmentModal';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Slideover from '../Slideover/Slideover';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

import SnippetTool from '../SnippetTool/SnippetTool';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';

/* eslint-disable-next-line */
export interface MessengerProps {
  caseId?: string;
  toPhoneNumbers:
    | {
        name: string;
        role: string;
        phoneNumbers: ContactNumberType[];
      }[]
    | undefined;
  contact: ContactType | undefined;
  messengerOpen: boolean;
  setMessengerOpen: Dispatch<SetStateAction<boolean>>;
  surveyLink?: string | undefined;
  communicationsData?: CommunicationType;
  overrideDefaultNumberToText?: string;
}

const CHARACTER_LIMIT = 1599;

export function Messenger(props: MessengerProps) {
  const { caseId, contact, communicationsData, messengerOpen, setMessengerOpen } = props;

  const [selectedFiles, setSelectedFileIds] = useState<FileResourceType[]>([]);
  const [charCount, setCharCount] = useState(0);
  const [phoneNumberToText, setPhoneNumberToText] = useState<string | undefined>();
  const [firmNumberToTextFrom, setFirmNumberToTextFrom] = useState<string | undefined>();
  const [showFileExplorer, setShowFileExplorer] = useState(false);
  const [cachedImages, setCachedImages] = useState<{
    [key: string]: string;
  }>({});
  const [newMessageBody, setNewMessageBody] = useState('');
  const [filteredMessages, setFilteredMessages] = useState<MessageType[] | undefined>([]);
  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  const provisionedPhoneNumbers = provisionedPhoneNumberData?.data;
  const clientCommunicationsNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication',
  );

  const fileQueries = useGetParallelFiles(
    selectedFiles.map((selectedFile) => getFullFileId(selectedFile)),
  );
  const sendMessage = useSendMessage(communicationsData?.communicationId ?? caseId ?? '');

  const updateCase = useUpdateCaseData();

  const toPhoneNumbersArray = props.toPhoneNumbers
    ?.map((phoneNumberObj) =>
      phoneNumberObj?.phoneNumbers
        .map((phoneNumber) => phoneNumber?.number ?? '')
        .filter((phoneNumber) => phoneNumber),
    )
    .flat();

  const messages = communicationsData?.messages;

  const bottomRef = useRef<HTMLDivElement>(null);

  const toPhoneNumberWithoutFormatting = convertToE164(phoneNumberToText);

  const rawFirmNumbers = clientCommunicationsNumbers?.map(
    (phoneNumber) => phoneNumber?.phoneNumber,
  );

  const filesToAttach = fileQueries
    .map(
      (query: {
        data?: {
          data: FileResourceType;
        };
      }) => {
        return query.data?.data;
      },
    )
    .filter((files) => files !== undefined)
    .flat();

  const formatMessageBody = (messageBody: string, direction: string): string => {
    const linkStyle =
      direction === 'outbound'
        ? 'text-white underline hover:text-light-blue'
        : 'text-atlantic-blue underline hover:text-light-blue';

    // Add this line to replace newline characters with <br> elements
    const messageBodyWithLineBreaks = messageBody.replace(/\n/g, '<br>');

    // Define an image URL regex
    const IMAGE_URL_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g;

    return messageBodyWithLineBreaks.replace(URL_REGEX, (url) => {
      const urlToCheck = url.split('?')[0];

      // Check if 'aqueduct-well' is in the message body and the URL is an image URL
      if (messageBodyWithLineBreaks.includes('aqueduct-well') && IMAGE_URL_REGEX.test(urlToCheck)) {
        if (!cachedImages[urlToCheck]) {
          setCachedImages((prevImages) => ({
            ...prevImages,
            [urlToCheck]: url,
          }));
        }

        return `<img src="${cachedImages[urlToCheck]}" alt="attachment" class="rounded-md" />`;
      } else if (
        messageBodyWithLineBreaks.includes('aqueduct-well') &&
        !IMAGE_URL_REGEX.test(urlToCheck)
      ) {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" download class="${linkStyle}">Download Attachment</a>`;
      }

      const prefix = url.startsWith('http') || url.startsWith('ftp') ? '' : 'http://';
      return `<a href="${prefix}${url}" target="_blank" rel="noopener noreferrer" class="${linkStyle}">${url}</a>`;
    });
  };

  // Send user message function
  const sendUserMessage = (messageBody: string, formCode?: string, shareLink?: string) => {
    const s3ObjRefs = filesToAttach
      .map((file: FileResourceType | undefined) => {
        if (file?.s3ObjKey) {
          return file.s3ObjKey;
        } else {
          return '';
        }
      })
      .filter((s3ObjKey) => s3ObjKey !== '');

    setNewMessageBody('');
    setSelectedFileIds([]);
    setCharCount(0);
    if (!toPhoneNumberWithoutFormatting || !firmNumberToTextFrom) {
      enqueueSnackbar('Please select a firm and client phone number', {
        variant: 'error',
      });
      return;
    }
    sendMessage
      .mutateAsync({
        contactId: contact?.contactId ?? '',
        messageBody: messageBody,
        to: toPhoneNumberWithoutFormatting,
        from: firmNumberToTextFrom,
        s3ObjRefs: s3ObjRefs,
      })
      .then((res) => {
        if (formCode && caseId) {
          updateCase.mutate({
            caseId: caseId,
            firmCaseSurveyReference: formCode,
            clientIntakeShareLink: shareLink,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error?.logMsg?.includes('not a valid phone number')) {
          enqueueSnackbar('Error: Not a valid phone number', {
            variant: 'error',
          });
        } else if (err?.response?.data?.error?.logMsg?.includes('unsubscribed')) {
          enqueueSnackbar('Error: Client is unsubscribed from messaging', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Error sending message', { variant: 'error' });
        }
      });
  };

  const handleSendMessage = () => {
    // Implement sending message logic here
    if (newMessageBody) {
      const regex = /\{\{(\w+)\}\}/;
      const found = newMessageBody.match(regex);

      // If there is a match, create a survey
      if (found) {
        if (props.surveyLink) {
          const messageWithLink = newMessageBody.replace(regex, props.surveyLink);

          sendUserMessage(messageWithLink);
        }
      } else {
        // If there is no match, directly send the message
        sendUserMessage(newMessageBody);
      }
    }
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages?.length]);

  const messagingDisabled = updateCase.isLoading;
  const fileAttachingDisabled = messagingDisabled || !caseId;

  // get the toPhoneNumber of the most recent message that was sent
  const mostRecentMessage = messages?.[messages.length - 1];

  const mostRecentMessageToPhoneNumber =
    mostRecentMessage?.direction === 'outbound'
      ? convertToE164(mostRecentMessage?.toNumber ?? '')
      : convertToE164(props.toPhoneNumbers?.[0]?.phoneNumbers?.[0]?.number ?? '');

  const mostRecentMessageFromPhoneNumber =
    mostRecentMessage?.direction === 'outbound'
      ? convertToE164(mostRecentMessage?.fromNumber ?? '')
      : convertToE164(rawFirmNumbers?.[0] ?? '');

  const handleSelectedFilesChange = (files: FileResourceType[]) => {
    setSelectedFileIds(files);
  };

  const handleAttachFilesToMessage = () => {
    if (filesToAttach.length === 0) {
      return;
    }

    setSelectedFileIds([]);
  };

  useEffect(() => {
    scrollToBottom();

    setTimeout(() => {
      scrollToBottom();
    }, 2000);
  }, [messengerOpen]);

  const defaultNumberToText = () => {
    const numberToSet = props.overrideDefaultNumberToText
      ? convertToE164(props.overrideDefaultNumberToText)
      : mostRecentMessageToPhoneNumber ??
        convertToE164(props.toPhoneNumbers?.[0]?.phoneNumbers?.[0]?.number);

    return numberToSet;
  };

  useEffect(() => {
    if (!firmNumberToTextFrom || firmNumberToTextFrom === '') {
      setFirmNumberToTextFrom(
        mostRecentMessageFromPhoneNumber || convertToE164(rawFirmNumbers?.[0] ?? ''),
      );
    }
  }, [
    props.toPhoneNumbers,
    mostRecentMessageToPhoneNumber,
    phoneNumberToText,
    mostRecentMessageFromPhoneNumber,
    firmNumberToTextFrom,
  ]);

  const fileAttachButton = (
    <button
      onClick={() => {
        setShowFileExplorer(true);
      }}
      className={cn(
        'p-2 focus:outline-none rounded-lg w-[36px] h-[36px] ',
        fileAttachingDisabled
          ? 'cursor-default text-gray-400'
          : 'hover:text-light-blue bg-light-blue hover:bg-atlantic-blue text-atlantic-blue cursor-pointer',
      )}
      disabled={fileAttachingDisabled}
    >
      <PaperClipIcon className="w-5 h-5" />
    </button>
  );

  function filterMessages(firmNumber: string, phoneNumber: string) {
    return messages?.filter(
      (message) =>
        (message.fromNumber === convertToE164(phoneNumber) &&
          message.toNumber === convertToE164(firmNumber)) ||
        (message.fromNumber === convertToE164(firmNumber) &&
          message.toNumber === convertToE164(phoneNumber)),
    );
  }

  useEffect(() => {
    setFilteredMessages(filterMessages(firmNumberToTextFrom ?? '', phoneNumberToText ?? ''));
  }, [firmNumberToTextFrom, phoneNumberToText]);

  useEffect(() => {
    setFilteredMessages(
      filterMessages(firmNumberToTextFrom ?? '', mostRecentMessageToPhoneNumber ?? ''),
    );
    setPhoneNumberToText(mostRecentMessageToPhoneNumber);
  }, [messengerOpen, communicationsData, mostRecentMessageToPhoneNumber]);

  return (
    <>
      {caseId && (
        <FileAttachmentModal
          caseId={caseId}
          showFileExplorer={showFileExplorer}
          setShowFileExplorer={setShowFileExplorer}
          onAttachFiles={handleAttachFilesToMessage}
          onSelectedFilesChange={handleSelectedFilesChange}
          forSMS
        />
      )}

      <Slideover
        title={'Messages'}
        description={'Send and receive messages with your client'}
        open={messengerOpen}
        setOpen={setMessengerOpen}
        displayDeleteButton={false}
        typing={false}
        warningOnExit={newMessageBody !== ''}
      >
        <div className="flex flex-col h-full space-y-3">
          <div className="space-y-4">
            <Select
              value={firmNumberToTextFrom}
              onValueChange={(value) => {
                setFirmNumberToTextFrom(convertToE164(value));
              }}
            >
              <SelectTrigger className="relative w-full">
                <div className="absolute inline-block px-1 text-xs font-medium text-gray-400 bg-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70 -top-2 left-2">
                  Client Communications Phone Numbers
                </div>
                <SelectValue placeholder="Select a Phone Number" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Client Communications Phone Numbers</SelectLabel>
                  {rawFirmNumbers?.map((phoneNumber) => {
                    if (!phoneNumber) {
                      return null;
                    }

                    const value = convertToE164(phoneNumber);

                    return (
                      <SelectItem key={value} value={value ?? ''}>
                        {formatPhoneNumber(phoneNumber) ?? ''}
                        {phoneNumber === mostRecentMessageToPhoneNumber && ` (Most Recent)`}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select
              value={phoneNumberToText ?? defaultNumberToText()}
              onValueChange={(value) => {
                setPhoneNumberToText(convertToE164(value));
              }}
            >
              <SelectTrigger className="relative w-full">
                <div className="absolute inline-block px-1 text-xs font-medium text-gray-400 bg-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70 -top-2 left-2">
                  Phone Numbers
                </div>
                <SelectValue placeholder="Select a Phone Number" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {props.toPhoneNumbers?.map((phoneNumberObj) => {
                    if (phoneNumberObj?.phoneNumbers?.length === 0) {
                      return null;
                    }

                    return (
                      <Fragment key={phoneNumberObj?.phoneNumbers?.[0]?.number ?? ''}>
                        <SelectLabel>
                          {phoneNumberObj?.role} {`(${phoneNumberObj?.name})`}
                        </SelectLabel>

                        {phoneNumberObj.phoneNumbers
                          ?.filter((phoneNumber) => phoneNumber.numberType !== 'fax')
                          ?.map((phoneNumber) => {
                            if (!phoneNumber?.number) {
                              return null;
                            }

                            const value = convertToE164(phoneNumber.number);

                            return (
                              <SelectItem key={value} value={value ?? ''}>
                                {formatPhoneNumber(phoneNumber?.number) ?? ''}
                                {phoneNumber.number === mostRecentMessageToPhoneNumber &&
                                  ` (Most Recent)`}
                              </SelectItem>
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col justify-between border border-gray-300 rounded-lg shadow-lg  h-[85%]">
            <div className="w-full p-4 overflow-y-auto">
              {(filteredMessages?.length === 0 || filteredMessages === undefined) && (
                <div className="flex items-center justify-center h-32">
                  <span className="text-gray-500">No messages</span>
                </div>
              )}

              {filteredMessages &&
                filteredMessages?.map((message: MessageType, index) => {
                  return (
                    <div
                      key={`messageId-${index}`}
                      className={`flex flex-col my-2 ${
                        message.direction === 'outbound' ? 'items-end pl-10' : 'items-start pr-10'
                      }`}
                    >
                      <div
                        className={`p-2 text-sm rounded-lg  ${
                          message.direction === 'outbound'
                            ? 'bg-atlantic-blue text-white'
                            : 'bg-gray-100 text-gray-800'
                        } `}
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              formatMessageBody(message.body, message.direction),
                            ) as string,
                          }}
                        />
                      </div>
                      <span className="mt-1 text-xs text-gray-900">
                        {formatPhoneNumber(message.fromNumber ?? '') ?? ''}
                      </span>

                      <span className="text-xs text-gray-500 ">
                        {formatDate(message.dateCreated)}
                      </span>
                    </div>
                  );
                })}

              <div ref={bottomRef}></div>
            </div>
            {/* Chat input */}
            <div className="w-full p-2 bg-gray-200 rounded-b-lg ">
              <div>
                {filesToAttach.length > 0 && caseId && (
                  <div className="flex flex-wrap items-center w-full gap-1.5 pb-2">
                    {filesToAttach.map((file: FileResourceType | undefined) => {
                      return (
                        <div
                          key={file?.resourceId}
                          className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 capitalize rounded-md ring-1 ring-inset bg-gray-50 ring-gray-600/20"
                        >
                          <div className="w-4 h-4 mr-2 text-gray-400">
                            <PaperClipIcon className="w-4 h-4" />
                          </div>
                          <div className="text-sm text-gray-500">{file?.name}</div>
                          <button
                            onClick={() => {
                              if (!file) {
                                return;
                              }

                              const id = getFullFileId(file);

                              setSelectedFileIds((prev) => {
                                return prev.filter((stateFile) => getFullFileId(stateFile) !== id);
                              });
                            }}
                          >
                            <TrashIcon className="w-4 h-4 ml-2 text-red-500 hover:text-red-800" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="flex items-center w-full">
                <textarea
                  value={newMessageBody}
                  maxLength={1599}
                  onChange={(e) => {
                    // Update charCount and messageBody
                    setCharCount(e.target.value.length);
                    setNewMessageBody(e.target.value);
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'inherit';
                    target.style.height = `${target.scrollHeight + 30}px`;
                    e.target = target;
                  }}
                  onSelect={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'inherit';
                    target.style.height = `${target.scrollHeight + 30}px`;
                    e.target = target;
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && (e.shiftKey || e.metaKey)) {
                      e.preventDefault();
                      setNewMessageBody(newMessageBody + '\n');
                    } else if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  className={cn(
                    'flex-grow px-4 py-2 text-sm bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-atlantic-blue',
                    messagingDisabled ? 'cursor-default bg-gray-200 pointer-events-none' : '',
                  )}
                  placeholder="Type a message..."
                  disabled={messagingDisabled}
                />
              </div>
              <div className="flex items-start justify-between w-full mt-2">
                <div className="mt-1 ml-2 text-xs text-gray-600">
                  {charCount}/{CHARACTER_LIMIT} characters
                </div>

                <div className="flex items-start gap-2">
                  <SnippetTool
                    onSnippetSelect={(message: string) => {
                      setNewMessageBody(message);
                    }}
                  />

                  {fileAttachingDisabled ? (
                    <TooltipWrapper message="Please navigate to the associated case to attach a file to a message">
                      {fileAttachButton}
                    </TooltipWrapper>
                  ) : (
                    fileAttachButton
                  )}

                  <button
                    onClick={handleSendMessage}
                    className={cn(
                      'p-2 focus:outline-none rounded-lg w-[36px] h-[36px]',
                      messagingDisabled
                        ? 'cursor-default text-gray-400'
                        : 'hover:text-light-blue bg-light-blue hover:bg-atlantic-blue text-atlantic-blue cursor-pointer',
                    )}
                    disabled={messagingDisabled}
                  >
                    {updateCase.isLoading ? (
                      <div className="-mt-0.5">
                        <GladiateLoader height={25} />
                      </div>
                    ) : (
                      <div>
                        <PaperAirplaneIcon className="w-5 h-5" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slideover>
    </>
  );
}

export default Messenger;
