import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { axiosInstance } from '../https';
import {
  createCaseSurveyV3,
  deleteCaseSurveyV3,
  getListCaseSurveysV3,
  getListSurveysV3,
} from '../requests/gromatici';
import { queryKeys } from '../../static/queryKeys';

export interface FirmSurveyType {
  surveys: {
    surveyId: string;
    typeFormAttributes: {
      title: string;
    };
  }[];
}

export interface SurveyType {
  surveyResponse: {
    fields: {
      question: string;
      answer: string;
      id: string;
    }[];
  };
}

// #region Firm Surveys

export const useGetListSurveys = () =>
  useQuery({
    queryKey: [queryKeys.firmSurveys],
    queryFn: async () => getListSurveysV3(),
  });

// #endregion

// #region Case Surveys
// TODO type this after with the response structure
export const useGetListCaseSurveys = (caseId: string) =>
  useQuery({
    queryKey: [queryKeys.caseSurveys, caseId],
    queryFn: async () => getListCaseSurveysV3(caseId),
  });

export const useCreateCaseSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseSurveyV3>[0]) => createCaseSurveyV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.caseSurveys] });
    },
  });
};

export const useDeleteCaseSurvey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (surveyId: string) => deleteCaseSurveyV3(surveyId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.caseSurveys] });
    },
    onError: () => {
      enqueueSnackbar('Error Deleting Survey', {
        variant: 'error',
      });
    },
  });
};
// #endregion
