import { contactConnectionRoleColorMap, displayContactName } from '@colosseum/data';
import {
  ContactConnectionType,
  ContactType,
  contactConnectionOptionsReverse,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase, uniq } from 'lodash';
import Chip from '../Chip/Chip';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

export const columns: ColumnDef<
  ContactType & { roles: ContactConnectionType['relationship'][] }
>[] = [
  {
    accessorFn: (row) => displayContactName(row),
    id: 'Connection Name',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      uniq(row.roles.filter((role) => role !== 'connection').map((role) => startCase(role))),
    id: 'Relationship',
    sortUndefined: 1,
    sortingFn: (a, b, columnId) => {
      return (a.getValue(columnId) as string[])?.length > (b.getValue(columnId) as string[])?.length
        ? 1
        : -1;
    },
    header: renderHeader,
    cell: (props) => {
      const roles = uniq(props.row.original?.roles as ContactConnectionType['relationship'][])
        .filter((role) => {
          return role !== 'connection';
        })
        .sort();
      const reversedRoles = roles
        .map((role) => role && contactConnectionOptionsReverse[role])
        .sort();
      return (
        <div className="flex flex-wrap gap-1">
          {reversedRoles?.slice(0, 4).map((roleOnCase) => (
            <Chip
              key={roleOnCase}
              name={startCase(roleOnCase)}
              color={contactConnectionRoleColorMap?.[roleOnCase ?? 'default']}
            />
          ))}
          {reversedRoles.length > 4 && (
            <TooltipWrapper
              message={reversedRoles.map((roleOnCase) => startCase(roleOnCase)).join(', ')}
            >
              <div>
                <Chip name={`+${reversedRoles.length - 4} more`} color="stone" />
              </div>
            </TooltipWrapper>
          )}
        </div>
      );
    },
  },
];
