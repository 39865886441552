/* eslint-disable no-case-declarations */
import {
  CaseContactConnectionRoleType,
  ContactAddressType,
  ContactConnectionType,
  ContactEmailType,
  ContactNumberType,
  ContactType,
  ItemTagConnectionType,
  StaffType,
  TeamType,
} from '@gladiate/types';
import { FilterFn } from '@tanstack/react-table';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { startCase } from 'lodash';

export const getFuzzyFilterFn =
  ({
    formatAddress,
    displayContactName,
  }: {
    formatAddress: FormatAddressFn;
    displayContactName: DisplayContactNameFn;
  }): FilterFn<unknown> =>
    (row, columnId, originalValue, addMeta) => {
      const value = normalizeVal(originalValue);
      const itemRank = rankItemsByType(
        row.getValue(columnId),
        value,
        columnId,
        formatAddress,
        displayContactName,
      );
      addMeta({ itemRank });
      return itemRank.passed;
    };

type FormatAddressFn = (address: ContactAddressType) => string;
type DisplayContactNameFn = (contact: ContactType) => string;

const normalizeVal = (val: unknown, removeExtras = false): string => {
  const regex = removeExtras ? /[(),+-\s]/g : /[(),+-]/g;
  return String(val)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(regex, '');
};

const rankItemsByType = (
  cellValue: unknown,
  value: string,
  type: string,
  formatAddress: FormatAddressFn,
  displayContactName: DisplayContactNameFn,
): RankingInfo => {
  switch (type) {
    case 'Assignees':
      return rankItem(
        (cellValue as StaffType[]).map((assignee) => assignee.displayName).join(', ') ?? '',
        value,
      );
    case 'Phone Number':
    case 'Number':
      if (typeof cellValue === 'string') {
        return rankItem(cellValue, normalizeVal(value, true));
      }
      const numbers = (cellValue as ContactNumberType[])
        ?.map((number) => normalizeVal(number.number, true))
        .filter(Boolean);
      return rankItem(numbers ?? '', normalizeVal(value, true));
    case 'Email':
      if (typeof cellValue === 'string') {
        return rankItem(cellValue, value);
      }
      const emails = (cellValue as ContactEmailType[])?.map((email) => email.emailAddress);
      return rankItem(emails ?? '', value);
    case 'Address':
      return rankItem(
        (cellValue as ContactAddressType[]).map((address) => formatAddress(address)) ?? '',
        value,
      );
    case 'Roles':
      return rankItem(
        (cellValue as CaseContactConnectionRoleType[])?.map((role) => startCase(role.roleOnCase)) ??
        '',
        value,
      );
    case 'Teams':
      return rankItem((cellValue as TeamType[]).map((team) => team.title) ?? '', value);
    case 'Tags':
      return rankItem(
        (cellValue as ItemTagConnectionType[]).map((tag) => startCase(tag?.tagAttributes?.title)) ??
        '',
        value,
      );
    case 'Serving Discovery':
      return rankItem(
        (cellValue as ContactType[]).map((contact) => displayContactName(contact)) ?? '',
        value,
      );
    case 'Types of Discovery':
      return rankItem((cellValue as string[])?.map((type) => startCase(type)) ?? '', value);
    case 'Medical Treatment Type':
      return rankItem((cellValue as string) ?? '', value);
    case 'Contact Connections':
      return rankItem(
        (cellValue as ContactConnectionType[]).map((contact) =>
          displayContactName(contact.connectedToContact),
        ) ?? '',
        value,
      );
    default:
      return rankItem(cellValue ?? '', value);
  }
};
