import { formatAddresses, formatPhoneNumber } from '@colosseum/data';
import { ContactItemViewer, renderCell, renderHeader } from '@colosseum/shared-ui';
import { CoCounselType, ContactAddressType, HurinUserType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<CoCounselType & HurinUserType>[] = [
  {
    accessorFn: (row) => row.name,
    id: 'Name',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.email,
    id: 'Email',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.UserStatus,
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => {
      const status = props.getValue() as string;
      return status === 'FORCE_CHANGE_PASSWORD' ? (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-yellow-800 bg-yellow-100 rounded-full">
          Pending
        </span>
      ) : (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
          Active
        </span>
      );
    },
  },
  {
    accessorFn: (row) => row.phone_number,
    id: 'Phone Number',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPhoneNumber }),
  },
  {
    accessorFn: (row) => row.cocounselName,
    id: 'Firm Name',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => [
      {
        streetAddress: row.cocounselFirmAddress,
        city: row.cocounselFirmCity,
        state: row.cocounselFirmState,
        zipCode: row.cocounselFirmZip,
      },
    ],
    id: 'Firm Address',
    header: renderHeader,
    enableHiding: false,
    cell: (props) => {
      const totalAddresses = props.getValue() as ContactAddressType[];
      const { primaryAddress, additionalAddresses } = formatAddresses(totalAddresses);
      return (
        <ContactItemViewer primaryValue={primaryAddress} additionalValues={additionalAddresses} />
      );
    },
  },
];
