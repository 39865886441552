import { CardSection } from '@colosseum/shared-ui';
import NextButton from '../NextButton/NextButton';
import { CampaignStepOptions, ContactCampaign } from '@gladiate/types';
import { useNavigate, useParams } from 'react-router';
import ContactSubscriptionTable from '../SubscriptionTables/ContactSubscriptionTable';
import { useGetContactCampaign, useGetContactCampaignSubscriptions } from '@colosseum/data';

export function ContactCampaignSubscriptionPage() {
  // #region Hooks
  const { campaignId: contactCampaignId } = useParams() as {
    campaignId: string;
  };

  const navigate = useNavigate();

  // #endregion

  // #region Data Fetching
  const { data: campaignData, isLoading: isCampaignDataLoading } =
    useGetContactCampaign(contactCampaignId);
  const { data: campaignSubscriptionData } = useGetContactCampaignSubscriptions(contactCampaignId);
  // #endregion

  // #region State

  // #endregion

  // #region Derived State
  const subscriptionData = campaignData?.data;
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <CardSection
      cardHeading="Contact Campaign Subscriptions"
      className="flex flex-col justify-center"
    >
      <div className="flex flex-col gap-4 pt-4 ml-2">
        <ContactSubscriptionTable contactCampaignId={contactCampaignId} />
        <div className="flex justify-between">
          <button
            type="button"
            className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <NextButton
            title="Continue"
            nextRoute={`/campaigns/${contactCampaignId}/contact/${CampaignStepOptions.summary}`}
          />
        </div>
      </div>
    </CardSection>
  );
}
