import { cn } from '@colosseum/data';
import { XMarkIcon } from '@heroicons/react/24/solid';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { useEffect, useState } from 'react';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface ActionConfirmModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionFunction: any;
  id?: string;
  callback?: any;
  actionFunctionIsMutation?: boolean;
  title?: string;
  description?: string;
  buttonText?: string;
}

export function ActionConfirmModal(props: ActionConfirmModalProps) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    return () => {
      if (props.open) {
        // set open to false if the modal is open so that the slideoverProvider's count stays accurate
        props.setOpen(false);
      }
    };
  }, [props.open]);

  return (
    <AlertDialog.Root open={props.open} onOpenChange={props.setOpen}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
        <AlertDialog.Content
          className={cn(
            'data-[state=open]:animate-contentShow flex flex-col z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
            'w-[90vw] max-w-[500px] ',
            'max-h-[85vh]',
          )}
        >
          <button
            onClick={() => props.setOpen(false)}
            className="absolute top-[15px] right-[15px] w-[20px] h-[20px]  text-mauve11 hover:text-mauve12 focus:outline-none"
          >
            <XMarkIcon />
          </button>
          <AlertDialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
            {props.title ?? 'Delete Item'}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-mauve11 mt-4 mb-5 text-[15px] leading-normal">
            {props.description ??
              'Are you sure you want to delete this? All of the data will be permanently removed. This action cannot be undone.'}
          </AlertDialog.Description>
          <div className="flex justify-end gap-[25px]">
            <AlertDialog.Cancel asChild>
              <Button variant="outline">Cancel</Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                variant="destructive"
                data-cy="delete-button-confirm"
                onClick={() => {
                  // TODO: Refactor to allow for .then callback from slideovers
                  setLoading(true);
                  if (props.actionFunctionIsMutation) {
                    props.actionFunction();
                    props.setOpen(false);
                    setLoading(false);
                    if (props.callback) {
                      props.callback();
                    }
                  } else {
                    if (props.id) {
                      props
                        .actionFunction(props.id)
                        .then(() => {
                          setLoading(false);
                          props.setOpen(false);

                          if (props.callback) {
                            props.callback();
                          }
                        })
                        .catch(() => {
                          setLoading(false);
                        });
                      setLoading(false);
                    } else {
                      props
                        .actionFunction()
                        ?.then((res: any) => {
                          setLoading(false);
                          props.setOpen(false);
                          if (props.callback) {
                            props.callback();
                          }
                        })
                        .catch((err: any) => {
                          setLoading(false);
                        });
                      setLoading(false);
                    }
                  }
                }}
              >
                {loading ? (
                  <GladiateLoader height={40} white={true} />
                ) : (
                  props.buttonText ?? 'Delete'
                )}
              </Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

export default ActionConfirmModal;
