import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';

import {
  createNoteAttachmentV3,
  createNoteReplyV3,
  createNoteV3,
  deleteNoteAttachmentV3,
  deleteNoteReplyV3,
  deleteNoteV3,
  getNotesV3,
  swapNotePriorityV3,
  updateNoteReplyV3,
  updateNoteV3,
} from '../requests/papyrus';
import { DefaultV3Error } from '@gladiate/types';
import { AxiosError } from 'axios';
import { enqueueAPISnackbar } from '../../utils/snackbars';

export const useGetNotes = (resourceId?: Parameters<typeof getNotesV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.notes, resourceId],
    queryFn: async () => getNotesV3(resourceId),
    enabled: !!resourceId,
  });
};

export const useCreateNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      resourceId,
      data,
    }: {
      resourceId: Parameters<typeof createNoteV3>[0];
      data: Parameters<typeof createNoteV3>[1];
    }) => {
      return createNoteV3(resourceId, data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [`${queryKeys.notes}`],
      });
    },
  });
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      resourceId,
      data,
    }: {
      resourceId?: Parameters<typeof updateNoteV3>[0];
      data: Parameters<typeof updateNoteV3>[1];
    }) => updateNoteV3(resourceId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useDeleteNote = (resourceId?: Parameters<typeof deleteNoteV3>[0]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteNoteV3(resourceId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useCreateNoteReply = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      noteId,
      data,
    }: {
      noteId: Parameters<typeof createNoteReplyV3>[0];
      data: Parameters<typeof createNoteReplyV3>[1];
    }) => createNoteReplyV3(noteId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useSwapNotePriority = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      noteIdOne,
      noteIdTwo,
    }: {
      noteIdOne: Parameters<typeof swapNotePriorityV3>[0];
      noteIdTwo: Parameters<typeof swapNotePriorityV3>[1];
    }) => swapNotePriorityV3(noteIdOne, noteIdTwo),
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useUpdateNoteReply = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      noteReplyId,
      data,
    }: {
      noteReplyId: Parameters<typeof updateNoteReplyV3>[0];
      data: Parameters<typeof updateNoteReplyV3>[1];
    }) => updateNoteReplyV3(noteReplyId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useDeleteNoteReply = (resourceId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteNoteReplyV3(resourceId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useCreateNoteAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      noteId,
      data,
    }: {
      noteId: Parameters<typeof createNoteAttachmentV3>[0];
      data: Parameters<typeof createNoteAttachmentV3>[1];
    }) => createNoteAttachmentV3(noteId, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};

export const useDeleteNoteAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (noteAttachmentId: string) => deleteNoteAttachmentV3(noteAttachmentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.notes],
      });
    },
  });
};
