import { CaseCompleteType, useGetCasesListView } from '../lib/react-query/case';
import { useGetFirmCaseConflicts } from '../lib/react-query/case-contact-connection';
import { useGetFirmUserWithDisplayNameFromUsername } from './useGetFirmUserWithDisplayNameFromUsername';

export const useGetCasesComplete = () => {
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const casesRes = useGetCasesListView();
  const cases = casesRes?.data?.data || [];
  const completeCaseData: CaseCompleteType[] = [];

  const { data: conflictsData } = useGetFirmCaseConflicts();
  const conflicts = conflictsData?.data || [];

  cases.forEach((caseData, i) => {
    completeCaseData[i] = {
      ...cases[i],
      umUimCoverage: '',
      thirdPartyCoverage: '',
      medicalTreatmentAmountBilled: 0,
      medicalTreatments: [],
      assignees: [],
      expenses: null,
      conflicts: [],
      mriCount: null,
      surgeryCount: null,
      injectionCount: null,
      referralSource: null,
      contacts: [],
    };

    // REFERRAL SOURCE
    completeCaseData[i].referralSource = caseData.referralSource?.title || null;

    // CASE STATUS
    completeCaseData[i].caseStatus = caseData.caseStatusUpdates?.find(
      (statusUpdate) => !statusUpdate.dateEnded,
    )?.caseStatus;

    // POLICIES
    const {
      umUimPerAccident,
      umUimPerPerson,
      bodilyInjuryLiabilityPerAccident,
      bodilyInjuryLiabilityPerPerson,
    } = (caseData.policies || []).reduce(
      (acc, policy) => ({
        umUimPerAccident: Math.max(
          acc.umUimPerAccident,
          policy.umUimCoverageAmountPerAccident || 0,
        ),
        umUimPerPerson: Math.max(acc.umUimPerPerson, policy.umUimCoverageAmountPerPerson || 0),
        bodilyInjuryLiabilityPerAccident:
          acc.bodilyInjuryLiabilityPerAccident +
          (policy.bodilyInjuryLiabilityPerAccident || policy.bodilyInjuryAmountPerAccident || 0),
        bodilyInjuryLiabilityPerPerson:
          acc.bodilyInjuryLiabilityPerPerson +
          (policy.bodilyInjuryLiabilityPerPerson || policy.bodilyInjuryAmountPerPerson || 0),
      }),
      {
        umUimPerAccident: 0,
        umUimPerPerson: 0,
        bodilyInjuryLiabilityPerAccident: 0,
        bodilyInjuryLiabilityPerPerson: 0,
      },
    );

    completeCaseData[i].umUimCoverage = `${umUimPerPerson || '-'} / ${umUimPerAccident || '-'}`;
    completeCaseData[i].thirdPartyCoverage = `${bodilyInjuryLiabilityPerPerson || '-'} / ${bodilyInjuryLiabilityPerAccident || '-'
      }`;

    // MEDICAL TREATMENTS
    let amountBilled = 0;
    completeCaseData[i].medicalTreatments = [];
    caseData.medicalTreatments?.forEach((treatment) => {
      if (treatment.medicalTreatmentId?.split('-')[0] === caseData.caseId) {
        if (treatment.amountBilled && typeof treatment.amountBilled === 'number') {
          amountBilled += treatment.amountBilled;
        }
        if (treatment.mriPerformed === '1' && typeof treatment.mriCount === 'number') {
          completeCaseData[i].mriCount = (completeCaseData[i].mriCount || 0) + treatment.mriCount;
        }
        if (treatment.surgeryPerformed === '1' && typeof treatment.surgeryCount === 'number') {
          completeCaseData[i].surgeryCount =
            (completeCaseData[i].surgeryCount || 0) + treatment.surgeryCount;
        }
        if (treatment.injectionPerformed === '1' && typeof treatment.injectionCount === 'number') {
          completeCaseData[i].injectionCount =
            (completeCaseData[i].injectionCount || 0) + treatment.injectionCount;
        }
        completeCaseData[i]?.medicalTreatments?.push(treatment);
      }
    });
    completeCaseData[i].medicalTreatmentAmountBilled = amountBilled;

    caseData.caseContactConnections.forEach((caseContactConnection) => {
      const contact = caseContactConnection.contact;
      if (contact) {
        completeCaseData[i].contacts.push(contact);
      }
    });

    // STAFF
    completeCaseData[i].assignees = (caseData.staff || [])
      .filter((staffMember) => staffMember?.staffId?.split('-')[0] === caseData.caseId)
      .map((staffMember) => ({
        ...staffMember,
        displayName:
          getFirmUserWithDisplayNameFromUsername(staffMember?.username ?? '')?.displayName ?? '-',
      }));

    // EXPENSES
    completeCaseData[i].expenses = (caseData.expenses || []).reduce((total, expense) => {
      if (expense?.caseExpenseId?.split('-')[0] === caseData.caseId && expense?.waive !== '1') {
        const totalCost = (expense?.unitCount ?? 1) * (expense?.unitPrice ?? 0);
        return total + totalCost;
      }
      return total;
    }, 0);

    // CONFLICTS
    completeCaseData[i].conflicts = conflicts.filter(
      (conflict) => conflict?.clientCaseId?.split('-')[0] === caseData.caseId,
    );
  });

  return {
    ...casesRes,
    data: {
      data: completeCaseData,
    },
  };
};
