'use client';
import { CalendarFormInput } from '../CalendarFormInput/CalendarFormInput';
import { CurrencyInput } from '../CurrencyInput/CurrencyInput';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import { TextFormInput } from '../TextFormInput/TextFormInput';
import { PolicyFormFieldProps } from './PolicyForm';

export const DefaultPolicyFormFields: React.FC<PolicyFormFieldProps> = ({
  handleUpdatePolicy,
  form,
  policy,
}) => {
  return (
    <>
      <SelectFormInput
        {...form.register(`policyType`)}
        title="Policy Type"
        listItems={{
          'First Party': 'firstParty',
          'Third Party': 'thirdParty',
        }}
        listItemsIsObject
        defaultValue={policy?.policyType}
        placeholderText="Select a type"
        handleOnChange={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('policyType', target.value);
        }}
      />

      <TextFormInput
        {...form.register(`policyNumber`)}
        title="Policy Number"
        //Synthetic event was not working so I had to use any
        handleOnBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('policyNumber', target.value);
        }}
      />

      <TextFormInput
        {...form.register(`claimNumber`)}
        title="Claim Number"
        handleOnBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('claimNumber', target.value);
        }}
      />

      <CalendarFormInput
        {...form.register(`effectiveDatesOfCoverageStart`)}
        title="Effective Dates of Coverage"
        handleOnChange={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('effectiveDatesOfCoverageStart', target.value);
        }}
        resourceTypeObj={{
          id: policy?.policyId,
          type: 'policy',
        }}
      />

      <CalendarFormInput
        {...form.register(`effectiveDatesOfCoverageEnd`)}
        title="Effective Dates of Coverage End"
        handleOnChange={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('effectiveDatesOfCoverageEnd', target.value);
        }}
        resourceTypeObj={{
          id: policy?.policyId,
          type: 'policy',
        }}
      />
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Bodily Injury Liability Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.bodilyInjuryLiabilityCoverage}
          placeholderText="Select a Bodily Injury Liability Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('bodilyInjuryLiabilityCoverage', target.value);
          }}
          {...form.register(`bodilyInjuryLiabilityCoverage`)}
        />
      </div>
      {form.getValues('bodilyInjuryLiabilityCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`bodilyInjuryLiabilityPerPerson`, {
              valueAsNumber: true,
            })}
            title="Bodily Injury Per Person"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('bodilyInjuryLiabilityPerPerson', target.value);
            }}
            maxLength={20}
          />
          <CurrencyInput
            {...form.register(`bodilyInjuryLiabilityPerAccident`, {
              valueAsNumber: true,
            })}
            title="Bodily Injury Per Accident"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('bodilyInjuryLiabilityPerAccident', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}

      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Property Damage Liability Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.propertyDamageLiabilityCoverage}
          placeholderText="Select a Property Damage Liability Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('propertyDamageLiabilityCoverage', target.value);
          }}
          {...form.register(`propertyDamageLiabilityCoverage`)}
        />
      </div>
      {form.getValues('propertyDamageLiabilityCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`propertyDamageLiability`, {
              valueAsNumber: true,
            })}
            title="Property Damage Liability"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('propertyDamageLiability', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Personal Injury Protection Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.personalInjuryProtectionCoverage}
          placeholderText="Select a Personal Injury Protection Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('personalInjuryProtectionCoverage', target.value);
          }}
          {...form.register(`personalInjuryProtectionCoverage`)}
        />
      </div>
      {form.getValues('personalInjuryProtectionCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`personalInjuryProtectionAmount`, {
              valueAsNumber: true,
            })}
            title="Personal Injury Protection"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('personalInjuryProtectionAmount', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Medical Payment Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.medicalPaymentCoverage}
          placeholderText="Select a Medical Payment Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('medicalPaymentCoverage', target.value);
          }}
          {...form.register(`medicalPaymentCoverage`)}
        />
      </div>
      {form.getValues('medicalPaymentCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`medicalPaymentCoverageAmount`, {
              valueAsNumber: true,
            })}
            title="Medical Payment Coverage"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('medicalPaymentCoverageAmount', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="UM/UIM Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.umUimCoverage}
          placeholderText="Select a UM/UIM Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('umUimCoverage', target.value);
          }}
          {...form.register(`umUimCoverage`)}
        />
      </div>
      {form.getValues('umUimCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`umUimCoverageAmountPerPerson`, {
              valueAsNumber: true,
            })}
            title="UM/UIM  Per Person"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('umUimCoverageAmountPerPerson', target.value);
            }}
            maxLength={20}
          />
          <CurrencyInput
            {...form.register(`umUimCoverageAmountPerAccident`, {
              valueAsNumber: true,
            })}
            title="UM/UIM Per Accident"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('umUimCoverageAmountPerAccident', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="UM/UIM Property Damage Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.umUimMotoristPropertyDamageCoverage}
          placeholderText="Select a UM/UIM Property Damage Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('umUimMotoristPropertyDamageCoverage', target.value);
          }}
          {...form.register(`umUimMotoristPropertyDamageCoverage`)}
        />
      </div>
      {form.getValues('umUimMotoristPropertyDamageCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`umUimMotoristPropertyDamage`, {
              valueAsNumber: true,
            })}
            title="UM/UIM Property Damage"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('umUimMotoristPropertyDamage', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Liability Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.liabilityCoverage}
          placeholderText="Select a Liability Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('liabilityCoverage', target.value);
          }}
          {...form.register(`liabilityCoverage`)}
        />
      </div>
      {form.getValues('liabilityCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`liabilityCoveragePerPerson`, {
              valueAsNumber: true,
            })}
            title="Liability Coverage Per Person"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('liabilityCoveragePerPerson', target.value);
            }}
            maxLength={20}
          />
          <CurrencyInput
            {...form.register(`liabilityCoveragePerAccident`, {
              valueAsNumber: true,
            })}
            title="Liability Coverage Per Accident"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('liabilityCoveragePerAccident', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <div className="grid col-span-2 grid-cols-subgrid">
        <SelectFormInput
          title="Umbrella Coverage"
          listItems={{
            Included: 'included',
            'Not Applicable': 'notApplicable',
            Unknown: 'unknown',
          }}
          listItemsIsObject
          defaultValue={policy?.umbrellaPolicyCoverage}
          placeholderText="Select an Umbrella Coverage"
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdatePolicy('umbrellaPolicyCoverage', target.value);
          }}
          {...form.register(`umbrellaPolicyCoverage`)}
        />
      </div>
      {form.getValues('umbrellaPolicyCoverage') === 'included' && (
        <div className="grid col-span-2 grid-cols-subgrid">
          <CurrencyInput
            {...form.register(`umbrellaPolicy`, {
              valueAsNumber: true,
            })}
            title="Umbrella Policy"
            formHandleBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdatePolicy('umbrellaPolicy', target.value);
            }}
            maxLength={20}
          />
        </div>
      )}
      <CurrencyInput
        {...form.register(`deductibleAmount`, {
          valueAsNumber: true,
        })}
        title="Deductible"
        formHandleBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('deductibleAmount', target.value);
        }}
        maxLength={20}
      />
      <div></div>
      <CurrencyInput
        {...form.register(`rentalReimbursement`, {
          valueAsNumber: true,
        })}
        title="Rental Reimbursement"
        formHandleBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdatePolicy('rentalReimbursement', target.value);
        }}
        maxLength={20}
      />
    </>
  );
};
