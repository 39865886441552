import { cn, displayContactName, prettifyDateString } from '@colosseum/data';
import {
  ContactItemViewer,
  DataTableColumnHeader,
  renderCell,
  renderHeader,
} from '@colosseum/shared-ui';
import { ContactType, DiscoveryType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const columns: ColumnDef<
  DiscoveryType & { defendant?: ContactType; contact?: ContactType } & {
    servingDiscovery: ContactType[];
    contact?: ContactType;
  }
>[] = [
  {
    accessorFn: (row) => {
      const contactName = displayContactName(row.defendant);
      const sentOrReceived = startCase(row.sentOrReceived);
      return [sentOrReceived, contactName];
    },
    id: 'Sent or Received / Defendant',
    enableHiding: false,
    sortUndefined: 1,
    header: (props) => (
      <span className="whitespace-nowrap">
        <DataTableColumnHeader column={props.column} title={props.column.id} />
      </span>
    ),
    cell: (props) => {
      const [sentOrReceived, contactName] = props.getValue<string[]>();
      const textClasses = 'font-semibold';
      return (
        <div>
          <span className={cn(textClasses, !sentOrReceived && 'text-red-500')}>
            {sentOrReceived || '<No Sent or Received>'}
          </span>
          {' / '}
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Defendant>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.sentOrReceived,
    id: 'Sent or Received',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: startCase }),
  },
  {
    accessorFn: (row) => displayContactName(row.defendant),
    id: 'Defendant',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.servingDiscovery,
    id: 'Serving Discovery',
    header: renderHeader,
    cell: (props) => {
      const servingDiscoveryList = props.getValue() as ContactType[];
      return (
        <ContactItemViewer
          primaryValue={
            servingDiscoveryList.length ? displayContactName(servingDiscoveryList[0]) : '-'
          }
          additionalValues={servingDiscoveryList.slice(1).map((item) => displayContactName(item))}
        />
      );
    },
  },
  {
    accessorFn: (row) => row.typesOfDiscovery,
    id: 'Types of Discovery',
    header: renderHeader,
    cell: (props) => {
      const totalTypesOfDiscoveries = props.getValue() as string[];
      if (!totalTypesOfDiscoveries?.length) return '-';

      const discoveryTypesMapping = {
        formInterrogatories: 'Form Interrogatories',
        specialInterrogatories: 'Special Interrogatories',
        requestsForProduction: 'Requests for Production',
        requestsForAdmission: 'Requests for Admission',
      };

      const formattedTypesOfDiscoveries = totalTypesOfDiscoveries.map(
        // @ts-expect-error ts ignore this please
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (typeOfDiscovery) => discoveryTypesMapping[typeOfDiscovery],
      );

      const [primaryTypeOfDiscovery, ...additionalTypesOfDiscovery] = formattedTypesOfDiscoveries;

      return (
        <div className="font-medium">
          <ContactItemViewer
            primaryValue={primaryTypeOfDiscovery}
            additionalValues={additionalTypesOfDiscovery}
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateServed),
    id: 'Date Served',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },

  {
    accessorFn: (row) => row.setNumber,
    id: 'Set Number',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      if (row.methodOfDelivery === 'handDelivered') {
        return 'Hand-delivered';
      }
      if (row.methodOfDelivery === 'courtEFiling') {
        return 'Court E-Filing';
      }
      if (row.methodOfDelivery === 'fileSharing') {
        return 'File-sharing';
      }
      return startCase(row.methodOfDelivery);
    },
    id: 'Method of Delivery',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.responseDueDate),
    id: 'Response Due',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
