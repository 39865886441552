import { timeAgo } from '@colosseum/data';
import { GladiateLoader } from '@colosseum/shared-ui';
import { LitigationPlanAction, LitigationPlanType } from '@gladiate/types';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from 'lucide-react';

/* eslint-disable-next-line */
export interface LitigationPlanProps {
  handlePlanClick: (plan: LitigationPlanType) => void;
  handleActionClick: (
    actions: LitigationPlanAction[],
    action: LitigationPlanAction,
    plan: LitigationPlanType,
  ) => void;
  handleCreateAction: (index: number, plan: LitigationPlanType) => void;
  plan: LitigationPlanType;
  planIndex: number;
  isCreateActionLoading?: boolean;
  isPlanLoading?: boolean;
}

export function LitigationPlan(props: LitigationPlanProps) {
  const {
    handlePlanClick,
    handleActionClick,
    handleCreateAction,
    plan,
    planIndex,
    isCreateActionLoading,
    isPlanLoading,
  } = props;
  return (
    <div
      key={plan.litigationPlanId}
      className="relative px-5 border border-gray-300 rounded-lg shadow-lg py-7 "
    >
      <button
        onClick={() => handlePlanClick(plan)}
        className="absolute top-2 right-2 hover:text-sky-blue hover:animate-pulse"
      >
        <PencilSquareIcon className="w-6 h-6" />
      </button>
      <div className="absolute text-sm text-gray-500 bottom-2 right-2">
        Modified {timeAgo(plan.dateModified ?? '')}
      </div>
      <div className="text-xl font-bold text-sky-blue">{plan.title}</div>
      <div className="mt-3 mb-3 text-sm font-semibold text-gray-400">{plan.description}</div>

      {isPlanLoading && (
        <>
          <div className="h-8 my-4 bg-gray-100 rounded-md animate-pulse"></div>
          <div className="h-8 my-4 bg-gray-100 rounded-md animate-pulse"></div>
          <div className="h-8 my-4 bg-gray-100 rounded-md animate-pulse"></div>
        </>
      )}

      {plan.litigationPlanActions &&
        plan.litigationPlanActions.length > 0 &&
        plan.litigationPlanActions
          .sort((a, b) => {
            const aDate = a.dateCreated ? new Date(a.dateCreated).getTime() : 0;
            const bDate = b.dateCreated ? new Date(b.dateCreated).getTime() : 0;

            return aDate - bDate;
          })
          .map((action: LitigationPlanAction, index: number) => {
            return (
              <div
                key={action.litigationPlanActionId}
                className="flex items-center justify-between my-4"
              >
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-4 h-4 mr-2 text-xs text-white rounded-full bg-sky-blue">
                    {index + 1}
                  </div>
                  {action.title ? action.title : '-'}
                </div>
                <button
                  onClick={() => {
                    handleActionClick(plan.litigationPlanActions, action, plan);
                  }}
                  className="underline text-sky-blue hover:text-atlantic-blue "
                >
                  edit
                </button>
              </div>
            );
          })}

      <button
        onClick={() => {
          handleCreateAction(planIndex, plan);
        }}
        className="flex items-center -ml-1 group hover:text-sky-blue "
      >
        {isCreateActionLoading ? (
          <GladiateLoader height={30} />
        ) : (
          <PlusCircleIcon className="w-6 h-6 mr-1 -mt-0.5" />
        )}
        <div>Add new action</div>
      </button>
    </div>
  );
}

export default LitigationPlan;
