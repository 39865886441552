import { useDeleteTasksForTaskPlan } from '@colosseum/data';
import { TaskForTaskPlanType } from '@gladiate/types';
import { Dispatch, SetStateAction } from 'react';
import Slideover from '../Slideover/Slideover';
import TaskPlanTaskForm from '../forms/TaskPlanTaskForm/TaskPlanTaskForm';

/* eslint-disable-next-line */
export interface TaskPlanTaskSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  task?: TaskForTaskPlanType;
}

export function TaskPlanTaskSlideover(props: TaskPlanTaskSlideoverProps) {
  const { open, setOpen, task } = props;
  const deleteTaskForPlan = useDeleteTasksForTaskPlan();

  return (
    <Slideover
      dataCy="task-plan-task-slideover"
      title={'Task Plan Task'}
      description={'Edit your task plan task'}
      open={open}
      setOpen={setOpen}
      displayDeleteButton={true}
      typing={false}
      deleteFunction={() => {
        deleteTaskForPlan.mutate(task?.planTaskId ?? '');
      }}
    >
      <TaskPlanTaskForm task={task} />
    </Slideover>
  );
}

export default TaskPlanTaskSlideover;
