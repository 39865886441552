import { useUpdateTrustAccountTransaction } from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  CurrencyInput,
  Form,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import {
  TrustAccountTransactionType,
  caseContactConnectionOptions,
  trustReasonForEntryOptions,
  trustTypeOfEntryOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

/* eslint-disable-next-line */
export interface CaseTrustAccountingFormProps {
  transaction?: TrustAccountTransactionType;
  caseId?: string;
  setTyping: (value: boolean) => void;
}

const formSchema = z.object({
  amount: z.number().optional(),
  checkNumber: z.string().optional(),
  datePaid: z.string().optional(),
  description: z.string().optional(),
  reasonForEntry: z.string().optional(),
  typeOfEntry: z.string().optional(),
});

export function CaseTrustAccountingForm(props: CaseTrustAccountingFormProps) {
  const { caseId, transaction, setTyping } = props;

  // const updateExpense = useUpdateExpense();
  const updateTrustAccountTransaction = useUpdateTrustAccountTransaction();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: props.transaction,
  });

  function handleUpdate(
    field: keyof z.infer<typeof formSchema>,
    update?: string | string[] | number | boolean,
  ) {
    if (!props.transaction) {
      return;
    }
    updateTrustAccountTransaction
      .mutateAsync({
        trustAccountTransactionId: props.transaction?.trustAccountTransactionId,
        [field]: update,
      })
      .then(() => {
        setTyping(false);
      });
  }

  return (
    <div className="">
      <Form {...form}>
        <form>
          <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
            <CalendarFormInput
              {...form.register(`datePaid`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('datePaid', dayjs(target.value).format('YYYY-MM-DD'));
              }}
              title="Date Paid"
              resourceTypeObj={{
                id: props.transaction?.trustAccountTransactionId,
                type: 'trust-account-transaction',
              }}
            />
            <TextFormInput
              {...form.register(`checkNumber`)}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('checkNumber', target.value);
              }}
              handleOnChange={() => setTyping(true)}
              title="Check #"
            />
            <SelectFormInput
              title="Type of Entry"
              {...form.register(`typeOfEntry`)}
              listItems={trustTypeOfEntryOptions}
              listItemsIsObject
              defaultValue={props.transaction?.typeOfEntry}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('typeOfEntry', target.value);
              }}
              placeholderText="Select Type of Entry"
            />

            <SelectFormInput
              title="Reason for Entry"
              {...form.register(`reasonForEntry`)}
              listItems={trustReasonForEntryOptions}
              listItemsIsObject
              defaultValue={props.transaction?.reasonForEntry}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('reasonForEntry', target.value);
              }}
              placeholderText="Select Reason for Entry"
            />
            <div className="col-span-2">
              <TextFormInput
                {...form.register(`description`)}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
                handleOnChange={() => setTyping(true)}
                title="Description"
                type="textarea"
              />
            </div>
            <CurrencyInput
              {...form.register('amount', {
                valueAsNumber: true,
              })}
              formHandleBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                const value = parseFloat(target.value);

                handleUpdate('amount', value);
              }}
              title="Amount"
            />
          </div>
        </form>
      </Form>
      <div className="flex flex-col w-full ml-1 rounded-md gap-y-4">
        <CaseContactConnectionLinker
          caseId={caseId ?? ''}
          title="Payor"
          roleOnCase={caseContactConnectionOptions.payor}
          itemType="trustAccountTransaction"
          itemId={transaction?.trustAccountTransactionId}
        />
        <CaseContactConnectionLinker
          caseId={caseId ?? ''}
          title="Payee"
          roleOnCase={caseContactConnectionOptions.payee}
          itemType="trustAccountTransaction"
          itemId={transaction?.trustAccountTransactionId}
        />
      </div>
    </div>
  );
}

export default CaseTrustAccountingForm;
