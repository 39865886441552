import { cn, getGlobalIcon } from '@colosseum/data';
import { LinkType } from './Breadcrumbs';

export interface BreadcrumbItemProps {
  link?: LinkType;
  lastItem?: boolean;
}

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  const { link, lastItem } = props;
  const BreadcrumbIcon = getGlobalIcon(link?.icon);
  return (
    <div className="flex items-center p-0 text-sm" key={link?.label}>
      {BreadcrumbIcon && <BreadcrumbIcon className="w-4 h-4 font-bold" />}
      {link?.linkText?.map((linkTextItem, i) => {
        return (
          <div
            key={`${link?.label}-${i}`}
            className={cn(
              link?.onClick?.[i] && !link?.unclickable && 'cursor-pointer hover:text-gray-300',
              'pl-1 font-bold',
            )}
            onClick={link.onClick?.[i] && !link?.unclickable ? link?.onClick?.[i] : () => {}}
          >
            {linkTextItem}
            {link?.linkText && i !== link?.linkText?.length - 1 && <span>{', '}</span>}
          </div>
        );
      })}
      {/* put a slash between items except last one */}
      {!lastItem && <span className="px-1">{' / '}</span>}
    </div>
  );
}

export default BreadcrumbItem;
