import {
  DefaultPolicyFormSchema,
  HealthPolicyFormSchema,
  LifePolicyFormSchema,
  usePolicyForm,
} from '@colosseum/data';
import { PolicyType } from '@gladiate/types';
import { useCallback, useMemo } from 'react';
import { GladiateLoader } from '../../GladiateLoader/GladiateLoader';
import { Form } from '../../shadcn/Form/Form';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import { DefaultContactConnections } from './ContactConnections/DefaultContactConnections';
import { HealthContactConnections } from './ContactConnections/HealthContactConnections';
import { DefaultPolicyFormFields } from './DefaultPolicyForm';
import { HealthInsurancePolicyFormFields } from './HealthInsurancePolicyForm';
import { LifeInsurancePolicyFormFields } from './LifeInsurancePolicyForm';
import { LifeContactConnections } from './ContactConnections/LifeContactConnections';

export interface PolicyFormProps {
  caseId: string;
  policy: PolicyType | undefined;
  policyLoading: boolean;
}

export interface PolicyFormFieldProps {
  form: ReturnType<typeof usePolicyForm>['form'];
  handleUpdatePolicy: ReturnType<typeof usePolicyForm>['handleUpdatePolicy'];
  policy?: PolicyType;
}

export function PolicyForm(props: PolicyFormProps) {
  const { caseId, policy, policyLoading } = props;

  const { form, handleUpdatePolicy, updatePolicyMutation, setupPayload } = usePolicyForm({
    policy,
  });

  const DynamicFormFields = useMemo(() => {
    switch (policy?.type) {
      case 'Life':
        return {
          BottomFields: LifeInsurancePolicyFormFields,
          ContactConnections: LifeContactConnections,
        };
      case 'Health':
        return {
          BottomFields: HealthInsurancePolicyFormFields,
          ContactConnections: HealthContactConnections,
        };
      default:
        return {
          BottomFields: DefaultPolicyFormFields,
          ContactConnections: DefaultContactConnections,
        };
    }
  }, [policy]);

  const onInsuranceTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!policy) return;

      const newType = e.target.value;
      const formSchema =
        {
          Health: HealthPolicyFormSchema,
          Life: LifePolicyFormSchema,
        }[newType] || DefaultPolicyFormSchema;
      const updates: Partial<PolicyType> & Record<string, unknown> = {};

      const formFields = Object.keys(formSchema.shape);
      Object.keys(DefaultPolicyFormSchema.shape).forEach((key) => {
        if (!formFields.includes(key)) {
          updates[key] = undefined;
        }
      });

      updatePolicyMutation.mutate({
        ...Object.keys(updates).reduce((acc, key) => {
          return {
            ...acc,
            ...setupPayload(key, updates[key] as string | number | undefined),
          };
        }, {} as PolicyType),
        ...setupPayload('type', newType),
        ...setupPayload('policyId', policy.policyId),
      } as PolicyType);

      form.reset();
    },
    [form, policy, setupPayload, updatePolicyMutation],
  );

  if (policyLoading) {
    return (
      <div>
        <GladiateLoader />
      </div>
    );
  }

  return (
    <div className="space-y-5 ">
      {policy ? (
        <Form {...form}>
          <form className="space-y-5">
            <div className="col-span-2 pt-2">
              <SelectFormInput
                {...form.register(`type`)}
                title="Insurance Type"
                listItems={['Auto', 'Home', 'Life', 'Health', 'Property', 'Commercial']}
                defaultValue={
                  policy?.type
                    ? policy?.type.charAt(0).toUpperCase() + policy?.type.slice(1)
                    : 'Auto'
                }
                placeholderText="Select a type"
                handleOnChange={onInsuranceTypeChange}
              />
            </div>
            <div className="pl-1 space-y-3">
              <DynamicFormFields.ContactConnections
                {...{
                  caseId,
                  policy,
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-3 mt-8">
              <SelectFormInput
                {...form.register(`coverageStatus`)}
                title="Coverage Status"
                listItems={{
                  Confirmed: 'confirmed',
                  Denied: 'denied',
                  Pending: 'pending',
                  Unknown: 'unknown',
                }}
                listItemsIsObject
                defaultValue={policy?.coverageStatus}
                placeholderText="Select a Coverage Status"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdatePolicy('coverageStatus', target.value);
                }}
              />

              <DynamicFormFields.BottomFields
                {...{
                  handleUpdatePolicy,
                  form,
                  policy,
                }}
              />

              <div></div>
            </div>
          </form>
        </Form>
      ) : (
        <div>
          <GladiateLoader />
        </div>
      )}
    </div>
  );
}

export default PolicyForm;
