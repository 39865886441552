import { enqueueSnackbar } from 'notistack';

export function enqueueServerErrorSnackbar() {
  enqueueSnackbar('Whoops, something went wrong.', {
    variant: 'error',
    preventDuplicate: true,
    autoHideDuration: 3000,
  });
}

export function enqueueAPISnackbar({
  message,
  key,
  variant,
}: {
  message?: string;
  key?: string;
  variant: 'success' | 'error' | 'warning';
}) {
  let backupMessage = 'Action completed';
  switch (message) {
    case 'success':
      backupMessage = 'Action completed';
      break;
    case 'error':
      backupMessage = 'Whoops, something went wrong';
      break;
    case 'warning':
      backupMessage = 'Warning';
      break;
    default:
      backupMessage = 'Action completed';
      break;
  }

  const snackbarBody: {
    variant: 'success' | 'error' | 'warning';
    preventDuplicate: boolean;
    autoHideDuration: number;
    key?: string;
  } = {
    variant,
    preventDuplicate: true,
    autoHideDuration: 3000,
  };
  if (key) snackbarBody.key = key;
  enqueueSnackbar(message || backupMessage, snackbarBody);
}
