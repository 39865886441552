import { EventDates } from '@gladiate/types';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
dayjs?.extend(relativeTime);
dayjs?.extend(duration);

export function formatDate(date: string) {
  if (!date) return '';

  date = date + ' UTC';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!timeZone) return date;

  const time = utcToZonedTime(date, timeZone);

  if (!time) return date;

  return format(time, 'yyyy-MM-dd hh:mm a');
}

export function convertDiffToHoursDuration(difference: number) {
  const duration = dayjs.duration(difference);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return dayjs.duration({
    hours: Math.floor(duration.asHours()),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  });
}

export function prettifyDateString(date: string | undefined) {
  if (!date) {
    return null;
  }
  return dayjs(date).format('MMM D, YYYY');
}

export function prettifyDateTimeString(date: string | undefined) {
  if (!date) {
    return null;
  }
  return dayjs(date).format('MMM D, YYYY h:mm A');
}

export function timeAgo(dateString: string, utc?: boolean): string {
  // Parse the input date with dayjs
  const inputDate = dayjs(dateString, { utc: true });

  // Return the relative time from now
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return inputDate.fromNow();
}

// export function timeAgo(dateString: string): string {
//   // Explicitly parse the input date as UTC
//   const inputDate = dayjs(dateString, { utc: true });

//   // Return the relative time from now
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//   return inputDate.fromNow();
// }

export function calculateDaysAgo(dateString: string) {
  const daysAgo = dayjs().diff(dayjs(dateString), 'd');
  return `${daysAgo} ${pluralize('days', daysAgo)} ago`;
}

export function getCurrentDateTime() {
  const now = new Date();

  // Add 1 minute to the current time
  let oneMinuteLater = new Date(now.getTime() + 60 * 1000);

  // Check if the date has changed after adding 1 minute
  // (for example, if the current time was 23:59)
  if (oneMinuteLater.getDate() !== now.getDate()) {
    // If the date has changed, adjust the end time to 23:59
    oneMinuteLater = new Date(now);
    oneMinuteLater.setHours(23);
    oneMinuteLater.setMinutes(59);
    oneMinuteLater.setSeconds(0);
    oneMinuteLater.setMilliseconds(0);
  }

  const date = now.toISOString().split('T')[0];
  const time = now.toTimeString().split(' ')[0].substring(0, 5);
  const endTime = oneMinuteLater.toTimeString().split(' ')[0].substring(0, 5);

  return {
    date,
    time,
    endTime,
  };
}

export function convertToEpoch(event: EventDates) {
  // Combine the date and time strings
  const startDateTime = `${dayjs(event.start_date).format('YYYY-MM-DD')}T${event.start_time}:00`;
  const endDateTime = `${dayjs(event.end_date).format('YYYY-MM-DD')}T${event.end_time}:00`;

  // Create Date objects and get the epoch timestamp (in seconds)
  const start_time = new Date(startDateTime).getTime() / 1000;
  const end_time = new Date(endDateTime).getTime() / 1000;

  // Return the epoch timestamps
  return {
    start_time,
    end_time,
  };
}

export function convertFromEpoch(event: { start_time: number; end_time: number }) {
  // Create new Date objects using the timestamps (multiplying by 1000 to get milliseconds)
  const startDate = new Date(event.start_time * 1000);
  const endDate = new Date(event.end_time * 1000);

  // Get the date strings in the format YYYY-MM-DD
  const start_date = startDate.toLocaleDateString().split('T')[0];
  const end_date = endDate.toLocaleDateString().split('T')[0];

  // Get the time strings in the format HH:mm
  const start_time = startDate.toTimeString().split(' ')[0].substring(0, 5);
  const end_time = endDate.toTimeString().split(' ')[0].substring(0, 5);

  // Return the date and time strings
  return {
    start_date,
    end_date,
    start_time,
    end_time,
  };
}

export const daysBetween = (date1: string, date2: string) => {
  return dayjs(date2).diff(dayjs(date1), 'day');
};

export function getOrdinalNum(n: number) {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}
