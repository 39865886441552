import {
  generateTimeStamps,
  parseDeliveryMechanism,
  useCreateContactCampaignTrigger,
  useDeleteContactCampaignTrigger,
  useGetProvisionedPhoneNumbers,
  useUpdateContactCampaignTrigger,
} from '@colosseum/data';
import {
  CampaignDeliveryMechanism,
  ContactCampaign,
  ContactCampaignTrigger,
  ContactCampaignTriggerOptions,
  HandwryttenCardOrderDetails,
  deliveryMechanismOptionsMap,
  TimeZoneOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { invert, set } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Slideover } from '../Slideover/Slideover';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
import HandwryttenSelectForm from '../HandwryttenSelect/HandwryttenSelect';
import CreateButton from '../CreateButton/CreateButton';
import Typography from '../Typography/Typography';
import SnippetTool from '../SnippetTool/SnippetTool';
import TemplateDictionaryTool from '../TemplateDictionaryTool/TemplateDictionaryTool';

const CHARACTER_LIMIT = 1599;
const formSchema = z.object({
  deliveryMechanism: z.union([
    z.literal<CampaignDeliveryMechanism>('handwrytten'),
    z.literal<CampaignDeliveryMechanism>('sms'),
  ]),
  date: z.string().optional(),
  sendAtTime: z.string().optional(),
  sendAtTimeZone: z.string().optional(),
  sendOptions: z.union([z.literal('isBirthday'), z.literal('date')]),
  message: z.string().max(CHARACTER_LIMIT, 'Please enter a shorter message'),
  provisionedPhoneNumberId: z.string().optional(),
});

export function ContactCampaignTriggerSlideover({
  contactCampaignTrigger,
  open,
  setOpen,
  contactCampaignId,
}: {
  contactCampaignTrigger: (ContactCampaignTrigger & ContactCampaign) | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  contactCampaignId: string;
}) {
  // #region Hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: contactCampaignTrigger as ContactCampaignTrigger &
      ContactCampaign & { sendOptions: 'date' | 'isBirthday' },
  });
  const createTrigger = useCreateContactCampaignTrigger();
  const updateTrigger = useUpdateContactCampaignTrigger();
  const deleteTrigger = useDeleteContactCampaignTrigger();
  // #endregion

  // #region Data Fetching
  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  // #endregion

  // #region State
  const [typing, setTyping] = useState(false);
  const [charCount, setCharCount] = useState(contactCampaignTrigger?.message?.length ?? 0);
  const [_, setFormFields] = useState<string>();
  const [triggerOption, setTriggerOption] = useState<ContactCampaignTriggerOptions | undefined>(
    undefined,
  );
  const [cardOrderDetails, setCardOrderDetails] = useState<HandwryttenCardOrderDetails>({});
  const [charCountLimit, setCharCountLimit] = useState(CHARACTER_LIMIT);
  // #endregion

  // #region Derived State
  const contactCampaignTriggerOptions: Record<string, ContactCampaignTriggerOptions> = {
    'Contact Birthday': 'isBirthday',
    'Specific Date': 'date',
  };
  const provisionedPhoneNumbers = provisionedPhoneNumberData?.data;

  const clientCommunicationPhoneNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication',
  );
  const clientCommunicationNumbersListItems = clientCommunicationPhoneNumbers?.reduce(
    (acc, item) => {
      return {
        ...acc,
        [item.phoneNumber]: item.provisionedPhoneNumberId,
      };
    },
    {},
  ) as { [key: string]: string };

  const timeZoneOptions: ContactCampaignTrigger['sendAtTimeZone'][] = [
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa',
  ];
  // #endregion

  // #region useEffects
  useEffect(() => {
    setTyping(false);
    if (contactCampaignTrigger && contactCampaignTrigger?.isBirthday === '1') {
      setTriggerOption('isBirthday');
    } else if (contactCampaignTrigger && contactCampaignTrigger?.isBirthday === '0') {
      setTriggerOption('date');
    }

    if (!open) {
      setCardOrderDetails({});
      form.reset({ message: '' });
      setTriggerOption(undefined);
    }
  }, [open]);

  useEffect(() => {
    setCharCount(contactCampaignTrigger?.message?.length ?? 0);
  }, [contactCampaignTrigger]);

  // #endregion

  // #region Event Handlers
  function isTriggerFormValid(): boolean {
    if (!form.getValues('deliveryMechanism')) {
      return false;
    }

    if (!form.getValues('message')) {
      return false;
    }

    if (form.getValues('deliveryMechanism').includes('handwrytten')) {
      if (!cardOrderDetails.cardId) {
        return false;
      }
      if (!cardOrderDetails.fontId) {
        return false;
      }
    }

    if (form.getValues('deliveryMechanism').includes('sms')) {
      if (!form.getValues('provisionedPhoneNumberId')) {
        return false;
      }
    }

    if (form.getValues('sendOptions') === 'date') {
      if (!form.getValues('sendAtTime')) {
        return false;
      }
    }

    return true;
  }

  function handleUpdateCampaignTrigger() {
    setTyping(true);
    const cadenceParameters = {
      isBirthday: () => ({
        isBirthday: '1',
        date: '0',
      }),
      date: () => ({
        isBirthday: '0',
        date: '1',
      }),
    };
    function buildCadenceParameters(str: keyof typeof cadenceParameters) {
      return cadenceParameters[str];
    }

    if (contactCampaignId && triggerOption && !contactCampaignTrigger?.contactCampaignTriggerId) {
      createTrigger
        .mutateAsync({
          contactCampaignId: contactCampaignId ?? '',
          data: {
            message: form.getValues('message') ?? '',
            deliveryMechanism: form.getValues('deliveryMechanism') as CampaignDeliveryMechanism,
            ...(buildCadenceParameters(triggerOption)() ?? null),
            handwryttenCardId: cardOrderDetails.cardId?.toString(),
            handwryttenFontId: cardOrderDetails.fontId?.toString(),
            provisionedPhoneNumberId: form.getValues('provisionedPhoneNumberId'),
            date: form.getValues('date'),
            sendAtTimeZone: form.getValues('sendAtTimeZone') as TimeZoneOptions,
            sendAtTimeHour:
              parseInt((form?.getValues('sendAtTime') || '').split(':')[0]) ||
              contactCampaignTrigger?.sendAtTimeHour,
            sendAtTimeMinute:
              parseInt((form?.getValues('sendAtTime') || '').split(':')[1]) ||
              contactCampaignTrigger?.sendAtTimeMinute,
          },
        })
        .then((res) => {
          setTyping(false);
          setOpen(false);
        });
    } else {
      triggerOption &&
        updateTrigger
          .mutateAsync({
            message: form.getValues('message') ?? '',
            contactCampaignTriggerId: contactCampaignTrigger?.contactCampaignTriggerId ?? '',
            deliveryMechanism: form.getValues('deliveryMechanism') as CampaignDeliveryMechanism,
            ...(buildCadenceParameters(triggerOption)() ?? null),
            handwryttenCardId: cardOrderDetails.cardId?.toString(),
            handwryttenFontId: cardOrderDetails.fontId?.toString(),
            provisionedPhoneNumberId: form.getValues('provisionedPhoneNumberId'),
            date: form.getValues('date'),
            sendAtTimeZone: form.getValues('sendAtTimeZone') as TimeZoneOptions,
            sendAtTimeHour:
              parseInt((form?.getValues('sendAtTime') || '').split(':')[0]) ||
              contactCampaignTrigger?.sendAtTimeHour,
            sendAtTimeMinute:
              parseInt((form?.getValues('sendAtTime') || '').split(':')[1]) ||
              contactCampaignTrigger?.sendAtTimeMinute,
          })
          .then((res) => {
            setTyping(false);
            setOpen(false);
          });
    }
  }

  function handleDeleteCampaignTrigger() {
    deleteTrigger.mutateAsync(contactCampaignTrigger?.contactCampaignTriggerId ?? '').then(() => {
      setOpen(false);
      setCardOrderDetails({});
    });
  }
  // #endregion

  return (
    <div>
      <Slideover
        open={open}
        setOpen={setOpen}
        title={'Campaign Message Details'}
        description={'Edit campaign message details'}
        displayDeleteButton={true}
        deleteButtonText="Remove Trigger"
        deleteFunction={handleDeleteCampaignTrigger}
        typing={typing}
        submitButton={
          <CreateButton
            title={
              contactCampaignTrigger?.contactCampaignTriggerId ? 'Update Trigger' : 'Create Trigger'
            }
            onClick={() => {
              handleUpdateCampaignTrigger();
            }}
            disabled={!isTriggerFormValid()}
            loading={createTrigger.isLoading || updateTrigger.isLoading || typing}
          />
        }
      >
        <>
          <Form {...form}>
            <form>
              <div className="flex flex-col gap-5 pt-5">
                <SelectFormInput
                  {...form.register(`deliveryMechanism`)}
                  title="Delivery Mechanism"
                  listItems={invert(deliveryMechanismOptionsMap)}
                  listItemsIsObject
                  defaultValue={parseDeliveryMechanism(
                    contactCampaignTrigger?.deliveryMechanism as unknown as CampaignDeliveryMechanism[],
                  )}
                  placeholderText="How would you like to send this message?"
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    if (target.value === 'sms') {
                      setCharCountLimit(CHARACTER_LIMIT);
                    }
                    setFormFields(target.value);
                  }}
                />
                {form.getValues('deliveryMechanism')?.includes('sms') && (
                  <SelectFormInput
                    title="Client Communication Numbers"
                    listItems={clientCommunicationNumbersListItems}
                    listItemsIsObject
                    defaultValue={contactCampaignTrigger?.provisionedPhoneNumberId}
                    placeholderText="Select a phone number"
                    handleOnChange={(e: React.SyntheticEvent) => {}}
                    {...form.register(`provisionedPhoneNumberId`)}
                  />
                )}
                {form.getValues('deliveryMechanism')?.includes('handwrytten') && (
                  <>
                    <HandwryttenSelectForm
                      setCharacterCount={setCharCountLimit}
                      setCardOrder={setCardOrderDetails}
                      handwryttenCardOrderDetails={{
                        cardId: contactCampaignTrigger?.handwryttenCardId?.toString(),
                        fontId: contactCampaignTrigger?.handwryttenFontId?.toString(),
                      }}
                    />
                  </>
                )}
                <div className="flex flex-col justify-between border border-gray-300 rounded-lg shadow-lg  h-[85%] p-4  bg-gray-200">
                  <TextFormInput
                    title={'Campaign Message'}
                    type="textarea"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      setCharCount(target.value.length);
                    }}
                    placeholderText={'Enter a message'}
                    hideLabel={true}
                    {...form.register(`message`)}
                  />

                  <div className="flex flex-row justify-between mt-2 ml-2 ">
                    <Typography size="xs" color="gray">
                      {charCount}/{charCountLimit} characters
                    </Typography>
                    <div className="flex flex-row gap-2">
                      <SnippetTool
                        onSnippetSelect={(message: string) => {
                          form.setValue('message', message);
                          setCharCount(message.length);
                        }}
                      />
                      {/* <TemplateDictionaryTool
                        onTemplateSelect={(templateString: string) => {
                          const currentCampaignMessage =
                            form.getValues('message');
                          form.setValue(
                            'message',
                            `${currentCampaignMessage} ${templateString}`
                          );
                        }}
                      /> */}
                    </div>
                  </div>
                </div>

                <SelectFormInput
                  title="Campaign options"
                  listItems={contactCampaignTriggerOptions}
                  listItemsIsObject
                  placeholderText="When would you like to send this message?"
                  defaultValue={
                    contactCampaignTrigger && contactCampaignTrigger.isBirthday === '1'
                      ? 'isBirthday'
                      : !contactCampaignTrigger
                      ? undefined
                      : 'date'
                  }
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    setTriggerOption(target.value as ContactCampaignTriggerOptions);
                  }}
                  {...form.register(`sendOptions`)}
                />
                {triggerOption === 'date' && (
                  <>
                    <CalendarFormInput
                      {...form.register(`date`)}
                      handleOnChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        setFormFields(target.value);
                      }}
                      title="Send on Date"
                    />
                    <SelectFormInput
                      {...form.register(`sendAtTime`)}
                      title="Time"
                      listItems={generateTimeStamps(9, 20, 15)}
                      listItemsIsObject
                      defaultValue={
                        contactCampaignTrigger
                          ? `${contactCampaignTrigger?.sendAtTimeHour
                              ?.toString()
                              .padStart(2, '0')}:${contactCampaignTrigger?.sendAtTimeMinute}`
                          : undefined
                      }
                      handleOnChange={(e: React.SyntheticEvent) => {}}
                      placeholderText="Select a Time"
                    />
                    <SelectFormInput
                      {...form.register(`sendAtTimeZone`)}
                      title="Timezone"
                      listItems={timeZoneOptions}
                      defaultValue={contactCampaignTrigger?.sendAtTimeZone}
                      handleOnChange={(e: React.SyntheticEvent) => {}}
                      placeholderText="Select a Timezone"
                    />
                  </>
                )}
              </div>
            </form>
          </Form>
        </>
      </Slideover>
    </div>
  );
}

export default ContactCampaignTriggerSlideover;
