import { TemplateVariables } from '@gladiate/types';
import { getFirmId } from '../../utils/authUtils';
import { axiosInstance } from '../https';

const firmId = getFirmId();

// POST generate_document
export const generateDocument = (data: TemplateVariables) => {
  const { fileId, ...rest } = data;
  return axiosInstance.post<{
    status: string;
    templateKey: string;
    genDocKey: string;
  }>(`/libera/${firmId}/${fileId}`, rest);
};

export const getDocumentTags = (templateId: string) => {
  return axiosInstance.get<{
    documentTags: {
      display: string[];
      hide: string[];
    };
    templateKey: string;
    status: string;
  }>(`/libera/${firmId}/${templateId}`);
};
