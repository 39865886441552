import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { UTBMSCodeType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<UTBMSCodeType>[] = [
  {
    accessorFn: (row: UTBMSCodeType) => row.groupName,
    id: 'Group',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: UTBMSCodeType) => row.name,
    id: 'Name',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: UTBMSCodeType) => row.code,
    id: 'Code',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: UTBMSCodeType) => row.description,
    id: 'Description',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
