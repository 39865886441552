import {
  displayContactName,
  prettifyDateString,
  useGetContactsInfinite,
  useGetFirmUserWithDisplayNameFromUsername,
  useUpdateArctrievalRecordsRequest,
} from '@colosseum/data';
import {
  Form,
  LabyrinthTextInput,
  SelectFormInput,
  Switch,
  Typography,
} from '@colosseum/shared-ui';
import { RecordRequest } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { commandForRecordsRequestV3 } from 'libs/data/src/lib/requests/argonauts';
import { startCase } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';

const editRequestSelectOptions = (status: string) => {
  switch (status) {
    case 'canceled': {
      return {} as { [key: string]: string };
      // return { Delete: 'delete' } as { [key: string]: string };
    }
    case 'completed': {
      return { Reopen: 'reopen' };
    }
    case 'Deleted': {
      return {};
    }
    case 'Reopen': {
      // return { Delete: 'delete', Reopen: 'reopen' };
      return { Reopen: 'reopen' };
    }
    case 're-sent': {
      return {
        Cancel: 'cancel',
        Resend: 'resend',
        Complete: 'complete',
        // Delete: 'delete',
      };
    }
    default:
      return {
        Cancel: 'cancel',
        Resend: 'resend',
        Complete: 'complete',
        // Delete: 'delete',
      };
  }
};

export interface ViewOnlyRecordRequestsFormProps {
  viewOnlyRecordRequest?: RecordRequest;
  updateRecordRequest: boolean;
  setUpdateRecordRequest: Dispatch<SetStateAction<boolean>>;
  setViewOnlyOpen: Dispatch<SetStateAction<boolean>>;
}

const formSchema = z.object({
  deliverByEmail: z.boolean(),
  deliverByFax: z.boolean(),
  deliverByMail: z.boolean(),
  isNoRecords: z.boolean().optional(),
  requestStatus: z.string(),
});

export function ViewOnlyRecordRequestsForm(props: ViewOnlyRecordRequestsFormProps) {
  const { viewOnlyRecordRequest, updateRecordRequest, setUpdateRecordRequest, setViewOnlyOpen } =
    props;
  const navigate = useNavigate();
  const contactsQuery = useGetContactsInfinite();
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const allContacts = contactsQuery?.data?.data;
  const updateArctrievalEntity = useUpdateArctrievalRecordsRequest();

  const [deliverByEmail, setDeliverByEmail] = useState(false);
  const [deliverByFax, setDeliverByFax] = useState(false);
  const [deliverByMail, setDeliverByMail] = useState(false);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [newRequestStatus, setNewRequestStatus] = useState<
    Parameters<typeof commandForRecordsRequestV3>[0]['command'] | undefined
  >();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      deliverByEmail: false,
      deliverByFax: false,
      deliverByMail: false,
      isNoRecords: false,
      requestStatus: '',
    },
  });

  const handleUpdateArctrievalForm = (data: any) => {
    const bodyObj: {
      email?: boolean;
      fax?: boolean;
      usMail?: boolean;
      isNoRecords?: boolean;
    } = {
      email: data.deliverByEmail,
      fax: data.deliverByFax,
      usMail: data.deliverByMail,
    };
    if (newRequestStatus === 'complete') {
      bodyObj['isNoRecords'] = isNoRecords;
      delete bodyObj.email;
      delete bodyObj.fax;
      delete bodyObj.usMail;
    }
    updateArctrievalEntity
      .mutateAsync({
        requestId: viewOnlyRecordRequest?.requestId ?? '',
        command: newRequestStatus ?? 'resend',
        body: bodyObj,
      })
      .then(() => {
        setUpdateRecordRequest(false);
        setViewOnlyOpen(false);
      });
  };

  useEffect(() => {
    if (updateRecordRequest) {
      form.handleSubmit(handleUpdateArctrievalForm)();
      if (!form.formState.isValid) {
        setUpdateRecordRequest(false);
      }
    }
  }, [updateRecordRequest]);

  return (
    <div>
      <Form {...form}>
        <div className="flex items-center text-sm whitespace-nowrap">
          Billing Entity
          <div className="flex items-center justify-between p-1 px-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue ">
            <p
              onClick={() => {
                navigate(`/contacts/${viewOnlyRecordRequest?.recordsProviderContactId ?? ''}`);
              }}
              className="cursor-pointer hover:underline hover:text-black"
            >
              {displayContactName(
                allContacts?.find(
                  (contact: any) =>
                    contact.contactId === viewOnlyRecordRequest?.recordsProviderContactId,
                ),
              )}
            </p>
          </div>
        </div>

        <div className="flex items-center mt-5 text-sm whitespace-nowrap">
          Requested By
          <div className="flex items-center justify-between p-1 px-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue ">
            <p className="">
              {
                getFirmUserWithDisplayNameFromUsername(
                  viewOnlyRecordRequest?.recordsRequestorUsername ?? '',
                ).displayName
              }
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 pt-2 pb-2 mt-5 gap-y-5 gap-x-3">
          <LabyrinthTextInput
            fieldInfo={{
              title: 'Date Created',
              type: 'textBox',
              valueVariable: 'dateCreated',
            }}
            fieldValue={
              viewOnlyRecordRequest?.dateCreated
                ? prettifyDateString(viewOnlyRecordRequest?.dateCreated)
                : 'N/A'
            }
            handleOnChange={() => {
              //do nothing
            }}
            handleOnBlur={() => {
              //do nothing
            }}
            disabled={true}
          />
          <LabyrinthTextInput
            fieldInfo={{
              title: 'Date Modified',
              type: 'textBox',
              valueVariable: 'dateModified',
            }}
            fieldValue={
              viewOnlyRecordRequest?.dateCreated
                ? prettifyDateString(viewOnlyRecordRequest?.dateModified ?? '')
                : 'N/A'
            }
            handleOnChange={() => {
              //do nothing
            }}
            handleOnBlur={() => {
              //do nothing
            }}
            disabled={true}
          />
          <div>
            Requested Record(s)
            <div className="ml-1 text-sm list-disc">
              {viewOnlyRecordRequest?.requestedInformation
                ?.split(',')
                ?.map((item: any, index: number) => {
                  return <li key={index}>{item}</li>;
                })}
            </div>
          </div>
          <div></div>
          <span>
            <Typography variant="subtext">Current status</Typography>
            <Typography>{startCase(viewOnlyRecordRequest?.requestStatus)}</Typography>
          </span>
          <div></div>
          <SelectFormInput
            title="New Status"
            {...form.register(`requestStatus`)}
            listItems={editRequestSelectOptions(viewOnlyRecordRequest?.requestStatus ?? '')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              setNewRequestStatus(target.value);
              form.setValue('requestStatus', target.value);
            }}
            listItemsIsObject
            defaultValue={viewOnlyRecordRequest?.requestStatus}
            placeholderText="Select Policy Type"
          />
          <div></div>
          {['cancel', 'resend', 'reopen'].includes(newRequestStatus ?? '') && (
            <>
              <Switch
                fullRow
                title="Email"
                name="deliverByEmail"
                value={deliverByEmail}
                handleChange={(e) => {
                  setDeliverByEmail(e.target.value);
                  form.setValue('deliverByEmail', e.target.value);
                }}
              />
              <Switch
                fullRow
                title="Fax"
                name="deliverByFax"
                value={deliverByFax}
                handleChange={(e) => {
                  setDeliverByFax(e.target.value);
                  form.setValue('deliverByFax', e.target.value);
                }}
              />
              <Switch
                fullRow
                title="Mail"
                name="deliverByMail"
                value={deliverByMail}
                handleChange={(e) => {
                  setDeliverByMail(e.target.value);
                  form.setValue('deliverByMail', e.target.value);
                }}
              />
            </>
          )}
          {newRequestStatus === 'complete' && (
            <Switch
              fullRow
              title="Received Records?"
              name="isNoRecords"
              value={isNoRecords}
              handleChange={(e) => {
                setIsNoRecords(e.target.value);
                form.setValue('isNoRecords', e.target.value);
              }}
            />
          )}
        </div>
      </Form>
    </div>
  );
}

export default ViewOnlyRecordRequestsForm;
