export interface ArctrievalEntity {
  firmId: string;
  gladiateId: string;
  dateCreated?: string;
  dateModified?: string;

  accountType?: 'client' | 'provider' | 'user';
  arctrievalId?: string;
}

export const manualRequestStatusOptions = {
  cancelled: 'cancelled',
  received: 'received',
  resend: 're sent',
  requested: 'requested',
} as const;

export interface CommandForRecordsRequestType {
  requestId: string;
  command: string;
  body: { email?: boolean; fax?: boolean; usMail?: boolean };
}

export const manualRecordRequestLogMechanismOptions = {
  manual: 'manual',
} as const;

export interface RecordRequest {
  firmId?: string;
  requestId?: string;
  dateCreated?: string;
  dateModified?: string;

  arctrievalRequestId?: string;
  associatedMedicalTreatmentId?: string;
  dateSubmitted?: string;
  dateRequestRangeStart?: string;
  dateRequestRangeEnd?: string;
  dateReceived?: string;
  phoneNumber?: string;
  faxNumber?: string;
  email?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  streetAddress?: string;
  streetAddress2?: string;
  streetAddress3?: string;
  streetAddress4?: string;
  country?: string;
  receivedRecords?: '1' | '0';
  recordsClientContactId?: string;
  recordsProviderContactId?: string;
  recordsRequestorUsername?: string;
  requestedInformation?: string;
  requestStatus?: RequestStatusOptions;
  caseId?: string;
  logMechanism?: string;
}

export type RequestStatusOptions =
  | 'cancelled'
  | 'resend'
  | 'reopen'
  | 'received'
  | 'requested'
  | 'open'
  | 'completed';
