import FullCalendar from '@fullcalendar/react'; // must go before plugins

import {
  ColorMap,
  cn,
  convertFromEpoch,
  convertToEpoch,
  generateTimeStamps,
  getCurrentDateTime,
  getCurrentTimeRoundedTo15,
  useCreateCalendarEvent,
  useCreateEventReminders,
  useDeleteCalendarEvent,
  useGetCalendarEventAndReminders,
  useGetCalendarEvents,
  useGetPrimaryFirmCalendar,
  useGetUserNylasAccount,
  useGetUsersCalendars,
  useUpdateCalendarEvent,
} from '@colosseum/data';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  EventDates,
  EventReminderPayload,
  LabyrinthUpdateParams,
  NylasEvent,
  NylasMetadataType,
} from '@gladiate/types';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import CategoryCombobox, { CategoryItems } from '../CategoryCombobox/CategoryCombobox';
import CreateButton from '../CreateButton/CreateButton';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import InnerHtmlInput from '../InnerHtmlInput/InnerHtmlInput';
import Slideover from '../Slideover/Slideover';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Checkbox } from '../shadcn/Checkbox/Checkbox';
import { Form } from '../shadcn/Form/Form';
import { Input } from '../shadcn/Input/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { C } from '@fullcalendar/core/internal-common';

/* eslint-disable-next-line */
export interface CalendarProps {
  addEventDisabled?: boolean;
  defaultView?: string;
  caseId?: string;
  id?: string;
}

const formSchema = z.object({
  title: z.string().optional(),
  location: z.string().optional(),
  description: z.string().optional(),
  eventStartDate: z.string().optional(),
  eventStartTime: z.string().optional(),
  eventEndDate: z.string().optional(),
  eventEndTime: z.string().optional(),
});

const reminderFormSchema = z.object({
  reminderAmountF: z.number().nonnegative().min(1).max(99),
  reminderUnitsF: z.union([z.literal('1'), z.literal('7'), z.literal('31'), z.literal('365')]),
});

const INITIAL_DATE_RANGE = {
  startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
};

type ColorCode = typeof ColorMap extends { [key: string]: infer U } ? U : never;

type NylasMetadataTypeColorMap = {
  [key in NylasMetadataType]?: [ColorCode, ColorCode]; // [calendar cell color, text color]
};

const NylasMetadataObjectMap: NylasMetadataTypeColorMap = {
  case: [ColorMap['blue-400'], ColorMap['white']],
  'case-incident-date': [ColorMap['red-500'], ColorMap['white']],
  'case-statute-of-limitations': [ColorMap['red-500'], ColorMap['white']],
  contact: [ColorMap['purple-500'], ColorMap['white']],
  discovery: [ColorMap['red-300'], ColorMap['white']],
  expense: [ColorMap['teal-300'], ColorMap['white']],
  'litigation-details': [ColorMap['green-300'], ColorMap['black']],
  'litigation-discovery': [ColorMap['orange-300'], ColorMap['white']],
  'medical-record-request': [ColorMap['red-300'], ColorMap['white']],
  'medical-treatment-bill': [ColorMap['indigo-200'], ColorMap['white']],
  'medical-treatment-appointment': [ColorMap['indigo-300'], ColorMap['white']],
  'medical-treatment-history': [ColorMap['red-200'], ColorMap['white']],
  policy: [ColorMap['red-500'], ColorMap['white']],
  'settlement-proposal': [ColorMap['red-200'], ColorMap['white']],
  template: [ColorMap['red-500'], ColorMap['white']],
  'trust-account-transaction': [ColorMap['red-500'], ColorMap['white']],
  reminder: [ColorMap['red-500'], ColorMap['white']],
};

export function Calendar(props: CalendarProps) {
  const { caseId } = props;

  const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);

  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const [openSlideover, setOpenSlideover] = useState(false);
  const [calendarEventPayload, setCalendarEventPayload] = useState<
    {
      eventId?: string;
      title?: string;
      description?: string;
      location?: string;
    } & EventDates
  >({
    start_date: getCurrentDateTime().date,
    start_time: getCurrentDateTime().time,
    end_date: getCurrentDateTime().date,
    end_time: getCurrentDateTime().endTime,
    allDay: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [selectedMetadataFilters, setSelectedMetadataFilters] = useState<string[]>(
    Object.keys(NylasMetadataObjectMap),
  );

  const [reminders, setReminders] = useState<{ amount: number; reminder?: NylasEvent }[]>([]);
  const { data: nylasAccountData, isLoading: isNylasAccountLoading } = useGetUserNylasAccount();
  const { data: primaryCalendarData, isLoading: isNylasFirmLoading } = useGetPrimaryFirmCalendar();
  const { data: userCalendarsData, isLoading: isUserCalendarsLoading } = useGetUsersCalendars();
  const { data: individualEventData } = useGetCalendarEventAndReminders(
    calendarEventPayload?.eventId ?? '',
  );
  const isGladiateEvent = individualEventData?.data?.eventCalendarId || !isEditing; // if creating we also want this to be true

  const userCalendars = userCalendarsData?.data ?? [];

  const timeStamps = generateTimeStamps(0, 24, 15);

  useEffect(() => {
    const baseEvent = individualEventData?.data;
    const eventReminders = individualEventData?.data?.reminders ?? [];

    const reminderArray = eventReminders.map((reminder) => ({
      amount: Math.abs((reminder.eventStartTime - baseEvent?.eventStartTime) / 60 / 60),
      reminder: reminder,
    }));
    setReminders(reminderArray);
  }, [individualEventData]);

  const eventQueries = useGetCalendarEvents({
    calendars: userCalendars,
    dateRange,
    caseId,
  });

  const events = eventQueries
    ?.map((eventQuery) => {
      return eventQuery?.data?.data ?? [];
    })
    .flat();

  const navigate = useNavigate();

  const primaryCalendarId = primaryCalendarData?.data?.calendarId ?? '';

  //this is a placeholder for the mutation
  const createEventMutation = useCreateCalendarEvent(primaryCalendarId);
  const updateEventMutation = useUpdateCalendarEvent(primaryCalendarId);
  const deleteEventMutation = useDeleteCalendarEvent(primaryCalendarId);
  const createReminderMutation = useCreateEventReminders(primaryCalendarId);

  const filteredEvents = events?.filter((event: NylasEvent) => {
    const isCalendarSelected = selectedCalendars.includes(event.eventCalendarId);

    const matchesMetadataFilter = selectedMetadataFilters.includes(
      event.eventMetadata?.objectType ?? '',
    );

    if (event.eventMetadata?.eventType) {
      return (
        event.eventMetadata?.eventType === 'reminder' &&
        selectedMetadataFilters.includes('reminder')
      );
    } else if (event.eventMetadata?.objectType) {
      return isCalendarSelected && matchesMetadataFilter;
    } else {
      return isCalendarSelected;
    }
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: calendarEventPayload,
  });

  const reminderForm = useForm<z.infer<typeof reminderFormSchema>>({
    resolver: zodResolver(reminderFormSchema),
    mode: 'onSubmit',
  });

  function addToReminders() {
    const reminderAmountF = reminderForm.getValues('reminderAmountF');
    const reminderUnitsF = reminderForm.getValues('reminderUnitsF');
    setReminders([...reminders, { amount: parseInt(reminderUnitsF) * reminderAmountF * 24 }]);
  }

  const modifiedEvents = filteredEvents?.map((event: NylasEvent) => {
    const eventObject = {
      id: event.eventId,
      title: event.eventTitle,
      allDay: false,
      color: event?.eventMetadata?.objectType
        ? NylasMetadataObjectMap?.[event?.eventMetadata?.objectType]?.[0]
        : ColorMap['sky-600'],
      textColor: event?.eventMetadata?.objectType
        ? NylasMetadataObjectMap?.[event?.eventMetadata?.objectType]?.[1]
        : ColorMap['white'],
    } as {
      id: string;
      title: string;
      allDay: boolean;
      color: string;
      textColor: string;
      start?: Date;
      end?: Date;
    };

    switch (event.eventWhen.object) {
      case 'date':
        eventObject.start = dayjs(event.eventWhen.date).toDate();
        eventObject.end = dayjs(event.eventWhen.date).toDate();
        eventObject.allDay = true;
        break;

      case 'datespan':
        if (event.eventWhen.start_date && event.eventWhen.end_date) {
          eventObject.start = dayjs(event.eventWhen.start_date).toDate();
          eventObject.end = dayjs(event.eventWhen.end_date).add(1, 'day').toDate();
          eventObject.allDay = true;
        }
        break;

      case 'timespan':
        if (event.eventWhen.start_time && event.eventWhen.end_time) {
          eventObject.start = dayjs.unix(event.eventWhen.start_time).toDate();
          eventObject.end = dayjs.unix(event.eventWhen.end_time).toDate();
        }
        break;

      default:
        return {};
    }

    return eventObject;
  });

  useEffect(() => {
    if (openSlideover === true && !calendarEventPayload.eventId) {
      setCalendarEventPayload({
        start_date: dayjs().format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        start_time: timeStamps[getCurrentTimeRoundedTo15({ offset15Interval: '0' })],
        end_time: timeStamps[getCurrentTimeRoundedTo15({ offset15Interval: '4' })],
        allDay: false,
      });
    }
    if (openSlideover === false) {
      setIsEditing(false);
      setCalendarEventPayload({
        start_time: timeStamps[getCurrentTimeRoundedTo15({ offset15Interval: '0' })],
        end_time: timeStamps[getCurrentTimeRoundedTo15({ offset15Interval: '4' })],
        allDay: false,
      });
      reminderForm.reset();
    } else {
      form.setValue('title', calendarEventPayload.title);
      form.setValue('description', calendarEventPayload.description);
      form.setValue('location', calendarEventPayload.location);
    }
  }, [openSlideover]);

  useEffect(() => {
    if (
      dayjs(form.getValues('eventEndDate')).valueOf() <
      dayjs(form.getValues('eventStartDate')).valueOf()
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
    if (form.getValues('title') === '') {
      setSaveDisabled(true);
    }
  }, [form.watch()]);

  useEffect(() => {
    if (caseId) {
      setSelectedCalendars([primaryCalendarId]);
    } else {
      setSelectedCalendars(userCalendars.map((calendar) => calendar.calendarId));
    }
  }, [isUserCalendarsLoading]);

  if (isNylasAccountLoading || isNylasFirmLoading || isUserCalendarsLoading) {
    return (
      <div className="relative">
        <div className="absolute z-20 p-3 font-semibold text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ">
          <GladiateLoader />
        </div>
        <div className="opacity-50">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
          />
        </div>
      </div>
    );
  }

  if (nylasAccountData === undefined || primaryCalendarData === undefined) {
    return (
      <div className="relative mt-5">
        <button
          onClick={() => navigate('/profile')}
          className="absolute z-10 p-3 font-semibold text-white transform -translate-x-1/2 -translate-y-1/2 rounded-md shadow-lg hover:bg-atlantic-blue top-1/2 left-1/2 bg-sky-blue"
        >
          Connect Calendar
        </button>
        <div className="rounded-lg pointer-events-none opacity-30">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
          />
        </div>
      </div>
    );
  }

  const calendarEventFilters: CategoryItems[] = [
    {
      accounting: [
        { title: 'Expense', categoryItemKey: 'expense' },
        {
          title: 'Trust Account Transaction',
          categoryItemKey: 'trust-account-transaction',
        },
      ],
    },
    {
      medicals: [
        {
          title: 'Appointments',
          categoryItemKey: 'medical-treatment-appointment',
        },
        {
          title: 'Bills',
          categoryItemKey: 'medical-treatment-bill',
        },
        {
          title: 'Record Requests',
          categoryItemKey: 'medical-record-request',
        },
        {
          title: 'Treatment History',
          categoryItemKey: 'medical-treatment-history',
        },
      ],
    },
    {
      incident: [
        { title: 'Incident Date', categoryItemKey: 'case-incident-date' },
        { title: 'Policy', categoryItemKey: 'policy' },
        {
          title: 'Statute of Limitation',
          categoryItemKey: 'case-statute-of-limitations',
        },
      ],
    },
    {
      litigation: [
        { title: 'Details', categoryItemKey: 'litigation-details' },
        { title: 'Discovery', categoryItemKey: 'litigation-discovery' },
      ],
    },
    {
      negotiations: [
        {
          title: 'Settlement Proposal',
          categoryItemKey: 'settlement-proposal',
        },
      ],
    },
    {
      reminders: [{ title: 'Reminders', categoryItemKey: 'reminder' }],
    },
  ];

  const userCalendarFilterObj = userCalendars.reduce(
    (acc, calendar) => {
      calendar.primaryCalendar === true
        ? acc.firm.push({
            title: calendar.calendarName,
            categoryItemKey: calendar.calendarId,
          })
        : acc.personal.push({
            title: calendar.calendarName,
            categoryItemKey: calendar.calendarId,
          });
      return acc;
    },
    {
      firm: [],
      personal: [],
    } as {
      firm: { title: string; categoryItemKey: string }[];
      personal: { title: string; categoryItemKey: string }[];
    },
  );

  const userCalendarFilters: CategoryItems[] = [
    { personal: userCalendarFilterObj.personal },
    { firm: userCalendarFilterObj.firm },
  ];

  return (
    <>
      <div className="mt-5">
        <div className="flex items-center justify-between mb-3">
          <div className="flex justify-end gap-2 mb-4">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline" size="sm" className="h-8 border-dashed">
                  <CalendarIcon className="w-5 h-5 mr-2 " />
                  Calendars
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <CategoryCombobox
                  listObject={userCalendarFilters}
                  selectedValues={selectedCalendars}
                  setSelectedValues={setSelectedCalendars}
                  searchPlaceholder="Search for a calendar"
                />
              </PopoverContent>
            </Popover>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline" size="sm" className="h-8 border-dashed">
                  <FunnelIcon className="w-5 h-5 mr-2 " />
                  Event Filter
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <CategoryCombobox
                  listObject={calendarEventFilters}
                  selectedValues={selectedMetadataFilters}
                  setSelectedValues={setSelectedMetadataFilters}
                  searchPlaceholder="Search for an event type"
                />
              </PopoverContent>
            </Popover>
          </div>
          {!props.addEventDisabled && (
            <div className="flex items-center justify-between ">
              <button
                onClick={() => {
                  setOpenSlideover(true);
                }}
                type="button"
                className={
                  ' whitespace-nowrap px-3 sm:px-0 sm:w-36 inline-flex items-center justify-center text-sm font-medium text-white  border border-transparent rounded-md shadow-sm    py-2 focus:outline-none focus:ring-2 bg-atlantic-blue focus:ring-atlantic-blue focus:ring-offset-2 '
                }
              >
                <div>
                  <div className="flex items-center">
                    <p className="hidden sm:block">Add Event</p>
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>

        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView={props.defaultView ?? 'dayGridMonth'}
          events={modifiedEvents}
          datesSet={({ start, end, view }) => {
            const startDateFormatted = dayjs(start).format('YYYY-MM-DD');
            const endDateFormatted = dayjs(end).format('YYYY-MM-DD');

            setDateRange({
              startDate: startDateFormatted,
              endDate: endDateFormatted,
            });
          }}
          eventClick={(info) => {
            setIsEditing(true);

            const event = events?.find((event: any) => event.eventId === info.event.id);

            if (event?.eventWhen.object === 'date') {
              setCalendarEventPayload({
                eventId: event?.eventId,
                title: event?.eventTitle,
                description: event?.eventDesc,
                location: event?.eventLocation ?? '',
                start_date: event?.eventWhen.date,
                end_date: event?.eventWhen.date,
                allDay: true,
              });
              setOpenSlideover(true);
            }

            if (event?.eventWhen.object === 'datespan') {
              setCalendarEventPayload({
                eventId: event?.eventId,
                title: event?.eventTitle,
                description: event?.eventDesc,
                location: event?.eventLocation ?? '',
                start_date: event?.eventWhen.start_date,
                end_date: event?.eventWhen.end_date,
                allDay: true,
              });
              setOpenSlideover(true);
            }

            if (event?.eventWhen.object === 'timespan') {
              if (!event?.eventWhen.start_time || !event?.eventWhen.end_time) {
                return;
              }

              const eventWhen = convertFromEpoch({
                start_time: event?.eventWhen.start_time,
                end_time: event?.eventWhen.end_time,
              });
              setCalendarEventPayload({
                eventId: event?.eventId,
                title: event?.eventTitle,
                description: event?.eventDesc,
                location: event?.eventLocation ?? '',
                allDay: false,
                ...eventWhen,
              });
              setOpenSlideover(true);
            }
          }}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listYear',
          }}
        />
      </div>

      <Slideover
        title={'Event Details'}
        description={'Fill out the details of your event below.'}
        open={openSlideover}
        setOpen={setOpenSlideover}
        displayDeleteButton={false}
        typing={false}
        bottomButtons={
          <div className="flex justify-between p-4 border-t border-gray-300">
            <Button
              type="button"
              disabled={!isGladiateEvent}
              onClick={() => {
                if (isEditing) {
                  // Delete the event
                  deleteEventMutation.mutateAsync(calendarEventPayload.eventId ?? '').then(() => {
                    setOpenSlideover(false);
                  });
                } else {
                  setOpenSlideover(false);
                }
              }}
              className={cn(
                isEditing
                  ? ' px-4 py-2 text-sm font-medium text-red-500 border border-red-500 rounded-md shadow-sm hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
                  : 'inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-sky-blue',
              )}
            >
              {createEventMutation.isLoading ||
              updateEventMutation.isLoading ||
              deleteEventMutation.isLoading ? (
                <GladiateLoader height={20} />
              ) : (
                <div> {isEditing ? 'Delete' : 'Cancel'}</div>
              )}
            </Button>
            <button
              type="button"
              onClick={() => {
                const title = form.getValues('title');
                const location = form.getValues('location');

                const { start_time, end_time, start_date, end_date, allDay, ...rest } =
                  calendarEventPayload;

                const whenObject = allDay
                  ? start_date === end_date
                    ? { date: start_date ?? '' }
                    : {
                        start_date: start_date ?? '',
                        end_date: end_date ?? '',
                      }
                  : {
                      ...convertToEpoch({
                        start_date,
                        end_date,
                        start_time,
                        end_time,
                        allDay,
                      }),
                    };
                const remindersToAdd = reminders
                  .filter((reminder) => !reminder.reminder)
                  .map((reminder) => reminder.amount);

                if (isEditing) {
                  updateEventMutation
                    .mutateAsync({
                      ...rest,
                      title,
                      eventId: calendarEventPayload.eventId,
                      when: whenObject,
                      metadata: {
                        key1: props.caseId ?? '',
                      },
                      location,
                    })
                    .then(() => {
                      createReminderMutation.mutateAsync({
                        calendarId: primaryCalendarId,
                        eventId: calendarEventPayload.eventId ?? '',
                        reminders: {
                          eventNotificationTimes: remindersToAdd,
                        } as EventReminderPayload,
                      });
                    })
                    .then(() => {
                      setOpenSlideover(false);
                    });
                } else {
                  createEventMutation
                    .mutateAsync({
                      ...rest,
                      title,
                      when: whenObject,
                      metadata: {
                        key1: props.caseId ?? '',
                      },
                      location,
                    })
                    .then((res) => {
                      const eventId = res.data?.eventId;
                      createReminderMutation
                        .mutateAsync({
                          calendarId: primaryCalendarId,
                          eventId,
                          reminders: {
                            eventNotificationTimes: remindersToAdd,
                          } as EventReminderPayload,
                        })
                        .then(() => {
                          setOpenSlideover(false);
                        });
                    });
                }
              }}
              disabled={saveDisabled || !isGladiateEvent}
              className={cn(
                'inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm ',
                saveDisabled || !isGladiateEvent
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-sky-blue hover:bg-atlantic-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-sky-blue',
              )}
            >
              {createEventMutation.isLoading ||
              updateEventMutation.isLoading ||
              deleteEventMutation.isLoading ? (
                <GladiateLoader white height={20} />
              ) : (
                'Save'
              )}
            </button>
          </div>
        }
        onClose={() => {
          reminderForm.reset();
          setReminders([]);
          form.reset({
            title: '',
            location: '',
            description: '',
            eventStartDate: dayjs().format('YYYY-MM-DD'),
            eventEndDate: dayjs().format('YYYY-MM-DD'),
          });
        }}
      >
        <div>
          <Form {...form}>
            <div className="grid grid-cols-2 gap-y-5 gap-x-3">
              <div className="col-span-2">
                <TextFormInput
                  {...form.register('title')}
                  title="Event Title"
                  disabled={!isGladiateEvent}
                />
              </div>

              <div className="col-span-2">
                <TextFormInput
                  {...form.register('location')}
                  title="Location"
                  disabled={!isGladiateEvent}
                />
              </div>
              {isGladiateEvent ? (
                <div className="col-span-2">
                  <TextFormInput
                    {...form.register('description')}
                    title="Description"
                    type="textarea"
                    disabled={!isGladiateEvent}
                  />
                </div>
              ) : (
                <InnerHtmlInput
                  label="Description"
                  value={calendarEventPayload.description}
                  variant="textarea"
                />
              )}

              <div className="flex items-center col-span-2 gap-x-4">
                All Day Event{' '}
                <Checkbox
                  checked={calendarEventPayload.allDay}
                  onCheckedChange={() => {
                    setCalendarEventPayload({
                      ...calendarEventPayload,
                      allDay: !calendarEventPayload.allDay,
                    });
                  }}
                  disabled={!isGladiateEvent}
                />
              </div>

              {calendarEventPayload.allDay ? (
                <>
                  <CalendarFormInput
                    {...form.register(`eventStartDate`)}
                    title="Start Date"
                    hideSyncButton={true}
                    defaultToToday={true}
                    defaultValue={calendarEventPayload.start_date}
                    disabled={!isGladiateEvent}
                  />
                  <CalendarFormInput
                    {...form.register(`eventEndDate`)}
                    title="End Date"
                    hideSyncButton={true}
                    defaultToToday={true}
                    defaultValue={calendarEventPayload.end_date}
                    disabled={!isGladiateEvent}
                  />
                </>
              ) : (
                <>
                  <CalendarFormInput
                    {...form.register(`eventStartDate`)}
                    title="Start Date"
                    hideSyncButton={true}
                    defaultValue={calendarEventPayload.start_date}
                    handleOnChange={(e) => {
                      setCalendarEventPayload((prev) => ({
                        ...prev,
                        start_date: (e.target as HTMLInputElement).value,
                      }));
                    }}
                    disabled={!isGladiateEvent}
                  />
                  <SelectFormInput
                    {...form.register(`eventStartTime`)}
                    title="Start Time"
                    listItems={generateTimeStamps(0, 24, 15)}
                    listItemsIsObject
                    defaultValue={calendarEventPayload.start_time}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      setCalendarEventPayload((prev) => ({
                        ...prev,
                        start_time: (e.target as HTMLInputElement).value,
                      }));
                    }}
                    placeholderText="Select a Time"
                    disabled={!isGladiateEvent}
                  />
                  <CalendarFormInput
                    {...form.register(`eventEndDate`)}
                    title="End Date"
                    hideSyncButton={true}
                    defaultValue={calendarEventPayload.end_date}
                    handleOnChange={(e) => {
                      setCalendarEventPayload((prev) => ({
                        ...prev,
                        end_date: (e.target as HTMLInputElement).value,
                      }));
                    }}
                    disabled={!isGladiateEvent}
                  />
                  <SelectFormInput
                    {...form.register(`eventEndTime`)}
                    title="End Time"
                    listItems={generateTimeStamps(0, 24, 15)}
                    listItemsIsObject
                    defaultValue={calendarEventPayload.end_time}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      setCalendarEventPayload((prev) => ({
                        ...prev,
                        end_time: (e.target as HTMLInputElement).value,
                      }));
                    }}
                    placeholderText="Select a Time"
                    disabled={!isGladiateEvent}
                  />
                </>
              )}
            </div>
          </Form>
          <div className="w-full mt-5 border rounded-lg shadow-md">
            <div className="flex items-center justify-between p-3 font-semibold bg-gray-100 border-b rounded-t-lg">
              Reminders
            </div>

            <div>
              {individualEventData?.data?.eventType === 'reminder' ? (
                <div className="p-4 space-y-3 text-sm text-gray-500">
                  A reminder cannot be created for this type of event
                </div>
              ) : (
                <>
                  {reminders.map((reminderEvent, index) => (
                    <div key={index} className="flex items-center justify-between p-3">
                      <div className="flex items-center gap-x-2">
                        <div className="flex items-center justify-center w-8 h-8 text-white rounded-md bg-atlantic-blue">
                          <div className="text-sm font-semibold">
                            {Math.abs(reminderEvent.amount / 24)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm font-semibold">Reminder</div>
                          <div className="text-xs text-gray-500">
                            {reminderEvent.amount === 1 ? 'day before' : 'days before'}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          if (reminderEvent?.reminder?.eventId) {
                            deleteEventMutation.mutateAsync(reminderEvent.reminder.eventId);
                          } else {
                            setReminders(reminders.filter((_, i) => i !== index));
                          }
                        }}
                      >
                        <div className="flex items-center justify-center w-8 h-8 text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-400 hover:text-white fadeAnimation">
                          <TrashIcon className="w-5 h-5" />
                        </div>
                      </button>
                    </div>
                  ))}
                  <div className="p-2">
                    <div className="p-2 border border-gray-300 border-dashed rounded-lg ">
                      <Form {...reminderForm}>
                        <form>
                          <div className="flex flex-col gap-2">
                            <div className="grid w-full grid-cols-2 gap-2">
                              <Input
                                type="number"
                                {...reminderForm.register('reminderAmountF')}
                                title="Reminder Time"
                                min={1}
                                label="Reminder Time"
                                defaultValue={1}
                              />
                              <SelectFormInput
                                {...reminderForm.register('reminderUnitsF')}
                                title="Period"
                                listItems={{
                                  Days: '1',
                                  Weeks: '7',
                                  Months: '31',
                                  Years: '365',
                                }}
                                listItemsIsObject
                                handleOnChange={(e: React.SyntheticEvent) => {}}
                              />
                            </div>
                            <Button
                              type="button"
                              onClick={() => {
                                addToReminders();
                              }}
                              variant="primary"
                              disabled={
                                reminderForm.getValues('reminderAmountF') === undefined ||
                                reminderForm.getValues('reminderUnitsF') === undefined
                              }
                            >
                              Add Reminder
                            </Button>
                          </div>
                        </form>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Slideover>
    </>
  );
}

export default Calendar;
