import { OfflineEventType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateOfflineEvent } from '../../../lib/react-query/offline-events';

const formSchema = z.object({
  busy: z.boolean().optional(),
  caseId: z.string().optional(),
  categoryId: z.string().optional(),
  date: z.string().optional(),
  description: z.string().optional(),
  endDateTime: z.string().optional(),
  endDate: z.string().optional(),
  endTime: z.string().optional(),
  location: z.string().optional(),
  owner: z.string().optional(),
  startDateTime: z.string().optional(),
  startTime: z.string().optional(),
  startDate: z.string().optional(),
  synced: z.string().optional(),
  time: z.string().optional(),
  title: z.string().optional(),
  type: z.string().optional(),
  attendees: z.array(z.string()).optional(),
});

interface useCaseOfflineEventFormProps {
  event?: OfflineEventType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCaseOfflineEventForm = (props: useCaseOfflineEventFormProps) => {
  const { event, setTyping } = props;
  // TODO: ADD THIS
  const updateEvent = useUpdateOfflineEvent();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    // @ts-expect-error Need to type attendees properly
    defaultValues: event,
  });
  function handleUpdate(
    name: keyof Omit<OfflineEventType, 'eventId' | 'dateCreated' | 'dateModified' | 'firmId'>,
    value?: string,
  ) {
    if (!event) {
      //TODO: Determine if this will ever happen - enqueue snackbar?
      return;
    }
    if (form.getFieldState(name).isDirty) {
      updateEvent
        .mutateAsync({
          eventId: props.event?.eventId ?? '',
          [name]: value,
        })
        .then(() => {
          setTyping(false);
          form.resetField(name, { defaultValue: value });
        });
    }
  }
  return { form, handleUpdate };
};
