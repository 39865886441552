import { useUpdateDiscovery } from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  ClientSelectDropdown,
  Form,
  MultiSelectFormInput,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import { DiscoveryType, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

/* eslint-disable-next-line */
export interface DiscoveryFormProps {
  caseId: string;
  discovery?: DiscoveryType;
  setTyping: (value: boolean) => void;
}

const formSchema = z.object({
  sentOrReceived: z.string().optional(),
  typeOfDiscovery: z.string().optional(),
  typesOfDiscovery: z.array(z.string()).optional(),
  policyType: z.string().optional(),
  dateServed: z.string().optional(),
  setNumber: z.string().optional(),
  methodOfDelivery: z.string().optional(),
  responseDueDate: z.string().optional(),
  caseContactConnectionId: z.string().optional(),
});

const discoveryTypeOptionsArray = [
  { title: 'Form Interrogatories', value: 'formInterrogatories' },
  { title: 'Special Interrogatories', value: 'specialInterrogatories' },
  { title: 'Requests for Production', value: 'requestsForProduction' },
  { title: 'Requests for Admission', value: 'requestsForAdmission' },
];

export function DiscoveryForm(props: DiscoveryFormProps) {
  const { caseId, discovery, setTyping } = props;
  // const updateExpense = useUpdateExpense();
  const updateDiscovery = useUpdateDiscovery();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: props.discovery,
  });

  function handleUpdate(
    field: keyof z.infer<typeof formSchema>,
    update?: string | number | boolean | (string | undefined)[],
  ) {
    if (!props.discovery) {
      return;
    }
    updateDiscovery
      .mutateAsync({
        discoveryId: props.discovery?.discoveryId,
        [field]: update,
      })
      .then(() => {
        setTyping(false);
      });
  }

  return (
    <div className="">
      <Form {...form}>
        <form>
          {discovery && (
            <ClientSelectDropdown
              caseId={caseId}
              form={form}
              caseItemType="discovery"
              caseItemId={discovery?.discoveryId}
            />
          )}
          <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
            <div>
              <SelectFormInput
                title="Sent or Received"
                {...form.register(`sentOrReceived`)}
                listItems={{
                  Sent: 'sent',
                  Received: 'received',
                }}
                listItemsIsObject
                defaultValue={props.discovery?.sentOrReceived}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('sentOrReceived', target.value);
                }}
                placeholderText="Select Sent or Recieved"
              />
            </div>
            <div></div>
            <div className="flex flex-col w-full col-span-2 ml-1 rounded-md gap-y-4">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Defendant"
                roleOnCase={caseContactConnectionOptions.defendant}
                itemType="discovery"
                itemId={discovery?.discoveryId}
              />
            </div>
            <div className="flex flex-col w-full col-span-2 ml-1 rounded-md gap-y-4">
              <CaseContactConnectionLinker
                caseId={caseId}
                title="Serving Discovery"
                roleOnCase={caseContactConnectionOptions.servingDiscovery}
                itemType="discovery"
                itemId={discovery?.discoveryId}
                showMultiple
              />
            </div>
            <MultiSelectFormInput
              title="Type of Discovery"
              {...form.register(`typesOfDiscovery`)}
              selectedOptions={discovery?.typesOfDiscovery ?? []}
              options={discoveryTypeOptionsArray}
              optionsIsObject
              onChange={(option: string) => {
                const addedDiscoveries = discovery?.typesOfDiscovery;
                const arr = addedDiscoveries ?? [];
                if (arr.includes(option)) {
                  const index = arr.indexOf(option);
                  if (index > -1) {
                    arr.splice(index, 1);
                  }
                } else {
                  arr.push(option);
                }
                handleUpdate('typesOfDiscovery', arr);
              }}
            />
            <SelectFormInput
              title="Method of Delivery"
              {...form.register(`methodOfDelivery`)}
              listItems={{
                Email: 'email',
                'Certified Mail': 'certifiedMail',
                'Hand-delivered': 'handDelivered',
                'Court E-Filing': 'courtEFiling',
                'File-sharing': 'fileSharing',
                Fax: 'fax',
              }}
              listItemsIsObject
              defaultValue={props.discovery?.methodOfDelivery}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('methodOfDelivery', target.value);
              }}
              placeholderText="Select Method of Delivery"
            />
            <CalendarFormInput
              {...form.register(`dateServed`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('dateServed', dayjs(target.value).format('YYYY-MM-DD'));
              }}
              title="Date Served"
              resourceTypeObj={{
                type: 'litigation-discovery',
                id: discovery?.discoveryId,
              }}
            />
            <CalendarFormInput
              {...form.register(`responseDueDate`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('responseDueDate', dayjs(target.value).format('YYYY-MM-DD'));
              }}
              title="Response Due"
              resourceTypeObj={{
                type: 'litigation-discovery',
                id: discovery?.discoveryId,
              }}
            />

            <TextFormInput
              {...form.register(`setNumber`)}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('setNumber', target.value);
              }}
              handleOnChange={() => setTyping(true)}
              title="Set Number"
            />
          </div>
        </form>
      </Form>
    </div>
  );
}

export default DiscoveryForm;
