import { CaseAccessType, CoCounselType, HurinUserType } from '@gladiate/types';
import { useGetCases } from '../lib/react-query/case';
import { useGetAllCaseAccesses, useGetCoCounsel, useGetFirmUsers } from '../lib/react-query/firm';

export type CompleteCoCounselType = HurinUserType &
  CoCounselType & { caseAccesses: (CaseAccessType & { caseTitle: string })[] };

export const useGetCoCounselsComplete = () => {
  const firmUsers = useGetFirmUsers(true);
  const coCounselRes = useGetCoCounsel();
  const caseAccesses = useGetAllCaseAccesses();
  const cases = useGetCases();
  const coCounsels = coCounselRes?.data?.data || [];
  const completeCoCounsels: CompleteCoCounselType[] = coCounsels.map((coCounsel) => {
    const selectedUserDetails = firmUsers.data?.data?.find(
      (user) => user.username === coCounsel.username,
    );
    const userCaseAccesses =
      caseAccesses?.data?.data
        ?.map((caseAccess) => {
          const caseAccessCaseData = cases?.data?.data?.find(
            (caseData) => caseData.caseId === caseAccess.caseId,
          );
          return {
            ...caseAccess,
            caseTitle: caseAccessCaseData?.caseTitle ?? '',
          };
        })
        .filter((caseAccess) => caseAccess.userAccessId.split('-')[0] === coCounsel.username) ?? [];
    return {
      ...coCounsel,
      ...selectedUserDetails,
      caseAccesses: userCaseAccesses,
    };
  });
  return {
    ...coCounselRes,
    data: {
      ...coCounselRes.data,
      data: completeCoCounsels,
    },
    isLoading: coCounselRes?.isLoading || caseAccesses?.isLoading || firmUsers?.isLoading,
  };
};
