import { ItemTagConnectionType, StaffType, TeamType } from '@gladiate/types';
import { Row, Table } from '@tanstack/react-table';
import dayjs from 'dayjs';

export function stringIncludesFilterFn<T>(row: Row<T>, id: string, value: string[]) {
  const rowValue: string = row.getValue(id);
  if (rowValue === null || rowValue === undefined) {
    // This needs to be done since we have conditional key returns
    return false;
  }
  if (typeof value !== 'string') {
    return value.some((val) => rowValue?.toLowerCase().includes(val?.toLowerCase()));
  } else {
    return rowValue?.toLowerCase()?.includes(String(value)?.toLowerCase());
  }
}

export function containsTagsFilterFn<T>(row: Row<T>, id: string, value: string[]) {
  const rowValue: ItemTagConnectionType[] = row.getValue(id);
  if (rowValue === null || rowValue === undefined) {
    // This needs to be done since we have conditional key returns
    return false;
  }
  return value.some((val) => rowValue?.some((tag) => tag?.tagAttributes?.title === val));
}

export function containsAssigneeFilterFn<T>(row: Row<T>, id: string, value: string[]) {
  const rowValue: StaffType[] = row.getValue(id);
  if (rowValue === null || rowValue === undefined) {
    // This needs to be done since we have conditional key returns
    return false;
  }
  return value.some((val) => rowValue?.some((assignee) => assignee?.username === val));
}

export function containsTeamFilterFn<T>(row: Row<T>, id: string, value: string[]) {
  const rowValue: TeamType[] = row.getValue(id);
  if (rowValue === null || rowValue === undefined) {
    // This needs to be done since we have conditional key returns
    return false;
  }
  return value.some((val) => rowValue?.some((assignee) => assignee?.teamId === val));
}

export function dateEndFilterFn<T>(row: Row<T>, id: string, value: string): boolean {
  const rowValue: Date = new Date(row.getValue(id));
  if (!rowValue) {
    return false;
  }
  const endDate = new Date(value);
  return rowValue <= endDate;
}

export function dateRangeFilterFn<T>(
  row: Row<T>,
  id: string,
  value: string[], // Change the type to string[]
) {
  const rowValue: string = row.getValue(id);
  if (!rowValue) {
    return false;
  }
  const rowValueDate = dayjs(rowValue).isValid() ? dayjs(row.getValue(id)) : null;

  if (!rowValueDate) {
    return false;
  }

  // Convert string array to Date objects for startDate and endDate
  const [startDateString, endDateString] = value;
  const startDate = startDateString
    ? dayjs(startDateString).isValid()
      ? dayjs(startDateString)
      : null
    : null;
  const endDate = endDateString
    ? dayjs(endDateString).isValid()
      ? dayjs(endDateString)
      : null
    : null;

  if (startDate && endDate) {
    return rowValueDate >= startDate && rowValueDate <= endDate;
  } else if (startDate) {
    return rowValueDate >= startDate;
  } else if (endDate) {
    return rowValueDate <= endDate;
  }
  return true;
}

export function conflictFilterFn<T>(row: Row<T>, id: string, value: string): boolean {
  const rowValue: string = row.getValue(id);
  if (value.includes('yes') && value.includes('no')) {
    return true;
  } else if (value.includes('yes')) {
    return rowValue.length > 0;
  } else if (value.includes('no')) {
    return rowValue.length === 0;
  }
  return true;
}

export function rangeFilterFn<T>(
  row: Row<T>,
  id: string,
  value: string[], // Change the type to string[]
) {
  const rowValue: string | unknown[] = row.getValue(id);

  const formattedRowValue = ['string', 'number'].includes(typeof rowValue)
    ? Number(rowValue)
    : rowValue?.length; // If rowValue is an array, use the length of the array

  // Convert string array to Date objects for startDate and endDate
  const [minStr, maxStr] = value;

  const min = minStr ? Number(minStr) : null;
  const max = maxStr ? Number(maxStr) : null;

  if (rowValue) {
    if (min && max) {
      return formattedRowValue >= min && formattedRowValue <= max;
    } else if (min) {
      return formattedRowValue >= min;
    } else if (max) {
      return formattedRowValue <= max;
    }
  } else {
    // if row value is null, we want to keep null items if only sorting by max
    if (min && max) {
      return false;
    } else if (min) {
      return false;
    } else if (max) {
      return true;
    }
  }
  return true;
}

export const getRangeValue = (index: number, columnTitle: string, table: Table<any>) => {
  const column = table.getColumn(columnTitle);
  const selectedValues: Date[] = column?.getFilterValue() as Date[];
  if (!selectedValues || !selectedValues[index]) return;

  return new Date(selectedValues[index]);
};

export const onRangeSelect = (index: number, columnId: string, table: Table<any>, date?: Date) => {
  const column = table.getColumn(columnId);
  const selectedValues = (column?.getFilterValue() as Date[]) ?? [];
  if (date) {
    const dateWithoutTime = index === 0 ? dayjs(date).startOf('day') : dayjs(date).endOf('day');
    selectedValues[index] = dateWithoutTime.toDate();
  }
  column?.setFilterValue(selectedValues.length ? selectedValues : undefined);
};
