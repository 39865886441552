import { prettifyDateString } from '@colosseum/data';
import { AdobeAgreementStatus } from '@gladiate/types';
import Typography from '../Typography/Typography';

export interface AgreementStatusFormProps {
  agreementStatus?: AdobeAgreementStatus;
}

export function AgreementStatusForm(props: AgreementStatusFormProps) {
  const { agreementStatus } = props;
  const valueLines = [
    { label: 'Agreement Name', value: agreementStatus?.agreementName },
    { label: 'Status', value: agreementStatus?.status },
    {
      label: 'Recipient Emails',
      value: agreementStatus?.participants?.map((participart) => participart.email).join(', '),
    },
    {
      label: 'Date Created',
      value: prettifyDateString(agreementStatus?.dateCreated),
    },
  ];
  if (!agreementStatus) {
    return (
      <Typography variant="subtext">
        No Agreement Status found. It may have been deleted.
      </Typography>
    );
  }
  return (
    <div className="flex flex-col gap-y-2">
      {valueLines.map((line) => (
        <div className="flex items-center gap-x-2" key={line.label}>
          <Typography variant="subtext" size="md">
            {line.label}
          </Typography>
          <Typography>{line.value}</Typography>
        </div>
      ))}
    </div>
  );
}

export default AgreementStatusForm;
