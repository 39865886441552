import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';

import { cn } from '@colosseum/data';
import { buttonVariants } from '../Button/Button';

export const tabsListClass = 'py-4 text-gray-500 bg-transparent rounded-none border-b-gray-300';
export const tabTriggerClass =
  'flex items-center px-8 py-3 text-xl font-semibold text-gray-300 border-b-2 border-b-gray-300 data-[state=active]:border-atlantic-blue data-[state=active]:text-atlantic-blue';
export const tabClassSlideover = cn(
  tabTriggerClass,
  'text-sm px-4 py-2 data-[state=active]:text-white data-[state=active]:border-white data-[state=active]:bg-transparent',
);

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'inline-flex md:flex-nowrap md:h-11 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground',
      'flex-wrap',
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      buttonVariants({ variant: 'ghost', size: 'unset', textColor: 'default' }), // we want to use the ghost syles here
      'inline-flex items-center rounded-none hover:rounded-t-md justify-center whitespace-nowrap font-semibold px-3 py-2 text-sm ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm',
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    className?: string;
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
