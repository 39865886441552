import { useGetContact, useUpdateCustomAttributeValue } from '@colosseum/data';
import { ContactType, CustomAttributeType, CustomAttributeValueType } from '@gladiate/types';
import { useState } from 'react';
import ContactChip from '../ContactChip/ContactChip';
import { ContactDropdown } from '../ContactConnectionDropdown/ContactConnectionDropdown';

export interface CustomAtributeContactSelectProps {
  customAttributeValue: CustomAttributeValueType & CustomAttributeType;
  contacts?: ContactType[];
}
const ContactChipWithContactData = ({
  contactId,
  customAttributeValue,
}: {
  contactId: string;
  customAttributeValue: CustomAttributeValueType & CustomAttributeType;
}) => {
  const updateCustomAttributeValue = useUpdateCustomAttributeValue();
  const { data: contactData } = useGetContact(contactId);
  return (
    <ContactChip
      contactId={contactData?.data.contactId ?? ''}
      onDelete={(e) => {
        e.preventDefault();
        const removedValueArray = customAttributeValue?.attributeMultiValue?.filter(
          (attr) => contactId !== attr,
        );
        updateCustomAttributeValue.mutate({
          customAttributeValueId: customAttributeValue.customAttributeValueId,
          attributeMultiValue: removedValueArray,
        });
      }}
    />
  );
};

export function CustomAtributeContactSelect(props: CustomAtributeContactSelectProps) {
  const { customAttributeValue, contacts } = props;
  const updateCustomAttributeValue = useUpdateCustomAttributeValue();
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <>
      {customAttributeValue.attributeLabel}
      <div className="flex items-center gap-x-2">
        <div className="relative flex flex-wrap">
          {customAttributeValue?.attributeMultiValue?.map((item) => {
            return (
              <ContactChipWithContactData
                contactId={item}
                key={item}
                customAttributeValue={customAttributeValue}
              />
            );
          })}
        </div>
        <ContactDropdown
          contactOptions={contacts}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          linkingFunction={(data) => {
            updateCustomAttributeValue.mutate({
              customAttributeValueId: customAttributeValue.customAttributeValueId,
              attributeMultiValue: [
                ...(customAttributeValue?.attributeMultiValue ?? []),
                data?.contactId ?? '',
              ],
            });
          }}
        />
      </div>
    </>
  );
}

export default CustomAtributeContactSelect;
