import { cn, displayContactName, prettifyDateString } from '@colosseum/data';
import { MedicalTreatmentAppointment, type ContactType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

export const medicalTreatmentAppointmentColumns: ColumnDef<
  MedicalTreatmentAppointment & {
    medicalProvider?: ContactType;
    client?: ContactType;
    medicalTreatmentTypeTitle?: string;
  }
>[] = [
  {
    accessorFn: (row) => prettifyDateString(row.date),
    id: 'Appointment Date',
    header: renderHeader,
    sortingFn: 'dayjsDateTime',
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.medicalTreatmentTypeTitle,
    id: 'Medical Treatment Type',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (row.client ? displayContactName(row.client) : undefined),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      return row.medicalProvider ? displayContactName(row.medicalProvider) : undefined;
    },
    id: 'Medical Provider',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<string>();
      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Medical Provider>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row?.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
];
