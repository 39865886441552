// Dependencies
import {
  casesSectionMappings,
  scrollToCaseSection,
  useGetAuditsWithNotifications,
  useGetCaseContactConnectionsViewModel,
} from '@colosseum/data';
import {
  CommunicationLog,
  ResourceHistory,
  ResourceNotes,
  ResourceTasks,
  SlideoverContext,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { useContext, useEffect, useState } from 'react';
import CaseContactsTable from '../CaseContactsTable/CaseContactsTable';
import CaseSurveys from '../CaseSurveys/CaseSurveys';

export interface CollaborationHubProps {
  caseId: string;
}

export function CollaborationHub(props: CollaborationHubProps) {
  const { caseId } = props;
  const { setPendingSlideoverToOpen, pendingSlideoverToOpen } = useContext(SlideoverContext);
  const [activeTab, setActiveTab] = useState('notes');

  const audits = useGetAuditsWithNotifications({
    itemId: caseId,
    isCase: true,
  });

  const { data: caseContactConnectionsData } = useGetCaseContactConnectionsViewModel(caseId);

  useEffect(() => {
    const ref = document.querySelectorAll(
      `[data-case-submenu-item='${casesSectionMappings.collaborationHub}']`,
    )[0];

    if (
      pendingSlideoverToOpen?.type === 'communication' ||
      pendingSlideoverToOpen?.type === 'communicationLog'
    ) {
      setActiveTab('communication-log');
      scrollToCaseSection(ref, true);
    } else if (pendingSlideoverToOpen?.type === 'caseContactConnection') {
      setActiveTab('contacts');
      scrollToCaseSection(ref, true);
    } else if (pendingSlideoverToOpen?.type === 'caseSurvey') {
      setActiveTab('surveys');
      scrollToCaseSection(ref, true);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs defaultValue="notes" value={activeTab} onValueChange={setActiveTab}>
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger className={tabTriggerClass} key="notes" value="notes" data-cy="case-notes-tab">
          Notes
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} key="tasks" value="tasks" data-cy="case-tasks-tab">
          Tasks
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="communication-log"
          value="communication-log"
          data-cy="case-communication-log-tab"
        >
          Communication
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="surveys"
          value="surveys"
          data-cy="case-surveys-tab"
        >
          <span className="flex gap-x-2">Surveys</span>
        </TabsTrigger>
        <TabsTrigger
          className={tabTriggerClass}
          key="contacts"
          value="contacts"
          data-cy="case-contacts-tab"
        >
          <span className="flex gap-x-2">Contacts</span>
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} key="feed" value="feed" data-cy="case-feed-tab">
          <span className="flex gap-x-2">
            Feed
            {audits.notificationCount && audits.notificationCount !== '0' && (
              <div className="self-center data-[state=active]:no-underline no-underline w-6 h-5 text-xs text-white bg-orange-500 rounded-xl justify-center flex flex-col">
                {audits.notificationCount}
              </div>
            )}
          </span>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="notes">
        <ResourceNotes
          scrollable
          createType="case"
          resourceId={caseId ?? ''}
          showFilters
          caseId={caseId}
        />
      </TabsContent>
      <TabsContent value="tasks">
        <ResourceTasks createType="case" isPaginated resourceId={caseId} scrollable />
      </TabsContent>
      <TabsContent value="communication-log">
        <CommunicationLog
          contactIds={
            caseContactConnectionsData?.data?.map((connection) => connection.contact?.contactId) ??
            []
          }
        />
      </TabsContent>
      <TabsContent value="feed">
        <ResourceHistory
          isPaginated={true}
          audits={audits}
          isAuditsLoading={audits.isLoading}
          setNotificationsOnly={audits.setNotificationsOnly}
          notificationsOnly={audits.notificationsOnly}
        />
      </TabsContent>
      <TabsContent value="contacts">
        <CaseContactsTable />
      </TabsContent>
      <TabsContent value="surveys">
        <CaseSurveys />
      </TabsContent>
    </Tabs>
  );
}

export default CollaborationHub;
