import {
  CompleteCoCounselType,
  useCreateCaseAccess,
  useDeleteCaseAccess,
  useGetCoCounselsComplete,
} from '@colosseum/data';
import { FirmUserLookup, GladiateLoader } from '@colosseum/shared-ui';

export interface CaseCoCounselProps {
  caseId: string;
}

export function CaseCoCounsel(props: CaseCoCounselProps) {
  const { caseId } = props;
  const { data: coCounselData, isLoading: isCoCounselLoading } = useGetCoCounselsComplete();
  const coCounselsForCase = coCounselData.data.filter((coCounsel) => {
    return coCounsel.caseAccesses.some((caseAccess) => {
      return caseAccess.caseId === caseId;
    });
  });
  const addCaseAccess = useCreateCaseAccess();
  const deleteCaseAccess = useDeleteCaseAccess();

  return (
    <div className="mt-3 mb-5">
      {isCoCounselLoading && (
        <div className="flex flex-col justify-center h-60">
          <GladiateLoader height={50} className="text-atlantic-blue" />
        </div>
      )}
      <div className="mt-3 mb-5">
        <FirmUserLookup
          coCounselOnly
          key="coCounselId"
          noOptionsMessage="No Co-Counsels available."
          selectedUsers={coCounselsForCase}
          handleAdd={(user: string) => {
            return addCaseAccess.mutateAsync({
              caseId,
              username: user,
            });
          }}
          handleRemove={(user: CompleteCoCounselType) => {
            const currentCaseAccess = user.caseAccesses.find((caseAccess) => {
              return caseAccess.caseId === caseId;
            });
            return deleteCaseAccess.mutateAsync(currentCaseAccess?.userAccessId ?? '');
          }}
          allowMultiple
        />
      </div>
    </div>
  );
}

export default CaseCoCounsel;
