import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from '../shadcn/Command/Command';
import { Check } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { cn } from '@colosseum/data';

// TODO see if we can replace the input with this Checkbox
const Checkbox = () => {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div
      className={cn(
        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
        isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
      )}
      onClick={() => setIsSelected(!isSelected)}
    >
      <Check className={cn('h-4 w-4')} />
    </div>
  );
};

export interface CategoryItems {
  [key: string]: {
    title: string;
    categoryItemKey: string;
  }[];
}
/* eslint-disable-next-line */
export interface CategoryComboboxProps {
  listObject?: CategoryItems[];
  selectedValues?: React.ComponentState;
  setSelectedValues?: React.Dispatch<React.SetStateAction<any>>;
  searchPlaceholder?: string;
}

/**
 * @description A combobox that allows the user to select options within multiple categories.
 * @usage view `Calendar.tsx` for an example of how to build the listObject
 */
export function CategoryCombobox(props: CategoryComboboxProps) {
  const { listObject, selectedValues, setSelectedValues, searchPlaceholder = 'Search ...' } = props;

  const sortedListObject = listObject?.sort((a, b) => {
    const keyA = Object.keys(a)[0].toLowerCase();
    const keyB = Object.keys(b)[0].toLowerCase();
    return keyA.localeCompare(keyB);
  });

  const [values, setValues] = useState(selectedValues);

  useEffect(() => {
    setValues(selectedValues);
  }, [selectedValues]);

  return (
    <Command className="border rounded-lg shadow-md">
      <CommandInput placeholder={searchPlaceholder} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandSeparator />
        <CommandGroup>
          <CommandItem
            onSelect={() => {
              if (values.length > 0) {
                setSelectedValues && setSelectedValues([]);
              } else {
                setSelectedValues &&
                  setSelectedValues(
                    listObject?.flatMap((category) =>
                      category[Object.keys(category)[0]].map((item) => item.categoryItemKey),
                    ),
                  );
                setValues(selectedValues);
              }
            }}
            className="justify-center text-center"
          >
            {values.length > 0 ? <div>Clear Selection</div> : <div>Select All</div>}
          </CommandItem>
        </CommandGroup>
        {sortedListObject?.map((category) => {
          const categoryKey = Object.keys(category)[0];
          const categoryItemKeys = category[categoryKey].map((item) => item.categoryItemKey);
          if (category[categoryKey].length === 0) {
            return null;
          }
          return (
            <>
              <CommandSeparator />
              <CommandGroup heading={categoryKey} className="capitalize">
                {category[categoryKey]
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  .map((subItem) => (
                    <CommandItem key={subItem.categoryItemKey}>
                      <label key={subItem.categoryItemKey} className="w-full truncate">
                        <input
                          type="checkbox"
                          className={cn(
                            'w-4 h-4 mr-2  border-gray-300 rounded-sm form-checkbox focus:ring-0',
                          )}
                          value={subItem.categoryItemKey}
                          defaultChecked={selectedValues?.includes(subItem.categoryItemKey)}
                          checked={selectedValues?.includes(subItem.categoryItemKey)}
                          onChange={(e) => {
                            setSelectedValues &&
                              setSelectedValues(
                                e.target.checked
                                  ? [...selectedValues, e.target.value]
                                  : selectedValues.filter(
                                      (filter: string) => filter !== e.target.value,
                                    ),
                              );
                          }}
                        />
                        <span>{subItem.title}</span>
                      </label>
                    </CommandItem>
                  ))}
              </CommandGroup>
            </>
          );
        })}
      </CommandList>
    </Command>
  );
}

export default CategoryCombobox;
