import { useGetContactsInfinite, useInsightsMedicalRecordsViewModel } from '@colosseum/data';
import { useState } from 'react';
import DataTabs from '../DataTabs/DataTabs';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './medical-records-table-columns';

export interface MedicalRecordsTableProps {}

export function MedicalRecordsTable(props: MedicalRecordsTableProps) {
  // #region Hooks
  // #endregion

  // #region Data Fetching

  const { data: medicalRecordsCasesData, isLoading } = useInsightsMedicalRecordsViewModel();
  const { data: contactsData } = useGetContactsInfinite();

  // #endregion

  // #region State
  const [grouping, setGrouping] = useState(['Case Title']);

  // #endregion

  // #region Derived State
  const medicalRecordsDataWithCase = medicalRecordsCasesData.data || [];
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <>
      <DataTabs
        filters={[
          {
            title: 'By Case',
            count: medicalRecordsDataWithCase?.length,
            value: 'Case Title',
          },
          {
            title: 'By Records Provider',
            count: medicalRecordsDataWithCase?.length,
            value: 'Records Provider',
          },
        ]}
        setActiveFilter={(value: string) => {
          setGrouping([value]);
        }}
        activeFilter={grouping[0]}
        className="mb-4"
      />

      <DataTable
        data={medicalRecordsDataWithCase}
        columns={columns}
        isViewOnly
        isLoading={isLoading}
        tableName="MedicalRecords"
        grouping={grouping}
        defaultPageSize={25}
        meta={{
          contacts: contactsData?.data,
        }}
      />
    </>
  );
}
