import {
  ScenarioComplete,
  bitBToB,
  displayContactName,
  formatPrice,
  formatVehicleName,
  getChipsForParty,
} from '@colosseum/data';
import { Typography } from '@colosseum/shared-ui';
import { CaseContactConnectionType, ContactType } from '@gladiate/types';
import { isNumber } from 'lodash';
import CarouselCard from './CarouselCard';

export interface PartyVehicleSummaryCardProps {
  contact?: ContactType & CaseContactConnectionType;
  vehicle?: ScenarioComplete['vehicles'][0];
}

const estimateOrFinalValue = (estimate?: number, final?: number) => {
  if (isNumber(final)) {
    return formatPrice(final, true);
  }
  if (isNumber(estimate)) {
    return `${formatPrice(estimate, true)} (Est)`;
  }
  return undefined;
};

export const PartyVehicleSummaryCard = (props: PartyVehicleSummaryCardProps) => {
  const { contact, vehicle } = props;
  const totalLoss = estimateOrFinalValue(vehicle?.totalLossProposedFmv, vehicle?.totalLossFinalFmv);
  const repairCost = estimateOrFinalValue(vehicle?.repairEstimate, vehicle?.repairFinalCost);
  const valueLines = [
    {
      title: 'Total Loss FMV',
      value: bitBToB(vehicle?.isTotalLoss) && totalLoss ? totalLoss : undefined,
    },
    {
      title: 'Repair Cost',
      value: bitBToB(vehicle?.isRepairable) && repairCost ? repairCost : undefined,
    },
    {
      title: 'Diminished Value',
      value:
        bitBToB(vehicle?.isDiminishedValueClaim) &&
        typeof vehicle?.diminishedValueClaimFinalAmount === 'number'
          ? formatPrice(vehicle.diminishedValueClaimFinalAmount, true)
          : undefined,
    },
    {
      title: 'Personal Property',
      value:
        typeof vehicle?.personalPropertyDamage === 'number'
          ? formatPrice(vehicle?.personalPropertyDamage, true)
          : undefined,
    },
    {
      title: 'Company Property',
      value: bitBToB(vehicle?.companyProperty) ? 'Yes' : undefined,
    },
    {
      title: 'Government Property',
      value: bitBToB(vehicle?.governmentProperty) ? 'Yes' : undefined,
    },
  ];
  const chips = getChipsForParty(contact?.roles ?? []);
  return (
    <CarouselCard
      contact={contact}
      type="vehicle"
      id={vehicle?.vehicleId ?? ''}
      roleChips={chips}
      valueLines={valueLines}
    >
      <div className="flex gap-1">
        <span>
          <Typography size="xs" variant="grayUpper" className="mb-[-2px] select-none">
            Vehicle
          </Typography>
          <Typography size="sm" className="flex items-center gap-x-0.5 select-none">
            {formatVehicleName(vehicle)}
          </Typography>
        </span>
        <span className="flex-grow"></span>
        {bitBToB(vehicle?.isRentalCarNeeded) && vehicle?.rentalCarCompany && (
          <div className="flex gap-1">
            <span>
              <Typography variant="grayUpper" size="xs" className="mb-[-2px] select-none">
                Rental
              </Typography>
              <Typography size="sm" className="flex items-center gap-x-0.5 select-none">
                {displayContactName(vehicle?.rentalCarCompany)}
              </Typography>
            </span>
          </div>
        )}
      </div>
    </CarouselCard>
  );
};

export default PartyVehicleSummaryCard;
