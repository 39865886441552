import {
  ArctrievalEntity,
  CommandForRecordsRequestType,
  DefaultV3Response,
  RecordRequest,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseArgonautsRoute = `argonauts/${API_STAGE}`;

// #region ------------------ arctrieval/commands ------------ //
// POST send_arctrieval_request_v3
export const sendArctrievalRequestV3 = (data: {
  arctrievalData: {
    deliveryMethod: {
      email: boolean;
      fax: boolean;
      usMail: boolean;
    };
    endingDate: string;
    isDraft: boolean;
    requestedInformation: string;
    requestType: 1;
    startingDate: string;
  };
  caseId: string;
  recordsClientContactId: string;
  recordsProviderContactId: string;
  recordsRequestorUsername: string;
}) =>
  axiosInstance
    .post<DefaultV3Response<RecordRequest>>(
      `${baseArgonautsRoute}/v3/arctrieval/records-requests/send`,
      data,
    )
    .then((res) => res.data);

// #region ------------------ arctrieval/entities ------------ //
type CreateArctrievalEntityV3Data = Pick<ArctrievalEntity, 'gladiateId'>;
type ConditionalArctrievalEntityData =
  | ({ accountType: 'client' } & {
      arctrievalData: {
        firstName: string;
        lastName: string;
        email: string;
        dateOfBirth: string;
        arctrievalIntakeForm: string;
      };
    })
  | ({ accountType: 'provider' } & {
      arctrievalData: {
        firstName: string;
        lastName: string;
        email?: string;
        fax?: string;
        dateOfBirth: string;
        organizationName: string;
        address: {
          address1: string;
          city: string;
          state: string;
          zip: string;
          country: 'United States';
        };
      };
    });

// POST create_arctrieval_entity_v3
export const createArctrievalEntityV3 = (
  data: CreateArctrievalEntityV3Data & ConditionalArctrievalEntityData,
) =>
  axiosInstance
    .post<DefaultV3Response<ArctrievalEntity>>(`${baseArgonautsRoute}/v3/arctrieval/entities`, data)
    .then((res) => res.data);

// GET get_arctrieval_entity_v3
export const getArctrievalEntityV3 = (gladiateId: string) =>
  axiosInstance
    .get<DefaultV3Response<ArctrievalEntity>>(
      `${baseArgonautsRoute}/v3/arctrieval/entities/${gladiateId}`,
    )
    .then((res) => res.data);

// GET list_arctrieval_entities_v3
export const listArctrievalEntitiesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ArctrievalEntity[]>>(`${baseArgonautsRoute}/v3/arctrieval/entities`)
    .then((res) => res.data);

// PATCH update_arctrieval_entity_v3
export const updateArctrievalEntityV3 = (
  data: Partial<ArctrievalEntity> & { gladiateId: string },
) => {
  const { gladiateId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<ArctrievalEntity>>(
      `${baseArgonautsRoute}/v3/arctrieval/entities/${gladiateId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ------------------ Record Requests ---------------- //
// GET list_records_requests_v3
export const listRecordsRequestsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<RecordRequest[]>>(`${baseArgonautsRoute}/v3/records-requests`)
    .then((res) => res.data);

// GET list_record_requests_for_case_v3
export const listRecordRequestsForCaseV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<RecordRequest[]>>(
      `${baseArgonautsRoute}/v3/records-requests/cases/${caseId}`,
    )
    .then((res) => res.data);

export const updateRecordRequest = (data: RecordRequest) => {
  const { requestId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<RecordRequest>>(
      `${baseArgonautsRoute}/v3/records-requests/${requestId}`,
      { ...rest },
    )
    .then((res) => res.data);
};

export const createRecordRequest = (data: RecordRequest) => {
  return axiosInstance
    .post<DefaultV3Response<RecordRequest>>(`${baseArgonautsRoute}/v3/records-requests`, {
      ...data,
    })
    .then((res) => res.data);
};

export const deleteRecordRequest = (requestId: string) => {
  return axiosInstance
    .delete<DefaultV3Response<RecordRequest>>(
      `${baseArgonautsRoute}/v3/records-requests/${requestId}`,
    )
    .then((res) => res.data);
};

// #endregion

// #region ------------ record-requests/commands -------------- //
// POST cancel_records_request_v3
// POST complete_records_request_v3
// POST reopen_records_request_v3
// POST resend_records_request_v3

export const commandForRecordsRequestV3 = (data: CommandForRecordsRequestType) =>
  axiosInstance
    .post<DefaultV3Response<RecordRequest>>(
      `${baseArgonautsRoute}/v3/arctrieval/records-requests/${data.requestId}/${data.command}`,
      { arctrievalData: data.body },
    )
    .then((res) => res.data);
