import { formatPrice, getChipsForParty } from '@colosseum/data';
import { TooltipWrapper, Typography } from '@colosseum/shared-ui';
import { MedicalTreatmentSummaryType } from '@gladiate/types';
import CarouselCard from './CarouselCard';

export interface MedicalTreatmentSummaryCardProps {
  item: Partial<MedicalTreatmentSummaryType>;
}

export const MedicalTreatmentSummaryCard = (props: MedicalTreatmentSummaryCardProps) => {
  const { item } = props;
  const valueLines = [
    // {
    //   title: 'Treatment Types',
    //   value: 3,
    // },
    {
      title: 'Amount Billed',
      value:
        typeof item?.totalMedicals === 'number' && item.totalMedicals > 0
          ? formatPrice(item.totalMedicals, true)
          : undefined,
    },
    {
      title: 'Paid + Owed',
      value:
        typeof item?.paidPlusOwed === 'number' && item.paidPlusOwed > 0
          ? formatPrice(item.paidPlusOwed, true)
          : undefined,
    },
    {
      title: 'Max Treatment Gap',
      value:
        typeof item?.maxTreatmentGap === 'number' && item.maxTreatmentGap > 0
          ? `${item.maxTreatmentGap} days`
          : undefined,
    },
    {
      title: 'Time to First Treatment',
      value: item?.timeToFirstTreatment ? `${item.timeToFirstTreatment} days` : undefined,
    },
    {
      title: 'Last Treatment',
      value: item?.timeToLastTreatment ? `${item.timeToLastTreatment} days` : undefined,
    },
  ];
  const chips = getChipsForParty(item?.roles ?? []);
  return (
    <CarouselCard
      contact={item?.contact}
      roleChips={chips}
      type="medicalTreatment"
      valueLines={valueLines}
    >
      {/* <Typography>Treatments</Typography> */}
      <div className="flex gap-1">
        <span>
          <Typography size="xs" variant="grayUpper" className="mb-[-2px] select-none">
            Treatments
          </Typography>
          {item.medicalTreatmentTypes && item.medicalTreatmentTypes.length > 0 ? (
            <div className="flex ">
              <Typography size="sm" className="gap-x-0.5 select-none">
                {item?.medicalTreatmentTypes
                  ?.slice(0, 3)
                  .map((type) => type)
                  .join(', ')}
                {item.medicalTreatmentTypes.length > 3 && (
                  <TooltipWrapper message={item.medicalTreatmentTypes.join(', ')}>
                    <span className="ml-0.5 text-atlantic-blue">
                      +{item.medicalTreatmentTypes.length - 3} more
                    </span>
                  </TooltipWrapper>
                )}
              </Typography>
            </div>
          ) : (
            <Typography size="sm" className="flex items-center gap-x-0.5 select-none">
              N/A
            </Typography>
          )}
        </span>
      </div>
    </CarouselCard>
  );
};

export default MedicalTreatmentSummaryCard;
