import { renderCell, renderHeader, stringIncludesFilterFn } from '@colosseum/shared-ui';
import { CaseType, ExpenseType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { expenseColumns } from '../cases/CaseExpenses/case-expenses-columns';

export const lineupExpensesColumns = expenseColumns as ColumnDef<
  ExpenseType & { case?: CaseType }
>[];

lineupExpensesColumns.push({
  id: 'Case',
  accessorFn: (row) => row?.case?.caseTitle,
  sortUndefined: 1,
  filterFn: stringIncludesFilterFn,
  header: renderHeader,
  cell: (props) => renderCell({ props }),
});
