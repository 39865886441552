export interface AdobeAuthUrlType {
  authUrl: string;
}

export interface AdobeAccountType {
  agreementWebhookId: string;
  firmId: string;
  oauthStatus: string;
}
export interface AdobeAccountDetailsType {
  accountType: string;
  company: string;
  created: string;
  id: string;
  modified: string;
  name: string;
}

export interface AdobeUserType {
  adobeEmail: string;
  adobeUserId: string;
  firmId: string;
  isAdmin: boolean;
  isOwner: boolean;
  signerPrivelage: boolean;
  username: string;
  status: string;
}
export interface AdobeUserDetailsType {
  status: string;
  user: {
    accountId: string;
    accountType: string;
    company: string;
    createdDate: string;
    email: string;
    firstName: string;
    id: string;
    initials: string;
    isAccountAdmin: boolean;
    lastName: string;
    locale: string;
    status: string;
  };
}

export interface AdobeSignUserStatus {
  status: 'INACTIVE' | 'ACTIVE';
  username: string;
}

export interface AdobeUserType {
  adobeEmail: string;
  adobeUser: AdobeUserDetailsType;
  adobeUserId: string;
  dateModified: string;
  firmId: string;
  isAdmin: boolean;
  isOwner: boolean;
  signerPrivelage: boolean;
  username: string;
  userStatus: string;
}

export interface AdobeUserDetailsType {
  accountId: string;
  accountType: string;
  company: string;
  createdDate: string;
  email: string;
  firstName: string;
  id: string;
  initials: string;
  isAccountAdmin: true;
  lastName: string;
  locale: string;
  oauthStatus: string;
  status: string;
}

export interface AdobeSignerType {
  adobeEmail: string;
  adobeUserId: string;
  dateModified: string;
  firmId: string;
  firstName: string;
  isAdmin: boolean;
  isOwner: false;
  lastName: string;
  signerPrivelage: boolean;
  username: string;
  status: string;
}

export interface AdobeSignUserAccount {
  adobeUser: AdobeUserType;
  AdobeUserDetails?: AdobeUserDetailsType;
  status: string;
}
export interface AdobeSignUserAccountError {
  response: {
    data: {
      status: string;
      message: string;
      oauthStatus: string;
    };
  };
}

export const adobeUserStatus = {
  active: 'ACTIVE',
  created: 'CREATED',
  inactive: 'INACTIVE',
  notFound: 'NOT_FOUND',
} as const;

export const adobeOauthStatus = {
  needAuth: 'NEED_AUTH',
  invalid: 'INVALID',
  valid: 'VALID',
} as const;

export interface AdobeAgreementStatus {
  status: string;
  agreementId: string;
  agreementName: string;
  caseId: string;
  dateCreated: string;
  dateModified: string;
  firmId: string;
  participants: {
    email: string;
  }[];
}
