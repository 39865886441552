import { useState } from 'react';
import { useGetAllAudits, useGetAudits, useGetCaseAudits } from '../lib/react-query/audit';
import { useGetNotifications } from '../lib/react-query/notification';

export const useGetAuditsWithNotifications = ({
  itemId,
  isCase,
}: {
  itemId?: string;
  isCase?: boolean;
}) => {
  const [notificationsOnly, setNotificationsOnly] = useState(true);
  const allAuditsRes = useGetAllAudits();
  const itemAuditRes = useGetAudits(itemId);
  const caseAuditRes = useGetCaseAudits(itemId);
  const auditRes = itemId ? (isCase ? caseAuditRes : itemAuditRes) : allAuditsRes;
  const { data: notifications, isLoading: isNotificationsLoading } = useGetNotifications();
  const notificationsData = auditRes?.data?.data?.filter((audit) =>
    notifications?.data?.find((notification) => notification.auditId === audit.auditId),
  );
  const displayDataFiltered = notificationsOnly ? notificationsData : auditRes?.data?.data;
  const auditsWithNotifications = displayDataFiltered?.map((displayDataItem) => {
    const notification = notifications?.data?.find(
      (notification) => notification.auditId === displayDataItem.auditId,
    );
    const returnObj = { ...displayDataItem };
    if (!notification) return returnObj;
    return { notification: { ...notification }, ...displayDataItem };
  });
  return {
    data: auditsWithNotifications,
    isLoading: auditRes?.isLoading || isNotificationsLoading,
    notificationCount: notificationsData
      ? notificationsData?.length > 99
        ? '99+'
        : String(notificationsData?.length)
      : undefined,
    notificationsOnly,
    setNotificationsOnly,
  };
};
