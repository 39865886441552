import {
  tanstackTableNames,
  useCreateTeam,
  useDeleteTeam,
  useDeleteUserFromFirm,
  useGetFirmGroupsAndPermissions,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetTeams,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { TeamManagementForm } from '../TeamManagementForm/TeamManagementForm';
import { columns } from './team-table-columns';

const teamSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export function TeamManagement() {
  const [open, setOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const { data: teamsData, isLoading: isTeamsLoading } = useGetTeams();
  const selectedTeam = teamsData?.data.find((team) => team.teamId === selectedTeamId);
  const { data: firmGroupsData } = useGetFirmGroupsAndPermissions();
  const firmGroups = firmGroupsData?.data ?? [];
  const coCounselFirmGroup = firmGroups.find((group) => group.groupName === 'CoCounsel');

  const createTeam = useCreateTeam();
  const deleteTeam = useDeleteTeam();
  const deleteUser = useDeleteUserFromFirm();

  const teamForm = useForm<z.infer<typeof teamSchema>>({
    resolver: zodResolver(teamSchema),
    mode: 'onBlur',
    values: {},
  });

  const updateTeamForm = useForm<z.infer<typeof teamSchema>>({
    resolver: zodResolver(teamSchema),
    mode: 'onBlur',
    values: selectedTeam,
  });

  const teams = teamsData?.data || [];
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const teamsWithTeammateDisplayNames = teams.map((team) => {
    return {
      ...team,
      teammates: team.teammates?.map((teammate) => ({
        ...teammate,
        displayName: getFirmUserWithDisplayNameFromUsername(teammate.username ?? '').displayName,
      })),
    };
  });

  const handleDelete = () => {
    deleteTeam.mutate(selectedTeam?.teamId);
  };

  useEffect(() => {
    if (!open) {
      setIsTyping(false);
      setSelectedTeamId(undefined);
      teamForm.reset({ title: '', description: '' });
      updateTeamForm.reset({ title: '', description: '' });
    }
  }, [open]);

  return (
    <>
      <Form {...updateTeamForm}>
        <Slideover
          title={'Update Team'}
          description={'View and edit the details of the selected team'}
          open={open}
          setOpen={setOpen}
          displayDeleteButton={true}
          deleteFunction={handleDelete}
          typing={isTyping}
        >
          <div>{selectedTeam ? <TeamManagementForm selectedTeam={selectedTeam} /> : undefined}</div>
        </Slideover>
      </Form>

      <div className="px-1">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Teams</h1>
            <p className="mt-2 text-sm text-gray-700">{`A list of all the Teams in your firm.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title="Add Team"
              className="px-4 py-2"
              onClick={() => {
                createTeam.mutateAsync({}).then((res) => {
                  setSelectedTeamId(res.data.teamId);
                  setOpen(true);
                });
              }}
              loading={createTeam.isLoading}
            />
          </div>
        </div>

        <DataTable
          tableName={tanstackTableNames.teams}
          handleRowClick={(row) => {
            setOpen(true);
            setSelectedTeamId(row.original.teamId);
          }}
          data={teamsWithTeammateDisplayNames}
          columns={columns}
          filters={[]}
          isLoading={isTeamsLoading}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.teams, {
            'Date Created': false,
          })}
        />
      </div>
    </>
  );
}

export default TeamManagement;
