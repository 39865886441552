import {
  useCreateContactResource,
  useDeleteContactResource,
  useUpdateContactResource,
} from '@colosseum/data';
import {
  ContactEmploymentType,
  ContactViewModelType,
  contactConnectionOptions,
} from '@gladiate/types';
import dayjs from 'dayjs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionButtonGroup from '../../ActionButtonGroup/ActionButtonGroup';
import ContactConnectionDropdown from '../../ContactConnectionDropdown/ContactConnectionDropdown';
import CreateButton from '../../CreateButton/CreateButton';
import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface ClientEmploymentsFormProps {
  contact: ContactViewModelType;
}

export function ClientEmploymentsForm(props: ClientEmploymentsFormProps) {
  const { contact } = props;
  const form = useFormContext();
  const employmentForm = useFieldArray({
    control: form.control,
    name: 'contactEmployments', // unique name for your Field Array
  });

  const createContactEmployment = useCreateContactResource<ContactEmploymentType>('employments');
  const deleteContactEmployment = useDeleteContactResource('employments');
  const updateContactEmployment = useUpdateContactResource('employments');
  const isLoading =
    createContactEmployment.isLoading ||
    deleteContactEmployment.isLoading ||
    updateContactEmployment.isLoading;

  const handleUpdateContactEmployment = (name: string, value: string | number, i: number) => {
    form.setValue(`contactEmployments.${i}.${name}`, value);
    updateContactEmployment.mutate({
      resourceId: form.getValues(`contactEmployments.${i}.contactEmploymentId`),
      [name]: value,
    });
  };

  const renderTextFormInput = (name: string, title: string, i: number) => {
    return (
      <TextFormInput
        {...form.register(`contactEmployments.${i}.${name}`)}
        handleOnBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdateContactEmployment(name, target.value, i);
        }}
        title={title}
      />
    );
  };
  return (
    <>
      <div className="divide-y divide-gray-300">
        {employmentForm.fields.map((field, i) => (
          <div
            className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4"
            key={field.id}
          >
            <div className="col-span-2">
              <ContactConnectionDropdown
                contact={contact}
                title="Employer"
                primaryRelationship={contactConnectionOptions.employee}
                secondaryRelationship={contactConnectionOptions.employer}
                contactEmploymentId={form.getValues(`contactEmployments.${i}.contactEmploymentId`)}
              />
            </div>

            <div className="col-span-2">
              <ContactConnectionDropdown
                contact={contact}
                title="Direct Manager"
                primaryRelationship={contactConnectionOptions.directReport}
                secondaryRelationship={contactConnectionOptions.directManager}
                contactEmploymentId={form.getValues(`contactEmployments.${i}.contactEmploymentId`)}
              />
            </div>
            {renderTextFormInput('title', 'Title / Position', i)}
            <SelectFormInput
              title="Wage type"
              {...form.register(`contactEmployments.${i}.payType`)}
              listItems={['Hourly', 'Yearly']}
              defaultValue={contact?.contactEmployments[i]?.payType}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactEmployment('payType', target.value, i);
              }}
              placeholderText="Select a wage type"
            />
            <CurrencyInput
              {...form.register(`contactEmployments.${i}.payRate`, {
                valueAsNumber: true,
              })}
              formHandleBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                const value = parseFloat(target.value);

                handleUpdateContactEmployment('payRate', value, i);
              }}
              title="Wage Rate"
            />

            <CalendarFormInput
              {...form.register(`contactEmployments.${i}.startDate`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactEmployment(
                  'startDate',
                  dayjs(target.value).format('YYYY-MM-DD'),
                  i,
                );
              }}
              title="Start Date"
              resourceTypeObj={{
                id: contact.contactId,
                type: 'contact',
              }}
            />
            <CalendarFormInput
              {...form.register(`contactEmployments.${i}.endDate`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactEmployment(
                  'endDate',
                  dayjs(target.value).format('YYYY-MM-DD'),
                  i,
                );
              }}
              title="End Date"
              disabled={(date) => dayjs(date) > dayjs().add(5, 'years')}
              resourceTypeObj={{
                id: contact.contactId,
                type: 'contact',
              }}
            />

            {renderTextFormInput('responsibilities', 'Duties / Responsibilities', i)}

            <ActionButtonGroup
              deleteFunction={() => {
                deleteContactEmployment.mutate({
                  resourceId: form.getValues(`contactEmployments.${i}.contactEmploymentId`) ?? '',
                });
                employmentForm.remove(i);
              }}
              dataCyTitle={`employment-${i}`}
            />
          </div>
        ))}
      </div>
      <div className="col-span-2 pb-8">
        <CreateButton
          disabled={isLoading}
          loading={isLoading}
          title="Add Employment"
          dataCy="create-employment-button"
          onClick={() => {
            createContactEmployment.mutateAsync({ contactId: contact.contactId }).then((res) => {
              employmentForm.append({
                contactEmploymentId: res.data.contactEmploymentId,
                title: '',
                startDate: '',
                endDate: '',
                payType: '',
                responsibilities: '',
                directManagerContactConnectionId: '',
                employerContactConnectionId: '',
                hourlyRate: null,
              });
            });
          }}
        />
      </div>
    </>
  );
}

export default ClientEmploymentsForm;
