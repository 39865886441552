/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  tagColors,
  useCreateItemTagConnection,
  useDeleteItemTagConnection,
  useGetItemTagConnections,
  useGetTags,
  useUpdateTag,
} from '@colosseum/data';
import { ItemTagConnectionType, TagType } from '@gladiate/types';
import { enqueueSnackbar } from 'notistack';
import { GladiateLoader } from '../GladiateLoader/GladiateLoader';
import { Tag } from '../Tag/Tag';
import { TagCombobox } from '../TagCombobox/TagCombobox';

/* eslint-disable-next-line */
export type ResourceTagsProps = {
  resourceId: string;
  resourceType: ItemTagConnectionType['type'];
  isViewOnly?: boolean;
};

export function ResourceTags({ resourceId, resourceType, isViewOnly }: ResourceTagsProps) {
  const { data: itemTagConnectionsData, isLoading: isItemTagConnectionsLoading } =
    useGetItemTagConnections(resourceId.split('-')[0], resourceType);
  const { data: tagsData } = useGetTags();
  const createItemTagConnection = useCreateItemTagConnection(
    resourceId.split('-')[0],
    resourceType,
  );
  const deleteItemTagConnection = useDeleteItemTagConnection(
    resourceId.split('-')[0],
    resourceType,
  );
  const updateTag = useUpdateTag();
  const itemTagConnections =
    itemTagConnectionsData?.data.filter(
      (itemTagConnection) =>
        itemTagConnection.type === resourceType &&
        itemTagConnection.itemTagConnectionId.startsWith(resourceId),
    ) ?? [];
  const tags = tagsData?.data || [];

  // sort resourceTags by newest to oldest using dateModified if dateCreated exists
  const sortedItemTagConnections = itemTagConnections.sort((a, b) => {
    if (a.dateCreated && b.dateCreated) {
      return b.dateCreated < a.dateCreated ? 1 : -1;
    } else {
      return 0;
    }
  });

  const doesTagExist = (title: string) => {
    const tagExists = tags.find((t: TagType) => t.title === title);
    if (tagExists) {
      return true;
    } else {
      return false;
    }
  };

  function handleCreateResourceTag(data: Partial<TagType>) {
    const { color, title, tagId } = data;
    if (!tagId && title && doesTagExist(title)) {
      enqueueSnackbar('Tag with this title already exists', {
        variant: 'error',
      });
      return;
    }
    let params = {};
    if (tagId) {
      params = { color, title, tagId };
    } else {
      const color = tagColors[Math.floor(Math.random() * tagColors.length)];
      params = { color, title, type: resourceType };
    }
    createItemTagConnection.mutate({ ...params, itemId: resourceId });
  }

  function handleDeleteItemTagConnection(resourceTagId: string) {
    deleteItemTagConnection.mutate(resourceTagId);
  }

  function handleUpdateTag({
    tagId,
    title,
    color,
  }: {
    tagId: string;
    title?: string;
    color?: string;
  }) {
    if (title === '') {
      // Must have a title
      return 'no title';
    }
    if (!tagId && title && doesTagExist(title)) {
      enqueueSnackbar('Tag with this title already exists', {
        variant: 'error',
      });
      return;
    }
    if (!tagId) {
      return 'no tag'; // must have a tag ID to update a tag
    }
    updateTag.mutate({ tagId, color, title });
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="flex flex-wrap gap-y-2">
          {isItemTagConnectionsLoading && (
            <div className="flex items-center px-3 mr-2 text-sm text-white border border-gray-300 rounded-full first:ml-0">
              <div className="mt-0.5">
                <GladiateLoader height={20} />
              </div>
            </div>
          )}
          {sortedItemTagConnections.map((tag, index: number) => (
            <Tag
              itemTagConnectionId={tag.itemTagConnectionId ?? ''}
              color={tag.tagAttributes?.color ?? tagColors[0]}
              title={tag.tagAttributes?.title ?? 'No Title'}
              key={`${tag.tagId}-${index}`}
              handleDeleteItemTagConnection={handleDeleteItemTagConnection}
              isViewOnly={isViewOnly}
            />
          ))}
          {!isViewOnly && (
            <TagCombobox
              tags={tags || []}
              selectedTags={sortedItemTagConnections}
              handleCreateResourceTag={handleCreateResourceTag}
              handleDeleteItemTagConnection={handleDeleteItemTagConnection}
              handleUpdateTag={handleUpdateTag}
              isUpdateTagSuccess={updateTag.isSuccess}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResourceTags;
