import { useGetAllTimeLogs, useGetCases } from '@colosseum/data';
import { CardSection, InsightsImpactTable, TimeLogTable } from '@colosseum/shared-ui';
import { InsightsImpactDataProps } from 'libs/shared-ui/src/lib/InsightsImpact/insights-impact-table-columns';
import { Statistic } from '../../home/models/Statistic';
import { InsightsDataCard } from './InsightsDataCard';

export function TeamPage() {
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data: allTimeLogsData, isLoading: isTimeLogsLoading } = useGetAllTimeLogs();
  const { data: casesData, isLoading: isCasesLoading } = useGetCases();

  const overviewData = [
    {
      icon: 'clock',
      title: 'Users Logging Time',
      currency: false,
      value: 12,
    },
    {
      icon: 'clock',
      title: 'Avg. Hours per Day',
      currency: false,
      value: 6,
    },
    {
      icon: 'clock',
      title: 'Avg. Cases per Day',
      currency: false,
      value: 4,
    },
    {
      icon: 'clock',
      title: 'Avg. Tasks Completed per Day',
      currency: false,
      value: 7.2,
    },
  ];

  const impactData: InsightsImpactDataProps[] = [
    {
      teamMember: 'Michael Johnson',
      role: 'Attorney',
      casesClosed: 6,
      closedWithFee: '82%',
      avgSettlement: '$5,200',
      avgFee: '$1,400',
      clientNPS: 72,
    },
    {
      teamMember: 'Emily White',
      role: 'Paralegal',
      casesClosed: 5,
      closedWithFee: '80%',
      avgSettlement: '$4,800',
      avgFee: '$1,300',
      clientNPS: 68,
    },
    {
      teamMember: 'David Brown',
      role: 'Attorney',
      casesClosed: 8,
      closedWithFee: '85%',
      avgSettlement: '$5,600',
      avgFee: '$1,500',
      clientNPS: 78,
    },
    {
      teamMember: 'Jessica Davis',
      role: 'Paralegal',
      casesClosed: 6,
      closedWithFee: '83%',
      avgSettlement: '$5,000',
      avgFee: '$1,350',
      clientNPS: 74,
    },
    {
      teamMember: 'Robert Wilson',
      role: 'Attorney',
      casesClosed: 7,
      closedWithFee: '84%',
      avgSettlement: '$5,400',
      avgFee: '$1,450',
      clientNPS: 75,
    },
    {
      teamMember: 'Sophia Martinez',
      role: 'Paralegal',
      casesClosed: 6,
      closedWithFee: '82%',
      avgSettlement: '$5,200',
      avgFee: '$1,400',
      clientNPS: 72,
    },
    {
      teamMember: 'Christopher Taylor',
      role: 'Attorney',
      casesClosed: 5,
      closedWithFee: '80%',
      avgSettlement: '$4,800',
      avgFee: '$1,300',
      clientNPS: 68,
    },
    {
      teamMember: 'Olivia Anderson',
      role: 'Paralegal',
      casesClosed: 8,
      closedWithFee: '85%',
      avgSettlement: '$5,600',
      avgFee: '$1,500',
      clientNPS: 78,
    },
  ];
  // #endregion

  // #region State
  // #endregion

  // #region Derived State
  const firmStatistics: Statistic[] = [];
  overviewData.forEach((data) => {
    firmStatistics.push({
      icon: data.icon,
      title: data.title,
      value: data.value,
      currency: data.currency,
    });
  });

  const isLoading = isTimeLogsLoading || isCasesLoading;
  const formattedTimeLogs = allTimeLogsData?.data?.map((timeLog) => {
    const caseData = casesData?.data?.find((c) => c.caseId === timeLog.timeLogId.split('-')[0]);
    return {
      ...timeLog,
      caseTitle: caseData ? caseData?.caseTitle ?? '-' : '<Deleted Case>',
    };
  });
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <div className="flex flex-col gap-5">
      <InsightsDataCard data={firmStatistics} />
      <CardSection cardHeading="Impact Report">
        <InsightsImpactTable data={impactData} />
      </CardSection>
      <CardSection cardHeading="Time Logs">
        <TimeLogTable
          timeLogs={formattedTimeLogs ?? []}
          isLoading={isLoading}
          otherTableProps={{
            initialSort: {
              id: 'Date',
              desc: true,
            },
            initialVisibility: {
              Date: false,
            },
          }}
        />
      </CardSection>
    </div>
  );
}
