import { DefaultV3Response, PolicyType, ScenarioType, VehicleType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const basePetorritumRoute = `petorritum/${API_STAGE}/v3`;

// GET get_scenario_for_case_v3
export const getScenarioForCaseV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<ScenarioType>>(`${basePetorritumRoute}/scenarios/cases/${caseId}`)
    .then((res) => res.data);

export const getPoliciesForFirmV3 = () =>
  axiosInstance
    .get<DefaultV3Response<PolicyType[]>>(`${basePetorritumRoute}/policies`)
    .then((res) => res.data);

// POST create_policy_v3
export const createPolicyForScenarioV3 = (data: { caseId: string & Partial<PolicyType> }) =>
  axiosInstance
    .post<DefaultV3Response<PolicyType>>(`${basePetorritumRoute}/policies`, data)
    .then((res) => res.data);

export const updatePolicyForScenarioV3 = (data: { policyId: string & Partial<PolicyType> }) => {
  const { policyId, ...rest } = data;

  return axiosInstance.patch<DefaultV3Response<PolicyType>>(
    `${basePetorritumRoute}/policies/${policyId}`,
    rest,
  );
};

// POST create_vehicle_v3
export const createVehicleForScenarioV3 = (data: { caseId: string & Partial<VehicleType> }) =>
  axiosInstance
    .post<DefaultV3Response<VehicleType>>(`${basePetorritumRoute}/vehicles`, data)
    .then((res) => res.data);

export const updateVehicleForScenarioV3 = (data: { vehicleId: string & Partial<VehicleType> }) => {
  const { vehicleId, ...rest } = data;

  return axiosInstance.patch<DefaultV3Response<VehicleType>>(
    `${basePetorritumRoute}/vehicles/${vehicleId}`,
    rest,
  );
};

export const createItemConnectionForScenarioV3 = (
  data:
    | {
        caseId: string;
        policyId?: string;
        vehicleId?: string;
      }
    | {
        caseId: string;
        policyId: string;
        partyId: string;
      }
    | {
        caseId: string;
        vehicleId: string;
        partyId: string;
      },
) =>
  axiosInstance
    .post<DefaultV3Response<VehicleType>>(`${basePetorritumRoute}/item-connections`, data)
    .then((res) => res.data);

export const deleteItemConnectionForScenarioV3 = (itemConnectionId: string) =>
  axiosInstance.delete(`${basePetorritumRoute}/item-connections/${itemConnectionId}`);

export const deletePolicyV3 = (policyId: string) =>
  axiosInstance.delete(`${basePetorritumRoute}/policies/${policyId}`);

// GET get_vehicles_v3
export const getVehiclesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<VehicleType[]>>(`${basePetorritumRoute}/vehicles`)
    .then((res) => res.data);

export const deleteVehicleV3 = (vehicleId: string) =>
  axiosInstance.delete(`${basePetorritumRoute}/vehicles/${vehicleId}`);
