import { cn, useGetCases } from '@colosseum/data';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import QuickCreateCaseSlideover from '../QuickCreateCaseSlideover/QuickCreateCaseSlideover';
import { Button } from '../shadcn/Button/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
/* eslint-disable-next-line */
export interface AllCasesDropdownProps {
  isLoading: boolean;
  onSelectCase: (caseId: string) => void;
  linkId?: string;
  filterCaseIds?: string[];
  hideCreateOption?: boolean;
  excludedCaseIds?: string[];

  customIcon?: React.ReactNode;
  buttonClassName?: string;
  customButton?: React.ReactNode;
}

export function AllCasesDropdown(props: AllCasesDropdownProps) {
  const { caseId } = useParams() as { caseId: string };

  const { onSelectCase, isLoading, linkId, hideCreateOption } = props;
  const { data: casesData } = useGetCases();
  const allCases = casesData?.data
    ? casesData.data.filter((caseItem) => !props.excludedCaseIds?.includes(caseItem.caseId))
    : [];

  const convertTableViewId = (tableViewId: string) => {
    if (!tableViewId || tableViewId === 'case-' || tableViewId === 'case') {
      return '';
    }

    const splitIds = tableViewId.split('-');
    if (splitIds.length > 1) {
      return splitIds[1];
    }

    return '';
  };

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [quickCaseCreate, setQuickCaseCreate] = useState(false);

  useEffect(() => {
    if (open === false) {
      setSearch(''); // reset search when searchbox closes
    }
  }, [open]);

  return (
    <>
      <QuickCreateCaseSlideover
        open={quickCaseCreate}
        setOpen={setQuickCaseCreate}
        linkIds={{
          linkId: linkId ?? '',
          caseId,
        }}
      />
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          {props.customButton ?? (
            <Button variant="outline" size="unset" className={cn('p-0.5', props.buttonClassName)}>
              {isLoading ? (
                <GladiateLoader height={20} />
              ) : (
                props.customIcon ?? <PlusIcon className="w-5 h-5 " />
              )}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent className="bg-white ">
          <div className="sticky top-0 z-10 px-2 border-b border-gray-300 ">
            <div className="flex items-center ">
              <MagnifyingGlassIcon className="w-4 h-4 text-gray-700 " />
              <input
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
                className="block py-2 ml-2 text-sm text-gray-700 focus:outline-none"
              />
            </div>
          </div>
          <div className="px-1 py-1 overflow-y-auto max-h-72">
            {!hideCreateOption && (
              <div
                onClick={() => {
                  //placeholder
                  setQuickCaseCreate(true);
                }}
                className="flex items-center px-2 py-1 text-sm text-gray-700 bg-white rounded-md cursor-pointer hover:bg-gray-100"
              >
                <span className="ml-2 font-semibold">Create New Case...</span>
              </div>
            )}
            {allCases
              ?.filter(
                (item: { caseTitle?: string; tableViewId?: string }) =>
                  item.caseTitle?.toLowerCase()?.includes(search?.toLowerCase()) &&
                  (props.filterCaseIds
                    ? !props.filterCaseIds.includes(convertTableViewId(item.tableViewId ?? ''))
                    : true),
              )
              ?.sort((a, b) => {
                if (!a?.caseTitle || !b?.caseTitle) return 0;
                return a?.caseTitle.localeCompare(b.caseTitle);
              })
              .map((item) => (
                <div
                  key={item.caseId}
                  onClick={() => {
                    //placeholder
                    onSelectCase(item.caseId ?? '');
                    setOpen(false);
                  }}
                  className="flex items-center justify-between px-2 py-1 text-sm text-gray-700 bg-white rounded-md cursor-pointer hover:bg-gray-100"
                >
                  <span className="ml-2">{item.caseTitle || '-'}</span>
                </div>
              ))}
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default AllCasesDropdown;
