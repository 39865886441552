import {
  casesSectionMappings,
  displayContactName,
  enqueueAPISnackbar,
  roundToNearest15Minutes,
  scrollToCaseSection,
  useCreateOfflineEvent,
  useDeleteOfflineEvent,
  useGetContactsInfinite,
  useGetEventCategories,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetOfflineEvents,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';
import { EventAttendeeType, OfflineEventType } from '@gladiate/types';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import CaseEventsForm from '../CaseEventsForm/CaseEventsForm';
import { columns } from './case-event-columns';

export interface CaseEventsProps {
  caseId: string;
}

export type CompleteEventsDataType = OfflineEventType & {
  ownerDisplayName?: string;
  categoryTitle?: string;
  attendeesWithDisplayNames: ({ displayName: string } & EventAttendeeType)[];
};

export function CaseEvents(props: CaseEventsProps) {
  const { caseId } = props;
  const {
    data: offlineEventsData,
    isError: isOfflineEventsError,
    isLoading: isOfflineEventsLoading,
  } = useGetOfflineEvents({
    caseId,
  });
  const createOfflineEvent = useCreateOfflineEvent();
  const deleteOfflineEvent = useDeleteOfflineEvent();
  const { data: eventCategoriesData } = useGetEventCategories();

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const { data: allContactsData } = useGetContactsInfinite();

  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const completeEventsData = offlineEventsData?.data.map((event) => ({
    ...event,

    categoryTitle: eventCategoriesData?.data.find(
      (category) => category.categoryId === event.categoryId,
    )?.title,
    attendeesWithDisplayNames: event.attendees?.map((attendee) => {
      if (attendee.attendeeType === 'user') {
        return {
          ...attendee,
          displayName: getFirmUserWithDisplayNameFromUsername(attendee?.gladiateId ?? '')
            ?.displayName,
        };
      } else {
        return {
          ...attendee,
          displayName: displayContactName(
            allContactsData?.data?.find(
              (contact) => contact.contactId === attendee?.gladiateId ?? '',
            ),
          ),
        };
      }
    }),
    ownerDisplayName: getFirmUserWithDisplayNameFromUsername(event?.owner ?? '')?.displayName,
  })) as CompleteEventsDataType[];

  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();
  const selectedEvent = completeEventsData?.find((event) => event.eventId === selectedEventId);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'offlineEvent' && !isOfflineEventsLoading) {
      if (offlineEventsData?.data.find((s) => s.eventId === pendingSlideoverToOpen.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.calendar}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setSlideoverOpen(true);
        setSelectedEventId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Offline Event not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, isOfflineEventsLoading]);

  return (
    <div>
      <ResourceSlideover
        setOpen={setSlideoverOpen}
        open={slideoverOpen}
        deleteFunction={() => {
          if (selectedEventId) {
            deleteOfflineEvent.mutate(selectedEventId);
          }
        }}
        title="Offline Event"
        description="Edit your offline event."
        displayDeleteButton={true}
        typing={typing}
        caseId={caseId}
        createType="offlineEvent"
        resourceId={selectedEvent?.eventId ?? ''}
      >
        <TabsContent value="details">
          <CaseEventsForm
            event={completeEventsData?.find((event) => selectedEvent?.eventId === event.eventId)}
            setTyping={setTyping}
          />
        </TabsContent>
      </ResourceSlideover>
      <DataTable
        columns={columns}
        handleRowClick={(row) => {
          setSelectedEventId(row.original.eventId);
          setSlideoverOpen(true);
        }}
        data={completeEventsData ?? []}
        tableName="caseEvents"
        isError={isOfflineEventsError}
        isLoading={isOfflineEventsLoading}
        customRightButton={
          <CreateButton
            className="ml-2"
            title="Create Event"
            loading={createOfflineEvent.isLoading}
            onClick={() => {
              const currentTime = dayjs()
                .minute(roundToNearest15Minutes(dayjs().minute()))
                .second(0)
                .millisecond(0);
              const currentPlusOneHourTime = dayjs(currentTime).add(1, 'hour');
              createOfflineEvent
                .mutateAsync({
                  caseId,
                  startDateTime: currentTime.toISOString(),
                  endDateTime: currentPlusOneHourTime.toISOString(),
                })
                .then((res) => {
                  setSelectedEventId(res.data.eventId);
                  setSlideoverOpen(true);
                });
            }}
          />
        }
      />
    </div>
  );
}

export default CaseEvents;
