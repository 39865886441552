import {
  BanknotesIcon,
  BookOpenIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  CircleStackIcon,
  Cog8ToothIcon,
  CpuChipIcon,
  CreditCardIcon,
  DocumentCheckIcon,
  DocumentIcon,
  EyeDropperIcon,
  FolderOpenIcon,
  HomeIcon,
  IdentificationIcon,
  InboxIcon,
  LifebuoyIcon,
  ListBulletIcon,
  MapIcon,
  PaperAirplaneIcon,
  PhoneIcon,
  PhotoIcon,
  ReceiptPercentIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
  SwatchIcon,
  TruckIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { ArrowLeftRightIcon, CalendarIcon, MessageCircleIcon } from 'lucide-react';

export const getGlobalIcon = (icon?: string) => {
  switch (icon) {
    case 'agreement': {
      return ArrowLeftRightIcon;
    }
    case 'automations': {
      return CpuChipIcon;
    }
    case 'calendar': {
      return CalendarIcon;
    }
    case 'campaigns': {
      return PaperAirplaneIcon;
    }
    case 'case': {
      return FolderOpenIcon;
    }
    case 'caseContactConnection': {
      return RectangleStackIcon;
    }
    case 'communication': {
      return MessageCircleIcon;
    }
    case 'contact': {
      return UserIcon;
    }
    case 'contacts': {
      return UsersIcon;
    }
    case 'dashboard': {
      return HomeIcon;
    }
    case 'discovery': {
      return MapIcon;
    }
    case 'email': {
      return InboxIcon;
    }
    case 'expense': {
      return BanknotesIcon;
    }
    case 'file': {
      return DocumentIcon;
    }
    case 'insights': {
      return ChartBarSquareIcon;
    }
    case 'lead': {
      return LifebuoyIcon;
    }
    case 'lien': {
      return CircleStackIcon;
    }
    case 'lineups': {
      return SwatchIcon;
    }
    case 'offlineEvent': {
      return BookOpenIcon;
    }
    case 'phone': {
      return PhoneIcon;
    }
    case 'priorMedicalTreatment':
    case 'medicalTreatment': {
      return EyeDropperIcon;
    }
    case 'medicalRecordRequest': {
      return BuildingOffice2Icon;
    }
    case 'note': {
      return ListBulletIcon;
    }
    case 'photo': {
      return PhotoIcon;
    }
    case 'policy': {
      return ShieldCheckIcon;
    }
    case 'staff': {
      return IdentificationIcon;
    }
    case 'caseSurvey': {
      return ChartPieIcon;
    }
    case 'task': {
      return DocumentCheckIcon;
    }
    case 'template': {
      return RectangleGroupIcon;
    }
    case 'trustAccountTransaction': {
      return CalculatorIcon;
    }
    case 'vehicle': {
      return TruckIcon;
    }
    case 'settings': {
      return Cog8ToothIcon;
    }
    case 'settlementProposal': {
      return ReceiptPercentIcon;
    }
    case 'subscriptions': {
      return CreditCardIcon;
    }
    default:
      return null;
  }
};
