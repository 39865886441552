import {
  displayContactName,
  tanstackTableNames,
  useGetContactCampaignSubscriptions,
} from '@colosseum/data';
import { DataTable, renderCell, renderCellWithChips, renderHeader } from '@colosseum/shared-ui';
import { ContactCampaignSubscription } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import ContactCampaignSubscriptionSlideover from '../ContactCampaignSubscriptionPage/ContactCampaignSubscriptionSlideover';

export interface SubscriptionTableProps {
  contactCampaignId: string;
  isViewOnly?: boolean;
}

export const columns: ColumnDef<ContactCampaignSubscription>[] = [
  {
    accessorFn: (row) => {
      return displayContactName(row.contact);
    },
    id: 'Name',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => row.status,
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
];

export function ContactSubscriptionTable(props: SubscriptionTableProps) {
  const { contactCampaignId, isViewOnly = false } = props;

  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data: campaignSubscriptionData, isLoading: campaignSubscriptionLoading } =
    useGetContactCampaignSubscriptions(contactCampaignId);
  // #endregion

  // #region State
  const contactSubscriptions = campaignSubscriptionData?.data;
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<ContactCampaignSubscription | null>();
  // #endregion

  // #region Derived State

  // #endregion

  return (
    <>
      {selectedSubscription && (
        <ContactCampaignSubscriptionSlideover
          slideoverOpen={slideoverOpen}
          setSlideoverOpen={setSlideoverOpen}
          contactCampaignSubscription={selectedSubscription}
        />
      )}
      {isViewOnly ? (
        <DataTable
          isViewOnly
          data={contactSubscriptions ?? []}
          columns={columns}
          filters={[]}
          tableName={tanstackTableNames.contactCampaignSubscription}
          isLoading={campaignSubscriptionLoading}
          showSearchBar
        />
      ) : (
        <DataTable
          handleRowClick={(row) => {
            setSelectedSubscription(row.original);
            setSlideoverOpen(true);
          }}
          data={contactSubscriptions ?? []}
          columns={columns}
          filters={[]}
          tableName={tanstackTableNames.contactCampaignSubscription}
          isLoading={campaignSubscriptionLoading}
          showSearchBar
        />
      )}
    </>
  );
}

export default ContactSubscriptionTable;
