// collegium-census queries

import {
  AudienceType,
  CaseContactConnectionType,
  ContactAddressType,
  ContactEmailType,
  ContactEmploymentType,
  ContactNumberType,
  ContactResourceStringType,
  ContactType,
  ContactViewModelType,
  DefaultV3Error,
  DefaultV3Response,
} from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { queryKeys } from '../../static/queryKeys';
import { fetchLastEvaluatedKeys } from '../../utils/reactQueryUtils';
import { enqueueAPISnackbar, enqueueServerErrorSnackbar } from '../../utils/snackbars';
import {
  baseFirmContactsRoute,
  createContactResourceRoute,
  deleteContactResourceRoute,
  deleteContactRoute,
  getFirmContactRouteComplete,
  getSelectedContactsRoute,
} from '../apiRoutes';
import { axiosInstance } from '../https';
import {
  createAudienceV3,
  createContactConnectionV3,
  deleteAudienceV3,
  deleteContactConnectionV3,
  getAllContactConnectionsV3,
  getAudiencesV3,
  getContactAddressesV3,
  getContactConnectionsV3,
  getContactEmailsV3,
  getContactEmploymentsV3,
  getContactNumbersV3,
  getContactsV3,
  updateAudienceV3,
} from '../requests/collegium-census';
import { getCaseContactConnectionsByResourceV3 } from '../requests/conscription';
import { getContactListViewV3 } from '../requests/sibyls';

interface MutateContactResourceData {
  resourceId: string;
  number?: string;
  phoneExtension?: string;
  email?: string;
  emailAddress?: string;
}

export const useGetAudiences = () => {
  return useQuery({
    queryKey: [queryKeys.audiences],
    queryFn: async () => getAudiencesV3(),
  });
};

export const useCreateAudience = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createAudienceV3>[0]) => createAudienceV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.audiences],
      });
    },
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      } else {
        enqueueAPISnackbar({
          message: 'Error updating preset filter. Please try again later.',
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateAudience = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateAudienceV3>[0]) => updateAudienceV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.audiences],
      });
    },
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      } else {
        enqueueAPISnackbar({
          message: 'Error updating preset filter. Please try again later.',
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteAudience = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof deleteAudienceV3>[0]) => deleteAudienceV3(data),
    onSuccess: (result) => {
      if (result?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: result?.meta?.userMsg,
          variant: 'success',
        });
      }
    },
    onMutate: async (audienceId: string) => {
      await queryClient.cancelQueries({
        queryKey: [queryKeys.audiences],
      });

      // Snapshot the previous value
      const previousAudiences = queryClient.getQueryData([queryKeys.audiences]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        [queryKeys.audiences],
        (old: DefaultV3Response<AudienceType[]> | undefined) => {
          if (!old) return;
          const oldCopy = cloneDeep(old);
          const index: number = oldCopy.data.findIndex(
            (audience) => audience?.audienceId === audienceId,
          );

          oldCopy.data.splice(index, 1);
          return oldCopy;
        },
      );

      return { previousAudiences };
    },
    onError: (_err, _newTodo, context) => {
      enqueueServerErrorSnackbar();
      queryClient.setQueryData([queryKeys.audiences], context?.previousAudiences);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.audiences],
      });
    },
  });
};

export const useGetContact = (contactId?: string) => {
  return useQuery<DefaultV3Response<ContactViewModelType>, AxiosError<DefaultV3Error>>({
    queryKey: [queryKeys.contacts, contactId],
    queryFn: async () =>
      axiosInstance
        .get<DefaultV3Response<ContactViewModelType>>(getFirmContactRouteComplete(contactId ?? ''))
        .then((res) => res.data),
    enabled: !!contactId,
  });
};

export const useGetContactsInfinite = () => {
  const queryKey = [queryKeys.contacts, 'infinite'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactType>(
        getContactsV3,
        'contactId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactsListViewInfinite = () => {
  const queryKey = [queryKeys.contacts, 'infiniteListView'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactViewModelType>(
        getContactListViewV3,
        'contactId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactAddresses = () => {
  const queryKey = [queryKeys.contactAddresses];
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () => getContactAddressesV3(),
  });
};

export const useGetContactAddressesInfinite = () => {
  const queryKey = [queryKeys.contactAddresses, 'infinite'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactAddressType>(
        getContactAddressesV3,
        'contactAddressId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactEmails = () => {
  const queryKey = [queryKeys.contactEmails];
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () => getContactEmailsV3(),
  });
};

export const useGetContactEmailsInfinite = (enabled?: boolean) => {
  const queryKey = [queryKeys.contactEmails, 'infinite'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactEmailType>(
        getContactEmailsV3,
        'contactEmailId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactEmployments = () => {
  const queryKey = [queryKeys.contactEmployments];
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () => getContactEmploymentsV3(),
  });
};

export const useGetContactEmploymentsInfinite = (enabled?: boolean) => {
  const queryKey = [queryKeys.contactEmployments, 'infinite'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactEmploymentType>(
        getContactEmploymentsV3,
        'contactEmploymentId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactNumbers = () => {
  const queryKey = [queryKeys.contactNumbers];
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () => getContactNumbersV3(),
  });
};

export const useGetContactNumbersInfinite = (enabled?: boolean) => {
  const queryKey = [queryKeys.contactNumbers, 'infinite'];
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: async () =>
      fetchLastEvaluatedKeys<ContactNumberType>(
        getContactNumbersV3,
        'contactNumberId',
        'firmId',
        queryClient,
        queryKey,
      ),
  });
};

export const useGetContactConnections = (enabled?: boolean) => {
  return useQuery({
    queryKey: [queryKeys.contactConnections],
    enabled: enabled,
    queryFn: async () => getAllContactConnectionsV3(),
  });
};

export const useGetCaseContactConnectionsByContact = (contactId?: string) => {
  return useQuery({
    queryKey: [queryKeys.contacts, queryKeys.casesForContact, contactId],
    queryFn: async () =>
      getCaseContactConnectionsByResourceV3<CaseContactConnectionType[]>(
        contactId ?? '',
        'contacts',
      ),
    enabled: !!contactId,
  });
};

export const useGetListOfContactsByIDs = (contactIds: string[]) => {
  return useQuery<DefaultV3Response<ContactViewModelType[]>, DefaultV3Error>({
    queryKey: ['contacts', contactIds],
    queryFn: async () =>
      axiosInstance
        .post<DefaultV3Response<ContactViewModelType[]>>(getSelectedContactsRoute, {
          contacts: contactIds,
        })
        .then((res) => res.data),
    enabled: !!contactIds,
  });
};

export const useCreateContact = () => {
  const queryClient = useQueryClient();

  return useMutation<DefaultV3Response<ContactType>, AxiosError<DefaultV3Error>>({
    mutationFn: () =>
      axiosInstance
        .post<DefaultV3Response<ContactType>>(baseFirmContactsRoute, {})
        .then((res) => res.data),
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DefaultV3Response<ContactType>,
    AxiosError<DefaultV3Error>,
    { contactId: string }
  >({
    mutationFn: ({ contactId }) => axiosInstance.delete(deleteContactRoute(contactId)),
    onMutate: async ({ contactId }: { contactId: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [queryKeys.contacts],
      });
      // Snapshot the previous value
      const previousContacts = queryClient.getQueryData([queryKeys.contacts]);
      if (!previousContacts) return; // if all contacts haven't been fetched we dont need optimistic ui
      // Optimistically update to the new value
      queryClient.setQueryData(
        [queryKeys.contacts],
        (old: DefaultV3Response<ContactType[]> | undefined) => {
          if (!old) return;
          const oldCopy = cloneDeep(old);
          const index: number = oldCopy.data.findIndex(
            (contact: { contactId: string }) => contact?.contactId === contactId,
          );
          oldCopy.data.splice(index, 1);
          return oldCopy;
        },
      );
      // Return a context object with the snapshotted value
      return { previousContacts };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context: any) => {
      enqueueServerErrorSnackbar();
      queryClient.setQueryData([queryKeys.contacts], context?.previousContacts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
    },
  });
};

export const useCreateContactConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createContactConnectionV3>[0]) =>
      createContactConnectionV3(data).then((res) => res.data),
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'create-contact-connection-error',
        variant: 'error',
      });
    },
  });
};

export const useDeleteContactConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contactConnectionId: string) =>
      deleteContactConnectionV3(contactConnectionId).then((res) => res.data),
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'delete-contact-connection-error',
        variant: 'error',
      });
    },
  });
};

export const useCreateContactResource = <T>(resourceType: ContactResourceStringType) => {
  const queryClient = useQueryClient();
  return useMutation<DefaultV3Response<T>, AxiosError<DefaultV3Error>, { contactId: string }>({
    mutationFn: ({ contactId }: { contactId: string }) =>
      axiosInstance
        .post<DefaultV3Response<T>>(createContactResourceRoute(resourceType, contactId))
        .then((res) => res.data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

export const useDeleteContactResource = <T>(resourceType: ContactResourceStringType) => {
  const queryClient = useQueryClient();

  return useMutation<DefaultV3Response<T>, AxiosError<DefaultV3Error>, { resourceId: string }>({
    mutationFn: ({ resourceId }: { resourceId: string }) =>
      axiosInstance.delete(deleteContactResourceRoute(resourceType, resourceId)),
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

const queryKeyMapping = {
  employments: queryKeys.contactEmployments,
  emails: queryKeys.contactEmails,
  addresses: queryKeys.contactAddresses,
  connections: queryKeys.contactConnections,
  'web-addresses': queryKeys.contactWebAddresses,
  numbers: queryKeys.contactNumbers,
};

export const useUpdateContactResource = <T>(resourceType: ContactResourceStringType) => {
  const queryClient = useQueryClient();
  return useMutation<DefaultV3Response<T>, AxiosError<DefaultV3Error>, MutateContactResourceData>({
    mutationFn: ({ resourceId, ...data }) => {
      if (!resourceId)
        return Promise.reject(new Error('resourceId is required to update a contact resource'));

      return axiosInstance.patch(deleteContactResourceRoute(resourceType, resourceId), data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeyMapping[resourceType]],
      });
      queryClient.invalidateQueries([queryKeys.caseContactConnections]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

export const useUpdateContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { contactId: ContactType['contactId'] } & Partial<ContactType>) => {
      const { contactId, ...rest } = data;
      return axiosInstance
        .patch<DefaultV3Response<ContactType>>(deleteContactRoute(contactId), rest)
        .then((res) => res.data);
    },
    onMutate: (data: { contactId: string }) => {
      // Update the cache optimistically
      // TODO: Make this optimistically update caseContactConnections as well
      queryClient.setQueryData(
        [queryKeys.contacts, data.contactId],
        (oldData: { data: ContactType[] } | undefined) => {
          if (!oldData) return;

          return {
            status: 'SUCCESS',
            data: { ...oldData.data, ...data },
          };
        },
      );

      // Return the previous data and the tempId for rollback
      return {
        previousData: queryClient.getQueryData<any>([queryKeys.contacts, data.contactId]),
      };
    },
    onError: (error, variables, context) => {
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData([queryKeys.contacts, variables.contactId], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.contacts]);
      queryClient.invalidateQueries([queryKeys.caseContactConnections]);
    },
  });
};

export const useGetContactConnectionsByContact = (contactId: string) => {
  return useQuery({
    queryKey: [queryKeys.contacts, contactId, 'connections'],
    queryFn: async () => getContactConnectionsV3(contactId),
    enabled: !!contactId,
  });
};
