import {
  CompleteCoCounselType,
  US_STATES,
  useCreateCaseAccess,
  useDeleteCaseAccess,
} from '@colosseum/data';
import {
  AllCasesDropdown,
  BubbleItem,
  PhoneInput,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

export interface CoCounselFormProps {
  groupId?: string;
  handleOnBlur?: (e: React.SyntheticEvent) => void;
  selectedUser?: CompleteCoCounselType;
}

export const CoCounselForm = (props: CoCounselFormProps) => {
  const { groupId, handleOnBlur, selectedUser } = props;
  const form = useFormContext();
  const createCaseAccess = useCreateCaseAccess();
  const deleteCaseAccess = useDeleteCaseAccess();

  const handleSelectCaseAccess = (caseId: string) => {
    if (selectedUser?.username) {
      createCaseAccess.mutate({
        caseId,
        username: selectedUser?.username,
      });
    }
  };

  const handleDeleteCaseAccess = (caseAccessId: string) => {
    deleteCaseAccess.mutate(caseAccessId);
  };

  return (
    <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
      <h1 className="font-semibold">User</h1>
      <div></div>
      <TextFormInput {...form.register(`name`)} title="Name" handleOnBlur={handleOnBlur} />
      <TextFormInput
        {...form.register(`email`)}
        title="Email"
        handleOnBlur={handleOnBlur}
        disabled={!!handleOnBlur}
      />
      <PhoneInput
        formHandleBlur={(e) => {
          form.setValue(`phone_number`, e.target.value ? e.target.value : '');
          if (handleOnBlur) handleOnBlur(e);
        }}
        {...form.register(`phone_number`)}
        title="Phone Number"
      />
      <div></div>
      <h1 className="font-semibold">Firm</h1>
      <div></div>
      <TextFormInput
        {...form.register(`cocounselName`)}
        title="Firm Name"
        handleOnBlur={handleOnBlur}
      />
      <TextFormInput
        {...form.register(`cocounselFirmAddress`)}
        title="Firm Address"
        handleOnBlur={handleOnBlur}
      />
      <TextFormInput
        {...form.register(`cocounselFirmCity`)}
        title="Firm City"
        handleOnBlur={handleOnBlur}
      />
      <SelectFormInput
        title="Firm State"
        {...form.register(`cocounselFirmState`)}
        listItems={US_STATES}
        defaultValue={form.getValues(`cocounselFirmState`)}
        handleOnChange={(e: React.SyntheticEvent) => {
          if (handleOnBlur) handleOnBlur(e);
        }}
      />
      <TextFormInput
        {...form.register(`cocounselFirmZip`)}
        title="Firm Zip"
        handleOnBlur={handleOnBlur}
      />
      <div></div>
      <h1 className="font-semibold">Cases</h1>
      <div></div>
      {!handleOnBlur ? (
        <div className="text-xs">You must add user before adding cases.</div>
      ) : (
        <div className="flex flex-row flex-wrap col-span-2 gap-2">
          {selectedUser && selectedUser.caseAccesses.length > 0 ? (
            selectedUser.caseAccesses.map((caseAccess) => {
              return (
                <BubbleItem
                  key={caseAccess.userAccessId}
                  text={caseAccess.caseTitle ?? '-'}
                  icon={<LinkIcon className="w-4 h-4 mr-1" />}
                  onDelete={(e: any) => {
                    e.preventDefault();
                    handleDeleteCaseAccess(caseAccess.userAccessId);
                  }}
                  href={`/cases/${caseAccess.caseId}`}
                />
              );
            })
          ) : (
            <div>No cases added.</div>
          )}
          <AllCasesDropdown
            isLoading={false}
            onSelectCase={handleSelectCaseAccess}
            hideCreateOption
          ></AllCasesDropdown>
        </div>
      )}

      <input hidden {...form.register(`groupId`)} value={groupId} />
      <input hidden {...form.register(`username`)} value={selectedUser?.username} />
    </div>
  );
};
