import { useLocation } from 'react-router';
import { usePrevious } from './usePrevious';

export function usePathParts() {
  const location = useLocation();
  const pathparts = location.pathname.split('/');
  const [_, resource, resourceId] = pathparts;
  const prevPath = usePrevious(pathparts) ?? '';
  const [__, prevResource, prevResourceId] = prevPath;
  const prevPathParts = {
    resource: prevResource,
    resourceId: prevResourceId,
  };

  return { resource, resourceId, prevPathParts };
}
