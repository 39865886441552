import { useUpdateTask } from '@colosseum/data';
import { TaskType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form } from '../../shadcn/Form/Form';
import TaskStatusSelect from '../../TaskStatusSelect/TaskStatusSelect';
import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

export interface TaskFormProps {
  task?: TaskType;
  setTyping: (value: boolean) => void;
}

const formSchema = z.object({
  title: z.string(),
  description: z.string(),
  dueDate: z.string(),
});

export function TaskForm(props: TaskFormProps) {
  const { task, setTyping } = props;
  const taskForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: task ?? {},
  });

  const updateTask = useUpdateTask();
  function handleUpdate(
    name: keyof Pick<TaskType, 'title' | 'description' | 'dueDate'>,
    value?: string,
  ) {
    if (!task) {
      //TODO: Determine if this will ever happen - enqueue snackbar?
      return;
    }
    if (taskForm.getFieldState(name).isDirty) {
      updateTask
        .mutateAsync({
          taskId: task.taskId,
          data: { [name]: value },
        })
        .then(() => {
          setTyping(false);
          taskForm.resetField(name, { defaultValue: value });
        });
    }
  }
  return (
    <div className="grid w-full grid-cols-2 mb-2 gap-x-3 gap-y-5">
      <Form {...taskForm}>
        <div className="col-span-2">
          <TextFormInput
            title="Title"
            {...taskForm.register('title')}
            handleOnBlur={(e) => handleUpdate('title', e.target.value)}
          />
        </div>
        <div className="col-span-2">
          <TextFormInput
            title="Description"
            type="textarea"
            {...taskForm.register('description')}
            handleOnBlur={(e) => handleUpdate('description', e.target.value)}
          />
        </div>
        <TaskStatusSelect task={task} triggerClassName="h-10" />
        <CalendarFormInput
          {...taskForm.register(`dueDate`)}
          handleOnChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement;
            handleUpdate('dueDate', dayjs(target.value).format('YYYY-MM-DD'));
          }}
          title="Due Date"
        />
      </Form>
    </div>
  );
}

export default TaskForm;
