import {
  displayContactName,
  getCaseContactConnectionRoleIdForParty,
  useCreateCaseContactConnection,
  useCreateItemConnectionForScenario,
  useCreatePolicyForScenario,
  useDeleteVehicle,
  useGetContactsInfinite,
  useGetPoliciesWithInsurer,
  useGetRoleIdForItem,
} from '@colosseum/data';
import { ActionConfirmModal, Button, EntityAddPopover } from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  ContactType,
  PolicyType,
  ScenarioType,
  VehicleType,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { ChevronDownIcon, ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { TrashIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { handleCreateConnectionProps } from './PartiesAndPolicies';
import { PolicyTitle } from './PolicyCard';
import LinkedPartiesTable from './linked-entity-tables/LinkedPartiesTable';
import LinkedPoliciesTable from './linked-entity-tables/LinkedPoliciesTable';
import { startCase } from 'lodash';

export interface VehicleCardProps {
  caseId: string;
  vehicle: VehicleType;
  parties: (ContactType & { roles: CaseContactConnectionRoleType[] })[];
  scenario?: ScenarioType;
  setNewContactId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedPolicyId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedVehicleId: Dispatch<SetStateAction<string | undefined>>;
  setContactSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setPolicySlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setVehicleSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedConnectionId: Dispatch<SetStateAction<string | undefined>>;
  handleCreateConnection: (options: handleCreateConnectionProps) => void;
  handleLinkedPartiesRowClick: (
    row: Row<ContactType>,
    parentEntity: {
      type: 'vehicle';
      id: string;
    },
  ) => void;
  handleLinkedPoliciesRowClick: (
    row: Row<PolicyType>,
    parentEntity: {
      type: 'vehicle';
      id: string;
    },
  ) => void;
}

export const VehicleCard = (props: VehicleCardProps) => {
  const {
    caseId,
    vehicle,
    parties,
    scenario,
    setSelectedPolicyId,
    setSelectedVehicleId,
    setContactSlideoverOpen,
    setVehicleSlideoverOpen,
    setPolicySlideoverOpen,
    setNewContactId,
    handleCreateConnection,
    handleLinkedPartiesRowClick,
    handleLinkedPoliciesRowClick,
  } = props;

  const [partiesTableOpen, setPartiesTableOpen] = useState(false);
  const [policyTableOpen, setPolicyTableOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState<VehicleType>();
  const [policiesLoading, setPoliciesLoading] = useState('');
  const [vehiclesLoading, setVehiclesLoading] = useState('');

  // Data fetching
  const { data: allContactsData } = useGetContactsInfinite();
  const allContacts = allContactsData?.data;
  const createCaseContactConnection = useCreateCaseContactConnection();
  const deleteVehicle = useDeleteVehicle();
  const createPolicy = useCreatePolicyForScenario();
  const createItemConnection = useCreateItemConnectionForScenario();
  const policiesWithInsurer = useGetPoliciesWithInsurer(caseId, scenario?.policies);

  const getRoleIdForItem = useGetRoleIdForItem<PolicyType>(caseId);

  if (!vehicle.vehicleId) return null;

  const partiesForVehicle = scenario?.itemConnections
    ?.filter(
      (itemConnection) => itemConnection.vehicleId === vehicle.vehicleId && itemConnection.partyId,
    )
    .map((itemConnection) =>
      parties?.find(
        (contact) => getCaseContactConnectionRoleIdForParty(contact) === itemConnection.partyId,
      ),
    )
    .filter((party) => !!party);

  const policiesForVehicle =
    scenario?.itemConnections
      ?.filter(
        (itemConnection) =>
          itemConnection.vehicleId === vehicle.vehicleId && itemConnection.policyId,
      )
      .map((itemConnection) =>
        policiesWithInsurer.find((policy) => policy?.policyId === itemConnection.policyId),
      ) ?? [];

  return (
    <div className="relative py-7">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-lg font-semibold">
            {vehicle.year ?? '-'} {vehicle.make ?? '-'} {vehicle.model ?? '-'}
          </div>
          <table className="table flex-col max-w-[80%] mx-2 mt-2 overflow-y-auto gap-x-1 -scroll-mx-2">
            <tr className="table-row">
              <td className="pb-2 pr-4 text-xs typography-gray-upper">VIN</td>
              <td className="pb-2 text-xs font-bold text-left">{vehicle.vin ?? '-'}</td>
            </tr>
          </table>
        </div>
        <div className="absolute flex items-center gap-2 top-3 right-3">
          <Button
            variant="outline"
            className="p-1 h-[26px]"
            onClick={() => {
              setSelectedVehicleId(vehicle?.vehicleId);
              setVehicleSlideoverOpen(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            variant="destructive"
            className="p-1 h-[26px]"
            onClick={() => {
              setVehicleToDelete(vehicle);
              setDeleteModalOpen(true);
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
      <div className="mt-4 space-y-3">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => {
                if (!vehicle.vehicleId) return;
                setPolicyTableOpen(!policyTableOpen);
              }}
            >
              {policyTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Policies
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {policiesForVehicle?.length}
              </div>
            </Button>
            {policyTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${scenario?.policies?.length ?? 0} policies...`}
                title="Link Policy"
                onAdd={() => {
                  if (!vehicle.vehicleId) return;

                  setPoliciesLoading(vehicle.vehicleId);
                  createPolicy
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      const id = res?.data?.policyId;
                      if (!id) {
                        return;
                      }
                      createItemConnection
                        .mutateAsync({
                          caseId: caseId,
                          vehicleId: vehicle.vehicleId,
                          policyId: id,
                        })
                        .then(() => {
                          setSelectedPolicyId(res?.data?.policyId);
                          setPoliciesLoading('');
                          setPolicySlideoverOpen(true);
                        });
                    });
                }}
                isLoading={policiesLoading === vehicle.vehicleId}
                listItems={
                  scenario?.policies?.map((policy) => {
                    const insurerForPolicy = getRoleIdForItem(policy, 'insurer', 'policyId');
                    const insurer = allContacts?.find(
                      (contact) => contact.contactId === insurerForPolicy,
                    );
                    return {
                      label: `${displayContactName(insurer) ?? '-'} / ${
                        startCase(policy.policyType) ?? '-'
                      } / ${policy.type ?? '-'}`,
                      jsxLabel: (
                        <PolicyTitle
                          insurer={insurer}
                          size="sm"
                          policy={policy}
                          placeholderText="N/A"
                        />
                      ),
                      onClick: () => {
                        if (
                          scenario?.itemConnections?.some((itemConnection) => {
                            return (
                              itemConnection.policyId === policy.policyId &&
                              itemConnection.vehicleId === vehicle.vehicleId
                            );
                          })
                        ) {
                          enqueueSnackbar('Policy is already connected to the vehicle', {
                            variant: 'warning',
                          });
                          return;
                        }

                        setPoliciesLoading(vehicle?.vehicleId ?? '');
                        createItemConnection
                          .mutateAsync({
                            caseId: caseId,
                            vehicleId: vehicle.vehicleId,
                            policyId: policy.policyId,
                          })
                          .then(() => {
                            setPoliciesLoading('');
                          });
                      },
                    };
                  }) ?? []
                }
              />
            )}
          </div>
          {policyTableOpen && (
            <div className="mt-5">
              <LinkedPoliciesTable
                policies={policiesForVehicle}
                handleRowClick={(row) => {
                  const parentEntity = {
                    type: 'vehicle' as const,
                    id: vehicle.vehicleId,
                  };
                  handleLinkedPoliciesRowClick(row, parentEntity);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 space-y-3">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => {
                if (!vehicle.vehicleId) return;

                setPartiesTableOpen(!partiesTableOpen);
              }}
            >
              {partiesTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Parties
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {partiesForVehicle?.length}
              </div>
            </Button>
            {partiesTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${allContactsData?.data.length ?? 0}${
                  allContactsData?.meta.lastEvaluatedKey ? '+' : ''
                } contacts...`}
                title="Link Party"
                onAdd={() => {
                  setVehiclesLoading(vehicle.vehicleId);
                  createCaseContactConnection
                    .mutateAsync({
                      caseId: caseId,
                      roleOnCase: partiesAndPoliciesConnectionOptions.party,
                    })
                    .then((res) => {
                      createItemConnection.mutate({
                        caseId: caseId,
                        partyId: res?.data?.caseContactConnectionRole?.caseContactConnectionRoleId,
                        vehicleId: vehicle.vehicleId,
                      });
                      setNewContactId(res?.data?.contactId);
                      setContactSlideoverOpen(true);
                      setPoliciesLoading('');
                    });
                }}
                isLoading={vehiclesLoading === vehicle.vehicleId}
                listItems={
                  allContacts
                    ?.filter((contact) => {
                      //if they have either a first or last name, show them
                      return contact.firstName || contact.lastName || contact.name;
                    })
                    ?.map((contact) => {
                      const matchingContact = parties?.find(
                        (party) => party.contactId === contact.contactId,
                      );
                      return {
                        label: displayContactName(contact),
                        onClick: () => {
                          if (
                            scenario?.itemConnections
                              ?.filter(
                                (itemConnection) =>
                                  itemConnection.vehicleId === vehicle.vehicleId &&
                                  itemConnection.partyId,
                              )
                              .some((itemConnection) => {
                                return (
                                  itemConnection.partyId ===
                                  getCaseContactConnectionRoleIdForParty(matchingContact)
                                );
                              })
                          ) {
                            enqueueSnackbar('Party is already connected to the vehicle', {
                              variant: 'warning',
                            });
                            return;
                          }
                          // if contact is not a party, create the party
                          if (!matchingContact) {
                            handleCreateConnection({
                              roleOnCase: partiesAndPoliciesConnectionOptions.party,
                              contactId: contact.contactId,
                              vehicleId: vehicle.vehicleId,
                            });
                          } else {
                            setPoliciesLoading(vehicle?.vehicleId ?? '');
                            createItemConnection
                              .mutateAsync({
                                caseId: caseId,
                                vehicleId: vehicle.vehicleId,
                                partyId: getCaseContactConnectionRoleIdForParty(matchingContact),
                              })
                              .then(() => {
                                setPoliciesLoading('');
                              });
                          }
                        },
                      };
                    }) ?? []
                }
              />
            )}
          </div>
          {partiesTableOpen && partiesForVehicle && (
            <div className="mt-5">
              <LinkedPartiesTable
                parties={partiesForVehicle}
                handleRowClick={(row) =>
                  handleLinkedPartiesRowClick(row, {
                    type: 'vehicle',
                    id: vehicle.vehicleId,
                  })
                }
                isLoading={false}
              />
            </div>
          )}
        </div>
      </div>
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        actionFunction={() => {
          if (vehicleToDelete) {
            deleteVehicle.mutate(vehicleToDelete.vehicleId);
            setDeleteModalOpen(false);
          }
        }}
      />
    </div>
  );
};

export default VehicleCard;
