import {
  tanstackTableNames,
  useCreateEventCategory,
  useDeleteEventCategory,
  useGetEventCategories,
} from '@colosseum/data';
import { CreateButton, DataTable, Slideover } from '@colosseum/shared-ui';
import { EventCategoryType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import EventCategoriesForm from '../EventCategoriesForm/EventCategoriesForm';
import { columns } from './event-category-columns';

export interface EventCategoriesProps {}

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export function EventCategories(props: EventCategoriesProps) {
  const { data: eventCategories, isLoading: isEventCategoriesLoading } = useGetEventCategories();
  const createEventCategory = useCreateEventCategory();
  const deleteEventCategory = useDeleteEventCategory();

  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const selectedCategory = eventCategories?.data?.find(
    (category) => category.categoryId === selectedCategoryId,
  );
  const [typing, setTyping] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      title: selectedCategory?.title || '',
      description: selectedCategory?.description || '',
    },
  });

  useEffect(() => {
    if (!slideoverOpen) {
      form.reset();
      setSelectedCategoryId(undefined);
    }
  }, [slideoverOpen, form]);

  return (
    <div>
      <Slideover
        displayDeleteButton={true}
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        deleteFunction={() => {
          if (selectedCategoryId)
            deleteEventCategory.mutateAsync(selectedCategoryId).then(() => {
              setSelectedCategoryId(undefined);
              setSlideoverOpen(false);
            });
        }}
        title="Event Categories"
        description="Create and manage event categories."
        typing={typing}
      >
        <EventCategoriesForm category={selectedCategory} setTyping={setTyping} />
      </Slideover>

      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="referral-sources" className="text-xl font-semibold text-gray-900">
              Event Categories
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's event categories.`}</p>
          </div>
        </div>
        <DataTable
          columns={columns}
          isLoading={isEventCategoriesLoading}
          data={eventCategories?.data ?? []}
          tableName={tanstackTableNames.eventCategories}
          handleRowClick={(row: Row<EventCategoryType>) => {
            setSlideoverOpen(true);
            setSelectedCategoryId(row.original.categoryId);
          }}
          customRightButton={
            <CreateButton
              className="ml-2"
              title="Create New Category"
              loading={createEventCategory.isLoading}
              onClick={() => {
                createEventCategory.mutateAsync({}).then((res) => {
                  setSlideoverOpen(true);
                  setSelectedCategoryId(res.data.categoryId);
                });
              }}
            />
          }
        />
      </div>
    </div>
  );
}

export default EventCategories;
