/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cn, useUpdateTask } from '@colosseum/data';
import { taskStatusOptions, TaskType } from '@gladiate/types';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import { startCase } from 'lodash';
import { statusCircleOptions } from '../ResourceTask/ResourceTask';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';
dayjs.extend(calendar);
dayjs.extend(utc);

export interface TaskStatusSelectProps {
  task?: TaskType;
  triggerClassName?: string;
}

export function TaskStatusSelect(props: TaskStatusSelectProps) {
  const { task, triggerClassName } = props;
  const updateResourceTask = useUpdateTask();

  return (
    <Select
      onValueChange={(value) => {
        updateResourceTask.mutate({
          taskId: task?.taskId ?? '',
          data: {
            status: value as taskStatusOptions,
          },
        });
      }}
    >
      <SelectTrigger className={cn('w-auto h-8', triggerClassName)}>
        <SelectValue>
          <div className="flex text-nowrap gap-x-1">
            {statusCircleOptions[task?.status ?? taskStatusOptions.toDo]}
            {startCase(task?.status ?? taskStatusOptions.toDo)}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Status</SelectLabel>
          <SelectItem key={taskStatusOptions.toDo} value={taskStatusOptions.toDo}>
            <div className="flex gap-x-2">
              {statusCircleOptions[taskStatusOptions.toDo]}
              <div>To do</div>
            </div>
          </SelectItem>
          <SelectItem key={taskStatusOptions.inProgress} value={taskStatusOptions.inProgress}>
            <div className="flex gap-x-2">
              {statusCircleOptions[taskStatusOptions.inProgress]}
              <div>In Progress</div>
            </div>
          </SelectItem>
          <SelectItem key={taskStatusOptions.completed} value={taskStatusOptions.completed}>
            <div className="flex gap-x-2">
              {statusCircleOptions[taskStatusOptions.completed]}
              <div>Completed</div>
            </div>
          </SelectItem>
          <SelectItem
            key={taskStatusOptions.willNotComplete}
            value={taskStatusOptions.willNotComplete}
          >
            <div className="flex gap-x-2">
              {statusCircleOptions[taskStatusOptions.willNotComplete]}
              <div>Will Not Complete</div>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default TaskStatusSelect;
