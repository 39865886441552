import { booleanToString, useCaseSettlementForm } from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  CheckboxFormInput,
  ClientSelectDropdown,
  CurrencyInput,
  Form,
  MultiSelectFormInput,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import {
  SettlementProposalType,
  caseContactConnectionOptions,
  settlementCategoryOptions,
  settlementCoverageTypeOptions,
  settlementPolicyTypeOptions,
  settlementStatusOptions,
} from '@gladiate/types';
import dayjs from 'dayjs';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface CaseSettlementFormProps {
  settlement?: SettlementProposalType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
  caseId: string;
}

export function CaseSettlementForm(props: CaseSettlementFormProps) {
  const { settlement, setTyping, caseId } = props;

  const { form, handleUpdate } = useCaseSettlementForm({
    settlement,
    setTyping,
  });

  const [selectedContingencies, setSelectedContingencies] = useState<string[]>(
    settlement?.proposalContingencies ?? [],
  );

  return (
    <div className="">
      <Form {...form}>
        <form>
          <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
            <div className="col-span-2">
              <ClientSelectDropdown
                caseId={caseId}
                form={form}
                caseItemType="settlementProposal"
                caseItemId={settlement?.settlementProposalId ?? ''}
              />
            </div>

            <CalendarFormInput
              {...form.register(`dateIssued`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('dateIssued', dayjs(target.value).format('YYYY-MM-DD'));
              }}
              title="Date Issued"
              resourceTypeObj={{
                type: 'settlement-proposal',
                id: settlement?.settlementProposalId,
              }}
            />
            <CalendarFormInput
              {...form.register(`expirationDate`)}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('expirationDate', dayjs(target.value).format('YYYY-MM-DD'));
              }}
              title="Expiration Date"
              resourceTypeObj={{
                type: 'settlement-proposal',
                id: settlement?.settlementProposalId,
              }}
            />

            <SelectFormInput
              title="Policy Type"
              {...form.register(`policyType`)}
              listItems={settlementPolicyTypeOptions}
              listItemsIsObject
              defaultValue={props.settlement?.policyType}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('policyType', target.value);
              }}
              placeholderText="Select Policy Type"
            />
            <SelectFormInput
              title="Coverage Type"
              {...form.register(`coverageType`)}
              listItems={settlementCoverageTypeOptions}
              listItemsIsObject
              defaultValue={props.settlement?.coverageType}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('coverageType', target.value);
              }}
              placeholderText="Select Coverage Type"
            />
            <SelectFormInput
              title="Category"
              {...form.register(`type`)}
              listItems={settlementCategoryOptions}
              listItemsIsObject
              defaultValue={props.settlement?.type}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('type', target.value);
              }}
              placeholderText="Select Category"
            />
            <CurrencyInput
              {...form.register('amount', {
                valueAsNumber: true,
              })}
              formHandleBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                const value = parseFloat(target.value);

                handleUpdate('amount', value);
              }}
              title="Amount"
            />
            <CheckboxFormInput
              {...form.register('policyLimits')}
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('policyLimits', booleanToString(target.value));
              }}
              title="Policy Limits"
            />
            <div className="col-span-1"></div>
            <SelectFormInput
              title="Status"
              listItems={settlementStatusOptions}
              listItemsIsObject
              defaultValue={settlement?.proposalStatus ?? 'notAccepted'}
              placeholderText="Select a status"
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('proposalStatus', target.value);
              }}
              {...form.register(`proposalStatus`)}
            />
            {settlement?.proposalStatus === 'acceptedWithContingencies' && (
              <MultiSelectFormInput
                title="Contingencies"
                {...form.register(`proposalContingencies`)}
                selectedOptions={selectedContingencies ?? []}
                options={[
                  { title: 'Additional Claimant', value: 'additionalClaimant' },
                  { title: 'Affidavits', value: 'affidavits' },
                  { title: 'Apportionment', value: 'apportionment' },
                  {
                    title: 'First-Party Settlement',
                    value: 'firstPartySettlement',
                  },
                  { title: 'Lien', value: 'lien' },
                  {
                    title: "Minor's Compromise",
                    value: 'minorsCompromise',
                  },
                  { title: 'Release', value: 'release' },
                  { title: 'Other', value: 'other' },
                ]}
                optionsIsObject
                onChange={(option: string) => {
                  setSelectedContingencies((prev) => {
                    let newObj = [...prev];
                    if (newObj.includes(option)) {
                      newObj = newObj.filter((i) => i !== option);
                    } else {
                      newObj = [...newObj, option];
                    }
                    form.setValue('proposalContingencies', newObj, {
                      shouldDirty: true,
                    });
                    handleUpdate('proposalContingencies', newObj);
                    return newObj;
                  });
                }}
              />
            )}
            <div className="col-span-2">
              <TextFormInput
                {...form.register(`description`)}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
                handleOnChange={() => setTyping(true)}
                title="Description"
                type="textarea"
              />
            </div>
          </div>
        </form>
      </Form>
      <div className="flex flex-col w-full ml-1 rounded-md gap-y-4">
        <CaseContactConnectionLinker
          caseId={caseId}
          title="Insurer"
          roleOnCase={caseContactConnectionOptions.insurer}
          itemId={settlement?.settlementProposalId}
          itemType="settlementProposal"
        />
        <CaseContactConnectionLinker
          caseId={caseId}
          title="Adjuster"
          roleOnCase={caseContactConnectionOptions.adjuster}
          itemId={settlement?.settlementProposalId}
          itemType="settlementProposal"
        />
      </div>
    </div>
  );
}

export default CaseSettlementForm;
