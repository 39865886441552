import { CardSection, MedicalProvidersTable, MedicalRecordsTable } from '@colosseum/shared-ui';
import { Statistic } from '../../home/models/Statistic';
import { InsightsDataCard } from './InsightsDataCard';
import { InsightsDataCardChart } from './InsightsDataCardChart';
export function ProvidersPage() {
  // #region Hooks

  // #endregion

  // #region Data Fetching
  const overviewData = [
    {
      icon: 'clock',
      title: 'Providers on Cases',
      currency: false,
      value: 75,
    },
    {
      icon: 'clock',
      title: 'Balance Outstand',
      currency: false,
      value: '$3.4M',
    },
    {
      icon: 'clock',
      title: 'Liens Outstanding',
      currency: false,
      value: '$1.2M',
    },
    {
      icon: 'clock',
      title: 'Records Outstanding',
      currency: false,
      value: 124,
    },
  ];

  const billedAmountTrendsData = [
    {
      icon: 'clock',
      title: 'ER Visit',
      currency: true,
      value: '$4,500',
      chartData: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
    },
    {
      icon: 'clock',
      title: 'Pain Injections',
      currency: true,
      value: '$24,491',
      chartData: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },

        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
      ],
    },
  ];

  const reductionAmountTrendsData = [
    {
      icon: 'clock',
      title: 'ER Visit',
      currency: false,
      value: '3%',
      chartData: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
    },
    {
      icon: 'clock',
      title: 'Pain Injections',
      currency: false,
      value: '65%',
      chartData: [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },

        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
      ],
    },
  ];
  // #endregion

  // #region State

  // #endregion

  // #region Derived State
  const firmStatistics: Statistic[] = [];
  overviewData.forEach((data) => {
    firmStatistics.push({
      icon: data.icon,
      title: data.title,
      value: data.value,
      currency: data.currency,
    });
  });
  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <>
      <div className="flex flex-col gap-5">
        <InsightsDataCard data={firmStatistics} />
        <CardSection cardHeading="Provider Billed Amount Trends by Treatment">
          <>
            {billedAmountTrendsData?.map((statistic: Statistic & { chartData: any[] }) => (
              <InsightsDataCardChart
                key={statistic.title}
                cardData={statistic}
                chartData={statistic.chartData}
                chartDataKey="pv"
              />
            ))}
          </>
        </CardSection>
        <CardSection cardHeading="Provider Reduction Amount Trends by Treatment">
          <>
            {reductionAmountTrendsData?.map((statistic: Statistic & { chartData: any[] }) => (
              <InsightsDataCardChart
                key={statistic.title}
                cardData={statistic}
                chartData={statistic.chartData}
                chartDataKey="pv"
              />
            ))}
          </>
        </CardSection>
        <CardSection cardHeading="Provider Balance Report">
          <MedicalProvidersTable />
        </CardSection>
        <CardSection cardHeading="Medical Record Report">
          <MedicalRecordsTable />
        </CardSection>
      </div>
    </>
  );
}
