import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createLienV3,
  createMedicalTreatmentAppointmentV3,
  createMedicalTreatmentBillsV3,
  createMedicalTreatmentTypeV3,
  createPriorMedicalTreatmentV3,
  deleteLienV3,
  deleteMedicalTreatmentAppointmentV3,
  deleteMedicalTreatmentBillsV3,
  deleteMedicalTreatmentTypeV3,
  deletePriorMedicalTreatmentV3,
  getAllMedicalTreatmentAppointmentsV3,
  getAllMedicalTreatmentBillsV3,
  getLiensV3,
  getMedicalTreatmentAppointmentsV3,
  getMedicalTreatmentBillsV3,
  getMedicalTreatmentTypesV3,
  getPriorMedicalTreatmentsV3,
  updateLienV3,
  updateMedicalTreatmentAppointmentV3,
  updateMedicalTreatmentBillsV3,
  updateMedicalTreatmentTypeV3,
  updatePriorMedicalTreatmentV3,
} from '../requests/hippocrates';
import { getCaseMedicalSummaryV3, getClientMedicalSummaryV3 } from '../requests/sibyls';

export const useGetCaseMedicalSummary = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.medicalTreatments, caseId, 'summary'],
    queryFn: async () => getCaseMedicalSummaryV3(caseId),
    enabled: !!caseId,
  });
};

export const useGetClientMedicalSummary = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.medicalTreatments, caseId, 'client-summary'],
    queryFn: async () => getClientMedicalSummaryV3(caseId),
    enabled: !!caseId,
  });
};

// #region ------------ LIENS ------------------- //

export const useGetLiens = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.liens, caseId],
    queryFn: async () => getLiensV3(caseId),
    enabled: !!caseId,
  });
};

export const useCreateLien = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createLienV3>[0]) => createLienV3(data),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.liens, variables.caseId],
      });
    },
  });
};

export const useDeleteLien = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (lienId: string) => deleteLienV3(lienId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.liens],
      });
    },
  });
};

export const useUpdateLien = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateLienV3>[0]) => updateLienV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.liens],
      });
    },
  });
};

// #endregion

// #region ------------ MEDICAL TREATMENTS ------------------- //
export const useGetMedicalTreatments = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.medicalTreatments, caseId],
    queryFn: async () => getMedicalTreatmentBillsV3(caseId),
    enabled: !!caseId,
  });
};

export const useGetFirmMedicalTreatments = () => {
  return useQuery({
    queryKey: [queryKeys.medicalTreatments],
    queryFn: async () => getAllMedicalTreatmentBillsV3(),
  });
};

export const useCreateMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createMedicalTreatmentBillsV3>[0]) =>
      createMedicalTreatmentBillsV3(data),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments, variables.caseId],
      });
    },
  });
};

export const useDeleteMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (treatmentId: string) => deleteMedicalTreatmentBillsV3(treatmentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments],
      });
    },
  });
};

export const useUpdateMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateMedicalTreatmentBillsV3>[0]) =>
      updateMedicalTreatmentBillsV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments],
      });
    },
  });
};
// #endregion

// #region ------------ MEDICAL TREATMENT TYPES ------------------- //
export const useCreateMedicalTreatmentType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createMedicalTreatmentTypeV3>[0]) =>
      createMedicalTreatmentTypeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatmentTypes],
      });
    },
  });
};

export const useDeleteMedicalTreatmentType = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (typeId: string) => deleteMedicalTreatmentTypeV3(typeId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatmentTypes],
      });
    },
  });
};

export const useUpdateMedicalTreatmentType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateMedicalTreatmentTypeV3>[0]) =>
      updateMedicalTreatmentTypeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatmentTypes],
      });
    },
  });
};

export const useGetMedicalTreatmentTypes = () => {
  return useQuery({
    queryKey: [queryKeys.medicalTreatmentTypes],
    queryFn: getMedicalTreatmentTypesV3,
  });
};

// #endregion

// #region ----------- PRIOR MEDICAL TREATMENTS ------------------- //
export const useGetPriorMedicalTreatments = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.priorMedicalTreatments, caseId],
    queryFn: async () => getPriorMedicalTreatmentsV3(caseId),
    enabled: !!caseId,
  });
};

export const useCreatePriorMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createPriorMedicalTreatmentV3>[0]) =>
      createPriorMedicalTreatmentV3(data),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.priorMedicalTreatments, variables.caseId],
      });
    },
  });
};

export const useDeletePriorMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (treatmentId: string) => deletePriorMedicalTreatmentV3(treatmentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.priorMedicalTreatments],
      });
    },
  });
};

export const useUpdatePriorMedicalTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updatePriorMedicalTreatmentV3>[0]) =>
      updatePriorMedicalTreatmentV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.priorMedicalTreatments],
      });
    },
  });
};

// #endregion

//#region ------------ MEDICAL APPOINTMENTS ---------------------- //
export const useCreateMedicalTreatmentAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createMedicalTreatmentAppointmentV3>[0]) =>
      createMedicalTreatmentAppointmentV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalAppointments],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments],
      });
    },
  });
};

export const useGetMedicalTreatmentAppointments = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.medicalAppointments, caseId],
    queryFn: async () => getMedicalTreatmentAppointmentsV3(caseId),
    enabled: !!caseId,
  });
};

export const useDeleteMedicalTreatmentAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appointmentId: string) => deleteMedicalTreatmentAppointmentV3(appointmentId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalAppointments],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments],
      });
    },
  });
};

export const useGetAllMedicalTreatmentAppointments = () => {
  return useQuery({
    queryKey: [queryKeys.medicalAppointments],
    queryFn: async () => getAllMedicalTreatmentAppointmentsV3(),
  });
};

export const useUpdateMedicalTreatmentAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateMedicalTreatmentAppointmentV3>[0]) =>
      updateMedicalTreatmentAppointmentV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalAppointments],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.medicalTreatments],
      });
    },
  });
};

//#endregion
