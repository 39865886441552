/* eslint-disable @tanstack/query/exhaustive-deps */
/* eslint-disable @tanstack/query/prefer-query-object-syntax */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useQueries, useQuery } from '@tanstack/react-query';
import * as qs from 'qs';
import { getFirmId } from '../utils/authUtils';
import { axiosInstance } from './https';
import { VITE_BASE_API_URL } from '../static/importMetaEnv';

const baseUrl = VITE_BASE_API_URL;
const firmId = getFirmId();

export function getRequestPlain(path: string, urlParamsObject: object, options: object) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;

  const data = axiosInstance.get(requestUrl, mergedOptions).then((res) => {
    return res.data;
  });

  return data;
}

/**
 * Helper to make GET requests to our API endpoints
 * @param {string} path Path of the API route
 * @param {string} service API service to use for the request (e.g. 'contacts', 'conscript', etc.) also used for the query key and caching purposes
 * @param {string} queryKey Query key for the query
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to get request

 * @returns Parsed API call response, or error, or loading state
 */
export function getRequestDefault(
  path: string,
  service: string,
  queryKey: string,
  urlParamsObject: object,
  options: object,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}/${service}/${firmId}${path}${
    queryString ? `?${queryString}` : ''
  }`;

  const useQueryParams = {
    queryKey: [queryKey],
    queryFn: () =>
      axiosInstance.get(requestUrl, mergedOptions).then((res) => {
        return res.data;
      }),
  };

  const query = useQuery(useQueryParams);

  // Handle response
  if (query.error) {
    console.error(query.error);
  }

  return query;
}

/**
 * Helper to make GET requests to our API endpoints with custom path
 * @param {string} path Path of the API route
 * @param {string} queryKey Query key for the query
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to get request
 * @param {number} retries Number of retries to make if the request fails

 * @returns Parsed API call response, or error, or loading state
 */
export function getRequestCustomPath(
  path: string,
  queryKey: string,
  urlParamsObject: object,
  options: object,
  retries?: number,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      axiosInstance.get(requestUrl, mergedOptions).then((res) => {
        return res.data;
      }),
    retry: retries ? retries : 3,
  });

  // Handle response
  if (query.error) {
    console.error(query.error);
  }

  return query;
}

/**
 * Helper to make GET requests to our API endpoints that waits for the passed in enabled value to be true
 * @param {string} path Path of the API route
 * @param {string} queryKey Query key for the query
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to get request
 * @param {any} enabled Value to wait for before making the request
 * @param {number} retries Number of retries to make if the request fails

 * @returns Parsed API call response, or error, or loading state
 */
export function getRequestWaitForVariable(
  path: string,
  queryKey: string[],
  urlParamsObject: object,
  options: object,
  enabled: any,
  retries?: number,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;

  const useQueryParams = {
    queryKey: queryKey,
    queryFn: () =>
      axiosInstance.get(requestUrl, mergedOptions).then((res) => {
        return res.data;
      }),
    enabled: !!enabled,
    retry: retries ? retries : 3,
  };

  const query = useQuery(useQueryParams);

  //! UNCOMMENT THIS WHEN ARGONAUTS IS FIXED
  // Handle response
  // if (query.error) {
  //   console.error(query.error);
  // }

  return query;
}

/**
 * Helper to make GET requests to our API endpoints with custom query key and path
 * @param {string} path Path of the API route
 * @param {any[]} queryKeyArr Custom query keys for the query (e.g. ['todo', 5]) https://tanstack.com/query/v4/docs/react/guides/query-keys
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to get request

 * @returns Parsed API call response, or error, or loading state
 */
export function getRequestCustomPathCustomKey(
  path: string,
  queryKeyArr: any[],
  urlParamsObject: object,
  options: object,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;

  const query = useQuery({
    queryKey: queryKeyArr,
    queryFn: () =>
      axiosInstance.get(requestUrl, mergedOptions).then((res) => {
        return res.data;
      }),
  });

  // Handle response
  if (query.error) {
    console.error(query.error);
  }

  return query;
}

// helper function to check if an object is iterable
function isIterable(input: any) {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === 'function';
}
export function recursiveRequest(
  path: string,
  queryKey: string[],
  dataKey: string,
  options: object,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const requestUrl = `${baseUrl}${path}`;

  async function fetchRecursively(last_evaluated_key: any, prevData: any): Promise<any> {
    const response = await axiosInstance.post(requestUrl, { last_evaluated_key }, mergedOptions);

    const data = response.data;

    const newData = data?.[dataKey] && isIterable(data?.[dataKey]) ? data?.[dataKey] : [];

    if (data.last_evaluated_key) {
      return await fetchRecursively(data.last_evaluated_key, [...prevData, ...newData]);
    }

    return [...prevData, ...newData];
  }

  const query = useQuery(queryKey, async () => fetchRecursively(null, []));

  // Handle response
  if (query.error) {
    console.error(query.error);
  }

  return query;
}

/**
 * Helper to make GET requests to our API endpoints with custom query key and path
 * @param {any[]} items Array of items to make requests for
 * @param {function} pathBuilder Function that takes an item and returns the path for the request
 * @param {function} queryKeyBuilder Function that takes an item and returns the query key for the request (e.g. ['todo', {id: 5}]) https://tanstack.com/query/v4/docs/react/guides/query-keys

 * @returns Parsed API call response, or error, or loading state
 */
// Add this function to your global data fetching utilities
export function getRequestParallelQueries(
  items: any[],
  pathBuilder: (item: any) => string,
  queryKeyBuilder: (item: any) => any[],
) {
  return useQueries({
    queries: (items || []).map((item) => {
      const path = pathBuilder(item);
      const queryKey = queryKeyBuilder(item);
      return {
        queryKey,
        queryFn: () =>
          axiosInstance.get(`${baseUrl}${path}`).then((res) => {
            return res.data;
          }),
      };
    }),
  });
}

export function getRequestRefetchInterval(
  path: string,
  queryKey: string,
  urlParamsObject: object,
  options: object,
  interval: number,
) {
  if (!baseUrl) {
    console.error('VITE_BASE_API_URL is not defined');
  }

  // Merge default and user options
  const mergedOptions = {
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);

  const requestUrl = `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [queryKey],
    queryFn: () =>
      axiosInstance.get(requestUrl, mergedOptions).then((res) => {
        return res.data;
      }),
    refetchInterval: interval,
    refetchIntervalInBackground: true, // Enable refetching when the browser window is not in focus
  });

  // Handle response
  if (query.error) {
    console.error(query.error);
  }

  return query;
}

/**
 * Helper to make POST requests to our API endpoints
 * @param {string} path Path of the API route
 * @param {string} service API service to use for the request (e.g. 'contacts', 'conscript', etc.) also used for the query key and caching purposes
 * @param {Object} body Body of the request

 * @returns Returns an axios instance 
 */
export const defaultPost = async (path: string, service: string, body: object) => {
  const url = `${baseUrl}/${service}/${firmId}${path}`;

  return axiosInstance({
    url,
    method: 'POST',
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

/**
 * Helper to make POST requests to our API endpoints with custom path
 * @param {string} path Path of the API route
 * @param {Object} body Body of the request

 * @returns Returns an axios instance 
 */
export const postCustomPath = async (path: string, body: object) => {
  const url = `${baseUrl}${path}`;

  return axiosInstance({
    url,
    method: 'POST',
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

/**
 * Helper to make PUT requests to our API endpoints with custom path
 * @param {string} customPath Path of the API route
 * @param {Object} body Body of the request
 * @returns Returns an axios instance
 */
export const putCustomPath = async (customPath: string, body: object) => {
  const url = `${baseUrl}${customPath}`;

  return axiosInstance({
    url,
    method: 'PUT',
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

/**
 * Helper to make DELETE requests to our API endpoints
 * @param {string} path Path of the API route
 * @param {string} service API service to use for the request (e.g. 'contacts', 'conscript', etc.) also used for the query key and caching purposes
 * @param {Object} body Body of the request
 * @returns Returns an axios instance
 */
export const defaultDelete = async (path: string, service: string, body: object) => {
  const url = `${baseUrl}/${service}/${firmId}${path}`;

  return axiosInstance({
    url,
    method: 'DELETE',
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

/**
 * Helper to make DELETE requests to our API endpoints with custom path
 * @param {string} customPath Path of the API route
 * @param {Object} body Body of the request
 * @returns Returns an axios instance
 */
export const deleteCustomPath = async (customPath: string, body: object) => {
  const url = `${baseUrl}${customPath}`;

  return axiosInstance({
    url,
    method: 'DELETE',
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
