import { tanstackTableNames } from '@colosseum/data';
import { DataTable, getInitialVisibilityForTable } from '@colosseum/shared-ui';
import { ContactType, VehicleType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { vehicleColumns } from './vehicle-table-columns';

/* eslint-disable-next-line */
export interface LinkedVehiclesTableProps {
  vehicles: (
    | (VehicleType & {
        towCompany?: ContactType;
        repairShop?: ContactType;
        rentalCarCompany?: ContactType;
      })
    | undefined
  )[];
  handleRowClick: (row: Row<VehicleType>) => void;
  isLoading: boolean;
}

export function LinkedVehiclesTable(props: LinkedVehiclesTableProps) {
  const { vehicles, handleRowClick, isLoading } = props;
  return (
    <DataTable
      hideFooter
      columns={vehicleColumns}
      data={vehicles ?? []}
      handleRowClick={handleRowClick}
      isLoading={isLoading}
      tableName={tanstackTableNames.scenarioVehicles}
    />
  );
}

export default LinkedVehiclesTable;
