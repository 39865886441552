import {
  cn,
  useCreateCustomAttribute,
  useCreateCustomAttributeOption,
  useDeleteCustomAttribute,
  useDeleteCustomAttributeOption,
  useGetCustomAttributes,
  useUpdateCustomAttribute,
} from '@colosseum/data';
import {
  Button,
  DataTable,
  Form,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slideover,
  TextFormInput,
} from '@colosseum/shared-ui';
import { CustomAttributeType, attributeTypeOptions } from '@gladiate/types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { startCase } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './custom-attribute-table-columns';

/* eslint-disable-next-line */
export interface CustomAttributesTableProps {
  attributeResource: 'case' | 'contact';
}

const formSchema = z.object({
  attributeLabel: z.string(),
  attributeType: z.string().optional(),
  newDropdownOption: z.string().optional(),
});

const newOptionSchema = z.object({
  newDropdownOption: z.string(),
});

export function CustomAttributesTable(props: CustomAttributesTableProps) {
  const { attributeResource } = props;
  const { data, isLoading: isCustomAttributesLoading } = useGetCustomAttributes();
  const deleteCustomAttribute = useDeleteCustomAttribute();
  const createCustomAttribute = useCreateCustomAttribute();
  const updateCustomAttribute = useUpdateCustomAttribute();
  const createCustomAttributeOption = useCreateCustomAttributeOption();
  const deleteCustomAttributeOption = useDeleteCustomAttributeOption();
  const typing = createCustomAttribute.isLoading || updateCustomAttribute.isLoading;

  const filteredData = data?.data.filter(
    (attribute) => attribute.attributeResource === attributeResource,
  );
  const [open, setOpen] = useState(false);
  const [openCustomAttributeId, setOpenCustomAttributeId] = useState<string | undefined>();
  const openCustomAttribute = useMemo(
    () => data?.data.find((attribute) => attribute.customAttributeId === openCustomAttributeId),
    [data, openCustomAttributeId],
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      attributeLabel: openCustomAttribute?.attributeLabel || '',
      attributeType: openCustomAttribute?.attributeType || '',
      newDropdownOption: '',
    },
  });

  const newDropdownOptionForm = useForm<z.infer<typeof newOptionSchema>>({
    resolver: zodResolver(newOptionSchema),
    mode: 'onBlur',
    values: {
      newDropdownOption: '',
    },
  });

  const handleCreate = (data: {
    attributeLabel?: string;
    attributeType: CustomAttributeType['attributeType'];
  }) => {
    createCustomAttribute.mutateAsync({ ...data, attributeResource }).then((res) => {
      setOpenCustomAttributeId(res.data.customAttributeId);
      setOpen(true);
    });
  };

  const handleUpdate = (key: string, value: string | string[]) => {
    return updateCustomAttribute.mutateAsync({
      customAttributeId: openCustomAttribute?.customAttributeId ?? '',
      [key]: value,
    });
  };

  const listItems = useMemo(
    () =>
      attributeTypeOptions.reduce(
        (acc, option) => ({
          ...acc,
          [startCase(option)]: option,
        }),
        {} as string[],
      ),
    [attributeTypeOptions],
  );

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenCustomAttributeId(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openCustomAttribute?.customAttributeId)
            deleteCustomAttribute.mutateAsync(openCustomAttribute?.customAttributeId).then(() => {
              setOpenCustomAttributeId(undefined);
              setOpen(false);
            });
        }}
        title="Custom Attribute"
        description="Update your Custom Attribute's information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <div>
                <span className="font-semibold">{'Type: '}</span>
                {startCase(openCustomAttribute?.attributeType)}
              </div>
              <TextFormInput
                {...form.register(`attributeLabel`)}
                title="Label"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('attributeLabel', target.value);
                }}
              />
              {['dropdown', 'multiSelect'].includes(openCustomAttribute?.attributeType ?? '') && (
                <div>
                  <div className="font-semibold">Dropdown options</div>
                  <div className="flex flex-col mt-8">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full px-1 py-2 align-middle">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full">
                            <thead className="bg-white">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-3/4"
                                >
                                  Option
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4"
                                >
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            {
                              // if there are no options then display a message
                              (!openCustomAttribute?.customAttributeOptions ||
                                openCustomAttribute?.customAttributeOptions.length === 0) && (
                                <tbody className="bg-white">
                                  <tr className="border-t border-gray-200">
                                    <td
                                      colSpan={5}
                                      className="py-4 pl-4 pr-2 text-sm font-semibold text-left text-gray-900 bg-gray-100 sm:pl-6 sm:pr-3"
                                    >
                                      <div className="flex items-center justify-center">
                                        No options have been added yet.
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            }
                            <tbody className="bg-white">
                              {openCustomAttribute?.customAttributeOptions &&
                                openCustomAttribute?.customAttributeOptions?.map((option) => (
                                  <Fragment key={option.customAttributeOptionId}>
                                    <tr
                                      className={cn(
                                        'cursor-pointer hover:bg-gray-200',
                                        'border-gray-200',
                                        'border-t',
                                      )}
                                    >
                                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        {option.customOptionValue || '-'}
                                      </td>
                                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                        <Button
                                          className="p-1"
                                          onClick={(e) => {
                                            e.preventDefault(); // delete option
                                            deleteCustomAttributeOption.mutate(
                                              option.customAttributeOptionId,
                                            );
                                          }}
                                        >
                                          <TrashIcon className="w-4 h-4 text-red-500" />
                                        </Button>
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form {...newDropdownOptionForm}>
                    <form className="flex pt-4 gap-x-2">
                      <TextFormInput
                        {...newDropdownOptionForm.register('newDropdownOption')}
                        title="New Dropdown Option"
                      />
                      <Button
                        type="submit"
                        className="px-6 text-white bg-sky-blue hover:bg-atlantic-blue hover:text-white"
                        variant="outline"
                        onClick={(e) => {
                          // create new option
                          e.preventDefault();
                          createCustomAttributeOption
                            .mutateAsync({
                              customAttributeId: openCustomAttribute?.customAttributeId ?? '',
                              customOptionValue:
                                newDropdownOptionForm.getValues('newDropdownOption') ?? '',
                            })
                            .then(() => {
                              newDropdownOptionForm.resetField('newDropdownOption');
                            });
                        }}
                      >
                        Add Option
                      </Button>
                    </form>
                  </Form>
                </div>
              )}
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="case-types" className="text-xl font-semibold text-gray-900">
              {`${startCase(attributeResource)} Custom Attributes`}
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's ${attributeResource} custom attributes.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Select
              name="attribute-type-dropdown"
              value={undefined}
              onValueChange={(type) => {
                handleCreate({
                  attributeType: type as CustomAttributeType['attributeType'],
                });
              }}
            >
              <SelectTrigger
                data-cy="create-attribute-dropdown-button"
                className="w-auto text-white bg-atlantic-blue"
              >
                <SelectValue>Create New Attribute</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {attributeTypeOptions?.map((listItem) => (
                    <SelectItem key={listItem} value={listItem ?? ''} noIndicator>
                      {startCase(listItem)}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <DataTable
                hideToolbar
                data={filteredData ?? []}
                columns={columns}
                handleRowClick={(e) => {
                  setOpenCustomAttributeId(e.original.customAttributeId);
                  setOpen(true);
                }}
                initialSort={{
                  id: 'Date Created',
                  desc: true,
                }}
                initialVisibility={{
                  'Date Created': false,
                }}
                isLoading={isCustomAttributesLoading}
                tableName="customAttributes"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomAttributesTable;
