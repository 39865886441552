import {
  BitBoolean,
  CallType,
  EmailType,
  ManualMessageType,
  MessageType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { useGetCaseContactConnectionsViewModel } from '../lib/react-query/case-contact-connection';
import { useGetContact } from '../lib/react-query/contact';
import { useGetCommunicationsForContacts, useGetEmailsForCase } from '../lib/react-query/phone';
import { convertToE164 } from '../utils/miscUtils';

export interface CommunicationLogViewModel {
  id: string;
  type: 'emails' | 'calls' | 'messages' | 'unknown';
  icon?: string;
  label?: CommLogItemLabel;
  isSystemGenerated?: BitBoolean;
  contactId?: string;
  dateCreated?: string;
  dateModified?: string;
  dateTime?: string;
  description?: string;
  firmId?: string;
  logMechanism?: string;
  subject?: string;
  direction?: string;
  duration?: string;
  fromNumber?: string;
  toNumber?: string;
  status?: string;
  uri?: string;
  body?: string;
}

export const getIdAndType = (obj: CallType | MessageType | EmailType) => {
  const unknown = 'unknown';
  const result: {
    id: string;
    type: 'emails' | 'calls' | 'messages' | 'unknown';
  } = {
    id: unknown,
    type: unknown,
  };
  if ('callId' in obj) {
    result.id = obj.callId || unknown;
    result.type = 'calls';
  } else if ('messageId' in obj) {
    result.id = obj.messageId || unknown;
    result.type = 'messages';
  } else if ('emailId' in obj) {
    result.id = obj.emailId || unknown;
    result.type = 'emails';
  }
  return result;
};

interface CommLogItemLabel {
  text: string;
  icon: string;
}

const commLogItemLabelOptions = {
  textSystem: {
    text: 'Text - System',
    icon: 'communication',
  },
  emailSystem: {
    text: 'Email - System',
    icon: 'email',
  },
  textManual: {
    text: 'Text - Manual Entry',
    icon: 'communication',
  },
  emailManual: {
    text: 'Email - Manual Entry',
    icon: 'email',
  },
  callManual: {
    text: 'Call - Manual Entry',
    icon: 'phone',
  },
  unknown: {
    text: 'Unknown',
    icon: '',
  },
};

const getLabel = (logMechanism: string, type: string) => {
  switch (logMechanism) {
    case 'twilio':
      return commLogItemLabelOptions.textSystem;
    case 'generated':
      return commLogItemLabelOptions.emailSystem;
    case 'manual':
      if (type === 'messages') {
        return commLogItemLabelOptions.textManual;
      } else if (type === 'emails') {
        return commLogItemLabelOptions.emailManual;
      }
      return commLogItemLabelOptions.callManual;
    default:
      return commLogItemLabelOptions.unknown;
  }
};

export interface CommLogContacts {
  [key: string]: {
    phoneNumberE164List: string[];
    roleList: (string | undefined)[];
  };
}

export const getCommunicationLogViewModel = (
  log: CallType | MessageType | EmailType,
): CommunicationLogViewModel => {
  const { id, type } = getIdAndType(log);
  return {
    id,
    type,
    label: getLabel(log?.logMechanism || '', type),
    isSystemGenerated: ['twilio', 'generated'].includes(log?.logMechanism || '') ? '1' : '0',
    contactId: id.split('-')[0] || '',
    dateCreated: log.dateCreated,
    dateTime: log.dateTime,
    description: log.description || '',
    logMechanism: log.logMechanism || '',
    ...('dateModified' in log ? { dateModified: log.dateModified } : {}),
    ...('firmId' in log ? { firmId: log.firmId } : {}),
    ...('subject' in log ? { subject: log.subject } : {}),
    ...('direction' in log ? { direction: log.direction } : {}),
    ...('duration' in log ? { duration: log.duration } : {}),
    ...('fromNumber' in log ? { fromNumber: log.fromNumber } : {}),
    ...('toNumber' in log ? { toNumber: log.toNumber } : {}),
    ...('status' in log ? { status: log.status } : {}),
    ...('uri' in log ? { uri: log.uri } : {}),
    ...('body' in log ? { body: log.body } : {}),
    ...('contactId' in log ? { contactId: log.contactId } : {}),
  };
};

export const getUpdateBody = (data: CommunicationLogViewModel): ManualMessageType => {
  return {
    ...(data.type === 'calls' ? { callId: data.id } : {}),
    ...(data.type === 'messages' ? { messageId: data.id } : {}),
    ...(data.type === 'emails' ? { emailId: data.id } : {}),
    ...('fromNumber' in data ? { fromNumber: data.fromNumber } : {}),
    ...('toNumber' in data ? { toNumber: data.toNumber } : {}),
    ...('direction' in data ? { direction: data.direction } : {}),
    ...('description' in data ? { description: data.description } : {}),
    ...('dateTime' in data ? { dateTime: data.dateTime } : {}),
    ...('body' in data ? { body: data.body } : {}),
    ...('subject' in data ? { subject: data.subject } : {}),
    ...('contactId' in data ? { contactId: data.contactId } : {}),
  };
};

export const useGetCommunicationLogViewModel = (caseId: string, contactIdList: string[]) => {
  const { data: communicationsData, isLoading: isCommunicationsLoading } =
    useGetCommunicationsForContacts(contactIdList);
  const { data: emailsData, isLoading } = useGetEmailsForCase(caseId);

  const { data: caseContactConnectionsData } = useGetCaseContactConnectionsViewModel(caseId);
  const caseContactConnections = caseContactConnectionsData?.data || [];
  const caseContactConnectionContacts = caseContactConnections.map((connection) => {
    return connection.contact;
  });
  const { data: contactData } = useGetContact(
    contactIdList.length === 1 ? contactIdList[0] : undefined,
  ); // only enable if exactly one contact
  const allContacts =
    contactIdList.length > 1 ? caseContactConnectionContacts : [contactData?.data];
  const allContactWithRoles = allContacts.map((contact) => {
    return {
      ...contact,
      roles: caseContactConnections.find(
        (connection) => connection.contact.contactId === contact?.contactId,
      )?.roles,
    };
  });

  const getCommLogs = () => {
    const textAndCallList: CommunicationLogViewModel[] = (communicationsData?.data || [])
      .flatMap((communication) => [...communication.messages, ...communication.calls])
      .map(getCommunicationLogViewModel);

    const emailList = (emailsData?.data || []).map(getCommunicationLogViewModel);

    return (
      textAndCallList.concat(emailList).sort((a, b) => {
        if (!a.dateTime || !b.dateTime) {
          return 0;
        }

        return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
      }) || []
    );
  };

  const contactsRelatedToCasePhoneNumbers = allContacts
    ?.flatMap((contact) => {
      return contact?.contactNumbers?.map((phone) => {
        return {
          id: contact.contactId,
          phoneNumber: convertToE164(phone?.number ?? ''),
        };
      });
    })
    .filter((phone) => phone?.phoneNumber);

  const contactRoles: CommLogContacts = {};
  allContactWithRoles.forEach((item) => {
    contactRoles[item?.contactId ?? ''] = {
      phoneNumberE164List:
        contactsRelatedToCasePhoneNumbers
          ?.filter((number) => number?.id === item?.contactId)
          .map((number) => number?.phoneNumber || '') || [],
      roleList: [
        ...new Set(
          item?.roles
            ?.filter(
              (role) => role?.roleOnCase && role?.roleOnCase !== caseContactConnectionOptions.party,
            )
            .map((role) => role?.roleOnCase),
        ),
      ],
    };
  });

  return {
    data: { logs: getCommLogs(), contacts: contactRoles },
    isLoading: isCommunicationsLoading || isLoading,
  };
};
