import { settingsSectionMappings } from '@colosseum/data';
import { AttorneysFeesTable } from '@colosseum/shared-ui';

/* eslint-disable-next-line */
export interface AttorneyFeesProps {}

export function AttorneyFees() {
  return (
    <>
      <div className="px-1">
        <div
          className="sm:flex sm:items-center"
          data-settings-submenu-item={settingsSectionMappings.attorneyFees}
        >
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Attorney&apos;s Fees</h1>
            <p className="mt-2 text-sm text-gray-700">
              Your firm attorney&apos;s fees that will be loaded with every new case.
            </p>
          </div>
        </div>
      </div>
      <AttorneysFeesTable />
    </>
  );
}

export default AttorneyFees;
