import {
  useCreateTaskForTaskPlan,
  useGetStaffRoles,
  useGetTasksForTaskPlan,
  useUpdateTaskForTaskPlan,
} from '@colosseum/data';
import { TaskForTaskPlanType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import CaseStatusSelect from '../../CaseStatusSelect/CaseStatusSelect';
import TaskPlanTasksCard from '../../TaskPlanTasksCard/TaskPlanTasksCard';
import { Form, FormItem, FormLabel } from '../../shadcn/Form/Form';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

export interface TaskPlanTaskFormProps {
  task?: TaskForTaskPlanType;
}

const taskFormSchema = z.object({
  assignee: z.string().optional(),
  assigner: z.string().optional(),
  caseStatus: z.string().optional(),
  creationAfterDaysInStatus: z.coerce.number().nonnegative().optional(),
  daysToDueDate: z.coerce.number().nonnegative().optional(),
  description: z.string().optional(),
  title: z.string().optional(),
});

export function TaskPlanTaskForm(props: TaskPlanTaskFormProps) {
  const { task } = props;
  const { data: tasksForPlanData, isLoading: tasksLoading } = useGetTasksForTaskPlan(
    task?.planTaskId?.split('-')[0] ?? '',
  );
  const tasksForPlan = tasksForPlanData && tasksForPlanData.data;
  const dependantTasks = tasksForPlan?.filter(
    (planTask) => planTask?.createOnPlanTaskIdCompletion === task?.planTaskId,
  );

  const { data: staffRolesData } = useGetStaffRoles();
  const updateTaskForPlan = useUpdateTaskForTaskPlan();
  const createTaskForPlan = useCreateTaskForTaskPlan();
  const staffRoleDropdownOptions =
    staffRolesData?.data?.reduce((acc, staffRole) => {
      return {
        ...acc,
        [staffRole.title ?? '']: staffRole.roleId ?? '',
      };
    }, {} as { [key: string]: string }) ?? {};
  const taskForm = useForm<z.infer<typeof taskFormSchema>>({
    resolver: zodResolver(taskFormSchema),
    values: {
      assignee: task?.assignee,
      assigner: task?.assigner,
      caseStatus: task?.caseStatus,
      creationAfterDaysInStatus: task?.creationAfterDaysInStatus,
      daysToDueDate: task?.daysToDueDate,
      description: task?.description,
      title: task?.title,
    },
    mode: 'onBlur',
  });

  const handleOnBlur = (key: string, value: string | number) => {
    updateTaskForPlan.mutate({
      planTaskId: task?.planTaskId ?? '',
      data: {
        [key]: value,
      },
    });
  };
  return (
    <Form {...taskForm}>
      <form>
        <div className="grid grid-cols-2 pb-6 mb-6 border-b border-b-gray-300 gap-x-3 gap-y-5 border-b-1">
          <TextFormInput
            name="title"
            title="Title"
            handleOnBlur={(e) => {
              handleOnBlur('title', e.target.value);
            }}
          />
          <FormItem className="w-full">
            <FormLabel>Case Status</FormLabel>
            <CaseStatusSelect
              className="w-full"
              currentCaseStatusId={task?.caseStatus}
              handleValueChange={(caseStatusId: string) => {
                if (caseStatusId) {
                  handleOnBlur('caseStatus', caseStatusId);
                }
              }}
            />
          </FormItem>

          <SelectFormInput
            placeholderText="Select Assignee"
            defaultValue={task?.assignee}
            listItems={staffRoleDropdownOptions}
            listItemsIsObject
            name={'assignee'}
            title={'Assignee'}
            handleOnChange={(e) => {
              handleOnBlur('assignee', e.target.value);
            }}
          />
          <SelectFormInput
            placeholderText="Select Assigner"
            defaultValue={task?.assigner}
            listItems={staffRoleDropdownOptions}
            listItemsIsObject
            name={'assigner'}
            title={'Assigner'}
            handleOnChange={(e) => {
              handleOnBlur('assigner', e.target.value);
            }}
          />
          <TextFormInput
            {...taskForm.register('daysToDueDate')}
            title="Days to Due Date"
            type="number"
            handleOnBlur={(e) => {
              const value = Number(e.target.value);
              if (value < 0) return;
              handleOnBlur('daysToDueDate', value);
            }}
          />
          <TextFormInput
            {...taskForm.register('creationAfterDaysInStatus')}
            title="Days in Status before Creation"
            type="number"
            handleOnBlur={(e) => {
              const value = Number(e.target.value);
              if (value < 0) return;
              handleOnBlur('creationAfterDaysInStatus', value);
            }}
          />
          <div className="col-span-2">
            <TextFormInput
              name="description"
              title="Description"
              type="textarea"
              handleOnBlur={(e) => {
                handleOnBlur('description', e.target.value);
              }}
            />
          </div>
        </div>
        <TaskPlanTasksCard
          tasks={dependantTasks ?? []}
          title="Tasks created upon completion"
          isLoading={false}
          handleCreateTaskForTaskPlan={() =>
            createTaskForPlan.mutateAsync({
              planId: task?.planTaskId?.split('-')[0] ?? '',
              data: {
                title: 'New Task',
                description: 'New Task Description',
                createOnPlanTaskIdCompletion: task?.planTaskId ?? '',
              },
            })
          }
        />
      </form>
    </Form>
  );
}

export default TaskPlanTaskForm;
