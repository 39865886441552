import {
  displayContactName,
  useGetAllVehicles,
  useGetCaseContactConnections,
  useGetCases,
  useGetContactsInfinite,
} from '@colosseum/data';
import { CaseSubType } from '@gladiate/types';
import { startCase } from 'lodash';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { SlideoverContext } from '../SlideoverProvider';
import BreadcrumbItem from './BreadcrumbItem';

export interface BreadcrumbsProps {
  resourceType?: CaseSubType;
  resourceTypes?: CaseSubType[];
  resourceId?: string;
  isLead?: boolean;
}

export type LinkType = {
  onClick?: (() => void)[];
  label?: string;
  linkText?: string[];
  unclickable?: boolean;
  icon?: string;
};

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { resourceType, resourceId, isLead } = props;
  const caseId = resourceId?.split('-')[0];
  const navigate = useNavigate();
  const { setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const { data: casesData, isLoading: isCasesDataLoading } = useGetCases();
  const caseData = casesData?.data?.find((caseItem) => caseItem.caseId === caseId);
  const { data: vehiclesData } = useGetAllVehicles(resourceType === 'vehicle');

  const vehicleData = vehiclesData?.data?.find(
    (vehicleItem) =>
      vehicleItem.vehicleId === resourceId?.split('-')[0] + '-' + resourceId?.split('-')[1],
  );
  const { data: contactsData } = useGetContactsInfinite();
  const contactData = contactsData?.data?.find((contactItem) => contactItem.contactId === caseId);
  const { data: conscriptionsForContact } = useGetCaseContactConnections(
    contactData?.contactId ?? '',
    'contacts',
  );
  const casesForContact = conscriptionsForContact?.data?.map((conscription) =>
    casesData?.data?.find(
      (caseItem) => caseItem.caseId === conscription.caseContactConnectionId.split('-')[0],
    ),
  );

  const urlPrefix = isLead ? '/leads' : '/cases';

  const generateBreadcrumbLinks = () => {
    const caseChip = isCasesDataLoading
      ? {
          linkText: ['Loading...'],
          unclickable: true,
        }
      : caseData
      ? {
          label: isLead ? 'Lead' : 'Case',
          linkText: [caseData?.caseTitle ?? '<No Title>'],
          onClick: [
            () => {
              navigate(`${urlPrefix}/${caseData?.caseId}`);
            },
          ],
          icon: isLead ? 'lead' : 'case',
        }
      : {
          linkText: [isLead ? '<Deleted Lead>' : '<Deleted Case>'],
          unclickable: true,
        };
    const contactChip = contactData
      ? {
          label: 'Contact',
          linkText: [displayContactName(contactData) ?? '<No Name>'],
          onClick: [
            () => {
              navigate(`/contacts/${contactData?.contactId}`);
            },
          ],
          icon: 'contact',
        }
      : {
          linkText: ['<Deleted or Unknown Contact>'],
          unclickable: true,
          icon: 'contact',
        };

    switch (resourceType) {
      case 'case': {
        return [caseChip];
      }
      case 'lead': {
        return [caseChip];
      }
      case 'communicationLog':
      case 'communication': {
        const contactsCaseChip = casesForContact
          ? {
              label: isLead ? 'Lead' : casesForContact.length > 1 ? 'Cases' : 'Case',
              linkText: casesForContact?.map((caseItem) => caseItem?.caseTitle ?? '<No Title>'),
              onClick: casesForContact?.map((caseItem) => {
                return () => navigate(`${urlPrefix}/${caseItem?.caseId}`);
              }),
              icon: isLead ? 'lead' : 'case',
            }
          : {
              linkText: [isLead ? '<Deleted Lead>' : '<Deleted Case>'],
              unclickable: true,
              icon: isLead ? 'lead' : 'case',
            };
        const communicationChips: LinkType[] = [contactChip];
        if (casesForContact?.length) {
          communicationChips.push(contactsCaseChip);
          communicationChips.push({
            linkText: [startCase(resourceType)],
            onClick: [
              () => {
                navigate(`${urlPrefix}/${casesForContact?.[0]?.caseId}`);
                setPendingSlideoverToOpen({
                  type: resourceType,
                });
              },
            ],
            unclickable: !casesForContact?.[0],
            icon: 'communication',
          });
        } else {
          communicationChips.push({
            linkText: [startCase(resourceType)],
            onClick: [
              () => {
                navigate(`/contacts/${contactData?.contactId}`);
                setPendingSlideoverToOpen({
                  type: resourceType,
                });
              },
            ],
            unclickable: !contactData?.contactId,
            icon: resourceType,
          });
        }
        return communicationChips;
      }
      case 'vehicle': {
        const vehicleLinkText =
          vehicleData?.make && vehicleData?.model
            ? `${vehicleData.make} ${vehicleData.model}`
            : '<No Title>';
        return [
          caseChip,
          {
            label: 'Vehicle',
            linkText: [vehicleLinkText],
            icon: 'vehicle',
            onClick: [
              () => {
                navigate(`${urlPrefix}/${caseData?.caseId}`);
                setPendingSlideoverToOpen({
                  id: resourceId?.split('-')[0] + '-' + resourceId?.split('-')[1],
                  type: resourceType,
                });
              },
            ],
          },
        ];
      }
      case 'contact': {
        return [contactChip];
      }
      case 'agreement':
      case 'file':
      case 'caseContactConnection':
      case 'discovery':
      case 'expense':
      case 'caseSurvey':
      case 'lien':
      case 'medicalAppointment':
      case 'medicalTreatment':
      case 'offlineEvent':
      case 'priorMedicalTreatment':
      case 'settlementProposal':
      case 'medicalRecordRequest':
      case 'policy':
      case 'trustAccountTransaction': {
        return [
          caseChip,
          {
            linkText: [startCase(resourceType)],
            onClick: [
              () => {
                navigate(`${urlPrefix}/${caseData?.caseId}`);
                setPendingSlideoverToOpen({
                  id: resourceId?.split('-')[0] + '-' + resourceId?.split('-')[1],
                  type: resourceType,
                });
              },
            ],
            unclickable: !caseData?.caseId,
            icon: resourceType,
          },
        ];
      }
      case 'note':
      case 'noteReply': {
        const baseChip = caseData ? caseChip : contactData ? contactChip : null;
        const chips = [];
        if (baseChip) {
          chips.push(baseChip);
        }
        chips.push({ linkText: [startCase(resourceType)], icon: 'note' });
        return chips as LinkType[];
      }
      case 'staff':
      case 'task':
      case 'email': {
        return [caseChip, { linkText: [startCase(resourceType)], icon: resourceType }];
      }
      case 'template': // TODO: add template breadcrumb
      default: {
        return [{ linkText: [startCase(resourceType)], icon: resourceType }];
      }
    }
  };

  const links: LinkType[] = generateBreadcrumbLinks();

  return (
    <div>
      <nav aria-label="breadcrumb" className="flex items-center">
        {links.map((link, index) => (
          <BreadcrumbItem
            key={`${link?.label}-${index}`}
            link={link}
            lastItem={index === links.length - 1}
          />
        ))}
      </nav>
    </div>
  );
}

export default Breadcrumbs;
