import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import React from 'react';
import { cn } from '@colosseum/data';

export interface SwitchProps {
  disabled?: boolean;
  fullRow?: boolean;
  handleChange: (arg0: any) => void;
  justify?: 'left' | 'right' | 'between';
  name: string;
  title: string;
  value: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const { disabled, fullRow, handleChange, justify, name, title, value } = props;
  return (
    <HeadlessUiSwitch.Group
      ref={ref}
      as="div"
      className={classNames(
        'flex items-center px-1',
        `justify-${(justify as string) ?? 'between'}`,
        fullRow ? 'w-1/2 col-span-2' : '',
      )}
    >
      <HeadlessUiSwitch.Label as="span" className="mr-3 text-sm">
        <label
          htmlFor={name}
          className={cn(
            'text-gray-900',
            disabled ? 'opacity-40 pointer-events-none' : 'font-medium',
          )}
        >
          {title ?? 'No title'}
        </label>
      </HeadlessUiSwitch.Label>
      <HeadlessUiSwitch
        id={name}
        data-cy={`switch-${name }`}
        data-testid={`switch-${name }`}
        checked={value}
        className={classNames(
          value ? 'bg-atlantic-blue' : 'bg-gray-200',
          disabled ? 'pointer-events-none bg-gray-200' : 'cursor-pointer',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-atlantic-blue focus:ring-offset-2',
        )}
        // TODO: FIx this eslint-disable. not sure why its happening
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
        onChange={(e) => !disabled && handleChange({ target: { value: e, name } })}
      >
        {/* <span className="sr-only">Use setting</span> */}
        <span
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <span
            className={classNames(
              value ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            {!disabled && (
              <svg className="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          <span
            className={classNames(
              value ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            {!disabled && (
              <svg className="w-3 h-3 text-atlantic-blue" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            )}
          </span>
        </span>
      </HeadlessUiSwitch>
    </HeadlessUiSwitch.Group>
  );
});

Switch.displayName = 'Switch';

export default Switch;
