/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cn, registerChangeEvents } from '@colosseum/data';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/Form/Form';
import { Input } from '../../shadcn/Input/Input';
import { Textarea } from '../../shadcn/TextArea/TextArea';

/* eslint-disable-next-line */
export interface TextFormInputProps {
  handleOnBlur?:
    | (React.FocusEventHandler<HTMLTextAreaElement> & React.FocusEventHandler<HTMLInputElement>)
    | undefined;
  handleOnChange?:
    | (React.FocusEventHandler<HTMLTextAreaElement> & React.FocusEventHandler<HTMLInputElement>)
    | undefined;
  name: string;
  title: string;
  placeholderText?: string;
  type?: string;
  dataCy?: string;
  disabled?: boolean;
  labelClassName?: string;
  maxLength?: number;
  hideLabel?: boolean;
  allowUserTags?: boolean;
}

export const TextFormInput = React.forwardRef<HTMLInputElement, TextFormInputProps>(
  (props, ref) => {
    const {
      handleOnBlur,
      name,
      title,
      type,
      placeholderText = '',
      dataCy,
      handleOnChange,
      disabled,
      labelClassName,
      maxLength,
      hideLabel = false,
      allowUserTags = false,
    } = props;
    const form = useFormContext();
    const RenderComponent = type === 'textarea' ? Textarea : Input;

    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            {!hideLabel && <FormLabel className={labelClassName}>{title ?? 'Field'}</FormLabel>}
            <FormControl>
              <RenderComponent
                className={cn(disabled && ' ring-gray-200 text-gray-500')}
                {...registerChangeEvents({
                  field,
                  form,
                  handleOnBlur,
                  handleOnChange,
                })}
                {...field}
                maxLength={maxLength ?? undefined}
                ref={ref}
                type={type ?? 'text'}
                data-cy={dataCy ?? `text-input-${kebabCase(name) as string}`}
                disabled={disabled}
                placeholder={placeholderText}
                allowUserTags={allowUserTags}
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    );
  },
);

TextFormInput.displayName = 'TextFormInput';

export default TextFormInput;
