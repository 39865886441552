import {
  useGetFirmUserWithDisplayNameFromUsername,
  useGetNotes,
  useSwapNotePriority,
} from '@colosseum/data';
import { Button, GladiateLoader, ResourceNote, Slideover, Typography } from '@colosseum/shared-ui';
import { NoteType } from '@gladiate/types';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@heroicons/react/24/outline';
import { DrawingPinIcon } from '@radix-ui/react-icons';
import dayjs from 'dayjs';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useState } from 'react';

/* eslint-disable-next-line */
export interface PinnedNotesCardProps {
  caseId: string;
}

export function PinnedNotesCard(props: PinnedNotesCardProps) {
  const { caseId } = props;
  // #region Hooks
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  });
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  // #endregion

  // #region Data Fetching
  const {
    data: notes,
    isLoading: isResourceNotesLoading,
    isFetching: isResourceNotesFetching,
  } = useGetNotes(caseId);
  const swapNotePriorities = useSwapNotePriority();
  // #endregion

  // #region State
  const [reorderSlideoverOpen, setReorderSlideoverOpen] = useState(false);
  // #endregion

  // #region Derived State
  const pinnedNotes =
    notes?.data
      ?.filter((note) => note.pinned === '1')
      .sort((a, b) => {
        if (!a.priority) return -1;
        if (!b.priority) return 1;
        if (a.priority > b.priority) return 1;
        if (a.priority < b.priority) return -1;
        return 0;
      }) ?? [];
  const lowestPriority = pinnedNotes[0]?.priority ?? 0;
  const highestPriority = pinnedNotes[pinnedNotes.length - 1]?.priority ?? 0;
  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers
  const handleMoveNoteUp = (note: NoteType) => {
    const noteIndex = pinnedNotes?.findIndex((n) => n.noteId === note.noteId) ?? 0;
    if (noteIndex === 0) return;
    const noteAbove = pinnedNotes?.[noteIndex - 1];
    swapNotePriorities.mutate({
      noteIdOne: note.noteId,
      noteIdTwo: noteAbove?.noteId,
    });
  };

  const handleMoveNoteDown = (note: NoteType) => {
    const noteIndex =
      pinnedNotes?.findIndex((n) => n.noteId === note.noteId) ?? pinnedNotes?.length - 1;
    if (noteIndex === pinnedNotes.length - 1) return;
    const noteBelow = pinnedNotes?.[noteIndex + 1];
    swapNotePriorities.mutate({
      noteIdOne: note.noteId,
      noteIdTwo: noteBelow?.noteId,
    });
  };
  // #endregion

  return (
    <div className="relative w-full p-6 pb-4 bg-white border border-gray-200 shadow-sm rounded-xl">
      <Slideover
        open={reorderSlideoverOpen}
        setOpen={setReorderSlideoverOpen}
        title="Reorder Pinned Notes"
        description="Click the up and down arrows to reorder the pinned notes."
      >
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            {pinnedNotes.map((note) => (
              <tr key={note.noteId} className="transition">
                <td className="">
                  <h1 className="mt-4 text-xs font-light">{`${
                    getFirmUserWithDisplayNameFromUsername(note.username ?? '')?.displayName
                  } - ${dayjs(`${note?.dateCreated ?? ''}Z`).calendar()}`}</h1>
                  <h1 className="mb-4 whitespace-pre-wrap line-clamp-4">{note.message}</h1>
                </td>
                <td className="w-[3%]">
                  {isResourceNotesLoading || isResourceNotesFetching ? (
                    <GladiateLoader height={40} className="w-[28px]" />
                  ) : (
                    <Button
                      disabled={note.priority === lowestPriority}
                      className="px-1 hover:text-atlantic-blue"
                      onClick={() => handleMoveNoteUp(note)}
                    >
                      <ArrowUpIcon className="w-5 h-5" />
                    </Button>
                  )}
                </td>
                <td className="w-[3%]">
                  {isResourceNotesLoading || isResourceNotesFetching ? (
                    <GladiateLoader height={40} className="w-[28px]" />
                  ) : (
                    <Button
                      disabled={note.priority === highestPriority}
                      className="px-1 hover:text-atlantic-blue"
                      onClick={() => handleMoveNoteDown(note)}
                    >
                      <ArrowDownIcon className="w-5 h-5" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </Slideover>
      <Typography variant="heading" className="flex items-center pb-4 gap-x-1">
        <DrawingPinIcon width={20} height={20} color="blue" />
        Pinned Notes
        <Button
          variant="outline"
          className="h-8 py-1 ml-2"
          onClick={() => setReorderSlideoverOpen(true)}
          disabled={pinnedNotes?.length === 0}
        >
          <Typography>Reorder</Typography>
        </Button>
      </Typography>
      {isResourceNotesLoading && (
        <div className="flex items-center justify-center h-32">Loading...</div>
      )}
      {pinnedNotes.length > 0 && (
        <div className="flex">
          <Button onClick={scrollPrev} className="self-center w-5 h-5 p-1 mr-2" variant="ghost">
            <ArrowLeftIcon className="h-5 min-w-5" />
          </Button>
          <div className="overflow-hidden grow" ref={emblaRef}>
            <div className="flex">
              {pinnedNotes.map((note, index) => (
                <div
                  className="flex-[0_0_98%] lg:flex-[0_0_49%] mr-2.5 justify-between border border-gray-200 rounded-xl p-4"
                  key={note.noteId}
                >
                  <ResourceNote note={note} caseId={caseId} />
                </div>
              ))}
            </div>
          </div>
          <Button onClick={scrollNext} className="self-center w-5 h-5 p-1 ml-2" variant="ghost">
            <ArrowRightIcon className="h-5 min-w-5 " />
          </Button>
        </div>
      )}
      {pinnedNotes.length === 0 && (
        <div className="flex items-center justify-center h-32">No pinned notes.</div>
      )}
    </div>
  );
}

export default PinnedNotesCard;
