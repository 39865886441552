import {
  CaseCompleteType,
  flattenArrayByKey,
  useGetCaseStatuses,
  useGetCasesComplete,
} from '@colosseum/data';
import { CaseStatusUpdateType } from '@gladiate/types';
import { useEffect, useState } from 'react';
import DataTabs from '../DataTabs/DataTabs';
import { DataTable } from '../shadcn/data-table/DataTable';
import {
  CycleByCaseColumns,
  CycleByStatusColumns,
  CycleTimeTableData,
} from './cycle-time-table-columns';

export interface CycleTimeTableProps {}

export function CycleTimeTable(props: CycleTimeTableProps) {
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const casesQuery = useGetCasesComplete();
  const { data: caseStatusesData } = useGetCaseStatuses(true);
  // #endregion

  // #region State
  const [grouping, setGrouping] = useState(['Case Title']);
  const [tableColumns, setTableColumns] = useState(CycleByCaseColumns);
  // #endregion

  // #region Derived State
  const casesWithStatusUpdates = casesQuery.data?.data?.map((caseData) => ({
    ...caseData,
    caseStatusUpdates: caseData.caseStatusUpdates
      ?.map((statusUpdate) => ({
        caseTitle: caseData.caseTitle,
        ...statusUpdate,
        status: caseStatusesData?.data.find(
          (status) => status.caseStatusId === statusUpdate.caseStatusId,
        ),
        caseStatusUpdate: caseStatusesData?.data.find(
          (status) => status.caseStatusId === statusUpdate.caseStatusId,
        ),
      }))
      .flat(),
  }));

  const flattenedCycleTimeData = flattenArrayByKey<CaseCompleteType, CaseStatusUpdateType>(
    casesWithStatusUpdates,
    'caseStatusUpdates',
  );
  // #endregion

  // #region useEffects
  useEffect(() => {
    if (grouping[0] === 'Case Title') {
      setTableColumns(CycleByCaseColumns);
    } else {
      setTableColumns(CycleByStatusColumns);
    }
  }, [grouping]);
  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <>
      <div className="flex flex-col gap-y-6">
        <DataTabs
          filters={[
            {
              title: 'By Case',
              count: casesQuery.data?.data?.length,
              value: 'Case Title',
            },
            {
              title: 'By Status',
              count: caseStatusesData?.data?.length ?? 0,
              value: 'Status',
            },
          ]}
          setActiveFilter={(value) => {
            setGrouping([value]);
          }}
          activeFilter={grouping[0]}
        />

        <DataTable
          isViewOnly
          data={flattenedCycleTimeData}
          columns={tableColumns}
          isLoading={casesQuery.isLoading || casesQuery.isFetching}
          tableName="Cycle Time"
          grouping={grouping}
          initialGroupingsExpanded={false}
          defaultPageSize={20}
        />
      </div>
    </>
  );
}
