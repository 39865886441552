import { removeNonNumericCharacters } from '@colosseum/data';
import { LabyrinthField, LabyrinthUpdateHandler } from '@gladiate/types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import Tooltip from '../data-display/Tooltip/Tooltip';

/* eslint-disable-next-line */
export interface LabyrinthTextInputProps {
  fieldInfo: LabyrinthField;
  fieldValue: any;
  handleOnChange: LabyrinthUpdateHandler;
  handleOnBlur: LabyrinthUpdateHandler;
  disabled?: boolean;
  greyedOut?: boolean;
}

const ZipCodeFormat = ({
  field, // { name, value, onChange, onBlur }
  ...props
}: any) => {
  const { fieldInfo, handleOnBlur, fieldValue, handleOnChange, ...rest } = props;

  return <PatternFormat {...field} {...rest} format="#####" allowEmptyFormatting />;
};

const CurrencyFormat = ({ field, ...props }: any) => {
  const { fieldInfo, handleOnBlur, fieldValue, handleOnChange, ...rest } = props;

  return (
    <NumericFormat
      {...field}
      {...rest}
      value={fieldValue}
      decimalScale={2}
      thousandSeparator={','}
    />
  );
};

const SSNFormat = ({
  field, // { name, value, onChange, onBlur }
  ...props
}: any) => {
  const { fieldInfo, handleOnBlur, fieldValue, handleOnChange, ...rest } = props;

  return <PatternFormat {...field} {...rest} format="###-##-####" allowEmptyFormatting mask="_" />;
};

function formatIncomingPhoneNumber(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+1 (' + match[2] + ') ' + match[3] + ' ' + match[4];
  }
  return null;
}
/**
 * @deprecated
 */
export function LabyrinthTextInput(props: LabyrinthTextInputProps) {
  const [value, setValue] = useState(props.fieldValue ?? '');
  const [error, setError] = useState(false);
  const [showSSN, setShowSSN] = useState(false);

  const fieldType = props.fieldInfo.type;

  const getFieldType = () => {
    switch (fieldType) {
      case 'datePicker':
        return 'date';
      case 'textBox':
        return 'text';
      case 'numberInput':
        return 'number';
      case 'time':
        return 'time';
      default:
        return 'text';
    }
  };

  useEffect(() => {
    setValue(props.fieldValue);

    if (props.fieldInfo?.type === 'phoneNumber') {
      const formattedNumber = formatIncomingPhoneNumber(props.fieldValue);
      setValue(formattedNumber);
    }
  }, [props.fieldValue]);

  const fieldClassName = classNames(
    'form-input block w-full rounded-md border-0 py-2 text-gray-900  placeholder:text-gray-400 sm:leading-6',
    props.disabled
      ? ''
      : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue shadow-sm',
    error ? 'ring-1 ring-inset ring-red-600' : '',
    props.greyedOut ? 'bg-gray-100' : '',
  );

  const labelClassName = classNames(
    'absolute inline-block flex items-center px-1 text-xs font-medium text-gray-400  rounded-sm -top-2 left-2',
    error ? ' animate__animated animate__shakeX text-red-600' : ' text-gray-400',
    props.greyedOut ? '' : 'bg-white',
  );

  switch (fieldType) {
    case 'textArea': {
      return (
        <div className="relative col-span-2 ">
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <textarea
            // name="name"
            // id="name"
            disabled={props.disabled}
            value={value}
            onChange={(e) => {
              if (props.fieldInfo?.title?.toLowerCase().includes('zip')) {
                if (/[a-zA-Z]/.test(e.target.value)) {
                  return;
                }
              }

              setValue(e.target.value);

              props.handleOnChange({
                value: e.target.value,
                field: props.fieldInfo,
              });
            }}
            onBlur={(e) => {
              if (props.fieldInfo.format) {
                const regex = new RegExp(props.fieldInfo?.format);
                const isValid = regex.test(e.target.value);
                setError(!isValid);
                if (isValid) {
                  props.handleOnBlur({
                    value: e.target.value,
                    field: props.fieldInfo,
                  });
                }
              } else {
                setError(false);
                props.handleOnBlur({
                  value: e.target.value,
                  field: props.fieldInfo,
                });
              }
            }}
            className={classNames(
              ' form-textarea block w-full rounded-md border-0 py-2 text-gray-900  placeholder:text-gray-400 sm:leading-6 h-36',
              props.disabled
                ? ''
                : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue shadow-sm',
              error ? 'ring-1 ring-inset ring-red-600' : '',
            )}
          />
        </div>
      );
    }
    case 'currency': {
      return (
        <div className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <CurrencyFormat
            disabled={props.disabled}
            value={value}
            field={{
              value,
              onChange: (e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                setValue(target.value);
                props.handleOnChange({
                  value: target.value,
                  field: props.fieldInfo,
                });
              },
              onBlur: (e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                const removeCommas = target.value.replace(/,/g, '');
                const amount = parseFloat(removeCommas);
                props.handleOnBlur({
                  value: amount,
                  field: props.fieldInfo,
                });
              },
            }}
            {...props}
            className={classNames(
              'form-input block w-full rounded-md pl-7 border-0 py-2 text-gray-900  placeholder:text-gray-400 sm:leading-6',
              props.disabled
                ? ''
                : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue shadow-sm',
              error ? 'ring-1 ring-inset ring-red-600' : '',
            )}
          />
        </div>
      );
    }
    case 'phoneNumber': {
      return (
        <div className="relative">
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <PatternFormat
            disabled={props.disabled}
            className={fieldClassName}
            format="+1 (###) ### ####"
            allowEmptyFormatting
            mask="_"
            value={value}
            onValueChange={(values, sourceInfo) => {
              setValue(values.formattedValue);

              props.handleOnChange({
                value: values.value,
                field: props.fieldInfo,
              });
            }}
            onBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              const rawVal = `+${removeNonNumericCharacters(target.value)}`;

              props.handleOnBlur({
                value: rawVal,
                field: props.fieldInfo,
              });
            }}
          />
        </div>
      );
    }
    case 'zipCode': {
      return (
        <div className="relative">
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <ZipCodeFormat
            value={value}
            field={{
              value,
              onChange: (e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                setValue(target.value);
                props.handleOnChange({
                  value: target.value,
                  field: props.fieldInfo,
                });
              },
              onBlur: (e: any) => {
                props.handleOnBlur({
                  value: e.target.value,
                  field: props.fieldInfo,
                });
              },
            }}
            {...props}
            className={fieldClassName}
          />
        </div>
      );
    }
    case 'socialSecurityNumber': {
      return (
        <div className="relative">
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <SSNFormat
            value={value}
            type={showSSN ? 'text' : 'password'}
            disabled={props.disabled}
            onFocus={() => setShowSSN(true)}
            field={{
              value,
              onChange: (e: any) => {
                setValue(e.target.value);
                props.handleOnChange({
                  value: e.target.value,
                  field: props.fieldInfo,
                });
              },
              onBlur: (e: any) => {
                setShowSSN(false);

                props.handleOnBlur({
                  value: e.target.value,
                  field: props.fieldInfo,
                });
              },
            }}
            {...props}
            className={fieldClassName}
          />
        </div>
      );
    }
    default: {
      return (
        <div className="relative">
          <label
            // htmlFor="name"
            className={labelClassName}
          >
            {props.fieldInfo.title}
            {props.fieldInfo.tooltip && <Tooltip message={props.fieldInfo.tooltip.description} />}
          </label>
          <input
            type={getFieldType()}
            disabled={props.disabled}
            value={value}
            onChange={(e) => {
              if (props.fieldInfo?.title?.toLowerCase().includes('zip')) {
                if (/[a-zA-Z]/.test(e.target.value)) {
                  return;
                }
              }

              if (fieldType === 'numberInput' && e.target.value.length > 0) {
                if (!/^([1-9]\d*)$/.test(e.target.value)) {
                  return;
                }
              }

              setValue(e.target.value);

              props.handleOnChange({
                value: e.target.value,
                field: props.fieldInfo,
              });
            }}
            onBlur={(e) => {
              if (props.fieldInfo.format) {
                const regex = new RegExp(props.fieldInfo?.format);
                const isValid = regex.test(e.target.value);
                setError(!isValid);
                if (isValid) {
                  props.handleOnBlur({
                    value: e.target.value,
                    field: props.fieldInfo,
                  });
                }
              } else {
                setError(false);
                props.handleOnBlur({
                  value: e.target.value,
                  field: props.fieldInfo,
                });
              }
            }}
            className={fieldClassName}
          />
        </div>
      );
    }
  }
}

export default LabyrinthTextInput;
