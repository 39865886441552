/* eslint-disable-next-line */
export interface IconLogoProps {
  styles?: string;
}

export function IconLogo(props: IconLogoProps) {
  return (
    <svg
      className={props?.styles ? props.styles : ''}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55.69 62.86"
      role="img"
    >
      <path d="M30.66.32.32,30.66a1.08,1.08,0,0,0,0,1.54L30.66,62.54a1.09,1.09,0,0,0,1.86-.76V1.09A1.09,1.09,0,0,0,30.66.32Z" />
      <path d="M36.68,49.73V32.19a1.29,1.29,0,0,1,1.25-1.32H54.44a1.34,1.34,0,0,1,.89,2.26L38.82,50.67A1.25,1.25,0,0,1,36.68,49.73Z" />
    </svg>
  );
}

export default IconLogo;
