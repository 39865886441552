import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  applyLitigationPlanToCaseV3,
  createCaseLitigationActionNotificationV3,
  createCaseLitigationActionV3,
  createDiscoveryV3,
  createLitigationPlanActionNotificationV3,
  createLitigationPlanActionV3,
  createLitigationPlanV3,
  deleteCaseLitigationActionNotificationV3,
  deleteCaseLitigationActionV3,
  deleteDiscoveryV3,
  deleteLitigationPlanActionNotificationV3,
  deleteLitigationPlanActionV3,
  deleteLitigationPlanV3,
  getCaseLitigationActionsV3,
  getDiscoveriesForCaseV3,
  listCaseLitigationActionNotificationsV3,
  listLitigationPlanActionNotificationsV3,
  listLitigationPlansV3,
  updateCaseLitigationActionNotificationV3,
  updateCaseLitigationActionV3,
  updateDiscoveryV3,
  updateLitigationPlanActionNotificationV3,
  updateLitigationPlanActionV3,
  updateLitigationPlanV3,
} from '../requests/testudo';

// #region ---------- LITIGATION PLANS -------------------------
export const useGetLitigationPlans = () => {
  return useQuery({
    queryKey: [queryKeys.litigationPlans],
    queryFn: async () => listLitigationPlansV3(),
  });
};
export const useCreateLitigationPlan = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createLitigationPlanV3>[0]) =>
      createLitigationPlanV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};
export const useDeleteLitigationPlan = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteLitigationPlanV3>[0]) =>
      deleteLitigationPlanV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};

export const useUpdateLitigationPlan = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateLitigationPlanV3>[0]) =>
      updateLitigationPlanV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};

export const useApplyLitigationPlanToCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof applyLitigationPlanToCaseV3>[0]) =>
      applyLitigationPlanToCaseV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationActions],
      });
    },
  });
};

// #endregion

// #region ---------- LITIGATION PLAN ACTION NOTIFICATIONS -----
export const useGetLitigationPlanActionNotifications = (
  caseLitigationActionId: Parameters<typeof listLitigationPlanActionNotificationsV3>[0],
) => {
  return useQuery({
    queryKey: [queryKeys.litigationPlanActionNotifications, caseLitigationActionId],
    queryFn: async () => listLitigationPlanActionNotificationsV3(caseLitigationActionId),
    enabled: !!caseLitigationActionId,
  });
};
export const useCreateLitigationPlanActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createLitigationPlanActionNotificationV3>[0]) =>
      createLitigationPlanActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlanActionNotifications],
      });
    },
  });
};

export const useUpdateLitigationPlanActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateLitigationPlanActionNotificationV3>[0]) =>
      updateLitigationPlanActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlanActionNotifications],
      });
    },
  });
};

export const useDeleteLitigationPlanActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteLitigationPlanActionNotificationV3>[0]) =>
      deleteLitigationPlanActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlanActionNotifications],
      });
    },
  });
};
// #endregion

// #region ---------- LITIGATION PLAN ACTIONS ------------------
export const useCreateLitigationPlanAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createLitigationPlanActionV3>[0]) =>
      createLitigationPlanActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};

export const useUpdateLitigationPlanAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateLitigationPlanActionV3>[0]) =>
      updateLitigationPlanActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};

export const useDeleteLitigationPlanAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteLitigationPlanActionV3>[0]) =>
      deleteLitigationPlanActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.litigationPlans],
      });
    },
  });
};
// #endregion

// #region ---------- CASE LITIGATION ACTION NOTIFICATIONS -----
export const useGetCaseLitigationPlanActionNotifications = (
  caseLitigationActionId?: Parameters<typeof listCaseLitigationActionNotificationsV3>[0],
) => {
  return useQuery({
    queryKey: [queryKeys.caseLitigationPlanActionNotifications, caseLitigationActionId],
    queryFn: async () => listCaseLitigationActionNotificationsV3(caseLitigationActionId),
    enabled: !!caseLitigationActionId,
  });
};

export const useCreateCaseLitigationActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseLitigationActionNotificationV3>[0]) =>
      createCaseLitigationActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationPlanActionNotifications],
      });
    },
  });
};

export const useUpdateCaseLitigationActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseLitigationActionNotificationV3>[0]) =>
      updateCaseLitigationActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationPlanActionNotifications],
      });
    },
  });
};

export const useDeleteCaseLitigationActionNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteCaseLitigationActionNotificationV3>[0]) =>
      deleteCaseLitigationActionNotificationV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationPlanActionNotifications],
      });
    },
  });
};
// #endregion

// #region ---------- CASE LITIGATION ACTIONS ------------------
export const useGetCaseLitigationActions = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseLitigationActions, caseId],
    queryFn: async () => getCaseLitigationActionsV3(caseId),
    enabled: !!caseId,
  });
};
export const useCreateCaseLitigationAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseLitigationActionV3>[0]) =>
      createCaseLitigationActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationActions],
      });
    },
  });
};
export const useDeleteCaseLitigationAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteCaseLitigationActionV3>[0]) =>
      deleteCaseLitigationActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationActions],
      });
    },
  });
};

export const useUpdateCaseLitigationAction = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseLitigationActionV3>[0]) =>
      updateCaseLitigationActionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseLitigationActions],
      });
    },
  });
};
// #endregion

// #region ---------- DISCOVERY ---------------------------------
export const useGetDiscoveriesForCase = (caseId: string) =>
  useQuery({
    queryKey: [queryKeys.discovery, caseId],
    queryFn: async () => getDiscoveriesForCaseV3(caseId),
    enabled: !!caseId,
  });

export const useCreateDiscovery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createDiscoveryV3>[0]) => createDiscoveryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.discovery],
      });
    },
  });
};

export const useUpdateDiscovery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateDiscoveryV3>[0]) => updateDiscoveryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.discovery],
      });
    },
  });
};

export const useDeleteDiscovery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteDiscoveryV3>[0]) => deleteDiscoveryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.discovery],
      });
    },
  });
};

// #endregion
