import styles from './TypingDots.module.css';

/* eslint-disable-next-line */
export interface TypingDotsProps {}

export function TypingDots(props: TypingDotsProps) {
  return (
    <div className={styles['container']}>
      <span className={styles['dot']}></span>
      <span className={styles['dot']}></span>
      <span className={styles['dot']}></span>
    </div>
  );
}

export default TypingDots;
