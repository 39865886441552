import { useGetMedicalTreatmentTypes } from '@colosseum/data';
import { PriorMedicalTreatmentType, caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';
import CaseContactConnectionLinker from '../CaseContactConnectionLinker/CaseContactConnectionLinker';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface PriorTreatmentFormProps {
  updateHandler: any;
  treatment: PriorMedicalTreatmentType;
  caseId: string;
  typing: boolean;
  setTyping: Dispatch<SetStateAction<boolean>>;
}

const formSchema = z.object({
  description: z.string().optional(),
  medicalTreatmentTypeId: z.string().optional(),
  startingDateOfService: z.string().optional(),
  endingDateOfService: z.string().optional(),
});

//TODO this component is a mess and needs major rework to be more readable and maintainable
export function PriorTreatmentForm(props: PriorTreatmentFormProps) {
  const { caseId, treatment, updateHandler, setTyping } = props;
  const { data: medicalTreatmentTypeData } = useGetMedicalTreatmentTypes();
  const medicalTreatmentTypeOptions = medicalTreatmentTypeData?.data
    .filter((treatmentType) => treatmentType.title)
    .reduce((acc, treatmentType) => {
      return {
        ...acc,
        [treatmentType.title ?? '']: treatmentType.medicalTreatmentTypeId,
      };
    }, {});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...treatment,
    },
  });

  async function handleUpdate(value: string | number, key: string) {
    await updateHandler(value, key, treatment.priorMedicalTreatmentId);
    setTyping(false);
  }

  function handleUpdateNumber(value: any, key: string) {
    // strip value of currency formatting
    value = value.replace(/[^0-9.-]+/g, '');

    const temp = parseFloat(value);
    updateHandler(temp, key, treatment.priorMedicalTreatmentId);
  }

  function handleUpdateBool(value: '1' | '0', key: string) {
    updateHandler(value, key, treatment.priorMedicalTreatmentId);
  }

  const renderNumberInput = ({
    key,
    label,
    decimals,
    prefix,
  }: {
    key: string;
    label: string;
    decimals?: number;
    prefix?: string;
  }) => {
    const decimalsToShow = decimals ?? 0;
    return (
      <div className="relative w-full p-3 border border-gray-300 rounded-md">
        <div className="flex items-center">
          {prefix && <span className="mr-2 text-sm text-gray-500">{prefix}</span>}
          <NumericFormat
            className="w-full bg-white focus:outline-none"
            value={treatment?.[key as keyof PriorMedicalTreatmentType]?.toLocaleString('en-US', {
              minimumFractionDigits: decimalsToShow,
            })}
            onBlur={(e) => {
              setTyping(false);
              handleUpdateNumber(e.target.value, key);
            }}
            onChange={(e) => {
              setTyping(true);
            }}
            decimalScale={decimalsToShow}
            thousandSeparator=","
            allowNegative={false}
          />
          <div className="absolute px-1 text-xs text-gray-400 bg-white rounded-md -top-2 left-2">
            {label}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Form {...form}>
        <form>
          <div className="grid grid-cols-2 pt-6 gap-y-5 gap-x-3">
            <CalendarFormInput
              name="startingDateOfService"
              title="Starting Date of Service"
              handleOnChange={(e) => {
                const target = e.target as HTMLInputElement;
                setTyping(true);
                handleUpdate(target.value, 'startingDateOfService');
              }}
              resourceTypeObj={{
                type: 'medical-treatment-history',
                id: treatment.priorMedicalTreatmentId,
              }}
            />
            <CalendarFormInput
              name="endingDateOfService"
              title="Ending Date of Service"
              handleOnChange={(e) => {
                const target = e.target as HTMLInputElement;
                setTyping(true);

                handleUpdate(target.value, 'endingDateOfService');
              }}
              resourceTypeObj={{
                type: 'medical-treatment-bill',
                id: treatment.priorMedicalTreatmentId,
              }}
            />
            <div className="min-h-[50px]">
              <SelectFormInput
                {...form.register('medicalTreatmentTypeId')}
                title="Type of Treatment"
                listItems={medicalTreatmentTypeOptions ?? {}}
                listItemsIsObject
                defaultValue={treatment?.medicalTreatmentTypeId}
                triggerClassName="h-[50px]"
                placeholderText="Select a type"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate(target.value, target.name);
                }}
              />
            </div>

            {renderNumberInput({
              key: 'numberOfVisits',
              label: 'Number of Visits',
            })}
            <CaseContactConnectionLinker
              caseId={caseId}
              title="Medical Provider"
              roleOnCase={caseContactConnectionOptions.medicalProvider}
              itemType="priorMedicalTreatment"
              itemId={treatment.priorMedicalTreatmentId}
            />
            <div className="col-span-2">
              <TextFormInput
                {...form.register('description')}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate(target.value, 'description');
                }}
                handleOnChange={() => {
                  setTyping(true);
                }}
              />
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}

export default PriorTreatmentForm;
