import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createLinkedCaseV3,
  deleteLinkedCaseV3,
  getLinkedCasesV3,
} from '../requests/appeals-to-caesar';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import { AxiosError } from 'axios';
import { DefaultV3Error } from '@gladiate/types';

export const useGetLinkedTogetherCases = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.linkedCases, caseId],
    queryFn: async () => getLinkedCasesV3(caseId),
    enabled: !!caseId,
  });
};

export const useCreateLinkedTogetherCase = (caseId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { caseId: string; linkedCaseId: string }) => createLinkedCaseV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.linkedCases, caseId],
      });
    },
    onSuccess: () => {
      enqueueAPISnackbar({
        message: 'Linked case successfully created',
        key: 'create-linked-together-case-success',
        variant: 'success',
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'create-linked-together-case-error',
        variant: 'error',
      });
    },
  });
};

export const useDeleteLinkedTogetherCase = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (linkedCaseId: string) => deleteLinkedCaseV3(linkedCaseId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.linkedCases],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'delete-linked-together-case-error',
        variant: 'error',
      });
    },
  });
};
