import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';

/* eslint-disable-next-line */
export interface EditButtonProps {
  route: string;
}

export function EditButton(props: EditButtonProps) {
  const navigate = useNavigate();
  const { route } = props;
  return (
    <button
      className="text-gray-400 hover:text-light-blue"
      onClick={() => {
        navigate(route);
      }}
    >
      <PencilSquareIcon width={20} height={20} />
    </button>
  );
}

export default EditButton;
