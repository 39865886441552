import { getFirmId, getFullFileId, useCreateResourceAttachment } from '@colosseum/data';
import { FileResourceType, ResourceAttachmentType } from '@gladiate/types';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../../lib/shadcn/Popover/Popover';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import FileAttachmentModal from '../FileAttachmentModal/FileAttachmentModal';
import { DocumentGenerationSlideover } from '../FileExplorer/DocGenSlideover';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface ResourceFileAttacherProps {
  caseId: string;
  type: ResourceAttachmentType;
  resourceId: string;
  designation?: string;
  onLoadingStateChange?: (loading: boolean) => void;
  unavailableResources?: {
    resourceId: string;
    reason?: string;
  }[];
  large?: boolean;
}

export function ResourceFileAttacher(props: ResourceFileAttacherProps) {
  const { caseId, type, resourceId, onLoadingStateChange, designation, unavailableResources } =
    props;

  const firmId = getFirmId();

  const [showFileModal, setShowFileModal] = useState(false);
  const [filesToAttach, setFilesToAttach] = useState<FileResourceType[]>([]);

  const [showDocGenModal, setShowDocGenModal] = useState(false);

  const createResourceAttachment = useCreateResourceAttachment(resourceId);

  useEffect(() => {
    if (onLoadingStateChange) {
      onLoadingStateChange(createResourceAttachment.isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResourceAttachment.isLoading]);

  return (
    <>
      <FileAttachmentModal
        unavailableResources={unavailableResources}
        caseId={caseId}
        showFileExplorer={showFileModal}
        setShowFileExplorer={setShowFileModal}
        onAttachFiles={() => {
          filesToAttach.forEach((fileToAttach) => {
            createResourceAttachment.mutateAsync({
              resourceId: getFullFileId(fileToAttach), // this is somewhat confusing, but the fileId is the resourceId because of the backend implementation
              type,
              designation,
            });
          });
        }}
        onSelectedFilesChange={(files) => {
          setFilesToAttach(files);
        }}
      />

      <DocumentGenerationSlideover
        caseId={caseId}
        isOpen={showDocGenModal}
        currentDirectoryId={`${firmId}-${caseId}`}
        onClose={() => setShowDocGenModal(false)}
        onDocumentGenerated={() => setShowDocGenModal(false)}
      />

      <Popover>
        <PopoverTrigger asChild>
          {props.large ? (
            <Button variant="outline" className="mr-2">
              <PaperClipIcon className="w-4 h-4 mr-2" /> Add Content
            </Button>
          ) : (
            <Button variant="outline" size={'xs'} className="p-1 h-[26px] w-[26px]">
              <PaperClipIcon />
            </Button>
          )}
        </PopoverTrigger>

        <PopoverContent className="p-2 gap-1 flex flex-col max-w-xs text-left w-[200px] text-sm animate-in fade-in">
          <button
            onClick={() => setShowFileModal(true)}
            className="flex items-center gap-2 p-1 pl-2 transition-all rounded hover:bg-gray-100"
          >
            Add Attachment
          </button>

          <TooltipWrapper message="After generating a document, you can attach it to this case.">
            <button
              onClick={() => setShowDocGenModal(true)}
              className="flex items-center gap-2 p-1 pl-2 transition-all rounded hover:bg-gray-100"
            >
              Generate Document
            </button>
          </TooltipWrapper>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default ResourceFileAttacher;
