import { format } from 'date-fns';
import { useNavigate } from 'react-router';

import { CaseCompleteType, daysBetween, getMedicalCoverageForCaseList } from '@colosseum/data';
import Typography from '../Typography/Typography';

/* eslint-disable-next-line */
export interface CardViewProps {
  cases: CaseCompleteType[];
}

export function CardView(props: CardViewProps) {
  const navigate = useNavigate();

  if (props.cases?.length === 0)
    return (
      <div className="p-5 ">
        <div className="border shadow-lg rounded-2xl">
          <Typography variant="subtext" className="px-2 py-10 text-center">
            No results.
          </Typography>
        </div>
      </div>
    );

  return (
    <div className="p-5 ">
      <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3">
        {props.cases?.map((c) => {
          const mostRecentCaseStatusUpdate = c?.caseStatusUpdates?.sort((a, b) => {
            return new Date(b?.dateStarted).getTime() - new Date(a?.dateEnded ?? '').getTime();
          })[0];

          //days between most recent workflow start and now
          const daysInStatus = !isNaN(
            daysBetween(
              `${mostRecentCaseStatusUpdate?.dateStarted}Z` || '',
              new Date().toISOString(),
            ),
          )
            ? daysBetween(
                `${mostRecentCaseStatusUpdate?.dateStarted}Z` || '',
                new Date().toISOString(),
              )
            : 0;

          return (
            <div
              onClick={() => {
                const { caseId } = c;

                if (!caseId) {
                  console.error('Case Not Found');
                }

                navigate(`/cases/${caseId}`);
              }}
              key={c.caseId}
              className="px-3 py-5 text-lg font-semibold border border-gray-300 rounded-md shadow-md cursor-pointer hover:bg-gray-100 "
            >
              <div>
                <div>{c?.caseTitle ? c.caseTitle : '-'}</div>
                <div className="flex items-center mt-2 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Case Type</div>
                  {c?.caseType?.title ?? '-'}
                </div>
                <div className="flex items-center mt-2 text-sm text-atlantic-blue">
                  <div className="px-1 py-0.5 rounded-md bg-light-blue  mr-5">
                    {c?.caseStatus?.title ?? 'No Status'}
                  </div>
                  {/* amount of days it is in this status */}
                  {daysInStatus} days
                </div>
              </div>
              <div className="mt-10 border-t border-t-gray-400">
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Third Party Coverage</div>
                  {getMedicalCoverageForCaseList({
                    caseData: c,
                    type: 'thirdPartyCoverage',
                  })}
                </div>
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Um/Uim Coverage</div>
                  {getMedicalCoverageForCaseList({
                    caseData: c,
                    type: 'umUimCoverage',
                  })}
                </div>
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Medicals</div>
                  {c?.medicalTreatmentAmountBilled?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }) ?? '-'}
                </div>
              </div>
              <div className="mt-10 border-t border-t-gray-400">
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Incident Date</div>
                  {c?.dateOfIncident
                    ? format(new Date(c?.dateOfIncident), 'LLLL dd, yyyy') ?? '-'
                    : '-'}
                </div>
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Open Date</div>
                  <div className="">
                    {c?.caseOpenDate
                      ? format(new Date(c?.caseOpenDate), 'LLLL dd, yyyy') ?? '-'
                      : '-'}
                  </div>
                </div>
                <div className="flex items-center mt-5 text-sm">
                  <div className="mr-5 text-gray-400 uppercase">Statute Date</div>
                  {c?.statuteOfLimitations
                    ? format(new Date(c?.statuteOfLimitations), 'LLLL dd, yyyy') ?? '-'
                    : '-'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CardView;
