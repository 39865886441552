import { HandWryttenFont, HandwryttenCard, HandwryttenCardWithCategories } from '@gladiate/types';
import { axiosInstance } from '../https';

const baseHandwryttenRoute = 'https://api.handwrytten.com/v2';

export interface HandwryttenV2Response<T> {
  httpCode: number;
  status: string;
  total_pages?: number;
  cards?: T;
  fonts?: T;
}

export const getHandwryttenCardsWithCategories = () =>
  axiosInstance
    .get<HandwryttenV2Response<HandwryttenCardWithCategories[]>>(
      `${baseHandwryttenRoute}/cards/list/sections`,
    )
    .then((res) => res.data);

export const getHandwryttenCards = () =>
  axiosInstance
    .get<HandwryttenV2Response<HandwryttenCard[]>>(`${baseHandwryttenRoute}/cards/list?lowres=true`)
    .then((res) => res.data);

export const getHandwryttenFonts = () =>
  axiosInstance
    .get<HandwryttenV2Response<HandWryttenFont[]>>(`${baseHandwryttenRoute}/fonts/list`)
    .then((res) => res.data);
