/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  useCreatePaymentMethod,
  VITE_JUSTIFI_CLIENT,
  VITE_JUSTIFI_ACCOUNT,
  useUpdatePaymentMethod,
} from '@colosseum/data';
import { JustifiCardDetails } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import CreateButton from '../CreateButton/CreateButton';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
import './CardForm.module.css';

export interface CardFormProps {
  setSlideoverOpen: (value: boolean) => void;
}

const CLIENT_ID = VITE_JUSTIFI_CLIENT;
const ACCOUNT_ID = VITE_JUSTIFI_ACCOUNT;

// Previous PostResponse type
type CreatePaymentMethodResponse = {
  signature: string;
  customer_id: null | string;
  account_id: string;
  id: string;
  card: JustifiCardDetails;
};

const formSchema = z.object({
  name: z.string().nonempty(),
  email: z.string().email(),
  address_postal_code: z.string().nonempty().min(5),
});

export function CardForm(props: CardFormProps) {
  const { setSlideoverOpen } = props;
  const cardFormRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const createPaymentMethod = useCreatePaymentMethod();
  const updatePrimaryPayment = useUpdatePaymentMethod();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      address_postal_code: '',
    },
    mode: 'onBlur', // Validation will trigger on each field's blur event
  });

  useEffect(() => {
    const cardForm = cardFormRef.current;
    if (!cardForm) return;
    const handleCardFormReady = () => {
      // card is ready to be used
    };
    cardForm.addEventListener('cardFormReady', handleCardFormReady);
    return () => {
      cardForm.removeEventListener('cardFormReady', handleCardFormReady);
    };
  }, []);

  const createPaymentHandler = (data: z.infer<typeof formSchema>) => {
    const cardForm = cardFormRef.current;
    if (!cardForm) return;

    setLoading(true);

    cardForm
      .validate()
      .then((validationData: { isValid: boolean }) => {
        if (!validationData.isValid) {
          enqueueSnackbar('Invalid card details.', {
            variant: 'error',
          });
          setLoading(false);

          return;
        }

        const paymentMethodData = {
          name: data.name,
          address_postal_code: data.address_postal_code,
        };

        cardForm
          .tokenize(CLIENT_ID, paymentMethodData, ACCOUNT_ID)
          .then((tokenizeData: CreatePaymentMethodResponse) => {
            createPaymentMethod
              .mutateAsync({
                paymentMethodId: tokenizeData.id,
                billingEmailAddress: data.email,
                isPrimary: '1',
              })
              .then(() => {
                setLoading(false);
                setSlideoverOpen(false);
              })
              .catch(() => {
                setLoading(false);
              });
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const { isValid } = form.formState;

  return (
    <div className="flex flex-col justify-between h-full">
      <Form {...form}>
        <form className="p-5 ">
          <div className="grid grid-cols-2 pt-2 pb-2 mt-3 gap-y-5 gap-x-3">
            <TextFormInput name="name" title="Name" />
            <TextFormInput name="address_postal_code" title="Postal Code" />
            <TextFormInput name="email" title="Email" />
          </div>

          <div className="p-2 mt-2 border rounded-lg shadow-lg bg-gray-50">
            {/* @ts-ignore */}
            <justifi-card-form ref={cardFormRef} />
          </div>
        </form>
      </Form>
      <div className="flex justify-end pt-3 mt-auto border-t">
        <div className="pr-4 ">
          <CreateButton
            title={'Add Card'}
            disabled={!isValid || createPaymentMethod.isLoading || loading} // Disable button based on isValid flag
            loading={createPaymentMethod.isLoading || loading}
            onClick={form.handleSubmit(createPaymentHandler)}
          />
        </div>
      </div>
    </div>
  );
}

export default CardForm;
