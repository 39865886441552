import {
  groupCaseStatusesByCategory,
  useCreateTaskForTaskPlan,
  useGetCaseStatuses,
  useGetCaseTypes,
  useGetTasksForTaskPlan,
  useUpdateTaskPlan,
} from '@colosseum/data';
import { CaseStatusType, TaskPlanType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import GladiateLoader from '../../GladiateLoader/GladiateLoader';
import TaskPlanTasksCard from '../../TaskPlanTasksCard/TaskPlanTasksCard';
import { Form } from '../../shadcn/Form/Form';
import { MultiSelectFormInput } from '../MultiSelectFormInput/MultiSelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';
import Typography from '../../Typography/Typography';

/* eslint-disable-next-line */
export interface TaskPlanFormProps {
  plan: TaskPlanType | null;
}

const planFormSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
});

export function TaskPlanForm(props: TaskPlanFormProps) {
  const { plan } = props;

  const [loadingIndex, setLoadingIndex] = useState(-1);
  const updatePlan = useUpdateTaskPlan();
  const createTaskForPlan = useCreateTaskForTaskPlan();

  const { data: caseStatusesData } = useGetCaseStatuses();
  const caseStatuses = caseStatusesData ? caseStatusesData.data : [];

  const { data: caseTypesData } = useGetCaseTypes();
  const caseTypes = caseTypesData ? caseTypesData.data : [];

  const { data: tasksForPlanData, isLoading: tasksLoading } = useGetTasksForTaskPlan(
    plan?.planId ?? '',
  );
  const tasksForPlan = tasksForPlanData && tasksForPlanData.data;

  const planForm = useForm<z.infer<typeof planFormSchema>>({
    resolver: zodResolver(planFormSchema),
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (plan) {
        return plan;
      }
      return {};
    }, [plan]),
  });

  const handleOnBlur = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    updatePlan.mutate({
      planId: plan?.planId ?? '',
      data: {
        [target.name]: target.value ?? '',
      },
    });
  };

  const categorySortedCaseStatuses = groupCaseStatusesByCategory(caseStatuses || [], false);

  const categoryHeadings = Object.keys(categorySortedCaseStatuses) as Array<
    CaseStatusType['category']
  >;

  return (
    <div>
      <div className="mb-4 text-lg font-semibold">Task Plan Description</div>

      <Form {...planForm}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
            <TextFormInput
              name="title"
              title="Title"
              handleOnBlur={handleOnBlur}
              dataCy="text-input-title-slideover"
            />
            <MultiSelectFormInput
              title={'Case Types'}
              options={caseTypes.map((caseType) => {
                return {
                  title: caseType.title ?? '',
                  value: caseType.caseTypeId,
                };
              })}
              optionsIsObject
              selectedOptions={plan?.caseType ?? []}
              onChange={(option) => {
                updatePlan.mutate({
                  planId: plan?.planId ?? '',
                  data: {
                    caseType: plan?.caseType?.includes(option)
                      ? plan?.caseType?.filter((item) => item !== option)
                      : [...(plan?.caseType ?? []), option],
                  },
                });
              }}
            />
            <div className="col-span-2">
              <TextFormInput
                name="description"
                title="Description"
                handleOnBlur={handleOnBlur}
                type="textarea"
                dataCy="text-input-description-slideover"
              />
            </div>
          </div>
        </form>
      </Form>

      <div className="my-4 text-lg font-semibold">Tasks</div>
      {tasksLoading && (
        <div>
          <GladiateLoader height={52} />
        </div>
      )}
      {categoryHeadings &&
        !tasksLoading &&
        categoryHeadings.map((category) => {
          return (
            <>
              <Typography
                className="capitalize pt-4 italic"
                key={category}
                color={'gray'}
                variant={'semiBold'}
              >
                {category}
              </Typography>
              <>
                {category &&
                  !tasksLoading &&
                  categorySortedCaseStatuses[category] &&
                  categorySortedCaseStatuses[category]?.map((caseStatus, index) => {
                    return (
                      <div className="py-2 rounded-lg" key={caseStatus.caseStatusId}>
                        {tasksForPlan && (
                          <TaskPlanTasksCard
                            tasks={
                              tasksForPlan.sort((a, b) => {
                                return dayjs(a.dateCreated).unix() - dayjs(b.dateCreated).unix();
                              }) ?? []
                            }
                            caseStatusId={caseStatus.caseStatusId}
                            title={caseStatus.title}
                            isLoading={createTaskForPlan.isLoading && loadingIndex === index}
                            handleCreateTaskForTaskPlan={() =>
                              createTaskForPlan.mutateAsync({
                                planId: plan?.planId ?? '',
                                data: {
                                  title: 'New Task',
                                  description: 'New Task Description',
                                  caseStatus: caseStatus.caseStatusId,
                                },
                              })
                            }
                          />
                        )}
                      </div>
                    );
                  })}
              </>
            </>
          );
        })}
    </div>
  );
}

export default TaskPlanForm;
