import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createAdobeSignUserAccountV3,
  getAdobeUserV3,
  getAdobeAuthUrlV3,
  sendAdobeUserAuthCodeV3,
  getAdobeUserListV3,
  getAdobeAccountListV3,
  createAdobeAccountV3,
  updateAdobeAdminV3,
  updateAdobeUserStatusV3,
  updateAdobeSignerPrivelageV3,
  deleteAdobeUserV3,
  getAdobeSignerListV3,
  getAgreementsForCaseV3,
} from '../requests/sirens-sign';
import { AxiosError } from 'axios';
import { enqueueAPISnackbar } from '../../utils/snackbars';

export interface AdobeAccountRes {
  account: {
    firmId: string;
    oauthStatus: string;
    userIds: string[];
  };
  status: string;
}

export interface AdobeAccountAuthCodePayload {
  authCode: string;
  successCb?: (result: any) => void;
  errorCb?: (error: any) => void;
}

export const useGetAdobeUserV3 = (username: Parameters<typeof getAdobeUserV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.adobeUserAccount, username],
    queryFn: async () => getAdobeUserV3(username),
    onError: (error: AxiosError<DefaultV3Error>) => error,
  });
};

export const useGetAdobeAuthUrlV3 = () => {
  return useQuery({
    queryKey: [queryKeys.adobeAuthUrl],
    queryFn: async () => getAdobeAuthUrlV3(),
  });
};

export const useGetAdobeAccountListV3 = () => {
  return useQuery({
    queryKey: [queryKeys.adobeFirmAccount],
    queryFn: async () => getAdobeAccountListV3(),
    retry: false,
    onError: (error: AxiosError<DefaultV3Error>) => error,
  });
};

export const useGetAdobeUserListV3 = () => {
  return useQuery({
    queryKey: [queryKeys.adobeFirmUsers],
    queryFn: async () => getAdobeUserListV3(),
  });
};

export const useUpdateAdobeUserStatusV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateAdobeUserStatusV3>[0]) =>
      updateAdobeUserStatusV3(data),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-account-update-error',
        variant: 'error',
      });
    },
  });
};

export const useDeleteAdobeAccountV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof deleteAdobeUserV3>[0]) => deleteAdobeUserV3(data),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-user-delete-error',
        variant: 'error',
      });
    },
  });
};

export const useUpdateAdobeAccountSignerStatusV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateAdobeSignerPrivelageV3>[0]) =>
      updateAdobeSignerPrivelageV3(data),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-signer-priv-update-error',
        variant: 'error',
      });
    },
  });
};

export const useUpdateAdobeAdminV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateAdobeAdminV3>[0]) => updateAdobeAdminV3(data),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-admin-update-error',
        variant: 'error',
      });
    },
  });
};

export const useSendAuthedAdobeSignUserCodeV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (authCode: Parameters<typeof sendAdobeUserAuthCodeV3>[0]) =>
      sendAdobeUserAuthCodeV3(authCode),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-send-auth-code-error',
        variant: 'error',
      });
    },
  });
};

export const useCreateAdobeSignUserAccountV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => createAdobeSignUserAccountV3(),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-signer-create-error',
        variant: 'error',
      });
    },
  });
};

export const useCreateAdobeAccountV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (companyName: Parameters<typeof createAdobeAccountV3>[0]) =>
      createAdobeAccountV3(companyName),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-account-create-error',
        variant: 'error',
      });
    },
  });
};

export const useGetAdobeSignerListV3 = () => {
  return useQuery({
    queryKey: [queryKeys.adobeSigners],
    queryFn: () => getAdobeSignerListV3(),
  });
};

export const useUpdateAdobeSignUserStatusV3 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateAdobeUserStatusV3>[0]) =>
      updateAdobeUserStatusV3(data),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeSigners]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.adobeFirmUsers]);
      queryClient.invalidateQueries([queryKeys.adobeSigners]);
      queryClient.invalidateQueries([queryKeys.adobeFirmAccount]);
      queryClient.invalidateQueries([queryKeys.adobeUserAccount]);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.firmUserDetails],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'adobe-signer-update-error',
        variant: 'error',
      });
    },
  });
};

export const useGetAgreementsForCaseV3 = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.adobeAgreements, caseId],
    queryFn: async () => getAgreementsForCaseV3(caseId),
  });
};
