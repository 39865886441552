import { TaskForTaskPlanType } from '@gladiate/types';
import { kebabCase } from 'lodash';
import { PlusCircleIcon } from 'lucide-react';
import { useState } from 'react';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import TaskPlanTaskSlideover from '../TaskPlanTaskSlideover/TaskPlanTaskSlideover';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import TaskPlanTasksCardInfo from './TaskPlanTasksCardInfo';

/* eslint-disable-next-line */
export interface TaskPlanTasksCardProps {
  tasks: TaskForTaskPlanType[];
  title?: string;
  isLoading: boolean;
  handleCreateTaskForTaskPlan: () => Promise<any>;
  caseStatusId?: string;
}

export function TaskPlanTasksCard(props: TaskPlanTasksCardProps) {
  const { tasks, title, handleCreateTaskForTaskPlan, isLoading, caseStatusId } = props;
  const [open, setOpen] = useState(false);
  const [selectedPlanTaskId, setSelectedPlanTaskId] = useState<string>();

  const selectedPlanTask = tasks?.find((task) => task.planTaskId === selectedPlanTaskId);
  return (
    <div className="p-2 border border-gray-300 rounded-lg">
      <TaskPlanTaskSlideover open={open} setOpen={setOpen} task={selectedPlanTask} />
      <div className="flex items-center justify-between">
        <Typography variant={'semiBold'} size={'sm'}>
          {title ?? 'Task Plan Tasks'}
        </Typography>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCreateTaskForTaskPlan();
          }}
          variant="ghost"
          data-cy={`create-task-${kebabCase(title)}`}
        >
          {isLoading ? <GladiateLoader height={20} /> : <PlusCircleIcon className="w-5 h-5" />}
        </Button>
      </div>

      <div className="rounded-lg">
        {tasks &&
          tasks.map((task, index) => {
            return (
              (caseStatusId ? task.caseStatus === caseStatusId : true) && (
                <div className="pt-2">
                  <TaskPlanTasksCardInfo
                    task={task}
                    key={task.planTaskId}
                    setOpen={setOpen}
                    setSelectedPlanTaskId={setSelectedPlanTaskId}
                  />
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}

export default TaskPlanTasksCard;
