import { CampaignDeliveryMechanism } from '@gladiate/types';

export function parseDeliveryMechanism(mechanism: CampaignDeliveryMechanism[]) {
  if (!mechanism) {
    return undefined;
  }
  if (mechanism.includes('sms')) {
    return 'sms';
  }
  if (mechanism.includes('handwrytten')) {
    return 'handwrytten';
  }
  return undefined;
}
