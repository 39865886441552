import {
  useCreateCaseLitigationActionNotification,
  useCreateLitigationPlanActionNotification,
  useDeleteCaseLitigationActionNotification,
  useDeleteLitigationPlanActionNotification,
  useGetCaseLitigationPlanActionNotifications,
  useGetLitigationPlanActionNotifications,
  useUpdateCaseLitigationAction,
  useUpdateCaseLitigationActionNotification,
  useUpdateLitigationPlanAction,
  useUpdateLitigationPlanActionNotification,
} from '@colosseum/data';
import { LitigationPlanAction } from '@gladiate/types';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from '../shadcn/Form/Form';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';

/* eslint-disable-next-line */
export interface LitigationActionFormProps {
  litigationPlanAction?: LitigationPlanAction;
  otherActions: LitigationPlanAction[];
  caseAction: boolean;
}

export function LitigationActionForm(props: LitigationActionFormProps) {
  const [deleteLoadingIndex, setDeleteLoadingIndex] = useState<number | null>(null);
  const createCaseLitigationActionNotification = useCreateCaseLitigationActionNotification();
  const createLitigationPlanActionNotification = useCreateLitigationPlanActionNotification();

  const updateCaseLitigationAction = useUpdateCaseLitigationAction();
  const updateLitigationPlanActionHook = useUpdateLitigationPlanAction();

  const caseLitigationPlanActionNotifications = useGetCaseLitigationPlanActionNotifications(
    props.litigationPlanAction?.caseLitigationActionId,
  );

  const litigationPlanActionNotifications = useGetLitigationPlanActionNotifications(
    props.litigationPlanAction?.litigationPlanActionId,
  );

  const updateCaseLitigationActionNotification = useUpdateCaseLitigationActionNotification();
  const updateLitigationPlanActionNotification = useUpdateLitigationPlanActionNotification();

  const deleteCaseLitigationActionNotification = useDeleteCaseLitigationActionNotification();
  const deleteLitigationPlanActionNotification = useDeleteLitigationPlanActionNotification();

  const notificationsQuery = props.caseAction
    ? caseLitigationPlanActionNotifications
    : litigationPlanActionNotifications;

  const notifications = notificationsQuery.data?.data;

  const createNotification = props.caseAction
    ? createCaseLitigationActionNotification
    : createLitigationPlanActionNotification;
  const updateLitigationPlanAction = props.caseAction
    ? updateCaseLitigationAction
    : updateLitigationPlanActionHook;
  const updateNotification = props.caseAction
    ? updateCaseLitigationActionNotification
    : updateLitigationPlanActionNotification;
  const deleteNotification = props.caseAction
    ? deleteCaseLitigationActionNotification
    : deleteLitigationPlanActionNotification;

  const updateKey = props.caseAction ? 'caseLitigationActionId' : 'litigationPlanActionId';

  const notificationUpdateKey = props.caseAction
    ? 'caseLitigationActionNotificationId'
    : 'litigationPlanActionNotificationId';

  const updateId = props.caseAction
    ? props.litigationPlanAction?.caseLitigationActionId
    : props.litigationPlanAction?.litigationPlanActionId;

  const form = useForm({
    mode: 'onBlur',
    defaultValues: props.litigationPlanAction,
  });

  const notificationsForm = useForm({
    mode: 'onBlur',
    values: { notifications: notifications },
  });

  const notificationFormArray = useFieldArray({
    control: notificationsForm.control,
    name: 'notifications',
  });

  function handleUpdateAction(value: string | number, valueVariable: string) {
    updateLitigationPlanAction.mutate({
      [updateKey]: updateId,
      [valueVariable]: value,
    });
  }

  const otherActionsTitlesStringArr = props.otherActions
    .filter(
      (action) =>
        action.litigationPlanActionId !== props.litigationPlanAction?.litigationPlanActionId &&
        action.title !== undefined,
    )
    .map((action) => {
      if (action.title === undefined) return '';

      return action.title;
    });

  const handleUpdateNotification = (value: string | number, valueVariable: string, id: string) => {
    updateNotification.mutate({
      [notificationUpdateKey]: id,
      [valueVariable]: value,
    });
  };

  return (
    <div className="w-full">
      <div className="text-lg font-semibold ">Action Details</div>
      <Form {...form}>
        <form className="grid grid-cols-1 pt-2 pb-2 mt-3 gap-y-5 gap-x-3">
          <TextFormInput
            title="Event Name"
            name="title"
            handleOnBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLTextAreaElement;
              handleUpdateAction(target.value, 'title');
            }}
          />
          <TextFormInput
            title="Days"
            name="days"
            type="number"
            handleOnBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLTextAreaElement;
              handleUpdateAction(parseInt(target.value), 'days');
            }}
          />
          <SelectFormInput
            title="All days / Court days"
            name="typeOfDays"
            listItems={['All Days', 'Court Days']}
            defaultValue={props.litigationPlanAction?.typeOfDays}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdateAction(target.value, 'typeOfDays');
            }}
          />
          <SelectFormInput
            title="Before / After"
            name="beforeAfter"
            listItems={['Before', 'After']}
            defaultValue={props.litigationPlanAction?.beforeAfter}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdateAction(target.value, 'beforeAfter');
            }}
          />
          <SelectFormInput
            title="Trigger Event"
            name="triggerAction"
            listItems={['Initial Filing Date', 'Trial Date', ...otherActionsTitlesStringArr]}
            defaultValue={props.litigationPlanAction?.triggerAction}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdateAction(target.value, 'triggerAction');
            }}
          />
          <SelectFormInput
            title="Trigger Action on Status"
            name="triggerActionOnStatus"
            listItems={['Completed', 'Deadline Set']}
            defaultValue={props.litigationPlanAction?.triggerActionOnStatus}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdateAction(target.value, 'triggerActionOnStatus');
            }}
          />
          {props.caseAction && (
            <>
              <CalendarFormInput
                title="Deadline Date"
                name="deadlineDate"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLSelectElement;
                  handleUpdateAction(target.value, 'deadlineDate');
                }}
                hideSyncButton
              />
              <CalendarFormInput
                title="Completion Date"
                name="completionDate"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLSelectElement;
                  handleUpdateAction(target.value, 'completionDate');
                }}
                hideSyncButton
              />
            </>
          )}
        </form>
      </Form>
      <Form {...notificationsForm}>
        <form className="grid grid-cols-1 pt-2 pb-2 mt-3 gap-y-5 gap-x-3">
          <div className="mt-5 text-lg font-semibold">Notifications</div>
          {notificationFormArray.fields.map((field, index: number) => (
            <div key={field.id} className="grid grid-cols-2 pt-2 pb-2 mt-3 gap-y-5 gap-x-3">
              {notificationsQuery.isLoading && (
                <>
                  <div className="w-full h-8 bg-gray-100 rounded-md animate-pulse"></div>
                  <div className="w-full h-8 bg-gray-100 rounded-md animate-pulse"></div>
                </>
              )}
              <TextFormInput
                title="Number"
                {...notificationsForm.register(`notifications.${index}.timeUnits`)}
                type="number"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  const notificationUpdateId = props.caseAction
                    ? notifications?.[index].caseLitigationActionNotificationId
                    : notifications?.[index].litigationPlanActionNotificationId;
                  if (notificationUpdateId) {
                    handleUpdateNotification(
                      parseInt(target.value),
                      'timeUnits',
                      notificationUpdateId,
                    );
                  }
                }}
              />
              <div className="flex items-center">
                <div className="w-full pr-2">
                  <SelectFormInput
                    title="Time Type"
                    {...notificationsForm.register(`notifications.${index}.timeType`)}
                    listItems={['minute(s)', 'hour(s)', 'day(s)', 'week(s)']}
                    defaultValue={notifications?.[index]?.timeType}
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      const notificationUpdateId = props.caseAction
                        ? notifications?.[index].caseLitigationActionNotificationId
                        : notifications?.[index].litigationPlanActionNotificationId;
                      if (notificationUpdateId) {
                        handleUpdateNotification(target.value, 'timeType', notificationUpdateId);
                      }
                    }}
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteLoadingIndex(index);
                    const notificationUpdateId = props.caseAction
                      ? notifications?.[index].caseLitigationActionNotificationId
                      : notifications?.[index].litigationPlanActionNotificationId;
                    deleteNotification
                      .mutateAsync(notificationUpdateId)
                      .then(() => setDeleteLoadingIndex(-1));
                  }}
                >
                  {deleteLoadingIndex === index ? (
                    <GladiateLoader height={30} />
                  ) : (
                    <TrashIcon className="w-5 h-5 text-gray-400 cursor-pointer hover:text-red-500" />
                  )}
                </button>
              </div>
            </div>
          ))}
          <button
            onClick={(e) => {
              e.preventDefault();
              const itemId = props.caseAction
                ? props.litigationPlanAction?.caseLitigationActionId
                : props.litigationPlanAction?.litigationPlanActionId;
              if (itemId) {
                createNotification.mutate(itemId);
              }
            }}
            className="flex items-center mt-5 ml-2group hover:text-sky-blue "
          >
            {createNotification.isLoading ? (
              <GladiateLoader height={30} />
            ) : (
              <PlusCircleIcon className="w-6 h-6 mr-1 -mt-0.5" />
            )}
            <div>Add notification</div>
          </button>
        </form>
      </Form>
    </div>
  );
}

export default LitigationActionForm;
