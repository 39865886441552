/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { booleanToString } from '@colosseum/data';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Toggle from '../../Toggle/Toggle';
import { FormControl, FormField, FormItem, FormMessage } from '../../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface ToggleFormInputProps {
  handleOnChangeFunction: (name: any, value: any) => void;
  name: string;
}

export const ToggleFormInput = React.forwardRef<HTMLInputElement, ToggleFormInputProps>(
  (props, ref) => {
    const { name, handleOnChangeFunction } = props;
    const form = useFormContext();
    const handleOnChangeForm = (value: boolean) => {
      const sanitizedValue = booleanToString(value);
      form.setValue(name, sanitizedValue, {
        shouldDirty: true,
      });
      handleOnChangeFunction(name, sanitizedValue);
    };

    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Toggle
                {...field}
                newState={field.value === '1'}
                onToggleChange={handleOnChangeForm}
                editable
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    );
  },
);

ToggleFormInput.displayName = 'ToggleFormInput';

export default ToggleFormInput;
