import {
  getNameToDisplayFromCognito,
  useCreateTask,
  useCreateTaskAssignee,
  useCreateTaskAssigner,
  useGetCases,
  useGetFirmUsers,
  useGetTasks,
} from '@colosseum/data';
import { TaskType, TaskTypeOptionsType, taskStatusOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { startCase } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import FirmUserLookup from '../FirmUserLookup/FirmUserLookup';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ResourceTask from '../ResourceTask/ResourceTask';
import Typography from '../Typography/Typography';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';
import { dayjsDateTime } from '../shadcn/data-table/DataTable';
import { DataTablePagination } from '../shadcn/data-table/DataTablePagination';
import { DataTableToolbar } from '../shadcn/data-table/DataTableToolbar';
import { columns } from './task-table-columns';

/* eslint-disable-next-line */
export interface ResourceTasksProps {
  createType?: TaskTypeOptionsType;
  resourceId?: string;
  scrollable?: boolean;
  isPaginated: boolean;
  showCaseFilter?: boolean;
  isLead?: boolean;
}
export type TanstackTableTaskType = TaskType & {
  caseId: string;
  assigneesList: string[];
};

const formSchema = z.object({
  title: z.string().nonempty(),
  description: z.string(),
  dueDate: z.string().optional(),
  assignee: z.string().optional(),
});

const sortOptions = [
  { id: 'Date Created', desc: false, title: 'Date Created (Oldest)' },
  { id: 'Date Created', desc: true, title: 'Date Created (Newest)' },
  { id: 'Due Date', desc: false, title: 'Due Date (Earliest)' },
  { id: 'Due Date', desc: true, title: 'Due Date (Latest)' },
  { id: 'Title', desc: false, title: 'Title (A-Z)' },
  { id: 'Title', desc: true, title: 'Title (Z-A)' },
  { id: 'Status', desc: false, title: 'Status (Most progress)' },
  { id: 'Status', desc: true, title: 'Status (Least progress)' },
] as const;

export function ResourceTasks(props: ResourceTasksProps) {
  const { createType, isPaginated, resourceId, scrollable, showCaseFilter, isLead } = props;
  const [assignees, setAssignees] = useState<string[]>([]);
  const [assigners, setAssigners] = useState<string[]>([]);
  const { data: tasksData, isLoading: isTasksLoading } = useGetTasks(resourceId);
  const tasks = tasksData?.data ?? [];
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const firmUsers = useMemo(() => {
    return (
      firmUsersData?.map((user) => {
        const displayName = getNameToDisplayFromCognito(user) ?? '';
        return {
          displayName,
          value: user?.Username ?? '',
        };
      }) ?? []
    );
  }, [firmUsersData]);
  const createTask = useCreateTask(resourceId);
  const createTaskAssignee = useCreateTaskAssignee();
  const createTaskAssigner = useCreateTaskAssigner();
  const casesRes = useGetCases();
  const casesWithTasks = tasks.map((task) => {
    return task.taskId?.split('-')[0];
  });

  const formattedTasks: TanstackTableTaskType[] = useMemo(
    () =>
      tasks.map((task) => {
        return {
          ...task,
          caseId: task.taskId?.split('-')[0] ?? '',
          assigneesList: task.assignees.map((assignee) => assignee.userAssigned) ?? [],
          status: task.status ?? taskStatusOptions.toDo,
        };
      }),
    [tasks],
  );
  const tableFilters = useMemo(() => {
    const filters = [
      {
        id: 'Assignees',
        options: firmUsers
          ? [
              ...(firmUsers?.map((user) => {
                return { value: user?.value, label: user.displayName };
              }) ?? []),
              { value: '-', label: 'No assignee' },
            ]
          : [],
      },
      {
        id: 'Status',
        options: Object.values(taskStatusOptions).map((status) => {
          return { value: status, label: startCase(status) };
        }),
      },
    ];
    if (showCaseFilter) {
      filters.push({
        id: 'Case',
        options: casesRes?.data?.data
          ? casesRes?.data?.data
              .filter((c) => c.caseTitle && casesWithTasks.includes(c.caseId))
              .map((c) => {
                return { value: c.caseId, label: c.caseTitle ?? '-' };
              })
          : [],
      });
    }
    return filters;
  }, [casesWithTasks, firmUsers, showCaseFilter, casesRes?.data?.data]);

  // This function lets the user filter by assignees
  const customAssigneesFilter = (row: any, id: string, value: string[]) => {
    if (row?.getValue(id).length === 0 && value.includes('-')) return true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return row?.getValue(id).some((item: string) => value?.includes(item));
  };

  // this function is written in order to get the numeric values for the assignees filter
  // e.g 'John Doe' => 2, 'Jane Doe' => 1
  // this is done because the default getFacetedUniqueValues function does not work with
  // nested arrays
  // ref https://github.com/TanStack/table/blob/9ef186f53dbad3047e8cc968a665794de7c44314/packages/table-core/src/utils/getFacetedUniqueValues.ts#L4
  const customGetFacetedUniqueValues = () => (table: any, columnId: string) => {
    if (columnId === 'Assignees') {
      return () => {
        const facetedRowModel = table.getColumn(columnId)?.getFacetedRowModel();
        if (!facetedRowModel) return new Map();
        const assigneeMap = new Map<any, number>();
        facetedRowModel.flatRows.forEach((row: any) => {
          const assigneesArray = row.getUniqueValues(columnId);
          assigneesArray.forEach((assignees: string[]) => {
            if (assignees.length === 0) {
              assigneeMap.set('-', (assigneeMap.get('-') ?? 0) + 1);
            }
            assignees.forEach((assignee: string) => {
              if (assigneeMap.has(assignee)) {
                assigneeMap.set(assignee, (assigneeMap.get(assignee) ?? 0) + 1);
              } else {
                assigneeMap.set(assignee, 1);
              }
            });
          });
        });
        return assigneeMap;
      };
    }
    return getFacetedUniqueValues()(table, columnId);
  };

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data: formattedTasks,
    columns,
    filterFns: {
      assigneesFilter: customAssigneesFilter,
    },
    state: {
      columnFilters,
    },
    sortingFns: {
      dayjsDateTime: dayjsDateTime,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: customGetFacetedUniqueValues(),
    getPaginationRowModel: isPaginated ? getPaginationRowModel() : undefined,
    autoResetPageIndex: isPaginated ? false : undefined,
    filterFromLeafRows: false,
  });

  const newTaskForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      dueDate: '',
    },
  });
  const handleCreateTask = (data: { title: string; description: string }) => {
    return createTask.mutateAsync({ ...data, type: createType ?? 'case' }).then((res) => {
      if (res.data) {
        Promise.all([
          ...assignees.map((assignee) => {
            return handleCreateAssignee(assignee, res.data.taskId);
          }),
          ...assigners.map((assigner) => {
            return handleCreateAssigner(assigner, res.data.taskId);
          }),
        ]).then(() => {
          enqueueSnackbar('Task successfully created', {
            variant: 'success',
          });
          newTaskForm.reset();
          setAssignees([]);
          setAssigners([]);
        });
      }
    });
  };

  const handleCreateAssignee = async (assignee: string, taskId?: string) => {
    return createTaskAssignee.mutateAsync({
      taskId,
      data: { userAssigned: assignee },
    });
  };

  const handleCreateAssigner = async (assigner: string, taskId?: string) => {
    return createTaskAssigner.mutateAsync({
      taskId,
      data: { userAssigned: assigner },
    });
  };

  if (isTasksLoading) return <GladiateLoader />;

  return (
    <div className="space-y-4">
      {createType && (
        <Form {...newTaskForm}>
          <form
            onSubmit={newTaskForm.handleSubmit(handleCreateTask)}
            className="border-b border-gray-300"
          >
            <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
              <TextFormInput title="Title" {...newTaskForm.register('title')} />
              <CalendarFormInput
                {...newTaskForm.register(`dueDate`)}
                title="Due Date"
                hideSyncButton
              />
              <TextFormInput
                title="Description"
                type="textarea"
                {...newTaskForm.register('description')}
              />

              <div className="flex flex-wrap items-center justify-between mb-4 gap-y-2 grow-1">
                <div className="flex flex-col flex-wrap gap-2">
                  <div className="flex flex-wrap items-center gap-2">
                    <Typography color="gray" size="sm" className="mr-2 w-15">
                      {'Assignees: '}
                    </Typography>

                    <FirmUserLookup
                      key="assigneeId"
                      selectedUsers={
                        assignees.map((assignee) => ({
                          username: assignee,
                          assigneeId: assignee,
                        })) ?? []
                      }
                      handleAdd={async (value: string) => {
                        setAssignees((prevState) => [...prevState, value]);
                        return Promise.resolve();
                      }}
                      handleRemove={async (user) => {
                        setAssignees((prevState) => [
                          ...prevState.filter((a) => a !== user.assigneeId),
                        ]);
                        return Promise.resolve();
                      }}
                      allowMultiple
                      tagClassName="mt-0"
                      buttonClassName="mt-0"
                      buttonLoaderClassName="mt-0"
                      buttonDataCy="assignee-dropdown"
                    />
                  </div>
                  <div className="flex flex-wrap items-center gap-2">
                    <Typography color="gray" size="sm" className="mr-2 w-15">
                      {'Assigners: '}
                    </Typography>
                    <FirmUserLookup
                      key="assigneeId"
                      selectedUsers={
                        assigners.map((assigner) => ({
                          username: assigner,
                          assigneeId: assigner,
                        })) ?? []
                      }
                      handleAdd={async (value: string) => {
                        setAssigners((prevState) => [...prevState, value]);
                        return Promise.resolve();
                      }}
                      handleRemove={async (user) => {
                        setAssigners((prevState) => [
                          ...prevState.filter((a) => a !== user.assigneeId),
                        ]);
                        return Promise.resolve();
                      }}
                      allowMultiple
                      tagClassName="mt-0"
                      buttonClassName="mt-0"
                      buttonLoaderClassName="mt-0"
                      buttonDataCy="assigner-dropdown"
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="grow"></div>
                  <Button
                    disabled={createTask.isLoading}
                    variant="primary"
                    className="float-right p-2 focus:outline-none rounded-lg min-w-[75px] h-9"
                    type="submit"
                    data-cy="add-task-button"
                  >
                    {createTask.isLoading ? <GladiateLoader height={20} /> : <div>Add Task</div>}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Form>
      )}
      <div className="my-4">
        <DataTableToolbar
          filters={tableFilters}
          table={table}
          hideViewButton
          customRightButton={
            <Select
              onValueChange={(e) => {
                const [id, desc] = e.split('-');
                table.setSorting([{ id, desc: desc === 'true' }]);
              }}
            >
              <SelectTrigger className="w-auto" data-cy="select-trigger-workflow-status">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((sortOption) => (
                  <SelectItem
                    key={`${sortOption.id}-${sortOption.desc}`}
                    value={`${sortOption.id}-${sortOption.desc}`}
                    data-cy={`select-item-workflow-status-${sortOption.id}`}
                  >
                    {sortOption.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          }
        />
      </div>
      <div
        className={`${
          scrollable ? 'max-h-[500px] overflow-auto relative' : ''
        } px-4 border-gray-300 border rounded-xl`}
      >
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <div key={row.original.taskId} className="border-b last:border-b-0">
              <ResourceTask
                task={row.original}
                handleCreateAssignee={handleCreateAssignee}
                handleCreateAssigner={handleCreateAssigner}
                isLead={isLead}
              />
            </div>
          ))
        ) : (
          <Typography color="gray" size="sm" className="p-4">
            No results.
          </Typography>
        )}
      </div>
      {isPaginated && <DataTablePagination table={table} />}
    </div>
  );
}

export default ResourceTasks;
