// Data
import {
  cn,
  groupCaseStatusesByCategory,
  statusToCategoryMapping,
  useGetCaseStatuses,
} from '@colosseum/data';
import { CaseStatusType } from '@gladiate/types';
import { kebabCase } from 'lodash';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';

/* eslint-disable-next-line */
export interface CaseStatusSelectProps {
  currentCaseStatusId?: string;
  handleValueChange: (newCaseStatusId: string) => void;
  className?: string;
}

export function CaseStatusSelect(props: CaseStatusSelectProps) {
  const { currentCaseStatusId, handleValueChange, className } = props;

  const { data: caseStatusesRes } = useGetCaseStatuses();
  const caseStatuses = caseStatusesRes?.data;

  const groupedCaseStatuses = groupCaseStatusesByCategory(caseStatuses || [], true);

  return (
    <Select value={currentCaseStatusId} onValueChange={handleValueChange}>
      <SelectTrigger className={cn('w-auto', className)} data-cy="select-trigger-workflow-status">
        <SelectValue placeholder="Select a case status" />
      </SelectTrigger>
      <SelectContent>
        {(Object.keys(groupedCaseStatuses) as Array<NonNullable<CaseStatusType['category']>>).map(
          (statusGroup) => (
            <SelectGroup key={statusGroup}>
              <SelectLabel>{statusToCategoryMapping[statusGroup]}</SelectLabel>
              {groupedCaseStatuses[statusGroup]?.map((status) => (
                <SelectItem
                  key={status.caseStatusId}
                  value={status.caseStatusId}
                  data-cy={`select-item-workflow-status-${kebabCase(status.title)}`}
                >
                  {status.title}
                </SelectItem>
              ))}
            </SelectGroup>
          ),
        )}
      </SelectContent>
    </Select>
  );
}

export default CaseStatusSelect;
