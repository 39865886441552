import FirmStatistic from '../../home/FirmStatistic/FirmStatistic';
import { Statistic } from '../../home/models/Statistic';

interface InsightsOverviewProps {
  data: Statistic[];
}

export function InsightsDataCard(props: InsightsOverviewProps) {
  const { data } = props;
  return (
    <div className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
      {data &&
        data?.map((statistic: Statistic) => (
          <FirmStatistic key={statistic.title} statistic={statistic} />
        ))}
    </div>
  );
}
