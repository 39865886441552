import FullLogoBlack from '../../images/fullLogoBlack.png';

/* eslint-disable-next-line */
export interface FullLogoProps {
  styles?: string;
}

export function FullLogo(props: FullLogoProps) {
  return <img src={FullLogoBlack} className={props.styles ? props.styles : ''} alt="Full Logo" />;
}

export default FullLogo;
