import {
  tanstackTableNames,
  useCreateDefaultAttorneyFee,
  useDeleteDefaultAttorneyFee,
  useGetDefaultAttorneyFees,
} from '@colosseum/data';
import { DefaultAttorneyFeeType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { useState } from 'react';
import CreateButton from '../CreateButton/CreateButton';
import FeeAgreementForm from '../FeeAgreementForm/FeeAgreementForm';
import Slideover from '../Slideover/Slideover';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './attorneys-fees-table-columns';

export interface AttorneysFeesTableProps {}

export function AttorneysFeesTable(props: AttorneysFeesTableProps) {
  // #region Hooks
  const createFeeAgreement = useCreateDefaultAttorneyFee();
  const deleteFeeAgreement = useDeleteDefaultAttorneyFee();
  // #endregion

  // #region Data Fetching
  const { data: feeAgreementsData, isLoading: isFeeAgreementsLoading } =
    useGetDefaultAttorneyFees();
  const feeAgreements = feeAgreementsData?.data ?? [];
  // #endregion

  // #region State
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedFeeAgreement, setSelectedFeeAgreement] = useState<DefaultAttorneyFeeType | null>(
    null,
  );
  const [typing, setTyping] = useState(false);

  // #endregion

  // #region Derived State
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  function handleRowClick(e: Row<DefaultAttorneyFeeType>) {
    setSlideoverOpen(true);
    setSelectedFeeAgreement(e.original);
  }
  // #endregion

  return (
    <>
      <Slideover
        title="Edit Attorney's Fees"
        description="Edit the title, description, and percentage of the fee agreement."
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        displayDeleteButton={true}
        deleteFunction={() => {
          setTyping(true);
          selectedFeeAgreement?.defaultAttorneyFeeId &&
            deleteFeeAgreement.mutateAsync(selectedFeeAgreement.defaultAttorneyFeeId).then(() => {
              setTyping(false);
              setSlideoverOpen(false);
              setSelectedFeeAgreement(null);
            });
        }}
        typing={typing}
      >
        <>
          {selectedFeeAgreement && (
            <FeeAgreementForm
              feeAgreement={selectedFeeAgreement}
              editable={true}
              setTyping={setTyping}
              isDefaultFee={true}
            />
          )}
        </>
      </Slideover>
      <DataTable
        data={feeAgreements}
        columns={columns}
        handleRowClick={handleRowClick}
        isLoading={isFeeAgreementsLoading}
        tableName={tanstackTableNames.attorneysFees}
        customRightButton={
          <div className="pl-2">
            <CreateButton
              title="Add Attorney Fee"
              loading={createFeeAgreement.isLoading || isFeeAgreementsLoading}
              onClick={() => {
                setTyping(true);
                createFeeAgreement.mutateAsync().then((res) => {
                  setSelectedFeeAgreement(res.data);
                  setTyping(false);
                  setSlideoverOpen(true);
                });
              }}
            />
          </div>
        }
      />
    </>
  );
}
