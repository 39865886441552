import {
  caseContactConnectionOptions,
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  CaseContactSubObject,
} from '@gladiate/types';
import { useCreateCaseContactConnection } from '../lib/react-query/case-contact-connection';
import { useUpdateRecordRequest } from '../lib/react-query/medical-record-request';

interface CreateCaseContactConnectionWithItemProps {
  caseId: string;
  contactId: string | undefined;
  itemId: string;
  itemType: CaseContactSubObject;
  roleOnCase: CaseContactConnectionRoleType['roleOnCase'];
}

interface CreateCCCWithSoleClientProps
  extends Omit<CreateCaseContactConnectionWithItemProps, 'contactId'> {
  caseContactConnections: CaseContactConnectionType[] | undefined;
}
/**
 *
 * @description This hook is used to create a case contact connection when associating a contact with a case sub object such as a medical treatment.
 */
export const useCreateCaseContactConnectionWithItem = () => {
  const createCaseContactConnection = useCreateCaseContactConnection();
  const updateManualRecordRequest = useUpdateRecordRequest();

  /**
   * @description This function is used to create a case contact connection with itemId and itemType
   */
  const createCCCWithItem = async (props: CreateCaseContactConnectionWithItemProps) => {
    return createCaseContactConnection.mutateAsync({
      caseId: props.caseId,
      contactId: props.contactId,
      itemId: props.itemId,
      itemType: props.itemType,
      roleOnCase: caseContactConnectionOptions.client,
    });
  };

  /**
   * @description This function is used to create a case contact connection with a sole client
   */
  const createCCCSoleClient = async (props: CreateCCCWithSoleClientProps) => {
    const existingClientsOnCase = props.caseContactConnections?.filter((contact) =>
      contact.roles.find((role) => role.roleOnCase === 'client'),
    );
    if (existingClientsOnCase?.length === 1) {
      if (props.itemType === 'recordRequest') {
        updateManualRecordRequest.mutate({
          recordsClientContactId: existingClientsOnCase[0].contactId,
          requestId: props.itemId,
        });
      }
      return createCaseContactConnection.mutateAsync({
        caseId: props.caseId,
        contactId: existingClientsOnCase[0].contactId,
        itemId: props.itemId,
        itemType: props.itemType,
        roleOnCase: caseContactConnectionOptions.client,
      });
    } else return;
  };

  return {
    createCaseContactConnectionWithItem: createCCCWithItem,
    createCaseContactConnectionWithItemSoleClient: createCCCSoleClient,
  };
};
