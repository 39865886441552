import {
  bitBToB,
  useDeletePaymentMethod,
  useGetPaymentMethods,
  useUpdatePaymentMethod,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  Button,
  CardForm,
  CreateButton,
  Form,
  SelectFormInput,
  Slideover,
  Typography,
} from '@colosseum/shared-ui';
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import chip from './chip.png';

const formSchema = z.object({
  primaryPaymentMethodId: z.string().optional(),
});

/* eslint-disable-next-line */
export interface PaymentMethodsProps {}

export function PaymentMethods(props: PaymentMethodsProps) {
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [methodToDeleteId, setMethodToDeleteId] = useState('');
  const [primaryCardId, setPrimaryCardId] = useState<string>();
  const { data: paymentMethodsData } = useGetPaymentMethods();
  const updatePrimaryPayment = useUpdatePaymentMethod();
  const paymentMethods = paymentMethodsData?.data;

  const paymentMethodsDropdownOptions = paymentMethods
    ?.map((paymentMethod) => {
      const { brand, acct_last_four, id } = paymentMethod.cardDetails.data.card;
      return {
        id,
        brand,
        acct_last_four,
      };
    })
    ?.reduce(
      (acc, obj, currentIndex) => {
        const cardName = `${startCase(obj.brand)} XXXX XXXX XXXX ${obj.acct_last_four}`;
        const key = acc[`${cardName}`] ? `${cardName}-${currentIndex}` : `${cardName}`;
        acc[key] = obj.id;
        return acc;
      },
      {} as {
        [key: string]: string;
      },
    );

  const deletePaymentMethod = useDeletePaymentMethod();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (paymentMethods) {
      const primaryMethod = paymentMethods.find((method) => bitBToB(method.isPrimary));
      if (primaryMethod) setPrimaryCardId(primaryMethod.paymentMethodId);
    }
  }, [paymentMethods]);

  return (
    <>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={() => {
          deletePaymentMethod.mutateAsync(methodToDeleteId).then(() => {
            setShowDeleteModal(false);
            setMethodToDeleteId('');
            setPrimaryCardId(undefined);
          });
        }}
      />
      <Slideover
        title="Add Payment Method"
        description="Add a new payment method."
        open={open}
        setOpen={setOpen}
        displayDeleteButton={false}
        typing={false}
        noPadding
      >
        <CardForm setSlideoverOpen={setOpen} />
      </Slideover>
      <div className="px-1">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <Typography variant="heading" color="black">
              Payment Methods
            </Typography>
            <Typography variant="xs" color="gray" className="mt-2 text-sm text-gray-700">
              Add or remove payment methods.
            </Typography>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title="Add Payment"
              loading={false}
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
        </div>
        <Form {...form}>
          <div className="flex items-center gap-2 pt-4 w-[350px] ">
            <SelectFormInput
              title="Primary Payment Method"
              listItemsIsObject
              value={primaryCardId}
              defaultValue={undefined}
              listItems={paymentMethodsDropdownOptions ?? {}}
              handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const cardId = e.target.value;
                updatePrimaryPayment.mutateAsync({
                  paymentMethodId: cardId,
                  isPrimary: '1',
                });
              }}
              placeholderText={'Select a primary payment method'}
              tooltipMessage={'This is the card that will be charged.'}
              {...form.register('primaryPaymentMethodId')}
            />
          </div>
        </Form>

        <div className="flex flex-row flex-wrap gap-4 mt-5">
          {paymentMethods &&
            paymentMethods
              ?.sort((a, b) => {
                if (bitBToB(a.isPrimary) === true) {
                  return -1;
                }
                return 1;
              })
              .map((paymentMethod) => {
                const last4 = paymentMethod.cardDetails.data.card.acct_last_four ?? '-';
                const expirationDate =
                  `${paymentMethod.cardDetails.data.card.month}/${paymentMethod.cardDetails.data.card.year}` ??
                  '-/-';
                const brand = paymentMethod.cardDetails.data.card.brand.replace(/_/g, ' ');

                const isPrimaryPaymentMethod = bitBToB(paymentMethod.isPrimary);

                return (
                  <div
                    key={paymentMethod.paymentMethodId}
                    className="w-64 h-40 rounded-lg shadow-lg bg-gradient-to-r from-sky-blue via-atlantic-blue to-gray-900"
                  >
                    <div className="relative flex justify-between m-2">
                      <div className={'self-center pl-2'}>
                        <img src={chip} alt="Smart card" className=" w-8" />
                      </div>

                      <Button
                        onClick={() => {
                          setMethodToDeleteId(paymentMethod.paymentMethodId);
                          setShowDeleteModal(true);
                        }}
                        variant="default"
                        className={'p-0 px-2'}
                      >
                        <TrashIcon className="w-5 h-5 text-white hover:text-red-300" />
                      </Button>
                    </div>
                    <div className="flex px-4 pt-6">
                      <Typography variant="semiBold" color="white" className={'uppercase'}>
                        XXXX XXXX XXXX {last4}
                      </Typography>
                    </div>

                    <div className="flex items-center justify-between p-2  px-4 text-white font-quick">
                      <div className="flex flex-col">
                        <Typography variant="subtext" color="white" size={'xs'}>
                          {' '}
                          {expirationDate}{' '}
                        </Typography>
                        <Typography variant="subtext" color="white" className={'uppercase'}>
                          {brand}
                        </Typography>
                      </div>
                      {isPrimaryPaymentMethod && (
                        <CheckCircleIcon className="text-green-500 w-5 h-5 self-end" />
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
}

export default PaymentMethods;
