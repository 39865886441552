import { displayContactName, prettifyDateString } from '@colosseum/data';
import {
  dateRangeFilterFn,
  renderCell,
  renderCellWithChips,
  renderHeader,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { ContactType, TaskType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export interface RecordRequestTaskDataForTableType extends TaskType {
  providerAddress: string;
  providerName: string;
  providerEmail: string;
  providerPhone: string;
  caseTitle: string;
  assigneesDisplayNames: string[];
  assignersDisplayNames: string[];
  recordsProvider: ContactType | undefined;
  client: ContactType | undefined;
  requestingUser: string | undefined;
}

export const recordRequestTaskLineupColumns: ColumnDef<RecordRequestTaskDataForTableType>[] = [
  {
    accessorFn: (row) => row?.caseTitle,
    id: 'Case',
    sortUndefined: 1,
    header: renderHeader,
    filterFn: stringIncludesFilterFn,
    cell: (props) => renderCell({ props }),
    enableHiding: false,
  },
  {
    accessorFn: (row) => displayContactName(row?.client),
    id: 'Client',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },

  {
    accessorFn: (row) => row.providerName,
    id: 'Provider Name',
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  // {
  //   accessorFn: (row) => row.providerEmail,
  //   id: 'Provider Email',
  //   header: renderHeader,
  //   cell: (props) => {
  //     return renderCell({ props });
  //   },
  // },
  // {
  //   accessorFn: (row) => row.providerPhone,
  //   id: 'Provider Phone',
  //   header: renderHeader,
  //   cell: (props) => {
  //     return renderCell({ props });
  //   },
  // },
  // {
  //   accessorFn: (row) => row.providerAddress,
  //   id: 'Provider Address',
  //   header: renderHeader,
  //   cell: (props) => {
  //     return renderCell({ props });
  //   },
  // },
  {
    accessorFn: (row) => row?.title,
    id: 'Title',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    enableHiding: false,
  },
  {
    accessorFn: (row) => row?.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => startCase(row?.status),
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    filterFn: stringIncludesFilterFn,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dueDate),
    id: 'Due Date',
    sortUndefined: -1,
    sortingFn: 'dayjsDateTime',
    filterFn: dateRangeFilterFn<RecordRequestTaskDataForTableType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.assigneesDisplayNames.join(', '),
    id: 'Assignees',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
  {
    accessorFn: (row) => row?.assignersDisplayNames.join(', '),
    id: 'Assigners',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
];
