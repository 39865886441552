import {
  AdobeAccountDetailsType,
  AdobeAccountType,
  AdobeAgreementStatus,
  AdobeAuthUrlType,
  AdobeSignUserAccount,
  AdobeSignUserStatus,
  AdobeSignerType,
  AdobeUserType,
  DefaultV3Response,
  SigningEvent,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const baseSirensRoute = `sirens/${API_STAGE}/v3`;

// ------------------- ADOBE SIGN ---------------------------------- //
// #region

// GET get_signers_v3
export const getAdobeSignerListV3 = () =>
  axiosInstance
    .get<DefaultV3Response<AdobeSignerType[]>>(`${baseSirensRoute}/users/signers`)
    .then((res) => res.data);

// PATCH admin_change_user_sign_privelage_v3
export const updateAdobeSignerPrivelageV3 = (data: {
  username: string;
  signerPrivelage: boolean;
}) =>
  axiosInstance
    .patch<DefaultV3Response<AdobeUserType>>(
      `${baseSirensRoute}/users/${data.username}/sign-privelage`,
      {
        signerPrivelage: data.signerPrivelage,
      },
    )
    .then((res) => res.data);

// GET get_user_v3
export const getAdobeUserV3 = (username: string) =>
  axiosInstance
    .get<DefaultV3Response<AdobeSignUserAccount>>(`${baseSirensRoute}/users/${username}`)
    .then((res) => res.data);

// GET get_users_v3
export const getAdobeUserListV3 = () =>
  axiosInstance
    .get<DefaultV3Response<AdobeUserType[]>>(`${baseSirensRoute}/users`)
    .then((res) => res.data);

// POST connect_user_to_account_v3
export const createAdobeSignUserAccountV3 = () =>
  axiosInstance.post<DefaultV3Response<any>>(`${baseSirensRoute}/users`).then((res) => res.data);

// POST send_to_sign_v3
export const sendDocumentForSignV3 = (data: SigningEvent) =>
  axiosInstance
    .post<
      DefaultV3Response<{
        status: string;
        templateKey: string;
        genDocKey: string;
      }>
    >(`${baseSirensRoute}/agreements`, data)
    .then((res) => res.data);

// GET get_user_auth_url_v3
export const getAdobeAuthUrlV3 = () =>
  axiosInstance
    .get<DefaultV3Response<AdobeAuthUrlType>>(`${baseSirensRoute}/oauth`)
    .then((res) => res.data);

export interface AdobeOauthCodeType {
  agreementWebhookId: string;
  firmId: string;
  oauthStatus: string;
}

// POST send_user_auth_code_v3
export const sendAdobeUserAuthCodeV3 = (authCode: string) =>
  axiosInstance
    .post<DefaultV3Response<AdobeOauthCodeType>>(`${baseSirensRoute}/oauth`, {
      authCode,
    })
    .then((res) => res.data);

export interface AdobeFirmAccountRes {
  AdobeAccount: AdobeAccountType;
  AdobeAccountDetails: AdobeAccountDetailsType;
  status: string;
}

// GET get_account_v3
export const getAdobeAccountListV3 = () =>
  axiosInstance
    .get<DefaultV3Response<AdobeFirmAccountRes>>(`${baseSirensRoute}/accounts`)
    .then((res) => res.data);

// POST enable_adobe_account_integration_v3
export const createAdobeAccountV3 = (companyName: string) =>
  axiosInstance
    .post<DefaultV3Response<any>>(`${baseSirensRoute}/accounts`, {
      companyName,
    })
    .then((res) => res.data);

// PATCH admin_change_user_admin_v3
export const updateAdobeAdminV3 = (data: { username: string; isAdmin: boolean }) =>
  axiosInstance
    .patch<DefaultV3Response<AdobeUserType>>(`${baseSirensRoute}/users/${data.username}/admin`, {
      isAdmin: data.isAdmin,
    })
    .then((res) => res.data);

// PATCH admin_change_user_status_v3
export const updateAdobeUserStatusV3 = (data: {
  username: string;
  status: 'INACTIVE' | 'ACTIVE';
}) =>
  axiosInstance
    .patch<DefaultV3Response<AdobeUserType>>(`${baseSirensRoute}/users/${data.username}/status`, {
      status: data.status,
    })
    .then((res) => res.data);

// DEL admin_delete_user_on_account_v3
export const deleteAdobeUserV3 = (data: { username: string }) =>
  axiosInstance
    .delete<DefaultV3Response<AdobeUserType>>(`${baseSirensRoute}/users/${data.username}/delete`)
    .then((res) => res.data);

// GET get_agreements_for_case_v3
export const getAgreementsForCaseV3 = (caseId: string) => {
  return axiosInstance
    .get<DefaultV3Response<AdobeAgreementStatus[]>>(`${baseSirensRoute}/agreements/cases/${caseId}`)
    .then((res) => res.data);
};

// #endregion
