import {
  contactConnectionRoleColorMap,
  useCreateContactConnection,
  useDeleteContactConnection,
  useGetContactConnectionsByContact,
} from '@colosseum/data';
import {
  ContactConnectionType,
  ContactType,
  contactConnectionOptionsReverse,
} from '@gladiate/types';
import { startCase, uniq } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Chip from '../Chip/Chip';
import ContactChip from '../ContactChip/ContactChip';
import { getUniqueContactConnections } from '../ContactConnectionsTable/ContactConnectionsTable';
import Slideover from '../Slideover/Slideover';
import Typography from '../Typography/Typography';
import EditRelationshipsPopover from './EditRelationshipsPopover';

export interface ContactConnectionsTableSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedContact?: ContactType & {
    roles: ContactConnectionType['relationship'][];
  };
  primaryContactId: string;
}

export function ContactConnectionsTableSlideover(props: ContactConnectionsTableSlideoverProps) {
  const { open, setOpen, selectedContact, primaryContactId } = props;
  const roles = uniq(selectedContact?.roles).filter((role) => role !== 'connection');
  const reversedRoles = roles.map((role) => role && contactConnectionOptionsReverse[role]);
  const createContactConnection = useCreateContactConnection();
  const deleteContactConnection = useDeleteContactConnection();
  const { data: contactConnections } = useGetContactConnectionsByContact(primaryContactId);

  const uniqueContactConnections = getUniqueContactConnections(contactConnections?.data);

  const handleAddContactRole = (role: ContactConnectionType['relationship']) => {
    if (!selectedContact || !role) return;
    createContactConnection.mutate({
      primaryContactId: primaryContactId,
      secondaryContactId: selectedContact?.contactId,
      primaryContactRelationship: contactConnectionOptionsReverse[role],
    });
  };

  const handleRemoveContactRole = (role: ContactConnectionType['relationship']) => {
    if (!selectedContact || !role) return;
    const rolesToDelete = uniqueContactConnections[selectedContact?.contactId]?.filter(
      (connection) => connection.relationship === contactConnectionOptionsReverse[role],
    );
    rolesToDelete?.forEach((role) => {
      deleteContactConnection.mutate(role.contactConnectionId);
    });
  };

  useEffect(() => {
    if (!selectedContact) {
      setOpen(false);
    }
  }, [selectedContact?.roles]);

  return (
    <Slideover
      open={open}
      setOpen={setOpen}
      title="Contact Connection"
      description="View your contact connection"
      deleteButtonText="Remove Contact Connection"
      displayDeleteButton
      deleteFunction={() => {
        if (!selectedContact) return;
        const rolesToDelete = uniqueContactConnections[selectedContact?.contactId];
        rolesToDelete?.forEach((role) => {
          deleteContactConnection.mutate(role.contactConnectionId);
        });
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex-col">
          <Typography variant="semiBold">Connected Contact</Typography>
          <ContactChip contactId={selectedContact?.contactId} viewOnly />
        </div>
        <div className="flex-col">
          <Typography variant="semiBold">Relations to Connected Contact</Typography>
          <div className="flex flex-wrap items-center gap-1">
            {reversedRoles?.map((role) => (
              <Chip
                key={role}
                name={startCase(role)}
                color={contactConnectionRoleColorMap?.[role ?? 'default']}
              />
            ))}
            <EditRelationshipsPopover
              contact={selectedContact}
              handleAddRole={handleAddContactRole}
              handleRemoveRole={handleRemoveContactRole}
            />
          </div>
        </div>
      </div>
    </Slideover>
  );
}

export default ContactConnectionsTableSlideover;
