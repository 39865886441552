/* eslint-disable react/jsx-no-useless-fragment */
import { enqueueAPISnackbar } from '@colosseum/data';
import { Button, FullLogo, SpinLoader } from '@colosseum/shared-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import Auth from '@aws-amplify/auth';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

const TermsAndPrivacySection = () => {
  return (
    <p className="mx-auto mt-3 text-sm text-gray-500">
      {`By clicking 'Sign in' you agree to the Gladiate`}{' '}
      <NavLink
        className="underline text-sky-blue"
        to="https://www.gladiatelaw.com/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="button" variant="link" size="unset" className="underline">
          Terms of Service
        </Button>
      </NavLink>{' '}
      and{' '}
      <NavLink
        className="underline text-sky-blue"
        to="https://www.gladiatelaw.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="button" variant="link" size="unset" className="underline">
          Privacy Policy
        </Button>
      </NavLink>{' '}
      .
    </p>
  );
};

/* eslint-disable-next-line */
export interface LoginPageProps {}

export function LoginPage() {
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);

  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [firstTimeUser, setFirstTimeUser] = useState(false);
  const [firstTimeUserObj, setFirstTimeUserObj] = useState<any>({});

  const [passwordReset, setPasswordReset] = useState(false);

  const [firstTimeForgotPassword, setFirstTimeForgotPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function newUserSignIn() {
    try {
      Auth.completeNewPassword(
        firstTimeUserObj, // the Cognito User Object
        newPassword, // the new password
        {},
        // OPTIONAL, the required attributes
        // {
        //   email: 'xxxx@example.com',
        //   phone_number: '1234567890',
        // }
      )
        .then((user) => {
          // at this time the user is logged in if no MFA required
          localStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
          localStorage.setItem('accessToken', user.signInUserSession.accessToken.jwtToken);

          navigate('/cases');

          // refresh page
          window.location.reload();
        })
        .catch((error) => {
          enqueueAPISnackbar({
            message: `error signing in ${error?.message || ''}`,
            variant: 'error',
            key: 'login-new-password-error',
          });
        });
    } catch (error) {
      enqueueAPISnackbar({
        message: `error signing in ${error || ''}`,
        variant: 'error',
        key: 'login-new-sign-in-error',
      });
    }
  }

  async function signIn(e: any) {
    e.preventDefault();

    try {
      setIsLoading(true);
      await Auth.signIn(e.target.username.value, e.target.password.value).then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          setFirstTimeUser(true);
          setFirstTimeUserObj(user);
        } else {
          // other situations
          localStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
          localStorage.setItem('accessToken', user.signInUserSession.accessToken.jwtToken);

          navigate('/cases');

          // refresh page
          window.location.reload();
        }
        setIsLoading(false);
      });
    } catch (err) {
      const error = err as { message?: string };
      setIsLoading(false);
      enqueueAPISnackbar({
        message: error?.message || 'Incorrect username or password.',
        variant: 'error',
        key: 'login-error',
      });
    }
  }

  async function initForgotPassword() {
    const errorMessage =
      'You are trying to reset your password but you have not yet logged in for the first time. Please check your email titled "Welcome to Gladiate!" and follow the instructions to reset your password.';

    try {
      await Auth.forgotPassword(username).then((res) => {
        if (res.message === 'NotAuthorizedException') {
          setErrorMessage(errorMessage);
          handleBack();
        } else {
          setForgotPassword(true);
        }
      });
    } catch (error) {
      setFirstTimeForgotPassword(true);
      setErrorMessage(errorMessage);
      handleBack();
    }
  }

  async function confirmForgotPassword() {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword).then(() => {
        setForgotPassword(false);
        setPasswordReset(false);
      });
    } catch (error) {
      setFirstTimeForgotPassword(true);
      handleBack();
      enqueueAPISnackbar({
        message: `error confirming forgot password code ${error || ''}`,
        variant: 'error',
        key: 'login-confirming-error',
      });
    }
  }

  function handleBack() {
    setForgotPassword(false);
    setFirstTimeUser(false);
    setPasswordReset(false);
  }

  return (
    <div data-cy="login">
      <Helmet defer={false}>
        <title>Gladiate | Login</title>
      </Helmet>
      <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
            Sign in to your account
          </h2> */}
          <FullLogo styles={'w-auto h-12 mx-auto mb-5'} />
          {/* <p className="mt-2 text-sm text-center text-gray-600">
            Or{' '}
            <a
              href="#"
              className="font-medium text-sky-blue hover:text-atlantic-blue"
            >
              start your 14-day free trial
            </a>
          </p> */}
        </div>

        {firstTimeUser && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="relative px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
              {/* back arrow */}
              <div className="absolute flex items-center justify-between top-2 left-2">
                <div className="flex items-center">
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <ArrowLeftIcon className="w-5 h-5 mr-2" />
                    Back
                  </Button>
                </div>
              </div>
              <form className="mt-8 space-y-6" action="#" method="POST">
                <div>Please reset your password below.</div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="new-password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      value={newPassword}
                      className="block w-full p-3 rounded-md  border border-gray-200  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none  focus:ring-sky-blue sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      newUserSignIn();
                    }}
                    className="w-full"
                  >
                    {passwordReset ? 'Reset Password' : 'Reset Password'}
                  </Button>
                  <TermsAndPrivacySection />
                </div>
              </form>
            </div>
          </div>
        )}

        {forgotPassword && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="relative px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
              <div className="absolute flex items-center justify-between top-2 left-2">
                <div className="flex items-center">
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <ArrowLeftIcon className="w-5 h-5 mr-2" />
                    Back
                  </Button>
                </div>
              </div>
              <form className="mt-8 space-y-6" action="#" method="POST">
                {passwordReset ? (
                  <>
                    <div>Please check your email for a code to reset your password.</div>
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Code
                      </label>
                      <div className="mt-2">
                        <input
                          id="code"
                          name="code"
                          type="text"
                          autoComplete="code"
                          required
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                          value={code}
                          className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-blue sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        New Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="new-password"
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          required
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          value={newPassword}
                          className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-blue sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Username or Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="usernameForReset"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username}
                        className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                )}

                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();

                      if (passwordReset) {
                        confirmForgotPassword();
                      } else {
                        initForgotPassword();
                        setPasswordReset(true);
                      }
                    }}
                    className="w-full"
                  >
                    {passwordReset ? 'Reset Password' : 'Reset Password'}
                  </Button>
                  <TermsAndPrivacySection />
                </div>
              </form>
            </div>
          </div>
        )}

        {!forgotPassword && !firstTimeUser && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-2 bg-white rounded-lg shadow">
              {firstTimeForgotPassword && (
                <div className="px-4 pt-4">
                  <div className="relative p-2 text-sm text-red-800 bg-red-100 border border-red-800 rounded-lg">
                    {errorMessage}
                    <button
                      onClick={() => {
                        setFirstTimeForgotPassword(false);
                      }}
                      className="absolute top-1 right-1"
                    >
                      <XMarkIcon className="w-5 h-5 ml-2" />
                    </button>
                  </div>
                </div>
              )}
              <form className="px-4 py-8 space-y-6 sm:px-10" method="POST" onSubmit={signIn}>
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username or Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      autoComplete="username"
                      required
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      value={username}
                      className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-blue sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-blue sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
                  {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="w-4 h-4 border-gray-200 rounded text-sky-blue focus:ring-sky-blue"
                  />
                  <label
                    htmlFor="remember-me"
                    className="block ml-2 text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div> 

                  
                </div> */}

                <div className="">
                  <Button variant="primary" disabled={isLoading} type="submit" className="w-full">
                    {isLoading ? <SpinLoader color="blue" /> : 'Sign in'}
                  </Button>
                  <div className="mt-3 text-sm">
                    <Button
                      onClick={() => setForgotPassword(!forgotPassword)}
                      variant="link"
                      size="unset"
                    >
                      Forgot your password?
                    </Button>
                  </div>
                  <TermsAndPrivacySection />
                </div>
              </form>

              {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 bg-white">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-3 mt-6">
                <div>
                  <a
                    href="#"
                    className="inline-flex justify-center w-full px-4 py-2 text-gray-500 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex justify-center w-full px-4 py-2 text-gray-500 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex justify-center w-full px-4 py-2 text-gray-500 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
