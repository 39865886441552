import {
  AllCasesDropdown,
  Button,
  GladiateLoader,
  ScrollArea,
  TooltipWrapper,
  Typography,
} from '@colosseum/shared-ui';
import { LinkedCaseType } from '@gladiate/types';
import { Tooltip } from '@radix-ui/react-tooltip';
import { LinkIcon, PlusCircle, TrashIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface LinkedCasesCardProps {
  linkedCases: LinkedCaseType[] | undefined;
  caseId: string;
  handleLinkCase: {
    mutation: (caseId: string) => void;
    isLoading: boolean;
  };
  handleUnlinkCase: {
    mutation: (linkedCaseId: string) => void;
    isLoading: boolean;
  };
  isLoading: boolean;
}

export function LinkedCasesCard({
  caseId,
  linkedCases,
  handleLinkCase,
  handleUnlinkCase,
  isLoading,
}: LinkedCasesCardProps) {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  useEffect(() => {
    if (!handleUnlinkCase.isLoading) {
      setDeletingId(null);
    }
  }, [handleUnlinkCase.isLoading]);

  const excludedCases = [
    caseId,
    ...(linkedCases?.map((linkedCase) => linkedCase.relatedCaseId) ?? []),
  ];

  const Wrapper = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <div className="flex flex-col w-full p-5 px-3 pt-5 mt-5 bg-white border border-b border-gray-200 shadow-sm rounded-xl animate-in fade-in">
        {children}
      </div>
    ),
    [],
  );

  if (isLoading) {
    return (
      <Wrapper key={'loading-content'}>
        <GladiateLoader height={25} />
      </Wrapper>
    );
  }

  if (!linkedCases?.length) {
    return (
      <Wrapper key={'no-linked-cases-content'}>
        <AllCasesDropdown
          excludedCaseIds={excludedCases}
          customButton={
            <Button disabled={handleLinkCase.isLoading} variant="outline" size="sm" className="h-8">
              {handleLinkCase.isLoading ? (
                <GladiateLoader className="animate-in fade-in" height={20} />
              ) : (
                <PlusCircle className="w-4 h-4 mr-2" />
              )}
              Link a new case
            </Button>
          }
          onSelectCase={handleLinkCase.mutation}
          hideCreateOption
          isLoading={handleLinkCase.isLoading}
          customIcon={'+'}
          buttonClassName="w-8 h-8"
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper key={'linked-cases-content'}>
      <div className="flex items-center justify-between pb-2 px-2">
        <p className="flex self-center font-semibold">Linked Cases</p>
        <AllCasesDropdown
          excludedCaseIds={excludedCases}
          onSelectCase={handleLinkCase.mutation}
          hideCreateOption
          isLoading={handleLinkCase.isLoading}
          customIcon={'+'}
          buttonClassName="w-8 h-8"
        />
      </div>
      <div className="flex flex-col divide-y divide-gray-200 h-fit max-h-[130px]  overflow-y-hidden hover:overflow-y-auto">
        {linkedCases.map((linkedCase) => (
          <div
            className="flex items-center gap-2 px-2 text-sm group justify-between rounded-lg !w-full"
            key={linkedCase.relatedCaseId}
          >
            <TooltipWrapper
              delayDuration={500}
              message={
                linkedCase.relatedCase.caseTitle ?? linkedCase.relatedCase.caseName ?? 'Untitled'
              }
            >
              <Link
                to={`/cases/${linkedCase.relatedCaseId}`}
                className="flex items-center gap-x-1 cursor-pointer group max-w-[80%] w-full"
              >
                <div>
                  <LinkIcon className="!w-3 !h-3 !aspect-square" />
                </div>

                <Typography className="truncate text-blue-400 underline group-hover:text-blue-500 transition-colors">
                  {linkedCase.relatedCase.caseTitle ??
                    linkedCase.relatedCase.caseName ??
                    'Untitled'}
                </Typography>
              </Link>
            </TooltipWrapper>

            <TooltipWrapper delayDuration={500} message={'Remove case from linked cases'}>
              <Button
                disabled={handleUnlinkCase.isLoading}
                onClick={() => {
                  handleUnlinkCase.mutation(linkedCase.linkedCaseId);
                  setDeletingId(linkedCase.linkedCaseId);
                }}
                variant={'ghost'}
                size={'xs'}
                className="h-8 border-dashed"
              >
                {handleUnlinkCase.isLoading && deletingId === linkedCase.linkedCaseId ? (
                  <GladiateLoader className="animate-in fade-in" height={20} />
                ) : (
                  <TrashIcon className="w-4 h-4 animate-in fade-in text-red-500 hover:text-red-800" />
                )}
              </Button>
            </TooltipWrapper>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

export default LinkedCasesCard;
