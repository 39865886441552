import { cn } from '@colosseum/data';
import { cva, type VariantProps } from 'class-variance-authority';
import { DynamicTypography, DynamicTypographyProps } from './DynamicTypography';

const typographyVariants = cva('', {
  variants: {
    variant: {
      grayUpper: 'text-xs font-semibold tracking-tighter text-gray-400 uppercase',
      subtext: 'text-sm text-gray-500',
      heading: 'text-xl font-semibold',
      pageHeading: 'text-3xl font-semibold ml-1',
      tableCell: 'font-medium',
      semiBold: 'font-semibold',
      xs: 'text-xs',
      xlHeading: 'mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl',
      link: 'text-base font-medium text-atlantic-blue hover:text-sky-blue',
      default: '',
    },
    color: {
      black: 'text-black',
      gray: 'text-gray-500',
      red: 'text-red-500',
      yellow: 'text-yellow-500',
      blue: 'text-blue-500',
      white: 'text-white',
      default: '',
    },
    size: {
      default: '',
      '3xl': 'text-3xl',
      xl: 'text-xl',
      lg: 'text-lg',
      md: 'text-md',
      sm: 'text-sm',
      xs: 'text-xs',
    },
  },
  defaultVariants: {
    variant: 'default',
    color: 'default',
    size: 'default',
  },
});

export type TypographyProps = {
  className?: string;
  children: React.ReactNode;
  parentTag?: string;
  tag?: DynamicTypographyProps['tag'];
} & VariantProps<typeof typographyVariants>;

export function Typography(props: TypographyProps) {
  const { children, variant, color, size, className, tag, ...rest } = props;
  return (
    <DynamicTypography
      tag={tag}
      className={cn(
        typographyVariants({
          variant,
          color,
          size,
          className,
        }),
        'break-words',
      )}
      {...rest}
    >
      {children}
    </DynamicTypography>
  );
}

export default Typography;
