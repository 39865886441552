import { ContactResourceStringType } from '@gladiate/types';
import { getFirmId } from '../utils/authUtils';
import { VITE_API_STAGE } from '../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const firmId = getFirmId();

// Conscription
export const conscriptionRoute = `/conscript/${firmId}`;

// Collegium - Contacts
export const baseCollegiumRoute = `/collegium/${API_STAGE}`;
export const baseFirmContactsRoute = `${baseCollegiumRoute}/v3/contacts`;
export const deleteContactRoute = (contactId: string) =>
  `${baseCollegiumRoute}/v3/contacts/${contactId}`;
export const createContactResourceRoute = (
  resourceType: ContactResourceStringType,
  contactId: string,
) => `${baseCollegiumRoute}/v3/contacts/${contactId}/${resourceType}`;
export const deleteContactResourceRoute = (
  resourceType: ContactResourceStringType,
  resourceId: string,
) => `${baseCollegiumRoute}/v3/contacts/${resourceType}/${resourceId}`;
// TODO: ASK ABOUT THE CONTACT ID IN THE NAME OF THIS ONE
export const getFirmContactRoute = (contactId: string) =>
  `${baseCollegiumRoute}/v3/contacts/${contactId}`;
export const getFirmContactRouteComplete = (contactId: string) =>
  `${getFirmContactRoute(contactId)}/complete`;
export const getSelectedContactsRoute = `${baseCollegiumRoute}/v3/contacts/selected-contacts`;

// Error reporting
export const errorReportingRoute = `/sanitatis/v1/${firmId}/util/report/error`;

export const baseFirmSettingsRoute = `/jurisconsults/${API_STAGE}/v3/firms`;
export const firmSettingsRoute = `${baseFirmSettingsRoute}/${firmId}`;
export const firmSettingsOfficeRoute = `${baseFirmSettingsRoute}/firm-offices`;
export const updateFirmOfficeRoute = (firmOfficeId: string) =>
  `${baseFirmSettingsRoute}/firm-offices/${firmOfficeId}`;
