import { Line, LineChart, ResponsiveContainer } from 'recharts';
import FirmStatistic from '../../home/FirmStatistic/FirmStatistic';
import { Statistic } from '../../home/models/Statistic';
import { CardSection } from '@colosseum/shared-ui';

interface InsightsDataCardChartProps {
  cardData: Statistic;
  chartData: any;
  chartDataKey: string;
  color?: string; //must use rgb/hex ex. 'rgb(0,81,208)'
}

export function InsightsDataCardChart(props: InsightsDataCardChartProps) {
  const { cardData, chartData, chartDataKey, color } = props;
  // TODO get design tokens in place of hardcoded color and get a mapping of rgx/hex for the chart
  const chartColor = color ? color : 'rgb(0,81,208)';
  return (
    <>
      <div className="grid grid-cols-6 gap-5 mt-5  w-full">
        <div className="col-span-2">
          <FirmStatistic key={cardData.title} statistic={cardData} />
        </div>
        <div className="col-span-4 flex-wrap">
          <div className="pl-2 bg-blue-700 rounded-l-xl rounded-r-2xl">
            <CardSection className={`rounded-l-none`}>
              <ResponsiveContainer width="100%" height={85}>
                <LineChart width={700} height={85} data={chartData}>
                  <Line
                    type="monotone"
                    dataKey={chartDataKey}
                    stroke={chartColor}
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardSection>
          </div>
        </div>
      </div>
    </>
  );
}
