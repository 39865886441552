import * as Tooltip from '@radix-ui/react-tooltip';
import React from 'react';
import Typography from '../../Typography/Typography';

/* eslint-disable-next-line */
export interface TooltipWrapperProps {
  children: React.ReactNode;
  message: string | React.ReactNode;
  delayDuration?: number;
}

// TODO: fix dup render (GLAD-2523)
export function TooltipWrapper(props: TooltipWrapperProps) {
  return (
    <Tooltip.Provider delayDuration={props.delayDuration ?? 100}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{props.children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="z-50 max-w-md rounded-md data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none bg-gray-50 px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] whitespace-pre-wrap"
            sideOffset={5}
            collisionPadding={10}
            hidden={!props.message}
          >
            <Typography size="sm">{props.message}</Typography>

            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default TooltipWrapper;
