import { cn } from '@colosseum/data';

export const EMLIcon = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 303.188 303.188"
    className={cn('w-8 h-8 min-w-8', className)}
  >
    <g>
      <polygon
        style={{ fill: '#E4E4E4' }}
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
      />
      <g>
        <path
          style={{ fill: '#A4A9AD' }}
          d="M211.372,91.577c0-3.568-2.92-6.488-6.488-6.488H98.305c-3.568,0-6.488,2.92-6.488,6.488v55.682
			c0,3.568,2.92,6.488,6.488,6.488h106.579c3.568,0,6.488-2.92,6.488-6.488L211.372,91.577L211.372,91.577z"
        />
        <path
          style={{ fill: '#D1D3D3' }}
          d="M151.595,108.435l-57.596,43.658c1.148,1.024,2.655,1.654,4.306,1.654h106.579
			c1.65,0,3.157-0.63,4.307-1.654L151.595,108.435z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M151.595,130.401L93.999,86.743c1.148-1.024,2.655-1.654,4.306-1.654h106.579
			c1.65,0,3.157,0.63,4.307,1.654L151.595,130.401z"
        />
      </g>
      <polygon
        style={{ fill: '#A4A9AD' }}
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
      />
      <g>
        <g>
          <path
            style={{ fill: '#A4A9AD' }}
            d="M115.983,264.77H88.435v-46.401h27.549v10.061h-15.013v7.299h13.902v10.061h-13.902v8.76h15.013
				L115.983,264.77L115.983,264.77z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M144.326,264.77l-9.49-33.42h-0.285c0.443,5.691,0.666,10.113,0.666,13.266v20.154h-11.108
				v-46.401h16.694l9.68,32.944h0.254l9.49-32.944h16.727v46.401h-11.521v-20.344c0-1.059,0.016-2.232,0.048-3.523
				c0.031-1.291,0.175-4.453,0.429-9.49h-0.286l-9.362,33.357H144.326z"
          />
          <path
            style={{ fill: '#A4A9AD' }}
            d="M186.95,264.77v-46.401h12.536v36.276h17.869v10.125H186.95z"
          />
        </g>
      </g>
      <polygon style={{ fill: '#D1D3D3' }} points="219.821,50.525 270.346,50.525 219.821,0 	" />
    </g>
  </svg>
);
