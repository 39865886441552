import {
  DefaultV3Error,
  DefaultV3Response,
  ManualMessageType,
  MessageType,
  SendMessagePayload,
} from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { stagesForRefetching } from '../../static/global';
import { queryKeys } from '../../static/queryKeys';
import { postCustomPath } from '../defaults';
import {
  checkAvailablePhoneNumbersV3,
  createEmail,
  createManualCommunicationV3,
  createMessageSnippetV3,
  deleteEmail,
  deleteManualCommunicationV3,
  deleteMessageSnippetV3,
  getCommunicationsForContact,
  getCommunicationsForContacts,
  getCommunicationsForFirmV3,
  getEmailsForCase,
  getMessageSnippetsV3,
  getProvisionedPhoneNumbersV3,
  provisionPhoneNumberV3,
  sendMessageV3,
  updateEmail,
  updateManualCommunicationV3,
  updateMessageSnippetV3,
} from '../requests/nuntius';
import { AxiosError } from 'axios';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const useGetCommunications = (contactId: string) => {
  return useQuery({
    queryKey: [queryKeys.communications, contactId],
    queryFn: async () => getCommunicationsForContact(contactId),
    refetchInterval: stagesForRefetching.includes(API_STAGE) ? 5000 : undefined,
    enabled: !!contactId,
  });
};

export const useGetFirmCommunications = () => {
  return useQuery({
    queryKey: [queryKeys.communications],
    queryFn: async () => getCommunicationsForFirmV3(),
  });
};

export const useGetCommunicationsForContacts = (contactIds: string[]) => {
  return useQuery({
    queryKey: [queryKeys.communications, contactIds],
    queryFn: async () => getCommunicationsForContacts(contactIds),
    enabled: !!contactIds,
  });
};

export const useGetEmailsForCase = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseEmails, caseId],
    queryFn: async () => getEmailsForCase(caseId),
    enabled: !!caseId,
  });
};

export const useUpdateEmail = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data }: { data: ManualMessageType }) => updateEmail(data),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.caseEmails, caseId]);
    },
  });
};

export const useCreateManualCommunication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ data, type }: { data: ManualMessageType; type: 'messages' | 'calls' }) =>
      createManualCommunicationV3(data, type),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.communications]);
    },
  });
};

export const useCreateEmail = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ManualMessageType) => createEmail(data, caseId),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.caseEmails, caseId]);
    },
  });
};

export const useUpdateManualCommunication = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      data,
      type,
    }: {
      data: ManualMessageType;
      type: 'messages' | 'calls' | 'emails' | 'unknown';
    }) => updateManualCommunicationV3(data, type),
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.communications]);
    },
  });
};

export const useDeleteManualCommunication = () => {
  const query = useQueryClient();

  return useMutation({
    mutationFn: async ({
      data,
      type,
    }: {
      data: ManualMessageType;
      type: 'messages' | 'calls' | 'emails' | 'unknown';
    }) => deleteManualCommunicationV3(data, type),
    onSettled: () => {
      query.invalidateQueries({
        queryKey: [queryKeys.communications],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'delete-communication-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteEmail = (caseId: string) => {
  const query = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => deleteEmail(id),
    onSettled: () => {
      query.invalidateQueries({
        queryKey: [queryKeys.caseEmails, caseId],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'delete-communication-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useSendMessage = (communicationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => sendMessageV3(data),
    onMutate: (variables: SendMessagePayload) => {
      const newMessage = {
        messageId: 'tempId',
        direction: 'outbound',
        body: variables.messageBody,
        fromNumber: variables.from,
        toNumber: variables.to,
        dateCreated: dayjs().toISOString(),
      };

      queryClient.setQueryData(
        [queryKeys.messages, communicationId],
        (oldData: DefaultV3Response<MessageType[]> | undefined) => {
          if (!oldData) {
            return {
              data: [newMessage],
              meta: {
                userMsg: '',
              },
            };
          }

          const optimisticData = oldData?.data.concat(newMessage) || [];

          return {
            data: optimisticData,
            meta: oldData?.meta ?? {
              userMsg: '',
            },
          };
        },
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.communications]);
      queryClient.invalidateQueries([queryKeys.messages, communicationId]);
    },
  });
};

export const useGetMessagingSnippets = () => {
  return useQuery({
    queryKey: ['messagingSnippets'],
    queryFn: async () => getMessageSnippetsV3(),
  });
};

export const useCreateMessagingSnippet = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ message }: Parameters<typeof createMessageSnippetV3>[0]) =>
      createMessageSnippetV3({ message }),
    onMutate: ({ message }) => {
      // Get the current data from the cache
      const currentData: any = queryClient.getQueryData(['messagingSnippets']);

      // Generate a temporary id for the new message
      const tempId = new Date().toISOString();

      // Create a new message object
      const newMessage = {
        id: tempId,
        message,
      };

      // Update the cache optimistically
      // TODO: Remove this and type oldData properly
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      queryClient.setQueryData(['messagingSnippets'], (oldData: any) => ({
        ...oldData,
        data: [...oldData.data, newMessage],
      }));

      // Return the previous data and the tempId for rollback
      return { previousData: currentData, tempId };
    },
    onSuccess: () => {
      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries(['messagingSnippets']);
    },
    onError: (error, variables, context) => {
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData(['messagingSnippets'], context.previousData);
      }
    },
  });
};

export const useDeleteMessagingSnippet = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ snippetId }: { snippetId: string }) => deleteMessageSnippetV3(snippetId),
    onMutate: ({ snippetId }) => {
      // Get the current data from the cache
      const currentData: any = queryClient.getQueryData(['messagingSnippets']);

      // Filter out the message that is being deleted
      const updatedSnippets = currentData.data.filter(
        (snippet: any) => snippet.snippetId !== snippetId,
      );

      // Update the cache optimistically
      // TODO: Remove this and type oldData properly
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      queryClient.setQueryData(['messagingSnippets'], (oldData: any) => ({
        ...oldData,
        data: updatedSnippets,
      }));

      // Return the previous data and the tempId for rollback
      return { previousData: currentData };
    },
    onSuccess: () => {
      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries(['messagingSnippets']);
    },
    onError: (error, variables, context) => {
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData(['messagingSnippets'], context.previousData);
      }
    },
  });
};

export const useUpdateMessagingSnippet = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ snippetId, message }: Parameters<typeof updateMessageSnippetV3>[0]) =>
      updateMessageSnippetV3({ snippetId, message }),
    onMutate: ({ snippetId, message }) => {
      // Get the current data from the cache
      const currentData: any = queryClient.getQueryData(['messagingSnippets']);

      // Filter out the message that is being deleted
      const updatedSnippets = currentData.data.map((snippet: any) => {
        if (snippet.snippetId === snippetId) {
          // TODO: Remove this and type return properly
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return {
            ...snippet,
            message,
          };
        }
        // TODO: Remove this and type return properly
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return snippet;
      });

      // Update the cache optimistically
      // TODO: Remove this and type oldData properly
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      queryClient.setQueryData(['messagingSnippets'], (oldData: any) => ({
        ...oldData,
        data: updatedSnippets,
      }));

      // Return the previous data and the tempId for rollback
      return { previousData: currentData };
    },
    onSuccess: () => {
      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries(['messagingSnippets']);
    },
    onError: (error, variables, context) => {
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData(['messagingSnippets'], context.previousData);
      }
    },
  });
};

export const useCheckAvailablePhoneNumbers = (areaCode: string) => {
  return useQuery({
    queryKey: [queryKeys.availablePhoneNumbers, areaCode],
    queryFn: async () => checkAvailablePhoneNumbersV3(areaCode),
    enabled: !!areaCode,
  });
};

export const useProvisionPhoneNumber = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof provisionPhoneNumberV3>[0]) =>
      provisionPhoneNumberV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.provisionedPhoneNumbers],
      });
    },
  });
};

export const useGetProvisionedPhoneNumbers = () => {
  return useQuery({
    queryKey: [queryKeys.provisionedPhoneNumbers],
    queryFn: async () => getProvisionedPhoneNumbersV3(),
  });
};

// -----------------------------------------------------------------------------------------
// -------------------------- EVERYTHING BELOW HERE IS DEPRECATED --------------------------
// -----------------------------------------------------------------------------------------
/* eslint-disable */
/**
 * @deprecated The method should not be used going forward but is still used in the app.
 */
export const useGetNumbersForAreaCode = () => {
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults(['available-numbers'], {
    mutationFn: (data) => postCustomPath(`/nuntius/v1/availableNumbers`, data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
    onSettled: (data, error, variables, context) => {
      // do nothing
    },
  });
  return useMutation(['available-numbers']);
};
