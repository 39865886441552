export const tanstackTableNames = {
  adobeAdminSettings: 'adobeAdminSettings',
  attorneysFees: 'attorneysFees',
  caseAudience: 'caseAudience',
  caseCampaigns: 'case-campaigns',
  caseCampaignSubscription: 'case-campaign-subscriptions',
  caseContacts: 'case-contacts-table',
  cases: 'cases',
  caseStatuses: 'case-statuses',
  caseTypes: 'case-types',
  contactCampaigns: 'contact-campaigns',
  contactCampaignSubscription: 'contact-campaign-subscriptions',
  contactConnections: 'contact-connections',
  contacts: 'contacts',
  discovery: 'discovery',
  expenseCategories: 'expenseCategories',
  expenses: 'caseExpenses',
  eventCategories: 'eventCategories',
  leads: 'leads',
  liens: 'liens',
  lineupExpenses: 'lineup-expenses',
  lineupRecordRequestTasks: 'lineup-record-request-tasks',
  litigationPlanAction: 'litigation-plan-action',
  medicalProviders: 'medicalProviders',
  medicalTreatments: 'medical-treatments',
  medicalTreatmentTypes: 'medicalTreatmentTypes',
  priorMedicalTreatments: 'prior-medical-treatments',
  providerAppointments: 'provider-appointments',
  providerBills: 'provider-bills',
  providerRecordRequests: 'provider-record-requests',
  recordRequests: 'record-requests',
  scenarioParties: 'scenario-parties-table',
  scenarioPolicies: 'scenario-policies-table',
  scenarioVehicles: 'scenario-vehicles-table',
  settlements: 'settlements',
  staffRoles: 'staff-roles',
  templates: 'templates',
  timeTrackingCategories: 'timeTrackingCategories',
  trustAccounting: 'trustAccountTransactions',
  utbmsCodes: 'UTBMSCodes',
  teams: 'teams',
} as const;
