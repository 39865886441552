import { VITE_API_STAGE, stagesForRefetching } from '@colosseum/data';
import { SlideoverProvider } from '@colosseum/shared-ui';
import { Providers as MicrosoftGraphProviders } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router';

const API_STAGE = VITE_API_STAGE;

const HOUR = 1000 * 60 * 60;

const MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID;

if (!MicrosoftGraphProviders.globalProvider) {
  MicrosoftGraphProviders.globalProvider = new Msal2Provider({
    clientId: MICROSOFT_CLIENT_ID,
    isIncrementalConsentDisabled: true,
    scopes: [
      'openid',
      'profile',
      'files.read',
      'files.read.all',
      'files.readwrite',
      'files.readwrite.all',
      'user.read',
      'people.read',
      'sites.read.all',
      'sites.readWrite.all',
      'group.read.all',
      'group.readwrite.all',
    ],
    isMultiAccountEnabled: false,
  });
}

export function App() {
  //React Query Client
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              const errorRes = error as AxiosError;
              if (errorRes.response?.status === 404)
                return false; // we don't want to retry on 404s, means resource is not there
              else if (failureCount < 2) return true;
              else return false;
            },
            enabled: true,
            refetchOnWindowFocus: stagesForRefetching.includes(API_STAGE),
            //staletime:  1hr for pre/prod/demo,  12 hours for others
            staleTime: stagesForRefetching.includes(API_STAGE) ? HOUR : HOUR * 12,
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <SlideoverProvider>
        <SnackbarProvider />
        <HelmetProvider>
          <Outlet />
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </SlideoverProvider>
    </QueryClientProvider>
  );
}

export default App;
