import Auth from '@aws-amplify/auth';
import {
  cn,
  displayContactName,
  getNameToDisplayFromCognito,
  menuNavigation,
  scrollToCaseSection,
  useGetCase,
  useGetContact,
  useGetCurrentUser,
  useGetFirmDetails,
  usePathParts,
  usePermissions,
} from '@colosseum/data';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import FullLogo from '../FullLogo/FullLogo';
import IconLogo from '../IconLogo/IconLogo';
import { Button } from '../shadcn/Button/Button';

const navMenuItemClasses = 'group flex items-center px-2 py-0.5 text-sm font-medium rounded-md';
/* eslint-disable-next-line */
export interface MenuProps {
  children?: React.ReactNode;
}

type SubMenuItems = Element & {
  dataset: {
    caseSubmenuItem?: string;
    settingsSubmenuItem?: string;
    automationsSubmenuItem?: string;
    contactSubmenuItem?: string;
  };
};

const checkAuth = async () => {
  // check if token is expired
  const token = localStorage.getItem('idToken');
  if (token) {
    const decoded: any = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      await Auth.currentSession()
        .then((data: any) => {
          localStorage.setItem('accessToken', data.accessToken.jwtToken);
          localStorage.setItem('refreshToken', data.refreshToken.token);
          localStorage.setItem('idToken', data.idToken.jwtToken);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          window.location.reload();
        });
    }
  }
};

const UserInformationArea = ({
  displayName,
  firmName,
}: {
  displayName: string;
  firmName: string;
}) => {
  const queryClient = useQueryClient();

  function signOut() {
    try {
      Auth.signOut().then((res) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        queryClient.invalidateQueries();
        window.location.href = '/login';
      });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }

  return (
    <div className="flex flex-col w-full p-4 px-4 mx-auto">
      <NavLink to="/profile">
        <Button
          variant="ghost"
          className="flex items-start justify-start w-full h-auto px-0 py-2 mb-3 group"
        >
          <div>
            <UserCircleIcon className="mx-auto w-14 h-14" />
          </div>
          <div className="self-center ml-2 text-left">
            <p className="text-sm font-semibold text-gray-800 group-hover:text-gray-900">
              {displayName}
            </p>

            <p className="text-sm font-medium text-gray-600">{firmName ? firmName : ''}</p>
          </div>
        </Button>
      </NavLink>

      <Button variant="secondary" onClick={signOut} className="mb-5">
        Sign Out
      </Button>
    </div>
  );
};

export function Menu(props: MenuProps) {
  const { children } = props;
  checkAuth();

  const { hasPageAccess } = usePermissions();

  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const firmProfileQuery = useGetFirmDetails();
  const firmProfile = firmProfileQuery.data?.data;
  const firmName = firmProfile?.Description;

  const currentUserQuery = useGetCurrentUser();
  const firmUser = currentUserQuery.data?.data;
  const role = firmUser?.groupName?.toLowerCase();
  const usersFeatureAccess = firmUser?.['custom:featureAccess']?.toLowerCase();

  const displayName = getNameToDisplayFromCognito(firmUser) ?? '-';

  //set active menu item and set all others to false
  const location = useLocation();
  const { resource, resourceId, prevPathParts } = usePathParts();

  const caseId: string | undefined = resource === 'cases' && resourceId ? resourceId : undefined;
  const contactId: string | undefined =
    resource === 'contacts' && resourceId ? resourceId : undefined;

  const [caseSubMenuItems, setCaseSubMenuItems] = useState<NodeListOf<SubMenuItems>>(
    document.querySelectorAll<SubMenuItems>('[data-case-submenu-item]'),
  );
  const [settingsSubMenuItems, setSettingsSubMenuItems] = useState<NodeListOf<SubMenuItems>>(
    document.querySelectorAll<SubMenuItems>('[data-settings-submenu-item]'),
  );
  const [automationsSubMenuItems, setAutomationsSubMenuItems] = useState<NodeListOf<SubMenuItems>>(
    document.querySelectorAll<SubMenuItems>('[data-automations-submenu-item]'),
  );
  const [contactSubmenuItem, setContactSubmenuItem] = useState<NodeListOf<SubMenuItems>>(
    document.querySelectorAll<SubMenuItems>('[data-contact-submenu-item]'),
  );

  const { data: caseData, isLoading: isCaseLoading } = useGetCase(caseId);
  const { data: contactData, isLoading: isContactLoading } = useGetContact(contactId);

  const contactName = displayContactName(contactData?.data);

  useEffect(() => {
    if (caseId) {
      const submenuItems = document.querySelectorAll<SubMenuItems>('[data-case-submenu-item]');
      setCaseSubMenuItems(submenuItems);
    }
  }, [caseId, caseData?.data?.caseTitle, isCaseLoading]);

  useEffect(() => {
    if (contactId) {
      const submenuItems = document.querySelectorAll<SubMenuItems>('[data-contact-submenu-item]');
      setContactSubmenuItem(submenuItems);
    }
  }, [contactId, contactName, isContactLoading]);

  useEffect(() => {
    if (resource === 'settings' && prevPathParts.resource !== 'settings') {
      setTimeout(() => {
        const submenuItems = document.querySelectorAll<SubMenuItems>(
          '[data-settings-submenu-item]',
        );
        setSettingsSubMenuItems(submenuItems);
      }, 200); // this needs a bit of time to let pages render
    } else if (resource === 'automations' && prevPathParts.resource !== 'automations') {
      const submenuItems = document.querySelectorAll<SubMenuItems>(
        '[data-automations-submenu-item]',
      );
      setAutomationsSubMenuItems(submenuItems);
    }
  }, [resource, prevPathParts]);

  const navLinks = useMemo(
    () =>
      menuNavigation
        .map((item) => {
          const itemCopy = { ...item };
          // Use hasAccess or hasPageAccess based on whether it's a service or a page
          const canAccess = item.pageMapKey ? hasPageAccess(item.pageMapKey) : true;

          if (location.pathname.includes(item.href.split('/')[1])) {
            itemCopy.current = true;
          } else {
            itemCopy.current = false;
          }

          // Only include items that the user has access to
          return canAccess ? itemCopy : null;
        })
        .filter(Boolean), // Filter out null items
    [location.pathname, caseData],
  );

  const hasFeatureAccess = useCallback(
    (itemFeatureAccess?: string[]) => {
      if (itemFeatureAccess) {
        if (usersFeatureAccess) {
          return itemFeatureAccess.includes(usersFeatureAccess);
        } else {
          return false;
        }
      }
      return true;
    },
    [usersFeatureAccess],
  );

  // pages with submenu titles
  const relevantSubMenuPaths = ['cases', 'contacts'];
  const firstSubMenuItemIsTitle = useMemo(() => {
    return relevantSubMenuPaths.some((path) => location.pathname.includes(path));
  }, [relevantSubMenuPaths, location.pathname]);

  const renderSubmenu = (
    submenuItems: typeof caseSubMenuItems,
    datasetKey:
      | 'caseSubmenuItem'
      | 'settingsSubmenuItem'
      | 'automationsSubmenuItem'
      | 'contactSubmenuItem',
  ) => {
    const firstItemIsTitleClasses =
      'first:border-none first:hover:bg-inherit first:text-sm first:cursor-default first:text-black first:ml-3 first:mt-2';

    return (
      <div>
        {Array.from(submenuItems).map((item) => {
          return (
            <NavLink
              key={item.dataset[datasetKey]}
              onClick={() => scrollToCaseSection(item)}
              to="#"
              className={cn(
                'flex items-center px-2 py-1 ml-8 text-xs text-gray-500 border-l border-gray-300 group hover:bg-gray-50 hover:text-gray-900',
                firstSubMenuItemIsTitle && firstItemIsTitleClasses,
              )}
            >
              {item.dataset[datasetKey]}
            </NavLink>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {menuCollapsed ? (
        <div className="hidden w-20 md:block group ">
          <nav className="fixed z-30 h-full p-3">
            <div className="hidden w-56 h-full bg-white border shadow-sm rounded-3xl group-hover:flex animate__animated animate__slideInLeft animate__faster">
              <button
                onClick={() => {
                  setMenuCollapsed(false);
                }}
                className="absolute text-gray-600 rounded-md hover:bg-gray-100 right-4 top-2"
              >
                <ChevronDoubleRightIcon className="w-6 h-6" />
              </button>
              <div className="flex flex-col justify-between flex-1 min-h-0 bg-white rounded-2xl">
                <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <FullLogo styles="w-auto h-10" />
                  </div>
                  <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
                    {navLinks.map((item) => {
                      if (!item) return null;

                      if (hasFeatureAccess(item.featureAccess)) {
                        return (
                          <NavLink
                            to={item.href}
                            key={item.name}
                            className={cn(
                              item.current
                                ? 'bg-black text-white'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              navMenuItemClasses,
                            )}
                          >
                            <item.icon
                              className={cn(
                                item.current
                                  ? 'text-white'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </NavLink>
                        );
                      }
                      return null;
                    })}
                  </nav>
                </div>

                {/* USER INFORMATION AREA DESKTOP */}
                <UserInformationArea displayName={displayName} firmName={firmName ?? ''} />
              </div>
            </div>

            {/* mini menu */}
            <div className="z-50 flex w-20 h-full pt-2 bg-white border shadow-sm rounded-3xl group-hover:hidden animate__animated animate__fadeIn animate__faster">
              <div className="flex flex-col justify-between flex-1 h-full px-4 pt-3 pb-4 overflow-y-auto">
                <div>
                  <IconLogo styles="w-10 h-10 mx-auto p-1 mb-5" />
                  {navLinks.map((item) => {
                    if (!item) return null;

                    if (!item.access || (role && item.access.includes(role))) {
                      return (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={cn(
                            item.current
                              ? 'bg-black text-white'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex justify-center  items-center px-2 py-2 text-sm font-medium rounded-md',
                          )}
                        >
                          <item.icon
                            className={cn(
                              item.current
                                ? 'text-white'
                                : 'text-gray-400 group-hover:text-gray-500',
                              ' flex-shrink-0 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                        </NavLink>
                      );
                    }
                    return null;
                  })}
                </div>
                <div className="flex flex-col items-center">
                  <UserCircleIcon className="mb-3 w-14 h-14" />
                  <button className="w-full py-2 pl-3 pr-5 mb-5 text-sm font-semibold rounded-lg cursor-pointer bg-light-blue hover:bg-atlantic-blue hover:text-white">
                    <ArrowLeftOnRectangleIcon className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      ) : (
        <div className="hidden py-3 pl-3 lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-1 min-h-0 bg-white border shadow-sm rounded-2xl ">
            <button
              onClick={() => {
                setMenuCollapsed(true);
              }}
              className="absolute z-50 text-gray-600 rounded-md hover:bg-gray-100 right-4 top-5"
            >
              <ChevronDoubleLeftIcon className="w-6 h-6" />
            </button>
            <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <FullLogo styles="w-auto h-10" />
              </div>
              <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
                {navLinks.map((item) => {
                  if (!item) return null;
                  if (hasFeatureAccess(item.featureAccess)) {
                    return (
                      <Fragment key={item.name}>
                        <NavLink
                          to={item.href}
                          className={cn(
                            item.current
                              ? 'bg-black text-white'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            navMenuItemClasses,
                          )}
                        >
                          <item.icon
                            className={cn(
                              item.current
                                ? 'text-white'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                        {resource === 'cases' &&
                          item.current &&
                          caseId &&
                          renderSubmenu(caseSubMenuItems, 'caseSubmenuItem')}
                        {resource === 'settings' &&
                          item.current &&
                          renderSubmenu(settingsSubMenuItems, 'settingsSubmenuItem')}
                        {resource === 'automations' &&
                          item.current &&
                          renderSubmenu(automationsSubMenuItems, 'automationsSubmenuItem')}
                        {resource === 'contacts' &&
                          item.current &&
                          contactId &&
                          renderSubmenu(contactSubmenuItem, 'contactSubmenuItem')}
                      </Fragment>
                    );
                  }
                  return null;
                })}
              </nav>
            </div>

            {/* USER INFORMATION AREA DESKTOP */}
            <UserInformationArea displayName={displayName} firmName={firmName ?? ''} />
          </div>
        </div>
      )}

      <div className={cn('flex flex-col flex-1 ', menuCollapsed ? 'md:pl-24' : 'lg:pl-64')}>
        <div
          className={cn(
            'sticky top-0 z-10 pt-1 pl-1 bg-gray-100 sm:pl-3 sm:pt-3',
            menuCollapsed ? 'md:hidden' : 'lg:hidden',
          )}
        >
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-blue"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            <div className={cn('px-4 mx-auto max-w-12xl sm:px-6', menuCollapsed ? '' : 'lg:px-8')}>
              {children ? children : <Outlet />}
              {/* <Outlet /> */}
            </div>
          </div>
        </main>
      </div>

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <FullLogo styles="w-auto h-10" />
                  </div>
                  <nav className="px-2 mt-5 space-y-1">
                    {navLinks.map((item) => {
                      if (!item) return null;
                      if (!item.access || (item.access && item.access.includes(role ?? ''))) {
                        return (
                          <NavLink
                            onClick={() => setSidebarOpen(false)}
                            key={item.href + 'full'}
                            to={item.href}
                            className={cn(
                              item.current
                                ? 'bg-black text-white'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                            )}
                          >
                            <item.icon
                              className={cn(
                                item.current
                                  ? 'text-white'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-4 flex-shrink-0 h-6 w-6',
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </NavLink>
                        );
                      }
                      return null;
                    })}
                  </nav>
                </div>
                {/* USER INFORMATION AREA MOBILE */}
                <UserInformationArea displayName={displayName} firmName={firmName ?? ''} />
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Menu;
