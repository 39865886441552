import { AdobeUserType, OneClickSignSettingType } from '@gladiate/types';
import { useForm } from 'react-hook-form';
import { Form } from '../../shadcn/Form/Form';
import TextFormInput from '../TextFormInput/TextFormInput';
import {
  formatPhoneNumber,
  useGetAdobeUserListV3,
  useGetProvisionedPhoneNumbers,
  useGetTemplates,
  useUpdateOneClickSignSetting,
  useCreateOneClickSignSetting,
} from '@colosseum/data';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import GladiateLoader from '../../GladiateLoader/GladiateLoader';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction } from 'react';

export const oneClickSignSettingsFormId = 'some-form-id';

export interface OneClickSignSettingsFormProps {
  selectedOneClickSignSetting?: OneClickSignSettingType;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

export function OneClickSignSettingsForm(props: OneClickSignSettingsFormProps) {
  const { selectedOneClickSignSetting, setOpen } = props;

  const updateOneClickSign = useUpdateOneClickSignSetting();
  const createOneClickSignSetting = useCreateOneClickSignSetting();

  const { data: templateData, isLoading: isTemplateLoading } = useGetTemplates();
  const { data: phoneNumbersData, isLoading: isPhoneNumbersLoading } =
    useGetProvisionedPhoneNumbers();
  const { data: adobeUsersData, isLoading: isAdobeUsersLoading } = useGetAdobeUserListV3();

  const formSchema = z.object({
    title: z.string().min(1, 'Required'),
    description: z.string().optional(),
    templateId: z.string().min(1),
    senderSigningAttorneyEmail: z.string().min(1),
    senderSigningAttorneyName: z.string(), // assigned when email selected
    provisionedPhoneNumberId: z.string().min(1),
  });

  const form = useForm({
    mode: 'onSubmit',
    values: selectedOneClickSignSetting,
    resolver: zodResolver(formSchema),
  });

  const senderOptions: { [key: string]: string } =
    (adobeUsersData?.data &&
      adobeUsersData.data
        .filter((item) => item.signerPrivelage)
        ?.map((user: AdobeUserType) => {
          return {
            email: user.adobeUser.email,
            firstName: user.adobeUser.firstName,
            lastName: user.adobeUser.lastName,
          };
        })
        .reduce((prev, current: { email: string; firstName: string; lastName: string }) => {
          const { email, firstName, lastName } = current;
          return { ...prev, [`${firstName} ${lastName} - ${email}`]: email };
        }, {})) ||
    {};

  const templateOptions: { [key: string]: string } =
    (templateData?.data &&
      templateData?.data
        .filter((template) => template.title)
        ?.reduce((next, template) => {
          const { templateId, title } = template;
          return { ...next, [title ?? '']: templateId };
        }, {})) ||
    {};

  const phoneNumberOptions: { [key: string]: string } =
    (phoneNumbersData?.data &&
      phoneNumbersData?.data
        ?.filter((phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication')
        .reduce((acc, curr) => {
          return {
            ...acc,
            [formatPhoneNumber(curr.phoneNumber)]: curr.provisionedPhoneNumberId,
          };
        }, {})) ||
    {};

  const onSubmit = (values: OneClickSignSettingType) => {
    if (selectedOneClickSignSetting?.oneClickSignSettingId) {
      updateOneClickSign.mutate({
        ...values,
        oneClickSignSettingId: selectedOneClickSignSetting.oneClickSignSettingId,
      });
    } else {
      createOneClickSignSetting.mutateAsync({ ...values });
    }
    setOpen?.(false);
  };

  return (
    <div>
      {isTemplateLoading || isPhoneNumbersLoading || isAdobeUsersLoading ? (
        <GladiateLoader />
      ) : (
        <Form {...form}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              form.handleSubmit(onSubmit)(event);
            }}
            id={oneClickSignSettingsFormId}
          >
            <div className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
              <div className="col-span-2">
                <TextFormInput
                  {...form.register('title')}
                  title="Title"
                  placeholderText="Enter the title ..."
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLTextAreaElement;
                    form.setValue('title', target.value);
                  }}
                />
              </div>
              <div className="col-span-2">
                <TextFormInput
                  {...form.register('description')}
                  title="Description"
                  placeholderText="Enter the description ..."
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLTextAreaElement;
                    form.setValue('description', target.value);
                  }}
                />
              </div>
              <div className="col-span-2">
                <SelectFormInput
                  {...form.register('templateId')}
                  title="Template"
                  defaultValue={selectedOneClickSignSetting?.templateId}
                  listItems={templateOptions || {}}
                  listItemsIsObject
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLTextAreaElement;
                    form.setValue('templateId', target.value);
                  }}
                />
              </div>
              <div className="col-span-2">
                <SelectFormInput
                  {...form.register('senderSigningAttorneyEmail')}
                  title="Sender"
                  listItemsIsObject
                  defaultValue={selectedOneClickSignSetting?.senderSigningAttorneyEmail}
                  listItems={senderOptions || {}}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLTextAreaElement;
                    form.setValue('senderSigningAttorneyEmail', target.value);
                    const user = adobeUsersData?.data.find(
                      (item) => item.adobeEmail === target.value,
                    );
                    if (user) {
                      form.setValue(
                        'senderSigningAttorneyName',
                        `${user.adobeUser.firstName} ${user.adobeUser.lastName}`,
                      );
                    }
                  }}
                />
              </div>
              <div className="col-span-2">
                <SelectFormInput
                  {...form.register('provisionedPhoneNumberId')}
                  title="Phone Number"
                  defaultValue={
                    selectedOneClickSignSetting?.provisionedPhoneNumberId
                      ? formatPhoneNumber(selectedOneClickSignSetting.provisionedPhoneNumberId)
                      : undefined
                  }
                  listItemsIsObject
                  listItems={phoneNumberOptions}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLTextAreaElement;
                    form.setValue('provisionedPhoneNumberId', target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </Form>
      )}
    </div>
  );
}

export default OneClickSignSettingsForm;
