import { registerChangeEvents } from '@colosseum/data';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../shadcn/Form/Form';

import { Input } from '../shadcn/Input/Input';
/* eslint-disable-next-line */
export interface SsnInputProps {
  formHandleBlur: any;
  name: string;
  title: string;
}

// React.forwardRef<
// HTMLInputElement,
// TextFormInputProps
// >((props, ref) => {

export const SsnInput = React.forwardRef<HTMLInputElement, SsnInputProps>((props, ref) => {
  const form = useFormContext();
  const { formHandleBlur, name, title } = props;
  const [showSSN, setShowSSN] = useState(false);
  const [unformattedValue, setUnformattedValue] = useState('');

  const handleOnBlur = () => {
    formHandleBlur({
      target: { name: name, value: unformattedValue },
    });

    setShowSSN(false);
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{title}</FormLabel>
          <FormControl>
            <PatternFormat
              format="###-##-####"
              mask="_"
              customInput={Input}
              onFocus={() => setShowSSN(true)}
              type={showSSN ? 'text' : 'password'}
              data-cy="text-input-ssn"
              placeholder="___-__-____"
              onValueChange={({ value }) => {
                setUnformattedValue(value);
                field.onChange(value);
              }}
              {...registerChangeEvents({ field, form, handleOnBlur })}
              {...field}
              ref={ref}
            />
          </FormControl>

          <FormMessage />
        </FormItem>
      )}
    />
  );
});

SsnInput.displayName = 'SsnInput';

export default SsnInput;
