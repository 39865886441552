import { cn, displayContactName, formatPrice, prettifyDateString } from '@colosseum/data';
import {
  DataTableColumnHeader,
  renderCell,
  renderCellWithChips,
  renderHeader,
} from '@colosseum/shared-ui';
import {
  ContactType,
  SettlementProposalType,
  settlementCategoryOptions,
  settlementCoverageTypeOptions,
  settlementPolicyTypeOptions,
  settlementStatusOptions,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const columns: ColumnDef<
  SettlementProposalType & {
    insurer?: ContactType;
    adjuster?: ContactType;
    client?: ContactType;
  }
>[] = [
  {
    accessorFn: (row) => displayContactName(row.client),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      const contactName = displayContactName(row.insurer);
      const policyType = startCase(row.policyType);
      const coverageType = row.coverageType;
      return [contactName, policyType, coverageType];
    },
    id: 'Insurer / Policy / Coverage',
    enableHiding: false,
    sortUndefined: 1,
    header: (props) => (
      <span className="whitespace-nowrap">
        <DataTableColumnHeader column={props.column} title={props.column.id} />
      </span>
    ),
    cell: (props) => {
      const [contactName, policyType, coverageType] = props.getValue<string[]>();
      const textClasses = 'font-semibold';
      return (
        <div>
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Insurer>'}
          </span>
          {' / '}
          <span className={cn(textClasses, !policyType && 'text-red-500')}>
            {policyType || '<No Policy Type>'}
          </span>
          {' / '}
          <span className={cn(textClasses, !coverageType && 'text-red-500')}>
            {(
              Object.keys(
                settlementCoverageTypeOptions,
              ) as (keyof typeof settlementCoverageTypeOptions)[]
            ).find((key) => settlementCoverageTypeOptions[key] === coverageType) ||
              '<No Coverage Type>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateIssued),
    id: 'Date Issued',
    sortingFn: 'dayjsDateTime',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.expirationDate),
    id: 'Expiration Date',
    sortingFn: 'dayjsDateTime',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      (Object.keys(settlementCategoryOptions) as (keyof typeof settlementCategoryOptions)[]).find(
        (key) => settlementCategoryOptions[key] === row.type,
      ),
    id: 'Offer/Demand',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      (Object.keys(settlementStatusOptions) as (keyof typeof settlementStatusOptions)[]).find(
        (key) => settlementStatusOptions[key] === row.proposalStatus,
      ),
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: (columnId, values) => {
      return values.some((v) => {
        const status = v.getValue<string>(columnId);
        return status === 'accepted';
      });
    },
    aggregatedCell: (props) => {
      const value = props.getValue();
      return value ? 'Accepted' : '';
    },
  },
  {
    accessorFn: (row) =>
      row.proposalStatus === 'acceptedWithContingencies'
        ? row?.proposalContingencies?.join(', ')
        : '',
    id: 'Contingencies',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) =>
      renderCellWithChips({
        props,
      }),
  },
  {
    accessorFn: (row) =>
      (
        Object.keys(settlementCoverageTypeOptions) as (keyof typeof settlementCoverageTypeOptions)[]
      ).find((key) => settlementCoverageTypeOptions[key] === row.coverageType),
    id: 'Coverage Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      (
        Object.keys(settlementPolicyTypeOptions) as (keyof typeof settlementPolicyTypeOptions)[]
      ).find((key) => settlementPolicyTypeOptions[key] === row.policyType),
    id: 'Policy Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.insurer),
    id: 'Insurer',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.adjuster),
    id: 'Adjuster',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.amount,
    id: 'Amount',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregatedCell: (props) => '',
  },
  {
    accessorFn: (row) => (row.policyLimits === '1' ? 'Yes' : 'No'),
    id: 'Policy Limits',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: startCase }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
