export const contactsSectionMappings = {
  biographical: 'Details',
  contactConnections: 'Contact Connections',
  numbers: 'Numbers',
  emails: 'Emails',
  addresses: 'Addresses',
  employmentHistory: 'Employment History',
  webAddresses: 'Web Addresses',
  caseContactConnections: 'Case(s) associated with contact',
  communication: 'Communication',
  customAttributes: 'Custom Attributes',
  notes: 'Notes',
  tags: 'Tags',
  summary: 'Summary',
} as const;
