import {
  CaseContactConnectionRoleType,
  ContactType,
  PolicyType,
  caseContactConnectionOptions,
  caseContactConnectionPriorityOrder,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { uniq } from 'lodash';
import { useGetRoleIdForItem } from '../hooks/useGetBillingEntityId';
import { useGetContactsInfinite } from '../lib/react-query/contact';
import { roleColorMap } from '../static/chipColorMap';
import { sortValuesByOrder } from './miscUtils';

export const useGetPoliciesWithInsurer = (caseId: string, policies?: PolicyType[]) => {
  const { data: allContactsData } = useGetContactsInfinite();
  const getRoleIdForItem = useGetRoleIdForItem<PolicyType>(caseId);
  if (!policies) {
    return [];
  }
  return policies
    .map((policy) => {
      if (!policy) {
        return;
      }
      const insurerId = getRoleIdForItem(policy, caseContactConnectionOptions.insurer, 'policyId');

      const insurer = allContactsData?.data?.find((contact) => contact.contactId === insurerId);
      return { ...policy, insurer };
    })
    .filter((policy) => policy);
};

export const getChipsForParty = (roles: CaseContactConnectionRoleType[]) => {
  return sortValuesByOrder(
    uniq(roles?.map((role) => role.roleOnCase ?? '')).filter(
      (role) =>
        !!role && role !== partiesAndPoliciesConnectionOptions.party && !role.includes('refer'),
    ) ?? [],
    caseContactConnectionPriorityOrder,
  ).map((chip) => ({
    title: chip,
    color: roleColorMap[chip],
  }));
};

export const getCaseContactConnectionRoleIdForParty = (
  party?: ContactType & { roles: CaseContactConnectionRoleType[] },
) => {
  if (!party) return;
  return party.roles?.find((role) => role.roleOnCase === partiesAndPoliciesConnectionOptions.party)
    ?.caseContactConnectionRoleId;
};
