import {
  ContactType,
  MedicalTreatmentBill,
  RecordRequest,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { useCallback } from 'react';
import { useGetContactsInfinite } from '../lib/react-query/contact';
import { useGetRecordRequestsForCase } from '../lib/react-query/medical-record-request';
import { useGetMedicalTreatmentTypes } from '../lib/react-query/medical-treatment';
import { getAggregateValues, getCalculatedFigures } from '../utils/medicalTreatmentUtils';
import { useGetRoleIdForItem } from './useGetBillingEntityId';

export interface CompleteMedicalTreatmentType extends MedicalTreatmentBill {
  billingEntity: ContactType;
  inferredPresentBalance?: number;
  inferredUnpaidBalance?: number;
  medicalTreatmentTypeName: string;
  netBillAfterAdjustments: number;
  medicalProvider: ContactType;
  paidPlusOwed: number;
  recordRequests: RecordRequest[];
  reductionAmount?: number;
  reductionPercentage?: number;
  totalPayments: number;
  unpaidBalance?: number;
}

export interface CompleteMedicalTreatmentsType {
  aggregateAdjustments: number;
  aggregateAmountBilled: number;
  aggregateNetBillAfterAdjustments: number;
  aggregatePaidPlusOwed: number;
  aggregatePresentBalance: number;
  aggregateReductions: number;
  aggregateReductionsPercent: number;
  aggregateTotalPayments: number;
  aggregateUnpaidBalance: number;
  completeTreatments: CompleteMedicalTreatmentType[];
}

export function useCompleteMedicalTreatmentValues(caseId: string) {
  const getRoleIdForItem = useGetRoleIdForItem<MedicalTreatmentBill>(caseId);

  const { data: allContactsData } = useGetContactsInfinite();

  const { data: medicalTreatmentTypeData } = useGetMedicalTreatmentTypes();

  const recordRequestQuery = useGetRecordRequestsForCase(caseId);

  const recordRequestData = recordRequestQuery?.data?.data;

  const getTreatmentTypeTitleFromId = useCallback(
    (id: string) => {
      return medicalTreatmentTypeData?.data?.find((type) => type.medicalTreatmentTypeId === id)
        ?.title;
    },
    [medicalTreatmentTypeData],
  );

  const getCompleteMedicalTreatmentValues = (treatments: MedicalTreatmentBill[]) => {
    const completeTreatments = treatments.map((treatment) => {
      return getCompleteMedicalTreatmentValue(treatment);
    });
    const aggregateValues = getAggregateValues(treatments);

    return {
      ...aggregateValues,
      completeTreatments: completeTreatments,
    } as CompleteMedicalTreatmentsType;
  };

  const getCompleteMedicalTreatmentValue = (treatment: MedicalTreatmentBill) => {
    const billingEntityId = getRoleIdForItem(
      treatment,
      caseContactConnectionOptions.billingEntity,
      'medicalTreatmentId',
    );
    const medicalProviderId = getRoleIdForItem(
      treatment,
      caseContactConnectionOptions.medicalProvider,
      'medicalTreatmentId',
    );
    const billingEntity = allContactsData?.data?.find(
      (contact) => contact.contactId === billingEntityId,
    );
    const medicalProvider = allContactsData?.data?.find(
      (contact) => contact.contactId === medicalProviderId,
    );
    const medicalTreatmentTypeName = getTreatmentTypeTitleFromId(treatment?.medicalTreatmentTypeId);
    const recordRequests = recordRequestData?.filter((recordRequest) => {
      return recordRequest.recordsProviderContactId === billingEntityId;
    });

    const treatmentWithCalculatedValues = getCalculatedFigures(treatment);

    return {
      ...treatmentWithCalculatedValues,
      billingEntity,
      medicalProvider,
      medicalTreatmentTypeName,
      recordRequests,
    } as CompleteMedicalTreatmentType;
  };

  return {
    getCompleteMedicalTreatmentValue,
    getCompleteMedicalTreatmentValues,
  };
}
