import {
  SurveyType,
  contactTypeOptions,
  contactTypeOptionsOld,
  displayContactName,
  formatContactChipDetails,
  makeAcronym,
  prettifyDateString,
  timeAgo,
  useDeleteCaseContactConnectionRole,
  useGetCaseConflictsForCase,
  useGetListOfContactsByIDs,
} from '@colosseum/data';
import {
  Button,
  CaseConflictIcon,
  ContactChipDetails,
  ContactInfoForm,
  ContactInfoSlideover,
  CustomErrorBoundary,
  Messenger,
  ResourceSlideover,
  TabsContent,
  TextSkeleton,
  Typography,
} from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  CaseType,
  CommunicationType,
  ContactViewModelType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@sentry/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import ContactsSelect from '../ContactsSelect/ContactsSelect';

/* eslint-disable-next-line */
export interface CaseClientCardProps {
  caseId: string;
  linkId?: string;
  caseData?: CaseType;
  caseClientConnection: Pick<CaseContactConnectionType, 'roles' | 'caseContactConnectionId'>;
  contact?: ContactViewModelType;
  surveyData?: SurveyType;
  messagingDates: {
    lastResponse: string;
    lastOutbound: string;
  };
  linkedCases?: { id: string; name?: string }[];
  isCaseLoading: boolean;
  isCaseContactConnectionsLoading: boolean;
  isContactLoading: boolean;
  isContactFetching?: boolean;
  isMessagingDatesLoading: boolean;
  communicationsData?: CommunicationType;
  onDeleteCallback?: (state: boolean) => void;
  handleAddClient: {
    mutation: (role: CaseContactConnectionRoleType['roleOnCase'], contactId: string) => void;
    isLoading: boolean;
  };
}

export function CaseClientCard(props: CaseClientCardProps) {
  const {
    caseData,
    caseId,
    caseClientConnection,
    contact,
    isCaseContactConnectionsLoading,
    isContactLoading,
    isContactFetching,
    isMessagingDatesLoading,
    linkedCases,
    linkId,
    messagingDates,
    surveyData,
    communicationsData,
    onDeleteCallback,
    handleAddClient,
  } = props;
  const contactName = displayContactName(contact);

  const deleteCaseContactConnectionRole = useDeleteCaseContactConnectionRole();

  const { data: caseConflicts } = useGetCaseConflictsForCase(caseId);

  const handleDeleteConnection = (roleOnCase: CaseContactConnectionRoleType['roleOnCase']) => {
    const rolesToDelete = caseClientConnection.roles?.filter(
      (role) => role.roleOnCase === roleOnCase,
    );

    if (caseClientConnection?.caseContactConnectionId && rolesToDelete) {
      rolesToDelete.forEach((roleToDelete) => {
        deleteCaseContactConnectionRole.mutate(roleToDelete.caseContactConnectionRoleId);
      });
    }
    onDeleteCallback && onDeleteCallback(true);
  };

  const primaryNumber = contact?.contactNumbers?.[0]?.number;

  const [messagesOpen, setMessagesOpen] = useState(false);
  const [clientDetailsOpen, setClientDetailsOpen] = useState(false);
  const [newClientSlideoverOpen, setNewClientSlideoverOpen] = useState(false);

  const calculateAge = (dateOfBirth: string) => {
    return String(dayjs()?.diff(dayjs(dateOfBirth), 'year'));
  };

  const age = contact?.dateOfBirth ? calculateAge(contact.dateOfBirth) : null;
  const clientInfoFormatted = contact ? formatContactChipDetails(contact) : {};

  const primaryContactPhoneConnections = contact?.contactConnections?.filter(
    (connection) => connection.relationship === 'primaryContact',
  );

  const primaryContactPhoneIds = primaryContactPhoneConnections?.map(
    (connection) => connection.connectedToContactId,
  );

  const contacts = useGetListOfContactsByIDs(primaryContactPhoneIds ?? [])?.data?.data;

  const primaryContactPhoneObjs = contacts?.map((contact) => ({
    name: displayContactName(contact),
    role: 'Primary Contact',
    phoneNumbers: contact.contactNumbers,
  }));

  const textingDisabled =
    primaryContactPhoneObjs?.length === 0 &&
    (primaryNumber === undefined || primaryNumber?.length === 0);

  const textButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        setMessagesOpen(true);
      }}
      className={`px-6 py-1 border rounded-lg ${
        textingDisabled
          ? 'opacity-50 cursor-not-allowed'
          : 'fadeAnimation hover:bg-light-blue hover:text-atlantic-blue'
      }`}
      disabled={textingDisabled}
    >
      Text
    </button>
  );

  return (
    <>
      <ContactInfoSlideover
        contact={contact}
        open={newClientSlideoverOpen}
        setOpen={setNewClientSlideoverOpen}
      />
      <Messenger
        caseId={caseId}
        toPhoneNumbers={[
          ...[
            {
              phoneNumbers: contact?.contactNumbers ?? [],
              role: 'Client',
              name: contactName,
            },
          ],
          ...(primaryContactPhoneObjs ?? []),
        ]}
        contact={contact}
        surveyLink={caseData?.clientIntakeShareLink}
        communicationsData={communicationsData}
        messengerOpen={messagesOpen}
        setMessengerOpen={setMessagesOpen}
      />
      <ResourceSlideover
        title={'Client Information'}
        description={'View and edit client information'}
        open={clientDetailsOpen}
        setOpen={setClientDetailsOpen}
        displayDeleteButton={true}
        deleteButtonText="Delete Client/Case Link"
        deleteFunction={() => handleDeleteConnection(caseContactConnectionOptions.client)}
        typing={false}
        hideSections={{
          attachments: true,
          tasks: true,
          feed: true,
        }}
        caseId={caseId}
        resourceId={contact?.contactId ?? ''}
        createType="contact"
      >
        <TabsContent value="details">
          {contact && (
            <ContactInfoForm
              caseData={{
                caseTitle: caseData?.caseTitle,
                caseId: caseId,
              }}
              conscriptionId={caseClientConnection?.caseContactConnectionId}
              contact={contact}
              inSlideover
            />
          )}
        </TabsContent>
      </ResourceSlideover>
      <div className="relative w-full h-full p-5 bg-white border border-gray-200 shadow-sm rounded-xl">
        {!isCaseContactConnectionsLoading &&
          !isContactFetching &&
          !contact && ( // if no contact and everything is loaded
            <div className="absolute inset-0 z-10 text-center rounded-xl backdrop-blur-sm">
              <div className="flex items-center justify-center h-full top-2/4">
                <ContactsSelect
                  handleSelectContact={handleAddClient.mutation}
                  caseId={caseId}
                  buttonMessage="Select a contact"
                  isLoading={handleAddClient?.isLoading}
                />
              </div>
            </div>
          )}
        {isContactLoading ? (
          <div className="absolute w-7 h-7 top-2 right-3">
            <TextSkeleton />
          </div>
        ) : (
          <Button
            variant="ghost"
            onClick={() => {
              setClientDetailsOpen(true);
            }}
            className="absolute text-gray-400 fadeAnimation top-2 right-3"
          >
            <PencilSquareIcon className=" w-7 h-7" />
          </Button>
        )}

        <div className="flex flex-col items-center justify-center w-full pb-4 border-b">
          <div className="flex items-center justify-center w-20 h-20 text-lg font-semibold uppercase bg-gray-200 rounded-full">
            {isContactLoading ? (
              <div className="w-full h-full bg-gray-100 rounded-full animate-pulse">&nbsp;</div>
            ) : (
              <div>{makeAcronym(displayContactName(contact), 2)}</div>
            )}
          </div>
          <div className="flex justify-center max-w-full mt-3">
            <Typography size="xl" className="w-full font-bold">
              {isContactLoading ? (
                <div className="w-20">
                  <TextSkeleton />
                </div>
              ) : (
                contactName
              )}
            </Typography>
            <span className="self-center">
              <CaseConflictIcon caseConflicts={caseConflicts?.data ?? []} />
            </span>
          </div>
          <div className="max-w-full mt-2 text-sm text-gray-500">
            {![contactTypeOptions.Organization, contactTypeOptionsOld.Organization].includes(
              contact?.contactType ?? '',
            ) &&
              (isContactLoading ? (
                <div className="w-20">
                  <TextSkeleton />
                </div>
              ) : (
                <ContactChipDetails
                  contact={contact}
                  hideName
                  textSlideoverLaunch={() => setMessagesOpen(true)}
                />
              ))}
          </div>
        </div>
        <div className="px-2 pt-4 space-y-2">
          <div className="flex items-center justify-between text-sm">
            <div className="font-semibold tracking-tighter text-gray-400 uppercase">
              Last Response
            </div>
            <div>
              {isMessagingDatesLoading ? (
                <div className="w-40">
                  <TextSkeleton />
                </div>
              ) : messagingDates?.lastResponse ? (
                `${prettifyDateString(messagingDates?.lastResponse) ?? ''} (${timeAgo(
                  messagingDates?.lastResponse,
                  true,
                )})`
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="flex items-center justify-between text-sm">
            <div className="font-semibold tracking-tighter text-gray-400 uppercase">
              Last Outbound
            </div>
            <div>
              {isMessagingDatesLoading ? (
                <div className="w-40">
                  <TextSkeleton />
                </div>
              ) : messagingDates?.lastOutbound ? (
                `${prettifyDateString(messagingDates?.lastOutbound) ?? ''} (${timeAgo(
                  messagingDates?.lastOutbound,
                  true,
                )})`
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(CaseClientCard, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
