import { roundToNearest15Minutes } from '@colosseum/data';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { kebabCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../shadcn/Form/Form';
dayjs.extend(customParseFormat);

/* eslint-disable-next-line */
export interface TimePickerProps {
  defaultValue?: string;
  formKey: string;
  name: string;
  title: string;
  updateFn?: (arg0?: any) => void;
  roundToFifteenMinutes?: boolean;
  maxHour?: string; // '18:00'
  minHour?: string; // '09:00'
  showMessage?: boolean;
}

export function TimePicker(props: TimePickerProps) {
  const {
    title,
    updateFn,
    roundToFifteenMinutes = true,
    maxHour = '18:00',
    minHour = '09:00',
    showMessage = true,
    formKey,
  } = props;
  const form = useFormContext();

  const timeInput = document.getElementById('myTimeInput') as HTMLInputElement;

  function handleBlur() {
    const time = dayjs(timeInput?.value, 'HH:mm');
    const maxTime = dayjs(maxHour, 'HH:mm');
    const minTime = dayjs(minHour, 'HH:mm');

    if (time.isBefore(minTime)) {
      timeInput.value = minHour.toString();
      form.setValue(formKey, minHour);
      updateFn && updateFn();
      return;
    }

    if (time.isAfter(maxTime)) {
      timeInput.value = maxHour.toString();
      form.setValue(formKey, maxHour);
      updateFn && updateFn();
      return;
    }

    const [hours, minutes] = timeInput.value.split(':');
    if (roundToFifteenMinutes) {
      timeInput.value = [hours, roundToNearest15Minutes(parseInt(minutes))].join(':');
      form.setValue(formKey, [hours, roundToNearest15Minutes(parseInt(minutes))].join(':'));
    }
    updateFn && updateFn();
  }

  return (
    <FormField
      control={form.control}
      name={title}
      render={({ field }) => (
        <div className="flex gap-2 text-xs">
          <FormItem data-cy={`select-input-${kebabCase(title)}`}>
            <FormLabel>{title}</FormLabel>
            <FormControl>
              <div className="flex h-10 w-full items-center justify-between rounded-md border border-input border-gray-300 bg-transparent px-3 py-2 text-sm bg-white ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 overflow-auto">
                <input
                  id="myTimeInput"
                  {...form.register(formKey)}
                  className="focus:outline-none focus:bg-transparent w-24"
                  type="time"
                  min={minHour}
                  max={maxHour}
                  name={title}
                  onChange={(value) => {
                    form.setValue(formKey, value.target.value, {
                      shouldDirty: true,
                    });
                  }}
                  onBlur={() => handleBlur()}
                />{' '}
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
          <div className="w-60 self-center">
            {showMessage && `Messages are sent between ${minHour} - ${maxHour}`}
            {roundToFifteenMinutes && ' rounded to the nearest 15 minutes'}
          </div>
        </div>
      )}
    />
  );
}
export default TimePicker;
