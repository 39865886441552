import { PolicyType } from '@gladiate/types';
import { prettifyDateString } from './dateUtils';
import { CoverageTypeOptions, NoticeStatusOptions } from './forms/case/usePolicyForm';
import { formatPrice } from './priceUtils';

const truncatePersonAccidentValues = (perPerson?: number, perAccident?: number) => {
  const isPerPersonNumber = typeof perPerson === 'number';
  const isPerAccidentNumber = typeof perAccident === 'number';
  if (isPerPersonNumber || isPerAccidentNumber) {
    return `${isPerPersonNumber ? formatPrice(perPerson, true) : '-'} / ${
      isPerAccidentNumber ? formatPrice(perAccident, true) : '-'
    }`;
  }
  return undefined;
};

export const getPolicyValueLines = (
  policy?: PolicyType,
): { title: string; value: string | undefined }[] => [
  {
    title: 'Notice Status',
    value: NoticeStatusOptions.find((option) => option.value === policy?.noticeStatus)?.label,
  },
  {
    title: 'BI Liability',
    value: truncatePersonAccidentValues(
      policy?.bodilyInjuryLiabilityPerPerson,
      policy?.bodilyInjuryLiabilityPerAccident,
    ),
  },
  {
    title: 'Property Liability',
    value:
      typeof policy?.propertyDamageLiability === 'number'
        ? formatPrice(policy.propertyDamageLiability, true)
        : undefined,
  },

  {
    title: 'PIP',
    value:
      typeof policy?.personalInjuryProtectionAmount === 'number'
        ? formatPrice(policy.personalInjuryProtectionAmount, true)
        : undefined,
  },
  {
    title: 'MedPay',
    value:
      typeof policy?.medicalPaymentCoverageAmount === 'number'
        ? formatPrice(policy.medicalPaymentCoverageAmount, true)
        : undefined,
  },
  {
    title: 'UM/UIM',
    value: truncatePersonAccidentValues(
      policy?.umUimCoverageAmountPerPerson,
      policy?.umUimCoverageAmountPerAccident,
    ),
  },
  {
    title: 'UM/UIM PD',
    value:
      typeof policy?.umUimMotoristPropertyDamage === 'number'
        ? formatPrice(policy.umUimMotoristPropertyDamage, true)
        : undefined,
  },
  {
    title: 'Ded',
    value:
      typeof policy?.deductibleAmount === 'number'
        ? formatPrice(policy.deductibleAmount, true)
        : undefined,
  },
  {
    title: 'Rental',
    value:
      typeof policy?.rentalReimbursement === 'number'
        ? formatPrice(policy.rentalReimbursement, true)
        : undefined,
  },
  {
    title: 'Liability',
    value: truncatePersonAccidentValues(
      policy?.liabilityCoveragePerPerson,
      policy?.liabilityCoveragePerAccident,
    ),
  },
  {
    title: 'Umbrella',
    value:
      typeof policy?.umbrellaPolicy === 'number'
        ? formatPrice(policy.umbrellaPolicy, true)
        : undefined,
  },
  {
    title: 'Death Benefit',
    value:
      typeof policy?.deathBenefit === 'number' ? formatPrice(policy.deathBenefit, true) : undefined,
  },
  {
    title: 'Accidental Death Benefit Rider',
    value: policy?.accidentalDeathBenefitRider,
  },
  {
    title: 'Policy Number',
    value: policy?.policyNumber,
  },
  {
    title: 'Coverage Start Date',
    value: prettifyDateString(policy?.coverageStartDate) ?? undefined,
  },
  {
    title: 'Coverage End Date',
    value: prettifyDateString(policy?.coverageEndDate) ?? undefined,
  },
  {
    title: 'Contestability End Date',
    value: prettifyDateString(policy?.contestabilityEndDate) ?? undefined,
  },
  {
    title: 'Out of Pocket Max',
    value:
      typeof policy?.outOfPocketMax === 'number'
        ? formatPrice(policy.outOfPocketMax, true)
        : undefined,
  },
  {
    title: 'Payer Number',
    value: policy?.payerNumber,
  },
  {
    title: 'Claim Submission Number',
    value: policy?.claimSubmissionNumber,
  },
  {
    title: 'Member Id',
    value: policy?.memberId,
  },
  {
    title: 'Coverage Type',
    value: CoverageTypeOptions.find((option) => option.value === policy?.coverageType)?.label,
  },
  {
    title: 'State',
    value: policy?.state,
  },
];
