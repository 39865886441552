import {
  CompleteMedicalTreatmentType,
  casesSectionMappings,
  enqueueAPISnackbar,
  formatPrice,
  mergeMedicalTreatmentsAndContacts,
  scrollToCaseSection,
  tanstackTableNames,
  useCompleteMedicalTreatmentValues,
  useCreateCaseContactConnection,
  useCreateCaseContactConnectionWithItem,
  useCreateMedicalTreatment,
  useDeleteMedicalTreatment,
  useGetCase,
  useGetCaseContactConnections,
  useGetContactsInfinite,
  useGetMedicalTreatments,
  useUpdateCaseData,
  useUpdateMedicalTreatment,
} from '@colosseum/data';
import { CreateButton, DataTable, SlideoverContext, Typography } from '@colosseum/shared-ui';

import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { medicalTreatmentBillColumns } from './active-medical-treatment-table-columns';
import { ActiveMedicalTreatmentSlideover } from './ActiveMedicalTreatmentSlideover';

/* eslint-disable-next-line */
export interface ActiveMedicalTreatmentsProps {
  activeTreatmentId?: string;
  setActiveTreatmentId: Dispatch<SetStateAction<string | undefined>>;
  caseId: string;
  contactId?: string;
  hideSummary?: boolean;
}

export function ActiveMedicalTreatments(props: ActiveMedicalTreatmentsProps) {
  const { activeTreatmentId, setActiveTreatmentId, caseId, contactId, hideSummary } = props;

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const [open, setOpen] = useState(false);

  const { data: treatmentsData, isLoading: treatmentsLoading } = useGetMedicalTreatments(caseId);
  const { createCaseContactConnectionWithItemSoleClient } =
    useCreateCaseContactConnectionWithItem();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const createMedicalTreatment = useCreateMedicalTreatment();
  const deleteMedicalTreatment = useDeleteMedicalTreatment();
  const updateMedicalTreatment = useUpdateMedicalTreatment();
  const { getCompleteMedicalTreatmentValues } = useCompleteMedicalTreatmentValues(caseId);
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');
  const { data: contactsData } = useGetContactsInfinite();

  const { data: caseData } = useGetCase(caseId);
  const caseDetails = caseData?.data;
  const updateCaseMutation = useUpdateCaseData();

  const treatments = treatmentsData?.data;

  const { completeTreatments, ...totalValues } = getCompleteMedicalTreatmentValues(
    treatments ?? [],
  );

  const treatmentsWithClients = mergeMedicalTreatmentsAndContacts({
    caseContactConnections: caseContactConnectionsData?.data ?? [],
    medicalTreatments: completeTreatments ?? [],
    contacts: contactsData?.data ?? [],
  }).filter((treatment) =>
    contactId
      ? // @ts-expect-error medicalProvider does exist on this object, typing is slightly off.
        treatment?.medicalProvider?.contactId === contactId ||
        // @ts-expect-error medicalProvider does exist on this object, typing is slightly off.
        treatment?.billingEntity?.contactId === contactId
      : true,
  );

  const activeTreatment =
    completeTreatments?.find((treatment) => treatment?.medicalTreatmentId === activeTreatmentId) ??
    ({} as CompleteMedicalTreatmentType);

  const handleCreateTreatment = () => {
    createMedicalTreatment
      .mutateAsync({
        caseId,
      })
      .then((res) => {
        setActiveTreatmentId(res.data.medicalTreatmentId);
        setOpen(true);
        // create a new ccc with treatment only if there is only one client on the case
        createCaseContactConnectionWithItemSoleClient({
          caseId,
          itemId: res.data.medicalTreatmentId,
          itemType: 'medicalTreatment',
          roleOnCase: 'client',
          caseContactConnections: caseContactConnectionsData?.data,
        });
        if (contactId) {
          createCaseContactConnection.mutate({
            caseId,
            contactId,
            itemId: res.data.medicalTreatmentId,
            itemType: 'medicalTreatment',
            roleOnCase: 'medicalProvider',
          });
        }
      });
  };

  const handleDeleteTreatment = () => {
    deleteMedicalTreatment.mutateAsync(activeTreatment?.medicalTreatmentId).then(() => {
      setOpen(false);
      setActiveTreatmentId(undefined);
    });
  };

  function updateEstimatedMedicalCosts(amount: number) {
    updateCaseMutation.mutate({
      caseId: caseId,
      estimatedMedicalCosts: amount,
    });
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'medicalTreatment' && !treatmentsLoading) {
      if (treatmentsData?.data.find((t) => t.medicalTreatmentId === pendingSlideoverToOpen?.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.medicals}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActiveTreatmentId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Medical Treatment not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, treatmentsLoading]);
  return (
    <>
      <ActiveMedicalTreatmentSlideover
        open={open}
        setOpen={setOpen}
        caseId={caseId}
        caseDetails={caseDetails}
        activeTreatment={activeTreatment}
        setActiveTreatmentId={setActiveTreatmentId}
      />

      <div>
        <div className="pt-4">
          {!hideSummary && (
            <>
              <div className="flex flex-col gap-2 pb-4 pl-4">
                <Typography variant="semiBold">Estimated Medical Costs</Typography>
                <div className="flex items-center float-right border   w-32 border-gray-300 rounded-md py-0.5 px-1 -mr-1 text-right">
                  <span className="mr-2 text-sm text-gray-500">$</span>
                  <NumericFormat
                    className="w-24 text-right focus:outline-none"
                    decimalScale={2}
                    thousandSeparator=","
                    onBlur={(e) => {
                      updateEstimatedMedicalCosts(parseFloat(e.target.value.replace(/,/g, '')));
                    }}
                    value={caseDetails?.estimatedMedicalCosts || 0}
                    placeholder="0.00"
                  />
                </div>
              </div>
              <div className="pl-4 font-semibold underline">Totals</div>
              <table className="border-separate border-spacing-x-4">
                <tr>
                  <td className="pb-2 pl-3 pr-4 text-sm text-right sm:pr-6 md:pr-0 "></td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Amount Billed: '}</td>
                  <td className="text-right">
                    {formatPrice(totalValues?.aggregateAmountBilled ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Adjustments: '}</td>
                  <td className="text-right">
                    {formatPrice(totalValues?.aggregateAdjustments ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Total Payments: '}</td>
                  <td className="text-right">
                    {formatPrice(totalValues?.aggregateTotalPayments ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Unpaid Balance: '}</td>
                  <td className="text-right">
                    {formatPrice(totalValues?.aggregateUnpaidBalance ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Reduction: '}</td>
                  <td className="text-right">
                    {totalValues?.aggregateReductions
                      ? formatPrice(totalValues?.aggregateReductions)
                      : 'N/A'}
                  </td>
                  <td>
                    {totalValues?.aggregateReductions
                      ? `(${totalValues?.aggregateReductionsPercent.toFixed(2)}%)`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Present Balance: '}</td>
                  <td className="text-right">
                    {formatPrice(totalValues?.aggregatePresentBalance ?? 0)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">{'Percentage of Estimated Medicals Costs: '}</td>
                  <td className="text-right">
                    {caseDetails?.estimatedMedicalCosts && caseDetails?.estimatedMedicalCosts != 0
                      ? `${(
                          (totalValues?.aggregatePresentBalance /
                            caseDetails?.estimatedMedicalCosts) *
                          100
                        ).toFixed(2)}%`
                      : 'N/A'}
                  </td>
                </tr>
              </table>
            </>
          )}
          <div className="flex items-center justify-end w-full pb-3 align-middle">
            <CreateButton
              title={'Add Treatment Bill'}
              loading={createMedicalTreatment.isLoading}
              onClick={handleCreateTreatment}
              dataCy="create-treatment-button"
            />
          </div>

          <DataTable
            data={treatmentsWithClients ?? []}
            initialSort={{
              id: 'Date Created',
              desc: true,
            }}
            showSearchBar
            filters={[]}
            // TODO fix typing
            columns={medicalTreatmentBillColumns as any}
            handleRowClick={(item) => {
              const group = item.getIsGrouped();
              if (!group) {
                setActiveTreatmentId(item.original.medicalTreatmentId);
                setOpen(true);
              }
            }}
            initialVisibility={{
              'Client Paid out of Pocket': false,
              'Date Created': false,
              'Date Modified': false,
              'Date Verified': false,
              'Ending Date of Service': false,
              'Health Insurance Coverage': false,
              'Injection Count': false,
              'Med Pay Coverage': false,
              'Medical Provider': false,
              'MRI Count': false,
              'Number of Visits': false,
              'PIP Coverage': false,
              'Surgery Count': false,
              'Type of Treatment': false,
              Description: false,
            }}
            initialGroupingsExpanded={true}
            isLoading={treatmentsLoading}
            tableName={tanstackTableNames.medicalTreatments}
            autoResetExpanded={false}
            grouping={['Billing Entity']}
          />
        </div>
      </div>
    </>
  );
}

export default ActiveMedicalTreatments;
