import ReactSlider from 'react-slider';
import './Style.css';

/* eslint-disable-next-line */
export interface RangeSliderProps {
  title: string;
  value: number;
  setValue: any;
  // eslint-disable-next-line no-unused-vars
  onAfterChange: (value: number) => void;
}

export function RangeSlider(props2: RangeSliderProps) {
  return (
    <div className="pb-20">
      <div className="flex">
        <div className="mb-5 text-gray-800">{props2.title}</div>
      </div>

      <ReactSlider
        onAfterChange={(value) => {
          props2.onAfterChange(value);
        }}
        className="relative w-full h-full"
        thumbClassName="rounded-lg font-semibold -mt-3 w-16 text-sm p-1 bg-light-blue text-atlantic-blue text-center active:bg-atlantic-blue hover:bg-atlantic-blue hover:text-white cursor-pointer active:text-white"
        trackClassName="track"
        markClassName="h-3 top-5 w-0.5 bg-gray-400 rounded-full ml-8"
        step={1}
        marks={[0, 25, 33.3, 50, 75, 100]}
        onChange={(value) => {
          props2.setValue(value);
        }}
        value={props2.value}
        renderMark={(props: any) => {
          const markAmountOfChars = props.key?.toString()?.length || 0;

          return (
            <div className="" {...props}>
              <div
                onClick={() => {
                  props2.onAfterChange(parseFloat(props.key as string));
                }}
                className={
                  'absolute mt-4 text-xs text-left rounded-lg cursor-pointer hover:bg-gray-200 px-1 ' +
                  (markAmountOfChars > 1
                    ? markAmountOfChars > 2
                      ? ' -ml-3.5 '
                      : ' -ml-2.5 '
                    : ' -ml-1.5 ')
                }
              >
                {props.key === 33.3 ? (
                  <div className="inline-block w-8">33 &#8531;</div>
                ) : (
                  props.key
                )}
              </div>
            </div>
          );
        }}
        renderThumb={(props /*, state*/) => (
          <div className="p-2 bg-white" {...props}>
            {props2.value === 33.3 ? (
              <div className="inline-block w-9">33 &#8531;</div>
            ) : (
              props2.value
            )}
          </div>
        )}
        // snapDragDisabled={true}
      />
    </div>
  );
}

export default RangeSlider;
