import {
  cn,
  getFirmId,
  useCreateTemplateResourceChildItem,
  useCreateTemplateResourceRootItem,
  useDeleteTemplateResourceItem,
  useGetCaseTypes,
  useGetExhaustiveFileTemplateResource,
  useGetFirmFileRepoTemplates,
  useRenameTemplateResourceItem,
  useUpdateTemplateDescription,
} from '@colosseum/data';
import {
  CreateButton,
  Form,
  GladiateLoader,
  Input,
  MultiSelectFormInput,
  Slideover,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { FileResourceTemplateType } from '@gladiate/types';
import {
  CheckIcon,
  DocumentIcon,
  FolderIcon,
  FolderPlusIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

/* eslint-disable-next-line */
export interface DefaultFileRepoStructureProps {}

const firmId = getFirmId();

export const AddNewFolder: FC<{ onCreate: () => void; level: number }> = ({ onCreate, level }) => {
  return (
    <div
      onClick={onCreate}
      className={cn(
        'flex items-center ml-7  text-xs text-gray-400 tracking-tight cursor-pointer hover:text-gray-800',
        level === 0 ? 'ml-7' : '',
      )}
      style={{ paddingLeft: level * 20 }} // Added this line
    >
      <FolderPlusIcon className="w-5 h-5 " />
      <span className="flex-shrink w-40 ml-1 truncate ">Add New Folder</span>
    </div>
  );
};

interface TemplateFileTreeNodeProps {
  item: FileResourceTemplateType | undefined;
  level: number;
  onCreate: (id: string) => void;
  onDelete: (id: string) => void;
  onRename: (id: string, newName: string) => void;
}

export const TemplateFileTreeNode: FC<TemplateFileTreeNodeProps> = (props) => {
  const { item, level, onCreate, onDelete, onRename } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [newName, setNewName] = useState<string>('');
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  if (!item) return null;

  const isDirectory = item.templateResourceType === 'DIR';
  const itemId = `${item.templateParentResourceId}-${item.templateResourceId}`;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="">
      <div
        className={cn('flex items-center  rounded-md justify-between', level === 0 ? 'hidden' : '')}
        style={{ paddingLeft: level * 20 }}
      >
        <div
          onClick={isRenaming ? undefined : toggleExpand}
          className="flex items-center w-full px-1 overflow-hidden "
        >
          {isDirectory ? (
            <FolderIcon className="w-5 h-5 ml-1 text-atlantic-blue" />
          ) : (
            <DocumentIcon className="w-5 h-5 ml-1 text-light-blue" />
          )}

          <span className="flex-shrink w-40 py-1 ml-2 truncate">
            {isRenaming ? (
              <div className="z-50 flex items-center ">
                <Input
                  className="py-1"
                  value={newName}
                  placeholder="New Name"
                  onChange={(e) => setNewName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onRename(itemId, newName);
                      setIsRenaming(false);
                      setNewName('');
                    }
                  }}
                />
                <button
                  onClick={() => {
                    onRename(itemId, newName);
                    setIsRenaming(false);
                    setNewName('');
                  }}
                  className="px-2 py-1 ml-2 font-bold text-green-500 border border-green-500 rounded-lg hover:bg-green-500 hover:text-white"
                >
                  <CheckIcon className="w-5 h-5" />
                </button>
              </div>
            ) : (
              item.templateResourceName
            )}
          </span>
        </div>

        {!isRenaming && (
          <button
            onClick={() => {
              setIsRenaming(true);
            }}
            className="z-50 mr-2 text-yellow-500 hover:text-yellow-800"
          >
            <PencilSquareIcon className="w-5 h-5 " />
          </button>
        )}

        {level !== 0 && (
          <button
            onClick={() => {
              onDelete(itemId);
            }}
            className="z-50 mr-2 text-red-500 hover:text-red-800"
          >
            <TrashIcon className="w-5 h-5 " />
          </button>
        )}
      </div>

      <AddNewFolder onCreate={() => onCreate(itemId)} level={level} />

      {isDirectory &&
        item.children?.map((child) => (
          <TemplateFileTreeNode
            key={`${child.templateParentResourceId}-${child.templateResourceId}`}
            item={child}
            level={level + 1}
            onCreate={onCreate}
            onDelete={onDelete}
            onRename={onRename}
          />
        ))}
    </div>
  );
};

const formSchema = z.object({
  templateName: z.string().nonempty(),
  templateDescription: z.string().nonempty(),
});

export function DefaultFileRepoStructure() {
  const [showTemplateSlideover, setShowTemplateSlideover] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  const { data: firmFileRepoTemplatesData, isLoading: firmFileRepoTemplatesIsLoading } =
    useGetFirmFileRepoTemplates();
  const { data: firmFileRepoTemplateData, isLoading: firmFileRepoTemplateIsLoading } =
    useGetExhaustiveFileTemplateResource(selectedTemplate);
  const createRootFolder = useCreateTemplateResourceRootItem(selectedTemplate);
  const createChildFolder = useCreateTemplateResourceChildItem(selectedTemplate);
  const deleteFolder = useDeleteTemplateResourceItem(selectedTemplate);
  const renameFolder = useRenameTemplateResourceItem(selectedTemplate);
  const updateTemplate = useUpdateTemplateDescription(selectedTemplate);

  const { data: caseTypesData } = useGetCaseTypes();
  const caseTypes = caseTypesData ? caseTypesData.data : [];

  const templates = firmFileRepoTemplatesData?.data;
  const selectedTemplateData = firmFileRepoTemplateData?.data?.templateResourceItem;
  const selectedTemplateDescription = firmFileRepoTemplateData?.data?.templateDescriptionItem;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      templateName: selectedTemplateDescription?.templateName,
      templateDescription: selectedTemplateDescription?.templateDescription,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
  }

  const createFolderHandler = (itemId: string) => {
    if (selectedTemplate) {
      createChildFolder.mutate({
        resourceType: 'DIR',
        name: 'New Folder',
        currentDirectory: itemId,
      });
    }
  };

  const deleteFolderHandler = (itemId: string) => {
    if (selectedTemplate) {
      deleteFolder.mutate({
        templateResourceId: itemId,
      });
    }
  };

  const renameFolderHandler = (itemId: string, newName: string) => {
    if (selectedTemplate) {
      renameFolder.mutate({
        templateResourceId: itemId,
        newName,
      });
    }
  };

  const handleOnBlur = (e: any) => {
    if (selectedTemplate) {
      updateTemplate.mutate({
        templateResourceId: selectedTemplate,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    form.reset({
      templateName: selectedTemplateDescription?.templateName,
      templateDescription: selectedTemplateDescription?.templateDescription,
    });
  }, [selectedTemplateDescription]);

  return (
    <>
      <Slideover
        title={'File Repository Template'}
        description={'Edit and manage file repository templates.'}
        open={showTemplateSlideover}
        setOpen={setShowTemplateSlideover}
        displayDeleteButton={true}
        typing={false}
        deleteFunction={() => {
          deleteFolderHandler(`${firmId}-${selectedTemplate}`);
          setSelectedTemplate('');
          setShowTemplateSlideover(false);
        }}
      >
        <div>
          <div className="mb-4 text-lg font-semibold">Template Description</div>

          {firmFileRepoTemplateIsLoading ? (
            <GladiateLoader />
          ) : (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="">
                <div className="grid grid-cols-2 mb-5 gap-x-3 gap-y-5">
                  <TextFormInput handleOnBlur={handleOnBlur} name="templateName" title="Name" />
                  <MultiSelectFormInput
                    title="Case Types"
                    options={caseTypes.map((caseType) => {
                      return {
                        title: caseType.title ?? '',
                        value: caseType.caseTypeId,
                      };
                    })}
                    optionsIsObject
                    selectedOptions={selectedTemplateDescription?.caseTypes ?? []}
                    onChange={(option) => {
                      if (selectedTemplate) {
                        const caseTypes = selectedTemplateDescription?.caseTypes ?? [];
                        const isTypeChecked = caseTypes.includes(option);

                        updateTemplate.mutate({
                          templateResourceId: selectedTemplate,
                          caseTypes: isTypeChecked
                            ? caseTypes.filter((currentType) => currentType !== option) // Remove case type when unchecked
                            : [...caseTypes, option], // Add case type when checked
                        });
                      }
                    }}
                  />
                  <div className="col-span-2">
                    <TextFormInput
                      handleOnBlur={handleOnBlur}
                      name="templateDescription"
                      title="Description"
                      type="textarea"
                    />
                  </div>
                </div>
              </form>
            </Form>
          )}
          <div className="mt-5">
            <div className="mb-2 text-lg font-semibold">Folder Structure</div>
            {firmFileRepoTemplateIsLoading ||
            createChildFolder.isLoading ||
            createRootFolder.isLoading ||
            renameFolder.isLoading ||
            deleteFolder.isLoading ? (
              <GladiateLoader />
            ) : (
              <TemplateFileTreeNode
                item={selectedTemplateData}
                level={0}
                onCreate={createFolderHandler}
                onDelete={deleteFolderHandler}
                onRename={renameFolderHandler}
              />
            )}
          </div>
        </div>
      </Slideover>
      <div
        className="relative bg-white rounded-2xl"
        data-automations-submenu-item="File Repository Structures"
      >
        <div className="flex justify-between">
          <div>
            <h1 className="text-xl font-semibold text-gray-900">File Repository Structures</h1>
            <p className="mt-2 text-sm text-gray-700">
              Default repository structures for different types of cases.
            </p>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Add Template'}
              loading={createRootFolder.isLoading}
              onClick={() => {
                createRootFolder.mutate({
                  resourceType: 'DIR',
                  name: 'New Template',
                  currentDirectory: null,
                });
              }}
            />
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-6 space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3 ">
            {templates &&
              templates.map((template) => {
                return (
                  <div
                    key={`${template.templateParentResourceId}-${template.templateResourceId}`}
                    className="relative px-5 border border-gray-300 rounded-lg shadow-lg py-7"
                  >
                    <button
                      onClick={() => {
                        setSelectedTemplate(template.templateResourceId ?? '');
                        setShowTemplateSlideover(true);
                      }}
                      className="absolute top-2 right-2 hover:text-sky-blue hover:animate-pulse"
                    >
                      <PencilSquareIcon className="w-6 h-6" />
                    </button>
                    <div className="text-xl font-bold text-sky-blue">{template.templateName}</div>
                    <div className="mt-3 mb-3 text-sm font-semibold text-gray-400">
                      {template.templateDescription}
                    </div>
                  </div>
                );
              })}
          </div>
          {!firmFileRepoTemplatesIsLoading && !templates?.length && (
            <div className="flex items-center justify-center w-full">
              <Typography className="mt-2" size="sm" variant="subtext">
                No results
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DefaultFileRepoStructure;
