import { cn } from '@colosseum/data';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:opacity-50 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        primary:
          'whitespace-nowrap py-2 px-3 inline-flex items-center justify-center text-sm font-medium text-white border border-transparent rounded-md shadow-sm disabled:pointer-events-none disabled:bg-gray-300 disabled:cursor-not-allowed enabled:hover:bg-atlantic-blue  enabled:bg-sky-blue',
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'px-4 py-2 text-sm font-medium bg-red-600 text-white border border-transparent rounded-md shadow-sm hover:bg-red-100 focus:outline-none enabled:hover:bg-red-800',
        outline:
          'border border-input hover:bg-accent hover:text-accent-foreground disabled:pointer-events-none',
        outlinePrimary: 'border border-atlantic-blue text-atlantic-blue hover:bg-light-blue',
        secondary:
          'rounded-lg cursor-pointer bg-light-blue hover:bg-atlantic-blue hover:text-white',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'font-normal text-atlantic-blue hover:text-sky-blue text-left',
      },
      hoverVariant: {
        default: '',
        lightBlue: 'hover:bg-light-blue',
      },
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-3',
        xs: 'h-8 text-sm px-2',
        lg: 'h-11 px-8',
        unset: '',
      },
      textColor: {
        primary: 'text-atlantic-blue hover:text-sky-blue',
        skyBlue: 'text-sky-blue',
        black: 'text-black',
        gray: 'text-gray-500',
        default: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      hoverVariant: 'default',
      size: 'default',
      textColor: 'default',
    },
  },
);

const Button = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
      asChild?: boolean;
    }
>(({ className, variant, hoverVariant, size, textColor, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp
      className={cn(buttonVariants({ variant, hoverVariant, size, textColor, className }))}
      ref={ref}
      {...props}
    />
  );
});
Button.displayName = 'Button';

export { Button, buttonVariants };
