import {
  CompleteMedicalTreatmentType,
  cn,
  displayContactName,
  formatPrice,
  getAggregateValues,
  prettifyDateString,
} from '@colosseum/data';
import { CaseStatusType, ContactType } from '@gladiate/types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';

const aggregatePriceCell = (props: CellContext<FirmMedicalTreatmentsComplete, unknown>) => {
  const value = props.getValue<number>();
  return value > 0 ? formatPrice(value) : '-';
};

export const columns: ColumnDef<FirmMedicalTreatmentsComplete>[] = [
  {
    accessorFn: (row) => {
      return row.billingEntity ? displayContactName(row.billingEntity) : undefined;
    },
    id: 'Billing Entity',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<string>();
      const textClasses = 'font-semibold';
      return (
        <span className={cn(textClasses, !contactName && 'text-red-500')}>
          {contactName || '<No Billing Entity>'}
        </span>
      );
    },
  },
  {
    accessorFn: (row) => {
      return row.medicalProvider ? displayContactName(row.medicalProvider) : undefined;
    },
    id: 'Medical Provider',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<string>();
      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Medical Provider>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date Created',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row.dateModified,
    id: 'Date Modified',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row.startingDateOfService,
    id: 'Starting Date of Service',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row.endingDateOfService,
    id: 'Ending Date of Service',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row.medicalTreatmentTypeName,
    id: 'Type of Treatment',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.numberOfVisits,
    id: 'Number of Visits',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      return row.surgeryPerformed === '1' ? row.surgeryCount : undefined;
    },
    id: 'Surgery Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: 'sum',

    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.mriPerformed === '1' ? row.mriCount : undefined;
    },
    id: 'MRI Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: 'sum',
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.healthInsuranceCoverage === '1' ? row.healthInsurancePayments : undefined;
    },
    id: 'Health Insurance Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => {
      return row.clientPaidOutOfPocket === '1' ? row.clientPaidOutOfPocketPayment : undefined;
    },
    id: 'Client Paid out of Pocket',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => {
      return row.personalInjuryProtectionCoverage === '1'
        ? row.personalInjuryProtectionPayment
        : undefined;
    },
    id: 'PIP Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => {
      return row.medicalPaymentCoverage === '1' ? row.medicalPaymentAmount : undefined;
    },
    id: 'Med Pay Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => {
      return row.injectionPerformed === '1' ? row.injectionCount : undefined;
    },
    id: 'Injection Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: 'sum',
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => row.amountBilled,
    id: 'Amount Billed',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => row.adjustments,
    id: 'Adjustments',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: 'sum',
    aggregatedCell: aggregatePriceCell,
  },
  {
    accessorFn: (row) => row.totalPayments,
    id: 'Total Payments',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
    aggregationFn: 'sum',
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.unpaidBalance,
    id: 'Unpaid Balance',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const unpaidBalance = props.getValue<number>();
      const inferredUnpaidBalance = props.row.original.inferredUnpaidBalance;
      if (typeof unpaidBalance === 'number') {
        return formatPrice(unpaidBalance);
      } else if (inferredUnpaidBalance) {
        return (
          <TooltipWrapper message="This value is assumed from the present balance, because there is no unpaid balance set.">
            <span className="text-yellow-500">{formatPrice(inferredUnpaidBalance)}</span>
          </TooltipWrapper>
        );
      } else {
        return '-';
      }
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return completeTreatments?.aggregateUnpaidBalance;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return [row.reductionAmount, row.reductionPercentage];
    },
    id: 'Reduction',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const [reductionAmount, reductionPercentage] = props.getValue<[number, number]>();
      const reductionAmountToDisplay =
        reductionAmount >= 0 ? formatPrice(Number(reductionAmount)) : '-';
      const reductionPercentageToDisplay =
        reductionPercentage >= 0 ? `${reductionPercentage.toFixed(2)}%` : '-';
      return (
        <div>
          <span>
            {reductionAmount >= 0
              ? `${reductionAmountToDisplay} (${reductionPercentageToDisplay})`
              : '-'}
          </span>
        </div>
      );
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return [
        completeTreatments?.aggregateReductions,
        completeTreatments?.aggregateReductionsPercent,
      ];
    },
    aggregatedCell: (props) => {
      const [reductionAmount, reductionPercentage] =
        props.getValue<[number | undefined, number | undefined]>();
      if (
        typeof reductionAmount == 'undefined' ||
        typeof reductionPercentage == 'undefined' ||
        reductionAmount < 0 ||
        reductionPercentage < 0
      ) {
        return '-';
      }
      return `${formatPrice(reductionAmount)} (${reductionPercentage.toFixed(2)}%)`;
    },
  },
  {
    accessorFn: (row) => row.presentBalance,
    id: 'Present Balance',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const presentBalance = props.getValue<number>();
      const inferredPresentBalance = props.row.original.inferredPresentBalance;
      if (presentBalance) {
        return formatPrice(presentBalance);
      } else if (inferredPresentBalance) {
        return (
          <TooltipWrapper message="This value is assumed from the unpaid balance, because there is no present balance set.">
            <span className="text-yellow-500">{formatPrice(inferredPresentBalance)}</span>
          </TooltipWrapper>
        );
      } else {
        return '-';
      }
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return completeTreatments?.aggregatePresentBalance;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.balanceVerifiedDate,
    id: 'Date Verified',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
];

export type FirmMedicalTreatmentsComplete = CompleteMedicalTreatmentType & {
  billingEntity?: ContactType;
  contact?: ContactType;
  caseTitle?: string;
  caseStatus?: CaseStatusType;
};
export const insightsColumns: ColumnDef<FirmMedicalTreatmentsComplete>[] = [
  {
    accessorFn: (row) => row?.caseTitle,
    id: 'Case Title',
    header: renderHeader,
    cell: (props) => {
      return renderCell({ props });
    },
  },
  {
    accessorFn: (row) => {
      return row.caseStatus?.title;
    },
    id: 'Case Status',
    header: renderHeader,
    cell: (props) => {
      const grouping = props.table.options.state.grouping as string[];
      if (grouping.toString() === 'Case Title') {
        return null;
      }
      return renderCell({ props });
    },
    aggregationFn: (columnId, values) => {
      const statuses = values.map((value) => value.original);
      return statuses[0].caseStatus?.title;
    },
    aggregatedCell: (props) => {
      const grouping = props.table.options.state.grouping as string[];
      if (grouping.toString() === 'Billing Entity') {
        return null;
      }
      return props.getValue<string>() || '-';
    },
  },
  ...columns,
];
