import {
  CaseCompleteType,
  useGetAuditsWithNotifications,
  useLocalStorage,
  usePermissions,
} from '@colosseum/data';
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  ResourceHistory,
  ResourceNotes,
  ResourceTasks,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { SlidersHorizontal } from 'lucide-react';
import AccountingSection from '../AccountingSection/AccountingSection';
import IncidentSection from '../IncidentSection/IncidentSection';
import LitigationSection from '../LitigationSection/LitigationSection';
import MedicalsSection from '../MedicalsSection/MedicalsSection';
import NegotiationSection from '../NegotiationSection/NegotiationSection';

/* eslint-disable-next-line */
export interface CaseReviewViewProps {
  activeCase?: CaseCompleteType;
}

export function CaseReviewView(props: CaseReviewViewProps) {
  const { activeCase } = props;
  const { hasServiceAccess } = usePermissions();
  const defaultVisibleModules = {} as {
    [key: string]: boolean;
  };
  if (hasServiceAccess('caseIncidentAccess')) {
    defaultVisibleModules.incident = true;
  }
  if (hasServiceAccess('caseMedicalsAccess')) {
    defaultVisibleModules.medicals = true;
  }
  if (hasServiceAccess('caseAccountingAccess')) {
    defaultVisibleModules.accounting = true;
  }
  if (hasServiceAccess('caseNegotiationsAccess')) {
    defaultVisibleModules.negotiations = true;
  }
  if (hasServiceAccess('caseLitigationAccess')) {
    defaultVisibleModules.litigation = true;
  }

  const [visibleModules, setVisibleModules] = useLocalStorage<{
    [key: string]: boolean;
  }>('caseReviewModules', defaultVisibleModules);

  const audits = useGetAuditsWithNotifications({
    itemId: activeCase?.caseId,
    isCase: true,
  });
  return (
    <div className="h-0 min-h-full p-4 overflow-x-hidden overflow-y-auto border rounded-lg">
      <div className="flex">
        {activeCase && (
          <a
            key={activeCase.caseId}
            target="_blank"
            rel="noreferrer"
            href={`/cases/${activeCase.caseId}`}
            className="flex hover:text-atlantic-blue"
          >
            <ArrowTopRightOnSquareIcon height={24} className="pe-1" />
            <Typography variant="heading" size="xl" className="mb-4 line-clamp-1">
              Case Review - {activeCase?.caseTitle ?? 'No Title'}
            </Typography>
          </a>
        )}

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm" className="hidden h-8 ml-auto lg:flex">
              <SlidersHorizontal className="w-4 h-4 mr-2" />
              Modules
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <DropdownMenuLabel>Toggle Modules</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem
              key="all"
              checked={Object.keys(visibleModules).every((module) => visibleModules[module])}
              onSelect={(e) => e.preventDefault()}
              onCheckedChange={() => {
                if (Object.keys(visibleModules).every((module) => visibleModules[module])) {
                  setVisibleModules({
                    incident: false,
                    medicals: false,
                    accounting: false,
                    negotiations: false,
                    litigation: false,
                  });
                } else if (Object.keys(visibleModules).some((module) => !visibleModules[module])) {
                  setVisibleModules({
                    incident: true,
                    medicals: true,
                    accounting: true,
                    negotiations: true,
                    litigation: true,
                  });
                }
              }}
            >
              Toggle all
            </DropdownMenuCheckboxItem>

            <DropdownMenuSeparator />
            {Object.keys(defaultVisibleModules).map((module) => {
              return (
                <DropdownMenuCheckboxItem
                  key={module}
                  className="capitalize"
                  checked={visibleModules[module]}
                  onSelect={(e) => e.preventDefault()}
                  onCheckedChange={(value) => {
                    setVisibleModules({
                      ...visibleModules,
                      [module]: !!value,
                    });
                  }}
                >
                  {module}
                </DropdownMenuCheckboxItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {activeCase ? (
        <Tabs defaultValue="details">
          <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
            <TabsTrigger
              className={tabTriggerClass}
              key="details"
              value="details"
              data-cy="case-notes-tab"
            >
              Details
            </TabsTrigger>
            <TabsTrigger
              className={tabTriggerClass}
              key="notes"
              value="notes"
              data-cy="case-notes-tab"
            >
              Notes
            </TabsTrigger>
            <TabsTrigger
              className={tabTriggerClass}
              key="tasks"
              value="tasks"
              data-cy="case-notes-tab"
            >
              Tasks
            </TabsTrigger>
            <TabsTrigger
              className={tabTriggerClass}
              key="feed"
              value="feed"
              data-cy="case-notes-tab"
            >
              Feed
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details">
            <div className="flex flex-col divide-y-2 gap-y-10">
              {Object.keys(visibleModules).every((module) => visibleModules[module] === false) && (
                <div>
                  <Typography variant="subtext" size="lg">
                    No modules selected. Please select a module to view details.
                  </Typography>
                </div>
              )}
              {visibleModules.incident && activeCase && hasServiceAccess('caseIncidentAccess') && (
                <div>
                  <Typography variant="heading" className="pt-4 pl-2">
                    Incident
                  </Typography>
                  <IncidentSection caseData={activeCase} isCaseLoading={false} />
                </div>
              )}
              {visibleModules.medicals && hasServiceAccess('caseMedicalsAccess') && (
                <div>
                  <Typography variant="heading" className="pt-4 pl-2">
                    Medicals
                  </Typography>
                  <MedicalsSection caseData={activeCase} />
                </div>
              )}
              {visibleModules.accounting && hasServiceAccess('caseAccountingAccess') && (
                <div>
                  <Typography variant="heading" className="pt-4 pl-2">
                    Accounting
                  </Typography>
                  <AccountingSection caseId={activeCase?.caseId} />
                </div>
              )}
              {visibleModules.negotiations && hasServiceAccess('caseNegotiationsAccess') && (
                <div>
                  <Typography variant="heading" className="pt-4 pl-2">
                    Negotiations
                  </Typography>
                  <NegotiationSection caseId={activeCase?.caseId} />
                </div>
              )}
              {visibleModules.litigation && hasServiceAccess('caseLitigationAccess') && (
                <div>
                  <Typography variant="heading" className="pt-4 pl-2">
                    Litigation
                  </Typography>
                  <LitigationSection caseData={activeCase} />
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value="notes">
            <ResourceNotes
              showFilters
              createType="case"
              resourceId={activeCase?.caseId ?? ''}
              caseId={activeCase?.caseId}
            />
          </TabsContent>
          <TabsContent value="tasks">
            <ResourceTasks isPaginated={false} createType="case" resourceId={activeCase?.caseId} />
          </TabsContent>
          <TabsContent value="feed">
            <ResourceHistory
              isPaginated={true}
              audits={audits}
              isAuditsLoading={audits.isLoading}
              setNotificationsOnly={audits.setNotificationsOnly}
              notificationsOnly={audits.notificationsOnly}
            />
          </TabsContent>
        </Tabs>
      ) : (
        <Typography variant="subtext" size="lg" className="flex justify-center mt-10">
          No case selected. Please select a case from the table to begin your review.
        </Typography>
      )}
    </div>
  );
}

export default CaseReviewView;
