import {
  useCreateContactResource,
  useDeleteContactResource,
  useUpdateContactResource,
} from '@colosseum/data';
import { ContactEmailType } from '@gladiate/types';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionButtonGroup from '../../ActionButtonGroup/ActionButtonGroup';
import CreateButton from '../../CreateButton/CreateButton';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface ClientEmailsFormProps {
  contactId: string;
}

export function ClientEmailsForm(props: ClientEmailsFormProps) {
  const { contactId } = props;
  const form = useFormContext();
  const emailForm = useFieldArray({
    control: form.control,
    name: 'contactEmails', // unique name for your Field Array
  });
  const createContactEmail = useCreateContactResource<ContactEmailType>('emails');
  const deleteContactEmail = useDeleteContactResource<ContactEmailType>('emails');
  const updateContactEmail = useUpdateContactResource<ContactEmailType>('emails');
  const isLoading =
    createContactEmail.isLoading || deleteContactEmail.isLoading || updateContactEmail.isLoading;

  const handleUpdateContactEmail = (name: string, value: string, i: number) => {
    form.setValue(`contactEmails.${i}.${name}`, value);
    updateContactEmail.mutate({
      resourceId: form.getValues(`contactEmails.${i}.contactEmailId`),
      [name]: value,
    });
  };
  return (
    <>
      <div className="divide-y divide-gray-300">
        {emailForm.fields.map((field: Record<'id', string> & { contactEmailId?: string }, i) => (
          <div className="grid grid-cols-2 py-5 mb-2 gap-x-3 gap-y-5" key={field.id}>
            <TextFormInput
              {...form.register(`contactEmails.${i}.emailLabel`)}
              name={`contactEmails.${i}.emailLabel`}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactEmail('emailLabel', target.value, i);
              }}
              title="Email Label"
            />

            <TextFormInput
              {...form.register(`contactEmails.${i}.emailAddress`)}
              name={`contactEmails.${i}.emailAddress`}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdateContactEmail('emailAddress', target.value, i);
              }}
              title="Email"
              maxLength={200}
            />

            <ActionButtonGroup
              copyText={form.getValues(`contactEmails.${i}.emailAddress`)}
              deleteFunction={() => {
                if (!field.contactEmailId) return;
                deleteContactEmail.mutate({
                  resourceId: field.contactEmailId,
                });
                emailForm.remove(i);
              }}
              emailAddress={form.getValues(`contactEmails.${i}.emailAddress`)}
              dataCyTitle={`email-${i}`}
            />
          </div>
        ))}
      </div>
      <div className="col-span-2">
        <CreateButton
          disabled={isLoading}
          loading={isLoading}
          dataCy="create-email-button"
          title="Add Email"
          onClick={() => {
            createContactEmail.mutateAsync({ contactId }).then((res) => {
              emailForm.append({
                contactEmailId: res.data.contactEmailId,
                emailAddress: '',
              });
            });
          }}
        />
      </div>
    </>
  );
}

export default ClientEmailsForm;
