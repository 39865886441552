import { cn } from '@colosseum/data';
import { kebabCase } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/Form/Form';
import { inputClass } from '../../shadcn/Input/Input';

/* eslint-disable-next-line */
export interface PhoneInputProps {
  formHandleBlur: (arg0: any) => void;
  formHandleChange?: (arg0: any) => void;
  name: string;
  title: string;
  disabled?: boolean;
}

export function PhoneInput(props: PhoneInputProps) {
  const { formHandleBlur, name, title, formHandleChange, disabled } = props;
  const form = useFormContext();

  const [unformattedValue, setUnformattedValue] = useState('');

  const handleOnBlur = () =>
    formHandleBlur({
      target: {
        name: name,
        value: unformattedValue ? `+${unformattedValue}` : undefined,
      },
    });

  const handleOnChange = (value: string) => {
    const event = {
      target: {
        name: name,
        value: value ?? undefined,
      },
    };
    formHandleChange && formHandleChange(event);
  };

  // Remove tabbing into the flag icon
  const flagPhoneElements = document.querySelectorAll('.selected-flag');
  flagPhoneElements.forEach((element) => element.setAttribute('tabindex', '-1'));

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel htmlFor={name} className="left-[44px] z-20">
              {title}
            </FormLabel>
            <FormControl>
              <ReactPhoneInput
                inputProps={{
                  className: cn(
                    inputClass,
                    'pl-[48px]',
                    disabled ? ' ring-gray-200 text-gray-500' : '',
                  ),
                  'data-cy': `phone-input-${kebabCase(name)}`,
                  id: name,
                }}
                country={'us'}
                dropdownStyle={{ height: '150px' }}
                buttonClass="rounded-l-md hover:rounded-l-md"
                containerStyle={{ zIndex: 15 }}
                specialLabel=""
                preferredCountries={['us', 'ca']}
                placeholder="+1 (___) ___-____"
                disabled={disabled}
                countryCodeEditable={false}
                value={field.value?.replace('+', '')}
                onChange={(value) => {
                  setUnformattedValue(value);
                  field.onChange(value);
                  handleOnChange(value);
                }}
                onBlur={(e) => {
                  form.trigger(name);
                  handleOnBlur();
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

export default PhoneInput;
