import { CardSection, Typography } from '@colosseum/shared-ui';
import { useNavigate, useParams } from 'react-router';
import NextButton from '../NextButton/NextButton';
import { CampaignStepOptions } from '@gladiate/types';
import CaseSubscriptionTable from '../SubscriptionTables/CaseSubscriptionTable';
import { useGetCaseCampaign } from '@colosseum/data';

export function CaseCampaignSubscriptionPage() {
  // #region Hooks
  const { campaignId: caseCampaignId } = useParams() as { campaignId: string };
  const navigate = useNavigate();
  // #endregion

  // #region Data Fetching
  const {
    data: caseCampaignData,
    isLoading: isCaseCampaignLoading,
    isFetching: isCaseCampaignFetching,
  } = useGetCaseCampaign(caseCampaignId);
  // #endregion

  // #region State
  const subscriptionData = caseCampaignData?.data;
  // #endregion

  // #region Derived State

  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <CardSection cardHeading="Case Campaign Subscriptions" className="flex flex-col justify-center">
      <div className="flex flex-col gap-4 pt-4 ml-2">
        <CaseSubscriptionTable caseCampaignSubscriptionId={caseCampaignId} />
        <div className="flex justify-between">
          <button
            type="button"
            className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <NextButton
            title="Continue"
            nextRoute={`/campaigns/${caseCampaignId}/case/${CampaignStepOptions.summary}`}
          />
        </div>
      </div>
    </CardSection>
  );
}
