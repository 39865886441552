import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createReferralSourceV3,
  deleteReferralSourceV3,
  getReferralSourcesV3,
  updateReferralSourceV3,
} from '../requests/jurisconsults';

// #region Referral Source
export const useGetReferralSources = () => {
  return useQuery({
    queryKey: [queryKeys.referralSources],
    queryFn: async () => getReferralSourcesV3(),
  });
};

export const useCreateReferralSource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createReferralSourceV3>[0]) =>
      createReferralSourceV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.referralSources],
      });
    },
  });
};

export const useUpdateReferralSource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateReferralSourceV3>[0]) =>
      updateReferralSourceV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.referralSources],
      });
    },
  });
};

export const useDeleteReferralSource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (referralSourceId: string) => deleteReferralSourceV3(referralSourceId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.referralSources],
      });
    },
  });
};

// #endregion
