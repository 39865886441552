import { useCreateTaskPlan, useDeleteTaskPlan, useGetTaskPlans } from '@colosseum/data';
import { CreateButton, Slideover, TaskPlanForm, Typography } from '@colosseum/shared-ui';
import { TaskPlanType } from '@gladiate/types';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface TaskPlanSectionProps {}

export function TaskPlanSection(props: TaskPlanSectionProps) {
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<TaskPlanType | null>(null);

  const { data: taskPlanData, isLoading } = useGetTaskPlans();
  const taskPlans = taskPlanData && taskPlanData.data;
  const createPlan = useCreateTaskPlan();
  const deletePlan = useDeleteTaskPlan();

  useEffect(() => {
    if (selectedPlan && taskPlans) {
      const plan = taskPlans.find((plan) => plan.planId === selectedPlan.planId);

      if (plan) {
        setSelectedPlan(plan);
      }
    }
  }, [taskPlanData]);

  useEffect(() => {
    if (selectedPlan?.planId) {
      setOpen(true);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (open === false) {
      setSelectedPlan(null);
    }
  }, [open]);

  return (
    <>
      <div className="px-1" data-automations-submenu-item="Task Plans">
        <div className="justify-between sm:flex sm:items-center">
          <h1 className="text-xl font-semibold text-gray-900">Task Plans</h1>
          <CreateButton
            dataCy="create-task-plan"
            title="Create Task Plan"
            loading={createPlan.isLoading}
            onClick={() => {
              createPlan.mutateAsync({}).then((res) => {
                setSelectedPlan({
                  planId: res.data?.planId ?? '',
                });
              });
            }}
          />
        </div>
        <div className="mt-6 space-y-4 overflow-auto sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:mx-auto xl:mx-0 xl:grid-cols-3 max-h-96">
          {taskPlans &&
            taskPlans
              .sort((a, b) => {
                if (!a.dateCreated) return -1;
                if (!b.dateCreated) return 1;
                if (a.dateCreated > b.dateCreated) {
                  return -1;
                }
                if (a.dateCreated < b.dateCreated) {
                  return 1;
                }
                return 0;
              })
              .map((plan) => (
                <div
                  className="relative px-5 border border-gray-300 rounded-lg shadow-lg py-7"
                  key={plan.planId}
                >
                  <button
                    onClick={() => {
                      setSelectedPlan(plan);
                    }}
                    className="absolute top-2 right-2 hover:text-sky-blue hover:animate-pulse"
                  >
                    <PencilSquareIcon className="w-6 h-6" />
                  </button>
                  <div className="text-xl font-bold text-sky-blue">{plan.title ?? 'Untitled'}</div>
                  <div className="mt-3 mb-3 text-sm font-semibold text-gray-400">
                    {plan.description ?? "This plan doesn't have a description yet."}
                  </div>
                </div>
              ))}
        </div>
        {!isLoading && !taskPlans?.length && (
          <div className="w-full flex justify-center items-center">
            <Typography className="mt-2" size="sm" variant="subtext">
              No results
            </Typography>
          </div>
        )}
      </div>
      <Slideover
        dataCy="task-plan-slideover"
        title={'Task Plan'}
        description={'Edit your task plan'}
        open={open}
        setOpen={setOpen}
        displayDeleteButton={true}
        typing={false}
        deleteFunction={() => {
          if (selectedPlan && selectedPlan.planId) {
            deletePlan.mutate(selectedPlan.planId);
          }
        }}
      >
        <TaskPlanForm plan={selectedPlan} />
      </Slideover>
    </>
  );
}

export default TaskPlanSection;
