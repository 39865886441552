import { useGetCurrentUser, useUpdateFirmUserAttributes } from '@colosseum/data';
import { Toggle, TooltipWrapper } from '@colosseum/shared-ui';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

/* eslint-disable-next-line */
export interface TimeLogPreferencesProps {}

export function TimeLogPreferences(props: TimeLogPreferencesProps) {
  const currentUser = useGetCurrentUser();
  const firmUser = currentUser.data?.data;
  const userMutation = useUpdateFirmUserAttributes(firmUser?.Username ?? '');
  return (
    <div className="flex flex-col p-2 pt-6 gap-y-6">
      <div className="flex gap-x-4">
        <h1>Automatic Timer Start</h1>
        <TooltipWrapper
          message={
            "When navigating into a case with this setting on, a timer will be started automatically, if there isn't already an active timer."
          }
        >
          <QuestionMarkCircleIcon className="self-center w-4 h-4 text-gray-500" />
        </TooltipWrapper>
        <Toggle
          newState={currentUser?.data?.data?.automaticTimerStart === '1'}
          editable
          onToggleChange={(value) => {
            userMutation.mutate({
              data: {
                automaticTimerStart: value ? '1' : '0',
              },
            });
          }}
        />
      </div>
      <div className="flex gap-x-4">
        <h1>Automatic Timer Stop</h1>
        <TooltipWrapper
          message={
            'When navigating out of a case with this setting on, any active timer will be stopped automatically.'
          }
        >
          <QuestionMarkCircleIcon className="self-center w-4 h-4 text-gray-500" />
        </TooltipWrapper>
        <Toggle
          newState={currentUser?.data?.data?.automaticTimerStop === '1'}
          editable
          onToggleChange={(value) => {
            userMutation.mutate({
              data: {
                automaticTimerStop: value ? '1' : '0',
              },
            });
          }}
        />
      </div>
    </div>
  );
}

export default TimeLogPreferences;
