import {
  CoverageTypeOptions,
  NoticeStatusOptions,
  displayContactName,
  formatPrice,
  prettifyDateString,
} from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { ContactType, PolicyType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

const truncatePersonAccidentValues = (perPerson?: number, perAccident?: number) => {
  const isPerPersonNumber = typeof perPerson === 'number';
  const isPerAccidentNumber = typeof perAccident === 'number';
  if (isPerPersonNumber || isPerAccidentNumber) {
    return `${isPerPersonNumber ? formatPrice(perPerson, true) : '-'} / ${
      isPerAccidentNumber ? formatPrice(perAccident, true) : '-'
    }`;
  }
  return undefined;
};

export const policyColumns: ColumnDef<
  (PolicyType & { insurer: ContactType | undefined }) | undefined
>[] = [
  {
    accessorFn: (row) => displayContactName(row?.insurer),
    id: 'Insurer',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.policyNumber,
    id: 'Policy Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.claimNumber,
    id: 'Claim Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.type,
    id: 'Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      truncatePersonAccidentValues(
        row?.bodilyInjuryLiabilityPerPerson,
        row?.bodilyInjuryLiabilityPerAccident,
      ),
    id: 'BI Liability',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.propertyDamageLiability,
    id: 'Property Liability',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.personalInjuryProtectionAmount,
    id: 'PIP',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.medicalPaymentCoverageAmount,
    id: 'MedPay',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) =>
      truncatePersonAccidentValues(
        row?.umUimCoverageAmountPerPerson,
        row?.umUimCoverageAmountPerAccident,
      ),
    id: 'UM/UIM',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.umUimMotoristPropertyDamage,
    id: 'UM/UIM PD',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.deductibleAmount,
    id: 'Ded',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.rentalReimbursement,
    id: 'Rental',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) =>
      truncatePersonAccidentValues(
        row?.liabilityCoveragePerPerson,
        row?.liabilityCoveragePerAccident,
      ),
    id: 'Liability',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.umbrellaPolicy,
    id: 'Umbrella',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.deathBenefit,
    id: 'Death Benefit',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.accidentalDeathBenefitRider,
    id: 'Accidental Death Benefit Rider',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row?.coverageStartDate),
    id: 'Coverage Start Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row?.coverageEndDate),
    id: 'Coverage End Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row?.contestabilityEndDate),
    id: 'Contestability End Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.outOfPocketMax,
    id: 'Out of Pocket Max',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
  },
  {
    accessorFn: (row) => row?.payerNumber,
    id: 'Payer Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.claimSubmissionNumber,
    id: 'Claim Submission Number',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.memberId,
    id: 'Member Id',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      CoverageTypeOptions.find((option) => option.value === row?.coverageType)?.label,
    id: 'Coverage Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.state,
    id: 'State',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) =>
      NoticeStatusOptions.find((option) => option.value === row?.noticeStatus)?.label,
    id: 'Notice Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
