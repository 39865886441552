/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getNameToDisplayFromCognito,
  getUser,
  useCreateNoteReply,
  useDeleteNote,
  useDeleteNoteReply,
  useGetFirmUsers,
  useGetParallelFiles,
  useGetParallelS3FileDownloadLinks,
  useUpdateNote,
  useUpdateNoteReply,
} from '@colosseum/data';
import { NoteReplyType, NoteType } from '@gladiate/types';

import { EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { DrawingPinIcon } from '@radix-ui/react-icons';
import { PopoverTrigger } from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import LineClampedTextContainer from '../LineClampedTextContainer/LineClampedTextContainer';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import ResourceFileAttacher from '../ResourceFileAttacher/ResourceFileAttacher';
import ResourceFileAttachments from '../ResourceFileAttachments/ResourceFileAttachments';
import ResourceNoteForm from '../ResourceNoteForm/ResourceNoteForm';
import ResourceTags from '../ResourceTags/ResourceTags';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';
import { Popover, PopoverContent } from '../shadcn/Popover/Popover';
dayjs.extend(calendar);
dayjs.extend(utc);

interface SharedProps {
  parentType?: NoteType['type'];
  showTypeChip?: boolean;
  isViewOnly?: boolean;
  caseId?: string;
  isLead?: boolean;
  isLoading?: boolean;
}

interface NoteProps extends SharedProps {
  note: NoteType;
  isReply?: false;
}

interface NoteReplyProps extends SharedProps {
  note: NoteReplyType;
  isReply: true;
}

export type ResourceNoteProps = NoteProps | NoteReplyProps;
/* eslint-disable-next-line */

const formSchema = z.object({
  message: z.string().nonempty(),
});

export function ResourceNote(props: ResourceNoteProps) {
  const { note, parentType, isViewOnly, caseId, isReply, isLead, isLoading } = props;
  // @ts-ignore
  const noteId = isReply ? note.noteReplyId : (note.noteId as string);
  const updateResourceNote = useUpdateNote();
  const updateNoteReply = useUpdateNoteReply();
  const [showReplies, setShowReplies] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeReplyId, setActiveReplyId] = useState('');
  const [ellipsisOpen, setEllipsisOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const noteMessageRef = useRef<HTMLDivElement>(null);

  const deleteResourceNote = useDeleteNote(noteId);
  const deleteNoteReply = useDeleteNoteReply(noteId ?? '');

  const createNoteReply = useCreateNoteReply();

  const isDeletingLoading = deleteResourceNote?.isLoading || deleteNoteReply?.isLoading;

  const isUpdatingLoading = updateResourceNote?.isLoading || updateNoteReply?.isLoading;

  const firmUsersQuery = useGetFirmUsers(true);
  const user = getUser();
  const isOwnNote = note.username === user;
  const firmUsersData = firmUsersQuery.data?.data;
  const firmUser = firmUsersData?.find((firmUser) => note.username === firmUser?.Username);

  const replyRef = useRef(null);

  const noteAttachmentFileQueries = useGetParallelFiles(
    isReply
      ? []
      : // @ts-ignore
        (note?.attachments?.map(
          (attachment) => (attachment?.fileResourceId as string) ?? '',
        ) as string[]) ?? [],
  );

  const noteAttachmentData = noteAttachmentFileQueries?.map((query) => query?.data?.data);

  const downloadLinkUrlQueries = useGetParallelS3FileDownloadLinks(
    noteAttachmentData?.map((file) => {
      return file?.s3ObjKey ?? '';
    }) ?? [],
  );

  const displayName = getNameToDisplayFromCognito(firmUser) ?? 'System Generated';

  const replyForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  });

  const handleCreateReply = (data: { message: string }) => {
    createNoteReply
      .mutateAsync({
        noteId: activeReplyId,
        data: { ...data, type: parentType },
      })
      .then((res) => {
        if (res?.data) {
          replyForm.reset();
          setActiveReplyId('');
          setShowReplies(true);
        }
      });
  };

  const handleDeleteNote = () => {
    if (isReply) {
      deleteNoteReply.mutateAsync().then((res) => {
        if (res.data && res.meta.userMsg) {
          enqueueSnackbar(res.meta.userMsg, {
            variant: 'success',
          });
        }
      });
    } else {
      deleteResourceNote.mutateAsync().then((res) => {
        if (res.data && res.meta.userMsg) {
          enqueueSnackbar(res.meta.userMsg, {
            variant: 'success',
          });
        }
      });
    }
  };

  const handleEditNote = (data: { message: string }) => {
    const { message } = data;
    if (isReply) {
      return updateNoteReply
        .mutateAsync({
          data: {
            message,
          },
          noteReplyId: note.noteReplyId ?? '',
        })
        .then(() => setEditMode(false));
    } else {
      return updateResourceNote
        .mutateAsync({
          data: {
            message,
          },
          // @ts-ignore
          resourceId: note?.noteId,
        })
        .then(() => setEditMode(false));
    }
  };

  const handlePinNote = () => {
    if (isReply) return;
    updateResourceNote.mutate({
      data: {
        pinned: note.pinned === '1' ? '0' : '1',
      },
      // @ts-ignore
      resourceId: note?.noteId,
    });
  };

  useEffect(() => {
    function parseAndStyleUserMentions() {
      const div = noteMessageRef?.current?.getElementsByTagName('div')[0];
      const message = div?.textContent;
      const messageArray = message?.split(' ');

      if (div && messageArray) {
        messageArray.forEach((entry) => {
          if (entry.startsWith('@')) {
            const username = entry.slice(1);
            const firmUser = firmUsersData?.find((firmUser) => firmUser.Username === username);

            if (firmUser && firmUser.name) {
              const spanElement = document.createElement('span');
              spanElement.classList.add(
                'text-blue-500',
                'bg-blue-50',
                'font-medium',
                'rounded-md',
                'px-1',
              );
              spanElement.textContent = `@${firmUser.name}`;

              div.innerHTML = div.innerHTML.replace(`@${username}`, spanElement.outerHTML);
            }
          }
        });
      }
    }

    parseAndStyleUserMentions();
  }, [firmUsersData, noteId]);

  return (
    <div>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={handleDeleteNote}
        actionFunctionIsMutation={true}
        title={'Delete Note'}
      />
      <Breadcrumbs resourceType={note?.type} resourceId={noteId} isLead={isLead} />
      <div className="flex gap-2 pt-4">
        <ProfilePhoto
          className="self-center rounded-none min-w-12"
          loading={false}
          diameter={12}
          displayName={displayName}
        />
        <div className="flex flex-col grow">
          <div className="flex flex-row gap-x-2">
            <div className="text-md">{displayName}</div>
            <div className="self-center text-xs text-gray-500 white?espace-nowrap">
              {dayjs(`${note?.dateCreated ?? ''}Z`).calendar()}
            </div>
          </div>
          <div className="flex gap-x-2">
            {!isReply && (
              <>
                <ResourceTags
                  // @ts-ignore
                  resourceId={note.noteId ?? ''}
                  resourceType={'note'}
                  isViewOnly={isViewOnly}
                />
                {isOwnNote && !isViewOnly && caseId && noteId && (
                  <ResourceFileAttacher caseId={caseId} type="note" resourceId={noteId} />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex">
          {!isReply && caseId && (
            <Button onClick={handlePinNote} variant="ghost" className="text-gray-500">
              {isUpdatingLoading ? (
                <GladiateLoader height={20} />
              ) : (
                <DrawingPinIcon
                  width={20}
                  height={20}
                  color={note.pinned === '1' ? 'blue' : undefined}
                />
              )}
            </Button>
          )}
          {isOwnNote && !isViewOnly && (
            <Popover modal open={ellipsisOpen} onOpenChange={setEllipsisOpen}>
              <PopoverTrigger asChild>
                <Button variant="ghost">
                  <EllipsisHorizontalIcon className="w-5 h-5" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col">
                  <Button
                    variant="ghost"
                    textColor="gray"
                    className="justify-start p-4 rounded-b-none"
                    onClick={() => {
                      setEllipsisOpen(false);
                      setEditMode(true);
                    }}
                  >
                    {isDeletingLoading ? (
                      <GladiateLoader height={20} />
                    ) : (
                      <div className="flex gap-x-1">
                        <PencilIcon className="w-4 h-4" />
                        <div>Edit</div>
                      </div>
                    )}
                  </Button>
                  <Button
                    variant="ghost"
                    textColor="gray"
                    className="justify-start p-4 rounded-t-none"
                    onClick={() => {
                      setEllipsisOpen(false);
                      setShowDeleteModal(true);
                    }}
                  >
                    {isDeletingLoading ? (
                      <GladiateLoader height={20} />
                    ) : (
                      <div className="flex gap-x-1">
                        <TrashIcon className="w-4 h-4 text-red-500" />
                        <div>Delete</div>
                      </div>
                    )}
                  </Button>
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
      {editMode ? (
        <div className="py-4 mb-4">
          <ResourceNoteForm
            handleNoteSubmit={handleEditNote}
            isLoading={updateNoteReply.isLoading || updateResourceNote.isLoading}
            messageTitle="Edit Note"
            buttonText="Update Note"
            defaultMessage={note.message}
            setEditMode={setEditMode}
          />
        </div>
      ) : (
        <div ref={noteMessageRef}>
          <LineClampedTextContainer text={note.message ?? ''} className="pt-4" />
        </div>
      )}
      {!isReply && noteId && (
        <ResourceFileAttachments caseId={caseId} resourceId={noteId} restrictDelete={isViewOnly} />
      )}
      <div className="flex mb-2 text-sm gap-x-2" style={{ lineHeight: '40px' }}>
        {!isReply && !isViewOnly && !parentType && (
          <Button
            variant="ghost"
            className="text"
            textColor="primary"
            // @ts-ignore
            onClick={() => setActiveReplyId(note.noteId ?? '')}
          >
            Reply
          </Button>
        )}
      </div>
      <div className="pl-12">
        <Form {...replyForm}>
          <form onSubmit={replyForm.handleSubmit(handleCreateReply)}>
            {/* @ts-ignore */}
            {!isReply && activeReplyId === note.noteId && (
              <div className="flex flex-col mt-4">
                <TextFormInput
                  title="Reply"
                  type="textarea"
                  {...replyForm.register('message')}
                  allowUserTags
                  ref={replyRef}
                />
                <div className="flex mt-2 ml-auto gap-x-2">
                  <Button
                    onClick={() => setActiveReplyId('')}
                    type="button"
                    variant="outline"
                    size="sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={createNoteReply.isLoading}
                    type="submit"
                    variant="primary"
                    size="sm"
                    className="min-w-[50px]"
                  >
                    {createNoteReply.isLoading ? <GladiateLoader height={20} /> : <div>Reply</div>}
                  </Button>
                </div>
              </div>
            )}
          </form>
        </Form>
        <div>
          {!parentType &&
            !isReply &&
            // @ts-ignore
            note.replies &&
            // @ts-ignore
            note?.replies.length > 0 && (
              <Button
                className="text-sm text-gray-500"
                onClick={() => setShowReplies(!showReplies)}
              >
                —{showReplies ? 'Hide' : `View ${(note as NoteType)?.replies?.length}`} replies
              </Button>
            )}
          {!isReply &&
            showReplies &&
            // @ts-ignore
            note?.replies?.map((reply) => (
              <ResourceNote
                parentType={note.type}
                key={reply.noteReplyId}
                note={reply}
                isReply
                isViewOnly={isViewOnly}
                caseId={caseId}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ResourceNote;
