import {
  tanstackTableNames,
  useCreateUTBMSCode,
  useDeleteUTBMSCode,
  useGetUTBMSCodes,
  useUpdateUTBMSCode,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { UTBMSCodeType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './UTBMSCategoryDefaultsTableColumns';

/* eslint-disable-next-line */
export interface UTBMSCategoryDefaultsProps {}

const formSchema = z.object({
  name: z.string(),
  code: z.string(),
  description: z.string().optional(),
});

export function UTBMSCategoryDefaults(props: UTBMSCategoryDefaultsProps) {
  const [open, setOpen] = useState(false);
  const [openUTBMSCategory, setOpenUTBMSCategory] = useState<UTBMSCodeType | undefined>();
  const createCode = useCreateUTBMSCode();
  const updateCode = useUpdateUTBMSCode();
  const deleteCode = useDeleteUTBMSCode();
  const {
    data: UTBMSCodesData,
    isLoading: isUTBMSCodesLoading,
    isError: isUTBMSCodesError,
  } = useGetUTBMSCodes();
  const UTBMSCodesGrouped = UTBMSCodesData?.data?.map((item) => {
    return {
      ...item,
      groupName: item.industryStandard === '1' ? 'Industry Standard' : 'Custom',
    };
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      name: openUTBMSCategory?.name || '',
      code: openUTBMSCategory?.code || '',
      description: openUTBMSCategory?.description || '',
    },
  });

  const handleCreate = () => {
    createCode.mutateAsync().then((res) => {
      setOpen(true);
      setOpenUTBMSCategory(res?.data);
    });
  };

  const handleUpdate = (key: string, value: string) => {
    updateCode.mutate({
      caseExpenseUtbmsCodeId: openUTBMSCategory?.caseExpenseUtbmsCodeId ?? '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenUTBMSCategory(undefined);
    }
  }, [open, form]);

  const isIndustryStandard = openUTBMSCategory?.industryStandard === '1';

  return (
    <>
      <Slideover
        displayDeleteButton={!isIndustryStandard}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openUTBMSCategory?.caseExpenseUtbmsCodeId)
            deleteCode.mutateAsync(openUTBMSCategory?.caseExpenseUtbmsCodeId).then(() => {
              setOpenUTBMSCategory(undefined);
              setOpen(false);
            });
        }}
        title="UTBMS Code"
        description="Update your UTBMS code information here."
      >
        <Form {...form}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`code`)}
                title="Code"
                name="code"
                disabled={isIndustryStandard}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('code', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`name`)}
                title="Name"
                name="name"
                disabled={isIndustryStandard}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('name', target.value);
                }}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                name="description"
                type="textarea"
                disabled={isIndustryStandard}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">UTBMS Codes</h1>
            <p className="mt-2 text-sm text-gray-700">Your firm&apos;s UTBMS codes.</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton title="Add Code" loading={createCode.isLoading} onClick={handleCreate} />
          </div>
        </div>
        <DataTable
          data={UTBMSCodesGrouped || []}
          initialSort={{
            id: 'Name',
            desc: false,
          }}
          columns={columns}
          isLoading={isUTBMSCodesLoading}
          isError={isUTBMSCodesError}
          tableName={tanstackTableNames.utbmsCodes}
          handleRowClick={(item: Row<UTBMSCodeType>) => {
            if (!item.getIsGrouped()) {
              setOpenUTBMSCategory(item.original);
              setOpen(true);
            }
          }}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.utbmsCodes, {})}
          hideViewButton
          grouping={['Group']}
          autoResetExpanded={false}
          initialGroupingsExpanded={false}
        />
      </div>
    </>
  );
}

export default UTBMSCategoryDefaults;
