import { PolicyType, caseContactConnectionOptions } from '@gladiate/types';
import { CaseContactConnectionLinker } from '../../../CaseContactConnectionLinker/CaseContactConnectionLinker';

interface HealthContactConnectionsProps {
  caseId: string;
  policy: PolicyType | undefined;
}

export const HealthContactConnections: React.FC<HealthContactConnectionsProps> = ({
  caseId,
  policy,
}) => {
  return (
    <>
      <CaseContactConnectionLinker
        caseId={caseId}
        title="Insurer"
        roleOnCase="insurer"
        showMultiple
        itemType="policy"
        itemId={policy?.policyId}
      />

      <CaseContactConnectionLinker
        caseId={caseId}
        title="Policy Holder"
        roleOnCase={caseContactConnectionOptions.policyHolder}
        showMultiple
        itemType="policy"
        itemId={policy?.policyId}
      />
    </>
  );
};
