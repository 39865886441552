export const daysInStatusRelativeToGoalOptions = [
  {
    label: 'On Schedule',
    value: 'under',
  },
  {
    label: 'Overdue',
    value: 'over',
  },
  {
    label: 'Approaching',
    value: 'approaching',
  },
];

export const conflictsOptions = [
  {
    label: 'Present',
    value: 'yes',
  },
  {
    label: 'None',
    value: 'no',
  },
];
