// conscription types
import { ContactViewModelType } from './Contact';
import { caseContactConnectionOptions } from './connectionOptions';

export interface CaseContactConnectionRoleType {
  caseContactConnectionRoleId: string;
  dateCreated?: string;
  dateModified?: string;
  descriptionOfIncident?: string;
  favorableToClient?: boolean;
  firmId: string;
  positionInVehicle?: string;
  relationshipToClient?: string;
  roleOnCase: (typeof caseContactConnectionOptions)[keyof typeof caseContactConnectionOptions];
  wearingSeatBelt?: boolean;
  willingToTestify?: boolean;
  itemType?: string;
  itemId?: string;
}

export interface CaseContactConnectionViewModelType {
  caseContactConnectionId: string;
  roles: CaseContactConnectionRoleType[];
  contact: ContactViewModelType;
  dateCreated: string;
  dateModified: string;
  descriptionOfCaseInvolvement?: string;
  firmId: string;
  liabilityStatus?: string;
}

export interface CaseContactConnectionType {
  caseContactConnectionId: string;
  contactId: string;
  dateCreated?: string;
  dateModified?: string;
  descriptionOfCaseInvolvement?: string;
  firmId: string;
  liabilityStatus?: string;
  roles: CaseContactConnectionRoleType[];
  vehicleId?: string;
  contact?: ContactViewModelType;
}

export const caseContactConnectionPriorityOrder = [
  caseContactConnectionOptions.client,
  caseContactConnectionOptions.driver,
  caseContactConnectionOptions.witness,
  caseContactConnectionOptions.defendant,
];

export type CaseContactSubObject =
  | 'caseExpense'
  | 'discovery'
  | 'lien'
  | 'medicalTreatment'
  | 'medicalAppointment'
  | 'offlineEvent'
  | 'policy'
  | 'priorMedicalTreatment'
  | 'recordRequest'
  | 'settlementProposal'
  | 'trustAccountTransaction'
  | 'vehicle';
