export const registerChangeEvents = ({
  field,
  form,
  handleOnBlur,
  handleOnChange,
}: {
  field: any;
  form: any;
  handleOnBlur?: any;
  handleOnChange?: any;
}) => {
  // TODO: Fix this - unsure what is happening here
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...form.register(field.name, {
      onBlur: handleOnBlur,
      onChange: handleOnChange,
    }),
  };
};
