import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createStaffRoleV3,
  createStaffV3,
  deleteStaffRoleV3,
  deleteStaffV3,
  getAllStaffV3,
  getStaffRolesV3,
  getStaffV3,
  updateStaffRoleV3,
} from '../requests/legion';

export const useGetStaff = (caseId?: Parameters<typeof getStaffV3>[0]) => {
  return useQuery({
    queryKey: [queryKeys.staff, caseId],
    queryFn: async () => getStaffV3(caseId ?? ''),
    enabled: !!caseId,
  });
};

export const useGetFirmStaff = () => {
  return useQuery({
    queryKey: [queryKeys.staff],
    queryFn: async () => getAllStaffV3(),
  });
};

export const useCreateStaff = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createStaffV3>[0]) => createStaffV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff],
      });
    },
  });
};

export const useDeleteStaff = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (staffId: string) => deleteStaffV3(staffId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff],
      });
    },
  });
};

export const useGetStaffRoles = () => {
  return useQuery({
    queryKey: [queryKeys.staffRoles],
    queryFn: async () => getStaffRolesV3(),
  });
};

export const useCreateStaffRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createStaffRoleV3>[0]) => createStaffRoleV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staffRoles],
      });
    },
  });
};

export const useUpdateStaffRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateStaffRoleV3>[0]) => updateStaffRoleV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staffRoles],
      });
    },
  });
};

export const useDeleteStaffRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (roleId: string) => deleteStaffRoleV3(roleId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.staffRoles],
      });
    },
  });
};
