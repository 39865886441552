import { HurinUserType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateFirmUserAttributes } from '../../../lib/react-query/firm';
import { zodPhoneNumberType } from '../zodValidationTypes';

const formSchema = z.object({
  name: z.string().optional(),
  userName: z.string(),
  email: z.string().optional(),
  phone_number: zodPhoneNumberType.optional(),
  groupId: z.string().optional(),
});

const updatableKeys = ['name', 'email', 'phone_number'];

export const useUpdateCognitoUserForm = (
  user?: HurinUserType,
  parentFormValues?: z.infer<typeof formSchema>,
) => {
  const localForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    values: { ...user, userName: user?.username ?? '' },
  });

  const formValues = parentFormValues ?? localForm.getValues(); // If a form is passed in, we want to use that instead of creating a new one
  const updateUserMutation = useUpdateFirmUserAttributes(formValues?.userName ?? '');

  const handleUpdateCognitoUser = (name: string, value?: string | number | string[]) => {
    if (updatableKeys.includes(name)) {
      return updateUserMutation.mutateAsync({
        data: {
          [name]: value,
        },
      });
    } else {
      return new Promise<{ data: HurinUserType }>(() => {});
    }
  };

  return {
    form: localForm,
    handleUpdate: handleUpdateCognitoUser,
    isLoading: updateUserMutation.isLoading,
  };
};
