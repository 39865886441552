import { useGetHandwryttenCardsWithCategories, useGetHandwryttenFonts } from '@colosseum/data';
import { HandWryttenFont, HandwryttenCard, HandwryttenCardOrderDetails } from '@gladiate/types';
import { PlusCircle } from 'lucide-react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Typography from '../Typography/Typography';
import { FormLabel } from '../shadcn/Form/Form';
import Modal from '../shadcn/Modal/Modal';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';
import { Button } from '../shadcn/Button/Button';

const FontViewer = ({ fontSource }: { fontSource: string }) => {
  return (
    <>
      <style>{`
        @font-face {
          font-family: 'MyFont';
          src: url(${fontSource}) format('woff');
        }

        .font {
          font-family: 'MyFont';
          font-size: xx-large;
          max-width: 100%;
          max-height: 100%;
          line-height: normal;
        }
      `}</style>
      <p className="font">This is how your sample text will look!</p>
    </>
  );
};

const SelectCardEmptyState = ({
  setOpen,
  isLoading,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <>
        <div className="flex gap-2 animate-fade">
          <div className="rounded-md size-20 bg-slate-200">{''}</div>
          <div className="flex flex-col gap-2">
            <div className="flex h-6 rounded-md w-36 bg-slate-200">{''}</div>
            <div className="flex w-20 h-4 rounded-md bg-slate-200">{''}</div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="relative flex gap-2 ">
      <div className="flex gap-2 blur">
        <div className="rounded-md size-20 bg-slate-200">{''}</div>
        <div className="flex flex-col gap-2">
          <div className="flex h-6 rounded-md w-36 bg-slate-200">{''}</div>
          <div className="flex w-20 h-4 rounded-md bg-slate-200">{''}</div>
        </div>
      </div>
      <div className="absolute inset-0 flex items-center justify-center w-full">
        {' '}
        <Button
          variant="outline"
          size="sm"
          className="h-8 border-dashed"
          onClick={() => setOpen(true)}
          type="button"
        >
          <PlusCircle className="w-4 h-4 mr-2" />
          Select a card
        </Button>
      </div>
    </div>
  );
};

const SelectCard = ({
  cardId,
  fontId,
  cards,
  setOpen,
  setCardCategory,
}: {
  cardId: string | undefined;
  fontId: string | undefined;
  cards?: { [key: string]: HandwryttenCard };
  setOpen: Dispatch<SetStateAction<boolean>>;
  setCardCategory: Dispatch<SetStateAction<string>>;
}) => {
  const [selectedCard, setSelectedCard] = useState<HandwryttenCard>();
  const [font, setFont] = useState<HandWryttenFont>();
  const [isCardImageLoading, setIsCardImageLoading] = useState(false);

  const { data: handwryttenFontsData } = useGetHandwryttenFonts();

  const handwryttenFonts = handwryttenFontsData?.fonts;

  useEffect(() => {
    if (cardId) setIsCardImageLoading(true);
  }, []);

  useEffect(() => {
    const handwryttenFont = handwryttenFonts?.find((font) => font.id === fontId);
    if (!handwryttenFont) {
      return;
    }
    setFont(handwryttenFont);
  }, [handwryttenFonts, fontId]);

  useEffect(() => {
    const handwryttenCard = cardId && cards?.[cardId];
    if (!handwryttenCard) {
      return;
    }
    setSelectedCard(handwryttenCard);
  }, [cards, cardId]);

  if (!selectedCard) {
    return (
      <>
        <SelectCardEmptyState setOpen={setOpen} isLoading={isCardImageLoading} />
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className="flex flex-row items-center w-full gap-3"
        onClick={() => {
          selectedCard && setCardCategory(selectedCard.category_name);
          setOpen(true);
        }}
      >
        <img
          src={selectedCard?.cover}
          alt={selectedCard?.name}
          className="object-cover rounded-md shadow-md w-28"
          id="selected-card-image"
          onLoad={() => {
            setIsCardImageLoading(true);
          }}
        />
        <div className="flex flex-col self-stretch justify-between text-justify ">
          <div>
            <Typography size={'lg'} variant={'semiBold'} className="tracking-wide">
              {selectedCard?.name}
            </Typography>
            <Typography
              size={'sm'}
              color={'blue'}
              className="inline-block w-auto px-1 bg-blue-200 rounded-md "
            >
              {selectedCard?.category_name}
            </Typography>
          </div>

          {!font && selectedCard && (
            <Typography size={'sm'} color={'red'}>
              No Font Selected
            </Typography>
          )}

          {font && selectedCard && (
            <div className="pt-1 ">
              <FontViewer fontSource={font?.path} />
            </div>
          )}
        </div>
      </button>
    </>
  );
};

export interface HandwryttenSelectProps {
  setCardOrder: React.Dispatch<React.SetStateAction<HandwryttenCardOrderDetails>>;
  setCharacterCount?: React.Dispatch<React.SetStateAction<number>>;
  handwryttenCardOrderDetails?: {
    fontId: string | undefined;
    cardId: string | undefined;
  };
}

export function HandwryttenSelectForm(props: HandwryttenSelectProps) {
  const { setCardOrder, setCharacterCount, handwryttenCardOrderDetails } = props;
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data: handwryttenCards } = useGetHandwryttenCardsWithCategories();
  const { data: handwryttenFonts } = useGetHandwryttenFonts();
  // #endregion

  // #region State
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedCard, setSelectedCard] = useState<string | undefined>(
    handwryttenCardOrderDetails?.cardId,
  );
  const [selectedFont, setSelectedFont] = useState<string | undefined>(
    handwryttenCardOrderDetails?.fontId,
  );
  const [cardsForCategory, setCardsForCategory] = useState<any[]>();
  const [isOpen, setOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  // #endregion

  // #region Derived State
  const allCardsData = handwryttenCards?.cards;
  const allCardsFlat = allCardsData?.map((category) => category.value).flat();
  const allCardsMap = allCardsFlat?.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item,
    };
  }, {} as { [key: string]: HandwryttenCard });

  const cardCategories = allCardsData
    ?.map((category) => category.key)
    .filter((category) => category !== 'Customizable Cards')
    .sort();
  // #endregion

  // #region useEffects
  useEffect(() => {
    setCardsForCategory(allCardsData?.find((card) => card.key === selectedCategory)?.value);
  }, [selectedCategory]);

  useEffect(() => {
    // A small delay to allow the card images to render / load from the browser cache before scrolling
    selectedCard &&
      setTimeout(() => {
        document?.getElementById(selectedCard)?.scrollIntoView({ behavior: 'smooth' });
      }, 50);
  }, [isOpen, selectedCard]);

  useEffect(() => {
    setCardOrder &&
      selectedCard &&
      setCardOrder({
        cardId: parseInt(selectedCard),
        fontId: selectedFont,
      });
  }, [selectedCard, selectedFont]);

  // #endregion

  // #region Event Handlers
  // #endregion

  return (
    <div>
      <div className="flex flex-col gap-4">
        {handwryttenFonts?.fonts && (
          <>
            <Select
              onValueChange={(value) => {
                const font = handwryttenFonts?.fonts?.find((font) => font.id === value);
                if (font) setSelectedFont(font.id.toString());
              }}
              value={selectedFont}
            >
              <SelectTrigger className="relative w-full">
                <FormLabel>Font</FormLabel>
                <SelectValue placeholder="Select a Font" />
              </SelectTrigger>
              <SelectContent>
                {[
                  { fontLabel: 'Print', fontId: 'hwCaitlin' },
                  { fontLabel: 'Cursive', fontId: 'hwJenna' },
                  { fontLabel: 'Uppercase', fontId: 'hwWill' },
                ].map((font) => (
                  <SelectItem key={font.fontLabel} value={font.fontId}>
                    {font.fontLabel}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}

        <div className="relative w-full p-3 border border-gray-300 rounded-md">
          <Select
            onValueChange={(value) => {
              const card = allCardsFlat?.find((card) => card.id === parseInt(value));
              if (card) setSelectedCard(card.id.toString());

              setCharacterCount && setCharacterCount(card?.characters || 0);
              setOpen(false);
            }}
            value={selectedCard}
          >
            <div>
              <FormLabel>Card</FormLabel>
            </div>

            <SelectCard
              cardId={selectedCard}
              fontId={selectedFont}
              cards={allCardsMap}
              setOpen={setOpen}
              setCardCategory={setSelectedCategory}
            />
            <SelectContent>
              <Modal
                width="w-[80vw]"
                height="h-[80vh]"
                open={isOpen}
                setOpen={setOpen}
                title="Handwrytten Cards"
                hideAllInteraction={true}
                description="First select a category from the list below and then click on the card to select it."
              >
                <div className="grid grid-cols-12 pb-4 overflow-auto">
                  <div className="col-span-3 overflow-hidden hover:overflow-y-scroll ">
                    <div className="w-full h-full">
                      {!cardCategories ? (
                        <>
                          {[...Array(20)].map((_, i) => (
                            <>
                              <div
                                key={i}
                                className="w-full h-8 my-2 rounded-md bg-slate-200 animate-fade"
                              ></div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <ul className="space-y-2 font-medium bg-gray-50">
                            {cardCategories?.map((category) => (
                              <button
                                key={category}
                                className={`flex w-full items-center p-2 text-gray-900 rounded-lg dark:text-white 
                              ${
                                selectedCategory === category
                                  ? 'hover:bg-blue-200 '
                                  : 'hover:bg-gray-100 '
                              }
                              ${selectedCategory === category ? 'bg-blue-100' : ''}`}
                                type="button"
                                onClick={() => {
                                  setSelectedCategory(category);
                                }}
                              >
                                <span className="">{category}</span>
                              </button>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    ref={modalRef}
                    id="handwrytten-card-modal"
                    className="grid grid-flow-row grid-cols-3 col-span-9 gap-4 overflow-auto"
                  >
                    {selectedCategory ? (
                      <>
                        {cardsForCategory?.map((card) => (
                          <SelectItem key={card.id} value={card.id.toString()}>
                            <button className="flex flex-col gap-4" id={card.id.toString()}>
                              <img
                                src={card.cover}
                                alt={card.name}
                                className="object-contain p-2 rounded-md shadow-md size-96"
                              />
                              <div className="flex-col self-center">
                                <Typography size={'lg'}>{card.name}</Typography>
                                <Typography className="inline-block w-auto px-1 text-blue-500 bg-blue-200 rounded-md">
                                  {card.category_name}
                                </Typography>
                              </div>
                            </button>
                          </SelectItem>
                        ))}
                      </>
                    ) : (
                      <div className="flex self-center justify-center col-span-3">
                        <div className="flex flex-col items-center gap-2 p-2 rounded-md">
                          <Typography size={'3xl'} color={'black'}>
                            Choose a category...
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default HandwryttenSelectForm;
