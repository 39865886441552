import { useGetFirmMedicalTreatments } from '../lib/react-query/medical-treatment';
import { flattenArrayByKey } from '../utils/miscUtils';
import { useCompleteMedicalTreatmentValues } from './useCompleteMedicalTreatmentValues';
import { useGetCasesComplete } from './useGetCasesComplete';

// TODO: When Medical Requests V3 is done, replace this so we're not mapping through cases and making n api calls
export const useGetInsightsMedicalViewModel = () => {
  const { data: casesData, isLoading: casesDataLoading } = useGetCasesComplete();

  const { data: treatmentsDataFirmWide, isLoading: treatmentsLoading } =
    useGetFirmMedicalTreatments();

  const mappedData = casesData.data?.map((caseData) => {
    const { getCompleteMedicalTreatmentValues } = useCompleteMedicalTreatmentValues(
      caseData.caseId,
    );
    const complete =
      treatmentsDataFirmWide && getCompleteMedicalTreatmentValues(treatmentsDataFirmWide?.data);

    return {
      ...caseData,
      completeTreatments: complete?.completeTreatments.filter((completeTreatment) =>
        caseData.medicalTreatments
          ?.map((item) => item.medicalTreatmentId)
          .includes(completeTreatment.medicalTreatmentId),
      ),
    };
  });

  return {
    data: {
      data: flattenArrayByKey(mappedData, 'completeTreatments'),
    },
    isLoading: casesDataLoading || treatmentsLoading,
  };
};
