import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createCaseCampaignTriggerV3,
  createCaseCampaignV3,
  createContactCampaignTriggerV3,
  createContactCampaignV3,
  deleteCaseCampaignTriggerV3,
  deleteCaseCampaignV3,
  deleteContactCampaignTriggerV3,
  deleteContactCampaignV3,
  getCaseCampaignV3,
  getContactCampaignV3,
  getListCaseCampaignsV3,
  getListContactCampaignsV3,
  updateCaseCampaignSubscriptionV3,
  updateCaseCampaignTriggerV3,
  updateCaseCampaignV3,
  updateContactCampaignSubscriptionV3,
  updateContactCampaignTriggerV3,
  updateContactCampaignV3,
} from '../requests/gordian-knot';
import {
  getHandwryttenCards,
  getHandwryttenCardsWithCategories,
  getHandwryttenFonts,
} from '../requests/handwrytten';
import {
  getCaseCampaignSubscriptionsV3,
  getContactCampaignSubscriptionsV3,
} from '../requests/sibyls';

// #region Case Campaign
/**
 *
 * @param caseCampaignId
 * @description Get a single case campaign by id
 * @returns  CaseCampaign
 */
export const useGetCaseCampaign = (caseCampaignId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseCampaigns, caseCampaignId],
    queryFn: async () => getCaseCampaignV3(caseCampaignId),
  });
};

/**
 * @description Get all case campaigns
 * @returns List of case campaigns
 */
export const useGetCaseCampaigns = () => {
  return useQuery({
    queryKey: [queryKeys.caseCampaigns],
    queryFn: async () => getListCaseCampaignsV3(),
  });
};

/**
 * @description Delete a case campaign
 */
export const useDeleteCaseCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (caseCampaignId: Parameters<typeof deleteCaseCampaignV3>[0]) =>
      deleteCaseCampaignV3(caseCampaignId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns],
      });
    },
  });
};

/**
 * @description Create a case campaign
 */
export const useCreateCaseCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createCaseCampaignV3>[0]) =>
      createCaseCampaignV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns],
      });
    },
  });
};

/**
 * @description Update a case campaign
 */
export const useUpdateCaseCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateCaseCampaignV3>[0]) =>
      updateCaseCampaignV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns, variables?.caseCampaignId],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaignSubscriptions, variables?.caseCampaignId],
      });
    },
  });
};

/**
 * @description Create a case campaign trigger
 */
export const useCreateCaseCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createCaseCampaignTriggerV3>[0]) =>
      createCaseCampaignTriggerV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns, variables?.caseCampaignId],
      });
    },
  });
};

/**
 * @description Update a case campaign trigger
 */
export const useUpdateCaseCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateCaseCampaignTriggerV3>[0]) =>
      updateCaseCampaignTriggerV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns, variables?.caseCampaignTriggerId],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns],
      });
    },
  });
};

/**
 * @description Delete a case campaign trigger
 */
export const useDeleteCaseCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (caseCampaignTriggerId: Parameters<typeof deleteCaseCampaignTriggerV3>[0]) =>
      deleteCaseCampaignTriggerV3(caseCampaignTriggerId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns],
      });
    },
  });
};

/**
 * @description Get all case campaigns subscriptions
 */
export const useUpdateCaseCampaignSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateCaseCampaignSubscriptionV3>[0]) =>
      updateCaseCampaignSubscriptionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaignSubscriptions],
      });
    },
  });
};

/**
 * @description Get all case campaigns subscriptions
 */
export const useGetCaseCampaignSubscriptions = (caseCampaignId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseCampaignSubscriptions, caseCampaignId],
    queryFn: async () => getCaseCampaignSubscriptionsV3(caseCampaignId),
  });
};
// #endregion

// #region Contact Campaign
/**
 * @description Get all contact campaigns
 */
export const useGetContactCampaigns = () => {
  return useQuery({
    queryKey: [queryKeys.contactCampaigns],
    queryFn: async () => getListContactCampaignsV3(),
  });
};

/**
 * @description Get a contact campaign by id
 * @param contactCampaignId
 */
export const useGetContactCampaign = (contactCampaignId: string) => {
  return useQuery({
    queryKey: [queryKeys.contactCampaigns, contactCampaignId],
    queryFn: async () => getContactCampaignV3(contactCampaignId),
  });
};

/**
 * @description Create a contact campaign
 */
export const useCreateContactCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createContactCampaignV3>[0]) =>
      createContactCampaignV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns],
      });
    },
  });
};

/**
 * @description Delete a contact campaign
 */
export const useDeleteContactCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (contactCampaignId: Parameters<typeof deleteContactCampaignV3>[0]) =>
      deleteContactCampaignV3(contactCampaignId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns],
      });
    },
  });
};

/**
 * @description Update a contact campaign
 */
export const useUpdateContactCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateContactCampaignV3>[0]) =>
      updateContactCampaignV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaignSubscriptions],
      });
    },
  });
};

/**
 * @description Create a contact campaign trigger
 */
export const useCreateContactCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof createContactCampaignTriggerV3>[0]) =>
      createContactCampaignTriggerV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns, variables?.contactCampaignId],
      });
    },
  });
};

/**
 * @description Update a contact campaign trigger
 */
export const useUpdateContactCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateContactCampaignTriggerV3>[0]) =>
      updateContactCampaignTriggerV3(data),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseCampaigns, variables?.contactCampaignTriggerId],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns],
      });
    },
  });
};

/**
 * @description Delete a contact campaign trigger
 */
export const useDeleteContactCampaignTrigger = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      contactCampaignTriggerId: Parameters<typeof deleteContactCampaignTriggerV3>[0],
    ) => deleteContactCampaignTriggerV3(contactCampaignTriggerId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaigns],
      });
    },
  });
};

/**
 * @description update a Contact Campaign Subscription
 */
export const useUpdateContactCampaignSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: Parameters<typeof updateContactCampaignSubscriptionV3>[0]) =>
      updateContactCampaignSubscriptionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contactCampaignSubscriptions],
      });
    },
  });
};

/**
 * @description Get all contact campaigns subscriptions
 */
export const useGetContactCampaignSubscriptions = (contactCampaignId: string) => {
  return useQuery({
    queryKey: [queryKeys.contactCampaignSubscriptions, contactCampaignId],
    queryFn: async () => getContactCampaignSubscriptionsV3(contactCampaignId),
  });
};
// #endregion

// #region Handwrytten service
/**
 * @description Get all handwrytten cards with categories
 */
export const useGetHandwryttenCardsWithCategories = () => {
  return useQuery({
    queryKey: ['handwryttenCards'],
    queryFn: async () => getHandwryttenCardsWithCategories(),
  });
};

/**
 * @description Get all handwrytten cards
 */
export const useGetHandwryttenCards = () => {
  return useQuery({
    queryKey: ['handwryttenCards'],
    queryFn: async () => getHandwryttenCards(),
  });
};

/**
 * @description Get all handwrytten fonts
 */
export const useGetHandwryttenFonts = () => {
  return useQuery({
    queryKey: ['handwryttenFonts'],
    queryFn: async () => getHandwryttenFonts(),
  });
};

// #endregion
