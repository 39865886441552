import {
  cn,
  displayContactName,
  tanstackTableNames,
  useGetContactsInfinite,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetRecordRequestTaskLineup,
} from '@colosseum/data';
import { DataTable, ResourceSlideover, TabsContent } from '@colosseum/shared-ui';
import { caseContactConnectionOptions } from '@gladiate/types';
import { startCase, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { ManualRecordRequestForm } from '../../cases/ManualRecordRequestForm.tsx/ManualRecordRequestForm';
import ViewOnlyRecordRequestsForm from '../../cases/ViewOnlyRecordRequestsForm/ViewOnlyRecordRequestsForm';
import { recordRequestTaskLineupColumns } from './lineup-record-request-tasks-columns';

export function RecordRequestTasksLineupsTable() {
  const { data: recordRequestTasksLineupData, isLoading: isRecordRequestTasksLoading } =
    useGetRecordRequestTaskLineup();
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();

  const [showModal, setShowModal] = useState(false);
  const [manualRecordSlideoverOpen, setManualRecordSlideoverOpen] = useState(false);
  const [viewOnlyOpen, setViewOnlyOpen] = useState(false);
  const [updateRecordRequest, setUpdateRecordRequest] = useState(false);
  const [activeTaskId, setActiveTaskId] = useState<string | undefined>();

  const tableData = recordRequestTasksLineupData?.data.map((row) => {
    const client =
      row?.case?.caseContactConnections?.length > 0
        ? row?.case?.caseContactConnections?.filter((connection) =>
            connection.roles.some(
              (role) => role.roleOnCase === caseContactConnectionOptions.client,
            ),
          )?.[0]?.contact
        : null;
    const provider =
      row?.case?.caseContactConnections?.length > 0
        ? row?.case?.caseContactConnections?.filter((connection) =>
            connection.roles.some(
              (role) => role.roleOnCase === caseContactConnectionOptions.recordsProvider,
            ),
          )?.[0]?.contact
        : null;

    // const providerPhone =
    //   provider?.contactNumbers?.filter((number) => number.numberType === 'phone')?.[0]?.number ??
    //   '';

    return {
      ...row,
      client,
      caseTitle: row.case?.caseTitle ?? '',
      recordRequest: {
        ...row.recordRequest,
      },
      providerName: displayContactName(provider ?? {}),
      // providerPhone: providerPhone,
      // providerEmail: provider?.contactEmails?.[0]?.emailAddress ?? '',
      // providerAddress: formatAddress(provider?.contactAddresses?.[0] ?? {}),
      assigneesDisplayNames: row.assignees.map((assignee) => {
        const user = getFirmUserWithDisplayNameFromUsername(assignee.userAssigned);
        return user?.displayName ?? '';
      }),
      assignersDisplayNames: row.assigners.map((assigner) => {
        const user = getFirmUserWithDisplayNameFromUsername(assigner.userAssigned);
        return user?.displayName ?? '';
      }),
    };
  });
  const activeTask = tableData?.find((task) => task.taskId === activeTaskId);

  const tableFilters = useMemo(() => {
    const filters: {
      id: string;
      options: { value: string; label: string }[];
    }[] = [
      {
        id: 'Case',
        options:
          uniqBy(
            tableData?.filter((expense) => expense.case?.caseTitle),
            (expense) => expense.case?.caseId,
          )?.map((expense) => ({
            value: expense?.case?.caseTitle ?? '',
            label: expense?.case?.caseTitle ?? '',
          })) ?? [],
      },
      {
        id: 'Status',
        options:
          uniqBy(tableData, 'status').map((row) => ({
            value: startCase(row?.status) ?? '',
            label: startCase(row?.status) ?? '',
          })) ?? [],
      },
      {
        id: 'Client',
        options:
          uniqBy(
            tableData?.filter((row) => row.client),
            (row) => row.client?.contactId,
          )?.map((expense) => ({
            value: expense?.client ? displayContactName(expense?.client) : '',
            label: expense?.client ? displayContactName(expense?.client) : '',
          })) ?? [],
      },
      {
        id: 'Provider Name',
        options:
          uniqBy(
            tableData?.filter((row) => row.providerName),
            (row) => row.providerName,
          )?.map((row) => ({
            value: row?.providerName ?? '',
            label: row?.providerName ?? '',
          })) ?? [],
      },
    ];
    return filters;
  }, [recordRequestTasksLineupData]);

  return (
    <div>
      <ResourceSlideover
        title={'Medical Record Requests'}
        description={'Edit and review your records requests.'}
        open={viewOnlyOpen}
        setOpen={setViewOnlyOpen}
        displayDeleteButton={false}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setViewOnlyOpen(false);
              }}
            >
              Close
            </button>
            <button
              type="submit"
              onClick={() => {
                setUpdateRecordRequest(true);
              }}
              className={cn(
                'inline-flex  justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                ' bg-sky-blue hover:bg-atlantic-blue  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue ',
              )}
            >
              Edit Request
            </button>
          </div>
        }
        createType="medicalRecordRequest"
        resourceId={activeTask?.recordRequest?.requestId ?? ''}
        caseId={activeTask?.case?.caseId ?? ''}
        typing={false}
      >
        <TabsContent value="details">
          <ViewOnlyRecordRequestsForm
            viewOnlyRecordRequest={activeTask?.recordRequest}
            setViewOnlyOpen={setViewOnlyOpen}
            updateRecordRequest={updateRecordRequest}
            setUpdateRecordRequest={setUpdateRecordRequest}
          />
        </TabsContent>
      </ResourceSlideover>
      <ResourceSlideover
        caseId={activeTask?.case?.caseId ?? ''}
        title="Manual Medical Record Requests"
        description="Edit and review your manual records requests."
        open={manualRecordSlideoverOpen}
        setOpen={setManualRecordSlideoverOpen}
        typing={false}
        displayDeleteButton={true}
        deleteFunction={() => {
          if (activeTaskId) {
            //   deleteManualRecordRequest.mutateAsync(selectedManualRecordId).then(() => {
            //     if (medicalProviderId) {
            //       queryClient.invalidateQueries([queryKeys.medicalTreatments]);
            //     }
            //   });
          }
        }}
        onClose={() => setActiveTaskId(undefined)}
        createType="medicalRecordRequest"
        resourceId={activeTask?.recordRequest?.requestId ?? ''}
      >
        <TabsContent value="details">
          <ManualRecordRequestForm
            caseId={activeTask?.case?.caseId ?? ''}
            data={activeTask?.recordRequest}
          />
        </TabsContent>
      </ResourceSlideover>

      <DataTable
        tableName={tanstackTableNames.lineupRecordRequestTasks}
        columns={recordRequestTaskLineupColumns}
        // @ts-expect-error this needs to be typed better
        data={tableData ?? []}
        showSearchBar
        disableAutoResetPageIndex
        isLoading={isRecordRequestTasksLoading}
        filters={tableFilters}
        handleRowClick={(row) => {
          if (row.original.recordRequest.logMechanism === 'manual') {
            setManualRecordSlideoverOpen(true);
          } else if (row.original.recordRequest.logMechanism === 'arctrieval') {
            setViewOnlyOpen(true);
          }
          setActiveTaskId(row.original.taskId);
          setShowModal(true);
        }}
        /*
        ----- THESE ARE FROM EXPENSE LINEUPS -----
        */
      />
    </div>
  );
}

export default RecordRequestTasksLineupsTable;
