/* eslint-disable-next-line */
export interface ShimmerSkeletonWrapperProps {
  children: React.ReactNode;
}

export function ShimmerSkeletonWrapper({ children }: ShimmerSkeletonWrapperProps) {
  return (
    <div className="relative overflow-hidden bg-white/5 before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] rounded-xl before:border-t before:bg-gradient-to-r before:from-transparent before:via-slate-500/10 before:to-transparent">
      {children}
    </div>
  );
}

export default ShimmerSkeletonWrapper;
