import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createEventAttendeeV3,
  createEventCategoryV3,
  createOfflineEventV3,
  deleteEventAttendeeV3,
  deleteEventCategoryV3,
  deleteOfflineEventV3,
  getEventCategoriesV3,
  getOfflineEventsV3,
  updateEventCategoryV3,
  updateOfflineEventV3,
} from '../requests/pheidippides';

export const useGetOfflineEvents = (data: Parameters<typeof getOfflineEventsV3>[0]) => {
  const { caseId, userId } = data;
  return useQuery({
    queryKey: [queryKeys.offlineEvents, caseId, userId],
    queryFn: () => getOfflineEventsV3({ caseId, userId }),
  });
};

export const useCreateOfflineEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createOfflineEventV3>[0]) => createOfflineEventV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.offlineEvents],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateOfflineEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateOfflineEventV3>[0]) => updateOfflineEventV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.offlineEvents],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteOfflineEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (eventId: string) => deleteOfflineEventV3(eventId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.offlineEvents],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
    onSuccess: (res: any) => {
      if (res?.meta?.userMsg) {
        enqueueAPISnackbar({ message: res.meta.userMsg, variant: 'success' });
      } else {
        enqueueAPISnackbar({ message: 'Event deleted', variant: 'success' });
      }
    },
  });
};

// #region ------------------------- Event Categories -------------------------

export const useGetEventCategories = () => {
  return useQuery({
    queryKey: [queryKeys.eventCategories],
    queryFn: () => getEventCategoriesV3(),
  });
};

export const useCreateEventCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createEventCategoryV3>[0]) => createEventCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventCategories],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateEventCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateEventCategoryV3>[0]) => updateEventCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventCategories],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteEventCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (categoryId: string) => deleteEventCategoryV3(categoryId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventCategories],
      });
    },
    onSuccess: (res: any) => {
      if (res?.meta?.userMsg) {
        enqueueAPISnackbar({ message: res.meta.userMsg, variant: 'success' });
      } else {
        enqueueAPISnackbar({ message: 'Event deleted', variant: 'success' });
      }
    },
  });
};
// #endregion

// #region ------------------------- Event Attendees -------------------------
export const useCreateEventAttendee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createEventAttendeeV3>[0]) => createEventAttendeeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.offlineEvents],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteEventAttendee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteEventAttendeeV3>[0]) => deleteEventAttendeeV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.offlineEvents],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

// #endregion
