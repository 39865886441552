export interface chipColorMapType {
  [key: string]:
    | 'red'
    | 'rose'
    | 'yellow'
    | 'gold'
    | 'green'
    | 'emerald'
    | 'teal'
    | 'blue'
    | 'sky'
    | 'purple'
    | 'violet'
    | 'gray'
    | 'orange'
    | 'stone'
    | undefined;
}

export const roleColorMap: chipColorMapType = {
  additionalContact: 'orange',
  adjuster: 'red',
  ambulance: 'rose',
  attorney: 'yellow',
  billingEntity: 'sky',
  bodilyInjuryAdjuster: 'green',
  client: 'gold',
  coCounsel: 'emerald',
  decedent: 'sky',
  defendant: 'blue',
  defenseCounsel: 'teal',
  driver: 'purple',
  expert: 'red',
  firstResponder: 'violet',
  hospital: 'orange',
  insuranceEntity: 'red',
  insuranceRepresentative: 'rose',
  insurer: 'stone',
  judge: 'yellow',
  judgeAssistant: 'gold',
  lawFirm: 'blue',
  lienHolder: 'green',
  lienProvider: 'emerald',
  mediator: 'teal',
  medicalProvider: 'blue',
  medPayAdjuster: 'sky',
  owner: 'purple',
  party: 'violet',
  passenger: 'gray',
  payee: 'orange',
  payor: 'blue',
  pipAdjuster: 'rose',
  plaintiff: 'yellow',
  policyHolder: 'sky',
  primaryContact: 'red',
  propertyDamageAdjuster: 'purple',
  referralFromContact: 'violet',
  referToAttorney: 'orange',
  referToFirm: 'red',
  rentalCarCompany: 'rose',
  repairShop: 'yellow',
  representative: 'gold',
  servingDiscovery: 'green',
  subrogationCompany: 'sky',
  subrogationRepresentative: 'purple',
  towCompany: 'emerald',
  umUimAdjuster: 'teal',
  vendor: 'blue',
  witness: 'purple',
} as const;

export const contactConnectionRoleColorMap: chipColorMapType = {
  branch: 'red',
  child: 'sky',
  connection: 'green',
  courtWard: 'blue',
  directManager: 'yellow',
  directReport: 'gold',
  emergencyContact: 'emerald',
  emergencyContactDependant: 'teal',
  employee: 'red',
  employer: 'rose',
  family: 'yellow',
  friend: 'gold',
  guardian: 'blue',
  guardianAdLitem: 'sky',
  headquarters: 'purple',
  healthClub: 'purple',
  healthClubMember: 'violet',
  parent: 'gray',
  primaryContact: 'stone',
  primaryContactDependant: 'red',
  spouse: 'green',
  union: 'gray',
  unionMember: 'orange',
  ward: 'rose',
} as const;

export const campaignStatusColorMap: chipColorMapType = {
  draft: 'yellow',
  active: 'green',
  past: 'blue',
  paused: 'stone',
  inactive: 'rose',
} as const;

export const billingHistoryStatusMap: chipColorMapType = {
  success: 'green',
  pending: 'yellow',
  failed: 'red',
} as const;

export const recordsRequestStatusMap: chipColorMapType = {
  cancelled: 'red',
  canceled: 'red',
  resend: 'yellow',
  're-sent': 'yellow',
  reopen: 'teal',
  completed: 'blue',
  open: 'green',
  'past-due': 'orange',
} as const;

export const allChipsColorMap: chipColorMapType = {
  'invalid-email': 'red',
  'medical-record-request': 'blue',
  'medical-treatment': 'yellow',
  'primary-contact': 'purple',
  'settlement-proposal': 'red',
  'third party': 'yellow',
  'trust-account-transaction': 'orange',
  case: 'green',
  contact: undefined,
  default: undefined,
  expense: 'purple',
  reply: undefined,
  sent: 'yellow',
  signed: 'green',
  template: undefined,
  ...billingHistoryStatusMap,
  ...campaignStatusColorMap,
  ...roleColorMap,
  ...recordsRequestStatusMap,
  ...contactConnectionRoleColorMap,
} as const;
