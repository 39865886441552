import { useGetCase, useUpdateCaseData } from '../lib/react-query/case';

export const useSetCaseTitle = (caseId: string) => {
  const updateCase = useUpdateCaseData();
  const caseData = useGetCase(caseId); // get case data to check if title has already been set
  const setCaseTitleFromName = ({
    firstName,
    lastName,
  }: {
    firstName?: string;
    lastName?: string;
  }) => {
    // we dont want to set title if: no first name, no last name, or if case already has a title
    if (!firstName || !lastName || caseData.isLoading || caseData.data?.data?.caseTitle) return;
    updateCase.mutate({
      caseId,
      caseTitle: `${lastName}, ${firstName}`,
    });
  };
  return setCaseTitleFromName;
};
