import { cn } from '@colosseum/data';
import {
  ResourceFileAttacher,
  ResourceFileAttacherProps,
  ResourceFileAttachments,
} from '@colosseum/shared-ui';
import { ResourceFileAttachmentType } from '@gladiate/types';
import { useMemo, useState } from 'react';

interface IncidentFormFilesProps {
  caseId: string;
}

const INCIDENT_REPORT_SECTIONS = [
  {
    title: 'Incident Report Files',
    resourceId: 'incident-files',
  },
  {
    title: "Drivers' License files",
    resourceId: 'drivers-license-files',
  },
  {
    title: 'Police Report Files',
    resourceId: 'police-report-files',
  },
];

export const IncidentFormFiles: React.FC<IncidentFormFilesProps> = ({ caseId }) => {
  const [files, setFiles] = useState<ResourceFileAttachmentType[]>([]);
  const unavailableResources = useMemo(() => {
    return INCIDENT_REPORT_SECTIONS.map((section) => {
      return files
        .filter((file) => file.designation === section.resourceId)
        .map((file) => ({
          resourceId: file.resourceId,
          reason: 'File already attached',
        }));
    });
  }, [files]);

  return (
    <div className="flex w-full flex-col justify-between gap-x-2">
      {INCIDENT_REPORT_SECTIONS.map((incident, idx) => (
        <div
          key={incident.resourceId}
          className={cn('flex flex-col w-full flex-grow text-sm pr-4 animate-in fade-in')}
        >
          <div className="flex items-center gap-x-2">
            <h1>{incident.title}</h1>

            <ResourceFileAttacher
              unavailableResources={
                unavailableResources[idx] as ResourceFileAttacherProps['unavailableResources']
              }
              caseId={caseId}
              resourceId={caseId}
              type={'discovery'}
              designation={incident.resourceId}
            />
          </div>

          <ResourceFileAttachments
            inSlideover={true}
            designation={incident.resourceId}
            resourceId={caseId}
            caseId={caseId}
            setFiles={setFiles}
          />
        </div>
      ))}
    </div>
  );
};
