import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { TooltipWrapper } from '../TooltipWrapper/TooltipWrapper';

/* eslint-disable-next-line */
export interface TooltipProps {
  message?: string;
}

export function Tooltip(props: TooltipProps) {
  return (
    <TooltipWrapper message={props.message}>
      <span className="ml-1 text-gray-400">
        <InformationCircleIcon className="w-4 h-4" />
      </span>
    </TooltipWrapper>
  );
}

export default Tooltip;
