import { cn } from '@colosseum/data';

export const WavIcon = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 303.188 303.188"
    className={cn('w-8 h-8 min-w-8', className)}
  >
    <g>
      <polygon
        style={{ fill: '#E8E8E8' }}
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
      />
      <g>
        <circle style={{ fill: '#38A3FF' }} cx="150.127" cy="122.142" r="59.225" />
      </g>
      <path
        style={{ fill: '#333E48' }}
        d="M167.622,89.613c-8.07-4.134-11.744-6.569-11.744-11.455l-4.974,0.876v54.963
		c-2.645-0.313-5.585-0.241-8.699,0.308c-11.341,2.001-20.533,9.387-20.533,16.499c0,7.11,9.192,11.256,20.533,9.257
		c11.341-2.001,20.533-9.388,20.533-16.497V106.32c0-9.05,9.061-8.18,9.409,3.479c0.349,11.66-4.698,14.791-4.698,14.791l5.22,2.262
		C183.982,115.542,180.566,96.243,167.622,89.613z"
      />
      <g>
        <path
          style={{ fill: '#A4A9AD' }}
          d="M125.217,273.871h-15.325l-4.595-20.904c-0.22-0.875-0.563-2.615-1.034-5.219
			c-0.471-2.604-0.814-4.779-1.033-6.53c-0.176,1.423-0.459,3.189-0.854,5.3s-0.783,4.053-1.165,5.825
			c-0.383,1.773-1.975,8.948-4.774,21.528H81.11l-11.88-47.979h12.503l5.219,24.056c1.182,5.316,1.99,9.55,2.428,12.7
			c0.284-2.231,0.793-5.262,1.526-9.091c0.733-3.828,1.417-7,2.052-9.517l4.232-18.148h12.012l4.102,18.148
			c0.701,2.909,1.412,6.269,2.134,10.075c0.722,3.807,1.202,6.65,1.444,8.532c0.283-2.429,1.061-6.64,2.33-12.635l5.316-24.121
			h12.503L125.217,273.871z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M172.671,273.871l-2.362-8.992H154.72l-2.428,8.992h-14.243l15.654-48.176h17.295l15.851,48.176
			H172.671z M167.617,254.246l-2.067-7.876c-0.481-1.751-1.066-4.015-1.756-6.794c-0.689-2.777-1.144-4.768-1.361-5.973
			c-0.197,1.116-0.586,2.954-1.165,5.514c-0.58,2.561-1.866,7.604-3.856,15.129L167.617,254.246L167.617,254.246z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M218.977,225.892h14.506l-15.72,47.979h-15.26l-15.654-47.979h14.571l6.53,24.317
			c1.357,5.404,2.101,9.168,2.232,11.29c0.153-1.531,0.46-3.446,0.919-5.743c0.46-2.297,0.864-4.102,1.215-5.415L218.977,225.892z"
        />
      </g>
      <polygon
        style={{ fill: '#38A3FF' }}
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
      />
      <polygon style={{ fill: '#D1D3D3' }} points="219.821,50.525 270.346,50.525 219.821,0 	" />
    </g>
  </svg>
);
