import { useGetContactCampaign } from '@colosseum/data';
import { CardSection, ContactCampaignTriggersTable } from '@colosseum/shared-ui';
import { CampaignStepOptions, ContactCampaign } from '@gladiate/types';
import { useNavigate, useParams } from 'react-router';
import NextButton from '../NextButton/NextButton';

export interface ContactCampaignStagesPageProps {
  campaignData?: ContactCampaign;
}
export function ContactCampaignStagesPage(props: ContactCampaignStagesPageProps) {
  // #region Hooks
  const { campaignId: contactCampaignId } = useParams() as {
    campaignId: string;
  };
  const navigate = useNavigate();
  // #endregion

  // #region Data Fetching
  const { data: campaignData } = useGetContactCampaign(contactCampaignId);

  // #endregion

  // #region State

  // #endregion

  // #region Derived State

  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers

  // #endregion

  return (
    <>
      <div className="flex flex-col gap-5">
        <CardSection
          cardHeading="Contact Campaign Selection"
          className="flex flex-col justify-center"
        >
          <>
            {campaignData && <ContactCampaignTriggersTable campaignData={campaignData.data} />}
            <div className="flex justify-between pt-4">
              <button
                type="button"
                className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <NextButton
                title="Continue"
                nextRoute={`/campaigns/${contactCampaignId}/contact/${CampaignStepOptions.subscription}`}
              />
            </div>
          </>
        </CardSection>
      </div>
    </>
  );
}

export default ContactCampaignStagesPage;
