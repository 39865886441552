import { tanstackTableNames } from '@colosseum/data';
import { DataTable } from '@colosseum/shared-ui';
import { ContactType, PolicyType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { policyColumns } from './policy-table-columns';

/* eslint-disable-next-line */
export interface LinkedPoliciesTableProps {
  policies: ((PolicyType & { insurer: ContactType | undefined }) | undefined)[];
  handleRowClick: (row: Row<PolicyType>) => void;
}

export function LinkedPoliciesTable(props: LinkedPoliciesTableProps) {
  const { policies, handleRowClick } = props;
  return (
    <DataTable
      hideFooter
      columns={policyColumns}
      data={policies}
      handleRowClick={handleRowClick}
      tableName={tanstackTableNames.scenarioPolicies}
    />
  );
}

export default LinkedPoliciesTable;
