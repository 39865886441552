import { Button, Popover, PopoverContent, PopoverTrigger } from '@colosseum/shared-ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface SearchPopoverProps {}

declare global {
  interface Window {
    find: (arg0: string, arg1: boolean, arg2: boolean, arg3: boolean) => boolean;
  }
} // This is a hack to get around the fact that window.find is not a standard function

export function SearchPopover(props: SearchPopoverProps) {
  const [searchCase, setSearchCase] = useState('');

  const findMatchOnCase = (reverse: boolean) => {
    // Get a reference to the search input field
    const searchInput = document.querySelector('input[name="search"]');

    if (searchInput) {
      // Temporarily disable the search input field
      searchInput.setAttribute('disabled', 'true');
      // Perform the search
      const isFound = window.find(searchCase, false, reverse, true);

      //! This currently does not work because it is counting the search input field as a match
      // Check if window.find returned any result
      if (!isFound) {
        enqueueSnackbar('No results were found for this search', {
          variant: 'error',
        });
      }
      // Re-enable the search input field
      searchInput.removeAttribute('disabled');
    }
  };

  return (
    <Popover>
      <PopoverTrigger className="h-10">
        <Button
          variant="ghost"
          hoverVariant="lightBlue"
          className="font-normal text-gray-500 text-md"
        >
          <MagnifyingGlassIcon className="inline-block w-5 h-5 mr-2 " />
          Search
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-white ">
        <div className="flex items-center p-2 rounded-lg ">
          <div className="relative flex items-center rounded-lg">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Start Typing..."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  findMatchOnCase(false);
                }
              }}
              onChange={(e) => setSearchCase(e.target.value)}
              className="block w-full h-10 pl-2 pr-12 border-gray-300 rounded-lg sm:text-sm focus:outline-none"
            />
            <div className="absolute inset-y-0 right-0 flex py-1 pr-1.5 ">
              <Button
                onClick={() => {
                  findMatchOnCase(false);
                }}
                variant="outline"
                size="unset"
                className="py-1.5 px-2"
              >
                <kbd>Enter</kbd>
              </Button>
            </div>
          </div>
          <div className="relative flex items-center justify-between px-1 mx-auto space-x-1 text-center align-middle border-gray-300 rounded-r-md">
            <Button
              variant="outline"
              size="unset"
              className="py-1.5"
              onClick={() => {
                findMatchOnCase(true);
              }}
            >
              <ArrowLeftIcon className="w-4 h-4 ml-2 mr-1 " />
            </Button>
            <Button
              variant="outline"
              size="unset"
              className="py-1.5"
              onClick={() => {
                findMatchOnCase(false);
              }}
            >
              <ArrowRightIcon className="w-4 h-4 ml-1 mr-2" />
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default SearchPopover;
