import {
  cn,
  displayContactName,
  generateTimeStamps,
  useCaseOfflineEventForm,
  useCreateEventAttendee,
  useDeleteEventAttendee,
  useGetContactsInfinite,
  useGetEventCategories,
  useGetFirmUsers,
  useGetFirmUserWithDisplayNameFromUsername,
} from '@colosseum/data';
import {
  Button,
  CalendarFormInput,
  CheckboxFormInput,
  Chip,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
  Form,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectFormInput,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { EventAttendeeType } from '@gladiate/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useState } from 'react';
import { CompleteEventsDataType } from '../CaseEvents/CaseEvents';

dayjs.extend(customParseFormat);

export interface CaseEventsFormProps {
  event?: CompleteEventsDataType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CaseEventsForm(props: CaseEventsFormProps) {
  const { event, setTyping } = props;

  const firmUsersData = useGetFirmUsers();
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const { data: allContactsData } = useGetContactsInfinite();

  const { form, handleUpdate } = useCaseOfflineEventForm({
    event,
    setTyping,
  });
  const { data: eventCategories } = useGetEventCategories();

  const createEventAttendee = useCreateEventAttendee();
  const deleteEventAttendee = useDeleteEventAttendee();

  const contactsWithDisplayName = allContactsData?.data
    ?.map((contact) => ({
      ...contact,
      displayName: displayContactName(contact),
    }))
    .filter((contact) =>
      contact.contactType === 'individual' ? contact.firstName || contact.lastName : contact.name,
    ) // remove contacts without names
    .sort((a, b) => a.displayName.localeCompare(b.displayName)); // sort contacts by name
  const categoriesWithTitle = eventCategories?.data?.filter((category) => category.title);

  const [selectedAttendees, setSelectedAttendees] = useState<
    {
      displayName: string;
      attendeeType: EventAttendeeType['attendeeType'];
      attendeeId?: EventAttendeeType['attendeeId'];
      gladiateId: EventAttendeeType['gladiateId'];
    }[]
  >(
    event?.attendeesWithDisplayNames?.map((attendee) => ({
      displayName: attendee.displayName,
      attendeeType: attendee.attendeeType,
      attendeeId: attendee.attendeeId,
      gladiateId: attendee.gladiateId,
    })) ?? [],
  );

  return (
    <Form {...form}>
      <form>
        <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
          <div className="col-span-2">
            <TextFormInput
              {...form.register(`title`)}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('title', target.value);
              }}
              handleOnChange={() => setTyping(true)}
              title="Title"
            />
          </div>
          <div className="col-span-2">
            <TextFormInput
              {...form.register(`description`)}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('description', target.value);
              }}
              handleOnChange={() => setTyping(true)}
              title="Description"
              type="textarea"
            />
          </div>
          <CalendarFormInput
            {...form.register(`startDateTime`)}
            title="Start Date"
            defaultValue={dayjs(event?.startDateTime).format('YYYY-MM-DD')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              const targetDate = dayjs(target.value);
              const newDate = dayjs(event?.startDateTime)
                .month(targetDate.month())
                .date(targetDate.date())
                .year(targetDate.year());
              handleUpdate('startDateTime', newDate.toISOString());
            }}
            hideSyncButton={true}
          />
          <SelectFormInput
            {...form.register(`startDateTime`)}
            title="Start Time"
            listItems={generateTimeStamps(0, 24, 15)}
            listItemsIsObject
            disabled={!form.getValues('startDateTime')}
            value={dayjs(event?.startDateTime).format('HH:mm')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              const targetTime = target.value.split(':');
              const newDate = dayjs(event?.startDateTime)
                .hour(Number(targetTime[0]))
                .minute(Number(targetTime[1]));
              handleUpdate('startDateTime', newDate.toISOString());
            }}
            placeholderText="Select a Time"
          />
          <CalendarFormInput
            {...form.register(`endDateTime`)}
            title="End Date"
            defaultValue={dayjs(event?.endDateTime).format('YYYY-MM-DD')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              const targetDate = dayjs(target.value);
              const newDate = dayjs(event?.endDateTime)
                .month(targetDate.month())
                .date(targetDate.date())
                .year(targetDate.year());
              handleUpdate('endDateTime', newDate.toISOString());
            }}
            hideSyncButton={true}
          />
          <SelectFormInput
            {...form.register(`endDateTime`)}
            title="End Time"
            listItems={generateTimeStamps(0, 24, 15)}
            listItemsIsObject
            disabled={!form.getValues('endDateTime')}
            value={dayjs(event?.endDateTime).format('HH:mm')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              const targetTime = target.value.split(':');
              const newDate = dayjs(event?.endDateTime)
                .hour(Number(targetTime[0]))
                .minute(Number(targetTime[1]));
              handleUpdate('endDateTime', newDate.toISOString());
            }}
            placeholderText="Select a Time"
          />
          <TextFormInput
            {...form.register(`location`)}
            handleOnBlur={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdate('location', target.value);
            }}
            handleOnChange={() => setTyping(true)}
            title="Location"
          />
          <SelectFormInput
            {...form.register(`categoryId`)}
            title="Category"
            listItemsIsObject
            listItems={
              categoriesWithTitle?.reduce((acc, category) => {
                if (!category) return acc;
                // @ts-expect-error - TS doesn't like the fact that we're using a dynamic key here
                acc[category?.title] = category.categoryId;
                return acc;
              }, {}) ?? []
            }
            defaultValue={event?.categoryId}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLSelectElement;
              handleUpdate('categoryId', target.value);
            }}
          />
          <CheckboxFormInput
            {...form.register('busy')}
            handleOnChange={(e: React.SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              handleUpdate('busy', target.value);
            }}
            title="Busy?"
          />
        </div>
        <Typography variant="heading" className="mb-2">
          Attendees
        </Typography>
        <div className="flex flex-wrap gap-2">
          {selectedAttendees.map((attendee) => {
            if (attendee.attendeeType === 'user') {
              return (
                <Chip
                  key={attendee.gladiateId}
                  name={
                    getFirmUserWithDisplayNameFromUsername(attendee.gladiateId ?? '')?.displayName
                  }
                  color="blue"
                  className="w-fit"
                />
              );
            } else {
              return (
                <Chip
                  key={attendee.gladiateId}
                  name={
                    contactsWithDisplayName?.find(
                      (contact) => contact.contactId === attendee.gladiateId,
                    )?.displayName
                  }
                  color="green"
                  className="w-fit"
                />
              );
            }
          })}
        </div>
        <Popover modal>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" className="w-1/2 h-8 mt-2 ">
              Select Attendees
            </Button>
          </PopoverTrigger>
          <PopoverContent asChild>
            <Command className="border rounded-lg shadow-md">
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Users">
                  {firmUsersData?.data?.data?.map((user) => (
                    <CommandItem key={user.Username} disabled={true}>
                      <label key={user.Username} className="w-full truncate">
                        <input
                          type="checkbox"
                          className={cn(
                            'w-4 h-4 mr-2  border-gray-300 rounded-sm form-checkbox focus:ring-0',
                          )}
                          value={
                            event?.attendees?.find(
                              (attendee) => attendee.gladiateId === user.Username,
                            )?.attendeeId
                          }
                          checked={selectedAttendees.some(
                            (attendee) => attendee.gladiateId === user.Username,
                          )}
                          onChange={(e) => {
                            if (!event?.eventId) return;
                            if (e.target.checked) {
                              createEventAttendee.mutateAsync({
                                eventId: event?.eventId,
                                gladiateId: user.Username,
                                attendeeType: 'user',
                              });
                              setSelectedAttendees([
                                ...selectedAttendees,
                                {
                                  attendeeType: 'user',
                                  gladiateId: user.Username,
                                  displayName:
                                    getFirmUserWithDisplayNameFromUsername(user.Username ?? '')
                                      .displayName ?? '',
                                },
                              ]);
                            } else {
                              const attendeeIdToDelete = event?.attendees?.find(
                                (attendee) => attendee.gladiateId === user.Username,
                              )?.attendeeId;
                              if (attendeeIdToDelete) {
                                deleteEventAttendee.mutateAsync(attendeeIdToDelete);
                                setSelectedAttendees(
                                  selectedAttendees.filter(
                                    (attendee) => attendee.gladiateId !== user.Username,
                                  ),
                                );
                              }
                            }
                          }}
                        />
                        <span>
                          {getFirmUserWithDisplayNameFromUsername(user.Username ?? '').displayName}
                        </span>
                      </label>
                    </CommandItem>
                  ))}
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Contacts">
                  {contactsWithDisplayName?.map((contact) => (
                    <CommandItem key={contact.contactId} disabled={true}>
                      <label key={contact.contactId} className="w-full truncate">
                        <input
                          type="checkbox"
                          className={cn(
                            'w-4 h-4 mr-2  border-gray-300 rounded-sm form-checkbox focus:ring-0',
                          )}
                          value={
                            selectedAttendees.find(
                              (attendee) => attendee.gladiateId === contact.contactId,
                            )?.attendeeId
                          }
                          checked={selectedAttendees.some(
                            (attendee) => attendee.gladiateId === contact.contactId,
                          )}
                          onChange={(e) => {
                            if (!event?.eventId) return;
                            if (e.target.checked) {
                              createEventAttendee.mutateAsync({
                                eventId: event?.eventId,
                                gladiateId: contact.contactId,
                                attendeeType: 'contact',
                              });
                              setSelectedAttendees([
                                ...selectedAttendees,
                                {
                                  attendeeType: 'contact',
                                  gladiateId: contact.contactId,
                                  displayName: contact.displayName,
                                },
                              ]);
                            } else {
                              const attendeeIdToDelete = event?.attendees?.find(
                                (attendee) => attendee.gladiateId === contact.contactId,
                              )?.attendeeId;
                              if (attendeeIdToDelete) {
                                deleteEventAttendee.mutateAsync(attendeeIdToDelete);
                                setSelectedAttendees(
                                  selectedAttendees.filter(
                                    (attendee) => attendee.gladiateId !== contact.contactId,
                                  ),
                                );
                              }
                            }
                          }}
                        />
                        <span>{contact.displayName}</span>
                      </label>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </form>
    </Form>
  );
}

export default CaseEventsForm;
