import { automationsSectionMappings, useGetListSurveys } from '@colosseum/data';
import { DataTable, Slideover } from '@colosseum/shared-ui';
import { Row } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { columns } from './firm-survey-table-columns';
import { FirmSurveysList } from '@gladiate/types';

export default function FirmSurveys() {
  const { data: firmSurveysData, isLoading } = useGetListSurveys();
  const [surveyFieldsSlideover, setSurveyFieldsSlideover] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState('');

  const selectedSurveyData = useMemo(
    () => firmSurveysData?.data?.find((survey) => survey.surveyId === selectedSurveyId),
    [firmSurveysData, selectedSurveyId],
  );

  function handleClick(e: Row<FirmSurveysList>) {
    setSurveyFieldsSlideover(true);
    setSelectedSurveyId(e.original.surveyId);
  }

  return (
    <>
      <div
        className="flex flex-col"
        data-automations-submenu-item={automationsSectionMappings.firmSurveys}
      >
        <div className="flex justify-between">
          <div>
            <h1 className="text-xl font-semibold text-gray-900">
              {automationsSectionMappings.firmSurveys}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              These are the surveys that are available for your firm.
            </p>
          </div>
        </div>

        {firmSurveysData?.data && (
          <DataTable
            data={firmSurveysData?.data}
            columns={columns}
            handleRowClick={handleClick}
            isLoading={isLoading}
            tableName="Firm Details"
            hideViewButton
          />
        )}
        <Slideover
          open={surveyFieldsSlideover}
          setOpen={setSurveyFieldsSlideover}
          title="Survey Details"
          description="View the details of the selected survey"
        >
          <h2 className="text-lg font-bold">{selectedSurveyData?.typeform.title}</h2>
          <table className="min-w-full">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-md font-semibold text-gray-900 w-1/2"
                >
                  Survey Item(s)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {selectedSurveyData?.typeform.fields.map((field, index) => (
                <tr key={index}>
                  <td className="px-3 py-2 text-sm text-gray-900">{field.question}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Slideover>
      </div>
    </>
  );
}
