import {
  CompleteMedicalTreatmentType,
  getContactById,
  useGetBillingEntityIdForTreatment,
} from '@colosseum/data';
import { useParams } from 'react-router';

/* eslint-disable-next-line */
export interface CloseOutstandingBillsFormProps {
  medicalTreatments: CompleteMedicalTreatmentType[];
  aggregateUnpaidBalance: number;
  aggregateReductions: number;
  aggregatePresentBalance: number;
}

export function CloseOutstandingBillsForm(props: CloseOutstandingBillsFormProps) {
  const { caseId } = useParams() as { caseId: string }; // caseId will always exist, react-router v6 forces typing to be optional for all params
  const getBillingEntityIdForTreatment = useGetBillingEntityIdForTreatment(caseId);
  return (
    <div className="flex -mx-4 fl1ex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
            >
              Billing Entity
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Unpaid Balance
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Negotiated Reduction
            </th>
            <th
              scope="col"
              className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 "
            >
              Present Balance
            </th>
          </tr>
        </thead>
        <tbody className="">
          {props.medicalTreatments &&
            props.medicalTreatments?.map((treatment, index) => {
              // calculate the percentage of unpaid balance that negotiated reduction is
              const reductions = treatment?.reductionAmount || 0;

              const unpaidBalance = treatment?.unpaidBalance || 0;

              const percentage = treatment?.reductionPercentage || 0;

              // using the billing entity id, get the billing entity name
              const billingEntityId = getBillingEntityIdForTreatment(treatment);
              const contact = getContactById(billingEntityId);

              return (
                <tr key={`${treatment?.medicalTreatmentId}${index}`} className="">
                  <td
                    className={
                      'pl-4 pr-3 text-sm sm:pl-6 md:pl-0 pb-3 ' + (index === 0 ? ' pt-3 ' : ' ')
                    }
                  >
                    <div className="font-medium text-gray-900 ">
                      {`${contact?.firstName || ''} ${contact?.lastName || ''}` || '-'}
                    </div>
                  </td>

                  <td
                    className={
                      'pl-3 pr-4 text-sm text-right pb-3 ' + (index === 0 ? ' pt-3 ' : '  ')
                    }
                  >
                    {treatment?.unpaidBalance?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '-'}
                  </td>

                  <td
                    className={
                      'pl-3 pr-4 text-sm text-right  pb-3 ' + (index === 0 ? ' pt-3 ' : '  ')
                    }
                  >
                    {Number(treatment?.reductionAmount)?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '-'}{' '}
                    {percentage !== 0 && (
                      <div>
                        {'( '}
                        {Number(percentage).toFixed(2)}%{')'}
                      </div>
                    )}
                  </td>

                  <td
                    className={
                      'pl-3 pr-4 text-sm text-right text-gray-500  pb-3 ' +
                      (index === 0 ? ' pt-3 ' : '  ')
                    }
                  >
                    {treatment?.presentBalance?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }) || '-'}
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              className="pt-4 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 md:pl-0 whitespace-nowrap"
            >
              Total
            </th>
            <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900">
              {props?.aggregateUnpaidBalance?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) || '$0.00'}
            </td>
            <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 ">
              {props?.aggregateReductions?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) || '$0.00'}
            </td>
            <td className="pt-4 pl-3 pr-4 text-sm font-semibold text-right text-gray-900 ">
              {props?.aggregatePresentBalance?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }) || '$0.00'}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default CloseOutstandingBillsForm;
