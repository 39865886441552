import { ReactNode } from 'react';
import SectionCollapsible from '../SectionCollapsible/SectionCollapsible';
import { cn } from '@colosseum/data';

/* eslint-disable-next-line */
export interface CardSectionProps {
  children: ReactNode;
  className?: string;
  submenuItem?: string;
  collapseSection?: boolean;
  cardHeading?: string;
}

export function CardSection(props: CardSectionProps) {
  const { children, className, submenuItem, collapseSection, cardHeading } = props;

  const style = collapseSection ? 'w-full' : 'w-full p-5 bg-white border shadow-sm rounded-xl';
  return (
    <section data-contact-submenu-item={submenuItem?.toString()}>
      <div className={cn(style, className)}>
        {collapseSection ? (
          <SectionCollapsible title={submenuItem?.toString() || ''}>{children}</SectionCollapsible>
        ) : (
          <>
            {cardHeading && <h2 className="ml-2 text-xl font-semibold">{cardHeading}</h2>}
            {children}
          </>
        )}
      </div>
    </section>
  );
}

export default CardSection;
