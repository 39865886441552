import { useUpdateAttorneyFeeForCase, useUpdateDefaultAttorneyFee } from '@colosseum/data';
import { DefaultAttorneyFeeType, FeeAgreementType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import RangeSlider from '../RangeSlider/RangeSlider';
import CheckboxFormInput from '../forms/CheckboxFormInput/CheckboxFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface FeeAgreementFormProps {
  feeAgreement: Partial<FeeAgreementType & DefaultAttorneyFeeType> | undefined;
  editable: boolean;
  setTyping?: any;
  isDefaultFee?: boolean;
}

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  feePercentage: z.number().optional(),
  currentlyApplicable: z.boolean().optional(),
});

export function FeeAgreementForm(props: FeeAgreementFormProps) {
  const { feeAgreement, editable, setTyping, isDefaultFee = false } = props;
  const [feeValue, setFeeValue] = useState<number>(0);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: feeAgreement,
  });

  const updateDefaultFeeAgreement = useUpdateDefaultAttorneyFee();
  const updateFeeAgreement = useUpdateAttorneyFeeForCase();

  useEffect(() => {
    setFeeValue(feeAgreement?.feePercentage ?? 0);
  }, [feeAgreement]);

  function handleUpdate(
    field: keyof z.infer<typeof formSchema>,
    update?: string | string[] | number | boolean,
  ) {
    setTyping && setTyping(true);
    !isDefaultFee
      ? updateFeeAgreement.mutateAsync({
          attorneyFeeId: feeAgreement?.attorneyFeeId ?? '',
          [field]: update,
        })
      : updateDefaultFeeAgreement
          .mutateAsync({
            defaultAttorneyFeeId:
              feeAgreement?.defaultAttorneyFeeId ?? feeAgreement?.attorneyFeeId ?? '',
            [field]: update,
          })
          .then(() => {
            setTyping && setTyping(false);
          });
  }

  return (
    <div className="">
      <Form {...form}>
        <form>
          <div className="grid grid-cols-1 pt-2 pb-2 gap-y-5 gap-x-3">
            <div className="grid items-center self-center grid-cols-2">
              <TextFormInput
                {...form.register('title')}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
                disabled={!editable}
              />
              <CheckboxFormInput
                {...form.register('currentlyApplicable')}
                name={'currentlyApplicable'}
                title={'Currently Applicable'}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('currentlyApplicable', target.value);
                }}
                isDisabled={!editable}
              />
            </div>

            <TextFormInput
              {...form.register('description')}
              title="Description"
              type="textarea"
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('description', target.value);
              }}
              disabled={!editable}
            />
          </div>
        </form>
      </Form>
      <div className="px-1 mt-2">
        <RangeSlider
          title="Fee Percentage"
          value={feeValue}
          setValue={setFeeValue}
          onAfterChange={(value: number) => {
            handleUpdate('feePercentage', value);
          }}
        />
      </div>
    </div>
  );
}

export default FeeAgreementForm;
