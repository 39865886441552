/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  calculateContactAge,
  cn,
  displayContactName,
  formatAddresses,
  formatEmails,
  formatPhoneNumbers,
  prettifyDateString,
} from '@colosseum/data';
import { ContactViewModelType } from '@gladiate/types';
import { CakeIcon } from '@heroicons/react/24/outline';
import { PersonIcon } from '@radix-ui/react-icons';
import { Languages, HomeIcon, MailIcon, PhoneIcon } from 'lucide-react';
import pluralize from 'pluralize';
import Typography from '../Typography/Typography';
import { ContactItemViewer } from './ContactItemViewer';

export interface ContactChipDetailsProps {
  contact?: ContactViewModelType;
  onClick?: (e: React.SyntheticEvent) => void;
  hideName?: boolean;
  textSlideoverLaunch?: () => void;
}

const iconClasses = 'size-4 min-w-4 min-h-4';

export function ContactChipDetails(props: ContactChipDetailsProps) {
  const { contact, onClick, hideName, textSlideoverLaunch } = props;
  const contactAge = calculateContactAge(contact);
  if (!contact) {
    return null;
  }
  const { primaryPhoneNumber, additionalPhoneNumbers } = formatPhoneNumbers(
    contact?.contactNumbers,
  );
  const { primaryEmail, additionalEmails } = formatEmails(contact?.contactEmails);

  const { primaryAddress, additionalAddresses } = formatAddresses(contact?.contactAddresses);

  const isOrg = contact?.contactType === 'organization';

  return (
    <div onClick={onClick} className={cn('space-y-0.5', onClick !== undefined && 'cursor-pointer')}>
      {!hideName && (
        <Typography className="flex items-center max-w-full gap-x-2">
          <PersonIcon className={iconClasses} />
          {displayContactName(contact)}
        </Typography>
      )}
      <div
        onClick={textSlideoverLaunch}
        className={cn('flex items-center gap-x-2', textSlideoverLaunch ? 'cursor-pointer' : '')}
      >
        <PhoneIcon className={iconClasses} />
        <ContactItemViewer
          primaryValue={primaryPhoneNumber}
          additionalValues={additionalPhoneNumbers}
          className={cn(
            textSlideoverLaunch !== undefined &&
              (primaryPhoneNumber.length > 1 || additionalPhoneNumbers.length > 0) &&
              'text-atlantic-blue',
          )}
        />
      </div>
      <div className="flex items-center gap-x-2">
        <MailIcon className={iconClasses} />
        <ContactItemViewer
          primaryValue={primaryEmail}
          additionalValues={additionalEmails?.map(
            // @ts-ignore
            (email) => (email?.emailAddress ?? '') as string,
          )}
        />
      </div>
      <div className="flex items-center gap-x-2">
        <HomeIcon className={iconClasses} />
        <ContactItemViewer primaryValue={primaryAddress} additionalValues={additionalAddresses} />
      </div>
      {!isOrg && (
        <>
          <div className="flex items-center gap-x-2">
            <CakeIcon className={iconClasses} />
            {contact.dateOfBirth
              ? `${prettifyDateString(contact?.dateOfBirth)}${
                  contact.deceased && contact.dateOfDeath
                    ? ' - ' + prettifyDateString(contact?.dateOfDeath)
                    : ''
                } (${contactAge} ${pluralize('year', contactAge)} old)`
              : '-'}
          </div>
          <div className="flex items-center gap-x-2">
            <Languages className={iconClasses} />
            {contact.languages.length
              ? contact.languages
                  .map((language) => language.charAt(0).toUpperCase() + language.slice(1))
                  .join(', ')
              : '-'}
          </div>
        </>
      )}
    </div>
  );
}
export default ContactChipDetails;
