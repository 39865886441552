import {
  US_STATES,
  settingsSectionMappings,
  useCreateFirmOffice,
  useDeleteFirmOffice,
  useGetFirm,
  useGetFirmOffices,
  useUpdateFirmOffice,
  useUpdateFirmSettings,
} from '@colosseum/data';
import {
  Button,
  CreateButton,
  Form,
  MultiSelectFormInput,
  SectionCollapsible,
  TextFormInput,
} from '@colosseum/shared-ui';
import { FirmOfficeType } from '@gladiate/types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

/* eslint-disable-next-line */
export interface FirmDetailsProps {}

export function FirmDetails() {
  const { data: firmSettingsData, isFetched: isFirmSettingsFetched } = useGetFirm();
  const { data: firmOfficesData, isFetched: isFirmOfficesFetched } = useGetFirmOffices();
  const firmOffices = firmOfficesData?.data;
  const firmSettings = firmSettingsData?.data;
  const updateFirmOffice = useUpdateFirmOffice();
  const updateFirmSettings = useUpdateFirmSettings();
  const createFirmOffice = useCreateFirmOffice();
  const deleteFirmOffice = useDeleteFirmOffice();

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      firmName: firmSettings?.firmName,
      practiceLocations: firmSettings?.practiceLocations ?? [],
      firmOffices: firmOffices ?? [],
    },
  });

  const officeForms = useFieldArray({
    control: form.control,
    name: 'firmOffices',
  });

  const handleFirmOfficeUpdate = (name: keyof FirmOfficeType, value: string, i: number) => {
    updateFirmOffice.mutate({
      firmOfficeId: form.getValues(`firmOffices.${i}.firmOfficeId`),
      [name]: value,
    });
  };

  const handleTextInputChange = (name: 'firmName', value: string) => {
    updateFirmSettings.mutate({
      [name]: value,
    });
  };

  useEffect(() => {
    form.reset({
      firmName: firmSettings?.firmName,
      practiceLocations: firmSettings?.practiceLocations ?? [],
      firmOffices: firmOffices ?? [],
    }); // This allows the form to set its defaults once the data is fetched
  }, [isFirmOfficesFetched, isFirmSettingsFetched]);

  return (
    <div
      className="relative bg-white rounded-2xl"
      data-settings-submenu-item={settingsSectionMappings.firmDetails}
    >
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Firm Details</h1>
          <p className="mt-2 text-sm text-gray-700">Details about your firm.</p>
        </div>
      </div>
      <Form {...form}>
        <form>
          <div className="grid grid-cols-2 mt-5 gap-x-3 gap-y-5 ">
            <TextFormInput
              title="Firm Name"
              name="firmName"
              handleOnChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                form.setValue('firmName', target.value);
              }}
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleTextInputChange('firmName', target.value);
              }}
            />

            <MultiSelectFormInput
              title="Practice Locations"
              options={US_STATES}
              selectedOptions={firmSettings?.practiceLocations ?? []}
              onChange={(option: string) => {
                const practiceLocations = firmSettings?.practiceLocations ?? [];

                if (practiceLocations.includes(option)) {
                  const index = practiceLocations.indexOf(option);

                  practiceLocations.splice(index, 1);
                } else {
                  practiceLocations.push(option);
                }

                form.setValue('practiceLocations', practiceLocations);

                updateFirmSettings.mutate({
                  practiceLocations,
                });
              }}
            />
          </div>

          <SectionCollapsible title="Firm Offices">
            {officeForms.fields.map((field, i) => (
              <div key={field.id} className="px-2 py-5 border-b border-b-gray-300">
                <div className="grid grid-cols-2 gap-x-3 gap-y-5">
                  <TextFormInput
                    {...form.register(`firmOffices.${i}.streetAddress`)}
                    title="Address"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      form.setValue(`firmOffices.${i}.streetAddress`, target.value);
                    }}
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleFirmOfficeUpdate('streetAddress', target.value, i);
                    }}
                  />

                  <TextFormInput
                    {...form.register(`firmOffices.${i}.city`)}
                    title="City"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      form.setValue(`firmOffices.${i}.city`, target.value);
                    }}
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleFirmOfficeUpdate('city', target.value, i);
                    }}
                  />

                  <TextFormInput
                    {...form.register(`firmOffices.${i}.state`)}
                    title="State"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      form.setValue(`firmOffices.${i}.state`, target.value);
                    }}
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleFirmOfficeUpdate('state', target.value, i);
                    }}
                  />

                  <div className="flex gap-x-2">
                    <div className="grow">
                      <TextFormInput
                        {...form.register(`firmOffices.${i}.zip`)}
                        title="Zip Code"
                        handleOnChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          form.setValue(`firmOffices.${i}.zip`, target.value);
                        }}
                        handleOnBlur={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          handleFirmOfficeUpdate('zip', target.value, i);
                        }}
                      />
                    </div>

                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        deleteFirmOffice.mutate(form.getValues(`firmOffices.${i}.firmOfficeId`));
                        officeForms.remove(i);
                      }}
                      variant="destructive"
                      className="self-center p-2"
                    >
                      <TrashIcon className="w-5 h-5 " />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-4 ml-2">
              <CreateButton
                title={'Add Firm Office'}
                loading={createFirmOffice.isLoading}
                onClick={() => {
                  createFirmOffice.mutateAsync().then((res) =>
                    officeForms.append({
                      firmOfficeId: res.data?.data?.firmOfficeId,
                      streetAddress: '',
                      city: '',
                      state: '',
                      zip: '',
                    }),
                  );
                }}
              />
            </div>
          </SectionCollapsible>
        </form>
      </Form>
    </div>
  );
}

export default FirmDetails;
