import { displayContactName, makeAcronym } from '@colosseum/data';
import { Button, Typography } from '@colosseum/shared-ui';
import { CaseContactConnectionRoleType, ContactType } from '@gladiate/types';
import ContactsSelect from '../ContactsSelect/ContactsSelect';

/* eslint-disable-next-line */
export interface ClientsOnCaseCardProps {
  clientsOnCase: ContactType[];
  onClickHandler: (client: ContactType) => void;
  caseId: string;
  handleAddClient: {
    mutation: (role: CaseContactConnectionRoleType['roleOnCase'], contactId: string) => void;
    isLoading: boolean;
  };
}

export function ClientsOnCaseCard(props: ClientsOnCaseCardProps) {
  const { clientsOnCase, onClickHandler, caseId, handleAddClient } = props;
  return (
    <div className="flex flex-col w-full p-5 pt-5 mt-5 bg-white border border-b border-gray-200 shadow-sm rounded-xl ">
      {clientsOnCase.length < 2 ? (
        <>
          <ContactsSelect
            handleSelectContact={handleAddClient.mutation}
            caseId={caseId}
            buttonMessage="Add more clients to this case"
            isLoading={handleAddClient.isLoading}
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between pb-2">
            <p className="flex self-center font-semibold ">Clients on Case</p>
            <ContactsSelect
              handleSelectContact={handleAddClient.mutation}
              caseId={caseId}
              buttonMessage="+"
              customButton={
                <Button variant="outline" size="sm" className="w-8 h-8">
                  +
                </Button>
              }
              isLoading={handleAddClient.isLoading}
            />
          </div>
          <div className="flex flex-col divide-y divide-gray-200  h-fit max-h-[130px]  overflow-y-hidden hover:overflow-y-auto">
            <>
              {clientsOnCase
                .sort((a, b) =>
                  displayContactName(a).toLocaleLowerCase().localeCompare(displayContactName(b)),
                )
                .map((client) => (
                  <div
                    className="flex items-center max-w-full gap-2 py-2 text-sm group hover:cursor-pointer"
                    onClick={() => onClickHandler(client)}
                    key={client.contactId}
                  >
                    <div className="flex items-center justify-center h-5 bg-gray-200 rounded-full min-w-5 group-hover:bg-blue-200">
                      <p className="text-2xs">{makeAcronym(displayContactName(client), 2)} </p>
                    </div>
                    <Typography className="max-w-[90%]">{displayContactName(client)}</Typography>
                  </div>
                ))}
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default ClientsOnCaseCard;
