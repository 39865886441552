import { useGetFirmUsers } from '../lib/react-query/firm';
import { getNameToDisplayFromCognito } from '../utils/authUtils';

export const useGetFirmUserWithDisplayNameFromUsername = (showCoCounsel: boolean = true) => {
  const firmUsersQuery = useGetFirmUsers(showCoCounsel);
  const firmUsersData = firmUsersQuery.data?.data;
  const getFirmUserWithDisplayNameFromUsername = (username: string) => {
    const firmUser = firmUsersData?.find((firmUser) => username === firmUser?.Username);
    const displayName = getNameToDisplayFromCognito(firmUser);
    return { ...firmUser, displayName };
  };
  return { getFirmUserWithDisplayNameFromUsername };
};
