/* eslint-disable jsx-a11y/anchor-is-valid */
import { cn } from '@colosseum/data';
import { DropDownMenuItem } from '@gladiate/types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

interface HandleDropDownMenuSelection {
  (dropDownMenuItem: DropDownMenuItem): void;
}

/* eslint-disable-next-line */
export interface DropdownMenuProps {
  menuItems: DropDownMenuItem[];
  menuTitle?: string;
  displayDownArrow: boolean;
  handleSelection: HandleDropDownMenuSelection;
  children?: any;
  noOptionsMessage?: string;
}

export function DropdownMenu(props: DropdownMenuProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center justify-center text-sm font-medium group hover:text-gray-900">
          {props.children ? <div>{props.children}</div> : <span>{props.menuTitle}</span>}

          {props.displayDownArrow ? (
            <ChevronDownIcon
              className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          ) : null}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            'absolute z-50 w-56 mt-2 origin-top-right  bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          }
        >
          <div className="py-1">
            {props.menuItems.length === 0 && (
              <Menu.Item as="div" className="px-4 py-2">
                {`${props.noOptionsMessage ?? 'No Items Available'}`}
              </Menu.Item>
            )}
            {props.menuItems?.map((menuItem) => {
              return (
                <Menu.Item key={menuItem.value} data-cy={'dropdown-item-' + menuItem.value}>
                  {({ active }) => (
                    <a
                      onClick={() => props.handleSelection(menuItem)}
                      className={cn(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}
                    >
                      {menuItem.displayName}
                    </a>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DropdownMenu;
