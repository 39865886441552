export const queryKeys = {
  adobeAgreements: 'adobeAgreements',
  adobeAuthUrl: 'adobeAuthUrl',
  adobeFirmAccount: 'adobeFirmAccount',
  adobeFirmUsers: 'adobeFirmUsers',
  adobeSigners: 'adobeSigners',
  adobeUserAccount: 'adobeUserAccount',
  arctrievalEntities: 'arctrievalEntities',
  arctrievalRequests: 'arctrievalRequests',
  attorneyFees: 'attorneyFees',
  audiences: 'audiences',
  auditDetails: 'auditDetails',
  audits: 'audits',
  availablePhoneNumbers: 'availablePhoneNumbers',
  availableSubscriptions: 'availableSubscriptions',
  billingHistory: 'billingHistory',
  campaignComplete: 'campaignComplete',
  campaigns: 'campaigns',
  caseAccesses: 'caseAccesses',
  caseAudits: 'caseAudits',
  caseCampaigns: 'caseCampaigns',
  caseCampaignSubscriptions: 'caseCampaignSubscriptions',
  caseConflicts: 'caseConflicts',
  caseContactConnections: 'caseContactConnections',
  caseEmails: 'caseEmails',
  caseLitigationActions: 'caseLitigationActions',
  caseLitigationPlanActionNotifications: 'caseLitigationPlanActionNotifications',
  caseMedicalSummary: 'caseMedicalSummary',
  cases: 'cases',
  casesForContact: 'casesForContact',
  caseStatuses: 'caseStatuses',
  caseStatusUpdates: 'caseStatusUpdates',
  caseSurveys: 'caseSurveys',
  caseTypes: 'caseTypes',
  coCounsels: 'coCounsels',
  communications: 'communications',
  contactAddresses: 'contactAddresses',
  contactCampaigns: 'contactCampaigns',
  contactCampaignSubscriptions: 'contactCampaignSubscriptions',
  contactConnections: 'contactConnections',
  contactEmails: 'contactEmails',
  contactEmployments: 'contactEmployments',
  contactNumbers: 'contactNumbers',
  contacts: 'contacts',
  contactWebAddresses: 'contactWebAddresses',
  currentUser: 'currentUser',
  customAttributeOptions: 'customAttributeOptions',
  customAttributes: 'customAttributes',
  defaultAttorneyFees: 'defaultAttorneyFees',
  discovery: 'discovery',
  eventCategories: 'eventCategories',
  expenseAttachments: 'expenseAttachments',
  expenseCategories: 'expenseCategories',
  expenses: 'expenses',
  fileDownloadLinks: 'fileDownloadLinks',
  fileItem: 'fileItem',
  firm: 'firm',
  firmGroups: 'firmGroups',
  firmOffices: 'firmOffices',
  firmPaymentAccount: 'firmPaymentAccount',
  firmSurveys: 'firmSurveys',
  firmTransactions: 'firmTransactions',
  firmUserDetails: 'firmUserDetails',
  firmUsers: 'firmUsers',
  itemTagConnections: 'itemTagConnections',
  liens: 'liens',
  linkedCases: 'linkedCases',
  litigationPlanActionNotifications: 'litigationPlanActionNotifications',
  litigationPlanActions: 'litigationPlanActions',
  litigationPlans: 'litigationPlans',
  medicalAppointments: 'medicalAppointments',
  medicalTreatments: 'medicalTreatments',
  medicalTreatmentTypes: 'medicalTreatmentTypes',
  messages: 'messages',
  metrics: 'metrics',
  notes: 'notes',
  notificationPreferences: 'notificationPreferences',
  notifications: 'notifications',
  offlineEvents: 'offlineEvents',
  oneClickSignSettings: 'oneClickSignSettings',
  paymentMethods: 'paymentMethods',
  policies: 'policies',
  presetFilters: 'presetFilters',
  priorMedicalTreatments: 'priorMedicalTreatments',
  provisionedPhoneNumbers: 'provisionedPhoneNumbers',
  recordRequests: 'recordRequests',
  referralSources: 'referralSources',
  scenario: 'scenario',
  selectedCases: 'selectedCases',
  settlements: 'settlements',
  staff: 'staff',
  staffRoles: 'staffRoles',
  subscriptions: 'subscriptions',
  tags: 'tags',
  taskPlans: 'taskPlans',
  tasks: 'tasks',
  tasksForPlans: 'tasksForPlans',
  teams: 'teams',
  templates: 'templates',
  timeLogCategories: 'timeLogCategories',
  timeLogs: 'timeLogs',
  trustAccountTransactions: 'trustAccountTransactions',
  user: 'user',
  UTBMSCodes: 'UTBMSCodes',
  vehicles: 'vehicles',
} as const;
