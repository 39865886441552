import {
  casesSectionMappings,
  enqueueAPISnackbar,
  scrollToCaseSection,
  tanstackTableNames,
  useCreatePriorMedicalTreatment,
  useDeletePriorMedicalTreatment,
  useGetContactsInfinite,
  useGetMedicalTreatmentTypes,
  useGetPriorMedicalTreatments,
  useGetRoleIdForItem,
  useUpdatePriorMedicalTreatment,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  PriorTreatmentForm,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';

import { PriorMedicalTreatmentType, caseContactConnectionOptions } from '@gladiate/types';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { columns } from './prior-medical-treatment-table-columns';

/* eslint-disable-next-line */
export interface ActiveMedicalTreatmentsProps {
  activePriorTreatmentId?: string;
  caseId: string;
}

export function TreatmentHistory(props: ActiveMedicalTreatmentsProps) {
  const { caseId } = props;
  const [activePriorTreatmentId, setActivePriorTreatmentId] = useState<string>();

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const [open, setOpen] = useState(false);

  const [typing, setTyping] = useState(false);

  const { data: priorTreatmentsData, isLoading: isPriorTreatmentsLoading } =
    useGetPriorMedicalTreatments(caseId);
  const createPriorMedicalTreatment = useCreatePriorMedicalTreatment();
  const deletePriorMedicalTreatment = useDeletePriorMedicalTreatment();
  const updatePriorMedicalTreatment = useUpdatePriorMedicalTreatment();
  const { data: medicalTreatmentTypeData } = useGetMedicalTreatmentTypes();

  const { data: allContactsData } = useGetContactsInfinite();

  const priorTreatments = priorTreatmentsData?.data;
  const getRoleIdForItem = useGetRoleIdForItem<PriorMedicalTreatmentType>(caseId);
  const priorTreatmentsWithMedicalProvider = priorTreatments?.map((priorTreatment) => {
    const medicalProviderId = getRoleIdForItem(
      priorTreatment,
      caseContactConnectionOptions.medicalProvider,
      'priorMedicalTreatmentId',
    );
    const medicalProvider = allContactsData?.data?.find(
      (contact) => contact.contactId === medicalProviderId,
    );
    const medicalTreatmentTypeName = medicalTreatmentTypeData?.data?.find(
      (type) => type.medicalTreatmentTypeId === priorTreatment.medicalTreatmentTypeId,
    )?.title;
    return {
      ...priorTreatment,
      medicalProvider,
      medicalTreatmentTypeName,
    };
  });

  const activePriorTreatment =
    priorTreatmentsWithMedicalProvider?.find(
      (treatment) => treatment?.priorMedicalTreatmentId === activePriorTreatmentId,
    ) ?? ({} as PriorMedicalTreatmentType);

  const handleCreateTreatment = () => {
    createPriorMedicalTreatment
      .mutateAsync({
        caseId,
      })
      .then((res) => {
        setActivePriorTreatmentId(res.data.priorMedicalTreatmentId);
        setOpen(true);
      });
  };

  const handleDeleteTreatment = () => {
    deletePriorMedicalTreatment
      .mutateAsync(activePriorTreatment?.priorMedicalTreatmentId)
      .then(() => {
        setOpen(false);
        setActivePriorTreatmentId(undefined);
      });
  };

  function handleUpdateTreatment(val: string | number, key: string, medicalTreatmentId: string) {
    const treatmentUpdate = {
      priorMedicalTreatmentId: medicalTreatmentId,
      [key]: val,
    };
    updatePriorMedicalTreatment.mutate(treatmentUpdate);
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'priorMedicalTreatment' && !isPriorTreatmentsLoading) {
      if (
        priorTreatmentsData?.data.find(
          (d) => d.priorMedicalTreatmentId === pendingSlideoverToOpen?.id,
        )
      ) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.medicals}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActivePriorTreatmentId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Prior Medical Treatment not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, isPriorTreatmentsLoading]);
  return (
    <>
      <ResourceSlideover
        open={open}
        setOpen={setOpen}
        title={'Prior Medical Treatment'}
        description={'Add a prior medical treatment'}
        typing={typing}
        deleteFunction={handleDeleteTreatment}
        displayDeleteButton={true}
        createType="priorMedicalTreatment"
        resourceId={activePriorTreatment.priorMedicalTreatmentId}
        caseId={caseId}
      >
        <TabsContent value="details">
          <PriorTreatmentForm
            updateHandler={handleUpdateTreatment}
            treatment={activePriorTreatment}
            caseId={caseId}
            typing={typing}
            setTyping={setTyping}
          />
        </TabsContent>
      </ResourceSlideover>
      <div className="px-2">
        <div className="pt-4">
          <div className="flex items-center justify-end w-full pb-3 align-middle">
            <CreateButton
              title={'Add Prior Treatment'}
              loading={createPriorMedicalTreatment.isLoading}
              onClick={handleCreateTreatment}
              dataCy="create-treatment-button"
            />
          </div>
          <DataTable
            data={priorTreatmentsWithMedicalProvider ?? []}
            showSearchBar
            initialSort={{
              id: 'Date Created',
              desc: true,
            }}
            filters={[]}
            columns={columns}
            handleRowClick={(item) => {
              const group = item.getIsGrouped();
              if (!group) {
                setActivePriorTreatmentId(item.original.priorMedicalTreatmentId);
                setOpen(true);
              }
            }}
            initialVisibility={
              {
                'Date Created': false,
                'Date Modified': false,
                'Date Verified': false,
              } as VisibilityState
            }
            initialGroupingsExpanded={true}
            isLoading={isPriorTreatmentsLoading}
            tableName={tanstackTableNames.priorMedicalTreatments}
            autoResetExpanded={false}
          />
        </div>
      </div>
    </>
  );
}

export default TreatmentHistory;
