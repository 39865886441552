import { postErrorToSlack } from '@colosseum/data';
import { useEffect, useState } from 'react';

export const CustomErrorBoundary = (props: { error: Error }) => {
  const [firstRender, setFirstRender] = useState(true);
  const url = window.location.href;
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      postErrorToSlack({ message: props.error.stack, url });
    }
  }, []);
  return (
    <div className="p-5 m-auto bg-white border border-gray-200 rounded-xl" data-cy="error-boundary">
      Whoops, something went wrong. Gladiate has been notified and is working to fix this error.
    </div>
  );
};

export default CustomErrorBoundary;
