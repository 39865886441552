import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@colosseum/data';
import 'react-day-picker/dist/style.css';

export type CalendarPickerProps = React.ComponentProps<typeof DayPicker> & {
  className?: string;
  classNames?: string;
  showOutsideDays?: boolean;
};

function CalendarPicker({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarPickerProps) {
  return (
    <DayPicker
      captionLayout="dropdown-buttons"
      fromYear={1900}
      toYear={2100}
      showOutsideDays={showOutsideDays}
      className={cn('bg-white', className)}
      classNames={
        {
          // months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          // month: 'space-y-4',
          // caption: 'flex pt-1 relative items-center',
          // caption_label: 'text-sm font-medium hidden',
          // nav: 'space-x-1 flex items-center',
          // nav_button: cn(
          //   buttonVariants({ variant: 'outline' }),
          //   'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
          // ),
          // nav_button_previous: 'absolute left-1',
          // nav_button_next: 'absolute right-1',
          // table: 'w-full border-collapse space-y-1',
          // head_row: 'flex',
          // head_cell:
          //   'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
          // row: 'flex w-full mt-2',
          // cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          // day: cn(
          //   buttonVariants({ variant: 'ghost' }),
          //   'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
          // ),
          // day_selected:
          //   'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          // day_today: 'bg-accent text-accent-foreground',
          // day_outside: 'text-muted-foreground opacity-50',
          // day_disabled: 'text-muted-foreground opacity-50',
          // day_range_middle:
          //   'aria-selected:bg-accent aria-selected:text-accent-foreground',
          // day_hidden: 'invisible',
          // ...classNames,
        }
      }
      // components={{
      //   IconLeft: ({ ...props }) => <ChevronLeft className="w-4 h-4" />,
      //   IconRight: ({ ...props }) => <ChevronRight className="w-4 h-4" />,
      // }}
      {...props}
    />
  );
}
CalendarPicker.displayName = 'CalendarPicker';

export { CalendarPicker };
