import { XMarkIcon } from '@heroicons/react/20/solid';

/* eslint-disable-next-line */
export interface BubbleItemProps {
  text: string;
  icon?: any;
  href?: string;
  onClick?: () => void;
  onDelete?: (arg0?: any) => void;
}

export function BubbleItem(props: BubbleItemProps) {
  return (
    <div className="flex px-2 py-1 text-xs font-semibold rounded-md text-atlantic-blue bg-light-blue w-fit">
      {props.onClick ? (
        <div onClick={props.onClick} className="cursor-pointer hover:underline hover:text-white">
          {props.text}
        </div>
      ) : (
        <div>
          {props.href ? (
            <a
              href={props.href}
              className="flex items-center cursor-pointer hover:underline hover:text-white"
            >
              {props.icon} {props.text}
            </a>
          ) : (
            <div className="flex items-center">
              {props.icon} {props.text}
            </div>
          )}
        </div>
      )}

      {props.onDelete && (
        <button onClick={props.onDelete}>
          <XMarkIcon className="w-4 h-4 ml-1 hover:text-white" />
        </button>
      )}
    </div>
  );
}

export default BubbleItem;
