// Dependencies

// Components
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  GladiateLoader,
  Typography,
} from '@colosseum/shared-ui';

// React-query
import { cn, useAssignTeam, useGetCase, useGetTeams, useUpdateCaseData } from '@colosseum/data';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

export interface CaseTeamSelectProps {
  caseId: string;
}

export function CaseTeamSelect(props: CaseTeamSelectProps) {
  const { caseId } = props;
  const updateCaseMutation = useUpdateCaseData();
  const { data: caseData } = useGetCase(caseId);
  const assignTeam = useAssignTeam();
  const { data: teamsData, isLoading: isTeamsLoading } = useGetTeams();
  const assignedTeam = teamsData?.data?.find((team) => team.teamId === caseData?.data?.teamId);

  const handleAssignTeam = (teamId: string) => {
    assignTeam.mutate({ teamId, caseId });
  };

  const handleUnassignTeam = () => {
    updateCaseMutation.mutate({
      caseId,
      teamId: '',
    });
  };

  return (
    <div className="flex">
      {assignedTeam && (
        <div
          key={assignedTeam?.teamId}
          className={cn(
            'flex mt-3  px-2 py-1.5 text-xs mr-3 font-semibold text-atlantic-blue bg-light-blue rounded-full w-fit',
          )}
          data-cy="team-chip-name"
        >
          {assignedTeam?.title}
          <button
            onClick={() => {
              handleUnassignTeam();
            }}
            className="ml-2 rounded-full hover:bg-atlantic-blue hover:text-white"
          >
            {isTeamsLoading ? <GladiateLoader height={16} /> : <XMarkIcon className="w-4 h-4 " />}
          </button>
        </div>
      )}
      {!assignedTeam && (
        <DropdownMenu>
          <DropdownMenuContent>
            {teamsData?.data
              ?.filter((team) => team.title)
              .map((team) => (
                <DropdownMenuItem onClick={() => handleAssignTeam(team.teamId)} key={team.teamId}>
                  {team.title}
                </DropdownMenuItem>
              ))}
            {teamsData?.data?.length === 0 && (
              <DropdownMenuItem>
                <Typography variant="subtext">
                  No teams available. You can create a new team in the settings section.
                </Typography>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
          <DropdownMenuTrigger>
            <div className="'w-5 h-5 inline-flex items-center justify-center flex-shrink-0 mt-3 text-gray-400 bg-white border-2 border-gray-200 border-dashed rounded-full w-7 h-7 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2',">
              <span className="sr-only">Add team</span>
              <PlusIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </DropdownMenuTrigger>
        </DropdownMenu>
      )}
    </div>
  );
}

export default CaseTeamSelect;
