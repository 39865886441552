import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Table } from '@tanstack/react-table';
import { SlidersHorizontal } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../Button/Button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../DropdownMenu/DropdownMenu';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
}

export function DataTableViewOptions<TData>({ table }: DataTableViewOptionsProps<TData>) {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="hidden h-8 ml-auto lg:flex">
          <SlidersHorizontal className="w-4 h-4 mr-2" />
          View
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {/* We can't currently use this as we need to remove the fields 
        that we want always hidden (referral source id in case page as an example) */}
        {/* {table.getIsSomeColumnsVisible() ? ( 
          <>
            <DropdownMenuCheckboxItem
              key={'all-items'}
              className="capitalize"
              checked={false}
              onCheckedChange={(value) => table.toggleAllColumnsVisible(false)}
            >
              Deselect all
            </DropdownMenuCheckboxItem>
            <DropdownMenuSeparator />
          </>
        ) : null} */}
        {table
          .getAllColumns()
          .filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide())
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize cursor-pointer"
                checked={column.getIsVisible()}
                onClick={(e) => {
                  e.preventDefault();
                  column.toggleVisibility(!column.getIsVisible());
                }}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
