import { VehicleType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdateVehicleForScenario } from '../../../lib/react-query/vehicle';
import { zodCurrencyType } from '../zodValidationTypes';
import { isNil } from 'lodash';

const formSchema = z.object({
  companyProperty: z.string().optional(),
  costToRepairResultingFromIncident: zodCurrencyType,
  diminishedValueClaimFinalAmount: zodCurrencyType,
  diminishedValueClaimOdometerAtIncident: z.coerce.number().optional(),
  governmentProperty: z.string().optional(),
  isDiminishedValueClaim: z.string().optional(),
  isRentalCarNeeded: z.string().optional(),
  isRepairable: z.string().optional(),
  isTotalLoss: z.string().optional(),
  licensePlate: z.string().optional(),
  make: z.string().optional(),
  model: z.string().optional(),
  ownershipStatus: z.string().optional(),
  personalPropertyDamage: zodCurrencyType,
  personalPropertyDamageDescription: z.string().optional(),
  rentalEndDate: z.string().optional(),
  rentalStartDate: z.string().optional(),
  repairEstimate: zodCurrencyType,
  repairFinalCost: zodCurrencyType,
  totalLossFinalFmv: zodCurrencyType,
  totalLossProposedFmv: zodCurrencyType,
  value: zodCurrencyType,
  vin: z.string().optional(),
  year: z.string().optional(),
});

export const useVehicleForm = ({ vehicle }: { vehicle?: VehicleType }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: vehicle,
  });
  const updateVehicle = useUpdateVehicleForScenario();
  const handleUpdateVehicle = (
    name: keyof Omit<VehicleType, 'vehicleId' | 'firmId' | 'dateCreated' | 'dateModified'>,
    value: string | number | undefined | null,
  ) => {
    if (!vehicle?.vehicleId) {
      enqueueSnackbar('Error updating vehicle', {
        variant: 'error',
      });

      return;
    }
    if (form.getFieldState(name)?.isDirty) {
      updateVehicle
        .mutateAsync({
          vehicleId: vehicle?.vehicleId,
          [name]: !isNil(value) ? value : null,
        })
        .then(() => form.resetField(name, { defaultValue: value }));
    }
  };
  return { form, handleUpdateVehicle };
};
