import axios, { AxiosResponse } from 'axios';

export type UploadDocumentType = {
  url: string;
  rawFile: File;
};
export const uploadDocument = (data: UploadDocumentType) =>
  axios
    .put<AxiosResponse<{ payload: { status: number } }>>(`${data.url}`, data.rawFile)
    .then((res) => res);
