import { registerChangeEvents } from '@colosseum/data';
import kebabCase from 'lodash/kebabCase';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/Form/Form';
import { Input } from '../../shadcn/Input/Input';

export interface CurrencyInputProps {
  formHandleBlur: (arg0: any) => void;
  name: string;
  title: string;
  maxLength?: number;
  className?: string;
}

export function CurrencyInput(props: CurrencyInputProps) {
  const { formHandleBlur, name, title, className, maxLength } = props;
  const form = useFormContext();
  const [val, setVal] = useState('');

  const handleOnBlur = () => {
    if (val) {
      formHandleBlur({
        target: {
          name: name,
          value: Number(val),
        },
      });
    } else {
      formHandleBlur({
        target: {
          name: name,
          value: null,
        },
      });
    }
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className={className}>
            <FormLabel>{title}</FormLabel>
            <FormControl data-cy={`text-input-${kebabCase(name) as string}`}>
              <NumericFormat
                defaultValue={field.value}
                maxLength={maxLength}
                currency
                decimalScale={2}
                thousandSeparator=","
                customInput={Input}
                placeholder={'0.00'}
                onValueChange={({ value }) => {
                  form.setValue(name, value);
                  field.onChange(value);
                  setVal(value);
                }}
                {...field}
                {...registerChangeEvents({ field, form, handleOnBlur })}
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

export default CurrencyInput;
