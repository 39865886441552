import {
  DefaultV3Response,
  TaskAssigneeType,
  TaskAssignerType,
  TaskCommentType,
  TaskForTaskPlanType,
  TaskPlanType,
  TaskType,
  TaskTypeOptionsType,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseStrategikonRoute = `strategikon/${API_STAGE}`;

// POST create_task_v3
export const createTaskV3 = (
  data: Partial<TaskType> & { type: TaskTypeOptionsType },
  itemId?: string,
) =>
  axiosInstance
    .post<DefaultV3Response<TaskType>>(`${baseStrategikonRoute}/v3/${itemId}/tasks`, data)
    .then((res) => res.data);

// DEL delete_task_v3
export const deleteTaskV3 = (taskId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskType>>(`${baseStrategikonRoute}/v3/tasks/${taskId}`)
    .then((res) => res.data);

// GET get_all_tasks_v3
export const getAllTasksV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TaskType[]>>(`${baseStrategikonRoute}/v3/tasks`)
    .then((res) => res.data);

// GET get_tasks_v3
export const getTasksV3 = (itemId?: string) =>
  axiosInstance
    .get<DefaultV3Response<TaskType[]>>(`${baseStrategikonRoute}/v3/${itemId}/tasks`)
    .then((res) => res.data);

// PATCH update_task_v3
export const updateTaskV3 = (taskId: string, data: Partial<TaskType>) =>
  axiosInstance
    .patch<DefaultV3Response<TaskType>>(`${baseStrategikonRoute}/v3/tasks/${taskId}`, data)
    .then((res) => res.data);

// -------------------- Plan Task ------------------------ //

// POST create_plan_task_v3
export const createTaskPlanTaskV3 = (planId: string, data: Partial<TaskForTaskPlanType>) =>
  axiosInstance
    .post<DefaultV3Response<TaskForTaskPlanType>>(
      `${baseStrategikonRoute}/v3/plans/${planId}/tasks`,
      data,
    )
    .then((res) => res.data);

// DEL delete_plan_task_v3
export const deleteTaskPlanTaskV3 = (planTaskId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskForTaskPlanType>>(
      `${baseStrategikonRoute}/v3/plans/tasks/${planTaskId}`,
    )
    .then((res) => res.data);

// GET get_plan_tasks_v3
export const getTaskPlanTasksV3 = (planId: string) =>
  axiosInstance
    .get<DefaultV3Response<TaskForTaskPlanType[]>>(
      `${baseStrategikonRoute}/v3/plans/${planId}/tasks`,
    )
    .then((res) => res.data);

// PATCH update_plan_task_v3
export const updateTaskPlanTaskV3 = (planTaskId: string, data: Partial<TaskForTaskPlanType>) =>
  axiosInstance
    .patch<DefaultV3Response<TaskForTaskPlanType>>(
      `${baseStrategikonRoute}/v3/plans/tasks/${planTaskId}`,
      data,
    )
    .then((res) => res.data);

// -------------------- Plans ------------------------ //

// POST create_plan_v3
export const createTaskPlanV3 = (data: {
  title?: string;
  description?: string;
  caseType?: string[];
}) =>
  axiosInstance
    .post<DefaultV3Response<TaskPlanType>>(`${baseStrategikonRoute}/v3/plans`, data)
    .then((res) => res.data);

// DEL delete_plan_v3
export const deleteTaskPlanV3 = (planId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskPlanType>>(`${baseStrategikonRoute}/v3/plans/${planId}`)
    .then((res) => res.data);

// GET get_plans_v3
export const getTaskPlansV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TaskPlanType[]>>(`${baseStrategikonRoute}/v3/plans`)
    .then((res) => res.data);

// PATCH update_plan_v3
export const updateTaskPlanV3 = (
  planId: string,
  data: {
    title?: string;
    description?: string;
    caseType?: string[];
  },
) =>
  axiosInstance
    .patch<DefaultV3Response<TaskPlanType>>(`${baseStrategikonRoute}/v3/plans/${planId}`, data)
    .then((res) => res.data);

// -------------------- Assignees -------------------- //

// POST create_task_assignee_v3
export const createTaskAssigneeV3 = (taskId?: string, data?: { userAssigned: string }) =>
  axiosInstance
    .post<DefaultV3Response<TaskAssigneeType>>(
      `${baseStrategikonRoute}/v3/tasks/${taskId}/assignees`,
      data,
    )
    .then((res) => res.data);

// DEL delete_task_assignee_v3
export const deleteTaskAssigneeV3 = (assigneeId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskAssigneeType>>(
      `${baseStrategikonRoute}/v3/tasks/assignees/${assigneeId}`,
    )
    .then((res) => res.data);

// -------------------- Assigners -------------------- //

// POST create_task_assigner_v3
export const createTaskAssignerV3 = (taskId?: string, data?: { userAssigned: string }) =>
  axiosInstance
    .post<DefaultV3Response<TaskAssignerType>>(
      `${baseStrategikonRoute}/v3/tasks/${taskId}/assigners`,
      data,
    )
    .then((res) => res.data);

// DEL delete_task_assigner_v3
export const deleteTaskAssignerV3 = (assignerId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskAssignerType>>(
      `${baseStrategikonRoute}/v3/tasks/assigners/${assignerId}`,
    )
    .then((res) => res.data);

// -------------------- Task Comment -------------------- //

// POST create_task_comment_v3
export const createTaskCommentV3 = (taskId?: string, data?: { message: string }) =>
  axiosInstance
    .post<DefaultV3Response<TaskCommentType>>(
      `${baseStrategikonRoute}/v3/tasks/${taskId}/comments`,
      data,
    )
    .then((res) => res.data);

// DEL delete_task_comment_v3
export const deleteTaskCommentV3 = (taskCommentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<TaskCommentType>>(
      `${baseStrategikonRoute}/v3/tasks/comments/${taskCommentId}`,
    )
    .then((res) => res.data);

// PATCH update_task_comment_v3
export const updateTaskCommentV3 = (taskCommentId: string, data: { message: string }) =>
  axiosInstance
    .patch<DefaultV3Response<TaskCommentType>>(
      `${baseStrategikonRoute}/v3/tasks/comments/${taskCommentId}`,
      data,
    )
    .then((res) => res.data);
