import { cn } from '@colosseum/data';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

export interface ListItem {
  label: string;
  jsxLabel?: JSX.Element;
  onClick: () => void;
}
interface EntityAddPopoverProps {
  title: string;
  onAdd: () => void; //TODO: This should be allowed to be undefined if hideCreateNew is true
  isLoading?: boolean;
  isFetchingMore?: boolean;
  buttonVariant?: 'primary' | 'outline';
  isError?: boolean;
  listHeight?: number;
  listItems: ListItem[];
  primary?: boolean;
  placeholder?: string;
  buttonClassName?: string;
  hideCreateNew?: boolean;
  hideSearch?: boolean;
}

export const EntityAddPopover = ({
  title,
  onAdd,
  isLoading,
  isError,
  isFetchingMore,
  listHeight,
  listItems,
  primary,
  placeholder,
  buttonClassName,
  hideCreateNew,
  hideSearch,
  buttonVariant,
}: EntityAddPopoverProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  // Sort list items alphabetically by label
  const sortedItems = useMemo(() => {
    return listItems.sort((a, b) => a.label.localeCompare(b.label));
  }, [listItems]);

  // Filter sorted items based on search term
  const filteredItems = sortedItems.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={isLoading} onClick={() => setOpen(false)}>
        <Button
          variant={buttonVariant ?? 'primary'}
          type="button"
          disabled={isLoading}
          className={cn(
            primary && 'py-2',
            buttonClassName,
            isLoading && 'opacity-50 pointer-events-none bg-gray-500',
          )}
        >
          {isLoading ? <GladiateLoader white height={20} /> : title}
        </Button>
      </PopoverTrigger>
      {isError && (
        <PopoverContent>
          <Typography variant="subtext" className="px-4 py-2">
            Error fetching data. Gladiate has been notified.
          </Typography>
        </PopoverContent>
      )}
      {!isError && (
        <PopoverContent className="z-50 space-y-1 overflow-auto max-h-72">
          <div className="sticky top-0 z-10 px-1 bg-white">
            {!hideSearch && (
              <div className="flex mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-blue focus:border-transparent">
                <div className="absolute py-3 left-4">
                  {isFetchingMore || isLoading ? (
                    <GladiateLoader height={16} />
                  ) : (
                    <MagnifyingGlassIcon className="w-4 h-4 text-gray-700 " />
                  )}
                </div>
                <input
                  type="text"
                  placeholder={placeholder ?? 'Search...'}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full py-2 pl-8 rounded-md"
                />
              </div>
            )}
            {!hideCreateNew && (
              <button
                onClick={() => {
                  onAdd();
                  setOpen(false);
                }}
                className="w-full px-4 py-2 text-sm font-semibold text-left text-blue-700 bg-blue-100 rounded-md hover:bg-blue-200"
              >
                {isLoading ? 'Adding...' : 'Add New...'}
              </button>
            )}
          </div>
          <div className="p-1">
            <Virtuoso // virtualize this as it is a large list
              style={{ height: listHeight ? `${listHeight}px` : '180px' }}
              totalCount={filteredItems.length}
              itemContent={(index: number) => (
                <button
                  key={index}
                  onClick={() => {
                    filteredItems[index].onClick();
                    setOpen(false);
                  }}
                  className="flex items-center w-full px-3 py-2 text-left rounded-md hover:bg-gray-100"
                >
                  {filteredItems[index]?.jsxLabel ?? filteredItems[index].label}
                </button>
              )}
            />
          </div>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default EntityAddPopover;
