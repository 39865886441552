import {
  casesSectionMappings,
  enqueueAPISnackbar,
  mergeDiscoveryAndContacts,
  scrollToCaseSection,
  tanstackTableNames,
  useCreateCaseContactConnectionWithItem,
  useCreateDiscovery,
  useDeleteDiscovery,
  useGetCaseContactConnections,
  useGetContactsInfinite,
  useGetDiscoveriesForCase,
  useGetRoleIdForItem,
  useGetRoleIdListForItem,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
} from '@colosseum/shared-ui';
import { DiscoveryType, caseContactConnectionOptions } from '@gladiate/types';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import DiscoveryForm from '../DiscoveryForm/DiscoveryForm';
import { columns } from './discovery-table-columns';

export interface DiscoveryProps {
  caseId: string;
}

export function Discovery(props: DiscoveryProps) {
  const { caseId } = props;
  const [activeDiscoveryId, setActiveDiscoveryId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [typing, setTyping] = useState(false);

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const createDiscovery = useCreateDiscovery();
  const deleteDiscovery = useDeleteDiscovery();

  const { data: discoveryData, isLoading: isDiscoveryLoading } = useGetDiscoveriesForCase(caseId);

  const activeDiscovery = discoveryData?.data.find(
    (discovery) => discovery.discoveryId === activeDiscoveryId,
  );

  const { data: contactsData } = useGetContactsInfinite();

  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');

  const mergedClientDiscoveries = mergeDiscoveryAndContacts({
    caseContactConnections: caseContactConnectionsData?.data ?? [],
    contacts: contactsData?.data ?? [],
    discoveries: discoveryData?.data ?? [],
  });

  const { createCaseContactConnectionWithItemSoleClient } =
    useCreateCaseContactConnectionWithItem();

  const getRoleIdForItem = useGetRoleIdForItem<DiscoveryType>(caseId);
  const getRoleIdListForItem = useGetRoleIdListForItem<DiscoveryType>(caseId);

  const discoveriesWithCaseContacts = mergedClientDiscoveries.map((discovery) => {
    const defendantId = getRoleIdForItem(
      discovery,
      caseContactConnectionOptions.defendant,
      'discoveryId',
    );
    const defendant = contactsData?.data?.find((contact) => contact.contactId === defendantId);
    const servingDiscoveryIdList = getRoleIdListForItem(
      discovery,
      caseContactConnectionOptions.servingDiscovery,
      'discoveryId',
    ).map((item) => item.contactId);
    const servingDiscovery =
      contactsData?.data?.filter((contact) => servingDiscoveryIdList.includes(contact.contactId)) ||
      [];
    return {
      ...discovery,
      defendant,
      servingDiscovery,
    };
  });

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'discovery' && !isDiscoveryLoading) {
      if (discoveryData?.data.find((d) => d.discoveryId === pendingSlideoverToOpen?.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.litigation}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActiveDiscoveryId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Discovery not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, isDiscoveryLoading]);

  return (
    <>
      <ResourceSlideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (activeDiscovery?.discoveryId) {
            deleteDiscovery.mutate(activeDiscovery.discoveryId);
          }
        }}
        title="Discovery"
        description="Add and edit key discovery information."
        typing={typing}
        createType="discovery"
        resourceId={activeDiscovery?.discoveryId ?? ''}
        caseId={caseId}
      >
        <TabsContent value="details">
          <DiscoveryForm discovery={activeDiscovery} setTyping={setTyping} caseId={caseId} />
        </TabsContent>
      </ResourceSlideover>
      <div className="">
        <div className="justify-end mb-4 sm:flex sm:items-center">
          <CreateButton
            title={'Add Discovery'}
            loading={createDiscovery.isLoading}
            onClick={() => {
              createDiscovery.mutateAsync(caseId).then((res) => {
                setActiveDiscoveryId(res?.data.discoveryId);
                setOpen(true);
                createCaseContactConnectionWithItemSoleClient({
                  caseId,
                  itemId: res.data.discoveryId,
                  itemType: 'discovery',
                  roleOnCase: 'client',
                  caseContactConnections: caseContactConnectionsData?.data,
                });
              });
            }}
          />
        </div>
        <DataTable
          data={discoveriesWithCaseContacts ?? []}
          initialSort={{
            id: 'Date Created',
            desc: true,
          }}
          showSearchBar
          filters={[]}
          columns={columns}
          handleRowClick={(item) => {
            const group = item.getIsGrouped();
            if (!group) {
              setActiveDiscoveryId(item.original.discoveryId);
              setOpen(true);
            }
          }}
          initialVisibility={
            {
              'Date Created': false,
              Defendant: false,
              'Sent or Received': false,
            } as VisibilityState
          }
          // These take priority over user preferences, even if the user has column preferences in local storage
          persistentVisibility={{ 'Sent or Received / Defendant': true }}
          isLoading={isDiscoveryLoading}
          autoResetExpanded={false}
          tableName={tanstackTableNames.discovery}
          grouping={['Sent or Received / Defendant']}
        />
      </div>
    </>
  );
}

export default Discovery;
