/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { cn } from '@colosseum/data';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import kebabCase from 'lodash/kebabCase';
import pluralize from 'pluralize';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../shadcn/Form/Form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../shadcn/Select/Select';

/* eslint-disable-next-line */
interface SharedProps {
  defaultValue?: string;
  value?: string;
  name: string;
  title: string;
  placeholderText?: string;
  emptyMessage?: string;
  handleOnChange: (arg0: any) => void;
  triggerClassName?: string;
  contentClassNames?: string;
  valueClassNames?: string;
  disabled?: boolean;
  tooltipMessage?: string;
}

interface ListItemsIsObjectProps {
  listItemsIsObjectWithDetails?: false;
  listItemsIsObject: true;
  listItems: {
    [key: string]: string;
  };
}

interface ListItemsIsStringProps {
  listItemsIsObjectWithDetails?: false;
  listItemsIsObject?: false;
  listItems: string[];
}

interface ListItemsObjectsWithDetailsProps {
  listItemsIsObject?: false;
  listItemsIsObjectWithDetails: true;
  listItems: {
    [key: string]: {
      value: string;
      details: string;
    };
  };
}

export type SelectFormInputProps = SharedProps &
  (ListItemsIsObjectProps | ListItemsIsStringProps | ListItemsObjectsWithDetailsProps);

export const SelectFormInput = React.forwardRef<HTMLInputElement, SelectFormInputProps>(
  (props, ref) => {
    const {
      contentClassNames,
      defaultValue,
      disabled,
      emptyMessage,
      handleOnChange,
      listItems,
      listItemsIsObject,
      listItemsIsObjectWithDetails,
      name,
      placeholderText,
      title,
      tooltipMessage,
      triggerClassName,
      value,
      valueClassNames,
    } = props;
    const form = useFormContext();
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem data-cy={`select-input-${kebabCase(name) as string}`} className={'w-full'}>
            <FormLabel>
              <div className="flex gap-1">
                {title}
                {tooltipMessage && (
                  <TooltipWrapper message={tooltipMessage}>
                    <QuestionMarkCircleIcon className="self-center w-3 h-3 text-gray-500" />
                  </TooltipWrapper>
                )}
              </div>
            </FormLabel>

            <Select
              defaultValue={defaultValue ?? undefined}
              value={value ?? undefined}
              onValueChange={(value) => {
                form.setValue(name, value, { shouldDirty: true });
                handleOnChange({ target: { name: name, value } });
              }}
            >
              <FormControl>
                <SelectTrigger disabled={disabled} className={cn('w-full', triggerClassName)}>
                  <div className={valueClassNames}>
                    <SelectValue placeholder={placeholderText || `Select a ${title as string}`} />
                  </div>
                </SelectTrigger>
              </FormControl>

              <SelectContent ref={ref} className={cn('w-full', contentClassNames)}>
                {listItems &&
                  // If this is an array, this will just generate keys such as [0,1,2] and will still grab the values for display
                  // If this is a dict, it will use the object keys to display values and use the object values as select value
                  // ------------ IF using an object you MUST specify listItemsIsObject={true} ------------ (might be a way to do this without a flag but this works for now)
                  Object.keys(listItems).map((key: number | string) => {
                    if (listItemsIsObjectWithDetails) {
                      return (
                        <SelectItem key={listItems[key].value} value={listItems[key].value}>
                          <div className="flex max-w-[400px] line-clamp-2">{key}</div>
                          <div className="text-xs text-gray-500 max-w-[400px] line-clamp-2">
                            {listItems[key].details}
                          </div>
                        </SelectItem>
                      );
                    }

                    return (
                      <SelectItem
                        className="w-full"
                        //@ts-ignore
                        key={listItems[key]}
                        //@ts-ignore
                        value={listItems[key]}
                      >
                        <div className="max-w-[400px] line-clamp-1">
                          {/* @ts-ignore */}
                          {listItemsIsObject ? key : listItems[key]}
                        </div>
                      </SelectItem>
                    );
                  })}

                {listItems && (Object.keys(listItems).length === 0 || listItems.length === 0) && (
                  <SelectItem disabled value="">
                    {emptyMessage ?? `No ${pluralize(title.toLowerCase())} found.`}
                  </SelectItem>
                )}
              </SelectContent>
            </Select>

            <FormMessage />
          </FormItem>
        )}
      />
    );
  },
);

SelectFormInput.displayName = 'SelectFormInput';

export default SelectFormInput;
