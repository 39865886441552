import {
  displayContactName,
  useCreateContactResource,
  useDeleteContactResource,
  useGetCommunications,
  useUpdateContactResource,
} from '@colosseum/data';
import { ContactNumberType, ContactViewModelType } from '@gladiate/types';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { camelCase, startCase } from 'lodash';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionButtonGroup from '../../ActionButtonGroup/ActionButtonGroup';
import CreateButton from '../../CreateButton/CreateButton';
import Messenger from '../../Messenger/Messenger';
import PhoneInput from '../PhoneInput/PhoneInput';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface ClientPhoneNumbersFormProps {
  contact: ContactViewModelType;
  caseId: string;
}

export function ClientPhoneNumbersForm(props: ClientPhoneNumbersFormProps) {
  const { contact, caseId } = props;

  const [messengerOpen, setMessengerOpen] = useState(false);
  const [numberToText, setNumberToText] = useState('');
  const { data: communicationsData, isLoading: isCommunicationsDataLoading } = useGetCommunications(
    contact?.contactId,
  );

  const form = useFormContext();
  const phoneNumberForm = useFieldArray({
    control: form.control,
    name: 'contactNumbers', // unique name for your Field Array
  });
  const createContactPhoneNumber = useCreateContactResource<ContactNumberType>('numbers');
  const deleteContactPhoneNumber = useDeleteContactResource<ContactNumberType>('numbers');
  const updateContactPhoneNumber = useUpdateContactResource<ContactNumberType>('numbers');
  const isLoading =
    createContactPhoneNumber.isLoading ||
    deleteContactPhoneNumber.isLoading ||
    updateContactPhoneNumber.isLoading;

  const handleUpdateContactPhoneNumber = (name: string, value: string, i: number) => {
    form.setValue(`contactNumbers.${i}.${name}`, value);
    updateContactPhoneNumber.mutate({
      resourceId: contact?.contactNumbers?.[i]?.contactNumberId,
      [name]: value,
    });
  };

  const textingDisabled =
    contact?.contactNumbers?.length === 0 ||
    (contact?.contactNumbers?.[0]?.number?.length ?? 0) < 11;

  return (
    <>
      <Messenger
        caseId={caseId}
        toPhoneNumbers={[
          {
            phoneNumbers: contact?.contactNumbers,
            name: displayContactName(contact),
            role: 'Contact',
          },
        ]}
        contact={contact}
        communicationsData={communicationsData?.data}
        messengerOpen={messengerOpen}
        setMessengerOpen={setMessengerOpen}
        overrideDefaultNumberToText={numberToText}
      />

      <div className="divide-y divide-gray-300">
        {phoneNumberForm.fields?.map(
          (field: Record<'id', string> & { contactNumberId?: string }, i) => (
            <div key={field.id} className="grid grid-cols-2 py-5 mt-3 mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`contactNumbers.${i}.numberLabel`)}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactPhoneNumber('numberLabel', target.value, i);
                }}
                title="Number Label"
                data-cy={`contactNumbers.${i}.numberLabel`}
              />

              <SelectFormInput
                listItems={['Phone', 'Fax']}
                name={'numberType'}
                title={'Number Type'}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactPhoneNumber('numberType', camelCase(target.value), i);
                }}
                defaultValue={startCase(form.getValues(`contactNumbers.${i}.numberType`))}
              />

              <PhoneInput
                {...form.register(`contactNumbers.${i}.number`)}
                formHandleBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactPhoneNumber('number', target.value, i);
                }}
                title="Number"
                data-cy={`contactNumbers.${i}.number`}
              />

              <TextFormInput
                {...form.register(`contactNumbers.${i}.numberExtension`)}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactPhoneNumber('numberExtension', target.value, i);
                }}
                title="Number Extension"
                data-cy={`contactNumbers.${i}.numberExtension`}
              />

              <ActionButtonGroup
                copyText={form.getValues(`contactNumbers.${i}.number`)}
                deleteFunction={() => {
                  deleteContactPhoneNumber.mutate({
                    resourceId: contact?.contactNumbers?.[i]?.contactNumberId,
                  });
                  phoneNumberForm.remove(i);
                }}
                dataCyTitle={`number-${i}`}
                additionalButton1={{
                  disabled: textingDisabled,
                  icon: <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />,
                  message: `Text ${form.getValues(`contactNumbers.${i}.number`)}`,
                  clickHandler: () => {
                    setMessengerOpen(true);
                    setNumberToText(form.getValues(`contactNumbers.${i}.number`));
                  },
                }}
              />
            </div>
          ),
        )}
      </div>
      <div className="col-span-2">
        <CreateButton
          disabled={isLoading}
          loading={isLoading}
          dataCy="create-number-button"
          title="Add Number"
          onClick={() => {
            createContactPhoneNumber.mutateAsync({ contactId: contact?.contactId }).then((res) => {
              phoneNumberForm.append({
                contactPhoneNumberId: res.data?.contactNumberId,
              });
            });
          }}
        />
      </div>
    </>
  );
}

export default ClientPhoneNumbersForm;
