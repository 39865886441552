import { useParams } from 'react-router';
import MedicalsSection from './MedicalsSection/MedicalsSection';
import { CaseDetailPage } from './CaseDetailPage/CaseDetailPage';

export const CasePage = () => {
  const { caseId } = useParams() as { caseId: string };

  return (
    <div className="@container">
      <CaseDetailPage caseId={caseId} />
    </div>
  );
};

export default CasePage;
