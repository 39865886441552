import { cn, getAbsoluteUrl } from '@colosseum/data';
import { FileResourceType } from '@gladiate/types';
import { DocumentIcon, FolderIcon, LinkIcon, TrashIcon } from '@heroicons/react/24/outline';

/* eslint-disable-next-line */
export interface AttachedFileChipProps {
  file: FileResourceType;
  downloadLink: string;
  onPreview: (file: FileResourceType) => void;
  onDelete: (fileId: string) => void;
  deletable: boolean;
  hideName?: boolean;
}

export function AttachedFileChip(props: AttachedFileChipProps) {
  const { file, downloadLink, onPreview, onDelete, deletable, hideName } = props;

  const IMAGE_URL_REGEX = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g;
  const isImage = IMAGE_URL_REGEX.test(downloadLink);
  const fileType = isImage ? 'IMAGE' : file.resourceType;

  const fileName = hideName ? '' : file.name;

  const deleteButton = () => {
    if (!deletable) return null;
    return (
      <button
        type="button"
        className="pl-1 text-red-500 hover:text-red-800"
        onClick={(e) => {
          e.stopPropagation();
          if (!file.resourceId) return;

          onDelete(file.resourceId);
        }}
      >
        <TrashIcon className="w-4 h-4 " />
      </button>
    );
  };

  const renderAttachment = () => {
    const sharedClasses = {
      group:
        'flex items-center px-2 group bg-gray-100 rounded-lg min-h-16 gap-2 text-gray-500 justify-between',
      button:
        'flex items-center gap-2 hover:underline hover:text-black group group-hover:text-black text-gray-500 overflow-hidden',
    };

    switch (fileType) {
      case 'LINK':
        return (
          <div
            // className="flex items-center overflow-hidden text-sm text-gray-500 cursor-pointer hover:underline hover:text-black whitespace-nowrap text-ellipsis"
            className={cn(sharedClasses.group)}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getAbsoluteUrl(file.urlRefLink ?? '')}
              className={cn(sharedClasses.button)}
            >
              <LinkIcon className="w-5 h-5" />
              {file.name}
            </a>
            {deleteButton()}
          </div>
        );
      case 'DIR':
        return (
          <div
            // className="overflow-hidden text-sm text-gray-500 cursor-pointer hover:underline hover:text-black whitespace-nowrap text-ellipsis"
            className={cn(sharedClasses.group)}
          >
            <button
              type="button"
              onClick={() => onPreview(file)}
              className={cn(sharedClasses.button)}
            >
              <FolderIcon className="w-5 h-5" />
              {fileName}
            </button>
            {deleteButton()}
          </div>
        );
      case 'IMAGE':
        return (
          <div
            // className="overflow-hidden text-sm text-gray-500 cursor-pointer hover:underline hover:text-black whitespace-nowrap text-ellipsis"
            className={cn(sharedClasses.group)}
          >
            <button
              type="button"
              className={cn(sharedClasses.button)}
              onClick={() => onPreview(file)}
            >
              <img
                src={downloadLink}
                alt={file.name}
                className="object-cover rounded-md shadow-md size-11 "
              />
              <div className="overflow-hidden hover:underline hover:text-black text-ellipsis">
                {fileName}
              </div>
            </button>

            {deleteButton()}
          </div>
        );
      default:
        return (
          <div
            // className="overflow-hidden text-sm text-gray-500 cursor-pointer hover:underline hover:text-black whitespace-nowrap text-ellipsis"
            className={cn(sharedClasses.group)}
          >
            <button
              type="button"
              onClick={() => onPreview(file)}
              className={cn(sharedClasses.button)}
            >
              <DocumentIcon className="w-5 h-5" />

              {fileName}
            </button>
            {deleteButton()}
          </div>
        );
    }
  };

  return renderAttachment();
}

export default AttachedFileChip;
