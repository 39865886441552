import { tanstackTableNames } from '@colosseum/data';
import { DataTable } from '@colosseum/shared-ui';
import { CaseContactConnectionRoleType, ContactType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { partyColumns } from './party-table-columns';

/* eslint-disable-next-line */
export interface LinkedPartiesTableProps {
  parties: ((ContactType & { roles: CaseContactConnectionRoleType[] }) | undefined)[];
  handleRowClick: (row: Row<ContactType>) => void;
  isLoading: boolean;
}

export function LinkedPartiesTable(props: LinkedPartiesTableProps) {
  const { parties, handleRowClick, isLoading } = props;
  return (
    <DataTable
      hideFooter
      hideToolbar
      columns={partyColumns}
      data={parties ?? []}
      handleRowClick={handleRowClick}
      isLoading={isLoading}
      tableName={tanstackTableNames.scenarioParties}
    />
  );
}

export default LinkedPartiesTable;
