import { useDeletePresetFilter, useGetPresetFilters } from '@colosseum/data';
import { FilterSelect, FilterSelectProps } from '@colosseum/shared-ui';
import { PresetFilterType } from '@gladiate/types';
import { useState } from 'react';

export function PresetFilterSelect(
  props: Pick<
    FilterSelectProps<PresetFilterType>,
    'activeFilter' | 'handleItemSelect' | 'placeholder' | 'triggerClassName'
  >,
) {
  const [open, setOpen] = useState(false);

  const { data: presetFilters, isLoading: isPresetFiltersLoading } = useGetPresetFilters();
  const deletePresetFilter = useDeletePresetFilter();

  return (
    <FilterSelect<PresetFilterType>
      {...props}
      data={presetFilters?.data}
      deleteFilter={deletePresetFilter}
      isLoading={isPresetFiltersLoading}
      open={open}
      resourceIdKey="presetFilterId"
      emptyMessage="No preset filters have been created."
      setOpen={setOpen}
    />
  );
}

export default PresetFilterSelect;
