import { cn } from '@colosseum/data';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface LineClampedTextContainerProps {
  text: string;
  className?: string;
}

export function LineClampedTextContainer(props: LineClampedTextContainerProps) {
  const { text, className } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const [showEntireMessage, setShowEntireMessage] = useState(false);

  const [seeMoreVisible, setSeeMoreVisible] = useState(false);

  const checkIfSeeMoreVisible = () => {
    if (divRef?.current) {
      if (divRef?.current?.offsetHeight < divRef?.current?.scrollHeight) {
        setSeeMoreVisible(true); // if the div is overflowing, show the see more button
      } else {
        setSeeMoreVisible(false); // if the div is not overflowing, hide the see more button
      }
    }
  };

  useEffect(() => {
    checkIfSeeMoreVisible();
  }, [text]);

  return (
    <>
      <div
        ref={divRef}
        className={cn(
          'line-clamp-4 whitespace-pre-wrap', // to override the line-clamp-4 class, just pass line-clamp-x in className
          className,
          showEntireMessage && 'line-clamp-none',
        )}
      >
        {text}
      </div>
      {!showEntireMessage && (
        <Button
          variant="link"
          size="unset"
          className={cn(!seeMoreVisible && 'invisible h-0')}
          onClick={() => setShowEntireMessage(true)}
        >
          Show all
        </Button>
      )}
      {showEntireMessage && (
        <Button
          variant="link"
          size="unset"
          className={cn(!seeMoreVisible && 'invisible')}
          onClick={() => {
            setShowEntireMessage(false);
            setTimeout(checkIfSeeMoreVisible, 100); // this needs a timeout to give time for the div to collapse
          }}
        >
          Show less
        </Button>
      )}
    </>
  );
}

export default LineClampedTextContainer;
