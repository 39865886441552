import { cn } from '@colosseum/data';

export const MP3Icon = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 303.188 303.188"
    className={cn('w-8 h-8 min-w-8', className)}
  >
    <g>
      <polygon
        style={{ fill: '#E8E8E8' }}
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
      />
      <g>
        <path
          style={{ fill: '#A4A9AD' }}
          d="M103.524,273.871l-9.813-34.557h-0.295c0.459,5.886,0.689,10.459,0.689,13.718v20.839H82.62
			v-47.979h17.262l10.01,34.065h0.262l9.813-34.065h17.295v47.979H125.35v-21.036c0-1.094,0.016-2.308,0.049-3.643
			c0.033-1.334,0.181-4.605,0.443-9.813h-0.296l-9.681,34.491h-12.341V273.871z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M182.418,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917h-12.963v-47.979h16.605c6.06,0,10.61,1.324,13.652,3.971C180.896,232.51,182.418,236.273,182.418,241.152z
			 M160.562,247.387h2.362c1.947,0,3.495-0.546,4.643-1.641c1.149-1.094,1.724-2.604,1.724-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.347L160.562,247.387L160.562,247.387z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M220.092,236.295c0,2.976-0.908,5.53-2.724,7.663s-4.441,3.681-7.876,4.643v0.197
			c8.008,1.007,12.011,4.792,12.011,11.355c0,4.463-1.767,7.975-5.3,10.535s-8.439,3.839-14.719,3.839
			c-2.582,0-4.972-0.186-7.171-0.558c-2.198-0.372-4.577-1.05-7.138-2.035V261.17c2.101,1.072,4.239,1.865,6.416,2.379
			c2.177,0.515,4.185,0.771,6.023,0.771c2.844,0,4.917-0.399,6.219-1.198s1.952-2.051,1.952-3.758c0-1.313-0.339-2.324-1.017-3.036
			c-0.679-0.71-1.773-1.247-3.282-1.608c-1.51-0.361-3.479-0.542-5.907-0.542h-2.953v-9.747h3.019c6.586,0,9.879-1.684,9.879-5.054
			c0-1.268-0.487-2.209-1.461-2.822c-0.974-0.612-2.28-0.919-3.922-0.919c-3.064,0-6.235,1.029-9.517,3.085l-5.382-8.664
			c2.537-1.75,5.136-2.997,7.794-3.741s5.704-1.116,9.14-1.116c4.966,0,8.86,0.984,11.683,2.954
			C218.681,230.126,220.092,232.838,220.092,236.295z"
        />
      </g>
      <polygon
        style={{ fill: '#38A3FF' }}
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
      />
      <polygon style={{ fill: '#D1D3D3' }} points="219.821,50.525 270.346,50.525 219.821,0 	" />
      <g>
        <circle style={{ fill: '#38A3FF' }} cx="150.304" cy="122.143" r="59.401" />
        <path
          style={{ fill: '#333E48' }}
          d="M130.903,91.176v47.938c-1.681-0.199-3.551-0.154-5.529,0.195
			c-7.212,1.271-13.057,5.968-13.057,10.49s5.845,7.157,13.057,5.886c7.211-1.271,13.056-5.968,13.056-10.49v-38.703l32.749-5.775
			v31.294c-1.68-0.199-3.549-0.153-5.529,0.196c-7.213,1.271-13.057,5.968-13.057,10.49s5.844,7.157,13.057,5.886
			c7.21-1.271,13.056-5.968,13.056-10.49V82.748L130.903,91.176z"
        />
      </g>
    </g>
  </svg>
);
