import {
  displayContactName,
  tanstackTableNames,
  useDeleteExpense,
  useGetExpenseCategories,
  useGetFirmCaseExpenses,
  useGetFirmUserWithDisplayNameFromUsername,
  useGetUTBMSCodes,
} from '@colosseum/data';
import { DataTable, ExpenseForm, ResourceSlideover, SlideoverContext } from '@colosseum/shared-ui';
import { caseContactConnectionOptions } from '@gladiate/types';
import { TabsContent } from '@radix-ui/react-tabs';
import { uniq, uniqBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { lineupExpensesColumns } from '../lineup-expenses-columns';

export interface ExpenseLineupsTableProps {}

export function ExpenseLineupsTable(props: ExpenseLineupsTableProps) {
  const [showModal, setShowModal] = useState(false);
  const [typing, setTyping] = useState(false);
  const [activeExpenseId, setActiveExpenseId] = useState<string | undefined>();

  const { data: firmCaseExpensesData, isLoading: isCaseExpensesLoading } = useGetFirmCaseExpenses();
  const { getFirmUserWithDisplayNameFromUsername } = useGetFirmUserWithDisplayNameFromUsername();
  const { data: expenseCategories } = useGetExpenseCategories();
  const { data: UTBMSCodesData } = useGetUTBMSCodes();
  const deleteExpenseMutation = useDeleteExpense();
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const navigate = useNavigate();

  const tableData = useMemo(
    () =>
      firmCaseExpensesData?.data?.map((expense) => {
        const client =
          expense?.caseContactConnection?.length > 0
            ? expense?.caseContactConnection?.filter((connection) =>
                connection.roles.some(
                  (role) => role.roleOnCase === caseContactConnectionOptions.client,
                ),
              )?.[0]?.contact
            : null;
        const vendor =
          expense?.caseContactConnection?.length > 0
            ? expense?.caseContactConnection?.filter((connection) =>
                connection.roles.some(
                  (role) => role.roleOnCase === caseContactConnectionOptions.vendor,
                ),
              )?.[0]?.contact
            : null;
        return {
          ...expense,
          case: expense.case,
          totalCost:
            expense.unitCount && expense.unitPrice
              ? expense.unitCount * expense.unitPrice
              : undefined,
          category: expense.caseExpenseCategory ? expense.caseExpenseCategory : undefined,
          categoryId: expense.caseExpenseCategory?.caseExpenseCategoryId ?? undefined,
          UTBMSCode: expense.caseExpenseUtbmsCode ?? null,
          utbmsCodeId: expense.caseExpenseUtbmsCode?.caseExpenseUtbmsCodeId ?? undefined,
          contact: client,
          vendor: vendor ? displayContactName(vendor) : undefined,
          incurredByDisplayNames: expense.incurredBy?.map((incurrer) => {
            return getFirmUserWithDisplayNameFromUsername(incurrer).displayName ?? incurrer;
          }),
        };
      }) ?? [],
    [firmCaseExpensesData?.data],
  );

  const tableFilters = useMemo(() => {
    const filters: {
      id: string;
      options: { value: string; label: string }[];
    }[] = [
      {
        id: 'Case',
        options:
          uniqBy(
            tableData?.filter((expense) => expense.case?.caseTitle),
            (expense) => expense.case?.caseId,
          )?.map((expense) => ({
            value: expense?.case?.caseTitle ?? '',
            label: expense?.case?.caseTitle ?? '',
          })) ?? [],
      },
      {
        id: 'Category',
        options:
          expenseCategories?.data.map((category) => ({
            value: category?.title ?? '',
            label: category?.title ?? '',
          })) ?? [],
      },
      {
        id: 'UTBMS Code',
        options:
          UTBMSCodesData?.data.map((code) => ({
            value: code ? `${code?.code} ${code?.name}` : '-',
            label: code ? `${code?.code} ${code?.name}` : '-',
          })) ?? [],
      },
      {
        id: 'Client',
        options:
          uniqBy(
            tableData?.filter((expense) => expense.contact),
            (expense) => expense.contact?.contactId,
          )?.map((expense) => ({
            value: expense?.contact ? displayContactName(expense?.contact) : '',
            label: expense?.contact ? displayContactName(expense?.contact) : '',
          })) ?? [],
      },
      {
        id: 'Vendor',
        options: uniqBy(
          tableData?.filter((expense) => expense.vendor),
          (expense) => expense.vendor,
        )?.map(
          (expense) =>
            ({
              value: expense.vendor ?? '',
              label: expense.vendor ?? '',
            } ?? []),
        ),
      },
      {
        id: 'Incurred By',
        options: uniq(
          tableData
            .map((expense) => expense.incurredByDisplayNames)
            .flat()
            .filter((displayName) => displayName),
        ).map(
          (incurredBy) =>
            ({
              value: incurredBy ?? '',
              label: incurredBy ?? '',
            } ?? []),
        ),
      },
    ];
    return filters;
  }, [expenseCategories?.data, tableData, UTBMSCodesData?.data]);

  const activeExpense = tableData.find((expense) => expense.caseExpenseId === activeExpenseId);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'expense' && !isCaseExpensesLoading) {
      setShowModal(true);
      setActiveExpenseId(pendingSlideoverToOpen?.id ?? '');
      setPendingSlideoverToOpen(undefined);
      navigate('/lineups', { replace: true });
    }
  }, [pendingSlideoverToOpen, setPendingSlideoverToOpen, isCaseExpensesLoading]);

  return (
    <div>
      <ResourceSlideover
        open={showModal}
        setOpen={setShowModal}
        title="Expense"
        description="Edit the details of your case expense."
        typing={typing}
        createType="expense"
        resourceId={activeExpense?.caseExpenseId ?? ''}
        caseId={activeExpense?.case?.caseId ?? ''}
        displayDeleteButton
        deleteFunction={() => {
          if (activeExpense?.caseExpenseId) {
            deleteExpenseMutation
              .mutateAsync(activeExpense?.caseExpenseId)
              .then(() => setShowModal(false));
          }
        }}
      >
        <TabsContent value="details">
          {activeExpense && (
            <ExpenseForm
              caseId={activeExpense?.case?.caseId}
              expense={activeExpense}
              setShowModal={setShowModal}
              typing={typing}
              setTyping={setTyping}
            />
          )}
        </TabsContent>
      </ResourceSlideover>
      <DataTable
        showSearchBar
        filters={tableFilters}
        disableAutoResetPageIndex
        isLoading={isCaseExpensesLoading}
        columns={lineupExpensesColumns}
        data={tableData}
        tableName={tanstackTableNames.lineupExpenses}
        handleRowClick={(row) => {
          setActiveExpenseId(row.original?.caseExpenseId);
          setShowModal(true);
        }}
      />
    </div>
  );
}

export default ExpenseLineupsTable;
