/* eslint-disable react/prop-types */
import { cn } from '@colosseum/data';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { buttonVariants } from '../Button/Button';

export type DefaultCalendarProps = React.ComponentProps<typeof DayPicker> & {
  month?: number;
};

function DefaultCalendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: DefaultCalendarProps & { month?: number }) {
  return (
    <DayPicker
      captionLayout="dropdown-buttons"
      // TODO: Look into - not sure why this is complaining
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultMonth={props.selected ? new Date(props?.selected) : undefined}
      fromYear={dayjs().subtract(15, 'years').year()}
      toYear={dayjs().add(15, 'years').year()}
      fixedWeeks
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 text-sm font-medium',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-light-blue first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-light-blue text-light-blue-foreground',
        day_outside: 'text-muted-foreground opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-light-blue aria-selected:text-light-blue-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon className="w-4 h-4" />,
        IconRight: ({ ...props }) => <ChevronRightIcon className="w-4 h-4" />,
      }}
      {...props}
    />
  );
}
DefaultCalendar.displayName = 'DefaultCalendar';

export { DefaultCalendar };
