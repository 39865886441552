import { cn, makeAcronym } from '@colosseum/data';
import { Avatar, AvatarFallback, AvatarImage } from '../data-display/Avatar/Avatar';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

/* eslint-disable-next-line */
export interface AssigneeChipGroupProps {
  assignees: string[];
  readonly?: boolean;
  small?: boolean;
}

export function AssigneeChipGroup(props: AssigneeChipGroupProps) {
  const { assignees, small, readonly } = props;
  // this is the +x avatar, where x is the number of assignees - 3
  const assigneesMore = assignees.length > 3 ? [`+${assignees.length - 3}`] : [];
  // make array of assignees, shortening their names to their initials
  const initialsOnlyAssignees = assignees.slice(0, 3).map((assignee) => makeAcronym(assignee, 2));
  const shortenedAssignees = [...initialsOnlyAssignees, ...assigneesMore];
  return (
    <div className="flex p-0">
      {assignees.length
        ? shortenedAssignees.map((assignee, index) => (
            <TooltipWrapper
              key={assignee}
              message={index < 3 ? assignees[index] : assignees.slice(3).join(', ')}
            >
              <Avatar
                className={cn(
                  `mr-[-8px] z-[${index}] border-2 border-white`,
                  small && 'h-6 w-6 text-xs',
                )}
              >
                <AvatarImage src="" />
                <AvatarFallback>{assignee}</AvatarFallback>
              </Avatar>
            </TooltipWrapper>
          ))
        : !readonly && (
            <Avatar>
              <AvatarImage src="" />
              <AvatarFallback>+</AvatarFallback>
            </Avatar>
          )}
    </div>
  );
}

export default AssigneeChipGroup;
