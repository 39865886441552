export const DocumentOptions: string[] = [
  'Billing Statements (Itemized)',
  'Coding Summary',
  'HCFA Forms (CMS-1500)',
  'UB-04',
  'All Billing Records',
  'Cardiology Reports',
  'Consultation Reports',
  'Discharge Summary',
  'ER Records',
  'External Notes & Reports',
  'Facesheet',
  'History & Physical Exams',
  'Implant Records',
  'Laboratory Reports',
  'Medication List',
  'Operative Reports',
  'Pathology Reports',
  'Patient Correspondence',
  'Problem List',
  'Radiology Reports',
  'Transportation Records',
  'All Medical Records',
];
