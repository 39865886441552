//! PLEASE DO NOT EDIT THIS FILE UNLESS DISCUSSED WITH THE TEAM
import { Token } from '@gladiate/types';
import axios, { AxiosInstance } from 'axios';
import jwt_decode from 'jwt-decode';
import { refreshToken } from '../utils/authUtils';
import { VITE_BASE_API_URL } from '../static/importMetaEnv';

const baseUrl = VITE_BASE_API_URL;

/**
 *
 * @description Initializes the axios instance with the necessary interceptors
 * @param axiosInstance The axios instance to initialize
 * @returns The initialized axios instance
 */
const initializeAxiosInstance = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('idToken');

      if (!token) {
        // Reject the request if no token is found
        const error = new Error('No auth token found');
        return Promise.reject(error);
      }

      // Refresh the token if it is expired
      const decoded: Token = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        refreshToken();
      }

      // Set the Authorization header
      const auth = `Bearer ${token}`;
      config.headers.Authorization = auth;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // Response interceptor to handle 401 errors and retry the request with a refreshed token
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (error && error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshToken();

        const token = localStorage.getItem('idToken');

        if (!token) {
          // Reject the request if no token is found
          const error = new Error('No auth token found');
          return Promise.reject(error);
        }

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    },
  );
  return axiosInstance;
};

// Create an axios instance with the base URL
export const axiosInstance = axios.create({
  baseURL: baseUrl,
});
initializeAxiosInstance(axiosInstance);

// Create an axios instance with the base URL and apply the axios-case-converter middleware
export const axiosInstanceCamelCase = axios.create({
  baseURL: baseUrl,
});
initializeAxiosInstance(axiosInstanceCamelCase);
