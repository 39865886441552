import {
  displayContactName,
  findRoleSpecificCaseContactConnections,
  queryKeys,
  useCreateCaseContactConnection,
  useDeleteCaseContactConnectionRole,
  useGetCaseContactConnectionsViewModel,
  useGetContactsInfinite,
  useUpdateCaseContactConnectionRole,
} from '@colosseum/data';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionViewModelType,
  CaseContactSubObject,
  ContactType,
} from '@gladiate/types';
import { PopoverContent } from '@radix-ui/react-popover';
import { useQueryClient } from '@tanstack/react-query';
import { UserPlusIcon } from 'lucide-react';
import { Fragment, useEffect, useState } from 'react';
import ContactChip from '../ContactChip/ContactChip';
import ContactInfoSlideover from '../ContactInfoSlideover/ContactInfoSlideover';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import OptionLookup from '../OptionLookup/OptionLookup';
import { Button } from '../shadcn/Button/Button';
import { FormControl, FormItem, FormLabel } from '../shadcn/Form/Form';
import { Input } from '../shadcn/Input/Input';
import { Popover, PopoverTrigger } from '../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface CaseContactConnectionLinkerProps {
  title: string;
  roleOnCase: CaseContactConnectionRoleType['roleOnCase'];
  showMultiple?: boolean;
  fields?: { key: string; title: string }[];
  itemId?: string;
  itemType?: CaseContactSubObject;
  caseId: string;
  linkingFunction?: (arg0: string) => void;
}

export function CaseContactConnectionLinker(props: CaseContactConnectionLinkerProps) {
  const { title, roleOnCase, showMultiple, fields, itemId, itemType, caseId, linkingFunction } =
    props;
  const {
    data: contactsData,
    isLoading: contactsLoading,
    isError: isContactsError,
  } = useGetContactsInfinite();
  const {
    data: caseContactConnections,
    isLoading: contactConnectionsLoading,
    isFetching: contactConnectionsFetching,
  } = useGetCaseContactConnectionsViewModel(caseId);

  const [newContactSlideoverOpen, setNewContactSlideoverOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [lastAction, setLastAction] = useState<'delete' | 'update' | undefined>(undefined);

  const queryClient = useQueryClient();
  const deleteCaseContactConnectionRole = useDeleteCaseContactConnectionRole();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const updateCaseContactConnectionRole = useUpdateCaseContactConnectionRole();
  const contactsToShow = findRoleSpecificCaseContactConnections<CaseContactConnectionViewModelType>(
    {
      caseContactConnections: caseContactConnections?.data,
      role: roleOnCase,
    },
  );

  const contactsToShowFilteredByRoleOnCase =
    (itemType &&
      itemId &&
      contactsToShow.filter((contact) => {
        const contactWithMatchingRole = contact?.roles.filter(
          (role) => role.roleOnCase === roleOnCase,
        );

        const roleWithMatchingItem = contactWithMatchingRole?.filter(
          (role) => role.itemId === itemId,
        );

        return roleWithMatchingItem?.length > 0;
      })) ||
    contactsToShow;

  const showButtonFlag =
    contactsToShowFilteredByRoleOnCase && contactsToShowFilteredByRoleOnCase.length === 0;

  const contactsToRender = contactsToShowFilteredByRoleOnCase;

  const handleCreateCaseContactConnection = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLastAction('update');
    const res = createCaseContactConnection
      .mutateAsync({
        caseId,
        roleOnCase,
      })
      .then((res) => {
        setSelectedContactId(res.data.contactId);
        setNewContactSlideoverOpen(true);
        if (itemType && itemId) {
          updateCaseContactConnectionRole.mutate({
            caseContactConnectionRoleId:
              res.data.caseContactConnectionRole?.caseContactConnectionRoleId,
            itemType,
            itemId,
          });
        }
      });
    return res;
  };

  const handleDeleteCaseContactConnectionRole = (
    e: React.SyntheticEvent,
    contact: CaseContactConnectionViewModelType,
  ) => {
    e.preventDefault();
    const rolesToDelete = contact.roles.filter(
      (role) => role.roleOnCase === roleOnCase && itemId === role.itemId,
    );
    setLastAction('delete');
    linkingFunction?.('');
    rolesToDelete.forEach((role) =>
      deleteCaseContactConnectionRole.mutateAsync(role?.caseContactConnectionRoleId ?? ''),
    );
  };

  useEffect(() => {
    if (!contactConnectionsFetching && lastAction === 'update') {
      setLastAction(undefined);
    }
  }, [contactConnectionsFetching]);

  return (
    <div>
      <ContactInfoSlideover
        contactId={selectedContactId}
        open={newContactSlideoverOpen}
        setOpen={setNewContactSlideoverOpen}
        expandSections={{
          biographical: true,
        }}
      />
      <div className="flex flex-wrap items-center self-start gap-2 text-sm whitespace-nowrap">
        <span className="h-[28px] flex items-center w-full col-span-2">{title}</span>
        {contactsToRender &&
          contactsToRender.map((contact) => (
            <Fragment key={contact.caseContactConnectionId}>
              <ContactChip
                contactId={contact.contact.contactId}
                onDelete={(e) => handleDeleteCaseContactConnectionRole(e, contact)}
              />
              {fields && (
                <div className="w-full">
                  <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                    {fields &&
                      fields.map((field) => {
                        const caseContactConnectionRole = contact.roles.find(
                          (role) => role.roleOnCase === roleOnCase,
                        );

                        const key = field.key as keyof typeof caseContactConnectionRole;

                        return (
                          <FormItem
                            key={`${field.key} ${
                              caseContactConnectionRole?.caseContactConnectionRoleId ?? ''
                            }`}
                            className="mb-2"
                          >
                            <FormLabel>{field?.title ?? 'Field'}</FormLabel>
                            <FormControl>
                              <Input
                                id={`${field.key} ${
                                  caseContactConnectionRole?.caseContactConnectionRoleId ?? ''
                                }`}
                                type="text"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                defaultValue={caseContactConnectionRole?.[key] ?? ''}
                                onBlur={(e) => {
                                  updateCaseContactConnectionRole.mutate({
                                    caseContactConnectionRoleId:
                                      caseContactConnectionRole?.caseContactConnectionRoleId,
                                    [field.key]: e.target.value,
                                  });
                                }}
                              />
                            </FormControl>
                          </FormItem>
                        );
                      })}
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        {contactsLoading ||
        contactConnectionsLoading ||
        (contactConnectionsFetching && lastAction === 'update') ||
        createCaseContactConnection.isLoading ||
        updateCaseContactConnectionRole.isLoading ? (
          <button className="flex items-center w-full text-sm text-center">
            <GladiateLoader height={42} />
          </button>
        ) : (
          <div>
            {(showButtonFlag || showMultiple) && (
              <div className="">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      size="unset"
                      className="flex items-center w-full p-2 text-sm text-center"
                    >
                      <UserPlusIcon className={'w-6 h-6 text-black'} />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="z-50 mt-2 overflow-auto border rounded-md shadow-md border-input">
                    <OptionLookup
                      isFetchingMore={!!contactsData?.meta?.lastEvaluatedKey}
                      placeholder={`Search ${contactsData?.data.length ?? 0}${
                        contactsData?.meta.lastEvaluatedKey ? '+' : ''
                      } contacts...`}
                      caseId={caseId}
                      options={contactsData?.data ?? []}
                      displayOption={(option: ContactType) => displayContactName(option)}
                      isError={isContactsError}
                      idString={'contactId'}
                      linkingFunction={(option: ContactType) => {
                        setLastAction('update');
                        return createCaseContactConnection
                          .mutateAsync({
                            caseId,
                            contactId: option?.contactId,
                            roleOnCase,
                          })
                          .then((res) => {
                            if (itemId && itemType) {
                              updateCaseContactConnectionRole
                                .mutateAsync({
                                  caseContactConnectionRoleId:
                                    res?.data.caseContactConnectionRole
                                      ?.caseContactConnectionRoleId,
                                  itemId,
                                  itemType,
                                })
                                .then(() => {
                                  // When adding new provider or billing entity we need to refresh providers
                                  queryClient.invalidateQueries([queryKeys.medicalTreatments]);
                                });
                            }
                          })
                          .then(() => {
                            linkingFunction?.(option.contactId);
                          });
                      }}
                      createFunction={handleCreateCaseContactConnection}
                      rightAlign={true}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CaseContactConnectionLinker;
