import { cn } from '@colosseum/data';
import DOMPurify from 'dompurify';

export interface InnerHtmlInputProps {
  value?: string;
  label?: string;
  variant?: 'text' | 'textarea';
}

export function InnerHtmlInput(props: InnerHtmlInputProps) {
  const { value, label = 'Text Field', variant = 'text' } = props;
  const sanitizedValue = DOMPurify.sanitize(value ?? '') as string;
  return (
    <div className="relative col-span-2 ">
      <label className="absolute z-10 inline-block px-1 text-xs font-medium text-gray-400 bg-white rounded-sm peer-disabled:cursor-not-allowed peer-disabled:opacity-70 -top-2 left-2">
        {label}
      </label>
      <div
        className={cn(
          'form-input sm:text-sm block w-full py-2  text-gray-500 border-0 rounded-md shadow-sm cursor-default form-textarea placeholder:text-gray-400 sm:leading-6 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue whitespace-pre-wrap',
          variant === 'textarea' ? 'overflow-y-auto h-36' : 'h-10',
        )}
        dangerouslySetInnerHTML={{ __html: sanitizedValue }}
      />
    </div>
  );
}

export default InnerHtmlInput;
