import {
  displayContactName,
  tanstackTableNames,
  useCreateCaseContactConnection,
  useDeleteCaseContactConnection,
  useGetCaseContactConnectionsViewModel,
  useGetContactsInfinite,
} from '@colosseum/data';
import {
  CaseContactConnectionSlideover,
  ContactInfoSlideover,
  DataTable,
  EntityAddPopover,
} from '@colosseum/shared-ui';
import { caseContactConnectionOptions } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { startCase } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as z from 'zod';

import { CaseContactConnectionColumn, columns } from './case-contacts-table-columns';

const formSchema = z.object({
  descriptionOfCaseInvolvement: z.string().optional(),
});

export interface CaseContactsTableProps {}

export function CaseContactsTable(props: CaseContactsTableProps) {
  const deleteCaseContactConnection = useDeleteCaseContactConnection();
  const [caseContactConnectionSlideoverOpen, setCaseContactConnectionSlideoverOpen] =
    useState(false);
  const [newContactId, setNewContactId] = useState<string | undefined>();
  const [contactSlideoverOpen, setContactSlideoverOpen] = useState(false);

  const [selectedCaseContactConnectionId, setSelectedCaseContactConnectionId] = useState('');
  const { caseId } = useParams() as { caseId: string };
  const {
    data: contactsData,
    isLoading: isContactsDataLoading,
    isError: isContactsError,
  } = useGetContactsInfinite();

  const { data: caseContactConnectionsDataViewModel, isLoading: isCaseContactConnectionsLoading } =
    useGetCaseContactConnectionsViewModel(caseId);

  const createCaseContactConnection = useCreateCaseContactConnection();

  const selectedCaseContactConnection = caseContactConnectionsDataViewModel?.data.find(
    (c) => c.caseContactConnectionId === selectedCaseContactConnectionId,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: selectedCaseContactConnection,
  });

  function handleClick(e: Row<CaseContactConnectionColumn>) {
    setCaseContactConnectionSlideoverOpen(true);
    setSelectedCaseContactConnectionId(e.original?.caseContactConnectionId ?? '');
  }

  function handleCaseContactConnectionUnlink() {
    deleteCaseContactConnection.mutateAsync(
      selectedCaseContactConnection?.caseContactConnectionId ?? '',
    );
  }

  function handleCreateCaseContactConnection() {
    form.resetField('descriptionOfCaseInvolvement', { defaultValue: '' });
    setNewContactId('');
    setSelectedCaseContactConnectionId('');
    setCaseContactConnectionSlideoverOpen(true);

    createCaseContactConnection.mutateAsync({ caseId }).then((res) => {
      setSelectedCaseContactConnectionId(res?.data?.caseContactConnectionId);
      setNewContactId(res?.data?.contactId);
      setContactSlideoverOpen(true);
    });
  }

  function handleContactDelete() {
    setCaseContactConnectionSlideoverOpen(false);
  }

  const rolesFilter = [
    ...new Set(
      caseContactConnectionsDataViewModel?.data.reduce((acc, caseContactConnection) => {
        if (caseContactConnection.roles) {
          caseContactConnection.roles.forEach((role) => acc.add(role.roleOnCase));
        }
        return acc;
      }, new Set()),
    ),
  ]
    .map((role) => {
      return {
        value: role as string,
        label: startCase(role as string),
      };
    })
    .filter((role) => role.value !== caseContactConnectionOptions.party)
    .sort((a, b) => a.label.localeCompare(b.label));

  const tableFilters = useMemo(
    () => [
      {
        id: 'Roles',
        options: rolesFilter,
      },
    ],
    [],
  );

  return (
    <>
      <div className="flex items-center justify-end w-full pb-3 align-middle">
        <ContactInfoSlideover
          contactId={newContactId}
          open={contactSlideoverOpen}
          setOpen={setContactSlideoverOpen}
          onDelete={handleContactDelete}
        />
        <EntityAddPopover
          title="Add Contact"
          onAdd={() => {
            handleCreateCaseContactConnection();
          }}
          primary
          placeholder={`Search ${contactsData?.data.length ?? 0}${
            contactsData?.meta.lastEvaluatedKey ? '+' : ''
          } contacts...`}
          isError={isContactsError}
          isLoading={createCaseContactConnection.isLoading}
          isFetchingMore={!!contactsData?.meta?.lastEvaluatedKey}
          listItems={
            contactsData?.data
              ?.filter((contact) => {
                return contact.firstName || contact.lastName || contact.name;
              })
              ?.map((contact) => {
                return {
                  label: displayContactName(contact),
                  onClick: () => {
                    createCaseContactConnection.mutateAsync({
                      caseId,
                      contactId: contact.contactId,
                    });
                  },
                };
              }) ?? []
          }
        />
      </div>
      <CaseContactConnectionSlideover
        title={'Case Contact Connection'}
        description={'View your case contact connection'}
        open={caseContactConnectionSlideoverOpen}
        setOpen={setCaseContactConnectionSlideoverOpen}
        displayDeleteButton={true}
        deleteButtonText="Remove Contact from Case"
        deleteFunction={handleCaseContactConnectionUnlink}
        deleteModalTitle="Delete Contact Connection"
        deleteModalDescription="Are you sure you want to remove the contact from the case? All of their roles will be removed as well."
        contact={selectedCaseContactConnection?.contact}
        handleContactDelete={handleContactDelete}
        selectedCaseContactConnection={{
          ...selectedCaseContactConnection,
          roles: selectedCaseContactConnection?.roles ?? [],
          caseContactConnectionId: selectedCaseContactConnectionId,
        }}
        caseId={caseId}
      />
      <DataTable
        data={caseContactConnectionsDataViewModel?.data ?? []}
        initialSort={{
          id: 'Contact Name',
          desc: false,
        }}
        columns={columns}
        handleRowClick={handleClick}
        isLoading={isCaseContactConnectionsLoading || isContactsDataLoading}
        tableName={tanstackTableNames.caseContacts}
        initialVisibility={{
          'Description of Case Involvement': false,
        }}
        filters={tableFilters}
        showSearchBar
      />
    </>
  );
}

export default CaseContactsTable;
