import { bToBitB } from '@colosseum/data';
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@colosseum/shared-ui';
import { GroupPermission, PermissionType } from '@gladiate/types';
import { CaretDownIcon } from '@radix-ui/react-icons';

export function UserGroupsPermissionsDropdown({
  selectedPermissions,
  setSelectedPermissions,
  options,
  selectedGroup,
}: {
  selectedPermissions?: Partial<GroupPermission> | null;
  setSelectedPermissions: (value: Partial<GroupPermission>) => void;
  options: {
    value: keyof PermissionType;
    label: string;
  }[];
  selectedGroup: Partial<GroupPermission> | null;
}) {
  const optionsChecked = options.map((option) => ({
    ...option,
    checked: selectedPermissions?.[option.value]
      ? selectedPermissions?.[option.value] === '1'
      : selectedGroup?.[option.value] === '1',
  }));

  const checkedPermissionsLabels = optionsChecked
    .filter((option) => option.checked)
    .map((option) => option.label);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="hidden h-8 ml-auto lg:flex">
          {checkedPermissionsLabels.length > 0
            ? checkedPermissionsLabels.length < 3
              ? checkedPermissionsLabels.join(', ')
              : `${checkedPermissionsLabels.length} Permissions`
            : '0 Permissions'}
          <CaretDownIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DropdownMenuLabel>Toggle Permissions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {optionsChecked.map((option) => (
          <DropdownMenuCheckboxItem
            key={option.value}
            className="capitalize"
            checked={option.checked}
            onCheckedChange={(value) => {
              setSelectedPermissions({
                ...selectedPermissions,
                [option.value]: bToBitB(value),
              });
            }}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default UserGroupsPermissionsDropdown;
