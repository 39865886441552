/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ContactViewModelType,
  ExpenseType,
  FileResourceType,
  LienType,
  OneClickSignSettingType,
  SigningEvent,
  TemplateType,
  TemplateVariables,
  caseContactConnectionOptions,
} from '@gladiate/types';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  displayContactName,
  getFirmId,
  getS3FileDownloadLink,
  prettifyDateString,
  useCompleteLienValues,
  useCompleteMedicalTreatmentValues,
  useGetCase,
  useGetCaseContactConnectionsViewModel,
  useGetCaseExpensesWithCategories,
  useGetContactsInfinite,
  useGetLiens,
  useGetMedicalTreatments,
  useGetRoleIdForItem,
  useGetScenarioForCase,
} from '..';

export const downloadFile = async (item: FileResourceType) => {
  if (!item.s3ObjKey) {
    enqueueSnackbar('File does not exist', { variant: 'error' });
    return;
  }

  const res = await getS3FileDownloadLink(item.s3ObjKey);
  const link = res.data?.url;

  if (link) {
    const response = await fetch(link);
    const blob = await response.blob();

    const extension = item.s3ObjKey.split('.').pop();
    let fileName = item.name;

    if (extension && !item?.name?.endsWith(`.${extension}`)) {
      fileName = `${item.name}.${extension}`;
    }

    if (!fileName) {
      fileName = 'file';
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

/**
 * @description This hook gets and formats the data needed to generate a document
 */
export const useGenerateDocumentData = (
  caseId: string,
  fileId: string,
  outputFormat: string,
  selectedInsurancePolicy?: string,
) => {
  const { data: caseData, isLoading: isCaseLoading } = useGetCase(caseId);

  const { data: expenseData, isLoading: isExpenseLoading } =
    useGetCaseExpensesWithCategories(caseId);
  const { data: medicalTreatmentData, isLoading: isMedicalTreatmentLoading } =
    useGetMedicalTreatments(caseId ?? '');
  const { data: lienData } = useGetLiens(caseId);
  const { getCompleteMedicalTreatmentValues } = useCompleteMedicalTreatmentValues(caseId);
  const { getCompleteLienValues } = useCompleteLienValues(caseId);
  const getRoleIdForExpense = useGetRoleIdForItem<ExpenseType>(caseId);
  const getRoleIdForLien = useGetRoleIdForItem<LienType>(caseId);

  const { completeTreatments, ...aggregateValues } = getCompleteMedicalTreatmentValues(
    medicalTreatmentData?.data ?? [],
  );
  const completeLiens = getCompleteLienValues(lienData?.data ?? []);
  const { data: allContactsData } = useGetContactsInfinite();
  const expensesTable = {
    expenses: expenseData?.data
      ?.filter((expense) => expense.clientBillable)
      .map((expense) => {
        const vendorId = getRoleIdForExpense(
          expense,
          caseContactConnectionOptions.vendor,
          'caseExpenseId',
        );
        const clientId = getRoleIdForExpense(
          expense,
          caseContactConnectionOptions.client,
          'caseExpenseId',
        );
        const vendor = allContactsData?.data?.find((contact) => contact.contactId === vendorId);
        const client = allContactsData?.data?.find((contact) => contact.contactId === clientId);
        return {
          ...expense,
          title: expense.category?.title ?? '-',
          vendor: vendor ? displayContactName(vendor) : '-',
          client: client ? displayContactName(client) : '-',
          unitCount: expense.unitCount?.toString() ?? '-',
          unitPrice: expense.unitPrice?.toString() ?? '-',
          paymentCompleted: expense.paymentCompleted ? 'Yes' : '-',
          UTBMSCodeName: expense.UTBMSCode?.name ?? '-',
          dateIncurred: prettifyDateString(expense.dateIncurred)?.toString() ?? '-',
        };
      }),
    total: expenseData?.meta?.aggregateTotalCost,
  };
  const liensTable = {
    liens: completeLiens.map((lien) => {
      const clientId = getRoleIdForLien(lien, caseContactConnectionOptions.client, 'lienId');
      const client = allContactsData?.data?.find((contact) => contact.contactId === clientId);
      return {
        ...lien,
        lienHolderName: displayContactName(lien.lienHolder),
        lienProviderName: displayContactName(lien.lienProvider),
        client: client ? displayContactName(client) : '-',
        reduction: lien.reductionAmount,
        accepted: lien.accepted ? 'Accepted' : '-',
        balanceVerifiedDate: lien.balanceVerifiedDate
          ? prettifyDateString(lien.balanceVerifiedDate)?.toString()
          : '-',
      };
    }),
    lienAmountTotal: completeLiens.reduce((acc, lien) => acc + (lien?.lienAmount ?? 0), 0),
    reductionAmountTotal: completeLiens.reduce(
      (acc, lien) => acc + (lien?.reductionAmount ?? 0),
      0,
    ),
    finalBalanceTotal: completeLiens.reduce((acc, lien) => acc + (lien?.finalBalance ?? 0), 0),
  };
  const { data: scenarioData } = useGetScenarioForCase(caseId);

  const insurances = scenarioData?.data?.policies;

  const { data: caseContactConnectionsData, isLoading: isCaseContactConnectionsLoading } =
    useGetCaseContactConnectionsViewModel(caseId);

  const clientContactConscription = useMemo(
    () =>
      // possible TODO: refactor to use findRoleSpecificCaseContactConnections
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.roles.some(
          (caseContactConnectionRole) =>
            caseContactConnectionRole.roleOnCase === caseContactConnectionOptions.client,
        ),
      ),
    [caseContactConnectionsData],
  );

  const spouseConscription = useMemo(
    () =>
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.contact.contactConnections.some(
          (contactConnection) => contactConnection.relationship === 'spouse',
        ),
      ),
    [caseContactConnectionsData],
  );

  const defendantConscription = useMemo(
    () =>
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.roles.some(
          (caseContactConnectionRole) => caseContactConnectionRole.roleOnCase === 'defendant',
        ),
      ),
    [caseContactConnectionsData],
  );

  const insurerConscription = useMemo(
    () =>
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.roles.some(
          (caseContactConnectionRole) => caseContactConnectionRole.roleOnCase === 'insurer',
        ),
      ),
    [caseContactConnectionsData],
  );

  const policyHolderConscription = useMemo(
    () =>
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.roles.some(
          (caseContactConnectionRole) => caseContactConnectionRole.roleOnCase === 'policyHolder',
        ),
      ),
    [caseContactConnectionsData],
  );

  const adjusterConscription = useMemo(
    () =>
      caseContactConnectionsData?.data?.find((caseContactConnection) =>
        caseContactConnection?.roles.some(
          (caseContactConnectionRole) => caseContactConnectionRole.roleOnCase === 'adjuster',
        ),
      ),
    [caseContactConnectionsData],
  );

  const contactDetails = clientContactConscription?.contact;
  const caseDetails = caseData?.data;
  const finalEditedMedicalTreatments = {
    medicalTreatments: completeTreatments.map((treatment) => ({
      ...treatment,
      medicalProvider: treatment.medicalProvider,
      billingEntity: treatment.billingEntity
        ? displayContactName(treatment.billingEntity)
        : undefined,
      startingDateOfService: dayjs(treatment.startingDateOfService).format('MMMM DD, YYYY'),
      amountBilled: treatment.amountBilled ?? undefined,
      adjustments: treatment.adjustments ?? undefined,
      totalPayments: treatment.totalPayments ?? undefined,
      unpaidBalance: treatment.unpaidBalance ?? undefined,
      reductions: treatment.reductionAmount ?? undefined,
      presentBalance: treatment.presentBalance ?? undefined,
    })),
    ...aggregateValues,
  };

  function getInsurancePolicy(selectedInsurancePolicy: string) {
    const insurancePolicy = insurances?.find(
      (policy) => policy.policyId === selectedInsurancePolicy,
    );

    return insurancePolicy;
  }

  const insurancePolicy = getInsurancePolicy(selectedInsurancePolicy ?? '');

  const employer = contactDetails?.contactConnections?.find(
    (contactConnection: any) => contactConnection.relationship === 'employer',
  )?.connectedToContact;

  const generateDocumentData = () => {
    return {
      case_caseType: caseDetails?.caseType?.title ?? '',
      case_dateOfIncident: caseDetails?.dateOfIncident ?? '',
      case_descriptionOfIncident: caseDetails?.clientDescriptionOfIncident ?? '',
      case_descriptionOfInjuries: caseDetails?.descriptionOfClientInjuries ?? '',
      case_incidentAddress: caseDetails?.incidentStreet ?? '',
      case_incidentCity: caseDetails?.incidentCity ?? '',
      case_incidentState: caseDetails?.incidentState ?? '',
      contact_client_address: contactDetails?.contactAddresses[0]?.streetAddress ?? '',
      contact_client_city: contactDetails?.contactAddresses[0]?.city ?? '',
      contact_client_dateOfBirth: contactDetails?.dateOfBirth,
      contact_client_emailAddress: contactDetails?.contactEmails[0]?.emailAddress ?? '',
      contact_client_employer: employer
        ? displayContactName(employer)
          ? displayContactName(employer)
          : ''
        : '',
      contact_client_firstName: contactDetails?.firstName ?? '',
      contact_client_jobPosition: contactDetails?.contactEmployments[0]?.title ?? '',
      contact_client_jobStartDate: contactDetails?.contactEmployments[0]?.startDate ?? '',
      contact_client_lastName: contactDetails?.lastName ?? '',
      contact_client_phoneNumber: contactDetails?.contactNumbers[0]?.number,
      contact_client_socialSecurityNumber: contactDetails?.socialSecurityNumber,
      contact_client_state: contactDetails?.contactAddresses[0]?.state ?? '',
      contact_client_zip: contactDetails?.contactAddresses[0]?.zipCode ?? '',
      contact_insuranceAdjuster_address:
        adjusterConscription?.contact.contactAddresses?.[0]?.streetAddress ?? '',
      contact_insuranceAdjuster_emailAddress:
        adjusterConscription?.contact?.contactEmails?.[0]?.emailAddress ?? '',
      contact_insuranceAdjuster_firstName: adjusterConscription?.contact.firstName ?? '',
      contact_insuranceAdjuster_lastName: adjusterConscription?.contact.lastName ?? '',
      contact_insuranceAdjuster_name: adjusterConscription?.contact?.name ?? '',
      contact_insuranceAdjuster_phoneNumber:
        adjusterConscription?.contact.contactNumbers?.[0]?.number ?? '',
      contact_insurer_emailAddress:
        insurerConscription?.contact?.contactEmails?.[0]?.emailAddress ?? '',
      contact_insurer_name: insurerConscription?.contact?.name ?? '',
      contact_policyHolder_emailAddress:
        policyHolderConscription?.contact?.contactEmails?.[0]?.emailAddress ?? '',
      contact_policyHolder_name: policyHolderConscription?.contact?.name ?? '',
      contact_spouse_firstName: spouseConscription?.contact.firstName ?? '',
      contact_spouse_lastName: spouseConscription?.contact.lastName ?? '',
      currentDate: dayjs().format('MMMM DD, YYYY'),
      expenseAggregates: expensesTable ?? [],
      expenses: expensesTable?.expenses ?? [],
      fileId: fileId,
      insurance_claimNumber: insurancePolicy?.claimNumber ?? '',
      insurance_effectiveDatesOfCoverageEnd: insurancePolicy?.effectiveDatesOfCoverageEnd ?? '',
      insurance_effectiveDatesOfCoverageStart: insurancePolicy?.effectiveDatesOfCoverageStart ?? '',
      insurance_liabilityCoverage: String(insurancePolicy?.liabilityCoveragePerAccident),
      insurance_medicalPaymentCoverage: String(insurancePolicy?.medicalPaymentCoverageAmount) ?? '',
      insurance_policyNumber: insurancePolicy?.policyNumber ?? '',
      insurance_umbrellaPolicy: String(insurancePolicy?.umbrellaPolicy) ?? '',
      liens: liensTable.liens ?? [],
      liensAggregates: liensTable ?? [],
      outputFormat: outputFormat,
      treatment: finalEditedMedicalTreatments.medicalTreatments ?? [],
      treatmentAggregates: finalEditedMedicalTreatments ?? '',
    } as TemplateVariables;
  };

  return {
    generateDocumentData,
    isLoading:
      isCaseLoading ||
      isExpenseLoading ||
      isMedicalTreatmentLoading ||
      isCaseContactConnectionsLoading,
  };
};

/**
 *
 * @description This function creates a signing event object that can be used to send a document for sign
 * @returns
 */
export const createSigningEvent = ({
  oneClickSignFirstSetting,
  objKey,
  caseId,
  oneClickSignTemplate,
  contactInfo,
  signer,
  username,
  firmPhoneNumber,
}: {
  oneClickSignFirstSetting: OneClickSignSettingType | undefined;
  objKey: string;
  caseId: string;
  oneClickSignTemplate: TemplateType | undefined;
  contactInfo: ContactViewModelType | undefined;
  username: string;
  signer: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email: string;
  };
  firmPhoneNumber: string;
}): SigningEvent => {
  const firmName = getFirmId();
  return {
    username,
    userEmail: oneClickSignFirstSetting?.senderSigningAttorneyEmail ?? '',
    objKey,
    caseId,
    firmPhoneNumber,
    signaturePayload: {
      message: oneClickSignTemplate?.defaultMessage ?? '',
      documentName: oneClickSignTemplate?.defaultSubjectContinued ?? `${firmName ?? ''} document`,
      participantSetsInfo: [
        {
          order: 1,
          role: 'SIGNER',
          memberInfos: [
            {
              email: signer.email,
              name: `${signer?.firstName ?? ''} ${signer?.lastName ?? ''}`,
              phoneNumber: signer.phoneNumber ?? contactInfo?.contactNumbers?.[0]?.number ?? '',
              contactId: contactInfo?.contactId ?? '',
            },
          ],
        },
        {
          order: 2,
          role: 'SIGNER',
          memberInfos: [
            {
              email: oneClickSignFirstSetting?.senderSigningAttorneyEmail ?? '',
              name: oneClickSignFirstSetting?.senderSigningAttorneyName ?? '',
            },
          ],
        },
      ],
    },
  };
};

/**
 *
 * @description This function finds the path from the root node to the target node recursively
 * @param node This is the node you want to start finding the path from
 * @param targetItem This is the node you want the path for
 * @returns An array of nodes that represent the path from the root node to the target node
 * @example findFolderPathFromRoot([name: '/', children: {name: 'abc'}], targetItem: {name: 'abc}') will return [{name: '/', children: {name: 'abc'}}, {name: 'abc'}]
 *
 */
export const findFolderPathFromRoot = (
  node?: FileResourceType,
  targetItemId?: string,
): FileResourceType[] | undefined => {
  if (node?.resourceId === targetItemId) {
    return [node] as FileResourceType[] | undefined;
  } else {
    //If current node not search node match, examine children. For first
    //child that returns an array (path), prepend current node to that
    //path result
    if (!node?.children) {
      return;
    }
    for (const child of node.children) {
      const childPath: FileResourceType[] | undefined = findFolderPathFromRoot(child, targetItemId);
      if (Array.isArray(childPath)) {
        if (node?.resourceId !== targetItemId) {
          childPath.unshift(child);
        }
        return childPath;
      }
    }
  }
  return;
};

/**
 * @description This function will return the full file id of a file
 * @param file The file you want to get the full file id for
 * @returns The full file id of the file
 * @example getFullFileId({parentResourceId: 'abc', resourceId: 'def'}) will return 'abc-def'
 */
export const getFullFileId = (file: FileResourceType) => {
  return `${file.parentResourceId}-${file.resourceId}`;
};
