import {
  CaseAccessType,
  CoCounselType,
  DefaultV3Response,
  FirmType,
  GroupPermission,
  HurinUserType,
} from '@gladiate/types';
import { reduceCognitoAttributes } from '../../utils/authUtils';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const baseHurinRoute = `hurin/${API_STAGE}`;

// #region ------------------ Firms ----------------------

export const getFirmDetailsV3Url = (firmId: string) => `${baseHurinRoute}/v3/firms/${firmId}`;

// GET get_firm_v3
export const getFirmDetailsV3 = (firmId: string) =>
  axiosInstance
    .get<DefaultV3Response<FirmType>>(getFirmDetailsV3Url(firmId))
    .then((res) => res.data);
// #endregion

// #region ------------------ Users -----------------------

// POST add_user_to_firm_group_v3
export const addUserToFirmV3Url = (userId: string) => `${baseHurinRoute}/v3/users/${userId}/firms`;

export const addUserToFirmV3 = (userId: string) =>
  axiosInstance
    .post<DefaultV3Response<HurinUserType>>(addUserToFirmV3Url(userId))
    .then((res) => res.data);

// DELETE delete_user_from_firm_v3
export const deleteUserFromFirmV3Url = (userId?: string) => `${baseHurinRoute}/v3/users/${userId}`;

export const deleteUserFromFirmV3 = (userId?: string) =>
  axiosInstance
    .delete<DefaultV3Response<HurinUserType>>(deleteUserFromFirmV3Url(userId))
    .then((res) => res.data);

// PATCH disable_user_for_firm_v3
export const disableUserForFirmV3Url = (userId: string) =>
  `${baseHurinRoute}/v3/users/${userId}/disable`;

export const disableUserForFirmV3 = (userId: string) =>
  axiosInstance
    .patch<DefaultV3Response<HurinUserType>>(disableUserForFirmV3Url(userId))
    .then((res) => res.data);

// PATCH enable_user_for_firm_v3
export const enableUserForFirmV3Url = (userId: string) =>
  `${baseHurinRoute}/v3/users/${userId}/enable`;

export const enableUserForFirmV3 = (userId: string) =>
  axiosInstance
    .patch<DefaultV3Response<HurinUserType>>(enableUserForFirmV3Url(userId))
    .then((res) => res.data);

// POST create_user_for_firm_v3
export const createFirmUserV3Url = () => `${baseHurinRoute}/v3/users`;

export const createFirmUserV3 = (data: HurinUserType & { username: string }) =>
  axiosInstance
    .post<DefaultV3Response<HurinUserType>>(createFirmUserV3Url(), data)
    .then((res) => res.data);

// GET get_current_user_v3
export const getCurrentUserV3Url = () => `${baseHurinRoute}/v3/users/current`;

export const getCurrentUserV3 = () =>
  axiosInstance
    .get<DefaultV3Response<HurinUserType & Partial<GroupPermission>>>(getCurrentUserV3Url())
    .then((res) => {
      const attributes = reduceCognitoAttributes(res.data.data);
      return {
        ...res.data,
        data: { ...res.data.data, ...attributes } as HurinUserType & GroupPermission,
      };
    });

// GET get_user_for_firm_v3
export const getUserForFirmV3Url = (userId?: string) => `${baseHurinRoute}/users/${userId}`;

export const getUserForFirmV3 = (userId?: string) =>
  axiosInstance
    .get<DefaultV3Response<HurinUserType>>(getUserForFirmV3Url(userId))
    .then((res) => res.data);

// GET get_users_for_firm_v3
export const getUsersForFirmV3Url = () => `${baseHurinRoute}/v3/users`;

export const getUsersForFirmV3 = () =>
  axiosInstance
    .get<DefaultV3Response<HurinUserType[]>>(getUsersForFirmV3Url())
    .then((res) => {
      const filteredUsers = res.data?.data
        .map((user) => {
          const attributes = reduceCognitoAttributes(user);
          return {
            ...user,
            ...attributes,
          };
        })
        ?.filter((user) => {
          if (!user.Attributes) {
            return true;
          }

          return user?.['custom:portalStatus'] !== 'hidden';
        });

      return {
        ...res.data,
        data: {
          data: filteredUsers as HurinUserType[],
        },
      };
    })
    .then((res) => res.data);

// PATCH update_user_for_firm_v3
export const updateFirmUserV3Url = (userId: string) => `${baseHurinRoute}/v3/users/${userId}`;

export const updateFirmUserV3 = (userId: string, data: HurinUserType) =>
  axiosInstance
    .patch<DefaultV3Response<HurinUserType>>(updateFirmUserV3Url(userId), data)
    .then((res) => res.data);

// #endregion

// #region ------------------ User-Invitation -----------------------
// POST resend_user_invitation_v3
export const resendUserInvitationToJoinFirmV3 = (userId: string) =>
  axiosInstance
    .post<DefaultV3Response<HurinUserType>>(
      `${baseHurinRoute}/v3/users/${userId}/resend-invitation`,
    )
    .then((res) => res.data);
//#endregion

// #region ------------------ Group Permissions ----------------------
// POST create_firm_group_permission_v3
export const createFirmGroupV3 = (data: Partial<GroupPermission>) =>
  axiosInstance
    .post<DefaultV3Response<GroupPermission>>(`${baseHurinRoute}/v3/groups`, data)
    .then((res) => res.data);

// DELETE delete_group_permission_v3
export const deleteFirmGroupV3 = (groupId: string) =>
  axiosInstance
    .delete<DefaultV3Response<GroupPermission>>(`${baseHurinRoute}/v3/groups/${groupId}`)
    .then((res) => res.data);

// GET get_firm_group_permissions_v3
export const getFirmGroupPermissionsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<GroupPermission[]>>(`${baseHurinRoute}/v3/groups`)
    .then((res) => res.data);

// PATCH update_group_permission_v3
export const updateFirmGroupV3 = (groupId: string, data: Partial<GroupPermission>) =>
  axiosInstance
    .patch<DefaultV3Response<GroupPermission>>(`${baseHurinRoute}/v3/groups/${groupId}`, data)
    .then((res) => res.data);
// #endregion

// #region ------------------ Case Access ----------------------
// POST create_case_access_v3
export const createCaseAccessV3 = (data: { username: string; caseId: string }) => {
  const { username, caseId } = data;
  return axiosInstance
    .post<DefaultV3Response<CaseAccessType>>(`${baseHurinRoute}/v3/case-accesses/${username}`, {
      caseId,
    })
    .then((res) => res.data);
};

// DELETE delete_case_access_v3
export const deleteCaseAccessV3 = (userAccessId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseAccessType>>(
      `${baseHurinRoute}/v3/case-accesses/case-access/${userAccessId}`,
    )
    .then((res) => res.data);

// GET get_all_case_accesses_v3
export const getAllCaseAccessesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseAccessType[]>>(`${baseHurinRoute}/v3/case-accesses`)
    .then((res) => res.data);

// GET get_case_accesses_v3
export const getCaseAccessesV3 = (username: string) =>
  axiosInstance
    .get<DefaultV3Response<CaseAccessType[]>>(`${baseHurinRoute}/v3/case-accesses/${username}`)
    .then((res) => res.data);
//#endregion

// #region ------------------ Co Counsels ----------------------
// POST create_co_counsel_v3
export const createCoCounselV3 = (
  data: Pick<
    CoCounselType,
    | 'username'
    | 'cocounselName'
    | 'cocounselFirmAddress'
    | 'cocounselFirmCity'
    | 'cocounselFirmState'
    | 'cocounselFirmZip'
  >,
) =>
  axiosInstance
    .post<DefaultV3Response<CoCounselType>>(
      `${baseHurinRoute}/v3/co-counsels/${data.username}`,
      data,
    )
    .then((res) => res.data);

// DELETE delete_co_counsel_v3
export const deleteCoCounselV3 = (username?: string) =>
  axiosInstance
    .delete<DefaultV3Response<CoCounselType>>(`${baseHurinRoute}/v3/co-counsels/${username}`)
    .then((res) => res.data);

// GET get_co_counsels_v3
export const getCoCounselsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CoCounselType[]>>(`${baseHurinRoute}/v3/co-counsels`)
    .then((res) => res.data);

// PATCH update_co_counsel_v3
export const updateCoCounselV3 = (data: Partial<CoCounselType> & { username: string }) => {
  const { username, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CoCounselType>>(`${baseHurinRoute}/v3/co-counsels/${username}`, rest)
    .then((res) => res.data);
};

// #endregion
