import {
  displayContactName,
  getChipsForParty,
  makeAcronym,
  queryKeys,
  useDeleteCaseContactConnection,
  useDeleteCaseContactConnectionRole,
  useGetCaseContactConnections,
} from '@colosseum/data';
import {
  Button,
  CaseContactConnectionSlideover,
  Chip,
  MedicalTreatmentAppointmentsTable,
} from '@colosseum/shared-ui';
import { MedicalTreatmentSummaryType } from '@gladiate/types';
import { ChevronDownIcon, ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { startCase, uniq } from 'lodash';
import { useState } from 'react';
import MedicalRecordRequests from '../../cases/MedicalRecordRequests/MedicalRecordRequests';
import ActiveMedicalTreatments from '../../cases/MedicalTreatments/ActiveMedicalTreatments/ActiveMedicalTreatments';

export interface ProvidersListCardProps {
  providerData?: MedicalTreatmentSummaryType;
  isLoading: boolean;
  caseId: string;
}

export function ProvidersListCard(props: ProvidersListCardProps) {
  const { providerData, isLoading, caseId } = props;

  const queryClient = useQueryClient();

  const [caseContactConnectionSlideoverOpen, setCaseContactConnectionSlideoverOpen] =
    useState(false);

  const [appointmentsTableOpen, setAppointmentsTableOpen] = useState(false);

  const [billsTableOpen, setBillsTableOpen] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState<string | undefined>();

  const [recordRequestsTableOpen, setRecordRequestsTableOpen] = useState(false);

  const contactInitials = makeAcronym(displayContactName(providerData?.contact), 2);
  const chips = getChipsForParty(providerData?.roles ?? []);

  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');

  const deleteCaseContactConnection = useDeleteCaseContactConnection();

  const removeRole = useDeleteCaseContactConnectionRole();

  return (
    <div className="relative py-7">
      <CaseContactConnectionSlideover
        title={'Case Contact Connection'}
        description={'View your case contact connection'}
        open={caseContactConnectionSlideoverOpen}
        setOpen={setCaseContactConnectionSlideoverOpen}
        displayDeleteButton
        deleteModalTitle="Delete Provider"
        deleteModalDescription="Are you sure you want to delete this provider? This will remove this contact from all associated bills, appointments and record requests. This action CANNOT be undone."
        deleteFunction={() => {
          const connection = caseContactConnectionsData?.data.find(
            (connection) => connection.contactId === providerData?.contact.contactId,
          );
          connection?.roles?.forEach((role) => {
            removeRole.mutateAsync(role.caseContactConnectionRoleId).then(() => {
              queryClient.invalidateQueries([queryKeys.medicalTreatments]);
              deleteCaseContactConnection.mutateAsync(connection?.caseContactConnectionId);
            });
          });
        }}
        contact={providerData?.contact}
        handleContactDelete={() => {
          setCaseContactConnectionSlideoverOpen(false);
        }}
        // @ts-expect-error this is right but typescript is being weird
        selectedCaseContactConnection={
          providerData?.contact && caseContactConnectionsData?.data
            ? caseContactConnectionsData?.data.find(
                (connection) => connection.contactId === providerData?.contact.contactId,
              )
            : undefined
        }
        caseId={caseId}
      />

      <div className="flex gap-x-3 ne">
        <div className="flex-shrink-0 rounded-sm bg-atlantic-blue size-12">
          <div className="flex items-center justify-center w-full h-full text-white">
            {contactInitials}
          </div>
        </div>
        <div>
          <div className="flex">{displayContactName(providerData?.contact)}</div>
          <div className="flex flex-wrap items-center gap-1">
            {uniq(chips)?.map((role, index) => {
              return (
                <Chip
                  key={`${role.title}=${index}`}
                  name={startCase(role.title)}
                  color={role.color}
                />
              );
            })}
          </div>
        </div>
        <div className="grow"></div>
        <Button
          onClick={() => {
            setCaseContactConnectionSlideoverOpen(true);
          }}
          variant="outline"
          className="p-1 h-[26px]"
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
      </div>
      <div className="mt-4 space-y-2">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setAppointmentsTableOpen(!appointmentsTableOpen)}
            >
              {appointmentsTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Appointments
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {providerData?.medicalTreatmentAppointments?.length || 0}
              </div>
            </Button>
          </div>
          {appointmentsTableOpen && (
            <MedicalTreatmentAppointmentsTable
              hideSummary
              caseId={caseId}
              contactId={providerData?.contactId}
            />
          )}
        </div>
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setBillsTableOpen(!billsTableOpen)}
            >
              {billsTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Bills
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {providerData?.medicalTreatments?.length || 0}
              </div>
            </Button>
          </div>
          {billsTableOpen && (
            <ActiveMedicalTreatments
              caseId={caseId}
              contactId={providerData?.contactId}
              activeTreatmentId={selectedBillId}
              setActiveTreatmentId={setSelectedBillId}
              hideSummary
            />
          )}
        </div>
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setRecordRequestsTableOpen(!recordRequestsTableOpen)}
            >
              {recordRequestsTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Record Requests
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {providerData?.recordRequests?.length || 0}
              </div>
            </Button>
          </div>

          {recordRequestsTableOpen && (
            <MedicalRecordRequests caseId={caseId} medicalProviderId={providerData?.contactId} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProvidersListCard;
