import {
  casesSectionMappings,
  enqueueAPISnackbar,
  findResourceSpecificCaseContactConnectionRoles,
  formatPrice,
  scrollToCaseSection,
  tanstackTableNames,
  useCreateTrustAccountTransaction,
  useDeleteTrustAccountTransaction,
  useGetCaseContactConnections,
  useGetContactsInfinite,
  useGetTrustAccountTransactions,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  ResourceSlideover,
  SlideoverContext,
  TabsContent,
  Typography,
} from '@colosseum/shared-ui';
import { ContactType, caseContactConnectionOptions } from '@gladiate/types';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import CaseTrustAccountingForm from '../CaseTrustAccountingForm/CaseTrustAccountingForm';
import { columns } from './case-trust-accounting-table-columns';

export interface CaseTrustAccountingProps {
  caseId: string;
}

export function CaseTrustAccounting(props: CaseTrustAccountingProps) {
  const { caseId } = props;
  const [open, setOpen] = useState(false);
  const [activeTrustAccountTransactionId, setActiveTrustAccountTransactionId] = useState<string>();

  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);

  const [typing, setTyping] = useState(false);

  const createTrustAccountTransactionMutation = useCreateTrustAccountTransaction();
  const deleteTrustAccountTransactionMutation = useDeleteTrustAccountTransaction();

  const { data: trustAccountTransactionsData, isLoading: isTrustAccountTransactionsLoading } =
    useGetTrustAccountTransactions(caseId);

  const activeTrustAccountTransaction = trustAccountTransactionsData?.data.find(
    (transaction) => transaction.trustAccountTransactionId === activeTrustAccountTransactionId,
  );

  const { data: caseContactConnections, isLoading: contactConnectionsLoading } =
    useGetCaseContactConnections(caseId, 'cases');

  const { data: contactsData } = useGetContactsInfinite();

  const connections = findResourceSpecificCaseContactConnectionRoles({
    caseContactConnections: caseContactConnections?.data,
    itemType: 'trustAccountTransaction',
  });

  // This logic is messy but the data is very much not related so this will ahve to do
  const trustAccountTransactionsWithRoles =
    trustAccountTransactionsData?.data.map((transaction) => {
      let trustAccountTransactionPayor;
      let trustAccountTransactionPayee;
      connections.forEach((connection) => {
        connection.roles.forEach((role) => {
          if (
            role.roleOnCase &&
            (
              [caseContactConnectionOptions.payee, caseContactConnectionOptions.payor] as string[]
            ).includes(role.roleOnCase) &&
            role.itemId === transaction.trustAccountTransactionId
          ) {
            contactsData?.data?.forEach((contact) => {
              if (contact.contactId === connection.contactId) {
                if (role.roleOnCase === caseContactConnectionOptions.payee) {
                  trustAccountTransactionPayee = contact;
                }
                if (role.roleOnCase === caseContactConnectionOptions.payor) {
                  trustAccountTransactionPayor = contact;
                }
              }
            });
          }
        });
      });
      return {
        ...transaction,
        payor: trustAccountTransactionPayor as ContactType | undefined,
        payee: trustAccountTransactionPayee as ContactType | undefined,
      };
    }) ?? [];

  useEffect(() => {
    if (
      pendingSlideoverToOpen?.type === 'trustAccountTransaction' &&
      !isTrustAccountTransactionsLoading
    ) {
      if (
        trustAccountTransactionsData?.data.find(
          (t) => t.trustAccountTransactionId === pendingSlideoverToOpen?.id,
        )
      ) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.accounting}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActiveTrustAccountTransactionId(pendingSlideoverToOpen?.id ?? '');
      } else {
        enqueueAPISnackbar({
          message: 'Trust Account Transaction not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, isTrustAccountTransactionsLoading]);

  return (
    <>
      <ResourceSlideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          deleteTrustAccountTransactionMutation.mutate(
            activeTrustAccountTransaction?.trustAccountTransactionId,
          );
        }}
        title="Trust Account Transactions"
        description="Get started by filling in the information below to
            create your new Trust Account Transaction."
        typing={typing}
        caseId={caseId}
        resourceId={activeTrustAccountTransaction?.trustAccountTransactionId ?? ''}
        createType="trustAccountTransaction"
      >
        <TabsContent value="details">
          <CaseTrustAccountingForm
            caseId={caseId}
            transaction={activeTrustAccountTransaction}
            setTyping={setTyping}
          />
        </TabsContent>
      </ResourceSlideover>

      <div className="">
        <div className="flex gap-x-2">
          <Typography color="gray">Running Total:</Typography>
          <Typography>
            {formatPrice(trustAccountTransactionsData?.meta.runningTotal ?? 0)}
          </Typography>
        </div>
        <div className="mb-4 sm:flex sm:items-center">
          <div className="grow"></div>
          <div className="sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Add Transaction'}
              loading={createTrustAccountTransactionMutation.isLoading}
              onClick={() => {
                createTrustAccountTransactionMutation.mutateAsync({ caseId }).then((res) => {
                  setActiveTrustAccountTransactionId(res.data.trustAccountTransactionId);
                  setOpen(true);
                });
              }}
            />
          </div>
        </div>
        <DataTable
          data={trustAccountTransactionsWithRoles ?? []}
          initialSort={{
            id: 'Date Created',
            desc: true,
          }}
          showSearchBar
          filters={[]}
          columns={columns}
          handleRowClick={(item) => {
            setActiveTrustAccountTransactionId(item.original.trustAccountTransactionId);
            setOpen(true);
          }}
          initialVisibility={
            {
              'Date Created': false,
            } as VisibilityState
          }
          isLoading={isTrustAccountTransactionsLoading}
          tableName={tanstackTableNames.trustAccounting}
        />
      </div>
    </>
  );
}

export default CaseTrustAccounting;
