import { usePermissions } from '@colosseum/data';
import { CustomErrorBoundary, SectionContainer } from '@colosseum/shared-ui';
import { withErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import DefaultFileRepoStructure from '../settings/DefaultFileRepoStructure/DefaultFileRepoStructure';
import LitigationPlans from '../settings/LitigationPlans/LitigationPlans';
import OneClickSignSettings from '../settings/OneClickSignSettings/OneClickSignSettings';
import TaskPlanSection from '../settings/TaskPlanSection/TaskPlanSection';
import FirmSurveys from './Surveys/FirmSurveys';

export function AutomationsPage() {
  const { hasServiceAccess } = usePermissions();
  return (
    <div data-cy="automations">
      <Helmet defer={false}>
        <title>Automations</title>
      </Helmet>
      {hasServiceAccess('automationsOneClickSignAccess') && (
        <SectionContainer>
          <OneClickSignSettings />
        </SectionContainer>
      )}
      {hasServiceAccess('automationsTaskPlansAccess') && (
        <SectionContainer>
          <TaskPlanSection />
        </SectionContainer>
      )}
      {hasServiceAccess('automationsFileRepoStructureAccess') && (
        <SectionContainer>
          <DefaultFileRepoStructure />
        </SectionContainer>
      )}
      {hasServiceAccess('automationsLitigationPlansAccess') && (
        <SectionContainer>
          <LitigationPlans />
        </SectionContainer>
      )}
      {hasServiceAccess('automationsFirmSurveysAccess') && (
        <SectionContainer>
          <FirmSurveys />
        </SectionContainer>
      )}
    </div>
  );
}

export default withErrorBoundary(AutomationsPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
