import {
  generateTimeStamps,
  parseIntFormValues,
  useCreateCaseCampaignTrigger,
  useDeleteCaseCampaignTrigger,
  useGetCaseStatuses,
  useGetProvisionedPhoneNumbers,
  useUpdateCaseCampaignTrigger,
} from '@colosseum/data';
import {
  CampaignDeliveryCadence,
  CampaignDeliveryMechanism,
  CaseCampaign,
  CaseCampaignTrigger,
  CaseStatusType,
  deliveryCadenceListItemsWithDetails,
  deliveryMechanismOptionsMap,
  HandwryttenCardOrderDetails,
  SendAfterParams,
  SendOnRepeatParams,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { invert } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import HandwryttenSelectForm from '../HandwryttenSelect/HandwryttenSelect';
import { Slideover } from '../Slideover/Slideover';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
import CreateButton from '../CreateButton/CreateButton';
import Typography from '../Typography/Typography';
import SnippetTool from '../SnippetTool/SnippetTool';
import TemplateDictionaryTool from '../TemplateDictionaryTool/TemplateDictionaryTool';

const CHARACTER_LIMIT = 1599;
const formSchema = z.object({
  caseStatusId: z.string(),
  deliveryMechanism: z.union([
    z.literal<CampaignDeliveryMechanism>('handwrytten'),
    z.literal<CampaignDeliveryMechanism>('sms'),
  ]),
  deliveryCadence: z.union([
    z.literal<CampaignDeliveryCadence>('sendImmediately'),
    z.literal<CampaignDeliveryCadence>('sendAfter'),
    z.literal<CampaignDeliveryCadence>('sendOnRepeat'),
  ]),
  sendAfterTimeDimension: z
    .union([
      z.literal<SendAfterParams['sendAfterTimeDimensions']>('hours'),
      z.literal<SendAfterParams['sendAfterTimeDimensions']>('days'),
      z.literal<SendAfterParams['sendAfterTimeDimensions']>('weeks'),
    ])
    .optional(),
  sendAfterTimeAmount: z.coerce.number().min(1).max(52).optional(),
  sendOnRepeatDimension: z
    .union([
      z.literal<SendOnRepeatParams['sendOnRepeatDimension']>('Daily'),
      z.literal<SendOnRepeatParams['sendOnRepeatDimension']>('Weekly'),
      z.literal<SendOnRepeatParams['sendOnRepeatDimension']>('Monthly'),
    ])
    .optional(),
  sendOnRepeatCalendarDay: z.coerce.number().min(1).max(31).optional(),
  sendOnRepeatWeekday: z.array(z.string()).optional(),
  sendOnRepeatTime: z.string().optional(),
  sendOnRepeatTimeZone: z.string().optional(),
  sendOnRepeatTimeHour: z.number().optional(),
  sendOnRepeatTimeMinute: z.number().optional(),
  message: z.string().max(CHARACTER_LIMIT, 'Please enter a shorter message'),
  provisionedPhoneNumberId: z.string().optional(),
});

export function CaseCampaignTriggerSlideover({
  caseCampaignTrigger,
  open,
  setOpen,
  caseCampaignId,
}: {
  caseCampaignTrigger: (CaseCampaignTrigger & CaseCampaign) | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  caseCampaignId: string;
}) {
  // #region Hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    // TODO fix typing
    values: caseCampaignTrigger as any,
  });

  const createTrigger = useCreateCaseCampaignTrigger();
  const updateTrigger = useUpdateCaseCampaignTrigger();
  const deleteTrigger = useDeleteCaseCampaignTrigger();
  // #endregion

  // #region Data Fetching
  const { data: caseStatuses, isLoading: isCaseStatusesLoading } = useGetCaseStatuses();
  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  // #endregion

  // #region State
  const [typing, setTyping] = useState(false);
  const [charCount, setCharCount] = useState(caseCampaignTrigger?.message?.length ?? 0);
  const [charCountLimit, setCharCountLimit] = useState(CHARACTER_LIMIT);
  const [cardOrderDetails, setCardOrderDetails] = useState<HandwryttenCardOrderDetails>({});
  const [selectedCadence, setSelectedCadence] = useState<CampaignDeliveryCadence>();

  const [sendOnRepeatTimeDimension, setSendOnRepeatTimeDimension] =
    useState<SendOnRepeatParams['sendOnRepeatDimension']>();

  const [deliveryMechanism, setDeliveryMechanism] = useState<CampaignDeliveryMechanism>(
    caseCampaignTrigger?.deliveryMechanism as CampaignDeliveryMechanism,
  );
  // #endregion

  // #region Derived State
  const provisionedPhoneNumbers = provisionedPhoneNumberData?.data;

  const clientCommunicationPhoneNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication',
  );
  const clientCommunicationNumbersListItems = clientCommunicationPhoneNumbers?.reduce(
    (acc, item) => {
      return {
        ...acc,
        [item.phoneNumber]: item.provisionedPhoneNumberId,
      };
    },
    {},
  ) as { [key: string]: string };

  const caseStatusListItems =
    caseStatuses?.data.reduce((acc, status: CaseStatusType) => {
      if (status.title) {
        acc[status.title] = status.caseStatusId;
      }
      return acc;
    }, {} as { [key: string]: string }) ?? {};

  const sendAfterTimeDimensionListItems: Record<
    string,
    SendAfterParams['sendAfterTimeDimensions']
  > = {
    Hours: 'hours',
    Days: 'days',
    Weeks: 'weeks',
  };

  const sendOnRepeatOptions: SendOnRepeatParams['sendOnRepeatDimension'][] = [
    'Daily',
    'Weekly',
    'Monthly',
  ];

  const sendOnRepeatCalendarDayOptions: string[] = Array.from({ length: 31 }, (_, i) => i + 1).map(
    (item) => item.toString(),
  );

  const sendOnRepeatWeekDayOptions: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const sendOnRepeatTimeZoneOptions: SendOnRepeatParams['sendOnRepeatTimeZone'][] = [
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa',
  ];
  // #endregion

  // #region useEffects
  useEffect(() => {
    setTyping(false);
    setSelectedCadence(caseCampaignTrigger?.deliveryCadence as CampaignDeliveryCadence);
    setSendOnRepeatTimeDimension(
      caseCampaignTrigger?.sendOnRepeatDimension as SendOnRepeatParams['sendOnRepeatDimension'],
    );
    setDeliveryMechanism(caseCampaignTrigger?.deliveryMechanism as CampaignDeliveryMechanism);
  }, [open]);

  useEffect(() => {
    setCharCount(caseCampaignTrigger?.message?.length ?? 0);
  }, [caseCampaignTrigger]);

  // #endregion

  // #region Event Handlers
  function isTriggerFormValid(): boolean {
    if (!form.getValues('caseStatusId')) {
      return false;
    }

    if (!form.getValues('deliveryMechanism')) {
      return false;
    }

    if (!form.getValues('message')) {
      return false;
    }

    if (form.getValues('deliveryMechanism').includes('handwrytten')) {
      if (!cardOrderDetails.cardId) {
        return false;
      }
      if (!cardOrderDetails.fontId) {
        return false;
      }
    }

    if (form.getValues('deliveryMechanism').includes('sms')) {
      if (!form.getValues('provisionedPhoneNumberId')) {
        return false;
      }
    }

    if (!form.getValues('deliveryCadence')) {
      return false;
    }

    if (form.getValues('deliveryCadence') === 'sendAfter') {
      if (!form.getValues('sendAfterTimeAmount')) {
        return false;
      }
      if (!form.getValues('sendAfterTimeDimension')) {
        return false;
      }
    }

    if (form.getValues('deliveryCadence') === 'sendOnRepeat') {
      if (!form.getValues('sendOnRepeatDimension')) {
        return false;
      }
      if (form.getValues('sendOnRepeatDimension') === 'Daily') {
        if (!form.getValues('sendOnRepeatTime')) {
          return false;
        }
        if (!form.getValues('sendOnRepeatTimeZone')) {
          return false;
        }
      }

      if (form.getValues('sendOnRepeatDimension') === 'Weekly') {
        if (!form.getValues('sendOnRepeatWeekday')) {
          return false;
        }
        if (!form.getValues('sendOnRepeatTime')) {
          return false;
        }
      }
      if (form.getValues('sendOnRepeatDimension') === 'Monthly') {
        if (!form.getValues('sendOnRepeatCalendarDay')) {
          return false;
        }
        if (!form.getValues('sendOnRepeatTime')) {
          return false;
        }
      }
    }

    return Object.keys(form.formState.errors).length === 0;
  }

  function handleUpdateCampaignTrigger() {
    const cadenceParameters = {
      sendImmediately: () => ({
        message: form.getValues('message'),
        sendAfterTimeDimension: undefined,
        sendAfterTimeAmount: undefined,
        sendOnRepeatWeekday: undefined,
      }),
      sendAfter: () => ({
        message: form.getValues('message'),
        sendAfterTimeAmount: parseIntFormValues(form.getValues('sendAfterTimeAmount')),
        sendAfterTimeDimension: form.getValues('sendAfterTimeDimension'),
        sendOnRepeatWeekday: undefined,
      }),
      sendOnRepeat: (dimension: 'Daily' | 'Weekly' | 'Monthly') => ({
        message: form.getValues('message'),
        sendAfterTimeDimension: undefined,
        sendAfterTimeAmount: undefined,
        ...(dimension === 'Daily'
          ? {
              sendOnRepeatTime: form.getValues('sendOnRepeatTime'),
              sendOnRepeatTimeHour: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[0],
              ),
              sendOnRepeatTimeMinute: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[1],
              ),
              sendOnRepeatTimeZone: form.getValues('sendOnRepeatTimeZone'),
              sendOnRepeatDimension: 'Daily',
            }
          : dimension === 'Weekly'
          ? {
              sendOnRepeatWeekday: form.getValues('sendOnRepeatWeekday')
                ? [form.getValues('sendOnRepeatWeekday')].flat()
                : undefined,
              sendOnRepeatDimension: 'Weekly',
              sendOnRepeatTime: form.getValues('sendOnRepeatTime'),
              sendOnRepeatTimeHour: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[0],
              ),
              sendOnRepeatTimeMinute: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[1],
              ),
              sendOnRepeatTimeZone: form.getValues('sendOnRepeatTimeZone'),
            }
          : {
              sendOnRepeatCalendarDay: form.getValues('sendOnRepeatCalendarDay')
                ? [form.getValues('sendOnRepeatCalendarDay')]
                : undefined,
              sendOnRepeatTime: form.getValues('sendOnRepeatTime'),
              sendOnRepeatTimeHour: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[0],
              ),
              sendOnRepeatTimeMinute: parseInt(
                (form?.getValues('sendOnRepeatTime') || '').split(':')[1],
              ),
              sendOnRepeatTimeZone: form.getValues('sendOnRepeatTimeZone'),
              sendOnRepeatDimension: 'Monthly',
            }),
      }),
    };

    function buildCadenceParameters(str: keyof typeof cadenceParameters) {
      if (!str) {
        return () => {};
      }
      return cadenceParameters[str];
    }

    const selectCadence = form.getValues('deliveryCadence') as keyof typeof cadenceParameters;

    const dimension = form.getValues(
      'sendOnRepeatDimension',
    ) as SendOnRepeatParams['sendOnRepeatDimension'];

    setTyping(true);
    if (!caseCampaignTrigger?.caseCampaignTriggerId) {
      createTrigger
        .mutateAsync({
          caseCampaignId: caseCampaignId,
          data: {
            caseStatusId: form.getValues('caseStatusId') ?? '',
            deliveryMechanism: form.getValues('deliveryMechanism'),
            deliveryCadence: form.getValues('deliveryCadence'),
            ...buildCadenceParameters(selectCadence)(dimension),
            handwryttenCardId: cardOrderDetails.cardId?.toString(),
            handwryttenFontId: cardOrderDetails.fontId?.toString(),
            provisionedPhoneNumberId: form.getValues('provisionedPhoneNumberId'),
          },
        })
        .then(() => {
          setTyping(false);
          setOpen(false);
          form.reset();
        });
    } else {
      updateTrigger
        .mutateAsync({
          caseCampaignTriggerId: caseCampaignTrigger?.caseCampaignTriggerId ?? '',
          caseStatusId: form.getValues('caseStatusId') ?? '',
          deliveryMechanism: form.getValues('deliveryMechanism'),
          deliveryCadence: form.getValues('deliveryCadence'),
          ...buildCadenceParameters(selectCadence)(dimension),
          handwryttenCardId: cardOrderDetails.cardId?.toString(),
          handwryttenFontId: cardOrderDetails.fontId?.toString(),
          provisionedPhoneNumberId: form.getValues('provisionedPhoneNumberId'),
        })
        .then(() => {
          setTyping(false);
          setOpen(false);
          form.reset();
        });
    }
  }

  function handleDeleteCampaignTrigger() {
    setTyping(true);

    deleteTrigger.mutateAsync(caseCampaignTrigger?.caseCampaignTriggerId ?? '').then(() => {
      setTyping(false);
      setOpen(false);
      form.reset();
    });
  }
  // #endregion

  return (
    <div>
      <Slideover
        open={open}
        setOpen={setOpen}
        title={'Campaign Message Details'}
        description={'Edit campaign message details'}
        displayDeleteButton={true}
        deleteButtonText="Remove Case Status Trigger"
        deleteFunction={handleDeleteCampaignTrigger}
        typing={typing}
        onClose={() => {
          setCardOrderDetails({});
          setCharCount(0);
          setSelectedCadence(undefined);
          form.reset({ message: '' });
        }}
        submitButton={
          <CreateButton
            title={caseCampaignTrigger?.caseCampaignTriggerId ? 'Update Trigger' : 'Create Trigger'}
            onClick={() => {
              handleUpdateCampaignTrigger();
            }}
            disabled={!isTriggerFormValid()}
            loading={createTrigger.isLoading || updateTrigger.isLoading || typing}
          />
        }
      >
        <>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpdateCampaignTrigger)}>
              <div className="flex flex-col gap-5 pt-5">
                <span className="text-sm">
                  All contacts marked as <span className="font-bold">Primary Contact</span> will
                  receive this campaign
                </span>
                <SelectFormInput
                  {...form.register(`caseStatusId`)}
                  title="Case Status"
                  listItems={caseStatusListItems}
                  listItemsIsObject
                  placeholderText="Select a Case Status"
                  handleOnChange={(e: React.SyntheticEvent) => {}}
                  disabled={isCaseStatusesLoading}
                  defaultValue={caseCampaignTrigger?.caseStatusId}
                />
                <SelectFormInput
                  {...form.register(`deliveryMechanism`)}
                  title="Delivery Mechanism"
                  listItems={invert(deliveryMechanismOptionsMap)}
                  listItemsIsObject
                  defaultValue={caseCampaignTrigger?.deliveryMechanism}
                  placeholderText="How would you like to send this message?"
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    setDeliveryMechanism(target.value as CampaignDeliveryMechanism);
                    if (target.value === 'sms') {
                      setCharCountLimit(CHARACTER_LIMIT);
                    }
                  }}
                />
                {deliveryMechanism === 'sms' && (
                  <SelectFormInput
                    {...form.register(`provisionedPhoneNumberId`)}
                    title="Client Communication Numbers"
                    listItems={clientCommunicationNumbersListItems}
                    listItemsIsObject
                    defaultValue={caseCampaignTrigger?.provisionedPhoneNumberId}
                    placeholderText="Select a phone number"
                    handleOnChange={(e: React.SyntheticEvent) => {}}
                  />
                )}
                {deliveryMechanism === 'handwrytten' && (
                  <>
                    <HandwryttenSelectForm
                      setCharacterCount={setCharCountLimit}
                      setCardOrder={setCardOrderDetails}
                      handwryttenCardOrderDetails={{
                        cardId: caseCampaignTrigger?.handwryttenCardId?.toString(),
                        fontId: caseCampaignTrigger?.handwryttenFontId?.toString(),
                      }}
                    />
                  </>
                )}

                <div className="flex flex-col justify-between border border-gray-300 rounded-lg shadow-lg  h-[85%] p-4  bg-gray-200">
                  <TextFormInput
                    title={'Campaign Message'}
                    type="textarea"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      setCharCount(target.value.length);
                    }}
                    placeholderText={'Enter a message'}
                    hideLabel={true}
                    {...form.register(`message`)}
                  />

                  <div className="flex flex-row justify-between mt-2 ml-2 ">
                    <Typography size="xs" color="gray">
                      {charCount}/{charCountLimit} characters
                    </Typography>
                    <SnippetTool
                      onSnippetSelect={(message: string) => {
                        form.setValue('message', message);
                        setCharCount(message.length);
                      }}
                    />
                    {/* <TemplateDictionaryTool
                      onTemplateSelect={(templateString: string) => {
                        const currentCampaignMessage =
                          form.getValues('message');
                        form.setValue(
                          'message',
                          `${currentCampaignMessage} ${templateString}`
                        );
                      }}
                    /> */}
                  </div>
                </div>
                <SelectFormInput
                  {...form.register(`deliveryCadence`)}
                  title="Delivery Cadence"
                  listItemsIsObjectWithDetails
                  listItems={deliveryCadenceListItemsWithDetails}
                  defaultValue={caseCampaignTrigger?.deliveryCadence}
                  placeholderText="When would you like to send this message?"
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    setSelectedCadence(target.value as CampaignDeliveryCadence);
                  }}
                  triggerClassName="flex h-50"
                />
                {selectedCadence === 'sendAfter' && (
                  <>
                    <div className="grid grid-cols-2 gap-2">
                      <TextFormInput
                        {...form.register('sendAfterTimeAmount')}
                        title="Time Amount"
                        handleOnChange={(e: React.SyntheticEvent) => {}}
                        placeholderText="Enter a Time"
                      />
                      <SelectFormInput
                        {...form.register(`sendAfterTimeDimension`)}
                        title="Time Dimension"
                        listItems={sendAfterTimeDimensionListItems}
                        listItemsIsObject
                        defaultValue={caseCampaignTrigger?.sendAfterTimeDimension}
                        handleOnChange={(e: React.SyntheticEvent) => {}}
                      />
                    </div>
                  </>
                )}
                {form.getValues('deliveryCadence') === 'sendOnRepeat' && (
                  <>
                    <div className="flex flex-col gap-4">
                      <SelectFormInput
                        {...form.register(`sendOnRepeatDimension`)}
                        title="Daily / Weekly / Monthly"
                        listItems={sendOnRepeatOptions}
                        defaultValue={
                          caseCampaignTrigger?.sendOnRepeatDimension as SendOnRepeatParams['sendOnRepeatDimension']
                        }
                        handleOnChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          setSendOnRepeatTimeDimension(
                            target.value as SendOnRepeatParams['sendOnRepeatDimension'],
                          );
                        }}
                      />
                      {sendOnRepeatTimeDimension === 'Daily' && (
                        <div className="flex flex-col gap-4">
                          <SelectFormInput
                            {...form.register(`sendOnRepeatTime`)}
                            title="Time"
                            listItems={generateTimeStamps(9, 20, 15)}
                            listItemsIsObject
                            defaultValue={form.getValues('sendOnRepeatTime') || undefined}
                            handleOnChange={(e: React.SyntheticEvent) => {}}
                            placeholderText="Select a Time"
                          />
                          <SelectFormInput
                            {...form.register(`sendOnRepeatTimeZone`)}
                            title="Timezone"
                            listItems={sendOnRepeatTimeZoneOptions}
                            defaultValue={form.getValues('sendOnRepeatTimeZone') || undefined}
                            handleOnChange={(e: React.SyntheticEvent) => {}}
                            placeholderText="Select a Timezone"
                          />
                        </div>
                      )}
                      {sendOnRepeatTimeDimension === 'Weekly' && (
                        <>
                          <div className="grid grid-cols-2 gap-2">
                            <SelectFormInput
                              {...form.register(`sendOnRepeatWeekday`)}
                              title="Weekday"
                              listItems={sendOnRepeatWeekDayOptions}
                              defaultValue={form.getValues('sendOnRepeatWeekday')?.[0] || undefined}
                              placeholderText="Select a Weekday"
                              handleOnChange={(e: React.SyntheticEvent) => {}}
                            />
                            <SelectFormInput
                              {...form.register(`sendOnRepeatTime`)}
                              title="Time"
                              listItems={generateTimeStamps(9, 20, 15)}
                              listItemsIsObject
                              defaultValue={form.getValues('sendOnRepeatTime') || undefined}
                              handleOnChange={(e: React.SyntheticEvent) => {}}
                              placeholderText="Select a Time"
                            />
                          </div>
                          <SelectFormInput
                            {...form.register(`sendOnRepeatTimeZone`)}
                            title="Timezone"
                            listItems={sendOnRepeatTimeZoneOptions}
                            defaultValue={form.getValues('sendOnRepeatTimeZone') || undefined}
                            handleOnChange={(e: React.SyntheticEvent) => {}}
                            placeholderText="Select a Timezone"
                          />
                        </>
                      )}
                      {sendOnRepeatTimeDimension === 'Monthly' && (
                        <>
                          <div className="grid grid-cols-2 gap-2">
                            <SelectFormInput
                              {...form.register(`sendOnRepeatCalendarDay`)}
                              defaultValue={
                                form.getValues('sendOnRepeatCalendarDay')?.toString() || undefined
                              }
                              title="Date"
                              listItems={sendOnRepeatCalendarDayOptions}
                              placeholderText="Select a Date"
                              handleOnChange={(e: React.SyntheticEvent) => {}}
                            />
                            <SelectFormInput
                              {...form.register(`sendOnRepeatTime`)}
                              title="Time"
                              listItems={generateTimeStamps(9, 20, 15)}
                              listItemsIsObject
                              defaultValue={form.getValues('sendOnRepeatTime') || undefined}
                              handleOnChange={(e: React.SyntheticEvent) => {}}
                              placeholderText="Select a Time"
                            />
                          </div>
                          <SelectFormInput
                            {...form.register(`sendOnRepeatTimeZone`)}
                            title="Timezone"
                            listItems={sendOnRepeatTimeZoneOptions}
                            defaultValue={form.getValues('sendOnRepeatTimeZone') || undefined}
                            handleOnChange={(e: React.SyntheticEvent) => {}}
                            placeholderText="Select a Timezone"
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </form>
          </Form>
        </>
      </Slideover>
    </div>
  );
}

export default CaseCampaignTriggerSlideover;
