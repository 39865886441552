import { useDeleteAudience, useGetAudiences } from '@colosseum/data';
import { FilterSelect, FilterSelectProps } from '@colosseum/shared-ui';
import { AudienceType } from '@gladiate/types';
import { useState } from 'react';

/**
 * This component just exists so we don't have to do so much leg work to use the
 * FilterSelect component in the ContactsPage
 */
export function AudienceSelect(
  props: Pick<
    FilterSelectProps<AudienceType>,
    'activeFilter' | 'handleItemSelect' | 'placeholder' | 'triggerClassName'
  >,
) {
  const [audienceSelectOpen, setAudienceSelectOpen] = useState(false);

  const { data: audiencesData, isLoading: isAudiencesLoading } = useGetAudiences();
  const deleteAudience = useDeleteAudience();

  return (
    <FilterSelect<AudienceType>
      {...props}
      data={audiencesData?.data}
      deleteFilter={deleteAudience}
      isLoading={isAudiencesLoading}
      open={audienceSelectOpen}
      resourceIdKey="audienceId"
      emptyMessage="No audiences have been created."
      setOpen={setAudienceSelectOpen}
    />
  );
}

export default AudienceSelect;
