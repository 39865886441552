import {
  createPresetFilterV3,
  updatePresetFilterV3,
  useCreatePresetFilter,
  useURLParamsAsObject,
  useUpdatePresetFilter,
} from '@colosseum/data';
import {
  Button,
  CreateButton,
  Form,
  Slideover,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { PresetFilterType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { startCase } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import PresetFilterChip from '../PresetFilterChip/PresetFilterChip';

export interface PresetFilterSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  presetFilterToEdit?: PresetFilterType;
  setPresetFilterToEdit?: Dispatch<SetStateAction<PresetFilterType | undefined>>;
  setActivePresetFilter: Dispatch<SetStateAction<PresetFilterType | undefined>>;
}

const presetFilterFormSchema = z.object({
  title: z.string().trim().min(1, "This field can't be blank"),
  description: z.string().optional(),
});

export function PresetFilterSlideover(props: PresetFilterSlideoverProps) {
  const { open, setOpen, presetFilterToEdit, setPresetFilterToEdit, setActivePresetFilter } = props;
  const createPresetFilter = useCreatePresetFilter();
  const updatePresetFilter = useUpdatePresetFilter();
  const queryParamsObject = useURLParamsAsObject();
  const presetFilterForm = useForm<z.infer<typeof presetFilterFormSchema>>({
    resolver: zodResolver(presetFilterFormSchema),
    values: {
      title: presetFilterToEdit?.title ?? '',
      description: presetFilterToEdit?.description ?? '',
    },
  });

  const createPresetFilterHandler = (data: Parameters<typeof createPresetFilterV3>[0]) => {
    const presetFilter: {
      title: string | undefined;
      description?: string;
    } = {
      title: data.title ?? '',
      description: data.description,
      ...queryParamsObject,
    };

    createPresetFilter.mutateAsync(presetFilter).then((res) => {
      presetFilterForm.reset();
      setActivePresetFilter(res.data);
      setOpen(false);
    });
  };

  const updatePresetFilterHandler = (data: Parameters<typeof updatePresetFilterV3>[0]) => {
    if (!presetFilterToEdit?.presetFilterId) return;
    const presetFilter: {
      title: string | undefined;
      description?: string;
    } = {
      title: data.title ?? '',
      description: data.description,
      ...queryParamsObject,
    };

    updatePresetFilter
      .mutateAsync({
        ...presetFilter,
        presetFilterId: presetFilterToEdit?.presetFilterId,
      })
      .then((res) => {
        setActivePresetFilter(res.data);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (!open) {
      presetFilterForm.reset();
      setPresetFilterToEdit?.(undefined);
    }
  }, [open, presetFilterForm, setPresetFilterToEdit]);

  return (
    <div>
      <Slideover
        title={presetFilterToEdit ? `Edit ${presetFilterToEdit.title}` : 'Create new preset filter'}
        description={
          presetFilterToEdit ? 'Update an existing preset filter.' : 'Create a new preset filter.'
        }
        open={open}
        setOpen={setOpen}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5 border-t border-t-gray-200">
            <Button
              variant="outlinePrimary"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            {presetFilterToEdit ? (
              <CreateButton
                title={'Update Preset Filter'}
                loading={updatePresetFilter.isLoading}
                onClick={presetFilterForm.handleSubmit(updatePresetFilterHandler)}
              />
            ) : (
              <CreateButton
                title={'Create Preset Filter'}
                loading={createPresetFilter.isLoading}
                onClick={presetFilterForm.handleSubmit(createPresetFilterHandler)}
              />
            )}
          </div>
        }
      >
        <Form {...presetFilterForm}>
          <form>
            <div className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
              <div className="col-span-2">
                <TextFormInput
                  name="title"
                  title="Title"
                  dataCy="text-input-title-slideover"
                  maxLength={200}
                />
              </div>
              <div className="col-span-2">
                <TextFormInput
                  name="description"
                  title="Description"
                  type="textarea"
                  dataCy="text-input-description-slideover"
                  maxLength={400}
                />
              </div>
            </div>
          </form>
        </Form>
        <Typography variant="heading" className="pt-2">
          Summary
        </Typography>
        <div>
          {Object.keys(queryParamsObject).map((key) => (
            <div key={key} className="mt-4 ">
              <Typography variant="grayUpper">{startCase(key)}</Typography>
              <div className="flex flex-wrap mt-2 gap-x-2 gap-y-4">
                {typeof queryParamsObject[key] === 'string' ? (
                  <PresetFilterChip title={queryParamsObject[key] as string} filterName={key} />
                ) : (
                  (queryParamsObject[key] as string[])?.map((keyArrayItem) => (
                    <PresetFilterChip key={keyArrayItem} title={keyArrayItem} filterName={key} />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </Slideover>
    </div>
  );
}

export default PresetFilterSlideover;
