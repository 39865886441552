import { getNameToDisplayFromCognito, useGetFirmUsers } from '@colosseum/data';
import { XMarkIcon } from '@heroicons/react/24/solid';
import GladiateLoader from '../GladiateLoader/GladiateLoader';

/* eslint-disable-next-line */
export interface AssigneeChipProps {
  assigneeId: string;
  handleDelete: () => void;
  isDeleting?: boolean;
}

export function AssigneeChip(props: AssigneeChipProps) {
  const { assigneeId, handleDelete, isDeleting } = props;
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const firmUsers =
    firmUsersData?.map((user) => {
      const displayName = getNameToDisplayFromCognito(user);
      return {
        displayName,
        value: user?.Username,
      };
    }) ?? [];
  return (
    <div className="justify-between inline-block p-1 px-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue h-fit min-w-fit w-fit">
      <div className="flex items-center">
        <p data-cy="assignee-chip-name" className="text-xs">
          {firmUsers?.find((user) => user.value === assigneeId)?.displayName}
        </p>
        {isDeleting ? (
          <GladiateLoader height={16} className="h-4 ml-2 min-w-4" />
        ) : (
          <XMarkIcon
            onClick={handleDelete}
            className="w-4 h-4 ml-2 cursor-pointer hover:text-black"
          />
        )}
      </div>
    </div>
  );
}

export default AssigneeChip;
