import { DefaultV3Response, ItemTagConnectionType, TagType } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const baseTitulusRoute = `/titulus/${API_STAGE}/v3`;

// ------------------ TAGS ------------------

export const createTagV3 = (data: Partial<TagType>) => {
  return axiosInstance.post(`${baseTitulusRoute}/tags`, data);
};

export const deleteTagV3 = (tagId: string) => {
  return axiosInstance.delete(`${baseTitulusRoute}/tags/${tagId}`);
};

export const getTagsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<TagType[]>>(`${baseTitulusRoute}/tags`)
    .then((res) => res.data);

export const updateTagV3 = (data: Partial<TagType>) => {
  const { tagId, ...rest } = data;
  return axiosInstance.patch(`${baseTitulusRoute}/tags/${tagId}`, rest);
};

// ------------------ ITEMTAGCONNECTIONS ------------------

export const createItemTagConnectionV3 = (
  data: Partial<ItemTagConnectionType> & {
    tagAttributes?: Partial<TagType>;
    itemId: string;
  },
) => {
  const { itemId, ...rest } = data;
  return axiosInstance.post(`${baseTitulusRoute}/${itemId}/tag-connections`, rest);
};

export const deleteItemTagConnectionV3 = (itemTagConnectionId: string) => {
  return axiosInstance.delete(`${baseTitulusRoute}/tag-connections/${itemTagConnectionId}`);
};

export const getItemTagConnectionsV3 = (itemId: string) =>
  axiosInstance
    .get<DefaultV3Response<ItemTagConnectionType[]>>(
      `${baseTitulusRoute}/${itemId}/tag-connections`,
    )
    .then((res) => res.data);

export const getAllItemTagConnectionsV3 = () => {
  return axiosInstance
    .get<DefaultV3Response<ItemTagConnectionType[]>>(`${baseTitulusRoute}/tag-connections`)
    .then((res) => res.data);
};
