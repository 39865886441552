import { cn } from '@colosseum/data';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import kebabCase from 'lodash/kebabCase';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/Popover/Popover';

type optionsObject = {
  title: string;
  value: string;
};
interface SharedProps {
  title: string;
  selectedOptions: string[];
  onChange: (option: string) => void;
  disabled?: boolean;
}

type ConditionalProps =
  | { options: optionsObject[]; optionsIsObject: true }
  | { options: string[]; optionsIsObject?: false };

export type MultiSelectFormInputProps = SharedProps & ConditionalProps;

export function MultiSelectFormInput(props: MultiSelectFormInputProps) {
  const { title, options, optionsIsObject, selectedOptions, onChange, disabled } = props;

  const selectedOptionsTooltip = optionsIsObject
    ? selectedOptions?.map((option) => options?.find((o) => o.value === option)?.title)?.join(', ')
    : selectedOptions?.join(', ');

  return (
    <Popover modal>
      <TooltipWrapper message={selectedOptionsTooltip}>
        <PopoverTrigger>
          <div
            data-cy={`multi-select-${kebabCase(title) as string}`}
            className={cn(
              'flex items-center justify-between px-3 py-[7px] font-semibold border rounded-md',
              {
                'text-gray-500 bg-gray-100 border-gray-300': disabled,
                'text-gray-900 border-gray-300': !disabled,
              },
            )}
          >
            <div className="flex items-center">
              <div
                className={cn('mr-1.5 rounded py-0.5 px-1.5 text-xs font-semibold tabular-nums', {
                  'bg-gray-200 text-gray-700': !disabled,
                  'bg-gray-300 text-gray-500': disabled,
                })}
              >
                {(selectedOptions && selectedOptions.length) ?? 0}
              </div>
              {title}
            </div>

            <ChevronDownIcon
              className={cn('w-5 h-5 ml-2', {
                'text-gray-400': !disabled,
                'text-gray-300': disabled,
              })}
            />
          </div>
        </PopoverTrigger>
      </TooltipWrapper>
      <PopoverContent className="z-50 overflow-auto max-h-96">
        <div className="p-4 space-y-2">
          {options &&
            (optionsIsObject
              ? options.map((option) => (
                  <div key={option.value} className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 mr-2 text-blue-500 border-gray-300 rounded focus:ring-blue-500 form-checkbox"
                      checked={selectedOptions?.includes(option.value)}
                      onChange={() => onChange(option.value)}
                      data-cy={`multi-select-option-${kebabCase(option.title) as string}`}
                      disabled={disabled}
                    />

                    <div className="overflow-hidden text-ellipsis">{option.title}</div>
                  </div>
                ))
              : options.map((option) => (
                  <div key={option} className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 mr-2 text-blue-500 border-gray-300 rounded focus:ring-blue-500 form-checkbox"
                      checked={selectedOptions?.includes(option)}
                      onChange={() => onChange(option)}
                      data-cy={`multi-select-option-${kebabCase(option) as string}`}
                      disabled={disabled} // Disable checkbox
                    />

                    <div className="overflow-hidden text-ellipsis">{option}</div>
                  </div>
                )))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default MultiSelectFormInput;
