import { useGetAuditsWithNotifications } from '@colosseum/data';
import {
  Calendar,
  CustomErrorBoundary,
  ResourceHistory,
  ResourceTasks,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { withErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import CasesBySource from './CasesBySource/CasesBySource';
import FirmAnalytics from './FirmAnalytics/FirmAnalytics';
import OpenCases from './OpenCases/OpenCases';

/* eslint-disable-next-line */
export interface HomePageProps {}

export function HomePage(props: HomePageProps) {
  const audits = useGetAuditsWithNotifications({});

  return (
    <div className="w-full gap-4" data-cy="homepage">
      <Helmet defer={false}>
        <title>Dashboard</title>
      </Helmet>
      <FirmAnalytics />
      <div className="sm:flex justify-between sm:max-h-[1050px] w-full sm:space-x-5">
        <OpenCases />
        <CasesBySource />
      </div>
      <div className="w-full p-5 mt-5 bg-white border shadow-sm rounded-xl">
        <Tabs defaultValue="tasks">
          <TabsList id="firm-tabs" className="my-4 text-gray-500 bg-transparent">
            <TabsTrigger
              className={tabTriggerClass}
              key="tasks"
              value="tasks"
              data-cy="firm-tasks-tab"
            >
              Tasks
            </TabsTrigger>
            <TabsTrigger
              key="feed"
              value="feed"
              data-cy="firm-feed-tab"
              className={tabTriggerClass}
            >
              <span className="flex gap-x-2">
                Feed
                {audits.notificationCount && audits.notificationCount !== '0' && (
                  <div className="self-center data-[state=active]:no-underline no-underline w-6 h-5 text-xs text-white bg-orange-500 rounded-xl justify-center flex flex-col">
                    {audits.notificationCount}
                  </div>
                )}
              </span>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="tasks">
            <ResourceTasks scrollable isPaginated showCaseFilter />
          </TabsContent>
          <TabsContent value="feed">
            <ResourceHistory
              isPaginated={true}
              showCaseFilter
              audits={audits}
              isAuditsLoading={audits.isLoading}
              setNotificationsOnly={audits.setNotificationsOnly}
              notificationsOnly={audits.notificationsOnly}
            />
          </TabsContent>
        </Tabs>
      </div>
      <div className="w-full p-5 mt-5 bg-white border shadow-sm rounded-xl">
        <Calendar defaultView="timeGridWeek" addEventDisabled={true} />
      </div>
    </div>
  );
}

export default withErrorBoundary(HomePage, { fallback: CustomErrorBoundary });
