import { TooltipWrapper, renderCell, renderHeader } from '@colosseum/shared-ui';
import { AdobeUserType } from '@gladiate/types';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const getLabel = (status: string) => {
  switch (status) {
    case 'ACTIVE': {
      return (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
          Active
        </span>
      );
    }
    case 'INACTIVE': {
      return (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
          Inactive
        </span>
      );
    }
    case 'CREATED': {
      return (
        <TooltipWrapper
          message={'Account has been created but adobe onboarding has not been completed.'}
        >
          <span className="inline-flex px-2 text-xs font-semibold leading-5 text-yellow-800 bg-yellow-100 rounded-full">
            Created
          </span>
        </TooltipWrapper>
      );
    }
    default: {
      return (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
          Unknown
        </span>
      );
      break;
    }
  }
};

export const columns: ColumnDef<AdobeUserType>[] = [
  {
    accessorFn: (row) => `${row.adobeUser.firstName} ${row.adobeUser.lastName}`,
    id: 'Name',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) =>
      renderCell({
        props,
        valueModifierFunc: (item: string) => startCase(item),
      }),
    sortingFn: 'alphanumeric',
  },
  {
    accessorFn: (row) => row.username,
    id: 'User Name',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.userStatus,
    id: 'Status',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props: CellContext<AdobeUserType, unknown>) => {
      const status = props.getValue() as string;
      return getLabel(status);
    },
  },
  {
    accessorFn: (row) => (row.isAdmin ? '1' : ''),
    id: 'Role',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props: CellContext<AdobeUserType, unknown>) => {
      const isAdmin = props.getValue() as string;
      return (
        <span>
          {isAdmin && (
            <div className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
              Admin
            </div>
          )}
        </span>
      );
    },
  },
  {
    accessorFn: (row) => (row.signerPrivelage ? '1' : ''),
    id: 'Signer',
    sortUndefined: 1,
    enableHiding: false,
    header: renderHeader,
    cell: (props: CellContext<AdobeUserType, unknown>) => {
      const value = props.getValue() as string;
      return (
        <span>
          {value && (
            <div className="inline-flex justify-center font-semibold text-green-800 align-middle bg-green-100 rounded-full">
              <CheckBadgeIcon className="w-5 h-5 text-green-500" />
            </div>
          )}
        </span>
      );
    },
  },
];
