import { FileResourceType } from '@gladiate/types';
import { Dispatch, SetStateAction } from 'react';
import { FileExplorer } from '../FileExplorer/FileExplorer';
import Modal from '../shadcn/Modal/Modal';

interface FileAttachmentModalProps {
  caseId: string;
  showFileExplorer: boolean;
  setShowFileExplorer: Dispatch<SetStateAction<boolean>>;
  onAttachFiles: () => void;
  onSelectedFilesChange: (files: FileResourceType[]) => void;
  forSMS?: boolean;
  unavailableResources?: {
    resourceId: string;
    reason?: string;
  }[];
}

export function FileAttachmentModal(props: FileAttachmentModalProps) {
  const {
    caseId,
    showFileExplorer,
    setShowFileExplorer,
    onAttachFiles,
    onSelectedFilesChange,
    forSMS,
    unavailableResources,
  } = props;

  return (
    <Modal
      open={showFileExplorer}
      setOpen={setShowFileExplorer}
      primaryButton={{
        label: 'Attach',
        className: 'text-white bg-atlantic-blue hover:bg-light-blue rounded-md px-4',
        onClick: () => {
          onAttachFiles();
          setShowFileExplorer(false);
        },
      }}
      title={'Attach Files'}
      width="w-[60vw]"
    >
      <div className="mb-5">
        <FileExplorer
          caseId={caseId}
          onSelectedFilesChange={onSelectedFilesChange}
          unavailableResources={unavailableResources}
          buttonsState={{
            addContent: true,
            createFolder: true,
            rename: true,
            move: false,
            delete: true,
            generateDocument: false,
            sendForSignature: false,
            download: false,
          }}
          collapsibleHidden
          listViewOnly
          folderSelectDisabled={forSMS}
        />
      </div>
    </Modal>
  );
}

export default FileAttachmentModal;
