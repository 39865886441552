import {
  cn,
  enqueueAPISnackbar,
  getNameToDisplayFromCognito,
  useGetCoCounselsComplete,
  useGetFirmUsers,
} from '@colosseum/data';
import { DropDownMenuItem } from '@gladiate/types';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import GladiateLoader from '../GladiateLoader/GladiateLoader';

/* eslint-disable-next-line */
export interface FirmUserLookupProps {
  selectedUsers:
    | { balanceVerifiedUser?: string; staffId?: string; username?: string }[]
    | undefined;
  handleAdd: (user: string) => Promise<any>;
  handleRemove: (user: any) => Promise<any> | undefined;
  key: 'balanceVerifiedUser' | 'staffId' | 'assigneeId' | 'coCounselId';
  allowMultiple: boolean;
  tagClassName?: string;
  buttonClassName?: string;
  buttonLoaderClassName?: string;
  buttonDataCy?: string;
  coCounselOnly?: boolean;
  noOptionsMessage?: string;
}

export function FirmUserLookup(props: FirmUserLookupProps) {
  const firmUsersQuery = useGetFirmUsers();
  const coCounselQuery = useGetCoCounselsComplete();
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState<string>();

  const firmUsersData = props.coCounselOnly
    ? coCounselQuery?.data?.data
    : firmUsersQuery.data?.data;
  const showAddButton = props.allowMultiple
    ? true
    : !props.selectedUsers || props.selectedUsers?.length === 0;

  const firmUsers =
    firmUsersData?.map((user) => {
      const displayName = getNameToDisplayFromCognito(user) ?? '-';
      return {
        displayName,
        value: user?.Username ?? '',
      };
    }) ?? [];

  return (
    <div className="">
      <div className="flex flex-wrap items-center ">
        {props.selectedUsers &&
          props.selectedUsers?.map((person) => (
            <div
              key={person[props.key as keyof typeof person]}
              className={cn(
                'flex mt-3  px-2 py-1.5 text-xs mr-3 font-semibold text-atlantic-blue bg-light-blue rounded-full ',
                props.tagClassName,
              )}
              data-cy="assignee-chip-name"
            >
              {firmUsers?.find((firmUser) => firmUser.value === person.username)?.displayName}
              <button
                onClick={() => {
                  setDeleting(person.username);
                  props.handleRemove(person)?.then(() => setDeleting(undefined));
                }}
                className="ml-2 rounded-full hover:bg-atlantic-blue hover:text-white"
              >
                {deleting === person.username ? (
                  <GladiateLoader height={16} />
                ) : (
                  <XMarkIcon className="w-4 h-4 " />
                )}
              </button>
            </div>
          ))}
        {creating && (
          <GladiateLoader
            className={(cn('px-2 mt-3 mr-3'), props.buttonLoaderClassName)}
            height={28}
          />
        )}
        {showAddButton && (
          <div
            className={cn(
              'inline-flex items-center justify-center flex-shrink-0 mt-3 text-gray-400 bg-white border-2 border-gray-200 border-dashed rounded-full w-7 h-7 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-blue focus:ring-offset-2',
              props.buttonClassName,
            )}
            data-cy={props.buttonDataCy}
          >
            <DropdownMenu
              menuItems={firmUsers}
              displayDownArrow={false}
              noOptionsMessage={props.noOptionsMessage}
              handleSelection={(dropDownMenuItem: DropDownMenuItem) => {
                setCreating(true);
                props
                  .handleAdd(dropDownMenuItem?.value)
                  .then(() => setCreating(false))
                  ?.catch((err) => {
                    if (err.response?.data?.meta?.userMsg) {
                      enqueueAPISnackbar({
                        message: err.response.data.meta.userMsg,
                        variant: 'error',
                      });
                    }
                    setCreating(false);
                  });
              }}
            >
              <div className="-mb-1">
                <span className="sr-only">Add team member</span>
                <PlusIcon className="w-5 h-5" aria-hidden="true" />
              </div>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
}

export default FirmUserLookup;
