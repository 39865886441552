import {
  CaseConflictType,
  CaseContactConnectionRoleType,
  CaseContactConnectionType,
  CaseContactSubObject,
  DefaultV3Response,
  PolicyType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

export const baseConscriptionRoute = `conscription/${API_STAGE}`;

// #region --------- POLICIES ----------- //
// POST create_case_contact_connection_policy_v3
export const createCaseContactConnectionPolicyV3 = (
  data: {
    caseContactConnectionId: string;
  } & Partial<PolicyType>,
) => {
  const { caseContactConnectionId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<PolicyType>>(
      `${baseConscriptionRoute}/v3/connections/${caseContactConnectionId}/policies`,
      rest,
    )
    .then((res) => res.data);
};

// GET get_all_case_contact_connection_policies_v3
export const getAllCaseContactConnectionPoliciesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<PolicyType[]>>(`${baseConscriptionRoute}/v3/connections/policies`)
    .then((res) => res.data);

// GET get_cast_contact_connection_policies_v3
export const getCaseContactConnectionPoliciesV3 = (caseContactConnectionId: string) =>
  axiosInstance
    .get<DefaultV3Response<PolicyType[]>>(
      `${baseConscriptionRoute}/v3/connections/${caseContactConnectionId}/policies`,
    )
    .then((res) => res.data);

// #endregion

// #region --------- ROLES -------------- //
// DEL delete_case_contact_connection_role_v3
export const deleteCaseContactConnectionRoleV3 = (caseContactConnectionRoleId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseContactConnectionRoleType>>(
      `${baseConscriptionRoute}/v3/connections/roles/${caseContactConnectionRoleId}`,
    )
    .then((res) => res.data);

// PATCH update_case_contact_connection_role_v3
export const updateCaseContactConnectionRoleV3 = (data: {
  vehicleId?: string;
  roleOnCase?: string;
  caseContactConnectionRoleId?: string;
  itemType?: CaseContactSubObject;
  itemId?: string;
}) => {
  const { caseContactConnectionRoleId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CaseContactConnectionRoleType>>(
      `${baseConscriptionRoute}/v3/connections/roles/${caseContactConnectionRoleId}`,
      rest,
    )
    .then((res) => res.data);
};

// POST create_case_contact_connection_role_v3
export const createCaseContactConnectionRoleV3 = (data: {
  caseContactConnectionId: string;
  roleOnCase: CaseContactConnectionRoleType['roleOnCase'];
}) => {
  const { caseContactConnectionId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<CaseContactConnectionRoleType>>(
      `${baseConscriptionRoute}/v3/connections/${caseContactConnectionId}/roles`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ------- CONNECTIONS -------- //
// POST create_case_contact_connection_v3
export const createCaseContactConnectionV3 = (data: {
  contactId: string;
  roleOnCase?: string; //TODO: Make this list of roles
  caseId: string;
  itemType?: CaseContactSubObject;
  itemId?: string;
}) =>
  axiosInstance
    .post<
      DefaultV3Response<{
        caseContactConnectionId: string;
        caseContactConnectionRole?: CaseContactConnectionRoleType;
        contactId: string;
      }>
    >(`${baseConscriptionRoute}/v3/connections`, data)
    .then((res) => res.data);

// DEL delete_case_contact_connection_v3
export const deleteCaseContactConnectionV3 = (caseContactConnectionId: string) => {
  return axiosInstance
    .delete<DefaultV3Response<CaseContactConnectionType>>(
      `${baseConscriptionRoute}/v3/connections/${caseContactConnectionId}`,
    )
    .then((res) => res.data);
};

// GET get_case_contact_connections_by_case_v3,
// GET get_case_contact_connections_by_contact_v3
export const getCaseContactConnectionsByResourceV3 = <T>(
  resourceId: string,
  resourceType: 'cases' | 'contacts',
) =>
  axiosInstance
    .get<DefaultV3Response<T>>(
      `${baseConscriptionRoute}/v3/connections/${resourceType}/${resourceId}`,
    )
    .then((res) => res.data);

// GET get_clients_v3
export const getClientsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseContactConnectionType[]>>(
      `${baseConscriptionRoute}/v3/connections/clients`,
    )
    .then((res) => res.data);

// PATCH update_case_contact_connection_v3
export const updateCaseContactConnectionV3 = (
  data: Partial<CaseContactConnectionType> & {
    caseContactConnectionId: string;
  },
) => {
  const { caseContactConnectionId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CaseContactConnectionType>>(
      `${baseConscriptionRoute}/v3/connections/${caseContactConnectionId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region --------- CONFLICTS ---------- //
// GET get_all_case_conflicts_for_case_v3
export const getAllCaseConflictsForCaseV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<CaseConflictType[]>>(
      `${baseConscriptionRoute}/v3/connections/conflicts/cases/${caseId}`,
    )
    .then((res) => res.data);

// GET get_all_case_conflicts_for_firm_v3
export const getAllCaseConflictsForFirmV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseConflictType[]>>(`${baseConscriptionRoute}/v3/connections/conflicts`)
    .then((res) => res.data);
// #endregion
