import {
  DocumentOptions,
  US_STATES,
  displayContactName,
  useCreateArctrievalEntity,
  useCreateArctrievalRequest,
  useGetArctrievalEntities,
  useGetCaseContactConnectionsViewModel,
  useGetContact,
  useGetContactsInfinite,
  useGetCurrentUser,
  useUpdateArctrievalEntity,
  zodPhoneNumberType,
} from '@colosseum/data';
import {
  CalendarFormInput,
  ClientSelectDropdown,
  ContactChip,
  Form,
  FormField,
  FormMessage,
  GladiateLoader,
  MultiSelectDropdown,
  OptionLookup,
  PhoneInput,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectFormInput,
  Switch,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { ContactType, LabyrinthUpdateParams } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { createArctrievalEntityV3 } from 'libs/data/src/lib/requests/argonauts';
import { UserPlusIcon } from 'lucide-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import ArcClientAuthorizationForm from '../ArcClientAuthorizationForm/ArcClientAuthorizationForm';

export interface RecordRequestFormProps {
  caseId: string;
  setReadyToSend: Dispatch<SetStateAction<boolean>>;
  sendRecordRequest: boolean;
  setSendRecordRequest: Dispatch<SetStateAction<boolean>>;
  setSlideOverOpen: Dispatch<SetStateAction<boolean>>;
  slideOverOpen: boolean;
}

const formSchema = z
  .object({
    caseContactConnectionId: z.string().optional(),
    startingDate: z.string().min(1, "This field can't be blank"),
    endingDate: z.string().min(1, "This field can't be blank"),
    requestedInformation: z.string().min(1, 'Please select at least one option'),
    street: z.string().min(1, "This field can't be blank"),
    email: z.string().optional(),
    city: z.string().min(1, "This field can't be blank"),
    zip: z.string().min(5, 'Zip code must be atleast 5 characters.'),
    state: z.string().min(1, "This field can't be blank"),
    faxNumber: zodPhoneNumberType.optional(),
    deliverByEmail: z.boolean().optional(),
    deliverByFax: z.boolean().optional(),
    deliverByMail: z.boolean().optional(),
    methodSelected: z.boolean({
      required_error: 'Must select at least one delivery method.',
    }),
  })
  .refine(
    (data) =>
      // If deliverByEmail is true, email must be present
      (data.deliverByEmail === true && data.email && data.email?.length >= 1) ||
      data.deliverByEmail === false,
    {
      path: ['email'], // Pointing out which field is invalid
      message: 'Please enter an email address',
    },
  )
  .refine(
    (data) =>
      // If deliverByFax is true, faxNumber must be present
      (data.deliverByFax === true && data.faxNumber && data.faxNumber?.length >= 10) ||
      data.deliverByFax === false,
    {
      path: ['faxNumber'], // Pointing out which field is invalid
      message: 'Please enter a valid fax number',
    },
  )
  .refine((data) => data.endingDate >= data.startingDate, {
    message: 'Ending date cannot be earlier than start date.',
    path: ['endingDate'],
  });

export function RecordRequestForm(props: RecordRequestFormProps) {
  const { caseId, sendRecordRequest, setSendRecordRequest, setSlideOverOpen, setReadyToSend } =
    props;
  // #region Hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      requestedInformation: '',
      deliverByEmail: false,
      deliverByFax: false,
      deliverByMail: false,
    },
  });
  // #endregion

  // #region Data Fetching
  const currentUser = useGetCurrentUser();
  const { data: allContactsData } = useGetContactsInfinite();
  const { data: caseContactConnectionsData } = useGetCaseContactConnectionsViewModel(caseId);
  const createArctrievalRequest = useCreateArctrievalRequest();
  const createArctrievalEntity = useCreateArctrievalEntity();
  const updateArctrievalEntity = useUpdateArctrievalEntity();
  const arcFirmUsersQuery = useGetArctrievalEntities();
  const arcFirmClientsData = arcFirmUsersQuery?.data?.data.filter(
    (user) => user.accountType === 'client',
  );
  const arcFirmProvidersData = arcFirmUsersQuery?.data?.data.filter(
    (user) => user.accountType === 'provider',
  );
  // #endregion

  // #region State
  const [activeBillingEntityId, setActiveBillingEntityId] = useState<string>();
  const [deliverByEmail, setDeliverByEmail] = useState(false);
  const [deliverByFax, setDeliverByFax] = useState(false);
  const [deliverByMail, setDeliverByMail] = useState(false);
  const [state, setState] = useState<string>();
  // #endregion

  // #region Derived State

  const selectedClientCaseContactConnectionId = form.getValues('caseContactConnectionId');
  const selectedClientContactId = caseContactConnectionsData?.data.find(
    (connection) => connection.caseContactConnectionId === selectedClientCaseContactConnectionId,
  )?.contact?.contactId;

  const selectedClientHasArcAccount = arcFirmClientsData?.some(
    (client) => client.gladiateId === selectedClientContactId,
  );

  const { data: billingEntityContactDetailsData } = useGetContact(activeBillingEntityId);
  const billingEntityContactDetails = billingEntityContactDetailsData?.data;

  const billingEntityHasArcAccount = arcFirmProvidersData?.some(
    (provider) => provider.gladiateId === activeBillingEntityId,
  );
  // #endregion

  // #region useEffects

  useEffect(() => {
    if (billingEntityContactDetails) {
      if (billingEntityContactDetails?.contactAddresses[0]) {
        form.setValue(
          'street',
          billingEntityContactDetails?.contactAddresses[0]?.streetAddress ?? '',
        );
        form.setValue('city', billingEntityContactDetails?.contactAddresses[0]?.city ?? '');
        setState(billingEntityContactDetails?.contactAddresses[0]?.state);
        form.setValue('state', billingEntityContactDetails?.contactAddresses[0]?.state ?? '');
        form.setValue('zip', billingEntityContactDetails?.contactAddresses[0]?.zipCode ?? '');
      }
      if (billingEntityContactDetails?.contactEmails[0]) {
        form.setValue('email', billingEntityContactDetails?.contactEmails[0].emailAddress);
      }
    }
  }, [billingEntityContactDetails]);

  useEffect(() => {
    if (selectedClientContactId && selectedClientHasArcAccount) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [selectedClientContactId, selectedClientHasArcAccount, setReadyToSend]);

  const handleSubmitArctrievalForm = (data: any) => {
    const arctrievalEntityData: Parameters<typeof createArctrievalEntityV3>[0] = {
      gladiateId: billingEntityContactDetails?.contactId ?? '',
      accountType: 'provider',
      arctrievalData: {
        firstName: billingEntityContactDetails?.firstName ?? '',
        lastName: billingEntityContactDetails?.lastName ?? '',
        email: deliverByEmail ? form.getValues('email') ?? '' : undefined,
        fax: deliverByFax ? form.getValues('faxNumber')?.replace('+1', '') ?? '' : undefined,
        dateOfBirth: billingEntityContactDetails?.dateOfBirth ?? '',
        organizationName: displayContactName(billingEntityContactDetails),
        address: {
          address1: form.getValues('street'),
          city: form.getValues('city'),
          state: state ?? '',
          zip: form.getValues('zip'),
          country: 'United States',
        },
      },
    };
    let arctrievalEntityMutation: typeof updateArctrievalEntity | typeof createArctrievalEntity =
      createArctrievalEntity;
    if (billingEntityHasArcAccount) {
      arctrievalEntityMutation = updateArctrievalEntity;
    } else {
      arctrievalEntityMutation = createArctrievalEntity;
    }
    if (billingEntityContactDetails) {
      arctrievalEntityMutation.mutateAsync(arctrievalEntityData).then((res) => {
        createArctrievalRequest.mutateAsync({
          arctrievalData: {
            deliveryMethod: {
              email: deliverByEmail,
              fax: deliverByFax,
              usMail: deliverByMail,
            },
            startingDate: form.getValues('startingDate'),
            endingDate: form.getValues('endingDate'),
            // isDraft: !stagesForRefetching.includes(API_STAGE),
            isDraft: false,
            requestedInformation: form.getValues('requestedInformation'),
            requestType: 1,
          },
          caseId,
          recordsClientContactId: selectedClientContactId ?? '',
          recordsProviderContactId: res.data.gladiateId,
          recordsRequestorUsername: currentUser.data?.data.username ?? '',
        });
      });
    }
    setSendRecordRequest(false);
    setSlideOverOpen(false);
  };

  useEffect(() => {
    if (sendRecordRequest) {
      form.handleSubmit(handleSubmitArctrievalForm)();
      if (!form.formState.isValid) {
        setSendRecordRequest(false);
      }
    }
  }, [sendRecordRequest]);
  // #endregion

  // #region Event Handlers
  const handleDocumentSelect = (params: LabyrinthUpdateParams) => {
    if (params.value.length === 0) {
      form.setValue('requestedInformation', '');
    } else {
      form.setValue('requestedInformation', params.value.join(', '));
    }
  };
  // #endregion

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmitArctrievalForm)}>
          <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
            <div className="col-span-2">
              <ClientSelectDropdown
                caseItemType="recordRequest"
                caseItemId={'1'} //TODO: Replace with rec req id
                caseId={caseId}
                form={form}
              />
            </div>
            {selectedClientContactId ? (
              arcFirmUsersQuery.isLoading ? (
                <GladiateLoader />
              ) : !selectedClientHasArcAccount ? (
                <div className="col-span-2">
                  <ArcClientAuthorizationForm clientId={selectedClientContactId ?? ''} />
                </div>
              ) : (
                <>
                  {selectedClientHasArcAccount && (
                    <div className="relative w-full col-span-2 ml-1 rounded-md">
                      {activeBillingEntityId ? (
                        <div className="flex flex-wrap items-center self-start gap-2 text-sm whitespace-nowrap">
                          <ContactChip
                            contactId={billingEntityContactDetails?.contactId}
                            onDelete={() => {
                              setActiveBillingEntityId('');
                            }}
                          />
                        </div>
                      ) : (
                        <div className="relative w-full col-span-2 ml-1 rounded-md">
                          Billing Entity
                          <Popover modal>
                            <PopoverTrigger asChild>
                              <button className="flex items-center w-full text-sm text-center">
                                <UserPlusIcon className={'w-6 h-6 text-black'} />
                              </button>
                            </PopoverTrigger>
                            <PopoverContent className="z-50">
                              <OptionLookup
                                isFetchingMore={!!allContactsData?.meta.lastEvaluatedKey}
                                placeholder={`Search ${allContactsData?.data.length ?? 0}${
                                  allContactsData?.meta.lastEvaluatedKey ? '+' : ''
                                } contacts...`}
                                caseId={caseId}
                                options={allContactsData?.data ?? []}
                                displayOption={(option: ContactType) => displayContactName(option)}
                                idString={'contactId'}
                                linkingFunction={(option: ContactType) => {
                                  setActiveBillingEntityId(option.contactId);
                                }}
                                createFunction={() => {
                                  //noop
                                }}
                                rightAlign={true}
                              />
                            </PopoverContent>
                          </Popover>
                        </div>
                      )}
                    </div>
                  )}
                  <CalendarFormInput
                    {...form.register(`startingDate`)}
                    title="Starting Date of Service *"
                    disabled={(date) => date > dayjs().toDate()}
                    resourceTypeObj={{
                      id: '', // TODO add the ID when the feature working
                      type: 'medical-record-request',
                    }}
                  />
                  <CalendarFormInput
                    {...form.register(`endingDate`)}
                    title="Ending Date of Service *"
                    disabled={(date) =>
                      date > dayjs().toDate() ||
                      date < dayjs(form.getValues('startingDate')).toDate()
                    }
                    resourceTypeObj={{
                      id: '', // TODO add the ID when the feature working
                      type: 'medical-record-request',
                    }}
                  />
                  <div className="col-span-2">
                    <MultiSelectDropdown
                      fieldInfo={{
                        title: 'Document Type *',
                        valueVariable: 'documents',
                        type: 'multiSelectDropdown',
                        options: DocumentOptions,
                      }}
                      fieldValue={[]}
                      handleOnChange={handleDocumentSelect}
                    />
                    <FormField
                      control={form.control}
                      name={'requestedInformation'}
                      render={({ field }) => <FormMessage />}
                    />
                  </div>
                  <TextFormInput {...form.register('street')} title="Street *" />
                  <TextFormInput {...form.register('city')} title="City *" />
                  <SelectFormInput
                    {...form.register('state')}
                    title="State *"
                    listItems={US_STATES}
                    value={state}
                    handleOnChange={(e) => {
                      setState(e.target.value);
                      form.setValue('state', e.target.value);
                    }}
                  />
                  <TextFormInput {...form.register('zip')} title="Zip *" />
                  <div className="col-span-2 mt-3 font-semibold">
                    Send Request via:
                    <FormField
                      control={form.control}
                      name={'methodSelected'}
                      render={({ field }) => <FormMessage />}
                    />
                  </div>
                  <Switch
                    fullRow
                    title="Email"
                    name="deliverByEmail"
                    value={deliverByEmail}
                    handleChange={(e) => {
                      setDeliverByEmail(e.target.value);
                      form.setValue('deliverByEmail', e.target.value);
                      form.setValue(
                        'methodSelected',
                        e.target.value || deliverByMail || deliverByFax,
                      );
                    }}
                  />

                  {deliverByEmail && <TextFormInput {...form.register('email')} title="Email *" />}
                  {deliverByEmail && <div></div>}
                  <Switch
                    fullRow
                    title="Fax"
                    name="deliverByFax"
                    value={deliverByFax}
                    handleChange={(e) => {
                      setDeliverByFax(e.target.value);
                      form.setValue('deliverByFax', e.target.value);
                      form.setValue(
                        'methodSelected',
                        e.target.value || deliverByEmail || deliverByMail,
                      );
                    }}
                  />
                  {deliverByFax && (
                    <PhoneInput
                      {...form.register(`faxNumber`)}
                      formHandleBlur={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        form.setValue(`faxNumber`, target.value ? target.value : '');
                      }}
                      title="Fax"
                    />
                  )}
                  {deliverByFax && <div></div>}
                  <Switch
                    fullRow
                    title="US Mail"
                    name="deliverByMail"
                    value={deliverByMail}
                    handleChange={(e) => {
                      setDeliverByMail(e.target.value);
                      form.setValue('deliverByMail', e.target.value);
                      form.setValue(
                        'methodSelected',
                        e.target.value || deliverByEmail || deliverByFax,
                      );
                    }}
                  />
                </>
              )
            ) : (
              <Typography tag="div" variant="subtext">
                Select a client to get started.
              </Typography>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
}

export default RecordRequestForm;
