import { chipColorMapType, displayContactName } from '@colosseum/data';
import { Chip, SlideoverContext, TooltipWrapper } from '@colosseum/shared-ui';
import { CaseContactConnectionType, ContactType } from '@gladiate/types';
import { startCase } from 'lodash';
import { useContext } from 'react';

export interface CarouselCardProps {
  type: 'policy' | 'vehicle' | 'medicalTreatment';
  id?: string;
  contact?: ContactType & CaseContactConnectionType;
  roleChips?: {
    title: string;
    color: chipColorMapType[keyof chipColorMapType];
  }[];
  valueLines: { title: string; value: string | undefined }[] | undefined;
  children: React.ReactNode;
}

export const getNounForTitle = (type: CarouselCardProps['type']) => {
  switch (type) {
    case 'policy':
      return 'Policy';
    case 'vehicle':
      return 'Vehicle';
    case 'medicalTreatment':
      return 'Medicals';
  }
};

export const CarouselCard = (props: CarouselCardProps) => {
  const { setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const { contact, type, id, roleChips, valueLines, children } = props;
  const contactName = displayContactName(contact) || '-';
  return (
    <div
      onClick={() => {
        type &&
          id &&
          setPendingSlideoverToOpen({
            type,
            id,
            dontScroll: true,
          });
      }}
      className="flex-[0_0_99%] lg:flex-[0_0_50%] xl:flex-[0_0_33%] flex flex-col bg-gray-100 rounded-xl py-4 mr-2.5 h-[280px] justify-start cursor-pointer"
    >
      <div className="pb-2 mx-2 border-b">
        <span className="pb-2 text-sm font-semibold select-none">
          {`${contactName.endsWith('s') ? `${contactName}'` : `${contactName}'s`} ${getNounForTitle(
            type,
          )}`}
        </span>
        <div className="flex flex-col gap-2">
          <div className="flex flex-wrap gap-1">
            {roleChips?.slice(0, 2).map((role) => (
              <Chip key={role.title} name={startCase(role.title)} color={role.color} />
            ))}
            {roleChips?.length ? (
              roleChips?.length > 2 ? (
                <TooltipWrapper
                  message={String(
                    roleChips
                      ?.slice(2)
                      .map((chip) => startCase(chip.title))
                      .join(', '),
                  )}
                >
                  <span>
                    <Chip name={`+${roleChips?.length - 2}`} color="gray" />
                  </span>
                </TooltipWrapper>
              ) : null
            ) : (
              <span>
                <Chip name="No Role" color="gray" />
              </span>
            )}
          </div>
          {children}
        </div>
      </div>
      <table className="flex flex-col mx-2 mt-2 overflow-y-auto gap-x-1 -scroll-mx-2">
        {valueLines
          ?.filter((item) => item.value)
          .map((item) => (
            <tr className="flex" key={item.title}>
              <td className="w-[60%] text-xs typography-gray-upper select-none">{item.title}</td>
              <td className="pb-2 text-xs font-bold text-left select-none">{item.value}</td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default CarouselCard;
