import { prettifyDateString } from '@colosseum/data';
import {
  containsTagsFilterFn,
  renderCell,
  renderHeader,
  renderNumberCellWithHover,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { ItemTagConnectionType, TemplateType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns = (
  onTemplateClick: (arg0: TemplateType) => void,
): ColumnDef<TemplateType & { tags: ItemTagConnectionType[] }>[] => [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    filterFn: 'includesString',
    cell: (props) => renderCell({ props }),
    size: 20,
  },
  {
    accessorFn: (row) => row.fileName,
    id: 'Attachment',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) =>
      props.row.original.fileName ? (
        <div className="flex items-center justify-between p-1 px-2 ml-2 font-semibold text-center rounded-full text-atlantic-blue bg-light-blue min-w-[200px]">
          <p
            className="cursor-pointer hover:underline hover:text-black"
            onClick={(e) => {
              e.stopPropagation();
              onTemplateClick(props.row.original);
            }}
          >
            {props.row?.original.fileName}
          </p>
        </div>
      ) : (
        '-'
      ),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.sign,
    id: 'Sign',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) =>
      renderCell({
        props,
        valueModifierFunc: (value) =>
          String(value).charAt(0).toUpperCase() + String(value).slice(1),
      }),
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date Created',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },

  {
    accessorFn: (row) => row.tags,
    id: 'Tags',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: containsTagsFilterFn<TemplateType & { tags: ItemTagConnectionType[] }>,
    cell: (props) => {
      const tagList = props.getValue<ItemTagConnectionType[]>() ?? [];

      const tagTitles = tagList.map((tag) => tag?.tagAttributes?.title).join(', ');

      return renderNumberCellWithHover({
        value: `${tagList.length || '-'}`,
        tooltipText: tagTitles,
      });
    },
  },
];
