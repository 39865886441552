import { FC } from 'react';

interface FileResourceItemSkeletonProps {
  isListView?: boolean;
}

export const FileResourceItemSkeleton: FC<FileResourceItemSkeletonProps> = (props) => {
  const { isListView } = props;

  if (isListView) {
    return (
      <div className="flex items-center w-full py-4">
        <div className="w-4 h-4 mr-2 bg-gray-200 rounded"></div>
        <div className="w-1/2 h-4 bg-gray-200 rounded"></div>
      </div>
    );
  }

  return (
    <div className="p-4 animate-pulse">
      <div className="w-12 h-12 mx-auto mb-2 bg-gray-200 rounded"></div>
      <div className="h-4 bg-gray-200 rounded"></div>
    </div>
  );
};
