import { PAGES_MAP } from '@gladiate/types';
import { getGlobalIcon } from '../../utils/iconUtils';

export const menuNavigation: {
  name: string;
  href: string;
  icon: any;
  current: boolean;
  subMenu?: { name: string; id?: string }[];
  access?: string[];
  featureAccess?: string[];
  pageMapKey: keyof typeof PAGES_MAP;
}[] = [
  {
    name: 'Dashboard',
    href: '/home',
    icon: getGlobalIcon('dashboard'),
    current: false,
    pageMapKey: 'sectionDashboard',
  },
  {
    name: 'Insights',
    href: '/insights',
    icon: getGlobalIcon('insights'),
    current: false,
    pageMapKey: 'sectionInsights',
  },
  {
    name: 'Leads',
    href: '/leads',
    icon: getGlobalIcon('lead'),
    current: false,
    pageMapKey: 'sectionLeads',
  },
  {
    name: 'Cases',
    href: '/cases',
    icon: getGlobalIcon('case'),
    current: false,
    subMenu: [],
    pageMapKey: 'sectionCases',
  },
  {
    name: 'Contacts',
    href: '/contacts',
    icon: getGlobalIcon('contacts'),
    current: false,
    subMenu: [],
    pageMapKey: 'sectionContacts',
  },
  {
    name: 'Line ups',
    href: '/lineups',
    icon: getGlobalIcon('lineups'),
    current: false,
    subMenu: [],
    pageMapKey: 'sectionLineUps',
  },
  {
    name: 'Campaigns',
    href: '/campaigns',
    icon: getGlobalIcon('campaigns'),
    current: false,
    subMenu: [],
    pageMapKey: 'sectionCampaigns',
  },
  {
    name: 'Templates',
    href: '/templates',
    icon: getGlobalIcon('template'),
    current: false,
    pageMapKey: 'sectionTemplates',
  },
  {
    name: 'Automations',
    href: '/automations',
    icon: getGlobalIcon('automations'),
    current: false,
    access: ['admin'],
    pageMapKey: 'sectionAutomations',
  },
  {
    name: 'Subscriptions',
    href: '/subscriptions',
    icon: getGlobalIcon('subscriptions'),
    current: false,
    access: ['admin'],
    pageMapKey: 'sectionSubscriptions',
  },
  {
    name: 'Settings',
    href: '/settings',
    icon: getGlobalIcon('settings'),
    current: false,
    access: ['admin'],
    pageMapKey: 'sectionSettings',
  },
];
