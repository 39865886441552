import { useGetSelectedCases } from '@colosseum/data';
import { CaseConflictType } from '@gladiate/types';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

export const iconLabel = 'exclamation triangle icon';
export const conflictMessage =
  'There is a similar contact on the following case(s) that could result in a potential conflict:';

/* eslint-disable-next-line */
export interface CaseConflictIconProps {
  caseConflicts: CaseConflictType[];
}

export function CaseConflictIcon(props: CaseConflictIconProps) {
  const { caseConflicts } = props;
  const { data: conflictingCasesData } = useGetSelectedCases(
    caseConflicts?.map((conflict) => conflict.otherCaseId),
  );
  const conflictingCases = conflictingCasesData?.data ?? [];

  if (caseConflicts.length === 0) {
    return null;
  }

  return (
    <TooltipWrapper
      message={
        <div className="text-black">
          {conflictMessage}
          <div className="p-2">
            {conflictingCases.map((conflict) => (
              <a
                key={conflict.caseId}
                target="_blank"
                rel="noreferrer"
                href={`/cases/${conflict.caseId}`}
                className="mt-2 ml-6 text-blue-500 list-item"
              >
                {conflict.caseTitle ?? 'No title'}
              </a>
            ))}
          </div>
        </div>
      }
    >
      <ExclamationTriangleIcon
        aria-label="exclamation triangle icon"
        className="w-5 h-5 ml-2 text-yellow-500"
      />
    </TooltipWrapper>
  );
}

export default CaseConflictIcon;
