import {
  cn,
  getS3FileUploadLink,
  useCreateArctrievalEntity,
  useGetArctrievalEntities,
  useGetContact,
  useUpdateContact,
  useUploadDocumentToS3,
} from '@colosseum/data';
import {
  CalendarFormInput,
  DragDropFile,
  Form,
  TextFormInput,
  TooltipWrapper,
} from '@colosseum/shared-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FileObject } from '../MedicalRecordRequests/MedicalRecordRequests';

export interface ArcClientAuthorizationFormProps {
  clientId: string;
}

const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  dateOfBirth: z.string(),
});

export function ArcClientAuthorizationForm(props: ArcClientAuthorizationFormProps) {
  const { clientId } = props;
  // #region Hooks
  const queryClient = useQueryClient();
  // #endregion

  // #region Data Fetching

  const uploadToS3Mutation = useUploadDocumentToS3();
  const arcFirmUsersQuery = useGetArctrievalEntities();
  const { data: clientContactData } = useGetContact(clientId);
  const clientContactInfo = clientContactData?.data;
  // #endregion

  // #region State
  const [fileObject, setFileObject] = useState<FileObject | null>(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  // #endregion

  // #region Derived State

  // #endregion

  // #region useEffects

  // #endregion

  // #region Event Handlers

  // #endregion

  const arcFirmUsersData = arcFirmUsersQuery?.data?.data.filter(
    (user) => user.accountType === 'user', // make sure we only check for users here
  );
  const arcFirmAllowed = arcFirmUsersData && arcFirmUsersData.length > 0;

  const updateContact = useUpdateContact();
  const createArctrievalEntity = useCreateArctrievalEntity();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      firstName: clientContactInfo?.firstName || '',
      lastName: clientContactInfo?.lastName || '',
      email: clientContactInfo?.contactEmails?.[0]?.emailAddress || '',
      dateOfBirth: clientContactInfo?.dateOfBirth || '',
    },
  });

  function uploadFile(file: any) {
    setUploadLoading(true);
    const fileExtension = file.name.split('.').pop();

    getS3FileUploadLink(fileExtension).then((linkRes) => {
      const uploadUrl = linkRes.data?.url;

      uploadToS3Mutation
        .mutateAsync({
          url: uploadUrl,
          rawFile: file,
        })
        .then(() => {
          setFileObject({
            uploadUrl: uploadUrl,
            objKey: linkRes.data?.objKey,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          });
          setUploadLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar('Error uploading file', {
            variant: 'error',
          });
          setUploadLoading(false);
        });
    });
  }

  function deleteFile() {
    setFileObject(null);
  }

  function handleFormSubmit(values: {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    arctrievalIntakeForm: string;
  }) {
    createArctrievalEntity
      .mutateAsync({
        accountType: 'client',
        gladiateId: clientId,
        arctrievalData: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          dateOfBirth: values.dateOfBirth,
          arctrievalIntakeForm: values?.arctrievalIntakeForm ?? '',
        },
      })
      .then((res) => {
        queryClient.invalidateQueries({
          queryKey: ['arcClient'],
        });
      });
  }

  const updateClientDisabled =
    form.getValues('firstName') === '' ||
    form.getValues('lastName') === '' ||
    form.getValues('dateOfBirth') === '' ||
    form.getValues('email') === '' ||
    fileObject === null ||
    fileObject === undefined ||
    fileObject?.objKey === '';

  return (
    <div>
      <p className="p-2 pb-5 text-gray-700">
        Enable medical record and medical bill requests within Gladiate by uploading the required
        client authorization.
      </p>
      <Form {...form}>
        <form>
          <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
            <TextFormInput {...form.register(`firstName`)} title="First Name" />
            <TextFormInput {...form.register(`lastName`)} title="Last Name" />
            <CalendarFormInput
              {...form.register(`dateOfBirth`)}
              title="Date of Birth"
              hideSyncButton
            />
            <TextFormInput {...form.register(`email`)} title="Email" />
          </div>
        </form>
      </Form>
      <div>
        <DragDropFile
          fileObject={fileObject}
          deleteHandler={deleteFile}
          uploadHandler={uploadFile}
          subheadingCopy="Supports PDF Documents"
          loading={uploadLoading}
          setLoading={setUploadLoading}
          accept=".pdf"
        />
      </div>
      <div className="flex justify-end w-full">
        <TooltipWrapper
          message={
            !arcFirmAllowed
              ? 'Please reach out to Gladiate to get setup with an Arctrieval account'
              : updateClientDisabled
              ? 'Please fill out all fields'
              : ''
          }
        >
          <button
            type="button"
            disabled={arcFirmAllowed === false || updateClientDisabled}
            className={cn(
              'mt-5 rounded-md border border-sky-blue py-1.5 px-12 text-center text-sm  leading-6 text-sky-blue  shadow-sm',
              arcFirmAllowed === false || updateClientDisabled
                ? 'opacity-50 cursor-not-allowed '
                : 'hover:bg-atlantic-blue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-blue',
            )}
            onClick={() => {
              const submitObj = {
                firstName: form.getValues('firstName'),
                lastName: form.getValues('lastName'),
                dateOfBirth: form.getValues('dateOfBirth'),
                email: form.getValues('email'),
                arctrievalIntakeForm: fileObject ? fileObject.objKey : '',
              };

              handleFormSubmit(submitObj);
            }}
          >
            Submit
          </button>
        </TooltipWrapper>
      </div>
    </div>
  );
}

export default ArcClientAuthorizationForm;
