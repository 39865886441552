import { bitBToB, prettifyDateString } from '@colosseum/data';
import {
  BitBoolean,
  CampaignDeliveryMechanism,
  ContactCampaign,
  ContactCampaignTrigger,
  deliveryMechanismOptionsMap,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { renderCell, renderHeader } from '../shadcn/data-table/table-render-helpers';
import Typography from '../Typography/Typography';

const Incomplete = () => (
  <Typography variant="subtext" color="red">
    Incomplete
  </Typography>
);

export const columns: ColumnDef<ContactCampaign & ContactCampaignTrigger>[] = [
  {
    accessorFn: (row) => row.message,
    id: 'Message Preview',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => ({
      isBirthday: row.isBirthday,
      date: row.date,
    }),
    id: 'Campaign Type',
    header: renderHeader,
    cell: (props) => {
      const campaignType = props.getValue() as {
        isBirthday: BitBoolean;
        date: BitBoolean;
      };
      return bitBToB(campaignType.isBirthday) ? 'Contact Birthday' : 'Specific Date';
    },
  },
  {
    accessorFn: (row) => row.deliveryMechanism,
    id: 'Deliver By',
    header: renderHeader,
    cell: (props) => {
      const deliveryMechanism = props.getValue() as CampaignDeliveryMechanism;
      return deliveryMechanismOptionsMap[deliveryMechanism] ?? <Incomplete />;
    },
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date Created',
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
];
