import { chipColorMapType, cn } from '@colosseum/data';
import kebabCase from 'lodash/kebabCase';

/* eslint-disable-next-line */
export interface ChipProps {
  name?: string;
  color?: chipColorMapType[keyof chipColorMapType];
  className?: string;
}

const colorClassMap = {
  red: 'text-red-700 bg-red-50 ring-red-600/20',
  rose: 'text-rose-800 bg-rose-100 ring-rose-600/20',
  yellow: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
  gold: 'text-yellow-600 bg-yellow-100 ring-yellow-600/20',
  green: 'text-green-700 bg-green-50 ring-green-600/20',
  emerald: 'text-emerald-800 bg-emerald-100 ring-emerald-600/20',
  teal: 'text-teal-700 bg-teal-50 ring-teal-600/20',
  blue: 'text-blue-700 bg-blue-50 ring-blue-600/20',
  sky: 'text-sky-800 bg-sky-100 ring-sky-600/20',
  purple: 'text-purple-700 bg-purple-50 ring-purple-600/20',
  violet: 'text-violet-500 bg-violet-100 ring-violet-600/20',
  gray: 'text-gray-800 bg-gray-300 ring-gray-600/20',
  orange: 'text-orange-700 bg-orange-50 ring-orange-600/20',
  stone: 'text-stone-700 bg-stone-100 ring-stone-600/20',
};

export function Chip(props: ChipProps) {
  const { name, color, className } = props;
  const colorClass = colorClassMap[color ?? 'green'];

  return (
    <span
      className={cn(
        'inline-flex items-center px-2 py-1 h-fit text-xs font-medium capitalize rounded-md ring-1 ring-inset whitespace-nowrap cursor-default select-none',
        colorClass,
        className,
      )}
      data-cy={`chip-${kebabCase(name) as string}`}
    >
      {name}
    </span>
  );
}

export default Chip;
