import { useGetAllTimeLogs, useGetCases } from '@colosseum/data';
import {
  CardSection,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { Helmet } from 'react-helmet-async';
import { CycleTimePage } from './CycleTimePage';
import { ProvidersPage } from './ProvidersPage';
import { TeamPage } from './TeamPage';

// TODO move type to somewhere more central
export interface TabItem {
  key: string;
  title: string;
  cypressTag: string;
}

/* eslint-disable-next-line */
export interface InsightsPageProps {}

export function InsightsPage(props: InsightsPageProps) {
  //#region Data Fetching
  const { data: allTimeLogsData, isLoading: isTimeLogsLoading } = useGetAllTimeLogs();
  const { data: casesData, isLoading: isCasesLoading } = useGetCases();
  //#endregion

  //#region Derived State
  const isLoading = isTimeLogsLoading || isCasesLoading;
  const formattedTimeLogs = allTimeLogsData?.data?.map((timeLog) => {
    const caseData = casesData?.data?.find((c) => c.caseId === timeLog.timeLogId.split('-')[0]);
    return {
      ...timeLog,
      caseTitle: caseData ? caseData?.caseTitle ?? '-' : '<Deleted Case>',
    };
  });
  //#endregion

  const TabItems: TabItem[] = [
    {
      key: 'team',
      title: 'Team',
      cypressTag: 'insights-team',
    },
    {
      key: 'cycle-time',
      title: 'Cycle Time',
      cypressTag: 'insights-cycle-time',
    },
    {
      key: 'providers',
      title: 'Providers',
      cypressTag: 'insights-providers',
    },
  ];

  return (
    <>
      <Helmet defer={false}>
        <title>Insights</title>
      </Helmet>

      <CardSection>
        <Tabs defaultValue="team">
          <Typography tag="h1" variant="pageHeading" data-cy="insights">
            Insights
          </Typography>
          <TabsList
            id="insights"
            className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'
          >
            {TabItems.map((tabItem) => (
              <TabsTrigger
                className={tabTriggerClass}
                key={tabItem.key}
                value={tabItem.key}
                data-cy={tabItem.cypressTag}
              >
                {tabItem.title}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent value="team">
            <TeamPage />
          </TabsContent>
          <TabsContent value="cycle-time">
            <CycleTimePage />
          </TabsContent>
          <TabsContent value="providers">
            <ProvidersPage />
          </TabsContent>
        </Tabs>
      </CardSection>
    </>
  );
}

export default InsightsPage;
