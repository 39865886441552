import {
  US_STATES,
  useCreateContactResource,
  useDeleteContactResource,
  useUpdateContactResource,
} from '@colosseum/data';
import { ContactAddressType, ContactViewModelType } from '@gladiate/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionButtonGroup from '../../ActionButtonGroup/ActionButtonGroup';
import CreateButton from '../../CreateButton/CreateButton';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface ClientAddressesFormProps {
  contact: ContactViewModelType;
}

export function ClientAddressesForm(props: ClientAddressesFormProps) {
  const { contact } = props;
  const form = useFormContext();
  const addressForm = useFieldArray({
    control: form.control,
    name: 'contactAddresses', // unique name for your Field Array
  });
  const createContactAddress = useCreateContactResource<ContactAddressType>('addresses');
  const deleteContactAddress = useDeleteContactResource<ContactAddressType>('addresses');
  const updateContactAddress = useUpdateContactResource<ContactAddressType>('addresses');
  const isLoading =
    createContactAddress.isLoading ||
    deleteContactAddress.isLoading ||
    updateContactAddress.isLoading;

  const handleUpdateContactAddress = (name: string, value: string, i: number) => {
    updateContactAddress.mutate({
      resourceId: form.getValues(`contactAddresses.${i}.contactAddressId`),
      [name]: value,
    });
  };

  const renderTextFormInput = (name: string, title: string, i: number) => {
    return (
      <TextFormInput
        {...form.register(`contactAddresses.${i}.${name}`)}
        name={`contactAddresses.${i}.${name}`}
        handleOnBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdateContactAddress(name, target.value, i);
        }}
        title={title}
      />
    );
  };

  return (
    <>
      <div className="divide-y divide-gray-300">
        {addressForm.fields.map(
          (field: Record<'id', string> & { contactAddressId?: string }, i) => (
            <div
              className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4"
              key={field.id}
            >
              {renderTextFormInput('addressLabel', 'Address Label', i)}

              {renderTextFormInput('streetAddress', 'Street Address', i)}
              {renderTextFormInput('streetAddress2', 'Street Address 2', i)}
              {renderTextFormInput('streetAddress3', 'Street Address 3', i)}
              {renderTextFormInput('streetAddress4', 'Street Address 4', i)}

              {renderTextFormInput('city', 'City', i)}

              <SelectFormInput
                title="State"
                {...form.register(`contactAddresses.${i}.state`)}
                listItems={US_STATES}
                defaultValue={contact.contactAddresses[i]?.state}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateContactAddress('state', target.value, i);
                }}
              />

              {renderTextFormInput('zipCode', 'Zip Code', i)}
              {renderTextFormInput('county', 'County', i)}

              {renderTextFormInput('country', 'Country', i)}

              <ActionButtonGroup
                copyText={`${form.getValues(
                  `contactAddresses.${i}.streetAddress`,
                )} ${form.getValues(`contactAddresses.${i}.streetAddress2`)} ${form.getValues(
                  `contactAddresses.${i}.streetAddress3`,
                )} ${form.getValues(`contactAddresses.${i}.streetAddress4`)} ${form.getValues(
                  `contactAddresses.${i}.city`,
                )} ${form.getValues(`contactAddresses.${i}.state`)} ${form.getValues(
                  `contactAddresses.${i}.zipCode`,
                )}`}
                deleteFunction={() => {
                  if (!field.contactAddressId) return;

                  deleteContactAddress.mutate({
                    resourceId: field.contactAddressId,
                  });
                  addressForm.remove(i);
                }}
                dataCyTitle={`address-${i}`}
              />
            </div>
          ),
        )}
      </div>
      <div className="col-span-2">
        <CreateButton
          disabled={isLoading}
          loading={isLoading}
          title="Add Address"
          dataCy="create-address-button"
          onClick={() => {
            createContactAddress.mutateAsync({ contactId: contact?.contactId }).then((res) => {
              addressForm.append({
                contactAddressId: res.data.contactAddressId,
                state: '',
                city: '',
                streetAddress: '',
                zipCode: '',
                county: '',
                country: '',
              });
            });
          }}
        />
      </div>
    </>
  );
}

export default ClientAddressesForm;
