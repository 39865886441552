import {
  CaseCompleteType,
  cn,
  daysBetween,
  enqueueAPISnackbar,
  findCurrentCaseStatusUpdate,
  hexToRGBA,
  useGetCaseStatuses,
} from '@colosseum/data';
import { CaseStatusUpdateType } from '@gladiate/types';
import { Eventcalendar, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface TimelineViewProps {
  cases: CaseCompleteType[];
}

export function TimelineView(props: TimelineViewProps) {
  const navigate = useNavigate();

  const { data: caseStatusesData } = useGetCaseStatuses();
  const colorsMap =
    useMemo(() => {
      return caseStatusesData?.data?.reduce((acc, item) => {
        return {
          ...acc,
          [item?.caseStatusId]: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        };
      }, {} as { [key: string]: string });
    }, caseStatusesData?.data) ?? {};

  const [viewType, setViewType] = useState<'month' | 'week' | 'day' | 'year'>('month');
  const [resolution, setResolution] = useState<'hour' | 'day' | 'week' | 'month' | 'year'>('day');

  const calculateGoalAndTimeElapsedColor = (goal: number, timeElapsed: number) => {
    const percent = timeElapsed / goal;
    if (percent >= 0.8 && percent <= 1) {
      // If 80% of the way to the goal, show as yellow
      return 'text-yellow-500';
    } else if (percent > 1) {
      return 'text-red-600';
    } else {
      return '';
    }
  };

  const view = useMemo<MbscEventcalendarView>(() => {
    if (viewType === 'month' && resolution === 'year') {
      setResolution('month');
    }

    if (viewType === 'week' && (resolution === 'month' || resolution === 'year')) {
      setResolution('week');
    }

    if (
      viewType === 'day' &&
      (resolution === 'month' || resolution === 'year' || resolution === 'week')
    ) {
      setResolution('day');
    }

    return {
      timeline: {
        type: viewType,
        resolution: resolution,
        // eventMargin: '10px',
        rowHeight: 'equal',
        // eventHeight: 'auto',
      },
    };
  }, [viewType, resolution]);

  const myEvents = useMemo(() => {
    const eventsFromCases: {
      start: Date;
      end: Date;
      resource: string;
      title?: string;
      color: string;
      goal?: number;
    }[] = [];
    props.cases
      .filter((item) => item.caseStatusUpdates && item.caseStatusUpdates?.length > 0)
      .forEach((caseItem: CaseCompleteType) => {
        caseItem.caseStatusUpdates?.map((statusItem: CaseStatusUpdateType) => {
          eventsFromCases.push({
            start: dayjs(statusItem?.dateStarted, { utc: true }).toDate(),
            end:
              dayjs(statusItem?.dateEnded, { utc: true }).toDate() ??
              dayjs(null, { utc: true }).toDate(),
            resource: caseItem?.caseId || '',
            title: caseStatusesData?.data?.find
              ? caseStatusesData?.data?.find(
                  (item) => item?.caseStatusId === statusItem?.caseStatusId,
                )?.title
              : '',
            goal: caseItem.caseStatus?.goal,
            color: colorsMap[statusItem?.caseStatusId] ?? '',
          });
        });
      });

    return eventsFromCases;
  }, [props.cases]);

  const myResources = useMemo(() => {
    if (!props.cases) return [];
    return props.cases.map((item: CaseCompleteType) => {
      const id = item?.caseId;
      return {
        id: id,
        name: item.caseTitle || 'No Title',
        caseStatus: item.caseStatus?.title || 'No Status',
        goal: item?.caseStatus?.goal || 'No Goal',
        dateCreated: findCurrentCaseStatusUpdate(item.caseStatusUpdates)?.dateStarted || '',
      };
    });
  }, [props.cases]);

  type ResourceType = (typeof myResources)[0];

  const renderMyResource = (resource: ResourceType) => {
    // find the most recent event for this resource and use it to calculate the days between
    const mostRecentEvent = myEvents.find((event) => event?.resource === resource.id);

    const daysBetweenCurrentStatus = mostRecentEvent
      ? daysBetween(mostRecentEvent.start.toISOString(), mostRecentEvent.end.toISOString())
      : daysBetween(resource.dateCreated, '');

    return (
      <div
        onClick={() => {
          if (typeof resource.id === 'string' && resource.id.includes('case')) {
            enqueueAPISnackbar({
              message: 'This case no longer exists.',
              variant: 'error',
              key: 'case-not-found-error',
            });
            return;
          }

          if (!resource.id) {
            enqueueAPISnackbar({
              message: 'This case no longer exists.',
              variant: 'error',
              key: 'case-not-found-error',
            });
            return;
          }

          navigate(`/cases/${resource.id}`);
        }}
        className="flex flex-col justify-between w-full h-full p-3 cursor-pointer hover:bg-gray-100 rounded-xl "
      >
        <div className="overflow-hidden text-ellipsis ">{resource?.name}</div>
        <div className="flex items-center justify-between ">
          <div className="text-xs mt-1 bg-light-blue text-atlantic-blue rounded-md py-0.5 px-1 w-fit">
            {resource?.caseStatus}
          </div>
          {mostRecentEvent && (
            <div className="mt-1 text-xs text-gray-500">
              {daysBetweenCurrentStatus === -1 ? '' : `${daysBetweenCurrentStatus} days`}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMyEvent = (event: MbscCalendarEvent) => {
    return (
      <div
        style={{
          background: hexToRGBA(event?.color ?? '', 0.2),
        }}
        className="h-24 p-3 ml-2 rounded-lg "
        onClick={() => {
          if (typeof event?.resource === 'string' && event?.resource.includes('case')) {
            enqueueAPISnackbar({
              message: 'This case no longer exists.',
              variant: 'error',
              key: 'case-not-found-error',
            });
            return;
          }

          if (!event?.resource) {
            enqueueAPISnackbar({
              message: 'This case no longer exists.',
              variant: 'error',
              key: 'case-not-found-error',
            });
            return;
          }

          navigate(`/cases/${String(event?.resource)}`);
        }}
      >
        <div className="sticky top-0 left-[200px] z-50 inline-block max-w-full max-h-full overflow-hidden text-sm font-semibold text-black align-top">
          <div className="text-black">{event?.title}</div>
          <div
            className={calculateGoalAndTimeElapsedColor(
              event?.original?.goal,
              daysBetween(event?.original?.start as string, event?.original?.end as string) ?? 0,
            )}
          >{`${
            daysBetween(event?.original?.start as string, event?.original?.end as string) ?? 0
          } days`}</div>
          <div>
            {(event?.original?.goal as string) !== undefined
              ? `Goal: ${event?.original?.goal as string} days`
              : 'No goal set'}
          </div>
        </div>
      </div>
    );
  };

  if (props.cases.length === 0)
    return (
      <div className="p-5">
        <div className="border shadow-lg rounded-2xl">
          <Typography variant="subtext" className="px-2 py-10 text-center">
            No results.
          </Typography>
        </div>
      </div>
    );

  return (
    <div className="p-5 ">
      <div className="border shadow-lg rounded-2xl">
        <div className="flex flex-wrap">
          <div className="px-2 py-3">
            <div className="mb-2">Page Size:</div>
            <span className="inline-flex rounded-md shadow-sm isolate">
              <Button
                variant={viewType === 'day' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setViewType('day');
                }}
                className={cn('relative px-4 py-2 rounded-r-none border-r-0')}
              >
                Day
              </Button>
              <Button
                variant={viewType === 'week' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setViewType('week');
                }}
                className={cn('relative px-4 py-2 rounded-none border-r-0')}
              >
                Week
              </Button>
              <Button
                variant={viewType === 'month' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setViewType('month');
                }}
                className={cn('relative px-4 py-2 rounded-none border-r-0')}
              >
                Month
              </Button>
              <Button
                variant={viewType === 'year' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setViewType('year');
                }}
                className={cn('relative px-4 py-2 rounded-l-none')}
              >
                Year
              </Button>
            </span>
          </div>
          <div className="px-2 py-3">
            <div className="mb-2">Resolution:</div>
            <span className="inline-flex rounded-md shadow-sm isolate">
              <Button
                variant={resolution === 'hour' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setResolution('hour');
                }}
                className={cn('relative px-4 py-2 rounded-r-none border-r-0')}
              >
                Hour
              </Button>
              <Button
                variant={resolution === 'day' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setResolution('day');
                }}
                className={cn('relative px-4 py-2 rounded-none border-r-0')}
              >
                Day
              </Button>
              <Button
                variant={resolution === 'week' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setResolution('week');
                }}
                className={cn('relative px-4 py-2 rounded-none border-r-0')}
                disabled={viewType === 'day'}
              >
                Week
              </Button>
              <Button
                variant={resolution === 'month' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setResolution('month');
                }}
                className={cn('relative px-4 py-2 rounded-none border-r-0')}
                disabled={viewType === 'day' || viewType === 'week'}
              >
                Month
              </Button>
              <Button
                variant={resolution === 'year' ? 'primary' : 'outline'}
                type="button"
                onClick={() => {
                  setResolution('year');
                }}
                className={cn('relative px-4 py-2 rounded-l-none')}
                disabled={viewType === 'day' || viewType === 'week' || viewType === 'month'}
              >
                Year
              </Button>
            </span>
          </div>
        </div>

        <Eventcalendar
          theme="ios"
          themeVariant="light"
          view={view}
          data={myEvents ?? []}
          resources={myResources}
          renderResource={renderMyResource}
          renderScheduleEvent={renderMyEvent as any}
          onCellClick={(event, inst) => {
            if (typeof event.resource === 'string' && event.resource.includes('case')) {
              enqueueAPISnackbar({
                message: 'This case no longer exists.',
                variant: 'error',
                key: 'case-not-found-error',
              });
              return;
            }

            if (!event.resource) {
              enqueueAPISnackbar({
                message: 'This case no longer exists.',
                variant: 'error',
                key: 'case-not-found-error',
              });
              return;
            }

            navigate(`/cases/${event.resource}`);
          }}
        />
      </div>
    </div>
  );
}

export default TimelineView;
