import {
  SlideoverContext,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { useContext, useEffect, useState } from 'react';
import CaseSettlements from '../CaseSettlements/CaseSettlements';
import Liens from '../Liens/Liens';
import { useParams } from 'react-router';

export interface NegotiationSectionProps {
  caseId: string;
}

export function NegotiationSection(props: NegotiationSectionProps) {
  const { caseId } = useParams<{ caseId: string }>();
  const [activeTab, setActiveTab] = useState('settlement');
  const { pendingSlideoverToOpen } = useContext(SlideoverContext);

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'settlementProposal') {
      setActiveTab('settlement');
    } else if (pendingSlideoverToOpen?.type === 'lien') {
      setActiveTab('lien');
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger className={tabTriggerClass} value="settlement">
          Settlement
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} value="lien">
          Liens
        </TabsTrigger>
      </TabsList>
      <TabsContent value="settlement">
        <CaseSettlements caseId={caseId ?? ''} />
      </TabsContent>
      <TabsContent value="lien">
        <Liens caseId={caseId ?? ''} />
      </TabsContent>
    </Tabs>
  );
}

export default NegotiationSection;
