import {
  useCreateTeammate,
  useDeleteTeammate,
  useGetStaffRoles,
  useGetTeams,
  useUpdateTeam,
} from '@colosseum/data';
import {
  AssigneeChip,
  FirmUserDropdownMenu,
  GladiateLoader,
  TextFormInput,
  Typography,
} from '@colosseum/shared-ui';
import { TeamType } from '@gladiate/types';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface TeamManagementFormProps {
  teamId?: string;
  selectedTeam: TeamType;
}

export const TeamManagementForm = (props: TeamManagementFormProps) => {
  const { selectedTeam } = props;
  const form = useFormContext();
  const updateTeam = useUpdateTeam();
  const [deletingTeammateId, setDeletingTeammateId] = useState<string | undefined>(undefined);
  // this is used to only show loader on the last action
  const [lastAction, setLastAction] = useState<{
    action: 'create' | 'delete';
    roleId?: string;
  }>({ action: 'create', roleId: '' });

  const { data: staffRolesData, isLoading: isStaffRolesLoading } = useGetStaffRoles();

  const { isFetching: isFetchingTeams } = useGetTeams();
  const createTeammate = useCreateTeammate();
  const deleteTeammate = useDeleteTeammate();

  const handleOnBlur = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    updateTeam.mutate({
      teamId: selectedTeam.teamId,
      [target.name]: target.value,
    });
  };

  return (
    <div className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
      <div className="col-span-2">
        <TextFormInput {...form.register(`title`)} title="Title" handleOnBlur={handleOnBlur} />
      </div>
      <div className="col-span-2">
        <TextFormInput
          {...form.register(`description`)}
          title="Description"
          handleOnBlur={handleOnBlur}
          type="textarea"
        />
      </div>
      {isStaffRolesLoading && (
        <div className="flex flex-col justify-center h-60">
          <GladiateLoader height={50} className="text-atlantic-blue" />
        </div>
      )}
      {!isStaffRolesLoading && <Typography variant="heading">Staff Roles</Typography>}
      {staffRolesData?.data?.map((staffRole) => (
        <div key={staffRole.roleId} className="flex flex-col col-span-2 gap-y-2">
          <Typography>{staffRole.title}</Typography>
          <div className="flex flex-wrap items-center gap-2">
            {selectedTeam.teammates
              ?.filter((teammate) => teammate.roleId === staffRole.roleId)
              ?.map((teammate) => (
                <AssigneeChip
                  key={teammate.username}
                  assigneeId={teammate.username ?? ''}
                  handleDelete={() => {
                    setDeletingTeammateId(teammate.teammateId);
                    setLastAction({
                      action: 'delete',
                      roleId: staffRole.roleId,
                    });
                    deleteTeammate.mutateAsync(teammate.teammateId).then(() => {
                      setDeletingTeammateId(undefined);
                    });
                  }}
                  isDeleting={
                    (deleteTeammate.isLoading ||
                      (isFetchingTeams && lastAction.action === 'delete')) &&
                    deletingTeammateId === teammate.teammateId
                  }
                />
              ))}
            {createTeammate.isLoading ||
              (lastAction.action === 'create' &&
                lastAction.roleId === staffRole.roleId &&
                isFetchingTeams && <GladiateLoader height={16} />)}
            <FirmUserDropdownMenu
              handleChange={(value) => {
                setLastAction({ action: 'create', roleId: staffRole.roleId });
                createTeammate.mutate({
                  teamId: selectedTeam.teamId,
                  username: value,
                  roleId: staffRole.roleId,
                });
              }}
              selectedUsers={
                (selectedTeam?.teammates
                  ?.filter((teammate) => teammate.username && teammate.roleId === staffRole.roleId)
                  ?.map((teammate) => teammate.username) as string[]) ?? []
              }
              data-cy="custom-attribute-user-select"
              hideLabel
              triggerClassName="w-5 h-5 p-0 focus:ring-0"
              title={
                <div className="flex 'inline-flex items-center justify-center flex-shrink-0 text-gray-400 bg-white border-2 border-gray-200 border-dashed rounded-full w-7 h-7 hover:border-gray-300 hover:text-gray-500 focus:outline-none ">
                  <PlusIcon className="w-5 h-5" aria-hidden="true" />
                </div>
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};
