export const contactConnectionOptions = {
  branch: 'branch',
  child: 'child',
  connection: 'connection',
  courtWard: 'courtWard',
  directManager: 'directManager',
  directReport: 'directReport',
  emergencyContact: 'emergencyContact',
  emergencyContactDependant: 'emergencyContactDependant',
  employee: 'employee',
  employer: 'employer',
  family: 'family',
  friend: 'friend',
  guardian: 'guardian',
  guardianAdLitem: 'guardianAdLitem',
  headquarters: 'headquarters',
  healthClub: 'healthClub',
  healthClubMember: 'healthClubMember',
  parent: 'parent',
  primaryContact: 'primaryContact',
  primaryContactDependant: 'primaryContactDependant',
  spouse: 'spouse',
  union: 'union',
  unionMember: 'unionMember',
  ward: 'ward',
} as const; // CONTACT1 is a ____ of CONTACT2

export const contactConnectionOptionsReverse = {
  connection: 'connection',

  directManager: 'directReport',
  directReport: 'directManager',

  emergencyContact: 'emergencyContactDependant',
  emergencyContactDependant: 'emergencyContact',

  employee: 'employer',
  employer: 'employee',

  family: 'family',

  friend: 'friend',

  guardian: 'ward',
  ward: 'guardian',

  guardianAdLitem: 'courtWard',
  courtWard: 'guardianAdLitem',

  headquarters: 'branch',
  branch: 'headquarters',

  parent: 'child',
  child: 'parent',

  healthClub: 'healthClubMember',
  healthClubMember: 'healthClub',

  primaryContact: 'primaryContactDependant',
  primaryContactDependant: 'primaryContact',

  spouse: 'spouse',

  union: 'unionMember',
  unionMember: 'union',
} as const;

export type CaseContactConnectionRoles = {
  [key in keyof typeof caseContactConnectionOptions]: key;
};

export const caseContactConnectionOptions = {
  additionalContact: 'additionalContact',
  adjuster: 'adjuster',
  ambulance: 'ambulance',
  attendee: 'attendee',
  attorney: 'attorney',
  billingEntity: 'billingEntity',
  bodilyInjuryAdjuster: 'bodilyInjuryAdjuster',
  client: 'client',
  coCounsel: 'coCounsel',
  decedent: 'decedent',
  defendant: 'defendant',
  defenseCounsel: 'defenseCounsel',
  driver: 'driver',
  expert: 'expert',
  firstResponder: 'firstResponder',
  hospital: 'hospital',
  insuranceEntity: 'insuranceEntity',
  insuranceRepresentative: 'insuranceRepresentative',
  insurer: 'insurer',
  judge: 'judge',
  judgeAssistant: 'judgeAssistant',
  lawFirm: 'lawFirm',
  lienHolder: 'lienHolder',
  lienProvider: 'lienProvider',
  mediator: 'mediator',
  medicalProvider: 'medicalProvider',
  medPayAdjuster: 'medPayAdjuster',
  owner: 'owner',
  party: 'party',
  passenger: 'passenger',
  payee: 'payee',
  payor: 'payor',
  pipAdjuster: 'pipAdjuster',
  plaintiff: 'plaintiff',
  policyHolder: 'policyHolder',
  primaryContact: 'primaryContact',
  propertyDamageAdjuster: 'propertyDamageAdjuster',
  recordsProvider: 'recordsProvider',
  referralFromContact: 'referralSource',
  referToAttorney: 'referToAttorney',
  referToFirm: 'referToFirm',
  rentalCarCompany: 'rentalCarCompany',
  repairShop: 'repairShop',
  representative: 'representative',
  servingDiscovery: 'servingDiscovery',
  subrogationCompany: 'subrogationCompany',
  subrogationRepresentative: 'subrogationRepresentative',
  thirdParty: 'thirdParty',
  towCompany: 'towCompany',
  umUimAdjuster: 'umUimAdjuster',
  vendor: 'vendor',
  witness: 'witness',
} as const;

export const partiesAndPoliciesConnectionOptions = {
  party: 'party',
  client: 'client',
  defendant: 'defendant',
  driver: 'driver',
  passenger: 'passenger',
  owner: 'owner',
  policyHolder: 'policyHolder',
} as const;
