import {
  useCreateCustomAttributeValue,
  useDeleteCustomAttributeValue,
  useGetContactsInfinite,
  useGetCustomAttributeValues,
  useGetCustomAttributes,
  useUpdateCustomAttributeValue,
} from '@colosseum/data';
import { CustomAttributeType, CustomAttributeValueType } from '@gladiate/types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import AssigneeChip from '../AssigneeChip/AssigneeChip';
import FirmUserDropdownMenu from '../FirmUserDropdownMenu/FirmUserDropdownMenu';
import Toggle from '../Toggle/Toggle';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import MultiSelectFormInput from '../forms/MultiSelectFormInput/MultiSelectFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn/Select/Select';
import CustomAtributeCaseSelect from './CustomAttributeCaseSelect';
import CustomAtributeContactSelect from './CustomAttributeContactSelect';

/* eslint-disable-next-line */
export interface CustomAtributeValuesFormProps {
  objectId: string;
  attributeType: 'case' | 'contact';
}

export function CustomAtributeValuesForm(props: CustomAtributeValuesFormProps) {
  const { objectId, attributeType } = props;
  const { data } = useGetCustomAttributeValues(objectId);
  const createCustomAttributeValue = useCreateCustomAttributeValue();
  const updateCustomAttributeValue = useUpdateCustomAttributeValue();
  const deleteCustomAttributeValue = useDeleteCustomAttributeValue();
  const { data: customAttributes } = useGetCustomAttributes();
  const { data: allContacts } = useGetContactsInfinite();
  const [customAttributeValueIdToDelete, setCustomAttributeValueIdToDelete] = useState<
    { id: string; i: number } | undefined
  >();
  const filteredCustomAttributes =
    customAttributes?.data.filter(
      (attribute) => attribute.attributeResource === attributeType && attribute.attributeLabel,
    ) ?? [];
  const customAttributeValuesWithTitles = data?.data
    .map((customAttributeValue) => ({
      ...filteredCustomAttributes?.find(
        (attribute) => attribute.customAttributeId === customAttributeValue.customAttributeId,
      ),
      ...customAttributeValue,
    }))
    .sort((a, b) => (a?.dateCreated > b?.dateCreated ? 1 : -1));

  const form = useForm({
    mode: 'onBlur',
    values: useMemo(() => {
      return { attributes: customAttributeValuesWithTitles ?? undefined };
    }, [customAttributeValuesWithTitles]),
  });

  const attributesForm = useFieldArray({
    control: form.control,
    name: 'attributes',
  });

  const handleCreate = (value: CustomAttributeType['customAttributeId']) => {
    createCustomAttributeValue.mutateAsync({ objectId, customAttributeId: value }).then((res) => {
      attributesForm.append({
        ...res.data.customAttribute,
        ...res.data.customAttributeValue,
      });
    });
  };

  const handleChange = (e: React.SyntheticEvent, customAttributeValueId: string) => {
    const target = e.target as HTMLInputElement;
    updateCustomAttributeValue.mutate({
      customAttributeValueId,
      attributeSingleValue: target.value,
    });
  };

  const renderCustomAttributeValue = (
    customAttributeValue: CustomAttributeValueType & CustomAttributeType,
    i: number,
  ) => {
    if (!customAttributeValue) return;
    switch (customAttributeValue.attributeType) {
      case 'boolean':
        return (
          <div className="flex items-center h-8 p-1 text-sm font-medium gap-x-2">
            <Toggle
              newState={customAttributeValue?.attributeSingleValue === '1'}
              editable
              onToggleChange={(value) => {
                updateCustomAttributeValue.mutate({
                  customAttributeValueId: customAttributeValue.customAttributeValueId,
                  attributeSingleValue: value ? '1' : '0',
                });
              }}
            />
            <span>{customAttributeValue.attributeLabel}</span>
          </div>
        );
      case 'caseSelect':
        return (
          <CustomAtributeCaseSelect
            updateValue={(data) => {
              updateCustomAttributeValue.mutate({
                customAttributeValueId: customAttributeValue.customAttributeValueId,
                attributeMultiValue: [...(customAttributeValue?.attributeMultiValue ?? []), data],
              });
            }}
            deleteValue={(caseId: string) => {
              const removedValueArray = customAttributeValue?.attributeMultiValue?.filter(
                (item) => item !== caseId,
              );
              updateCustomAttributeValue.mutate({
                customAttributeValueId: customAttributeValue.customAttributeValueId,
                attributeMultiValue: removedValueArray,
              });
            }}
            customAttributeValue={customAttributeValue}
            data-cy="custom-attribute-case-select"
          />
        );
      case 'contactSelect':
        return (
          <CustomAtributeContactSelect
            customAttributeValue={customAttributeValue}
            contacts={allContacts?.data ?? []}
            data-cy="custom-attribute-contact-select"
          />
        );
      case 'userSelect':
        return (
          <div className="flex flex-col">
            <div className="mb-4">{customAttributeValue.attributeLabel}</div>
            <div className="flex flex-wrap items-center gap-x-2">
              {customAttributeValue?.attributeMultiValue?.map((item) => (
                <AssigneeChip
                  key={item}
                  assigneeId={item}
                  handleDelete={() => {
                    const removedValueArray = customAttributeValue?.attributeMultiValue?.filter(
                      (attr) => item !== attr,
                    );
                    updateCustomAttributeValue.mutate({
                      customAttributeValueId: customAttributeValue.customAttributeValueId,
                      attributeMultiValue: removedValueArray,
                    });
                  }}
                />
              ))}

              <FirmUserDropdownMenu
                handleChange={(value) => {
                  updateCustomAttributeValue.mutate({
                    customAttributeValueId: customAttributeValue.customAttributeValueId,
                    attributeMultiValue: [
                      ...(customAttributeValue.attributeMultiValue ?? []),
                      value,
                    ],
                  });
                }}
                selectedUsers={customAttributeValue?.attributeMultiValue ?? []}
                data-cy="custom-attribute-user-select"
              />
            </div>
          </div>
        );
      case 'dropdown':
        return (
          <SelectFormInput
            {...form.register(`attributes.${i}.attributeSingleValue`)}
            title={customAttributeValue.attributeLabel ?? ''}
            defaultValue={customAttributeValue.attributeSingleValue}
            placeholderText="Select an option"
            listItems={
              customAttributeValue.customAttributeOptions?.reduce(
                (acc, customAttributeOption) => ({
                  ...acc,
                  [customAttributeOption.customOptionValue]:
                    customAttributeOption.customAttributeOptionId,
                }),
                {},
              ) ?? ({} as { [key: string]: string })
            }
            listItemsIsObject
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateCustomAttributeValue.mutate({
                customAttributeValueId: customAttributeValue.customAttributeValueId,
                attributeSingleValue: e.target.value,
              });
            }}
            data-cy="custom-attribute-dropdown"
          />
        );
      case 'multiSelect':
        return (
          <div className="flex items-center gap-x-2">
            <MultiSelectFormInput
              title={customAttributeValue.attributeLabel ?? ''}
              options={
                customAttributeValue.customAttributeOptions?.map((customAttributeOption) => ({
                  title: customAttributeOption.customOptionValue,
                  value: customAttributeOption.customAttributeOptionId,
                })) ?? []
              }
              optionsIsObject
              selectedOptions={customAttributeValue.attributeMultiValue ?? []}
              onChange={(option: string) => {
                let newOptions = customAttributeValue.attributeMultiValue ?? [];
                if (customAttributeValue.attributeMultiValue?.includes(option)) {
                  newOptions = newOptions.filter((item) => item !== option);
                } else {
                  newOptions.push(option);
                }
                updateCustomAttributeValue.mutate({
                  customAttributeValueId: customAttributeValue.customAttributeValueId,
                  attributeMultiValue: newOptions,
                });
              }}
              data-cy="custom-attribute-multi-select"
            />
          </div>
        );
      case 'date':
        return (
          <CalendarFormInput
            {...form.register(`attributes.${i}.attributeSingleValue`)}
            handleOnChange={(e: React.SyntheticEvent) =>
              handleChange(e, customAttributeValue.customAttributeValueId)
            }
            title={customAttributeValue.attributeLabel ?? ''}
            resourceTypeObj={{
              type: attributeType,
              id: objectId,
            }}
          />
        );
      case 'text':
      case 'currency':
      case 'email':
      case 'number':
      case 'phone':
      case 'textarea':
      case 'url':
      default:
        return (
          <TextFormInput
            {...form.register(`attributes.${i}.attributeSingleValue`)}
            key={customAttributeValue.customAttributeValueId}
            title={customAttributeValue.attributeLabel ?? ''}
            handleOnBlur={(e: React.SyntheticEvent) =>
              handleChange(e, customAttributeValue.customAttributeValueId)
            }
            type={customAttributeValue.attributeType === 'textarea' ? 'textarea' : undefined}
          />
        );
    }
  };

  return (
    <div>
      {attributesForm.fields.length > 0 ? (
        <Form {...form}>
          <form className="flex flex-col py-6 gap-y-6">
            {attributesForm.fields.map((item, i) => (
              <div className="flex items-center w-full gap-x-2 " key={item.customAttributeValueId}>
                <span className="grow">{renderCustomAttributeValue(item, i)}</span>
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    setCustomAttributeValueIdToDelete({
                      id: item.customAttributeValueId,
                      i,
                    });
                  }}
                >
                  <TrashIcon className="w-5 h-5" />
                </Button>
              </div>
            ))}
          </form>
        </Form>
      ) : (
        <div>
          {filteredCustomAttributes.length ? (
            <div className="py-4 text-gray-500">
              No custom attributes found. Click the button below to add one.
            </div>
          ) : (
            <div className="py-4">
              No custom attributes exists.{' '}
              <Link className="underline text-atlantic-blue" to="/settings">
                Click here to start creating.
              </Link>
            </div>
          )}
        </div>
      )}
      {!!filteredCustomAttributes.length && (
        <Select
          data-cy="custom-attribute-dropdown"
          name="firm-dropdown"
          value=""
          onValueChange={handleCreate}
        >
          <SelectTrigger
            data-cy="custom-attribute-dropdown-button"
            className="w-auto text-white hover:bg-atlantic-blue bg-sky-blue"
          >
            <SelectValue>Add Attribute</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {filteredCustomAttributes.map((customAttribute) => (
                <SelectItem
                  key={customAttribute.customAttributeId}
                  value={customAttribute.customAttributeId ?? ''}
                  noIndicator
                >
                  {customAttribute.attributeLabel}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
      <ActionConfirmModal
        open={!!customAttributeValueIdToDelete}
        setOpen={(open: boolean) => {
          if (!open) setCustomAttributeValueIdToDelete(undefined);
        }}
        actionFunction={() => {
          if (customAttributeValueIdToDelete) {
            deleteCustomAttributeValue.mutateAsync(customAttributeValueIdToDelete.id).then(() => {
              attributesForm.remove(customAttributeValueIdToDelete.i);
              setCustomAttributeValueIdToDelete(undefined);
            });
          }
        }}
        title="Delete Custom Attribute Value"
      />
    </div>
  );
}

export default CustomAtributeValuesForm;
