import {
  useCreateContactResource,
  useDeleteContactResource,
  useUpdateContactResource,
} from '@colosseum/data';
import { ContactViewModelType, ContactWebAddressType } from '@gladiate/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionButtonGroup from '../../ActionButtonGroup/ActionButtonGroup';
import CreateButton from '../../CreateButton/CreateButton';
import TextFormInput from '../TextFormInput/TextFormInput';

/* eslint-disable-next-line */
export interface ContactWebAddressesProps {
  contact: ContactViewModelType;
}

export function ContactWebAddresses(props: ContactWebAddressesProps) {
  const { contact } = props;
  const form = useFormContext();
  const webAddressForm = useFieldArray({
    control: form.control,
    name: 'contactWebAddresses', // unique name for your Field Array
  });
  const createContactWebAddress = useCreateContactResource<ContactWebAddressType>('web-addresses');
  const deleteContactWebAddress = useDeleteContactResource<ContactWebAddressType>('web-addresses');
  const updateContactWebAddress = useUpdateContactResource<ContactWebAddressType>('web-addresses');
  const isLoading =
    createContactWebAddress.isLoading ||
    deleteContactWebAddress.isLoading ||
    updateContactWebAddress.isLoading;

  const handleUpdateContactWebAddress = (name: string, value: string, i: number) => {
    form.setValue(`contactWebAddresses.${i}.${name}`, value);
    updateContactWebAddress.mutate({
      resourceId: form.getValues(`contactWebAddresses.${i}.contactWebAddressId`),
      [name]: value,
    });
  };

  const renderTextFormInput = (name: string, title: string, i: number) => {
    return (
      <TextFormInput
        {...form.register(`contactWebAddresses.${i}.${name}`)}
        name={`contactWebAddresses.${i}.${name}`}
        handleOnBlur={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          handleUpdateContactWebAddress(name, target.value, i);
        }}
        title={title}
      />
    );
  };
  return (
    <>
      <div className="divide-y divide-gray-300">
        {webAddressForm.fields.map(
          (field: Record<'id', string> & { contactWebAddressId?: string }, i) => (
            <div
              className="grid grid-cols-2 py-5 gap-x-3 gap-y-5 first:pt-4 last:pb-4"
              key={field.id}
            >
              {renderTextFormInput('webAddressLabel', 'Web Address Label', i)}

              {renderTextFormInput('webAddress', 'Web Address', i)}

              <ActionButtonGroup
                copyText={contact.contactWebAddresses[i]?.webAddress}
                url={contact.contactWebAddresses[i]?.webAddress}
                deleteFunction={() => {
                  if (!field.contactWebAddressId) return;

                  deleteContactWebAddress.mutate({
                    resourceId: field.contactWebAddressId,
                  });
                  webAddressForm.remove(i);
                }}
                showLink
              />
            </div>
          ),
        )}
      </div>
      <div className="col-span-2">
        <CreateButton
          disabled={isLoading}
          loading={isLoading}
          title="Add Web Address"
          dataCy="create-web-address-button"
          onClick={() => {
            createContactWebAddress.mutateAsync({ contactId: contact?.contactId }).then((res) => {
              webAddressForm.append({
                contactWebAddressId: res.data.contactWebAddressId,
                webAddress: res.data.webAddress,
              });
            });
          }}
        />
      </div>
    </>
  );
}

export default ContactWebAddresses;
