/* eslint-disable @typescript-eslint/no-empty-function */
import {
  CaseContactConnectionType,
  CaseContactConnectionViewModelType,
  CaseContactSubObject,
  DefaultV3Response,
} from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import { getFirmId } from '../../utils/authUtils';
import { baseFirmContactsRoute, conscriptionRoute } from '../apiRoutes';
import { axiosInstance } from '../https';
import {
  createCaseContactConnectionPolicyV3,
  createCaseContactConnectionRoleV3,
  createCaseContactConnectionV3,
  deleteCaseContactConnectionRoleV3,
  deleteCaseContactConnectionV3,
  getAllCaseConflictsForCaseV3,
  getAllCaseConflictsForFirmV3,
  getCaseContactConnectionPoliciesV3,
  getCaseContactConnectionsByResourceV3,
  updateCaseContactConnectionRoleV3,
  updateCaseContactConnectionV3,
} from '../requests/conscription';
import { getCaseContactsV3 } from '../requests/sibyls';

const firmId = getFirmId();

interface CaseContactConnectionsRes {
  caseContactConnections: CaseContactConnectionType[];
  status: 'SUCCESS' | 'NOT_FOUND';
}

export type conscriptionResourceTypes = 'cases';
export type connectionTypes = 'contact' | 'case';

export const useGetCaseContactConnections = (
  resourceId: Parameters<typeof getCaseContactConnectionsByResourceV3>[0],
  resourceType: Parameters<typeof getCaseContactConnectionsByResourceV3>[1],
) => {
  return useQuery({
    queryKey: [queryKeys.caseContactConnections, resourceId, resourceType],
    queryFn: async () =>
      getCaseContactConnectionsByResourceV3<CaseContactConnectionType[]>(resourceId, resourceType),
    enabled: !!resourceId,
  });
};

export const useGetCaseContactConnectionsViewModel = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseContactConnections, 'viewModel', caseId, 'case'],
    queryFn: async () => getCaseContactsV3(caseId),
    enabled: !!caseId,
  });
};

export const useUpdateCaseContactConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseContactConnectionV3>[0]) =>
      updateCaseContactConnectionV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
    },
  });
};

// TODO: add optimistic updates to this
/**
 * @description This hook is used to create a case contact connection - If there is no contactId provided it will create a contact first then create the connection
 */
export const useCreateCaseContactConnection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      contactId?: string;
      roleOnCase?: string;
      caseId: string;
      itemType?: CaseContactSubObject;
      itemId?: string;
    }) => {
      const { contactId, roleOnCase, caseId, itemType, itemId } = data;

      if (!contactId) {
        // if no contact create a contact then casecontactconnection
        return axiosInstance
          .post<DefaultV3Response<{ contactId: string }>>(baseFirmContactsRoute)
          .then((res) => {
            return createCaseContactConnectionV3({
              contactId: res.data.data.contactId,
              roleOnCase,
              caseId,
            }).then((connectionRes) => {
              return {
                ...connectionRes,
                data: {
                  ...connectionRes.data,
                  contactId: res?.data?.data?.contactId,
                },
              };
            });
          });
      } else {
        // just create casecontactconnection
        return createCaseContactConnectionV3({
          contactId,
          roleOnCase,
          caseId,
          itemType,
          itemId,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
      queryClient.invalidateQueries({
        queryKey: ['policy'],
      });
    },
  });
};

export const useDeleteCaseContactConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (caseContactConnectionId: Parameters<typeof deleteCaseContactConnectionV3>[0]) =>
      deleteCaseContactConnectionV3(caseContactConnectionId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

export const useCreateCaseContactConnectionRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseContactConnectionRoleV3>[0]) =>
      createCaseContactConnectionRoleV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
    },
  });
};
export const useDeleteCaseContactConnectionRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      caseContactConnectionRoleId: Parameters<typeof deleteCaseContactConnectionRoleV3>[0],
    ) => deleteCaseContactConnectionRoleV3(caseContactConnectionRoleId),
    onMutate: (caseContactConnectionRoleId) => {
      // Update the cache optimistically
      queryClient.setQueryData(
        [
          queryKeys.caseContactConnections,
          'viewModel',
          caseContactConnectionRoleId.split('-')[0],
          'case',
        ],
        (oldData: DefaultV3Response<CaseContactConnectionViewModelType[]> | undefined) => {
          if (!oldData) return;
          const newDataWithoutRole = oldData.data?.map((caseContactConnection) => {
            if (
              caseContactConnection.caseContactConnectionId ===
              caseContactConnectionRoleId.split('-')[0] +
                '-' +
                caseContactConnectionRoleId.split('-')[1]
            ) {
              return {
                ...caseContactConnection,
                roles: caseContactConnection?.roles?.filter(
                  (role) => role.caseContactConnectionRoleId !== caseContactConnectionRoleId,
                ),
              };
            }
            return caseContactConnection;
          });
          return {
            ...oldData,
            data: newDataWithoutRole,
          };
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
      queryClient.invalidateQueries({
        queryKey: ['policy'],
      });
      // pretty sure we can get rid of this?
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contacts],
      });
    },
  });
};

export const useUpdateCaseContactConnectionRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateCaseContactConnectionRoleV3>[0]) =>
      updateCaseContactConnectionRoleV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections],
      });
    },
  });
};

export const useCreatePolicyForCaseContactConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createCaseContactConnectionPolicyV3>[0]) =>
      createCaseContactConnectionPolicyV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['policy'],
      });
    },
  });
};

export const useGetPoliciesForCaseContactConnection = (caseContactConnectionId: string) => {
  return useQuery({
    queryKey: ['policy', caseContactConnectionId],
    queryFn: async () => getCaseContactConnectionPoliciesV3(caseContactConnectionId),
    enabled: !!caseContactConnectionId,
  });
};

export const useGetCaseConflictsForCase = (caseId: string) => {
  return useQuery({
    queryKey: [queryKeys.caseConflicts, caseId],
    queryFn: async () => getAllCaseConflictsForCaseV3(caseId),
    enabled: !!caseId,
  });
};

export const useGetFirmCaseConflicts = () => {
  return useQuery({
    queryKey: [queryKeys.caseConflicts],
    queryFn: async () => getAllCaseConflictsForFirmV3(),
  });
};

// -----------------------------------------------------------------------------------------
// -------------------------- EVERYTHING BELOW HERE IS DEPRECATED --------------------------
// -----------------------------------------------------------------------------------------

/* eslint-disable */
/**
 * @deprecated Get rid of this when new endpoint for table data is created
 */
export const useUpdateCaseConscriptionData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => axiosInstance.patch(conscriptionRoute, data),
    onMutate: (data: { conscriptionId: string }) => {
      const caseId = data.conscriptionId?.split('-')[0];
      // Update the cache optimistically
      queryClient.setQueryData([queryKeys.caseContactConnections, caseId], (oldData: any) => {
        if (!oldData) return;
        const updatedConscriptionObj = oldData?.conscriptions.find(
          (conscription: any) => conscription.conscriptionId === data.conscriptionId,
        );

        const optimisticUpdate = {
          status: 'FOUND',
          conscriptions: [
            ...oldData?.conscriptions,
            {
              ...updatedConscriptionObj,
              ...data,
            },
          ],
        };

        return optimisticUpdate;
      });

      // Return the previous data and the tempId for rollback
      return {
        previousData: queryClient.getQueryData([queryKeys.caseContactConnections, caseId]),
      };
    },
    onSuccess: (result, variables) => {
      const caseId = variables.conscriptionId?.split('-')[0];

      // Invalidate the cache so it will refetch the updated data
      queryClient.invalidateQueries([queryKeys.caseContactConnections, caseId]);
    },
    onError: (error, variables, context) => {
      const caseId = variables.conscriptionId?.split('-')[0];
      // Rollback the cache to the previous data if there's an error
      if (context?.previousData) {
        queryClient.setQueryData([queryKeys.caseContactConnections, caseId], context.previousData);
      }
    },
    onSettled: (data, error, variables, context) => {
      const caseId = variables.conscriptionId?.split('-')[0];

      queryClient.invalidateQueries({
        queryKey: [queryKeys.caseContactConnections, caseId],
      });
    },
  });
};

/*eslint-enable */
