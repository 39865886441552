import { cn } from '@colosseum/data';
import { TrashIcon } from '@heroicons/react/24/outline';
import { UseMutationResult } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import Typography from '../Typography/Typography';
import { Button } from '../shadcn/Button/Button';
import { Select, SelectContent, SelectItem, SelectTrigger } from '../shadcn/Select/Select';

// TODO: Figure out how to type this with T for activeFilter and data
export interface FilterSelectProps<T> {
  activeFilter: any;
  data?: any[];
  deleteFilter: UseMutationResult<any, unknown, string, any>;
  emptyMessage?: string;
  handleItemSelect: (id: string) => void;
  isLoading: boolean;
  open: boolean;
  placeholder?: string; // If there is no placeholder there will also be no title displayed
  resourceIdKey: keyof T;
  setOpen: Dispatch<SetStateAction<boolean>>;
  triggerClassName?: string;
}

export function FilterSelect<T>(props: FilterSelectProps<T>) {
  const {
    activeFilter,
    data,
    deleteFilter,
    handleItemSelect,
    isLoading,
    open,
    placeholder,
    resourceIdKey,
    setOpen,
    triggerClassName,
    emptyMessage = 'No filters have been created.',
  } = props;

  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (deleteModalOpen) {
      setOpen(false);
    }
  }, [deleteModalOpen]);

  return (
    <div className="relative block -ml-px">
      <Select
        onValueChange={handleItemSelect}
        open={open}
        onOpenChange={setOpen}
        value={activeFilter?.[resourceIdKey] ?? ''}
      >
        <SelectTrigger className={cn('hover:bg-accent', triggerClassName)}>
          <Typography className="line-clamp-1">
            {placeholder ? activeFilter?.title ?? placeholder : ''}
          </Typography>
        </SelectTrigger>
        <SelectContent>
          {data?.map((dataItem) => (
            <div
              key={dataItem?.[resourceIdKey]}
              className="flex items-center justify-between cursor-pointer group hover:bg-gray-100"
            >
              <SelectItem
                noIndicator
                value={dataItem?.[resourceIdKey] ?? ''}
                className="justify-start w-full "
              >
                <Typography className="max-w-[90vw] sm:max-w-[600px] line-clamp-2">
                  {dataItem.title ?? 'Untitled Filter'}
                </Typography>
                <div className="text-xs text-gray-500 max-w-[90vw] sm:max-w-[600px] line-clamp-2">
                  {dataItem.description}
                </div>
              </SelectItem>
              <Button
                variant="ghost"
                size="unset"
                textColor="black"
                className="p-2 ml-2 opacity-0 group-hover:opacity-100 hover:bg-light-blue"
                onClick={() => {
                  setFilterToDelete(dataItem?.[resourceIdKey]);
                  setDeleteModalOpen(true);
                }}
              >
                <TrashIcon className="w-5 h-5 text-red-500" />
              </Button>
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center justify-between group">
              <div className="flex items-center justify-start w-full ">
                <SelectItem noIndicator value={''} disabled className="justify-start w-full ">
                  Loading...
                </SelectItem>
              </div>
            </div>
          )}
          {data?.length === 0 && (
            <div className="flex items-center justify-between group">
              <div className="flex items-center justify-start w-full ">
                <SelectItem noIndicator value={''} disabled className="justify-start w-full ">
                  <div className="text-gray-500">{emptyMessage}</div>
                </SelectItem>
              </div>
            </div>
          )}
        </SelectContent>
      </Select>
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        actionFunction={() => {
          if (filterToDelete) {
            deleteFilter.mutateAsync(filterToDelete).then(() => {
              if (activeFilter?.[resourceIdKey] === filterToDelete) {
                // unselect preset if it gets deleted
                handleItemSelect('');
                navigate(window.location.pathname);
              }
              setFilterToDelete(undefined);
              setDeleteModalOpen(false);
              setOpen(false);
            });
          }
        }}
      />
    </div>
  );
}

export default FilterSelect;
