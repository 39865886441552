import {
  BillingHistory,
  CaseType,
  DefaultV3Response,
  FirmSubscriptionAccount,
  JustifiCardDetails,
  PaymentMethod,
  Subscription,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

const baseExactusRoute = `/exactus/${API_STAGE}/v3`;

// #region Payments
// POST charge_firm_account_v3
export const chargeFirmAccountV3 = (data: {
  firmId: string;
  chargeAmount: number;
  subscriptionIds: string[];
  description: string;
}) => axiosInstance.post(`${baseExactusRoute}/payments/charge`, data);

// #endregion

// #region PaymentMethods
// POST create_payment_method_v3
export const createPaymentMethodV3 = (
  data: Pick<PaymentMethod, 'isPrimary' | 'billingEmailAddress'> & Partial<PaymentMethod>,
) => axiosInstance.post(`${baseExactusRoute}/payment-methods`, data);

// DELETE delete_payment_method_v3
export const deletePaymentMethodV3 = (paymentMethodId: string) =>
  axiosInstance.delete(`${baseExactusRoute}/payment-methods/${paymentMethodId}`);

// GET get_payment_methods_v3
export const getPaymentMethodsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<(PaymentMethod & { justifiDetails: JustifiCardDetails })[]>>(
      `${baseExactusRoute}/payment-methods`,
    )
    .then((res) => {
      const { data } = res.data;
      return {
        data: data.map((method) => ({
          ...method,
          cardDetails: method.justifiDetails,
        })),
      };
    });

// GET get_payment_method_v3
export const getPaymentMethodV3 = (paymentMethodId: string) =>
  axiosInstance
    .get<DefaultV3Response<PaymentMethod>>(`${baseExactusRoute}/payment-methods/${paymentMethodId}`)
    .then((res) => res.data);

// PATCH update_payment_method_v3
export const updatePaymentMethodV3 = (
  data: Pick<PaymentMethod, 'paymentMethodId'> & Partial<PaymentMethod>,
) => {
  const { paymentMethodId, ...rest } = data;
  return axiosInstance.patch(`${baseExactusRoute}/payment-methods/${paymentMethodId}`, rest);
};
// #endregion

// #region Subscriptions
// GET get_subscriptions_v3`
export const getSubscriptionsV3 = (queryParams?: string) =>
  axiosInstance
    .get<DefaultV3Response<Subscription>>(
      `${baseExactusRoute}/subscriptions?${queryParams ? queryParams : ''}`,
    )
    .then((res) => res.data);

// GET get_subscription_v3
export const getSubscriptionV3 = (subscriptionId: string) =>
  axiosInstance
    .get<DefaultV3Response<Subscription>>(`${baseExactusRoute}/subscriptions/${subscriptionId}`)
    .then((res) => res.data);

// POST create_subscription_v3
export const createSubscriptionV3 = (data: Subscription) =>
  axiosInstance.post(`${baseExactusRoute}/subscriptions`, data);

// DELETE delete_subscription_v3
export const deleteSubscriptionV3 = (subscriptionId: string) =>
  axiosInstance.delete(`${baseExactusRoute}/subscriptions/${subscriptionId}`);

// PATCH update_subscription_v3
export const updateSubscriptionV3 = (data: Subscription) => {
  const { subscriptionId, ...rest } = data.subscriptions[0];
  return axiosInstance.patch(`${baseExactusRoute}/subscriptions/${subscriptionId}`, rest);
};

// PATCH check_subscription_status_v3
export const checkSubscriptionStatusV3 = () =>
  axiosInstance.patch(`${baseExactusRoute}/subscriptions`);
// #endregion

// #region BillingHistory
// GET get_billing_history_v3
export const getBillingHistoryV3 = () =>
  axiosInstance
    .get<DefaultV3Response<BillingHistory[]>>(`${baseExactusRoute}/billing-history`)
    .then((res) => res.data);
// #endregion
