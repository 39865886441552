// Components
import { displayContactName, useGetContactsInfinite } from '@colosseum/data';
import {
  Button,
  OptionLookup,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@colosseum/shared-ui';
import { ContactType, caseContactConnectionOptions } from '@gladiate/types';

import { PlusCircle } from 'lucide-react';
import { useState } from 'react';

export interface ContactsSelectProps {
  handleSelectContact: (arg0: any, arg1: any) => void;
  isLoading?: boolean;
  caseId: string;
  customButton?: React.ReactNode;
  customRole?: string;
  buttonMessage: string;
}

export function ContactsSelect(props: ContactsSelectProps) {
  const {
    data: contactsData,
    isLoading: isContactsLoading,
    isError: isContactsError,
  } = useGetContactsInfinite();
  const [open, setOpen] = useState(false);
  const { handleSelectContact, isLoading, caseId, customButton, customRole, buttonMessage } = props;

  // Use custom role if provided, otherwise fallback to client role
  const role = customRole ?? caseContactConnectionOptions.client;
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {customButton ? (
          customButton
        ) : (
          <Button variant="outline" size="sm" className="h-8">
            <PlusCircle className="w-4 h-4 mr-2" />
            {buttonMessage}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="z-50 bg-white shadow-lg">
        <OptionLookup
          setOpen={setOpen}
          isFetchingMore={!!contactsData?.meta?.lastEvaluatedKey}
          caseId={caseId}
          options={contactsData?.data ?? []}
          displayOption={(option: ContactType) => displayContactName(option)}
          idString={'contactId'}
          linkingFunction={(option: ContactType) => {
            handleSelectContact(role, option.contactId);
            setOpen((prev) => !prev);
          }}
          createFunction={() => handleSelectContact(role, undefined)}
          rightAlign={true}
          mutationFn={true}
          isLoading={isContactsLoading || isLoading}
          isError={isContactsError}
          placeholder={
            isContactsLoading
              ? 'Loading...'
              : `Search ${contactsData?.data?.length ?? 0}${
                  contactsData?.meta.lastEvaluatedKey ? '+' : ''
                } contacts...`
          }
        />
      </PopoverContent>
    </Popover>
  );
}

export default ContactsSelect;
