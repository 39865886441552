import { registerChangeEvents } from '@colosseum/data';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { Checkbox } from '../../shadcn/Checkbox/Checkbox';
import { FormControl, FormField, FormItem } from '../../shadcn/Form/Form';

/* eslint-disable-next-line */
export interface CheckboxFormInputProps {
  handleOnChange: (e: React.SyntheticEvent) => void;
  name: string;
  title: string;
  isDisabled?: boolean;
  isDisabledMessage?: string;
}

export function CheckboxFormInput(props: CheckboxFormInputProps) {
  const { name, title, handleOnChange, isDisabled } = props;
  const form = useFormContext();

  const handleOnChangeForm = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    form.setValue(name, target.value, {
      shouldDirty: true,
    });
    handleOnChange(e);
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="px-2 border-transparent">
          <FormControl>
            <div className="flex items-center justify-between w-full col-span-2">
              <div className="flex ">
                <div className="text-sm">{title}</div>
                {isDisabled && (
                  <>
                    {' '}
                    <TooltipWrapper message={props.isDisabledMessage}>
                      <InformationCircleIcon className="w-4 h-4 text-gray-400" />
                    </TooltipWrapper>
                  </>
                )}
              </div>

              <Checkbox
                {...registerChangeEvents({
                  field,
                  form,
                  handleOnChange: handleOnChangeForm,
                })}
                checked={
                  [true, '1'].includes(field.value) // use '1' / ' 0' for boolean values
                }
                onCheckedChange={field.onChange}
                disabled={isDisabled}
              />
            </div>
          </FormControl>
        </FormItem>
      )}
    />
  );
}

export default CheckboxFormInput;
