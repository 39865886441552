import {
  cn,
  enqueueAPISnackbar,
  getUser,
  useAddUserToFirm,
  useCreateUserForFirm,
  useDeleteUserFromFirm,
  useDisableUserForFirm,
  useEnableUserForFirm,
  useGetAvailableSubscriptions,
  useGetFirmGroupsAndPermissions,
  useGetFirmUsers,
  useGetSubscriptions,
  useResendUserInvitation,
  useUpdateFirmUserAttributes,
} from '@colosseum/data';
import {
  DataTable,
  GladiateLoader,
  Slideover,
  renderHeader,
  Switch,
  Typography,
  Button,
} from '@colosseum/shared-ui';
import { HurinUserType, SubscriptionType } from '@gladiate/types';
import {
  CheckIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import FirmUserForm from '../../FirmUserForm/FirmUserForm';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';

/* eslint-disable-next-line */
export interface UserManagementProps {}

const user = getUser();

const createUserPayload: HurinUserType = {
  desiredDeliveryMediums: ['EMAIL'],
};

export function UserManagement() {
  const queryClient = useQueryClient();

  const createUserMutation = useCreateUserForFirm();
  const deleteUserMutation = useDeleteUserFromFirm();
  const resendInvitationMutation = useResendUserInvitation();
  const disableUserMutation = useDisableUserForFirm();
  const enableUserMutation = useEnableUserForFirm();
  const addUserToFirm = useAddUserToFirm();

  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | undefined>();
  const [creating, setCreating] = useState(false);
  const [creatingUsername, setCreatingUsername] = useState('');
  const [typing, setTyping] = useState(false);
  const [resent, setResent] = useState('');
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const [seats, setSeats] = useState<{ [key in SubscriptionType]: boolean }>({
    caseOptimization: false,
    clientEngagement: false,
    coCounsel: false,
    leadManagement: false,
    insight: false,
  });
  const updateUserMutation = useUpdateFirmUserAttributes(selectedUser || '');

  const { data: firmGroupsData } = useGetFirmGroupsAndPermissions();
  const firmGroups = firmGroupsData?.data;
  const { data: firmSubscriptionData } = useGetSubscriptions();
  const { data: availableSubscriptionsData } = useGetAvailableSubscriptions();

  const availableSubscriptions = availableSubscriptionsData?.data;

  const availableCaseOptimizationSubscriptions = availableSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'caseOptimization',
  );
  const availableClientEngagementSubscriptions = availableSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'clientEngagement',
  );
  const availableCoCounselSubscriptions = availableSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'coCounsel',
  );
  const availableLeadManagementSubscriptions = availableSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'leadManagement',
  );
  const availableInsightSubscriptions = availableSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'insight',
  );

  const noSeatsLeft = !availableSubscriptions?.length;

  const rolesMap: { [key: string]: string } =
    firmGroups?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr?.groupId || '']: curr?.groupName,
      };
    }, {}) || {};

  firmUsersData?.sort((a, b) => {
    const aRole = a?.groupName;
    const bRole = b?.groupName;
    const aName = a?.name;
    const bName = b?.name;

    if (aRole === 'Admin' && bRole !== 'Admin') {
      return -1;
    } else if (aRole !== 'Admin' && bRole === 'Admin') {
      return 1;
    } else {
      if (!aName) return 1;
      if (!bName) return -1;

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  function onSubmit() {
    if (creating) {
      const numberFromPayload = createUserPayload['phone_number'];

      if (numberFromPayload === undefined || numberFromPayload.length < 3) {
        //remove phone number from payload
        // ^ I am just replicating the functionality this had before, this needs to a refactor eventually
        delete createUserPayload['phone_number'];
      }

      if (creatingUsername === '') {
        enqueueAPISnackbar({
          message: 'Please enter a username',
          variant: 'error',
          key: 'user-no-name-error',
        });
      } else if (createUserPayload?.email === undefined) {
        enqueueAPISnackbar({
          message: 'Please enter an email',
          variant: 'error',
          key: 'user-no-email-error',
        });
      } else if (!Object.values(seats).includes(true)) {
        enqueueAPISnackbar({
          message: 'Please select a license for the user',
          variant: 'error',
          key: 'user-no-license-error',
        });
      } else
        createUserMutation
          .mutateAsync({
            username: creatingUsername,
            ...createUserPayload,
          })
          .then((res) => {
            const licensePayload = generateLicensePayload();
            updateUserMutation.mutateAsync({
              data: licensePayload,
              usernameOverride: res.data.Username,
            });

            addUserToFirm.mutateAsync(creatingUsername).then(() => {
              setTyping(false);
              setOpen(false);

              queryClient.invalidateQueries({
                queryKey: ['firmUsers'],
              });

              enqueueSnackbar('User added successfully', {
                variant: 'success',
                autoHideDuration: 3000,
              });
            });
          });
    }
  }

  function onDelete() {
    if (user === selectedUser) {
      enqueueSnackbar(
        'You cannot delete yourself from the portal. Please contact your firm administrator to remove yourself from the firm.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      );
      return;
    } else {
      if (!selectedUser) return;

      deleteUserMutation.mutateAsync(selectedUser).then(() => {
        setTyping(false);
        setOpen(false);
      });
    }
  }

  function resendInvite(user: HurinUserType) {
    if (!user.Username) return;

    resendInvitationMutation.mutateAsync(user.Username).then(() => {
      setTyping(false);
      setOpen(false);

      enqueueSnackbar('Invitation resent successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    });
  }

  function disableUser() {
    if (!selectedUser) return;

    disableUserMutation.mutateAsync(selectedUser).then(() => {
      setTyping(false);
      setOpen(false);
    });
  }

  function enableUser() {
    if (!selectedUser) return;

    enableUserMutation.mutateAsync(selectedUser).then(() => {
      setTyping(false);
      setOpen(false);
    });
  }

  const selectedUserDetails = firmUsersData?.find((user) => user.Username === selectedUser);

  function generateLicensePayload(subscriptionType?: SubscriptionType) {
    const subscriptionIdMapping: { [key in SubscriptionType]: string } = {
      caseOptimization: 'subscriptionCaseOptimizationId',
      clientEngagement: 'subscriptionClientEngagementId',
      coCounsel: 'subscriptionCoCounselId',
      leadManagement: 'subscriptionLeadManagementId',
      insight: 'subscriptionInsightId',
    };
    if (!subscriptionType) {
      return {
        [subscriptionIdMapping['caseOptimization']]:
          (availableSubscriptions &&
            seats['caseOptimization'] &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === 'caseOptimization',
            )?.subscriptionId) ||
          '',
        [subscriptionIdMapping['clientEngagement']]:
          (availableSubscriptions &&
            seats['clientEngagement'] &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === 'clientEngagement',
            )?.subscriptionId) ||
          '',
        [subscriptionIdMapping['coCounsel']]:
          (availableSubscriptions &&
            seats['coCounsel'] &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === 'coCounsel',
            )?.subscriptionId) ||
          '',
        [subscriptionIdMapping['leadManagement']]:
          (availableSubscriptions &&
            seats['leadManagement'] &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === 'leadManagement',
            )?.subscriptionId) ||
          '',
        [subscriptionIdMapping['insight']]:
          (availableSubscriptions &&
            seats['insight'] &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === 'insight',
            )?.subscriptionId) ||
          '',
      };
    }

    const previousSeatActive = seats[subscriptionType] === true;
    if (!previousSeatActive) {
      return {
        [subscriptionIdMapping[subscriptionType]]:
          (availableSubscriptions &&
            availableSubscriptions.find(
              (subscription) => subscription.subscriptionType === subscriptionType,
            )?.subscriptionId) ||
          '',
      };
    } else {
      return {
        [subscriptionIdMapping[subscriptionType]]: '',
      };
    }
  }

  function handleSubscriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTyping(true);

    const subscriptionType = e.target.name as SubscriptionType;

    const licensePayload = generateLicensePayload(subscriptionType);

    if (!selectedUser) {
      setSeats((prevState) => ({
        ...prevState,
        [subscriptionType]: !prevState[subscriptionType],
      }));
      return;
    }
    handleLicenseUpdate(licensePayload, subscriptionType);
  }

  function handleLicenseUpdate(
    subscriptionPayload: { [key: string]: string },
    subscriptionType?: SubscriptionType,
  ) {
    updateUserMutation.mutateAsync({ data: subscriptionPayload }).then(() => {
      if (subscriptionType) {
        setSeats((prevState) => ({
          ...prevState,
          [subscriptionType]: !prevState[subscriptionType],
        }));
      }
      setTyping(false);
    });
  }

  function isAddUserDisabled() {
    return !creatingUsername || !createUserPayload?.email || !Object.values(seats).includes(true);
  }
  const columns: ColumnDef<HurinUserType>[] = [
    {
      accessorFn: (row) => row.name,
      id: 'Name',
      sortUndefined: 1,
      header: renderHeader,
      enableHiding: false,
      cell: (props: CellContext<HurinUserType, unknown>) => {
        const user = props.row.original;
        return (
          <>
            {user.name ? (
              <div className="inline-flex">
                <div className="font-semibold ">{user.name}&nbsp;</div>
                <div className="italic ">-&nbsp;{user.Username}</div>
              </div>
            ) : (
              <div className="font-semibold">{user.Username}</div>
            )}
            <div className="text-gray-500">{user.email}</div>
          </>
        );
      },
    },
    {
      accessorFn: (row) => rolesMap[row?.groupId || ''] || '--',
      id: 'Role',
      sortUndefined: 1,
      header: renderHeader,
      enableHiding: false,
      cell: (props: CellContext<HurinUserType, unknown>) => {
        const user = props.row.original;
        const role = rolesMap[user?.groupId || ''];
        return <div className="text-gray-900 capitalize">{role ? role : 'No Role Assigned'}</div>;
      },
    },
    {
      accessorFn: (row) => row.UserStatus,
      id: 'Status',
      sortUndefined: 1,
      header: renderHeader,
      enableHiding: false,
      cell: (props: CellContext<HurinUserType, unknown>) => {
        const user = props.row.original;
        return user?.Enabled ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {user.UserStatus === 'FORCE_CHANGE_PASSWORD' ? (
              <div className="flex items-center">
                <span className="inline-flex px-2 text-xs font-semibold leading-5 text-yellow-800 bg-yellow-100 rounded-full">
                  Pending
                </span>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setResent(user?.username || '');
                    resendInvite(user);
                  }}
                  className={cn(
                    'ms-2 flex relative items-center p-1 text-xs font-semibold text-yellow-500 border border-yellow-500 rounded-lg cursor-pointer hover:border-yellow-800 hover:text-yellow-800 group hover:bg-yellow-100 whitespace-nowrap ',
                    resent === user?.username &&
                      'border-green-500 text-green-500 hover:border-green-500 hover:text-green-500',
                  )}
                >
                  <PaperAirplaneIcon
                    className={cn(
                      'w-4 h-4 mr-1 text-yellow-500 group-hover:text-yellow-800 transition-all duration-300 ease-in-out',
                      resent === user?.username && ' translate-x-16 opacity-0',
                    )}
                  />
                  <div
                    className={cn(
                      'transition-all duration-100 ease-in-out',
                      resent === user?.username && 'opacity-0',
                    )}
                  >
                    Resend Invite
                  </div>

                  <CheckIcon
                    className={cn(
                      'w-4 h-4 absolute top-1 left-2 text-green-500 group-hover:text-green-500 transition-all duration-300 ease-in-out',
                      resent === user?.username
                        ? 'translate-x-0 opacity-100'
                        : '-translate-x-16 opacity-0',
                    )}
                  />

                  <div
                    className={cn(
                      'w-4 h-4 absolute top-1 left-7 text-green-500 group-hover:text-green-500 transition-all duration-300 ease-in-out',
                      resent === user?.username
                        ? 'translate-x-0 opacity-100'
                        : '-translate-x-16 opacity-0',
                    )}
                  >
                    Invite Sent
                  </div>
                </button>
              </div>
            ) : (
              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                Active
              </span>
            )}
          </>
        ) : (
          <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
            Disabled
          </span>
        );
      },
    },
  ];

  // create a useeffect that checks if resent is greater than -1, set it back after 2 seconds
  useEffect(() => {
    if (resent) {
      setTimeout(() => {
        setResent('');
      }, 2000);
    }
  }, [resent]);

  useEffect(() => {
    if (open === false) {
      setSelectedUser(undefined);
      setCreating(false);
      setCreatingUsername('');

      // wipe out the createUserPayload
      // ^ again this needs to be refactored eventually but keeps original functionality
      createUserPayload &&
        Object.keys(createUserPayload).forEach((key) => {
          delete createUserPayload[key as keyof typeof createUserPayload];
        });

      createUserPayload['desiredDeliveryMediums'] = ['EMAIL'];
    }
  }, [open]);

  useEffect(() => {
    setSeats((prevState) => {
      return {
        ...prevState,
        caseOptimization: !!selectedUserDetails?.subscriptionCaseOptimizationId,
        clientEngagement: !!selectedUserDetails?.subscriptionClientEngagementId,
        coCounsel: !!selectedUserDetails?.subscriptionCoCounselId,
        leadManagement: !!selectedUserDetails?.subscriptionLeadManagementId,
        insight: !!selectedUserDetails?.subscriptionInsightId,
      };
    });
  }, [selectedUser]);

  return (
    <>
      <Slideover
        title={creating ? 'Add New User to Firm' : 'User Settings'}
        description={
          creating
            ? 'Add a new user to your firm by filling out the fields below.'
            : 'Edit the user settings below.'
        }
        open={open}
        setOpen={setOpen}
        displayDeleteButton={creating ? false : true}
        deleteFunction={onDelete}
        secondaryButton={
          creating ? null : (
            <button
              type="button"
              className="px-4 py-2 ml-4 text-sm font-medium text-yellow-500 border border-yellow-500 rounded-md shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              onClick={() => {
                if (selectedUserDetails?.Enabled) {
                  disableUser();
                } else {
                  enableUser();
                }
              }}
            >
              {disableUserMutation.isLoading || enableUserMutation.isLoading ? (
                <GladiateLoader height={20} />
              ) : (
                <> {selectedUserDetails?.Enabled ? 'Disable' : 'Enable'}</>
              )}
            </button>
          )
        }
        bottomButtons={
          creating ? (
            <div className="flex items-center justify-between px-5 py-5 border-t border-t-gray-200">
              <button
                type="button"
                className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
                onClick={() => {
                  setOpen(false);

                  setTimeout(() => {
                    setCreating(false);
                  }, 1000);
                }}
              >
                Cancel
              </button>

              <Button
                type="submit"
                onClick={() => {
                  onSubmit();
                }}
                className={cn(
                  'inline-flex justify-center px-6 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                  noSeatsLeft
                    ? 'cursor-not-allowed bg-gray-300'
                    : 'bg-sky-blue hover:bg-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue',
                )}
                disabled={noSeatsLeft || isAddUserDisabled()}
              >
                {createUserMutation.isLoading ? (
                  <div>
                    <GladiateLoader white height={20} />
                  </div>
                ) : (
                  'Add User'
                )}
              </Button>
            </div>
          ) : null
        }
        typing={typing}
      >
        <div className="p-4 rounded-md bg-blue-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="w-5 h-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="flex-1 ml-3 md:flex md:justify-between">
              <div className="flex-col gap-1 flex">
                <Typography variant={'subtext'}>Seats Remaining</Typography>
                <span className={'flex gap-2'}>
                  <Typography
                    className={
                      'text-xs bg-blue-400 items-center w-4 flex justify-center text-white rounded-md'
                    }
                  >
                    {' '}
                    {availableCaseOptimizationSubscriptions?.length ?? 0}
                  </Typography>
                  <Typography variant={'xs'} color={'blue'}>
                    Case Optimization Subscriptions
                  </Typography>
                </span>
                <span className={'flex gap-2'}>
                  <Typography
                    className={
                      'text-xs bg-blue-400 items-center w-4 flex justify-center text-white rounded-md'
                    }
                  >
                    {' '}
                    ∞
                  </Typography>
                  <Typography variant={'xs'} color={'blue'}>
                    Client Engagement Subscriptions
                  </Typography>
                </span>
              </div>
            </div>
          </div>
        </div>

        <FirmUserForm
          user={firmUsersData?.find((user) => {
            return user.Username === selectedUser;
          })}
          creatingNewUser={creating}
          setTyping={setTyping}
          creatingUsername={creatingUsername}
          setCreatingUsername={setCreatingUsername}
          createUserPayload={createUserPayload}
          firmGroups={firmGroups}
        />
        <div className="grid grid-cols-1 gap-2">
          <Typography variant={'heading'}>User Access</Typography>
          <Switch
            handleChange={handleSubscriptionChange}
            name={'caseOptimization'}
            title={'Case Optimization'}
            value={seats['caseOptimization']}
            disabled={
              availableCaseOptimizationSubscriptions?.length === 0 && !seats['caseOptimization']
            }
          />
          <Switch
            handleChange={handleSubscriptionChange}
            name={'clientEngagement'}
            title={'Client Engagement'}
            value={seats['clientEngagement']}
            disabled={
              availableClientEngagementSubscriptions?.length === 0 && !seats['clientEngagement']
            }
          />
        </div>
      </Slideover>
      <div className="px-1">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your firm including name, username, email, and role.
            </p>
          </div>
          <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setSelectedUser(undefined);
                setCreating(true);
                setOpen(true);
              }}
            >
              Add User
            </Button>
          </div>
        </div>
        {firmUsersQuery.isLoading ? (
          <div className="py-10 mt-8 border border-gray-300 rounded-lg shadow-md">
            <GladiateLoader />
          </div>
        ) : (
          <div className="flex flex-col mt-2">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <DataTable
                  data={firmUsersData || []}
                  initialSort={{
                    id: 'Name',
                    desc: false,
                  }}
                  columns={columns}
                  handleRowClick={(item: Row<HurinUserType>) => {
                    setCreating(false);
                    setSelectedUser(item.original.Username);
                    setOpen(true);
                  }}
                  isLoading={firmUsersQuery.isLoading}
                  isError={firmUsersQuery.isError}
                  tableName="user-management"
                  hideViewButton
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserManagement;
