import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;
const baseSanitatisUrl = `/ratio-sanitatis/${API_STAGE}/v3`;

export const postErrorToSlack = ({ message, url }: { message?: string; url?: string }) => {
  axiosInstance.post(`${baseSanitatisUrl}/utils/report-error`, {
    message: message,
    url: url,
  });
};
