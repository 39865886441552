import { bitBToB, useGetPaymentMethods, useGetSubscriptions } from '@colosseum/data';
import { NavLink } from 'react-router-dom';

/* eslint-disable-next-line */
export interface PaymentPromptBannerProps {}

export function PaymentPromptBanner(props: PaymentPromptBannerProps) {
  const { data: paymentMethodsData } = useGetPaymentMethods();

  const paymentMethods = paymentMethodsData?.data;

  if (
    paymentMethodsData &&
    paymentMethodsData?.data?.length > 0 &&
    !paymentMethods?.find((method) => bitBToB(method.isPrimary))
  ) {
    return (
      <div className="sticky rounded-lg top-2 lg:ml-[275px] ml-2 mr-2 z-50 min-h-10 bg-amber-400">
        <div className="m-4 text-xl font-semibold leading-10 text-center text-white ">
          {`Maintain your access. Select a primary payment method in `}
          <NavLink to="subscriptions" className="underline">
            Subscriptions
          </NavLink>
          {`.`}
        </div>
      </div>
    );
  }

  return (
    <>
      {paymentMethodsData && paymentMethodsData?.data?.length < 1 && (
        <div className="sticky rounded-lg top-2 lg:ml-[275px] ml-2 mr-2 z-50 min-h-10 bg-red-600">
          <div className="m-4 text-xl font-semibold leading-10 text-center text-white ">
            {`Maintain your access. Ensure your firm has payment information on file in `}
            <NavLink to="subscriptions" className="underline">
              Subscriptions
            </NavLink>
            {`.`}
          </div>
        </div>
      )}
      <div></div>
    </>
  );
}

export default PaymentPromptBanner;
