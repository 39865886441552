// Dependencies
import { useQueryClient } from '@tanstack/react-query';

// Components
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@colosseum/shared-ui';

// React-query
import { useGetCaseTypes, useUpdateCaseData } from '@colosseum/data';
import { CaseTypeType } from '@gladiate/types';
import kebabCase from 'lodash/kebabCase';

/* eslint-disable-next-line */
export interface CaseTypeSelectProps {
  caseId: string;
  currentCaseType?: CaseTypeType;
}

export function CaseTypeSelect(props: CaseTypeSelectProps) {
  const { caseId, currentCaseType } = props;
  const updateCaseMutation = useUpdateCaseData();
  const queryClient = useQueryClient();
  const { data: caseTypesRes, isLoading: isCaseTypesLoading } = useGetCaseTypes();
  const caseTypes = caseTypesRes?.data;

  function handleCaseTypeChange(newCaseType: string) {
    updateCaseMutation.mutateAsync({ caseId, caseTypeId: newCaseType }).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['fileItem'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fileItemExhaustive'],
      });

      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['fileItem'],
        });
        queryClient.invalidateQueries({
          queryKey: ['fileItemExhaustive'],
        });
      }, 2000);
    });
  }

  return (
    <Select value={currentCaseType?.caseTypeId} onValueChange={handleCaseTypeChange}>
      <SelectTrigger className="w-auto" data-cy="select-trigger-case-type">
        <SelectValue placeholder="Select a case type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Case Type</SelectLabel>
          {caseTypes?.map((caseType) => (
            <SelectItem
              data-cy={`select-item-case-type-${kebabCase(caseType.title) as string}`}
              key={caseType.caseTypeId}
              value={caseType.caseTypeId}
            >
              {caseType.title}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default CaseTypeSelect;
