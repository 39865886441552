import { BitBoolean } from '@gladiate/types';

/**
 *
 * @description Converts a bitBoolean to a boolean
 * @usage use this function when trying to check if a bitBoolean is true or false
 *
 */
export const bitBToB = (bitBoolean?: BitBoolean): boolean => {
  return bitBoolean === '1';
};

export const bToBitB = (booleanValue?: boolean | BitBoolean): BitBoolean => {
  return ['1', true].includes(booleanValue ?? '') ? '1' : '0';
};
