import {
  displayContactName,
  formatAddresses,
  formatEmails,
  formatPhoneNumbers,
  roleColorMap,
} from '@colosseum/data';
import {
  Chip,
  ContactItemViewer,
  TooltipWrapper,
  renderCell,
  renderHeader,
} from '@colosseum/shared-ui';
import {
  CaseContactConnectionRoleType,
  CaseContactConnectionViewModelType,
  ContactAddressType,
  ContactEmailType,
  ContactNumberType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

import { startCase, uniq } from 'lodash';

export type CaseContactConnectionColumn = CaseContactConnectionViewModelType;

export const columns: ColumnDef<CaseContactConnectionColumn>[] = [
  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Contact Name',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.roles,
    id: 'Roles',
    header: renderHeader,
    filterFn: (row, _id, value: CaseContactConnectionRoleType['roleOnCase'][]) => {
      const { roles } = row.original;
      const rolesInContactConnect = roles?.map(({ roleOnCase }) => roleOnCase);
      return value.some((elt) => rolesInContactConnect.includes(elt));
    },
    cell: (props) => {
      const totalRoles = (props.getValue() as CaseContactConnectionViewModelType['roles'])
        ?.map((role) => role.roleOnCase)
        .filter((roleOnCase) => roleOnCase && roleOnCase !== caseContactConnectionOptions.party);
      const uniqTotalRoles = uniq(totalRoles);

      if (!totalRoles?.length) return '-';

      return (
        <div className="flex flex-wrap gap-1">
          {uniqTotalRoles.slice(0, 4).map((roleOnCase) => (
            <Chip
              key={roleOnCase}
              name={startCase(roleOnCase)}
              color={roleColorMap?.[roleOnCase ?? 'default']}
            />
          ))}
          {uniqTotalRoles.length > 4 && (
            <TooltipWrapper
              message={uniqTotalRoles.map((roleOnCase) => startCase(roleOnCase)).join(', ')}
            >
              <div>
                <Chip name={`+${uniqTotalRoles.length - 4} more`} color="stone" />
              </div>
            </TooltipWrapper>
          )}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.contact.contactNumbers,
    id: 'Phone Number',
    header: renderHeader,
    cell: (props) => {
      const totalPhoneNumbers = props.getValue() as ContactNumberType[];
      const { primaryPhoneNumber, additionalPhoneNumbers } = formatPhoneNumbers(totalPhoneNumbers);
      return (
        <ContactItemViewer
          primaryValue={primaryPhoneNumber}
          additionalValues={additionalPhoneNumbers}
        />
      );
    },
  },
  {
    accessorFn: (row) => row.contact.contactEmails,
    id: 'Email',
    header: renderHeader,
    sortingFn: (a, b, columnId) => {
      const [emailA, emailB] = [
        a.getValue<ContactEmailType[]>(columnId)?.[0]?.emailAddress ?? 1,
        b.getValue<ContactEmailType[]>(columnId)?.[0]?.emailAddress ?? -1,
      ];
      return emailA.toLocaleString().localeCompare(emailB.toLocaleString());
    },
    cell: (props) => {
      const totalEmails = props.getValue() as ContactEmailType[];
      const { primaryEmail, additionalEmails } = formatEmails(totalEmails);
      return (
        <ContactItemViewer
          primaryValue={primaryEmail}
          additionalValues={additionalEmails?.map((email) => (email?.emailAddress ?? '') as string)}
        />
      );
    },
  },
  {
    accessorFn: (row) => row.contact.contactAddresses,
    id: 'Address',
    header: renderHeader,
    cell: (props) => {
      const totalAddresses = props.getValue() as ContactAddressType[];
      const { primaryAddress, additionalAddresses } = formatAddresses(totalAddresses);
      return (
        <ContactItemViewer primaryValue={primaryAddress} additionalValues={additionalAddresses} />
      );
    },
  },
  {
    accessorFn: (row) => row.descriptionOfCaseInvolvement,
    id: 'Description of Case Involvement',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
