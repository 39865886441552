import {
  swapIndices,
  useCreateAttorneyFeeForCase,
  useDeleteAttorneyFeeForCase,
  useDeleteCase,
  useGetCase,
  usePermissions,
} from '@colosseum/data';
import { ActionConfirmModal, Button, FeeAgreementForm, Typography } from '@colosseum/shared-ui';
import { FeeAgreementType } from '@gladiate/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReferCase from '../../cases/ReferCase/ReferCase';
import ReferralSourceSection from '../../cases/ReferralSourceSection/ReferralSourceSection';
import CaseCoCounsel from '../CaseCoCounsel/CaseCoCounsel';
import CaseStaff from '../CaseStaff/CaseStaff';
import CaseTeamSelect from '../CaseTeamSelect/CaseTeamSelect';
import CaseTypeSelect from '../CaseTypeSelect/CaseTypeSelect';

/* eslint-disable-next-line */
export interface CaseSettingsProps {
  caseId: string;
  feeAgreements: FeeAgreementType[] | undefined;
}

export function CaseSettings(props: CaseSettingsProps) {
  const { caseId, feeAgreements } = props;
  const navigate = useNavigate();
  const { isCocounsel } = usePermissions();

  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [feeAgreementState, setFeeAgreementState] = useState<FeeAgreementType[]>([
    {
      description: '',
      title: '',
      currentlyApplicable: false,
      feePercentage: 0,
    },
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const { data: activeCaseQuery, isLoading: activeCaseIsLoading } = useGetCase(caseId);

  const deleteCase = useDeleteCase();
  const createFeeAgreement = useCreateAttorneyFeeForCase(caseId);
  const deleteFeeAgreement = useDeleteAttorneyFeeForCase();

  const activeCaseData = activeCaseQuery?.data;

  const activeCaseDataFeeAgreements = feeAgreements;

  //sort fee agreements so that the currently applicable one the last one in the array
  const tempSortArray = activeCaseDataFeeAgreements ? [...activeCaseDataFeeAgreements] : [];

  const sortedFeeAgreements =
    !activeCaseIsLoading && tempSortArray
      ? tempSortArray?.sort((a: FeeAgreementType, b: FeeAgreementType) => {
          if (a.currentlyApplicable && !b.currentlyApplicable) {
            return 1;
          } else if (!a.currentlyApplicable && b.currentlyApplicable) {
            return -1;
          } else {
            return 0;
          }
        })
      : [];

  const feeAgreementsLength = sortedFeeAgreements?.length;

  async function handleDelete() {
    if (!caseId) {
      console.error('No case id on delete');
      return;
    }
    return deleteCase.mutateAsync(caseId).then(() => {
      navigate('/cases');
    });
  }

  useEffect(() => {
    setFeeAgreementState(sortedFeeAgreements);
  }, []);

  return (
    <>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={() => {
          if (deleteIndex !== -1) {
            const feeAgreementId = feeAgreementState[deleteIndex].attorneyFeeId;

            deleteFeeAgreement.mutateAsync(feeAgreementId ?? '').then(() => {
              const newFeeAgreementState = feeAgreementState.filter(
                (feeAgreement, i) => i !== deleteIndex,
              );

              setFeeAgreementState(newFeeAgreementState);
              setShowDeleteModal(false);
            });
          }
        }}
        callback={() => {
          setDeleteIndex(-1);
        }}
      />
      <div className="divide-y divide-gray-300">
        <div className="pb-5">
          <label className="text-xl font-semibold capitalize ">Team</label>
          <CaseTeamSelect caseId={caseId} />
        </div>
        <div className="pt-5">
          <label className="text-xl font-semibold capitalize ">Staff</label>

          <CaseStaff caseId={caseId} />
        </div>
        <div className="pt-5">
          <label className="text-xl font-semibold capitalize ">Co-Counsel</label>
          <CaseCoCounsel caseId={caseId} />
        </div>

        <ReferCase caseData={activeCaseQuery?.data} />
        <div className="flex flex-col">
          <Typography className="my-5 text-xl font-semibold capitalize">
            Marketing Referral Source
          </Typography>
          <ReferralSourceSection caseData={activeCaseQuery?.data} />
        </div>

        <div className="flex flex-col gap-4 pt-5 mb-10">
          <label className="text-xl font-semibold capitalize">Case Type</label>
          <CaseTypeSelect caseId={props.caseId} currentCaseType={activeCaseData?.caseType} />
        </div>

        <div className="pt-5">
          <div className="mb-3 text-xl font-semibold capitalize">Attorney’s Fees</div>
          <div
            onClick={() => {
              createFeeAgreement.mutateAsync().then((res) => {
                setFeeAgreementState([
                  ...feeAgreementState,
                  {
                    description: '',
                    title: '',
                    currentlyApplicable: false,
                    feePercentage: 0,
                    attorneyFeeId: res.data?.attorneyFeeId,
                  },
                ]);
                setTriggerAnimation(true);
                setTimeout(() => {
                  setTriggerAnimation(false);
                }, 1000);
              });
            }}
            className="px-2 py-1 mb-3 text-gray-500 border border-gray-500 border-dashed rounded-lg shadow-lg cursor-pointer group hover:border-gray-800"
          >
            <div className="w-full h-96">
              <div className="w-full mx-auto ">
                <div className="pt-1 text-center group-hover:text-gray-800 hover:text-gray-800 ">
                  Add Attorney Fee
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative "
            style={{
              height: `${feeAgreementsLength * 55}px`,
            }}
          >
            {feeAgreementState &&
              feeAgreementState.map((feeAgreement: FeeAgreementType, index: number) => {
                if (index === feeAgreementsLength - 1) {
                  return (
                    <div
                      key={feeAgreement.attorneyFeeId}
                      className={
                        'absolute px-2 py-1 mb-3  w-full bg-white border border-gray-300 rounded-lg shadow-lg  ' +
                        (triggerAnimation ? ' animate__animated animate__fadeInDown ' : '')
                      }
                      style={{
                        marginTop: `-${365 - index * 50}px`,
                        zIndex: 49,
                      }}
                    >
                      <FeeAgreementForm editable={true} feeAgreement={feeAgreement} />
                      <div className="flex justify-end w-full">
                        <Button
                          variant="destructive"
                          onClick={() => {
                            setDeleteIndex(index);
                            setShowDeleteModal(true);
                          }}
                          className="px-3 py-1 mb-1.5"
                        >
                          Delete Fee
                        </Button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={feeAgreement.attorneyFeeId}
                      className="absolute w-full px-2 py-1 mb-3 bg-gray-100 border border-gray-300 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 "
                      onClick={() => {
                        setTriggerAnimation(true);
                        setFeeAgreementState([
                          ...swapIndices<FeeAgreementType>(
                            feeAgreementState,
                            index,
                            feeAgreementState.length - 1,
                          ),
                        ]);
                        setTimeout(() => {
                          setTriggerAnimation(false);
                        }, 1000);
                      }}
                      style={{
                        marginTop: `-${365 - index * 50}px`,
                        zIndex: 20 + index,
                      }}
                    >
                      {<FeeAgreementForm editable={false} feeAgreement={feeAgreement} />}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      {!isCocounsel && (
        <div className="float-right pb-10 mt-2">
          <Button
            variant="destructive"
            onClick={() => {
              setShowDeleteCaseModal(true);
            }}
            data-cy="delete-case-button"
          >
            Delete Case
          </Button>
        </div>
      )}
      <ActionConfirmModal
        open={showDeleteCaseModal}
        setOpen={setShowDeleteCaseModal}
        actionFunction={handleDelete}
        title="Delete Case"
        buttonText="Delete Case"
        description="Deleting a case in Gladiate will result in irreversible loss of all associated data and documentation. This action cannot be undone."
      />
    </>
  );
}

export default CaseSettings;
