import {
  GladiateLoader,
  SlideoverContext,
  Tabs,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { CaseType } from '@gladiate/types';
import { TabsContent } from '@radix-ui/react-tabs';
import { useContext, useEffect, useState } from 'react';
import Discovery from '../../cases/Discovery/Discovery';
import LitigationForm from '../../cases/LitigationForm/LitigationForm/LitigationForm';
import { casesSectionMappings, scrollToCaseSection } from '@colosseum/data';

export interface LitigationSectionProps {
  caseData?: CaseType;
}

export function LitigationSection(props: LitigationSectionProps) {
  const { caseData } = props;
  const [activeTab, setActiveTab] = useState('details');
  const { pendingSlideoverToOpen } = useContext(SlideoverContext);

  useEffect(() => {
    const ref = document.querySelectorAll(
      `[data-case-submenu-item='${casesSectionMappings.litigation}']`,
    )[0];

    if (pendingSlideoverToOpen?.type === 'discovery') {
      setActiveTab('discovery');
      scrollToCaseSection(ref, true);
    }

    if (pendingSlideoverToOpen?.type === 'deadlines') {
      setActiveTab('deadlines');
      scrollToCaseSection(ref, true);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'>
        <TabsTrigger className={tabTriggerClass} value="details">
          Details
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} value="deadlines">
          Deadlines
        </TabsTrigger>
        <TabsTrigger className={tabTriggerClass} value="discovery">
          Discovery
        </TabsTrigger>
      </TabsList>
      {caseData ? <LitigationForm caseData={caseData} /> : <GladiateLoader />}
      <TabsContent value="discovery">
        <Discovery caseId={caseData?.caseId ?? ''} />
      </TabsContent>
    </Tabs>
  );
}

export default LitigationSection;
