import {
  DefaultV3Response,
  LienType,
  MedicalTreatmentAppointment,
  MedicalTreatmentBill,
  MedicalTreatmentTypeType,
  PriorMedicalTreatmentType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

const baseHippocratesRoute = `hippocrates/${API_STAGE}`;

// #region ------------- LIENS ---------------------------------- //

// POST create_lien_v3
export const createLienV3 = (data: Partial<LienType> & { caseId: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<LienType>>(`${baseHippocratesRoute}/v3/cases/${caseId}/liens`, rest)
    .then((res) => res.data);
};
// DELETE delete_lien_v3
export const deleteLienV3 = (lienId: string) =>
  axiosInstance
    .delete<DefaultV3Response<LienType>>(`${baseHippocratesRoute}/v3/cases/liens/${lienId}`)
    .then((res) => res.data);

// GET get_all_liens_v3
export const getAllLiensV3 = () =>
  axiosInstance
    .get<DefaultV3Response<LienType[]>>(`${baseHippocratesRoute}/v3/cases/liens`)
    .then((res) => res.data);

// GET get_liens_v3
export const getLiensV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<LienType[]>>(`${baseHippocratesRoute}/v3/cases/${caseId}/liens`)
    .then((res) => res.data);

// PATCH update_lien_v3
export const updateLienV3 = (data: Partial<LienType> & { lienId: string }) => {
  const { lienId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<LienType>>(`${baseHippocratesRoute}/v3/cases/liens/${lienId}`, rest)
    .then((res) => res.data);
};
// #endregion

// #region ------------ MEDICAL TREATMENT BILLS ------------------- //

// POST create_medical_treatment_bills_v3
export const createMedicalTreatmentBillsV3 = (
  data: Partial<MedicalTreatmentBill> & { caseId: string },
) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<MedicalTreatmentBill>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/medical-treatment-bills`,
      rest,
    )
    .then((res) => res.data);
};

// DELETE delete_medical_treatment_bills_v3
export const deleteMedicalTreatmentBillsV3 = (treatmentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<MedicalTreatmentBill>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-bills/${treatmentId}`,
    )
    .then((res) => res.data);

// GET get_medical_treatments_bills_v3
export const getMedicalTreatmentBillsV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<MedicalTreatmentBill[]>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/medical-treatment-bills`,
    )
    .then((res) => res.data);

// GET get_all_medical_treatment_bills_v3
export const getAllMedicalTreatmentBillsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<MedicalTreatmentBill[]>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-bills`,
    )
    .then((res) => res.data);

// PATCH get_medical_treatments_bills_v3
export const updateMedicalTreatmentBillsV3 = (
  data: Partial<MedicalTreatmentBill> & { medicalTreatmentId: string },
) => {
  const { medicalTreatmentId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<MedicalTreatmentBill>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-bills/${medicalTreatmentId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ------------ MEDICAL TREATMENT TYPES ------------------- //

// POST create_medical_treatment_type_v3
export const createMedicalTreatmentTypeV3 = (data: Partial<MedicalTreatmentTypeType>) =>
  axiosInstance
    .post<DefaultV3Response<MedicalTreatmentTypeType>>(
      `${baseHippocratesRoute}/v3/medical-treatment-types`,
      data,
    )
    .then((res) => res.data);

// DELETE delete_medical_treatment_type_v3
export const deleteMedicalTreatmentTypeV3 = (medicalTreatmentTypeId: string) =>
  axiosInstance
    .delete<DefaultV3Response<MedicalTreatmentTypeType>>(
      `${baseHippocratesRoute}/v3/medical-treatment-types/${medicalTreatmentTypeId}`,
    )
    .then((res) => res.data);

// GET get_medical_treatment_types_v3
export const getMedicalTreatmentTypesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<MedicalTreatmentTypeType[]>>(
      `${baseHippocratesRoute}/v3/medical-treatment-types`,
    )
    .then((res) => res.data);

// PATCH update_medical_treatment_type_v3
export const updateMedicalTreatmentTypeV3 = (
  data: Partial<MedicalTreatmentTypeType> & { medicalTreatmentTypeId: string },
) => {
  const { medicalTreatmentTypeId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<MedicalTreatmentTypeType>>(
      `${baseHippocratesRoute}/v3/medical-treatment-types/${medicalTreatmentTypeId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region ------------ PRIOR MEDICAL TREATMENTS ------------------- //

// POST create_prior_medical_treatment_v3
export const createPriorMedicalTreatmentV3 = (
  data: Partial<MedicalTreatmentBill> & { caseId: string },
) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<PriorMedicalTreatmentType>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/prior-medical-treatments`,
      rest,
    )
    .then((res) => res.data);
};

// DELETE delete_prior_medical_treatment_v3
export const deletePriorMedicalTreatmentV3 = (treatmentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<PriorMedicalTreatmentType>>(
      `${baseHippocratesRoute}/v3/cases/prior-medical-treatments/${treatmentId}`,
    )
    .then((res) => res.data);

// GET get_prior_medical_treatments_v3
export const getPriorMedicalTreatmentsV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<PriorMedicalTreatmentType[]>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/prior-medical-treatments`,
    )
    .then((res) => res.data);

// PATCH update_prior_medical_treatment_v3
export const updatePriorMedicalTreatmentV3 = (
  data: Partial<PriorMedicalTreatmentType> & { priorMedicalTreatmentId: string },
) => {
  const { priorMedicalTreatmentId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<PriorMedicalTreatmentType>>(
      `${baseHippocratesRoute}/v3/cases/prior-medical-treatments/${priorMedicalTreatmentId}`,
      rest,
    )
    .then((res) => res.data);
};
//#endregion

//#region ------------  MEDICAL TREATMENT APPOINTMENTS ------------ //
// POST create_medical_treatment_appointment_v3
export const createMedicalTreatmentAppointmentV3 = (
  data: Partial<MedicalTreatmentAppointment> & { caseId: string },
) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<MedicalTreatmentAppointment>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/medical-treatment-appointments`,
      rest,
    )
    .then((res) => res.data);
};

// DEL delete_medical_treatment_appointment_v3
export const deleteMedicalTreatmentAppointmentV3 = (medicalTreatmentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<MedicalTreatmentAppointment>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-appointments/${medicalTreatmentId}`,
    )
    .then((res) => res.data);

// GET get_all_medical_treatment_appointments_v3
export const getAllMedicalTreatmentAppointmentsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<MedicalTreatmentAppointment[]>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-appointments`,
    )
    .then((res) => res.data);

// GET get_medical_treatment_appointments_v3
export const getMedicalTreatmentAppointmentsV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<MedicalTreatmentAppointment[]>>(
      `${baseHippocratesRoute}/v3/cases/${caseId}/medical-treatment-appointments`,
    )
    .then((res) => res.data);

// PUT update_medical_treatment_appointment_v3
export const updateMedicalTreatmentAppointmentV3 = (
  data: Partial<MedicalTreatmentAppointment> & {
    medicalTreatmentAppointmentId: string;
  },
) => {
  const { medicalTreatmentAppointmentId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<MedicalTreatmentAppointment>>(
      `${baseHippocratesRoute}/v3/cases/medical-treatment-appointments/${medicalTreatmentAppointmentId}`,
      rest,
    )
    .then((res) => res.data);
};
//#endregion
