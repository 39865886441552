import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { CaseStatusType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const columns: ColumnDef<CaseStatusType>[] = [
  {
    accessorFn: (row: CaseStatusType) => row.category || 'Uncategorized',
    id: 'Category',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) =>
      renderCell({
        props,
        valueModifierFunc: startCase,
      }),
  },
  {
    accessorFn: (row: CaseStatusType) => row.title,
    id: 'Title',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: CaseStatusType) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row: CaseStatusType) => row.goal && row.goal.toString(),
    id: 'Goal',
    sortUndefined: 1,
    header: renderHeader,
    aggregationFn: () => {},
    cell: (props) => renderCell({ props }),
  },
];
