import { displayContactName, makeAcronym, useGetContact } from '@colosseum/data';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { kebabCase } from 'lodash';
import { useState } from 'react';
import ContactInfoSlideover from '../ContactInfoSlideover/ContactInfoSlideover';
import ContactChipDetails from './ContactChipDetails';

/* eslint-disable-next-line */
export interface ContactChipProps {
  contactId?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  onDelete?: (e: React.SyntheticEvent) => void;
  dataCyTitle?: string;
  viewOnly?: boolean;
  contactSlideoverDelete?: () => void;
}

export function ContactChip(props: ContactChipProps) {
  const {
    onClick,
    onDelete,
    dataCyTitle,
    viewOnly = false,
    contactSlideoverDelete,
    contactId,
  } = props;
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { data: queriedContact, isLoading } = useGetContact(contactId);

  const contact = queriedContact?.data;

  if (!contactId) {
    return null;
  }

  if (isLoading && !contact) {
    return (
      <div className="w-32 h-20 px-1 text-atlantic-blue hover:text-white">
        <div className="flex w-full h-full gap-2 p-2 bg-gray-100 rounded-md animate-fade">
          <div className="flex items-center self-center w-5 h-5 bg-gray-200 rounded-full"></div>
          <div className="flex items-center self-center w-full h-full bg-gray-200 rounded-md"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContactInfoSlideover
        open={contactModalOpen}
        setOpen={setContactModalOpen}
        contact={contact}
        onDelete={contactSlideoverDelete}
      />
      <div
        data-cy={`contact-connection-${kebabCase(dataCyTitle)}-${displayContactName(contact)}`}
        className="relative flex items-center py-2 pl-2 pr-8 mt-1 mr-3 text-xs font-semibold border-t-2 rounded-md gap-y-2 gap-x-2 border-t-atlantic-blue bg-light-blue text-atlantic-blue"
      >
        {!viewOnly && (
          <button
            className="absolute text-atlantic-blue cursor-pointer top-0.5 right-0.5 hover:text-white"
            onClick={onDelete}
          >
            <XMarkIcon strokeWidth={1.8} className="size-4" />
          </button>
        )}
        <div
          onClick={(e) => {
            e.preventDefault();
            setContactModalOpen(true);
          }}
          className="flex items-center gap-2"
        >
          <div className="flex h-full p-1 text-white bg-gray-500 rounded-full ">
            {makeAcronym(displayContactName(contact), 2)}
          </div>
          <div className="hover:cursor-pointer">
            <ContactChipDetails contact={contact} onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactChip;
