import {
  DefaultV3Response,
  EventAttendeeType,
  EventCategoryType,
  EventReminderPayload,
  NylasAccountInfo,
  NylasCalendar,
  NylasCreateCalendarPayload,
  NylasEvent,
  NylasEventPayload,
  OfflineEventType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

const basePheidippidesRoute = `/pheidippides/${API_STAGE}/v3`;

export const getEventAndRemindersV3 = (eventId: string) =>
  axiosInstance
    .get<DefaultV3Response<NylasEvent>>(`${basePheidippidesRoute}/events/${eventId}`)
    .then((res) => res.data);

export const getUserCalendarsV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasCalendar[]>>(`${basePheidippidesRoute}/calendars`)
    .then((res) => res.data);

export const getAccountAuthUrlV3 = () =>
  axiosInstance
    .get<
      DefaultV3Response<{
        authUrl: string;
      }>
    >(`${basePheidippidesRoute}/accounts/auth`)
    .then((res) => res.data);

export const getUserNylasAccountV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasAccountInfo>>(`${basePheidippidesRoute}/accounts/account`)
    .then((res) => res.data);

export const getPrimaryCalendarV3 = () =>
  axiosInstance
    .get<DefaultV3Response<NylasCalendar>>(`${basePheidippidesRoute}/calendars/primary`)
    .then((res) => res.data);

export const connectNylasAccountV3 = (authCode: string) =>
  axiosInstance
    .post<DefaultV3Response<Partial<NylasAccountInfo>>>(`${basePheidippidesRoute}/accounts`, {
      authCode,
    })
    .then((res) => res.data);

export const createCalendarV3 = (data: NylasCreateCalendarPayload) =>
  axiosInstance
    .post<DefaultV3Response<NylasCalendar>>(`${basePheidippidesRoute}/calendars`, data)
    .then((res) => res.data);

export const getCaseEventsForCalendarV3 = ({
  calendarId,
  dateRange,
  caseId,
  primaryCalendar,
}: {
  calendarId: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  caseId?: string;
  primaryCalendar?: boolean;
}) =>
  axiosInstance
    .post<DefaultV3Response<NylasEvent[]>>(`${basePheidippidesRoute}/events/views/${caseId}`, {
      calendarId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      primaryCalendar: primaryCalendar ?? false,
    })
    .then((res) => res.data);

export const createCalendarEventV3 = (calendarId: string, data: Partial<NylasEventPayload>) =>
  axiosInstance
    .post<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events`,
      data,
    )
    .then((res) => res.data);

export const updateCalendarEventV3 = (
  calendarId: string,
  eventId: string,
  data: Partial<NylasEventPayload>,
) =>
  axiosInstance
    .patch<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events/${eventId}`,
      data,
    )
    .then((res) => res.data);

export const deleteCalendarEventV3 = (calendarId: string, eventId: string) =>
  axiosInstance
    .delete<DefaultV3Response<NylasEventPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events/${eventId}`,
    )
    .then((res) => res.data);

export const createEventReminderV3 = (data: {
  calendarId: string;
  eventId: string;
  reminders: EventReminderPayload;
}) => {
  const { calendarId, eventId, reminders } = data;
  return axiosInstance
    .post<DefaultV3Response<EventReminderPayload>>(
      `${basePheidippidesRoute}/calendars/${calendarId}/events/${eventId}/reminders`,
      reminders,
    )
    .then((res) => res.data);
};
// #region ------------------------- Offline Events -------------------------

export const getOfflineEventsV3 = ({ caseId, userId }: { caseId?: string; userId?: string }) =>
  axiosInstance
    .post<DefaultV3Response<OfflineEventType[]>>(`${basePheidippidesRoute}/offline/events/filter`, {
      caseId,
      userId,
    })
    .then((res) => res.data);

export const createOfflineEventV3 = (data: Partial<OfflineEventType>) =>
  axiosInstance
    .post<DefaultV3Response<OfflineEventType>>(`${basePheidippidesRoute}/offline/events`, data)
    .then((res) => res.data);

export const updateOfflineEventV3 = (data: { eventId: string } & Partial<OfflineEventType>) => {
  const { eventId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<OfflineEventType>>(
      `${basePheidippidesRoute}/offline/events/${eventId}`,
      rest,
    )
    .then((res) => res.data);
};

export const deleteOfflineEventV3 = (eventId: string) =>
  axiosInstance
    .delete<DefaultV3Response<OfflineEventType>>(
      `${basePheidippidesRoute}/offline/events/${eventId}`,
    )
    .then((res) => res.data);

// #endregion

// #region ------------------------- Event Categories -------------------------
export const getEventCategoriesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<EventCategoryType[]>>(`${basePheidippidesRoute}/events/categories`)
    .then((res) => res.data);

export const createEventCategoryV3 = (data: Partial<EventCategoryType>) =>
  axiosInstance
    .post<DefaultV3Response<EventCategoryType>>(`${basePheidippidesRoute}/events/categories`, data)
    .then((res) => res.data);

export const updateEventCategoryV3 = (
  data: {
    categoryId: string;
  } & Partial<EventCategoryType>,
) => {
  const { categoryId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<EventCategoryType>>(
      `${basePheidippidesRoute}/events/categories/${categoryId}`,
      rest,
    )
    .then((res) => res.data);
};

export const deleteEventCategoryV3 = (categoryId: string) =>
  axiosInstance
    .delete<DefaultV3Response<EventCategoryType>>(
      `${basePheidippidesRoute}/events/categories/${categoryId}`,
    )
    .then((res) => res.data);

// #endregion

// #region ------------------------- Event Attendees -------------------------

export const createEventAttendeeV3 = (data: { eventId: string } & Partial<EventAttendeeType>) => {
  const { eventId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<string>>(`${basePheidippidesRoute}/events/${eventId}/attendees`, rest)
    .then((res) => res.data);
};

export const deleteEventAttendeeV3 = (attendeeId: string) => {
  return axiosInstance
    .delete<DefaultV3Response<string>>(`${basePheidippidesRoute}/events/attendees/${attendeeId}`)
    .then((res) => res.data);
};
