import {
  getNameToDisplayFromCognito,
  getUser,
  useDeleteTaskComment,
  useGetFirmUsers,
  useUpdateTaskComment,
} from '@colosseum/data';
import { TaskCommentType } from '@gladiate/types';
import { EllipsisHorizontalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface ResourceTaskCommentProps {
  comment: TaskCommentType;
}

const formSchema = z.object({
  message: z.string().nonempty(),
});

export function ResourceTaskComment(props: ResourceTaskCommentProps) {
  const { comment } = props;
  const user = getUser();
  const firmUsersQuery = useGetFirmUsers(true);
  const [ellipsisOpen, setEllipsisOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteTaskComment = useDeleteTaskComment();
  const updateTaskComment = useUpdateTaskComment();
  const firmUsersData = firmUsersQuery.data?.data;
  const isOwnComment = comment.username === user;

  const handleDeleteComment = () => {
    return deleteTaskComment.mutateAsync(comment.taskCommentId);
  };

  const handleUpdateComment = () => {
    return updateTaskComment
      .mutateAsync({
        taskCommentId: comment.taskCommentId,
        data: {
          message: commentForm.getValues('message'),
        },
      })
      .then(() => setEditMode(false));
  };

  const commentForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: comment.message ?? '',
    },
  });

  return (
    <div
      className="flex flex-col border-b first:pt-0 last:border-b-0 border-b-gray-300"
      key={comment.taskCommentId}
    >
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={handleDeleteComment}
        actionFunctionIsMutation={true}
        title={'Delete Comment'}
      />
      <div className="flex items-center gap-2 pt-4 ">
        <ProfilePhoto
          className="self-center rounded-none min-w-6"
          loading={false}
          diameter={6}
          displayName={comment.username}
        />
        <div className="flex flex-col">
          <div className="flex flex-row gap-x-2">
            <div className="text-md">
              {getNameToDisplayFromCognito(
                firmUsersData?.find((firmUser) => comment.username === firmUser?.Username),
              )}
            </div>
            <div className="self-center text-xs text-gray-500 whitespace-nowrap">
              {dayjs(`${comment?.dateCreated ?? ''}Z`).calendar()}
            </div>
            {isOwnComment && (
              <Popover modal open={ellipsisOpen} onOpenChange={setEllipsisOpen}>
                <PopoverTrigger>
                  <div className="text-sm text-gray-500">
                    <EllipsisHorizontalIcon className="w-5 h-5" />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="flex flex-col">
                    <Button
                      variant="ghost"
                      textColor="gray"
                      className="justify-start p-4 rounded-b-none"
                      onClick={() => {
                        setEllipsisOpen(false);
                        setEditMode(true);
                      }}
                    >
                      {deleteTaskComment.isLoading ? (
                        <GladiateLoader height={20} />
                      ) : (
                        <div className="flex gap-x-1">
                          <PencilIcon className="w-4 h-4" />
                          <div>Edit</div>
                        </div>
                      )}
                    </Button>
                    <Button
                      variant="ghost"
                      textColor="gray"
                      className="justify-start p-4 rounded-t-none"
                      onClick={() => {
                        setEllipsisOpen(false);
                        setShowDeleteModal(true);
                      }}
                    >
                      {deleteTaskComment.isLoading ? (
                        <GladiateLoader height={20} />
                      ) : (
                        <div className="flex gap-x-1">
                          <TrashIcon className="w-4 h-4 text-red-500" />
                          <div>Delete</div>
                        </div>
                      )}
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
      </div>
      {editMode ? (
        <Form {...commentForm}>
          <form onSubmit={commentForm.handleSubmit(handleUpdateComment)} className="pt-6">
            <TextFormInput
              title="Edit Comment"
              type="textarea"
              {...commentForm.register('message')}
            />
            <div className="float-right">
              {setEditMode && (
                <Button
                  className="mt-2 mr-2 h-[36px] border border-gray-300 rounded-lg"
                  onClick={() => setEditMode(false)}
                  type="button"
                >
                  Cancel
                </Button>
              )}
              <Button
                disabled={updateTaskComment.isLoading}
                className="mt-2 p-2 focus:outline-none rounded-lg min-w-[75px] h-9 hover:text-light-blue bg-light-blue hover:bg-atlantic-blue text-atlantic-blue cursor-pointer"
                type="submit"
              >
                {updateTaskComment.isLoading ? <GladiateLoader height={20} /> : <div>Update</div>}
              </Button>
            </div>
          </form>
        </Form>
      ) : (
        <div className="py-4">{comment.message}</div>
      )}
    </div>
  );
}

export default ResourceTaskComment;
