import {
  CaseContactConnectionType,
  MedicalTreatmentBill,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { findRoleSpecificCaseContactConnections, useGetCaseContactConnections } from '..';

export const useGetBillingEntityIdForTreatment = (caseId: string) => {
  //TODO: Replace all instances of useGetBillingEntityIdForTreatment with useGetRoleIdForItem
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');
  const billingEntities = findRoleSpecificCaseContactConnections<CaseContactConnectionType>({
    caseContactConnections: caseContactConnectionsData?.data,
    role: caseContactConnectionOptions.billingEntity,
  });
  const getBillingEntityIdForTreatment = (treatment: MedicalTreatmentBill) =>
    billingEntities.find((billingEntity) => {
      const matchingRole = billingEntity.roles.find(
        (role) => role.itemId === treatment.medicalTreatmentId,
      );
      return matchingRole;
    })?.contactId || 'Not Specified';
  return getBillingEntityIdForTreatment;
};

export const useGetRoleIdForItem = <T>(caseId: string) => {
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');
  const getRoleIdForItem = (
    item: T,
    role: keyof typeof caseContactConnectionOptions,
    key: keyof T,
  ) => {
    const roleToMatch = role;
    const roleEntities = findRoleSpecificCaseContactConnections<CaseContactConnectionType>({
      caseContactConnections: caseContactConnectionsData?.data,
      role: role,
    });
    return (
      roleEntities.find((roleEntity) => {
        const matchingRole = roleEntity.roles.find(
          (role) => role.itemId === item[key] && role.roleOnCase === roleToMatch,
        );
        return matchingRole;
      })?.contactId || 'Not Specified'
    );
  };
  return getRoleIdForItem;
};

export const useGetRoleIdListForItem = <T>(caseId: string) => {
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');
  const getRoleIdListForItem = (
    item: T,
    role: keyof typeof caseContactConnectionOptions,
    key: keyof T,
  ) => {
    const roleToMatch = role;
    const caseContactConnections =
      findRoleSpecificCaseContactConnections<CaseContactConnectionType>({
        caseContactConnections: caseContactConnectionsData?.data,
        role: role,
      });
    return caseContactConnections.filter((caseContactConnection) => {
      const matchingRole = caseContactConnection.roles.find(
        (role) => role.itemId === item[key] && role.roleOnCase === roleToMatch,
      );
      return matchingRole;
    });
  };
  return getRoleIdListForItem;
};
