import {
  CaseStatusType,
  CaseStatusUpdateType,
  CaseType,
  CaseTypeType,
  DefaultAttorneyFeeType,
  DefaultV3Response,
  FeeAgreementType,
  LinkedCaseType,
  PresetFilterType,
} from '@gladiate/types';
import { VITE_API_STAGE } from '../../static/importMetaEnv';
import { axiosInstance } from '../https';

const API_STAGE = VITE_API_STAGE;

export const baseAppealsRoute = `appeals/${API_STAGE}`;

// ------------------ Case ------------------
// #region
// POST create_case_v3
// NOTE: Can pass caseStatusId to this to create a caseStatusUpdate
export const createCaseV3 = (data: Partial<CaseType> & { caseStatusId?: string }) =>
  axiosInstance
    .post<DefaultV3Response<CaseType>>(`${baseAppealsRoute}/v3/cases`, data)
    .then((res) => res.data);

// DEL delete_case_v3
export const deleteCaseV3 = (caseId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseType>>(`${baseAppealsRoute}/v3/cases/${caseId}`, {})
    .then((res) => res.data);

// GET get_case_v3
export const getCaseV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<CaseType>>(`${baseAppealsRoute}/v3/cases/${caseId}`)
    .then((res) => res.data);

// GET get_cases_v3
export const getCasesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseType[]>>(`${baseAppealsRoute}/v3/cases`)
    .then((res) => res.data);

// POST get_selected_cases_v3
export const getSelectedCasesV3 = (cases: string[]) =>
  axiosInstance
    .post<DefaultV3Response<CaseType[]>>(`${baseAppealsRoute}/v3/cases/selected-cases`, {
      cases: cases,
    })
    .then((res) => res.data);

// PATCH update_case_v3
export const updateCaseV3 = (data: CaseType) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CaseType>>(`${baseAppealsRoute}/v3/cases/${caseId}`, rest)
    .then((res) => res.data);
};
// #endregion
// ------------------ Case Status Update ------------------
// #region
// GET get_all_case_status_updates_v3
export const getAllCaseStatusUpdatesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseStatusUpdateType[]>>(`${baseAppealsRoute}/v3/cases/status-updates`)
    .then((res) => res.data);

// POST create_case_status_update_v3
export const createCaseStatusUpdateV3 = (data: { caseId: string; caseStatusId: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<CaseType>>(
      `${baseAppealsRoute}/v3/cases/${caseId}/status-updates`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion
// ------------------ Case Status ------------------
// #region
// POST create_case_status_v3
export const createCaseStatusV3 = (data: {
  title?: CaseStatusType['title'];
  description?: CaseStatusType['description'];
  permanent?: CaseStatusType['permanent'];
  position?: CaseStatusType['position'];
  category?: CaseStatusType['category'];
}) =>
  axiosInstance
    .post<DefaultV3Response<CaseStatusType>>(`${baseAppealsRoute}/v3/case-statuses`, data)
    .then((res) => res.data);

// DEL delete_case_status_v3
export const deleteCaseStatusV3 = (caseStatusId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseStatusType>>(
      `${baseAppealsRoute}/v3/case-statuses/${caseStatusId}`,
    )
    .then((res) => res.data);

// GET get_case_statuses_v3
export const getCaseStatusesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseStatusType[]>>(`${baseAppealsRoute}/v3/case-statuses`)
    .then((res) => res.data);

// PATCH update_case_status_v3
export const updateCaseStatusV3 = (data: CaseStatusType) => {
  const { caseStatusId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CaseStatusType>>(
      `${baseAppealsRoute}/v3/case-statuses/${caseStatusId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion
// ------------------ Case Type ------------------
// #region
// POST create_case_type_v3
export const createCaseTypeV3 = (data?: {
  title?: CaseTypeType['title'];
  description?: CaseTypeType['description'];
  permanent?: CaseTypeType['permanent'];
}) =>
  axiosInstance
    .post<DefaultV3Response<CaseTypeType>>(`${baseAppealsRoute}/v3/case-types`, data)
    .then((res) => res.data);

// DEL delete_case_type_v3
export const deleteCaseTypeV3 = (caseTypeId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseTypeType>>(`${baseAppealsRoute}/v3/case-types/${caseTypeId}`)
    .then((res) => res.data);

// GET get_case_types_v3
export const getCaseTypesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<CaseTypeType[]>>(`${baseAppealsRoute}/v3/case-types`)
    .then((res) => res.data);

// PATCH update_case_type_v3
export const updateCaseTypeV3 = (data: CaseTypeType) => {
  const { caseTypeId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<CaseTypeType>>(`${baseAppealsRoute}/v3/case-types/${caseTypeId}`, rest)
    .then((res) => res.data);
};
// #endregion

// ------------------ Default Fee Agreement ------------------
// #region
export const defaultAttorneyFeeRoute = `${baseAppealsRoute}/v3/default-attorney-fees`;

// GET get_default_attorney_fees_v3
export const getDefaultAttorneyFeesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<DefaultAttorneyFeeType[]>>(defaultAttorneyFeeRoute)
    .then((res) => res.data);

// POST create_default_attorney_fee_v3
export const createDefaultAttorneyFeeV3 = () =>
  axiosInstance
    .post<DefaultV3Response<DefaultAttorneyFeeType>>(defaultAttorneyFeeRoute)
    .then((res) => res.data);

// PATCH update_default_attorney_fee_v3
export const updateDefaultAttorneyFeeV3 = (data: DefaultAttorneyFeeType) => {
  const { defaultAttorneyFeeId, ...rest } = data;
  return axiosInstance.patch(`${defaultAttorneyFeeRoute}/${defaultAttorneyFeeId}`, rest);
};

// DEL delete_default_attorney_fee_v3
export const deleteDefaultAttorneyFeeV3 = (defaultAttorneyFeeId: string) =>
  axiosInstance.delete(`${defaultAttorneyFeeRoute}/${defaultAttorneyFeeId}`);
// #endregion
// ------------------ Attorney Fee ------------------
// #region
// GET create_attorney_fee_v3
export const getCaseAttorneyFeesV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<FeeAgreementType[]>>(
      `${baseAppealsRoute}/v3/cases/${caseId}/attorney-fees`,
    )
    .then((res) => res.data);

// POST create_attorney_fee_v3
export const createCaseAttorneyFeeV3 = (caseId: string) =>
  axiosInstance
    .post<DefaultV3Response<FeeAgreementType>>(
      `${baseAppealsRoute}/v3/cases/${caseId}/attorney-fees`,
    )
    .then((res) => res.data);

// PATCH update_attorney_fee_v3
export const updateCaseAttorneyFeeV3 = (data: FeeAgreementType) => {
  const { attorneyFeeId, ...rest } = data;
  return axiosInstance.patch(`${baseAppealsRoute}/v3/cases/attorney-fees/${attorneyFeeId}`, rest);
};

// DEL delete_attorney_fee_v3
export const deleteCaseAttorneyFeeV3 = (attorneyFeeId: string) =>
  axiosInstance.delete(`${baseAppealsRoute}/v3/cases/attorney-fees/${attorneyFeeId}`);
// #endregion
// ------------------ Preset Filter ------------------
// #region
// POST create_preset_filter_v3
export const createPresetFilterV3 = (
  data: Partial<PresetFilterType> & { title: PresetFilterType['title'] },
) =>
  axiosInstance
    .post<DefaultV3Response<PresetFilterType>>(`${baseAppealsRoute}/v3/preset-filters`, data)
    .then((res) => res.data);

// DEL delete_preset_filter_v3
export const deletePresetFilterV3 = (presetFilterId: string) =>
  axiosInstance
    .delete<DefaultV3Response<PresetFilterType>>(
      `${baseAppealsRoute}/v3/preset-filters/${presetFilterId}`,
    )
    .then((res) => res.data);

// GET get_preset_filters_v3
export const getPresetFiltersV3 = () =>
  axiosInstance
    .get<DefaultV3Response<PresetFilterType[]>>(`${baseAppealsRoute}/v3/preset-filters`)
    .then((res) => res.data);

// PATCH update_preset_filter_v3
export const updatePresetFilterV3 = (data: Partial<PresetFilterType>) => {
  const { presetFilterId, ...rest } = data;
  return axiosInstance
    .put<DefaultV3Response<PresetFilterType>>(
      `${baseAppealsRoute}/v3/preset-filters/${presetFilterId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion
// ------------------ Links ------------------

// POST link_cases_v3
export const postLinkCasesV3 = (data: { caseIdOne: string; caseIdTwo: string }) =>
  axiosInstance
    .post<
      DefaultV3Response<{
        linkId: string;
        primaryCase: { caseId: string; linkId: string };
        secondaryCase: { caseId: string; linkId: string };
        conflict: boolean;
      }>
    >(`${baseAppealsRoute}/v3/cases/links`, data)
    .then((res) => res.data);

// ------------------ Linked Cases ------------------
// #region
// POST create_linked_case_v3
export const createLinkedCaseV3 = (data: { caseId: string; linkedCaseId: string }) =>
  axiosInstance
    .post<DefaultV3Response<unknown>>(`${baseAppealsRoute}/v3/cases/${data.caseId}/linked-cases`, {
      relatedCaseId: data.linkedCaseId,
    })
    .then((res) => res.data);

// GET get_linked_cases_v3
export const getLinkedCasesV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<LinkedCaseType[]>>(`${baseAppealsRoute}/v3/cases/${caseId}/linked-cases`)
    .then((res) => res.data);

// DEL delete_linked_case_v3
export const deleteLinkedCaseV3 = (linkedCaseId: string) =>
  axiosInstance.delete<DefaultV3Response<unknown>>(
    `${baseAppealsRoute}/v3/cases/linked-cases/${linkedCaseId}`,
  );
// #endregion
