import { AuditWithNotificationsType } from '@gladiate/types';
import { createColumnHelper } from '@tanstack/react-table';
import { dateRangeFilterFn } from '../shadcn/data-table/table-filter-helpers';

const columnHelper = createColumnHelper<AuditWithNotificationsType>();

export const columns = [
  columnHelper.accessor((row) => row.type, {
    id: 'Type',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor((row) => row?.auditId?.split('-')[0], {
    id: 'Case',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor((row) => row.dateModified ?? row.dateCreated, {
    id: 'Last Modified',
    filterFn: dateRangeFilterFn,
  }),
];
