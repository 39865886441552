import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { COURTS_CSV } from '../static/courts';

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const copyToClipboard = (text: string, e?: React.SyntheticEvent) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (!navigator.clipboard) {
    console.error('Clipboard API not available');
    return;
  }

  navigator.clipboard.writeText(text).then(
    () =>
      enqueueSnackbar(`Copied '${text}' to clipboard`, {
        variant: 'success',
        key: `copy-${text}`,
        preventDuplicate: true,
      }),
    (err) => console.error('Could not copy text: ', err),
  );
};

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getAbsoluteUrl = (url: string) => {
  if (!url) return '';

  if (!url.match(/^https?:\/\//i)) {
    return `https://${url}`;
  }
  return url;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export function formatPhoneNumber(number: string) {
  if (!number) return '';
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return number;
}

export function convertToE164(phoneNumber?: string) {
  if (!phoneNumber) return undefined;
  const number = phoneNumber.replace(/[^0-9]/g, '');
  const length = number.length;

  if (length === 10) {
    return `+1${number}`;
  } else if (length === 11) {
    return `+${number}`;
  } else {
    return number;
  }
}

export function sortValuesByOrder(values: string[], order: string[]): string[] {
  return values?.sort((a, b) => {
    const aIndex = order?.indexOf(a);
    const bIndex = order?.indexOf(b);

    // Both exist in order array
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // a exists but not b
    if (aIndex !== -1) {
      return -1;
    }

    // b exists but not a
    if (bIndex !== -1) {
      return 1;
    }

    // Neither exist
    return 0;
  });
}

export function removeNonNumericCharacters(number: string) {
  return number.replace(/\D/g, '');
}

export const hexToRGBA = (hex: string, opacity: number) => {
  let r = '0',
    g = '0',
    b = '0';

  // 3 digits
  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }

  return `rgba(${+r},${+g},${+b},${opacity})`;
};

interface CourtData {
  [state: string]: {
    [type: string]: string[];
  };
}

export const processCourtCsv = (): CourtData => {
  const lines = COURTS_CSV.split('\n');
  const courtData: CourtData = {};

  // Skip the header row by starting from index 1
  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (!line) continue;

    const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/g;
    const [state, type, court] = line
      .split(regex)
      .map((value) => value.replace(/^"|"$/g, '').trim());

    if (!courtData[state]) {
      courtData[state] = {};
    }

    if (!courtData[state][type]) {
      courtData[state][type] = [];
    }

    courtData[state][type].push(court);
  }

  return courtData;
};

export const URL_REGEX = new RegExp(
  // protocol identifier (optional)
  // short syntax // still required
  '(?:(?:(?:https?|ftp):)?\\/\\/)' +
    // user:pass BasicAuth (optional)
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    '(?:' +
    '(?:' +
    '[a-z0-9\\u00a1-\\uffff]' +
    '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
    ')?' +
    '[a-z0-9\\u00a1-\\uffff]\\.' +
    ')+' +
    // TLD identifier name, may end with dot
    '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    ')' +
    // port number (optional)
    '(?::\\d{2,5})?' +
    // resource path (optional)
    '(?:[/?#]\\S*)?',
  'i',
);

export const debounceApiRequest = (fn: any) => {
  return debounce(fn, 500);
};

/**
 *
 * @@description This function will split a string by commas, ignoring any comma that is escaped with a backslash
 * @returns
 */
export const commaSafeSplit = (str?: string | null, keepEscapeCharacter?: boolean) => {
  return (
    str
      ?.replace(/\\,/g, '{COMMA}')
      .split(',')
      .map(function (v) {
        if (keepEscapeCharacter) {
          return v.replace(/{COMMA}/g, '\\,');
        }
        return v.replace(/{COMMA}/g, ',');
      }) ?? []
  );
};

/**
 *
 * @@description This function will escape any commas in a string with a backslash
 * @returns
 */
export const escapeCommasInParam = (param: string) => {
  return param.replace(/,/g, '\\,');
};

/**
 * @description This function returns a flattened array of objects based on the passed in key
 */
export function flattenArrayByKey<T, U = void>(array: T[], key: string): (T & U)[] {
  return array.reduce((acc, item) => {
    const nestedArray = item[key as keyof T] as Array<U> | undefined;
    if (nestedArray && nestedArray?.length > 0) {
      return acc.concat(...nestedArray.map((subItem) => ({ ...item, ...subItem })));
    } else {
      return acc.concat(item as T & U);
    }
  }, [] as (T & U)[]);
}

/**
 *
 * @param value
 * @description This function will parse a string value to an integer
 */
export function parseIntFormValues(value: any) {
  if (value !== undefined) {
    return parseInt(value);
  }
  return 0;
}

/**
 *
 * @param minutes
 * @description This function will round a number to the nearest 15 minutes
 */
export function roundToNearest15Minutes(minutes: number) {
  minutes = Math.floor(minutes) || 0;
  const remainder = minutes % 15;
  return remainder > 7 ? minutes + (15 - remainder) : minutes - remainder;
}

/**
 *
 * @param start in 24 hr format
 * @param end in 24 hr format
 * @param step in minute increments
 * @returns an array of timestamps in 24 hr format
 */
export function generateTimeStamps(start: number, end: number, step: number) {
  const startTime = start * 60;
  const endTime = end * 60;
  const timestamps: { [key: string]: string } = {};
  for (let minute = startTime; minute < endTime; minute += step) {
    const hour = Math.floor(minute / 60);
    const formattedMinute = minute % 60;
    const period = hour >= 12 ? 'PM' : 'AM';

    const clockHour = hour % 12 || 12;
    const militaryHour = hour.toString().padStart(2, '0');

    const fullTime12Hour = `${clockHour}:${formattedMinute.toString().padStart(2, '0')} ${period}`;

    const fullTime24Hour = `${militaryHour}:${formattedMinute.toString().padStart(2, '0')}`;

    timestamps[fullTime12Hour] = fullTime24Hour;
  }

  return timestamps;
}

/**
 *
 * @param offset15Interval how many 15 minute intervals to offset the current time by
 * @returns string of the current time rounded to the last 15 minute quarter of the hour
 */
export function getCurrentTimeRoundedTo15({
  offset15Interval = '0',
}: {
  offset15Interval: '0' | '1' | '2' | '3' | '4';
}) {
  const now = new Date();
  const minutes = now.getMinutes();
  const offset15IntervalNum = parseInt(offset15Interval);

  if (minutes < 15) {
    now.setMinutes(0 + offset15IntervalNum * 15);
  } else if (minutes < 30) {
    now.setMinutes(15 + offset15IntervalNum * 15);
  } else if (minutes < 45) {
    now.setMinutes(30 + offset15IntervalNum * 15);
  } else {
    now.setMinutes(45 + offset15IntervalNum * 15);
  }
  return now.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
}

/**
 *
 * @param arr
 * @param index1
 * @param index2
 * @returns the original array with the values at index1 and index2 swapped
 */
export function swapIndices<T = any>(arr: T[], index1: number, index2: number) {
  if (index1 < 0 || index2 < 0 || index1 >= arr.length || index2 >= arr.length) {
    return arr;
  }
  const temp = arr[index1];
  arr[index1] = arr[index2];
  arr[index2] = temp;
  return arr;
}
