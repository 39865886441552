import { CoCounselType, HurinUserType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateCoCounsel } from '../../../lib/react-query/firm';
import { useUpdateCognitoUserForm } from './useUpdateCognitoUserForm';

const formSchema = z.object({
  userName: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  groupId: z.string().optional(),
  cocounselName: z.string().optional().optional(),
  cocounselFirmAddress: z.string().optional(),
  cocounselFirmCity: z.string().optional(),
  cocounselFirmState: z.string().optional(),
  cocounselFirmZip: z.string().optional(),
});

const updatableKeys = [
  'cocounselName',
  'cocounselFirmAddress',
  'cocounselFirmCity',
  'cocounselFirmState',
  'cocounselFirmZip',
];

export const useUpdateCoCounselForm = (user?: HurinUserType & CoCounselType) => {
  const updateCoCounsel = useUpdateCoCounsel();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
    values: { ...user, userName: user?.username ?? '' },
  });

  const { handleUpdate: handleUpdateCognitoUser, isLoading: isUpdateCognitoUserLoading } =
    useUpdateCognitoUserForm(user, form.getValues());

  const handleUpdateCoCounsel = (name: string, value?: string | number | string[]) => {
    const username = form.getValues('userName');
    if (
      updatableKeys.includes(name) &&
      username
      // && form.getFieldState(name).isDirty
    ) {
      return updateCoCounsel.mutateAsync({
        [name]: value,
        username,
      });
    } else {
      return handleUpdateCognitoUser(name, value);
    }
  };

  return {
    form,
    handleUpdate: handleUpdateCoCounsel,
    isLoading: isUpdateCognitoUserLoading || updateCoCounsel.isLoading,
  };
};
