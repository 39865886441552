import { cn, getNameToDisplayFromCognito, useGetFirmUsers } from '@colosseum/data';
import { UserPlusIcon } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '../shadcn/Select/Select';

/* eslint-disable-next-line */
export interface FirmUserDropdownMenuProps {
  handleChange: (value: string) => void;
  title?: string | React.ReactNode;
  selectedUsers: string[];
  dataCy?: string;
  hideLabel?: boolean;
  triggerClassName?: string;
}

export function FirmUserDropdownMenu(props: FirmUserDropdownMenuProps) {
  const { handleChange, title, selectedUsers, dataCy, hideLabel, triggerClassName } = props;
  const textDisplay = (
    <div>
      {title ?? (
        <div className="flex">
          <UserPlusIcon className="w-4 h-4 mr-1" />
          Assign user
        </div>
      )}
    </div>
  );
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  const firmUsers =
    firmUsersData?.map((user) => {
      const displayName = getNameToDisplayFromCognito(user);
      return {
        displayName,
        value: user?.Username,
      };
    }) ?? [];
  return (
    <Select name={dataCy ?? 'firm-dropdown'} onValueChange={handleChange}>
      <SelectTrigger
        data-cy={dataCy ?? 'firm-dropdown-button'}
        className={cn('w-auto h-5 p-0 border-none', triggerClassName)}
        hideCaret
      >
        {title}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {!hideLabel && <SelectLabel>{textDisplay}</SelectLabel>}
          {firmUsers?.map((item) => (
            <SelectItem
              key={item.value}
              value={item.value ?? ''}
              noIndicator
              disabled={selectedUsers?.includes(item.value ?? '')}
            >
              {item.displayName}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default FirmUserDropdownMenu;
