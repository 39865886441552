/* eslint-disable @typescript-eslint/no-empty-function */
import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createItemConnectionForScenarioV3,
  createPolicyForScenarioV3,
  createVehicleForScenarioV3,
  deleteItemConnectionForScenarioV3,
  deletePolicyV3,
  deleteVehicleV3,
  getScenarioForCaseV3,
  getVehiclesV3,
  updatePolicyForScenarioV3,
  updateVehicleForScenarioV3,
} from '../requests/petorritum';

export const useGetScenarioForCase = (caseId: string) =>
  useQuery({
    queryKey: [queryKeys.scenario, caseId],
    queryFn: async () => getScenarioForCaseV3(caseId),
    enabled: !!caseId,
  });

export const useCreatePolicyForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createPolicyForScenarioV3>[0]) =>
      createPolicyForScenarioV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useUpdatePolicyForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updatePolicyForScenarioV3>[0]) =>
      updatePolicyForScenarioV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useUpdateVehicleForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateVehicleForScenarioV3>[0]) =>
      updateVehicleForScenarioV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data.meta.userMsg) {
        enqueueAPISnackbar({
          message: error.response.data.meta.userMsg,
          variant: 'error',
        });
      }
    },
  });
};

export const useCreateVehicleForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createVehicleForScenarioV3>[0]) =>
      createVehicleForScenarioV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useCreateItemConnectionForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof createItemConnectionForScenarioV3>[0]) =>
      createItemConnectionForScenarioV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useDeleteItemConnectionForScenario = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteItemConnectionForScenarioV3(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useDeletePolicy = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deletePolicyV3(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useDeleteVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteVehicleV3(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.scenario],
      });
    },
  });
};

export const useGetAllVehicles = (enabled: boolean) => {
  return useQuery({
    queryKey: [queryKeys.vehicles],
    queryFn: async () => getVehiclesV3(),
    enabled,
  });
};
