import {
  ScenarioComplete,
  displayContactName,
  getCaseContactConnectionRoleIdForParty,
  getChipsForParty,
  makeAcronym,
  useCreateCaseContactConnection,
  useCreateItemConnectionForScenario,
  useCreatePolicyForScenario,
  useCreateVehicleForScenario,
  useDeleteCaseContactConnectionRole,
  useGetContactsInfinite,
  useGetPoliciesWithInsurer,
  useGetRoleIdForItem,
  useUpdateCaseContactConnection,
} from '@colosseum/data';
import { ActionConfirmModal, Button, Chip, EntityAddPopover } from '@colosseum/shared-ui';
import {
  CaseContactConnectionType,
  ContactType,
  PolicyType,
  VehicleType,
  partiesAndPoliciesConnectionOptions,
} from '@gladiate/types';
import { ChevronDownIcon, ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { startCase, uniq } from 'lodash';
import { TrashIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PolicyTitle } from './PolicyCard';
import LinkedPoliciesTable from './linked-entity-tables/LinkedPoliciesTable';
import LinkedVehiclesTable from './linked-entity-tables/LinkedVehiclesTable';

export interface PartyCardProps {
  caseId: string;
  contact: ContactType & CaseContactConnectionType;
  scenario?: ScenarioComplete;
  setSelectedPolicyId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedVehicleId: Dispatch<SetStateAction<string | undefined>>;
  setPolicySlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setVehicleSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setCaseContactConnectionSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedConnectionId: Dispatch<SetStateAction<string | undefined>>;
  handleLinkedPoliciesRowClick: (
    row: Row<PolicyType>,
    parentEntity: {
      type: 'party';
      id: string;
    },
  ) => void;
  handleLinkedVehiclesRowClick: (
    row: Row<VehicleType>,
    parentEntity: {
      type: 'party';
      id: string;
    },
  ) => void;
}

export const PartyCard = (props: PartyCardProps) => {
  const {
    caseId,
    contact,
    scenario,
    setSelectedPolicyId,
    setSelectedVehicleId,
    setPolicySlideoverOpen,
    setVehicleSlideoverOpen,
    setCaseContactConnectionSlideoverOpen,
    setSelectedConnectionId,
    handleLinkedPoliciesRowClick,
    handleLinkedVehiclesRowClick,
  } = props;
  const contactInitials = makeAcronym(displayContactName(contact), 2);

  const [policyTableOpen, setPolicyTableOpen] = useState(false);
  const [vehicleTableOpen, setVehicleTableOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [partyToUnlink, setPartyToUnlink] = useState<ContactType & CaseContactConnectionType>();
  const [policiesLoading, setPoliciesLoading] = useState('');
  const [vehiclesLoading, setVehiclesLoading] = useState('');

  // Data fetching
  const removeRole = useDeleteCaseContactConnectionRole();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const createPolicy = useCreatePolicyForScenario();
  const createVehicle = useCreateVehicleForScenario();
  const createItemConnection = useCreateItemConnectionForScenario();
  const updateCaseContactConnection = useUpdateCaseContactConnection();
  const policiesWithInsurer = useGetPoliciesWithInsurer(caseId, scenario?.policies);
  const { data: allContactsData } = useGetContactsInfinite();
  const allContacts = allContactsData?.data;
  const getRoleIdForItem = useGetRoleIdForItem<PolicyType>(caseId);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: { liabilityStatus: contact.liabilityStatus },
  });

  const connectionsForContact = scenario?.itemConnections?.filter((itemConnection) => {
    return itemConnection.partyId === getCaseContactConnectionRoleIdForParty(contact);
  });

  const itemConnectionVehicles =
    connectionsForContact
      ?.filter((itemConnection) => itemConnection.partyId && itemConnection.vehicleId)
      ?.map((vehicleConnection) =>
        scenario?.vehicles?.find((vehicle) => vehicle.vehicleId === vehicleConnection.vehicleId),
      ) ?? [];

  const itemConnectionPolicies =
    connectionsForContact
      ?.filter((itemConnection) => itemConnection.partyId && itemConnection.policyId)
      ?.map((policyConnection) =>
        policiesWithInsurer?.find((policy) => policy?.policyId === policyConnection.policyId),
      ) ?? [];

  const chips = getChipsForParty(contact.roles);

  const handleRoleChange = (party: ContactType & CaseContactConnectionType, item: string[]) => {
    // if already has role, remove it
    if (party?.roles?.some((role) => item.includes(role.roleOnCase ?? ''))) {
      const roleToDelete = party?.roles.filter((role) => item.includes(role.roleOnCase ?? ''));
      roleToDelete?.forEach((role) => {
        if (party?.caseContactConnectionId && role) {
          removeRole.mutate(role.caseContactConnectionRoleId);
        }
      });
    } else {
      item.forEach((role) => {
        createCaseContactConnection.mutate({
          caseId: caseId ?? '',
          contactId: party?.contactId,
          roleOnCase: role,
        });
      });
    }
  };

  return (
    <div className="relative py-7">
      <div className="absolute flex items-center gap-2 top-3 right-3">
        <Button
          onClick={() => {
            setCaseContactConnectionSlideoverOpen(true);
          }}
          variant="outline"
          className="p-1 h-[26px]"
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            setPartyToUnlink(contact);
            setDeleteModalOpen(true);
          }}
          className="h-[26px] p-1"
        >
          <TrashIcon className="w-4 h-4" />
        </Button>
      </div>

      <div className="flex gap-x-3">
        <div className="flex-shrink-0 rounded-sm bg-atlantic-blue size-12">
          <div className="flex items-center justify-center w-full h-full text-white">
            {contactInitials}
          </div>
        </div>
        <div className="">
          <div className="">{displayContactName(contact)}</div>
          <div className="flex flex-wrap items-center gap-1">
            {uniq(chips)?.map((role, index) => {
              return (
                <Chip
                  key={`${role.title}=${index}`}
                  name={startCase(role.title)}
                  color={role.color}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="mt-4 space-y-2">
        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setPolicyTableOpen(!policyTableOpen)}
            >
              {policyTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Policies
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {itemConnectionPolicies?.length}
              </div>
            </Button>
            {policyTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${scenario?.policies?.length ?? 0} policies...`}
                title="Link Policy"
                onAdd={() => {
                  setPoliciesLoading(getCaseContactConnectionRoleIdForParty(contact) || '');
                  createPolicy
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      const id = res?.data?.policyId;
                      if (!id) {
                        return;
                      }
                      createItemConnection
                        .mutateAsync({
                          caseId: caseId,
                          partyId: getCaseContactConnectionRoleIdForParty(contact),
                          policyId: id,
                        })
                        .then(() => {
                          setSelectedPolicyId(res?.data?.policyId);
                          setPoliciesLoading('');
                          setPolicySlideoverOpen(true);
                        });
                    });
                }}
                isLoading={policiesLoading === getCaseContactConnectionRoleIdForParty(contact)}
                listItems={
                  scenario?.policies?.map((policy) => {
                    const insurerForPolicy = getRoleIdForItem(policy, 'insurer', 'policyId');
                    const insurer = allContacts?.find(
                      (contact) => contact.contactId === insurerForPolicy,
                    );
                    return {
                      label: `${displayContactName(insurer) ?? '-'} / ${
                        startCase(policy.policyType) ?? '-'
                      } / ${policy.type ?? '-'}`,
                      jsxLabel: (
                        <PolicyTitle
                          insurer={insurer}
                          size="sm"
                          policy={policy}
                          placeholderText="N/A"
                        />
                      ),
                      onClick: () => {
                        //if policy is already connected to the party, don't connect it again
                        if (
                          connectionsForContact?.some((itemConnection) => {
                            return itemConnection.policyId === policy.policyId;
                          })
                        ) {
                          enqueueSnackbar('Policy is already connected to the party', {
                            variant: 'warning',
                          });
                          return;
                        }

                        setPoliciesLoading(getCaseContactConnectionRoleIdForParty(contact) || '');
                        createItemConnection
                          .mutateAsync({
                            caseId: caseId,
                            partyId: getCaseContactConnectionRoleIdForParty(contact),
                            policyId: policy.policyId,
                          })
                          .then(() => {
                            setPoliciesLoading('');
                          });
                      },
                    };
                  }) ?? []
                }
              />
            )}
          </div>

          {policyTableOpen && (
            <div className="mt-5">
              <LinkedPoliciesTable
                policies={itemConnectionPolicies ?? []}
                handleRowClick={(row) => {
                  const parentEntity = {
                    type: 'party' as const,
                    id: getCaseContactConnectionRoleIdForParty(contact) || '',
                  };
                  handleLinkedPoliciesRowClick(row, parentEntity);
                }}
              />
            </div>
          )}
        </div>

        <div>
          <div className="flex items-center justify-between gap-x-2">
            <Button
              variant="ghost"
              size="unset"
              textColor="primary"
              className="flex items-center py-2 pr-2 gap-x-1 text-md"
              onClick={() => setVehicleTableOpen(!vehicleTableOpen)}
            >
              {vehicleTableOpen ? (
                <ChevronDownIcon strokeWidth={1.8} className="w-5 h-5" />
              ) : (
                <ChevronRightIcon strokeWidth={1.8} className="w-5 h-5" />
              )}
              Vehicles
              <div className="px-2 tabular-nums py-0.5 ml-2 text-xs text-white bg-atlantic-blue rounded-full text-bold">
                {itemConnectionVehicles?.length}
              </div>
            </Button>
            {vehicleTableOpen && (
              <EntityAddPopover
                placeholder={`Search ${scenario?.vehicles?.length ?? 0} vehicles...`}
                title="Link Vehicle"
                onAdd={() => {
                  setVehiclesLoading(getCaseContactConnectionRoleIdForParty(contact) ?? '');
                  createVehicle
                    .mutateAsync({
                      caseId: caseId,
                    })
                    .then((res) => {
                      const id = res?.data?.vehicleId;
                      if (!id) {
                        return;
                      }
                      createItemConnection
                        .mutateAsync({
                          caseId: caseId,
                          partyId: getCaseContactConnectionRoleIdForParty(contact),
                          vehicleId: id,
                        })
                        .then(() => {
                          const connection = scenario?.itemConnections?.find((itemConnection) => {
                            return (
                              itemConnection.vehicleId === res?.data?.vehicleId &&
                              itemConnection.partyId ===
                                getCaseContactConnectionRoleIdForParty(contact)
                            );
                          });
                          setSelectedConnectionId(connection?.itemConnectionId);
                          setSelectedVehicleId(res?.data?.vehicleId);
                          setVehiclesLoading('');
                          setVehicleSlideoverOpen(true);
                        });
                    });
                }}
                isLoading={vehiclesLoading === getCaseContactConnectionRoleIdForParty(contact)}
                listItems={
                  scenario?.vehicles?.map((vehicle) => {
                    return {
                      label: `${vehicle.year ?? '-'} ${vehicle.make ?? '-'} ${
                        vehicle.model ?? '-'
                      }`,
                      onClick: () => {
                        //if vehicle is already connected to the party, don't connect it again
                        if (
                          connectionsForContact?.some((itemConnection) => {
                            return itemConnection.vehicleId === vehicle.vehicleId;
                          })
                        ) {
                          enqueueSnackbar('Vehicle is already connected to the party', {
                            variant: 'warning',
                          });
                          return;
                        }

                        setVehiclesLoading(getCaseContactConnectionRoleIdForParty(contact) ?? '');
                        createItemConnection
                          .mutateAsync({
                            caseId: caseId,
                            partyId: getCaseContactConnectionRoleIdForParty(contact),
                            vehicleId: vehicle.vehicleId,
                          })
                          .then(() => {
                            setVehiclesLoading('');
                          });
                      },
                    };
                  }) ?? []
                }
              />
            )}
          </div>
          {vehicleTableOpen && (
            <div className="mt-5">
              <LinkedVehiclesTable
                vehicles={itemConnectionVehicles ?? []}
                handleRowClick={(row) =>
                  handleLinkedVehiclesRowClick(row, {
                    type: 'party',
                    id: getCaseContactConnectionRoleIdForParty(contact) ?? '',
                  })
                }
                isLoading={vehiclesLoading === getCaseContactConnectionRoleIdForParty(contact)}
              />
            </div>
          )}
        </div>
      </div>
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title="Delete Party"
        description="Are you sure you want to unlink this contact from the 'Parties and Policies' module?"
        actionFunction={() => {
          if (partyToUnlink) {
            handleRoleChange(partyToUnlink, [partiesAndPoliciesConnectionOptions.party]);
            setDeleteModalOpen(false);
          }
        }}
      />
    </div>
  );
};

export default PartyCard;
