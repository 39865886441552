import {
  CaseStatusType,
  CaseStatusUpdateType,
  CaseType,
  ContactEmailType,
  ContactNumberType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  CaseCompleteType,
  bitBToB,
  formatPrice,
  queryKeys,
  useCreateCase,
  useCreateCaseContactConnection,
  useCreateCaseStatusUpdate,
  useCreateContact,
  useCreateContactResource,
  useGetContact,
  useLinkCases,
  useSetCaseTitle,
  useUpdateCaseData,
  useUpdateContact,
  useUpdateContactResource,
} from '..';

export const getMedicalCoverageForCaseList = ({
  caseData,
  type,
  returnUnformatted,
}: {
  caseData?: CaseCompleteType;
  type: 'thirdPartyCoverage' | 'umUimCoverage';
  returnUnformatted?: boolean;
}) => {
  if (!caseData) return '-';
  if (caseData?.[type]) {
    const numbers = caseData?.[type].split(' / ');
    if (returnUnformatted) return numbers;
    const formattedNumbers = numbers.map((num) => (num === '-' ? '-' : formatPrice(Number(num))));
    return formattedNumbers.join(' / ');
  }
  return '- / -';
};

export function groupCaseStatusesByCategory(
  caseStatuses: CaseStatusType[],
  hideUncategorized?: boolean,
) {
  const categorySortedCaseStatuses = {
    lead: [] as CaseStatusType[],
    open: [] as CaseStatusType[],
    refer: [] as CaseStatusType[],
    close: [] as CaseStatusType[],
    decline: [] as CaseStatusType[],
  } as {
    open: CaseStatusType[];
    refer: CaseStatusType[];
    close: CaseStatusType[];
    decline: CaseStatusType[];
    lead?: CaseStatusType[];
    none?: CaseStatusType[];
  };
  if (!caseStatuses) return categorySortedCaseStatuses;
  if (!hideUncategorized) categorySortedCaseStatuses.none = [] as CaseStatusType[];
  caseStatuses.forEach((caseStatus) => {
    if (caseStatus.category) {
      categorySortedCaseStatuses[caseStatus.category]?.push(caseStatus);
    } else if (!hideUncategorized) {
      categorySortedCaseStatuses.none?.push(caseStatus);
    }
  });
  return categorySortedCaseStatuses;
}

export const getOpenCases = (cases: CaseType[] | CaseCompleteType[]) => {
  return cases.filter((c) => (c.caseStatus ? c?.caseStatus?.category === 'open' : true));
};

export const findCurrentCaseStatusUpdate = (statusUpdates?: CaseStatusUpdateType[]) => {
  if (!statusUpdates) return null;
  return statusUpdates.find((statusUpdate) => !statusUpdate.dateEnded);
};

export const statusToCategoryMapping = {
  open: 'Open',
  close: 'Closed',
  refer: 'Referred',
  decline: 'Declined',
  none: 'Uncategorized',
  lead: 'Lead',
  '': 'Open',
};

export const scrollToCaseSection = (ref: Element, instant?: boolean) => {
  if (ref) {
    const headerSize =
      (document.getElementById('case-header')?.getBoundingClientRect()?.height ?? 0) + 60; // 60 gives more space
    window.scrollTo({
      top: ref.getBoundingClientRect().top + window.scrollY - headerSize,
      behavior: instant ? undefined : 'smooth',
    });
  }
};

export type QuickCaseCreateQueryKeyValues =
  | (typeof queryKeys)[keyof typeof queryKeys]
  | 'emails'
  | 'phoneNumbers';

export type QuickCaseCreateUpdateType = {
  resourceType: QuickCaseCreateQueryKeyValues;
  key: string;
  value: string;
};

/**
 *
 * @description This function can be used to update a case/lead object easily.
 */
export function useCaseUpdateHandler(caseId: string) {
  const createCaseStatusUpdate = useCreateCaseStatusUpdate();
  const updateCase = useUpdateCaseData();
  const handleUpdateCase = (key: string, value: string) => {
    if (key === 'caseStatusId') {
      return createCaseStatusUpdate.mutateAsync({
        caseId,
        caseStatusId: value,
      });
    } else {
      // Update the case
      return updateCase.mutateAsync({ caseId, [key]: value });
    }
  };
  return { handleUpdateCase };
}

/**
 * @description this function was originally being used to allow for updating case / contact info before case / contacts were created.
 * However, it saves a negligible amount of time and is not worth the complexity. It is being kept here for reference.
 * @returns
 */
export function useCaseCreationAndUpdateQueue() {
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [createdIds, setCreatedIds] = useState({
    caseId: '',
    contactId: '',
    emailId: '',
    phoneNumberId: '',
    conscriptionId: '',
  });
  const [updatesQueue, setUpdatesQueue] = useState<QuickCaseCreateUpdateType[]>([]);

  const queryClient = useQueryClient();
  const createCaseStatusUpdate = useCreateCaseStatusUpdate();
  const updateCase = useUpdateCaseData();
  const updateContact = useUpdateContact();
  const updateContactPhoneNumber = useUpdateContactResource<ContactNumberType>('numbers');
  const updateContactEmail = useUpdateContactResource('emails');

  const setCaseTitle = useSetCaseTitle(createdIds.caseId);

  const { data: contactData } = useGetContact(createdIds.contactId);
  const contactInfo = contactData?.data;
  const createCase = useCreateCase();
  const createContact = useCreateContact();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const createContactPhoneNumber = useCreateContactResource<ContactNumberType>('numbers');
  const createContactEmail = useCreateContactResource<ContactEmailType>('emails');
  const linkCase = useLinkCases();

  const allResourcesCreated = !!(
    createdIds.caseId !== '' &&
    createdIds.contactId !== '' &&
    createdIds.phoneNumberId !== '' &&
    createdIds.emailId !== '' &&
    createdIds.conscriptionId !== ''
  );

  const createCaseAndLinks = async ({
    linkIds,
    caseStatusId,
    caseError,
  }: {
    linkIds?: { caseId: string; linkId: string } | undefined;
    caseStatusId?: string;
    caseError?: any;
  }) => {
    try {
      setStatus('loading');

      const id = await createCase
        .mutateAsync({
          caseStatusId: caseStatusId,
        })
        .then((caseRes) => {
          setCreatedIds((prevIds) => ({
            ...prevIds,
            caseId: caseRes?.data.caseId,
          }));

          createContact.mutateAsync().then((contactRes) => {
            setCreatedIds((prevIds) => ({
              ...prevIds,
              contactId: contactRes?.data.contactId,
            }));

            if (linkIds) {
              linkCase.mutateAsync({
                caseIdOne: linkIds.caseId,
                caseIdTwo: caseRes?.data.caseId,
              });
            }

            createCaseContactConnection
              .mutateAsync({
                caseId: caseRes?.data.caseId,
                contactId: contactRes?.data.contactId,
                roleOnCase: caseContactConnectionOptions.client,
              })
              .then((res) => {
                setCreatedIds((prevIds) => ({
                  ...prevIds,
                  conscriptionId: res?.data.caseContactConnectionId,
                }));
              });
            createContactEmail
              .mutateAsync({
                contactId: contactRes?.data.contactId,
              })
              .then((res) => {
                setCreatedIds((prevIds) => ({
                  ...prevIds,
                  emailId: res?.data.contactEmailId,
                }));
              });
            createContactPhoneNumber
              .mutateAsync({
                contactId: contactRes?.data.contactId,
              })
              .then((res) => {
                setCreatedIds((prevIds) => ({
                  ...prevIds,
                  phoneNumberId: res?.data.contactNumberId,
                }));
              });
          });

          const id = caseRes?.data.caseId;

          if (!id || caseError) {
            enqueueSnackbar('Error creating case', {
              variant: 'error',
            });
          }
          setStatus('success');
          return id;
        })
        .catch((err) => {
          enqueueSnackbar('Error creating case', {
            variant: 'error',
          });
        });

      return id;
    } catch (err: any) {
      setStatus('error');
      setError(err);

      return null;
    }
  };

  const handleUpdates = async ({ key, resourceType, value }: QuickCaseCreateUpdateType) => {
    // Your existing handleUpdates logic
    if (key === 'caseStatusId' && createdIds.caseId) {
      await createCaseStatusUpdate.mutateAsync({
        caseId: createdIds.caseId,
        caseStatusId: value,
      });
    } else if (resourceType === queryKeys.cases && createdIds.caseId) {
      // Update the case
      await updateCase.mutateAsync({ caseId: createdIds.caseId, [key]: value });
    } else if (resourceType === queryKeys.contacts && createdIds.contactId) {
      // Update the contact
      if (key === 'firstName' || key === 'lastName') {
        setCaseTitle({
          firstName:
            key === 'firstName'
              ? value
              : contactInfo?.firstName ??
                updatesQueue.find((update) => update.key === 'firstName')?.value ??
                '',
          lastName:
            key === 'lastName'
              ? value
              : contactInfo?.lastName ??
                updatesQueue.find((update) => update.key === 'lastName')?.value ??
                '',
        });
      }
      await updateContact
        .mutateAsync({
          contactId: createdIds.contactId,
          [key]: value,
        })
        .then(() => {
          queryClient.invalidateQueries([queryKeys.cases]); // this is so that the case list updates
        });
    } else if (resourceType === 'phoneNumbers' && createdIds.phoneNumberId) {
      // Update the phone number
      await updateContactPhoneNumber.mutateAsync({
        resourceId: createdIds.phoneNumberId,
        number: value,
      });
    } else if (resourceType === 'emails' && createdIds.emailId) {
      // Update the email
      await updateContactEmail.mutateAsync({
        resourceId: createdIds.emailId,
        emailAddress: value,
      });
    } else {
      // If not ready, add to the queue
      setUpdatesQueue((prevState) => [...prevState, { resourceType, key, value }]);
    }

    // Add to queue logic
    setUpdatesQueue((prevQueue) => [
      ...prevQueue,
      {
        resourceType,
        key,
        value,
      },
    ]);

    return {
      caseId: createdIds?.caseId,
      resourceType,
      key,
      value,
    };
  };

  const flushUpdatesQueue = () => {
    updatesQueue.forEach((update) => {
      handleUpdates(update);
    });

    setUpdatesQueue([]); // Clear the queue
  };

  useEffect(() => {
    if (allResourcesCreated) {
      flushUpdatesQueue();
    }
  }, [allResourcesCreated]);

  return {
    createCaseAndLinks,
    handleUpdates,
    status,
    error,
    createdIds,
    updatesQueue,
    allResourcesCreated,
    setCreatedIds,
    flushUpdatesQueue,
  };
}

/**
 * @description This function is used to get the earliest *unsatisfied* statute of limitations for a case.
 * @param caseData
 * @returns statute of limitations or additional statute of limitations
 */
export function getEarliestStatuteOfLimitations(caseData?: CaseType) {
  if (!caseData)
    return {
      date: undefined,
      satisfied: false,
    };
  const {
    statuteOfLimitations,
    additionalStatuteOfLimitations,
    statuteOfLimitationsSatisfied,
    additionalStatuteOfLimitationsSatisfied,
  } = caseData;
  const statutes = [
    {
      date: statuteOfLimitations,
      satisfied: bitBToB(statuteOfLimitationsSatisfied),
    },
    {
      date: additionalStatuteOfLimitations,
      satisfied: bitBToB(additionalStatuteOfLimitationsSatisfied),
    },
  ];
  const unsatisfiedStatutes = statutes.filter((statute) => !statute.satisfied && statute.date);
  if (unsatisfiedStatutes.length === 0) return statutes[0];
  if (unsatisfiedStatutes.length === 1) return unsatisfiedStatutes[0];
  if (unsatisfiedStatutes.length > 1) {
    return unsatisfiedStatutes.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))[0];
  }
  return {
    date: statuteOfLimitations,
    satisfied: bitBToB(statuteOfLimitationsSatisfied),
  };
}
