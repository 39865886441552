import {
  allChipsColorMap,
  cn,
  getNameToDisplayFromCognito,
  sortValuesByOrder,
  useGetFirmUsers,
} from '@colosseum/data';
import {
  StaffType,
  caseContactConnectionOptions,
  caseContactConnectionPriorityOrder,
} from '@gladiate/types';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ColumnOrderState, SortingState, Table, VisibilityState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { isNil, kebabCase, startCase } from 'lodash';
import AssigneeChipGroup from '../../AssigneeChipGroup/AssigneeChipGroup';
import { Chip } from '../../Chip/Chip';
import TooltipWrapper from '../../data-display/TooltipWrapper/TooltipWrapper';
import { Badge } from '../Badge/Badge';
import { DataTableColumnHeader } from './DataTableColumnHeader';

export const tableRenderHelperTextClasses = 'max-w-[600px] min-w-[100px] truncate font-medium';

export const getInitialVisibilityForTable = (
  tableName: string,
  fallbackOptions?: VisibilityState,
) => {
  const localStorageVisibility = window.localStorage.getItem(`${tableName}ColumnVisibility`);
  return localStorageVisibility
    ? (JSON.parse(localStorageVisibility) as VisibilityState)
    : fallbackOptions;
};

export const getInitialSortForTable = (tableName: string) => {
  const localStorageSort = window.localStorage.getItem(`${tableName}ColumnSort`);
  return localStorageSort ? (JSON.parse(localStorageSort) as SortingState) : [];
};

export const getInitialOrderForTable = (tableName: string) => {
  const localStorageOrder = window.localStorage.getItem(`${tableName}ColumnOrder`);
  return localStorageOrder ? (JSON.parse(localStorageOrder) as ColumnOrderState) : undefined;
};

export const getInitialPageSizeForTable = (tableName: string) => {
  const localStoragePageSize = window.localStorage.getItem(`${tableName}PageSize`);
  return localStoragePageSize ? parseInt(localStoragePageSize) : undefined;
};

export const renderCell = ({
  props,
  valueModifierFunc,
  preFixString,
}: {
  props: any; // todo: type this
  valueModifierFunc?: (value: any) => any;
  preFixString?: string;
}) => {
  const value = props.getValue();
  const isGrouped = props.row.getIsGrouped();
  // if function returns us null we want to still use value
  const finalValue = valueModifierFunc && !isNil(value) ? valueModifierFunc(value) ?? value : value;

  return (
    <div className="flex space-x-2">
      <span className={cn(tableRenderHelperTextClasses, isGrouped && 'font-semibold min-w-0')}>
        {preFixString && finalValue !== '-' && finalValue ? preFixString : ''}
        {!isNil(finalValue) && finalValue !== '' ? finalValue : '-'}
      </span>
    </div>
  );
};

export const renderCellWithChips = ({
  props,
  valueModifierFunc,
  showParty = false,
}: {
  props: any;
  valueModifierFunc?: (value: any) => any;
  showParty?: boolean;
}) => {
  const value = props.getValue();

  // if function returns us null we want to still use value
  const finalValue: string = valueModifierFunc && value ? valueModifierFunc(value) ?? value : value;

  //finalValue is a comma separated string of chip names (e.g. "Plaintiff, Defendant") let's split it into an array
  const chipsArr = finalValue?.split(', ');

  // Sort chips
  const chips = sortValuesByOrder(chipsArr, caseContactConnectionPriorityOrder);

  return (
    <div className={cn('flex gap-2 flex-wrap', chips?.length > 1 ? 'min-w-[350px] ' : '')}>
      {chips?.map((chipName) => {
        if (chipName === '' || (chipName === caseContactConnectionOptions.party && !showParty))
          return null;
        return (
          <Chip
            key={chipName}
            name={startCase(chipName)}
            color={allChipsColorMap?.[kebabCase(chipName)]}
          />
        );
      })}
    </div>
  );
};

export const renderNumberCellWithHover = ({
  value,
  tooltipText,
}: {
  value: string;
  tooltipText: string;
}) => {
  return (
    <TooltipWrapper message={tooltipText}>
      <div className="flex space-x-2">
        <span className={tableRenderHelperTextClasses}>{value}</span>
      </div>
    </TooltipWrapper>
  );
};

export const renderTextCell = ({ value }: { value: string }) => {
  return (
    <div className="flex space-x-2">
      <span className={tableRenderHelperTextClasses}>{value}</span>
    </div>
  );
};

export const renderCellWithAssigneeChipGroup = ({ props }: { props: any }) => {
  const value = props.getValue() as StaffType[];
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const firmUsersQuery = useGetFirmUsers();
  const firmUsersData = firmUsersQuery.data?.data;
  // TODO: This firm user stuff is not being typed at all, need to fix this here and everywhere else.
  const caseAssignees =
    firmUsersData
      ?.filter((firmUser) =>
        value?.map((assignees) => assignees?.username).includes(firmUser.Username),
      )
      .map((item) => {
        if (!item?.name) {
          return item?.Username ?? '';
        }

        return getNameToDisplayFromCognito(item) ?? '-';
      }) ?? [];
  return (
    <div>
      {caseAssignees.length > 0 ? (
        <AssigneeChipGroup assignees={caseAssignees} />
      ) : (
        renderTextCell({ value: '-' })
      )}
    </div>
  );
};

export const renderHeader = (props: any) => (
  <DataTableColumnHeader column={props.column} title={props.column.id} />
);

export const formatDatesForFilterButton = (table: Table<any>, columnId: string) => {
  const column = table.getColumn(columnId);
  const selectedValues: Date[] = column?.getFilterValue() as Date[];
  if (!selectedValues) return;
  let startDate, endDate, formattedRange;
  if (selectedValues[0]) {
    startDate = dayjs(selectedValues[0])?.format('MMMM D, YYYY');
  }
  if (selectedValues[1]) {
    endDate = dayjs(selectedValues[1])?.format('MMMM D, YYYY');
  }
  if (startDate && endDate) {
    formattedRange = `${startDate} to ${endDate}`;
  } else if (startDate) {
    formattedRange = `After ${startDate}`;
  } else if (endDate) {
    formattedRange = `Before ${endDate}`;
  }
  return (
    <>
      <Badge variant="secondary" className="px-1 font-normal rounded-sm lg:hidden">
        {selectedValues.filter((v) => v).length}
      </Badge>
      <div className="hidden space-x-1 lg:flex">
        <Badge variant="secondary" className="px-1 pl-2 ml-2 font-normal rounded-sm">
          {formattedRange}
        </Badge>
      </div>
    </>
  );
};

export const renderCheckboxCell = ({ props }: { props: any }) => {
  const value = props.getValue();
  return (
    <div className="flex space-x-2">
      {value ? <CheckIcon className="w-5 h-5 text-green-500" /> : <></>}
    </div>
  );
};
