/* eslint-disable @typescript-eslint/no-var-requires */
import Lottie from 'lottie-react';

/* eslint-disable-next-line */
export interface GladiateLoaderProps {
  height?: number;
  white?: boolean;
  className?: string;
}

const whiteLoader = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
  fr: 25,
  ip: 25,
  op: 85,
  w: 1000,
  h: 1000,
  nm: 'Gladiate White',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [0],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [519.39, 500.947, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Small Triangle 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 60,
              s: [-156.274, -125.011, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [-56.274, -25.011, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [66.25, 66.25, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 60,
              s: [0, 0, 100],
            },
            { t: 80, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.044, -0.024],
                    [0, 0],
                    [5.121, 0.324],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.006, -0.011],
                    [0, 0],
                  ],
                  v: [
                    [66.112, -66.25],
                    [66.25, -66.181],
                    [66.191, 66.106],
                    [-66.233, 66.106],
                    [-66.25, 66.072],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -5,
      op: 245,
      st: -5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Small Triangle 4',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 30,
              s: [56.274, 25.011, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 50, s: [156.274, 125.011, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-66.25, -66.25, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100],
            },
            { t: 50, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.044, 0.024],
                    [0, 0],
                    [-5.121, -0.324],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.006, 0.011],
                    [0, 0],
                  ],
                  v: [
                    [-66.112, 66.25],
                    [-66.25, 66.181],
                    [-66.191, -66.106],
                    [66.233, -66.106],
                    [66.25, -66.072],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Big Triangle 2',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 55,
              s: [200, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [0, 0, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-112.997, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 55,
              s: [0, 0, 100],
            },
            { t: 80, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [112.997, -0.501],
                    [-112.997, -228.466],
                    [-112.997, 228.466],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 15,
      op: 265,
      st: 15,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Big Triangle 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 35,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 60, s: [-200, 0, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [112.997, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 35,
              s: [100, 100, 100],
            },
            { t: 60, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-112.997, -0.501],
                    [112.997, -228.466],
                    [112.997, 228.466],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 15,
      op: 265,
      st: 15,
      bm: 0,
    },
  ],
  markers: [],
};

const blackLoader = {
  v: '4.8.0',
  meta: {
    g: 'LottieFiles AE 3.0.2',
    a: '',
    k: '',
    d: '',
    tc: '#FFFFFF',
  },
  fr: 25,
  ip: 25,
  op: 85,
  w: 1000,
  h: 1000,
  nm: 'Gladiate',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25,
              s: [0],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [519.39, 500.947, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Small Triangle 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 60,
              s: [-156.274, -125.011, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [-56.274, -25.011, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [66.25, 66.25, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 60,
              s: [0, 0, 100],
            },
            { t: 80, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.044, -0.024],
                    [0, 0],
                    [5.121, 0.324],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.006, -0.011],
                    [0, 0],
                  ],
                  v: [
                    [66.112, -66.25],
                    [66.25, -66.181],
                    [66.191, 66.106],
                    [-66.233, 66.106],
                    [-66.25, 66.072],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -5,
      op: 245,
      st: -5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Small Triangle 4',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 30,
              s: [56.274, 25.011, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 50, s: [156.274, 125.011, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-66.25, -66.25, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100],
            },
            { t: 50, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.044, 0.024],
                    [0, 0],
                    [-5.121, -0.324],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.006, 0.011],
                    [0, 0],
                  ],
                  v: [
                    [-66.112, 66.25],
                    [-66.25, 66.181],
                    [-66.191, -66.106],
                    [66.233, -66.106],
                    [66.25, -66.072],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Big Triangle 2',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 55,
              s: [200, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [0, 0, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-112.997, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 55,
              s: [0, 0, 100],
            },
            { t: 80, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [112.997, -0.501],
                    [-112.997, -228.466],
                    [-112.997, 228.466],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 15,
      op: 265,
      st: 15,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Big Triangle 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.6, y: 0 },
              t: 35,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 60, s: [-200, 0, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [112.997, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
              o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
              t: 35,
              s: [100, 100, 100],
            },
            { t: 60, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-112.997, -0.501],
                    [112.997, -228.466],
                    [112.997, 228.466],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 20, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 15,
      op: 265,
      st: 15,
      bm: 0,
    },
  ],
  markers: [],
};

export function GladiateLoader(props: GladiateLoaderProps) {
  const style = {
    height: props.height || 100,
  };

  if (props.white) {
    return (
      <div className={props.className ? props.className : ''}>
        <Lottie style={style} animationData={whiteLoader} loop={true} />
      </div>
    );
  }

  return (
    <div className={props.className ? props.className : ''}>
      <Lottie style={style} animationData={blackLoader} loop={true} />
    </div>
  );
}

export default GladiateLoader;
