import { renderCell, renderHeader, stringIncludesFilterFn } from '@colosseum/shared-ui';
import { CustomAttributeType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

export const columns: ColumnDef<CustomAttributeType>[] = [
  {
    accessorFn: (row) => row.attributeLabel,
    id: 'Label',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => startCase(row.attributeType),
    id: 'Type',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => startCase(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
