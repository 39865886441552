import { useCreateStaff, useDeleteStaff, useGetStaff, useGetStaffRoles } from '@colosseum/data';
import { FirmUserLookup, GladiateLoader } from '@colosseum/shared-ui';
import { StaffType } from '@gladiate/types';

export interface CaseStaffProps {
  caseId: string;
}

export function CaseStaff(props: CaseStaffProps) {
  const { caseId } = props;
  const { data: staffData } = useGetStaff(caseId);
  const { data: staffRolesData, isLoading: isStaffRolesLoading } = useGetStaffRoles();
  const createStaff = useCreateStaff();
  const deleteStaff = useDeleteStaff();

  const staffGroupedByRoles = staffData?.data?.reduce((acc, staff) => {
    const role =
      staffRolesData?.data?.find((staffRole) => staffRole?.roleId === staff.roleId)?.title ??
      'No role';

    return {
      ...acc,
      [role]: [...(acc[role] ?? []), staff],
    };
  }, {} as { [key: string]: any[] });

  return (
    <div>
      <div>
        {isStaffRolesLoading && (
          <div className="flex flex-col justify-center h-60">
            <GladiateLoader height={50} className="text-atlantic-blue" />
          </div>
        )}
        {staffRolesData?.data?.map((staffRole) => {
          return (
            <div key={staffRole.roleId} className="mb-5 first:mt-3">
              <h3 className="text-gray-900 ">{staffRole.title}</h3>
              <FirmUserLookup
                key="staffId"
                selectedUsers={staffGroupedByRoles?.[staffRole.title ?? ''] ?? []}
                handleAdd={(user: string) => {
                  return createStaff.mutateAsync({
                    caseId,
                    staff: {
                      username: user,
                      roleId: staffRole.roleId,
                    },
                  });
                }}
                handleRemove={(user: StaffType) => {
                  return deleteStaff.mutateAsync(user?.staffId);
                }}
                allowMultiple
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CaseStaff;
