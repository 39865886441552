import { usePermissions } from '@colosseum/data';
import { CustomErrorBoundary, SectionContainer } from '@colosseum/shared-ui';
import { withErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import AdobeAdminSettings from './AdobeAdminSettings/AdobeAdminSettings';
import AttorneyFees from './AttorneyFees/AttorneyFees';
import CalendarSection from './CalendarSection/CalendarSection/CalendarSection';
import CustomAttributes from './CustomAttributes/CustomAttributes';
import ExpensesDefaults from './ExpensesDefaults/ExpensesDefaults';
import FirmDetails from './FirmDetails/FirmDetails';
import FirmStatusesTypes from './FirmStatusesTypes/FirmStatusesTypes';
import MedicalTreatmentTypes from './MedicalTreatmentTypes/MedicalTreatmentTypes';
import PhoneNumberSection from './PhoneNumberSection/PhoneNumberSection';
import TimeTrackingCategories from './TimeTrackingCategories/TimeTrackingCategories';
import UsersTab from './UsersTab/UsersTab';

/* eslint-disable-next-line */
export interface SettingsPageProps {}

export function SettingsPage() {
  const { hasServiceAccess } = usePermissions();
  return (
    <div data-cy="settings">
      <Helmet defer={false}>
        <title>Settings</title>
      </Helmet>
      {hasServiceAccess('settingsUserAccess') && (
        <SectionContainer>
          <UsersTab />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsFirmDetailsAccess') && (
        <SectionContainer>
          <FirmDetails />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsAttorneysFeesAccess') && (
        <SectionContainer>
          <AttorneyFees />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsCaseDefaultsAccess') && (
        <SectionContainer>
          <FirmStatusesTypes />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsTimeTrackingAccess') && (
        <SectionContainer>
          <TimeTrackingCategories />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsMedicalTreatmentTypesAccess') && (
        <SectionContainer>
          <MedicalTreatmentTypes />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsExpenseCategoriesAccess') && (
        <SectionContainer>
          <ExpensesDefaults />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsCustomAttributesAccess') && (
        <SectionContainer>
          <CustomAttributes />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsFirmCalendarAccess') && (
        <SectionContainer>
          <CalendarSection />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsESignAccess') && (
        <SectionContainer>
          <AdobeAdminSettings />
        </SectionContainer>
      )}
      {hasServiceAccess('settingsPhoneNumbersAccess') && (
        <SectionContainer>
          <PhoneNumberSection />
        </SectionContainer>
      )}
    </div>
  );
}

export default withErrorBoundary(SettingsPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
