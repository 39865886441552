import { cn } from '@colosseum/data';

export const MP4Icon = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 303.188 303.188"
    className={cn('w-8 h-8 min-w-8', className)}
  >
    <g>
      <polygon
        style={{ fill: '#E8E8E8' }}
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
      />
      <g>
        <rect x="90.902" y="61.704" style={{ fill: '#38A3FF' }} width="119.89" height="119.89" />
        <circle style={{ fill: '#FFFFFF' }} cx="150.847" cy="121.648" r="45.484" />
        <path
          style={{ fill: '#38A3FF' }}
          d="M144.439,143.105c-4.145,2.682-7.537,0.837-7.537-4.1v-34.711c0-4.938,3.392-6.783,7.537-4.102
			l25.622,16.579c4.146,2.683,4.146,7.073,0,9.754L144.439,143.105z"
        />
      </g>
      <polygon
        style={{ fill: '#38A3FF' }}
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
      />
      <g>
        <path
          style={{ fill: '#A4A9AD' }}
          d="M103.524,273.871l-9.813-34.557h-0.295c0.46,5.886,0.69,10.458,0.69,13.718v20.839H82.62v-47.979
			h17.262l10.009,34.065h0.263l9.813-34.065h17.295v47.979h-11.913v-21.036c0-1.094,0.017-2.308,0.049-3.643
			c0.033-1.334,0.181-4.605,0.443-9.813h-0.295l-9.681,34.491h-12.341V273.871z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M182.418,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917h-12.963v-47.979h16.605c6.06,0,10.61,1.324,13.652,3.971C180.896,232.51,182.418,236.273,182.418,241.152z
			 M160.562,247.387h2.362c1.947,0,3.495-0.546,4.643-1.641c1.149-1.094,1.724-2.604,1.724-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.347L160.562,247.387L160.562,247.387z"
        />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M223.242,264.583h-5.283v9.288H205.16v-9.288h-19.133v-9.517l19.953-29.175h11.979v29.011h5.283
			L223.242,264.583L223.242,264.583z M205.16,254.902v-5.579c0-1.05,0.038-2.467,0.115-4.25c0.076-1.783,0.136-2.718,0.18-2.806
			h-0.36c-0.744,1.685-1.587,3.26-2.527,4.726l-5.316,7.909H205.16z"
        />
      </g>
      <polygon style={{ fill: '#D1D3D3' }} points="219.821,50.525 270.346,50.525 219.821,0 	" />
    </g>
  </svg>
);
