import { cn, prettifyDateString, useGetBillingHistory, useGetSubscriptions } from '@colosseum/data';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CustomErrorBoundary,
  DataTable,
  SectionContainer,
  ShimmerSkeletonWrapper,
  renderCell,
  renderCellWithChips,
  renderHeader,
} from '@colosseum/shared-ui';
import { withErrorBoundary } from '@sentry/react';
import { ColumnDef } from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';
import PaymentMethods from '../../settings/PaymentMethods/PaymentMethods';
import { BillingHistory, SubscriptionModel } from '@gladiate/types';

const SubscriptionCardSkeleton = () => (
  <Card className="bg-white">
    <ShimmerSkeletonWrapper>
      <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
        <CardTitle className="">
          <div className="h-4 font-semibold rounded-md w-60 bg-slate-100"></div>
          <div className="w-40 h-2 mt-1 ml-2 rounded-md bg-slate-200"></div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-8 font-semibold rounded-md w-36 bg-slate-100"></div>
        <div className="h-2 mt-2 ml-2 rounded-md w-28 bg-slate-100"></div>
      </CardContent>
    </ShimmerSkeletonWrapper>
  </Card>
);

/* eslint-disable-next-line */
export interface SubscriptionsPageProps {}

const columns: ColumnDef<BillingHistory>[] = [
  {
    accessorFn: (row) => row.amount,
    id: 'Amount',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) =>
      renderCell({
        props,
        preFixString: '$',
        valueModifierFunc: (value) => (Number(value) / 100).toFixed(2),
      }),
  },
  {
    accessorFn: (row) => row.dateCreated,
    id: 'Date',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.status,
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCellWithChips({ props }),
  },
];

export function SubscriptionsPage(props: SubscriptionsPageProps) {
  // #region Hooks
  // #endregion
  // #region Data Fetching
  const { data: subscriptionData, isLoading: subscriptionsLoading } = useGetSubscriptions();
  const { data: billingHistory } = useGetBillingHistory();
  // #endregion

  // #region State
  // #endregion

  // #region Derived State
  const transactions = billingHistory?.data;
  const allSubscriptions = subscriptionData?.data.subscriptions;

  const caseOptimizationSubscriptions = allSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'caseOptimization',
  );
  const clientEngagementSubscriptions = allSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'clientEngagement',
  );
  const coCounselSubscriptions = allSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'coCounsel',
  );
  const leadManagementSubscriptions = allSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'leadManagement',
  );
  const insightsSubscriptions = allSubscriptions?.filter(
    (subscription) => subscription.subscriptionType === 'insight',
  );

  const SubscriptionTypeDetails: {
    [key: string]: {
      title: string;
      description: string;
      price: number;
      data?: SubscriptionModel[];
    };
  } = {
    caseOptimization: {
      title: 'Case Optimization Subscription',
      description: 'This is your case optimization subscription',
      price: 99,
      data: caseOptimizationSubscriptions,
    },
    clientEngagement: {
      title: 'Client Engagement Subscription',
      description: 'This is your client engagement subscription',
      price: 199,
      data: clientEngagementSubscriptions,
    },
    // TODO: Uncomment when these subscriptions are available
    // leadManagement: {
    //   title: 'Lead Management Subscription',
    //   description: 'This is your lead management subscription',
    //   price: 99,
    //   data: leadManagementSubscriptions,
    // },
    // coCounsel: {
    //   title: 'Co-Counsel Subscription',
    //   description: 'This is your co-counsel subscription',
    //   price: 99,
    //   data: coCounselSubscriptions,
    // },
    // insight: {
    //   title: 'Insight Subscription',
    //   description: 'This is your insight subscription',
    //   price: 99,
    //   data: insightsSubscriptions,
    // },
  };

  // #endregion
  // #region useEffects
  // #endregion
  // #region Event Handlers
  // #endregion

  return (
    <div data-cy="subscriptions">
      <Helmet defer={false}>
        <title>Subscriptions</title>
      </Helmet>
      <SectionContainer>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Subscriptions</h1>
          <p className="mt-2 text-sm text-gray-700">View all subscriptions for your firm.</p>
        </div>
        <div className="grid gap-4 mt-5 md:grid-cols-2 lg:grid-cols-2">
          {subscriptionsLoading ? (
            <>
              <SubscriptionCardSkeleton />
              <SubscriptionCardSkeleton />
            </>
          ) : (
            <>
              {Object.keys(SubscriptionTypeDetails).map((key: string) => {
                const subscription = SubscriptionTypeDetails[key];

                const renewalDate = subscription?.data && subscription?.data[0]?.renewalDate;
                const pricePerMonth = subscription?.data && subscription.data[0]?.pricePerMonth;

                return (
                  <Card
                    key={key}
                    className={cn(
                      'relative ',
                      subscription.data?.length === 0 || !subscription.data?.length
                        ? 'opacity-70 bg-gray-100'
                        : 'bg-white',
                    )}
                  >
                    <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
                      <CardTitle className="text-sm font-medium">
                        <div className="font-semibold ">{subscription?.title}</div>
                        <div className="ml-2 text-xs text-gray-600 ">
                          Renewal Date: {(renewalDate && prettifyDateString(renewalDate)) ?? 'N/A'}
                        </div>
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        {subscription.data?.length ?? '0'} license(s)
                      </div>
                      {pricePerMonth && (
                        <p className="ml-2 text-xs text-gray-600 ">
                          ${pricePerMonth}/ license / month
                        </p>
                      )}
                    </CardContent>
                  </Card>
                );
              })}
            </>
          )}
        </div>
      </SectionContainer>

      <SectionContainer>
        <PaymentMethods />
      </SectionContainer>

      <SectionContainer>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Transactions</h1>
          <p className="mt-2 text-sm text-gray-700">View all transactions for your firm.</p>
        </div>
        <DataTable
          tableName="Transactions"
          filters={[]}
          hideViewButton
          columns={columns}
          data={
            transactions?.map((transaction) => ({
              ...transaction,
              id: transaction.billingHistoryId,
            })) ?? []
          }
          handleRowClick={() => {
            //do nothing yet
          }}
          showCSVDownload
        />
      </SectionContainer>
    </div>
  );
}

export default withErrorBoundary(SubscriptionsPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
