import { useCreateNote, useGetNotes } from '@colosseum/data';
import { NoteType } from '@gladiate/types';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { startCase, uniq } from 'lodash';
import { PlusCircle } from 'lucide-react';
import { useMemo, useState } from 'react';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import ResourceNote from '../ResourceNote/ResourceNote';
import ResourceNoteForm from '../ResourceNoteForm/ResourceNoteForm';
import { Button } from '../shadcn/Button/Button';
import { DefaultCalendar } from '../shadcn/DefaultCalendar/DefaultCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import { dayjsDateTime } from '../shadcn/data-table/DataTable';
import { DataTableToolbar } from '../shadcn/data-table/DataTableToolbar';
import { getRangeValue, onRangeSelect } from '../shadcn/data-table/table-filter-helpers';
import { formatDatesForFilterButton } from '../shadcn/data-table/table-render-helpers';
import { columns } from './notes-table-columns';

export interface ResourceNotesProps {
  createType: NoteType['type'];
  resourceId: string;
  scrollable?: boolean;
  showFilters?: boolean;
  caseId?: string;
  isLead?: boolean;
}

export function ResourceNotes(props: ResourceNotesProps) {
  const { createType, resourceId, scrollable, showFilters, caseId, isLead } = props;
  const notesRes = useGetNotes(resourceId);
  const createResourceNote = useCreateNote();
  const notes = notesRes?.data?.data ?? [];

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data: notes,
    columns,
    state: {
      columnFilters,
    },
    sortingFns: {
      dayjsDateTime: dayjsDateTime,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const tableFilters = useMemo(() => {
    const typeOptions = uniq(notes.map((note) => note.type));
    const userOptions = uniq(notes.map((note) => note.username));
    return [
      {
        id: 'Type',
        options:
          typeOptions.map((type) => {
            return { value: type ?? '', label: startCase(type) };
          }) ?? [],
      },
      {
        id: 'User',
        options:
          userOptions.map((user) => {
            return { value: user ?? '', label: startCase(user) };
          }) ?? [],
      },
    ];
  }, [notes]);

  const handleCreateNote = (data: { message: string; type: NoteType['type'] }) => {
    return createResourceNote.mutateAsync({
      data: { ...data, type: createType },
      resourceId,
    });
  };

  if (notesRes.isLoading) return <GladiateLoader />;

  return (
    <div className="flex flex-col gap-y-4">
      <ResourceNoteForm
        isLoading={createResourceNote.isLoading || createResourceNote.isLoading}
        handleNoteSubmit={handleCreateNote}
        messageTitle="New Note"
        buttonText="Add Note"
        defaultMessage=""
        clearOnSubmit
      />
      {showFilters && (
        <div className="my-4">
          <DataTableToolbar
            filters={tableFilters}
            table={table}
            hideViewButton
            customButtons={
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="sm" className="h-8 border-dashed">
                    <PlusCircle className="w-4 h-4 mr-2" />
                    Date Created
                    {formatDatesForFilterButton(table, 'Date Created')}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-4 bg-white " align="center">
                  <div className="flex">
                    <DefaultCalendar
                      mode="single"
                      selected={getRangeValue(0, 'Date Created', table)}
                      onSelect={(date) => onRangeSelect(0, 'Date Created', table, date)}
                      initialFocus
                    />
                    <DefaultCalendar
                      mode="single"
                      selected={getRangeValue(1, 'Date Created', table)}
                      onSelect={(date) => onRangeSelect(1, 'Date Created', table, date)}
                      initialFocus
                    />
                  </div>
                </PopoverContent>
              </Popover>
            }
          />
        </div>
      )}
      <div
        className={`${
          scrollable ? 'max-h-[500px] overflow-auto' : ''
        } px-4 border-gray-300 border rounded-xl`}
      >
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <div key={row.original.noteId} className="py-4 border-b last:border-b-0">
              <ResourceNote note={row.original} caseId={caseId} isLead={isLead} />
            </div>
          ))
        ) : (
          <div className="py-12 text-center">No notes to display.</div>
        )}
      </div>
    </div>
  );
}

export default ResourceNotes;
