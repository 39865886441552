import { flattenArrayByKey, useGetCaseStatuses } from '@colosseum/data';
import { CaseCampaign, CaseCampaignTrigger, CaseStatusType } from '@gladiate/types';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import CaseCampaignTriggerSlideover from '../CampaignTriggerSlideovers/CaseCampaignTriggerSlideover';
import CreateButton from '../CreateButton/CreateButton';
import { DataTable } from '../shadcn/data-table/DataTable';
import { columns } from './case-triggers-table-columns';

export interface CaseCampaignTriggersTableProps {
  campaignData: CaseCampaign;
  viewOnly?: boolean;
  loading?: boolean;
}

export function CaseCampaignTriggersTable({
  campaignData,
  viewOnly = false,
  loading,
}: CaseCampaignTriggersTableProps) {
  // #region Hooks
  // #endregion

  // #region Data Fetching
  const { data: caseStatuses, isLoading: isCaseStatusesLoading } = useGetCaseStatuses();
  // #endregion

  // #region State
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [selectedCampaignTrigger, setSelectedCampaignTrigger] = useState<
    CaseCampaign & CaseCampaignTrigger
  >();
  // #endregion

  // #region Derived State
  const campaignTriggersData =
    campaignData.caseCampaignTriggers.length > 0
      ? flattenArrayByKey<CaseCampaign, CaseCampaignTrigger>([campaignData], 'caseCampaignTriggers')
      : [];

  const caseStatusListItems = ({ reverseKeyValue = false }) =>
    caseStatuses?.data.reduce((acc, status: CaseStatusType) => {
      if (status.title) {
        if (reverseKeyValue) acc[status.caseStatusId] = status.title;
        else acc[status.title] = status.caseStatusId;
      }
      return acc;
    }, {} as { [key: string]: string }) ?? {};

  // #endregion

  // #region useEffects
  useEffect(() => {
    if (!slideoverOpen) setSelectedCampaignTrigger(undefined);
  }, [slideoverOpen]);
  // #endregion

  // #region Event Handlers
  function handleRowClick(e: Row<CaseCampaign & CaseCampaignTrigger>) {
    setSlideoverOpen(true);
    setSelectedCampaignTrigger(e.original);
  }

  function handleCreateTrigger() {
    setSlideoverOpen(true);
  }
  // #endregion

  return (
    <>
      <CaseCampaignTriggerSlideover
        open={slideoverOpen}
        setOpen={setSlideoverOpen}
        caseCampaignTrigger={selectedCampaignTrigger}
        caseCampaignId={campaignData.caseCampaignId}
      />
      <div className="flex items-center justify-end w-full pb-3 align-middle"></div>
      {viewOnly ? (
        <DataTable
          data={campaignTriggersData}
          columns={columns}
          isLoading={isCaseStatusesLoading}
          tableName="CampaignTriggers"
          meta={{
            caseStatusMap: caseStatusListItems({ reverseKeyValue: true }),
          }}
          isViewOnly={viewOnly}
          noResultsMessage="No messages created yet."
          initialSort={{
            id: 'Date Created',
            desc: true,
          }}
        />
      ) : (
        <DataTable
          data={campaignTriggersData}
          columns={columns}
          handleRowClick={handleRowClick}
          isLoading={loading}
          tableName="CampaignTriggers"
          meta={{
            caseStatusMap: caseStatusListItems({ reverseKeyValue: true }),
          }}
          initialSort={{
            id: 'Date Created',
            desc: true,
          }}
          customRightButton={
            !viewOnly ? (
              <div className="pl-2">
                <CreateButton
                  title={'Create Message'}
                  onClick={handleCreateTrigger}
                  dataCy="create-campaign-trigger-button"
                />
              </div>
            ) : (
              <> </>
            )
          }
          noResultsMessage="No messages created yet."
        />
      )}
    </>
  );
}
