import { cn } from '@colosseum/data';

export const ZIPIcon = ({ className }: { className?: string }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 303.188 303.188"
    className={cn('w-8 h-8 min-w-8', className)}
  >
    <g>
      <polygon
        style={{ fill: '#E4E4E4' }}
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"
      />
      <g>
        <rect x="144.182" y="12.631" style={{ fill: '#333E48' }} width="14.824" height="132.962" />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M151.594,134.594c-8.247,0-14.933,20.253-14.933,28.5s6.686,14.933,14.933,14.933
			s14.933-6.686,14.933-14.933S159.841,134.594,151.594,134.594z M151.594,170.005c-3.817,0-6.912-3.094-6.912-6.912
			s3.094-6.912,6.912-6.912c3.817,0,6.912,3.094,6.912,6.912S155.411,170.005,151.594,170.005z"
        />
      </g>
      <polygon
        style={{ fill: '#A4A9AD' }}
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"
      />
      <g>
        <path
          style={{ fill: '#A4A9AD' }}
          d="M136.769,273.871H99.39v-8.007l20.773-29.503H99.882v-10.469h36.362v7.975l-20.773,29.536h21.298
			V273.871z"
        />
        <path style={{ fill: '#A4A9AD' }} d="M143.628,273.871v-47.979h13.028v47.979H143.628z" />
        <path
          style={{ fill: '#A4A9AD' }}
          d="M201.847,241.152c0,5.361-1.581,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
			v15.917h-12.963v-47.979h16.605c6.06,0,10.61,1.324,13.652,3.971C200.325,232.51,201.847,236.273,201.847,241.152z
			 M179.99,247.387h2.362c1.947,0,3.495-0.546,4.643-1.641c1.149-1.094,1.724-2.604,1.724-4.529c0-3.238-1.795-4.857-5.383-4.857
			h-3.347L179.99,247.387L179.99,247.387z"
        />
      </g>
      <polygon style={{ fill: '#D1D3D3' }} points="219.821,50.525 270.346,50.525 219.821,0 	" />
    </g>
  </svg>
);
