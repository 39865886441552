/* eslint-disable react/jsx-no-useless-fragment */
import { cn, copyToClipboard, useCaseItemLink, usePrevious } from '@colosseum/data';
import { CaseSubType } from '@gladiate/types';
import { LinkIcon } from '@heroicons/react/20/solid';
import { CheckIcon, X } from 'lucide-react';
import { Dispatch, ReactNode, SetStateAction, useContext, useLayoutEffect, useState } from 'react';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import { SlideoverContext } from '../SlideoverProvider';
import TypingDots from '../TypingDots/TypingDots';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { Button } from '../shadcn/Button/Button';
import Modal from '../shadcn/Modal/Modal';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../shadcn/Sheet/Sheet';
import { Tabs, TabsList, TabsTrigger, tabClassSlideover } from '../shadcn/Tabs/Tabs';

export interface SlideoverProps {
  bottomButtons?: any;
  children: JSX.Element | JSX.Element[];
  dataCy?: string;
  defaultTab?: string;
  deleteButtonText?: string;
  deleteFunction?: () => void;
  deleteModalDescription?: string;
  deleteModalTitle?: string;
  description?: string;
  displayDeleteButton?: boolean;
  noPadding?: boolean;
  onClose?: () => void;
  open: boolean;
  secondaryButton?: ReactNode;
  setOpen: Dispatch<SetStateAction<boolean>>;
  submitButton?: ReactNode;
  tabHeaders?: {
    value: string;
    name: string;
    showBottomButtons?: boolean;
    count?: string;
  }[];
  title?: string;
  typing?: boolean;
  warningOnExit?: boolean;
  resourceType?: CaseSubType;
  resourceId?: string;
}

export function Slideover(props: SlideoverProps) {
  const {
    open,
    setOpen,
    children,
    title,
    description,
    tabHeaders,
    noPadding,
    warningOnExit,
    dataCy,
    displayDeleteButton,
    deleteButtonText,
    deleteFunction,
    secondaryButton,
    submitButton,
    bottomButtons,
    defaultTab,
    typing,
    onClose,
    deleteModalDescription,
    deleteModalTitle,
    resourceId,
    resourceType,
  } = props;
  const { slideoverCount, updateSlideoverCount } = useContext(SlideoverContext);
  const prevOpen = usePrevious(open);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTab ?? 'details');
  const [showExitWarning, setShowExitWarning] = useState(false);
  const { path } = useCaseItemLink(resourceId ?? '', resourceType ?? 'case');
  const shouldDisplayDeleteButton =
    (displayDeleteButton && activeTab === 'details') ||
    tabHeaders?.some((tab) => tab.value === activeTab && tab.showBottomButtons);

  const shouldDisplayBottomButtons =
    (bottomButtons &&
      tabHeaders?.some((tab) => tab.value === activeTab && tab.showBottomButtons)) ||
    activeTab === 'details';

  // Create a function to control the exit warning display
  function handleClose() {
    if (warningOnExit) {
      setShowExitWarning(true);
    } else {
      setOpen(false);
      onClose?.(); // Call onClose callback if defined
    }
  }

  function handleLinkToItem() {
    if (!resourceType || !resourceId) return;
    const url = window.location.href;
    copyToClipboard(`${url}${path}`);
  }

  useLayoutEffect(() => {
    if (!open) {
      setActiveTab(defaultTab ?? 'details'); // Revert to first tab when slideover closes
    }
    if (open === true && prevOpen === false) {
      if (defaultTab) {
        setActiveTab(defaultTab);
      }
      updateSlideoverCount('add');
    } else if (open === false && prevOpen === true) {
      updateSlideoverCount('remove');
      onClose?.();
    }
  }, [open]);

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        } else setOpen(open);
      }}
    >
      <SheetContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        data-cy={dataCy ?? `slideover-${title}`}
        className="p-0 border-none rounded-l-lg focus:ring-0 focus-visible:outline-none"
      >
        <Tabs
          value={activeTab}
          onValueChange={(val: string) => setActiveTab(val)}
          className="flex flex-col h-svh"
        >
          <SheetHeader className="top-0 z-30 w-full px-4 py-6 text-white rounded-none rounded-tl-lg bg-atlantic-blue sm:px-6">
            <div className="flex gap-2">
              {resourceType && resourceId && (
                <div className="group self-center rounded-md p-0.5 group-hover:bg-atlantic-blue hover:cursor-pointer">
                  <TooltipWrapper message="Click to copy item link">
                    <LinkIcon
                      className="w-5 h-5 text-light-blue group-hover:text-white"
                      onClick={handleLinkToItem}
                    />
                  </TooltipWrapper>
                </div>
              )}

              <SheetTitle className="line-clamp-2">{title}</SheetTitle>
            </div>

            <SheetDescription className="text-sm text-light-blue">{description}</SheetDescription>
            {tabHeaders && (
              <TabsList className="mt-4 mb-[-8px] bg-transparent text-light-blue justify-start">
                {tabHeaders.map((tabHeader) => (
                  <TabsTrigger
                    className={tabClassSlideover}
                    key={tabHeader.value}
                    value={tabHeader.value}
                    data-cy={`slideover-tab-${tabHeader.value}`}
                  >
                    <span className="flex gap-x-1">
                      {tabHeader.name}
                      {tabHeader?.count && tabHeader.count !== '0' ? (
                        <div
                          className={cn(
                            'self-center data-[state=active]:no-underline no-underline min-w-4 h-4 text-xs   rounded-xl justify-center flex flex-col',
                            tabHeader?.value === 'feed'
                              ? 'bg-orange-500 text-white'
                              : 'bg-light-blue text-black',
                          )}
                        >
                          {tabHeader.count}
                        </div>
                      ) : null}
                    </span>
                  </TabsTrigger>
                ))}
              </TabsList>
            )}
            <SheetClose
              data-cy="slideover-close-button"
              className="absolute text-light-blue hover:text-atlantic-blue hover:bg-light-blue right-6 top-6 rounded-lg hover:rounded-lg opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
            >
              <X className="w-6 h-6" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </SheetHeader>

          {showExitWarning && (
            <Modal
              open={showExitWarning}
              setOpen={setShowExitWarning}
              title="Unsaved Changes"
              description="There are unsaved changes. Are you sure you want to exit?"
              primaryButton={{
                label: 'Yes, exit',
                className:
                  'text-red11 bg-red4 hover:bg-red5 focus:shadow-red7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]',
                onClick: () => props.setOpen(false),
              }}
            />
          )}

          <div
            id="sheet-body"
            className={cn(
              'overflow-auto grow w-screen max-w-full pointer-events-auto py-4',
              noPadding ? '' : 'px-5',
            )}
          >
            {children}
          </div>

          {shouldDisplayDeleteButton && (
            <div className="flex items-center justify-between border-t border-t-gray-200">
              <div className="flex items-center px-4 py-4">
                {deleteFunction && (
                  <Button
                    variant="destructive"
                    type="button"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    {deleteButtonText ?? 'Delete'}
                  </Button>
                )}
                {secondaryButton && <div>{secondaryButton}</div>}
              </div>
              {submitButton ? (
                <div className="pr-4">{submitButton}</div>
              ) : (
                <div className="flex flex-shrink-0 px-4 py-4">
                  {typing ? (
                    <TypingDots />
                  ) : (
                    <div className="flex items-center text-green-500">
                      Saved
                      <CheckIcon className="w-5 h-5 ml-2 text-green-500" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {shouldDisplayBottomButtons && <>{bottomButtons}</>}

          <ActionConfirmModal
            open={showDeleteModal}
            setOpen={setShowDeleteModal}
            actionFunction={deleteFunction}
            actionFunctionIsMutation={true}
            callback={() => setOpen(false)}
            title={deleteModalTitle}
            description={deleteModalDescription}
          />
        </Tabs>
      </SheetContent>
    </Sheet>
  );
}

export default Slideover;
