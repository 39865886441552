import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import {
  createTimeLogCategoryV3,
  createTimeLogV3,
  deleteTimeLogCategoryV3,
  deleteTimeLogV3,
  getAllTimeLogsV3,
  getTimeLogCategoriesV3,
  getTimeLogsV3,
  updateTimeLogCategoryV3,
  updateTimeLogV3,
} from '../requests/legion';

// ------------------- TIME LOG ------------------- //
// #region
export const useGetTimeLogs = (itemId?: string, username?: string) => {
  return useQuery({
    queryKey: [queryKeys.timeLogs, username, itemId],
    queryFn: async () =>
      getTimeLogsV3(itemId).then((res) => {
        if (username) {
          return {
            ...res,
            data: res.data.filter((timeLog) => timeLog.username === username),
          };
        } else {
          return res;
        }
      }),
    enabled: !!itemId,
  });
};

export const useGetAllTimeLogs = (username?: string) => {
  return useQuery({
    queryKey: [queryKeys.timeLogs, username],
    queryFn: async () =>
      getAllTimeLogsV3().then((res) => {
        if (username) {
          return {
            ...res,
            data: res.data.filter((timeLog) => timeLog.username === username),
          };
        } else {
          return res;
        }
      }),
  });
};

export const useCreateTimeLog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createTimeLogV3>[0]) => createTimeLogV3({ ...data }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogs],
      });
    },
  });
};

export const useDeleteTimeLog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (timeLogId: string) => deleteTimeLogV3(timeLogId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogs],
      });
    },
  });
};

export const useUpdateTimeLog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateTimeLogV3>[0]) => updateTimeLogV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogs],
      });
    },
  });
};
// #endregion
// ------------------- TIME LOG CATEGORY ------------------- //
// #region
export const useGetTimeLogCategories = () => {
  return useQuery({
    queryKey: [queryKeys.timeLogCategories],
    queryFn: async () => getTimeLogCategoriesV3(),
  });
};

export const useCreateTimeLogCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createTimeLogCategoryV3>[0]) =>
      createTimeLogCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogCategories],
      });
    },
  });
};

export const useUpdateTimeLogCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateTimeLogCategoryV3>[0]) =>
      updateTimeLogCategoryV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogCategories],
      });
    },
  });
};

export const useDeleteTimeLogCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (timeLogCategoryId: string) => deleteTimeLogCategoryV3(timeLogCategoryId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.timeLogCategories],
      });
    },
  });
};
// #endregion
