export const settingsSectionMappings = {
  users: 'User Management',
  userGroups: 'User Groups',
  firmDetails: 'Firm Details',
  attorneyFees: `Attorney's Fees`,
  caseDefaults: 'Case Defaults',
  timeTrackingCategories: 'Time Tracking Categories',
  medicalTreatmentTypes: 'Medical Treatment Types',
  expenseCategories: 'Expense Categories / Codes',
  customAttributes: 'Custom Attributes',
  firmCalendar: 'Firm Calendar',
  eSign: 'E-Sign',
  phoneNumbers: 'Phone Numbers',
} as const;
