import { createElement } from 'react';

export type TagVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'label' | 'span';

export interface DynamicTypographyProps {
  children: React.ReactNode;
  tag?: TagVariants;
  className?: string;
}

export const DynamicTypography = ({ tag = 'div', children, ...props }: DynamicTypographyProps) =>
  createElement(tag, props, children);
