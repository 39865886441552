import { ContactType, LienType, caseContactConnectionOptions } from '@gladiate/types';
import { useGetContactsInfinite } from '../lib/react-query/contact';
import { useGetRoleIdForItem } from './useGetBillingEntityId';

export interface CompleteLienType extends LienType {
  billingEntity: ContactType;
  lienHolder: ContactType;
  lienProvider: ContactType;
  reductionAmount?: number;
  reductionPercentage?: number;
}

export function useCompleteLienValues(caseId: string) {
  const getRoleIdForItem = useGetRoleIdForItem<LienType>(caseId);

  const { data: allContactsData } = useGetContactsInfinite();

  const getCompleteLienValues = (liens: LienType[]) => {
    const completeLiens = liens.map((lien) => {
      return getCompleteLienValue(lien);
    });
    return completeLiens;
  };

  const getCompleteLienValue = (lien: LienType) => {
    const billingEntityId = getRoleIdForItem(
      lien,
      caseContactConnectionOptions.billingEntity,
      'lienId',
    );
    const lienHolderId = getRoleIdForItem(lien, caseContactConnectionOptions.lienHolder, 'lienId');
    const lienProviderId = getRoleIdForItem(
      lien,
      caseContactConnectionOptions.lienProvider,
      'lienId',
    );
    const billingEntity = allContactsData?.data?.find(
      (contact) => contact.contactId === billingEntityId,
    );
    const lienHolder = allContactsData?.data?.find((contact) => contact.contactId === lienHolderId);
    const lienProvider = allContactsData?.data?.find(
      (contact) => contact.contactId === lienProviderId,
    );
    const reductionAmount =
      lien.lienAmount && lien.finalBalance ? lien.lienAmount - lien.finalBalance : undefined;
    const reductionPercentage =
      reductionAmount && lien.lienAmount ? (reductionAmount / lien.lienAmount) * 100 : undefined;

    return {
      ...lien,
      billingEntity,
      lienHolder,
      lienProvider,
      reductionAmount,
      reductionPercentage,
    } as CompleteLienType;
  };

  return {
    getCompleteLienValue,
    getCompleteLienValues,
  };
}
