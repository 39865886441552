import { ColumnDef } from '@tanstack/react-table';
import {
  renderCell,
  renderCheckboxCell,
  renderHeader,
} from '../shadcn/data-table/table-render-helpers';
import { DefaultAttorneyFeeType } from '@gladiate/types';

export const columns: ColumnDef<DefaultAttorneyFeeType>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    enableHiding: false,
  },
  {
    accessorFn: (row) => row.feePercentage,
    id: 'Percentage',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.currentlyApplicable,
    id: 'Default',
    header: renderHeader,
    cell: (props) => renderCheckboxCell({ props }),
  },
];
