import { ResourceAttachmentType } from '@gladiate/types';
import { useState } from 'react';
import ResourceFileAttacher from '../ResourceFileAttacher/ResourceFileAttacher';
import ResourceFileAttachments from '../ResourceFileAttachments/ResourceFileAttachments';
import { Input } from '../shadcn/Input/Input';

/* eslint-disable-next-line */
export interface ResourceFileAttachmentSectionProps {
  resourceId: string;
  caseId: string;
  type: ResourceAttachmentType;
}

export function ResourceFileAttachmentSection(props: ResourceFileAttachmentSectionProps) {
  const { resourceId, caseId, type } = props;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  if (!resourceId || !caseId) {
    console.error('ResourceFileAttachmentSection: resourceId is required');
    return;
  }

  return (
    <div className="gap-3 ">
      <div className="flex items-center justify-between pb-4 mb-3 text-lg border-b border-gray-200">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Attachments"
        />
        <ResourceFileAttacher
          large
          caseId={caseId}
          resourceId={resourceId}
          type={type}
          onLoadingStateChange={(loading) => setLoading(loading)}
        />
      </div>

      <ResourceFileAttachments
        caseId={caseId}
        resourceId={resourceId}
        search={search}
        attachmentsContainerClassNames="flex-col flex gap-3"
        inSlideover={true}
        loading={loading}
      />
    </div>
  );
}

export default ResourceFileAttachmentSection;
