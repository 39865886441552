import { cn, displayContactName, formatPrice, prettifyDateString } from '@colosseum/data';
import {
  TooltipWrapper,
  renderCell,
  renderHeader,
  tableRenderHelperTextClasses,
} from '@colosseum/shared-ui';
import { ContactType, TrustAccountTransactionType } from '@gladiate/types';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { startCase } from 'lodash';

const colorClasses = {
  deposit: 'text-green-500',
  withdrawal: 'text-red-500',
};

export const columns: ColumnDef<
  TrustAccountTransactionType & { payee?: ContactType; payor?: ContactType }
>[] = [
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.datePaid),
    id: 'Date Paid',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => startCase(row.reasonForEntry),
    id: 'Reason for Entry',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.checkNumber,
    id: 'Check Number',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.payor),
    id: 'Payor',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.payee),
    id: 'Payee',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => (row?.typeOfEntry ? startCase(row.typeOfEntry) : row?.typeOfEntry),
    id: 'Type of Entry',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => {
      const value = props.getValue() as TrustAccountTransactionType['typeOfEntry'] | null;
      const typeOfEntry = value;
      // if function returns us null we want to still use value
      const finalValue = typeOfEntry ? startCase(typeOfEntry) : typeOfEntry;

      return (
        <div className="flex space-x-2">
          {typeOfEntry ? (
            <span className={cn(tableRenderHelperTextClasses)}>{finalValue || '-'}</span>
          ) : (
            <TooltipWrapper message="This transaction is missing a type of entry and will not be counted in the running total">
              <ExclamationTriangleIcon className="w-5 h-5 ml-2 text-yellow-500" />
            </TooltipWrapper>
          )}
        </div>
      );
    },
  },
  {
    accessorFn: (row) =>
      row.typeOfEntry === 'withdrawal' && row?.amount ? row.amount * -1 : row?.amount,
    id: 'Amount',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => {
      const amount = props.getValue() as TrustAccountTransactionType['amount'];
      const type = props.row.original.typeOfEntry;
      // if function returns us null we want to still use value
      const finalValue = amount ? formatPrice(amount) ?? amount : amount;

      return (
        <div className="flex space-x-2">
          {amount ? (
            <span className={cn(tableRenderHelperTextClasses, colorClasses[type])}>
              {finalValue || '-'}
            </span>
          ) : (
            <span className={tableRenderHelperTextClasses}>-</span>
          )}
        </div>
      );
    },
  },
];
