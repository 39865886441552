/* eslint-disable-next-line */
import {
  useGetContactCampaign,
  useGetContactsInfinite,
  useGetTags,
  useUpdateContactCampaign,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  CardSection,
  Form,
  GladiateLoader,
  SelectCombobox,
  SelectFormInput,
  TextFormInput,
} from '@colosseum/shared-ui';
import {
  CampaignStepOptions,
  CaseCampaignStatus,
  ContactCampaign,
  ContactTypeOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';
import NextButton from '../NextButton/NextButton';

export interface ContactTypePageProps {
  campaignData?: ContactCampaign;
}

const formSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  inclusionaryTags: z.array(z.string()),
  exclusionaryTags: z.array(z.string()),
  status: z.union([
    z.literal<CaseCampaignStatus>('active'),
    z.literal<CaseCampaignStatus>('paused'),
    z.literal<CaseCampaignStatus>('draft'),
    z.literal<CaseCampaignStatus>('past'),
  ]),
  contactType: z.array(
    z.union([
      z.literal<ContactTypeOptions>('individual'),
      z.literal<ContactTypeOptions>('organization'),
    ]),
  ),
});

export function ContactCampaignCriteriaPage(props: ContactTypePageProps) {
  // #region Hooks
  const { campaignId: contactCampaignId } = useParams() as {
    campaignId: string;
  };
  const updateCampaign = useUpdateContactCampaign();

  const navigate = useNavigate();
  // #endregion

  // #region Data Fetching
  const { data: campaignData, isLoading: isCampaignDataLoading } =
    useGetContactCampaign(contactCampaignId);
  const { data: contactsData, isLoading: isContactsDataLoading } = useGetContactsInfinite();
  const { data: tagsData } = useGetTags();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: campaignData?.data,
    mode: 'onSubmit',
  });
  // #endregion

  // #region State
  const [selectedIncludeTags, setSelectedIncludeTags] = useState<string[]>([]);
  const [selectedExcludeTags, setSelectedExcludeTags] = useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = useState<string[]>([]);
  const [recalculateCampaignModal, setRecalculateCampaignModal] = useState(false);
  // #endregion
  // #region Derived State

  const availableTags = tagsData?.data.reduce((acc, item) => {
    if (!item.title) {
      return acc;
    }
    return {
      ...acc,
      [item.title.toLocaleLowerCase()]: item.tagId,
    };
  }, {} as { [key: string]: string });
  // #endregion

  // #region Event Handlers
  function onSubmit(data: z.infer<typeof formSchema>) {
    updateCampaign
      .mutateAsync({
        contactCampaignId,
        inclusionaryTags: selectedIncludeTags,
        exclusionaryTags: selectedExcludeTags,
        description: data.description,
        status: data.status,
        title: data.title,
        contactType: selectedContactTypes.map((contactType) => contactType as ContactTypeOptions),
      })
      .then(() => {
        navigate(`/campaigns/${contactCampaignId}/contact/${CampaignStepOptions.stages}`);
      });
  }

  // #endregion

  // #region useEffects
  useEffect(() => {
    setSelectedIncludeTags(campaignData?.data?.inclusionaryTags?.map((tag) => tag) ?? []);
    setSelectedExcludeTags(campaignData?.data?.exclusionaryTags?.map((tag) => tag) ?? []);
    campaignData?.data.contactType &&
      setSelectedContactTypes(
        campaignData.data.contactType.map((contactType) => contactType as string),
      );
  }, [campaignData?.data]);
  // #endregion

  if (isContactsDataLoading || isCampaignDataLoading) {
    return <GladiateLoader />;
  }

  return (
    <div className="flex flex-col gap-5">
      <ActionConfirmModal
        open={recalculateCampaignModal}
        setOpen={setRecalculateCampaignModal}
        actionFunction={() => {
          onSubmit(form.getValues());
          setRecalculateCampaignModal(false);
        }}
        buttonText="Recalculate Campaign"
        title="Recalculate Campaign"
        description="Recalculating this campaign may change who will be the recipient of this campaign. Are you sure you want to recalculate this campaign?"
        actionFunctionIsMutation={true}
      />
      <CardSection cardHeading="Contact Campaign Criteria" className="flex flex-col justify-center">
        <div className="flex flex-col">
          <div className="p-2 text-sm">
            Before continuing, please fill out the following information to build this campaign
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="w-full">
                <div className="grid grid-cols-1 pt-2 pb-2 gap-y-5 gap-x-3">
                  <TextFormInput {...form.register('title')} title="Campaign Title" />
                  <TextFormInput
                    {...form.register('description')}
                    title="Description"
                    type="textarea"
                  />

                  {availableTags && (
                    <SelectCombobox
                      listItems={{ ...availableTags }}
                      listItemsIsObject={true}
                      setSelectedValues={setSelectedIncludeTags}
                      selectedValues={selectedIncludeTags}
                      title="Inclusionary Tags"
                      tooltipMessage="Contacts with these Tags will be included in this campaign"
                    />
                  )}

                  {availableTags && (
                    <SelectCombobox
                      listItems={{ ...availableTags }}
                      listItemsIsObject={true}
                      setSelectedValues={setSelectedExcludeTags}
                      selectedValues={selectedExcludeTags}
                      title="Exclusionary Tags"
                      tooltipMessage="Contacts with these Tags will be excluded from this campaign"
                    />
                  )}

                  <SelectCombobox
                    listItems={['individual', 'organization']}
                    setSelectedValues={setSelectedContactTypes}
                    selectedValues={selectedContactTypes}
                    title="Contact Type"
                    {...form.register(`contactType`)}
                  />

                  <SelectFormInput
                    title="Campaign Status"
                    listItems={{
                      Active: 'active',
                      Paused: 'paused',
                      Draft: 'draft',
                    }}
                    listItemsIsObject
                    defaultValue={campaignData?.data.status ?? 'draft'}
                    placeholderText="Select a campaign status"
                    handleOnChange={(e: React.SyntheticEvent) => {}}
                    {...form.register(`status`)}
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="text-mauve11 bg-blue-100 hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px] sm:w-36"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </button>
                    <NextButton
                      submit={true}
                      title="Continue"
                      nextRoute={`/campaigns/${contactCampaignId}/contact/${CampaignStepOptions.subscription}`}
                      disabled={selectedIncludeTags?.length === 0}
                      onClick={() => {
                        setRecalculateCampaignModal(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Form>
          <div className="flex justify-center"></div>
        </div>
      </CardSection>
    </div>
  );
}

export default ContactCampaignCriteriaPage;
