import { ContactType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdateContact } from '../../lib/react-query/contact';
import { zodPhoneNumberType } from './zodValidationTypes';

const clientPhoneNumbersFormSchema = z.array(
  z.object({
    number: zodPhoneNumberType.optional(),
    phoneExtension: z.string().optional(),
  }),
);

const clientEmailsFormSchema = z.array(
  z.object({
    emailAddress: z.string().optional(),
  }),
);

const formSchema = z.object({
  deceased: z.string().optional(),
  dateOfDeath: z.string().optional(),
  bankruptcy: z.boolean().optional(),
  bankruptcyDescription: z.string().optional(),
  contactEmails: clientEmailsFormSchema,
  contactNumbers: clientPhoneNumbersFormSchema,
  dateOfBirth: z.string().optional(),
  driversLicenseNumber: z.string().optional(),
  driversLicenseState: z.string().optional(),
  felony: z.boolean().optional(),
  felonyDescription: z.string().optional(),
  firstName: z.string().optional(),
  gender: z.string().optional(),
  healthClubMembership: z.string().optional(),
  healthClubMembershipId: z.string().optional(),
  lastName: z.string().optional(),
  legalStatus: z.string().optional(),
  maritalStatus: z.string().optional(),
  middleName: z.string().optional(),
  name: z.string().optional(),
  nickname: z.string().optional(),
  prefix: z.string().optional(),
  languages: z.array(z.string()).optional(),
  priors: z.boolean().optional(),
  priorsDescription: z.string().optional(),
  pronouns: z.string().optional(),
  socialSecurityNumber: z.string().optional(),
  suffix: z.string().optional(),
  unionMembership: z.string().optional(),
  unionMembershipId: z.string().optional(),
  website: z.string().optional(),
});

export const useBiographicalForm = ({ contact }: { contact?: ContactType }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: contact,
  });

  const updateContact = useUpdateContact();

  const handleUpdateBiographical = (
    name: string,
    value: string | string[] | number | undefined | null,
  ) => {
    if (!contact) {
      enqueueSnackbar('Contact not found', {
        variant: 'error',
      });
      return;
    }
    updateContact.mutateAsync({
      contactId: contact.contactId,
      [name]: value ?? '',
    });
  };

  return { form, handleUpdateBiographical };
};
