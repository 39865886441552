import {
  cn,
  formatPhoneNumber,
  settingsSectionMappings,
  useCheckAvailablePhoneNumbers,
  useGetProvisionedPhoneNumbers,
  useProvisionPhoneNumber,
} from '@colosseum/data';
import {
  BubbleItem,
  CreateButton,
  GladiateLoader,
  LabyrinthTextInput,
  Slideover,
} from '@colosseum/shared-ui';
import { LabyrinthUpdateParams, ProvisionedPhoneType } from '@gladiate/types';
import { DevicePhoneMobileIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@radix-ui/react-icons';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface PhoneNumberSectionProps {}

type PhoneTypes = 'clientCommunication' | 'outreach';

const PhoneNumberDisplay = ({
  title,
  type,
  phoneNumberObjs,
  setOpenSlideover,
  setProvisionNumberType,
}: {
  title: string;
  type: PhoneTypes;
  phoneNumberObjs: ProvisionedPhoneType[] | undefined;
  setOpenSlideover: (isOpen: boolean) => void;
  setProvisionNumberType: (type: PhoneTypes) => void;
}) => (
  <div className="flex flex-wrap items-center w-full gap-2 px-2 font-semibold text-black ">
    <div className="pr-2">{title}</div>

    {phoneNumberObjs?.map((phoneNumberObj) => (
      <BubbleItem
        key={phoneNumberObj.phoneNumber}
        text={formatPhoneNumber(phoneNumberObj.phoneNumber)}
        icon={<DevicePhoneMobileIcon className="w-4 h-4 mr-1" aria-hidden="true" />}
      />
    ))}

    <button
      onClick={() => {
        setProvisionNumberType(type);
        setOpenSlideover(true);
      }}
      className="rounded-full border border-dashed border-atlantic-blue w-[25px] h-[25px] cursor-pointer inline-flex items-center justify-center text-atlantic-blue bg-white shadow-[0_2px_10px] shadow-blackA7 hover:bg-light-blue focus:shadow-[0_0_0_2px] focus:shadow-atlantic-blue focus:border-none  outline-none"
      aria-label="Link Item"
    >
      <PlusIcon />
    </button>
  </div>
);

export function PhoneNumberSection(props: PhoneNumberSectionProps) {
  const [openSlideover, setOpenSlideover] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [availableNumbers, setAvailableNumbers] = useState<string[]>([]);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [noNumbersAvailable, setNoNumbersAvailable] = useState(false);
  const [provisionNumberType, setProvisionNumberType] = useState<PhoneTypes>('clientCommunication');

  const { data: provisionedPhoneNumberData } = useGetProvisionedPhoneNumbers();
  const provisionedPhoneNumbers = provisionedPhoneNumberData?.data;
  const clientCommunicationPhoneNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'clientCommunication',
  );
  const outreachPhoneNumbers = provisionedPhoneNumbers?.filter(
    (phoneNumber) => phoneNumber.phoneNumberType === 'outreach',
  );

  const { data: availablePhoneNumbersData, isLoading: isAvailablePhoneNumbersLoading } =
    useCheckAvailablePhoneNumbers(areaCode);
  const availablePhoneNumbers = availablePhoneNumbersData?.data;

  const provisionPhoneNumber = useProvisionPhoneNumber();

  const buttonDisabled = provisionPhoneNumber.isLoading || selectedNumber === '' || areaCode === '';

  useEffect(() => {
    //reset state
    setAvailableNumbers([]);
    setSelectedNumber('');
    setNoNumbersAvailable(false);
    setAreaCode('');
  }, [openSlideover]);

  return (
    <>
      <Slideover
        title="Provision Phone Number"
        description="Provision a phone number to receive text messages and phone calls."
        open={openSlideover}
        setOpen={setOpenSlideover}
        displayDeleteButton={false}
        typing={false}
        bottomButtons={
          <div className="flex items-center justify-between px-5 py-5">
            <button
              type="button"
              className="inline-flex justify-center px-6 py-2 text-sm font-medium border rounded-md shadow-sm text-sky-blue border-sky-blue hover:text-atlantic-blue hover:border-atlantic-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-blue"
              onClick={() => {
                setOpenSlideover(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                provisionPhoneNumber
                  .mutateAsync({
                    phoneNumber: selectedNumber,
                    phoneNumberType: provisionNumberType,
                  })
                  .then(() => {
                    setOpenSlideover(false);
                    enqueueSnackbar('Phone number provisioned successfully.', {
                      variant: 'success',
                    });
                  })
                  .catch(() => {
                    enqueueSnackbar("Couldn't provision phone number. Please try again.", {
                      variant: 'error',
                    });
                  });
              }}
              disabled={buttonDisabled}
              className={cn(
                'inline-flex justify-center px-6 py-2  ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-blue',
                !buttonDisabled
                  ? ' bg-sky-blue hover:bg-atlantic-blue '
                  : ' bg-gray-300 pointer-events-none',
              )}
            >
              {provisionPhoneNumber.isLoading ? (
                <GladiateLoader height={20} white={true} />
              ) : (
                'Provision'
              )}
            </button>
          </div>
        }
      >
        <div>
          <div className="flex items-center justify-between">
            <div className="w-full pr-5">
              <LabyrinthTextInput
                fieldInfo={{
                  title: 'Area Code',
                  type: 'text',
                  valueVariable: 'areaCode',
                  format: `^[0-9]{3}$`,
                }}
                fieldValue={areaCode}
                handleOnChange={(params: LabyrinthUpdateParams) => {
                  setAreaCode(params.value);
                }}
                handleOnBlur={(params: LabyrinthUpdateParams) => {
                  // do nothing
                }}
              />
            </div>

            <CreateButton
              title={'Search'}
              loading={false}
              disabled={!/^[0-9]{3}$/.test(areaCode) || isAvailablePhoneNumbersLoading}
              onClick={() => {
                const rawNumbers = availablePhoneNumbers?.map(
                  (numberObj) => numberObj.phone_number,
                );

                if (rawNumbers?.length === 0) {
                  setAvailableNumbers([]);
                  setNoNumbersAvailable(true);
                } else {
                  setNoNumbersAvailable(false);
                  setAvailableNumbers(rawNumbers ?? []);
                }
              }}
            />
          </div>
          <div className="mt-5">
            <div className="ml-1 font-semibold">
              {availableNumbers.length !== 0 && !noNumbersAvailable && 'Available Numbers:'}
              {noNumbersAvailable && 'No numbers available.'}
            </div>

            <div className="mt-3 ml-2 space-y-3">
              {availableNumbers.map((number: string) => (
                <div key={number} className="flex items-center">
                  <input
                    id={number}
                    name="notification-method"
                    type="radio"
                    className="w-4 h-4 border-gray-300 text-atlantic-blue focus:ring-atlantic-blue form-radio"
                    onChange={() => setSelectedNumber(number)}
                  />
                  <label
                    htmlFor={number}
                    className="block ml-3 text-sm font-medium leading-6 text-gray-900"
                  >
                    {formatPhoneNumber(number)}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Slideover>
      <div
        className="relative bg-white rounded-2xl"
        data-settings-submenu-item={settingsSectionMappings.phoneNumbers}
      >
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Phone Numbers</h1>
          <p className="mt-2 text-sm text-gray-700">
            Add phone numbers to your account to receive text messages and phone calls.
          </p>
        </div>
        <div className="mt-5 space-y-5">
          <PhoneNumberDisplay
            title="Client Communications Numbers:"
            phoneNumberObjs={clientCommunicationPhoneNumbers}
            type="clientCommunication"
            setOpenSlideover={setOpenSlideover}
            setProvisionNumberType={setProvisionNumberType}
          />
          <PhoneNumberDisplay
            title="Outreach Numbers:"
            phoneNumberObjs={outreachPhoneNumbers}
            type="outreach"
            setOpenSlideover={setOpenSlideover}
            setProvisionNumberType={setProvisionNumberType}
          />
        </div>
      </div>
    </>
  );
}

export default PhoneNumberSection;
