import { HurinUserType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAddUserToFirm, useCreateUserForFirm } from '../../../lib/react-query/firm';
import { enqueueAPISnackbar } from '../../snackbars';
import { zodPhoneNumberType } from '../zodValidationTypes';

const formSchema = z.object({
  name: z.string(),
  userName: z.string().optional(),
  email: z.string(),
  phone_number: zodPhoneNumberType.optional(),
  groupId: z.string(),
});

export const useCreateCognitoUserForm = (parentFormValues: z.infer<typeof formSchema>) => {
  const createUserMutation = useCreateUserForFirm();
  const addUserToFirm = useAddUserToFirm();

  const localForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onSubmit',
  });

  const formValues = parentFormValues ?? localForm.getValues(); // If a form is passed in, we want to use that instead of creating a new one

  const handleCreateCognitoUser = () => {
    const username = formValues['userName'] ?? formValues['email'].split('@')[0];
    const email = formValues['email'] || undefined;
    const payload = {
      name: formValues['name'] || undefined,
      email: email || undefined,
      phone_number: formValues['phone_number'],
      desiredDeliveryMediums: ['EMAIL'],
      groupId: formValues['groupId'],
    };
    if (email === undefined) {
      enqueueAPISnackbar({
        message: 'Please enter an email',
        variant: 'error',
      });
      return new Promise<{ data: HurinUserType }>(() => {});
    } else if (username === '') {
      enqueueAPISnackbar({
        message: 'Please enter a username',
        variant: 'error',
      });
      return new Promise<{ data: HurinUserType }>(() => {});
    } else {
      return createUserMutation
        .mutateAsync({
          username: username,
          ...payload,
        })
        .then((res) => {
          addUserToFirm.mutateAsync(username);
          return res;
        });
    }
  };

  return {
    form: localForm,
    handleUpdate: handleCreateCognitoUser,
    isLoading: createUserMutation.isLoading,
  };
};
