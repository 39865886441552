import { camelCase, findKey } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { commaSafeSplit } from './miscUtils';

// Define your map to rename keys
const keyMap: Record<string, string> = {
  'Case Status': 'caseStatuses',
  'Case Type': 'caseTypes',
  'Contact Type': 'contactTypes',
  'Court Location': 'courtLocation',
  'Court Name': 'courtName',
  'Court Type': 'courtType',
  'Date Created': 'contactCreatedDateRange',
  'Days in Status Relative to Goal': 'daysInStatusRelativeToGoal',
  'Incident City': 'incidentCity',
  'Incident Date': 'incidentDateRange',
  'Incident State': 'incidentState',
  'Incident Street': 'incidentStreet',
  'Incident Zip Code': 'incidentZipCode',
  'Initial Filing Date': 'initialFilingDateRange',
  'Injection Count': 'injectionCount',
  'Marketing Referral Source ID': 'referralSources',
  'MRI Count': 'mriCount',
  'Open Date': 'openDateRange',
  'Statute Date': 'statuteDateRange',
  'Statute of Limitations Satisfied': 'statuteOfLimitationsSatisfied',
  'Surgery Count': 'surgeryCount',
  'Third Party Coverage Accident': 'thirdPartyCoveragePerAccident',
  'Third Party Coverage Person': 'thirdPartyCoveragePerPerson',
  'Total Medicals': 'totalMedicals',
  'Trial Date': 'trialDateRange',
  'UM/UIM Coverage Accident': 'umUimCoveragePerAccident',
  'UM/UIM Coverage Person': 'umUimCoveragePerPerson',
  'Uncompleted Tasks Percentage': 'uncompletedTasksPercent',
  'Zip Code': 'zipCodes',
  Assignees: 'assignees',
  Teams: 'teams',
  City: 'cities',
  Conflicts: 'conflicts',
  County: 'counties',
  Email: 'emails',
  Expenses: 'expenses',
  Phone: 'phoneNumbers',
  'Projected Settlement Amount': 'projectedSettlementAmount',
  'Estimated Medical Costs': 'estimatedMedicalCosts',
  State: 'states',
  Tags: 'tags',
  Treatments: 'treatments',
};

const dateRangeKeys = [
  'contactCreatedDateRange',
  'openDateRange',
  'incidentDateRange',
  'initialFilingDateRange',
  'trialDateRange',
  'statuteDateRange',
];

const rangeKeys = [
  'expenses',
  'injectionCount',
  'mriCount',
  'surgeryCount',
  'thirdPartyCoveragePerAccident',
  'thirdPartyCoveragePerPerson',
  'totalMedicals',
  'treatments',
  'umUimCoveragePerAccident',
  'umUimCoveragePerPerson',
  'uncompletedTasksPercent',
  'projectedSettlementAmount',
  'estimatedMedicalCosts',
];

export const useURLParamsAsObject = () => {
  const location = useLocation();
  const [queryParamsObject, setQueryParamsObject] = useState<Record<string, string | string[]>>({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const obj: Record<string, string | string[]> = {};

    for (const [key, value] of queryParams.entries()) {
      if (key === 'Name') continue;
      if (key === 'Case Title') continue;

      const newKey = keyMap[key] || camelCase(key);

      if (dateRangeKeys.includes(newKey)) {
        const dateValues = value.split(',');
        if (dateValues.length === 2) {
          obj[`${newKey}Start`] = dateValues[0];
          obj[`${newKey}End`] = dateValues[1];
        } else {
          obj[`${newKey}Start`] = value;
        }
      } else if (rangeKeys.includes(newKey)) {
        const rangeValues = value.split(',');
        if (rangeValues.length === 2) {
          obj[`${newKey}Minimum`] = rangeValues[0];
          obj[`${newKey}Maximum`] = rangeValues[1];
        } else {
          obj[`${newKey}Minimum`] = value;
        }
      } else {
        const splitValues = commaSafeSplit(value);
        obj[newKey] = splitValues;
      }
    }

    setQueryParamsObject(obj);
  }, [location.search]);

  return queryParamsObject;
};

export const objectToQueryString = (obj: Record<string, string | string[]>): string => {
  const params = new URLSearchParams();
  const reverseKeyMap: Record<string, string> = Object.fromEntries(
    Object.entries(keyMap).map(([key, value]) => [value, key]),
  );

  for (const [key, values] of Object.entries(obj)) {
    if (key === 'Name') continue;

    const originalKey = reverseKeyMap[key] || key;
    if (dateRangeKeys.includes(key.replace('Start', ''))) {
      // This logic is pretty messy but it works
      const tempKey = key.replace('Start', '');
      if (typeof values === 'string') {
        const startDate = obj[`${tempKey}Start`];
        const endDate = obj[`${tempKey}End`];
        const keyToSetInURL = findKey(keyMap, (value) => value === tempKey);
        if (keyToSetInURL) {
          params.set(
            keyToSetInURL,
            startDate && endDate ? `${startDate as string},${endDate as string}` : values,
          );
        }
      } else {
        console.error(`Expected string, got: ${typeof values} for key: ${key}`);
      }
    } else if (rangeKeys.includes(key.replace('Minimum', ''))) {
      const tempKey = key.replace('Minimum', '');
      if (typeof values === 'string') {
        const minVal = obj[`${tempKey}Minimum`];
        const maxVal = obj[`${tempKey}Maximum`];
        const keyToSetInURL = findKey(keyMap, (value) => value === tempKey);
        if (keyToSetInURL) {
          params.set(
            keyToSetInURL,
            minVal && maxVal ? `${minVal as string},${maxVal as string}` : values,
          );
        }
      } else {
        console.error(`Expected string, got: ${typeof values} for key: ${key}`);
      }
    } else {
      if (Array.isArray(values)) {
        const joinedValues = values.join(',');
        params.set(originalKey, joinedValues);
      } else {
        console.error(`Expected array, got: ${typeof values} for key: ${key}`);
      }
    }
  }

  return params.toString();
};
