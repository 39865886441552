import { useGetResourceAttachments } from '../lib/react-query/file-management';
import { useGetNotes } from '../lib/react-query/note';
import { useGetTasks } from '../lib/react-query/task';
import { useGetAuditsWithNotifications } from './useGetAuditsWithNotifications';

export const useGetResourceSlideoverItems = (resourceId?: string) => {
  const audits = useGetAuditsWithNotifications({
    itemId: resourceId,
  });
  const { data: notesData } = useGetNotes(resourceId);
  const { data: tasksData } = useGetTasks(resourceId);
  const { data: attachmentsData } = useGetResourceAttachments(resourceId ?? '');
  const auditsCount = audits?.notificationCount ? Number(audits.notificationCount) : 0;
  const notesCount = notesData?.data?.length ?? 0;
  const tasksCount = tasksData?.data?.length ?? 0;
  const attachmentsCount = attachmentsData?.data?.data?.length ?? 0;

  return {
    audits,
    notesData,
    tasksData,
    attachmentsData,
    auditsCount: auditsCount > 99 ? '99+' : auditsCount,
    notesCount: notesCount > 99 ? '99+' : notesCount,
    tasksCount: tasksCount > 99 ? '99+' : tasksCount,
    attachmentsCount: attachmentsCount > 99 ? '99+' : attachmentsCount,
  };
};
