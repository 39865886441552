import {
  DefaultV3Response,
  ExpenseCategoryType,
  ExpenseType,
  UTBMSCodeType,
} from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseNexumRoute = `nexum/${API_STAGE}`;

// ------------------ CASE EXPENSE ------------------

// POST create_case_expense_v3
export const createCaseExpenseV3 = (data: { caseId: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<ExpenseType>>(`${baseNexumRoute}/v3/cases/${caseId}/expenses`, rest)
    .then((res) => res.data);
};

// DELETE delete_case_expense_v3
export const deleteCaseExpenseV3 = (caseExpenseId: string) =>
  axiosInstance
    .delete<DefaultV3Response<ExpenseType>>(`${baseNexumRoute}/v3/cases/expenses/${caseExpenseId}`)
    .then((res) => res.data);

//GET get_all_case_expenses_v3
export const getAllCaseExpensesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ExpenseType[]>>(`${baseNexumRoute}/v3/cases/expenses`)
    .then((res) => res.data);

//GET get_case_expenses_v3
export const getCaseExpensesV3 = (caseId: string) =>
  axiosInstance
    .get<
      DefaultV3Response<ExpenseType[], { aggregateTotalCost: number; caseExpenseTotal: number }>
    >(`${baseNexumRoute}/v3/cases/${caseId}/expenses`)
    .then((res) => {
      const expenses = res.data.data.map((expense) => {
        const totalCost = (expense?.unitCount ?? 1) * (expense?.unitPrice ?? 0);
        return {
          ...expense,
          totalCost,
        };
      });
      const aggregateTotalCost = expenses.reduce((acc: number, expense: ExpenseType) => {
        if (expense.totalCost) {
          return acc + expense.totalCost;
        } else {
          return acc;
        }
      }, 0);
      const caseExpenseTotal = expenses.reduce((acc: number, expense: ExpenseType) => {
        if (expense.totalCost && expense.waive !== '1') {
          return acc + expense.totalCost;
        } else {
          return acc;
        }
      }, 0);
      return {
        data: expenses,
        meta: { ...res.data.meta, aggregateTotalCost, caseExpenseTotal },
      };
    });

// PATCH update_case_expense_v3
export const updateCaseExpenseV3 = (
  data: Partial<ExpenseType> & {
    caseExpenseId: ExpenseType['caseExpenseId'];
  },
) => {
  const { caseExpenseId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<ExpenseType>>(
      `${baseNexumRoute}/v3/cases/expenses/${caseExpenseId}`,
      rest,
    )
    .then((res) => res.data);
};

export const createExpenseAttachment = (data: {
  caseExpenseId: string;
  fileResourceId: string;
}) => {
  const { caseExpenseId, fileResourceId } = data;

  return axiosInstance.post<DefaultV3Response<{ caseExpenseAttachmentId: string }>>(
    `${baseNexumRoute}/v3/cases/expenses/${caseExpenseId}/attachments`,
    {
      fileResourceId,
    },
  );
};

export const getExpenseAttachments = (caseExpenseId: string) =>
  axiosInstance
    .get<DefaultV3Response<{ fileResourceId: string; attachmentId: string }[]>>(
      `${baseNexumRoute}/v3/cases/expenses/${caseExpenseId}/attachments`,
    )
    .then((res) => res.data);

export const deleteExpenseAttachment = (attachmentId: string) =>
  axiosInstance
    .delete<DefaultV3Response<{ fileResourceId: string }[]>>(
      `${baseNexumRoute}/v3/cases/expenses/attachments/${attachmentId}`,
    )
    .then((res) => res.data);

// ------------------ CASE EXPENSE CATEGORY ------------------

// POST create_case_expense_category_v3
export const createCaseExpenseCategoryV3 = (data: { title?: string; description?: string }) =>
  axiosInstance
    .post<DefaultV3Response<ExpenseCategoryType>>(
      `${baseNexumRoute}/v3/cases/expenses/categories`,
      data,
    )
    .then((res) => res.data);

// DELETE delete_case_expense_category_v3
export const deleteCaseExpenseCategoryV3 = (caseExpenseCategoryId: string) =>
  axiosInstance
    .delete<DefaultV3Response<ExpenseCategoryType>>(
      `${baseNexumRoute}/v3/cases/expenses/categories/${caseExpenseCategoryId}`,
    )
    .then((res) => res.data);

// GET get_case_expense_categories_v3
export const getCaseExpenseCategoriesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ExpenseCategoryType[]>>(`${baseNexumRoute}/v3/cases/expenses/categories`)
    .then((res) => res.data);

// PATCH update_case_expense_category_v3
export const updateCaseExpenseCategoryV3 = (
  data: Partial<ExpenseCategoryType> & { caseExpenseCategoryId: string },
) => {
  const { caseExpenseCategoryId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<ExpenseCategoryType>>(
      `${baseNexumRoute}/v3/cases/expenses/categories/${caseExpenseCategoryId}`,
      rest,
    )
    .then((res) => res.data);
};

export const getUTBMSExpenseCodes = () =>
  axiosInstance
    .get<DefaultV3Response<UTBMSCodeType[]>>(`${baseNexumRoute}/v3/cases/expenses/utbms-codes`)
    .then((res) => res.data);

export const createUTBMSExpenseCode = () =>
  axiosInstance
    .post<DefaultV3Response<UTBMSCodeType>>(`${baseNexumRoute}/v3/cases/expenses/utbms-codes`, {})
    .then((res) => res.data);

export const updateUTBMSExpenseCode = (data: Partial<UTBMSCodeType>) => {
  const { caseExpenseUtbmsCodeId, ...rest } = data;

  return axiosInstance.patch<DefaultV3Response<UTBMSCodeType>>(
    `${baseNexumRoute}/v3/cases/expenses/utbms-codes/${caseExpenseUtbmsCodeId}`,
    rest,
  );
};

export const deleteUTBMSExpenseCode = (caseExpenseUtbmsCodeId: string) =>
  axiosInstance.delete<DefaultV3Response<UTBMSCodeType>>(
    `${baseNexumRoute}/v3/cases/expenses/utbms-codes/${caseExpenseUtbmsCodeId}`,
  );
