import { CaseSurvey, DefaultV3Response, FirmSurveysList } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const baseGromaticiRoute = `gromatici/${API_STAGE}`;

// ------------------- Firm Surveys ------------------- //
// #region
// GET list_surveys_v3
export const getListSurveysV3 = () =>
  axiosInstance
    .get<DefaultV3Response<FirmSurveysList[]>>(`${baseGromaticiRoute}/v3/surveys`)
    .then((res) => res.data);
// #endregion

// ------------------- Case Surveys ------------------- //
// #region Case Surveys
// GET list_case_surveys_v3
export const getListCaseSurveysV3 = (caseId: string) =>
  axiosInstance
    .get<DefaultV3Response<CaseSurvey[]>>(`${baseGromaticiRoute}/v3/cases/${caseId}/surveys`)
    .then((res) => res.data);

// POST create_case_survey_v3
export const createCaseSurveyV3 = (data: { caseId: string; surveyId: string }) => {
  const { caseId, ...rest } = data;
  return axiosInstance
    .post<DefaultV3Response<CaseSurvey[]>>(`${baseGromaticiRoute}/v3/cases/${caseId}/surveys`, rest)
    .then((res) => res.data);
};

// DELETE delete_case_survey_v3
export const deleteCaseSurveyV3 = (surveyId: string) =>
  axiosInstance
    .delete<DefaultV3Response<CaseSurvey>>(`${baseGromaticiRoute}/v3/cases/surveys/${surveyId}`)
    .then((res) => res.data);

// #endregion
