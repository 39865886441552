import { cn } from '@colosseum/data';
import { useEffect, useRef, useState } from 'react';

/* eslint-disable-next-line */
export interface EditableTextAreaProps {
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  id: string;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  showOutlines?: boolean;
  dataCy?: string;
}

const outlineClasses =
  'outline outline-atlantic-blue outline-0 outline-offset-6 active:outline-1 hover:outline-1';

export function EditableTextArea(props: EditableTextAreaProps) {
  const { onBlur, id, defaultValue, placeholder, className, showOutlines, dataCy } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(defaultValue ?? placeholder ?? '');

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef?.current.scrollHeight;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  useEffect(() => {
    setValue(defaultValue ?? placeholder ?? '');
  }, [defaultValue, placeholder]);

  return (
    <textarea
      data-cy={dataCy ?? placeholder}
      ref={textAreaRef}
      onChange={(e) => {
        const target = e.target as HTMLTextAreaElement;
        setValue(target.value);
      }}
      onBlur={onBlur}
      id={id}
      placeholder={placeholder}
      value={value}
      className={cn(
        'flex grow p-1 rounded-lg resize-none outline-none',
        showOutlines && outlineClasses,
        className,
      )}
    />
  );
}

export default EditableTextArea;
