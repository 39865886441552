import { useGetAuditsWithNotifications, useUpdateCaseCampaignSubscription } from '@colosseum/data';
import { Breadcrumbs, Form, SelectFormInput, Slideover } from '@colosseum/shared-ui';
import { CampaignSubscriptionStatus, CaseCampaignSubscription } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface CaseCampaignSubscriptionSlideoverProps {
  slideoverOpen: boolean;
  setSlideoverOpen: Dispatch<SetStateAction<boolean>>;
  caseCampaignSubscription: CaseCampaignSubscription;
}

const formSchema = z.object({
  status: z.union([
    z.literal<CampaignSubscriptionStatus>('active'),
    z.literal<CampaignSubscriptionStatus>('inactive'),
  ]),
});

export default function CaseCampaignSubscriptionSlideover({
  slideoverOpen,
  setSlideoverOpen,
  caseCampaignSubscription,
}: CaseCampaignSubscriptionSlideoverProps) {
  // #region Hooks
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const updateCaseCampaignSubscription = useUpdateCaseCampaignSubscription();
  // #endregion

  // #region Data Fetching
  const { data: caseUpdatesData, isLoading } = useGetAuditsWithNotifications({
    itemId: caseCampaignSubscription.case.caseId,
    isCase: true,
  });

  // #endregion

  // #region State
  const [typing, setTyping] = useState(false);
  // #endregion

  // #region Derived State
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  function submitForm(data: z.infer<typeof formSchema>) {
    setTyping(true);
    updateCaseCampaignSubscription
      .mutateAsync({
        status: data.status,
        caseCampaignSubscriptionId: caseCampaignSubscription.caseCampaignSubscriptionId,
      })
      .then(() => {
        setTyping(false);
        setSlideoverOpen(false);
      });
  }
  // #endregion

  return (
    <>
      <Slideover
        setOpen={setSlideoverOpen}
        open={slideoverOpen}
        title="Case Campaign Subscription"
        typing={typing}
        displayDeleteButton={true}
      >
        <div className="flex flex-col gap-4 pt-2">
          <Breadcrumbs resourceType="case" resourceId={caseCampaignSubscription.case.caseId} />
          <Form {...form}>
            <form>
              <SelectFormInput
                title="Case Campaign Subscription Status"
                listItems={{
                  Active: 'active',
                  Inactive: 'inactive',
                }}
                listItemsIsObject
                placeholderText="Select a campaign status"
                handleOnChange={(e: React.SyntheticEvent) => {
                  submitForm(form.getValues());
                }}
                defaultValue={caseCampaignSubscription.status}
                {...form.register(`status`)}
                tooltipMessage='Subscriptions marked as "Active" will be included in the campaign. Subscriptions marked as "Inactive" will not be included in the campaign.'
              />
            </form>
          </Form>
        </div>
      </Slideover>
    </>
  );
}
