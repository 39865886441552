import { convertDiffToHoursDuration } from '@colosseum/data';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { useCallback, useEffect, useState } from 'react';
dayjs.extend(duration);
dayjs.extend(utc);
/* eslint-disable-next-line */
export interface TimerProps {
  startTime?: string;
  runTimer: boolean;
}

export function Timer(props: TimerProps) {
  const { startTime, runTimer } = props;
  const calculateTimeElapsed = useCallback(
    (startTime?: string) => {
      if (!startTime) return '...';
      return convertDiffToHoursDuration(dayjs().diff(dayjs.utc(startTime))).format(
        'HH:mm:ss',
      ) as string;
    },
    [startTime],
  );
  const [timeElapsed, setTimeElapsed] = useState<string | undefined>(
    calculateTimeElapsed(startTime),
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (runTimer && !interval) {
      interval = setInterval(() => {
        setTimeElapsed(calculateTimeElapsed(startTime));
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [runTimer, startTime]);
  return <span>{timeElapsed}</span>;
}

export default Timer;
