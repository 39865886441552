import { cn } from '@colosseum/data';
import * as React from 'react';

export const inputClass =
  'block w-full rounded-md form-input border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-atlantic-blue sm:text-sm sm:leading-6';

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    currency?: boolean; // Add the currency prop here
    className?: string;
    type?: string;
    label?: string;
    placeholder?: string;
  }
>(({ className, type, ...props }, ref) => {
  return (
    <div className="relative">
      {props.currency && (
        <span className="absolute mr-2 text-sm text-gray-500 left-2 top-2.5">$</span>
      )}
      {props.label && (
        <div className="absolute z-10 -top-2 left-2 inline-block bg-white rounded-sm px-1 text-xs font-medium text-gray-400">
          {props.label}
        </div>
      )}
      <input
        type={type}
        className={cn(inputClass, className, props.currency && 'pl-5')}
        ref={ref}
        {...props}
      />
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
