/* eslint-disable @typescript-eslint/no-empty-function */
import {
  DefaultV3Error,
  DefaultV3Response,
  FileResourceTemplateDescriptionType,
  FileResourceType,
  ResourceAttachmentType,
  SigningEvent,
  TemplateVariables,
} from '@gladiate/types';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import { uploadDocument } from '../requests/external';
import { generateDocument, getDocumentTags } from '../requests/signum-sigillum-libera';
import { sendDocumentForSignV3 } from '../requests/sirens-sign';
import {
  createFileRepoTemplateDescriptionV3,
  createFileRepoTemplateItemV3,
  createFileResourceItemV3,
  createResourceAttachmentV3,
  deleteFileRepoTemplateItemV3,
  deleteFileResourceItemV3,
  deleteResourceAttachmentV3,
  getDownloadFileLinkV3,
  getExhaustiveFileResourceItemsV3,
  getFileRepoTemplateExhaustiveV3,
  getFileResourceV3,
  getFirmFileRepoTemplatesV3,
  getResourceAttachmentsV3,
  getUploadFileLinkV3,
  moveFileResourceItemV3,
  renameFileRepoTemplateItemV3,
  renameFileResourceItemV3,
  updateFileRepoTemplateDescriptionV3,
} from '../requests/tabularium';

export const useGetParallelFiles = (resourceObjIds: string[]) => {
  return useQueries({
    queries: resourceObjIds.map((id) => {
      return {
        queryKey: [queryKeys.fileItem, id],
        queryFn: async () => getFileResourceV3(id),
        enabled: !!id,
      };
    }),
  });
};

export const useGetFileResource = (resourceObjIds: string) => {
  return useQuery({
    queryKey: [queryKeys.fileItem, resourceObjIds],
    queryFn: async () => getFileResourceV3(resourceObjIds),
    enabled: !!resourceObjIds,
  });
};

export const useGetExhaustiveFileResource = (caseId: string) => {
  return useQuery({
    queryKey: ['fileItemExhaustive', caseId],
    queryFn: async () => getExhaustiveFileResourceItemsV3(caseId),
    enabled: !!caseId,
  });
};

export const useGetFirmFileRepoTemplates = () => {
  return useQuery({
    queryKey: ['firmRepoTemplates'],
    queryFn: async () => getFirmFileRepoTemplatesV3(),
  });
};

export const useGetExhaustiveFileTemplateResource = (templateRootId: string) => {
  return useQuery({
    queryKey: ['fileItemExhaustive', templateRootId],
    queryFn: async () => getFileRepoTemplateExhaustiveV3(templateRootId),
    enabled: !!templateRootId,
  });
};

export const useCreateTemplateResourceRootItem = (templateRootId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      name: string;
      resourceType: 'DIR' | 'FILE';
      currentDirectory: string | null;
    }) => {
      return createFileRepoTemplateItemV3({
        templateResourceName: data.name,
        templateResourceType: data.resourceType,
      }).then((res) => {
        createFileRepoTemplateDescriptionV3(res.data.data?.templateResourceId ?? '', {
          templateName: 'New Template',
          templateDescription: 'Add a description here',
        });
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 1000);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 3000);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 1000);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 3000);
    },
  });
};

export const useCreateTemplateResourceChildItem = (templateRootId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      name: string;
      resourceType: 'DIR' | 'FILE';
      currentDirectory: string;
    }) => {
      return createFileRepoTemplateItemV3({
        templateParentObj: {
          templateParentResourceId: data.currentDirectory.split('-')[0],
          templateResourceId: data.currentDirectory.split('-')[1],
        },
        templateResourceName: data.name,
        templateResourceType: data.resourceType,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 2000);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);

      setTimeout(() => {
        queryClient.invalidateQueries(['firmRepoTemplates']);
      }, 2000);
    },
  });
};

export const useDeleteTemplateResourceItem = (templateRootId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { templateResourceId: string }) =>
      deleteFileRepoTemplateItemV3(data.templateResourceId),

    onSuccess: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
  });
};

export const useRenameTemplateResourceItem = (templateRootId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { templateResourceId: string; newName: string }) =>
      renameFileRepoTemplateItemV3(data.templateResourceId, {
        name: data.newName,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
  });
};

export const useUpdateTemplateDescription = (templateRootId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FileResourceTemplateDescriptionType) =>
      updateFileRepoTemplateDescriptionV3(data),
    // ! this seems broken but it works
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['fileItemExhaustive', templateRootId]);

      const previousTemplate = queryClient.getQueryData(['fileItemExhaustive', templateRootId]);

      queryClient.setQueryData(['fileItemExhaustive', templateRootId], (old: any) => {
        const optimisticUpdate = {
          ...old,
          templateDescription: {
            ...old.templateDescription,
            ...variables,
          },
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return optimisticUpdate;
      });

      return { previousTemplate };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['fileItemExhaustive', templateRootId], context?.previousTemplate);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItemExhaustive', templateRootId]);
      queryClient.invalidateQueries(['firmRepoTemplates']);
    },
  });
};

// this would be great with less endpoints :)
export const useUploadFileToCaseRepo = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { rawFile: File; currentDirectory: string }) => {
      return getUploadFileLinkV3(data.rawFile.name.split('.').pop() || 'pdf').then(
        (s3UploadLinkRes) => {
          const separateAxiosInstance = axios.create();

          return separateAxiosInstance({
            url: s3UploadLinkRes.data.url,
            method: 'PUT',
            data: data.rawFile,
          }).then(() => {
            return createFileResourceItemV3(caseId, {
              s3ObjKey: s3UploadLinkRes.data.objKey,
              size: data.rawFile.size,
              parentObj: {
                parentResourceId: data.currentDirectory.split('-')[0],
                resourceId: data.currentDirectory.split('-')[1],
              },
              name: data.rawFile.name,
              resourceType: 'FILE',
            });
          });
        },
      );
    },
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.currentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.currentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
  });
};

export const useUploadFileForPreview = () => {
  const count = 0;
  return useMutation({
    mutationFn: (data: { rawFile: File; objKey?: string }) => {
      if (data.objKey) {
        try {
          return getDownloadFileLinkV3(data.objKey);
        } catch (error) {
          // continue with uploading
        }
      }

      return getUploadFileLinkV3(data.rawFile.name.split('.').pop() || 'pdf').then(
        (s3UploadLinkRes) => {
          const separateAxiosInstance = axios.create();

          return separateAxiosInstance({
            url: s3UploadLinkRes.data.url,
            method: 'PUT',
            data: data.rawFile,
          }).then(() => {
            return getDownloadFileLinkV3(s3UploadLinkRes.data.objKey);
          });
        },
      );
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
  });
};

export const useCreateFileItemForCase = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      name: string;
      resourceType: 'DIR' | 'FILE';
      currentDirectory: string;
      s3ObjKey?: string;
      urlRefLink?: string;
    }) =>
      createFileResourceItemV3(caseId, {
        parentObj: {
          parentResourceId: data.currentDirectory.split('-')[0],
          resourceId: data.currentDirectory.split('-')[1],
        },
        name: data.name,
        resourceType: data.resourceType,
        s3ObjKey: data.s3ObjKey,
        urlRefLink: data.urlRefLink,
      }),
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.currentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.currentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
  });
};

export const useDeleteFileForCase = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { fileResourceId: string }) =>
      deleteFileResourceItemV3(caseId, data.fileResourceId),
    onSuccess: () => {
      queryClient.invalidateQueries(['fileItem']);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fileItem']);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
  });
};

export const useRenameFileForCase = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { fileItemToRename: string; newName: string; parentDirectory: string }) =>
      renameFileResourceItemV3(caseId, data.fileItemToRename, {
        name: data.newName,
      }),
    onMutate: async (variables) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['fileItem', variables.parentDirectory]);

      // Snapshot the previous value
      const previousFileItem: DefaultV3Response<FileResourceType> | undefined =
        queryClient.getQueryData(['fileItem', variables.parentDirectory]);

      const optimisticUpdate = {
        data: {
          ...previousFileItem?.data,
          children: previousFileItem?.data?.children?.map((child: FileResourceType) => {
            const childId = `${child.parentResourceId}-${child.resourceId}`;

            if (childId === variables.fileItemToRename) {
              return {
                ...child,
                name: variables.newName,
              };
            }
            return child;
          }),
        },
      };

      // Optimistically update to the new value
      queryClient.setQueryData(['fileItem', variables.parentDirectory], optimisticUpdate);

      // Return the snapshotted value
      return { previousFileItem };
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(['fileItem', variables.parentDirectory], context?.previousFileItem);
    },
    onSuccess: (result, variables, context) => {
      // Invalidate the query to refetch it and make sure our local data is up to date with the server data
      queryClient.invalidateQueries(['fileItem', variables.fileItemToRename]);
      queryClient.invalidateQueries(['fileItem', variables.parentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
    onSettled: (data, error, variables, context) => {
      // Invalidate the query to refetch it and make sure our local data is up to date with the server data
      queryClient.invalidateQueries(['fileItem', variables.fileItemToRename]);
      queryClient.invalidateQueries(['fileItem', variables.parentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
  });
};

export const useMoveFileForCase = (caseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      fileItemToMove: string;
      moveToDirectory: string;
      parentDirectory: string;
    }) =>
      moveFileResourceItemV3(caseId, data.fileItemToMove, {
        parentObj: {
          parentResourceId: data.moveToDirectory.split('-')[0],
          resourceId: data.moveToDirectory.split('-')[1],
        },
      }),
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.fileItemToMove]);
      queryClient.invalidateQueries(['fileItem', variables.moveToDirectory]);
      queryClient.invalidateQueries(['fileItem', variables.parentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      const message = error?.response?.data?.meta?.userMsg ?? '';

      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(['fileItem', variables.fileItemToMove]);
      queryClient.invalidateQueries(['fileItem', variables.moveToDirectory]);
      queryClient.invalidateQueries(['fileItem', variables.parentDirectory]);
      queryClient.invalidateQueries(['fileItemExhaustive']);
    },
  });
};

export const useGenerateDocumentWithData = () => {
  return useMutation({
    mutationFn: (data: TemplateVariables) => generateDocument(data),
  });
};

// Used for quick crate case and sendDocForm
export const useSendDocumentForSigning = () => {
  return useMutation({
    mutationFn: (data: SigningEvent) => sendDocumentForSignV3(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};

export const getS3FileDownloadLink = async (fileObjKey?: string) => {
  return getDownloadFileLinkV3(fileObjKey);
};

export const useGetS3FileDownloadLink = (fileObjKey?: string) => {
  return useQuery({
    queryKey: [queryKeys.fileDownloadLinks, fileObjKey],
    queryFn: async () => getDownloadFileLinkV3(fileObjKey),
    enabled: !!fileObjKey,
  });
};

export const useGetParallelS3FileDownloadLinks = (fileObjKeys: string[]) => {
  return useQueries({
    queries: fileObjKeys.map((id) => {
      return {
        queryKey: [queryKeys.fileDownloadLinks, id],
        queryFn: async () => getDownloadFileLinkV3(id),
        enabled: !!id,
      };
    }),
  });
};

export const getS3FileUploadLink = async (fileExtension: string | null) => {
  if (!fileExtension) return getUploadFileLinkV3('pdf');

  return getUploadFileLinkV3(fileExtension);
};

export const useUploadDocumentToS3 = () => {
  return useMutation({
    mutationFn: (data: Parameters<typeof uploadDocument>[0]) => uploadDocument(data),
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: 'Error uploading file',
        variant: 'error',
      });
    },
  });
};

export const useGetDocumentTags = (templateId: string) => {
  return useQuery({
    queryKey: ['documentTags', templateId],
    queryFn: async () => getDocumentTags(templateId),
    enabled: !!templateId,
  });
};

export const useGetResourceAttachments = (resourceId: string) => {
  return useQuery({
    queryKey: ['resourceAttachments', resourceId],
    queryFn: async () => getResourceAttachmentsV3(resourceId),
    enabled: !!resourceId,
  });
};

export const useCreateResourceAttachment = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      resourceId,
      type,
      designation,
    }: {
      resourceId: string;
      type: ResourceAttachmentType;
      designation?: string;
    }) => {
      return createResourceAttachmentV3({
        id,
        resourceId,
        type,
        designation,
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['resourceAttachments', id]);

      enqueueAPISnackbar({
        message: res?.data.meta.userMsg,
        variant: 'success',
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error?.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
    onSettled: (res) => {
      queryClient.invalidateQueries(['resourceAttachments', id]);
    },
  });
};

export const useDeleteResourceAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attachmentId, resourceId }: { attachmentId: string; resourceId: string }) => {
      return deleteResourceAttachmentV3(attachmentId);
    },
    onSuccess: (result, variables) => {
      queryClient.invalidateQueries(['resourceAttachments', variables.resourceId]);

      enqueueAPISnackbar({
        message: result?.data.meta.userMsg,
        variant: 'success',
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error?.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};
