import { DefaultV3Response, OneClickSignSettingType, ReferralSource } from '@gladiate/types';
import { axiosInstance } from '../https';
import { VITE_API_STAGE } from '../../static/importMetaEnv';

const API_STAGE = VITE_API_STAGE;

export const jurisconsultsRoute = `jurisconsults/${API_STAGE}/v3`;

// #region Referral Source
// POST create_referral_source_v3
export const createReferralSourceV3 = (data: Partial<ReferralSource>) =>
  axiosInstance
    .post<DefaultV3Response<ReferralSource>>(`${jurisconsultsRoute}/firms/referral-sources`, data)
    .then((res) => res.data);

// DEL delete_referral_source_v3
export const deleteReferralSourceV3 = (referralSourceId: string) =>
  axiosInstance
    .delete<DefaultV3Response<ReferralSource>>(
      `${jurisconsultsRoute}/firms/referral-sources/${referralSourceId}`,
    )
    .then((res) => res.data);

// GET list_referral_sources_v3
export const getReferralSourcesV3 = () =>
  axiosInstance
    .get<DefaultV3Response<ReferralSource[]>>(`${jurisconsultsRoute}/firms/referral-sources`)
    .then((res) => res.data);

// PATCH update_referral_source_v3
export const updateReferralSourceV3 = (data: Partial<ReferralSource>) => {
  const { referralSourceId, ...rest } = data;
  return axiosInstance
    .patch<DefaultV3Response<ReferralSource>>(
      `${jurisconsultsRoute}/firms/referral-sources/${referralSourceId}`,
      rest,
    )
    .then((res) => res.data);
};
// #endregion

// #region One Click Sign Settings

// GET get_one_click_sign_settings_v3
export const getOneClickSignSettingList = () =>
  axiosInstance
    .get<DefaultV3Response<OneClickSignSettingType[]>>(
      `${jurisconsultsRoute}/firms/one-click-sign-settings`,
    )
    .then((res) => res.data);

// GET get_one_click_sign_setting_v3
export const getOneClickSignSetting = (oneClickSignSettingId: string) =>
  axiosInstance
    .get<DefaultV3Response<OneClickSignSettingType[]>>(
      `${jurisconsultsRoute}/firms/one-click-sign-settings/${oneClickSignSettingId}`,
    )
    .then((res) => res.data);

// POST create_once_click_sign_setting_v3
export const createOneClickSignSetting = (data: OneClickSignSettingType) =>
  axiosInstance.post<DefaultV3Response<OneClickSignSettingType>>(
    `${jurisconsultsRoute}/firms/one-click-sign-settings/`,
    data,
  );

// PATCH update_one_click_sign_setting_v3
export const updateOneClickSignSetting = (data: OneClickSignSettingType) => {
  const { oneClickSignSettingId, ...rest } = data;
  return axiosInstance.patch<DefaultV3Response<OneClickSignSettingType[]>>(
    `${jurisconsultsRoute}/firms/one-click-sign-settings/${oneClickSignSettingId}`,
    rest,
  );
};

// DEL delete_one_click_sign_setting_v3
export const deleteOneClickSignSetting = (oneClickSignSettingId: string) =>
  axiosInstance.delete<DefaultV3Response<OneClickSignSettingType>>(
    `${jurisconsultsRoute}/firms/one-click-sign-settings/${oneClickSignSettingId}`,
  );

// #endregion
