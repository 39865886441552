export const ColorMap = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  'red-100': 'rgb(255, 51, 51)',
  'red-100-0.5': 'rgba(255, 51, 51, 0.5)',
  'red-200': 'rgb(255, 102, 102)',
  'red-200-0.5': 'rgba(255, 102, 102, 0.5)',
  'red-300': 'rgb(255, 255, 255)',
  'red-300-0.5': 'rgb(255, 204, 204)',
  'red-400': 'rgb(255, 51, 51)',
  'red-400-0.5': 'rgba(255, 51, 51, 0.5)',
  'red-500': 'rgb(255, 26, 26)',
  'red-500-0.5': 'rgba(255, 26, 26, 0.5)',
  'orange-100': 'rgb(255, 128, 0)',
  'orange-200': 'rgb(255, 153, 51)',
  'orange-300': 'rgb(255, 178, 102)',
  'orange-400': 'rgb(255, 204, 153)',
  'orange-500': 'rgb(255, 230, 204)',
  'yellow-100': 'rgb(255, 255, 0)',
  'yellow-200': 'rgb(255, 255, 102)',
  'yellow-300': 'rgb(255, 255, 153)',
  'yellow-400': 'rgb(255, 255, 204)',
  'yellow-500': 'rgb(255, 255, 230)',
  'green-100': 'rgb(0, 255, 0)',
  'green-200': 'rgb(51, 255, 51)',
  'green-300': 'rgb(102, 255, 102)',
  'green-400': 'rgb(153, 255, 153)',
  'green-500': 'rgb(204, 255, 204)',
  'teal-100': 'rgb(0, 128, 128)',
  'teal-200': 'rgb(51, 153, 153)',
  'teal-300': 'rgb(102, 178, 178)',
  'teal-400': 'rgb(153, 204, 204)',
  'teal-500': 'rgb(204, 230, 230)',
  'blue-100': 'rgb(0, 0, 255)',
  'blue-200': 'rgb(51, 51, 255)',
  'blue-300': 'rgb(102, 102, 255)',
  'blue-400': 'rgb(153, 153, 255)',
  'blue-500': 'rgb(204, 204, 255)',
  'sky-100': 'rgb(224, 242, 254)',
  'sky-200': 'rgb(186, 230, 253)',
  'sky-300': 'rgb(148, 218, 252)',
  'sky-400': 'rgb(110, 206, 251)',
  'sky-500': 'rgb(14, 165, 233)',
  'sky-600': 'rgb(12, 133, 189)',
  'indigo-100': 'rgb(75, 0, 130)',
  'indigo-200': 'rgb(102, 0, 204)',
  'indigo-300': 'rgb(128, 0, 255)',
  'indigo-400': 'rgb(153, 51, 255)',
  'indigo-500': 'rgb(178, 102, 255)',
  'purple-100': 'rgb(128, 0, 128)',
  'purple-200': 'rgb(153, 51, 153)',
  'purple-300': 'rgb(178, 102, 178)',
  'purple-400': 'rgb(204, 153, 204)',
  'purple-500': 'rgb(230, 204, 230)',
  'pink-100': 'rgb(255, 51, 255)',
  'pink-200': 'rgb(255, 102, 255)',
  'pink-300': 'rgb(255, 153, 255)',
  'pink-400': 'rgb(255, 204, 255)',
  'pink-500': 'rgb(255, 230, 255)',
  'gray-100': 'rgb(128, 128, 128)',
  'gray-200': 'rgb(153, 153, 153)',
  'gray-300': 'rgb(178, 178, 178)',
  'gray-400': 'rgb(204, 204, 204)',
  'gray-500': 'rgb(230, 230, 230)',
} as const;
