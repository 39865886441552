// titulus queries

import { DefaultV3Error, DefaultV3Response, ItemTagConnectionType } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar, enqueueServerErrorSnackbar } from '../../utils/snackbars';
import {
  createItemTagConnectionV3,
  deleteItemTagConnectionV3,
  deleteTagV3,
  getAllItemTagConnectionsV3,
  getItemTagConnectionsV3,
  getTagsV3,
  updateTagV3,
} from '../requests/titulus';

// ------------------ TAGS ------------------

export const useGetTags = () => {
  return useQuery({
    queryKey: [queryKeys.tags],
    queryFn: async () => getTagsV3(),
  });
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateTagV3>[0]) => updateTagV3(data),

    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: () => {
      enqueueServerErrorSnackbar();
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tags],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.itemTagConnections],
      });
    },
  });
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tagId: string) => deleteTagV3(tagId),
    onError: () => {
      enqueueServerErrorSnackbar();
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.tags],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.itemTagConnections],
      });
    },
    onSuccess: () => {
      enqueueAPISnackbar({ message: 'Tag deleted', variant: 'success' });
    },
  });
};

// ------------------ ITEM TAG CONNECTIONS ------------------

export const useGetItemTagConnections = (
  resourceId: string,
  resourceType: ItemTagConnectionType['type'],
) => {
  return useQuery({
    queryKey: [queryKeys.itemTagConnections, resourceId, resourceType],
    queryFn: async () => getItemTagConnectionsV3(resourceId),
    enabled: !!resourceId,
  });
};

export const useGetAllItemTagConnections = () => {
  return useQuery({
    queryKey: [queryKeys.itemTagConnections],
    queryFn: async () => getAllItemTagConnectionsV3(),
  });
};

export const useCreateItemTagConnection = (
  resourceId: string,
  resourceType: ItemTagConnectionType['type'],
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createItemTagConnectionV3>[0]) =>
      createItemTagConnectionV3({ ...data, type: resourceType }),
    //TODO: ADD BACK OPTIMISTIC UPDATE, removed because new v3 was too complicated with it
    onError: (err: AxiosError<DefaultV3Error>) => {
      const errorMsg = err?.response?.data.meta.userMsg ?? 'Something went wrong';
      enqueueAPISnackbar({ message: errorMsg, variant: 'error' });
    },
    // Always refetch after error or success:
    onSettled: (_data, _err, variables) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.itemTagConnections],
      });
      if (!variables?.tagId) {
        // If we are making a new resourceTag we want to invalidate tags
        queryClient.invalidateQueries({ queryKey: [queryKeys.tags] });
      }
    },
  });
};

export const useDeleteItemTagConnection = (
  resourceId: string,
  resourceType: ItemTagConnectionType['type'],
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof deleteItemTagConnectionV3>[0]) =>
      deleteItemTagConnectionV3(data),
    onMutate: async (deletedItemTagConnectionId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [queryKeys.itemTagConnections],
      });
      // Snapshot the previous value
      const previousItemTagConnections = queryClient.getQueryData([
        queryKeys.itemTagConnections,
        resourceId,
        resourceType,
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        [queryKeys.itemTagConnections, resourceId, resourceType],
        (old: DefaultV3Response<ItemTagConnectionType[]> | undefined) => {
          const oldCopy = cloneDeep(old);
          const index =
            oldCopy?.data.findIndex(
              (itemTagConnection) =>
                itemTagConnection.itemTagConnectionId === deletedItemTagConnectionId,
            ) ?? -1;
          if (index >= 0) {
            oldCopy?.data.splice(index, 1);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return oldCopy;
        },
      );
      // Return a context object with the snapshotted value
      return { previousItemTagConnections };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context) => {
      enqueueServerErrorSnackbar();
      queryClient.setQueryData([queryKeys.itemTagConnections], context?.previousItemTagConnections);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.itemTagConnections],
      });
    },
  });
};
