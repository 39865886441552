import {
  LeadListViewType,
  displayContactName,
  formatPhoneNumber,
  prettifyDateString,
} from '@colosseum/data';
import {
  containsTagsFilterFn,
  dateRangeFilterFn,
  renderCell,
  renderHeader,
  renderNumberCellWithHover,
  stringIncludesFilterFn,
} from '@colosseum/shared-ui';
import { ContactViewModelType, ItemTagConnectionType } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

interface LeadsPageColumnsType extends LeadListViewType {
  contact?: ContactViewModelType;
}

export const columns: ColumnDef<LeadsPageColumnsType>[] = [
  {
    accessorFn: (row) => row?.caseStatus?.title,
    id: 'Lead Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) => {
      const currentStatus = row.caseStatusUpdates?.find((statusUpdate) => !statusUpdate.dateEnded);
      return dayjs(currentStatus?.dateEnded).diff(currentStatus?.dateStarted, 'days') as number;
    },
    id: 'Days in Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.caseStatus?.goal,
    id: 'Lead Status Goal',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Name',
    sortUndefined: 1,
    enableHiding: false,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.contact?.contactNumbers?.[0]?.number,
    id: 'Phone Number',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPhoneNumber }),
  },
  {
    accessorFn: (row) => row.contact?.contactEmails?.[0]?.emailAddress,
    id: 'Email',
    sortUndefined: 1,

    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateOfIncident),
    id: 'Date of Incident',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentCity,
    id: 'Incident City',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.incidentState,
    id: 'Incident State',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.statuteOfLimitations,
    id: 'Statute',
    sortUndefined: 1,
    filterFn: stringIncludesFilterFn,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: prettifyDateString }),
  },
  {
    accessorFn: (row) => row?.caseType?.title,
    id: 'Lead Type',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    filterFn: (row, id, value: string) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) =>
      row.caseOpenDate
        ? `${prettifyDateString(row.caseOpenDate)} (${pluralize(
            'day',
            dayjs().diff(row.caseOpenDate, 'days'),
            true,
          )} ago)`
        : '-',
    id: 'Open Date',
    sortUndefined: -1,
    sortingFn: 'datetime',
    filterFn: dateRangeFilterFn<LeadsPageColumnsType>,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row?.tags,
    id: 'Tags',
    sortUndefined: -1,
    header: renderHeader,
    filterFn: containsTagsFilterFn<LeadsPageColumnsType>,
    cell: (props) => {
      const tagList = props.getValue<ItemTagConnectionType[]>() ?? [];

      const tagTitles = tagList.map((tag) => tag?.tagAttributes?.title).join(', ');

      return renderNumberCellWithHover({
        value: `${tagList.length || '-'}`,
        tooltipText: tagTitles,
      });
    },
  },
  {
    accessorFn: (row) => row?.referralSource?.title,
    id: 'Marketing Referral Source',
    sortUndefined: -1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
