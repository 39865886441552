import { useGetCases, useGetReferralSources } from '@colosseum/data';
import { GladiateLoader } from '@colosseum/shared-ui';
import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

/* eslint-disable-next-line */
export interface CasesBySourceProps {}

const colors = [
  '#577590',
  '#4d908e',
  '#43aa8b',
  '#90be6d',
  '#f9c74f',
  '#f8961e',
  '#f3722c',
  '#f94144',
  '#0051D0',
];

export function CasesBySource(props: CasesBySourceProps) {
  // REACT QUERY
  const casesQuery = useGetCases();
  const casesEntities = casesQuery.data?.data ?? [];
  const cases = Object.values(casesEntities); // it was complaining about about stuff so I added object as well
  const { data: referralSourcesData } = useGetReferralSources();

  const casesBySourceObj = useMemo(
    () =>
      cases.reduce(
        (
          acc: {
            [key: string]: {
              title: string;
              value: number;
              color: string;
            };
          },
          currentCase,
        ) => {
          const referralSourceTitle =
            referralSourcesData?.data?.find(
              (referralSource) => referralSource.referralSourceId === currentCase?.referralSourceId,
            )?.title ?? 'Not Specified';
          if (acc[referralSourceTitle]) {
            acc[referralSourceTitle].value += 1;
          }
          return acc;
        },
        referralSourcesData?.data?.reduce(
          (acc, currentSource, i) => ({
            ...acc,
            [currentSource?.title ?? 'Not Specified']: {
              title: currentSource.title ?? 'Not Specified',
              value: 0,
              color: colors[i % colors.length],
            },
          }),
          {
            'Not Specified': {
              title: 'Not Specified',
              value: 0,
              color: colors[colors.length - 1],
            },
          },
        ) ?? {},
      ),
    [cases, referralSourcesData?.data],
  );

  const casesBySourceArr = Object.values(casesBySourceObj).map((source) => {
    return {
      title: source.title,
      value: Math.round((source.value / cases.length) * 100),
      color: source.color,
    };
  });

  function formatReferralTitleValue(caseBySource: { title: string; value: number; color: string }) {
    const { title, value } = caseBySource;
    return isNaN(value) ? title : `${title} - ${value}%`;
  }

  return (
    <div className="relative p-5 mt-5 overflow-y-auto bg-white border shadow-sm rounded-xl sm:w-1/2">
      <h1 className="mb-5 text-lg font-medium leading-6 text-gray-900">Cases by Source</h1>
      {casesQuery.isLoading ? (
        <GladiateLoader />
      ) : (
        <div className="flex flex-col">
          {cases.length === 0 ? (
            <div className="p-10 flex text-md justify-center">Add a Case to get started!</div>
          ) : (
            <>
              <div className="md:px-24">
                <PieChart
                  animate={true}
                  label={({ dataEntry }) => (dataEntry.value > 0 ? `${dataEntry.value}%` : null)}
                  labelStyle={{
                    fill: '#fff',
                    opacity: 0.75,
                    pointerEvents: 'none',
                    fontSize: '7px',
                  }}
                  labelPosition={60}
                  data={casesBySourceArr}
                />
              </div>
              <div className="mt-5">
                <div className="grid w-full grid-cols-2">
                  {casesBySourceArr?.map((caseBySource) => {
                    return (
                      <div key={caseBySource.title} className="flex">
                        <div className="flex items-center">
                          <div
                            className="w-2 h-2 align-middle rounded-full"
                            style={{ backgroundColor: caseBySource.color }}
                          ></div>
                        </div>
                        <div className="m-2">{formatReferralTitleValue(caseBySource)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CasesBySource;
