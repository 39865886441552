/* eslint-disable @typescript-eslint/ban-ts-comment */
import { booleanToString, useVehicleForm } from '@colosseum/data';
import { VehicleType, caseContactConnectionOptions } from '@gladiate/types';
import dayjs from 'dayjs';
import { CaseContactConnectionLinker } from '../../CaseContactConnectionLinker/CaseContactConnectionLinker';
import { GladiateLoader } from '../../GladiateLoader/GladiateLoader';
import { Form } from '../../shadcn/Form/Form';
import CalendarFormInput from '../CalendarFormInput/CalendarFormInput';
import CheckboxFormInput from '../CheckboxFormInput/CheckboxFormInput';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import SelectFormInput from '../SelectFormInput/SelectFormInput';
import { TextFormInput } from '../TextFormInput/TextFormInput';
import ToggleFormInput from '../ToggleFormInput/ToggleFormInput';

export interface VehicleFormProps {
  caseId: string;
  vehicle: VehicleType | undefined;
  vehicleLoading: boolean;
}

export function VehicleForm(props: VehicleFormProps) {
  const { caseId, vehicle, vehicleLoading } = props;

  const { form, handleUpdateVehicle } = useVehicleForm({
    vehicle: vehicle,
  });

  if (vehicleLoading) {
    return (
      <div>
        <GladiateLoader />
      </div>
    );
  }

  return (
    <div className="space-y-5 ">
      {vehicle ? (
        <Form {...form}>
          <form className="space-y-5">
            <div className="grid grid-cols-2 gap-4 mt-2">
              <TextFormInput
                {...form.register('year')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('year', target.value);
                }}
                title="Year"
              />
              <TextFormInput
                {...form.register('make')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('make', target.value);
                }}
                title="Make"
              />
              <TextFormInput
                {...form.register('model')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('model', target.value);
                }}
                title="Model"
              />
              <TextFormInput
                {...form.register('vin')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('vin', target.value);
                }}
                title="Vin"
              />
              <TextFormInput
                {...form.register('licensePlate')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('licensePlate', target.value);
                }}
                title="License Plate"
              />
              <SelectFormInput
                {...form.register('ownershipStatus')}
                listItems={['Owned', 'Leased', 'Unknown']}
                defaultValue={vehicle.ownershipStatus}
                title="Ownership Status"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('ownershipStatus', target.value);
                }}
              />
              <div className="col-span-2">
                <CaseContactConnectionLinker
                  caseId={caseId}
                  title="Tow Company"
                  roleOnCase={caseContactConnectionOptions?.towCompany}
                  showMultiple
                  itemType="vehicle"
                  itemId={vehicle.vehicleId}
                />
              </div>
              <div className="col-span-2">
                <CaseContactConnectionLinker
                  caseId={caseId}
                  title="Repair Shop"
                  roleOnCase={caseContactConnectionOptions?.repairShop}
                  showMultiple
                  itemType="vehicle"
                  itemId={vehicle.vehicleId}
                />
              </div>
              <CurrencyInput
                {...form.register('personalPropertyDamage', {
                  valueAsNumber: true,
                })}
                formHandleBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('personalPropertyDamage', target.value);
                }}
                title="Personal Property Damage"
              />
              <TextFormInput
                {...form.register('personalPropertyDamageDescription')}
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('personalPropertyDamageDescription', target.value);
                }}
                title="Personal Property Damage Description"
              />
              <div className="flex items-center gap-x-2">
                Total Loss
                <ToggleFormInput
                  {...form.register('isTotalLoss')}
                  handleOnChangeFunction={handleUpdateVehicle}
                />
              </div>
              <div></div>
              {vehicle?.isTotalLoss === '1' && (
                <>
                  <CurrencyInput
                    {...form.register('totalLossProposedFmv', {
                      valueAsNumber: true,
                    })}
                    formHandleBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle('totalLossProposedFmv', target.value);
                    }}
                    title="Total Loss Proposed FMV"
                  />
                  <CurrencyInput
                    {...form.register('totalLossFinalFmv', {
                      valueAsNumber: true,
                    })}
                    formHandleBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle('totalLossFinalFmv', target.value);
                    }}
                    title="Total Loss Final FMV"
                  />
                </>
              )}
              <div className="flex items-center gap-x-2">
                Repairable
                <ToggleFormInput
                  {...form.register('isRepairable')}
                  handleOnChangeFunction={handleUpdateVehicle}
                />
              </div>
              <div></div>
              {vehicle.isRepairable === '1' && (
                <>
                  <CurrencyInput
                    {...form.register('repairEstimate', {
                      valueAsNumber: true,
                    })}
                    formHandleBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle('repairEstimate', target.value);
                    }}
                    title="Repair Estimate"
                  />
                  <CurrencyInput
                    {...form.register('repairFinalCost')}
                    formHandleBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle('repairFinalCost', target.value);
                    }}
                    title="Repair Final Cost"
                  />
                </>
              )}
              <div className="flex items-center gap-x-2">
                Diminished Value Claim
                <ToggleFormInput
                  {...form.register('isDiminishedValueClaim')}
                  handleOnChangeFunction={handleUpdateVehicle}
                />
              </div>
              <div></div>
              {vehicle.isDiminishedValueClaim === '1' && (
                <>
                  <CurrencyInput
                    {...form.register('diminishedValueClaimFinalAmount', {
                      valueAsNumber: true,
                    })}
                    formHandleBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle('diminishedValueClaimFinalAmount', target.value);
                    }}
                    title="DVC Final Amount"
                  />
                  <TextFormInput
                    {...form.register('diminishedValueClaimOdometerAtIncident', {
                      valueAsNumber: true,
                    })}
                    type="number"
                    title="DVC Odometer at Incident"
                    handleOnBlur={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle(
                        'diminishedValueClaimOdometerAtIncident',
                        Number(target.value),
                      );
                    }}
                  />
                </>
              )}
              <div className="flex items-center gap-x-2">
                Rental Car Needed
                <ToggleFormInput
                  {...form.register('isRentalCarNeeded')}
                  handleOnChangeFunction={handleUpdateVehicle}
                />
              </div>
              <div></div>
              {vehicle.isRentalCarNeeded === '1' && (
                <>
                  <CalendarFormInput
                    {...form.register(`rentalStartDate`)}
                    title="Rental Start Date"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle(
                        'rentalStartDate',
                        dayjs(target.value).format('YYYY-MM-DD'),
                      );
                    }}
                  />
                  <CalendarFormInput
                    {...form.register(`rentalEndDate`)}
                    title="Rental End Date"
                    handleOnChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      handleUpdateVehicle(
                        'rentalEndDate',
                        dayjs(target.value).format('YYYY-MM-DD'),
                      );
                    }}
                  />
                  <div className="col-span-2">
                    <CaseContactConnectionLinker
                      caseId={caseId}
                      title="Rental Car Company"
                      roleOnCase="rentalCarCompany"
                      showMultiple
                      itemType="vehicle"
                      itemId={vehicle.vehicleId}
                    />
                  </div>
                </>
              )}
              <CheckboxFormInput
                {...form.register('companyProperty')}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('companyProperty', booleanToString(target.value));
                }}
                title="Company Property"
              />
              <CheckboxFormInput
                {...form.register('governmentProperty')}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateVehicle('governmentProperty', booleanToString(target.value));
                }}
                title="Government Property"
              />
            </div>
          </form>
        </Form>
      ) : (
        <div>
          <GladiateLoader />
        </div>
      )}
    </div>
  );
}

export default VehicleForm;
