import { cn } from '@colosseum/data';
import dayjs from 'dayjs';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../shadcn/Button/Button';
import { DefaultCalendar } from '../shadcn/DefaultCalendar/DefaultCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface DateRangePickerProps {
  title: string;
  updateKey: string;
}

export function DateRangePicker(props: DateRangePickerProps) {
  const { title, updateKey } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const combinedDates = queryParams.get(updateKey) || '';
  const [startString, endString] = combinedDates.split(',');

  const [dateStart, setDateStart] = useState<Date | undefined>(
    // Dates like YYYY-MM-DD will be parsed as UTC, so we need to replace the dashes with slashes
    startString ? new Date(startString.replace(/-/g, '/')) : undefined,
  );
  const [dateEnd, setDateEnd] = useState<Date | undefined>(
    endString ? new Date(endString.replace(/-/g, '/')) : undefined,
  );

  const updateQueryParam = () => {
    const start = dateStart ? dayjs(dateStart).format('YYYY-MM-DD') : '';
    const end = dateEnd ? dayjs(dateEnd).format('YYYY-MM-DD') : '';

    let combinedValue = '';
    if (start && end) {
      combinedValue = `${start as string},${end as string}`;
    } else if (start) {
      combinedValue = start;
    } else if (end) {
      combinedValue = `0,${end as string}`;
    }

    if (combinedValue) {
      queryParams.set(updateKey, combinedValue);
    } else {
      queryParams.delete(updateKey);
    }

    navigate({ search: queryParams.toString() }, { replace: true });
  };

  useEffect(() => {
    updateQueryParam();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    const paramsSize = [...new Set(queryParams.keys())].length;
    // if query params are reset - we want to make these all undefined
    if (paramsSize === 0 && (dateStart || dateEnd)) {
      setDateEnd(undefined);
      setDateStart(undefined);
    }
  }, [queryParams]);

  return (
    <div className="flex w-full gap-5">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'w-1/2 justify-start relative text-left font-normal',
              !dateStart && 'text-muted-foreground',
            )}
          >
            <span className="absolute px-1 text-xs text-gray-400 bg-white -top-2 left-2">
              {`${title} Start`}
            </span>
            <CalendarIcon className="w-4 h-4 mr-2" />
            {dateStart ? dayjs(dateStart).format('YYYY-MM-DD') : <span>Pick a start date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <DefaultCalendar
            mode="single"
            selected={dateStart}
            onSelect={setDateStart}
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'w-1/2 justify-start text-left font-normal relative',
              !dateEnd && 'text-muted-foreground',
            )}
          >
            <span className="absolute px-1 text-xs text-gray-400 bg-white -top-2 left-2">
              {`${title} End`}
            </span>
            <CalendarIcon className="w-4 h-4 mr-2" />
            {dateEnd ? dayjs(dateEnd).format('YYYY-MM-DD') : <span>Pick an end date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <DefaultCalendar mode="single" selected={dateEnd} onSelect={setDateEnd} initialFocus />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default DateRangePicker;
