import {
  conflictsOptions,
  daysInStatusRelativeToGoalOptions,
  getNameToDisplayFromCognito,
  useDeletePresetFilter,
  useGetCaseStatuses,
  useGetCaseTypes,
  useGetCases,
  useGetFirmUsers,
  useGetReferralSources,
  useGetTags,
  useGetTeams,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  Button,
  DateRangePicker,
  FilterCombobox,
  Form,
  FormField,
  FormItem,
  FormLabel,
  RangePicker,
  Slideover,
} from '@colosseum/shared-ui';
import { CaseType, PresetFilterType } from '@gladiate/types';
import { uniq, uniqBy } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import PresetFilterSelect from '../PresetFilterSelect/PresetFilterSelect';
import PresetFilterSlideover from '../PresetFilterSlideover/PresetFilterSlideover';

export interface FiltersSlideoverProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handlePresetFilterSelect: (presetFilterId: string) => void;
  activePresetFilter?: PresetFilterType;
  setActivePresetFilter: Dispatch<SetStateAction<PresetFilterType | undefined>>;
}

const dateRangeFilters = [
  { title: 'Open Date', key: 'Open Date' },
  { title: 'Incident Date', key: 'Incident Date' },
  { title: 'Initial Filing Date', key: 'Initial Filing Date' },
  { title: 'Trial Date', key: 'Trial Date' },
  { title: 'Statute Date', key: 'Statute Date' },
];

const rangeFilters = [
  { title: 'Projected Settlement Amount', key: 'Projected Settlement Amount' },
  { title: 'Estimated Medical Costs', key: 'Estimated Medical Costs' },
  { title: 'Surgery Count', key: 'Surgery Count' },
  { title: 'Injection Count', key: 'Injection Count' },
  { title: 'MRI Count', key: 'MRI Count' },
  { title: 'Expenses', key: 'Expenses' },
  { title: 'Total Medicals', key: 'Total Medicals' },
  { title: 'Treatments', key: 'Treatments' },
  {
    title: 'Third Party Coverage Person',
    key: 'Third Party Coverage Person',
  },
  {
    title: 'Third Party Coverage Accident',
    key: 'Third Party Coverage Accident',
  },
  { title: 'UM/UIM Coverage Person', key: 'UM/UIM Coverage Person' },
  { title: 'UM/UIM Coverage Accident', key: 'UM/UIM Coverage Accident' },
  { title: 'Uncompleted Tasks %', key: 'Uncompleted Tasks Percentage' },
];

const getFilterOptions = <T,>({
  data,
  key,
  fallbackText,
}: {
  data?: T[];
  key: keyof T;
  fallbackText: string;
}) => {
  if (!data) return [];
  return (
    uniqBy(data, key)
      .filter((c) => c[key])
      .map((c) => ({
        value: (c[key] ?? '') as string,
        label: (c[key] ?? fallbackText) as string,
      })) ?? []
  );
};

export function FiltersSlideover(props: FiltersSlideoverProps) {
  const { open, setOpen, handlePresetFilterSelect, activePresetFilter, setActivePresetFilter } =
    props;
  const navigate = useNavigate();

  const [presetFilterDropdownOpen, setPresetFilterDropdownOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [presetFilterToDelete, setPresetFilterToDelete] = useState<string | undefined>(undefined);
  const [presetFilterModalOpen, setPresetFilterModalOpen] = useState(false);
  const [presetFilterToEdit, setPresetFilterToEdit] = useState<PresetFilterType | undefined>(
    undefined,
  );

  // Data fetching
  const { data: caseTypesRes } = useGetCaseTypes();
  const { data: caseStatusesRes } = useGetCaseStatuses();
  const deletePresetFilter = useDeletePresetFilter();
  const firmUsersQuery = useGetFirmUsers();
  const { data: tagsData } = useGetTags();
  const { data: casesData } = useGetCases();
  const { data: referralSourcesData } = useGetReferralSources();
  const { data: teamsData } = useGetTeams();

  const form = useForm();

  // Data organization
  const caseTypes = caseTypesRes?.data;
  const caseStatuses = caseStatusesRes?.data;

  const caseTypesObject =
    caseTypes?.map((caseType) => ({
      value: caseType.title ?? '',
      label: caseType.title ?? '',
    })) || [];
  caseTypesObject.push({ value: undefined as any, label: 'No Type' });
  const caseStatusesObject =
    caseStatuses
      ?.map((caseStatus) => {
        if (caseStatus.category === 'lead') {
          return undefined;
        }

        return {
          value: caseStatus.title ?? '',
          label: caseStatus.title ?? '',
        };
      })
      .filter((caseStatus) => caseStatus !== undefined) || [];
  caseStatusesObject.push({ value: undefined as any, label: 'No Status' });

  const firmUsersData = firmUsersQuery.data?.data;

  const tags = tagsData?.data ?? [];
  const tagsFilter = tags
    .filter((tag) => tag.title)
    .map((tag) => ({
      value: tag.title ?? '',
      label: tag.title ?? 'No Title',
    }));

  const courtNamesFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'courtName',
    fallbackText: 'No Court Name',
  });

  const courtLocationsFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'courtLocation',
    fallbackText: 'No Court Location',
  });
  const courtTypesFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'courtType',
    fallbackText: 'No Court Type',
  });
  const incidentCityFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'incidentCity',
    fallbackText: 'No Incident City',
  });
  const incidentStateFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'incidentState',
    fallbackText: 'No Incident State',
  });
  const incidentStreetFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'incidentStreet',
    fallbackText: 'No Incident Street',
  });
  const incidentZipCodeFilter = getFilterOptions<CaseType>({
    data: casesData?.data,
    key: 'incidentZipCode',
    fallbackText: 'No Incident Zip Code',
  });
  const uniqReferralSourceIds = uniq(casesData?.data.map((c) => c.referralSourceId)).filter(
    (id) => id !== undefined,
  );
  const incidentReferralFilter = uniqReferralSourceIds.map((uniqReferralSource) => ({
    label: referralSourcesData?.data.find(
      (referralSource) =>
        referralSource.referralSourceId === uniqReferralSource && referralSource.title,
    )?.title,
    value: uniqReferralSource,
  })) as { label: string; value: string }[];

  const firmUsers =
    firmUsersData?.map((user) => {
      const displayName = getNameToDisplayFromCognito(user) ?? '-';
      return {
        label: displayName,
        value: user?.Username ?? '',
      };
    }) ?? [];

  const teams =
    teamsData?.data
      .filter((team) => team.title)
      .map((team) => ({
        label: team.title ?? '',
        value: team.teamId ?? '',
      })) ?? [];

  return (
    <Slideover
      title="Filters"
      description="Apply custom filters to your cases."
      open={open}
      setOpen={setOpen}
      displayDeleteButton={false}
      typing={false}
      bottomButtons={
        <div className="flex items-center justify-end px-5 py-5 border-t border-t-gray-200">
          <Button
            variant="primary"
            className="px-6 py-2 ml-3"
            onClick={() => {
              setPresetFilterModalOpen(true);
              setPresetFilterToEdit(activePresetFilter);
            }}
            disabled={!activePresetFilter}
          >
            Update Preset
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="px-6 py-2 ml-3"
            onClick={() => setPresetFilterModalOpen(true)}
          >
            Create New Preset
          </Button>
        </div>
      }
    >
      <div>
        <div className="flex items-center pb-4 pr-3 border-b border-b-gray-200">
          <Form {...form}>
            <FormField
              control={form.control}
              name={'presetFilter'}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <div className="flex gap-1">Preset Filter</div>
                  </FormLabel>
                  <PresetFilterSelect
                    handleItemSelect={handlePresetFilterSelect}
                    activeFilter={activePresetFilter}
                    placeholder="Apply Preset Filter"
                    triggerClassName="min-w-[200px]"
                  />
                </FormItem>
              )}
            />
          </Form>
        </div>
        <div className="flex items-center mt-4 mb-4 text-lg font-semibold">
          <Button
            size="xs"
            variant="outline"
            onClick={() => {
              navigate(window.location.pathname);
              handlePresetFilterSelect('');
            }}
          >
            Reset Filters
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-5 pb-2">
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-5 pb-2">
              <FilterCombobox
                key="Case Type"
                title="Case Type"
                updateKey="Case Type"
                options={caseTypesObject}
              />
              <FilterCombobox
                key="Case Status"
                title="Case Status"
                updateKey="Case Status"
                options={caseStatusesObject}
              />
              <FilterCombobox
                key="Assignees"
                title="Assignees"
                updateKey="Assignees"
                options={firmUsers}
              />
              <FilterCombobox key="Teams" title="Teams" updateKey="Teams" options={teams} />
              <FilterCombobox key="Tags" title="Tags" updateKey="Tags" options={tagsFilter} />
              <FilterCombobox
                key="Conflicts"
                title="Conflicts"
                updateKey="Conflicts"
                ignoreAlphabeticalOrder
                hideSearch
                options={conflictsOptions}
              />
              <FilterCombobox
                key="Days in Status Relative to Goal"
                title="Days in Status Relative to Goal"
                placeholder="an option"
                updateKey="Days in Status Relative to Goal"
                ignoreAlphabeticalOrder
                hideSearch
                options={daysInStatusRelativeToGoalOptions}
              />
              <FilterCombobox
                key="Court Name"
                title="Court Name"
                updateKey="Court Name"
                options={courtNamesFilter}
              />
              <FilterCombobox
                key="Court Type"
                title="Court Type"
                updateKey="Court Type"
                options={courtTypesFilter}
              />
              <FilterCombobox
                key="Court Location"
                title="Court Location"
                updateKey="Court Location"
                options={courtLocationsFilter}
              />

              <FilterCombobox
                key="Statute of Limitations Satisfied"
                title="Statute of Limitations Satisfied"
                updateKey="Statute of Limitations Satisfied"
                ignoreAlphabeticalOrder
                hideSearch
                options={[
                  {
                    label: 'Yes',
                    value: 'Yes',
                  },
                  {
                    label: 'No',
                    value: 'No',
                  },
                ]}
              />
              <FilterCombobox
                key="Incident Street"
                title="Incident Street"
                updateKey="Incident Street"
                options={incidentStreetFilter}
              />
              <FilterCombobox
                key="Incident City"
                title="Incident City"
                updateKey="Incident City"
                options={incidentCityFilter}
              />
              <FilterCombobox
                key="Incident State"
                title="Incident State"
                updateKey="Incident State"
                options={incidentStateFilter}
              />
              <FilterCombobox
                key="Incident Zip Code"
                title="Incident Zip Code"
                updateKey="Incident Zip Code"
                options={incidentZipCodeFilter}
              />
              <FilterCombobox
                key="Marketing Referral Source ID"
                title="Marketing Referral Source"
                updateKey="Marketing Referral Source ID"
                options={incidentReferralFilter}
              />

              {dateRangeFilters.map((filter) => (
                <div className="col-span-2" key={filter.key}>
                  <DateRangePicker updateKey={filter.key} title={filter.title} />
                </div>
              ))}
              {rangeFilters.map((filter) => (
                <div className="col-span-2" key={filter.key}>
                  <RangePicker updateKey={filter.key} title={filter.title} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        actionFunction={() => {
          if (presetFilterToDelete) {
            deletePresetFilter.mutateAsync(presetFilterToDelete).then(() => {
              setPresetFilterToDelete(undefined);
              setDeleteModalOpen(false);
              if (activePresetFilter?.presetFilterId === presetFilterToDelete) {
                handlePresetFilterSelect('');
              }
            });
          }
        }}
      />
      <PresetFilterSlideover
        open={presetFilterModalOpen}
        setOpen={setPresetFilterModalOpen}
        presetFilterToEdit={presetFilterToEdit}
        setPresetFilterToEdit={setPresetFilterToEdit}
        setActivePresetFilter={setActivePresetFilter}
      />
    </Slideover>
  );
}

export default FiltersSlideover;
