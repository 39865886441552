import {
  useUpdateAdobeUserStatusV3,
  useCreateAdobeAccountV3,
  useDeleteAdobeAccountV3,
  useGetAdobeAccountListV3,
  useGetAdobeUserListV3,
  useGetFirm,
  useUpdateAdobeAdminV3,
  useUpdateAdobeAccountSignerStatusV3,
  settingsSectionMappings,
  useGetCurrentUser,
  tanstackTableNames,
} from '@colosseum/data';
import {
  Button,
  CheckboxFormInput,
  DataTable,
  Form,
  Slideover,
  TextFormInput,
  TooltipWrapper,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { AdobeUserType } from '@gladiate/types';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as z from 'zod';
import { columns } from './adobe-admin-settings-table-columns';

const createAdobeFirmFormSchema = z.object({
  companyName: z.string().min(1, 'Company name is required'),
});

const updateAdobeUserFormSchema = z.object({
  isAdmin: z.boolean(),
  signerPrivelage: z.boolean(),
  status: z.boolean(),
});

/* eslint-disable-next-line */
export interface AdobeAdminSettingsProps {}

export function AdobeAdminSettings(props: AdobeAdminSettingsProps) {
  const navigate = useNavigate();
  const { isError: isAdobeAccountListError, error: adobeAccountListError } =
    useGetAdobeAccountListV3();
  const {
    data: adobeUserListData,
    isLoading: isAdobeUserListLoading,
    isError: isAdobeUserListError,
  } = useGetAdobeUserListV3();
  const { data: firmSettingsData } = useGetFirm();
  const { data: currentUserData } = useGetCurrentUser();

  const firmSettings = firmSettingsData?.data;
  const firmName = firmSettings?.firmName;

  const [openSlideover, setOpenSlideover] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AdobeUserType>();

  const accountNotFound = adobeAccountListError?.response?.status === 404;
  const createFirmAccount = useCreateAdobeAccountV3();
  const updateAccountAdminStatus = useUpdateAdobeAdminV3();
  const updateAccountSignerStatus = useUpdateAdobeAccountSignerStatusV3();
  const updateAccountActiveStatus = useUpdateAdobeUserStatusV3();
  const deleteAccount = useDeleteAdobeAccountV3();

  const currentAdobeUser = adobeUserListData?.data.find(
    (item) => item.username === currentUserData?.data.username,
  );

  const createAdobeFirmForm = useForm<z.infer<typeof createAdobeFirmFormSchema>>({
    resolver: zodResolver(createAdobeFirmFormSchema),
    defaultValues: {
      companyName: firmName ?? '',
    },
  });

  const updateAdobeUserForm = useForm<z.infer<typeof updateAdobeUserFormSchema>>({
    resolver: zodResolver(updateAdobeUserFormSchema),
    mode: 'onBlur',
    defaultValues: {
      isAdmin: selectedUser?.isAdmin,
    },
  });

  function onSubmit(values: z.infer<typeof createAdobeFirmFormSchema>) {
    createFirmAccount.mutate(values.companyName);
    window.location.reload();
  }

  function handleEdit(userDetails: AdobeUserType) {
    setSelectedUser(userDetails);
    updateAdobeUserForm.reset({
      isAdmin: userDetails.isAdmin,
      signerPrivelage: userDetails.signerPrivelage,
      status: userDetails.userStatus === 'ACTIVE' ? true : false,
    });

    setOpenSlideover(true);
  }

  useEffect(() => {
    createAdobeFirmForm.reset({
      companyName: firmName ?? '',
    });
  }, [firmName]);

  return (
    <>
      <Slideover
        title={'Adobe Sign User Settings'}
        description={'Edit your Adobe Sign users here.'}
        open={openSlideover}
        setOpen={setOpenSlideover}
        displayDeleteButton={selectedUser?.username !== currentAdobeUser?.username}
        deleteFunction={() => {
          deleteAccount.mutate({
            username: selectedUser?.username ?? '',
          });
        }}
        typing={false}
      >
        <div>
          <Form {...updateAdobeUserForm}>
            <form className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
              <CheckboxFormInput
                handleOnChange={() => {
                  updateAccountAdminStatus.mutate({
                    username: selectedUser?.username || '',
                    isAdmin: !selectedUser?.isAdmin || false,
                  });
                }}
                name={'isAdmin'}
                title={'Admin'}
                isDisabled={selectedUser?.username === currentAdobeUser?.username}
                isDisabledMessage="You cannot change your own status"
              />
              <CheckboxFormInput
                handleOnChange={() => {
                  updateAccountSignerStatus.mutate({
                    username: selectedUser?.username || '',
                    signerPrivelage: !selectedUser?.signerPrivelage,
                  });
                }}
                name={'signerPrivelage'}
                title={'Signer Privilege'}
              />
              <div>
                <CheckboxFormInput
                  handleOnChange={() => {
                    updateAccountActiveStatus.mutate({
                      username: selectedUser?.username || '',
                      status: selectedUser?.userStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                    });
                  }}
                  name={'status'}
                  title={'Active'}
                  isDisabled={
                    selectedUser?.username === currentAdobeUser?.username || selectedUser?.isOwner
                  }
                  isDisabledMessage={
                    selectedUser?.username === currentAdobeUser?.username
                      ? 'You cannot deactivate your own account'
                      : selectedUser?.isOwner
                      ? 'The account owner cannot be deactivated'
                      : ''
                  }
                />
              </div>
            </form>
          </Form>
        </div>
      </Slideover>

      <div className="px-1" data-settings-submenu-item={settingsSectionMappings.eSign}>
        <div className="flex items-center justify-between">
          <div className="flex items-center text-xl font-semibold text-gray-900">
            E-Sign
            {!isAdobeAccountListError && !currentAdobeUser && (
              <TooltipWrapper
                message={'Please connect your account on the profile page. Click this to redirect.'}
              >
                <button
                  className="ml-2"
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
                </button>
              </TooltipWrapper>
            )}
          </div>

          {accountNotFound && (
            <div>
              <Form {...createAdobeFirmForm}>
                <form
                  onSubmit={createAdobeFirmForm.handleSubmit(onSubmit)}
                  className="flex items-center space-x-2"
                >
                  <div className="sm:w-72">
                    <TextFormInput
                      {...createAdobeFirmForm}
                      name={'companyName'}
                      title={'Firm Name'}
                    />
                  </div>

                  <Button
                    className="px-6 text-white bg-sky-blue hover:bg-atlantic-blue"
                    type="submit"
                  >
                    Create Account
                  </Button>
                </form>
              </Form>
            </div>
          )}
        </div>
        {!accountNotFound &&
          (currentAdobeUser?.isAdmin ? (
            <DataTable
              data={adobeUserListData?.data || []}
              initialSort={{
                id: 'Name',
                desc: false,
              }}
              columns={columns}
              isLoading={isAdobeUserListLoading}
              isError={isAdobeUserListError}
              tableName={tanstackTableNames.adobeAdminSettings}
              handleRowClick={(item: Row<AdobeUserType>) => {
                handleEdit({ ...item.original });
              }}
              initialVisibility={getInitialVisibilityForTable(
                tanstackTableNames.adobeAdminSettings,
                {},
              )}
              hideViewButton
            />
          ) : (
            <DataTable
              data={adobeUserListData?.data || []}
              initialSort={{
                id: 'Name',
                desc: false,
              }}
              columns={columns}
              isLoading={isAdobeUserListLoading}
              isError={isAdobeUserListError}
              tableName={tanstackTableNames.adobeAdminSettings}
              initialVisibility={getInitialVisibilityForTable(
                tanstackTableNames.adobeAdminSettings,
                {},
              )}
              hideViewButton
              isViewOnly
            />
          ))}
      </div>
    </>
  );
}

export default AdobeAdminSettings;
