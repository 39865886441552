import { prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';
import { LitigationPlanAction } from '@gladiate/types';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<LitigationPlanAction>[] = [
  {
    accessorFn: (row) => row.title,
    id: 'Action',
    sortUndefined: 1,
    header: renderHeader,
    enableHiding: false,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.days?.toLocaleString(),
    id: 'Days Until Deadline',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.deadlineDate),
    id: 'Deadline',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.status,
    id: 'Status',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.completionDate),
    id: 'Date Completed',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];
