import { cn, copyToClipboard, prettifyDateString } from '@colosseum/data';
import { renderCell, renderHeader } from '@colosseum/shared-ui';

import { TooltipWrapper } from '@colosseum/shared-ui';
import { CaseSurvey } from '@gladiate/types';
import { CheckIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
export const columns: ColumnDef<CaseSurvey>[] = [
  {
    accessorFn: (row) => row.typeform.title,
    id: 'Title',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortingFn: 'dayjsDateTime',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.typeform.status,
    id: 'Completed',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => {
      const isCompleted = props.getValue();
      if (isCompleted === 'completed') {
        return <CheckIcon className="w-5 h-5 text-green-500" />;
      }
      return null;
    },
  },
  {
    accessorFn: (row) => row.shareLink,
    id: 'Share Link',
    enableHiding: false,
    header: renderHeader,
    cell: (props) => {
      const shareLink = props.getValue() as string;
      const isCompleted = props.row.original.typeform.status === 'completed';
      const hoverMessage = isCompleted ? 'Survey is completed' : 'Copy to clipboard';
      const className = cn(
        'w-5 h-5',
        isCompleted
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-gray-900 hover:text-atlantic-blue cursor-pointer',
      );
      return (
        <TooltipWrapper message={hoverMessage}>
          <ClipboardDocumentCheckIcon
            className={className}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              !isCompleted && copyToClipboard(`${shareLink}`);
            }}
          />
        </TooltipWrapper>
      );
    },
  },
];
