import { Button, Typography } from '@colosseum/shared-ui';

export interface PreviewPageProps {
  previewTitle: string;
  previewMessage?: string;
  buttonRoute?: string;
  buttonMessage?: string;
  previewSection?: React.ReactNode;
  caption?: string | React.ReactNode;
}

export function PreviewFeaturePage({
  previewTitle,
  previewMessage,
  buttonRoute,
  buttonMessage,
  previewSection,
  caption,
}: PreviewPageProps) {
  return (
    <div className="h-[calc(100vh-6.625rem)] lg:h-[calc(100vh-3rem)] p-5 sm:p-10" data-cy="error">
      <div className="flex flex-col min-h-full bg-white border border-gray-300 rounded-lg">
        <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-12xl sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div className="py-16 max-w-[700px] flex flex-col gap-2">
              <Typography variant="xlHeading" className={'flex justify-center'}>
                {previewTitle}
              </Typography>
              <Typography size="md" color="gray" className="mt-2">
                {previewMessage}
              </Typography>
              <div className="flex justify-center ">{previewSection}</div>
              <div className="flex justify-center mt-8">
                <Button variant={'primary'}>
                  <a href={buttonRoute ?? '/home'}>{buttonMessage ?? 'View your subscriptions'}</a>
                </Button>
              </div>
              {typeof caption === 'string' ? (
                <Typography size="md" color="gray" className="mt-2">
                  {caption}
                </Typography>
              ) : (
                caption
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PreviewFeaturePage;
