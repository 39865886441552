import {
  displayContactName,
  mergedContactConnectionForRoleOnCase,
  useCreateCaseContactConnectionWithItem,
  useDeleteCaseContactConnectionRole,
  useGetCaseContactConnections,
  useGetContactsInfinite,
} from '@colosseum/data';
import { CaseContactSubObject, ContactType, caseContactConnectionOptions } from '@gladiate/types';
import { useEffect, useState } from 'react';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';

/* eslint-disable-next-line */
export interface ClientSelectDropdownProps {
  caseId: string;
  caseItemId: string;
  caseItemType: CaseContactSubObject;
  form: any;
}

/**
 *
 * @description this Component allows the selection of a client from a dropdown list of clients on a case to attribute a client to a case sub object.  Be sure to add the caseContactConnectionId to the existing form's schema.
 *  * @usage  use with createCaseContactConnectionWithItemSoleClient hook on case sub object creation to automatically assign the sole client.
 */
export function ClientSelectDropdown(props: ClientSelectDropdownProps) {
  const { form, caseId, caseItemType, caseItemId } = props;
  const [selectedClientCCCId, setSelectedClientCCCId] = useState<string>();
  const { createCaseContactConnectionWithItem } = useCreateCaseContactConnectionWithItem();
  const deleteCaseContactConnectionRole = useDeleteCaseContactConnectionRole();
  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId, 'cases');
  const { data: contactsData } = useGetContactsInfinite();

  const clientsOnCase = mergedContactConnectionForRoleOnCase({
    caseContactConnections: caseContactConnectionsData?.data,
    contacts: contactsData?.data,
    roleOnCase: 'client',
  });

  const clientsOnCaseDropDownOptions = clientsOnCase
    .map((client) => {
      return {
        name: displayContactName(client as ContactType),
        caseContactConnectionId: client.caseContactConnectionId,
        contactId: client.contactId,
      };
    })
    ?.reduce(
      (acc, client) => {
        acc[client.name] = client.caseContactConnectionId;
        return acc;
      },
      {} as {
        [key: string]: string;
      },
    );

  function handleClientChange(e: React.SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setSelectedClientCCCId(target.value);

    const roleToDelete = clientsOnCase
      .flatMap((client) => client.roles)
      .find(({ itemId, itemType }) => itemType === caseItemType && itemId === caseItemId);

    if (roleToDelete) {
      roleToDelete &&
        deleteCaseContactConnectionRole.mutateAsync(roleToDelete.caseContactConnectionRoleId);
    }
    createCaseContactConnectionWithItem({
      caseId: props.caseId,
      contactId:
        clientsOnCase.find((client) => client.caseContactConnectionId === target.value)
          ?.contactId ?? '',
      itemId: caseItemId,
      itemType: caseItemType,
      roleOnCase: caseContactConnectionOptions.client,
    });

    setSelectedClientCCCId(target.value);
  }

  useEffect(() => {
    // search CaseContactConnectionRoles for itemId
    const foundCaseContactConnectionForItem = caseContactConnectionsData?.data.find(
      (caseContactConnection) =>
        caseContactConnection.roles.find(
          ({ itemId, roleOnCase }) =>
            itemId === caseItemId && roleOnCase === caseContactConnectionOptions.client,
        ),
    );

    const foundClient = clientsOnCase.filter((client) =>
      client.roles?.find(
        ({ itemId, itemType }) => itemType === caseItemType && itemId === caseItemId,
      ),
    )[0];
    if ((foundClient && foundCaseContactConnectionForItem) || clientsOnCase.length === 1) {
      setSelectedClientCCCId(
        foundClient?.caseContactConnectionId ??
          foundCaseContactConnectionForItem?.caseContactConnectionId,
      );
      form.setValue(
        'caseContactConnectionId',
        foundClient?.caseContactConnectionId ??
          foundCaseContactConnectionForItem?.caseContactConnectionId,
      );
    }
  }, [caseContactConnectionsData]);

  return (
    <div>
      <div className="pt-4">
        <SelectFormInput
          title="Select Client"
          listItems={clientsOnCaseDropDownOptions}
          listItemsIsObject
          value={selectedClientCCCId}
          placeholderText="Assign to client"
          handleOnChange={handleClientChange}
          {...form.register(`caseContactConnectionId`)}
        />
      </div>
    </div>
  );
}

export default ClientSelectDropdown;
