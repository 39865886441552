import { registerChangeEvents, tagColors, useDeleteTag } from '@colosseum/data';
import { ItemTagConnectionType, TagType } from '@gladiate/types';
import { Combobox } from '@headlessui/react';
import { PencilSquareIcon, PlusIcon, TagIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import Slideover from '../Slideover/Slideover';
import { Button } from '../shadcn/Button/Button';
import { Form, FormControl, FormField, FormItem, FormLabel } from '../shadcn/Form/Form';
import { Input } from '../shadcn/Input/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';

/* eslint-disable-next-line */
export interface TagComboboxProps {
  tags: TagType[];
  selectedTags: ItemTagConnectionType[];
  handleCreateResourceTag: ({
    title,
    color,
    tagId,
  }: {
    title?: string;
    color?: string;
    tagId?: string;
  }) => void;
  handleDeleteItemTagConnection: (arg0: string) => void;
  handleUpdateTag: (arg0: any) => void;
  isUpdateTagSuccess: boolean;
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

const formSchema = z.object({
  title: z.string(),
  color: z.string(),
});

export function TagCombobox({
  handleCreateResourceTag,
  handleDeleteItemTagConnection,
  handleUpdateTag,
  selectedTags,
  tags,
}: TagComboboxProps) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [editTagSlideOverOpen, setEditTagSlideOverOpen] = useState(false);
  const deleteTag = useDeleteTag();
  const [tagToEditId, setTagToEditId] = useState('');
  const tagBeingEdited = useMemo(
    () =>
      tags.find((tag) => tag.tagId === tagToEditId) ?? {
        color: '',
        title: '',
      },
    [tags, tagToEditId],
  );

  const filteredTags = tags.filter((tag) => {
    return tag?.title?.toLowerCase().includes(query?.toLowerCase());
  });

  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return { color: tagBeingEdited.color, title: tagBeingEdited.title };
    }, [tagBeingEdited]),
  });

  function handleTitleBlur(e: any) {
    if (e.target.value !== tagBeingEdited.title) {
      // only update if changed
      handleUpdateTag({
        title: e.target.value,
        tagId: tagToEditId,
      });
    }
  }

  function handleColorBlur(color: string) {
    if (color !== tagBeingEdited.color) {
      // only update if changed
      handleUpdateTag({
        color,
        tagId: tagToEditId,
      });
    }
  }

  useEffect(() => {
    form.reset(tagBeingEdited);
  }, [tagBeingEdited]);

  return (
    <>
      <Slideover
        title={'Edit Tag'}
        description={'Edit the selected tag'}
        open={editTagSlideOverOpen}
        setOpen={setEditTagSlideOverOpen}
        displayDeleteButton={true}
        deleteFunction={() => {
          deleteTag.mutate(tagToEditId);
        }}
        typing={false}
      >
        <Form {...form}>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="grid grid-cols-1 m-auto gap-x-3 gap-y-5"
          >
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      {...registerChangeEvents({
                        field,
                        form,
                        handleOnBlur: handleTitleBlur,
                      })}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="color"
              render={({ field }) => (
                <FormItem className="pt-2.5">
                  <FormLabel>Color</FormLabel>
                  <FormControl style={{ marginBottom: '10px' }}>
                    <CirclePicker
                      width="unset"
                      color={tagBeingEdited?.color}
                      colors={tagColors}
                      onChangeComplete={(color) => {
                        handleColorBlur(color.hex);
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </Slideover>
      <div className="relative">
        <Popover modal open={open}>
          <PopoverTrigger asChild>
            <Button
              data-group="combobox"
              variant="outline"
              onClick={() => {
                setOpen(!open);
              }}
              className="p-1 h-[26px] w-[26px]"
            >
              <TagIcon className="w-4 h-4 text-inherit group-hover:text-atlantic-blue" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="bg-white" data-align="right">
            <Combobox
              as="div"
              by="tagId"
              // className="absolute right-auto z-30 top-8 w-60"
              value={selectedTags}
              multiple
              onBlur={(event: React.FocusEvent) => {
                const target = event.relatedTarget as HTMLElement;
                if (target?.dataset?.group !== 'combobox') {
                  // only close combobox if clicking outside of a combobox element
                  setOpen(false);
                }
              }}
            >
              <div className="relative">
                <Combobox.Input
                  autoFocus
                  data-group="combobox"
                  className="w-full py-2 pl-3 pr-20 bg-white rounded-md shadow-sm focus:border-sky-blue focus:outline-none focus:ring-1 focus:ring-sky-blue sm:text-sm"
                  onChange={(event) => {
                    setQuery(event.target.value.trim()); // trimming doesnt allow extra whitespace
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter') {
                      if (query.length > 0) {
                        handleCreateResourceTag({ title: query });
                        setQuery('');
                      }
                    }
                  }}
                  placeholder="Search or create a tag"
                  displayValue={(tag: TagType) => tag?.title ?? ''}
                />
                <div className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                  <Button
                    variant="outline"
                    size="xs"
                    data-group="combobox"
                    onClick={() => {
                      if (query.length > 0) {
                        handleCreateResourceTag({ title: query });
                        setQuery('');
                      }
                    }}
                    className="ml-3"
                  >
                    <PlusIcon className="w-4 h-4" />
                  </Button>
                </div>

                {filteredTags.length > 0 && (
                  <Combobox.Options
                    static
                    className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {filteredTags.map((tag) => {
                      return (
                        <Combobox.Option
                          data-group="combobox"
                          value={tag}
                          key={`${tag?.title} ${tag?.tagId}`}
                          onClick={() => {
                            const existingSelectedTag = selectedTags.find(
                              (parentTag) => parentTag.tagId === tag.tagId,
                            );
                            if (existingSelectedTag && existingSelectedTag.itemTagConnectionId) {
                              handleDeleteItemTagConnection(
                                existingSelectedTag.itemTagConnectionId,
                              );
                            } else {
                              handleCreateResourceTag({
                                tagId: tag.tagId,
                                title: tag?.title,
                                color: tag?.color,
                              });
                            }
                          }}
                          className={({ active, selected }) =>
                            classNames(
                              'relative cursor-pointer select-none py-2 pl-3 pr-3 my-1',
                              active || selected ? 'bg-light-blue text-white' : 'text-gray-900',
                            )
                          }
                        >
                          <div className="flex items-center justify-between group">
                            <span>
                              <span
                                style={{ backgroundColor: tag.color }}
                                className={classNames(
                                  'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                                )}
                              />
                              <span className={classNames('ml-3 truncate')}>{tag.title}</span>
                            </span>
                            <Button
                              variant="ghost"
                              size="xs"
                              textColor="black"
                              className="m-1 opacity-0 group-hover:opacity-100"
                              data-group="combobox"
                              onClick={(e) => {
                                setTagToEditId(tag.tagId ?? '');
                                e.preventDefault();
                                setEditTagSlideOverOpen(true);
                              }}
                            >
                              <PencilSquareIcon className="w-4 h-4" />
                            </Button>
                          </div>
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}

export default TagCombobox;
