import {
  tanstackTableNames,
  useCreateCaseCampaign,
  useCreateContactCampaign,
  useGetCaseCampaigns,
  useGetContactCampaigns,
} from '@colosseum/data';
import {
  CustomErrorBoundary,
  DataTable,
  GladiateLoader,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { CampaignStepOptions, CampaignType, CaseCampaign, ContactCampaign } from '@gladiate/types';
import { withErrorBoundary } from '@sentry/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { TabItem } from '../../insights/InsightsPage/InsightsPage';
import { columns as caseTableColumns } from './case-campaigns-table-columns';
import { columns as contactTableColumns } from './contact-campaigns-table-columns';

/* eslint-disable-next-line */
export interface CampaignsPageProps {}

export const CampaignsPage = () => {
  // #region Hooks
  const navigate = useNavigate();
  const createCaseCampaign = useCreateCaseCampaign();
  const createContactCampaign = useCreateContactCampaign();
  // #endregion

  // #region Data Fetching
  const {
    data: caseCampaignData,
    error: campaignError,
    isFetched: isCampaignFetched,
    isLoading: isCaseCampaignsLoading,
    isError: isCaseCampaignsError,
  } = useGetCaseCampaigns();

  const {
    data: contactCampaignData,
    error: contactError,
    isFetched: isContactFetched,
    isLoading: isContactCampaignsLoading,
    isError: isContactCampaignsError,
  } = useGetContactCampaigns();

  // #endregion

  // #region State
  const [viewType, setViewType] = useState<'table' | 'card'>('table');
  const [activeFilter, setActiveFilter] = useState<CampaignType['status']>('active');

  const TabItems: TabItem[] = [
    {
      key: 'case',
      title: 'Case',
      cypressTag: 'campaigns-case',
    },
    {
      key: 'contact',
      title: 'Contact',
      cypressTag: 'campaigns-contacts',
    },
  ];
  // #endregion

  // #region Derived State
  const caseCampaigns = caseCampaignData?.data ?? [];
  const contactCampaigns = contactCampaignData?.data ?? [];
  // #endregion

  // #region useEffects
  // #endregion

  // #region Event Handlers
  const handleClick = (item: CaseCampaign | ContactCampaign) => {
    if ('caseCampaignId' in item) {
      if (item.status === 'draft') {
        navigate(`/campaigns/${item.caseCampaignId}/case/${CampaignStepOptions.criteria}`);
      } else {
        navigate(`/campaigns/${item.caseCampaignId}/case/${CampaignStepOptions.summary}`);
      }
    } else if ('contactCampaignId' in item) {
      if (item.status === 'draft') {
        navigate(`/campaigns/${item.contactCampaignId}/contact/${CampaignStepOptions.criteria}`);
      } else {
        navigate(`/campaigns/${item.contactCampaignId}/contact/${CampaignStepOptions.summary}`);
      }
    }
  };
  // #endregion

  //  #region Components
  const CreateButton = ({
    onClick,
    isLoading,
    title,
  }: {
    onClick: () => void;
    isLoading: boolean;
    title: string;
  }) => {
    return (
      <button
        data-cy="create-campaign-button"
        onClick={onClick}
        disabled={isLoading}
        type="button"
        className={
          'relative inline-flex items-center px-4 py-2 ml-2  text-sm font-medium text-white rounded-md focus:z-10' +
          (isLoading ? ' cursor-not-allowed bg-gray-300' : ' hover:bg-atlantic-blue bg-sky-blue')
        }
      >
        {isLoading ? (
          <div className="w-16">
            <GladiateLoader white={true} height={20} />
          </div>
        ) : (
          <>{title}</>
        )}
      </button>
    );
  };
  //  #endregion
  return (
    <div className="relative mt-5 bg-white border shadow-sm rounded-xl">
      <Helmet defer={false}>
        <title>Campaigns</title>
      </Helmet>
      <div className="flex w-full px-5">
        <div className="flex flex-col justify-between w-full gap-2 mt-5 ml-1">
          <div className="flex justify-between w-full">
            <h1 className="text-3xl font-semibold">Campaigns</h1>
          </div>
          <div className="flex flex-col pb-5 gap-y-20 pb">
            <div>
              <Tabs defaultValue="case">
                <TabsList
                  id="campaigns"
                  className='my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300"'
                >
                  {TabItems.map((tabItem) => (
                    <TabsTrigger
                      className={tabTriggerClass}
                      key={tabItem.key}
                      value={tabItem.key}
                      data-cy={tabItem.cypressTag}
                    >
                      {tabItem.title}
                    </TabsTrigger>
                  ))}
                </TabsList>
                <TabsContent value="case">
                  <DataTable
                    tableName={tanstackTableNames.caseCampaigns}
                    data={caseCampaigns}
                    initialSort={{
                      id: 'Created Date',
                      desc: true,
                    }}
                    filters={[]}
                    columns={caseTableColumns}
                    handleRowClick={(item) => handleClick(item.original)}
                    isLoading={isCaseCampaignsLoading}
                    isError={isCaseCampaignsError}
                    customRightButton={
                      <CreateButton
                        title="Create Case Campaign"
                        isLoading={createCaseCampaign.isLoading}
                        onClick={() => {
                          createCaseCampaign.mutateAsync({}).then((res) => {
                            navigate(
                              `/campaigns/${res?.data.caseCampaignId}/case/${CampaignStepOptions.criteria}`,
                            );
                          });
                        }}
                      />
                    }
                  />
                </TabsContent>
                <TabsContent value="contact">
                  <DataTable
                    data={contactCampaigns}
                    tableName={tanstackTableNames.contactCampaigns}
                    initialSort={{
                      id: 'Created Date',
                      desc: true,
                    }}
                    filters={[]}
                    columns={contactTableColumns}
                    handleRowClick={(item) => handleClick(item.original)}
                    isLoading={isCaseCampaignsLoading}
                    isError={isCaseCampaignsError}
                    customRightButton={
                      <CreateButton
                        title="Create Contact Campaign"
                        isLoading={createContactCampaign.isLoading}
                        onClick={() => {
                          createContactCampaign.mutateAsync({}).then((res) => {
                            navigate(
                              `/campaigns/${res?.data.contactCampaignId}/contact/${CampaignStepOptions.criteria}`,
                            );
                          });
                        }}
                      />
                    }
                  />
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(CampaignsPage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});
