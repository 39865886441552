import { casesSectionMappings, scrollToCaseSection, useUpdateCaseData } from '@colosseum/data';
import {
  CheckboxFormInput,
  Form,
  MedicalTreatmentAppointmentsTable,
  SlideoverContext,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TextFormInput,
  tabTriggerClass,
} from '@colosseum/shared-ui';
import { CaseType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import MedicalRecordRequests from '../../cases/MedicalRecordRequests/MedicalRecordRequests';
import MedicalTreatmentBills from '../../cases/MedicalTreatments/MedicalTreatmentBills';
import TreatmentHistory from '../../cases/MedicalTreatments/PriorMedicalTreatments/PriorMedicalTreatments';
import ProvidersList from '../ProvidersList/ProvidersList';

const formSchema = z.object({
  clientSignedAuthorizationToReleaseInformationToThirdPartyDefense: z.boolean().optional(),
  descriptionOfClientInjuries: z.string().optional(),
});

const formKeys: { [key: string]: boolean | string } = {}; // make an object of all the schema keys
Object.keys(formSchema.shape).forEach((key: string) =>
  typeof formKeys[key] === 'boolean' ? (formKeys[key] = false) : (formKeys[key] = ''),
);

/* eslint-disable-next-line */
export interface MedicalsSectionProps {
  caseData: CaseType;
}

export function MedicalsSection(props: MedicalsSectionProps) {
  const { caseData } = props;
  const [activeTab, setActiveTab] = useState('providers');
  const { pendingSlideoverToOpen } = useContext(SlideoverContext);
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    values: useMemo(() => {
      return { ...formKeys, ...caseData };
    }, [caseData]) as z.infer<typeof formSchema>,
  });

  const updateCaseData = useUpdateCaseData();

  const handleBlur = (key: string, value: string) => {
    updateCaseData.mutate({
      [key]: value,
      caseId: caseData?.caseId ?? '',
    });
  };

  const renderTextFormInput = (
    key: keyof typeof formSchema.shape,
    title: string,
    type?: string,
  ) => (
    <TextFormInput
      {...form.register(key)}
      handleOnBlur={(e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        handleBlur(key, target.value);
      }}
      title={title}
      type={type}
    />
  );
  const renderCheckboxInput = (name: keyof typeof formSchema.shape, title: string) => (
    <CheckboxFormInput
      {...form.register(name)}
      title={title}
      handleOnChange={(e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        handleBlur(target.name, target.value);
      }}
    />
  );

  useEffect(() => {
    const ref = document.querySelectorAll(
      `[data-case-submenu-item='${casesSectionMappings.medicals}']`,
    )[0];

    if (pendingSlideoverToOpen?.type === 'medicalTreatment') {
      setActiveTab('bills');
      scrollToCaseSection(ref, true);
    } else if (pendingSlideoverToOpen?.type === 'medicalAppointment') {
      setActiveTab('appointments');
      scrollToCaseSection(ref, true);
    } else if (pendingSlideoverToOpen?.type === 'medicalRecordRequest') {
      setActiveTab('recordRequests');
      scrollToCaseSection(ref, true);
    } else if (pendingSlideoverToOpen?.type === 'priorMedicalTreatment') {
      setActiveTab('treatmentHistory');
      scrollToCaseSection(ref, true);
    }
  }, [pendingSlideoverToOpen]);

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <Form {...form}>
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <div className="w-full">
            <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3"></div>
            {renderTextFormInput(
              'descriptionOfClientInjuries',
              'Description of Injuries',
              'textarea',
            )}
            <Tabs value={activeTab} onValueChange={(val: string) => setActiveTab(val)}>
              <TabsList className="my-4 text-gray-500 bg-transparent rounded-none border-b-gray-300">
                <TabsTrigger className={tabTriggerClass} value="providers">
                  Providers
                </TabsTrigger>
                <TabsTrigger className={tabTriggerClass} value="appointments">
                  Appointments
                </TabsTrigger>
                <TabsTrigger className={tabTriggerClass} value="bills" data-cy="medicals-bills-tab">
                  Bills
                </TabsTrigger>
                <TabsTrigger className={tabTriggerClass} value="recordRequests">
                  Record Requests
                </TabsTrigger>
                <TabsTrigger className={tabTriggerClass} value="treatmentHistory">
                  Treatment History
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </form>
      </Form>
      <TabsContent value="appointments">
        <MedicalTreatmentAppointmentsTable caseId={caseData.caseId} />
      </TabsContent>
      <TabsContent value="bills">
        <MedicalTreatmentBills caseId={caseData.caseId} />
      </TabsContent>
      <TabsContent value="recordRequests">
        <MedicalRecordRequests caseId={caseData.caseId} />
      </TabsContent>
      <TabsContent value="treatmentHistory">
        <TreatmentHistory caseId={caseData.caseId} />
      </TabsContent>
      <TabsContent value="providers">
        <ProvidersList caseId={caseData.caseId} />
      </TabsContent>
    </Tabs>
  );
}

export default MedicalsSection;
