import { CampaignStep, CampaignTypeRoute } from '@gladiate/types';
import { useParams } from 'react-router';
import ErrorPage from '../../error/ErrorPage';
import CaseCampaignCriteriaPage from '../CaseCampaignCriteriaPage/CaseCampaignCriteriaPage';
import CaseCampaignStagesPage from '../CaseCampaignStagesPage/CaseCampaignStagesPage';
import ContactCampaignCriteriaPage from '../ContactCampaignCriteriaPage/ContactCampaignCriteriaPage';
import ContactCampaignStagesPage from '../ContactCampaignStagesPage/ContactCampaignStagesPage';
import CaseSummaryPage from '../SummaryPage/CaseSummaryPage';
import ContactSummaryPage from '../SummaryPage/ContactSummaryPage';
import { CaseCampaignSubscriptionPage } from '../CaseCampaignSubscriptionPage/CaseCampaignSubscriptionPage';
import { ContactCampaignSubscriptionPage } from '../ContactCampaignSubscriptionPage/ContactCampaignSubscriptionPage';
import ProtectedRoute from '../../auth/ProtectedRoute/ProtectedRoute';

type CampaignStepSelectParams = {
  campaignId: string;
  campaignStep: CampaignStep;
  campaignType: CampaignTypeRoute;
};

export function CampaignStepSelect() {
  const { campaignId, campaignStep, campaignType } = useParams<CampaignStepSelectParams>();

  if (campaignId === undefined) {
    return (
      <ErrorPage
        code={404}
        errorTitle={'Campaign not found'}
        errorMessage={'Please check the URL in the address bar and try again.'}
        backRoute="/campaigns"
        backMessage="Back to Campaigns"
      />
    );
  }

  switch (campaignStep) {
    case 'criteria':
      return (
        <ProtectedRoute>
          {campaignType === 'case' ? <CaseCampaignCriteriaPage /> : <ContactCampaignCriteriaPage />}
        </ProtectedRoute>
      );
    case 'stages':
      return (
        <ProtectedRoute>
          {campaignType === 'case' ? <CaseCampaignStagesPage /> : <ContactCampaignStagesPage />}
        </ProtectedRoute>
      );
    case 'subscription':
      return (
        <ProtectedRoute>
          {campaignType === 'case' ? (
            <CaseCampaignSubscriptionPage />
          ) : (
            <ContactCampaignSubscriptionPage />
          )}
        </ProtectedRoute>
      );
    case 'summary':
      return (
        <ProtectedRoute>
          {campaignType === 'case' ? <CaseSummaryPage /> : <ContactSummaryPage />}
        </ProtectedRoute>
      );

    default:
      return (
        <ErrorPage
          code={404}
          errorTitle={'Page not found'}
          errorMessage={'Please check the URL in the address bar and try again.'}
          backRoute="/campaigns"
          backMessage="Back to Campaigns"
        />
      );
  }
}

export default CampaignStepSelect;
