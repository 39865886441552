import { BitBoolean, MedicalTreatmentBill } from '@gladiate/types';
import { CompleteMedicalTreatmentsType } from '../hooks/useCompleteMedicalTreatmentValues';

export const numberOrZero = (num?: number) => {
  return typeof num === 'number' && num >= 0 ? num : 0;
};

export const conditionalNumber = (num?: number, condition?: BitBoolean) => {
  return condition === '1' ? numberOrZero(num) : 0;
};

export const getCalculatedFigures = (treatment: MedicalTreatmentBill) => {
  const {
    adjustments,
    amountBilled,
    clientPaidOutOfPocket,
    clientPaidOutOfPocketPayment,
    healthInsuranceCoverage,
    healthInsurancePayments,
    medicalPaymentAmount,
    medicalPaymentCoverage,
    personalInjuryProtectionCoverage,
    personalInjuryProtectionPayment,
    presentBalance,
  } = treatment;

  const netBillAfterAdjustments = amountBilled
    ? numberOrZero(amountBilled) - numberOrZero(adjustments)
    : undefined;

  const totalPayments =
    conditionalNumber(healthInsurancePayments, healthInsuranceCoverage) +
    conditionalNumber(clientPaidOutOfPocketPayment, clientPaidOutOfPocket) +
    conditionalNumber(personalInjuryProtectionPayment, personalInjuryProtectionCoverage) +
    conditionalNumber(medicalPaymentAmount, medicalPaymentCoverage);

  const unpaidBalance =
    netBillAfterAdjustments !== undefined && netBillAfterAdjustments >= 0
      ? netBillAfterAdjustments - totalPayments
      : undefined;

  let inferredUnpaidBalance;
  if (!unpaidBalance && presentBalance && presentBalance > 0) {
    // if unpaid balance doesn't exist we can infer it using present balance
    inferredUnpaidBalance = presentBalance;
  }

  let inferredPresentBalance;
  if (!presentBalance && unpaidBalance && unpaidBalance > 0) {
    // if present balance doesn't exist we can infer it using unpaid balance
    inferredPresentBalance = unpaidBalance;
  }

  const paidPlusOwed =
    numberOrZero(amountBilled) -
    (numberOrZero(adjustments) +
      conditionalNumber(personalInjuryProtectionPayment, personalInjuryProtectionCoverage));

  const reductionAmount =
    unpaidBalance && presentBalance
      ? numberOrZero(unpaidBalance) - numberOrZero(presentBalance)
      : undefined;

  const reductionPercentage =
    unpaidBalance && presentBalance ? (1 - presentBalance / unpaidBalance) * 100 : undefined;

  return {
    ...treatment,
    netBillAfterAdjustments,
    paidPlusOwed,
    reductionAmount,
    reductionPercentage,
    totalPayments,
    unpaidBalance,
    inferredPresentBalance,
    inferredUnpaidBalance,
  };
};

export const getAggregateValues = (treatments: MedicalTreatmentBill[]) => {
  let aggregateUnpaidBalance = 0;
  let aggregatePresentBalance = 0;
  let aggregateNetBillAfterAdjustments = 0;
  let aggregateAmountBilled = 0;
  let aggregateAdjustments = 0;
  let aggregatePaidPlusOwed = 0;
  let aggregateTotalPayments = 0;
  treatments.forEach((treatment) => {
    const completeTreatment = getCalculatedFigures(treatment);
    aggregateUnpaidBalance +=
      completeTreatment.unpaidBalance ?? (completeTreatment.inferredUnpaidBalance || 0);
    aggregatePresentBalance +=
      completeTreatment.presentBalance ?? (completeTreatment?.inferredPresentBalance || 0);
    aggregateNetBillAfterAdjustments += completeTreatment.netBillAfterAdjustments ?? 0;
    aggregateAdjustments += completeTreatment.adjustments ?? 0;
    aggregateAmountBilled += completeTreatment.amountBilled ?? 0;
    aggregatePaidPlusOwed += completeTreatment.paidPlusOwed ?? 0;
    aggregateTotalPayments += completeTreatment.totalPayments ?? 0;
  });
  const aggregateReductions = aggregateUnpaidBalance - aggregatePresentBalance;
  const aggregateReductionsPercent =
    aggregateReductions && aggregateUnpaidBalance
      ? (aggregateReductions / aggregateUnpaidBalance) * 100
      : undefined;

  return {
    aggregateAmountBilled,
    aggregateAdjustments,
    aggregatePaidPlusOwed,
    aggregateNetBillAfterAdjustments,
    aggregatePresentBalance,
    aggregateTotalPayments,
    aggregateReductions,
    aggregateReductionsPercent,
    aggregateUnpaidBalance,
  } as CompleteMedicalTreatmentsType;
};
