import { useUpdateContact } from '@colosseum/data';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';

/* eslint-disable-next-line */

// TODO THIS NEEDS REWORKING ON AUTOCOMPLETE
export interface SmallFormProps {
  role?: string;
  formValues: any;
  typing: boolean;
  setTyping: any;
}

const formSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  name: z.string().optional(),
});

export function SmallForm(props: SmallFormProps) {
  const updateContact = useUpdateContact();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: props.formValues,
  });

  //handle updates to form on blur
  function handleUpdate(field: any, value: any) {
    // if the value is the same as the initial value, we don't need to update the database
    if (props.formValues[field] === value) {
      return;
    }

    const update = {
      contactId: props.formValues.contactId,
      [field]: value,
    };

    updateContact.mutateAsync(update).then(() => {
      //invalidate queries
      props.setTyping(false);
    });
  }

  return (
    <Form {...form}>
      <form>
        <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
          {props.formValues?.role !== 'Billing Entity' &&
          props.formValues?.role !== 'Other - Entity' ? (
            <>
              <TextFormInput
                {...form.register('firstName')}
                title="First Name"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('firstName', target.value);
                }}
              />
              <TextFormInput
                {...form.register('lastName')}
                title="Last Name"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('lastName', target.value);
                }}
              />
            </>
          ) : (
            <TextFormInput
              {...form.register('name')}
              title="Name"
              handleOnBlur={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                handleUpdate('name', target.value);
              }}
            />
          )}
        </div>
      </form>
    </Form>
  );
}

export default SmallForm;
