/**
 * Get a contact by id
 * @param id The id of the contact (contactId)
 *
 * @returns A contact object or null if not found
 */

import { useGetContact } from '../lib/react-query/contact';

export function getContactById(id: string) {
  const contactsQuery = useGetContact(id);

  if (!contactsQuery?.data?.data) {
    return null;
  }

  const contact = contactsQuery.data.data;

  if (!contact) {
    return null;
  }

  const foundContact = contact;

  if (!foundContact) {
    return null;
  }

  return foundContact;
}
