import {
  convertDiffToHoursDuration,
  useCreateTimeLog,
  useGetAllTimeLogs,
  useGetCurrentUser,
  useGetTimeLogCategories,
  useGetTimeLogs,
  useUpdateTimeLog,
} from '@colosseum/data';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import CreateButton from '../CreateButton/CreateButton';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import Slideover from '../Slideover/Slideover';
import TimeLogTable from '../TimeLogTable/TimeLogTable';
import Timer from '../Timer/Timer';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Button } from '../shadcn/Button/Button';
import { Form } from '../shadcn/Form/Form';
import { TabsContent } from '../shadcn/Tabs/Tabs';
dayjs.extend(duration);

const formSchema = z.object({
  details: z.string().optional(),
  timeLogCategoryId: z.string().optional(),
});

export type TimeLogSlideoverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemId?: string;
};

export function TimeLogSlideover(props: TimeLogSlideoverProps) {
  const { open, setOpen, itemId } = props;
  const [selectedTimeLogId, setSelectedTimeLogId] = useState<string>();
  const currentUser = useGetCurrentUser();
  const { data: timeLogData, isLoading } = useGetTimeLogs(
    itemId,
    currentUser?.data?.data?.Username,
  );
  const { data: timeLogDataForCase, isLoading: isTimeLogDataForCaseLoading } =
    useGetTimeLogs(itemId);
  const { data: allTimeLogsData } = useGetAllTimeLogs(currentUser?.data?.data?.Username);
  const { data: timeLogCategoryData } = useGetTimeLogCategories();
  const timeLogCategoryOptions = timeLogCategoryData?.data.reduce((acc, category) => {
    return { ...acc, [category.title ?? '']: category.timeLogCategoryId };
  }, {});
  const selectedTimeLog = timeLogData?.data?.find(
    (timeLog) => timeLog.timeLogId === selectedTimeLogId,
  );
  const createTimeLog = useCreateTimeLog();
  const updateTimeLog = useUpdateTimeLog();
  const unfinishedTimeLog = useMemo(
    () => allTimeLogsData?.data?.find((timeLog) => !timeLog.endTime),
    [allTimeLogsData],
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      details: unfinishedTimeLog?.details ?? '',
      timeLogCategoryId: unfinishedTimeLog?.timeLogCategoryId ?? '',
    },
  });

  const handleCreateTimeLog = () => {
    if (itemId) {
      createTimeLog
        .mutateAsync({
          itemId,
          type: 'case',
        })
        .then((res) => setSelectedTimeLogId(res.data?.timeLogId));
    }
  };

  const handleEndTimeLog = () => {
    if (itemId && selectedTimeLog) {
      updateTimeLog.mutate({
        timeLogId: selectedTimeLog.timeLogId,
        endTime: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedTimeLogId(undefined);
      form.setValue('details', '');
      form.setValue('timeLogCategoryId', '');
    }
    if (unfinishedTimeLog) {
      setSelectedTimeLogId(unfinishedTimeLog?.timeLogId);
      form.setValue('details', unfinishedTimeLog?.details);
      form.setValue('timeLogCategoryId', unfinishedTimeLog?.timeLogCategoryId);
    }
  }, [open]);

  return (
    <Slideover
      open={open}
      setOpen={(e) => setOpen(false)}
      title="Time Log"
      description="Start, stop or edit time logs."
      tabHeaders={[
        { name: 'Timer', value: 'timer', showBottomButtons: true },
        { name: 'Time Log', value: 'log' },
      ]}
      defaultTab="timer"
      submitButton={
        <div className="flex items-center justify-end w-full py-4 pr-4 content-right min-h-[73px]">
          {(!unfinishedTimeLog || timeLogData?.data?.length === 0) && !selectedTimeLog && (
            <CreateButton
              title={selectedTimeLog ? 'Start New Timer' : 'Start Timer'}
              type="button"
              onClick={handleCreateTimeLog}
            />
          )}
          {unfinishedTimeLog && (
            <Button title="Stop Timer" onClick={handleEndTimeLog} variant="destructive">
              Stop timer
            </Button>
          )}
        </div>
      }
    >
      <TabsContent value="timer">
        {isLoading ? (
          <GladiateLoader />
        ) : (
          <div>
            {(!unfinishedTimeLog || timeLogData?.data?.length === 0) && !selectedTimeLog && (
              <div>No Timer active.</div>
            )}
            {selectedTimeLogId && (
              <div className="flex flex-col">
                <div className="pb-4">
                  <Breadcrumbs resourceType="case" resourceId={itemId} />
                </div>
                <div className="flex gap-x-1">
                  <span className="font-semibold">{`Time elapsed: `}</span>
                  {selectedTimeLog?.endTime ? (
                    convertDiffToHoursDuration(
                      dayjs(selectedTimeLog?.endTime).diff(dayjs(selectedTimeLog?.startTime)),
                    ).format('HH:mm:ss')
                  ) : (
                    <Timer startTime={selectedTimeLog?.startTime} runTimer={true} />
                  )}
                </div>
                <Form {...form}>
                  <form>
                    <div className="grid grid-cols-1 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4">
                      <SelectFormInput
                        title="Time Log Category"
                        {...form.register(`timeLogCategoryId`)}
                        listItems={timeLogCategoryOptions ?? {}}
                        listItemsIsObject
                        defaultValue={selectedTimeLog?.timeLogCategoryId}
                        handleOnChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          if (selectedTimeLog) {
                            updateTimeLog.mutate({
                              timeLogId: selectedTimeLog.timeLogId,
                              timeLogCategoryId: target.value,
                            });
                          }
                        }}
                        placeholderText="Select Time Log Category"
                      />
                      <TextFormInput
                        {...form.register('details')}
                        title="Details"
                        handleOnChange={(e) => {
                          const target = e.target as HTMLInputElement;
                          updateTimeLog.mutate({
                            timeLogId: selectedTimeLogId,
                            details: target.value,
                          });
                        }}
                        type="textarea"
                      />
                    </div>
                  </form>
                </Form>
              </div>
            )}
          </div>
        )}
      </TabsContent>
      <TabsContent value="log">
        <TimeLogTable
          timeLogs={timeLogDataForCase?.data ?? []}
          isLoading={isTimeLogDataForCaseLoading}
          compact
        />
      </TabsContent>
    </Slideover>
  );
}

export default TimeLogSlideover;
