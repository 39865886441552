import { PlusIcon } from '@heroicons/react/24/outline';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { Button } from '../shadcn/Button/Button';

/* eslint-disable-next-line */
export interface SharedProps {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  dataCy?: string;
  className?: string;
}

type ConditionalProps =
  | { onClick: React.MouseEventHandler<HTMLButtonElement>; type?: 'button' }
  | { onClick?: never; type: 'submit' };

export type CreateButtonProps = SharedProps & ConditionalProps;

export function CreateButton(props: CreateButtonProps) {
  const { disabled, disabledTooltip, ...rest } = props;

  const ButtonContent = (
    <Button
      variant="primary"
      onClick={(e) => {
        if (props.type !== 'submit') {
          e.preventDefault();
          if (!props.loading) {
            props.onClick(e);
          }
        }
      }}
      data-cy={props.dataCy ?? 'create-button'}
      type={props.type ?? 'button'}
      disabled={(props.loading || disabled) ?? false}
      {...rest}
    >
      <div>
        {props.loading ? (
          <div className="">
            <GladiateLoader white={true} height={20} />
          </div>
        ) : (
          <div className="flex items-center">
            <PlusIcon className="w-4 h-4 sm:mr-1 sm:hidden " />
            <p className="hidden sm:block">{props.title}</p>
          </div>
        )}
      </div>
    </Button>
  );

  return disabledTooltip ? (
    <TooltipWrapper message={props.disabledTooltip}>{ButtonContent}</TooltipWrapper>
  ) : (
    ButtonContent
  );
}

export default CreateButton;
