import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createPaymentMethodV3,
  deletePaymentMethodV3,
  getBillingHistoryV3,
  getPaymentMethodV3,
  getPaymentMethodsV3,
  getSubscriptionsV3,
  updatePaymentMethodV3,
} from '../requests/exactus';
import { getAvailableSubscriptionsV3 } from '../requests/sibyls';

// #region Payment methods
export const useGetPaymentMethod = (paymentMethodId: string) => {
  return useQuery({
    queryKey: [queryKeys.paymentMethods, paymentMethodId],
    queryFn: async () => getPaymentMethodV3(paymentMethodId),
  });
};

/**
 * @returns List of payment methods
 */
export const useGetPaymentMethods = () => {
  return useQuery({
    queryKey: [queryKeys.paymentMethods],
    queryFn: async () => getPaymentMethodsV3(),
  });
};

/**
 * @returns Mutation object for creating a payment method
 */
export const useCreatePaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createPaymentMethodV3>[0]) => createPaymentMethodV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.paymentMethods],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data.meta.userMsg,
        variant: 'error',
      });
    },
  });
};

/**
 * @returns Mutation object for deleting a payment method
 */
export const useDeletePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) => deletePaymentMethodV3(paymentMethodId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.paymentMethods],
      });
    },
  });
};

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updatePaymentMethodV3>[0]) => updatePaymentMethodV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.paymentMethods],
      });
    },
  });
};
// #endregion

// #region Payments
// #endregion

// #region Subscriptions
export const useGetSubscriptions = (queryParams?: string) => {
  return useQuery({
    queryKey: [queryKeys.subscriptions, queryParams],
    queryFn: async () => getSubscriptionsV3(queryParams),
  });
};

export const useGetAvailableSubscriptions = () => {
  return useQuery({
    queryKey: [queryKeys.availableSubscriptions],
    queryFn: () => getAvailableSubscriptionsV3(),
  });
};

// #endregion

// #region Billing History
/**
 * @returns Billing history
 */
export const useGetBillingHistory = () => {
  return useQuery({
    queryKey: [queryKeys.billingHistory],
    queryFn: async () => getBillingHistoryV3(),
  });
};
// #endregion
