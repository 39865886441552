import { cn, useGetStaffRoles, useGetTasksForTaskPlan } from '@colosseum/data';
import { TaskForTaskPlanType } from '@gladiate/types';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';

/* eslint-disable-next-line */
export interface TaskPlanTasksCardInfoProps {
  task: TaskForTaskPlanType;
  setOpen: (value: boolean) => void;
  setSelectedPlanTaskId: (value: string) => void;
}

export function TaskPlanTasksCardInfo(props: TaskPlanTasksCardInfoProps) {
  const { task, setOpen, setSelectedPlanTaskId } = props;
  const { data: staffRolesData } = useGetStaffRoles();

  const { data: tasksForPlanData } = useGetTasksForTaskPlan(task?.planTaskId?.split('-')[0] ?? '');
  const tasksForPlan = tasksForPlanData && tasksForPlanData.data;
  const tasksToCreateOnTaskCompletion = tasksForPlan?.filter(
    (taskForPlan) => taskForPlan.createOnPlanTaskIdCompletion === task?.planTaskId,
  );
  return (
    <div
      className={cn(
        ' border-b border-gray-300 group hover:bg-gray-100 last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer',
      )}
      onClick={() => {
        setSelectedPlanTaskId(task.planTaskId ?? '');
        setOpen(true);
      }}
      key={task.planTaskId}
    >
      <div className="px-2 py-3">
        <div className="">{task.title}</div>
        <div className="pb-4 text-sm text-gray-500">{task.description}</div>
        <div className="flex justify-between">
          <div className="flex items-center text-sm font-semibold text-gray-700">
            {task.assignee && 'Assignee: '}
            <span className="pl-1 font-normal text-gray-500">
              {staffRolesData?.data.find((role) => role.roleId === task.assignee)?.title ??
                'No Assignee'}
            </span>
          </div>
          <div className="flex items-center text-sm font-semibold text-gray-700">
            {task.assigner && 'Assigner: '}
            <span className="pl-1 font-normal text-gray-500">
              {staffRolesData?.data.find((role) => role.roleId === task.assigner)?.title ??
                'No Assigner'}
            </span>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-semibold text-gray-700">
            {!!task.daysToDueDate && 'Days to Due Date: '}
            {!!task.daysToDueDate && (
              <span className="pl-1 font-normal text-gray-500">{task.daysToDueDate}</span>
            )}
          </div>
          <div className="text-sm font-semibold text-gray-700">
            {!!task.creationAfterDaysInStatus && 'Days in Status Before Creation: '}
            {!!task.creationAfterDaysInStatus && (
              <span className="pl-1 font-normal text-gray-500">
                {task.creationAfterDaysInStatus}
              </span>
            )}
          </div>
        </div>

        {tasksToCreateOnTaskCompletion && tasksToCreateOnTaskCompletion?.length > 0 && (
          <TooltipWrapper
            message={tasksToCreateOnTaskCompletion?.map((task) => task.title).join(', ')}
          >
            <div className="text-sm font-semibold text-green-700">
              {`Tasks created upon completion: ${tasksToCreateOnTaskCompletion?.length}`}
            </div>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
}

export default TaskPlanTasksCardInfo;
