import { ColumnDef } from '@tanstack/react-table';
import {
  renderCell,
  renderCellWithChips,
  renderHeader,
} from '../shadcn/data-table/table-render-helpers';
import { BitBoolean, ContactType, RecordRequest } from '@gladiate/types';
import { InsightsMedicalRecordsViewModel, bitBToB, cn, displayContactName } from '@colosseum/data';
import { CheckIcon } from '@heroicons/react/24/outline';
import Typography from '../Typography/Typography';
import { startCase } from 'lodash';

export const columns: ColumnDef<InsightsMedicalRecordsViewModel>[] = [
  {
    accessorFn: (row) => row.recordRequestor,
    id: 'Requested By',
    header: renderHeader,
    cell: (props) => {
      const requestingUserName = props.getValue() as string;
      return (
        <Typography variant="tableCell" className="font-semibold">
          {startCase(requestingUserName)}
        </Typography>
      );
    },
  },
  {
    accessorFn: (row) => row.receivedRecords,
    id: 'Received',
    header: renderHeader,
    cell: (props) => {
      const isCompleted = props.getValue() as BitBoolean;
      if (bitBToB(isCompleted)) {
        return <CheckIcon className="w-5 h-5 text-green-500" />;
      }
      return null;
    },
  },
  {
    accessorFn: (row) => row.requestedInformation,
    id: 'Requested Information',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.requestStatus,
    id: 'Status',
    header: renderHeader,
    cell: (props) => {
      return renderCellWithChips({ props, valueModifierFunc: startCase });
    },
  },
  {
    accessorFn: (row) => row.case?.caseTitle,
    id: 'Case Title',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.provider,
    id: 'Records Provider',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactId = props.getValue();
      // @ts-expect-error contacts is passed in
      const contactData = props.table.options.meta?.contacts;
      const contact = contactData?.find((c: ContactType) => c.contactId === contactId);
      const textClasses = 'font-semibold';
      return (
        <Typography variant="tableCell" className={cn(textClasses, !contactId && 'text-red-500')}>
          {displayContactName(contact) || '<No Records Provider>'}
        </Typography>
      );
    },
  },
];
