import { DefaultV3Error } from '@gladiate/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKeys } from '../../static/queryKeys';
import { enqueueAPISnackbar } from '../../utils/snackbars';
import {
  createSettlementProposalV3,
  deleteSettlementProposalV3,
  getSettlementProposalsV3,
  updateSettlementProposalV3,
} from '../requests/lutatius';

export const useGetSettlementProposals = (
  caseId?: Parameters<typeof getSettlementProposalsV3>[0],
) => {
  return useQuery({
    // This types the success and error functions for axios
    queryKey: [queryKeys.settlements, caseId],
    queryFn: async () => getSettlementProposalsV3(caseId ?? ''),
    enabled: !!caseId,
  });
};

export const useCreateSettlementProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof createSettlementProposalV3>[0]) =>
      createSettlementProposalV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.settlements],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'create-settlement-error',
        variant: 'error',
      });
    },
  });
};

export const useUpdateSettlementProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Parameters<typeof updateSettlementProposalV3>[0]) =>
      updateSettlementProposalV3(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.settlements],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      if (error.response?.data?.meta?.userMsg) {
        enqueueAPISnackbar({
          message: error.response?.data?.meta?.userMsg,
          key: 'update-settlement-error',
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteSettlementProposal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (settlementProposalId?: string) => deleteSettlementProposalV3(settlementProposalId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.settlements],
      });
    },
    onError: (error: AxiosError<DefaultV3Error>) => {
      enqueueAPISnackbar({
        message: error.response?.data?.meta?.userMsg,
        key: 'delete-settlement-error',
        variant: 'error',
      });
    },
  });
};
