import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { TanstackTableTaskType } from './ResourceTasks';

const columnHelper = createColumnHelper<TanstackTableTaskType>();

export const columns = [
  columnHelper.accessor((row) => row.dateCreated, {
    id: 'Date Created',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor((row) => row.title, {
    id: 'Title',
  }),
  columnHelper.accessor((row) => row.assigneesList, {
    id: 'Assignees',
    filterFn: 'assigneesFilter' as any,
  }),
  columnHelper.accessor((row) => row.status, {
    id: 'Status',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor((row) => row.caseId, {
    id: 'Case',
    filterFn: 'arrIncludesSome',
  }),
  columnHelper.accessor(
    (row) => {
      const dueDate =
        row.dueDate && dayjs(row.dueDate).isValid()
          ? dayjs(row.dueDate ?? undefined).unix()
          : undefined;
      return String(dueDate);
    },
    {
      id: 'Due Date',
      filterFn: 'arrIncludesSome',
      sortingFn: 'basic',
    },
  ),
];
