import { CaseType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateCaseData } from '../../../lib/react-query/case';

export const caseIncidentFormSchema = z.object({
  additionalStatuteOfLimitations: z.string().optional(),
  additionalStatuteOfLimitationsLabel: z.string().optional(),
  additionalStatuteOfLimitationsSatisfied: z.string().optional(),
  citationsIssued: z.string().optional(),
  clientCellPhoneUseAtTimeOfIncident: z.boolean().optional(),
  clientCellPhoneUseAtTimeOfIncidentDescription: z.string().optional(),
  clientComingFromPriorToIncident: z.string().optional(),
  clientDescriptionOfIncident: z.string().optional(),
  clientDrugAlcoholUseAtTimeOfIncident: z.boolean().optional(),
  clientDrugAlcoholUseAtTimeOfIncidentDescription: z.string().optional(),
  clientInScopeOfWorkAtTimeOfIncident: z.string().optional(),
  clientGoingToPriorToIncident: z.string().optional(),
  clientStatementMadeAtScene: z.string().optional(),
  constructionPresentAtTimeOfIncident: z.boolean().optional(),
  constructionPresentAtTimeOfIncidentDescription: z.string().optional(),
  dateOfIncident: z.string().optional(),
  dayOfWeekIncidentOccurred: z.string().optional(),
  firstRespondersPresent: z.string().optional(),
  incidentCounty: z.string().optional(),
  incidentCity: z.string().optional(),
  incidentReports: z.string().optional(),
  incidentState: z.string().optional(),
  incidentStreet: z.string().optional(),
  incidentZipCode: z.string().optional(),
  incidentTimeOfDay: z.string().optional(),
  lightingQualityAtTimeOfIncident: z.string().optional(),
  roadConditionsAtTimeOfIncident: z.string().optional(),
  statuteOfLimitations: z.string().optional(),
  statuteOfLimitationsLabel: z.string().optional(),
  statuteOfLimitationsSatisfied: z.string().optional(),
  transportedByAmbulance: z.string().optional(),
  transportedToHospital: z.string().optional(),
  weatherAtTimeOfIncident: z.string().optional(),
});
const formKeys: { [key: string]: string } = {}; // make an object of all the schema keys
Object.keys(caseIncidentFormSchema.shape).forEach((key: string) => (formKeys[key] = ''));

interface useCaseIncidentFormProps {
  caseData?: CaseType;
  setTyping: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCaseIncidentForm = (props: useCaseIncidentFormProps) => {
  const { caseData, setTyping } = props;
  const updateCaseData = useUpdateCaseData();
  const form = useForm<z.infer<typeof caseIncidentFormSchema>>({
    resolver: zodResolver(caseIncidentFormSchema),
    mode: 'onBlur',
    defaultValues: { ...formKeys, ...caseData }, // any key we don't get back from the API, we'll set to an empty string
  });

  useEffect(() => {
    form.reset({ ...formKeys, ...caseData });
  }, [caseData?.caseId]); // This will reset the form when the caseId changes

  function handleUpdate(
    name: keyof Pick<
      CaseType,
      | 'additionalStatuteOfLimitations'
      | 'additionalStatuteOfLimitationsLabel'
      | 'additionalStatuteOfLimitationsSatisfied'
      | 'citationsIssued'
      | 'clientCellPhoneUseAtTimeOfIncident'
      | 'clientCellPhoneUseAtTimeOfIncidentDescription'
      | 'clientComingFromPriorToIncident'
      | 'clientDescriptionOfIncident'
      | 'clientDrugAlcoholUseAtTimeOfIncident'
      | 'clientDrugAlcoholUseAtTimeOfIncidentDescription'
      | 'clientInScopeOfWorkAtTimeOfIncident'
      | 'clientGoingToPriorToIncident'
      | 'clientStatementMadeAtScene'
      | 'constructionPresentAtTimeOfIncident'
      | 'constructionPresentAtTimeOfIncidentDescription'
      | 'dateOfIncident'
      | 'dayOfWeekIncidentOccurred'
      | 'firstRespondersPresent'
      | 'incidentCounty'
      | 'incidentCity'
      | 'incidentReports'
      | 'incidentState'
      | 'incidentStreet'
      | 'incidentZipCode'
      | 'incidentTimeOfDay'
      | 'lightingQualityAtTimeOfIncident'
      | 'roadConditionsAtTimeOfIncident'
      | 'statuteOfLimitations'
      | 'statuteOfLimitationsLabel'
      | 'statuteOfLimitationsSatisfied'
      | 'transportedByAmbulance'
      | 'transportedToHospital'
      | 'weatherAtTimeOfIncident'
    >,
    value?: string | boolean,
  ) {
    if (!caseData) {
      //TODO: Determine if this will ever happen - enqueue snackbar?
      return;
    }
    if (form.getFieldState(name).isDirty) {
      updateCaseData
        .mutateAsync({
          caseId: caseData.caseId,
          [name]: value,
        })
        .then(() => {
          setTyping(false);
          form.resetField(name, { defaultValue: value });
        });
    }
  }
  return { form, handleUpdate };
};
