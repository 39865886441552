import {
  CaseContactConnectionType,
  CaseType,
  ContactViewModelType,
  ReferralSource,
  TagType,
} from '@gladiate/types';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../static/queryKeys';
import { getLeadListViewV3 } from '../requests/sibyls';

export interface LeadListViewType extends CaseType {
  caseContactConnections: CaseContactConnectionType &
    {
      contact: ContactViewModelType;
    }[];
  tags?: TagType[];
  referralSource?: ReferralSource;
}

export const useGetLeadsListView = () => {
  return useQuery({
    queryKey: [queryKeys.cases, 'leads'],
    queryFn: async () => getLeadListViewV3(),
  });
};
