import {
  contactTypeOptions,
  contactTypeOptionsOld,
  contactsSectionMappings,
  displayContactName,
  makeAcronym,
  useGetCaseContactConnectionsByContact,
  useGetCases,
  useSetCaseTitle,
  useUpdateCaseConscriptionData,
  useUpdateContact,
  zodCurrencyType,
  zodPhoneNumberType,
} from '@colosseum/data';
import { CaseType, ContactType, ContactViewModelType } from '@gladiate/types';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as z from 'zod';
import AssociatedCases from '../../AssociatedCases/AssociatedCases';
import CardSection from '../../CardSection/CardSection';
import CommunicationLog from '../../CommunicationLog/CommunicationLog';
import ContactChipDetails from '../../ContactChip/ContactChipDetails';
import ContactConnectionsTable from '../../ContactConnectionsTable/ContactConnectionsTable';
import CustomAtributeValuesForm from '../../CustomAtributeValuesForm/CustomAtributeValuesForm';
import TextSkeleton from '../../TextSkeleton/TextSkeleton';
import { Button } from '../../shadcn/Button/Button';
import { Form } from '../../shadcn/Form/Form';
import BiographicalForm from '../BiographicalForm/BiographicalForm';
import ClientAddressesForm from '../ClientAddressesForm/ClientAddressesForm';
import ClientEmailsForm from '../ClientEmailsForm/ClientEmailsForm';
import ClientEmploymentsForm from '../ClientEmploymentsForm/ClientEmploymentsForm';
import ClientPhoneNumbersForm from '../ClientPhoneNumbersForm/ClientPhoneNumbersForm';
import ContactWebAddresses from '../ContactWebAddresses/ContactWebAddresses';
import { SelectFormInput } from '../SelectFormInput/SelectFormInput';

const Communication = ({ contact }: { contact: ContactType }) => (
  <CommunicationLog contactIds={[contact.contactId]} />
);

const CustomAttributes = ({ contact }: { contact: ContactType }) => (
  <CustomAtributeValuesForm objectId={contact?.contactId} attributeType="contact" />
);

const ContactInitials = ({
  isContactLoading,
  contactName,
}: {
  isContactLoading: boolean | undefined;
  contactName: string;
}) => {
  return (
    <div className="flex items-center justify-center w-20 h-20 text-lg font-semibold uppercase bg-gray-200 rounded-full">
      {isContactLoading ? (
        <div className="w-full h-full bg-gray-100 rounded-full animate-pulse">&nbsp;</div>
      ) : (
        <div>{makeAcronym(contactName, 2)}</div>
      )}
    </div>
  );
};

const ContactSummaryCard = ({
  contact,
  isContactLoading,
}: {
  contact: ContactViewModelType;
  isContactLoading?: boolean;
}) => {
  const contactName = displayContactName(contact);
  const updateContact = useUpdateContact();

  const handleOnChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    updateContact.mutate({
      contactId: contact.contactId,
      [target.name]: target.value,
    });
  };

  return (
    <div data-contact-submenu-item={contactsSectionMappings.summary}>
      <CardSection className="flex flex-col items-center mb-5">
        <ContactInitials isContactLoading={isContactLoading} contactName={contactName} />
        <div className="flex items-center mt-3 text-xl font-bold">
          {isContactLoading ? (
            <div className="w-20">
              <TextSkeleton />
            </div>
          ) : (
            contactName
          )}
        </div>
        <div className="mt-2 text-sm text-gray-500">
          {isContactLoading ? (
            <div className="w-20">
              <TextSkeleton />
            </div>
          ) : (
            <ContactChipDetails contact={contact} hideName />
          )}
        </div>
        <div className="pt-4">
          <SelectFormInput
            title="Contact Type"
            name="contactType"
            listItems={contactTypeOptions}
            listItemsIsObject
            defaultValue={contact.contactType ?? contactTypeOptions.Individual}
            handleOnChange={handleOnChange}
          />
        </div>
      </CardSection>
    </div>
  );
};

const formSchema = z.object({
  firstName: z.string().optional(),
  middleName: z.string().optional(),
  lastName: z.string().optional(),
  nickname: z.string().optional(),
  dateOfBirth: z.string().optional(),
  bankruptcy: z.boolean().optional(),
  bankruptcyDescription: z.string().optional(),
  contactAddresses: z.array(
    z.object({
      streetAddress: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      zipCode: z.string().optional(),
      county: z.string().optional(),
      country: z.string().optional(),
    }),
  ),
  contactNumbers: z.array(
    z.object({
      number: zodPhoneNumberType.optional(),
      phoneExtension: z.string().optional(),
    }),
  ),
  contactEmails: z.array(
    z.object({
      emailAddress: z.string().optional(),
    }),
  ),
  contactEmployments: z.array(
    z.object({
      annualSalary: z.string().optional(),
      directManagerContactConnectionId: z.string().optional(),
      employerContactConnectionId: z.string().optional(),
      endDate: z.string().optional(),
      hourlyRate: zodCurrencyType,
      payType: z.string().optional(),
      responsibilities: z.string().optional(),
      startDate: z.string().optional(),
      title: z.string().optional(),
    }),
  ),
  department: z.string().optional(),
  driversLicenseNumber: z.string().optional(),
  driversLicenseState: z.string().optional(),
  estimatedLostWages: z.string().optional(),
  facebookHandle: z.string().optional(),
  felony: z.boolean().optional(),
  felonyDescription: z.string().optional(),
  gender: z.string().optional(),
  healthClubMembership: z.string().optional(),
  healthClubMembershipId: z.string().optional(),
  impactOfInjuryOnWork: z.string().optional(),
  inScopeOfWorkAtTimeOfIncident: z.boolean().optional(),
  instagramHandle: z.string().optional(),
  legalStatus: z.string().optional(),
  linkedInHandle: z.string().optional(),
  maritalStatus: z.string().optional(),
  name: z.string().optional(),
  position: z.string().optional(),
  prefix: z.string().optional(),
  priors: z.boolean().optional(),
  priorsDescription: z.string().optional(),
  responsibilities: z.string().optional(),
  socialSecurityNumber: z.string().optional(),
  startDate: z.string().optional(),
  suffix: z.string().optional(),
  twitterHandle: z.string().optional(),
  typeOfWork: z.string().optional(),
  union: z.string().optional(),
  unionMembership: z.string().optional(),
  unionMembershipId: z.string().optional(),
  wageRate: z.string().optional(),
  website: z.string().optional(),
  workersCompClaimNumber: z.string().optional(),
});

export interface ExpandSections {
  biographical?: boolean;
  numbers?: boolean;
  emails?: boolean;
  addresses?: boolean;
  employmentHistory?: boolean;
  webAddresses?: boolean;
  caseContactConnection?: boolean;
  communication?: boolean;
  customAttributes?: boolean;
}

/* eslint-disable-next-line */
export interface ContactInfoFormProps {
  caseData: CaseType;
  contact: ContactViewModelType;
  conscriptionId: string;
  showTitle?: boolean;
  inSlideover?: boolean;
  expandSections?: ExpandSections;
}

export function ContactInfoForm(props: ContactInfoFormProps) {
  const { contact, caseData, conscriptionId, showTitle, inSlideover, expandSections } = props;
  const navigate = useNavigate();
  const updateContact = useUpdateContact();
  const updateConscription = useUpdateCaseConscriptionData();
  const { data: casesForContactData } = useGetCaseContactConnectionsByContact(contact.contactId);
  const { data: selectedCasesData } = useGetCases();
  const selectedCases = selectedCasesData?.data ?? [];
  const isOrganization = [
    contactTypeOptions.Organization,
    contactTypeOptionsOld.Organization,
  ].includes(contact.contactType ?? '');

  const setCaseTitle = useSetCaseTitle(caseData?.caseId);

  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: contact,
  });

  // 2. Define a submit handler.

  return (
    <Form {...form}>
      <form>
        {showTitle && (
          <div className="flex col-span-2 pb-5 ">
            <Button
              variant="ghost"
              hoverVariant="lightBlue"
              onClick={() => navigate('/contacts')}
              className="inline-block p-2 mr-4 fadeAnimation"
            >
              <ArrowLeftIcon className="w-5 h-5" />
            </Button>
            <h1 className="ml-1 text-3xl font-semibold">Contact Details</h1>
          </div>
        )}
        <ContactSummaryCard contact={contact} />
        <div className="flex flex-col gap-5">
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.biographical}
            collapseSection={expandSections?.biographical ? false : inSlideover}
            cardHeading={contactsSectionMappings.biographical}
          >
            <BiographicalForm contact={contact} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.contactConnections}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.contactConnections}
          >
            <ContactConnectionsTable contactId={contact?.contactId} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.numbers}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.numbers}
          >
            <ClientPhoneNumbersForm caseId={caseData?.caseId} contact={contact} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.emails}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.emails}
          >
            <ClientEmailsForm contactId={contact?.contactId} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.addresses}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.addresses}
          >
            <ClientAddressesForm contact={contact} />
          </CardSection>
          {!isOrganization && (
            <CardSection
              className="flex flex-col gap-4"
              submenuItem={contactsSectionMappings.employmentHistory}
              collapseSection={inSlideover}
              cardHeading={contactsSectionMappings.employmentHistory}
            >
              <ClientEmploymentsForm contact={contact} />
            </CardSection>
          )}
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.webAddresses}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.webAddresses}
          >
            <ContactWebAddresses contact={contact} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.caseContactConnections}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.caseContactConnections}
          >
            <AssociatedCases contact={contact} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.communication}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.communication}
          >
            <Communication contact={contact} />
          </CardSection>
          <CardSection
            className="flex flex-col gap-4"
            submenuItem={contactsSectionMappings.customAttributes}
            collapseSection={inSlideover}
            cardHeading={contactsSectionMappings.customAttributes}
          >
            <CustomAttributes contact={contact} />
          </CardSection>
        </div>
      </form>
    </Form>
  );
}

export default ContactInfoForm;
