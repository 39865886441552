import {
  processCourtCsv,
  tanstackTableNames,
  useApplyLitigationPlanToCase,
  useCreateCaseLitigationAction,
  useDeleteCaseLitigationAction,
  useGetCaseLitigationActions,
  useGetLitigationPlans,
  useUpdateCaseData,
} from '@colosseum/data';
import {
  CalendarFormInput,
  CaseContactConnectionLinker,
  CourtsTieredSelect,
  CreateButton,
  DataTable,
  Form,
  LitigationActionForm,
  SelectFormInput,
  Slideover,
  SlideoverContext,
  TextFormInput,
} from '@colosseum/shared-ui';
import {
  CaseType,
  LitigationPlanAction,
  LitigationPlanType,
  caseContactConnectionOptions,
} from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { TabsContent } from '@radix-ui/react-tabs';
import { Row } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as z from 'zod';
import { columns } from './LitigationTableColumns';

const formSchema = z.object({
  caseNumber: z.string().optional(),
  courtCounty: z.string().optional(),
  courtDepartment: z.string().optional(),
  courtLocation: z.string().optional(),
  courtName: z.string().optional(),
  courtType: z.string().optional(),
  initialFilingDate: z.string().optional(),
  trialDate: z.string().optional(),
});

const formKeys: { [key: string]: string } = {}; // make an object of all the schema keys
Object.keys(formSchema.shape).forEach((key: string) => (formKeys[key] = ''));

export interface LitigationFormProps {
  caseData?: CaseType;
}

export function LitigationForm(props: LitigationFormProps) {
  const { caseData } = props;
  const { caseId } = useParams<{ caseId: string }>();
  const [litigationPlanToApply, setLitigationPlanToApply] = useState<LitigationPlanType | null>(
    null,
  );
  const [selectedLitigationPlanAction, setSelectedLitigationPlanAction] = useState<
    LitigationPlanAction | undefined
  >();
  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const updateCaseData = useUpdateCaseData();

  const { data: litigationPlanData } = useGetLitigationPlans();
  const litigationPlans = litigationPlanData?.data?.sort(
    (a: LitigationPlanType, b: LitigationPlanType) => {
      const aDate = a.dateModified ? new Date(a.dateModified).getTime() : 0;
      const bDate = b.dateModified ? new Date(b.dateModified).getTime() : 0;

      return bDate - aDate;
    },
  );
  const {
    data: litigationPlanActionsData,
    isLoading: isLitigationPlanActionsLoading,
    isError: isLitigationPlanActionsError,
  } = useGetCaseLitigationActions(caseData?.caseId ?? caseId ?? '');

  const applyLitigationPlan = useApplyLitigationPlanToCase();
  const deleteLitigationPlanAction = useDeleteCaseLitigationAction();
  const createOneOffCaseLitigationAction = useCreateCaseLitigationAction();

  const litigationPlansForSelect = litigationPlans?.map((plan: LitigationPlanType) => {
    return plan.title ?? 'No title';
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      ...formKeys,
      ...caseData,
    },
  });

  useEffect(() => {
    form.reset({ ...formKeys, ...caseData });
  }, [caseData?.caseId]); // This will reset the form when the caseId changes

  const handleBlur = (key: string, value: string) => {
    updateCaseData.mutate({
      [key]: value,
      caseId: caseData?.caseId ?? '',
    });
  };

  const renderTextFormInput = (
    key: any,
    title: string,
    options?: { disabled?: boolean; type?: string },
  ) => (
    <TextFormInput
      {...form.register(key)}
      handleOnBlur={(e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        handleBlur(key, target.value);
      }}
      title={title}
      type={options?.type}
      disabled={options?.disabled}
    />
  );

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'deadlines') {
      const deadlineId = pendingSlideoverToOpen?.id;
      const litigationPlanAction = litigationPlanActionsData?.data?.find(
        (action: LitigationPlanAction) => action.caseLitigationActionId === deadlineId,
      );
      if (litigationPlanAction) {
        setSelectedLitigationPlanAction(litigationPlanAction);
        setSlideoverOpen(true);
        setPendingSlideoverToOpen(undefined);
      }
    }
  }, [litigationPlanActionsData?.data, pendingSlideoverToOpen, setPendingSlideoverToOpen]);

  return (
    <>
      <Slideover
        title="Edit Action"
        open={slideoverOpen}
        description={'Edit litigation action'}
        setOpen={setSlideoverOpen}
        displayDeleteButton={true}
        deleteFunction={() => {
          if (selectedLitigationPlanAction?.caseLitigationActionId) {
            deleteLitigationPlanAction
              .mutateAsync(selectedLitigationPlanAction?.caseLitigationActionId)
              .then((res) => {
                setSlideoverOpen(false);
              });
          }
        }}
        typing={false}
        resourceId={selectedLitigationPlanAction?.caseLitigationActionId ?? ''}
        resourceType={'deadlines'}
      >
        <LitigationActionForm
          litigationPlanAction={selectedLitigationPlanAction}
          otherActions={[]}
          caseAction={true}
        />
      </Slideover>
      <Form {...form}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="w-full">
            <TabsContent value="details">
              <div className="grid grid-cols-2 pt-2 pb-2 gap-y-5 gap-x-3">
                {renderTextFormInput('caseName', 'Case Name')}
                {renderTextFormInput('caseNumber', 'Case Number')}
                <CourtsTieredSelect
                  singleSelect
                  fieldInfo={{
                    title: 'a Court',
                    type: 'tieredSelect',
                    valueVariable: 'court',
                  }}
                  handleOnChange={(e) => {
                    updateCaseData.mutate({
                      courtName: e.value[0],
                      courtType: e.additionalInfo?.type,
                      courtLocation: e.additionalInfo?.state,
                      caseId: caseData?.caseId ?? '',
                    });
                    form.setValue('courtName', e.value[0]);
                    form.setValue('courtType', e.additionalInfo?.type ?? '');
                    form.setValue('courtLocation', e.additionalInfo?.state ?? '');
                  }}
                  courtData={processCourtCsv()}
                  fieldValue={caseData?.courtName ? [caseData.courtName] : []}
                />

                {renderTextFormInput('courtType', 'Court Type', {
                  disabled: true,
                })}
                {renderTextFormInput('courtLocation', 'Court Location', {
                  disabled: true,
                })}
                {renderTextFormInput('courtCounty', 'Court County')}
                <CalendarFormInput
                  {...form.register(`initialFilingDate`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleBlur('initialFilingDate', target.value);
                  }}
                  title="Initial Filing Date"
                  resourceTypeObj={{
                    id: caseData?.caseId,
                    type: 'litigation-details',
                  }}
                />
                <CalendarFormInput
                  {...form.register(`trialDate`)}
                  handleOnChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    handleBlur('trialDate', target.value);
                  }}
                  title="Trial Date"
                  resourceTypeObj={{
                    id: caseData?.caseId,
                    type: 'litigation-details',
                  }}
                />
                <CaseContactConnectionLinker
                  caseId={caseData?.caseId ?? ''}
                  title="Judge"
                  roleOnCase={caseContactConnectionOptions.judge}
                />
                <div></div>
                <CaseContactConnectionLinker
                  caseId={caseData?.caseId ?? ''}
                  title="Judge Assistant"
                  roleOnCase={caseContactConnectionOptions.judgeAssistant}
                />
              </div>
            </TabsContent>

            <TabsContent value="deadlines">
              <div className="p-2">
                <div className="mb-4 sm:flex sm:items-center">
                  <div className="flex items-center justify-between w-full align-middle">
                    <div className="w-1/2">
                      <SelectFormInput
                        title="Litigation Plan"
                        name="Litigation Plan"
                        listItems={litigationPlansForSelect ?? []}
                        handleOnChange={(e: React.SyntheticEvent) => {
                          const target = e.target as HTMLInputElement;
                          const litigationPlan = litigationPlans?.find(
                            (plan: LitigationPlanType) => {
                              return plan.title === target.value;
                            },
                          );

                          if (litigationPlan) {
                            setLitigationPlanToApply(litigationPlan);
                          } else {
                            setLitigationPlanToApply(null);
                          }
                        }}
                      />
                    </div>
                    <div className="flex items-center">
                      <CreateButton
                        className="mx-2"
                        disabled={!litigationPlanToApply}
                        title={'Generate Deadlines'}
                        loading={applyLitigationPlan.isLoading}
                        onClick={() => {
                          applyLitigationPlan.mutate({
                            caseId: caseData?.caseId ?? '',
                            litigationPlanId: litigationPlanToApply?.litigationPlanId ?? '',
                          });
                        }}
                      />
                      <CreateButton
                        title={'Add Action'}
                        loading={createOneOffCaseLitigationAction.isLoading}
                        onClick={() => {
                          createOneOffCaseLitigationAction
                            .mutateAsync(caseData?.caseId ?? '')
                            .then((res) => {
                              setSelectedLitigationPlanAction({
                                caseLitigationActionId: res.data.caseLitigationActionId,
                              });
                              setSlideoverOpen(true);
                            });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  data={litigationPlanActionsData?.data || []}
                  initialSort={{
                    id: 'Action',
                    desc: true,
                  }}
                  handleRowClick={(row: Row<LitigationPlanAction>) => {
                    setSlideoverOpen(true);
                    setSelectedLitigationPlanAction(row.original);
                  }}
                  showSearchBar
                  columns={columns}
                  isLoading={isLitigationPlanActionsLoading}
                  isError={isLitigationPlanActionsError}
                  tableName={tanstackTableNames.litigationPlanAction}
                />
              </div>
            </TabsContent>
          </div>
        </form>
      </Form>
    </>
  );
}

export default LitigationForm;
