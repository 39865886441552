import { cn, commaSafeSplit, escapeCommasInParam } from '@colosseum/data';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { uniqBy } from 'lodash';
import TooltipWrapper from '../data-display/TooltipWrapper/TooltipWrapper';
import { Command, CommandGroup, CommandInput, CommandItem } from '../shadcn/Command/Command';
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/Popover/Popover';
import Typography from '../Typography/Typography';

export interface FilterComboboxProps {
  title: string;
  updateKey: string;
  options: (
    | {
        value: any;
        label: string;
      }
    | null
    | undefined
  )[];
  hideSearch?: boolean;
  externalState?: { [key: string]: string }; // Add this line
  setExternalState?: (key: string, value: string) => void; // Add this line
  isLoading?: boolean;
  ignoreAlphabeticalOrder?: boolean;
  placeholder?: string;
  disableAdhocAdd?: boolean;
  tooltipMessage?: string;
}

const capitalizeTitle = (title: string) => {
  return title
    .replace(/\b(\w)/g, (s) => s.toUpperCase())
    .replace(/([A-Z])/g, '$1')
    .trim();
};

export function FilterCombobox(props: FilterComboboxProps) {
  const {
    disableAdhocAdd = false,
    externalState,
    ignoreAlphabeticalOrder,
    isLoading,
    options,
    placeholder,
    setExternalState,
    title,
    tooltipMessage,
    updateKey,
  } = props;

  const validOptions = useMemo(
    () =>
      (
        options.filter((option) => option !== null) as {
          value: string | undefined;
          label: string | undefined;
          context?: string;
        }[]
      ).filter((option) => option.value !== undefined && option.label !== undefined) as {
        value: string;
        label: string;
        context?: string;
      }[],
    [options],
  );

  const capitalizedTitle = capitalizeTitle(title);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    externalState ? externalState[updateKey] : queryParams.get(updateKey) || '',
  );
  const [adhocValue, setAdhocValue] = useState(''); // New state for adhoc input
  const [localOptions, setLocalOptions] = useState(validOptions);

  const handleAdhocAddition = () => {
    if (adhocValue.trim() === '') return;
    // Add the ad-hoc value to the local options if not already there
    if (!localOptions.some((opt) => opt.value === adhocValue)) {
      setLocalOptions((prevOptions) => {
        // Add the new option and then sort the list
        const updatedOptions = [...prevOptions, { value: adhocValue, label: adhocValue }];
        return ignoreAlphabeticalOrder
          ? updatedOptions
          : updatedOptions.sort((a, b) => {
              if (typeof a.label === 'string' && typeof b.label === 'string') {
                return a.label.localeCompare(b.label);
              }
              return 0;
            });
      });
    }

    handleOptionSelect(adhocValue);
    setAdhocValue('');
  };

  const addAdhocOptionsFromURL = () => {
    const currentParams = commaSafeSplit(queryParams.get(updateKey));
    const newLocalOptions = [...localOptions]; // copy the existing options

    currentParams.forEach((param) => {
      if (!localOptions.some((opt) => opt.value === param)) {
        newLocalOptions.push({ value: param, label: param });
      }
    });

    setLocalOptions(
      ignoreAlphabeticalOrder
        ? newLocalOptions
        : newLocalOptions.sort((a, b) => {
            if (typeof a.label === 'string' && typeof b.label === 'string') {
              return a.label.localeCompare(b.label);
            }
            return 0;
          }),
    );
  };

  const handleOptionSelect = (currentValue: string) => {
    const sanitizedCurrentValue = escapeCommasInParam(currentValue);
    if (externalState && setExternalState) {
      // Use external state if provided
      setExternalState(updateKey, currentValue);
    } else {
      // Fallback to the original behavior using query params
      const currentParamsValue = queryParams.get(updateKey);
      const values = currentParamsValue ? commaSafeSplit(currentParamsValue, true) : [];
      const index = values.indexOf(sanitizedCurrentValue);

      if (index !== -1) {
        values.splice(index, 1); // if the value is already in the array, remove it
      } else {
        values.push(sanitizedCurrentValue); // otherwise, add it
      }

      if (values.length === 0) {
        queryParams.delete(updateKey);
      } else {
        queryParams.set(updateKey, values.join(','));
      }

      navigate({ search: queryParams.toString() }, { replace: true });
    }
  };

  const specificQueryParam = queryParams.get(updateKey);

  useEffect(() => {
    addAdhocOptionsFromURL();
  }, [specificQueryParam]);

  useEffect(() => {
    setLocalOptions((prevValue) => uniqBy([...prevValue, ...validOptions], 'value'));
  }, [validOptions]);

  useEffect(() => {
    if (externalState) {
      setValue(externalState[updateKey] || '');
    } else {
      setValue(queryParams.get(updateKey) || '');
    }
  }, [externalState, queryParams, updateKey]);

  const selectedValues = externalState
    ? typeof externalState[updateKey] === 'string'
      ? commaSafeSplit(escapeCommasInParam(externalState[updateKey])) ?? [] // we want to show all options - but if option has a comma in it we don't want to treat that as multiple options
      : []
    : typeof queryParams.get(updateKey) === 'string'
    ? commaSafeSplit(queryParams.get(updateKey)) ?? []
    : [];

  // I know this is a beefy useEffect, but it ensures a default value if there is only one option
  useEffect(() => {
    // Check if there's only one option and external state is being used
    if (externalState && setExternalState && localOptions.length === 1) {
      const soleOptionValue = localOptions[0].value;
      setExternalState(updateKey, soleOptionValue); // Set the external state
      setValue(soleOptionValue); // Update the internal state
    }
  }, [localOptions, updateKey]);

  const getButtonTitle = () => {
    if (selectedValues.length === 0) {
      return `Select an option...`;
    } else if (selectedValues.length === 1 && selectedValues[0] === '') {
      return `Select an option...`;
    } else if (selectedValues.length === 1) {
      return localOptions.find((option) => option.value === selectedValues[0])?.label;
    } else if (selectedValues.length > 2) {
      return `${selectedValues.length} selected`;
    } else {
      // If two options are selected, show both of them
      return selectedValues
        .map((value) => localOptions.find((option) => option.value === value)?.label)
        .join(', ');
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <div className="relative">
          <div className="absolute flex text-gray-400 truncate bg-white left-2 -top-2">
            <span className="px-1 text-xs ">{capitalizedTitle}</span>
            {tooltipMessage && (
              <div className="pr-1">
                <TooltipWrapper message={tooltipMessage}>
                  <QuestionMarkCircleIcon className="self-center w-3 h-3 text-gray-500" />
                </TooltipWrapper>
              </div>
            )}
          </div>
          <button
            aria-expanded={open}
            type="button"
            className="flex items-center justify-between w-full px-3 py-2 text-sm font-medium text-left text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm text-nowrap hover:bg-gray-50 focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          >
            <span className="truncate">{getButtonTitle()}</span>
            <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </button>
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-full p-0 overflow-y-auto max-h-96 min-w-[200px]">
        <Command className="w-[500px]">
          {!props.hideSearch && (
            <CommandInput
              placeholder={`Search ${!disableAdhocAdd ? 'or add ' : ''}${capitalizedTitle}...`}
              value={adhocValue}
              onValueChange={(currentValue) => setAdhocValue(currentValue)}
            />
          )}
          {localOptions.length === 0 && adhocValue === '' && (
            <div className="p-4">No options available, type to add one</div>
          )}
          <CommandGroup>
            {adhocValue &&
              !disableAdhocAdd &&
              !localOptions.some((option) => option.value === adhocValue) && (
                <CommandItem
                  className="font-semibold"
                  key={`adhoc-${adhocValue}`}
                  onSelect={handleAdhocAddition}
                >
                  {`Add "${adhocValue}"`}
                </CommandItem>
              )}
            <Virtuoso
              style={{
                height: `${localOptions.length * 50}px`,
                maxHeight: '180px',
              }}
              className="max-w-[90vw]"
              totalCount={localOptions.length}
              itemContent={(index: number) => (
                <CommandItem
                  key={`${localOptions[index].value}-${index}`}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    handleOptionSelect(localOptions[index].value);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4 flex-shrink-0',
                      selectedValues.includes(localOptions[index].value)
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  <div>
                    <Typography className="max-w-[90vw] sm:max-w-[600px] line-clamp-2">
                      {localOptions[index].label}
                    </Typography>
                    {localOptions[index].context && (
                      <Typography variant="subtext" size="xs" className="truncate">
                        {localOptions[index].context}
                      </Typography>
                    )}
                  </div>
                </CommandItem>
              )}
            />

            {/* {localOptions.map(
              (
                option, // change from options to localOptions
                index
              ) => (
                <CommandItem
                  key={`${option.value}-${index}`}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue);
                    handleOptionSelect(option.value);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4 flex-shrink-0',
                      selectedValues.includes(option.value)
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                  <div>
                    <Typography className="max-w-[90vw] sm:max-w-[600px] line-clamp-2">
                      {option.label}
                    </Typography>
                    {option.context && (
                      <Typography
                        variant="subtext"
                        size="xs"
                        className="truncate"
                      >
                        {option.context}
                      </Typography>
                    )}
                  </div>
                </CommandItem>
              ) */}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default FilterCombobox;
