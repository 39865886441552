import {
  CommunicationLogViewModel,
  bitBToB,
  formatPhoneNumber,
  getUpdateBody,
  useGetCommunicationLogViewModel,
  useUpdateEmail,
  useUpdateManualCommunication,
} from '@colosseum/data';
import { ProvisionedPhoneType } from '@gladiate/types';
import { TabsContent } from '@radix-ui/react-tabs';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import GladiateLoader from '../GladiateLoader/GladiateLoader';
import CalendarFormInput from '../forms/CalendarFormInput/CalendarFormInput';
import SelectFormInput from '../forms/SelectFormInput/SelectFormInput';
import TextFormInput from '../forms/TextFormInput/TextFormInput';
import { Form } from '../shadcn/Form/Form';
/* eslint-disable-next-line */
export interface CommunicationLogFormProps {
  clientCommunicationsNumbers?: ProvisionedPhoneType[];
  contactsList: { [key: string]: string };
  selectedCommunication?: CommunicationLogViewModel;
  setTyping: Dispatch<SetStateAction<boolean>>;
}

export function CommunicationLogForm(props: CommunicationLogFormProps) {
  const { clientCommunicationsNumbers, contactsList, selectedCommunication, setTyping } = props;
  const form = useForm({
    mode: 'onBlur',
    values: selectedCommunication,
  });
  const { caseId } = useParams() as { caseId: string };

  const updateManualCommunication = useUpdateManualCommunication();
  const updateEmailCommunication = useUpdateEmail(caseId);

  const { data: tableData } = useGetCommunicationLogViewModel('', [
    selectedCommunication?.contactId ?? '',
  ]);

  const handleOnChange = (e: React.SyntheticEvent) => {
    setTyping(true);
  };

  const handleUpdateCommunication = (data: CommunicationLogViewModel) => {
    const update = getUpdateBody(data);
    if (data.type === 'emails') {
      updateEmailCommunication.mutate({
        data: update,
      });
    } else {
      updateManualCommunication.mutate({
        data: update,
        type: data.type,
      });
    }
    setTyping(false);
  };

  const toNumberOptions: { [key: string]: string } =
    (selectedCommunication &&
      selectedCommunication?.contactId &&
      tableData.contacts[selectedCommunication?.contactId] &&
      tableData.contacts[selectedCommunication?.contactId]?.phoneNumberE164List.reduce(
        (acc, number) => {
          return {
            ...acc,
            [formatPhoneNumber(number ?? '')]: number,
          };
        },
        {},
      )) ||
    [];

  const fromNumberOptions: { [key: string]: string } = {
    ...clientCommunicationsNumbers?.reduce((acc, phone) => {
      return {
        ...acc,
        [formatPhoneNumber(phone.phoneNumber)]: phone.phoneNumber,
      };
    }, {}),
  };

  return (
    <TabsContent value="details">
      {selectedCommunication ? (
        <div className="space-y-4">
          {(form.getValues().contactId || selectedCommunication?.contactId) && (
            <Breadcrumbs
              resourceType="contact"
              resourceId={form.getValues().contactId || selectedCommunication.contactId}
            />
          )}
          <Form {...form}>
            <form className="grid grid-cols-2 mb-2 gap-x-3 gap-y-5">
              {selectedCommunication?.type !== 'emails' && (
                <SelectFormInput
                  {...form.register('fromNumber')}
                  title="Firm Number"
                  listItemsIsObject
                  listItems={fromNumberOptions}
                  value={
                    selectedCommunication?.direction === 'inbound' // this logic is needed because the fromNumber and toNumber are switched based on message direction in BE
                      ? selectedCommunication.toNumber
                      : selectedCommunication?.fromNumber
                  }
                  handleOnChange={(e) => {
                    const target = e.target as HTMLSelectElement;
                    handleUpdateCommunication({
                      id: selectedCommunication.id,
                      type: selectedCommunication.type,
                      fromNumber: target.value,
                    });
                  }}
                  disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
                />
              )}
              {selectedCommunication?.type !== 'emails' && (
                <SelectFormInput
                  {...form.register('toNumber')}
                  title="Contact Number"
                  listItemsIsObject
                  listItems={toNumberOptions}
                  value={
                    selectedCommunication?.direction === 'inbound' // this logic is needed because the fromNumber and toNumber are switched based on message direction in BE
                      ? selectedCommunication?.fromNumber
                      : selectedCommunication?.toNumber
                  }
                  handleOnChange={(e) => {
                    const target = e.target as HTMLSelectElement;
                    handleUpdateCommunication({
                      id: selectedCommunication.id,
                      type: selectedCommunication.type,
                      toNumber: target.value,
                    });
                  }}
                  disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
                />
              )}
              <SelectFormInput
                valueClassNames="capitalize"
                contentClassNames="capitalize"
                name="direction"
                title="Direction"
                listItems={['inbound', 'outbound']}
                defaultValue={selectedCommunication?.direction}
                handleOnChange={(e) => {
                  const target = e.target as HTMLSelectElement;
                  handleUpdateCommunication({
                    id: selectedCommunication.id,
                    type: selectedCommunication.type,
                    direction: target.value,
                  });
                }}
                disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
              />

              {selectedCommunication?.type === 'emails' && (
                <SelectFormInput
                  valueClassNames="capitalize"
                  contentClassNames="capitalize"
                  name="contactId"
                  title="Contact"
                  listItemsIsObject
                  listItems={contactsList}
                  defaultValue={selectedCommunication?.contactId}
                  handleOnChange={(e) => {
                    const target = e.target as HTMLSelectElement;
                    handleUpdateCommunication({
                      id: selectedCommunication.id,
                      type: selectedCommunication.type,
                      contactId: target.value,
                    });
                  }}
                />
              )}
              <CalendarFormInput
                name="dateTime"
                title="Date"
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdateCommunication({
                    id: selectedCommunication.id,
                    type: selectedCommunication.type,
                    dateTime: target.value,
                  });
                }}
                disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
                hideSyncButton
              />
              {selectedCommunication?.type === 'emails' && (
                <div className="col-span-2">
                  <TextFormInput
                    name="subject"
                    title="Subject"
                    type="textarea"
                    placeholderText="Enter the email subject ..."
                    handleOnBlur={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      handleUpdateCommunication({
                        id: selectedCommunication.id,
                        type: selectedCommunication.type,
                        subject: target.value,
                      });
                    }}
                    handleOnChange={handleOnChange}
                    disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
                  />
                </div>
              )}
              <div className="col-span-2">
                {selectedCommunication?.type === 'messages' && (
                  <TextFormInput
                    name="body"
                    title="Message Body"
                    type="textarea"
                    handleOnBlur={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      handleUpdateCommunication({
                        id: selectedCommunication.id,
                        type: selectedCommunication.type,
                        body: target.value,
                      });
                    }}
                    handleOnChange={handleOnChange}
                    disabled={bitBToB(selectedCommunication?.isSystemGenerated)}
                  />
                )}
                {['calls', 'emails'].includes(selectedCommunication?.type) && (
                  <TextFormInput
                    name="description"
                    title="Description"
                    type="textarea"
                    placeholderText="Enter a short note ..."
                    handleOnBlur={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      handleUpdateCommunication({
                        id: selectedCommunication.id,
                        type: selectedCommunication.type,
                        description: target.value,
                      });
                    }}
                    handleOnChange={handleOnChange}
                  />
                )}
              </div>
            </form>
          </Form>
        </div>
      ) : (
        <GladiateLoader />
      )}
    </TabsContent>
  );
}

export default CommunicationLogForm;
